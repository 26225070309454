import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage, useIntl } from 'react-intl';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { useEffect } from 'react';
import { fromInvoices, fromStarterPackage } from '../../../../store/rootReducer';
import {
  getFeatureFromPlan,
  getFeatureName,
  getUnitName,
  Invoice,
  isPlatformProduct,
  isTestExecutionProduct,
  OrganizationFeature,
} from '../../../../models';
import { useAppDispatch } from '../../../../store';

const useStyles = makeStyles(theme => ({
  name: {
    marginBottom: theme.spacing(0),
    fontSize: 14,
    fontWeight: 500,
    color: '#233145',
  },
  sumPrice: {
    marginBottom: theme.spacing(0),
    fontSize: 14,
    fontWeight: 600,
    color: '#22283c',
  },
  unitPrice: {
    margin: theme.spacing(1, 0),
    fontSize: 12,
    fontWeight: 500,
    color: '#727993',
  },
  billingCycle: {
    margin: theme.spacing(1, 0),
    fontSize: 12,
    fontWeight: 500,
    color: '#4d5369',
  },
  line: {
    border: 'none',
    borderTop: '1px dashed #d6dae4',
    marginTop: theme.spacing(0.5),
  },
}));

interface PackageItemProps{
  accountId: number,
  packageName: string,
}
const PackageItem = (props: PackageItemProps) => {
  const {
    accountId,
    packageName,
  } = props;
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const {
    packageLoading,
    packagePreview,
  } = useSelector(fromInvoices.selectStarterPackageInvoicePreview);

  const currentStarterPackage = useSelector(fromStarterPackage.selectCurrentStarterPackage);

  useEffect(() => {
    const doPreviewStarterPackageInvoice = async () => {
      await dispatch(fromInvoices.doPreviewStarterPackageInvoice({
        accountId,
        packageName,
      }));
    };
    doPreviewStarterPackageInvoice().catch(() => {
    });
  }, [packageName]);

  const renderReplaceTestExecutionProduct = (invoices: Invoice[]) => {
    const from = getFeatureName(
      getFeatureFromPlan(invoices.find(invoice => invoice.total!! < 0)?.planId!!),
    );
    const to = getFeatureName(
      getFeatureFromPlan(invoices.find(invoice => invoice.total!! > 0)?.planId!!),
    );
    return (
      <FormattedMessage
        id="starter.replace.test.execution"
        values={{
          from: <FormattedMessage id={`subscriptions.${from}.title`} />,
          to: <FormattedMessage id={`subscriptions.${to}.title`} />,
        }}
      />
    );
  };

  const renderPackageItem = (feature: OrganizationFeature, productQuota: number) => {
    const productName = getFeatureName(feature);
    const quota = productQuota - (currentStarterPackage?.listPlans
      ?.find(plan => !isPlatformProduct(feature) && plan.feature === feature)?.quota ?? 0);
    return (
      <FormattedMessage
        id="starter.package.item"
        values={{
          productName: <FormattedMessage id={`subscriptions.${productName}.title`} />,
          quantity: quota,
          unit: <FormattedMessage id={getUnitName(productName, quota)} />,
        }}
      />
    );
  };

  const renderTestExecutionProducts = (testExecutionInvoices: Invoice[]) => {
    if (testExecutionInvoices.length > 1) {
      return renderReplaceTestExecutionProduct(testExecutionInvoices);
    }

    const feature = getFeatureFromPlan(testExecutionInvoices[0]?.planId!!);
    return feature && renderPackageItem(
      feature,
      testExecutionInvoices[0].quantity!!,
    );
  };

  const renderPlatformItem = (platformInvoice: Invoice[]) => {
    const feature = getFeatureFromPlan(platformInvoice[0]?.planId!!);
    return (
      <>
        {feature && renderPackageItem(
          feature,
          platformInvoice[0].quantity!!,
        )}
        {platformInvoice[0].total === 0 && <FormattedMessage id="starter.package.item.free" />}
      </>
    );
  };

  const renderKseItem = (kseInvoice: Invoice[]) => {
    const feature = getFeatureFromPlan(kseInvoice[0]?.planId!!);

    return feature && renderPackageItem(
      feature,
      kseInvoice[0].quantity!!,
    );
  };

  const renderStarterPackageItem = () => {
    const testExecutionInvoices = packagePreview
      ?.invoices
      ?.filter(invoice => isTestExecutionProduct(getFeatureFromPlan(invoice?.planId!!))) ?? [];
    const platformInvoices = packagePreview
      ?.invoices
      ?.filter(invoice => isPlatformProduct(getFeatureFromPlan(invoice?.planId!!))) ?? [];
    const kseInvoices = packagePreview
      ?.invoices
      ?.filter(invoice => getFeatureFromPlan(invoice?.planId!!)
        === OrganizationFeature.PER_USER_KSE) ?? [];
    return (
      <>
        <p className={classes.unitPrice}>
          {platformInvoices.length > 0
            && renderPlatformItem(platformInvoices)}
        </p>
        <p className={classes.unitPrice}>
          {testExecutionInvoices.length > 0
            && renderTestExecutionProducts(testExecutionInvoices)}
        </p>
        <p className={classes.unitPrice}>
          {kseInvoices.length > 0
            && renderKseItem(kseInvoices)}
        </p>
      </>
    );
  };

  return (
    <>
      <Grid id="package-item" container>
        <Grid item xs={9}>
          <p className={classes.name}>
            {packagePreview?.name}
          </p>
        </Grid>
        <Grid item xs={3} display="flex" justifyContent="flex-end" alignItems="center">
          {
            packageLoading
              ? <CircularProgress size={20} />
              : <p className={classes.sumPrice}>{`$${intl.formatNumber(packagePreview?.subtotal ?? 0)}`}</p>
          }
        </Grid>
        <Grid item xs={9}>
          {renderStarterPackageItem()}
        </Grid>
        <Grid item xs={3} display="flex" justifyContent="flex-end" alignItems="top">
          <p className={classes.billingCycle}>
            <FormattedMessage id="usage_plan_interval_yearly" />
          </p>
        </Grid>
      </Grid>
      <div className={classes.line} />
    </>
  );
};

export default PackageItem;
