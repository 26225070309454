import { EntityState, createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { SecuritySettings, UpdateSecuritySettings } from '../models/securitySetting';
import { AppState } from '.';
import { Operator, Query } from '../models/query';
import { SecuritySettingService } from '../services';
import { exceptionOf } from '../models';

export const SECURITY_SETTINGS_FEATURE_KEY = 'securitySettings';

interface SecuritySettingState extends EntityState<SecuritySettings> {
  loading: { [key: string]: boolean };
  error?: string;
}

const securitySettingAdapter = createEntityAdapter<SecuritySettings>();

export const createInitialState = (): SecuritySettingState => (
  securitySettingAdapter.getInitialState({
    loading: {},
  })
);

const securitySettingSlice = createSlice({
  name: SECURITY_SETTINGS_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAllSecuritySettings.pending, state => {
      state.loading[getAllSecuritySettings.typePrefix] = true;
    });
    builder.addCase(getAllSecuritySettings.fulfilled, (state, action) => {
      state.loading[getAllSecuritySettings.typePrefix] = false;
      securitySettingAdapter.setAll(state, action.payload.data);
    });
    builder.addCase(getAllSecuritySettings.rejected, state => {
      state.loading[getAllSecuritySettings.typePrefix] = false;
    });
    // upsersAccountSettings
    builder.addCase(doUpsertSecuritySettings.pending, state => {
      state.loading[doUpsertSecuritySettings.typePrefix] = true;
    });
    builder.addCase(doUpsertSecuritySettings.fulfilled, (state, action) => {
      state.loading[doUpsertSecuritySettings.typePrefix] = false;
      securitySettingAdapter.updateOne(state, {
        id: action.payload.data.id,
        changes: action.payload.data,
      });
    });
    builder.addCase(doUpsertSecuritySettings.rejected, (state, action) => {
      state.loading[doUpsertSecuritySettings.typePrefix] = false;
      state.error = action.error.message;
    });
  },
});

const selectSecuritySettings = (state: AppState) => state[SECURITY_SETTINGS_FEATURE_KEY];

export const {
  selectAll: selectAllSecuritySettings,
  selectEntities,
} = securitySettingAdapter.getSelectors(selectSecuritySettings);

export const selectAllSecuritySettingsByAccountId = (accountId: number) => createSelector(
  selectAllSecuritySettings,
  securitySettings => securitySettings.filter(setting => setting.accountId === accountId),
);

export const getAllSecuritySettings = createAsyncThunk(
  'securitySettings/getAllSecuritySettings',
  async (input: Partial<Pick<SecuritySettings, 'accountId'>>) => {
    const criteria: Array<Query<SecuritySettings>> = [
      {
        field: 'accountId',
        operator: Operator.EQ,
        value: input.accountId,
      },
    ];

    const response = await SecuritySettingService.getAllSecuritySettings(...criteria);
    response.data.forEach(setting => {
      setting.id = setting.accountId;
    });

    return { data: response.data, total: response.total };
  },
);

export const doUpsertSecuritySettings = createAsyncThunk(
  'securitySettings/doUpsertSecuritySettings',
  async (
    input: UpdateSecuritySettings,
    { rejectWithValue },
  ) => {
    try {
      const securitySetting = await SecuritySettingService
        .upsertSecuritySettings(
          input,
        );
      return { data: securitySetting };
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export default securitySettingSlice.reducer;
