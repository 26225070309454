import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import { ProductInterest, ProductName } from '../models';
import { AppState } from '.';
import { ProductInterestService } from '../services';
import { Operator, Query } from '../models/query';

export const PRODUCT_INTEREST_FEATURE_KEY = 'productInterest';

const scriptingLabel = [
  ProductName.STUDIO,
  ProductName.STUDIO_ENTERPRISE,
  ProductName.RECORDER,
  ProductName.KATALIUM,
];
const executionLabel = [ProductName.RUNTIME_ENGINE];
const orchestrationLabel = [ProductName.TESTOPS];

// @ts-ignore // due to createdAt field from BE is number, note date, and date is in base model
const defaultProducts: ProductInterest[] = Object.keys(ProductName)
  // filter out namespace functions
  .filter((name: string) => typeof ProductName[name as keyof typeof ProductName] !== 'function')
  .map(name => ({
    id: 0,
    userId: 0,
    productName: ProductName.fromString(name),
    archived: false,
  }));

export const scriptingProducts = defaultProducts.filter(
  it => scriptingLabel.includes(it.productName),
);
export const executionProducts = defaultProducts.filter(
  it => executionLabel.includes(it.productName),
);
export const orchestrationProducts = defaultProducts.filter(
  it => orchestrationLabel.includes(it.productName),
);
type ItemSubmittingState = {
  [key: string]: boolean;
};
interface ProductInterestState extends EntityState<ProductInterest> {
  loading: boolean;
  error?: string;
  count: number;
  submitting: ItemSubmittingState;
}

const productInterestAdapter = createEntityAdapter<ProductInterest>({
  selectId: productInterest => productInterest.productName,
});

export const createInitialState = (): ProductInterestState => (
  productInterestAdapter.getInitialState({
    loading: true,
    count: 0,
    submitting: {},
  })
);
export const doGetByCurrentUserId = createAsyncThunk(
  'productInterest/getByCurrentUserId',
  async (input: Pick<ProductInterest, 'userId'>) => {
    const criteria: Array<Query<ProductInterest>> = [
      { field: 'archived', operator: Operator.EQ, value: false },
      { field: 'userId', operator: Operator.EQ, value: input.userId },
    ];
    const response = await ProductInterestService.getByCurrentUserId(...criteria);
    return {
      productInterests: response.data,
      count: response.total,
    };
  },
);

export const doAddProductInterest = createAsyncThunk(
  'productInterest/addProductInterest',
  async (input: Parameters<typeof ProductInterestService['addProductInterest']>[0]) => {
    const response = await ProductInterestService.addProductInterest(input);
    return { productInterest: response };
  },
);

export const doRemoveProductInterest = createAsyncThunk(
  'productInterest/removeProductInterest',
  async (input: Parameters<typeof ProductInterestService['removeProductInterest']>[0]) => {
    await ProductInterestService.removeProductInterest(input);
    return { id: input.productName };
  },
);

export const doSetSubmittingItem = createAction<ProductInterest['productName']>(
  'productInterest/setSubmittingItem',
);
export const doUnsetSubmittingItem = createAction<ProductInterest['productName']>(
  'productInterest/unsetSubmittingItem',
);

const productInterestSlice = createSlice({
  name: PRODUCT_INTEREST_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doGetByCurrentUserId.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetByCurrentUserId.fulfilled, (state, action) => {
      state.loading = false;
      state.count = action.payload.count;
      productInterestAdapter.setAll(state, action.payload.productInterests);
    });
    builder.addCase(doGetByCurrentUserId.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(doAddProductInterest.fulfilled, (state, action) => {
      productInterestAdapter.upsertOne(state, action.payload.productInterest);
    });
    builder.addCase(doAddProductInterest.rejected, (state, action) => {
      state.error = action.error.message;
    });
    builder.addCase(doRemoveProductInterest.fulfilled, (state, action) => {
      productInterestAdapter.removeOne(state, action.payload.id);
    });
    builder.addCase(doSetSubmittingItem, (state, action) => {
      state.submitting[action.payload] = true;
    });
    builder.addCase(doUnsetSubmittingItem, (state, action) => {
      state.submitting[action.payload] = false;
    });
    builder.addCase(doRemoveProductInterest.rejected, (state, action) => {
      state.error = action.error.message;
    });
  },
});

const selectProductInterestFeature = (state: AppState) => state[PRODUCT_INTEREST_FEATURE_KEY];
const { selectAll } = productInterestAdapter.getSelectors(selectProductInterestFeature);

export const selectLoading = createSelector(selectProductInterestFeature, state => state.loading);

export const selectProductInterests = createSelector(
  selectAll,
  productInterestState => productInterestState,
);

export const selectSubmitting = createSelector(
  selectProductInterestFeature,
  state => state.submitting,
);

export default productInterestSlice.reducer;
