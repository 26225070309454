import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Navigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import Button from '@mui/material/Button';
// import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { validate as uuidValidate } from 'uuid';

import LoadingProgress from '../../../layout/LoadingProgress';
// import LoadingProgress from '../../../layout/LoadingProgress';
import { useQuery } from '../../../routes';
import { UserService } from '../../../services';
import { ReactComponent as EyeSlashIcon } from './eye-slash.svg';
import { ReactComponent as EyeIcon } from './eye.svg';
import { ReactComponent as WarningIcon } from './icon_warning.svg';
import { ReactComponent as Logo } from './katalon_logo_only.svg';
import { ReactComponent as PasswordIcon } from './password.svg';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
  },
  card: {
    width: '500px',
    height: '500px',
    display: 'flex',
    borderRadius: theme.spacing(1.5),
    border: '1px solid #D6D6D6',
    alignItems: 'center',
    padding: theme.spacing(0, 5.75, 3.25, 5.75),
    marginTop: theme.spacing(12.5),
  },
  title: {
    fontSize: theme.spacing(3),
    fontWeight: theme.typography.fontWeightBold,
  },
  instruction: {
    textAlign: 'center',
    fontSize: theme.spacing(2),
  },
  button: {
    color: '#fff',
    backgroundColor: '#0F8461',
    height: theme.spacing(6),
    '&:hover': {
      color: '#fff',
      backgroundColor: '#0F8461',
    },
  },
  textField: {
    marginTop: theme.spacing(2),
    minWidth: 300,
  },
  error: {
    color: theme.palette.error.main,
    margin: theme.spacing(2),
    fontWeight: '400',
    fontSize: theme.spacing(1.75),
  },
  errorContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '.2rem',
  },
  errorIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(0.5),
  },
}));

interface ResetPasswordInput {
  password: string;
  confirmPassword: string;
}

const passwordPolicyPattern = /^(?=.*[a-zA-Z]?)(?=.*[@#$%^&+=!*\\/?`(){}[\]|;:'",.<>])(?=.*\d?).{8,255}$/;

const ResetPassword = () => {
  const classes = useStyles();
  const { queryString, clear } = useQuery();
  const { search } = useLocation();
  const [successfulReset, setSuccessfulReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('reset.default.message');
  const [shouldRendered, setShouldRendered] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  const RESET_CODE_PARAM = 'reset_password_code';

  const resetPasswordCode = new URLSearchParams(search).get(RESET_CODE_PARAM);

  useEffect(() => {
    if (resetPasswordCode && !uuidValidate(resetPasswordCode)) {
      enqueueSnackbar(<FormattedMessage id="reset.form.unvalid_link" />, {
        variant: 'error',
      });
    } else {
      setShouldRendered(true);
    }
  }, [resetPasswordCode, enqueueSnackbar]);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const handlePasswordInputChange = (event: any) => setPassword(event.target.value);
  const handleConfirmPasswordInputChange = (event: any) => setConfirmPassword(event.target.value);

  const handleResetPassword = () => {
    if (!passwordPolicyPattern.test(password)) {
      setErrorMessage('error.user.password.invalid');
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage('error.confirm_password_does_not_match');
    }

    doResetPassword({ password, confirmPassword });
  };

  const doResetPassword = async (input: ResetPasswordInput) => {
    if (resetPasswordCode) {
      setLoading(true);
      try {
        await UserService.resetPassword({
          password: input.password,
          resetPasswordCode,
        });
        analytics.track('User Changed Password', {
          event_text: 'User Changed Password',
        });
        setSuccessfulReset(true);
        enqueueSnackbar(<FormattedMessage id="reset.form.resetsuccess" />, {
          variant: 'success',
        });
      } catch (e: any) {
        const responseData = e.response && e.response.data && e.response.data.errors;
        if (!(responseData && responseData.includes('password_match_email'))) {
          enqueueSnackbar(<FormattedMessage id="reset.form.expired" />, {
            variant: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    }
  };

  if (successfulReset) {
    clear(RESET_CODE_PARAM);
    return (
      <Navigate
        to={{
          pathname: '/login',
          search: queryString(),
        }}
        replace
      />
    );
  }

  return (
    <>
      {shouldRendered && (
        <Paper className={classes.root}>
          <Paper className={classes.card} style={{ borderTop: '12px solid black' }}>
            <Stack spacing={2} alignItems="center">
              <Logo />
              <Typography className={classes.title}>
                {intl.formatMessage({ id: 'forgot.form.title' })}
              </Typography>
              <Typography className={classes.instruction}>
                {intl.formatMessage({ id: 'reset.form.instruction' })}
              </Typography>

              <TextField
                variant="outlined"
                fullWidth
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={handlePasswordInputChange}
                placeholder={intl.formatMessage({ id: 'reset.confirm_password.placeholder' })}
                autoFocus
                className={classes.textField}
                InputProps={{
                  sx: {
                    height: '46px',
                    fontSize: '14px',
                    border: '1px solid #D6D6D6',
                    borderRadius: '8px',
                    '&.Mui-focused': {
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #5959EB',
                      },
                    },
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <EyeIcon /> : <EyeSlashIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                variant="outlined"
                fullWidth
                type={showConfirmPassword ? 'text' : 'password'}
                className={classes.textField}
                value={confirmPassword}
                onChange={handleConfirmPasswordInputChange}
                placeholder={intl.formatMessage({ id: 'reset.confirm_password.placeholder' })}
                InputProps={{
                  sx: {
                    height: '46px',
                    fontSize: '14px',
                    border: '1px solid #D6D6D6',
                    borderRadius: '8px',
                    '&.Mui-focused': {
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #5959EB',
                      },
                    },
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <EyeIcon /> : <EyeSlashIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Stack direction="row" spacing={1} alignSelf="flex-start" alignItems="center">
                <WarningIcon className={classes.errorIcon} />
                {errorMessage && (
                  <Typography variant="caption" className={classes.error}>
                    {intl.formatMessage({ id: errorMessage })}
                  </Typography>
                )}
              </Stack>

              <Button
                className={classes.button}
                fullWidth
                variant="contained"
                onClick={handleResetPassword}
              >
                {intl.formatMessage({ id: 'reset.form.submit' })}
              </Button>
            </Stack>
          </Paper>
        </Paper>
      )}
      {loading && <LoadingProgress />}
    </>
  );
};

export default ResetPassword;
