import { createAsyncThunk, createSelector, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { AppState } from '.';
import { TestOpsUserSsoInvitation, TestOpsUserSsoInvitationStatus } from '../models';
import { Operator, Query } from '../models/query';
import { TestOpsUserSsoInvitationService } from '../services';

export const TESTOPS_USER_SSO_INVITAION_FEATURE_KEY = 'testOpsUserSsoInvitations';

interface TestOpsUserSsoInvitationState extends EntityState<TestOpsUserSsoInvitation> {
  loading: boolean;
}

const testOpsUserSsoInvitationAdapter = createEntityAdapter<TestOpsUserSsoInvitation>();
const MAX_NUMBER_OF_RECORDS = 50;

export const createInitialState = (): TestOpsUserSsoInvitationState => (
  testOpsUserSsoInvitationAdapter.getInitialState({
    loading: false,
  })
);

export const doGetByOrganizationId = createAsyncThunk(
  'testOpsUserSsoInvitations/getByOrganizatioId',
  async (input: Required<Pick<TestOpsUserSsoInvitation, 'organizationId'>>) => {
    const criteria: Query<TestOpsUserSsoInvitation>[] = [
      {
        field: 'organizationId',
        operator: Operator.EQ,
        value: input.organizationId,
      },
      {
        field: 'status',
        operator: Operator.NE,
        value: TestOpsUserSsoInvitationStatus.DECLINED,
      },
    ];

    const firstResponse = await TestOpsUserSsoInvitationService
      .getTestOpsUserSsoInvitations(...criteria);
    const numberOfRequests = Math.ceil(firstResponse.total / MAX_NUMBER_OF_RECORDS);

    const remainingRequests = [];
    for (let i = 1; i < numberOfRequests; i += 1) {
      const request = TestOpsUserSsoInvitationService.getTestOpsUserSsoInvitations(
        ...criteria,
        { offset: MAX_NUMBER_OF_RECORDS * i },
      );
      remainingRequests.push(request);
    }
    const remainingResponses = await Promise.all(remainingRequests);
    const testOpsUserSsoInvitation = [...firstResponse.data];
    remainingResponses.forEach(response => testOpsUserSsoInvitation.push(...response.data));

    return { data: testOpsUserSsoInvitation, total: firstResponse.total };
  },
);

const testOpsUserSsoInvitationSlice = createSlice({
  name: TESTOPS_USER_SSO_INVITAION_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doGetByOrganizationId.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetByOrganizationId.fulfilled, (state, action) => {
      state.loading = false;
      testOpsUserSsoInvitationAdapter.setAll(state, action.payload.data);
    });
    builder.addCase(doGetByOrganizationId.rejected, state => {
      state.loading = false;
    });
  },
});

const selectTestOpsUserSsoInvitationFeature = (
  state: AppState,
) => state[TESTOPS_USER_SSO_INVITAION_FEATURE_KEY];

export const {
  selectAll: selectAllTestOpsUserSsoInvitations,
} = testOpsUserSsoInvitationAdapter.getSelectors(selectTestOpsUserSsoInvitationFeature);

export const selectPendinghByOrganizationIdAndInvitedUserId = (
  organizationId: number,
  invitedUserId: number,
) => createSelector(
  selectAllTestOpsUserSsoInvitations,
  testOpsUserSsoInvitations => testOpsUserSsoInvitations.find(it => (
    it.organizationId === organizationId
      && it.invitedUserId === invitedUserId
      && it.status === TestOpsUserSsoInvitationStatus.PENDING
  )),
);

export default testOpsUserSsoInvitationSlice.reducer;
