import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { renderToString } from 'react-dom/server';
import { IntlShape } from 'react-intl';
import { chartTooltip } from '../../components/chart-tooltip/chartTooltip';

interface ConcurrentSessionChartTooltipProps {
  content: {
    label: string;
    value: string;
  }[];
}

export const concurrentSessionChartTooltip = (
  formattedDates: string[],
  concurrentSessions: number[],
  maxConcurrentSessions: number[],
  intl: IntlShape,
  theme: Theme,
) => chartTooltip(
  tooltipModel => {
    const dataIndex = +(tooltipModel.body[0]?.lines?.[0] ?? 0).toString();
    const dataSetIndex = +(tooltipModel.body[0]?.lines?.[1] ?? 0).toString();
    const yValue = dataSetIndex === 0
      ? concurrentSessions[dataIndex]
      : maxConcurrentSessions[dataIndex];
    const content = [
      {
        label: intl.formatMessage({ id: 'testcloud_dashboard.usage_summary.date' }),
        value: formattedDates[dataIndex],
      },
    ];
    if (concurrentSessions[dataIndex] === yValue) {
      content.push({
        label: intl.formatMessage({ id: 'testcloud_dashboard.usage_summary.concurrent_sessions' }),
        value: yValue.toString(),
      });
    }
    if (maxConcurrentSessions[dataIndex] === yValue) {
      content.push({
        label: intl.formatMessage({ id: 'testcloud_dashboard.usage_summary.max_concurrent_sessions' }),
        value: yValue.toString(),
      });
    }
    return renderToString(
      <ConcurrentSessionChartTooltip content={content} />,
    );
  },
  {
    adjustRightWhenCursorInRightHalf: 20,
    adjustLeftWhenCursorInLeftHalf: 20,
    adjustTopWhenCursorInTopHalf: -30,
    adjustTopWhenCursorInBottomHalf: -45,
  },
  theme,
);

const ConcurrentSessionChartTooltip = (props: ConcurrentSessionChartTooltipProps) => {
  const { content } = props;
  return (
    <Box fontSize={14} color="background.paper" mx={2} my={1}>
      {
        content.map(row => (
          <Box>
            <Box display="inline" mr={1}>{`${row.label}:`}</Box>
            <Box display="inline" fontWeight="bold">{row.value}</Box>
          </Box>
        ))
      }
    </Box>
  );
};
