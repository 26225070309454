import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as InfoIcon } from '../../../icons/info-icon.svg';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#ffffff',
    margin: theme.spacing(0, 2, 2),
    borderRadius: theme.spacing(0.5),
    padding: '16px 24px 16px 24px',
  },
  description: {
    fontWeight: 500,
    fontSize: 16,
    color: '#233145',
    margin: 0,
  },
  icon: {
    marginRight: 10,
    marginBottom: -3,
  },
}));

const NoActiveSubscriptionsSection = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <span className={classes.description}>
        <InfoIcon className={classes.icon} />
        <FormattedMessage id="subscriptions.testops_platform.no_subscriptions" />
      </span>
    </div>
  );
};

export default NoActiveSubscriptionsSection;
