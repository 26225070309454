import { OrganizationRole } from '../models';
import RouteWithPageTitle, { RouteWithPageTitleProps } from './RouteWithPageTitle';
import { useOrgUserAuthenticate } from './useOrgUserAuthenticate';

type ProtectedOrganizationRouteProps = RouteWithPageTitleProps & {
  accessibleOrgRoles?: OrganizationRole[];
  isRootOrAdminAccessible?: boolean;
};
const ProtectedOrganizationRoute: React.FC<ProtectedOrganizationRouteProps> = props => {
  const { accessibleOrgRoles, isRootOrAdminAccessible } = props;
  return useOrgUserAuthenticate(accessibleOrgRoles, isRootOrAdminAccessible)
    ? <RouteWithPageTitle {...props} />
    : <></>;
};

export default ProtectedOrganizationRoute;
