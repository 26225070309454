import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import orderBy from 'lodash/fp/orderBy';
import {
  exceptionOf,
  OrganizationTrialRequest,
  SerializedException,
  TestOpsSubscriptionSource,
} from '../models';
import { Operator, Query } from '../models/query';
import { SubscriptionStatus } from '../models/subscriptionStatus';
import { SubscriptionService } from '../services';
import { TestOpsSubscription } from '../models/testOpsSubscription';
import { AppState } from './index';
import { AdditionalResources } from './subscriptionsSlice';

export const TESTOPS_SUBSCRIPTION_KEY = 'testOpsSubscriptions';

interface TestOpsSubscriptionState extends EntityState<TestOpsSubscription> {
  loading: boolean;
  invoiceNumbers: string[];
  trialRequest: OrganizationTrialRequest | null | undefined;
  errors: SerializedException[];
}

const testOpsSubscriptionsAdapter = createEntityAdapter<TestOpsSubscription>();
const createInitialState = (partialState: Partial<TestOpsSubscriptionState> = {}) => (
  testOpsSubscriptionsAdapter.getInitialState({
    ...partialState,
    loading: false,
    errors: [] as SerializedException[],
  })
);

export const doGetActiveByAccountId = createAsyncThunk(
  'testOpsSubscriptions/getByAccountId',
  async (input: Required<Pick<TestOpsSubscription, 'accountId'>> & AdditionalResources, { rejectWithValue }) => {
    const criteria: Query<TestOpsSubscription>[] = [
      {
        field: 'accountId',
        operator: Operator.EQ,
        value: input.accountId,
      },
      {
        field: 'status',
        operator: Operator.EQ,
        value: SubscriptionStatus.ACTIVE,
      },
      {
        field: 'expiryDate',
        operator: Operator.GT,
        value: new Date(Date.now() - (7 * 24 * 60 * 60 * 1000)).getTime(),
      },
    ];
    try {
      return (await SubscriptionService.getTestOpsSubscriptions(...criteria));
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

const testOpsSubscriptionsSlice = createSlice({
  name: TESTOPS_SUBSCRIPTION_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doGetActiveByAccountId.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetActiveByAccountId.fulfilled, (state, action) => {
      state.loading = true;
      testOpsSubscriptionsAdapter.upsertMany(state, action.payload);
    });
    builder.addCase(doGetActiveByAccountId.rejected, (state, action) => {
      state.loading = true;
      state.errors.push(action.payload as SerializedException);
    });
  },
});

const selectTOSubscriptionFeature = (state: AppState) => state[TESTOPS_SUBSCRIPTION_KEY];
const { selectAll } = testOpsSubscriptionsAdapter.getSelectors(selectTOSubscriptionFeature);

export const selectLoading = createSelector(
  selectTOSubscriptionFeature,
  subscriptionState => subscriptionState.loading,
);

export const selectByAccountId = (id: TestOpsSubscription['accountId']) => createSelector(
  selectAll,
  testOpsSubscriptions => orderBy('createdAt', 'desc', testOpsSubscriptions).filter(
    it => it.accountId === id,
  ),
);

/**
 * Get active paid TestOps Subscription at level account
 * @param accountId
 * @returns Active paid TestOps Subscription
 */
export const selectActivePaidTestOpsSubscriptionByAccountId = (id: TestOpsSubscription['accountId']) => createSelector(
  selectAll,
  all => all.filter(
    it => it.accountId === id
    && it.source === TestOpsSubscriptionSource.RECURLY,
  ),
);

export default testOpsSubscriptionsSlice.reducer;
