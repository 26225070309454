import { createAsyncThunk, createSelector, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { AppState } from '.';
import { OrganizationUserSso, SsoOption, SsoOptionStatus } from '../models';
import { Operator, Query } from '../models/query';
import { OrganizationUserSsoService } from '../services';

export const ORGANIZATION_USER_SSO_FEATURE_KEY = 'organizationUserSsos';

interface OrganizationUserSsoState extends EntityState<OrganizationUserSso> {
  loading: boolean;
}

const organizationUserSsoAdapter = createEntityAdapter<OrganizationUserSso>();
const MAX_NUMBER_OF_RECORDS = 50;

export const createInitialState = (): OrganizationUserSsoState => (
  organizationUserSsoAdapter.getInitialState({
    loading: false,
  })
);

export const doGetByOrganizationId = createAsyncThunk(
  'organizationUserSsos/getByOrganizatioId',
  async (input: Required<Pick<OrganizationUserSso, 'organizationId'>>) => {
    const criteria: Query<OrganizationUserSso>[] = [
      {
        field: 'organizationId',
        operator: Operator.EQ,
        value: input.organizationId,
      },
      {
        field: 'status',
        operator: Operator.NE,
        value: SsoOptionStatus.DECLINED,
      },
    ];

    const firstResponse = await OrganizationUserSsoService.getOrganizationUserSsos(...criteria);
    const numberOfRequests = Math.ceil(firstResponse.total / MAX_NUMBER_OF_RECORDS);

    const remainingRequests = [];
    for (let i = 1; i < numberOfRequests; i += 1) {
      const request = OrganizationUserSsoService.getOrganizationUserSsos(
        ...criteria,
        { offset: MAX_NUMBER_OF_RECORDS * i },
      );
      remainingRequests.push(request);
    }
    const remainingResponses = await Promise.all(remainingRequests);
    const organizationUserSsos = [...firstResponse.data];
    remainingResponses.forEach(response => organizationUserSsos.push(...response.data));

    return { data: organizationUserSsos, total: firstResponse.total };
  },
);

export const doAddOption = createAsyncThunk(
  'organizationUserSsos/addOption',
  async (input: Required<Pick<OrganizationUserSso, 'organizationUserId' | 'option'>>) => {
    const data = await OrganizationUserSsoService.addOption(input);
    return data;
  },
);

export const doDeleteById = createAsyncThunk(
  'organizationUserSsos/deleteById',
  async (input: Required<Pick<OrganizationUserSso, 'id'>>) => {
    await OrganizationUserSsoService.deleteById({ id: input.id });
    return input;
  },
);

const organizayionUserSsoSlice = createSlice({
  name: ORGANIZATION_USER_SSO_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doGetByOrganizationId.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetByOrganizationId.fulfilled, (state, action) => {
      state.loading = false;
      organizationUserSsoAdapter.setAll(state, action.payload.data);
    });
    builder.addCase(doGetByOrganizationId.rejected, state => {
      state.loading = false;
    });
    builder.addCase(doAddOption.pending, state => {
      state.loading = true;
    });
    builder.addCase(doAddOption.fulfilled, (state, action) => {
      state.loading = false;
      organizationUserSsoAdapter.addOne(state, action.payload);
    });
    builder.addCase(doAddOption.rejected, state => {
      state.loading = false;
    });
    builder.addCase(doDeleteById.pending, state => {
      state.loading = true;
    });
    builder.addCase(doDeleteById.fulfilled, (state, action) => {
      state.loading = false;
      organizationUserSsoAdapter.removeOne(state, action.payload.id);
    });
    builder.addCase(doDeleteById.rejected, state => {
      state.loading = false;
    });
  },
});

const selectOrganizationUserSsoFeature = (
  state: AppState,
) => state[ORGANIZATION_USER_SSO_FEATURE_KEY];

export const {
  selectAll: selectAllOrganizationUserSsos,
} = organizationUserSsoAdapter.getSelectors(selectOrganizationUserSsoFeature);

export const selectOneSsoAuthByOrganizationUserId = (organizationUserId: number) => createSelector(
  selectAllOrganizationUserSsos,
  organizationUserSsos => organizationUserSsos.find(it => (
    it.organizationUserId === organizationUserId
      && it.option === SsoOption.SSO_AUTH
  )),
);

export const selectOneBasicAuthByOrganizationUserId = (
  organizationUserId: number,
) => createSelector(
  selectAllOrganizationUserSsos,
  organizationUserSsos => organizationUserSsos.find(it => (
    it.organizationUserId === organizationUserId
      && it.option === SsoOption.BASIC_AUTH
  )),
);

export const selectByOrganizationId = (organizationId: number) => createSelector(
  selectAllOrganizationUserSsos,
  organizationUserSsos => organizationUserSsos.filter(it => it.organizationId === organizationId),
);

export default organizayionUserSsoSlice.reducer;
