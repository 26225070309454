import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import {
  useEffect,
  useRef,
  useState,
} from 'react';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import PlatformOfferingSection from './PlatformOfferingSection';
import { fromStudioSubscriptions, fromTestCloudSubscriptions, fromTestOpsPlatformSubscriptions } from '../../../../store';
import { OrganizationFeature } from '../../../../models';
import {
  isDiscountCampaignEnabled,
  sendTrackingData,
} from '../../utils';
import { useLaunchDarkly } from '../../../../launchdarkly';
import { fromInvoices } from '../../../../store/rootReducer';

const useStyles = makeStyles((theme => ({
  rootContainer: {
    backgroundColor: '#ffffff',
    margin: theme.spacing(0, 2, 2),
    borderRadius: theme.spacing(0.5),
  },
  defaultContainer: {
    margin: theme.spacing(0, 2, 4),
    padding: theme.spacing(0, 2, 3, 2),
  },
  sectionTitleContainer: {
    padding: theme.spacing(1, 1.5, 0, 1.5),
  },
  offerContainer: {
    padding: theme.spacing(0),
    borderRadius: theme.spacing(0.5),
  },
  sectionTitle: {
    padding: theme.spacing(0, 2),
    fontSize: 20,
    fontWeight: 600,
    color: '#233145',
    alignItems: 'center',
    display: 'flex',
  },
})));

export interface SingleProductsOfferingSectionProps {
  accountId: number;
  orgId: number;
  initialPlatformQuota?: number;
  initialKseQuota?: number;
  initialKreQuota?: number;
  initialTestCloudQuota?: number;
  initialTestCloudDesktopQuota?: number;
  initialTestCloudCrossBrowserQuota?: number;
  initialTestCloudMobileQuota?: number;
  initialVisualTestingQuota?: number;
  clearInitialQuota: Function;
  isApplyStarterPackage:boolean;
}

const SingleProductsOfferingSection = (props: SingleProductsOfferingSectionProps) => {
  const {
    accountId,
    orgId,
    initialPlatformQuota,
    initialKseQuota,
    initialKreQuota,
    initialTestCloudQuota,
    initialTestCloudDesktopQuota,
    initialTestCloudCrossBrowserQuota,
    initialTestCloudMobileQuota,
    initialVisualTestingQuota,
    clearInitialQuota,
    isApplyStarterPackage,
  } = props;

  const { flags } = useLaunchDarkly();
  const classes = useStyles();
  const offerRef = useRef<HTMLSpanElement | null>(null);
  const [refVisible, setRefVisible] = useState(false);
  const [expanded, setExpanded] = useState(
    !isApplyStarterPackage || isDiscountCampaignEnabled(accountId, flags),
  );

  const hasPlatformSubscription = useSelector(
    fromTestOpsPlatformSubscriptions.selectPaidTestOpsPlatformSubscriptionByAccountId(
      Number(accountId),
    ),
  ).length !== 0;
  const hasKSESubscription = useSelector(
    fromStudioSubscriptions.selectByAccountIdAndFeature(
      Number(accountId),
      OrganizationFeature.PER_USER_KSE,
    ),
  ).length !== 0;
  const hasKRESubscription = useSelector(
    fromStudioSubscriptions.selectByAccountIdAndFeature(
      Number(accountId),
      OrganizationFeature.UNLIMITED_ENGINE,
    ),
  ).length !== 0;

  const hasTestCloudSubscriptions = useSelector(
    fromTestCloudSubscriptions.selectByAccountId(
      Number(accountId),
    ),
  ).length !== 0;

  const hasCurrentSubscription = hasPlatformSubscription
    || hasKSESubscription || hasKRESubscription || hasTestCloudSubscriptions;

  const passDueInvoices = useSelector(fromInvoices.selectPastDueInvoices);

  useEffect(() => {
    if (!refVisible) {
      return;
    }
    if (hasCurrentSubscription && passDueInvoices.length === 0 && offerRef.current !== null) {
      offerRef.current.scrollIntoView();
    }
  }, [refVisible]);

  const renderSingleProductOffering = () => (
    <Paper
      id="single-product-offering-container"
      elevation={0}
      className={classes.defaultContainer}
    >
      <Box className={classes.offerContainer}>
        <PlatformOfferingSection
          accountId={accountId}
          initialPlatformQuota={initialPlatformQuota}
          initialKseQuota={initialKseQuota}
          initialKreQuota={initialKreQuota}
          initialTestCloudQuota={initialTestCloudQuota}
          initialTestCloudDesktopQuota={initialTestCloudDesktopQuota}
          initialTestCloudCrossBrowserQuota={initialTestCloudCrossBrowserQuota}
          initialTestCloudMobileQuota={initialTestCloudMobileQuota}
          initialVisualTestingQuota={initialVisualTestingQuota}
          clearInitialQuota={clearInitialQuota}
        />
      </Box>
    </Paper>
  );

  const renderSingleProductOfferingTitle = () => (
    <Typography
      variant="h3"
      className={classes.sectionTitle}
      ref={el => {
        offerRef.current = el;
        setRefVisible(!!el);
      }}
    >
      <FormattedMessage id="subscriptions.testops_platform.single-product.offering" />
      {isApplyStarterPackage && (expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />)}
    </Typography>
  );

  const toggleSingleProductSection = () => {
    if (!expanded) {
      sendTrackingData(
        'single_product_opened',
        accountId,
        orgId,
      );
    }
    setExpanded(!expanded);
  };

  return (
    <div id="single-product-offering-section" className={classes.rootContainer}>
      {isApplyStarterPackage
        ? (
          <>
            <Button
              id="single-product-section-collapse-btn"
              variant="text"
              style={{ backgroundColor: 'transparent' }}
              onClick={toggleSingleProductSection}
            >
              {renderSingleProductOfferingTitle()}
            </Button>
            <Collapse in={expanded}>
              {renderSingleProductOffering()}
            </Collapse>
          </>
        )
        : (
          <>
            <div className={classes.sectionTitleContainer}>
              {renderSingleProductOfferingTitle()}
            </div>
            {renderSingleProductOffering()}
          </>
        )}
    </div>
  );
};

export default SingleProductsOfferingSection;
