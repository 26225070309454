import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { useSelector } from 'react-redux';
import { Dialog, DialogHeader, DialogBody, DialogFooter } from '@katalon-studio/katalon-ui/Dialog';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Autocomplete, {
  AutocompleteProps, createFilterOptions,
} from '@mui/material/Autocomplete';
import Popper, { PopperProps } from '@mui/material/Popper';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Button from '@katalon-studio/katalon-ui/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactComponent as CircleXmarkIcon } from '../icons/circle-xmark-icon.svg';
import NoResult from '../license-utilization/components/NoResult';
import { getAvatarSource, OrganizationUser } from '../../models';
import { Filter, Operator } from '../../models/query';
import { useQuery } from '../../routes/useQuery';
import { fromUserGroup, fromUserGroupUser } from '../../store/rootReducer';
import { useAppDispatch, fromOrganizationUsers } from '../../store';
import { useNotification } from '../../notification';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
  },
  dialog: {
    position: 'absolute',
    borderRadius: theme.spacing(0.75),
    minWidth: theme.spacing(96),
  },
  dlgHeader: {
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    width: '100%',
    fontSize: theme.spacing(2),
    fontWeight: 700,
    color: '#233145',
    display: 'flex',
    marginBottom: 0,
  },
  dlgTitle: {
    flexGrow: 2,
    fontWeight: 500,
    fontSize: theme.spacing(3),
  },
  dlgContent: {
    width: '100%',
  },
  boxSpacing: {
    height: theme.spacing(2),
  },
  defaultIcon: {
    fontSize: theme.spacing(2),
    color: '#598ef9',
  },
  textFieldTitle: {
    marginTop: theme.spacing(1),
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(1),
    '& p': {
      fontSize: theme.spacing(1.75),
      fontWeight: 500,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      height: theme.spacing(5),
    },
  },
  userFilter: {
    width: '100%',
    '& input': {
      minHeight: theme.spacing(4),
    },
    '& .MuiOutlinedInput-root': {
      maxHeight: theme.spacing(6),
    },
  },
  option: {
    paddingBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '&[aria-selected="true"]': {
      backgroundColor: '#F0F8FF !important',
    },
  },
  noOptions: {
    overflowX: 'hidden',
  },
  avatarContainer: {
    marginTop: theme.spacing(0.5),
    position: 'relative',
  },
  avatar: {
    marginRight: theme.spacing(1),
    width: theme.spacing(3),
    borderRadius: '50%',
    height: 'auto',
  },
  userInfo: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: theme.spacing(1.5),
  },
  textField: {
    '& .MuiInputBase-root': {
      backgroundColor: 'unset',
      fontSize: theme.spacing(1.75),
      borderRadius: theme.spacing(0.75),
    },
  },
  chipTextField: {
    height: `${theme.spacing(2)} !important`,
  },
  disableInput: {
    '& .MuiAutocomplete-input': {
      maxHeight: `${theme.spacing(0)}`,
    },
  },
  emailChip: {
    margin: theme.spacing(0.5, 0.5),
    maxWidth: 200,
    borderRadius: theme.spacing(2),
    backgroundColor: 'rgba(104, 109, 128, 0.08)',
    '& span': {
      fontSize: theme.spacing(1.75),
    },
    '& .MuiChip-deleteIcon': {
      display: 'flex',
      fontSize: `${theme.spacing(1.75)} !important`,
      width: theme.spacing(1.75),
      height: theme.spacing(1.75),
      color: 'rgba(104, 109, 128, 0.54) !important',
      marginLeft: theme.spacing(0.625),
      marginRight: theme.spacing(0.625),
    },
  },
  cancelButton: {
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
  },
  dlgActionButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 3, 3, 3),
    marginTop: theme.spacing(2.5),
  },
  buttonSpacing: {
    width: theme.spacing(1),
  },
  activeBtn: {
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
    marginLeft: '0 !important',
  },
  disableBtn: {
    color: 'rgba(104, 109, 128) !important',
    backgroundColor: 'rgba(104, 109, 128, 0.24) !important',
  },
}));

interface AddUserToGroupResult {
  successEmails: string[];
  failedEmails: string[];
  ignoredFailedUsers: string[];
}

async function getPromiseResults(promises: Promise<any>[], responseType: string)
  : Promise<AddUserToGroupResult> {
  const settledResults = await Promise.allSettled(promises);

  const successEmails: string[] = [];
  const failedEmails: string[] = [];
  const ignoredFailedUsers: string[] = [];

  settledResults.forEach(result => {
    const promiseResult = result as any;
    const addUsersValue = promiseResult.value;

    if (addUsersValue.type === `${responseType}/fulfilled`) {
      successEmails.push(addUsersValue.meta.arg.userEmail);
    } else {
      const { payload } = addUsersValue;
      if (payload.message === 'user.has.not.exist' && payload.type === 'BAD_REQUEST') {
        ignoredFailedUsers.push(addUsersValue.meta.arg.userEmail);
        return;
      }
      failedEmails.push(addUsersValue.meta.arg.userEmail);
    }
  });

  return { successEmails, failedEmails, ignoredFailedUsers };
}

interface AddUserToGroupPopupProps {
  isOpen: boolean;
  orgId: number;
  userGroupId: number;
  onCloseDialog: () => void;
  onSuccessCallback: () => void;
}

const AddUserToGroupPopup = (props: AddUserToGroupPopupProps) => {
  const {
    isOpen,
    orgId,
    userGroupId,
    onCloseDialog,
    onSuccessCallback,
  } = props;

  const classes = useStyles();
  const intl = useIntl();
  const { get } = useQuery();
  const dispatch = useAppDispatch();
  const { sendSuccess, sendError } = useNotification();
  const filterInputRef = useRef<HTMLElement>(null);
  const [focused, setFocused] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isHide, setIsHide] = useState(true);
  const [canSubmit, setCanSubmit] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<OrganizationUser[]>([]);
  const currentFilters = useRef<Filter<OrganizationUser>[]>(JSON.parse(get('filters') || '[]'));
  const currentSelectedUserRef = useRef<OrganizationUser[]>([]);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon color="primary" fontSize="small" />;
  const orgUsers = useSelector(fromOrganizationUsers.selectByOrganizationId(orgId));
  const userGroupUsers = useSelector(fromUserGroupUser.selectAll());
  const [listOrgUsers, setListOrgUsers] = useState<OrganizationUser[]>(orgUsers);
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    if (isEmpty(userGroupUsers) || isEmpty(orgUsers)) {
      return;
    }

    const listUserGroupEmails = userGroupUsers.map(userGroupUser => userGroupUser.user.email);
    const users = orgUsers.filter(
      orgUser => !listUserGroupEmails.includes(orgUser.user.email),
    );

    setListOrgUsers(users);
  }, [userGroupUsers]);

  const closeDialog = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onCloseDialog();
  };

  const handleCloseDialog = (_: any, reason: string) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    onCloseDialog();
  };

  const handleClickAway = (event: any) => {
    const e = (event.target as HTMLElement);
    if ((filterInputRef.current?.contains(event.target as HTMLElement))
      || e.tagName === 'LI'
      || e.tagName === 'UL'
      || e.tagName === 'P'
      || e.tagName === 'INPUT'
      || e.tagName === 'IMG'
      || (e.tagName === 'DIV'
        && (
          e.className.includes('avatarContainer')
          || e.className.includes('option')
        )
      )) {
      return;
    }
    setFocused(false);
  };

  const findFilter = (
    currentFilters: Filter<OrganizationUser>[],
    filterName: keyof OrganizationUser,
  ) => {
    const i = currentFilters.findIndex(
      it => it.field === filterName && it.operator === Operator.IN,
    );
    if (i === -1) return undefined;
    return currentFilters[i];
  };

  const defaultValueForUserFilter: OrganizationUser[] = findFilter(currentFilters.current, 'email')?.value
    ?.map((email: string) => listOrgUsers.find(orgUser => orgUser.user.email === email))
    ?.filter((user: OrganizationUser) => user !== undefined)
    ?? [];

  const customMethod = createFilterOptions<OrganizationUser>({
    trim: true,
    stringify: (option: OrganizationUser) => `${option.user.firstName} ${option.user.lastName} ${option.user.email}`,
  });

  const handleSubmit = async () => {
    const userGroupPromise: Promise<any>[] = [];
    setLoading(true);

    currentSelectedUserRef.current.forEach(orgUser => {
      userGroupPromise.push(
        dispatch(fromUserGroup.doCreateUserGroupUser({
          userEmail: orgUser.user.email,
          userGroupId,
        })),
      );
    });

    const addUsersResult = await getPromiseResults(userGroupPromise, 'userGroup/doCreateUserGroupUser');
    const success = addUsersResult.successEmails.length;
    const failed = addUsersResult.failedEmails.length;

    if (currentSelectedUserRef.current.length === success) {
      sendSuccess({
        message: intl.formatMessage({ id: 'user.group.add.users.dialog.create.successfully' }, { number: success }),
      }, 5000);

      onSuccessCallback();
      onCloseDialog();
      return;
    }

    const failedAddUsers = currentSelectedUserRef.current.filter(
      user => addUsersResult.failedEmails.includes(user.user.email)
        && !addUsersResult.ignoredFailedUsers.includes(user.user.email),
    );

    if (failed > 0 || success === 0) {
      sendError({
        message: failed > 0
          ? intl.formatMessage({ id: 'user.group.add.users.dialog.create.failed.try_again' }, { number: failed })
          : intl.formatMessage({ id: 'user.group.add.users.dialog.create.failed' }, { number: 1 }),
        hasDismissIcon: true,
        hasTryAgain: failedAddUsers.length > 0,
        handleTryAgain: () => {
          setSelectedUsers(failedAddUsers);
          setCanSubmit(failedAddUsers.length > 0);
          currentSelectedUserRef.current = failedAddUsers;
          filterChanged('email', failedAddUsers.map(orgUser => orgUser.user.email));
          setIsHidden(false);
        },
        handleDismissNotify: () => {
          onCloseDialog();
        },
      });
    }

    setCanSubmit(false);
    setLoading(false);
    if (failedAddUsers.length > 0) {
      setIsHidden(true);
    } else {
      setIsHidden(false);
      onCloseDialog();
    }
    onSuccessCallback();
  };

  const handleUserChange: AutocompleteProps<OrganizationUser, true, true, undefined>['onChange'] = (_, value) => {
    currentSelectedUserRef.current = value;
    filterChanged('email', value.map(orgUser => orgUser.user.email));
    setCanSubmit(value.length > 0);
  };

  const filterChanged = (
    filterName: keyof OrganizationUser,
    valueSet: any[],
  ) => {
    const index = currentFilters.current.findIndex(
      it => it.field === filterName && it.operator === Operator.IN,
    );
    const newFilters = index !== -1 ? [...currentFilters.current]
      : [...currentFilters.current, null];
    if (valueSet.length > 0) {
      newFilters[index !== -1 ? index : newFilters.length - 1] = {
        field: filterName,
        operator: Operator.IN,
        value: valueSet,
      };
    } else {
      if (index === -1) return;
      newFilters.splice(index, 1);
    }
    currentFilters.current = newFilters as any[];
  };

  const PopperComponent = (props: PopperProps) => (
    <Popper
      {...props}
      id={`poper-${defaultValueForUserFilter}`}
      placement="bottom-start"
      modifiers={[
        {
          name: 'preventOverflow',
          enabled: false,
          options: {
            altBoundary: true,
          },
        },
      ]}
    />
  );

  const handlePopupIndicatorClick = () => {
    setIsHide(!isHide);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseDialog}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
      hidden={isHidden}
    >
      <DialogHeader
        id="user.group.add.users.dialog.header"
        className={classes.dlgHeader}
        onClick={closeDialog}
      >
        <Box className={classes.dlgTitle}>
          <FormattedMessage id="user.group.add.users.dialog.title" />
        </Box>
      </DialogHeader>
      <DialogBody
        id="user.group.add.users.dialog.body"
        className={classes.dlgContent}
      >
        <Box className={classes.textFieldTitle}>
          <Typography id="user.group.add.users.dialog.input.users">
            <FormattedMessage id="user.group.add.users.dialog.input.users" />
          </Typography>
        </Box>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Autocomplete
            ref={filterInputRef}
            key={`${defaultValueForUserFilter}`}// adding this would reinitialized the component with new defaultValue
            filterOptions={customMethod}
            className={classes.userFilter}
            classes={{
              option: classes.option,
              noOptions: classes.noOptions,
            }}
            multiple
            limitTags={2}
            disabled={loading}
            size="small"
            id="user-filter"
            options={Array.from(new Set([...listOrgUsers]))}
            defaultValue={defaultValueForUserFilter}
            disableCloseOnSelect
            onFocus={() => setIsHide(false)}
            onBlur={() => setFocused(false)}
            disableClearable
            selectOnFocus
            onChange={handleUserChange}
            getOptionLabel={orgUser => orgUser.user.email}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            clearText={intl.formatMessage({ id: 'license_utilization.machine_filter.clear_all' })}
            noOptionsText={<NoResult messages={[intl.formatMessage({ id: 'license_utilization.machine_filter.no_results_found' })]} />}
            PopperComponent={PopperComponent}
            renderOption={(props, orgUser, { selected }) => (
              <li {...props} key={orgUser.user.email} data-user="data-user">
                <div className={classes.option}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={selected}
                  />
                  <div className={classes.avatarContainer}>
                    <img className={classes.avatar} src={getAvatarSource(orgUser.user.avatar)} alt="img avatar" />
                  </div>
                  <div className={classes.userInfo}>
                    <Typography variant="body2">
                      {orgUser.user.email}
                    </Typography>
                  </div>
                </div>
              </li>
            )}
            // disableInput
            renderInput={params => (
              <TextField
                {...params}
                maxRows={1}
                variant="outlined"
                onClick={() => setFocused(true)}
                className={clsx(classes.textField, classes.chipTextField, {
                  [classes.disableInput]: currentSelectedUserRef.current.length > 3,
                })}
                value={selectedUsers}
                autoFocus={focused}
                placeholder={
                  currentSelectedUserRef.current.length === 0
                    ? intl.formatMessage({ id: 'user.group.create.user.group.dialog.email.placeholder' })
                    : undefined
                }
              />
            )}
            renderTags={(tagValue, getTagProps) => map(tagValue, (option, index) => (
              <Chip
                {...getTagProps({ index })}
                label={option.user.email}
                className={clsx(classes.emailChip)}
                deleteIcon={(
                  <CircleXmarkIcon />
                )}
              />
            ))}
            componentsProps={{
              popper: {
                sx: {
                  zIndex: 1400,
                },
              },
              popupIndicator: {
                onClick: handlePopupIndicatorClick,
              },
            }}
            open={focused && !isHide}
            {...(!focused ? {} : { renderTags: () => null })}
          />
        </ClickAwayListener>
        <Box className={classes.boxSpacing} />
      </DialogBody>
      <DialogFooter
        id="user.group.add.users.dialog.footer"
        className={classes.dlgActionButtons}
      >
        <Button
          id="user.group.add.users.dialog.button.cancel"
          className={classes.cancelButton}
          type="button"
          variant="text"
          size="small"
          color="primary"
          onClick={closeDialog}
        >
          <FormattedMessage id="common.cancel" />
        </Button>
        <Box className={classes.buttonSpacing} />
        <Button
          id="user.group.add.users.dialog.button.add"
          className={clsx(classes.activeBtn, {
            [classes.disableBtn]: loading || !canSubmit,
          })}
          type="submit"
          form="edit-account-name-form"
          color="primary"
          variant="contained"
          size="small"
          onClick={handleSubmit}
          disabled={loading || !canSubmit}
        >
          <FormattedMessage id="user.group.add.users.dialog.button.add" />
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default AddUserToGroupPopup;
