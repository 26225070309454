import Card from '@mui/material/Card';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import memorize from 'lodash/fp/memoize';
import { licensesAllocation } from '../../../layout/routes';
import { resolvePath, useQuery } from '../../../routes';
import KseLicenseAllocation from './tab-child/kse/KseLicenseAllocation';
import { AccountUser } from '../../../models';
import KreLicenseAllocation from './tab-child/kre/KreLicenseAllocation';

const useStyles = makeStyles(theme => ({
  rootCard: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  tabContainer: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  tabItem: {
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
    padding: theme.spacing(1, 2),
    '&.Mui-selected': {
      color: '#1847ca',
    },
  },
  detailContainer: {

  },
}));

const tabs = ['kse', 'kre', 'testcloud', 'test-results'];

const getTabIndex = memorize((tabName: string | undefined) => (
  tabs.findIndex(it => it === tabName) >= 0 ? tabs.findIndex(it => it === tabName) : 0
));

interface LicensesAllocationDetailProps {
  currentAccountUser: AccountUser | undefined,
}

const LicensesAllocationDetail = (props: LicensesAllocationDetailProps) => {
  const { currentAccountUser } = props;

  const intl = useIntl();
  const classes = useStyles();

  const params = useParams();
  const currentTab = params.page;
  const query = useQuery();
  const accountId = query.get('accountId');

  const [selectedTabIndex, setSelectedTabIndex] = useState(getTabIndex(currentTab));

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  return (
    <Card className={classes.rootCard}>
      <Tabs
        value={selectedTabIndex}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons={false}
        textColor="inherit"
        className={classes.tabContainer}
      >
        <Tab
          className={classes.tabItem}
          id="licenses.allocation.tab.kse"
          label={intl.formatMessage({ id: 'licenses.allocation.tab.kse' })}
          component={Link}
          to={resolvePath(licensesAllocation, { page: 'kse' }, { accountId: Number(accountId) })}
        />
        <Tab
          className={classes.tabItem}
          id="licenses.allocation.tab.kre"
          label={intl.formatMessage({ id: 'licenses.allocation.tab.kre' })}
          component={Link}
          to={resolvePath(licensesAllocation, { page: 'kre' }, { accountId: Number(accountId) })}
        />
        <Tab
          className={classes.tabItem}
          id="licenses.allocation.tab.test_cloud"
          label={intl.formatMessage({ id: 'licenses.allocation.tab.test_cloud' })}
          component={Link}
          to={resolvePath(licensesAllocation, { page: 'testcloud' }, { accountId: Number(accountId) })}
        />
        <Tab
          className={classes.tabItem}
          id="licenses.allocation.tab.test_results"
          label={intl.formatMessage({ id: 'licenses.allocation.tab.test_results' })}
          component={Link}
          to={resolvePath(licensesAllocation, { page: 'test-results' }, { accountId: Number(accountId) })}
        />
      </Tabs>
      <Box className={classes.detailContainer}>
        {currentTab === 'kse' && (
          <KseLicenseAllocation currentAccountUser={currentAccountUser} />
        )}
        {currentTab === 'kre' && (
          <KreLicenseAllocation currentAccountUser={currentAccountUser} />
        )}
      </Box>
    </Card>
  );
};

export default LicensesAllocationDetail;
