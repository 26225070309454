import { createAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { AppState } from '.';
import {
  UserInvitation,
} from '../models';

export const USER_PENDING_INVITATION_TAB_KEY = 'userPendingInvitationTab';

export type UserPendingInvitationItemLoadingState = {
  [key: number]: boolean;
};

interface UserInvitationState {
  itemsLoadingState: UserPendingInvitationItemLoadingState,
}

export const createInitialState = (): UserInvitationState => ({
  itemsLoadingState: {},
});

export const doSetLoadingItem = createAction<UserInvitation['id']>('userPendingInvitationTab/setLoadingItem');
export const doUnsetLoadingItem = createAction<UserInvitation['id']>('userPendingInvitationTab/unsetLoadingItem');
export const doResetState = createAction('userInvitationTab/resetState');

const userPendingInvitationTabSlice = createSlice({
  name: USER_PENDING_INVITATION_TAB_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doSetLoadingItem, (state, action) => {
      state.itemsLoadingState[action.payload] = true;
    });
    builder.addCase(doUnsetLoadingItem, (state, action) => {
      state.itemsLoadingState[action.payload] = false;
    });
    builder.addCase(doResetState, state => {
      state.itemsLoadingState = {};
    });
  },
});

export const selectUserInvitationTabState = (
  state: AppState,
) => state[USER_PENDING_INVITATION_TAB_KEY];

export const selectLoadingStateById = (id: UserInvitation['id']) => createSelector(
  selectUserInvitationTabState,
  state => state.itemsLoadingState[id],
);

export const selectAllUserInvitationLoadingState = createSelector(
  selectUserInvitationTabState,
  state => state.itemsLoadingState,
);

export default userPendingInvitationTabSlice.reducer;
