import { useSelector } from 'react-redux';
import MicroApp from '../components/micro-frontend/MicroApp';
import { useConfig } from '../config';
import { useLaunchDarkly } from '../launchdarkly';
import { OrganizationMigrationStatus } from '../models';
import { resolvePath, sso, useQuery } from '../routes';
import { fromOrganizations } from '../store';
import {
  accountSetting,
  accountSubscriptionManagement,
  accountUserManagement as accountUserManagementPage,
  activateLicenseServer,
  dashboard,
  licensesAllocation,
  licenseUtilization,
  maintenanceProcess,
  organizationManagement,
  orgCardView,
  paymentMethod,
  projectManagement,
  securitySettings,
  supportManagement,
  testCloudDashboard,
  userGroup,
  userManagement,
} from './routes';

const SidebarOrgRemodeling = () => {
  const { flags } = useLaunchDarkly();
  const { config } = useConfig();

  let orgId = Number(useQuery().get('orgId'));
  const organization = useSelector(fromOrganizations.selectOrganizationById(orgId));
  const organizations = useSelector(fromOrganizations.selectAllOrganizations);

  let accountId = useQuery().get('accountId') ?? '';
  if (!accountId) {
    accountId = organization?.accountId?.toString() ?? '';
  }

  const orgMigrationStatus = useSelector(
    fromOrganizations.selectOrgMigrationStatusById(Number(orgId)),
  );

  if (config?.onpremise && !orgId) {
    orgId = organizations[0]?.id;
  }

  const resolveOrgHomePath = (orgId: number) => {
    let projectUri: string;
    const oldUI = resolvePath(sso, undefined, { redirect_uri: `${process.env.REACT_APP_ADMIN_URL}/organization/${orgId}/teams/projects` });
    const newUI = resolvePath(projectManagement, undefined, { orgId: `${orgId}` });
    const maintenanceUI = resolvePath(maintenanceProcess, undefined);
    if (orgMigrationStatus === OrganizationMigrationStatus.MIGRATED
      || !!config?.onpremise) {
      projectUri = newUI;
    } else if (flags?.projectStandardizeEnabled) {
      projectUri = maintenanceUI;
    } else if (flags?.projectManagementNewUIEnabled) {
      projectUri = newUI;
    } else {
      projectUri = oldUI;
    }
    return projectUri;
  };

  const goToProjects = (orgId: number) => {
    let projectUri: string;
    const oldUI = resolvePath(sso, undefined, { redirect_uri: `${process.env.REACT_APP_ADMIN_URL}/organization/${orgId}/teams/projects` });
    const newUI = resolvePath(projectManagement, undefined, { orgId });
    const maintenanceUI = resolvePath(maintenanceProcess, undefined);
    if (orgMigrationStatus === OrganizationMigrationStatus.MIGRATED
      || !!config?.onpremise) {
      projectUri = newUI;
    } else if (flags?.projectStandardizeEnabled) {
      projectUri = maintenanceUI;
    } else if (flags?.projectManagementNewUIEnabled) {
      projectUri = newUI;
    } else {
      projectUri = oldUI;
    }
    return projectUri;
  };

  const resolvedTeamsPath = (orgId: number) => {
    if (flags?.projectStandardizeEnabled) {
      return resolvePath(
        maintenanceProcess,
        undefined,
      );
    }
    return resolvePath(
      sso,
      undefined,
      { redirect_uri: `${process.env.REACT_APP_ADMIN_URL}/organization/${orgId}/teams/settings` },
    );
  };

  return (
    <MicroApp
      id="katalon-navigation-ui"
      nodeId="katalon-navigation-ui-sidebar"
      path={process.env.REACT_APP_NAVIGATION_MICROAPP_URL ?? ''}
      overrideConfig={{
        myBaseUrl: `${window.location.origin}${process.env.PUBLIC_URL}`,
        katOneBaseName: config?.onpremise ? process.env.PUBLIC_URL : undefined,
        customDomainPostfix: `${process.env.REACT_APP_CUSTOM_DOMAIN_POSTFIX}`,
        migrationStatus: orgMigrationStatus,
        navigationPaths: {
          home: resolvePath(orgCardView, undefined, { accountId }),
          organizationsManagement: resolvePath(orgCardView, undefined, { accountId }),
          subscriptionManagement: resolvePath(
            accountSubscriptionManagement,
            undefined,
            { accountId },
          ),
          paymentMethodManagement: resolvePath(paymentMethod, undefined, { accountId }),
          securityManagement: resolvePath(securitySettings, undefined, { accountId }),
          accountSetting: resolvePath(accountSetting, undefined, { accountId }),
          licenseAllocation: resolvePath(licensesAllocation, { page: 'kse' }, { accountId }),
          accountUserManagement: resolvePath(accountUserManagementPage, { tab: 'active' }, { accountId }),
          projectManagement: goToProjects,
          userManagement: (orgId: number) => resolvePath(userManagement, undefined, { orgId }),
          licenseManagement: (orgId: number) => resolvePath(sso, undefined, { redirect_uri: `${process.env.REACT_APP_ADMIN_URL}/organization/${orgId}/admin/license_keys/kse` }),
          kseDashboard: (orgId: number) => resolvePath(licenseUtilization, undefined, { orgId }),
          platformDashboard: (orgId: number) => resolvePath(dashboard, undefined, { orgId }),
          testcloudDashboard: (orgId: number) => resolvePath(
            testCloudDashboard,
            undefined,
            { orgId },
          ),
          supportManagement: (orgId: number) => resolvePath(
            supportManagement,
            undefined,
            { orgId },
          ),
          teamsManagement: resolvedTeamsPath,
          organizationSetting: (orgId: number) => resolvePath(
            organizationManagement,
            undefined,
            { orgId, accountId },
          ),
          userGroup: (orgId: number) => resolvePath(userGroup, undefined, { orgId }),
          activateLicenseServer: resolvePath(activateLicenseServer, undefined, { accountId }),
          emailConfiguration: resolvePath(sso, undefined, { redirect_uri: `${process.env.REACT_APP_ADMIN_URL}/email-server-configuration?accountId=${accountId}` }),
        },
        resolveOrgHomePath,
        navigationType: 'SIDEBAR',
        nodeId: 'katalon-navigation-ui-sidebar',
      }}
    />
  );
};

export default SidebarOrgRemodeling;
