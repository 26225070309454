import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { useConfig } from '../../config';
import { DiscourseSsoParam } from '../../models/discourseSsoParam';
import { SsoService } from '../../services';
import { fromAuth, fromOrganizations } from '../../store';

// not a valid SSO payload, redirect to root page
// or an error page?
const InvalidSsoComponent = () => <Navigate to="/" replace />;

const DiscourseSsoComponent = (sso: string, sig: string) => {
  const [data, updateData] = useState<DiscourseSsoParam>();
  useEffect(() => {
    const getData = async () => {
      const resp = await SsoService.sso({ sso, sig });
      updateData(resp);
    };
    getData();
  }, [sso, sig]);

  // discourse forum
  if (data) {
    const { sso } = data;
    const { sig } = data;
    const discourseParams = new URLSearchParams({ sso, sig }).toString();
    window.location.replace(`${data.returnSsoUrl}?${discourseParams}`);
  }

  return <></>;
};

const SsoComponent = () => {
  const { search } = useLocation();
  const { config } = useConfig();
  const token = useSelector(fromAuth.selectToken);

  const ssoparams = new URLSearchParams(search);
  const sso = ssoparams.get('sso');
  const sig = ssoparams.get('sig');

  const currentOrganization = useSelector(fromOrganizations.selectSelectedOrganization);

  const getSubdomainLink = (domain: string | undefined) => `https://${domain}.${process.env.REACT_APP_CUSTOM_DOMAIN_POSTFIX}`;

  // discourse forum
  if (sso && sig) return DiscourseSsoComponent(sso, sig);

  if (!config) return <></>;

  // general SSO
  const redirectUri = new URLSearchParams(search).get('redirect_uri');
  if (redirectUri) {
    const convertedRedirectUri = redirectUri.startsWith('//')
      ? `${window.location.origin}${redirectUri.substr(2, redirectUri.length)}`
      : redirectUri;

    const uri = new URL(convertedRedirectUri);
    const canRedirect = config.whitelistedDomains.split(',').find(
      whitelistedDomain => uri.host === whitelistedDomain.trim() || uri.host.endsWith(`.${whitelistedDomain.trim()}`),
    );
    // if on-premise, ignore whitelisedDomain
    if (config.onpremise || canRedirect) {
      // hard-code support for zendesk login through website
      if (redirectUri.includes('support.katalon.com')) {
        window.location.replace(`https://www.katalon.com/help-center-redirect/?${new URLSearchParams({ access_token: token!.jwt })}`);
        return <></>;
      }

      const isMatchUri = uri.origin === window.location.origin
        || uri.href.startsWith(config.testOpsPublicUrl)
        || uri.href.startsWith(getSubdomainLink(currentOrganization?.domain))
        || uri.href.startsWith(process.env.REACT_APP_ADMIN_URL!!);

      // TestOps using katone_access_token, other sites using access_token
      if (isMatchUri && config?.iamEnabled) {
        uri.searchParams.append('new_login', 'true');
      } else if (isMatchUri) {
        uri.searchParams.append('katone_access_token', token!.jwt);
      } else {
        uri.searchParams.append('access_token', token!.jwt);
      }
      window.location.replace(uri.toString());

      return <></>;
    }
  }

  return <InvalidSsoComponent />;
};

export default SsoComponent;
