import Avatar from '@mui/material/Avatar';
import Checkbox from '@mui/material/Checkbox';
import ListSubheader from '@mui/material/ListSubheader';
import Paper from '@mui/material/Paper';
import Popper, { PopperProps } from '@mui/material/Popper';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Autocomplete, { AutocompleteProps, AutocompleteRenderGroupParams, createFilterOptions } from '@mui/material/Autocomplete';
import { useRef, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { getAvatarSource, getFullNameUser, User } from '../../../../models';
import NoResult from '../NoResult';

const useStyles = makeStyles(theme => ({
  userFilter: {
    width: '100%',
  },
  avatar: {
    marginRight: theme.spacing(1),
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
  },
  option: {
    paddingBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '&[aria-selected="true"]': {
      backgroundColor: '#F0F8FF !important',
    },
  },
  userInfo: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: theme.spacing(1.5),
  },
  avatarContainer: {
    position: 'relative',
  },
  statusIndicator: {
    position: 'absolute',
    content: '',
    width: theme.spacing(2),
    height: theme.spacing(2),
    top: '55%',
    left: '45%',
    borderRadius: '50%',
    borderWidth: theme.spacing(0.25),
    borderStyle: 'solid',
    borderColor: theme.palette.background.paper,
  },
  indicatorInfo: {
    display: 'flex',
    padding: theme.spacing(1, 2),
  },
  activeIndicator: {
    fill: '#00D63C',
    width: theme.spacing(2.25),
  },
  removedIndicator: {
    fill: '#808B9A',
    width: theme.spacing(2.25),
  },
  indicatorContainer: {
    display: 'flex',
    minWidth: theme.spacing(4),
    alignItems: 'center',
    '&:last-child': {
      marginLeft: theme.spacing(2),
    },
  },
  legend: {
    fontSize: theme.spacing(1.75),
  },
  indicatorTag: {
    border: '1px solid #D5D8DD',
    borderRadius: '4px',
  },
  noOptions: {
    overflowX: 'hidden',
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon color="primary" fontSize="small" />;

const PopperComponent = (props: PopperProps) => (
  <Popper
    {...props}
    placement="bottom-start"
    modifiers={[
      {
        name: 'preventOverflow',
        enabled: false,
        options: {
          altBoundary: true,
        },
      },
    ]}
  />
);

interface UserFilterProps {
  onChange: (value: User[]) => void;
  defaultValue: User[];
  options: User[];
  disabled: boolean;
}

const renderGroup = (params: AutocompleteRenderGroupParams) => [
  <ListSubheader key={params.key} component="div" disableSticky>
    {params.group}
  </ListSubheader>,
  params.children,
];

const UserFilter = (props: UserFilterProps) => {
  const { onChange, defaultValue, options, disabled } = props;
  const classes = useStyles();
  const intl = useIntl();
  const customMethod = createFilterOptions<User>({
    trim: true,
    stringify: (option: User) => `${option.firstName} ${option.lastName} ${option.email}`,
  });
  /*
    For some reason, the focused state is not updated after the page loads,
    so another ref is used to keep track of this by comparing itself with the
    current active (focused) element
  */
  const filterInputRef = useRef<HTMLDivElement>(null);
  const [focused, setFocused] = useState(false);
  const handleUserChange: AutocompleteProps<User, true, true, undefined>['onChange'] = (_, value) => {
    onChange(value);
  };

  useEffect(() => {
    if (document.activeElement !== filterInputRef.current) {
      setFocused(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.activeElement, filterInputRef]);

  return (
    <Autocomplete
      key={`${defaultValue}`}// adding this would reinitialized the component with new defaultValue
      filterOptions={customMethod}
      className={classes.userFilter}
      classes={{
        option: classes.option,
        noOptions: classes.noOptions,
      }}
      multiple
      limitTags={1}
      disabled={disabled}
      size="small"
      id="user-filter"
      options={Array.from(new Set([...defaultValue, ...options]))}
      defaultValue={defaultValue}
      disableCloseOnSelect
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onChange={handleUserChange}
      getOptionLabel={user => user.email}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      clearText={intl.formatMessage({ id: 'license_utilization.machine_filter.clear_all' })}
      noOptionsText={<NoResult messages={[intl.formatMessage({ id: 'license_utilization.machine_filter.no_results_found' })]} />}
      PopperComponent={PopperComponent}
      groupBy={option => (
        defaultValue.find(it => it.email === option.email)
          ? intl.formatMessage({ id: 'license_utilization.filter.selected' }, { count: defaultValue.length }).toUpperCase()
          : !option.isRemoved
            ? intl.formatMessage({ id: 'license_utilization.filter.active' }).toUpperCase()
            : intl.formatMessage({ id: 'license_utilization.filter.removed' }).toUpperCase()
      )}
      renderGroup={renderGroup}
      renderOption={(props, user, { selected }) => (
        <li {...props}>
          <div className={classes.option}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
            />
            <div className={classes.avatarContainer}>
              <Avatar className={classes.avatar} src={getAvatarSource(user.avatar)} />
              <div className={classes.statusIndicator} style={{ backgroundColor: user.isRemoved ? '#808B9A' : '#00D63C' }} />
            </div>
            <div className={classes.userInfo}>
              <Typography variant="body2">
                {getFullNameUser(user.firstName, user.lastName, user.email)}
              </Typography>
              <Typography variant="body2">
                {user.email}
              </Typography>
            </div>
          </div>
        </li>
      )}
      renderInput={params => (
        <TextField
          {...params}
          inputRef={filterInputRef}
          variant="outlined"
          label={intl.formatMessage({ id: 'license_utilization.filter.user.label' })}
          placeholder={
            defaultValue.length === 0
              ? intl.formatMessage({ id: 'license_utilization.filter.email.placeholder' })
              : undefined
          }
        />
      )}
      PaperComponent={({ children }) => (
        <Paper className={classes.indicatorTag}>
          {children}
          <div className={classes.indicatorInfo}>
            <div className={classes.indicatorContainer}>
              <Typography variant="subtitle1" className={classes.legend}>
                {`${intl.formatMessage({ id: 'license_utilization.filter.active' }).toUpperCase()}:`}
              </Typography>
              <FiberManualRecordIcon className={classes.activeIndicator} />
            </div>
            <div className={classes.indicatorContainer}>
              <Typography variant="subtitle1" className={classes.legend}>
                {`${intl.formatMessage({ id: 'license_utilization.filter.removed' }).toUpperCase()}:`}
              </Typography>
              <FiberManualRecordIcon className={classes.removedIndicator} />
            </div>
          </div>
        </Paper>
      )}
      open={focused}
      {...(!focused ? {} : { renderTags: () => null })}
    />
  );
};

export default UserFilter;
