import { initialize, LDClient, LDFlagSet } from 'launchdarkly-js-client-sdk';
import { ComponentType, createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { SubscriptionPlanPrice } from '../models/subscriptionPlanPrice';
import { DirectEcomTransition } from '../models/directEcomTransition';

export interface LaunchDarklyProps {
  client?: LDClient;
  initialized?: boolean;
  flags?: LaunchDarklyFlag;
  currentOrganizationId?: number;
  currentAccountId?: number;
  changeAccount?: (accountId: number) => Promise<void>;
}

export interface LaunchDarklyFlag extends Omit<LDFlagSet, keyof LDFlagSet> {
  subscriptionAndPaymentMethodDisabled?: boolean;
  newSecuritySettingFlowEnabled?: boolean;
  // add new flag's key here
  limitUserEnabled?: boolean;
  unlimitedVTPEnabled?: boolean;
  bulkActionOfLicenseManagementEnable?: boolean;
  setPasswordEnable?: boolean;
  projectStandardizeEnabled?: boolean;
  bankTransferEnabled?: boolean;
  enhanceUserSettingEnable?: boolean;
  restrictWireTransferEnabled?: boolean;
  monthlySubsYearEndCampaignConfig?: any;
  organizationMigrationStatusEnabled?: boolean;
  projectManagementNewUIEnabled?: boolean;
  invitedByEnabled?: boolean;
  bankTransferWhiteList?: any;
  licenseAllocationEnabled?: boolean;
  sidebarReleaseNewFeatureConfig?: any;
  testOpsUIEnabled?: boolean;
  accountUserManagementEnabled: boolean;
  newTestCloudSKUsEnabled: boolean;
  truetestUIEnabled?: boolean;
  subscriptionPlanPrice: SubscriptionPlanPrice;
  accountSecuritySettings?: boolean;
  directEcomTransition?: DirectEcomTransition;
  EUBankTransferWhiteList?: Array<number>;
  subscriptionRetryPayment?: boolean;
}

const defaultValue: LaunchDarklyFlag = {
  subscriptionAndPaymentMethodDisabled: false,
  newSecuritySettingFlowEnabled: false,
  unlimitedVTPEnabled: false,
  bulkActionOfLicenseManagementEnable: false,
  setPasswordEnable: false,
  projectStandardizeEnabled: false,
  bankTransferEnabled: false,
  enhanceUserSettingEnable: false,
  restrictWireTransferEnabled: false,
  monthlySubsYearEndCampaignConfig: undefined,
  organizationMigrationStatusEnabled: false,
  projectManagementNewUIEnabled: false,
  invitedByEnabled: false,
  bankTransferWhiteList: undefined,
  licenseAllocationEnabled: false,
  sidebarReleaseNewFeatureConfig: {},
  testOpsUIEnabled: false,
  accountUserManagementEnabled: false,
  newTestCloudSKUsEnabled: false,
  truetestUIEnabled: false,
  subscriptionPlanPrice: {},
  accountSecuritySettings: false,
  directEcomTransition: undefined,
  EUBankTransferWhiteList: [],
  subscriptionRetryPayment: false,
};

export const LaunchDarklyContext = createContext<LaunchDarklyProps>({});

export const LaunchDarklyProvider = ({ children }: { children: ReactNode }) => {
  const [flags, setFlags] = useState<LaunchDarklyProps['flags']>(defaultValue);
  const [client, setClient] = useState<LaunchDarklyProps['client']>();
  const [currentAccountId, setCurrentAccountId] = useState<LaunchDarklyProps['currentAccountId']>();
  const [initialized, setInitialized] = useState<LaunchDarklyProps['initialized']>(false);

  const initializeClient = async () => {
    if (!process.env.REACT_APP_LD_KEY || !process.env.REACT_APP_LD_USER) {
      setInitialized(true);
      return Promise.resolve('Missing LaunchDarkly Configuration');
    }
    const ldUser = {
      key: process.env.REACT_APP_LD_USER,
    };
    const ldClient = initialize(process.env.REACT_APP_LD_KEY!!, ldUser);
    setClient(ldClient);
    return ldClient.waitForInitialization()
      .then(() => {
        setInitialized(true);
      });
  };

  const changeAccount = async (accountId: number) => {
    if (!initialized || !client || currentAccountId === accountId) {
      return;
    }
    setCurrentAccountId(accountId);
    if (accountId) {
      const key = `account-${process.env.REACT_APP_LD_PREFIX}-${accountId}`;
      const accountFlags = await client.identify({ key }) as LaunchDarklyFlag;
      // eslint-disable-next-line max-len
      setFlags(accountFlags);
    }
  };

  const getAllFlags = () => {
    if (initialized && client) {
      const data = client.allFlags();
      setFlags(data as LaunchDarklyFlag);
      return data;
    }
    return {};
  };

  useEffect(() => {
    getAllFlags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized]);

  useEffect(() => {
    initializeClient();
    return function cleanup() {
      if (client) {
        client.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LaunchDarklyContext.Provider
      value={{
        client,
        flags,
        initialized,
        currentAccountId,
        changeAccount,
      }}
    >
      {children}
    </LaunchDarklyContext.Provider>
  );
};

export const withLaunchDarkly:
  <T extends LaunchDarklyProps>(Component: ComponentType<T>) =>
ComponentType<Pick<T, Exclude<keyof T, keyof LaunchDarklyProps>>> = (Component: any) => (
  props => <Component {...props} {...useContext(LaunchDarklyContext)} />
);

export const useLaunchDarkly = () => useContext(LaunchDarklyContext);
