import Axios from 'axios';
import {
  DataResponse,
  Organization,
  PageableResponse,
  StatisticData,
  TestOpsProject,
  TestOpsProjectView,
  TestOpsRecentProject,
  TestOpsRecentProjectView,
} from '../models';
import { composeQuery, composeStatisticQuery, Query, StatisticQuery } from '../models/query';

export const getTestOpsProjectStatistics = async (
  ...criteria: StatisticQuery<TestOpsProjectView>[]
) => (
  await Axios.get<PageableResponse<StatisticData>>('/v1/projects/statistics', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    params: composeStatisticQuery<TestOpsProjectView>(...criteria),
    data: {},
  })
).data;

export const getTestOpsProjects = async (...criteria: Query<TestOpsProjectView>[]) => (
  await Axios.get<PageableResponse<TestOpsProject>>('/v1/projects', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    params: composeQuery<TestOpsProjectView>(...criteria),
    data: {},
  })
).data;

export const getTestOpsRecentProjects = async (...criteria: Query<TestOpsRecentProjectView>[]) => (
  await Axios.get<PageableResponse<TestOpsRecentProject>>('/v1/recent-projects', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    params: composeQuery<TestOpsRecentProjectView>(...criteria),
    data: {},
  })
).data;

export const createProject = async (
  input: Required<Pick<TestOpsProject, 'teamId' | 'name' >>
  | (Required<Pick<TestOpsProject, 'name' >> & { organizationId: Organization['id'] }),
) => (await Axios.post<DataResponse<TestOpsProject>>('/v1/projects', input)).data.data;
