import { useSelector } from 'react-redux';

import { fromAuth } from '../../store';
import RootSetting from './RootSetting';

const Setting = () => {
  const isROOT = useSelector(fromAuth.selectIsROOT);

  // Render the setting screen corresponding to the user's role
  // For now, there is only setting screen for ROOT
  return (isROOT ? <RootSetting /> : null);
};

export default Setting;
