import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import DialogTransition from '../../../components/transition/DialogTransition';
import { SubscriptionWarningIcon } from '../../../layout/CustomIcon';
import LoadingProgress from '../../../layout/LoadingProgress';
import { OrganizationFeature, RecurlySubscriptionPayloadStatus, TestCloudSubscription } from '../../../models';
import { fromTestCloudSubscriptions, useAppDispatch } from '../../../store';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.spacing(2),
  },
  title: {
    color: theme.palette.warning.main,
    padding: theme.spacing(0, 3),
    fontWeight: theme.typography.fontWeightBold,
  },
  dialogContainer: {
    margin: theme.spacing(3),
    maxWidth: theme.spacing(63),
  },
  currentPlan: {
    fontWeight: theme.typography.fontWeightBold,
  },
  dialogContent: {
    lineHeight: theme.spacing(3),
    margin: theme.spacing(1, 0),
  },
  dialogContentContainer: {
    padding: theme.spacing(1, 3, 2, 3),
  },
  content: {
    color: '#172B4D',
    fontSize: '0.875rem',
  },
  getBackButton: {
    color: '#233145',
    height: theme.spacing(4),
    fontWeight: theme.typography.fontWeightMedium,
  },
  cancelButton: {
    color: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    marginLeft: theme.spacing(2),
    height: theme.spacing(3),
    fontWeight: theme.typography.fontWeightMedium,
  },
  confirmButton: {
    height: theme.spacing(4),
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const CancelSubscriptionButton = (props: { subscription: TestCloudSubscription }) => {
  const { subscription } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);
      await dispatch(fromTestCloudSubscriptions.doCancelSubscription({ id: subscription.id }))
        .then(unwrapResult)
        .then(() => {
          // TODO: change message when product team update message on ticket
          enqueueSnackbar(
            <FormattedMessage
              id="subscriptions.testcloud.cancel_subscription.success"
              values={{
                planName: OrganizationFeature.getFeatureFullName(subscription.feature),
              }}
            />,
            { variant: 'success' },
          );
          // reload to get new status of recurly subscription & cancelledAt
          setTimeout(() => window.location.reload(), 200);
        });
    } catch (e) {
      // TODO: show speific error if needed
      enqueueSnackbar(
        <FormattedMessage id="subscriptions.testcloud.cancel_subscription.failed" />,
        { variant: 'error' },
      );
      setLoading(false);
      setOpen(false);
    }
  };

  if (subscription.recurlySubscription?.status !== RecurlySubscriptionPayloadStatus.ACTIVE) {
    return null;
  }

  return (
    <>
      <Button
        variant="text"
        className={classes.cancelButton}
        onClick={() => setOpen(true)}
      >
        <FormattedMessage id="subscriptions.testcloud.cancel_dialog.cancel" />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{
          paper: classes.root,
        }}
        TransitionComponent={DialogTransition}
      >
        <div
          className={classes.dialogContainer}
        >
          <Grid
            container
            justifyContent="center"
          >
            <SubscriptionWarningIcon />
          </Grid>
          <Typography
            className={classes.title}
            variant="h3"
          >
            <FormattedMessage
              id="subscriptions.testcloud.cancel_dialog.title"
            />
          </Typography>
          <DialogContent
            className={classes.dialogContentContainer}
          >
            <DialogContentText id="alert-dialog-description">
              <Typography className={classes.content}>
                <FormattedMessage
                  id="subscriptions.testcloud.cancel_dialog.description"
                />
              </Typography>
              <Typography className={classes.content}>
                <FormattedMessage
                  id="subscriptions.testcloud.cancel_dialog.are_you_sure"
                  values={{
                    planName: OrganizationFeature.getFeatureFullName(subscription.feature),
                    b: (chunk: string) => (<b>{chunk}</b>),
                  }}
                />
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant="contained"
              className={classes.getBackButton}
            >
              <FormattedMessage id="subscriptions.testcloud.cancel_dialog.get_back" />
            </Button>
            <Button
              onClick={handleConfirm}
              variant="contained"
              className={classes.confirmButton}
              color="secondary"
            >
              <FormattedMessage id="subscriptions.testcloud.cancel_dialog.cancel_subscription" />
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      {loading && <LoadingProgress />}
    </>
  );
};

export default CancelSubscriptionButton;
