import Axios from 'axios';

import { KsSession, PageableResponse, MetricData } from '../models';
import { composeMetricQuery, MetricQuery } from '../models/query';

export const getLicenseUsage = async (...criteria: MetricQuery<KsSession>[]) => (await Axios.get<PageableResponse<MetricData>>('/v1/dashboard/license-usage', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: composeMetricQuery(...criteria),
  data: {},
})).data.data;
