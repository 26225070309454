import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import { TestOpsPlatformTierType } from '../models/testOpsPlatformTierType';
import { AppState } from '.';
import {
  exceptionOf,
  SingleProductOrder,
  RecurlySubscription,
  Subscription,
  OrganizationFeature,
  OrganizationTrialRequest,
  SerializedException,
  Account,
  OrderResponse,
  TestOpsSubscriptionSource,
  SubscriptionView,
} from '../models';
import { Operator, Query } from '../models/query';
import { SubscriptionStatus } from '../models/subscriptionStatus';
import { SubscriptionService } from '../services';

export const SUBSCRIPTION_FEATURE_KEY = 'subscriptions';
export const RECURLY_SUBSCRIPTION_INVALID = 'recurly.subscription.invalid';
export const REACTIVATE_RECURLY_SUBSCRIPTION_INVALID = 'reactivate.recurly.subscription.invalid';
export interface AdditionalResources {
  checkHasPremierSuccess?: boolean;
}

interface SubscriptionState extends EntityState<Subscription> {
  loading: boolean;
  isApplyStarterPackage: boolean;
  starterPackageSubscriptions: RecurlySubscription[],
  invoiceNumbers: string[];
  trialRequest: OrganizationTrialRequest | null | undefined;
  orderResponse: OrderResponse;
  errors: SerializedException[];
}

const subscriptionsAdapter = createEntityAdapter<Subscription>();
const createInitialState = (partialState: Partial<SubscriptionState> = {}) => (
  subscriptionsAdapter.getInitialState({
    ...partialState,
    loading: false,
    isApplyStarterPackage: false,
    errors: [] as SerializedException[],
  })
);

const MAX_NUMBER_OF_RECORDS = 50;

export const doGetActiveSubscriptionsByOrgId = createAsyncThunk(
  'subscriptions/getByOrgId',
  async (input: Required<Pick<Subscription, 'organizationId'>>, { rejectWithValue }) => {
    const criteria: Query<Subscription>[] = [
      {
        field: 'organizationId',
        operator: Operator.EQ,
        value: input.organizationId,
      },
      {
        field: 'status',
        operator: Operator.EQ,
        value: SubscriptionStatus.ACTIVE,
      },
      {
        field: 'expiryDate',
        operator: Operator.GT,
        value: Date.now(),
      },
    ];
    try {
      const response = await SubscriptionService.getSubscriptions(...criteria);
      return response;
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doGetActiveSubscriptionsByAccountId = createAsyncThunk(
  'subscriptions/getByAccountId',
  async (input: { accountId: Account['id'] }, { rejectWithValue }) => {
    const criteria: Query<SubscriptionView>[] = [
      {
        field: 'organization.accountId',
        operator: Operator.EQ,
        value: input.accountId,
      },
      {
        field: 'status',
        operator: Operator.EQ,
        value: SubscriptionStatus.ACTIVE,
      },
      {
        field: 'expiryDate',
        operator: Operator.GT,
        value: Date.now(),
      },
    ];
    try {
      const response = await SubscriptionService.querySubscriptionsView(...criteria);
      return { data: response.data, total: response.total };
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doGetActiveRecurlySubscriptionsByAccountId = createAsyncThunk(
  'subscriptions/recurly/getByAccountId',
  async (input: Required<Pick<Account, 'id'>>, { rejectWithValue }) => {
    try {
      const response = await SubscriptionService.getActiveRecurlySubscriptions(input);
      return response;
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doGetActiveRecurlySubscriptionsWithAllocationsByAccountId = createAsyncThunk(
  'subscriptions/recurly/getByAccountId',
  async (input: Required<Pick<Account, 'id'>>, { rejectWithValue }) => {
    try {
      const response = await SubscriptionService.getActiveRecurlySubscriptionsWithAllocations(
        input,
        true,
      );
      return response;
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doSubscribe = createAsyncThunk(
  'subscriptions/subscribe',
  async (order: SingleProductOrder[], { rejectWithValue }) => {
    try {
      const response = await Promise.all(order.map(it => SubscriptionService.subscribe(it)));
      return response;
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doUpgrade = createAsyncThunk(
  'subscriptions/upgradeSubscription',
  async (order: SingleProductOrder[], { rejectWithValue }) => {
    try {
      const response = await Promise.all(order.map(it => SubscriptionService.upgrade(it)));
      return response;
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doCreateTestOpsTrialRequest = createAsyncThunk(
  'subscriptions/createTestOpsTrialRequest',
  async (input: { organizationId: number; feature: OrganizationFeature }, { rejectWithValue }) => {
    try {
      const data = await SubscriptionService.trialRequest(input);
      return { data };
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doCancelSubscription = createAsyncThunk(
  'subscriptions/cancel',
  async (input: RecurlySubscription, { rejectWithValue }) => {
    try {
      const data = await SubscriptionService.cancel(input);
      return { data };
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doReactivateSubscription = createAsyncThunk(
  'subscriptions/reactivate',
  async (input: RecurlySubscription, { rejectWithValue }) => {
    try {
      const data = await SubscriptionService.reactivate(input);
      return { data };
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doCreateVisualTestingTrialRequest = createAsyncThunk(
  'subscriptions/createVisualTestingTrialRequest',
  async (accountId: number, { rejectWithValue }) => {
    try {
      const data = await SubscriptionService.trialVisualTestingRequest(accountId);
      return { data };
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doIsAccessStartPackage = createAsyncThunk(
  'subscriptions/isAccessStartPackage',
  async (accountId: number, { rejectWithValue }) => {
    try {
      const data = await SubscriptionService.isAccessStartPackage(accountId);
      return { data };
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doGetActiveSubscriptionsByListOrgId = createAsyncThunk(
  'subscriptions/getByListOrgId',
  async (organizationIds: Subscription['organizationId'][], { rejectWithValue }) => {
    const criteria: Query<Subscription>[] = [
      {
        field: 'organizationId',
        operator: Operator.IN,
        value: organizationIds,
      },
      {
        field: 'status',
        operator: Operator.EQ,
        value: SubscriptionStatus.ACTIVE,
      },
      {
        field: 'expiryDate',
        operator: Operator.GT,
        value: Date.now(),
      },
      { limit: MAX_NUMBER_OF_RECORDS },
    ];
    try {
      const firstResponse = await SubscriptionService.querySubscriptions(...criteria);
      const numberOfRequests = Math.ceil(firstResponse.total / MAX_NUMBER_OF_RECORDS);
      const remainingRequests = [];
      for (let i = 1; i < numberOfRequests; i += 1) {
        const req = SubscriptionService.querySubscriptions(
          ...criteria,
          { offset: MAX_NUMBER_OF_RECORDS * i },
        );
        remainingRequests.push(req);
      }
      const remainingResponses = await Promise.all(remainingRequests);
      const subscriptions = [...firstResponse.data];
      remainingResponses.forEach(r => {
        subscriptions.push(...r.data);
      });
      return subscriptions;
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

const subscriptionSlice = createSlice({
  name: SUBSCRIPTION_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {
    doReset(state) {
      state.starterPackageSubscriptions = [];
      state.invoiceNumbers = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(doGetActiveSubscriptionsByOrgId.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetActiveSubscriptionsByOrgId.fulfilled, (state, action) => {
      state.loading = false;
      subscriptionsAdapter.setAll(state, action.payload);
    });
    builder.addCase(doGetActiveSubscriptionsByOrgId.rejected, (state, action) => {
      state.loading = true;
      state.errors.push(action.payload as SerializedException);
    });
    builder.addCase(doSubscribe.pending, state => {
      state.loading = true;
    });
    builder.addCase(doSubscribe.fulfilled, (state, action) => {
      state.loading = false;
      state.invoiceNumbers = (action.payload as RecurlySubscription[])
        .map(it => it.recurlyInvoiceNumber!!);
    });
    builder.addCase(doSubscribe.rejected, (state, action) => {
      state.loading = false;
      state.invoiceNumbers = [];
      state.errors.push(action.payload as SerializedException);
    });
    builder.addCase(doUpgrade.pending, state => {
      state.loading = true;
    });
    builder.addCase(doUpgrade.fulfilled, (state, action) => {
      state.loading = false;
      state.invoiceNumbers = (action.payload as RecurlySubscription[])
        .map(it => it.recurlyInvoiceNumber!!);
    });
    builder.addCase(doUpgrade.rejected, (state, action) => {
      state.loading = false;
      state.invoiceNumbers = [];
      state.errors.push(action.payload as SerializedException);
    });
    builder.addCase(doCreateTestOpsTrialRequest.pending, state => {
      state.loading = true;
    });
    builder.addCase(doCreateTestOpsTrialRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.trialRequest = action.payload.data;
    });
    builder.addCase(doCreateTestOpsTrialRequest.rejected, (state, action) => {
      state.loading = false;
      state.trialRequest = null;
      state.errors.push(action.payload as SerializedException);
    });
    builder.addCase(doCancelSubscription.pending, state => {
      state.loading = true;
    });
    builder.addCase(doCancelSubscription.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(doCancelSubscription.rejected, (state, action) => {
      state.loading = false;
      state.errors.push(action.payload as SerializedException);
    });
    builder.addCase(doReactivateSubscription.pending, state => {
      state.loading = true;
    });
    builder.addCase(doReactivateSubscription.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(doReactivateSubscription.rejected, (state, action) => {
      state.loading = false;
      state.errors.push(action.payload as SerializedException);
    });
    builder.addCase(doIsAccessStartPackage.pending, state => {
      state.loading = true;
    });
    builder.addCase(doIsAccessStartPackage.fulfilled, (state, action) => {
      state.isApplyStarterPackage = action.payload.data;
      state.loading = false;
    });
    builder.addCase(doIsAccessStartPackage.rejected, (state, action) => {
      state.loading = false;
      state.errors.push(action.payload as SerializedException);
    });

    builder.addCase(doGetActiveSubscriptionsByListOrgId.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetActiveSubscriptionsByListOrgId.fulfilled, (state, action) => {
      state.loading = false;
      subscriptionsAdapter.setAll(state, action.payload);
    });
    builder.addCase(doGetActiveSubscriptionsByListOrgId.rejected, (state, action) => {
      state.loading = true;
      state.errors.push(action.payload as SerializedException);
    });

    builder.addCase(doGetActiveSubscriptionsByAccountId.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetActiveSubscriptionsByAccountId.fulfilled, (state, action) => {
      state.loading = false;
      subscriptionsAdapter.setAll(state, action.payload.data);
    });
    builder.addCase(doGetActiveSubscriptionsByAccountId.rejected, (state, action) => {
      state.loading = true;
      state.errors.push(action.payload as SerializedException);
    });
  },
});

const selectSubscriptionFeature = (state: AppState) => state[SUBSCRIPTION_FEATURE_KEY];
const { selectAll } = subscriptionsAdapter.getSelectors(selectSubscriptionFeature);

export const selectLoading = createSelector(
  selectSubscriptionFeature,
  subscriptionState => subscriptionState.loading,
);

export const selectSubscriptionByOrganizationId = (id: Subscription['organizationId']) => createSelector(
  selectAll,
  all => all.filter(it => it.organizationId === id),
);

export const selectAllSubscription = () => selectAll;

export const selectHasTOEnterpriseByOrganizationId = (id: Subscription['organizationId']) => createSelector(
  selectAll,
  all => all.find(
    it => it.organizationId === id
      && it.feature === OrganizationFeature.TESTOPS_ENTERPRISE
      && new Date(it.expiryDate).getTime() > Date.now(),
  ) !== undefined,
);

export const selectHasTOEnterprise = () => createSelector(
  selectAll,
  all => all.find(
    it => it.feature === OrganizationFeature.TESTOPS_ENTERPRISE
      && new Date(it.expiryDate).getTime() > Date.now(),
  ) !== undefined,
);

export const selectHasTOPlatformByOrganizationId = (id: Subscription['organizationId']) => createSelector(
  selectAll,
  all => all.find(
    it => it.organizationId === id
      && it.feature === OrganizationFeature.TESTOPS_PLATFORM
      && it.quantity >= 30000
      && it.source === TestOpsSubscriptionSource.RECURLY
      && new Date(it.expiryDate).getTime() > Date.now(),
  ) !== undefined,
);

export const selectTOPlatformByOrganizationId = (organizationId: number) => createSelector(
  selectAll,
  all => all.find(
    it => it.organizationId === organizationId
      && it.feature === OrganizationFeature.TESTOPS_PLATFORM,
  ),
);

export const selectHasTOPlatformUltimateByOrganizationId = (id: Subscription['organizationId']) => createSelector(
  selectAll,
  all => all.find(
    it => it.organizationId === id
      && it.feature === OrganizationFeature.TESTOPS_PLATFORM
      && (it.tier === TestOpsPlatformTierType.BUSINESS
        || it.tier === TestOpsPlatformTierType.ENTERPRISE)
      && new Date(it.expiryDate).getTime() > Date.now(),
  ) !== undefined,
);

export const selectHasTOPlatformUltimate = () => createSelector(
  selectAll,
  all => all.find(
    it => it.feature === OrganizationFeature.TESTOPS_PLATFORM
      && (it.tier === TestOpsPlatformTierType.BUSINESS
        || it.tier === TestOpsPlatformTierType.ENTERPRISE)
      && new Date(it.expiryDate).getTime() > Date.now(),
  ) !== undefined,
);

export const selectInvoiceNumbers = createSelector(
  selectSubscriptionFeature,
  it => it.invoiceNumbers,
);

export const selectPackageInvoiceNumbers = createSelector(
  selectSubscriptionFeature,
  it => it.starterPackageSubscriptions?.map(it => it.recurlyInvoiceNumber!!),
);

export const selectIsApplyStarterPackage = createSelector(
  selectSubscriptionFeature,
  it => it.isApplyStarterPackage,
);

export const selectErrors = () => createSelector(selectSubscriptionFeature, state => state.errors);

export const selectSubscriptionByListOrganizationId = (organizationIds: Subscription['organizationId'][]) => createSelector(
  selectAll,
  all => all.filter(it => organizationIds.includes(it.organizationId)),
);

export const {
  doReset,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
