import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import DurationUnitFormat from 'intl-unofficial-duration-unit-format';
import Skeleton from '@mui/material/Skeleton';

const options = {
  style: DurationUnitFormat.styles.NARROW,
  format: '{hour} {seconds}',
};
const intlDuration = new DurationUnitFormat('en', options);

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 1),
    minHeight: theme.spacing(14),
  },
  productName: {
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  duration: {
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightBold,
    marginTop: theme.spacing(1),
    fontSize: theme.spacing(4),
  },
}));

interface LicenseUsageLinearSummaryProps {
  productNames: string[],
  durations: number[],
  colors: string[],
  loading: boolean,
}
const LicenseUsageLinearSummary = (props: LicenseUsageLinearSummaryProps) => {
  const { productNames, durations, colors, loading } = props;
  const classes = useStyles();
  if (productNames.length !== durations.length || durations.length !== colors.length) return null;
  const licenseSkeletonCol = 5;
  // xs: 5*2, sm: 3*3, md: 3*3, lg: 3*4
  return (
    <Paper elevation={1} className={classes.root}>
      <Grid container spacing={1}>
        {loading ? (
          Array.from(Array(licenseSkeletonCol).keys()).map(el => (
            <Grid item xs key={el}>
              <Typography variant="body1" className={classes.productName}>
                <Skeleton />
              </Typography>
              <Typography variant="h2" className={classes.duration}>
                <Skeleton />
              </Typography>
            </Grid>
          ))
        ) : productNames.map((productName, i) => (
          <Grid item xs key={productName}>
            <Typography variant="body1" className={classes.productName}>
              {i + 1 < productNames.length && (
              <span style={{ color: colors[i] }}>&#11044;&nbsp;</span>
              )}
              {productName}
            </Typography>
            <Typography variant="h2" className={classes.duration} style={{ color: colors[i] }}>
              { intlDuration.format(durations[i] / 1000) }
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default LicenseUsageLinearSummary;
