import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { LayoutRouteQuery } from '../../layout/routes';
import { useNavigate, useQuery } from '../../routes';
import { fromAuth, fromOrganizations, useAppDispatch, fromCurrentOrgUser } from '../../store';
import LoadingProgress from '../../layout/LoadingProgress';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    flex: '1 0 0',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    '& > *:first-child': {
      marginRight: theme.spacing(1.5),
    },
  },
  content: {
    '& > *:not(:first-child)': {
      marginTop: theme.spacing(2),
    },
  },
  bold: {
    fontWeight: 500,
  },
}));

interface DeleteOrgInput {
  orgName: string;
}

const DeleteOrganizationDialog = () => {
  const selectedOrganization = useSelector(fromOrganizations.selectSelectedOrganization);
  const allOrganization = useSelector(fromOrganizations.selectAllOrganizations);
  const orgLoading = useSelector(fromOrganizations.selectLoadingGetOrganizationsByUserId);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { get } = useQuery();
  const orgId = get('orgId');
  const user = useSelector(fromAuth.selectUser);
  const orgTotalMember = useSelector(fromOrganizations.selectCount);
  const currentOrgUser = useSelector(fromCurrentOrgUser.selectByOrganizationIdAndEmail(
    user?.email || '',
    Number(orgId),
  ));
  const organization = useSelector(fromOrganizations.selectSelectedOrganization);
  const { navigate, replaceQuery } = useNavigate<LayoutRouteQuery>();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors: inputErrors },
  } = useForm<DeleteOrgInput>({
    defaultValues: { orgName: '' },
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const doDeleteOrg = async () => {
    setLoading(true);
    try {
      await dispatch(fromOrganizations.doDeleteOrganization({ id: selectedOrganization!.id }));
      enqueueSnackbar(<FormattedMessage id="organization.deleteorganizationdialog.success" />, { variant: 'success' });
      handleClose();
      if (allOrganization.length > 0) {
        navigate(
          '/organization',
          replaceQuery({ orgId: allOrganization[0].id }),
        );
      }
      analytics.track(
        'Organization Deleted',
        {
          user_id: user?.id,
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
          system_role: user?.roles,
          org_role: currentOrgUser?.role,
          org_id: organization?.id,
          org_name: organization?.name,
          total_members: orgTotalMember,
        },
      );
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        user_id: user?.id,
        org_role: currentOrgUser?.role,
        system_role: user?.roles,
        org_id: organization?.id,
        org_name: organization?.name,
        total_members: orgTotalMember,
        event: 'gtm_organization_deleted',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        <FormattedMessage id="organization.deleteorganizationdialog.understand_delete" />
      </Button>
      <Dialog
        onBackdropClick={handleClose}
        onClose={handleClose}
        aria-labelledby="confirmation-dialog-title"
        open={open}
      >
        <form onSubmit={handleSubmit(doDeleteOrg)}>
          <DialogTitle id="confirmation-dialog-title">
            <FormattedMessage id="organization.deleteorganizationdialog.title" />
          </DialogTitle>
          <DialogContent className={classes.content}>
            <Typography className={classes.bold} variant="body1">
              <FormattedMessage id="organization.deleteorganizationdialog.body" values={{ orgName: selectedOrganization?.name }} />
            </Typography>
            <Box fontWeight="fontWeightMedium">
              <FormattedMessage id="organization.deleteorganizationdialog.type_organizationname" values={{ orgName: selectedOrganization?.name }} />
            </Box>
            <TextField
              variant="outlined"
              fullWidth
              required
              autoFocus
              placeholder={selectedOrganization?.name}
              name="orgName"
              inputProps={{
                ...register('orgName', {
                  required: 'error.required',
                  validate: value => value === selectedOrganization?.name || 'error.orgname_dose_not_match',
                }),
              }}
              helperText={
                inputErrors.orgName && <FormattedMessage id={inputErrors.orgName?.message} />
              }
              error={!!inputErrors.orgName}
            />
          </DialogContent>
          <DialogActions>
            <Box className={classes.buttonContainer}>
              <Button
                variant="contained"
                type="submit"
                color="secondary"
                disabled={watch('orgName') !== selectedOrganization?.name}
              >
                <FormattedMessage id="organization.deleteorganizationdialog.delete_organization" />
              </Button>
              <Button variant="contained" color="primary" type="button" onClick={handleClose}>
                <FormattedMessage id="removeuser.confirmationdialog.cancel" />
              </Button>
            </Box>
          </DialogActions>
        </form>
        {(loading || orgLoading) && (
          <LoadingProgress />
        )}
      </Dialog>
    </>
  );
};

export default DeleteOrganizationDialog;
