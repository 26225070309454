import { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import { FormattedMessage } from 'react-intl';
import Button from '@katalon-studio/katalon-ui/Button';
import { ReactComponent as ArrowLeftIcon } from '../icons/arrow-left-icon.svg';
import { userGroup } from '../../layout/routes';
import { fromAuth, fromUserGroup } from '../../store';
import { useNavigate } from '../../routes/useNavigate';
import AddUserToGroupPopup from './AddUserToGroupPopup';
import { AccountRole, AccountUser, OrganizationUser, isOrganizationAdminOrOwner } from '../../models';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: theme.spacing(3),
    color: '#233145',
    fontWeight: theme.typography.fontWeightMedium,
  },
  backIconButton: {
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  backIcon: {
    color: 'rgba(104, 109, 128, 0.54)',
    marginRight: theme.spacing(1.5),
    '&:hover': {
      cursor: 'pointer',
    },
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  titleButton: {
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
    backgroundColor: '#e7e9ef',
    color: '#22283c',
    marginTop: theme.spacing(2),
  },
}));

interface UserGroupDetailHeaderProps {
  currentOrgUser: OrganizationUser | undefined;
  userGroupId: number,
  orgId: number,
  currentAccountUser?: AccountUser;
  addUserDialogCallback: () => void;
}

const UserGroupDetailHeader = (props: UserGroupDetailHeaderProps) => {
  const {
    currentOrgUser,
    userGroupId,
    orgId,
    currentAccountUser,
    addUserDialogCallback,
  } = props;

  const classes = useStyles();
  const { navigate, replaceQuery } = useNavigate();
  const [isOpenAddUsersDialog, setIsOpenAddUsersDialog] = useState(false);
  const isRootOrAdmin = useSelector(fromAuth.selectIsRootOrAdmin);
  const currentUserGroup = useSelector(fromUserGroup.selectUserGroupsById(Number(userGroupId)));

  const goToUserGroupListPage = () => {
    navigate(userGroup.path, replaceQuery({ orgId }));
  };

  if (!currentUserGroup) {
    return null;
  }

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={6}>
        <Box className={classes.root}>
          <IconButton
            id="project_management.common.close_button"
            size="small"
            aria-label="close"
            color="inherit"
            onClick={goToUserGroupListPage}
            className={classes.backIconButton}
          >
            <ArrowLeftIcon className={classes.backIcon} />
          </IconButton>
          {currentUserGroup.name}
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2} justifyContent="flex-end">
          { currentOrgUser
            && (isOrganizationAdminOrOwner(currentOrgUser.role)
            || AccountRole.isAdminOrOwnerRole(currentAccountUser?.role)
            || isRootOrAdmin)
            && (
              <Button
                id="user.group.add.users.button"
                size="small"
                variant="contained"
                className={classes.titleButton}
                onClick={() => setIsOpenAddUsersDialog(true)}
              >
                <FormattedMessage id="user.group.add.users.dialog.title" />
              </Button>
            )}
          {isOpenAddUsersDialog && (
            <AddUserToGroupPopup
              userGroupId={userGroupId}
              isOpen={isOpenAddUsersDialog}
              orgId={orgId}
              onCloseDialog={() => setIsOpenAddUsersDialog(false)}
              onSuccessCallback={addUserDialogCallback}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserGroupDetailHeader;
