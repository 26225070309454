import Button from '@katalon-studio/katalon-ui/Button';
import { Dialog, DialogBody, DialogFooter, DialogHeader } from '@katalon-studio/katalon-ui/Dialog';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CreateOrganizationSuccessIcon } from '../../layout/CustomIcon';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
  },
  dialog: {
    position: 'absolute',
    borderRadius: theme.spacing(0.75),
    width: theme.spacing(75),
    height: theme.spacing(50),
  },
  dlgHeader: {
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    width: '100%',
    height: theme.spacing(25),
    fontSize: theme.spacing(2),
    fontWeight: 700,
    color: '#233145',
    display: 'flex',
    '& .MuiIconButton-root': {
      marginBottom: theme.spacing(19.375),
    },
  },
  dlgTitle: {
    flexGrow: 2,
    fontWeight: 500,
    fontSize: theme.spacing(3),
    textAlign: 'center',
    marginTop: theme.spacing(3),
    '& p': {
      fontWeight: 600,
      fontSize: theme.spacing(2.5),
    },
  },
  dlgContent: {
    width: '100%',
  },
  boxSpacing: {
    height: theme.spacing(2),
  },
  dlgActionButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 3, 3, 3),
  },
  buttonSpacing: {
    width: theme.spacing(1),
  },
  cancelButton: {
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
  },
  disableBtn: {
    color: 'rgba(104, 109, 128) !important',
    backgroundColor: 'rgba(104, 109, 128, 0.24) !important',
  },
  activeBtn: {
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
    marginLeft: '0 !important',
  },
  textFieldTitle: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    '& p': {
      fontSize: theme.spacing(2),
      fontWeight: 'normal',
      paddingLeft: theme.spacing(4.5),
      paddingRight: theme.spacing(4.5),
    },
  },
}));

interface CreateOrganizationSuccessPopupProps {
  isOpen: boolean;
  onCloseDialog: () => void;
  onSuccessCallback: () => void;
}

const CreateOrganizationSuccessPopup = (props: CreateOrganizationSuccessPopupProps) => {
  const {
    isOpen,
    onCloseDialog,
    onSuccessCallback,
  } = props;

  const classes = useStyles();

  return (
    <>
      <Dialog
        classes={{ paper: classes.dialog }}
        open={isOpen}
        maxWidth="sm"
        placeholder="center"
        fullWidth
      >
        <DialogHeader
          id="organization.management.dialog.create.new.organization.success.header"
          className={classes.dlgHeader}
          onClick={onCloseDialog}
        >
          <Box className={classes.dlgTitle}>
            <CreateOrganizationSuccessIcon />
            <Typography id="organization.management.dialog.create.new.organization.success.title">
              <FormattedMessage id="organization.management.dialog.create.new.organization.success.title" />
            </Typography>
          </Box>
        </DialogHeader>
        <DialogBody
          id="organization.management.dialog.create.new.organization.success.body"
          className={classes.dlgContent}
        >
          <Box className={classes.textFieldTitle}>
            <Typography id="organization.management.dialog.create.new.organization.success.content">
              <FormattedMessage id="organization.management.dialog.create.new.organization.success.content" />
            </Typography>
          </Box>
        </DialogBody>
        <DialogFooter
          id="organization.management.dialog.create.new.organization.success.footer"
          className={classes.dlgActionButtons}
        >
          <Button
            id="organization.management.dialog.create.new.organization.success.submit"
            className={classes.activeBtn}
            type="submit"
            color="primary"
            variant="contained"
            size="small"
            onClick={onSuccessCallback}
          >
            <FormattedMessage id="organization.management.dialog.create.new.organization.success.submit" />
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default CreateOrganizationSuccessPopup;
