import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { VFC } from 'react';
import { useSelector } from 'react-redux';

import { fromAuth } from './store';

const useStyles = makeStyles(theme => ({
  '@keyframes filledUp': {
    from: {
      width: '20%',
    },
    to: {
      width: '85%',
    },
  },
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 2,
  },
  active: {
    background: theme.palette.secondary.main,
    animation: `$filledUp 1000ms ${theme.transitions.easing.easeInOut} forwards`,
  },
}));

const AppLoadingProgress: VFC = () => {
  // reduce from multiple loading state for global loading indicator
  // use only auth state loading for now.
  const isLoading = useSelector(fromAuth.selectLoading);
  const classes = useStyles();

  return <div className={clsx(classes.root, { [classes.active]: isLoading })} />;
};

export default AppLoadingProgress;
