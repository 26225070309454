import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useConfig } from '../../config';
import { isAdminOrOwner } from '../../models/accountRole';
import { resolvePath, sso as ssoPath, useQuery } from '../../routes';
import { fromAccountUsers, fromAccounts, fromAuth, fromOrganizations, useAppDispatch } from '../../store';
import { ReactComponent as WarningIcon } from './warning-icon.svg';

const useStyles = makeStyles(theme => ({
  warningBanner: {
    background: '#fef9e7',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'start',
    padding: theme.spacing(1, 2),
  },
  message: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: theme.spacing(1.75),
  },
  warningIcon: {
    width: theme.spacing(2),
  },
  warningLink: {
    color: '#598ef9',
    textDecoration: 'none',
  },
}));

const TERM_OF_USE_LINK = 'https://katalon.com/terms#customer-terms-of-use';

const FreeKsViolationWarningBanner = () => {
  const [display, setDisplay] = useState(false);
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { config } = useConfig();
  const query = useQuery();

  const [orgId, setOrgId] = useState(Number(query.get('orgId') ?? 0));
  const organization = useSelector(fromOrganizations.selectOrganizationById(orgId));
  const freeKsExpiry = useSelector(fromAccounts.selectFreeKsExpiry);
  const accountId = Number(useQuery().get('accountId') ?? organization?.accountId ?? 0);
  const user = useSelector(fromAuth.selectUser);
  const currentAccountUser = useSelector(fromAccountUsers.selectOneByUserIdAndAccountId(
    Number(user?.id),
    Number(accountId),
  ));
  const defaultOrg = useSelector(fromOrganizations.selectAllByAccountId(accountId))[0];

  useEffect(() => {
    if (accountId) {
      setDisplay(false);
      dispatch(fromAccountUsers.doGetByUserIdAndAccountId({ userId: user!!.id, accountId }));
    }
  }, [accountId]);

  useEffect(() => {
    if (orgId === 0 && defaultOrg) {
      setOrgId(defaultOrg.id);
    }
  }, [orgId, defaultOrg]);

  useEffect(() => {
    if (currentAccountUser && isAdminOrOwner(currentAccountUser.role)) {
      dispatch(fromAccounts.doGetFreeKsExpiryDate(accountId));
    }
  }, [currentAccountUser]);

  useEffect(() => {
    if (freeKsExpiry && config) {
      const notificationStartDate = config.freeKsViolationNotificationStartDate || 0;
      const currentTimestamp = Date.now();
      setDisplay(freeKsExpiry.isPaid
        && freeKsExpiry.expiryDate >= currentTimestamp
        && currentTimestamp >= notificationStartDate);
    }
  }, [freeKsExpiry, config]);

  const renderWarningContent = () => {
    const domainLicenseLink = resolvePath(
      ssoPath,
      undefined,
      {
        redirect_uri: `${process.env.REACT_APP_ADMIN_URL}/organization/${orgId}/admin/license_keys/kse`,
      },
    );
    return intl.formatMessage({ id: 'message.free_ks_violation_warning' }, {
      licenseLink: (
        <a className={classes.warningLink} href={domainLicenseLink}>
          {intl.formatMessage({ id: 'message.free_ks_violation_warning.license_text' })}
        </a>
      ),
      termLink: (
        <a className={classes.warningLink} href={TERM_OF_USE_LINK}>
          {intl.formatMessage({ id: 'message.free_ks_violation_warning.term_text' })}
        </a>
      ),
    });
  };

  if (!display) return <></>;

  return (
    <div className={classes.warningBanner}>
      <WarningIcon className={classes.warningIcon} />
      <span className={classes.message}>
        { renderWarningContent() }
      </span>
    </div>
  );
};

export default FreeKsViolationWarningBanner;
