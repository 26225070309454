import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import { useNavigate, useQuery } from '../../../routes';
import {
  useAppDispatch,
  fromAccounts,
} from '../../../store';
import {
  fromInvoices,
  fromPaymentMethod,
  fromBillingInformation,
} from '../../../store/rootReducer';
import LoadingProgress from '../../../layout/LoadingProgress';
import PromotionSection from './PromotionSection';
import PaymentInformationSection from '../testops-platform-checkout/payment-information-section/PaymentInformationSection';
import {
  accountSubscriptionManagement,
  paymentMethod,
} from '../../../layout/routes';
import { PaymentMethodType, TestOpsPlanInterval } from '../../../models';

const useStyles = makeStyles((theme => ({
  root: {
    height: '100%',
    width: '100%',
    borderRadius: '.5rem',
    padding: '1.5rem',
    overflowY: 'auto',
    backgroundColor: '#F9FBFF',
  },
  page: {
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
  },
  cardTitle: {
    fontSize: theme.spacing(2.5),
  },
  planContainer: {
    margin: theme.spacing(3, 0, 2),
  },
  testOpsPlatformContainer: {
    fontWeight: theme.typography.fontWeightBold,
    color: '#313B58',
    fontSize: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cardPaymentAndBilling: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(2.5),
    fontWeight: theme.typography.fontWeightBold,
    color: '#313B58',
    fontSize: theme.spacing(2),
  },
  planPurchaseDetails: {
    // padding: theme.spacing(4),
    borderRadius: theme.spacing(1),
  },
  badgeSubscription: {
    marginLeft: theme.spacing(1),
  },
  icon: {
    marginRight: 8,
    height: theme.spacing(2.125),
    width: theme.spacing(2.125),
  },
  titleComeBack: {
    color: '#727993',
    fontFamily: 'Inter',
    fontSize: theme.spacing(2),
    marginBottom: theme.spacing(0.75),
    fontWeight: 500,
  },
  iconBack: {
    marginRight: theme.spacing(2),
    color: '#727993',
  },
  backBtn: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  card: {
    display: 'flex',
  },
  cardTitleContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: theme.spacing(3),
    padding: theme.spacing(0, 1, 1, 0),
  },
  dialogTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    margin: theme.spacing(0, 0, 0),
    padding: theme.spacing(3, 0, 1, 2),
    fontSize: 24,
    color: '#233145',
  },
  dialogContent: {
    lineHeight: 1.5,
    fontSize: 16,
    padding: theme.spacing(0, 0, 3, 2),
    color: '#233145',
  },
  dialogAction: {
    marginBottom: 16,
  },
  goPaymentMethodBtn: {
    background: 'transparent',
    color: '#1847ca',
    fontSize: 14,
    fontWeight: 500,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  goSubscriptionBtn: {
    background: '#1847ca',
    color: 'white',
    borderRadius: 4,
    fontSize: 14,
    fontWeight: 500,
    '&:hover': {
      backgroundColor: '#1847ca',
    },
  },
})));

const DirectToSelfServeConfirmation = () => {
  const classes = useStyles();
  const { get } = useQuery();
  const intl = useIntl();
  const { replaceQuery, navigate } = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [isAvailableBankTransfer, setIsAvailableBankTransfer] = useState(false);
  const accountId = Number(get('accountId'));
  const currentCard = useSelector(fromPaymentMethod.selectOneByAccountId(Number(accountId)));
  const currentBillingInformation = useSelector(fromBillingInformation.selectOneByAccountId(
    Number(accountId),
  ));
  const selectedPaymentMethod = useSelector(fromPaymentMethod.selectSelectedPaymentMethod);
  const defaultPaymentMethod = useSelector(fromPaymentMethod.selectDefaultPaymentMethod);
  const isOptInToSelfServe = useSelector(fromAccounts.selectIsOptInToSelfServe);
  const renewalInvoices = useSelector(fromInvoices.selectRenewalInvoices);
  const dispatch = useAppDispatch();
  const [showConfirmationPopUp, setShowConfirmationPopUp] = useState(false);
  const [triggerOptInSelfServe, setTriggerOptInSelfServe] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchInfo = async () => {
      if (!accountId) return;
      await Promise.all([
        dispatch(fromInvoices.doPreviewRenewal(accountId)),
        dispatch(fromAccounts.doCheckOptInSelfServe(accountId)),
        dispatch(fromAccounts.doCheckPublicDomain(Number(accountId))),
      ]).finally(() => {
        setLoading(false);
      });
    };
    fetchInfo().catch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  useEffect(() => {
    if (triggerOptInSelfServe
      && ((selectedPaymentMethod === PaymentMethodType.CREDIT_CARD && currentCard)
        || selectedPaymentMethod === PaymentMethodType.BANK_TRANSFER)
      && currentBillingInformation
      && !defaultPaymentMethod
      && !isOptInToSelfServe
    ) {
      const optInSelfServe = async () => {
        setLoading(true);
        await Promise.all([
          dispatch(fromPaymentMethod.doUpdateDefaultPaymentMethod({
            accountId,
            defaultPaymentMethod: selectedPaymentMethod,
          })),
          dispatch(fromAccounts.doOptInSelfServe(accountId)),
        ]).then(() => {
          setShowConfirmationPopUp(true);
        }).finally(() => {
          setLoading(false);
        });
      };
      optInSelfServe();
    }
  }, [triggerOptInSelfServe, isOptInToSelfServe, currentBillingInformation, currentCard]);

  const goPaymentMethod = () => {
    navigate(paymentMethod.path, replaceQuery({
      accountId: `${accountId}`,
    }));
  };

  const goSubscriptionManagement = () => {
    navigate(accountSubscriptionManagement.path, replaceQuery({
      accountId: `${accountId}`,
    }));
  };

  useEffect(() => {
    const isAvailableBankTransfer = renewalInvoices.every(invoice => {
      if (!invoice.subscriptions) {
        return false;
      }
      const subscription = invoice.subscriptions[0];
      if (subscription.plan?.interval === TestOpsPlanInterval.MONTH) {
        return false;
      }

      const expiryDate = new Date(subscription.endDate);
      const currentDate = new Date();
      const diffTime = Math.abs(expiryDate.valueOf() - currentDate.valueOf());
      return Math.floor(diffTime / (1000 * 60 * 60 * 24)) >= 7;
    });

    setIsAvailableBankTransfer(isAvailableBankTransfer);
  }, [renewalInvoices]);

  const renderConfirmationPopUp = () => (
    <Dialog
      open={showConfirmationPopUp}
      id="order-confirmation-dialog"
      maxWidth="md"
      placeholder="center"
      fullWidth
      PaperProps={{
        sx: {
          paddingX: 2,
          borderRadius: 1.5,
          width: 600,
        },
      }}
    >
      <div className={classes.card}>
        <div id="order-confirmation-dialog-title" className={classes.cardTitleContent}>
          <DialogTitle className={classes.dialogTitle}>
            <FormattedMessage id="promotion.autorenewal_confirmation" />
          </DialogTitle>
        </div>
      </div>
      <DialogContent id="order-confirmation-dialog-content" className={classes.dialogContent}>
        {intl.formatMessage(
          { id: 'promotion.autorenewal_confirmation.instruction' },
          {
            br: <br />,
          },
        )}
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button id="payment-method-btn" className={classes.goPaymentMethodBtn} variant="contained" onClick={goPaymentMethod}>
          <FormattedMessage id="promotion.go_payment_method" />
        </Button>
        <Button id="subscription-btn" className={classes.goSubscriptionBtn} variant="contained" onClick={goSubscriptionManagement}>
          <FormattedMessage id="promotion.go_subscription_management" />
        </Button>
      </DialogActions>
    </Dialog>
  );

  if (isOptInToSelfServe && !triggerOptInSelfServe) {
    goSubscriptionManagement();
  }

  return (
    <>
      { isLoading ? <LoadingProgress /> : (
        <div className={classes.root}>
          <Button
            className={classes.backBtn}
            onClick={goSubscriptionManagement}
          >
            <Grid container alignItems="flex-end" className={classes.page}>
              <Grid item>
                <ArrowBackIcon className={classes.iconBack} />
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.titleComeBack}>
                  {intl.formatMessage({ id: 'billinginfo.billingcontact.back.to.subscription.management' })}
                </Typography>
              </Grid>
            </Grid>
          </Button>
          <PromotionSection />
          <PaymentInformationSection
            accountId={accountId}
            enableSave={false}
            availableRenewal={isAvailableBankTransfer}
            setTriggerOptInSelfServe={setTriggerOptInSelfServe}
          />
          {renderConfirmationPopUp()}
        </div>
      )}
    </>
  );
};

export default DirectToSelfServeConfirmation;
