import { ThemeOptions } from '@mui/material/styles';
import merge from 'lodash/fp/merge';

import themeOptions from './common';

export default merge<Partial<ThemeOptions>>({
  palette: {
    mode: 'dark',
    background: {
      default: '#202020',
    },
    primary: {
      light: '#87a6ff',
      dark: '#004dc1',
      main: '#367cff',
    },
  },
  typography: {
    subtitle1: {
      color: '#9ac9f5',
    },
    h5: {
      color: '#9ac9f5',
    },
    body1: {
      color: '#f8f8f8',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#262626',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: '#9ac9f5',
        },
      },
    },
  },
})(themeOptions);
