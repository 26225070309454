import { SubscriptionSource } from './subscriptionSource';
import { TestOpsSubscriptionSource } from './testOpsSubscriptionSource';

enum OrganizationFeature {
  KSE = 'KSE',
  ENGINE = 'ENGINE',
  FLOATING_ENGINE = 'FLOATING_ENGINE', // we dont use this
  UNLIMITED_KSE = 'UNLIMITED_KSE',
  UNLIMITED_ENGINE = 'UNLIMITED_ENGINE',
  TESTOPS = 'TESTOPS', // dont use this, just use for filtering TO license
  TESTOPS_BUSINESS = 'TESTOPS_BUSINESS',
  TESTOPS_ENTERPRISE = 'TESTOPS_ENTERPRISE',
  PER_USER_KSE = 'PER_USER_KSE',
  TESTCLOUD_MINUTE = 'TESTCLOUD_MINUTE',
  TESTCLOUD_SESSION_WEB = 'TESTCLOUD_SESSION_WEB',
  TESTCLOUD_FREE_TRIAL = 'TESTCLOUD_FREE_TRIAL',
  VISUAL_TESTING_PRO = 'VISUAL_TESTING_PRO',
  VISUAL_TESTING_STANDARD = 'VISUAL_TESTING_STANDARD',
  RECORDER = 'RECORDER',
  TESTOPS_PLATFORM = 'TESTOPS_PLATFORM',
  PER_USER_KSE_OFFLINE = 'PER_USER_KSE_OFFLINE',
  UNLIMITED_ENGINE_OFFLINE = 'UNLIMITED_ENGINE_OFFLINE',
  TESTCLOUD_SESSION_WEB_DESKTOP = 'TESTCLOUD_SESSION_WEB_DESKTOP',
  TESTCLOUD_SESSION_CROSS_BROWSER = 'TESTCLOUD_SESSION_CROSS_BROWSER',
  TESTCLOUD_SESSION_MOBILE_APP = 'TESTCLOUD_SESSION_MOBILE_APP',
  TESTCLOUD_SESSION_PRIVATE_DEVICE = 'TESTCLOUD_SESSION_PRIVATE_DEVICE',
  TRUE_TEST = 'TRUE_TEST',
  TESTOPS_G3 = 'TESTOPS_G3',
}

// https://github.com/typescript-eslint/typescript-eslint/issues/3568
// eslint-disable-next-line @typescript-eslint/no-redeclare
namespace OrganizationFeature {
  export const fromString = (str: string): OrganizationFeature | undefined => {
    if (str === 'KATALON_PLATFORM') {
      return OrganizationFeature.TESTOPS_PLATFORM;
    }
    if (str === OrganizationFeature.TESTOPS_PLATFORM.toString()) {
      return undefined;
    }
    return (OrganizationFeature as any)[str];
  };
  export const toString = (feature: OrganizationFeature): string => {
    if (feature === OrganizationFeature.TESTOPS_PLATFORM) {
      return 'KATALON_PLATFORM';
    }
    return feature.toString();
  };
  export const getFeatureFullName = (feature: OrganizationFeature | string | undefined) => {
    switch (feature) {
      case OrganizationFeature.KSE:
        return 'Katalon Studio Enterprise (Node-locked)';
      case OrganizationFeature.UNLIMITED_KSE:
        return 'Katalon Studio Enterprise (Floating)';
      case OrganizationFeature.ENGINE:
        return 'Katalon Runtime Engine (Node-locked)';
      case OrganizationFeature.UNLIMITED_ENGINE:
        return 'Katalon Runtime Engine (Floating)';
      case OrganizationFeature.TESTOPS_BUSINESS:
        return 'TestOps Business';
      case OrganizationFeature.TESTOPS_ENTERPRISE:
        return 'TestOps Enterprise';
      case OrganizationFeature.PER_USER_KSE:
        return 'Katalon Studio Enterprise (per-User)';
      case OrganizationFeature.TESTCLOUD_MINUTE:
        return 'Katalon TestCloud Per Minute';
      case OrganizationFeature.TESTCLOUD_SESSION_WEB:
        return 'Katalon TestCloud Per Session';
      case OrganizationFeature.TESTCLOUD_FREE_TRIAL:
        return 'Katalon TestCloud Free Trial';
      case OrganizationFeature.VISUAL_TESTING_PRO:
        return 'Visual Testing Professional';
      case OrganizationFeature.VISUAL_TESTING_STANDARD:
        return 'Visual Testing Standard';
      case OrganizationFeature.TESTOPS_PLATFORM:
        return 'Katalon Platform';
      case OrganizationFeature.RECORDER:
        return 'Katalon Recorder';
      case OrganizationFeature.PER_USER_KSE_OFFLINE:
        return 'Katalon Studio Enterprise - Hybrid';
      case OrganizationFeature.UNLIMITED_ENGINE_OFFLINE:
        return 'Katalon Runtime Engine (Floating) - Hybrid';
      case OrganizationFeature.TESTCLOUD_SESSION_WEB_DESKTOP:
        return 'Katalon TestCloud (Desktop Browser Testing)';
      case OrganizationFeature.TESTCLOUD_SESSION_CROSS_BROWSER:
        return 'Katalon TestCloud (Desktop & Mobile Browser Testing)';
      case OrganizationFeature.TESTCLOUD_SESSION_MOBILE_APP:
        return 'Katalon TestCloud (Mobile Native App Testing)';
      case OrganizationFeature.TESTCLOUD_SESSION_PRIVATE_DEVICE:
        return 'Katalon TestCloud (Private Device Testing)';
      case OrganizationFeature.TRUE_TEST:
        return 'Katalon TrueTest';
      case OrganizationFeature.TESTOPS_G3:
        return 'Katalon TestOps G3';
      default:
        return 'Unknown';
    }
  };

  export const getFeatureHalfFullName = (feature: OrganizationFeature | string | undefined) => {
    switch (feature) {
      case OrganizationFeature.KSE:
        return 'KSE Node-locked';
      case OrganizationFeature.UNLIMITED_KSE:
        return 'KSE Floating';
      case OrganizationFeature.ENGINE:
        return 'KRE Node-locked';
      case OrganizationFeature.UNLIMITED_ENGINE:
        return 'KRE Floating';
      case OrganizationFeature.TESTOPS_BUSINESS:
        return 'TestOps Business';
      case OrganizationFeature.TESTOPS_ENTERPRISE:
        return 'TestOps Enterprise';
      case OrganizationFeature.PER_USER_KSE:
        return 'KSE per-User';
      case OrganizationFeature.TESTCLOUD_SESSION_WEB:
      case OrganizationFeature.TESTCLOUD_MINUTE:
        return 'Katalon TestCloud';
      case OrganizationFeature.TESTCLOUD_SESSION_WEB_DESKTOP:
        return 'Katalon TestCloud (Desktop Browser Testing)';
      case OrganizationFeature.TESTCLOUD_SESSION_CROSS_BROWSER:
        return 'Katalon TestCloud (Desktop & Mobile Browser Testing)';
      case OrganizationFeature.TESTCLOUD_SESSION_MOBILE_APP:
        return 'Katalon TestCloud (Mobile Native App Testing)';
      case OrganizationFeature.TESTCLOUD_SESSION_PRIVATE_DEVICE:
        return 'Katalon TestCloud (Private Device Testing)';
      case OrganizationFeature.VISUAL_TESTING_PRO:
        return 'Visual Testing Professional';
      case OrganizationFeature.VISUAL_TESTING_STANDARD:
        return 'Visual Testing Standard';
      case OrganizationFeature.RECORDER:
        return 'Recorder';
      case OrganizationFeature.TESTOPS_PLATFORM:
        return 'Katalon Platform';
      case OrganizationFeature.PER_USER_KSE_OFFLINE:
        return 'KSE - Hybrid';
      case OrganizationFeature.UNLIMITED_ENGINE_OFFLINE:
        return 'KRE Floating - Hybrid';
      case OrganizationFeature.TRUE_TEST:
        return 'Katalon TrueTest';
      case OrganizationFeature.TESTOPS_G3:
        return 'Katalon TestOps G3';
      default:
        return 'Unknown';
    }
  };
  // since trial plans and free plan weren't saved in db, this func get TO plans basing on source
  export const getTestOpsFeature = (
    feature?: OrganizationFeature | undefined,
    source?: TestOpsSubscriptionSource,
  ) => {
    if (source === TestOpsSubscriptionSource.TRIAL_REQUEST) {
      return feature === OrganizationFeature.TESTOPS_BUSINESS ? 'TestOps Business Trial' : 'TestOps Enterprise Trial';
    }
    if (source === TestOpsSubscriptionSource.RECURLY) {
      return feature === OrganizationFeature.TESTOPS_BUSINESS
        ? getFeatureHalfFullName(OrganizationFeature.TESTOPS_BUSINESS)
        : getFeatureHalfFullName(OrganizationFeature.TESTOPS_ENTERPRISE);
    }
    return 'TestOps';
  };

  export const getTestOpsPlatformFeature = (
    source?: TestOpsSubscriptionSource,
  ) => {
    if (source === TestOpsSubscriptionSource.TRIAL_REQUEST) {
      return 'Katalon Platform Trial';
    }
    if (source === TestOpsSubscriptionSource.RECURLY) {
      return 'Katalon Platform';
    }
    return 'Katalon Platform Free';
  };
  export const getTestOpsTrialName = (
    feature?: OrganizationFeature | undefined,
  ) => {
    switch (feature) {
      case OrganizationFeature.TESTOPS_BUSINESS:
        return 'TestOps Business (30-day trial)';
      case OrganizationFeature.TESTOPS_ENTERPRISE:
        return 'TestOps Enterprise (45-day trial)';
      default: return 'TestOps Free';
    }
  };
  export const getFeatureFullNameWithoutKatalon = (
    feature: OrganizationFeature | string | undefined,
    source?: SubscriptionSource | string | undefined,
  ) => {
    switch (feature) {
      case OrganizationFeature.KSE:
        return 'Studio Enterprise (Node-locked)';
      case OrganizationFeature.UNLIMITED_KSE:
        return 'Studio Enterprise (Floating)';
      case OrganizationFeature.ENGINE:
        return 'Runtime Engine (Node-locked)';
      case OrganizationFeature.UNLIMITED_ENGINE:
        return 'Runtime Engine (Floating)';
      case OrganizationFeature.TESTOPS_BUSINESS:
        return 'TestOps Business';
      case OrganizationFeature.TESTOPS_ENTERPRISE:
        return 'TestOps Enterprise';
      case OrganizationFeature.PER_USER_KSE:
        return 'Studio Enterprise (per-User)';
      case OrganizationFeature.TESTCLOUD_MINUTE:
        return 'TestCloud Per Minute';
      case OrganizationFeature.TESTCLOUD_SESSION_WEB:
        return 'TestCloud Per Session';
      case OrganizationFeature.TESTCLOUD_FREE_TRIAL:
        return 'TestCloud Free Trial';
      case OrganizationFeature.VISUAL_TESTING_PRO:
        if (source === SubscriptionSource.TRIAL) {
          return 'Visual Testing Professional Trial';
        }
        return 'Visual Testing Professional';
      case OrganizationFeature.VISUAL_TESTING_STANDARD:
        return 'Visual Testing Standard';
      case OrganizationFeature.RECORDER:
        return 'Recorder';
      case OrganizationFeature.TESTOPS_PLATFORM:
        if (source === SubscriptionSource.TRIAL) {
          return 'Katalon Platform Trial';
        }
        if (source === SubscriptionSource.RECURLY) {
          return 'Katalon Platform';
        }
        return 'Katalon Platform Free';
      case OrganizationFeature.PER_USER_KSE_OFFLINE:
        return 'Studio Enterprise - Hybrid';
      case OrganizationFeature.UNLIMITED_ENGINE_OFFLINE:
        return 'Runtime Engine (Floating) - Hybrid';
      case OrganizationFeature.TESTCLOUD_SESSION_WEB_DESKTOP:
        return 'TestCloud Per Session (Desktop Browser)';
      case OrganizationFeature.TESTCLOUD_SESSION_CROSS_BROWSER:
        return 'TestCloud Per Session (Cross Browser)';
      case OrganizationFeature.TESTCLOUD_SESSION_MOBILE_APP:
        return 'TestCloud Per Session (Mobile Native App)';
      case OrganizationFeature.TESTCLOUD_SESSION_PRIVATE_DEVICE:
        return 'TestCloud Per Session (Private Device)';
      case OrganizationFeature.TRUE_TEST:
        return 'TrueTest';
      case OrganizationFeature.TESTOPS_G3:
        return 'TestOps G3';
      default:
        return 'Unknown';
    }
  };

  export const getFeaturePrefix = (
    feature?: OrganizationFeature | undefined,
  ) => {
    switch (feature) {
      case OrganizationFeature.PER_USER_KSE:
      case OrganizationFeature.PER_USER_KSE_OFFLINE:
        return 'kse';
      case OrganizationFeature.UNLIMITED_ENGINE:
      case OrganizationFeature.UNLIMITED_ENGINE_OFFLINE:
        return 'kre';
      case OrganizationFeature.TESTCLOUD_SESSION_WEB:
      case OrganizationFeature.TESTCLOUD_MINUTE:
        return 'testCloud';
      case OrganizationFeature.TESTCLOUD_SESSION_WEB_DESKTOP:
        return 'testCloudDesktop';
      case OrganizationFeature.TESTCLOUD_SESSION_CROSS_BROWSER:
        return 'testCloudCrossBrowser';
      case OrganizationFeature.TESTCLOUD_SESSION_MOBILE_APP:
        return 'testCloudMobile';
      case OrganizationFeature.TESTCLOUD_SESSION_PRIVATE_DEVICE:
        return 'testCloudPrivateDevice';
      case OrganizationFeature.VISUAL_TESTING_PRO:
      case OrganizationFeature.VISUAL_TESTING_STANDARD:
        return 'visualTesting';
      case OrganizationFeature.TESTOPS_PLATFORM:
        return 'platform';
      case OrganizationFeature.TRUE_TEST:
        return 'truetest';
      case OrganizationFeature.TESTOPS_G3:
        return 'testOpsG3';
      default:
        return 'Unknown';
    }
  };
}

export const isStudioFeature = (feature: OrganizationFeature) => [
  OrganizationFeature.KSE,
  OrganizationFeature.PER_USER_KSE,
  OrganizationFeature.UNLIMITED_KSE,
  OrganizationFeature.ENGINE,
  OrganizationFeature.UNLIMITED_ENGINE,
  OrganizationFeature.PER_USER_KSE_OFFLINE,
  OrganizationFeature.UNLIMITED_ENGINE_OFFLINE,
].includes(feature);

export const isVisualTestingFeature = (feature: OrganizationFeature) => [
  OrganizationFeature.VISUAL_TESTING_STANDARD,
  OrganizationFeature.VISUAL_TESTING_PRO,
].includes(feature);

export const isTestOpsPlatformFeature = (feature: OrganizationFeature) => [
  OrganizationFeature.TESTOPS_PLATFORM,
].includes(feature);

export const isFloatingEngineFeature = (feature: OrganizationFeature) => [
  OrganizationFeature.FLOATING_ENGINE,
].includes(feature);

export const isRecorderFeature = (feature: OrganizationFeature) => [
  OrganizationFeature.RECORDER,
].includes(feature);

export const isTestOpsFeature = (feature: OrganizationFeature) => [
  OrganizationFeature.TESTOPS,
  OrganizationFeature.TESTOPS_BUSINESS,
  OrganizationFeature.TESTOPS_ENTERPRISE,
].includes(feature);

export const isKseFeature = (feature: OrganizationFeature) => [
  OrganizationFeature.KSE,
  OrganizationFeature.PER_USER_KSE,
  OrganizationFeature.UNLIMITED_KSE,
  OrganizationFeature.PER_USER_KSE_OFFLINE,
].includes(feature);

export const testOpsTrials = [
  {
    id: OrganizationFeature.TESTOPS,
    name: OrganizationFeature.getTestOpsTrialName(
      OrganizationFeature.TESTOPS,
    ),
  },
  {
    id: OrganizationFeature.TESTOPS_BUSINESS,
    name: OrganizationFeature.getTestOpsTrialName(
      OrganizationFeature.TESTOPS_BUSINESS,
    ),
  },
  {
    id: OrganizationFeature.TESTOPS_ENTERPRISE,
    name: OrganizationFeature.getTestOpsTrialName(
      OrganizationFeature.TESTOPS_ENTERPRISE,
    ),
  },
];

export const isTestCloudFeature = (feature: OrganizationFeature | undefined) => feature && [
  OrganizationFeature.TESTCLOUD_MINUTE,
  OrganizationFeature.TESTCLOUD_SESSION_WEB,
  OrganizationFeature.TESTCLOUD_FREE_TRIAL,
  OrganizationFeature.TESTCLOUD_SESSION_WEB_DESKTOP,
  OrganizationFeature.TESTCLOUD_SESSION_CROSS_BROWSER,
  OrganizationFeature.TESTCLOUD_SESSION_MOBILE_APP,
  OrganizationFeature.TESTCLOUD_SESSION_PRIVATE_DEVICE,
].includes(feature);

// features that are inactive and not purchasable
export const deprecatedFeatures = [
  OrganizationFeature.KSE,
  OrganizationFeature.UNLIMITED_KSE,
  OrganizationFeature.ENGINE,
  OrganizationFeature.TESTCLOUD_MINUTE,
  OrganizationFeature.FLOATING_ENGINE,
];

export const getFeatureFromPlan = (planId: string): OrganizationFeature | undefined => {
  if (planId.startsWith('testops_platform')) {
    return OrganizationFeature.TESTOPS_PLATFORM;
  }

  if (planId.startsWith('testcloud_session_webdesktop')) {
    return OrganizationFeature.TESTCLOUD_SESSION_WEB_DESKTOP;
  }

  if (planId.startsWith('testcloud_session_crossbrowser')) {
    return OrganizationFeature.TESTCLOUD_SESSION_CROSS_BROWSER;
  }

  if (planId.startsWith('testcloud_session_web')) {
    return OrganizationFeature.TESTCLOUD_SESSION_WEB;
  }

  if (planId.startsWith('testcloud_session_mobileapp')) {
    return OrganizationFeature.TESTCLOUD_SESSION_MOBILE_APP;
  }

  if (planId.startsWith('visual_testing_pro')) {
    return OrganizationFeature.VISUAL_TESTING_PRO;
  }

  if (planId.startsWith('kse_per-user')) {
    return OrganizationFeature.PER_USER_KSE;
  }

  if (planId.startsWith('kre_floating')) {
    return OrganizationFeature.UNLIMITED_ENGINE;
  }

  if (planId.startsWith('true_test')) {
    return OrganizationFeature.TRUE_TEST;
  }

  if (planId.startsWith('testops_g3')) {
    return OrganizationFeature.TESTOPS_G3;
  }

  return undefined;
};

export const getFeatureName = (feature?: OrganizationFeature): string => {
  switch (feature) {
    case OrganizationFeature.PER_USER_KSE:
      return 'kse';
    case OrganizationFeature.PER_USER_KSE_OFFLINE:
      return 'kse_offline';
    case OrganizationFeature.UNLIMITED_ENGINE:
      return 'kre';
    case OrganizationFeature.UNLIMITED_ENGINE_OFFLINE:
      return 'kre_offline';
    case OrganizationFeature.TESTCLOUD_SESSION_WEB:
      return 'test_cloud';
    case OrganizationFeature.TESTOPS_PLATFORM:
      return 'testops_platform.testops_platform.2000';
    case OrganizationFeature.TESTCLOUD_SESSION_WEB_DESKTOP:
      return 'test_cloud_desktop';
    case OrganizationFeature.TESTCLOUD_SESSION_CROSS_BROWSER:
      return 'test_cloud_cross_browser';
    case OrganizationFeature.TESTCLOUD_SESSION_MOBILE_APP:
      return 'test_cloud_mobile';
    case OrganizationFeature.TESTCLOUD_SESSION_PRIVATE_DEVICE:
      return 'test_cloud_private_device';
    case OrganizationFeature.VISUAL_TESTING_PRO:
      return 'visualtesting';
    case OrganizationFeature.TRUE_TEST:
      return 'truetest';
    case OrganizationFeature.TESTOPS_G3:
      return 'testops_g3';
    default:
      return '';
  }
};

export const getIntervalPathParam = (feature?: OrganizationFeature): string => {
  switch (feature) {
    case OrganizationFeature.PER_USER_KSE:
      return 'kseCycle';
    case OrganizationFeature.UNLIMITED_ENGINE:
      return 'kreCycle';
    case OrganizationFeature.TESTOPS_PLATFORM:
      return 'platformCycle';
    case OrganizationFeature.TESTCLOUD_SESSION_WEB:
      return 'testCloudCycle';
    case OrganizationFeature.TESTCLOUD_SESSION_WEB_DESKTOP:
      return 'testCloudDesktopCycle';
    case OrganizationFeature.TESTCLOUD_SESSION_CROSS_BROWSER:
      return 'testCloudCrossBrowserCycle';
    case OrganizationFeature.TESTCLOUD_SESSION_MOBILE_APP:
      return 'testCloudMobileCycle';
    case OrganizationFeature.VISUAL_TESTING_PRO:
    case OrganizationFeature.VISUAL_TESTING_STANDARD:
      return 'visualTestingCycle';
    default:
      return '';
  }
};

export const getQuotaPathParam = (feature?: OrganizationFeature): string => {
  switch (feature) {
    case OrganizationFeature.PER_USER_KSE:
      return 'kseQuota';
    case OrganizationFeature.UNLIMITED_ENGINE:
      return 'kreQuota';
    case OrganizationFeature.TESTOPS_PLATFORM:
      return 'platformQuota';
    case OrganizationFeature.TESTCLOUD_SESSION_WEB:
      return 'testCloudQuota';
    case OrganizationFeature.TESTCLOUD_SESSION_WEB_DESKTOP:
      return 'testCloudDesktopQuota';
    case OrganizationFeature.TESTCLOUD_SESSION_CROSS_BROWSER:
      return 'testCloudCrossBrowserQuota';
    case OrganizationFeature.TESTCLOUD_SESSION_MOBILE_APP:
      return 'testCloudMobileQuota';
    case OrganizationFeature.TRUE_TEST:
      return 'truetest';
    case OrganizationFeature.VISUAL_TESTING_PRO:
    case OrganizationFeature.VISUAL_TESTING_STANDARD:
      return 'visualTestingQuota';
    default:
      return '';
  }
};

export const getUnitName = (featureName: string, quota?: number): string => {
  const suffix = quota === 1 || quota === undefined ? '_unit' : '_unit_plural';
  return `subscriptions.testops_platform.offering.${featureName}${suffix}`;
};

export const getFeatureTitle = (feature?: OrganizationFeature): string => {
  switch (feature) {
    case OrganizationFeature.PER_USER_KSE:
    case OrganizationFeature.PER_USER_KSE_OFFLINE:
      return 'starter.test_creation';
    case OrganizationFeature.UNLIMITED_ENGINE:
    case OrganizationFeature.UNLIMITED_ENGINE_OFFLINE:
      return 'starter.test_execution';
    case OrganizationFeature.TESTCLOUD_SESSION_WEB:
    case OrganizationFeature.TESTCLOUD_SESSION_WEB_DESKTOP:
    case OrganizationFeature.TESTCLOUD_SESSION_CROSS_BROWSER:
    case OrganizationFeature.TESTCLOUD_SESSION_MOBILE_APP:
    case OrganizationFeature.TESTCLOUD_SESSION_PRIVATE_DEVICE:
      return 'starter.test_execution';
    case OrganizationFeature.TESTOPS_PLATFORM:
      return 'starter.test_management';
    default:
      return '';
  }
};

export const getFeatureOfHybridFeature = (feature: OrganizationFeature): OrganizationFeature => {
  switch (feature) {
    case OrganizationFeature.PER_USER_KSE_OFFLINE:
      return OrganizationFeature.PER_USER_KSE;
    case OrganizationFeature.UNLIMITED_ENGINE_OFFLINE:
      return OrganizationFeature.UNLIMITED_ENGINE;
    default:
      return feature;
  }
};

export const isTestCreationProduct = (feature?: OrganizationFeature) => feature
  === OrganizationFeature.PER_USER_KSE;

export const isTestExecutionProduct = (feature?: OrganizationFeature) => feature
  === OrganizationFeature.UNLIMITED_ENGINE
  || feature === OrganizationFeature.TESTCLOUD_SESSION_WEB
  || feature === OrganizationFeature.TESTCLOUD_SESSION_WEB_DESKTOP
  || feature === OrganizationFeature.TESTCLOUD_SESSION_CROSS_BROWSER
  || feature === OrganizationFeature.TESTCLOUD_SESSION_MOBILE_APP
  || feature === OrganizationFeature.TESTCLOUD_SESSION_PRIVATE_DEVICE;

export const isPlatformProduct = (feature?: OrganizationFeature) => feature
  === OrganizationFeature.TESTOPS_PLATFORM;

export const isHybridFeature = (feature?: OrganizationFeature) => feature
  === OrganizationFeature.PER_USER_KSE_OFFLINE
  || feature === OrganizationFeature.UNLIMITED_ENGINE_OFFLINE;

export const isKreFloatingFeature = (feature?: OrganizationFeature) => feature
  === OrganizationFeature.UNLIMITED_ENGINE
  || feature === OrganizationFeature.UNLIMITED_ENGINE_OFFLINE;

export { OrganizationFeature };
