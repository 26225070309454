import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import { AppState } from '.';
import { exceptionOf, SerializedException, TestOpsSubscriptionSource, TestOpsSubscriptionStatus } from '../models/index';
import { Operator, Query } from '../models/query';
import { TestOpsPlatformSubscriptionService } from '../services';
import { TestOpsPlatformSubscription } from '../models/testOpsPlatformSubscription';
import { AdditionalResources } from './subscriptionsSlice';

export const TESTOPS_PLATFORM_SUBSCRIPTION_KEY = 'testOpsPlatformSubscription';
export const EXISTED_SUBSCRIPTION_TESTOPS_PLATFORM = 'testops.platform.subscription.existed';
interface TestOpsPlatformSubscriptionsState extends EntityState<TestOpsPlatformSubscription> {
  trialSubscription: TestOpsPlatformSubscription;
  currentSubscription: TestOpsPlatformSubscription;
  loading: boolean;
  errors: SerializedException[];
  error: SerializedException;
}

const testOpsPlatformSubscriptionsAdapter = createEntityAdapter<TestOpsPlatformSubscription>();
const createInitialState = (partialState: Partial<TestOpsPlatformSubscriptionsState> = {}) => (
  testOpsPlatformSubscriptionsAdapter.getInitialState({
    ...partialState,
    loading: false,
    errors: [] as SerializedException[],
  })
);

export const doGetAllTestOpsPlatformSubscriptionsByAccountId = createAsyncThunk(
  'testOpsPlatformSubscriptions/getByAccountId',
  async (input: Required<Pick<TestOpsPlatformSubscription, 'accountId'>> & AdditionalResources, { rejectWithValue }) => {
    const criteria: Query<TestOpsPlatformSubscription>[] = [
      {
        field: 'accountId',
        operator: Operator.EQ,
        value: input.accountId,
      },
    ];
    try {
      const testOpsPlatformSubscription = await TestOpsPlatformSubscriptionService
        .getTestOpsPlatformSubscriptions(
          input.checkHasPremierSuccess || false,
          ...criteria,
        );
      const trialTestOpsPlatformSubscription = await TestOpsPlatformSubscriptionService
        .getTrialTestOpsPlatformSubscriptions(input.accountId);
      return {
        platformSubscriptions: testOpsPlatformSubscription,
        trialSubscriptions: trialTestOpsPlatformSubscription,
      };
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doSubscribeUnlimitedTrial = createAsyncThunk(
  'testOpsPlatformSubscriptions/subscribeUnlimitedTrial',
  async (
    accountId: Parameters<typeof TestOpsPlatformSubscriptionService['subscribeUnlimitedTrial']>[0],
    { rejectWithValue },
  ) => {
    try {
      const trialSubscription = await TestOpsPlatformSubscriptionService
        .subscribeUnlimitedTrial(accountId);
      return trialSubscription;
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doCancelUnlimitedTrial = createAsyncThunk(
  'testOpsPlatformSubscriptions/cancelUnlimitedTrial',
  async (
    accountId: Parameters<typeof TestOpsPlatformSubscriptionService['cancelUnlimitedTrial']>[0],
    { rejectWithValue },
  ) => {
    try {
      const data = await TestOpsPlatformSubscriptionService
        .cancelUnlimitedTrial(accountId);
      return data;
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doResetEntity = createAction('entity/reset');

const testOpsPlatformSubscriptionsSlice = createSlice({
  name: TESTOPS_PLATFORM_SUBSCRIPTION_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doGetAllTestOpsPlatformSubscriptionsByAccountId.pending, state => {
      state.loading = true;
      state.errors = [];
    });
    builder.addCase(
      doGetAllTestOpsPlatformSubscriptionsByAccountId.fulfilled,
      (state, action) => {
        state.loading = false;
        testOpsPlatformSubscriptionsAdapter.setMany(
          state,
          action.payload.platformSubscriptions.data.filter(it => it!!),
        );
        testOpsPlatformSubscriptionsAdapter.setMany(
          state,
          action.payload.trialSubscriptions.data.filter(it => it!!),
        );
      },
    );
    builder.addCase(doGetAllTestOpsPlatformSubscriptionsByAccountId.rejected, state => {
      state.loading = false;
    });
    builder.addCase(doSubscribeUnlimitedTrial.pending, state => {
      state.loading = true;
      state.errors = [];
    });
    builder.addCase(doSubscribeUnlimitedTrial.fulfilled, (state, action) => {
      state.trialSubscription = action.payload;
      state.loading = false;
    });
    builder.addCase(doSubscribeUnlimitedTrial.rejected, (state, action) => {
      const payload = action.payload as SerializedException;
      state.errors.push(payload);
      state.loading = false;
    });
    builder.addCase(doCancelUnlimitedTrial.pending, state => {
      state.loading = true;
      state.errors = [];
    });
    builder.addCase(doCancelUnlimitedTrial.fulfilled, (state, action) => {
      state.currentSubscription = action.payload;
      state.loading = false;
    });
    builder.addCase(doCancelUnlimitedTrial.rejected, (state, action) => {
      const payload = action.payload as SerializedException;
      state.errors.push(payload);
      state.loading = false;
    });
    builder.addCase(doResetEntity, state => {
      testOpsPlatformSubscriptionsAdapter.removeAll(state);
    });
  },
});

// eslint-disable-next-line max-len
const selectTestOpsPlatformSubscriptions = (state: AppState) => state[TESTOPS_PLATFORM_SUBSCRIPTION_KEY];

// eslint-disable-next-line max-len
const { selectAll } = testOpsPlatformSubscriptionsAdapter.getSelectors(selectTestOpsPlatformSubscriptions);

export const selectErrors = () => createSelector(
  selectTestOpsPlatformSubscriptions,
  state => state.errors,
);

export const selectLoading = createSelector(
  selectTestOpsPlatformSubscriptions,
  subscriptionState => subscriptionState.loading,
);

export const selectTrialSubscription = () => createSelector(
  selectTestOpsPlatformSubscriptions,
  subscriptionState => subscriptionState.trialSubscription,
);

export const selectCurrentSubscription = () => createSelector(
  selectTestOpsPlatformSubscriptions,
  subscriptionState => subscriptionState.currentSubscription,
);

export const selectTestOpsPlatformSubscriptionByAccountId = (id: TestOpsPlatformSubscription['accountId']) => createSelector(
  selectAll,
  all => all.filter(it => it.accountId === id && it.status === TestOpsSubscriptionStatus.ACTIVE),
);

export const selectPaidTestOpsPlatformSubscriptionByAccountId = (id: TestOpsPlatformSubscription['accountId']) => createSelector(
  selectAll,
  all => all.filter(it => it.accountId === id
    && (it.source === TestOpsSubscriptionSource.RECURLY
      && it.status === TestOpsSubscriptionStatus.ACTIVE)),
);

export const selectTrialTestOpsPlatformSubscriptionByAccountId = (id: TestOpsPlatformSubscription['accountId']) => createSelector(
  selectAll,
  all => all.filter(it => it.accountId === id
    && it.source === TestOpsSubscriptionSource.TRIAL_REQUEST),
);

export const selectActiveTrialTestOpsPlatformSubscriptionByAccountId = (id: TestOpsPlatformSubscription['accountId']) => createSelector(
  selectAll,
  all => all.filter(it => it.accountId === id
    && it.source === TestOpsSubscriptionSource.TRIAL_REQUEST
    && it.status === TestOpsSubscriptionStatus.ACTIVE),
);

export default testOpsPlatformSubscriptionsSlice.reducer;
