import { Account } from './account';
import { BaseModel } from './base';

export enum PaymentMethodType {
  CREDIT_CARD = 'CREDIT_CARD',
  BANK_TRANSFER = 'BANK_TRANSFER',
}

export interface PaymentMethod extends BaseModel {
  name: string;
  last4: string;
  brand: string;
  expiration: string;
  accountId: Account['id'];
  stripeToken?: string;
}

export interface DefaultPaymentMethod {
  accountId: number,
  defaultPaymentMethod: PaymentMethodType,
}
