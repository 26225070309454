import { useSelector } from 'react-redux';
import AdditionalSubscriptionRow from './AdditionalSubscriptionRow';
import { Account, OrganizationFeature } from '../../../../models';
import {
  fromTestOpsPlatformSubscriptions,
  fromTestOpsSubscriptions,
  fromVisualTestingSubscriptions, useAppDispatch,
} from '../../../../store';
import { convertToTestOpsPlatformTierV2 } from '../../utils';
import { TestOpsPlatformTierTypeV2 } from '../../../../models/testOpsPlatformTierTypeV2';
import { useLaunchDarkly } from '../../../../launchdarkly';

export interface VisualTestingSubscriptionDetailsProps {
  account: Account | null,
  email: string | null,
  organizationId: number,
}
const VisualTestingSubscriptionDetails = (props: VisualTestingSubscriptionDetailsProps) => {
  const {
    account,
    organizationId,
    email,
  } = props;

  const dispatch = useAppDispatch();
  const { flags } = useLaunchDarkly();

  const currentTestOpsPlatformSubscription = useSelector(
    fromTestOpsPlatformSubscriptions.selectTestOpsPlatformSubscriptionByAccountId(
      Number(account?.id),
    ),
  )?.[0];

  const currentVisualTestingSubscription = useSelector(
    fromVisualTestingSubscriptions.selectByAccountId(
      Number(account?.id),
    ),
  )?.[0];

  const currentTestOpsSubscription = useSelector(
    fromTestOpsSubscriptions.selectByAccountId(
      Number(account?.id),
    ),
  )?.[0];

  const handleRefreshSubscription = () => {
    const fetchInfo = async () => {
      setTimeout(() => {}, 5000);
      await dispatch(fromVisualTestingSubscriptions.doGetActiveByAccountId({
        accountId: Number(account?.id),
        checkHasPremierSuccess: true,
      }));
    };
    fetchInfo().catch(() => {});
  };

  const isEnterprisePlanOrUltimatePlan = () => {
    if (currentTestOpsSubscription?.feature === OrganizationFeature.TESTOPS_ENTERPRISE) {
      return true;
    }

    const tier = convertToTestOpsPlatformTierV2(
      currentTestOpsPlatformSubscription?.tier,
    );
    return tier === TestOpsPlatformTierTypeV2.ULTIMATE;
  };

  const name = (currentVisualTestingSubscription || isEnterprisePlanOrUltimatePlan())
    ? 'subscriptions.visual_testing_professional'
    : 'subscriptions.visual_testing_standard';

  const getName = () => {
    if (flags?.unlimitedVTPEnabled) {
      return 'subscriptions.visual_testing_professional';
    }
    return name;
  };

  const getCurrentVTPSubscription = () => {
    if (flags?.unlimitedVTPEnabled) {
      return undefined;
    }
    return currentVisualTestingSubscription;
  };

  const getInitQuantity = () => {
    if (flags?.unlimitedVTPEnabled) {
      return 'Unlimited';
    }
    return isEnterprisePlanOrUltimatePlan() ? 5000 : 1000;
  };

  return (
    <>
      <AdditionalSubscriptionRow
        key={getName()}
        name={getName()}
        organizationId={organizationId}
        email={email}
        currentSubscription={getCurrentVTPSubscription()}
        initQuantity={getInitQuantity()}
        feature={OrganizationFeature.VISUAL_TESTING_PRO}
        handleRefreshSubscription={handleRefreshSubscription}
        account={account}
      />
    </>
  );
};

export default VisualTestingSubscriptionDetails;
