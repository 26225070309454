import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { notFound, resolvePath, useQuery } from '../../routes';
import OrgCardView from './OrgCardView';
import {
  fromAccounts,
  fromAccountUsers,
  fromAuth,
  fromOrganizations,
  fromTestOpsProject,
  useAppDispatch,
} from '../../store';
import LoadingProgress from '../../layout/LoadingProgress';
import { AccountRole, AccountUser } from '../../models';
import CreateOrganizationPopup from './CreateOrganizationPopup';
import { useLaunchDarkly } from '../../launchdarkly';
import CreateOrganizationSuccessPopup from './CreateOrganizationSuccessPopup';
import { licensesAllocation } from '../../layout/routes';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(3),
  },
  container: {
    paddingTop: '1.5rem',
  },
  header: {
    display: 'flex',
  },
  titleButton: {
    height: theme.spacing(4),
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
    float: 'inline-end',
  },
  disableBtn: {
    color: 'rgba(104, 109, 128) !important',
    backgroundColor: 'rgba(104, 109, 128, 0.24) !important',
  },
}));

const OrgCardViewManagement = () => {
  const { get } = useQuery();
  const intl = useIntl();
  const accountId = Number(get('accountId'));
  const classes = useStyles();
  const [isOpenCreateDialog, setIsOpenCreateDialog] = useState(false);
  const [isOpenCreateSuccessDialog, setIsOpenCreateSuccessDialog] = useState(false);
  const [createdOrgId, setCreatedOrgId] = useState(0);
  const [accountUsers, setAccountUsers] = useState([]);
  const dispatch = useAppDispatch();
  const { flags } = useLaunchDarkly();
  const user = useSelector(fromAuth.selectUser);
  const isRootOrAdmin = useSelector(fromAuth.selectIsRootOrAdmin);
  const accountUser = useSelector(
    fromAccountUsers.selectOneByUserIdAndAccountId(user?.id!!, accountId),
  );
  const accountOrganizations = useSelector(
    fromOrganizations.selectAllByAccountId(accountId),
  );
  const { isPaid } = useSelector(fromAccounts.selectFreeKsExpiry) ?? {};

  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    if (!accountId) return;
    const fetchInfo = async () => {
      await Promise.all([
        dispatch(fromOrganizations.doFetchOrganizationByAccountId({ accountId })),
        dispatch(fromAccountUsers.doGetByAccountId({ accountId }))
          .then(data => {
            if (data.payload) {
              // @ts-ignore
              setAccountUsers(data?.payload ?? []);
            }
          }),
        dispatch(fromTestOpsProject.doCountProjectsByUserEmail(user!)),
      ]);
      setFetched(true);
    };
    fetchInfo().catch(() => {});
  }, [accountId, createdOrgId]);

  const redirectLicenseAllocatePage = () => {
    const uri = resolvePath(
      licensesAllocation,
      { page: 'kse' },
      { accountId: Number(accountId) },
    );
    window.location.replace(uri.toString());
  };

  if (!fetched) return <LoadingProgress />;

  if (!accountUser && !isRootOrAdmin) return <Navigate to={notFound.path} replace />;

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Typography className={classes.title}>
              <FormattedMessage id="organization.card_view.page.header" />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            { flags?.accountUserManagementEnabled
              && (accountUser?.role === AccountRole.ADMIN
              || accountUser?.role === AccountRole.OWNER
              || isRootOrAdmin)
              && (
                <Tooltip
                  title={!isPaid ? intl.formatMessage({ id: 'organization.management.create.organization.tooltip' }) : ''}
                  placement="bottom"
                  TransitionProps={{ timeout: 0 }}
                  arrow={!isPaid}
                >
                  <Button
                    id="create.organization.button"
                    size="small"
                    onClick={() => isPaid && setIsOpenCreateDialog(true)}
                    className={clsx(classes.titleButton, {
                      [classes.disableBtn]: !isPaid,
                    })}
                    color="primary"
                    variant="contained"
                  >
                    {intl.formatMessage({ id: 'organization.management.create.button' })}
                  </Button>
                </Tooltip>
              )}
          </Grid>
        </Grid>
        {isOpenCreateDialog && flags?.accountUserManagementEnabled && (
          <CreateOrganizationPopup
            isOpen={isOpenCreateDialog}
            accountId={accountId}
            accountUsers={accountUsers
              .filter((accountUser: AccountUser) => accountUser.email !== user?.email)}
            onCloseDialog={() => setIsOpenCreateDialog(false)}
            onSuccessCallback={id => {
              setIsOpenCreateSuccessDialog(true);
              setCreatedOrgId(id);
            }}
          />
        )}
        {isOpenCreateSuccessDialog && flags?.accountUserManagementEnabled && (
          <CreateOrganizationSuccessPopup
            isOpen={isOpenCreateSuccessDialog}
            onCloseDialog={() => setIsOpenCreateSuccessDialog(false)}
            onSuccessCallback={redirectLicenseAllocatePage}
          />
        )}
      </Box>
      <Box className={classes.container}>
        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
          {
            accountOrganizations.map(organization => (
              <Grid key={organization.id} item xs={12} sm={4} md={4}>
                <OrgCardView
                  orgId={organization.id}
                />
              </Grid>
            ))
          }
        </Grid>
      </Box>
    </Box>
  );
};

export default OrgCardViewManagement;
