import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import { SerializedException } from '../models';
import { TestOpsConfigurationService } from '../services';
import { AppState } from './index';
import { TestOpsConfiguration } from '../models/testOpsConfiguration';

export const CONFIGURATION_FEATURE_KEY = 'configurations';

const testOpsConfigurationAdapter = createEntityAdapter<TestOpsConfiguration>();

interface ConfigurationState extends EntityState<TestOpsConfiguration> {
  loading: boolean;
  errors: SerializedException[];
}

export const createInitialState = (): ConfigurationState => (
  testOpsConfigurationAdapter.getInitialState({
    loading: false,
    errors: [] as SerializedException[],
  })
);

export const doGetConfigurationByName = createAsyncThunk(
  'testOps/getConfigurationByName',
  async (input: Required<Pick<TestOpsConfiguration, 'name'>>) => {
    const configuration = await TestOpsConfigurationService.getConfiguration(input.name);
    return { configuration };
  },
);

export const doGetTestOpsFeature = createAsyncThunk(
  'testOps/getTestOpsFeature',
  async () => {
    const configuration = await TestOpsConfigurationService.getConfiguration('testops_feature_on_premise');
    return { configuration };
  },
);

const testOpsConfigurationSlice = createSlice({
  name: CONFIGURATION_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doGetConfigurationByName.fulfilled, (state, action) => {
      state.loading = false;
      state.errors = [];
      testOpsConfigurationAdapter.upsertMany(state, action.payload);
    });
    builder.addCase(doGetConfigurationByName.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetConfigurationByName.rejected, (state, action) => {
      state.loading = false;
      state.errors.push(action.payload as SerializedException);
    });
    builder.addCase(doGetTestOpsFeature.fulfilled, (state, action) => {
      state.loading = false;
      state.errors = [];
      testOpsConfigurationAdapter.upsertMany(state, action.payload);
    });
    builder.addCase(doGetTestOpsFeature.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetTestOpsFeature.rejected, (state, action) => {
      state.loading = false;
      state.errors.push(action.payload as SerializedException);
    });
  },
});

const selectConfigurationFeature = (state: AppState) => state[CONFIGURATION_FEATURE_KEY];

const { selectAll } = testOpsConfigurationAdapter.getSelectors(selectConfigurationFeature);

export const selectTestOpsFeature = createSelector(
  selectAll,
  configurations => configurations.find(configuration => (
    configuration.name === 'testops_feature_on_premise'
  )),
);

export default testOpsConfigurationSlice.reducer;
