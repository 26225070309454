import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import ImageList from '@mui/material/ImageList';
import { FormattedMessage } from 'react-intl';
import PromotionBanner from './PromotionBanner';
import PackageItem from './PackageItem';
import {
  useAppDispatch,
  fromStudioSubscriptions,
} from '../../../../store';
import {
  StarterPackage,
  StarterPackageGroup,
  OrganizationFeature,
} from '../../../../models';
import { useQuery } from '../../../../routes';
import { fromStarterPackage } from '../../../../store/rootReducer';
import {
  STARTER_PACKAGE_DEFAULT,
  getGroupNameByPackageName,
  differenceInMonthDays,
  isDiscountCampaignEnabled,
} from '../../utils';
import { useLaunchDarkly } from '../../../../launchdarkly';

const useStyles = makeStyles((theme => ({
  rootContainer: {
    boxShadow: '0 8px 16px 0 rgba(24, 71, 202, 0.08)',
    margin: theme.spacing(0, 2, 4),
  },
  defaultContainer: {
    padding: theme.spacing(0, 2, 0, 3),
    borderRadius: theme.spacing(0.5),
    background: 'white',
    paddingTop: 24,
  },
  listPackageContainer: {
    overflow: 'hidden',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  listPackage: {
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
  },
  textProratedTitle: {
    margin: 0,
    color: '#233145',
    fontWeight: 'bold',
    fontSize: 16,
    marginBottom: 8,
    marginLeft: theme.spacing(1),
  },
  textProratedSubTitle: {
    margin: 0,
    color: '#4D5369',
    fontWeight: 500,
    fontSize: 14,
    marginLeft: theme.spacing(1),
  },
})));

export interface StarterPlanOfferingSectionProps {
  accountId: number;
}

const StarterPlanOfferingSection = (props: StarterPlanOfferingSectionProps) => {
  const {
    accountId,
  } = props;
  const { flags } = useLaunchDarkly();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const currentStarterPackage = useSelector(fromStarterPackage.selectCurrentStarterPackage);
  const groupPackages = useSelector(fromStarterPackage.selectGroupPackages);
  const { get } = useQuery();
  const selectedPackageName = isDiscountCampaignEnabled(accountId, flags) ? '' : get('starterPackage') ?? STARTER_PACKAGE_DEFAULT;
  const currentKSESubscription = useSelector(
    fromStudioSubscriptions.selectPaidSubscriptionByAccountId(
      Number(accountId),
      OrganizationFeature.PER_USER_KSE,
    ),
  )?.[0];

  const [
    listGroupPackages,
    setListStarterPackage] = useState(groupPackages);

  const listPackageRef = useRef<HTMLSpanElement | null>(null);

  const createDefaultSelectedStarterPackage = () => {
    const selectedPackage: StarterPackage = {
      group: getGroupNameByPackageName(selectedPackageName),
      name: selectedPackageName,
      listPlans: [],
      totalPrice: 0,
      discountedPrice: 0,
    };
    return selectedPackage;
  };

  useEffect(() => {
    if (!isDiscountCampaignEnabled(accountId, flags)) {
      const selectedPackage = createDefaultSelectedStarterPackage();
      dispatch(fromStarterPackage.doSelectStarterPackage(selectedPackage));
    }
  }, []);

  useEffect(() => {
    if (currentStarterPackage !== undefined && currentStarterPackage !== null) {
      const doGetUpgradableStarterPackages = async () => {
        dispatch(fromStarterPackage.doGetUpgradableStarterPackages(accountId));
      };
      doGetUpgradableStarterPackages().catch(() => {});
    } else {
      let selectedPackage;
      groupPackages.forEach(group => {
        const sp = group.starterPackages.find(
          pack => pack.name === selectedPackageName,
        );
        if (sp !== undefined) {
          selectedPackage = sp;
        }
      });
      if (selectedPackage) {
        doSelectStarterPackage(true, groupPackages, selectedPackage);
      } else {
        doSelectStarterPackage(false, groupPackages, selectedPackage);
      }
    }
  }, [currentStarterPackage]);

  useEffect(() => {
    setListStarterPackage(groupPackages);
  }, [groupPackages]);

  const doSelectStarterPackage = (
    selected: boolean,
    listGroups: StarterPackageGroup[],
    starterPackage?: StarterPackage,
  ) => {
    if (selected && starterPackage) {
      dispatch(fromStarterPackage.doSelectStarterPackage(starterPackage));
    } else {
      dispatch(fromStarterPackage.doResetSelectedStarterPackage());
    }
    dispatch(fromStarterPackage.doUpdateUpgradablePackages(listGroups));
  };

  const handlePackageSelection = (
    isSelected: boolean,
    isUpdateSelectedPackage: boolean,
    starterPackage: StarterPackage,
  ) => {
    const selected = !isSelected;
    const cloneListGroups = listGroupPackages.slice(0).map(group => {
      const cloneGroup = { ...group };
      cloneGroup.selected = selected && group.id === starterPackage.group;
      if (cloneGroup.id === starterPackage.group && isUpdateSelectedPackage) {
        cloneGroup.selectedStarterPackage = group.starterPackages
          .find(item => item.name === starterPackage.name);
      }
      return cloneGroup;
    });
    doSelectStarterPackage(selected, cloneListGroups, starterPackage);
    setListStarterPackage(cloneListGroups);
  };

  const handleChildPackageSelection = (isSelected: boolean, starterPackage?: StarterPackage) => {
    if (starterPackage !== undefined) {
      const cloneListGroups = listGroupPackages.slice(0).map(group => {
        const cloneGroup = { ...group };
        if (cloneGroup.id === starterPackage.group) {
          cloneGroup.selectedStarterPackage = group.starterPackages
            .find(item => item.name === starterPackage.name);
        }
        return cloneGroup;
      });
      if (isSelected) {
        doSelectStarterPackage(true, cloneListGroups, starterPackage);
      }

      setListStarterPackage(cloneListGroups);
    }
  };

  const makeId = () => {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    return array[0];
  };

  const getListPackageView = (groupPackages: StarterPackageGroup[]) => groupPackages
    .map(group => (
      <PackageItem
        key={makeId()}
        accountId={accountId}
        currentPackage={currentStarterPackage}
        starterPackageGroup={group}
        handleSelection={handlePackageSelection}
        handleItemSelection={handleChildPackageSelection}
      />
    ));

  const renderPackages = () => (
    <div id="starter-package-item" className={classes.listPackageContainer}>
      <ImageList
        className={classes.listPackage}
        cols={listGroupPackages.length < 3 ? 3 : listGroupPackages.length}
        ref={el => { listPackageRef.current = el; }}
      >
        {
          getListPackageView(listGroupPackages)
        }
      </ImageList>
    </div>
  );

  const getRemainingTimes = () => {
    if (currentKSESubscription === undefined) return 12;
    const expiredDate = new Date(currentKSESubscription.expiryDate);
    const distanceMonthDays = differenceInMonthDays(expiredDate, (new Date()));
    if (distanceMonthDays.months < 0) {
      return `${distanceMonthDays.days} days`;
    }
    if (distanceMonthDays.months > 1) {
      return `${distanceMonthDays.months} months`;
    }
    return '1 month';
  };

  const renderProrationDescription = () => {
    if (currentKSESubscription === undefined) {
      return (
        <div id="starter-package-description">
          <p className={classes.textProratedSubTitle}><FormattedMessage id="starter.prorated.subtitle" /></p>
        </div>
      );
    }

    return (
      <div id="starter-package-description">
        <p className={classes.textProratedTitle}>
          <FormattedMessage
            id="starter.prorated.existing.title"
            values={{
              time: getRemainingTimes(),
            }}
          />
        </p>
        <p className={classes.textProratedSubTitle}><FormattedMessage id="starter.prorated.existing.subtitle" /></p>
      </div>
    );
  };

  return (
    <>
      <div id="starter-package-offering-section" className={classes.rootContainer}>
        {/* Banner */}
        <PromotionBanner
          title="promote_banner.starter_plan"
          percent="promote_banner.30"
        />
        {/* Starter Package List */}
        <Paper
          elevation={0}
          className={classes.defaultContainer}
        >
          {renderProrationDescription()}
          {renderPackages()}
        </Paper>
      </div>
    </>
  );
};

export default StarterPlanOfferingSection;
