import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import Button from '@mui/material/Button';

import Link from '@mui/material/Link';
import { useSelector } from 'react-redux';
import { ReactComponent as InfoCircle } from '../../../icons/info-circle-red.svg';
import { retryPayment } from '../../../../layout/routes';
import { useNavigate } from '../../../../routes';
import { CUTOMER_SUPPORT_EMAIL } from '../../utils';
import { fromInvoices } from '../../../../store/rootReducer';
import { OrganizationFeature } from '../../../../models';

const useStyles = makeStyles((theme => ({
  rootContainer: {
    backgroundColor: '#ffffff',
    margin: theme.spacing(2, 2),
    borderRadius: theme.spacing(0.5),
  },
  defaultContainer: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0, 2, 3, 2),
  },
  sectionTitle: {
    padding: theme.spacing(1, 3),
    fontSize: theme.spacing(2.5),
    fontWeight: 600,
    color: '#d24720',
    alignItems: 'center',
    display: 'flex',
  },
  sectionTitleIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  sectionTitleText: {
    marginLeft: theme.spacing(2),
  },
  sectionDesc: {
    fontSize: theme.spacing(1.75),
    color: '#22283c',
    lineHeight: 1.5,
    paddingTop: theme.spacing(1.5),
  },
  expiryDateText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  actionBtn: {
    paddingTop: theme.spacing(2.5),
  },
  retryPaymentBtn: {
    color: '#fff',
  },
  contactCustomerBtn: {
    marginLeft: theme.spacing(2),
    color: '#22283c',
  },
})));

interface RetryPaymentProps {
  accountId: number,
}
const RetryPayment = (props: RetryPaymentProps) => {
  const classes = useStyles();
  const { replaceQuery, navigate } = useNavigate();
  const { accountId } = props;

  const nearestPastDueSub = useSelector(fromInvoices.selectNearestPastDueSubscription);

  const renderExpiryDate = () => (
    <span className={classes.expiryDateText}>
      <FormattedMessage
        id="subscription.management.retry.payment.expiry_date"
        values={{
          expiryDate: moment(nearestPastDueSub?.startDate).format('MMM Do'),
        }}
      />
    </span>
  );

  const renderGracePeriod = () => moment(nearestPastDueSub?.startDate).add(7, 'd').format('MMM Do');

  const renderSubscriptionName = () => {
    if (nearestPastDueSub?.subscriptions
      && nearestPastDueSub?.subscriptions?.length > 0) {
      return OrganizationFeature.getFeatureFullName(nearestPastDueSub.subscriptions[0].feature);
    }
    return undefined;
  };

  const goToRetryPayment = () => {
    navigate(retryPayment.path, replaceQuery({ accountId }));
  };

  return (
    <>
      <div id="retry-payment-section" className={classes.rootContainer}>
        <Typography variant="h3" className={classes.sectionTitle} id="retry-payment-title">
          <InfoCircle id="retry-payment-title-icon" className={classes.sectionTitleIcon} />
          <span className={classes.sectionTitleText} id="retry-payment-title-text">
            <FormattedMessage id="subscription.management.retry.payment.title" />
          </span>
        </Typography>
        <Paper
          elevation={0}
          className={classes.defaultContainer}
        >
          <div id="retry-payment-desc">
            <Typography variant="body1" className={classes.sectionDesc}>
              <FormattedMessage
                id="subscription.management.retry.payment.desc.first"
                values={{
                  subscriptionName: renderSubscriptionName(),
                  expiryDate: renderExpiryDate(),
                }}
              />
            </Typography>
            <Typography variant="body1" className={classes.sectionDesc}>
              <FormattedMessage
                id="subscription.management.retry.payment.desc.second"
                values={{
                  gracePeriod: renderGracePeriod(),
                }}
              />
            </Typography>
          </div>
          <div id="retry-payment-action" className={classes.actionBtn}>
            <Button
              id="payment-method-btn"
              className={classes.retryPaymentBtn}
              variant="contained"
              color="primary"
              onClick={goToRetryPayment}
            >
              <FormattedMessage id="subscription.management.retry.payment.btn" />
            </Button>
            <Button
              id="subscription-btn"
              className={classes.contactCustomerBtn}
              variant="contained"
              component={Link}
              href={CUTOMER_SUPPORT_EMAIL}
            >
              <FormattedMessage id="subscription.management.retry.payment.contact_sale.btn" />
            </Button>
          </div>
        </Paper>
      </div>
    </>
  );
};

export default RetryPayment;
