import { alpha } from '@mui/material/styles';
import { useTheme } from '@mui/styles';
import { ChartOptions, Plugin } from 'chart.js';
import findLast from 'lodash/fp/findLast';
import find from 'lodash/fp/find';
import { useEffect, useMemo, useRef } from 'react';
import { Chart } from 'react-chartjs-2';
import { IntlShape, useIntl } from 'react-intl';
import { MetricData, TestCloudUsage } from '../../models';
import { IntervalUnit } from '../../models/query';
import { concurrentSessionChartTooltip } from './ConcurrentSessionChartTooltip';
import { computeTimeIntervals } from './utils';

interface ConcurrentSessionChartProps {
  fallbackUsages: TestCloudUsage[] | null;
  concurrentSessionData: MetricData[] | null;
  adjustedConcurrentSessionData: MetricData[] | null;
  startDate: Date | undefined;
  endDate: Date | undefined;
}

interface ConcurrentSessionChartPoint {
  xLabel: string;
  concurrentSessions: number;
  maxConcurrentSessions: number;
}

/**
 * Compute data to show on chart. We should produce:
 * - the list of labels for the Ox axis (`xLabels`).
 * - two datasets for two lines (max concurrent sessions reached and allowed).
 *
 * For max concurrent sessions allowed, we pull them straight from the usage records.
 *   For days (or in general, periods) in which no usage records were found (hence, no metric data),
 *   we will search in `maxConcurrentSessionData` and use the data on the last day
 *   which **was before** and **had metric data** as a "fallback point".
 *
 * For max concurrent sessions reached, we will need to look at both `concurrentSessionData`
 * and `adjustedConcurrentSessionData` for each day and choose the greater value between the two.
 *   For days in which no metric data was found, we will use the "fallback point" trick as said.
 *
 * The `fallbackUsage` will help us in case the metric data of the first days shown on the
 * chart needs a fallback point.
 *
 * @param fallbackUsages TestCloudUsage records that can be used as fallback point for periods
 * during which no metric data was found.
 * @param concurrentSessionData max of field `concurrentSessions` among TestCloudUsage records,
 * grouped by time intervals
 * @param adjustedConcurrentSessionData 1 + max of field `concurrentSessions` among TestCloudUsage
 * records, where `eventType` is SESSION_ENDED, grouped by time intervals
 * @param startDate start of the chart, typically at the begin of a day.
 * @param endDate end of the chart, typically the time of viewing.
 * @param intl for translation purposes.
 * @returns the data object that can be passed to the Chart (chart.js) component.
 */
const computeData = (
  fallbackUsages: TestCloudUsage[] | null,
  concurrentSessionData: MetricData[] | null,
  adjustedConcurrentSessionData: MetricData[] | null,
  startDate: Date | undefined,
  endDate: Date | undefined,
  intl: IntlShape,
) => {
  const timeIntervals = (!startDate || !endDate) ? [] : computeTimeIntervals(
    startDate,
    endDate,
    1,
    IntervalUnit.DAYS,
  );
  const data: ConcurrentSessionChartPoint[] = (!startDate || !endDate)
    ? []
    : timeIntervals.map((date, i) => {
      const nextDate = i + 1 < timeIntervals.length ? timeIntervals[i + 1] : endDate;
      const fallbackConcurrent = findLast(
        it => new Date(it.timestamp).getTime() <= date.getTime(),
        fallbackUsages,
      );
      const fallbackMaxConcurrent = findLast(
        it => new Date(it.timestamp).getTime() < nextDate.getTime(),
        fallbackUsages,
      );
      const concurrent = find(
        it => new Date(it.startTime).getTime() === date.getTime(),
        concurrentSessionData,
      )?.value;
      const adjustedConcurrent = find(
        it => new Date(it.startTime).getTime() === date.getTime(),
        adjustedConcurrentSessionData,
      )?.value;
      return {
        xLabel: intl.formatDate(date, { month: 'short', day: 'numeric' }),
        concurrentSessions: Math.max(
          concurrent ?? fallbackConcurrent?.concurrentSessions ?? 0,
          adjustedConcurrent ?? 0,
        ),
        maxConcurrentSessions: fallbackMaxConcurrent?.maxConcurrentSessions ?? 0,
      };
    });
  return {
    labels: data.map(it => it.xLabel),
    datasets: [
      {
        label: intl.formatMessage({ id: 'testcloud_dashboard.usage_summary.concurrent_sessions' }),
        data: data.map(it => it.concurrentSessions),
        borderColor: '#5BCDDA',
        backgroundColor: alpha('#5BCDDA', 0.2),
        fill: 'start',
      },
      {
        label: intl.formatMessage({ id: 'testcloud_dashboard.usage_summary.max_concurrent_sessions' }),
        data: data.map(it => it.maxConcurrentSessions),
        borderColor: '#B41400',
        backgroundColor: '#B41400',
        fill: 'none',
      },
    ],
  };
};

const ConcurrentSessionChart = (props: ConcurrentSessionChartProps) => {
  const {
    fallbackUsages,
    concurrentSessionData,
    adjustedConcurrentSessionData,
    startDate,
    endDate,
  } = props;
  const intl = useIntl();
  const theme = useTheme();
  const xIndexHovered = useRef<number | undefined>(undefined);
  const chartRef = useRef();
  const data = useMemo(() => computeData(
    fallbackUsages,
    concurrentSessionData,
    adjustedConcurrentSessionData,
    startDate,
    endDate,
    intl,
  ), [
    fallbackUsages,
    concurrentSessionData,
    adjustedConcurrentSessionData,
    startDate,
    endDate,
    intl,
  ]);
  const plugins: Plugin[] = [{
    id: 'mouseOutHandler',
    afterEvent(chart, args) {
      if (args.event.type === 'mouseout') {
        xIndexHovered.current = undefined;
        chart.update('none');
      }
    },
  }];
  const options = useMemo<ChartOptions>(() => ({
    plugins: {
      filler: {
        propagate: false,
      },
      legend: {
        position: 'bottom',
        align: 'end',
        labels: {
          usePointStyle: true,
          pointStyle: 'line',
        },
      },
      tooltip: {
        enabled: false,
        external: concurrentSessionChartTooltip(
          data.labels,
          data.datasets[0].data,
          data.datasets[1].data,
          intl,
          theme,
        ),
        callbacks: {
          label: tooltipContext => [
            tooltipContext.dataIndex.toString(),
            tooltipContext.datasetIndex.toString(),
          ],
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        offset: true,
      },
      y: {
        grid: {
          display: true,
          borderDash: [10, 2],
        },
        ticks: {
          beginAtZero: true,
          stepSize: 1,
        },
        suggestedMin: 0,
        title: {
          display: true,
          text: 'Sessions',
          padding: {
            bottom: 20,
          },
          font: {
            weight: `${theme.typography.fontWeightBold}`,
          },
        },
        afterDataLimits: axis => {
          axis.max += 1;
          if (axis.min < 0) axis.min = 0;
        },
      },
    },
    hover: {
      mode: 'dataset',
    },
    onHover(_, activeElement, chart) {
      const xIndex = activeElement[0]?.datasetIndex;
      if (xIndex !== xIndexHovered.current) {
        xIndexHovered.current = xIndex;
        chart.update('none');
      }
    },
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 3,
    elements: {
      point: {
        radius: 3,
      },
      line: {
        borderWidth: 1,
      },
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [data]);

  useEffect(() => (
    () => {
      // @ts-ignore
      chartRef?.current?.update('none'); // update chart after unmounting to reset the tooltip
    }
  ), []);

  return <Chart ref={chartRef} type="line" options={options} data={data} plugins={plugins} />;
};

export default ConcurrentSessionChart;
