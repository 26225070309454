import { Config } from '../../models/config';
import { TestOpsSubscriptionSource } from '../../models/testOpsSubscriptionSource';
import { OrganizationFeature, SubscriptionSource, UsagePlan } from '../../models/index';

export interface UserLicense {
  feature: OrganizationFeature;
  quota: number;
  usagePlan?: UsagePlan;
  projects?: number;
  source?: SubscriptionSource;
}

export const getRedirectPath = (
  product: OrganizationFeature,
  orgId: number,
  config: Config | undefined,
) => {
  switch (product) {
    case OrganizationFeature.KSE:
      return `${process.env.REACT_APP_ADMIN_URL}/organization/${orgId}/admin/license_keys/kse`;
    case OrganizationFeature.UNLIMITED_KSE:
      return `${process.env.REACT_APP_ADMIN_URL}/organization/${orgId}/admin/license_keys/unlimited_kse`;
    case OrganizationFeature.PER_USER_KSE:
      return `${process.env.REACT_APP_ADMIN_URL}/organization/${orgId}/admin/license_keys/per_user_kse`;
    case OrganizationFeature.ENGINE:
      return `${process.env.REACT_APP_ADMIN_URL}/organization/${orgId}/admin/license_keys/engine`;
    case OrganizationFeature.UNLIMITED_ENGINE:
      return `${process.env.REACT_APP_ADMIN_URL}/organization/${orgId}/admin/license_keys/unlimited_engine`;
    default:
      return `${config?.testOpsPublicUrl}/organization/${orgId}/home`;
  }
};

export const UNLIMITED_EXECUTIONS = 9999999;
export const DEFAULT_EXECUTIONS = 2000;
export const kRELicenses = [
  OrganizationFeature.UNLIMITED_ENGINE,
  OrganizationFeature.ENGINE,
  OrganizationFeature.UNLIMITED_ENGINE_OFFLINE,
];
export const kSELicenses = [
  OrganizationFeature.KSE,
  OrganizationFeature.UNLIMITED_KSE,
  OrganizationFeature.PER_USER_KSE,
  OrganizationFeature.PER_USER_KSE_OFFLINE,
];

export const testCloudLicenses = [
  OrganizationFeature.TESTCLOUD_MINUTE,
  OrganizationFeature.TESTCLOUD_SESSION_WEB,
  OrganizationFeature.TESTCLOUD_FREE_TRIAL,
  OrganizationFeature.TESTCLOUD_SESSION_WEB_DESKTOP,
  OrganizationFeature.TESTCLOUD_SESSION_CROSS_BROWSER,
  OrganizationFeature.TESTCLOUD_SESSION_MOBILE_APP,
];

export const recorderLicenses = [
  OrganizationFeature.RECORDER,
];

export const testOpsPlatformLicense = [
  OrganizationFeature.TESTOPS_PLATFORM,
];

export const convertSubscriptionSource = (
  testOpsSubscriptionSource: TestOpsSubscriptionSource | undefined,
) => {
  switch (testOpsSubscriptionSource) {
    case TestOpsSubscriptionSource.RECURLY:
      return SubscriptionSource.RECURLY;
    case TestOpsSubscriptionSource.TRIAL_REQUEST:
      return SubscriptionSource.TRIAL;
    default:
      return undefined;
  }
};
