import Axios from 'axios';

import { TestOpsBillingInformation, DataResponse, GeneratedFields, PageableResponse } from '../models';
import { composeQuery, Query } from '../models/query';

export const upsertTestOpsBillingInformation = async (input: Required<Pick<TestOpsBillingInformation, 'organizationId'>> & Partial<Omit<TestOpsBillingInformation, 'organizationId' | GeneratedFields>> & Partial<Pick<TestOpsBillingInformation, 'id'>>) => (await Axios.put<DataResponse<TestOpsBillingInformation>>('/v1/billing-information', input)).data.data;

export const getTestOpsBillingInformation = async (...criteria: Query<TestOpsBillingInformation>[]) => (await Axios.get<PageableResponse<TestOpsBillingInformation>>('/v1/billing-information', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: composeQuery<TestOpsBillingInformation>(...criteria),
  data: {},
})).data.data;
