import Button from '@katalon-studio/katalon-ui/Button';
import { Dialog, DialogBody, DialogFooter, DialogHeader } from '@katalon-studio/katalon-ui/Dialog';
import Icon from '@katalon-studio/katalon-ui/Icon';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete, {
  AutocompleteRenderInputParams,
  createFilterOptions,
} from '@mui/material/Autocomplete';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { unwrapResult } from '@reduxjs/toolkit';
import clsx from 'clsx';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import some from 'lodash/some';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';
import filter from 'lodash/filter';
import {
  ReactComponent as UserGroupIcon,
} from '../../layout/icons/iconUserGroup.svg';
import LoadingProgress from '../../layout/LoadingProgress';
import {
  AccountUser,
  getAvatarSource,
  OrganizationRole,
  UserGroup,
} from '../../models';
import { useNotification } from '../../notification';
import {
  fromOrganizations,
  fromOrganizationUsers,
  fromTestOpsProject,
  useAppDispatch,
} from '../../store';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
  },
  dialog: {
    position: 'absolute',
    borderRadius: theme.spacing(0.75),
    minWidth: theme.spacing(96),
  },
  dlgHeader: {
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    width: '100%',
    fontSize: theme.spacing(2),
    fontWeight: 700,
    color: '#233145',
    display: 'flex',
    marginBottom: theme.spacing(0),
  },
  dlgTitle: {
    flexGrow: 2,
    fontWeight: 500,
    fontSize: theme.spacing(3),
  },
  dlgContent: {
    width: '100%',
  },
  boxSpacing: {
    height: theme.spacing(2),
  },
  alertBanner: {
    display: 'flex',
    padding: theme.spacing(2),
    backgroundColor: '#f0f4fe',
    color: 'rgba(0, 0, 0, 0.9)',
    width: '100%',
    height: theme.spacing(9),
    marginBottom: theme.spacing(1),
    justifyContent: 'flex-start',
    '& p': {
      fontSize: theme.spacing(1.75),
      fontWeight: 'normal',
    },
  },
  confirmBanner: {
    marginBottom: theme.spacing(2),
    height: `${theme.spacing(6.75)} !important`,
  },
  defaultIcon: {
    fontSize: theme.spacing(2),
    color: '#598ef9',
  },
  actionItemIcon: {
    marginRight: theme.spacing(1),
  },
  textFieldTitle: {
    width: '100%',
    marginBottom: theme.spacing(1),
    display: 'flex',
    '& p': {
      fontSize: theme.spacing(1.75),
      fontWeight: 500,
      lineHeight: theme.spacing(4.75),
    },
  },
  emailAutocomplete: {
    minHeight: theme.spacing(6.75),
  },
  emailTextField: {
    '& .MuiInputBase-root': {
      backgroundColor: 'unset',
      fontSize: theme.spacing(1.75),
      borderRadius: theme.spacing(0.75),
    },
  },
  emailTextFieldError: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#d24720',
    },
  },
  emailInput: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  avatarOption: {
    marginRight: theme.spacing(1),
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  emailOption: {
    fontSize: theme.spacing(1.75),
  },
  userGroupId: {
    width: '10%',
    textAlign: 'left',
    display: 'inline-block',
  },
  optionDropdown: {
    width: '80%',
  },
  addEmailIcon: {
    fontSize: theme.spacing(1.5),
    color: 'rgba(104, 109, 128, 0.54)',
    marginRight: theme.spacing(1),
  },
  emailChip: {
    margin: theme.spacing(0.5, 0.5),
    maxWidth: 200,
    borderRadius: theme.spacing(2),
    backgroundColor: 'rgba(104, 109, 128, 0.08)',
    '& span': {
      fontSize: theme.spacing(1.75),
    },
    '& .MuiChip-deleteIcon': {
      display: 'flex',
      fontSize: `${theme.spacing(1.75)} !important`,
      width: theme.spacing(1.75),
      height: theme.spacing(1.75),
      color: 'rgba(104, 109, 128, 0.54) !important',
      marginLeft: theme.spacing(0.625),
      marginRight: theme.spacing(0.625),
    },
  },
  emailChipInvalid: {
    backgroundColor: '#FFF',
    border: '1px solid rgba(210, 71, 32, 0.5)',
    '& span': {
      color: '#D24720',
    },
    '& .MuiChip-deleteIcon': {
      color: '#D24720B3 !important',
    },
  },
  emailErrorContainer: {
    height: theme.spacing(2.5),
    paddingLeft: theme.spacing(1.5),
    marginTop: theme.spacing(0.375),
  },
  emailInvalidError: {
    color: '#d24720',
    fontSize: theme.spacing(1.5),
    fontWeight: 'normal',
  },
  roleSelect: {
    height: theme.spacing(7),
  },
  roleItemText: {
    '& > span': {
      fontWeight: 'normal !important',
      fontSize: `${theme.spacing(1.75)} !important`,
      color: '#284169 !important',
    },
  },
  menuPaper: {
    maxHeight: theme.spacing(24),
    maxWidth: theme.spacing(45),
  },
  projectsSelect: {
    flex: 1,
    height: theme.spacing(7),
  },
  projectsSelectLabel: {
    '& .MuiSelect-select': {
      padding: theme.spacing(1.125),
    },
  },
  projectSelectQuickAction: {
    backgroundColor: '#FFFFFF',
    height: theme.spacing(4),
    fontWeight: 600,
    color: '#1847ca',
    paddingLeft: theme.spacing(3),
    '& > span': {
      fontWeight: 'normal !important',
      fontSize: `${theme.spacing(1.75)} !important`,
    },
  },
  projectsRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 0.5,
    overflow: 'auto',
    maxHeight: theme.spacing(8),
  },
  projectChip: {
    float: 'left',
    margin: theme.spacing(0.25),
    maxWidth: 200,
  },
  projectLabel: {
    fontSize: theme.spacing(1.75),
  },
  projectSelectedItem: {
    backgroundColor: '#FFFFFF !important',
    color: '#284169 !important',
  },
  projectItemCheckbox: {
    minWidth: theme.spacing(4),
    width: theme.spacing(4),
    height: theme.spacing(4),
    padding: 0,
  },
  projectItemText: {
    '& > span': {
      fontWeight: 'normal !important',
      fontSize: `${theme.spacing(1.75)} !important`,
      color: '#284169 !important',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  dlgActionButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 3, 3, 3),
  },
  buttonSpacing: {
    width: theme.spacing(1),
  },
  cancelButton: {
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
  },
  disableBtn: {
    color: 'rgba(104, 109, 128) !important',
    backgroundColor: 'rgba(104, 109, 128, 0.24) !important',
  },
  activeBtn: {
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
    marginLeft: '0 !important',
  },
  hiddenText: {
    opacity: '0',
    height: theme.spacing(0),
    width: theme.spacing(0),
  },
  icon: {
    height: theme.spacing(2.25),
    width: theme.spacing(2.25),
    lineHeight: 2.5,
  },
  requireSpan: {
    color: '#e53935',
    marginLeft: theme.spacing(0.5),
  },
  switch: {
    marginTop: theme.spacing(0),
    marginRight: theme.spacing(-0.75),
    marginLeft: theme.spacing(-0.75),
  },
  helperText: {
    fontSize: `${theme.spacing(1.5)} !important`,
    color: '#808b9a !important',
    fontWeight: 'normal !important',
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
  },
  helperError: {
    color: '#d24720 !important',
    fontSize: `${theme.spacing(1.5)} !important`,
    fontWeight: 'normal !important',
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
  },
}));

interface CreateOrganizationPopupProps {
  isOpen: boolean;
  accountId: number;
  accountUsers: AccountUser[];
  multiProjectEnabled?: boolean;
  onCloseDialog: () => void;
  onSuccessCallback: (id: number) => void;
}

interface InviteUser {
  id?: number;
  email: string;
  avatar?: string;
  label?: string;
  isValid?: boolean;
  isValidDomain?: boolean;
}

const CreateOrganizationPopup = (props: CreateOrganizationPopupProps) => {
  const {
    isOpen,
    accountUsers,
    accountId,
    onCloseDialog,
    onSuccessCallback,
  } = props;

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon color="primary" fontSize="small" />;
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [focus, setFocus] = useState(false);
  const [inputEmail, setInputEmail] = useState('');
  const { sendError } = useNotification();
  const [countInvalidEmail, setCountInvalidEmail] = useState(0);
  const [countInvalidDomainEmail, setCountInvalidDomainEmail] = useState(0);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reloadCount, setReloadCount] = useState(0);
  const [orgName, setOrgName] = useState('');
  const [projectName, setProjectName] = useState('First Project');
  const [canSubmit, setCanSubmit] = useState(false);
  const [canSubmitProjectName, setCanSubmitProjectName] = useState(false);
  const [memberSwitch, setMemberSwitch] = useState(false);
  const [projectSwitch, setProjectSwitch] = useState(false);
  const emailFilter = createFilterOptions<InviteUser>();
  const [accountUserOptions, setAccountUserOptions] = useState<InviteUser[]>([]);
  const currentSelectedUserRef = useRef<any[]>([]);
  const filterInputRef = useRef<HTMLElement>(null);
  const [hide, setHide] = useState(false);
  const myRefName = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // TODO: remove later
    setLoading(false);
    setAccountUserOptions(map(accountUsers, it => ({
      id: it.id,
      email: it.email,
      avatar: it.avatar,
    } as InviteUser)));
  }, [accountUsers]);

  useEffect(() => {
    setCountInvalidEmail(
      currentSelectedUserRef.current.filter(it => isInviteUser(it) && !it.isValid).length,
    );
    setCountInvalidDomainEmail(
      currentSelectedUserRef.current.filter(
        it => isInviteUser(it) && it.isValid && !it.isValidDomain,
      ).length,
    );
  }, [currentSelectedUserRef.current]);

  useEffect(() => {
    setDisableSubmit(
      !canSubmit
      || (memberSwitch && currentSelectedUserRef.current.length === 0)
      || (projectSwitch && (projectName.length === 0 || !canSubmitProjectName)),
    );
  }, [
    canSubmit,
    canSubmitProjectName,
    currentSelectedUserRef.current,
    memberSwitch,
    orgName,
    projectSwitch,
    projectName,
  ]);

  useEffect(() => {
    if (projectName.trim() === '') {
      setProjectName('First Project');
    }
    setCanSubmitProjectName(projectName.trim() !== '');
  }, [projectSwitch]);

  const closeDialog = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onCloseDialog();
  };

  const getUniqueAndValidateUserEmails = (users: any[]) => {
    const userGroups = uniqBy(users.filter(it => isUserGroup(it)), 'name');
    const uniqueUserEmails = uniqBy(users.filter(it => isInviteUser(it)), 'email');
    return [...userGroups, ...uniqueUserEmails.map(it => ({
      ...it,
      isValid: !!yup.string()
        .matches(/^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)
        .email().isValidSync(it.email),
      isValidDomain: true,
      // isValidDomain: !isOrganizationBillingManagerOrMember(currentOrgUser.role)
      //   || (
      //     notPublicDomain(it.email)
      //     && ownerEmail?.split('@')[1] === it.email.split('@')[1]
      //   ),
    }))];
  };

  const getListOptionFromEmails = (newValue: string) => {
    const enteredEmails = newValue.split(/[ ,\n\t]+/).filter(it => it.trim().length);
    const newEmails = uniq(enteredEmails);
    return map(newEmails, email => {
      const existUser = find(accountUserOptions, { email });
      return existUser ?? {
        email,
      };
    });
  };

  const handleEnterEmails = (newValue: string) => {
    handleClearOption();
    const newEmails = getListOptionFromEmails(newValue);
    currentSelectedUserRef.current = getUniqueAndValidateUserEmails([
      ...currentSelectedUserRef.current,
      ...newEmails,
    ]);
  };

  const handleClearOption = () => {
    setInputEmail('');
  };

  const onEmailInputChange = (event: { target: { value: any; name: any; }; }) => {
    const { value } = event.target;
    setInputEmail(value);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === ' ' || event.key === 'Alt' || event.key === 'Tab' || event.key === 'Enter' || event.key === ',') {
      event.preventDefault();
      handleEnterEmails(inputEmail);
    }
  };

  const handleChangeOrganizationName = (event: { target: { value: any; name: any; }; }) => {
    const { value } = event.target;
    if (value.trim().length <= 255) {
      setOrgName(value);
      setCanSubmit(value.trim() !== '');
    } else {
      const value2 = value.substring(0, 255);
      setOrgName(value2);
      setCanSubmit(value2.trim() !== '');
    }
  };

  const handleChangeProjectName = (event: { target: { value: any; name: any; }; }) => {
    const { value } = event.target;
    if (value.trim().length <= 255) {
      setProjectName(value);
      setCanSubmitProjectName(value.trim() !== '');
    } else {
      const value2 = value.substring(0, 255);
      setProjectName(value2);
      setCanSubmitProjectName(value.trim() !== '');
    }
  };

  const handleClickAway = (event: any) => {
    const e = (event.target as HTMLElement);
    if ((filterInputRef.current
      && filterInputRef.current.contains(event.target as HTMLElement))
      || e.tagName === 'LI'
      || e.tagName === 'UL'
      || e.tagName === 'P'
      || e.tagName === 'INPUT'
      || e.tagName === 'IMG'
      || (e.tagName === 'DIV'
        && (
          e.className.includes('avatarContainer')
          || e.className.includes('option')
        )
      )) {
      if (e.tagName === 'svg' || e.tagName === 'path') {
        // eslint-disable-next-line no-console
        console.log('---check focus----');
        // @ts-ignore
        myRefName.current.focus();
        setFocus(!focus);
      } else if (e.id !== 'organization.management.dialog.input.organization.name'
        && e.id !== 'organization.management.dialog.input.project'
        && e.id !== 'organization.management.dialog.member.switch'
        && e.id !== 'organization.management.dialog.project.switch'
        && e.id !== 'organization.management.dialog.invite.member.to.organization'
        && e.id !== 'organization.management.dialog.input.project.title'
        && e.id !== 'organization.management.dialog.member.switch'
        && e.id !== 'organization.management.dialog.project.switch'
      ) {
        setFocus(true);
      }
    } else {
      // @ts-ignore
      myRefName.current.blur();
      setFocus(false);
    }
    event.stopPropagation();
  };

  const renderEmailInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      id="organization.management.dialog.input.email.user.group"
      variant="outlined"
      fullWidth
      name="name"
      onClick={handleClickAway}
      className={classes.emailTextField}
      placeholder={
        isEmpty(currentSelectedUserRef.current)
          ? intl.formatMessage({
            id: 'organization.management.dialog.input.email.user.group.placeholder',
          })
          : undefined
      }
      maxRows={1}
      sx={{
        '& .MuiInputBase-input': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      }}
      value={currentSelectedUserRef.current}
      onChange={onEmailInputChange}
      onKeyDown={onKeyDown}
    />
  );

  const renderEmailOptionLabel = (option: any) => {
    if (typeof option === 'string') {
      return option;
    }
    if (isInviteUser(option)) {
      return option.label ?? option.email;
    }
    if (isUserGroup(option)) {
      return option.name;
    }
    if (option.label) {
      return option.label;
    }
    return option.email;
  };

  function isUserGroup(object: any): object is UserGroup {
    return 'name' in object;
  }

  function isInviteUser(object: any): object is InviteUser {
    return 'email' in object;
  }

  const handleDeleteOption = (option: any) => () => {
    if (isUserGroup(option)) {
      currentSelectedUserRef.current = currentSelectedUserRef.current
        .filter(it => it.name !== option.name);
    }
    if (isInviteUser(option)) {
      currentSelectedUserRef.current = currentSelectedUserRef.current
        .filter(it => it.email !== option.email);
    }
    setReloadCount(reloadCount + 1);
    handleUserGroupTagOut();
  };

  const handleUserGroupTagOut = () => {

  };

  const handleSubmitCreateOrganization = async () => {
    setLoading(true);
    setHide(true);
    await dispatch(fromOrganizations.doCreateOrganization({ name: orgName, accountId }))
      .then(unwrapResult)
      .then(async ({ organization }) => {
        const promises: Promise<any>[] = [];
        currentSelectedUserRef.current.forEach(existAccountUser => {
          if (isInviteUser(existAccountUser)) {
            promises.push(dispatch(fromOrganizationUsers.doCreateOrganizationUser({
              organizationId: organization.id,
              user: {
                email: existAccountUser.email,
              },
              role: OrganizationRole.MEMBER,
            })));
          }
        });
        const results = await Promise.all(promises);
        const totalFailed = filter(results, r => r.payload === undefined).length;

        if (totalFailed > 0) {
          sendError({
            message: intl.formatMessage(
              { id: 'organization.management.dialog.add.users.failed' },
              { count: totalFailed },
            ),
            hasDismissIcon: true,
          });
        }
        return organization;
      })
      .then(organization => {
        if (projectSwitch && projectName.length !== 0) {
          dispatch(fromTestOpsProject.doCreateProject({ projectName, orgId: organization.id }))
            .then(unwrapResult)
            .catch(() => {
              sendError({
                message: intl.formatMessage({ id: 'organization.management.dialog.create.project.failed' }),
                hasDismissIcon: true,
              });
            });
        }

        return organization;
      })
      .then(organization => {
        onCloseDialog();
        onSuccessCallback(organization.id);
        setLoading(false);
      })
      .catch(() => {
        sendError({
          message: intl.formatMessage({ id: 'organization.management.dialog.create.failed' }),
          hasDismissIcon: true,
          hasTryAgain: true,
          handleTryAgain: () => {
            setHide(false);
          },
          handleDismissNotify: () => {
            onCloseDialog();
          },
        });
      })
      .finally(() => setLoading(false));
  };

  // @ts-ignore
  return (
    <>
      <Dialog
        classes={{ paper: classes.dialog }}
        open={isOpen && !hide}
        maxWidth="sm"
        placeholder="center"
        fullWidth
      >
        <DialogHeader
          id="organization.management.dialog.create.new.organization.header"
          className={classes.dlgHeader}
          onClick={closeDialog}
        >
          <Box className={classes.dlgTitle}>
            <TextField inputRef={myRefName} autoFocus={!focus} className={classes.hiddenText} />
            <FormattedMessage id="organization.management.dialog.create.new.organization.title" />
          </Box>
        </DialogHeader>
        <DialogBody
          id="organization.management.dialog.create.new.organization.body"
          className={classes.dlgContent}
        >
          <Box className={classes.textFieldTitle}>
            <Typography id="organization.management.dialog.create.new.organization.name">
              <FormattedMessage id="organization.management.dialog.create.new.organization.name" />
              <span className={classes.requireSpan}>*</span>
            </Typography>
          </Box>
          <TextField
            id="organization.management.dialog.input.organization.name"
            variant="outlined"
            fullWidth
            autoFocus
            name="name"
            value={orgName}
            inputRef={nameRef}
            onClick={() => {
              // eslint-disable-next-line no-console
              console.log('----onClick-organization.name---');
            }}
            className={classes.emailTextField}
            placeholder={intl.formatMessage({
              id: 'organization.management.dialog.input.organization.placeholder',
            })}
            maxRows={1}
            inputProps={{
              maxLength: 255,
            }}
            sx={{
              '& .MuiInputBase-input': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
            onChange={handleChangeOrganizationName}
            onKeyDown={() => {
              // eslint-disable-next-line no-console
              console.log('-----project name typing---');
            }}
          />
          <Typography className={classes.helperText}>
            <FormattedMessage id="organization.management.dialog.organization.name.full.helper" />
          </Typography>
          <Box className={classes.boxSpacing} />
          <Box className={classes.textFieldTitle}>
            <Switch
              id="organization.management.dialog.member.switch"
              className={classes.switch}
              checked={memberSwitch}
              onChange={() => { setMemberSwitch(!memberSwitch); }}
            />
            <Typography id="organization.management.dialog.invite.member.to.organization">
              <FormattedMessage id="organization.management.dialog.invite.member.to.organization" />
            </Typography>
          </Box>
          <Box sx={{ display: memberSwitch ? 'block' : 'none' }}>
            <ClickAwayListener onClickAway={handleClickAway}>
              <Autocomplete
                id="organization.management.dialog.create.new.organization.input_email_autocomplete"
                className={clsx(
                  classes.emailAutocomplete,
                  {
                    [classes.emailTextFieldError]: countInvalidEmail,
                  },
                )}
                ref={filterInputRef}
                key={`${currentSelectedUserRef.current}`}
                multiple
                selectOnFocus
                disableCloseOnSelect
                open={focus}
                onClose={() => setFocus(false)}
                options={Array.from(new Set([
                  ...accountUserOptions,
                ]))}
                isOptionEqualToValue={(option: any, value: any) => {
                  if (isInviteUser(option)) {
                    return option.email === value.email;
                  }
                  return option.name === value.name;
                }}
                // PopperComponent={PopperComponent}
                getOptionLabel={renderEmailOptionLabel}
                renderOption={(
                  props,
                  option,
                  { selected },
                ) => {
                  if (isUserGroup(option)) {
                    // eslint-disable-next-line
                    return (
                      <Box component="li" {...props} key={option.name}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          checked={selected}
                        />
                        <Box className={classes.avatarOption}>
                          <UserGroupIcon />
                        </Box>
                        <Box className={classes.optionDropdown}>
                          <Typography className={classes.emailOption}>{option.name}</Typography>
                        </Box>
                      </Box>
                    );
                  }
                  if (isInviteUser(option) && !('label' in option)) {
                    const { avatar, email } = option;
                    const avatarSrc = getAvatarSource(avatar);
                    return (
                      <Box component="li" {...props} key={email}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          checked={selected}
                        />
                        <Avatar className={classes.avatarOption} src={avatarSrc} />
                        <Typography className={classes.emailOption}>{email}</Typography>
                      </Box>
                    );
                  }
                  const { email, label } = option;
                  return (
                    <Box component="li" {...props} key={email}>
                      <Icon
                        name="fa-plus"
                        type="fa-solid"
                        className={classes.addEmailIcon}
                      />
                      <Typography className={classes.emailOption}>{label}</Typography>
                    </Box>
                  );
                }}
                filterOptions={(options, params) => {
                  // if (isUserGroup(options)) {
                  //   return [];
                  // } else if (isInviteUser(options[0])) {
                  const filtered = emailFilter(options, params);
                  const { inputValue } = params;
                  let isExisting = some(options, { email: inputValue });
                  if (!isExisting) {
                    // prevent push message
                    isExisting = some(accountUserOptions, { email: inputValue });
                  }
                  if (inputValue !== '' && !isExisting && !filtered.length) {
                    filtered.push({
                      email: inputValue,
                      label: intl.formatMessage({
                        id: 'organization.management.dialog.input_email_add_new',
                      }, { email: inputValue }),
                    });
                  }
                  return filtered;
                }}
                defaultValue={currentSelectedUserRef.current}
                onChange={(_, newValue, reason, details) => {
                  handleClearOption();
                  const option = details?.option;
                  if (isInviteUser(option)) {
                    // @ts-ignore
                    if (!option?.id && reason !== 'removeOption') {
                      // @ts-ignore
                      const newUserEmail = option?.email;
                      if (typeof option === 'string') {
                        const newUserEmails = getListOptionFromEmails(option);
                        currentSelectedUserRef.current = getUniqueAndValidateUserEmails([
                          ...currentSelectedUserRef.current,
                          ...newUserEmails,
                        ]);
                        return;
                      }
                      currentSelectedUserRef.current = getUniqueAndValidateUserEmails([
                        ...currentSelectedUserRef.current,
                        {
                          email: newUserEmail ?? '',
                        }]);
                    } else {
                      currentSelectedUserRef.current = getUniqueAndValidateUserEmails(
                        map(newValue, it => {
                          if (typeof it === 'string') {
                            return {
                              email: it,
                            };
                          }
                          return it;
                        }),
                      );
                    }
                  } else {
                    currentSelectedUserRef.current = getUniqueAndValidateUserEmails(
                      map(newValue, it => {
                        if (typeof it === 'string') {
                          return {
                            email: it,
                          };
                        }
                        return it;
                      }),
                    );
                    handleClearOption();
                  }
                }}
                inputValue={inputEmail}
                renderInput={renderEmailInput}
                renderTags={(tagValue, getTagProps) => map(tagValue, (option, index) => {
                  if (isInviteUser(option) && !('label' in option)) {
                    return (
                      <Tooltip title={option.email}>
                        <Chip
                          {...getTagProps({ index })}
                          label={option.email}
                          className={clsx(classes.emailChip, {
                            [classes.emailChipInvalid]: option.isValid === false
                            || option.isValidDomain === false,
                          })}
                          onDelete={handleDeleteOption(option)}
                          deleteIcon={(
                            <Icon
                              name="fa-circle-xmark"
                              type="fa-solid"
                            />
                          )}
                        />
                      </Tooltip>
                    );
                  }
                  const { user } = option;
                  return (
                    <Chip
                      {...getTagProps({ index })}
                      label={user.email}
                      className={clsx(classes.emailChip, {
                        [classes.emailChipInvalid]: option.isValid === false
                        || option.isValidDomain === false,
                      })}
                      key={option.user.email}
                      onDelete={handleDeleteOption(option)}
                      deleteIcon={(
                        <Icon
                          name="fa-circle-xmark"
                          type="fa-solid"
                        />
                      )}
                    />
                  );
                })}
                disableClearable
                componentsProps={{
                  popper: {
                    sx: {
                      zIndex: 1400,
                    },
                  },
                }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                {...(!focus ? {} : { renderTags: () => null })}
              />
            </ClickAwayListener>
            {
              !!countInvalidEmail && (
                <div className={classes.emailErrorContainer}>
                  <Typography className={classes.emailInvalidError} id="organization.management.dialog.create.new.organization.input_email_error">
                    {
                      intl.formatMessage(
                        { id: 'organization.management.dialog.create.new.organization.input_email_error' },
                        {
                          count: countInvalidEmail,
                        },
                      )
                    }
                  </Typography>
                </div>
              )
            }
            {
              !!countInvalidDomainEmail && (
                <div className={classes.emailErrorContainer}>
                  <Typography className={classes.emailInvalidError} id="organization.management.dialog_invalid_domain">
                    {
                      intl.formatMessage(
                        { id: 'organization.management.dialog.input_email_invalid_domain' },
                        {
                          count: countInvalidDomainEmail,
                          // domain: notPublicDomain(ownerEmail || '')
                          //   ? ' '.concat(ownerEmail?.split('@')[1] || '')
                          //   : '',
                        },
                      )
                    }
                  </Typography>
                </div>
              )
            }
          </Box>
          { memberSwitch ? <Box className={classes.boxSpacing} /> : '' }
          <Box className={classes.textFieldTitle}>
            <Switch
              id="organization.management.dialog.project.switch"
              className={classes.switch}
              checked={projectSwitch}
              onChange={() => { setProjectSwitch(!projectSwitch); }}
            />
            <Typography id="organization.management.dialog.input.project.title">
              <FormattedMessage id="organization.management.dialog.input.project.title" />
            </Typography>
          </Box>
          <Box sx={{ display: projectSwitch ? 'block' : 'none' }}>
            <TextField
              id="organization.management.dialog.input.project"
              variant="outlined"
              fullWidth
              name="name"
              value={projectName}
              className={classes.emailTextField}
              placeholder={
                isEmpty(currentSelectedUserRef.current)
                  ? intl.formatMessage({
                    id: 'organization.management.dialog.input.project.placeholder',
                  })
                  : undefined
              }
              maxRows={1}
              sx={{
                '& .MuiInputBase-input': {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
              }}
              onChange={handleChangeProjectName}
            />
            <Typography className={classes.helperText}>
              <FormattedMessage id="organization.management.dialog.project.name.full.helper" />
            </Typography>
          </Box>
        </DialogBody>
        <DialogFooter
          id="organization.management.dialog.create.new.organization.footer"
          className={classes.dlgActionButtons}
        >
          <Button
            id="organization.management.dialog.create.new.organization.button.cancel"
            className={classes.cancelButton}
            type="button"
            variant="text"
            size="small"
            color="primary"
            onClick={closeDialog}
          >
            <FormattedMessage id="common.cancel" />
          </Button>
          <Box className={classes.buttonSpacing} />
          <Button
            id="organization.management.dialog.create.new.organization.save"
            disabled={disableSubmit}
            className={clsx(classes.activeBtn, {
              [classes.disableBtn]: disableSubmit,
            })}
            type="submit"
            form="edit-account-name-form"
            color="primary"
            variant="contained"
            size="small"
            onClick={handleSubmitCreateOrganization}
          >
            <FormattedMessage id="common.create" />
          </Button>
        </DialogFooter>
      </Dialog>
      {
        loading && <LoadingProgress />
      }
    </>
  );
};

export default CreateOrganizationPopup;
