import { createTheme, responsiveFontSizes, StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import dark from './dark';
import light from './light';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// import useMediaQuery from '@mui/material/useMediaQuery';
const supportedThemes = { dark, light };

export interface SwitchThemeProps {
  currentTheme: keyof typeof supportedThemes;
  switchTheme: (theme: SwitchThemeProps['currentTheme']) => void;
  overrideTheme: (themes: typeof supportedThemes) => void;
}

const KEY = 'k1.theme';
const SwitchThemeContext = createContext<SwitchThemeProps>({} as any);

export const SwitchThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [themes, setThemes] = useState<typeof supportedThemes>(supportedThemes);
  const savedTheme = localStorage.getItem(KEY);
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [currentTheme, setTheme] = useState<SwitchThemeProps['currentTheme']>('light');
  const switchTheme: SwitchThemeProps['switchTheme'] = theme => {
    setTheme(theme);
    localStorage.setItem(KEY, theme);
  };

  useEffect(() => setTheme(
    savedTheme && savedTheme in themes
      ? savedTheme as SwitchThemeProps['currentTheme']
      : 'light',
  ), [savedTheme, themes]);

  const switchThemeValue = useMemo(
    () => ({ currentTheme, switchTheme, overrideTheme: setThemes }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <SwitchThemeContext.Provider value={switchThemeValue}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={responsiveFontSizes(createTheme(themes[currentTheme]))}>
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </SwitchThemeContext.Provider>
  );
};

export const useSwitchTheme = () => useContext(SwitchThemeContext);
