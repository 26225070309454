import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';
import { ReactElement, useState } from 'react';
import CancellationSurveyDialog from './CancellationSurveyDialog';
import { fromAccounts } from '../../../../store';
import { sendTrackingData } from '../../utils';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
  },
  title: {
    margin: theme.spacing(0, 0, 0),
  },
  cardTitleContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: theme.spacing(0, 1, 0, 1),
  },
  dialogAction: {
    padding: 16,
  },
  dialogContent: {
    margin: theme.spacing(0, 1, 0, 1),
  },
  goBackButton: {
    backgroundColor: '#f0f1f2',
    color: 'var(--text-alias-default-text-color-initial)',
  },
}));

interface CancelSubscriptionDialogProps {
  isOpenCancelDialog: boolean;
  handleCloseDialog: Function;
  handleCancelSubscription: Function;
  title: ReactElement;
  messageText: ReactElement;
  buttonTitleId: string;
  organizationId: number;
  email: string | null;
  accountId: number;
}

const CancelSubscriptionDialog = (props: CancelSubscriptionDialogProps) => {
  const classes = useStyles();
  const [isOpenCancellationSurveyDialog, setOpenCancelationSurveyDialog] = useState(false);
  const {
    isOpenCancelDialog,
    handleCloseDialog,
    handleCancelSubscription,
    title,
    messageText,
    buttonTitleId,
    organizationId,
    email,
    accountId,
  } = props;

  const account = useSelector(fromAccounts.selectAccountById(Number(accountId)))!!;

  const onCloseButtonClick = () => {
    handleCloseDialog();
    sendTrackingData(
      'your_subs_cancellation_rejected',
      account?.id!!,
      organizationId,
    );
  };

  const onCancelTrialClick = () => {
    const cancel = async () => {
      handleCloseDialog();
      setOpenCancelationSurveyDialog(true);
    };
    // eslint-disable-next-line no-console
    cancel().catch(console.error);
    sendTrackingData(
      'your_subs_cancellation_confirmed',
      account?.id!!,
      organizationId,
    );
  };

  const onCompleteSurvey = async () => {
    setOpenCancelationSurveyDialog(false);
    handleCancelSubscription();
  };

  return (
    <>
      <Dialog id="cancel-subscription-dialog" open={isOpenCancelDialog} maxWidth="md">
        <div className={classes.card}>
          <div className={classes.cardTitleContent}>
            <DialogTitle className={classes.title}>
              {title}
            </DialogTitle>
            <IconButton
              id="close-icon"
              aria-label="close"
              onClick={onCloseButtonClick}
              edge="end"
              size="small"
            >
              <CloseIcon fontSize="medium" />
            </IconButton>
          </div>
        </div>
        <DialogContent className={classes.dialogContent}>
          {messageText}
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button id="close-btn" className={classes.goBackButton} size="medium" variant="contained" onClick={onCloseButtonClick}>
            <FormattedMessage id="subscriptions.testops_platform.cancel.go_back" />
          </Button>
          <Button id="cancel-subscription-btn" color="secondary" size="medium" variant="contained" onClick={onCancelTrialClick}>
            <FormattedMessage id={buttonTitleId} />
          </Button>
        </DialogActions>
      </Dialog>
      <CancellationSurveyDialog
        isOpenCancellationSurveyDialog={isOpenCancellationSurveyDialog}
        onCompleteSurvey={onCompleteSurvey}
        surveyUrl={`${process.env.REACT_APP_CANCELLATION_SURVEY_URL}&email=${email}&orgId=${organizationId}`}
      />
    </>
  );
};

export default CancelSubscriptionDialog;
