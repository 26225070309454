import { FormattedMessage, useIntl } from 'react-intl';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Button from '@katalon-studio/katalon-ui/Button';
import { Dialog, DialogBody, DialogFooter, DialogHeader } from '@katalon-studio/katalon-ui/Dialog';
import { unwrapResult } from '@reduxjs/toolkit';
import LoadingProgress from '../../layout/LoadingProgress';
import { useNotification } from '../../notification';
import { fromUserGroup, useAppDispatch } from '../../store';

const useStyles = makeStyles(theme => ({
  dialog: {
    position: 'absolute',
    borderRadius: theme.spacing(0.75),
    minWidth: theme.spacing(52.5),
  },
  subtext: {
    width: '100%',
    marginBottom: theme.spacing(1),
    '& p': {
      fontSize: theme.spacing(1.75),
      lineHeight: 2,
    },
    'white-space-collapse': 'break-spaces',
    color: '#233145',
  },
  subtitle: {
    fontWeight: 500,
  },
  form: {
    marginBottom: theme.spacing(2),
  },
  textarea: {
    width: '100%',
    height: '100%',
  },
  icon: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  reasonInput: {
    marginTop: theme.spacing(2),
    '& > *': {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
  },
  nameInput: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  asterisk: {
    color: theme.palette.warning.main,
  },
  error: {
    color: theme.palette.error.main,
    margin: 0,
  },
  closeButton: {
    float: 'right',
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
  iconBox: {
    flexGrow: 8,
  },
  editNameLabel: {
    flexGrow: 2,
    fontWeight: 500,
    fontSize: theme.spacing(3),
  },
  cancelButton: {
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
  },
  disableBtn: {
    color: '#ffffff',
    backgroundColor: '#d24720 !important',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(1.75),
    opacity: 0.2,
  },
  activeBtn: {
    color: '#ffffff',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(1.75),
    backgroundColor: '#d24720 !important',
  },
  dlgContent: {
    width: '100%',
  },
  dlgTitle: {
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    width: '100%',
    fontSize: theme.spacing(2),
    fontWeight: 700,
    color: '#233145',
    display: 'flex',
  },
  dlgActions: {
    width: '100%',
    padding: theme.spacing(0, 3, 3, 3),
    display: 'flex',
    alignItems: 'center',
  },
  dlgActionButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  boxSpacing: {
    width: theme.spacing(1),
  },
  textField: {
    '& .MuiInputBase-root': {
      backgroundColor: 'unset',
      fontSize: theme.spacing(1.75),
      borderRadius: theme.spacing(0.75),
    },
  },
}));

interface DeleteProjectProps {
  id: number;
  isOpen: boolean;
  onCloseDialog: () => void;
  onSuccessCallback: () => void;
}

const DeleteUserGroupPopup = (props: DeleteProjectProps) => {
  const {
    id,
    isOpen,
    onCloseDialog,
    onSuccessCallback,
  } = props;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();
  const { sendSuccess, sendError } = useNotification();
  const intl = useIntl();

  const closeDialog = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onCloseDialog();
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await dispatch(fromUserGroup
        .doDeleteUserGroup({ id }))
        .then(unwrapResult)
        .then(() => {
          onCloseDialog();
          setIsLoading(false);
          onSuccessCallback();
          sendSuccess({
            message: intl.formatMessage({ id: 'user.group.delete.succeed.message' }),
          }, 5000);
        });
    } catch (e: any) {
      onCloseDialog();
      setIsLoading(false);
      sendError({
        message: intl.formatMessage({ id: 'user.group.delete.failed.message' }),
      });
    }
  };

  return (
    <>
      <Dialog
        classes={{ paper: classes.dialog }}
        open={isOpen}
        maxWidth="sm"
        placeholder="center"
        fullWidth
      >
        <DialogHeader
          id="user.group.delete.dialog.header"
          className={classes.dlgTitle}
          onClick={closeDialog}
        >
          <Box className={classes.editNameLabel}>
            <FormattedMessage id="user.group.delete.dialog.header_title" />
          </Box>
        </DialogHeader>
        <DialogBody
          id="user.group.delete.dialog.body"
          className={classes.dlgContent}
        >
          <Box className={classes.subtext}>
            <Typography>
              <FormattedMessage id="user.group.delete.dialog.body_content" />
            </Typography>
          </Box>
        </DialogBody>
        <DialogFooter
          id="user.group.delete.dialog.footer"
          className={classes.dlgActions}
        >
          <div className={classes.dlgActionButtons}>
            <Button
              id="user.group.delete.dialog.button.cancel"
              className={classes.cancelButton}
              type="button"
              variant="text"
              size="small"
              color="primary"
              onClick={closeDialog}
            >
              <FormattedMessage id="common.cancel" />
            </Button>
            <Box className={classes.boxSpacing} />
            <Button
              id="user.group.delete.dialog.button.save"
              className={classes.activeBtn}
              type="submit"
              form="edit-account-name-form"
              onClick={() => handleSubmit()}
              color="primary"
              variant="contained"
              size="small"
            >
              <FormattedMessage id="common.delete" />
            </Button>
          </div>
        </DialogFooter>
      </Dialog>
      { isLoading && <LoadingProgress /> }
    </>
  );
};

export default DeleteUserGroupPopup;
