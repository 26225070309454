import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';

import { ConfigService } from '../../services';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    borderRadius: '.5rem',
    padding: '.1rem',
  },
  section: {
    padding: '1.25rem 2rem',
    marginBottom: '1.25rem',
    height: '100%',
  },
  box: {
    width: '100%',
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    alignItems: 'center',
    verticalAlign: 'middle',
    margin: '0 auto',
  },
  form: {
    width: '40%',
  },
  textField: {
    margin: `${theme.spacing(1)} 0px`,
    width: '100%',
  },
  button: {
    '& > *': {
      justifyContent: 'center',
    },
    display: 'block',
    float: 'right',
  },
}));

const RootSetting = () => {
  const classes = useStyles();
  const [segmentWriteKey, setSegmentWriteKey] = useState('');
  const [publicUrl, setPublicUrl] = useState('');
  const [whitelistedDomains, setWhitelistedDomains] = useState('');
  const [forumSecret, setForumSecret] = useState('');
  const [mandrillApiKey, setMandrillApiKey] = useState('');
  const [mandrillSubAccount, setMandrillSubAccount] = useState('');
  const [, setErrors] = useState<string[]>([]);

  const handleSegmentChange: React.ChangeEventHandler<HTMLInputElement> = e => (
    setSegmentWriteKey(e.target.value)
  );
  const handlePublicUrlChange: React.ChangeEventHandler<HTMLInputElement> = e => (
    setPublicUrl(e.target.value)
  );
  const handleWhitelistedDomainChange: React.ChangeEventHandler<HTMLInputElement> = e => (
    setWhitelistedDomains(e.target.value)
  );
  const handleForumSecretChange: React.ChangeEventHandler<HTMLInputElement> = e => (
    setForumSecret(e.target.value)
  );
  const handleMandrillApiKeyChange: React.ChangeEventHandler<HTMLInputElement> = e => (
    setMandrillApiKey(e.target.value)
  );
  const handleMandrillSubAccountChange: React.ChangeEventHandler<HTMLInputElement> = (
    e => setMandrillSubAccount(e.target.value)
  );
  const handleError = (errors: string[]) => {
    setErrors(errors);
  };

  const clearFields = () => {
    setSegmentWriteKey('');
    setPublicUrl('');
    setWhitelistedDomains('');
    setForumSecret('');
    setMandrillApiKey('');
    setMandrillSubAccount('');
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (
      publicUrl
      || whitelistedDomains
      || forumSecret
      || mandrillApiKey
      || mandrillSubAccount
      || segmentWriteKey
    ) {
      try {
        await ConfigService.setConfig({
          publicUrl,
          whitelistedDomains: whitelistedDomains.trim(),
          forumSecret,
          mandrillApiKey,
          mandrillSubAccount,
          segmentWriteKey,
        });
        clearFields();
      } catch (e: any) {
        handleError(e.response && e.response.data.errors);
      }
    }
  };

  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.section}>
        <Typography variant="h5">
          <FormattedMessage id="setting.title" />
        </Typography>
        <Box className={classes.box}>
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              size="small"
              label={<FormattedMessage id="setting.segment" />}
              className={classes.textField}
              value={segmentWriteKey}
              onChange={handleSegmentChange}
            />
            <TextField
              variant="outlined"
              size="small"
              type="url"
              label={<FormattedMessage id="setting.public_url" />}
              className={classes.textField}
              value={publicUrl}
              onChange={handlePublicUrlChange}
            />
            <TextField
              variant="outlined"
              size="small"
              label={<FormattedMessage id="setting.whitelisted_domain" />}
              className={classes.textField}
              value={whitelistedDomains}
              onChange={handleWhitelistedDomainChange}
            />
            <TextField
              variant="outlined"
              size="small"
              label={<FormattedMessage id="setting.forum_secret" />}
              className={classes.textField}
              value={forumSecret}
              onChange={handleForumSecretChange}
            />
            <TextField
              variant="outlined"
              size="small"
              label={<FormattedMessage id="setting.mandrill_api_key" />}
              className={classes.textField}
              value={mandrillApiKey}
              onChange={handleMandrillApiKeyChange}
            />
            <TextField
              variant="outlined"
              size="small"
              label={<FormattedMessage id="setting.mandrill_subaccount" />}
              className={classes.textField}
              value={mandrillSubAccount}
              onChange={handleMandrillSubAccountChange}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="medium"
              className={classes.button}
            >
              <FormattedMessage id="setting.button" />
            </Button>
          </form>
        </Box>
      </Paper>
    </div>
  );
};

export default RootSetting;
