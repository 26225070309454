import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useConfig } from '../../config';
import LoadingProgress from '../../layout/LoadingProgress';
import { Account } from '../../models';
import { CustomDomainSso, SecuritySettings } from '../../models/securitySetting';
import {
  fromSecuritySetting,
  useAppDispatch,
} from '../../store';

const SSO_LINK = 'https://docs.katalon.com/docs/administer/administration-tasks/organization-management/single-sign-on-configurations?utm_term=&utm_campaign=IN_Pmax_BDD&utm_source=adwords&utm_medium=pmcs&hsa_acc=4390546474&hsa_cam=20280143990&hsa_grp=&hsa_ad=&hsa_src=x&hsa_tgt=&hsa_kw=&hsa_mt=&hsa_net=adwords&hsa_ver=3';
const CUSTOM_LINK = 'https://docs.katalon.com/docs/administer/administration-tasks/organization-management/subdomain-configurations#create-a-subdomain';

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(2.0625),
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(2),
  },
  plan: {
    backgroundColor: '#fbc02d',
    color: '#233145',
    borderRadius: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    fontSize: theme.spacing(1.75),
    padding: theme.spacing(0.5),
    fontWeight: 'normal !important',
  },
  container: {
    width: theme.spacing(57),
  },
  includeContainer: {
    position: 'relative',
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(1.75),
  },
  description: {
    fontSize: theme.spacing(1.5),
    color: '#808b9a',
    marginBottom: theme.spacing(2.5),
  },
  switch: {
    marginTop: theme.spacing(-1.25),
    '& .Mui-disabled': {
      cursor: 'no-drop',
      border: 'none !important',
    },
  },
  refLink: {
    color: '#5959eb',
  },
  domainGuide: {
    marginLeft: theme.spacing(1.5),
    fontSize: theme.spacing(1.5),
    marginBottom: theme.spacing(2.5),
    color: '#808b9a',
  },
  domainError: {
    color: `${theme.palette.error.main} !important`,
  },
  button: {
    marginTop: theme.spacing(2.5),
    fontSize: theme.spacing(1.75),
    paddingY: theme.spacing(1),
    lineHeight: theme.spacing(3),
    fontWeight: theme.typography.fontWeightMedium,
  },
  input: {
    width: theme.spacing(77),
    '& .MuiOutlinedInput-root': {
      fontSize: theme.spacing(1.75),
      lineHeight: '1.5rem',
      border: 'solid 1px #dbdde5',
      backgroundColor: 'white',
      borderRadius: theme.spacing(0.5),
    },
    '& .Mui-disabled': {
      cursor: 'no-drop',
      border: 'none !important',
    },
  },
  domainSpan: {
    position: 'absolute',
    display: 'block',
    right: theme.spacing(5),
    color: '#808b9a',
    fontSize: theme.spacing(1.75),
    top: theme.spacing(2.4),
  },
  separateSpan: {
    position: 'absolute',
    right: theme.spacing(4),
    top: theme.spacing(2.4),
    height: '20px',
    border: 'solid 1px #dbdde5',
  },
  iconSpan: {
    position: 'absolute',
    right: theme.spacing(0),
    top: theme.spacing(1.25),
    color: '#598ef9',
    fontSize: theme.spacing(1.75),
  },
  icon: {
    top: theme.spacing(3.5),
    color: '#598ef9',
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  disableSwitch: {
    cursor: 'no-drop',
  },
  disableIcon: {
    color: '#c0c0c0 !important',
  },
  enableLink: {
    maxWidth: `${theme.spacing(57)} !important`,
  },
}));

export interface OrganizationSecurityProps {
  hasValidSubscription: boolean;
  securitySettings: SecuritySettings;
}

const AccountCustomDomainAndSso = (props: OrganizationSecurityProps) => {
  const { hasValidSubscription, securitySettings } = props;
  const classes = useStyles();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { config } = useConfig();

  const [customDomainEnable, setCustomDomainEnable] = useState(!config?.onpremise);
  const [ssoEnable, setSsoEnable] = useState(securitySettings.samlSSO ?? false);
  const [customDomainError, setCustomDomainError] = useState(false);
  const [customDomain, setCustomDomain] = useState(securitySettings.domain ?? '');
  const [currentCustomDomain, setCurrentCustomDomain] = useState(securitySettings.domain ?? '');
  const [ssoMetadata, setSsoMetadata] = useState(securitySettings.samlMetadata ?? '');
  const [currentSsoMetadata, setCurrentSsoMetadata] = useState(securitySettings.samlMetadata ?? '');
  const [ssoError, setSsoError] = useState(false);
  const [iconLinkEnabled, setIconLinkEnable] = useState(false);
  const customDomainRegex = /^[a-zA-Z0-9]*$/;

  const {
    handleSubmit,
  } = useForm<Required<Pick<Account, 'id' | 'name'>>>({
    defaultValues: { name: '' },
  });

  useEffect(() => {
    if (!securitySettings) return;

    setCustomDomainEnable(securitySettings.domain !== null && hasValidSubscription);
    setIconLinkEnable(securitySettings.domain !== null);
  }, [securitySettings.domain, hasValidSubscription]);

  useEffect(() => {
    setIconLinkEnable(currentCustomDomain !== null && currentCustomDomain.trim().length > 0);
  }, [currentCustomDomain]);

  const doUpdate = async () => {
    if (
      (customDomainEnable && customDomainError)
      || (ssoEnable && ssoError)
    ) return;
    setLoading(true);

    const reqCustomDomain = customDomainEnable ? currentCustomDomain : null;

    const input: CustomDomainSso = {
      customDomain: reqCustomDomain ?? undefined,
      samlMetadata: ssoEnable ? currentSsoMetadata : undefined,
      samlSSO: ssoEnable,
      samlMetadataType: ssoEnable ? 'XML_FILE' : undefined,
    };

    await dispatch(
      fromSecuritySetting.doUpsertSecuritySettings({
        accountId: securitySettings.accountId,
        customDomainSso: input,
      }),
    )
      .then(unwrapResult)
      .then(data => {
        enqueueSnackbar(
          intl.formatMessage(
            {
              id: config?.onpremise
                ? 'security_setting.custom_domain.update.successfully.onpremise'
                : 'security_setting.custom_domain.update.successfully',
            },
          ),
          { variant: 'success' },
        );
        refreshData(data.data);
        setIconLinkEnable(
          data.data.domain !== null
            && data.data.domain !== undefined
            && data.data.domain?.trim().length > 0,
        );
      })
      .catch((ex: any) => {
        enqueueSnackbar(
          intl.formatMessage({ id: ex.message }),
          { variant: 'error' },
        );
      })
      .finally(() => setLoading(false));
  };

  const refreshData = (data: SecuritySettings) => {
    setCustomDomainEnable(hasValidSubscription
      && data.domain !== undefined && data.domain !== null);
    setCustomDomain(data.domain ?? '');
    setCurrentCustomDomain(data.domain ?? '');
    setSsoMetadata(data.samlMetadata ?? '');
    setCurrentSsoMetadata(data.samlMetadata ?? '');
    setSsoEnable(data.samlSSO ?? false);
  };

  useEffect(() => {
    setCustomDomainError(customDomainEnable && (
      !customDomainRegex.test(currentCustomDomain)
      || currentCustomDomain === ''
    ));
  }, [currentCustomDomain, customDomainEnable]);

  useEffect(() => {
    setSsoEnable(customDomainEnable && ssoMetadata !== '');
  }, [customDomainEnable, ssoMetadata]);

  useEffect(() => {
    setSsoError(currentSsoMetadata === '' && ssoEnable);
  }, [currentSsoMetadata, ssoEnable]);

  const postFixDomain = `.${process.env.REACT_APP_CUSTOM_DOMAIN_POSTFIX}`;
  const onCustomButtonClicked = () => {
    // Will get organization's info to subdomain
    window.open(`https://${customDomain}${postFixDomain}`, '_blank');
  };

  const handleChangeCustomDomain: React.ChangeEventHandler<HTMLInputElement> = e => {
    setCurrentCustomDomain(String(e.target.value));
  };
  const handleOnSwitchCustomDomain = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isEnable: boolean = event.target.checked;
    setCustomDomainEnable(isEnable);
    if (!isEnable) {
      setSsoEnable(isEnable);
    } else {
      setCurrentCustomDomain(currentCustomDomain ?? customDomain);
      setSsoEnable(ssoMetadata !== '');
    }
  };

  const handleChangeSsoMetadata: React.ChangeEventHandler<HTMLInputElement> = e => {
    setCurrentSsoMetadata(String(e.target.value));
  };

  const handleOnSwitchSso = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isEnable: boolean = event.target.checked;
    setSsoEnable(isEnable);
    if (isEnable) {
      setCurrentSsoMetadata(currentSsoMetadata ?? ssoMetadata);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(doUpdate)} id="security_setting.custome_domain.form">
        <Box display="block" padding={2} alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" width="100%">
            {!config?.onpremise && (
              <>
                <Typography variant="subtitle2" className={classes.header}>
                  <FormattedMessage id="security_setting.custom_domain.header" />
                </Typography>
                <Typography variant="subtitle2" className={`${classes.header} ${classes.plan}`}>
                  <FormattedMessage id="security_setting.ultimate_plan" />
                </Typography>
              </>
            )}
            {config?.onpremise && (
              <Typography variant="subtitle2" className={classes.header}>
                <FormattedMessage id="security_setting.custom_domain.header.onpremise" />
              </Typography>
            )}
          </Box>
          {!config?.onpremise && (
            <Box display="flex" alignItems="center" width="100%">
              <Box display="block">
                <Box display="flex">
                  <Switch
                    id="security_setting.custome.domain.switch"
                    disabled={!hasValidSubscription}
                    checked={hasValidSubscription && customDomainEnable}
                    className={`${classes.switch} ${hasValidSubscription ? '' : classes.disableSwitch}`}
                    onChange={handleOnSwitchCustomDomain}
                  />
                  <Typography variant="subtitle2" className={classes.title}>
                    <FormattedMessage id="security_setting.custom_domain.title" />
                  </Typography>
                </Box>
                <Typography
                  variant="subtitle2"
                  className={`${classes.description} ${customDomainEnable ? classes.enableLink : ''}`}
                >
                  {intl.formatMessage(
                    { id: 'security_setting.custom_domain.description' },
                    {
                      link: (
                        <Link
                          id="security_setting.custom_domain.link"
                          className={classes.refLink}
                          underline="always"
                          href={CUSTOM_LINK}
                        >
                          <FormattedMessage id="security_setting.description.link" />
                        </Link>
                      ),
                    },
                  )}
                </Typography>
              </Box>
              <Box display={customDomainEnable ? 'block' : 'none'} alignItems="center">
                <Box display="flex" className={classes.includeContainer}>
                  <TextField
                    id="security_setting.custome.domain.textfield"
                    className={classes.input}
                    disabled={!hasValidSubscription}
                    error={customDomainError}
                    onChange={handleChangeCustomDomain}
                    multiline
                    value={currentCustomDomain}
                    variant="outlined"
                    rows={1}
                  />
                  <span className={classes.domainSpan}>
                    {postFixDomain}
                  </span>
                  <span className={classes.separateSpan} />
                  <span className={classes.iconSpan}>
                    <IconButton
                      id="security_setting.custome.domain.redirect.icon"
                      onClick={onCustomButtonClicked}
                      disabled={
                        !hasValidSubscription
                        || !iconLinkEnabled
                      }
                      size="large"
                    >
                      <OpenInNewIcon
                        className={`${classes.icon} ${!hasValidSubscription
                          || !iconLinkEnabled
                          ? classes.disableIcon : ''}`}
                        fontSize="small"
                      />
                    </IconButton>
                  </span>
                </Box>
                <Typography
                  className={`${classes.domainGuide} ${customDomainError ? classes.domainError : ''}`}
                  variant="caption"
                >
                  <FormattedMessage id="security_setting.custom_domain.input.guide" />
                </Typography>
              </Box>
            </Box>
          )}
          <Box display="flex" alignItems="center" width="100%">
            <Box display="block">
              <Box display="flex" className={classes.container}>
                <Switch
                  id="security_setting.sso.switch"
                  className={`${classes.switch} ${hasValidSubscription ? '' : classes.disableSwitch}`}
                  disabled={!hasValidSubscription || !customDomainEnable}
                  checked={hasValidSubscription && ssoEnable}
                  onChange={handleOnSwitchSso}
                />
                <Typography variant="subtitle2" className={classes.title}>
                  <FormattedMessage id="security_setting.sso.title" />
                </Typography>
              </Box>
              {!config?.onpremise && (
                <Typography variant="subtitle2" className={classes.description}>
                  {intl.formatMessage(
                    { id: 'security_setting.sso.description' },
                    {
                      link: (
                        <Link
                          id="security_setting.sso.link"
                          className={classes.refLink}
                          underline="always"
                          href={SSO_LINK}
                        >
                          <FormattedMessage id="security_setting.description.link" />
                        </Link>
                      ),
                    },
                  )}
                </Typography>
              )}
            </Box>
          </Box>
          <Box display={customDomainEnable && ssoEnable ? 'flex' : 'none'} width="100%">
            <Box display="block" className={classes.container}>
              <Typography variant="subtitle2" className={classes.title}>
                <FormattedMessage id="security_setting.metadata.title" />
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.description}
              >
                <FormattedMessage id="security_setting.metadata.description" />
              </Typography>
            </Box>
            <TextField
              id="security_setting.sso.textfield"
              className={classes.input}
              value={currentSsoMetadata}
              error={ssoError}
              disabled={!hasValidSubscription}
              onChange={handleChangeSsoMetadata}
              multiline
              rows={3}
            />
          </Box>
          <Button
            id="security_setting.custom_domain.button.update"
            variant="contained"
            color="primary"
            type="submit"
            sx={{
              '&.Mui-disabled': {
                background: '#686D80',
                color: '#c0c0c0',
              },
            }}
            disabled={
              !hasValidSubscription
              || (
                (
                  (
                    customDomainEnable && (
                      customDomainError
                      || (currentCustomDomain === customDomain)
                      || (currentCustomDomain === '')
                    )
                  )
                  || (!customDomainEnable && customDomain === '')
                )
                && (
                  (ssoEnable && (ssoError || (currentSsoMetadata === ssoMetadata)))
                  || (!ssoEnable && ssoMetadata === '')
                )
              )
            }
            className={classes.button}
          >
            <FormattedMessage id="security_setting.custom_domain.button.update" />
          </Button>
        </Box>
      </form>
      {loading && <LoadingProgress />}
    </>
  );
};

export default AccountCustomDomainAndSso;
