import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { resolvePath, welcome } from '../../routes';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  button: {
    marginTop: theme.spacing(1),
  },
  title: {
    paddingBottom: theme.spacing(3),
  },
}));

const NotFoundComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h2" className={classes.title}>
        <FormattedMessage id="not_found.title" />
      </Typography>
      <Typography variant="body1">
        <FormattedMessage id="not_found.body" />
      </Typography>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        size="medium"
        className={classes.button}
        component={RouterLink}
        to={resolvePath(welcome, undefined, {}, false)}
      >
        <FormattedMessage id="not_found.button" />
      </Button>
    </div>
  );
};

export default NotFoundComponent;
