import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AccountRole } from '../../models';
import { useQuery, useNavigate } from '../../routes';
import { fromAuth, fromAccountUsers, useAppDispatch } from '../../store';
import { accountSetting } from '../../layout/routes';
import { AccountService } from '../../services';
import LoadingProgress from '../../layout/LoadingProgress';

const EnableAiSetting = () => {
  const { get } = useQuery();
  const { navigate, replaceQuery } = useNavigate();
  const dispatch = useAppDispatch();

  const [isSendEmaillSuccess, setIsSendEmailSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const accountId = get('accountId');
  const user = useSelector(fromAuth.selectUser);
  const accountUser = useSelector(
    fromAccountUsers.selectOneByUserIdAndAccountId(
      Number(user?.id),
      Number(accountId),
    ),
  );

  useEffect(() => {
    if (!accountId) return;
    dispatch(
      fromAccountUsers.doGetByUserIdAndAccountId({
        userId: user?.id || 0,
        accountId: Number(accountId),
      }),
    );
  }, [accountId]);

  useEffect(() => {
    if (accountId && accountUser) {
      if (AccountRole.isOwner(accountUser.role)) {
        navigate(accountSetting.path, replaceQuery({ accountId }));
      } else {
        AccountService.sendEnableAiEmail(Number(accountId))
          .then(() => {
            setIsSendEmailSuccess(true);
          })
          .catch(() => {
            setIsSendEmailSuccess(false);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, [accountId, accountUser]);

  return (
    <>
      {isLoading ? (
        <LoadingProgress />
      ) : isSendEmaillSuccess ? (
        <FormattedMessage id="account_setting.send_enable_ai_email_sucessfully" />
      ) : (
        <FormattedMessage id="account_setting.send_enable_ai_email_failed" />
      )}
    </>
  );
};

export default EnableAiSetting;
