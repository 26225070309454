import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { ReactElement } from 'react';
import { Breakpoint } from '@mui/system';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
  },
  title: {
    margin: theme.spacing(0, 0, 0),
    fontSize: '1.4rem',
  },
  cardTitleContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: theme.spacing(0, 1, 0, 1),
  },
}));

interface CloseableDialogProps {
  id?: string;
  isOpenDialog: boolean;
  handleCloseDialog: Function;
  title: ReactElement;
  children: React.ReactNode;
  maxWidth: Breakpoint;
}

function CloseableDialog(props: CloseableDialogProps) {
  const classes = useStyles();

  const {
    id,
    isOpenDialog,
    handleCloseDialog,
    title,
    children,
    maxWidth,
  } = props;

  const onCloseButtonClick = () => {
    handleCloseDialog();
  };

  return (
    <>
      <Dialog id={id} open={isOpenDialog} maxWidth={maxWidth}>
        <div className={classes.card}>
          <div className={classes.cardTitleContent}>
            <DialogTitle id={`${id}-title`} className={classes.title}>
              {title}
            </DialogTitle>
            <IconButton
              id={`${id}-close-icon`}
              aria-label="close"
              onClick={onCloseButtonClick}
              edge="end"
              size="small"
            >
              <CloseIcon fontSize="medium" />
            </IconButton>
          </div>
        </div>
        {children}
      </Dialog>
    </>
  );
}

export default CloseableDialog;
