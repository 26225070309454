import { ComponentType, createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { Config } from '../models/config';
import { ConfigService } from '../services';

export interface ConfigProps {
  config?: Config;
}

export const ConfigContext = createContext<ConfigProps>({});

export const ConfigProvider = ({ children }: { children: ReactNode }) => {
  const [config, setConfig] = useState<ConfigProps['config']>();

  useEffect(() => {
    const fetchData = async () => setConfig(await ConfigService.getConfig());
    // eslint-disable-next-line no-console
    fetchData().catch(console.error);
  }, []);

  return <ConfigContext.Provider value={{ config }}>{children}</ConfigContext.Provider>;
};

export const withConfig:
  <T extends ConfigProps>(Component: ComponentType<T>) =>
ComponentType<Pick<T, Exclude<keyof T, keyof ConfigProps>>> = (Component: any) => (
  props => <Component {...props} {...useContext(ConfigContext)} />
);

export function useConfig(): ConfigProps {
  return useContext(ConfigContext);
}
