import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import DialogTransition from '../../../components/transition/DialogTransition';
import { SubscriptionWarningIcon } from '../../../layout/CustomIcon';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.spacing(2),
  },
  title: {
    color: theme.palette.warning.main,
    padding: theme.spacing(0, 3),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(2.5),
  },
  dialogContainer: {
    margin: theme.spacing(3),
    maxWidth: theme.spacing(63),
  },
  dialogContent: {
    lineHeight: theme.spacing(2.5),
    margin: theme.spacing(0.5, 0),
    color: '#172B4D',
    fontSize: '0.875rem',
  },
  dialogContentContainer: {
    padding: theme.spacing(1, 3, 2, 3),
  },
  getBackButton: {
    color: '#233145',
    backgroundColor: '#F0F1F2',
    height: theme.spacing(4),
    fontWeight: theme.typography.fontWeightMedium,
  },
}));
interface UpgradeWarningDialogProps {
  open: boolean;
  handleClose: () => void;
}

const UpgradeWarningDialog = (props: UpgradeWarningDialogProps) => {
  const {
    open,
    handleClose,
  } = props;
  const classes = useStyles();
  const intl = useIntl();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paper: classes.root,
      }}
      TransitionComponent={DialogTransition}
    >
      <div
        className={classes.dialogContainer}
      >
        <Grid
          container
          justifyContent="center"
        >
          <SubscriptionWarningIcon />
        </Grid>
        <Typography
          className={classes.title}
          variant="h3"
        >
          <FormattedMessage
            id="subscriptions.testcloud.warning_dialog.upgrade.title"
          />
        </Typography>
        <DialogContent
          className={classes.dialogContentContainer}
        >
          <DialogContentText id="alert-dialog-description">
            <Typography
              className={classes.dialogContent}
            >
              <FormattedMessage
                id="subscriptions.testcloud.warning_dialog.upgrade.description"
              />
            </Typography>
            <Typography
              className={classes.dialogContent}
            >
              {intl.formatMessage(
                { id: 'subscriptions.testcloud.warning_dialog.upgrade.contact' },
                { a: (name: ReactNode) => <Link underline="none" href="mailto:success@katalon.com">{name}</Link> },
              )}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            className={classes.getBackButton}
          >
            <FormattedMessage id="subscriptions.testcloud.get_back_button" />
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default UpgradeWarningDialog;
