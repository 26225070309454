import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Radio from '@mui/material/Radio';
import * as React from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ReactComponent as MostPopular } from '../../../icons/mostPopular.svg';
import {
  StarterPackage,
  StarterFeature,
  StarterPackageGroup,
} from '../../../../models/starterPackage';
import {
  formatUSPrice,
  sendTrackingData,
} from '../../utils';
import {
  getFeatureName,
  getFeatureTitle, getUnitName,
  isTestCreationProduct,
  isTestExecutionProduct,
  OrganizationFeature,
} from '../../../../models';
import { fromStarterPackage } from '../../../../store';

const useStyles = makeStyles(theme => ({
  rootContainer: {
    minWidth: 296,
    height: 340,
    position: 'relative',
    borderRadius: 4,
    border: 'solid 1px #dbdde5',
    paddingLeft: 16,
    paddingTop: 16,
    paddingBottom: 16,
    marginRight: 16,
    marginBottom: 16,
  },
  packageName: {
    fontSize: 14,
    fontWeight: 700,
    color: '#233145',
  },
  containerPrice: {
    display: 'flex',
    flexDirection: 'row',
  },
  spinner: {
    marginTop: 4,
  },
  selectedPackageName: {
    fontSize: 14,
    fontWeight: 700,
    color: '#1847ca',
  },
  lastPrice: {
    fontSize: 20,
    fontWeight: 600,
    color: '#233145',
    margin: 0,
  },
  selectedLastPrice: {
    fontSize: 20,
    fontWeight: 600,
    color: '#1847ca',
    margin: 0,
  },
  originalPrice: {
    fontSize: 14,
    fontWeight: 500,
    color: '#808b9a',
    textAlign: 'start',
    margin: 0,
    marginLeft: 12,
    textDecoration: 'line-through',
  },
  containerAllProduct: {
    marginTop: 16,
  },
  containerProduct: {
    marginTop: 8,
  },
  containerLineProduct: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: 16,
    position: 'relative',
    marginTop: 4,
  },
  productTitle: {
    fontSize: 14,
    fontWeight: 500,
    color: '#727993',
    margin: 0,
  },
  productName: {
    fontSize: 14,
    fontWeight: 500,
    color: '#233145',
    margin: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    overflowWrap: 'break-word',
  },
  selectButton: {
    alignItems: 'center',
    borderRadius: 4,
    fontSize: 14,
    fontWeight: 500,
    marginTop: 19,
    height: 32,
    position: 'absolute',
    bottom: 16,
    color: 'white',
    width: '-webkit-fill-available',
    minWidth: '-moz-available',
    marginRight: 24,
    marginLeft: 7,
    '&:hover': {
      background: '#002DAD',
    },
  },
  formatSelection: {
    border: 'solid 1px',
    backgroundColor: '#FFFFFF',
    borderColor: '#D5D8DD',
    height: theme.spacing(5),
    fontWeight: 500,
    fontSize: 14,
    marginTop: 4,
  },
  containerForm: {
    marginTop: theme.spacing(2),
  },
  asterisk: {
    color: '#db3131',
  },
  ribbon: {
    width: 'fit-content',
    position: 'absolute',
    right: 0,
    top: 24,
    margin: 0,
  },
}));

interface PackageItemProps {
  accountId: number;
  currentPackage: StarterPackage,
  starterPackageGroup: StarterPackageGroup,
  handleSelection: (
    selected: boolean,
    isUpdateSelectedPackage: boolean,
    starterPackage: StarterPackage) => void,
  handleItemSelection: (selected: boolean, sstarterPackage?: StarterPackage) => void,
}

const PackageItem = (props: PackageItemProps) => {
  const {
    accountId,
    currentPackage,
    starterPackageGroup,
    handleSelection,
    handleItemSelection,
  } = props;
  const classes = useStyles();
  const currentSelectedPackage = useSelector(fromStarterPackage.selectStarterPackage);
  const previewLoading = useSelector(fromStarterPackage.selectLoading);
  const [isLoading, setLoading] = useState(false);
  const listStarterPackages = starterPackageGroup.starterPackages;
  const [packageSelected, setPackageSelected] = useState(starterPackageGroup.selected);
  const currentPurchasedPlans = currentPackage ? currentPackage.listPlans : undefined;

  const getDefaultPackage = () => {
    if (listStarterPackages.length === 1) return listStarterPackages[0];
    if (starterPackageGroup.selectedStarterPackage !== undefined) {
      return starterPackageGroup.selectedStarterPackage;
    }

    const clonePackage = { ...listStarterPackages[0] };
    if (currentPackage) {
      return undefined;
    }
    if (clonePackage.group !== 'PACKAGE_1') {
      return undefined;
    }

    clonePackage.listPlans = clonePackage.listPlans
      .filter(item => !isTestExecutionProduct(item.feature));
    clonePackage.totalPrice = 0;
    clonePackage.discountedPrice = 0;
    return clonePackage;
  };

  const [selectedStarterPackage, setSelectedStarterPackage] = useState(getDefaultPackage());

  const getProductKey = (product: StarterFeature) => `${product.quota} ${product.feature}`;

  const getSelectedOption = (starterPackage: StarterPackage) => {
    const testExecutionProduct = starterPackage.listPlans.find(
      plan => isTestExecutionProduct(plan.feature),
    );
    if (testExecutionProduct) {
      return getProductKey(testExecutionProduct);
    }

    return getProductKey(
      starterPackage.listPlans.find(plan => isTestCreationProduct(plan.feature))!!,
    );
  };

  const handleSelectPackage = (
    isUpdateSelectedPackage: boolean,
    starterPackage?: StarterPackage,
  ) => {
    if (starterPackage) {
      handleSelection(packageSelected, isUpdateSelectedPackage, starterPackage);
      if (currentSelectedPackage
        && currentSelectedPackage.group !== starterPackageGroup.name
        && !starterPackageGroup.selected) {
        sendTrackingData(
          'starter_package_clicked',
          accountId,
          undefined,
          {
            package: currentSelectedPackage.group,
            action: 'deselect',
            option: getSelectedOption(currentSelectedPackage),
          },
        );
      }
      sendTrackingData(
        'starter_package_clicked',
        accountId,
        undefined,
        {
          package: starterPackage.group,
          action: starterPackageGroup.selected ? 'deselect' : 'select',
          option: getSelectedOption(starterPackage),
        },
      );
    }
  };

  useEffect(() => {
    setLoading(previewLoading);
    if (!previewLoading) {
      setPackageSelected(starterPackageGroup.selected);
    }
  }, [previewLoading]);

  const handleOnSelectPackage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedPackage = listStarterPackages.find(sp => sp.name === event.target.value);
    if (selectedPackage) {
      if (starterPackageGroup.selected && selectedPackage.name !== selectedStarterPackage?.name) {
        sendTrackingData(
          'starter_package_clicked',
          accountId,
          undefined,
          {
            package: selectedPackage.group,
            action: 'select',
            option: getSelectedOption(selectedPackage),
          },
        );
      }
      handleItemSelection(packageSelected, selectedPackage);
      setSelectedStarterPackage(selectedPackage);
    }
  };

  const handleTrackingWrongClick = () => sendTrackingData(
    'starter_package_clicked',
    accountId,
    undefined,
    {
      package: starterPackageGroup.id,
      action: '',
      option: selectedStarterPackage ? getSelectedOption(selectedStarterPackage) : '',
    },
  );

  // calculate quota is shown on UI bases on current package
  const updateNewPlansWithCurrentPlans = (listPlans: Array<StarterFeature>) => {
    if (currentPurchasedPlans === undefined) return listPlans;
    return listPlans.map(item => {
      const purchasedItem = currentPurchasedPlans.find(plan => item.feature === plan.feature);
      const newItem = { ...item };
      if (newItem.feature !== OrganizationFeature.TESTOPS_PLATFORM && purchasedItem) {
        newItem.quota -= purchasedItem.quota;
      }
      return newItem;
    });
  };

  const getPurchasedExecutionProduct = () => {
    if (!currentPurchasedPlans) return undefined;
    return currentPurchasedPlans.find(plan => isTestExecutionProduct(plan.feature));
  };

  const renderListPlans = (listPlans: Array<StarterFeature>) => listPlans.map(value => (
    <div key={value.planCode} className={classes.containerProduct}>
      <p className={classes.productTitle}>
        <FormattedMessage id={getFeatureTitle(value.feature)} />
      </p>
      <div key={value.feature?.toString()} className={classes.containerLineProduct}>
        <p className={classes.productName}>
          <span><FormattedMessage id={`subscriptions.${getFeatureName(value.feature)}.title`} /></span>
          {` x ${formatUSPrice(value.quota)} `}
          <span>
            <FormattedMessage id={getUnitName(getFeatureName(value.feature), value.quota)} />
          </span>
          {value.quota === 2000 && <span><FormattedMessage id="starter.free" /></span>}
        </p>
      </div>
    </div>
  ));

  const getPrefixProductDescription = (feature: StarterFeature) => {
    const purchasedProduct = getPurchasedExecutionProduct();
    if (feature.feature === purchasedProduct?.feature) {
      return (<FormattedMessage id="starter.keep" />);
    }
    return (<FormattedMessage id="starter.switch" />);
  };

  const renderRadioItems = (listProducts: Array<StarterFeature>) => listProducts.map(product => {
    const featureProduct = product.feature;
    const featureName = getFeatureName(featureProduct);
    return (
      <div key={`${product.quota}${product.feature}`}>
        <FormControlLabel
          value={product.starterPackageName}
          onClick={event => {
            if (starterPackageGroup.selected) {
              event.stopPropagation();
            }
          }}
          control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} onClick={event => event.stopPropagation()} />}
          label={(
            <span className={classes.productName}>
              {getPurchasedExecutionProduct() && getPrefixProductDescription(product)}
              <FormattedMessage id={`subscriptions.${featureName}.title`} />
              {` x ${product.quota} `}
              <FormattedMessage id={getUnitName(featureName, product.quota)} />
            </span>
          )}
        />
      </div>
    );
  });

  const renderSelectionProducts = (
    titleId: string,
    listProducts: Array<StarterFeature>,
  ) => (
    <div className={classes.containerForm}>
      <p className={classes.productTitle}>
        <FormattedMessage id={titleId} />
        <span className={classes.asterisk}><FormattedMessage id="starter.required" /></span>
      </p>
      <RadioGroup
        className={classes.productTitle}
        name="radio-buttons-group"
        value={selectedStarterPackage?.name ?? ''}
        onChange={handleOnSelectPackage}
      >
        {renderRadioItems(listProducts)}
      </RadioGroup>
    </div>
  );

  const renderOnePackage = () => renderListPlans(
    updateNewPlansWithCurrentPlans(selectedStarterPackage?.listPlans ?? [])
      .filter(item => item.quota > 0),
  );

  const renderTestCreationProduct = (testCreationProducts: StarterFeature[]) => {
    if (testCreationProducts.length > 0) {
      return (testCreationProducts.length === 1
        ? renderListPlans(testCreationProducts)
        : renderSelectionProducts(getFeatureTitle(
          testCreationProducts[0]?.feature ?? OrganizationFeature.PER_USER_KSE,
        ), testCreationProducts));
    }
    return <></>;
  };

  const renderManyPackages = () => {
    let allListPlans: Array<StarterFeature> = [];
    listStarterPackages.forEach(sp => {
      const listPlans = sp.listPlans.map(plan => ({ ...plan, starterPackageName: sp.name }));
      allListPlans = [...allListPlans, ...listPlans];
    });

    allListPlans = allListPlans.filter(
      (value, index, self) => index === self.findIndex(t => (t.planCode === value.planCode
        && t.quota === value.quota)),
    );
    let testCreationProducts = allListPlans.filter(plan => isTestCreationProduct(plan.feature));
    // Only show products with quota > 0
    testCreationProducts = updateNewPlansWithCurrentPlans(testCreationProducts)
      .filter(product => product.quota > 0);
    const executionProducts = allListPlans.filter(plan => isTestExecutionProduct(plan.feature));

    return (
      <div>
        {renderTestCreationProduct(testCreationProducts)}
        {executionProducts.length > 0 && renderSelectionProducts(getFeatureTitle(
          executionProducts[0]?.feature ?? OrganizationFeature.UNLIMITED_ENGINE,
        ), executionProducts)}
      </div>
    );
  };

  const renderListProduct = () => (
    <div className={classes.containerAllProduct}>
      {
        listStarterPackages.length === 1 ? renderOnePackage() : renderManyPackages()
      }
    </div>
  );

  const getSelectedSPDiscountPrice = () => {
    if (!selectedStarterPackage || selectedStarterPackage.discountedPrice === 0) {
      return 0;
    }
    return selectedStarterPackage.discountedPrice;
  };

  const getSelectedSPTotalPrice = () => {
    if (!selectedStarterPackage || selectedStarterPackage.totalPrice === 0) {
      return 0;
    }
    return selectedStarterPackage.totalPrice;
  };

  const renderPriceView = () => {
    if (isLoading) {
      return (<CircularProgress size={20} className={classes.spinner} />);
    }
    return (
      <div className={classes.containerPrice}>
        <p className={packageSelected ? classes.selectedLastPrice : classes.lastPrice}>
          $
          {getSelectedSPDiscountPrice() === 0 ? ' -' : formatUSPrice(getSelectedSPDiscountPrice())}
        </p>
        {getSelectedSPTotalPrice() !== 0 && (
          <p className={classes.originalPrice}>
            $
            {formatUSPrice(getSelectedSPTotalPrice())}
          </p>
        )}
      </div>
    );
  };

  return (
    <div
      className={classes.rootContainer}
      style={{
        borderColor: packageSelected ? '#005FCC' : '#dbdde5',
        borderWidth: packageSelected ? '2px' : '1px',
        paddingLeft: packageSelected ? '15px' : '16px',
        paddingTop: packageSelected ? '23px' : '24px',
        paddingRight: packageSelected ? '16px' : '15px',
        minHeight: 241,
      }}
      onClick={handleTrackingWrongClick}
    >
      <span className={packageSelected ? classes.selectedPackageName : classes.packageName}>
        {starterPackageGroup.name}
      </span>
      <div>
        {renderPriceView()}
      </div>
      {starterPackageGroup.id === 'PACKAGE_2' && (
        <MostPopular className={classes.ribbon} />
      )}
      {renderListProduct()}
      <Button
        className={classes.selectButton}
        style={{
          backgroundColor: packageSelected
            || getSelectedSPTotalPrice() === 0 ? '#E7E9EF' : '#1847CA',
          color: packageSelected
            || getSelectedSPTotalPrice() === 0 ? 'black' : 'white',
          bottom: packageSelected
            || getSelectedSPTotalPrice() === 0 ? '23px' : '24px',
        }}
        variant="contained"
        color="primary"
        onClick={event => {
          event.stopPropagation();
          handleSelectPackage(false, selectedStarterPackage);
        }}
        disabled={getSelectedSPTotalPrice() === 0 || isLoading}
      >
        {
          !packageSelected
            ? <FormattedMessage id="starter.button_select" />
            : <FormattedMessage id="starter.button_deselect" />
        }
      </Button>
    </div>
  );
};

export default PackageItem;
