import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import K1Typography from '../../../../components/design-system/K1Typography';
import { OrganizationFeature, TestOpsPlanInterval } from '../../../../models';
import PlanPurchaseDetails from './PlanPurchaseDetails';
import { fromVisualTestingSubscriptions } from '../../../../store';
import { PlanPrice } from '../../../../models/planPrice';
import { useLaunchDarkly } from '../../../../launchdarkly';
import { getCycleFromPathParam } from '../../utils';

export interface VisualTestingProPurchaseDetailsProps {
  accountId: number;
  initialQuota?: number;
  title: ReactElement;
  clearInitialQuota: Function;
}

const VisualTestingPurchaseDetails = (props: VisualTestingProPurchaseDetailsProps) => {
  const {
    accountId,
    initialQuota,
    title,
    clearInitialQuota,
  } = props;
  const { flags } = useLaunchDarkly();
  const visualTestingCycle = getCycleFromPathParam(OrganizationFeature.VISUAL_TESTING_PRO);

  const currentVisualTestingSubscription = useSelector(
    fromVisualTestingSubscriptions.selectPaidSubscriptionByAccountId(Number(accountId)),
  )?.[0];

  const isTrialRequest = useSelector(
    fromVisualTestingSubscriptions.selectTrialRequestSubscriptionByAccountId(Number(accountId)),
  )?.length > 0;

  const planNameComponent = (
    <K1Typography variant="h3" fontWeight="medium">
      { title }
    </K1Typography>
  );

  const quotaConverter = (
    chosenQuota: number,
    currentSubscription?: any,
  ) => chosenQuota + (currentSubscription?.quota || 0);

  return (
    <PlanPurchaseDetails
      currentSubscription={currentVisualTestingSubscription}
      planNameComponent={planNameComponent}
      showPlanDescription
      accountId={accountId}
      organizationFeature={OrganizationFeature.VISUAL_TESTING_PRO}
      initialQuota={!isTrialRequest ? initialQuota || 0 : 0}
      // eslint-disable-next-line max-len
      initialInterval={visualTestingCycle
        || currentVisualTestingSubscription?.billingCycle || TestOpsPlanInterval.YEAR}
      annualyPrice={flags?.subscriptionPlanPrice?.visualTestingProAnnually
        ?? PlanPrice.VISUAL_TESTING_PRO_ANNUALLY}
      monthlyPrice={flags?.subscriptionPlanPrice?.visualTestingProMonthly
        ?? PlanPrice.VISUAL_TESTING_PRO_MONTHLY}
      minQuota={0}
      maxQuota={500000}
      firstStep={currentVisualTestingSubscription ? 0 : 20000}
      step={5000}
      quotaConverter={quotaConverter}
      clearInitialQuota={clearInitialQuota}
      isTrialRequest={isTrialRequest}
    />
  );
};

export default VisualTestingPurchaseDetails;
