import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as InvalidInvitationIcon } from './InvalidInvitationIcon.svg';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(10),
    textAlign: 'center',
  },
  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.3,
  },
  message: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.22,
  },
}));

const InvalidInvitation = () => {
  const classes = useStyles();
  return (
    <Grid container justifyContent="center">
      <Grid item className={classes.root} xs={6}>
        <InvalidInvitationIcon />
        <Typography variant="h2" className={classes.title}>
          <FormattedMessage id="user_invitation.invalid.title" values={{ br: <br /> }} />
        </Typography>
        <Typography variant="h3" className={classes.message}>
          <FormattedMessage id="user_invitation.invalid.message" values={{ br: <br /> }} />
        </Typography>
      </Grid>
    </Grid>
  );
};
export default InvalidInvitation;
