import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { createOrganization } from '../../layout/routes';
import { User } from '../../models';
import { resolvePath } from '../../routes';
import { fromAuth, fromOrganizationUsers, useAppDispatch } from '../../store';
import { ReactComponent as EmptyOrganizationListIcon } from './emptyOrganizationListIcon.svg';
import OrganizationListOver6 from './OrganizationListOver6';
import OrganizationListShort from './OrganizationListShort';
import LoadingProgress from '../../layout/LoadingProgress';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    height: '100%',
    width: '100%',
    marginLeft: theme.spacing(12.5),
    marginRight: theme.spacing(20),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(5),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  searchBox: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
      '& > *': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        margin: 0,
      },
    },
    '& > *:first-child': {
      marginRight: theme.spacing(2),
    },
  },
  horizontalBox: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  search: {
    borderColor: theme.palette.text.disabled,
    paddingLeft: theme.spacing(1.5),
    border: 'solid 0.05rem',
    borderRadius: theme.shape.borderRadius,
  },
  searchButton: {
    padding: '0.4rem',
  },
  emptyOrg: {
    marginTop: theme.spacing(15),
    textAlign: 'center',
    alignItems: 'center',
    alignContent: 'center',
    width: '100%',
  },
}));
const OrganizationList = () => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const user: User | null = useSelector(fromAuth.selectUser);
  const orgUsers = useSelector(fromOrganizationUsers.selectOrgUsersByUserIdWithSearch);
  const [submitTextSearch, setSubmitTextSearch] = useState('');
  const [currentTextSearch, setCurrentTextSearch] = useState('');

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        await dispatch(fromOrganizationUsers.doGetOrgUsersByUserIdWithSearch({
          id: user!.id,
          textSearch: submitTextSearch,
        }));
      } finally {
        setLoading(false);
      }
    })();
  }, [dispatch, user, submitTextSearch]);

  const handleSearchButtonClick = () => {
    setSubmitTextSearch(currentTextSearch);
  };

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentTextSearch(event.target.value);
  };

  const handleOnKeyDownSearch = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') setSubmitTextSearch(currentTextSearch);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.title}>
        <Box>
          <Typography variant="h3">
            <FormattedMessage id="organizationlist.header.list" />
          </Typography>
        </Box>
        <Box className={classes.searchBox}>
          <Input
            className={classes.search}
            disableUnderline
            placeholder={intl.formatMessage({ id: 'organizationlist.header.search' })}
            onChange={handleSearchInputChange}
            onKeyDown={handleOnKeyDownSearch}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  className={classes.searchButton}
                  onClick={handleSearchButtonClick}
                  size="large"
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            )}
          />
          <Button
            component={RouterLink}
            to={resolvePath(createOrganization, undefined, {}, false)}
            variant="contained"
            color="primary"
            startIcon={<AddSharpIcon />}
          >
            <FormattedMessage id="organizationlist.header.create" />
          </Button>
        </Box>
      </Box>
      {!loading && (
        <Box className={classes.horizontalBox}>
          {(orgUsers.length === 0) && (
            <Box className={classes.emptyOrg}>
              <EmptyOrganizationListIcon />
              <Typography variant="subtitle1">
                <FormattedMessage id="organizationlist.emptylist.no_organization" />
              </Typography>
            </Box>
          )}
          {(orgUsers.length > 6) && (
            <OrganizationListOver6 />
          )}
          {(orgUsers.length <= 6 && orgUsers.length !== 0) && (
            <OrganizationListShort />
          )}
        </Box>
      )}
      {loading && <LoadingProgress />}
    </Box>
  );
};

export default OrganizationList;
