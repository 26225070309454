import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { subMilliseconds } from 'date-fns';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import sum from 'lodash/sum';
import { useMemo } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { ChartLoadingIcon } from '../../../../layout/CustomIcon';
import { OrganizationFeature } from '../../../../models/organizationFeature';
import { IntervalUnit } from '../../../../models/query';
import { fromLicenseUtilization } from '../../../../store';
import { LicenseUsage } from '../../../../store/licenseUtilizationSlice';
import EmptyData from './EmptyData';
import LicenseUsageLinearChart from './linear/LicenseUsageLinearChart';
import LicenseUsageLinearSummary from './linear/LicenseUsageLinearSummary';
import LicenseUsageStackedChart from './stacked/LicenseUsageStackedChart';
import LicenseUsageStackedSummary from './stacked/LicenseUsageStackedSummary';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2.5, 4),
    margin: theme.spacing(3, 2, 2, 2),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  chart: {
    width: '100%',
    padding: theme.spacing(0, 4),
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
  summary: {
    width: '100%',
    minHeight: theme.spacing(14),
  },
  tabBox: {
    marginTop: theme.spacing(-5),
    marginBottom: theme.spacing(4),
  },
  tabList: {
    borderRadius: theme.spacing(1),
    backgroundColor: (theme.components?.MuiTab?.styleOverrides?.textColorPrimary as any)?.backgroundColor ?? '#EFF6FC',
  },
  emptyDataContainer: {
    textAlign: 'center',
  },
  emptyDataImg: {
    display: 'block',
    margin: '0 auto',
  },
  chartLoadingIcon: {
    minHeight: '20rem',
  },
}));
// styles of components in @mui/lab seemingly can't be overriden in theme
const StyledToggleButtonGroup = withStyles(theme => ({
  grouped: {
    border: 'none',
    margin: theme.spacing(0.5),
    fontSize: theme.spacing(1.75),
    fontWeight: 'bold',
    minHeight: 0,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    minWidth: theme.spacing(14),
    '&.Mui-selected, &.Mui-selected:hover': {
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
    },
    textTransform: 'none',
  },
}))(ToggleButtonGroup);

const getFeatureColor = (feature: OrganizationFeature | undefined) => {
  switch (feature) {
    case OrganizationFeature.ENGINE:
      return '#2483F2';
    case OrganizationFeature.UNLIMITED_ENGINE:
      return '#42B156';
    case OrganizationFeature.UNLIMITED_KSE:
      return '#53C8ED';
    case OrganizationFeature.KSE:
      return '#F6AE6C';
    case OrganizationFeature.PER_USER_KSE:
      return '#CC3E58';
    default:
      return '#172B4D';
  }
};

const computeXLabel = (dataPoint: LicenseUsage, intl: IntlShape) => {
  switch (dataPoint.unit) {
    case IntervalUnit.HOURS:
      return `${intl.formatDate(new Date(dataPoint.startTime), { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' })}`;
    case IntervalUnit.DAYS:
      return `${intl.formatDate(new Date(dataPoint.startTime), { month: 'short', day: 'numeric' })}`;
    default:
      return `${intl.formatDate(new Date(dataPoint.startTime), { month: 'short', year: 'numeric' })}`;
  }
};

const computeStartDateString = (dataPoint: LicenseUsage, intl: IntlShape) => {
  switch (dataPoint.unit) {
    case IntervalUnit.HOURS:
      return `${intl.formatDate(new Date(dataPoint.startTime), { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hourCycle: 'h23' })}`;
    case IntervalUnit.DAYS:
      return `${intl.formatDate(new Date(dataPoint.startTime), { month: 'short', day: 'numeric' })}`;
    default:
      return `${intl.formatDate(new Date(dataPoint.startTime), { month: 'short', day: 'numeric' })}`;
  }
};

const computeEndDateString = (dataPoint: LicenseUsage, intl: IntlShape) => {
  switch (dataPoint.unit) {
    case IntervalUnit.HOURS:
      return `${intl.formatDate(subMilliseconds(new Date(dataPoint.endTime), 1), { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' })}`;
    case IntervalUnit.DAYS:
      return '';
    default:
      return `${intl.formatDate(subMilliseconds(new Date(dataPoint.endTime), 1), { month: 'short', day: 'numeric' })}`;
  }
};

const computeProps = (usage: LicenseUsage[], intl: IntlShape) => {
  // Common things
  const usageByFeature = groupBy(usage, u => u.feature);
  const featureStrings: string[] = sortBy(
    [...Object.keys(usageByFeature)],
    fs => OrganizationFeature.getFeatureHalfFullName(OrganizationFeature.fromString(fs)),
  );
  // featureStrings = ['UNLIMITED_ENGINE', 'ENGINE', 'UNLIMITED_KSE', 'KSE']
  //   because we sort by the feature's half-full name
  const features = featureStrings.map(fs => OrganizationFeature.fromString(fs));
  const colors = features.map(feature => getFeatureColor(feature));
  const productNames = features.map(feature => OrganizationFeature.getFeatureHalfFullName(feature));
  const durationOfFeature = featureStrings
    .map(p => usageByFeature[p].map(u => u.duration * 1000).reduce(((acc, d) => acc + d), 0));
  const sumAll = sum(durationOfFeature);
  const xLabels = usageByFeature[featureStrings[0]] // sorted
    ?.map(x => computeXLabel(x, intl));
  const startDateStrings = usageByFeature[featureStrings[0]]
    ?.map(x => computeStartDateString(x, intl));
  const endDateStrings = usageByFeature[featureStrings[0]]
    ?.map(x => computeEndDateString(x, intl));
  // console.log(xLabels);
  // any two-dimensional array in this function has:
  const R = productNames.length; // R rows
  const C = xLabels?.length; // C columns

  // Compute linear summary props
  const linearSummaryProps = {
    productNames: [...productNames, intl.formatMessage({ id: 'license_utilization.total' })],
    durations: [...durationOfFeature, sumAll],
    colors: [...colors, getFeatureColor(undefined)],
  };
  // Compute linear chart props
  // durationOfTime = sum by column of `durations`
  // durations will be in ms
  const durations = featureStrings
    .map(p => usageByFeature[p]
      .sort((x, y) => new Date(x.startTime).getTime() - new Date(y.startTime).getTime())
      .map(x => +(x.duration * 1000).toFixed(1)));
  const durationOfTime = xLabels?.map((_label, i) => (
    +productNames.reduce((acc, _, prodIndex) => acc + durations[prodIndex][i], 0).toFixed(1)
  ));
  // Many people complained about the percentages didn't add up to 100%
  // this is the simplest cheat
  const accumulatePercentages: number[] = Array(C).fill(0);
  const percentageEachMonth: number[][] = Array(R);
  for (let i = 0; i < R; i += 1) {
    percentageEachMonth[i] = Array(C).fill(0);
    if (i > 0 && i === R - 1) {
      for (let j = 0; j < C; j += 1) {
        if (durationOfTime[j] === 0) continue;
        percentageEachMonth[i][j] = +(100 - accumulatePercentages[j]).toFixed(1);
      }
    } else {
      for (let j = 0; j < C; j += 1) {
        if (durationOfTime[j] === 0) continue;
        percentageEachMonth[i][j] = +((durations[i][j] * 100) / durationOfTime[j]).toFixed(1);
        accumulatePercentages[j] += percentageEachMonth[i][j];
      }
    }
  }
  const linearChartProps = {
    productNames,
    durations,
    colors,
    xLabels,
    percentageEachMonth,
    startDateStrings,
    endDateStrings,
  };
  // Compute stacked chart props
  const stackedChartProps = {
    productNames,
    durations,
    colors,
    xLabels,
    totals: durationOfTime,
    percentageEachMonth,
    startDateStrings,
    endDateStrings,
  };
  // Compute stacked summary props
  const percentages = durationOfFeature.map(x => (sumAll !== 0
    ? Math.round((x * 100) / sumAll) : 0));
  // Many people complained about the percentages didn't add up to 100%
  // this is the simplest cheat
  percentages[R - 1] = +(100 - (sum(percentages) - percentages[R - 1])).toFixed(1);
  const stackedSummaryProps = {
    productNames,
    durations: durationOfFeature,
    colors,
    percentages,
    totalDuration: sumAll,
    totalDurationColor: getFeatureColor(undefined),
  };
  // -----------
  return {
    linearSummaryProps,
    linearChartProps,
    stackedSummaryProps,
    stackedChartProps,
  };
};

interface LicenseUsageSectionProps {
  loading: boolean;
}

const LicenseUsageSection = (props: LicenseUsageSectionProps) => {
  const usage = useSelector(fromLicenseUtilization.selectFilteredLicenseUsage);
  const { loading } = props;
  const intl = useIntl();
  // TODO: Put this computation to a useEffect when we do the loading skeleton
  // useMemo prevents unnecessary computing when switching between linear and stacked,
  //   though the computing time has been seen to be insignificant.
  const {
    linearSummaryProps,
    linearChartProps,
    stackedSummaryProps,
    stackedChartProps,
  } = useMemo(() => computeProps(usage!!, intl), [usage, intl]);
  const { totalDuration } = stackedSummaryProps;
  const classes = useStyles();
  const location = useLocation();
  const isStackedChosen = location.pathname.includes('stacked');
  const Linear = () => (
    <Grid container direction="column">
      <Grid className={classes.summary}>
        <LicenseUsageLinearSummary {...linearSummaryProps} loading={loading} />
      </Grid>
      <Grid className={classes.chart}>
        {loading && <ChartLoadingIcon className={classes.chartLoadingIcon} />}
        {!loading && totalDuration > 0 && <LicenseUsageLinearChart {...linearChartProps} />}
        {!loading && totalDuration <= 0 && <EmptyData />}
      </Grid>
    </Grid>
  );
  const Stacked = () => (
    <Grid container direction="column">
      <Grid className={classes.summary}>
        <LicenseUsageStackedSummary {...stackedSummaryProps} loading={loading} />
      </Grid>
      <Grid className={classes.chart}>
        {loading && <ChartLoadingIcon className={classes.chartLoadingIcon} />}
        {!loading && totalDuration > 0 && <LicenseUsageStackedChart {...stackedChartProps} />}
        {!loading && totalDuration <= 0 && <EmptyData />}
      </Grid>
    </Grid>
  );

  return (
    <Paper className={classes.root} elevation={0}>
      <Typography
        variant="h3"
        className={classes.title}
      >
        <FormattedMessage id="license_utilization.license_usage" />
      </Typography>
      <Grid container justifyContent="center" className={classes.tabBox}>
        <Grid item className={classes.tabList}>
          <StyledToggleButtonGroup
            value={isStackedChosen ? 'stacked' : 'linear'}
            exclusive
          >
            <ToggleButton
              component={Link}
              value="linear"
              disabled={loading}
              to={{
                pathname: 'linear',
                search: location.search,
              }}
            >
              <FormattedMessage id="license_utilization.linear" />
            </ToggleButton>
            <ToggleButton
              component={Link}
              value="stacked"
              disabled={loading}
              to={{
                pathname: 'stacked',
                search: location.search,
              }}
            >
              <FormattedMessage id="license_utilization.stacked" />
            </ToggleButton>
          </StyledToggleButtonGroup>
        </Grid>
      </Grid>
      <Routes>
        <Route path="linear" element={<Linear />} />
        <Route path="stacked" element={<Stacked />} />
        <Route
          path="*"
          element={(
            <Navigate
              to={{
                pathname: 'linear',
                search: location.search,
              }}
              replace
            />
          )}
        />
      </Routes>
    </Paper>
  );
};

export default LicenseUsageSection;
