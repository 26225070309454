import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import InputAdorment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';
import { Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import orderBy from 'lodash/fp/orderBy';
import throttle from 'lodash/throttle';
import React, { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import CopyableLink from '../../../components/copyable-link/CopyableLink';
import CheckedIcon from '../../../components/icons/CheckedIcon';
import UnCheckedIcon from '../../../components/icons/UnCheckedIcon';
import { useConfig } from '../../../config';
import { useLaunchDarkly } from '../../../launchdarkly';
import { getFullNameUser, isInvitationExpired, isKitInvitation } from '../../../models';
import { UserPendingInvitationItemLoadingState } from '../../../store/userPendingInvitationTabSlice';
import { ROWS_PER_PAGE, UserTable } from '../utils';
import PendingInvitationHeaderTable from './PendingInvitationHeaderTable';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    wordBreak: 'break-word',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  paginationBox: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    // there is no font match with design, use custom color here
    color: '#1D3066',
    paddingBottom: theme.spacing(2),
  },
  cell: {
    padding: theme.spacing(1.5, 0, 1.5, 2),
  },
  licenseAccessCell: {
    cursor: 'pointer',
    minWidth: theme.spacing(22),
  },
  email: {
    minWidth: theme.spacing(20),
    maxWidth: theme.spacing(26),
  },
  role: {
    minWidth: theme.spacing(10),
    textTransform: 'capitalize',
  },
  fullNameCell: {
    minWidth: theme.spacing(16),
    maxWidth: theme.spacing(20),
  },
  fullNameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  fullName: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  fullNameFlex: {
    flex: 1,
  },
  ssoChip: {
    marginLeft: theme.spacing(1),
    borderRadius: theme.spacing(1),
    height: theme.spacing(2),
    width: theme.spacing(5),
    backgroundColor: 'rgba(104, 109, 128, 0.08)',
  },
  ssoChipNew: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0, 0.5),
    borderRadius: theme.spacing(0.5),
    height: theme.spacing(3.375),
    width: theme.spacing(5.625),
    minWidth: theme.spacing(5.625),
    backgroundColor: 'rgba(104, 109, 128, 0.08)',
    color: '#233145',
    '& span': {
      fontSize: theme.spacing(1.75),
      fontWeight: 'normal',
      padding: 0,
    },
  },
  avatar: {
    marginRight: theme.spacing(1),
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  table: {
    padding: theme.spacing(0, 2),
  },
  checkbox: {
    padding: 0,
  },
  checkboxWrapper: {
    width: theme.spacing(1.75),
  },
  disabledHoverRow: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  invitationLinkContainer: {
    display: 'flex',
    maxWidth: theme.spacing(34),
    alignItems: 'self-end',
    paddingLeft: 0,
    paddingRight: 0,
  },
  invitationLink: {
    whiteSpace: 'nowrap',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  invitationLinkExpired: {
    whiteSpace: 'nowrap',
    color: '#B0B8CB',
  },
  ellipsisOverflow: {
    maxWidth: theme.spacing(32),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  renewButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: 0,
    marginLeft: theme.spacing(3),
  },
  filterContainer: {
    margin: theme.spacing(2),
  },
  search: {
    marginRight: theme.spacing(0.5),
    width: theme.spacing(35),
    '& > .MuiInputBase-root': {
      borderRadius: theme.spacing(0.5),
      maxHeight: theme.spacing(4),
      paddingLeft: theme.spacing(1),
    },
    '& > * > .MuiInputBase-inputSizeSmall': {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
    },
  },
  inputSearch: {
    fontSize: '0.875rem',
  },
  textButton: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(0, 0.5),
    minWidth: theme.spacing(5),
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    maxHeight: theme.spacing(4),
  },
  searchIcon: {
    fontSize: theme.spacing(2.1),
    color: '#284169',
  },
  noResultFound: {
    textAlign: 'center',
    margin: theme.spacing(2, 0),
  },
  invitationDate: {
    minWidth: theme.spacing(20),
    maxWidth: theme.spacing(26),
  },
}));

// only accept primitives for sorting
export interface PendingInvitationFields {
  id: number,
  fullName: string,
  email: string,
  invitationDate?: Date,
  invitedBy?: string,
  orgRole: string,
  licenseAccess: string,
  invitationLink: string,
  invitationDisplayLink: string,
  isSelectable: boolean,
  invitationLastSentDate: Date,
  type: string;
  loading: boolean;
  hasSsoEnabled?: boolean;
}

export type Order = 'asc' | 'desc';

interface PendingInvitationsTableProps {
  onSelectUser: (user: UserTable) => void,
  onSelectAllUsers: (isCheckedAll: boolean, userList: UserTable[]) => void,
  selectedUsers: UserTable[],
  page: number,
  onChangePage: (_: React.ChangeEvent<unknown> | null, value: number) => void,
  dataUser: UserTable[],
  onRevokeClick: () => void,
  onRevokeSsoClick: () => void,
  onResendClick: () => void,
  onRenewInvitation: (invitation: UserTable) => void,
  itemsLoadingState: UserPendingInvitationItemLoadingState,
  isSsoEnabled: boolean,
  disabledRevokeSso: boolean,
}

const PendingInvitationTable = (props: PendingInvitationsTableProps) => {
  const {
    onSelectUser,
    onSelectAllUsers,
    selectedUsers,
    page,
    onChangePage,
    dataUser,
    onRevokeClick,
    onRevokeSsoClick,
    onResendClick,
    onRenewInvitation,
    itemsLoadingState,
    isSsoEnabled,
    disabledRevokeSso,
  } = props;
  const classes = useStyles();
  const intl = useIntl();
  const { config } = useConfig();
  const { flags } = useLaunchDarkly();
  const rows: PendingInvitationFields[] = dataUser.map(invitation => {
    const {
      firstName, lastName, email, orgRole, invitedBy,
      licenseAccess, id, invitationLink, isSelectable, type, hasSsoEnabled,
    } = invitation;
    return {
      id,
      // if firstName and lastName of user invitation are null => show prefix email
      fullName: getFullNameUser(firstName, lastName, email),
      email,
      orgRole,
      invitedBy,
      licenseAccess: licenseAccess.join(', ') ?? '',
      invitationLink: invitationLink ?? '',
      isSelectable: isSelectable ?? false,
      invitationDisplayLink: `${(invitationLink ?? '').split('token')[0]}...`,
      invitationDate: invitation.createdAt ?? '', // old invitation from TO doesnt have createdAt.
      invitationLastSentDate: invitation.updatedAt ?? invitation.createdAt ?? 0,
      type: type ?? 'K1', // add defensive
      loading: itemsLoadingState[id],
      hasSsoEnabled,
    };
  });
  const [order, setOrder] = React.useState<Order>('desc');
  const [sortingField, setSortingField] = React.useState<keyof PendingInvitationFields>('invitationDate');
  const [matchedRows, setMatchedRows] = useState<PendingInvitationFields[]>(rows);
  const [currentSearchText, setCurrentSearchText] = useState<string>('');
  const currentPageRows = matchedRows.slice(
    (page - 1) * ROWS_PER_PAGE,
    ((page - 1) * ROWS_PER_PAGE) + ROWS_PER_PAGE,
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentSearchText = e.target.value.toLowerCase().trim();
    setCurrentSearchText(currentSearchText);
    if (page !== 1) onChangePage(null, 1);
  };

  useEffect(() => {
    const searchResultRows = rows.filter(
      it => (
        it.fullName.toLowerCase().includes(currentSearchText)
          || it.email.toLowerCase().includes(currentSearchText)
      ),
    );
    setMatchedRows(orderBy(sortingField, order, searchResultRows));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUser, currentSearchText, order, sortingField, itemsLoadingState]);

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    field: keyof PendingInvitationFields,
  ) => {
    const isAsc = sortingField === field && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setSortingField(field);
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    // check if there's searching
    if (currentSearchText.length > 0) {
      const filterMatchedUsers = dataUser.filter(
        user => currentPageRows.some(it => it.email === user.email) && user.isSelectable,
      );
      onSelectAllUsers(event.target.checked, filterMatchedUsers);
    } else {
      onSelectAllUsers(
        event.target.checked,
        dataUser.filter(user => (
          user.isSelectable && currentPageRows.some(it => it.email === user.email)
        )),
      );
    }
  };
  const handleClick = (_event: React.MouseEvent<unknown>, email: string) => {
    const selectedUserIndex = dataUser.findIndex(it => it.email === email);
    onSelectUser(dataUser[selectedUserIndex]);
  };

  const handleRenewClick = (email: string) => {
    const invitationIndex = dataUser.findIndex(it => it.email === email);
    onRenewInvitation(dataUser[invitationIndex]);
  };

  const isSelected = (email: string) => selectedUsers.findIndex(it => it.email === email) !== -1;

  const isActiveInvitation = (row: PendingInvitationFields) => (
    isKitInvitation(row.type)
      || !isInvitationExpired(Number(row.invitationLastSentDate), config)
  );

  const renderFullNameWithSSO = (row: PendingInvitationFields) => (
    <div className={classes.fullNameContainer}>
      <span className={clsx(classes.fullName, classes.fullNameFlex)}>
        {row.fullName}
      </span>
      {isSsoEnabled && row.hasSsoEnabled
        && <Chip className={classes.ssoChipNew} size="small" label="SSO" />}
    </div>
  );

  const renderInvitationDate = (row: PendingInvitationFields) => (
    row.invitationDate ? (
      <FormattedDate
        value={new Date(row.invitationDate)}
        month="short"
        day="numeric"
        year="numeric"
      />
    ) : <FormattedMessage id="user_management.cell.data.not.available" />
  );

  const renderLicenseAccess = (row: PendingInvitationFields) => (
    !row.licenseAccess.length ? <FormattedMessage id="user_management.cell.data.not.available" />
      : (
        <Tooltip title={row.licenseAccess} placement="top">
          <span>
            {row.licenseAccess.split(', ')[0]}
            {row.licenseAccess.split(', ').length > 1 && ',...'}
          </span>
        </Tooltip>
      )
  );

  const renderInvitationLinkAndAction = (row: PendingInvitationFields) => (
    <>
      <div
        // Display full invitation link if in on-premise
        className={
          clsx({
            [classes.invitationLink]: isActiveInvitation(row),
            [classes.invitationLinkExpired]: !isActiveInvitation(row),
            [classes.ellipsisOverflow]: navigator.clipboard,
            [classes.cursorPointer]: isActiveInvitation(row) && navigator.clipboard,
          })
        }
      >
        {!navigator.clipboard
          ? <span>{row.invitationLink}</span>
          : (
            <CopyableLink
              link={row.invitationLink}
              displayLink={row.invitationDisplayLink}
              isCopyable={isActiveInvitation(row)}
            />
          )}
      </div>
      {!isActiveInvitation(row) && (
      <Button
        size="small"
        className={classes.renewButton}
        variant="text"
        disabled={row.loading}
        onClick={throttle(
          () => handleRenewClick(row.email),
          500,
          { leading: true, trailing: false },
        )}
      >
        <FormattedMessage id="user_management.button.renew_invitation" />
      </Button>
      )}
    </>
  );

  return (
    <div className={classes.root}>
      <Grid container className={classes.filterContainer}>
        <TextField
          onChange={handleSearchChange}
          size="small"
          variant="standard"
          placeholder={intl.formatMessage({ id: 'user_management.search.placeholder' })}
          InputProps={{
            classes: { input: classes.inputSearch },
            disableUnderline: true,
            startAdornment: (<InputAdorment position="start"><SearchIcon className={classes.searchIcon} /></InputAdorment>),
          }}
          className={classes.search}
          autoComplete="off"
        />
        <Button
          onClick={onRevokeClick}
          disabled={selectedUsers.length === 0}
          className={classes.button}
          variant="contained"
        >
          <FormattedMessage id="user_management.button.revoke" />
        </Button>
        {isSsoEnabled && (
        <Button
          onClick={onRevokeSsoClick}
          disabled={disabledRevokeSso}
          className={classes.button}
          variant="contained"
        >
          <FormattedMessage id="user_management.button.revoke_sso" />
        </Button>
        )}
        <Button
          onClick={onResendClick}
          className={classes.button}
          disabled={selectedUsers.length === 0}
          variant="contained"
          color="primary"
        >
          <FormattedMessage id="user_management.button.resend_invitation" />
        </Button>
      </Grid>
      <TableContainer className={classes.table}>
        <Table>
          <PendingInvitationHeaderTable
            maxSelectable={currentPageRows.filter(row => row.isSelectable).length}
            numSelected={
              currentPageRows.filter(row => selectedUsers.some(it => it.email === row.email)).length
            }
            order={order}
            orderBy={sortingField}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={matchedRows.length}
          />
          <TableBody>
            {matchedRows.length > 0 ? currentPageRows
              .map(row => (
                <TableRow
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  className={classes.disabledHoverRow}
                >
                  <TableCell className={clsx(classes.checkboxWrapper)} padding="none">
                    {row.isSelectable ? (
                      <Checkbox
                        onClick={event => handleClick(event, row.email)}
                        classes={{
                          root: classes.checkbox,
                        }}
                        icon={<UnCheckedIcon />}
                        checkedIcon={<CheckedIcon />}
                        checked={isSelected(row.email)}
                      />
                    ) : null}
                  </TableCell>
                  <TableCell className={clsx(classes.fullNameCell, classes.cell)} padding="none">
                    {renderFullNameWithSSO(row)}
                  </TableCell>
                  <TableCell className={clsx(classes.email, classes.cell)} padding="none">{row.email}</TableCell>
                  <TableCell className={clsx(classes.invitationDate, classes.cell)} padding="none">
                    {renderInvitationDate(row)}
                  </TableCell>
                  {flags?.invitedByEnabled === true && (
                    <TableCell className={clsx(classes.cell, classes.email)}>
                      {row.invitedBy?.toLowerCase()}
                    </TableCell>
                  )}
                  <TableCell className={clsx(classes.cell, classes.role)}>
                    {row.orgRole.toLowerCase()}
                  </TableCell>
                  <TableCell className={clsx(classes.cell, classes.licenseAccessCell)}>
                    {renderLicenseAccess(row)}
                  </TableCell>
                  <TableCell className={classes.invitationLinkContainer}>
                    {renderInvitationLinkAndAction(row)}
                  </TableCell>
                </TableRow>
              )) : (
                <TableRow>
                  <TableCell colSpan={7}>
                    <Typography className={classes.noResultFound} variant="subtitle2">
                      {dataUser.length > 0 ? (
                        <FormattedMessage id="user_management.no_result_found" />
                      ) : (
                        <FormattedMessage id="license_utilization.empty_data" />
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {matchedRows.length > 0 && (
        <Box className={classes.paginationBox}>
          <Pagination
            shape="rounded"
            count={Math.ceil(matchedRows.length / ROWS_PER_PAGE)}
            page={page}
            onChange={onChangePage}
          />
          <Typography variant="body2">
            <FormattedMessage
              id={matchedRows.length > 1 ? 'license_utilization.session_detail.total_records'
                : 'license_utilization.session_detail.total_record'}
              values={{ count: matchedRows.length }}
            />
          </Typography>
        </Box>
      )}
    </div>
  );
};
export default PendingInvitationTable;
