import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { fromOrganizations, useAppDispatch } from '../../store';
import LoadingProgress from '../../layout/LoadingProgress';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: theme.spacing(3),
    fontWeight: theme.typography.fontWeightMedium,
  },
  label: {
    marginTop: theme.spacing(2),
    fontSize: theme.spacing(1.75),
    fontWeight: theme.typography.fontWeightMedium,
    height: theme.spacing(2.625),
  },
  save_button: {
    color: '#ffffff',
    backgroundColor: '#1847ca',
    '&:hover': {
      color: '#ffffff',
      backgroundColor: '#1847ca',
    },
  },
  cancel_button: {
    backgroundColor: '#ffffff',
    color: '#1847ca',
  },
  action_area: {
    marginRight: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
  },
  button_text: {
    fontSize: theme.spacing(1.75),
    fontWeight: theme.typography.fontWeightMedium,
  },
  textField: {
    fontSize: theme.spacing(1.75),
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(3),
  },
  dialogContent: {
    paddingTop: 0,
  },
}));

export interface UpdateOrgNameDialogProps {
  orgId: number,
  defaultValue: string,
  open: boolean;
  onClose: () => void;
}

const MAX_LENGTH = 255;

function UpdateOrgNameDialog(props: UpdateOrgNameDialogProps) {
  const { onClose, open, defaultValue, orgId } = props;
  const classes = useStyles();
  const intl = useIntl();
  const [inputText, setInputText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [allowUpdate, setAllowUpdate] = useState(false);

  useEffect(() => {
    setErrorMessage(
      inputText.length >= MAX_LENGTH
        ? intl.formatMessage({ id: 'organization.card_view.organization_profile.name_update.error.max_length.msg' })
        : '',
    );
    setAllowUpdate(inputText.trim().length !== 0 && inputText.length < MAX_LENGTH);
  }, [inputText]);

  useEffect(() => {
    if (!open) {
      setErrorMessage('');
    }
  }, [open]);

  const doUpdateOrganizationName = () => {
    if (inputText.trim().length === 0) {
      return;
    }
    const handleUpdateOrganizationName = async () => {
      setLoading(true);
      await dispatch(
        fromOrganizations.doUpdateOrganization({
          id: orgId,
          name: inputText,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          enqueueSnackbar(
            <FormattedMessage id="organization.card_view.organization_profile.name_update.success.msg" />,
            { variant: 'success' },
          );
          // Dispatch to micro FE navigation UI to update org name
          const updateEvent = new CustomEvent('parent-app:changeOrgName', { detail: { orgId } });
          window.dispatchEvent(updateEvent);
        })
        .catch(() => {
          enqueueSnackbar(
            <FormattedMessage id="organization.card_view.organization_profile.name_update.error.msg" />,
            { variant: 'error' },
          );
        })
        .finally(() => {
          setLoading(false);
          onClose();
        });
    };
    handleUpdateOrganizationName().catch(() => { });
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        open={open}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Typography id="organization.card_view.organization_profile.name_update.title" className={classes.title}>
            <FormattedMessage id="organization.card_view.organization_profile.name_update.title" />
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className={classes.dialogContent}>
          <Typography id="organization.card_view.organization_profile.name_update.label" className={classes.label}>
            <FormattedMessage id="organization.card_view.organization_profile.name_update.label" />
          </Typography>
          <TextField
            error={errorMessage !== ''}
            id="standard-size-normal"
            autoFocus
            margin="dense"
            fullWidth
            defaultValue={defaultValue}
            onChange={e => setInputText(e.target.value)}
            helperText={errorMessage}
            InputProps={{
              classes: {
                input: classes.textField,
              },
            }}
          />
        </DialogContent>
        <DialogActions className={classes.action_area}>
          <Button className={classes.cancel_button} onClick={onClose}>
            <Typography id="organization.card_view.organization_profile.name_update.cancel" className={classes.button_text}>
              <FormattedMessage id="organization.card_view.organization_profile.name_update.cancel" />
            </Typography>
          </Button>
          <Button
            className={classes.save_button}
            onClick={doUpdateOrganizationName}
            disabled={!allowUpdate}
            sx={{
              '&.Mui-disabled': {
                background: 'rgba(0, 0, 0, 0.05)',
                color: 'rgba(0, 0, 0, 0.2)',
              },
            }}
          >
            <Typography id="organization.card_view.organization_profile.name_update.save" className={classes.button_text}>
              <FormattedMessage id="organization.card_view.organization_profile.name_update.save" />
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
      {loading && <LoadingProgress />}
    </>
  );
}

export default UpdateOrgNameDialog;
