import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MicroApp from '../../components/micro-frontend/MicroApp';
import { useConfig } from '../../config';
import { useLaunchDarkly } from '../../launchdarkly';
import LoadingProgress from '../../layout/LoadingProgress';
import { maintenanceProcess, userGroupDetail } from '../../layout/routes';
import { OrganizationMigrationStatus } from '../../models';
import { sso, useNavigate, useQuery } from '../../routes';
import { fromLayout, fromOrganizations } from '../../store';
import { doGetOrgMigrationStatus } from '../../store/organizationsSlice';

const ProjectManagementMicroApp = () => {
  const { get } = useQuery();
  const { navigate, replaceQuery } = useNavigate();
  const orgId = get('orgId');
  const { initialized, flags } = useLaunchDarkly();
  const [rendered, setRendered] = useState(false);
  const { config } = useConfig();

  const orgMigrationStatus = useSelector(
    fromOrganizations.selectOrgMigrationStatusById(Number(orgId)),
  );

  const isLoading = useSelector(
    fromOrganizations.selectLoading(doGetOrgMigrationStatus.typePrefix),
  );

  const isMounted = useSelector(fromLayout.selectIsMountedMFE('katalon-project-management-container'));

  useEffect(() => {
    if (isLoading || !initialized || !config) {
      return;
    }
    if (orgMigrationStatus === undefined
      || orgMigrationStatus !== OrganizationMigrationStatus.MIGRATED) {
      if (flags?.projectStandardizeEnabled) {
        navigate(maintenanceProcess.path, replaceQuery({}));
        return;
      }
      if (flags?.projectManagementNewUIEnabled || config?.onpremise) {
        setRendered(true);
        return;
      }
      navigate(sso.path, replaceQuery({ redirect_uri: `${process.env.REACT_APP_ADMIN_URL}/organization/${orgId}/teams/projects` }));
      return;
    }
    setRendered(true);
  }, [isLoading, initialized, orgMigrationStatus, flags, config]);

  return (
    <>
      { (!rendered || !isMounted) && <LoadingProgress /> }
      <div id="katalon-project-management-container" />
      { rendered
        && (
          <MicroApp
            id="katalon-project-management"
            nodeId="katalon-project-management-container"
            path={process.env.REACT_APP_PROJECT_MANAGEMENT_MICROAPP_URL ?? ''}
            overrideConfig={{
              myBaseUrl: `${window.location.origin}${process.env.PUBLIC_URL}`,
              adminBaseUrl: `${process.env.REACT_APP_ADMIN_URL}`,
              customDomainPostfix: `${process.env.REACT_APP_CUSTOM_DOMAIN_POSTFIX}`,
              userGroupDetailPath: userGroupDetail.path,
            }}
          />
        )}
    </>
  );
};

export default ProjectManagementMicroApp;
