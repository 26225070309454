import Axios from 'axios';
import { DataResponse, PageableResponse } from '../models';
import { composeQuery, Query } from '../models/query';
import { OrganizationConfiguration } from '../models/organizationConfiguration';
import { CustomDomain, OrganizationCustomDomainAndSSO, SamlIdp } from '../models/organizationCustomDomainAndSSO';

export const get = async (...criteria: Query<OrganizationConfiguration>[]) => (
  await Axios.get<PageableResponse<OrganizationConfiguration>>('/v1/organization-configurations', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    params: composeQuery<OrganizationConfiguration>(...criteria),
    data: {},
  })
).data;

export const upsert = async (input: Required<Pick<OrganizationConfiguration, 'organizationId' | 'name'>[]>) => (await Axios.post<DataResponse<OrganizationConfiguration[]>>('/v1/organization-configurations', input)).data.data;

export const upsertDomainAndSSO = async (input: {
  organizationId: number;
  customDomain: CustomDomain;
  samlIdpDTO: SamlIdp | null;
}) => (await Axios.post<DataResponse<OrganizationCustomDomainAndSSO>>('/v1/organization-configurations/custom-domain-sso', input)).data.data;

export const getDomainAndSSO = async (organizationId: number) => (
  await Axios.get<DataResponse<OrganizationCustomDomainAndSSO>>(`/v1/organization-configurations/custom-domain-sso/${organizationId}`, {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    data: {},
  })
).data;
