import MuiAvatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { unwrapResult } from '@reduxjs/toolkit';
import cidrRegex from 'cidr-regex';
import ipRegex from 'ip-regex';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { inviteMember } from '../../layout/routes';
import { resolvePath, useQuery } from '../../routes';
import { resizeImage } from '../../services/organization';
import { fromAuth, fromCurrentOrgUser, fromOrganizations, fromOrganizationUsers, useAppDispatch } from '../../store';
import DeleteOrganizationDialog from './DeleteOrganizationDialog';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    borderRadius: '.5rem',
    padding: '1.5rem',
    overflow: 'auto',
  },
  section: {
    padding: '1.25rem 2.5rem',
    marginBottom: '1.25rem',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  title: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2),
  },
  sectionTitle: {
    paddingBottom: '1rem',
  },
  container: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  inviteButton: {
    float: 'right',
  },
  lastBodyElement: {
    paddingBottom: '1rem',
  },
  label: {
    marginBottom: '.5rem',
  },
  textBold: {
    marginBottom: '0.75rem',
  },
  table: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100vw - 8rem)',
    },
  },
  input: {
    display: 'none',
  },
  avatar: {
    height: '100%',
    width: '100%',
  },
  orgName: {
    width: '100%',
  },
  textField: {
    height: theme.spacing(5),
  },
  delete: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
  },
  infoBox: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: '1rem',
    [theme.breakpoints.down('md')]: {
      '& > *:first-child': {
        flexBasis: '100%',
      },
    },
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  avatarBox: {
    marginRight: '1rem',
  },
  avatarBlock: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      marginRight: '5rem',
    },
  },
  uploadLink: {
    cursor: 'pointer',
  },
  totalMember: {
    display: 'flex',
    flexDirection: 'column',
  },
  vertical: {
    display: 'flex',
    flexDirection: 'row',
  },
  addIcon: {
    bottom: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
  },
}));

const Avatar = withStyles({
  root: {
    width: 60,
    height: 60,
  },
})(MuiAvatar);

const Organization = () => {
  const classes = useStyles();
  const { get, queryDictionary } = useQuery();
  const orgId = get('orgId');
  const user = useSelector(fromAuth.selectUser);
  const orgTotalMember = useSelector(fromOrganizations.selectCount);
  const organization = useSelector(fromOrganizations.selectSelectedOrganization);
  const orgUsers = useSelector(fromOrganizationUsers.selectAllOrganizationUsers);
  const [orgUserUpdated, setOrgUserUpdated] = useState(false);
  const organizationUser = useSelector(
    fromCurrentOrgUser.selectByOrganizationIdAndEmail(user!.email, organization?.id || 0),
  );
  const totalMember = useSelector(fromOrganizations.selectCount);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [resizing, setResizing] = useState(false);
  const [orgName, setOrgName] = useState('');
  const [imageURI, setImageURI] = useState<string>();
  const [enableIdleTimeout, setEnableIdleTimeout] = useState(false);
  const [idleTimeout, setIdleTimeOut] = useState<number>();
  const [restrictedIps, setRestrictedIps] = useState<string>();
  const [ipError, setIpError] = useState(false);
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    analytics.page();
  }, []);

  /**
   * Send an update to the org-user after the page is loaded.
   * We make sure to do this just once, so we use a flag `orgUserUpdated`.
   */
  useEffect(() => {
    if (orgUserUpdated || !orgUsers.length || !dispatch || !user) return;
    const orgUser = orgUsers.find(orgUser => orgUser.organizationId === +orgId!
      && orgUser.userId === user.id);
    if (orgUser) {
      setOrgUserUpdated(true);
      dispatch(fromOrganizationUsers.doUpdateOrganizationUser({
        id: orgUser.id,
        role: orgUser.role,
        lastAccessedAt: new Date(Date.now()),
      }));
    }
  }, [orgUserUpdated, orgId, orgUsers, dispatch, user, organization]);

  useEffect(() => {
    if (organization) {
      setOrgName(organization.name);
      setRestrictedIps(organization.restrictedIps);
      setImageURI(organization.logo);
      if (organization.idleTimeout) {
        setEnableIdleTimeout(true);
        setIdleTimeOut(organization.idleTimeout);
      }
    }
  }, [organization]);

  const imageURISyncHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      try {
        setResizing(true);
        const resizedImage = await resizeImage(event.target.files[0]);
        setImageURI(resizedImage);
      } catch (error: any) {
        dispatch(fromOrganizations.doChangeError(error));
      } finally {
        setResizing(false);
      }
    }
  };

  const handleOrgNameChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(e => {
    setOrgName(e.target.value);
  }, []);

  const ipTest = (i: String) => {
    const itrim = i.trim();
    return ipRegex.v4({ exact: true }).test(itrim) || cidrRegex.v4({ exact: true }).test(itrim);
  };

  const handleIdleTimeoutChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(e => {
    setIdleTimeOut(+e.target.value || undefined);
  }, []);

  const handleRestrictedIpsChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(e => {
    setRestrictedIps(e.target.value);
    const ips = e.target.value.split(',');
    if (e.target.value === '' || ips.every(i => ipTest(i))) {
      setIpError(false);
      return;
    }
    setIpError(true);
  }, []);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async e => {
    e.stopPropagation();
    e.preventDefault();
    if (orgName && orgId) {
      setLoading(true);
      try {
        const result = await dispatch(fromOrganizations.doUpdateOrganization({
          id: +orgId,
          name: orgName.replace(/\s\s+/g, ' ').trim(),
          logo: imageURI,
          restrictedIps: restrictedIps ? restrictedIps.trim() : undefined,
          idleTimeout,
        }));
        unwrapResult(result);
        analytics.track(
          'Organization Information Updated',
          {
            user_id: user?.id,
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
            system_role: user?.roles,
            org_role: organizationUser?.role,
            org_id: organization?.id,
            old_org_name: organization?.name,
            org_name: orgName.replace(/\s\s+/g, ' ').trim(),
            total_members: orgTotalMember,
          },
        );
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          user_id: user?.id,
          org_role: organizationUser?.role,
          system_role: user?.roles,
          org_id: organization?.id,
          old_org_name: organization?.name,
          org_name: orgName.replace(/\s\s+/g, ' ').trim(),
          total_members: orgTotalMember,
          event: 'gtm_organization_information_updated',
        });
        enqueueSnackbar(<FormattedMessage id="organization.information.update.success" />, { variant: 'success' });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChangeIdleTimout = async (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setEnableIdleTimeout(checked);
    if (!checked && organization) {
      setLoading(true);
      try {
        await dispatch(fromOrganizations.doUpdateOrganization({
          id: organization.id,
          name: orgName.replace(/\s\s+/g, ' ').trim(),
          logo: imageURI,
          restrictedIps,
        }));
        enqueueSnackbar(<FormattedMessage id="organization.information.update.success" />, { variant: 'success' });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className={classes.root}>
      <Grid container className={classes.container}>
        <Typography variant="h3" className={classes.title}>
          {intl.formatMessage({ id: 'organization.management' })}
        </Typography>
      </Grid>

      <Paper className={classes.section} elevation={0}>
        <Typography variant="h4" className={classes.sectionTitle}>
          {intl.formatMessage({ id: 'organization.information' })}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box className={classes.infoBox}>
            <Box>
              <Box>
                <Typography
                  variant="h5"
                  className={classes.textBold}
                >
                  {intl.formatMessage({ id: 'createorganization.form.orgname' })}
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  value={orgName}
                  onChange={handleOrgNameChange}
                  className={classes.orgName}
                />
              </Box>
            </Box>

            <Box className={classes.totalMember}>
              <Box className={classes.vertical}>
                <Typography
                  variant="h5"
                  className={classes.textBold}
                >
                  {intl.formatMessage({ id: 'organization.information.total_member' })}
                </Typography>
                <Tooltip placement="top" title={intl.formatMessage({ id: 'organization.information.add_member' })}>
                  <RouterLink
                    to={resolvePath(inviteMember, undefined, queryDictionary())}
                    className={classes.addIcon}
                  >
                    <AddCircleRoundedIcon color="primary" fontSize="small" />
                  </RouterLink>
                </Tooltip>
              </Box>
              <Typography>{totalMember}</Typography>
            </Box>

            <Box>
              <Typography
                variant="h5"
                className={classes.textBold}
              >
                {intl.formatMessage({ id: 'organization.information.id' })}
              </Typography>
              <Typography>{organization?.id}</Typography>
            </Box>

            <Box className={classes.avatarBlock}>
              <Box className={classes.avatarBox}>
                <Avatar
                  variant="circular"
                  src={imageURI}
                  className={classes.avatar}
                >
                  {organization?.name[0]}
                </Avatar>
              </Box>
              <Box>
                <Typography
                  variant="h5"
                  className={classes.textBold}
                >
                  {intl.formatMessage({ id: 'organization.information.avatar' })}
                </Typography>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label
                  htmlFor="button-file"
                >
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link aria-label="upload picture" variant="body1" className={classes.uploadLink}>
                    {intl.formatMessage({ id: 'organization.information.upload_image' })}
                  </Link>
                  <input
                    accept="image/*"
                    type="file"
                    id="button-file"
                    onChange={e => imageURISyncHandler(e)}
                    hidden
                  />
                </label>
              </Box>
            </Box>
          </Box>
          <Box>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={
                resizing
                || loading
                || !organization
                || (orgName === organization?.name && !imageURI && !organization.logo)
                || (orgName === organization?.name && imageURI === organization.logo)
              }
            >
              {intl.formatMessage({ id: 'organization.information.update' })}
            </Button>
          </Box>
        </form>
      </Paper>

      <Paper className={classes.section} elevation={0}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4" className={classes.sectionTitle}>
                {intl.formatMessage({ id: 'organization.idle.timeout' })}
              </Typography>
              <Link href="https://docs.katalon.com/katalon-studio/docs/license-management.html#configure-idle-timeout" variant="body1">
                {intl.formatMessage({ id: 'organization.idle.timeout.kse' })}
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                className={classes.textBold}
              >
                {intl.formatMessage({ id: 'organization.idle.timeout.enable' })}
              </Typography>
              <FormControlLabel
                control={<Switch checked={enableIdleTimeout} color="primary" onChange={handleChangeIdleTimout} />}
                label={intl.formatMessage({ id: enableIdleTimeout ? 'organization.idle.timeout.enabled' : 'organization.idle.timeout.disabled' })}
              />
            </Grid>
            {enableIdleTimeout && (
              <>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    className={classes.textBold}
                  >
                    {intl.formatMessage({ id: 'organization.idle.timeout.minutes' })}
                  </Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                    autoFocus
                    required
                    value={idleTimeout}
                    onChange={handleIdleTimeoutChange}
                    inputProps={{ min: 1 }}
                    InputProps={{
                      className: classes.textField,
                      inputProps: { min: 1 },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={
                      loading || idleTimeout === organization?.idleTimeout
                      || !enableIdleTimeout
                    }
                  >
                    {intl.formatMessage({ id: 'organization.information.update' })}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </form>
      </Paper>

      <Paper className={classes.section} elevation={0}>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4" className={classes.sectionTitle}>
                {intl.formatMessage({ id: 'organization.ip.title' })}
              </Typography>
              <Typography className={classes.sectionTitle}>
                {intl.formatMessage({ id: 'organization.ip.prevent.unauthorized' })}
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                placeholder={intl.formatMessage({ id: 'organization.ip.validate' })}
                size="medium"
                value={restrictedIps}
                InputProps={{
                  className: classes.textField,
                }}
                onChange={handleRestrictedIpsChange}
                error={ipError}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={
                  loading || restrictedIps === organization?.restrictedIps || ipError
                  || (!restrictedIps && !organization?.restrictedIps)
                }
              >
                {intl.formatMessage({ id: 'organization.information.update' })}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>

      <Paper className={classes.section} elevation={0}>
        <Typography variant="h4" className={classes.sectionTitle}>
          {intl.formatMessage({ id: 'organization.information.danger_zone' })}
        </Typography>
        <Typography variant="body1" className={classes.delete}>
          {intl.formatMessage({ id: 'organization.information.delete' })}
        </Typography>
        <Typography className={classes.lastBodyElement}>
          {intl.formatMessage({ id: 'organization.information.delete_gone' })}
        </Typography>
        <DeleteOrganizationDialog />
      </Paper>

    </div>
  );
};

export default Organization;
