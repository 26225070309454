import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import debounce from 'lodash/fp/debounce';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { testCloudDashboard } from '../../layout/routes';
import { InvoiceState } from '../../models/invoice';
import { resolvePath, useQuery } from '../../routes';
import { fromOrganizations, useAppDispatch } from '../../store';
import {
  GET_INVOICE_CANNOT_READ_RECURLY_SUBSCRIPTION,
  GET_INVOICE_RECURLY_SUBSCRIPTION_NOT_EXISTED,
} from '../../store/invoiceSlice';
import { fromAuth, fromCurrentOrgUser, fromInvoices } from '../../store/rootReducer';
import Forbidden from '../forbidden';
import InvoiceTable from './InvoiceTable';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    height: '100%',
    overflowY: 'auto',
  },
  title: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2),
    display: 'flex',
    fontSize: theme.spacing(3),
  },
  section: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(1.5, 3),
  },
  thankyouTitle: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderBottom: '1px solid #DCDFE6',
    fontSize: theme.spacing(2.5),
    color: '#172B4D',
  },
  thankyouDescription: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: theme.spacing(2),
    fontWeight: '400',
  },
  manageLicenseButton: {
    height: theme.spacing(4),
    marginBottom: theme.spacing(2),
    fontWeight: '500',
  },
  tableCell: {
    padding: theme.spacing(1.75, 0),
    maxWidth: theme.spacing(33),
    '&.MuiTableCell-root': {
      paddingBottom: theme.spacing(1),
      borderBottom: '1px solid #DCDFE6',
    },
  },
  tableInnerCell: {
    padding: theme.spacing(2, 0),
    maxWidth: theme.spacing(33),
    color: '#172B4D',
  },
}));

const CheckOutQuotePaymentSuccess = () => {
  const classes = useStyles();
  const intl = useIntl();
  const { get, getAll } = useQuery();
  const dispatch = useAppDispatch();
  const orgId = get('orgId');
  const invoiceNumbers = getAll('invoiceNumber');
  const isUpgrading = get('upgrading') === 'true';
  const organization = useSelector(fromOrganizations.selectOrganizationById(Number(orgId)));
  const organizationName = `Organization ${organization?.name}`;
  const invoices = useSelector(fromInvoices.selectInvoiceByNumbers(invoiceNumbers));
  const user = useSelector(fromAuth.selectUser);
  const [loading, setLoading] = useState(true);
  const trialCount = useRef(0);
  const [isForbiddenError, setIsForbiddenError] = useState(false);
  const errors = useSelector(fromInvoices.selectErrors());
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!orgId) return;
    const fetchInformation = async () => {
      setLoading(true);
      await dispatch(fromCurrentOrgUser.doGetOrgUserByUserIdAndOrgId({
        id: user?.id || 0,
        organizationId: Number(orgId),
      }));
      if (invoiceNumbers.length > 0) trialCount.current += 1;
      await dispatch(
        fromInvoices.doGetInvoiceByInvoiceNumbers({
          accountId: Number(orgId),
          invoiceNumbers,
        }),
      );
      setLoading(false);
    };
    fetchInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!invoiceNumbers.length
      || !orgId
      || loading
      || trialCount.current > 4
    ) return;
    const pendingInvoices = invoices
      .filter(it => it?.state !== InvoiceState.FAILED && it?.state !== InvoiceState.PAID)
      .map(invoice => invoice!!.invoiceNumber!!);
    if (pendingInvoices.length === 0) return;
    debounce(4000, () => {
      const getInvoiceNumber = async () => {
        trialCount.current += 1;
        setLoading(true);
        await dispatch(
          fromInvoices.doGetInvoiceByInvoiceNumbers({
            accountId: Number(orgId),
            invoiceNumbers: pendingInvoices,
          }),
        );
        setLoading(false);
      };
      getInvoiceNumber().catch(() => {});
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices]);

  useEffect(() => {
    if (errors && errors[0]) {
      switch (errors[0].message) {
        case GET_INVOICE_RECURLY_SUBSCRIPTION_NOT_EXISTED:
          enqueueSnackbar(
            <FormattedMessage id="invoice_recurly_subscription_not_existed" />,
            {
              variant: 'error',
              preventDuplicate: true,
              key: intl.formatMessage({ id: 'invoice_recurly_subscription_not_existed' }),
              autoHideDuration: 2000,
              resumeHideDuration: 0,
            },
          );
          dispatch(fromInvoices.doResetErrors());
          setIsForbiddenError(true);
          break;
        case GET_INVOICE_CANNOT_READ_RECURLY_SUBSCRIPTION:
          enqueueSnackbar(
            <FormattedMessage id="invoice_cannot_read_recurly_subscription" />,
            {
              variant: 'error',
              preventDuplicate: true,
              key: intl.formatMessage({ id: 'invoice_cannot_read_recurly_subscription' }),
              autoHideDuration: 2000,
              resumeHideDuration: 0,
            },
          );
          dispatch(fromInvoices.doResetErrors());
          setIsForbiddenError(true);
          break;
        default:
          break;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  // if error code is permision errors -> 403 page but with the sidebars and stuff.
  if (isForbiddenError) {
    return <Forbidden />;
  }
  return (
    <div className={classes.root}>
      <Typography variant="h2" className={classes.title}>
        {intl.formatMessage({ id: 'subscriptions.testcloud.testcloud_subscription' })}
      </Typography>
      <Paper className={classes.section} elevation={0}>
        <Typography variant="h2" className={classes.thankyouTitle}>
          {isUpgrading
            ? intl.formatMessage({ id: 'payment_success.upgrade.title' })
            : intl.formatMessage({ id: 'payment_success.title' })}
        </Typography>
        <Typography variant="h3" className={classes.thankyouDescription}>
          {isUpgrading
            ? intl.formatMessage(
              { id: 'payment_success.upgrade.description' },
              {
                organizationName: <b>{organizationName}</b>,
                br: <br />,
                planName: (
                  <b>
                    {invoices
                      .map(it => it?.plan?.enterpriseProduct?.name)
                      .map((it, i) => (
                        <>
                          {i > 0 && (<span>,&nbsp;</span>)}
                          {it ? <span>{it}</span> : <Skeleton width={100} sx={{ display: 'inline-block' }} />}
                        </>
                      ))}
                  </b>
                ),
              },
            )
            : intl.formatMessage(
              { id: 'payment_success.description' },
              {
                organizationName: <b>{organizationName}</b>,
                br: <br />,
                planName: (
                  <b>
                    {invoices
                      .map(it => it?.plan?.enterpriseProduct?.name)
                      .map((it, i) => (
                        <>
                          {i > 0 && (<span>,&nbsp;</span>)}
                          {it ? <span>{it}</span> : <Skeleton width={100} sx={{ display: 'inline-block' }} />}
                        </>
                      ))}
                  </b>
                ),
              },
            )}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.manageLicenseButton}
          component={Link}
          to={resolvePath(testCloudDashboard, undefined, { orgId: orgId?.toString() })}
        >
          <Typography>
            {intl.formatMessage({ id: 'payment_success.manage_license.button' })}
          </Typography>
        </Button>
      </Paper>
      <Paper className={classes.section} elevation={0}>
        <Typography variant="h2" className={classes.thankyouTitle}>
          {intl.formatMessage({ id: 'payment_success.order_summary.title' })}
        </Typography>
        <InvoiceTable invoices={invoices} />
      </Paper>
    </div>
  );
};
export default CheckOutQuotePaymentSuccess;
