import {
  FormattedMessage,
  useIntl,
} from 'react-intl';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import {
  ReactElement,
  useEffect,
} from 'react';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { testOpsPlatformCheckout } from '../../../../layout/routes';
import { OrganizationFeature } from '../../../../models';
import {
  fromInvoices,
  fromStarterPackage,
  fromOrder,
} from '../../../../store/rootReducer';
import { MAX_TOTAL_AMOUNT, sendTrackingData } from '../../utils';
import { useNavigate } from '../../../../routes';

const useStyles = makeStyles((theme => ({
  stickyContainer: {
    right: theme.spacing(5.5),
    height: theme.spacing(13),
    width: theme.spacing(50),
    position: 'fixed',
    bottom: theme.spacing(0),
    padding: theme.spacing(0.5, 3, 1.5, 3),
    background: '#ffffff',
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
  },
  discountSticky: {
    height: theme.spacing(17),
  },
  checkoutButton: {
    margin: theme.spacing(2.5, 0, 0, 0),
  },
  totalPrice: {
    fontWeight: theme.typography.fontWeightBold,
    gap: theme.spacing(2),
    padding: theme.spacing(1.5, 0, 0.5, 0),
    marginRight: theme.spacing(2),
    color: '#276ef1',
    fontSize: theme.spacing(3),
  },
  totalNetPrice: {
    fontSize: 20,
    fontWeight: 700,
    color: '#276ef1',
    position: 'absolute',
    top: 0,
    right: 0,
    marginRight: theme.spacing(2),
    marginTop: 10,
  },
  containerPrice: {
    position: 'relative',
    width: '100%',
    height: 30,
    padding: theme.spacing(1, 0),
  },
  titlePrice: {
    fontSize: 15,
    fontWeight: theme.typography.fontWeightBold,
    color: '#727993',
    position: 'absolute',
    top: 0,
    bottom: 0,
  },
  valuePrice: {
    fontSize: 15,
    fontWeight: theme.typography.fontWeightBold,
    color: '#4d5369',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  pendingOrderTip: {
    fontSize: 10,
    fontWeight: 500,
    color: '#ffffff',
  },
})));

export interface PriceSummarySectionProps {
  accountId: number;
}

type QueryParam = {
  [key: string]: any; // 👈️ variable key
};

const PriceSummarySection = (props: PriceSummarySectionProps) => {
  const {
    accountId,
  } = props;
  const { navigate, replaceQuery } = useNavigate();
  const classes = useStyles();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const successEmail = <a target="_blank" href="mailto: success@katalon.com" rel="noreferrer">success@katalon.com</a>;
  const loading = useSelector(fromInvoices.selectLoading);
  const previewsNetTotal = useSelector(fromInvoices.selectPreviewsSubTotal);
  const previewsDiscountTotal = useSelector(fromInvoices.selectDiscountTotal);
  const previews = useSelector(fromInvoices.selectPreviews);
  const starterPackage = useSelector(fromStarterPackage.selectStarterPackage);
  const loadingPreviewSP = useSelector(fromStarterPackage.selectLoading);
  const pendingOrder = useSelector(fromOrder.selectPendingOrder);
  const isExceededMaxAmount = () => previewsNetTotal > MAX_TOTAL_AMOUNT;

  const handleOnClickCheckout = () => {
    if (previews.length > 0 || starterPackage !== undefined) {
      const checkoutPlatform = async () => {
        navigate(testOpsPlatformCheckout.path, replaceQuery(
          {
            accountId: `${accountId}`,
            ...(starterPackage) && { starterPackage: starterPackage?.name },
            ...generateParam(OrganizationFeature.TESTOPS_PLATFORM),
            ...generateParam(OrganizationFeature.PER_USER_KSE),
            ...generateParam(OrganizationFeature.UNLIMITED_ENGINE),
            ...generateParam(OrganizationFeature.TESTCLOUD_SESSION_WEB),
            ...generateParam(OrganizationFeature.TESTCLOUD_SESSION_WEB_DESKTOP),
            ...generateParam(OrganizationFeature.TESTCLOUD_SESSION_CROSS_BROWSER),
            ...generateParam(OrganizationFeature.TESTCLOUD_SESSION_MOBILE_APP),
            ...generateParam(OrganizationFeature.VISUAL_TESTING_PRO),
          },
        ));
      };
      checkoutPlatform().catch(() => {});
      sendTrackingData('payment_checkout_button_clicked', accountId);
    }
  };

  const generateParam = (feature?: OrganizationFeature) => {
    const prefix = OrganizationFeature.getFeaturePrefix(feature);
    const invoicePreview = previews.find(preview => preview.feature === feature);
    const featureParam: QueryParam = {};
    if (invoicePreview) {
      featureParam[`${prefix}Quota`] = invoicePreview.order?.newQuotaNumber;
      featureParam[`${prefix}Cycle`] = invoicePreview.order?.billingCycle;
      return featureParam;
    }
    return undefined;
  };

  const hasDiscount = (previewsDiscountTotal > 0);
  const isDisabledCheckoutButton = loading || loadingPreviewSP || isExceededMaxAmount() || (
    starterPackage === undefined && (previews.length === 0)
  ) || pendingOrder !== undefined;

  useEffect(() => {
    if (loading) return;

    if (isExceededMaxAmount()) {
      enqueueSnackbar(
        intl.formatMessage(
          { id: 'payment_status.total.exceeded.limit' },
          { email: successEmail },
        ),
        { variant: 'error' },
      );
    }
  }, [previewsNetTotal]);

  const renderViewPrice = (title: ReactElement, value: ReactElement) => (
    <div className={classes.containerPrice}>
      <p className={classes.titlePrice}>
        {title}
      </p>
      <Typography variant="h2" className={classes.totalPrice}>
        {
          loading
            ? <CircularProgress className={classes.totalNetPrice} size={20} />
            : value
        }
      </Typography>
    </div>
  );

  const getNetTotalPrice = () => {
    const previewTotal = previewsNetTotal ?? 0;
    const packagePrice = starterPackage?.discountedPrice ?? 0;
    return previewTotal + packagePrice;
  };

  return (
    <>
      <Grid container>
        <Grid item xs={8} />
        <Grid item xs={4}>
          <Box
            className={hasDiscount ? `${classes.stickyContainer} ${classes.discountSticky}` : classes.stickyContainer}
          >
            {
              renderViewPrice(
                (<FormattedMessage id="checkout.total_net_price" />),
                (
                  <p className={classes.valuePrice}>
                    $
                    {intl.formatNumber(getNetTotalPrice())}
                  </p>
                ),
              )
            }
            {hasDiscount && renderViewPrice(
              (<FormattedMessage id="checkout.discount" />),
              (
                <p className={classes.valuePrice}>
                  $
                  {intl.formatNumber(previewsDiscountTotal)}
                </p>
              ),
            )}
            {
              (isExceededMaxAmount())
              && (
                <Alert severity="error">
                  <FormattedMessage id="payment_status.total.exceeded.limit" values={{ email: successEmail }} />
                </Alert>
              )
            }
            <Tooltip
              title={pendingOrder ? (
                <div className={classes.pendingOrderTip}>
                  <FormattedMessage
                    id="bank.transfer.pending_order"
                  />
                </div>
              ) : ''}
              placement="top"
            >
              <span>
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={handleOnClickCheckout}
                  disabled={isDisabledCheckoutButton}
                  className={classes.checkoutButton}
                >
                  {intl.formatMessage({ id: 'billinginfo.checkout' })}
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default PriceSummarySection;
