import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme => ({
  cardTitle: {
    margin: theme.spacing(3, 0, 2),
    fontWeight: theme.typography.fontWeightBold,
    color: '#313B58',
    fontSize: 24,
  },
})));

const SurveyComplete = () => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h3" className={classes.cardTitle}>
        <FormattedMessage id="subscriptions.survey_complete.thank" />
      </Typography>
      <Divider />
      <Typography variant="body1">
        <FormattedMessage id="subscriptions.survey_complete.seeyah" />
      </Typography>
    </>
  );
};

export default SurveyComplete;
