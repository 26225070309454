import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { styled } from '@mui/material/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import Chip from '@mui/material/Chip';
import { useSelector } from 'react-redux';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { PaymentMethodType } from '../../../../models';
import { fromPaymentMethod } from '../../../../store/rootReducer';
import { useAppDispatch } from '../../../../store';
import { ReactComponent as InfoCircle } from '../../../icons/info-circle-white.svg';

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: theme.spacing(0.75),
    border: 'solid 1px #dbdde5',
  },
  cardSelected: {
    borderRadius: theme.spacing(0.75),
    border: 'solid 1px #1847ca',
    color: '#1847ca',
  },
  text: {
    fontSize: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
    flexGrow: 0,
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(0.5),
  },
  chip: {
    display: 'flex',
    flexDirection: 'row',
    width: 120,
    height: 21,
    backgroundColor: '#1847ca',
    borderRadius: theme.spacing(0.75),
  },
  chipLabelContainer: {
    display: 'flex',
  },
  chipLabel: {
    fontWeight: theme.typography.fontWeightLight,
    color: '#fff',
    fontSize: 14,
    marginTop: theme.spacing(0.25),
  },
  chipIcon: {
    display: 'flex',
    marginTop: theme.spacing(0.6),
    marginLeft: theme.spacing(0.75),
  },
}));

export interface PaymentMethod {
  icon: React.ReactElement;
  type: PaymentMethodType;
}

export interface PaymentMethodComponentProps {
  paymentMethod: PaymentMethod;
  isSelected: boolean;
}

export const DefaultPaymentMethodTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 10,
    fontWeight: 500,
    backgroundColor: '#616161',
    maxWidth: 'none',
  },
});

const PaymentMethodComponent = (props: PaymentMethodComponentProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const defaultPaymentMethod = useSelector(fromPaymentMethod.selectDefaultPaymentMethod);

  const {
    paymentMethod,
    isSelected,
  } = props;

  const selectPaymentMethod = () => {
    dispatch(fromPaymentMethod.doChangeSelectedPaymentMethod(paymentMethod.type));
    if (PaymentMethodType.BANK_TRANSFER === paymentMethod.type) {
      dispatch(fromPaymentMethod.doUpdateCardInformationInvalid(false));
    }
  };

  return (
    <Button
      id="payment-method-item"
      variant="outlined"
      className={isSelected ? classes.cardSelected : classes.card}
      style={{
        width: '200px',
        height: '80px',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: '16px',
        marginRight: '16px',
      }}
      onClick={selectPaymentMethod}
    >
      <Grid container>
        <Grid item xs={6}>
          {paymentMethod.icon}
        </Grid>
        <Grid item xs={6}>
          {defaultPaymentMethod === paymentMethod.type
            && (
              <Chip
                id="default.payment.method"
                className={classes.chip}
                label={(
                  <div className={classes.chipLabelContainer}>
                    <span className={classes.chipLabel}>
                      <FormattedMessage id="billinginfo.payment_method.default" />
                    </span>
                    <span className={classes.chipIcon}>
                      <DefaultPaymentMethodTooltip
                        arrow
                        placement="top"
                        title={intl.formatMessage({ id: 'billinginfo.payment_method.default.tooltip' })}
                      >
                        <InfoCircle />
                      </DefaultPaymentMethodTooltip>
                    </span>
                  </div>
                )}
              />
            )}
        </Grid>
        <Grid item className={classes.text} xs={12}>
          <FormattedMessage id={`billinginfo.payment_method.${paymentMethod.type}`} />
        </Grid>
      </Grid>
    </Button>
  );
};

export default PaymentMethodComponent;
