import Axios from 'axios';
import { DataResponse, Order, OrderResponse } from '../models';

export const getPendingOrder = async (accountId: number) => (
  await Axios.get<OrderResponse>('/v1/orders/pending-order', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    params: {
      account_id: accountId,
    },
    data: {},
  })
).data;

export const checkout = async (data: Order) => (
  await Axios.post<DataResponse<OrderResponse>>('/v1/orders/checkout', data)
).data;
