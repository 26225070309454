import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import { unwrapResult } from '@reduxjs/toolkit';
import throttle from 'lodash/throttle';
import { useEffect } from 'react';
import Avatar from 'react-avatar';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { home, invalidInvitation } from '../../layout/routes';
import { UserSsoInvitationStatus } from '../../models';
import { useNavigate, useQuery } from '../../routes';
import { fromAcceptInviteSsoPage, fromAuth, useAppDispatch } from '../../store';
import { CUTOMER_SUPPORT_EMAIL } from '../account/utils';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(10),
    flexDirection: 'column',
    textAlign: 'center',
  },
  message: {
    marginTop: theme.spacing(2),
  },
  warning: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(4),
    backgroundColor: '#FFFAE6', // Todo: move to system design
    padding: theme.spacing(2),
  },
  buttonContainer: {
    flexDirection: 'column',
  },
  button: {
    marginBottom: theme.spacing(3),
  },
  warningIcon: {
    color: '#FF7B1B',
    marginRight: theme.spacing(1),
  },
  avatarContainer: {
    marginBottom: theme.spacing(2),
  },
  supportEmail: {
    textDecoration: 'none',
    fontWeight: theme.typography.fontWeightBold,
    color: '#172B4D',
  },
  domain: {
    textDecoration: 'none',
  },
}));
const INVITATION_TOKEN_KEY = 'invitation_token';

const getSubdomainLink = (domain: string | undefined) => `https://${domain}.${process.env.REACT_APP_CUSTOM_DOMAIN_POSTFIX}`;

const AcceptSsoInvitation = () => {
  const classes = useStyles();
  const intl = useIntl();
  const { get } = useQuery();
  const { replace, redirect, replaceQuery } = useNavigate();
  const dispatch = useAppDispatch();
  const invitationToken = get(INVITATION_TOKEN_KEY) || '';
  const organization = useSelector(fromAcceptInviteSsoPage.selectOrganization());
  const loading = useSelector(fromAcceptInviteSsoPage.selectLoading());
  const submitting = useSelector(fromAcceptInviteSsoPage.selectSubmitting());
  const invitation = useSelector(fromAcceptInviteSsoPage.selectInvitation());
  const errors = useSelector(fromAcceptInviteSsoPage.selectErrors());
  const user = useSelector(fromAuth.selectUser);
  useEffect(() => {
    if (!invitationToken) {
      return;
    }
    const fetchInfo = () => {
      dispatch(fromAcceptInviteSsoPage.getInvitationByToken({ invitationToken }));
    };
    fetchInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationToken]);

  // delay after show toast
  const navigateToAdminHome = () => {
    setTimeout(() => {
      replace(home.path, replaceQuery({}));
    }, 1000);
  };

  // delay after show toast
  const navigateCustomDomain = () => {
    setTimeout(() => {
      redirect(getSubdomainLink(organization?.domain));
    }, 1000);
  };

  const navigateInvalidPage = () => {
    replace(invalidInvitation.path, replaceQuery({}));
  };

  const handleAcceptToEnableSso = throttle(
    async () => {
      const invitationRequest = {
        id: invitation?.id ?? 0,
        status: UserSsoInvitationStatus.ACCEPTED,
        invitationToken,
      };
      try {
        await dispatch(fromAcceptInviteSsoPage.acceptSsoInvitation(invitationRequest))
          .then(unwrapResult);
        navigateCustomDomain();
      // eslint-disable-next-line no-empty
      } catch (_) {}
    },
    500,
    { leading: true, trailing: false },
  );

  const handleDeclineToEnableSso = throttle(
    () => {
      dispatch(fromAcceptInviteSsoPage.declineSsoInvitation({ id: invitation?.id ?? 0 }))
        .finally(() => navigateToAdminHome());
    },
    500,
    { leading: true, trailing: false },
  );

  useEffect(() => {
    if (errors?.[0]?.message) {
      // since we dont have special behavior on submitting result, just navigate to invalid page
      navigateInvalidPage();
    }
    // eslint-disable-next-line
  }, [errors, invitationToken]);

  if (loading) {
    return <></>;
  }
  if (!organization || !invitation || invitation.status !== UserSsoInvitationStatus.PENDING
    || (user?.email !== invitation.email)) {
    navigateInvalidPage();
    return null;
  }

  return (
    <Grid container alignItems="center" justifyContent="center" className={classes.root}>
      <Grid item>
        <div className={classes.avatarContainer}>
          {organization.logo?.length ? (
            <img
              width={80}
              height={80}
              src={`/${organization.logo}`}
              title={organization.name}
              alt={organization.name}
            />
          ) : (
            <Avatar size="80" round name={organization.name} maxInitials={2} />
          )}
        </div>
        <Typography variant="h2">{organization?.name}</Typography>
        <Typography variant="h2">{intl.formatMessage({ id: 'user_invitation.sso.title' })}</Typography>
        <Typography variant="subtitle2" className={classes.message}>
          {intl.formatMessage(
            { id: 'user_invitation.sso.message' },
            {
              a: () => (
                <a className={classes.domain} href={getSubdomainLink(organization?.domain)}>
                  {getSubdomainLink(organization?.domain)?.replace('https://', '')}
                </a>
              ),
              link: organization?.domain,
              email: invitation?.email,
            },
          )}
        </Typography>
      </Grid>
      <Grid item className={classes.warning}>
        <Grid container alignItems="center">
          <WarningIcon className={classes.warningIcon} />
          <Typography variant="subtitle2">{intl.formatMessage({ id: 'user_invitation.sso.warning' })}</Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container className={classes.buttonContainer}>
          <Button
            disabled={submitting}
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleAcceptToEnableSso}
          >
            {intl.formatMessage({ id: 'user_invitation.sso.enable' })}
          </Button>
          <Button
            disabled={submitting}
            variant="text"
            className={classes.button}
            onClick={handleDeclineToEnableSso}
          >
            {intl.formatMessage({ id: 'user_invitation.sso.disable' })}
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2">
          {intl.formatMessage({ id: 'user_invitation.sso.security_message' })}
        </Typography>
        <Typography variant="subtitle2">
          {intl.formatMessage(
            { id: 'user_invitation.sso.security_message.support' },
            {
              email: 'support@katalon.com',
              a: () => (
                <a className={classes.supportEmail} href={CUTOMER_SUPPORT_EMAIL}>
                  support@katalon.com
                </a>
              ),
            },
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default AcceptSsoInvitation;
