import { createAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { AppState } from '.';
import {
  Organization,
  SerializedException,
  OrganizationUserSso,
  UserInvitation,
  exceptionOf,
  SsoOptionStatus,
} from '../models';
import { Operator, Query } from '../models/query';
import { OrganizationService, OrganizationUserSsoService, UserInvitationService } from '../services';

export const ACCEPT_INVITE_PAGE_KEY = 'accept_invite_page';
export const ERROR_USER_INVITATION_INVALID_LINK = 'user.invitation.invalid.link';
export const ERROR_USER_INVITATION_LIMITED_USER = 'user.invitation.exceeded.max.users';
export const ERROR_USER_INVITATION_EXCEEDED_LICENSES = 'user.invitation.number.of.licenses.exceeded';
export const ERROR_USER_INVITATION_EXPIRED_LINK = 'user.invitation.expired.link';
export const ERROR_USER_INVITATION_REVOKED_LINK = 'user.invitation.revoked.link';
export const ERROR_USER_INVITATION_ALREADY_JOINED_ORG = 'user.invitation.user.already.in.organization';
interface AcceptUserInvitationState {
  organization: Organization | null;
  invitation: UserInvitation[] | null;
  organizationUserSso: OrganizationUserSso | null;
  errors: SerializedException[];
  loading: boolean;
  submitting: boolean;
}

export const createInitialState = (): AcceptUserInvitationState => ({
  organization: null,
  invitation: null,
  loading: true,
  errors: [],
  submitting: false,
  organizationUserSso: null,
});

export const doResetState = createAction('acceptInvitation/resetState');
export const doGetUserInvitationData = createAsyncThunk(
  'acceptInvitation/getUserInvitationData',
  async (input: Pick<UserInvitation, 'invitationToken' | 'email'>) => {
    const criteria: Array<Query<UserInvitation>> = input.invitationToken.length > 0
      ? [
        { field: 'invitationToken', operator: Operator.EQ, value: input.invitationToken },
        { field: 'archived', operator: Operator.EQ, value: false },
        { field: 'accepted', operator: Operator.EQ, value: false },
        { limit: 1 },
      ]
      : [
        { field: 'email', operator: Operator.EQ, value: input.email },
        { field: 'archived', operator: Operator.EQ, value: false },
        { field: 'accepted', operator: Operator.EQ, value: false },
      ];
    // start fetch invitation by token
    const response = await UserInvitationService.getUserInvitations(...criteria);
    const orgId = response.data?.[0]?.organizationId || 0;
    const userId = response.data?.[0]?.invitedTestOpsUserId;
    const orgCriteria: Query<Organization> = {
      field: 'id',
      operator: Operator.EQ,
      value: orgId,
    };

    const orgResponse = await OrganizationService.getOrganizationByOrgId(orgCriteria);
    const organizationUserSsoCriteria: Query<OrganizationUserSso>[] = [
      {
        field: 'organizationId',
        operator: Operator.EQ,
        value: orgId,
      },
      {
        field: 'status',
        operator: Operator.EQ,
        value: SsoOptionStatus.PENDING,
      },
      {
        field: 'userId',
        operator: Operator.EQ,
        value: userId,
      },
    ];

    const organizationUserSso = await OrganizationUserSsoService.getOrganizationUserSsos(
      ...organizationUserSsoCriteria,
    );
    return {
      invitation: response.data,
      organization: orgResponse?.[0],
      organizationUserSso: organizationUserSso.data?.[0],
    };
  },
);

export const doAcceptInvitation = createAsyncThunk(
  'acceptInvitation/doAcceptInvitation',
  async (input: Pick<UserInvitation, 'id' | 'invitationToken'>, { rejectWithValue }) => {
    try {
      // start fetch invitation by token
      const response = await UserInvitationService.updateInvitation(input);
      return { invitation: response.data };
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doDeclineInvitation = createAsyncThunk(
  'acceptInvitation/doDeclineInvitation',
  async (input: Pick<UserInvitation, 'id' | 'type'>) => {
    // start fetch invitation by token
    const requestBody = {
      id: input.id,
      type: input.type,
    };
    const response = await UserInvitationService.deleteUserInvitation(requestBody);
    return { invitation: response };
  },
);

const acceptInvitationSlice = createSlice({
  name: ACCEPT_INVITE_PAGE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doGetUserInvitationData.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetUserInvitationData.rejected, (state, action) => {
      const payload = action.payload as SerializedException;
      state.errors.push(payload);
      state.loading = false;
    });
    builder.addCase(doGetUserInvitationData.fulfilled, (state, action) => {
      state.organization = action.payload.organization;
      state.invitation = action.payload.invitation;
      state.organizationUserSso = action.payload.organizationUserSso;
      state.loading = false;
    });
    builder.addCase(doResetState, state => {
      state.loading = true;
      state.submitting = false;
      state.errors = [];
    });
    builder.addCase(doAcceptInvitation.pending, state => {
      state.submitting = true;
    });
    builder.addCase(doAcceptInvitation.fulfilled, state => {
      state.submitting = false;
      state.errors = [];
    });
    builder.addCase(doAcceptInvitation.rejected, (state, action) => {
      const payload = action.payload as SerializedException;
      state.submitting = false;
      state.errors.push(payload);
    });
    builder.addCase(doDeclineInvitation.pending, state => {
      state.submitting = true;
    });
    builder.addCase(doDeclineInvitation.fulfilled, state => {
      state.submitting = false;
    });
    builder.addCase(doDeclineInvitation.rejected, state => {
      state.submitting = false;
    });
  },
});

const selectAcceptInvitation = (state: AppState) => state[ACCEPT_INVITE_PAGE_KEY];

export const selectLoading = () => createSelector(selectAcceptInvitation, state => state.loading);

export const selectSubmitting = () => (
  createSelector(selectAcceptInvitation, state => state.submitting)
);

export const selectErrors = () => createSelector(selectAcceptInvitation, state => state.errors);
export const selectInvitation = () => (
  createSelector(selectAcceptInvitation, state => state.invitation)
);
export const selectOrganization = () => (
  createSelector(selectAcceptInvitation, state => state.organization)
);
export const selectOrganizationUserSso = () => (
  createSelector(selectAcceptInvitation, state => state.organizationUserSso)
);

export default acceptInvitationSlice.reducer;
