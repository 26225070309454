import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import { FormattedMessage } from 'react-intl';
import { formatUSPrice } from '../utils';
import { Invoice, OrganizationFeature, getFeatureName, getUnitName } from '../../../models';
import { RecurlySubscriptionDTO } from '../../../models/recurlySubscriptionDTO';

interface SubscriptionDetailProps {
  invoice: Invoice,
}

const useStyles = makeStyles((() => ({
  container: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  tableCell: {
    fontSize: 14,
    fontWeight: 500,
    color: '#233145',
  },
  renewalPrice: {
    color: '#808b9a',
    fontSize: 14,
    textDecoration: 'line-through',
  },
  discountedPrice: {
    color: '#1847ca',
    fontSize: 14,
  },
})));

const SubscriptionDetail = (props: SubscriptionDetailProps) => {
  const classes = useStyles();
  const {
    invoice,
  } = props;

  const feature = invoice.subscriptions
    ? (invoice.subscriptions[0].feature || OrganizationFeature.PER_USER_KSE)
    : OrganizationFeature.PER_USER_KSE;

  const getDateSubscription = (subscription: RecurlySubscriptionDTO) => new Date(
    subscription.endDate,
  ).toLocaleDateString(
    'en-US',
    {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
  );

  const getQuantity = () => {
    if (feature === OrganizationFeature.TESTOPS_PLATFORM){
      return invoice.subscriptions ? invoice.subscriptions[0].quantity : 0;
    }
    return invoice.quantity;
  };

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={4} className={classes.tableCell}>
          {invoice.nameProduct || ''}
        </Grid>
        <Grid item xs={2} className={classes.tableCell}>
          {getQuantity()}
          &nbsp;
          <FormattedMessage id={getUnitName(getFeatureName(feature), getQuantity())} />
        </Grid>
        <Grid item xs={2.5} className={classes.tableCell}>
          {invoice.subscriptions ? getDateSubscription(invoice.subscriptions[0]) : ''}
        </Grid>
        <Grid item xs={3} className={classes.tableCell}>
          <span className={classes.renewalPrice}>
            $
            {formatUSPrice(invoice.subTotal || 0)}
          </span>
          <span>&nbsp;&nbsp;</span>
          <span className={classes.discountedPrice}>
            $
            {formatUSPrice(invoice.subscriptions ? (invoice.subscriptions[0].subTotal || 0) : 0)}
          </span>
        </Grid>
      </Grid>
    </div>
  );
};

export default SubscriptionDetail;
