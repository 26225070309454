import { stringify } from 'querystring';
import Axios from 'axios';
import { DataResponse, SingleProductOrder, OrganizationFeature, PageableResponse } from '../models';
import { Invoice } from '../models/invoice';
import { StarterPackageOrder } from '../models/starterPackageOrder';
import { StarterPackagePreview } from '../models/StarterPackagePreview';

export const getInvoices = async (invoiceRequest: Required<Pick<Invoice, 'accountId'>> & Required<{ invoiceNumbers: string[] }>) => (await Axios.get<DataResponse<Invoice[]>>('/v1/invoices', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: {
    account_id: invoiceRequest.accountId,
    invoice_number: invoiceRequest.invoiceNumbers,
  },
  paramsSerializer: {
    serialize: params => stringify(params),
  },
  data: {},
})).data.data;

export const getPendingInvoices = async (invoiceRequest: Required<Pick<Invoice, 'organizationId'>> & Required<{ features: OrganizationFeature[] }>) => (await Axios.get<PageableResponse<Invoice>>('/v1/invoices/pending', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: {
    organization_id: invoiceRequest.organizationId,
    features: invoiceRequest.features.map(it => it.toString()),
  },
  paramsSerializer: {
    serialize: params => stringify(params),
  },
  data: {},
})).data.data;

export const previewInvoice = async (data: Required<Pick<SingleProductOrder, 'organizationId' | 'planId' | 'number'>> & Partial<Pick<SingleProductOrder, 'discountCode' | 'newQuotaNumber'>>) => (await Axios.post<DataResponse<Invoice>>('/v1/invoices/preview', data)).data.data;

export const previewStarterPackageInvoice = async (data: Required<StarterPackageOrder>) => (await Axios.post<DataResponse<StarterPackagePreview>>('/v1/invoices/preview-starter-package', data)).data.data;

export const previewRenewal = async (accountId: number) => (await Axios.post<DataResponse<Invoice[]>>(`/v1/invoices/preview-renewal/${accountId}`, {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
})).data.data;

export const fetchPastDueInvoice = async (accountId: number) => (await Axios.get<DataResponse<Invoice[]>>(`/v1/invoices/past-due/${accountId}`, {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
})).data.data;

export const retryPayment = async (accountId: number) => (await Axios.post<DataResponse<string>>(`/v1/invoices/past-due/retry/${accountId}`, {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
})).data.data;
