import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { useConfig } from '../../../../config';

const DEBUG_MODE_LINK = 'https://docs.katalon.com/docs/author/debug-a-test-case/debug-a-test-case-in-katalon-studio#debug-mode';

const useStyles = makeStyles(theme => ({
  dialog: {
    padding: theme.spacing(2, 2.5),
    color: '#233145',
    borderRadius: theme.spacing(0.75),
  },
  title: {
    padding: '0px !important',
    margin: 0,
    marginBottom: theme.spacing(1),
  },
  titleText: {
    fontWeight: 500,
    fontSize: '1.25rem',
  },
  closeIconButton: {
    padding: 0,
  },
  content: {
    textAlign: 'left',
    padding: 0,
    color: '#233145',
  },
  list: {
    paddingLeft: theme.spacing(2.5),
  },
  warningText: {
    margin: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
  },
  contentText: {
    fontSize: '0.875rem',
  },
  upgradeButton: {
    minWidth: theme.spacing(12),
  },
  closeButton: {
    color: '#233145',
    fontWeight: 500,
  },
  link: {
    color: '#276ef1',
  },
}));

interface PlatformWarningDialogProps {
  subscriptionLink: string;
  isOpen: boolean;
  onClose: () => void;
}

const PlatformWarningDialog = (props: PlatformWarningDialogProps) => {
  const { subscriptionLink, onClose, isOpen } = props;
  const classes = useStyles();
  const intl = useIntl();
  const { config } = useConfig();

  const handleClose = () => {
    onClose();
  };

  if (!config) return <></>;

  return (
    <Dialog
      fullWidth
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      classes={{
        paper: classes.dialog,
      }}
    >
      <DialogTitle className={classes.title}>
        <Box
          display="flex"
          flexDirection="row"
          alignContent="center"
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography className={classes.titleText}>
              {intl.formatMessage({ id: 'dashboard.warning_platform_dialog.title' })}
            </Typography>
          </Box>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            size="small"
            disableTouchRipple
            className={classes.closeIconButton}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {config.onpremise
          ? (
            <Typography className={classes.warningText} variant="body1" gutterBottom>
              {
                intl.formatMessage({ id: 'dashboard.upgrade_contact' }, {
                  email: 'business@katalon.com',
                  a: () => <a href="mailto:business@katalon.com">business@katalon.com</a>,
                })
              }
            </Typography>
          ) : (
            <ul className={classes.list}>
              <li>
                <Typography className={classes.contentText}>
                  {intl.formatMessage({ id: 'dashboard.warning_platform_dialog.upgrade' }, {
                    a: (data: ReactNode) => (
                      <Link
                        className={classes.link}
                        component={RouterLink}
                        underline="none"
                        to={subscriptionLink}
                        onClick={handleClose}
                      >
                        {data}
                      </Link>
                    ),
                  })}
                </Typography>
              </li>
              <li>
                <Typography className={classes.contentText}>
                  {intl.formatMessage({ id: 'dashboard.warning_platform_dialog.debug' }, {
                    a: (data: ReactNode) => (
                      <Link
                        className={classes.link}
                        underline="none"
                        href={DEBUG_MODE_LINK}
                      >
                        {data}
                      </Link>
                    ),
                  })}
                </Typography>
              </li>
            </ul>
          )}
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.closeButton}
          onClick={handleClose}
          variant="contained"
        >
          <Typography fontWeight={500}>
            <FormattedMessage
              id="common.close"
            />
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default PlatformWarningDialog;
