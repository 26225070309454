import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { unwrapResult } from '@reduxjs/toolkit';
import memorize from 'lodash/fp/memoize';
import orderBy from 'lodash/orderBy';
import some from 'lodash/some';
import uniqBy from 'lodash/uniqBy';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';

import Tooltip from '@mui/material/Tooltip';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import { useConfig } from '../../config';
import { useLaunchDarkly } from '../../launchdarkly';
import LoadingProgress from '../../layout/LoadingProgress';
import {
  getFeatureOfHybridFeature,
  getFullNameUser,
  isHybridFeature,
  isK1Invitation,
  isKitInvitation,
  isKreFloatingFeature,
  isKseFeature,
  isOrganizationAdminOrOwner,
  isStudioFeature,
  OrganizationFeature,
  OrganizationRole,
  OrganizationUser,
  OrganizationUserFeature,
  Role,
  SsoOption,
  Subscription,
} from '../../models';
import { isInSystemMaintenance } from '../../models/config';
import { forbidden, useNavigate, useQuery } from '../../routes';
import { TestOpsTeamService } from '../../services';
import {
  fromAccounts,
  fromAccountUsers,
  fromAuth,
  fromCurrentOrgUser,
  fromOrganizationFeatureFlag,
  fromOrganizationRemovedUser,
  fromOrganizations,
  fromOrganizationUserFeature,
  fromOrganizationUsers,
  fromOrganizationUserSso,
  fromSubscriptions,
  fromTestOpsSubscriptions,
  fromTestOpsUserSsoInvitation,
  fromTestResultCount,
  fromUserInvitation,
  fromUserPendingInvitationTab,
  fromUserSsoInvitation,
  useAppDispatch,
} from '../../store';
import {
  ERROR_USER_INVITATION_EXCEEDED_LICENSES,
  ERROR_USER_INVITATION_LIMITED_USER,
} from '../../store/acceptInvitationPageSlice';
import { fromTestOpsPlatformSubscriptions, fromTestOpsProject, fromTestOpsTeam } from '../../store/rootReducer';
import { ERROR_ORGANIZATION_USER_NOT_EXISTED, ERROR_TEAM_USER_EXISTED } from '../../store/testOpsTeamSlice';
import ActiveUsersTable from './active-users/ActiveUsersTable';
import ConfirmChangeDialog from './ConfirmChangeDialog';
import EditSsoOptionsDialog from './EditSsoOptionsDialog';
import ExportActiveAndRemovedUsersButton from './ExportActiveAndRemovedUsersButton';
import InviteUsersDialogV2 from './invite-users-dialog-v2';
import PendingInvitationTable from './pending-invitations/PendingInvitationTable';
import RemovedUsersTable from './removed-users/RemovedUsersTable';
import { AvailableSubscription, defaultLoginOptions, LoginOptions, SubscriptionInvitation, UserTable } from './utils';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    borderRadius: '.5rem',
    padding: '1.5rem',
    overflowY: 'auto',
  },
  titleContainer: {
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(3),
  },
  paper: {
    width: '100%',
    flexDirection: 'column',
    margin: theme.spacing(0, 2, 2, 2),
    borderRadius: theme.spacing(1),
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(0, 0.5),
    minWidth: theme.spacing(5),
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  tableHeader: {
    display: 'flex',
    margin: theme.spacing(1, 0),
  },
  tabContainer: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  table: {
    margin: theme.spacing(1, 0),
  },
  titleButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  globalSearch: {
    color: '#516393',
    padding: theme.spacing(0, 1),
  },
}));

const tabs = ['active-users', 'pending-invitations', 'removed-users'];

const getTabIndex = memorize((tabName: string | undefined) => (
  tabs.findIndex(it => it === tabName) >= 0 ? tabs.findIndex(it => it === tabName) : 0
));

const getLicenseAccessList = (
  predicate: (_: OrganizationUserFeature) => boolean,
  subscriptions: Subscription[],
  userFeatures: OrganizationUserFeature[],
) => {
  // logic check:
  // - if org has both KRE F and NL; user has no KRE => return KRE NL + user's licenses;
  // - if org has both KRE F and NL; user has KRE-NL => return user's licenses;
  // - if org has both KRE F and NL; user has KRE-F => return user's licenses;
  // - if org has KRE F; user has no KRE => return KRE F + user's licenses;
  // - if org has KRE NL; user has no KRE => return KRE NL + user's licenses;
  // - if org has KRE NL / F ; user has KRE NL / F  => return user's licenses;
  const orgHasKreNl = subscriptions
    .find(it => it.feature === OrganizationFeature.ENGINE) !== undefined;
  const orgHasKreFl = subscriptions
    .find(it => isKreFloatingFeature(it.feature)) !== undefined;
  const featuresOfUser = userFeatures
    .filter(predicate)
    .map(it => it.feature);
  const userHasKreFl = featuresOfUser
    .find(it => it === OrganizationFeature.UNLIMITED_ENGINE) !== undefined;
  const userHasKreNl = featuresOfUser
    .find(it => it === OrganizationFeature.ENGINE) !== undefined;

  const finalFeaturesOfUser = (orgHasKreNl && !userHasKreFl && !userHasKreNl)
    ? [...featuresOfUser, OrganizationFeature.ENGINE]
    : (!orgHasKreNl && orgHasKreFl && !userHasKreFl && !userHasKreNl)
      ? [...featuresOfUser, OrganizationFeature.UNLIMITED_ENGINE]
      : featuresOfUser;
  const activeSubscriptionFeatures = subscriptions.map(it => getFeatureOfHybridFeature(it.feature));
  const finalActiveFeatures = finalFeaturesOfUser.filter(
    it => activeSubscriptionFeatures.includes(it),
  );
  return Array.from(new Set(finalActiveFeatures
    .map(f => OrganizationFeature.getFeatureHalfFullName(f))));
}; // remove duplicate value

const UserManagement = () => {
  const classes = useStyles();
  const { config } = useConfig();
  const intl = useIntl();
  const { get, clear, queryDictionary } = useQuery();
  const { replaceQuery, mergeQuery, replace: navigateReplace } = useNavigate();
  const orgId = get('orgId');
  const popupView = get('view') || '';
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const user = useSelector(fromAuth.selectUser);
  const currentOrgUser = useSelector(fromOrganizationUsers.selectByUserEmailAndOrganizationId(
    user?.email || '',
    Number(orgId),
  ));
  const location = useLocation();
  const { flags } = useLaunchDarkly();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedUsers, setSelectedUsers] = useState<UserTable[]>([]);
  const currentTab = location.pathname.split('/').pop();
  const [selectedTabIndex, setSelectedTabIndex] = useState(getTabIndex(currentTab));
  const isRootOrAdmin = useSelector(fromAuth.selectIsRootOrAdmin);
  const [removeUserDialogOpen, setRemoveUserDialogOpen] = useState(false);
  const [revokeInvitationDialogOpen, setRevokeInvitationDialogOpen] = useState(false);
  const [revokeSsoInvitationDialogOpen, setRevokeSsoInvitationDialogOpen] = useState(false);
  const [resendInvitationDialogOpen, setResendInvitationDialogOpen] = useState(false);
  const [changeRoleDialogOpen, setChangeRoleDiaglogOpen] = useState(false);
  const [inviteUsersDialogOpen, setInviteUsersDialogOpen] = useState(false);
  const [editSsoOptionsDialogOpen, setEditSsoOptionsDialogOpen] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [didFetchInvitations, setDidFetchInvitations] = useState(false);
  const [organizationUserId, setOrganizationUserId] = useState(0);
  const [loginOptions, setLoginOptions] = useState<LoginOptions>(defaultLoginOptions);
  const [selectedSubs, setSelectedSubs] = useState<SubscriptionInvitation[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<number[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<number[]>([]);

  // ***** Get init data from selector:
  const isSsoFlagEnabled = useSelector(fromOrganizationFeatureFlag.selectIsOrganizationSsoEnabled(
    Number(orgId),
  ));
  const currentOrganization = useSelector(fromOrganizations.selectSelectedOrganization);

  const accountId = currentOrganization?.accountId;
  const hasTOEnterprise = useSelector(fromSubscriptions
    .selectHasTOEnterprise());
  const hasTOPlatformUltimate = useSelector(fromSubscriptions
    .selectHasTOPlatformUltimate());
  // eslint-disable-next-line max-len
  const isSsoEnabled = (hasTOEnterprise || hasTOPlatformUltimate || isSsoFlagEnabled) && currentOrganization?.samlSsoEnabled;
  const organizationUserSsos = useSelector(fromOrganizationUserSso
    .selectByOrganizationId(Number(orgId)));
  const currentOrganizationUser = useSelector(
    fromCurrentOrgUser.selectByOrganizationIdAndEmail(
      user?.email || '',
      Number(orgId),
    ),
  );

  const users = useSelector(fromOrganizationUsers.selectByOrganizationId(
    Number(orgId) || 0,
  ));
  const userFeatures = useSelector(fromOrganizationUserFeature.selectFeaturesByOrganizationId(
    Number(orgId) || 0,
  ));
  const invitations = useSelector(fromUserInvitation.selectInvitationsByOrganizationId(
    Number(orgId) || 0,
  ));
  const subscriptions = useSelector(fromSubscriptions
    .selectAllSubscription());

  const removedUsers = useSelector(fromOrganizationRemovedUser
    .selectOrganizationRemovedUserByOrganizationId(Number(orgId) || 0));

  const userInvitationItemLoadingState = useSelector(fromUserPendingInvitationTab
    .selectAllUserInvitationLoadingState);

  const teams = useSelector(fromTestOpsTeam
    .selectTeamsOfOwnerOrAdminByOrgId(Number(orgId) || 0)) || [];

  const projects = useSelector(fromTestOpsProject
    .selectAllProjectsByOrganizationId(Number(orgId) || 0)) || [];
  // ***** end getting init data

  // ***** mapping specific data sets for specific tables:
  const checkSelectable = useCallback((userRole: OrganizationRole) => {
    // check selectable:
    // if currentUser is owner => checkbox in their record is hidden
    // if current row role is owner => hide checkbox
    // if currentUser is admin => hide the peer admins's checkboxes and other owners's
    // if currentUser is billing mnger => hide all checkbox (readonly)
    if (currentOrganizationUser?.role === OrganizationRole.BILLING_MANAGER) return false;
    if (userRole === OrganizationRole.OWNER) return false;
    return (!(currentOrganizationUser?.role === OrganizationRole.ADMIN
      && (userRole === OrganizationRole.ADMIN)));
  }, [currentOrganizationUser]);

  const usersOrg: UserTable[] = useMemo(() => (
    users.map(orgUser => {
      const userFeaturePredicate = (userFeature: OrganizationUserFeature) => orgUser.id
        === userFeature.organizationUserId;
      const features = getLicenseAccessList(
        userFeaturePredicate,
        subscriptions,
        userFeatures,
      );
      // joinDate is createdAt date of user in org, not the createdAt date of user
      const { createdAt } = orgUser;
      const orgUserSso = organizationUserSsos.find(
        it => it.organizationUserId === orgUser.id && it.option === SsoOption.SSO_AUTH,
      );
      return {
        ...orgUser.user,
        orgRole: orgUser.role,
        licenseAccess: features,
        createdAt,
        invitedBy: orgUser.invitedBy,
        isSelectable: checkSelectable(orgUser.role),
        // set the id of UserTable to OrganizationUser's id
        id: orgUser.id,
        hasSsoEnabled: orgUserSso !== undefined,
        ssoStatus: orgUserSso?.status,
        hasKseLicense: some(
          userFeatures.filter(userFeaturePredicate),
          it => isKseFeature(it.feature),
        ),
      };
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [users, userFeatures, currentOrganizationUser?.role]);
  const userInvitations: UserTable[] = useMemo(() => (
    // 1. Map invitation to UserTable
    // 2. Sort by type to get KatOne (type = K1) invitation first
    // 3. Sort by id to get latest invitation first
    // 4. Reverse array to get by unique email
    // 5. Get unique by email to avoid duplicate data
    // 6. Reverse array to keep ordinal as before sorting and get unique by email
    uniqBy(orderBy(invitations.map(invitation => {
      const orgRole = OrganizationRole.MEMBER;
      const { invitedUserId, type } = invitation;
      if (isK1Invitation(invitation.type)) {
        const features = getLicenseAccessList(
          userFeature => invitation.invitedTestOpsUserId === userFeature.userId,
          subscriptions,
          userFeatures,
        );
        const invitationLink = config?.onpremise && invitation.resetPasswordCode
          ? `${config?.publicUrl}/login?reset_password_code=${invitation.resetPasswordCode}&redirect=/accept-invitation?invitation_token=${invitation.invitationToken}&source=accept-invitation`
          : `${config?.publicUrl}/accept-invitation?invitation_token=${invitation.invitationToken}&source=accept-invitation`;
        return {
          ...invitation,
          licenseAccess: features,
          roles: invitation.user?.roles || [Role.USER],
          orgRole,
          invitationLink,
          invitedUserId,
          isSelectable: checkSelectable(orgRole),
          // set the id of UserTable to invitation's id
          id: invitation.id,
          type,
          hasSsoEnabled: organizationUserSsos.find(
            it => it.userId === invitation.invitedTestOpsUserId && it.option === SsoOption.SSO_AUTH,
          ) !== undefined,
          invitedTestOpsUserId: invitation.invitedTestOpsUserId,
          invitedBy: invitation.inviterUserEmail,
        };
      } // invitations from kit:
      const features = getLicenseAccessList(
        userFeature => invitation.invitedUserId === userFeature.userId,
        subscriptions,
        userFeatures,
      );
      // TestOps activation page using param named `token`
      // TODO: Configure Admin Public URL for OnPremise instead of using window location host
      const adminHost = config?.onpremise
        ? `${window.location.origin}`
        : process.env.REACT_APP_ADMIN_URL;
      const invitationLink = config?.onpremise && invitation.resetPasswordCode
        ? `${adminHost}/reset-password?token=${invitation.resetPasswordCode}&redirect=/accept-invitation?token=${invitation.invitationToken}`
        : `${adminHost}/accept-invitation?token=${invitation.invitationToken}`;
      return {
        lastName: invitation.user.lastName,
        firstName: invitation.user.firstName,
        email: invitation.user.email,
        roles: invitation.user?.roles,
        archived: false,
        createdAt: invitation?.createdAt,
        updatedAt: invitation?.updatedAt,
        licenseAccess: features,
        orgRole,
        invitationLink,
        invitedUserId,
        isSelectable: checkSelectable(orgRole),
        // set the id of UserTable to invitation's id
        id: invitation.id,
        type,
        hasSsoEnabled: organizationUserSsos.find(
          it => it.userId === invitation.invitedUserId && it.option === SsoOption.SSO_AUTH,
        ) !== undefined,
        invitedBy: invitation.inviterUserEmail,
      };
    }), ['type', 'id'], ['asc', 'desc']), 'email').reverse()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [invitations, userFeatures, currentOrganizationUser?.role]);
  // @ts-ignore
  const userRemovedData: UserTable[] = useMemo(() => (
    removedUsers.map(removedUser => ({
      ...removedUser,
      removalDate: removedUser.removedAt,
      orgRole: removedUser.role,
      lastLogin: removedUser.lastAccessedAt,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [removedUsers, userFeatures, currentOrganizationUser?.role]);

  const selectedSsoUsers = useMemo(
    () => (
      selectedUsers.filter(it => organizationUserSsos
        .filter(organizationUserSso => organizationUserSso.option === SsoOption.SSO_AUTH)
        .find(organizationUserSso => (
          it.invitedTestOpsUserId
            ? it.invitedTestOpsUserId === organizationUserSso.userId
            : it.invitedUserId === organizationUserSso.userId
        )) !== undefined)
    ),
    [selectedUsers, organizationUserSsos],
  );

  const availableSubs: AvailableSubscription[] = useMemo(
    () => sortBy(
      subscriptions.map(it => {
        const currentSubName = it.feature;
        const countRemainSub = it.quantity - userFeatures
          .filter(it => it.feature === currentSubName).length;
        return {
          feature: currentSubName,
          remainingLicenses: countRemainSub < 0 ? 0 : countRemainSub,
          total: it.quantity,
          featureHalfFullName: OrganizationFeature.getFeatureHalfFullName(currentSubName),
          source: it.source,
        };
      }).filter(it => !isHybridFeature(it.feature)),
      sub => sub.featureHalfFullName,
    ).reverse(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subscriptions, userFeatures.length],
  );

  const hasKseOrKreLicense = useMemo(
    () => subscriptions.some(sub => isStudioFeature(sub.feature)),
    [subscriptions],
  );

  // ***** end mapping data sets.

  // ***** handlers:
  const resetSelectedItems = (removedItemIds: number[]) => (
    setSelectedUsers(
      selectedUsers.filter(selectedUser => (
        removedItemIds.findIndex(it => it === selectedUser.id) === -1
      )),
    )
  );

  const handleChangeRoleClick = async () => {
    setChangeRoleDiaglogOpen(true);
  };
  const handleChangeRoleClose = () => {
    setChangeRoleDiaglogOpen(false);
  };
  const handleChangeRoleSubmit = async (
    listOrgUser: UserTable[],
    role: OrganizationRole | undefined,
  ) => {
    if (role) {
      const updatedIds: number[] = [];
      handleChangeRoleClose();
      setLoading(true);
      // eslint-disable-next-line no-restricted-syntax
      for (const orgUser of listOrgUser) {
        try {
          // eslint-disable-next-line no-await-in-loop
          await dispatch(fromOrganizationUsers.doUpdateOrganizationUser({ id: orgUser.id, role }))
            .then(unwrapResult)
            .then(() => updatedIds.push(orgUser.id));
        } catch (_) {
          setLoading(false);
          resetSelectedItems(updatedIds);
          if (config?.maintenanceStart) {
            return;
          }
          enqueueSnackbar(
            <FormattedMessage
              id="user_management.change_role.failed"
              values={{ count: selectedUsers.length - updatedIds.length }}
            />,
            { variant: 'error' },
          );
          return;
        }
      }
      resetSelectedItems(updatedIds);
      enqueueSnackbar(
        <FormattedMessage
          id="user_management.change_role.success"
          values={{ count: listOrgUser.length, role: OrganizationRole.getRoleFullName(role) }}
        />,
        { variant: 'success' },
      );
      setLoading(false);
    }
  };

  const handleRemoveUserClick = async () => {
    setRemoveUserDialogOpen(true);
  };
  const handleRemoveUserClose = () => {
    setRemoveUserDialogOpen(false);
  };
  const handleRemoveUserSubmit = async (listOrgUser: UserTable[]) => {
    setRemoveUserDialogOpen(false);
    const removedIds: number[] = [];
    setLoading(true);
    // eslint-disable-next-line no-restricted-syntax
    for (const orgUser of listOrgUser) {
      try {
        // eslint-disable-next-line no-await-in-loop
        await dispatch(fromOrganizationUsers.doRemoveOrganizationUser(orgUser.id))
          .then(unwrapResult)
          .then(() => removedIds.push(orgUser.id));
      } catch (_) {
        setLoading(false);
        resetSelectedItems(removedIds);
        if (isInSystemMaintenance(config)) {
          return;
        }
        enqueueSnackbar(
          // it should be remaining unchange items
          <FormattedMessage id="user_management.remove_user.failed" values={{ count: listOrgUser.length - removedIds.length }} />,
          { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } },
        );
        return;
      }
    }
    resetSelectedItems(removedIds);
    dispatch(fromOrganizationUsers.doRemoveBulkOrganizationUsers(removedIds));
    enqueueSnackbar(
      <FormattedMessage
        id="user_management.remove_user.success"
        values={{ count: listOrgUser.length }}
      />,
      { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } },
    );
    reloadDataInPage();
    setLoading(false);
  };
  const handleResendClick = async () => {
    setResendInvitationDialogOpen(true);
  };

  const handleResendInvitationClose = () => {
    setResendInvitationDialogOpen(false);
  };
  const handleResendInvitationSubmit = async (invitations: UserTable[]) => {
    setResendInvitationDialogOpen(false);
    const resendIds: number[] = [];
    setLoading(true);

    // eslint-disable-next-line no-restricted-syntax
    for (const invitation of invitations) {
      const invitationPayload = {
        invitedUserEmail: invitation.email.toLowerCase(),
        organizationId: Number(orgId),
        ssoOptions: [], // use empty for now
        assignedFeatures: [],
        // no need to input again, be will skip the assign if already has a pending.
      };
      try {
        // eslint-disable-next-line no-await-in-loop
        await dispatch(fromUserInvitation.doCreateInvitation(invitationPayload))
          .then(unwrapResult)
          .then(() => {
            resendIds.push(invitation.id);
            if (isKitInvitation(invitation.type)) {
              // do remove only case from kit, K1 will be upsert
              dispatch(fromUserInvitation.doRemoveInvitation(invitation.id));
            }
          });
      } catch (e: any) {
        setLoading(false);
        resetSelectedItems(resendIds);
        if (isInSystemMaintenance(config)) {
          return;
        }
        switch (e.message) {
          case ERROR_USER_INVITATION_EXCEEDED_LICENSES:
          case ERROR_USER_INVITATION_LIMITED_USER:
            enqueueSnackbar(
              intl.formatMessage({ id: e.message }),
              { variant: 'error' },
            );
            break;
          default:
            enqueueSnackbar(
              // it should be remaining unchange items
              intl.formatMessage({ id: 'user_management.resend_access_dialog.failed' }, { count: invitations.length - resendIds.length }),
              { variant: 'error' },
            );
            break;
        }
        return;
      }
    }
    resetSelectedItems(resendIds);
    enqueueSnackbar(
      intl.formatMessage({ id: 'user_management.resend_access_dialog.success' }, { count: invitations.length }),
      { variant: 'success' },
    );
    setLoading(false);
  };
  const handleRevokeClick = async () => {
    setRevokeInvitationDialogOpen(true);
  };

  const handleRevokeInvitationClose = () => {
    setRevokeInvitationDialogOpen(false);
  };
  const handleRevokeInvitationSubmit = async (invitations: UserTable[]) => {
    setRevokeInvitationDialogOpen(false);
    const removedIds: number[] = [];
    setLoading(true);

    // eslint-disable-next-line no-restricted-syntax
    for (const invitation of invitations) {
      // additional add `from` for invitation to prevent error alert,
      // this field is assigned in the `doGetUserInvitations`;
      const invitationPayload = { ...invitation, type: invitation.type || 'K1' };
      try {
        // eslint-disable-next-line no-await-in-loop
        await dispatch(fromUserInvitation.doRevokeInvitation(invitationPayload))
          .then(unwrapResult)
          .then(() => removedIds.push(invitation.id));
      } catch (_) {
        setLoading(false);
        resetSelectedItems(removedIds);
        if (isInSystemMaintenance(config)) {
          return;
        }
        enqueueSnackbar(
          // it should be remaining unchange items
          intl.formatMessage({ id: 'user_management.revoke_access_dialog.failed' }, { count: invitations.length - removedIds.length }),
          { variant: 'error' },
        );
        return;
      }
    }
    resetSelectedItems(removedIds);
    enqueueSnackbar(
      intl.formatMessage({ id: 'user_management.revoke_access_dialog.success' }, { count: invitations.length }),
      { variant: 'success' },
    );
    reloadDataInPage();
    setLoading(false);
  };

  const handleRevokeSsoClick = () => {
    setRevokeSsoInvitationDialogOpen(true);
  };

  const handleRevokeSsoInvitationClose = () => {
    setRevokeSsoInvitationDialogOpen(false);
  };
  const handleRevokeSsoInvitationSubmit = async (invitations: UserTable[]) => {
    setRevokeSsoInvitationDialogOpen(false);
    const removedIds: number[] = [];
    setLoading(true);
    // map to get organizationUserSsoId and invitationId
    // organizationUserSsoId: use to delete login option
    // invitationId: use to reset selected users
    const requestData = organizationUserSsos
      .filter(it => it.option === SsoOption.SSO_AUTH)
      .map(it => ({
        organizationUserSsoId: it.id,
        invitationId: invitations.find(invitation => (
          invitation.invitedTestOpsUserId
            ? invitation.invitedTestOpsUserId === it.userId
            : invitation.invitedUserId === it.userId
        ))?.id,
      }))
      .filter(it => it.invitationId !== undefined);

    // eslint-disable-next-line no-restricted-syntax
    for (const { organizationUserSsoId, invitationId } of requestData) {
      try {
        // eslint-disable-next-line no-await-in-loop
        await dispatch(fromOrganizationUserSso.doDeleteById({ id: organizationUserSsoId }))
          .then(unwrapResult)
          .then(() => removedIds.push(invitationId!!));
      } catch (_) {
        setLoading(false);
        resetSelectedItems(removedIds);
        if (isInSystemMaintenance(config)) {
          return;
        }
        enqueueSnackbar(
          // it should be remaining unchange items
          intl.formatMessage({ id: 'user_management.confirm_action_dialog.revoke_sso_invitation.failed' }, { count: requestData.length - removedIds.length }),
          { variant: 'error' },
        );
        return;
      }
    }
    resetSelectedItems(removedIds);
    enqueueSnackbar(
      intl.formatMessage({ id: 'user_management.confirm_action_dialog.revoke_sso_invitation.success' }, { count: requestData.length }),
      { variant: 'success' },
    );
    setLoading(false);
  };

  const handleCloseInviteUsersDialog = () => {
    setInviteUsersDialogOpen(false);
    setSelectedEmails([]);
    setLoginOptions(defaultLoginOptions);
    setSelectedSubs([]);
    setSelectedTeams([]);
    setSelectedProjects([]);
  };

  const handleRenewInvitation = async (invitation: UserTable) => {
    const { email, firstName, lastName } = invitation;
    const invitationPayload = {
      invitedUserEmail: email.toLowerCase(),
      organizationId: Number(orgId),
      ssoOptions: [], // use empty for now
      assignedFeatures: [],
      // no need to input again, be will skip the assign if already has a pending.
    };
    try {
      dispatch(fromUserPendingInvitationTab.doSetLoadingItem(invitation.id));
      await dispatch(fromUserInvitation.doCreateInvitation(invitationPayload)).then(unwrapResult);
      enqueueSnackbar(
        intl.formatMessage({ id: 'user_management.renew_invitation.success' }, { name: getFullNameUser(firstName, lastName, email) }),
        { variant: 'success' },
      );
    } catch (e: any) {
      if (isInSystemMaintenance(config)) {
        return;
      }
      switch (e.message) {
        case ERROR_USER_INVITATION_LIMITED_USER:
          enqueueSnackbar(
            intl.formatMessage({ id: e.message }),
            { variant: 'error' },
          );
          break;
        default:
          enqueueSnackbar(
            intl.formatMessage({ id: 'user_management.renew_invitation.failed' }, { name: getFullNameUser(firstName, lastName, email) }),
            { variant: 'error' },
          );
          break;
      }
    } finally {
      dispatch(fromUserPendingInvitationTab.doUnsetLoadingItem(invitation.id));
    }
  };

  const checkPermissionBeforeAction = async (redirectForbidden: boolean = false) => {
    // allow root and admin users fetch data
    if (isRootOrAdmin) return;
    const orgUsers = (await dispatch(fromCurrentOrgUser.doGetOrgUserByUserIdAndOrgId({
      id: user?.id ?? 0,
      organizationId: Number(orgId) ?? 0,
    })));
    const currentUser = (orgUsers.payload as OrganizationUser[])[0];
    if (!currentUser || !isOrganizationAdminOrOwner(currentUser.role)) {
      if (redirectForbidden || !currentUser) {
        navigateReplace(forbidden.path, replaceQuery(queryDictionary()));
      }
      // eslint-disable-next-line consistent-return,prefer-promise-reject-errors
      return Promise.reject('no permissions');
    }
    // eslint-disable-next-line consistent-return
    return Promise.resolve();
  };

  const handlePageChange = (_: React.ChangeEvent<unknown> | null, value: number) => {
    setPage(value);
  };

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTabIndex(newValue);
    setPage(1);
    setSelectedUsers([]);
  };

  const handleUserSelected = (user: UserTable) => {
    const selectedUserList = [...selectedUsers];
    const index = selectedUserList.findIndex(it => it.id === user.id);
    if (index === -1) selectedUserList.push(user);
    else selectedUserList.splice(index, 1);
    setSelectedUsers(selectedUserList);
  };
  const handleAllUsersSelected = (isCheckedAll: boolean, selectedList: UserTable[]) => {
    let selectedUserList;
    if (isCheckedAll) {
      selectedUserList = uniqBy([...selectedList, ...selectedUsers], 'email');
    } else {
      selectedUserList = selectedUsers
        .filter(selectedUser => selectedList.every(it => it.email !== selectedUser.email));
    }
    setSelectedUsers(selectedUserList);
  };
  const onLoadingExportUsers = async () => {
    await checkPermissionBeforeAction(true);
    setLoading(true);
  };
  const setViewQuery = () => {
    if (enableInvitation) {
      navigateReplace(undefined, mergeQuery({ view: 'invite_user' }));
    }
  };
  const unsetViewQuery = () => {
    clear('view');
    navigateReplace(undefined, replaceQuery(queryDictionary()));
  };
  const handleOpenInviteUsersDialog = () => {
    // async fetch
    Promise.all([
      dispatch(fromOrganizationUsers.doGetAllOrgUsers({ organizationId: Number(orgId) || 0 })),
      dispatch(
        fromSubscriptions.doGetActiveSubscriptionsByOrgId({ organizationId: Number(orgId) }),
      ),
      dispatch(fromOrganizationUserFeature.doGetOrgUserFeatures({ organizationId: Number(orgId) })),
    ]);
    setInviteUsersDialogOpen(true);
  };

  const havePlatformSubscription = useSelector(fromTestOpsPlatformSubscriptions
    .selectTestOpsPlatformSubscriptionByAccountId(accountId!!)).length > 0;
  const haveTestOpsSubscription = useSelector(fromTestOpsSubscriptions
    .selectByAccountId(accountId!!)).length > 0;
  const account = useSelector(fromAccounts.selectAccountById(
    Number(accountId),
  ));
  const totalAccountMembers = account?.numberAccountUsers || 0;
  const totalInvitations = useSelector(fromUserInvitation.selectTotalUserInvitationsInAccount);
  const freeKsExpiry = useSelector(fromAccounts.selectFreeKsExpiry);

  const enableInvitation = !flags?.limitUserEnabled || (havePlatformSubscription
    || haveTestOpsSubscription
    || totalAccountMembers + totalInvitations < 5);

  useEffect(() => {
    if (!user?.id || !accountId) {
      return;
    }
    const fetchInformation = async () => {
      await Promise.all([
        dispatch(fromAccountUsers.doGetByUserIdAndAccountId({ userId: user?.id, accountId })),
        dispatch(fromTestOpsPlatformSubscriptions
          .doGetAllTestOpsPlatformSubscriptionsByAccountId({ accountId })),
        dispatch(fromTestOpsSubscriptions.doGetActiveByAccountId({ accountId: Number(accountId) })),
        dispatch(fromUserInvitation.doCountInvitationsOfAccount({ id: accountId })),
        dispatch(fromTestOpsTeam
          .doGetTeamsOfOwnerOrAdminByOrgId({ organizationId: Number(orgId) })),
        dispatch(fromTestOpsProject.doGetProjectsByOrgId({ organizationId: Number(orgId) })),
      ]);
      await dispatch(fromAccounts.doCountMembersOfAccount({ id: accountId }));
    };

    setLoading(true);
    fetchInformation().catch(() => {});
    setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, accountId]);

  const reloadDataInPage = async () => {
    if (accountId) {
      await Promise.all([
        dispatch(fromAccounts.doCountMembersOfAccount({ id: accountId })),
        dispatch(fromUserInvitation.doCountInvitationsOfAccount({ id: accountId })),
        dispatch(fromUserInvitation.doGetUserInvitations({ organizationId: Number(orgId) })),
      ]);
    }
  };

  const handleSubmitInvitationsWithTeams = async (
    emails: string[],
    selectedSubs: SubscriptionInvitation[],
    selectedTeams: number[],
  ) => {
    if (flags?.limitUserEnabled
      && (!havePlatformSubscription && !haveTestOpsSubscription
        && totalAccountMembers + totalInvitations + emails.length > 5)
    ) {
      enqueueSnackbar(
        intl.formatMessage({ id: ERROR_USER_INVITATION_LIMITED_USER }),
        { variant: 'error' },
      );
      return;
    }
    setInviteUsersDialogOpen(false);
    setLoading(true);
    const invitedEmails: string[] = [];
    const invitedUserIds: number[] = [];
    const ssoOptions: string[] = [];
    Object.keys(loginOptions).forEach((option, i) => {
      if (Object.values(loginOptions)[i]) {
        ssoOptions.push(option);
      }
    });
    // Invite users
    // eslint-disable-next-line no-restricted-syntax
    for (const e of emails) {
      const invitation = {
        invitedUserEmail: e.toLowerCase(),
        organizationId: Number(orgId),
        assignedFeatures: selectedSubs.map(it => it.feature),
        ssoOptions: isSsoEnabled ? ssoOptions : [],
      };
      try {
        // eslint-disable-next-line no-await-in-loop
        await dispatch(fromUserInvitation.doCreateInvitation(invitation))
          .then(unwrapResult)
          .then(invitation => {
            invitedEmails.push(e);
            if (invitation.invitedTestOpsUserId) {
              invitedUserIds.push(invitation.invitedTestOpsUserId);
            }
          });
      } catch (error: any) {
        setLoading(false);
        // failed email:
        setSelectedEmails(emails
          .filter(email => invitedEmails.findIndex(it => it === email) === -1));
        if (isInSystemMaintenance(config)) {
          return;
        }
        switch (error.message) {
          case ERROR_USER_INVITATION_LIMITED_USER:
            enqueueSnackbar(
              intl.formatMessage({ id: error.message }),
              { variant: 'error' },
            );
            break;
          default:
            enqueueSnackbar(
              intl.formatMessage({ id: 'user_management.invite_users.failed' }, { count: emails.length - invitedEmails.length }),
              { variant: 'error' },
            );
            break;
        }
        unsetViewQuery();
        reloadDataInPage();
        return;
      }
    }

    // Add users to teams
    // eslint-disable-next-line no-restricted-syntax
    for (const teamId of selectedTeams) {
      try {
        // eslint-disable-next-line no-await-in-loop
        await TestOpsTeamService.addUsersToTeam({
          teamId,
          newUserIds: invitedUserIds,
        });
      } catch (error: any) {
        setLoading(false);
        const teamName = teams.find(it => it.id === teamId)?.name ?? '';
        const errMsg = error.response.data.errors[0];
        switch (errMsg) {
          case ERROR_ORGANIZATION_USER_NOT_EXISTED:
          case ERROR_TEAM_USER_EXISTED:
            enqueueSnackbar(
              intl.formatMessage({ id: errMsg }),
              { variant: 'error' },
            );
            break;
          default:
            enqueueSnackbar(
              intl.formatMessage(
                { id: 'user_management.invite_users.add_to_team_failed' },
                { teamName },
              ),
              { variant: 'error' },
            );
            break;
        }
        unsetViewQuery();
        reloadDataInPage();
        return;
      }
    }

    enqueueSnackbar(
      intl.formatMessage({ id: 'user_management.invite_users.success' }, { count: invitedEmails.length }),
      { variant: 'success' },
    );

    await Promise.all([
      dispatch(fromUserInvitation.doGetUserInvitations({ organizationId: Number(orgId) })),
      dispatch(fromOrganizationUserFeature.doGetOrgUserFeatures({ organizationId: Number(orgId) })),
      dispatch(fromOrganizationFeatureFlag
        .doGetByOrganizationId({ organizationId: Number(orgId) })),
      dispatch(fromOrganizationUserSso.doGetByOrganizationId({ organizationId: Number(orgId) })),
      dispatch(fromOrganizationUsers.doGetAllOrgUsers({ organizationId: Number(orgId) })),
    ]);

    if (accountId) {
      await dispatch(fromUserInvitation.doCountInvitationsOfAccount({ id: accountId }));
    }
    unsetViewQuery();
    setSelectedEmails([]);
    setSelectedSubs([]);
    setSelectedTeams([]);
    setSelectedProjects([]);
    setLoading(false);
    setLoginOptions(defaultLoginOptions);
  };

  const handleSelectEmail = (emails: string[]) => {
    setSelectedEmails([...emails]);
  };

  const handleSelectSubs = (subs: SubscriptionInvitation[]) => {
    setSelectedSubs([...subs]);
  };

  const handleSelectedTeams = (teamIds: number[]) => {
    setSelectedTeams([...teamIds]);
  };

  const handleSelectedProjects = (projectIds: number[]) => {
    setSelectedProjects([...projectIds]);
    const teamIds = uniq(
      filter(
        map(projectIds, projectId => find(projects, { id: projectId })?.teamId ?? -1),
        it => it !== -1,
      ),
    );
    setSelectedTeams([...teamIds]);
  };

  const handleEditSsoOptionsButtonClick = (organizationUserId: number) => {
    setOrganizationUserId(organizationUserId);
    setEditSsoOptionsDialogOpen(true);
  };

  const handleEditSsoOptionsSubmit = async (
    ssoAuthOptionId: number | undefined,
    basicAuthOptionId: number | undefined,
    basicAuthEnabled: boolean,
    ssoAuthEnabled: boolean,
  ) => {
    try {
      setLoading(true);
      if (ssoAuthOptionId !== undefined && !ssoAuthEnabled) {
        await dispatch(fromOrganizationUserSso
          .doDeleteById({ id: ssoAuthOptionId })).then(unwrapResult);
      }
      if (ssoAuthOptionId === undefined && ssoAuthEnabled) {
        await dispatch(fromOrganizationUserSso.doAddOption({
          organizationUserId,
          option: SsoOption.SSO_AUTH,
        })).then(unwrapResult);
      }
      if (basicAuthOptionId !== undefined && !basicAuthEnabled) {
        await dispatch(fromOrganizationUserSso
          .doDeleteById({ id: basicAuthOptionId })).then(unwrapResult);
      }
      if (basicAuthOptionId === undefined && basicAuthEnabled) {
        await dispatch(fromOrganizationUserSso.doAddOption({
          organizationUserId,
          option: SsoOption.BASIC_AUTH,
        })).then(unwrapResult);
      }
      // get user sso invitation
      await Promise.all([
        dispatch(fromTestOpsUserSsoInvitation
          .doGetByOrganizationId({ organizationId: Number(orgId) })),
        dispatch(fromUserSsoInvitation.doGetByOrganizationId({ organizationId: Number(orgId) })),
        dispatch(fromOrganizationUserSso.doGetByOrganizationId({ organizationId: Number(orgId) })),
      ]);
      enqueueSnackbar(
        intl.formatMessage({ id: 'user_management.edit_sso.success' }),
        { variant: 'success' },
      );
      setEditSsoOptionsDialogOpen(false);
    } catch (_) {
      enqueueSnackbar(
        intl.formatMessage({ id: 'user_management.edit_sso.failed' }),
        { variant: 'error' },
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChangeLoginOptions = (event: React.ChangeEvent<HTMLInputElement>, option: string) => {
    const tempLoginOptions: LoginOptions = { ...loginOptions };
    if (option === SsoOption.SSO_AUTH) {
      tempLoginOptions.SSO_AUTH = event.target.checked;
    } else {
      tempLoginOptions.BASIC_AUTH = event.target.checked;
    }
    setLoginOptions(tempLoginOptions);
  };

  const handleRefreshTeams = async () => {
    await dispatch(fromTestOpsTeam
      .doGetTeamsOfOwnerOrAdminByOrgId({ organizationId: Number(orgId) }));
  };
  const handleRefreshProjects = async () => {
    await Promise.all([
      dispatch(fromTestOpsTeam
        .doGetTeamsOfOwnerOrAdminByOrgId({ organizationId: Number(orgId) })),
      dispatch(fromTestOpsProject.doGetProjectsByOrgId({ organizationId: Number(orgId) })),
    ]);
  };
  // ***** end handlers.

  // ***** useEffect hooks:
  useEffect(() => {
    if (!orgId) return;

    const fetchInformation = () => {
      (async () => {
        try {
          setLoading(true);
          await checkPermissionBeforeAction();
          const dispatchByCurrentTab = () => {
            switch (currentTab) {
              case 'active-users': {
                return [
                  dispatch(fromOrganizationUsers.doGetAllOrgUsers({
                    organizationId: +orgId,
                  })),
                  dispatch(fromTestOpsUserSsoInvitation.doGetByOrganizationId(
                    { organizationId: +orgId },
                  )),
                  dispatch(fromUserSsoInvitation.doGetByOrganizationId(
                    { organizationId: +orgId },
                  )),
                ];
              }
              case 'pending-invitations': {
                return [dispatch(fromUserInvitation.doGetUserInvitations({
                  organizationId: +orgId,
                }))];
              }
              case 'removed-users': {
                return [dispatch(fromOrganizationRemovedUser.doGetAllOrganizationRemovedUser({
                  organizationId: +orgId,
                }))];
              }
              default:
                return [];
            }
          };
          await Promise.all([
            dispatchByCurrentTab(),
            dispatch(fromOrganizationUserFeature.doGetOrgUserFeatures(
              { organizationId: +orgId },
            )),
            dispatch(fromSubscriptions.doGetActiveSubscriptionsByAccountId(
              { accountId: accountId || 0 },
            )),
          ]);
          if (currentTab === 'pending-invitations') setDidFetchInvitations(true);
        } finally {
          setLoading(false);
        }
      })();
    };
    fetchInformation();
    // if user click back on browser, we need to reset selected tab if there is any change
    setSelectedTabIndex(getTabIndex(currentTab));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, orgId, users.length, user, accountId]);

  useEffect(() => {
    if (!orgId) {
      return;
    }
    Promise.all([
      dispatch(fromOrganizationFeatureFlag
        .doGetByOrganizationId({ organizationId: Number(orgId) })),
      dispatch(fromOrganizationUserSso.doGetByOrganizationId({ organizationId: Number(orgId) })),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, orgId]);

  useEffect(() => {
    // only call this once
    const fetchCurrentUsagePlan = async () => {
      await dispatch(fromTestResultCount.doGetLatestTestResultCount(Number(orgId)));
    };
    fetchCurrentUsagePlan();
    setSelectedEmails([]);
    setSelectedSubs([]);
    setSelectedTeams([]);

    return () => {
      dispatch(fromUserPendingInvitationTab.doResetState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  // check permission when user changes page
  useEffect(() => {
    if (isRootOrAdmin) return;
    checkPermissionBeforeAction().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  // check for popupView query
  useEffect(() => {
    if (popupView === 'invite_user') handleOpenInviteUsersDialog();
    else handleCloseInviteUsersDialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupView]);
  // ***** end useEffect hooks

  const ActiveUsersTab = () => (
    <Grid className={classes.table} item xs={12}>
      <ActiveUsersTable
        onChangeRoleClick={handleChangeRoleClick}
        onRemoveUserClick={handleRemoveUserClick}
        onSelectUser={handleUserSelected}
        onSelectAllUsers={handleAllUsersSelected}
        selectedUsers={selectedUsers}
        page={page}
        onChangePage={handlePageChange}
        dataUser={usersOrg}
        isSsoEnabled={Boolean(isSsoEnabled)}
        onEditSsoOptionsButtonClick={handleEditSsoOptionsButtonClick}
        currentOrganizationUser={currentOrganizationUser}
        isShowWarning={freeKsExpiry?.isPaid}
        isNewLicenseUI={freeKsExpiry?.isPaid}
      />
    </Grid>
  );
  // const ActiveUsersTab = activeUsersTab();
  const PendingUsersTab = () => (
    <Grid className={classes.table} item xs={12}>
      <PendingInvitationTable
        onSelectUser={handleUserSelected}
        onSelectAllUsers={handleAllUsersSelected}
        selectedUsers={selectedUsers}
        itemsLoadingState={userInvitationItemLoadingState}
        page={page}
        onChangePage={handlePageChange}
        dataUser={userInvitations}
        onRevokeClick={handleRevokeClick}
        onRevokeSsoClick={handleRevokeSsoClick}
        onResendClick={handleResendClick}
        onRenewInvitation={handleRenewInvitation}
        isSsoEnabled={Boolean(isSsoEnabled)}
        disabledRevokeSso={selectedSsoUsers.length <= 0}
      />
    </Grid>
  );
  // const PendingUsersTab = pendingUsersTab();
  const RemovedUsersTab = () => (loading ? <></> : (
    <Grid className={classes.table} item xs={12}>
      <RemovedUsersTable
        page={page}
        onChangePage={handlePageChange}
        dataUser={userRemovedData}
        isSsoEnabled={Boolean(isSsoEnabled)}
      />
    </Grid>
  ));
  // const RemovedUsersTab = removedUsersTab();
  if (flags?.projectStandardizeEnabled) {
    if (!currentOrgUser
      || !isOrganizationAdminOrOwner(currentOrgUser.role)) return <></>;
  }
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid container alignItems="flex-start" className={classes.titleContainer}>
          <Grid item xs={6}>
            <Typography variant="h2" className={classes.title}>
              <FormattedMessage id="layout.header.organizationsetting.user_management" />
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.titleButton}>
            {/* <Button className={clsx(classes.button, classes.globalSearch)}
            variant="contained"><SearchIcon /></Button> */}
            <div className={classes.button}>
              <ExportActiveAndRemovedUsersButton
                onLoading={onLoadingExportUsers}
                onComplete={() => setLoading(false)}
              />
            </div>
            <Tooltip
              id="user_management.header.tooltip.disable_invitation"
              title={enableInvitation ? '' : intl.formatMessage({ id: 'user.invitation.exceeded.max.users.tooltip' })}
              placement="top"
            >
              <span>
                <Button
                  id="user_management.header.button.invitation_user"
                  className={classes.button}
                  variant="contained"
                  disabled={!enableInvitation}
                  color="primary"
                  onClick={setViewQuery}
                >
                  <FormattedMessage id="user_management.button.invite_user" />
                </Button>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container className={classes.tabContainer}>
          <Tabs
            value={selectedTabIndex}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              id="user_management.tab.active_user"
              label={intl.formatMessage({ id: 'user_management.tab.active_user' })}
              component={Link}
              to={{
                pathname: 'active-users',
                search: location.search,
              }}
            />
            <Tab
              id="user_management.tab.pending_invitation"
              label={intl.formatMessage({ id: 'user_management.tab.pending_invitation' })}
              component={Link}
              to={{
                pathname: 'pending-invitations',
                search: location.search,
              }}
            />
            <Tab
              id="user_management.tab.removed_users"
              label={intl.formatMessage({ id: 'user_management.tab.removed_users' })}
              component={Link}
              to={{
                pathname: 'removed-users',
                search: location.search,
              }}
            />
          </Tabs>
        </Grid>
        <Paper className={classes.paper} square elevation={0}>
          <Routes>
            <Route
              path="active-users"
              element={(!loading && users.length > 0) ? <ActiveUsersTab /> : <></>}
            />
            <Route
              path="pending-invitations"
              element={(!loading && didFetchInvitations) ? <PendingUsersTab /> : <></>}
            />
            {/*
              We use a state named `matchedRows` inside PendingInvitationTable to render table rows.
              If PendingInvitationTable mounted before fetch invitations,
                `matchedRows` will not update automatically if data changed.
              We have 2 options:
                one is update `matchedRows` if data changed mannually through side effect,
                another is mounted PendingInvitationTable after fetch data.
              Because we have sort, search... in PendingInvitationTable, option 2 will be better.
              */}
            <Route
              path="removed-users"
              element={<RemovedUsersTab />}
            />
            {/* default redirect if the current url is not match: */}
            <Route
              path="*"
              element={(
                <Navigate
                  to={{
                    pathname: 'active-users',
                    search: location.search,
                  }}
                  replace
                />
              )}
            />
          </Routes>
        </Paper>
      </Grid>
      <ConfirmChangeDialog
        affectedUsers={selectedUsers}
        variant="changeRole"
        open={changeRoleDialogOpen}
        onClose={handleChangeRoleClose}
        onSubmit={handleChangeRoleSubmit}
      />
      <ConfirmChangeDialog
        affectedUsers={selectedUsers}
        variant="removeUser"
        open={removeUserDialogOpen}
        onClose={handleRemoveUserClose}
        onSubmit={handleRemoveUserSubmit}
      />
      <ConfirmChangeDialog
        affectedUsers={selectedUsers}
        variant="revokeInvitation"
        open={revokeInvitationDialogOpen}
        onClose={handleRevokeInvitationClose}
        onSubmit={handleRevokeInvitationSubmit}
        isSsoEnabled={isSsoEnabled || false}
      />
      <ConfirmChangeDialog
        affectedUsers={selectedSsoUsers}
        variant="revokeSsoInvitation"
        open={revokeSsoInvitationDialogOpen}
        onClose={handleRevokeSsoInvitationClose}
        onSubmit={handleRevokeSsoInvitationSubmit}
      />
      <ConfirmChangeDialog
        affectedUsers={selectedUsers}
        variant="resendInvitation"
        open={resendInvitationDialogOpen}
        onClose={handleResendInvitationClose}
        onSubmit={handleResendInvitationSubmit}
      />
      <InviteUsersDialogV2
        isSsoEnabled={isSsoEnabled ?? false}
        loginOptions={loginOptions}
        handleChangeLoginOptions={handleChangeLoginOptions}
        selectedEmails={selectedEmails}
        handleSelectEmails={handleSelectEmail}
        open={inviteUsersDialogOpen}
        hasKseOrKreLicense={hasKseOrKreLicense}
        onClose={unsetViewQuery}
        handleSubmit={handleSubmitInvitationsWithTeams}
        selectedSubs={selectedSubs}
        handleSelectSubs={handleSelectSubs}
        onBackButton={() => {}}
        havePlatformSubscription={havePlatformSubscription}
        haveTestOpsSubscription={haveTestOpsSubscription}
        totalAccountMembers={totalAccountMembers}
        totalInvitations={totalInvitations}
        availableSubs={availableSubs}
        teams={teams}
        selectedTeams={selectedTeams}
        handleRefreshTeams={handleRefreshTeams}
        handleSelectTeams={handleSelectedTeams}
        projects={projects}
        selectedProjects={selectedProjects}
        handleRefreshProjects={handleRefreshProjects}
        handleSelectProjects={handleSelectedProjects}
      />
      {isSsoEnabled && organizationUserId > 0 && (
        <EditSsoOptionsDialog
          organizationUserId={organizationUserId}
          domain={currentOrganization?.domain}
          open={editSsoOptionsDialogOpen}
          onClose={() => setEditSsoOptionsDialogOpen(false)}
          onSubmit={handleEditSsoOptionsSubmit}
        />
      )}
      {loading && <LoadingProgress />}
      <Outlet />
    </div>
  );
};

export default UserManagement;
