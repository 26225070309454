import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { orgCardView } from '../../../layout/routes';
import { resolvePath, welcome } from '../../../routes';
import { fromAccountUsers, fromAuth, useAppDispatch } from '../../../store';

const AccountWelcome = () => {
  const [loading, setLoading] = useState(true);

  const user = useSelector(fromAuth.selectUser);
  const accountUser = useSelector(
    fromAccountUsers.selectMinimumAccountIdAccountUsersByUserId(user!!.id),
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fromAccountUsers.doGetAccountUsersByUserId({ userId: user!!.id }))
      .then(() => setLoading(false));
  }, []);

  if (loading) {
    return null;
  }

  if (!accountUser) {
    return (
      <Navigate to={resolvePath(welcome, undefined, {}, false)} />
    );
  }

  return (
    <Navigate to={
      resolvePath(orgCardView, undefined, { accountId: accountUser.accountId }, false)
      }
    />
  );
};

export default AccountWelcome;
