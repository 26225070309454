import Axios from 'axios';
import { DataResponse } from '../models';
import { LicenseOnPremise } from '../models/LicenseOnPremise';
import { ConfigurationOnPremise } from '../models/ConfigurationOnPremise';

export const getMachineId = async () => (
  await Axios.get<DataResponse<LicenseOnPremise>>('/v1/license-key/machine-id', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
  })
).data;

export const getActivated = async () => (
  await Axios.get<DataResponse<ConfigurationOnPremise>>('/v1/license-key/activate', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
  })
).data;

export const activateLicense = async (data: Required<Pick<LicenseOnPremise, 'license'>>) => (
  await Axios.post<DataResponse<LicenseOnPremise>>('/v1/license-key/activate', data)
).data.data;
