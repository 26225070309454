import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { fromOrganizationUsers } from '../../store';
import OrganizationCard from './OrganizationCard';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
  },
  recentOrgs: {
    'text-transform': 'uppercase',
  },
  orgGrid: {
    padding: theme.spacing(3, 0),
  },
}));

const OrganizationListShort = () => {
  const classes = useStyles(); // CSS styles
  const orgUsers = useSelector(fromOrganizationUsers.selectOrgUsersByUserIdWithSearch);
  const sortedOrgUsers = orgUsers.sort(
    (orgUser1, orgUser2) => new Date(orgUser2?.lastAccessedAt || 0).getTime()
      - new Date(orgUser1?.lastAccessedAt || 0).getTime(),
  );

  return (
    <Box className={classes.root}>
      <Typography variant="subtitle1" className={classes.recentOrgs}>
        <FormattedMessage id="organizationlist.recent_orgs" />
      </Typography>
      <Grid className={classes.orgGrid} container spacing={4}>
        {sortedOrgUsers
          .map(orgUser => (
            <Grid key={orgUser.id} item xs={12} sm={6}>
              <OrganizationCard id={orgUser!!.organizationId} />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default OrganizationListShort;
