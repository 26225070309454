import Axios from 'axios';
import { DataResponse, PageableResponse } from '../models';
import { UserGroup, UserGroupUser } from '../models/userGroup';
import { composeQuery, Query } from '../models/query';

export const createUserGroup = async (
  input: Required<Pick<UserGroup, 'organizationId' | 'name' | 'accountId'>> & Partial<Pick<UserGroup, 'description'>>,
) => (await Axios.post<DataResponse<UserGroup>>('/v1/user-groups', input)).data.data;

export const inviteUserToGroup = async (
  input: Required<Pick<UserGroupUser, 'userGroupId' | 'userEmail'>>,
) => (await Axios.post<DataResponse<UserGroupUser>>(`/v1/user-groups/${input.userGroupId}/users`, input)).data.data;

export const getAllUserGroups = async (...criteria: Query<UserGroup>[]) => (await Axios.get<PageableResponse<UserGroup>>('/v1/user-groups', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: composeQuery<UserGroup>(...criteria),
  data: {},
})).data;

export const deleteUserGroup = async (input: Required<Pick<UserGroup, 'id'>>) => (await Axios.delete(`/v1/user-groups/${input.id}`)).data;

export const updateUserGroup = async (
  id: number,
  input: Partial<Pick<UserGroup, 'description' | 'name'>>,
) => (await Axios.put<DataResponse<UserGroup>>(`/v1/user-groups/${id}`, input)).data.data;
