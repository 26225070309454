import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import rootReducer, {
  fromAuth,
  fromTestOpsBillingInformation,
  fromLayout,
  fromOrganizations,
  fromOrganizationUsers,
  fromTestOpsPaymentMethod,
  fromTestOpsConfiguration,
  fromUsers,
  fromDashboard,
  fromTestResultCount,
  fromLicenseUtilization,
  fromMachines,
  fromOrganizationUserFeature,
  fromSubscriptions,
  fromTestOpsSubscriptions,
  fromOrganizationRemovedUser,
  fromUserInvitation,
  fromUserPendingInvitationTab,
  fromOrganizationUserSso,
  fromOrganizationFeatureFlag,
  fromCurrentOrgUser,
  fromTestOpsProject,
  fromTestOpsUserSsoInvitation,
  fromUserSsoInvitation,
  fromAcceptInvitePage,
  fromAcceptInviteSsoPage,
  fromAssignLicensePage,
  fromProductInterest,
  fromServiceCloudOrganizationUser,
  fromTestOpsEnterpriseProduct,
  fromTestCloudSubscriptions,
  fromTestCloudUsage,
  fromTestCloudQuote,
  fromAccountUsers,
  fromAccounts,
  fromOrganizationConfigurations,
  fromStudioSubscriptions,
  fromVisualTestingSubscriptions,
  fromTestOpsPlatformSubscriptions,
  fromStarterPackage,
  fromOrganizationWhitelistIps,
  fromTestOpsTeam,
  fromOrder,
  fromUserGroup,
  fromUserGroupUser,
  fromSecuritySetting,
} from './rootReducer';

const isDevelopment = process.env.NODE_ENV === 'development';
const store = configureStore({
  reducer: rootReducer,
  devTools: isDevelopment,
});

if (isDevelopment && module.hot) {
  module.hot.accept('./rootReducer', () => {
    // eslint-disable-next-line global-require
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppState = ReturnType<typeof rootReducer>;
export const useAppDispatch = () => useDispatch<typeof store.dispatch>();

export {
  fromUsers,
  fromAuth,
  fromLayout,
  fromOrganizations,
  fromOrganizationUsers,
  fromTestOpsPaymentMethod,
  fromTestOpsConfiguration,
  fromTestOpsBillingInformation,
  fromDashboard,
  fromTestResultCount,
  fromLicenseUtilization,
  fromMachines,
  fromOrganizationUserFeature,
  fromSubscriptions,
  fromTestOpsSubscriptions,
  fromOrganizationRemovedUser,
  fromUserInvitation,
  fromUserPendingInvitationTab,
  fromOrganizationUserSso,
  fromOrganizationFeatureFlag,
  fromCurrentOrgUser,
  fromTestOpsProject,
  fromTestOpsUserSsoInvitation,
  fromUserSsoInvitation,
  fromAcceptInvitePage,
  fromAcceptInviteSsoPage,
  fromAssignLicensePage,
  fromProductInterest,
  fromServiceCloudOrganizationUser,
  fromTestOpsEnterpriseProduct,
  fromTestCloudSubscriptions,
  fromTestCloudUsage,
  fromOrganizationConfigurations,
  fromOrganizationWhitelistIps,
  fromTestCloudQuote,
  fromAccountUsers,
  fromAccounts,
  fromStudioSubscriptions,
  fromVisualTestingSubscriptions,
  fromTestOpsPlatformSubscriptions,
  fromStarterPackage,
  fromTestOpsTeam,
  fromOrder,
  fromUserGroup,
  fromUserGroupUser,
  fromSecuritySetting,
};
export default store;
