import React, { ReactNode, useRef, useState } from 'react';
import clsx from 'clsx';
import Button from '@katalon-studio/katalon-ui/Button';
import { Dialog, DialogBody, DialogFooter, DialogHeader } from '@katalon-studio/katalon-ui/Dialog';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import map from 'lodash/map';
import Autocomplete, {
  AutocompleteProps, createFilterOptions,
} from '@mui/material/Autocomplete';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import Popper, { PopperProps } from '@mui/material/Popper';
import Checkbox from '@mui/material/Checkbox';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Chip from '@mui/material/Chip';
import { FormattedMessage, useIntl } from 'react-intl';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { ReactComponent as CircleXmarkIcon } from '../icons/circle-xmark-icon.svg';
import LoadingProgress from '../../layout/LoadingProgress';
import { getAvatarSource, OrganizationUser } from '../../models';
import NoResult from '../license-utilization/components/NoResult';
import { Filter, Operator } from '../../models/query';
import { useQuery } from '../../routes';
import { useAppDispatch } from '../../store';
import { fromUserGroup, fromUserGroupUser } from '../../store/rootReducer';
import { useNotification } from '../../notification';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
  },
  dialog: {
    position: 'absolute',
    borderRadius: theme.spacing(0.75),
    minWidth: theme.spacing(96),
  },
  dlgHeader: {
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    width: '100%',
    fontSize: theme.spacing(2),
    fontWeight: 700,
    color: '#233145',
    display: 'flex',
  },
  dlgTitle: {
    flexGrow: 2,
    fontWeight: 500,
    fontSize: theme.spacing(3),
  },
  dlgContent: {
    width: '100%',
  },
  boxSpacing: {
    height: theme.spacing(2),
  },
  defaultIcon: {
    fontSize: theme.spacing(2),
    color: '#598ef9',
  },
  actionItemIcon: {
    marginRight: theme.spacing(1),
  },
  textFieldTitle: {
    marginTop: theme.spacing(1),
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(1),
    '& p': {
      fontSize: theme.spacing(1.75),
      fontWeight: 500,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      height: theme.spacing(5),
    },
  },
  emailTextFieldError: {
    marginBottom: theme.spacing(1),
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#d24720',
    },
  },
  avatarOption: {
    marginRight: theme.spacing(1),
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  emailOption: {
    fontSize: theme.spacing(1.75),
  },
  addEmailIcon: {
    fontSize: theme.spacing(1.5),
    color: 'rgba(104, 109, 128, 0.54)',
    marginRight: theme.spacing(1),
  },
  menuPaper: {
    maxHeight: theme.spacing(24),
    maxWidth: theme.spacing(45),
  },
  dlgActionButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 3, 3, 3),
  },
  buttonSpacing: {
    width: theme.spacing(1),
  },
  cancelButton: {
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
  },
  disableBtn: {
    color: 'rgba(104, 109, 128) !important',
    backgroundColor: 'rgba(104, 109, 128, 0.24) !important',
  },
  activeBtn: {
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
    marginLeft: '0 !important',
  },
  textField: {
    '& .MuiInputBase-root': {
      backgroundColor: 'unset',
      fontSize: theme.spacing(1.75),
      borderRadius: theme.spacing(0.75),
    },
  },
  disableInput: {
    '& .MuiAutocomplete-input': {
      maxHeight: `${theme.spacing(0)}`,
    },
  },
  nameInput: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  helperText: {
    fontSize: `${theme.spacing(1.5)} !important`,
    color: '#808b9a !important',
    fontWeight: 'normal !important',
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
  },
  groupNameInvalidError: {
    color: '#d24720 !important',
    fontSize: `${theme.spacing(1.5)} !important`,
    fontWeight: 'normal !important',
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
  },
  groupNameTextFieldError: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#d24720',
    },
  },
  avatarContainer: {
    marginTop: theme.spacing(0.5),
    position: 'relative',
  },
  statusIndicator: {
    position: 'absolute',
    content: '',
    width: theme.spacing(2),
    height: theme.spacing(2),
    top: '55%',
    left: '45%',
    borderRadius: '50%',
    borderWidth: theme.spacing(0.25),
    borderStyle: 'solid',
    borderColor: theme.palette.background.paper,
  },
  indicatorInfo: {
    display: 'flex',
    padding: theme.spacing(1, 2),
  },
  activeIndicator: {
    fill: '#00D63C',
    width: theme.spacing(2.25),
  },
  removedIndicator: {
    fill: '#808B9A',
    width: theme.spacing(2.25),
  },
  indicatorContainer: {
    display: 'flex',
    minWidth: theme.spacing(4),
    alignItems: 'center',
    '&:last-child': {
      marginLeft: theme.spacing(2),
    },
  },
  legend: {
    fontSize: theme.spacing(1.75),
  },
  indicatorTag: {
    border: '1px solid #D5D8DD',
    borderRadius: '4px',
  },
  noOptions: {
    overflowX: 'hidden',
  },
  userFilter: {
    width: '100%',
    '& input': {
      minHeight: theme.spacing(4),
    },
    '& .MuiOutlinedInput-root': {
      maxHeight: theme.spacing(6),
    },
  },
  avatar: {
    marginRight: theme.spacing(1),
    width: theme.spacing(3),
    borderRadius: '50%',
    height: 'auto',
  },
  option: {
    paddingBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '&[aria-selected="true"]': {
      backgroundColor: '#F0F8FF !important',
    },
  },
  userInfo: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: theme.spacing(1.5),
  },
  requireSpan: {
    color: '#e53935',
    marginLeft: theme.spacing(0.5),
  },
  emailChipInvalid: {
    backgroundColor: '#FFF',
    border: '1px solid rgba(210, 71, 32, 0.5)',
    '& span': {
      color: '#D24720',
    },
    '& .MuiChip-deleteIcon': {
      color: '#D24720B3 !important',
    },
  },
  emailErrorContainer: {
    height: theme.spacing(2.5),
    paddingLeft: theme.spacing(1.5),
    marginTop: theme.spacing(0.375),
  },
  emailInvalidError: {
    color: '#d24720',
    fontSize: theme.spacing(1.5),
    fontWeight: 'normal',
  },
  emailChip: {
    margin: theme.spacing(0.5, 0.5),
    maxWidth: 200,
    borderRadius: theme.spacing(2),
    backgroundColor: 'rgba(104, 109, 128, 0.08)',
    '& span': {
      fontSize: theme.spacing(1.75),
    },
    '& .MuiChip-deleteIcon': {
      display: 'flex',
      fontSize: `${theme.spacing(1.75)} !important`,
      width: theme.spacing(1.75),
      height: theme.spacing(1.75),
      color: 'rgba(104, 109, 128, 0.54) !important',
      marginLeft: theme.spacing(0.625),
      marginRight: theme.spacing(0.625),
    },
  },
  chipTextField: {
    height: `${theme.spacing(2)} !important`,
  },
  tooltipInfoContent: {
    padding: theme.spacing(1),
  },
  questionIcon: {
    color: '#808b9a',
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
    marginLeft: theme.spacing(0.5),
    verticalAlign: 'text-bottom',
    marginBottom: theme.spacing(0.5),
  },
  hiddenText: {
    opacity: '0',
    height: theme.spacing(0),
    width: theme.spacing(0),
  },
}));

interface CreateUserGroupPopupProps {
  isOpen: boolean;
  orgId: number;
  currentOrgUser: OrganizationUser;
  orgUsers: OrganizationUser[];
  onCloseDialog: () => void;
  onSuccessCallback: () => void;
}

const CreateUserGroupPopup = (props: CreateUserGroupPopupProps) => {
  const {
    isOpen,
    onCloseDialog,
    currentOrgUser,
    orgUsers,
    orgId,
    onSuccessCallback,
  } = props;

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { get } = useQuery();
  const intl = useIntl();
  const { sendSuccess, sendError } = useNotification();
  const [loading, setLoading] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [groupDescription, setGroupDescription] = useState('');
  const [canSubmit, setCanSubmit] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasGroupNameExist, setHasGroupNameExist] = useState(false);
  const userGroups = useSelector(fromUserGroup.selectAllUserGroupsByOrganizationId(orgId));
  const [focused, setFocused] = useState(false);
  const filterInputRef = useRef<HTMLElement>(null);
  const currentSelectedUserRef = useRef<OrganizationUser[]>([]);
  const currentFilters = useRef<Filter<OrganizationUser>[]>(JSON.parse(get('filters') || '[]'));
  const groupNameRegexp = /[^A-Za-z0-9\s\u00C0-\u1EF9]*$/g;
  // eslint-disable-next-line no-useless-escape
  const specialCharacterRegexp = /[`!@#$\?%^&*~,.<>;':"\/\[\]\|{}\(\)=_+-]/;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon color="primary" fontSize="small" />;
  const USER_GROUP_DOC_URL = 'https://docs.katalon.com/docs';
  const myRefName = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);

  // @ts-ignore
  const handleSubmit = async () => {
    const userGroupPromise: Promise<any>[] = [];
    let createdUserGroupId = 0;
    const ignoredFailedUsers: string[] = [];

    if (!canSubmit) return;
    try {
      setLoading(true);
      // @ts-ignore
      await dispatch(fromUserGroup.doCreateUserGroup({
        organizationId: orgId,
        accountId: currentOrgUser.organization.accountId!!,
        name: groupName.trim().replace(/\s+/g, ' '),
        description: groupDescription.trim(),
      }))
        .then(unwrapResult)
        .then(data => {
          createdUserGroupId = data.userGroupId;
          if (currentSelectedUserRef.current.length > 0) {
            currentSelectedUserRef.current.forEach(orgUser => {
              userGroupPromise.push(
                dispatch(fromUserGroup.doCreateUserGroupUser({
                  userEmail: orgUser.user.email,
                  userGroupId: data.userGroupId,
                }))
                  .then(unwrapResult)
                  .catch((error: any) => {
                    if (error.message === 'user.has.not.exist' && error.type === 'BAD_REQUEST') {
                      ignoredFailedUsers.push(orgUser.user.email);
                    }
                    throw error;
                  }),
              );
            });
          }
          onCloseDialog();
          sendSuccess({
            message: intl.formatMessage({ id: 'user.group.create.user.group.dialog.create.successfully' }),
          }, 2000);
        });
    } catch (error: any) {
      sendError({
        message: intl.formatMessage({ id: 'user.group.create.user.group.dialog.create.failed' }),
        hasDismissIcon: true,
        hasTryAgain: true,
        handleTryAgain: () => {
          filterChanged('email', currentSelectedUserRef.current.map(orgUser => orgUser.user.email));
        },
        handleDismissNotify: () => {
          onCloseDialog();
        },
      });
    } finally {
      setLoading(false);
    }
    try {
      await Promise.all(userGroupPromise);
    } catch (error) {
      await dispatch(fromUserGroupUser.getAllUserGroupUsers({ userGroupId: createdUserGroupId }))
        .then(data => {
          // @ts-ignore
          const allUserGroupUsers = data?.payload?.data.map(({ user }) => user.email);

          const failedAddUsers = currentSelectedUserRef.current.filter(
            user => !allUserGroupUsers.includes(user.user.email)
              && !ignoredFailedUsers.includes(user.user.email),
          );
          if (failedAddUsers.length > 0) {
            sendError({
              message: intl.formatMessage({ id: 'user.group.create.user.group.dialog.create.failed' }),
              hasDismissIcon: true,
              hasTryAgain: true,
              handleTryAgain: () => {
                currentSelectedUserRef.current = failedAddUsers;
                filterChanged('email', failedAddUsers.map(orgUser => orgUser.user.email));
              },
              handleDismissNotify: () => {
                onCloseDialog();
              },
            });
          }
        });
    }
    onSuccessCallback();
  };

  const handleChangeText = (event: { target: { value: any; name: any; }; }) => {
    const { value } = event.target;
    if (value.trim().length <= 64) {
      setGroupName(value);
      setHasError((!groupNameRegexp.test(value) && !groupNameRegexp.test(value))
        || specialCharacterRegexp.test(value));
      setCanSubmit(value.trim() !== '');
      setHasGroupNameExist(userGroups.map(it => it.name.toLowerCase())
        .includes(value.trim().toLowerCase()));
    } else {
      const value2 = value.substring(0, 64);
      setGroupName(value2);
      setHasError(!groupNameRegexp.test(value2)
        || specialCharacterRegexp.test(value2));
      setCanSubmit(value2.trim() !== '');
      setHasGroupNameExist(userGroups.map(it => it.name.toLowerCase())
        .includes(value2.trim().toLowerCase()));
    }
  };

  const handleChangeDescription = (event: { target: { value: any; name: any; }; }) => {
    const { value } = event.target;
    if (value.trim().length <= 255) {
      setGroupDescription(value);
    } else {
      setGroupDescription(value.substring(0, 255));
    }
  };

  const closeDialog = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onCloseDialog();
  };

  const findFilter = (
    currentFilters: Filter<OrganizationUser>[],
    filterName: keyof OrganizationUser,
  ) => {
    const i = currentFilters.findIndex(
      it => it.field === filterName && it.operator === Operator.IN,
    );
    if (i === -1) return undefined;
    return currentFilters[i];
  };

  const defaultValueForUserFilter: OrganizationUser[] = findFilter(currentFilters.current, 'email')?.value
    ?.map((email: string) => orgUsers.find(orgUser => orgUser.user.email === email))
    ?.filter((user: OrganizationUser) => user !== undefined)
    ?? [];

  const handleUserChange: AutocompleteProps<OrganizationUser, true, true, undefined>['onChange'] = (_, value) => {
    currentSelectedUserRef.current = value;
    filterChanged('email', value.map(orgUser => orgUser.user.email));
  };

  const filterChanged = (
    filterName: keyof OrganizationUser,
    valueSet: any[],
  ) => {
    const index = currentFilters.current.findIndex(
      it => it.field === filterName && it.operator === Operator.IN,
    );
    const newFilters = index !== -1 ? [...currentFilters.current]
      : [...currentFilters.current, null];
    if (valueSet.length > 0) {
      newFilters[index !== -1 ? index : newFilters.length - 1] = {
        field: filterName,
        operator: Operator.IN,
        value: valueSet,
      };
    } else {
      if (index === -1) return;
      newFilters.splice(index, 1);
    }
    currentFilters.current = newFilters as any[];
  };

  const PopperComponent = (props: PopperProps) => (
    <Popper
      {...props}
      id={`poper-${defaultValueForUserFilter}`}
      placement="bottom-start"
      modifiers={[
        {
          name: 'preventOverflow',
          enabled: false,
          options: {
            altBoundary: true,
          },
        },
      ]}
    />
  );

  const customMethod = createFilterOptions<OrganizationUser>({
    trim: true,
    stringify: (option: OrganizationUser) => `${option.user.firstName} ${option.user.lastName} ${option.user.email}`,
  });

  const handleClickAway = (event: any) => {
    const e = (event.target as HTMLElement);
    if ((filterInputRef.current && filterInputRef.current.contains(event.target as HTMLElement))
      || e.tagName === 'LI'
      || e.tagName === 'UL'
      || e.tagName === 'P'
      || e.tagName === 'INPUT'
      || e.tagName === 'IMG'
      || (e.tagName === 'DIV'
        && (
          e.className.includes('avatarContainer')
          || e.className.includes('option')
        )
      )) {
      if (e.tagName === 'svg' || e.tagName === 'path') {
        // @ts-ignore
        myRefName.current.focus();
        setFocused(!focused);
      } else if (nameRef?.current?.contains(event.target as HTMLElement)) {
        nameRef.current.focus();
      } else {
        setFocused(true);
      }
    } else {
      // @ts-ignore
      myRefName.current.blur();
      if (descriptionRef?.current?.contains(event.target as HTMLElement)) {
        descriptionRef.current.focus();
      } else {
        myRefName?.current?.focus();
      }
      setFocused(false);
    }
    event.stopPropagation();
  };

  return (
    <>
      <Dialog
        classes={{ paper: classes.dialog }}
        open={isOpen}
        maxWidth="sm"
        placeholder="center"
        fullWidth
      >
        <DialogHeader
          id="user.group.create.user.group.dialog.header"
          className={classes.dlgHeader}
          onClick={closeDialog}
        >
          <Box className={classes.dlgTitle}>
            <TextField inputRef={myRefName} autoFocus={!focused} className={classes.hiddenText} />
            <FormattedMessage id="user.group.create.user.group.dialog.title" />
            <Tooltip
              title={(
                <div className={classes.tooltipInfoContent}>
                  {
                    intl.formatMessage({ id: 'user.group.create.user.group.dialog.tool.tip' }, {
                      a: (data: ReactNode) => (
                        <Link
                          target="_blank"
                          color="inherit"
                          underline="always"
                          fontWeight={500}
                          href={USER_GROUP_DOC_URL}
                        >
                          {data}
                        </Link>
                      ),
                    })
                  }
                </div>
              )}
              placement="right-end"
            >
              <HelpOutlineIcon fontSize="inherit" className={classes.questionIcon} />
            </Tooltip>
          </Box>
        </DialogHeader>
        <DialogBody
          id="user.group.create.user.group.dialog.body"
          className={classes.dlgContent}
        >
          <Box className={classes.textFieldTitle}>
            <Typography id="user.group.create.user.group.dialog.input.group.name">
              <FormattedMessage id="user.group.create.user.group.dialog.input.group.name" />
            </Typography>
            <span className={classes.requireSpan}>*</span>
          </Box>
          <TextField
            id="user.group.create.user.group.dialog.group.name"
            variant="outlined"
            fullWidth
            required
            name="name"
            value={groupName}
            inputRef={nameRef}
            className={`${hasError || hasGroupNameExist ? classes.groupNameTextFieldError : ''} ${classes.textField}`}
            inputProps={{
              classes: {
                input: classes.nameInput,
              },
            }}
            autoFocus
            onChange={handleChangeText}
            placeholder={intl.formatMessage({
              id: 'user.group.create.user.group.dialog.group.name.place_holder',
            })}
            sx={{
              '& .MuiInputBase-input': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
          />
          {!hasGroupNameExist && !hasError && (
            <Typography className={classes.helperText}>
              <FormattedMessage id="user.group.create.user.group.dialog.group.name.full.helper" />
            </Typography>
          )}
          {hasError && (
            <Typography className={classes.groupNameInvalidError}>
              <FormattedMessage id="user.group.create.user.group.dialog.group.name.helper" />
            </Typography>
          )}
          {hasGroupNameExist && (
            <Typography className={classes.groupNameInvalidError}>
              <FormattedMessage id="user.group.create.user.group.dialog.group.name.existed" />
            </Typography>
          )}
          <>
            <Box className={classes.boxSpacing} />
            <Box className={classes.textFieldTitle}>
              <Typography id="user.group.create.user.group.dialog.input.users">
                <FormattedMessage id="user.group.create.user.group.dialog.input.users" />
              </Typography>
            </Box>
            <ClickAwayListener onClickAway={handleClickAway}>
              <Autocomplete
                ref={filterInputRef}
                key={`${defaultValueForUserFilter}`}// adding this would reinitialized the component with new defaultValue
                filterOptions={customMethod}
                className={classes.userFilter}
                classes={{
                  option: classes.option,
                  noOptions: classes.noOptions,
                }}
                multiple
                limitTags={3}
                disabled={loading}
                size="small"
                id="user-filter"
                options={Array.from(new Set([...orgUsers]))}
                defaultValue={defaultValueForUserFilter}
                disableCloseOnSelect
                onBlur={() => {
                  setFocused(false);
                }}
                disableClearable
                selectOnFocus
                onChange={handleUserChange}
                getOptionLabel={orgUser => orgUser.user.email}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                clearText={intl.formatMessage({ id: 'license_utilization.machine_filter.clear_all' })}
                noOptionsText={<NoResult messages={[intl.formatMessage({ id: 'license_utilization.machine_filter.no_results_found' })]} />}
                PopperComponent={PopperComponent}
                renderOption={(props, orgUser, { selected }) => (
                  <li {...props} key={orgUser.user.email} data-user="data-user">
                    <div className={classes.option}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={selected}
                      />
                      <div className={classes.avatarContainer}>
                        <img className={classes.avatar} src={getAvatarSource(orgUser.user.avatar)} alt="img avatar" />
                      </div>
                      <div className={classes.userInfo}>
                        <Typography variant="body2">
                          {orgUser.user?.email}
                        </Typography>
                      </div>
                    </div>
                  </li>
                )}
                // disableInput
                renderInput={params => (
                  <TextField
                    {...params}
                    maxRows={1}
                    variant="outlined"
                    onClick={handleClickAway}
                    className={clsx(classes.textField, classes.chipTextField, {
                      [classes.disableInput]: currentSelectedUserRef.current.length > 3,
                    })}
                    value={currentSelectedUserRef.current}
                    placeholder={
                      currentSelectedUserRef.current.length === 0
                        ? intl.formatMessage({ id: 'user.group.create.user.group.dialog.email.placeholder' })
                        : undefined
                    }
                  />
                )}
                renderTags={(tagValue, getTagProps) => map(tagValue, (option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    label={option.user.email}
                    className={clsx(classes.emailChip)}
                    deleteIcon={(
                      <CircleXmarkIcon />
                    )}
                  />
                ))}
                componentsProps={{
                  popper: {
                    sx: {
                      zIndex: 1400,
                    },
                  },
                }}
                open={focused}
                {...(!focused ? {} : { renderTags: () => null })}
              />
            </ClickAwayListener>
          </>
          <Box className={classes.boxSpacing} />
          <Box className={classes.boxSpacing} />
          <Box className={classes.textFieldTitle}>
            <Typography id="user.group.create.user.group.dialog.input.group.description">
              <FormattedMessage id="user.group.create.user.group.dialog.input.group.description" />
            </Typography>
          </Box>
          <TextField
            id="user.group.create.user.group.dialog.project.description"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            required
            name="name"
            value={groupDescription}
            className={classes.textField}
            inputProps={{
              classes: {
                input: classes.nameInput,
              },
            }}
            onChange={handleChangeDescription}
            placeholder={intl.formatMessage({
              id: 'user.group.create.user.group.dialog.group.description.place_holder',
            })}
            sx={{
              '& .MuiInputBase-input': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
            inputRef={descriptionRef}
          />
          <Typography
            className={classes.helperText}
          >
            <FormattedMessage id="user.group.create.user.group.dialog.group.description.helper" />
          </Typography>
        </DialogBody>
        <DialogFooter
          id="user.group.create.user.group.dialog.footer"
          className={classes.dlgActionButtons}
        >
          <Button
            id="user.group.create.user.group.dialog.button.cancel"
            className={classes.cancelButton}
            type="button"
            variant="text"
            size="small"
            color="primary"
            onClick={closeDialog}
          >
            <FormattedMessage id="common.cancel" />
          </Button>
          <Box className={classes.buttonSpacing} />
          <Button
            id="user.group.create.user.group.dialog.button.save"
            className={clsx(classes.activeBtn, {
              [classes.disableBtn]: !canSubmit
                || hasError
                || hasGroupNameExist,
            })}
            type="submit"
            form="edit-account-name-form"
            color="primary"
            variant="contained"
            size="small"
            disabled={!canSubmit || hasError || hasGroupNameExist}
            onClick={handleSubmit}
          >
            <FormattedMessage id="common.create" />
          </Button>
        </DialogFooter>
      </Dialog>
      {loading && <LoadingProgress />}
    </>
  );
};

export default CreateUserGroupPopup;
