import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { OrganizationFeature, deprecatedFeatures } from '../../../models';
import { useQuery, notFound } from '../../../routes';
import PerSessionWebPurchaseInfo from '../testcloud/PerSesssionWebPurchaseInfo';
import CardAndBillingInformation from '../payment/CardAndBillingInformation';
import { fromAuth, fromCurrentOrgUser, fromTestCloudSubscriptions, useAppDispatch } from '../../../store';
import LoadingProgress from '../../../layout/LoadingProgress';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    borderRadius: '.5rem',
    padding: '1.5rem',
    overflowY: 'auto',
  },
  box: {
    padding: theme.spacing(1),
  },
  getQuoteTitle: {
    color: '#172B4D',
    padding: theme.spacing(0, 2),
    marginBottom: theme.spacing(5.75),
    fontSize: theme.spacing(3),
  },
  licenseContainer: {
    padding: theme.spacing(0, 2),
  },
}));
const TestCloudGetQuote = () => {
  const { get } = useQuery();
  const feature = get('feature');
  const orgId = get('orgId');
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const user = useSelector(fromAuth.selectUser);
  const currentTestCloudSubscription = useSelector(fromTestCloudSubscriptions
    .selectLatestActiveTestCloudSubscriptionByOrganizationId(Number(orgId)));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchInfo = async () => {
      if (!orgId || !user) return;
      setLoading(true);
      await Promise.all([
        dispatch(fromCurrentOrgUser.doGetOrgUserByUserIdAndOrgId(
          { id: user.id, organizationId: +orgId },
        )),
        dispatch(fromTestCloudSubscriptions
          .doGetTestCloudSubscriptionsByOrganizationId({
            organizationId: Number(orgId) || 0,
          })),
      ]);
      setLoading(false);
    };
    fetchInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, user?.email]);

  if (deprecatedFeatures.includes((feature as OrganizationFeature))
  || currentTestCloudSubscription) {
    return <Navigate to={notFound.path} replace />;
  }
  return (
    <>
      {!loading && (
      <Box className={classes.root}>
        <Typography variant="h2" fontWeight="bold" className={classes.getQuoteTitle}>
          <FormattedMessage id="subscriptions.testcloud.purchasing" />
        </Typography>
        <Grid container className={classes.licenseContainer}>
          <Grid item xs={8}>
            <Paper elevation={0}>
              <PerSessionWebPurchaseInfo currentSubscription={undefined} />
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <CardAndBillingInformation currentSubscription={undefined} />
          </Grid>
        </Grid>
      </Box>
      )}
      {loading && <LoadingProgress />}
    </>
  );
};
export default TestCloudGetQuote;
