import Axios from 'axios';
import { DataResponse, GeneratedFields } from '../models';
import { Config } from '../models/config';

export const getConfig = async () => (await Axios.get<DataResponse<Config>>(
  '/v1/config',
  {
    transformRequest: (data, headers) => {
      headers.delete('Authorization');
      return data;
    },
  },
)).data.data;

export const setConfig = async (input: Omit<Config, GeneratedFields | 'onpremise' | 'testOpsPublicUrl' | 'serviceCloudEnabled' | 'iamEnabled'>) => (await Axios.put<DataResponse<Config>>('/v1/config', input)).data.data;
