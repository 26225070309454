import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '.';

export const LAYOUT_FEATURE_KEY = 'layout';
export interface LayoutState {
  isShowMenu: boolean;
  mountedMFE: { [key: string]: boolean };
}

export const createInitialState = (): LayoutState => ({
  isShowMenu: false,
  mountedMFE: {},
});

const layoutSlice = createSlice({
  name: LAYOUT_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {
    doChangeMenuVisibility(state, action: PayloadAction<boolean>) {
      state.isShowMenu = action.payload;
    },
    doHideMenuByNavigateToCreateOrg(state) {
      state.isShowMenu = false;
    },
    doSetMountStatusMFE(state, action: PayloadAction<{ mfeName: string, isMounted: boolean }>) {
      const { mfeName, isMounted } = action.payload;
      state.mountedMFE[mfeName] = isMounted;
    },
  },
});

const selectLayoutFeature = (state: AppState) => state[LAYOUT_FEATURE_KEY];
export const selectIsShowMenu = createSelector(
  selectLayoutFeature,
  layoutState => layoutState.isShowMenu,
);
export const selectIsMountedMFE = (mfeName: string) => createSelector(
  selectLayoutFeature,
  layoutState => layoutState.mountedMFE[mfeName] ?? false,
);

export const {
  doChangeMenuVisibility,
  doHideMenuByNavigateToCreateOrg,
  doSetMountStatusMFE,
} = layoutSlice.actions;
export default layoutSlice.reducer;
