import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from '../../store';
import { fromAuth, fromUserGroup, fromUsers } from '../../store/rootReducer';
import LoadingProgress from '../../layout/LoadingProgress';
import { getFullNameUser } from '../../models/user';
import { AccountRole, AccountUser, OrganizationRole, OrganizationUser } from '../../models';

const useStyles = makeStyles(theme => ({
  pageTitle: {
    fontSize: theme.spacing(2.5),
    fontWeight: theme.typography.fontWeightMedium,
    color: '#233145',
  },
  card: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
  },
  detailTitle: {
    fontSize: theme.spacing(1.75),
    fontWeight: theme.typography.fontWeightMedium,
    color: '#233145',
  },
  detailDesc: {
    fontSize: theme.spacing(1.5),
    fontWeight: theme.typography.fontWeightRegular,
    color: '#808b9a',
  },
  detailContent: {
    fontSize: theme.spacing(1.75),
    fontWeight: theme.typography.fontWeightRegular,
    color: '#233145',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
  },
  textField: {
    width: '100%',
    color: '#233145',
    fontWeight: theme.typography.fontWeightRegular,
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: 'black',
    },
  },
  avatarWrapper: {
    display: 'flex',
    alignItems: 'end',
  },
  avatar: {
    fontSize: theme.spacing(2.5),
    fontWeight: theme.typography.fontWeightMedium,
    color: '#ffffff',
    backgroundColor: '#bdbdbd',
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: theme.spacing(1),
  },
  createdByWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  updateButton: {
    color: '#ffffff',
    backgroundColor: '#1847ca',
    '&:hover': {
      color: '#ffffff',
      backgroundColor: '#1847ca',
    },
    '&.Mui-disabled': {
      background: 'rgba(0, 0, 0, 0.05)',
      color: 'rgba(0, 0, 0, 0.2)',
    },
  },
  updateButtonText: {
    fontSize: theme.spacing(1.75),
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const groupNameRegex = /^(?!.*[^A-Za-z0-9\s\u0080-\uFFFF]).{1,64}$/;

const GROUP_NAME_MAX_LENGTH = 64;
const GROUP_DESC_MAX_LENGTH = 255;

interface UserGroupDetailAboutProps {
  userGroupId: number,
  orgId: number,
  currentOrgUser?: OrganizationUser,
  currentAccountUser?: AccountUser,
}

const UserGroupDetailAbout = (props: UserGroupDetailAboutProps) => {
  const {
    userGroupId,
    orgId,
    currentOrgUser,
    currentAccountUser,
  } = props;

  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const currentUserGroup = useSelector(fromUserGroup.selectUserGroupsById(Number(userGroupId)));
  const createdByUser = useSelector(fromUsers.selectUserById(currentUserGroup?.createdBy ?? 0));
  const isRootOrAdmin = useSelector(fromAuth.selectIsRootOrAdmin);
  const userGroups = useSelector(fromUserGroup.selectAllUserGroupsByOrganizationId(orgId));

  const [userGroupName, setUserGroupName] = useState('');
  const [userGroupDesc, setUserGroupDesc] = useState('');
  const [isFetched, setIsFetched] = useState(false);
  const [createdByAvatar, setCreatedByAvatar] = useState('');
  const [createdByFullName, setCreatedByFullName] = useState('');
  const [isTextChanged, setIsTextChanged] = useState(false);
  const [groupNameInput, setGroupNameInput] = useState('');
  const [groupDescInput, setGroupDescInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [groupNameError, setGroupNameError] = useState(false);
  const [groupDescError, setGroupDescError] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isUpdateEnabled, setIsUpdateEnabled] = useState(false);
  const [isGroupNameDuplicated, setIsGroupNameDuplicated] = useState(false);
  const [groupNameHelperText, setGroupNameHelperText] = useState('');
  const [isGroupNameBlank, setIsGroupNameBlank] = useState(false);

  const handleGroupNameInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => setGroupNameInput(event.target.value);

  const handleGroupDescInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => setGroupDescInput(event.target.value);

  useEffect(() => {
    let error = false;
    if (groupNameInput && !groupNameRegex.test(groupNameInput.trim())) {
      setGroupNameError(true);
      error = true;
    } else {
      setIsGroupNameBlank(!groupNameInput);
      setGroupNameError(false);
      if (userGroupName !== groupNameInput) {
        const otherUserGroupNames = userGroups
          .filter(ug => ug.id !== currentUserGroup?.id)
          .map(ug => ug.name.toLowerCase());
        setIsGroupNameDuplicated(otherUserGroupNames.includes(
          groupNameInput?.trim().toLowerCase(),
        ));
      } else {
        setIsGroupNameDuplicated(false);
      }
    }
    if (groupDescInput && groupDescInput.length > 255) {
      setGroupDescError(true);
      error = true;
    } else {
      setGroupDescError(false);
    }
    setIsError(error);
    setIsTextChanged(userGroupName !== groupNameInput || userGroupDesc !== groupDescInput);
  }, [
    groupNameInput,
    groupDescInput,
    userGroupName,
    userGroupDesc,
  ]);

  useEffect(() => {
    setIsUpdateEnabled(!isError && isTextChanged && !isGroupNameDuplicated && !isGroupNameBlank);
  }, [isTextChanged, isError, isGroupNameDuplicated, isGroupNameBlank]);

  useEffect(() => {
    setGroupNameInput(userGroupName);
    setGroupDescInput(userGroupDesc ?? '');
  }, [
    userGroupName,
    userGroupDesc,
  ]);

  useEffect(() => {
    const fetchInformation = async () => {
      await Promise.all([
        dispatch(fromUsers.doFetchUserById({ id: Number(currentUserGroup?.createdBy ?? 0) })),
      ]);
    };
    fetchInformation();
    setUserGroupName(currentUserGroup?.name);
    setUserGroupDesc(currentUserGroup?.description ?? '');
  }, [currentUserGroup]);

  useEffect(() => {
    setCreatedByAvatar(createdByUser?.avatar ?? '');
    setCreatedByFullName(
      getFullNameUser(createdByUser?.firstName, createdByUser?.lastName, createdByUser?.email),
    );
    setIsFetched(true);
  }, [createdByUser]);

  useEffect(() => {
    if (groupNameError) {
      setGroupNameHelperText(intl.formatMessage({ id: 'user.group.detail.about.groupName.regex.error' }));
    } else if (isGroupNameDuplicated) {
      setGroupNameHelperText(intl.formatMessage({ id: 'user.group.detail.about.groupName.duplicated.error.helptext' }));
    } else {
      setGroupNameHelperText(intl.formatMessage({ id: 'user.group.detail.about.groupName.helpText' }));
    }
  }, [groupNameError, isGroupNameDuplicated]);

  const stringAvatar = (name: string) => {
    if (name.split(' ').length > 1) {
      return ({
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
      });
    }
    return ({
      children: `${name.split(' ')[0][0]}`,
    });
  };

  const trimInput = (text: string) => text.trim().replace(/\s+/g, ' ');

  const doUpdateUserGroup = () => {
    const handleUpdate = async () => {
      setLoading(true);
      await dispatch(
        fromUserGroup.doUpdateUserGroup({
          id: userGroupId,
          name: groupNameInput.trim(),
          description: groupDescInput,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          enqueueSnackbar(
            <FormattedMessage id="user.group.detail.about.success.msg" />,
            { variant: 'success' },
          );
        })
        .catch(() => {
          enqueueSnackbar(
            <FormattedMessage id="user.group.detail.about.error.msg" />,
            { variant: 'error' },
          );
        })
        .finally(() => {
          setLoading(false);
          setGroupNameInput(trimInput(groupNameInput));
          setGroupDescInput(trimInput(groupDescInput));
          setUserGroupName(trimInput(groupNameInput));
          setUserGroupDesc(trimInput(groupDescInput));
        });
    };
    handleUpdate().catch(() => {
      setGroupNameInput(userGroupName);
      setGroupDescInput(userGroupDesc ?? '');
    });
  };

  if (isFetched && (!currentUserGroup)) {
    return null;
  }

  return (
    <>
      <Card className={classes.card}>
        <Grid container spacing={2}>
          {/* About */}
          <Grid item xs={12}>
            <Typography className={classes.pageTitle}>
              {intl.formatMessage({ id: 'user.group.detail.about.title' })}
            </Typography>
          </Grid>

          {/* Created On */}
          <Grid item xs={5}>
            <Box>
              <Typography className={classes.detailTitle}>
                {intl.formatMessage({ id: 'user.group.detail.about.createdOn' })}
              </Typography>
              <Typography className={classes.detailDesc}>
                {intl.formatMessage({ id: 'user.group.detail.about.createdOn.desc' })}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={7} className={classes.gridItem}>
            <Typography className={classes.detailContent} id="user.group.detail.about.created_on">
              {isFetched
                ? intl.formatDate(new Date(currentUserGroup?.createdAt), { month: 'short', day: 'numeric', year: 'numeric' })
                : ''}
            </Typography>
          </Grid>

          {/* Created By */}
          <Grid item xs={5}>
            <Box>
              <Typography className={classes.detailTitle}>
                {intl.formatMessage({ id: 'user.group.detail.about.createdBy' })}
              </Typography>
              <Typography className={classes.detailDesc}>
                {intl.formatMessage({ id: 'user.group.detail.about.createdBy.desc' })}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={7} className={classes.gridItem}>
            <Box className={classes.createdByWrapper}>
              <Box className={classes.avatarWrapper} id="user.group.detail.about.avatar">
                {createdByAvatar && <Avatar className={classes.avatar} src={`${createdByAvatar}`} />}
                {!createdByAvatar && <Avatar id={`organization.card_view.organization_profile.avatar-${orgId}`} className={classes.avatar} {...stringAvatar(createdByFullName?.toUpperCase() ?? '')} />}
              </Box>
              <Typography className={classes.detailContent} id="user.group.detail.about.created_by">
                {createdByFullName}
              </Typography>
            </Box>
          </Grid>

          {/* Group ID */}
          <Grid item xs={5}>
            <Box>
              <Typography className={classes.detailTitle}>
                {intl.formatMessage({ id: 'user.group.detail.about.groupId' })}
              </Typography>
              <Typography className={classes.detailDesc}>
                {intl.formatMessage({ id: 'user.group.detail.about.groupId.desc' })}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={7} className={classes.gridItem}>
            <Typography className={classes.detailContent} id="user.group.detail.about.group_id">
              {isFetched ? currentUserGroup?.id : ''}
            </Typography>
          </Grid>

          {/* Group Name */}
          <Grid item xs={5}>
            <Box>
              <Typography className={classes.detailTitle}>
                {intl.formatMessage({ id: 'user.group.detail.about.groupName' })}
              </Typography>
              <Typography className={classes.detailDesc}>
                {intl.formatMessage({ id: 'user.group.detail.about.groupName.desc' })}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={7} className={classes.gridItem}>
            <TextField
              rows={1}
              className={classes.textField}
              helperText={groupNameHelperText}
              value={groupNameInput}
              error={groupNameError || isGroupNameDuplicated}
              onChange={event => handleGroupNameInputChange(event)}
              inputProps={{ maxLength: GROUP_NAME_MAX_LENGTH }}
              InputProps={{
                classes: {
                  input: classes.detailContent,
                },
              }}
              disabled={!(AccountRole.isAdminOrOwnerRole(currentAccountUser?.role)
                || OrganizationRole.isAdminOrOwnerRole(currentOrgUser?.role)
                || isRootOrAdmin)}
              id="user.group.detail.about.groupName.textField"
            />
          </Grid>

          {/* Group Description */}
          <Grid item xs={5}>
            <Box>
              <Typography className={classes.detailTitle}>
                {intl.formatMessage({ id: 'user.group.detail.about.groupDesc' })}
              </Typography>
              <Typography className={classes.detailDesc}>
                {intl.formatMessage({ id: 'user.group.detail.about.groupDesc.desc' })}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={7} className={classes.gridItem}>
            <TextField
              className={classes.textField}
              helperText={intl.formatMessage({ id: 'user.group.detail.about.groupDesc.helpText' })}
              multiline
              rows={4}
              value={groupDescInput}
              error={groupDescError}
              onChange={event => handleGroupDescInputChange(event)}
              inputProps={{ maxLength: GROUP_DESC_MAX_LENGTH }}
              InputProps={{
                classes: {
                  input: classes.detailContent,
                },
              }}
              disabled={!(AccountRole.isAdminOrOwnerRole(currentAccountUser?.role)
                || OrganizationRole.isAdminOrOwnerRole(currentOrgUser?.role)
                || isRootOrAdmin)}
              id="user.group.detail.about.groupDesc.textField"
            />
          </Grid>

          {/* Update button */}
          {(AccountRole.isAdminOrOwnerRole(currentAccountUser?.role)
            || OrganizationRole.isAdminOrOwnerRole(currentOrgUser?.role)
            || isRootOrAdmin)
            && (
              <Grid item xs={12}>
                <Button
                  className={classes.updateButton}
                  disabled={!isUpdateEnabled}
                  onClick={doUpdateUserGroup}
                  id="user.group.detail.about.update_btn"
                >
                  <Typography className={classes.updateButtonText}>
                    {intl.formatMessage({ id: 'common.update' })}
                  </Typography>
                </Button>
              </Grid>
            )}
        </Grid>
      </Card>
      {(!isFetched || loading) && <LoadingProgress />}
    </>
  );
};

export default UserGroupDetailAbout;
