import { useLaunchDarkly } from '../../launchdarkly';
import AccountSecuritySetting from './AccountSecuritySetting';
import OrgSecuritySetting from './OrgSecuritySetting';

const SecuritySetting = () => {
  const { initialized, flags } = useLaunchDarkly();

  if (!initialized) {
    return null;
  }

  if (flags?.accountSecuritySettings) {
    return <AccountSecuritySetting />;
  }

  return <OrgSecuritySetting />;
};

export default SecuritySetting;
