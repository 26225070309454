import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as KatalonLogoIcon } from '../../layout/icons/KatalonLogoIcon.svg';
import { logout, resolvePath } from '../../routes';
import ListAccount from './ListAccount';
import PendingInvitationList from './PendingInvitationList';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    padding: theme.spacing(5, 2),
    width: 'auto',
    height: '100%',
    minHeight: '100vh',
    backgroundColor: '#F0F3FB',
  },
  subText: {
    fontSize: theme.spacing(3),
    fontWeight: 600,
    color: '#233145',
  },
  head: {
    textAlign: 'center',
  },
  body: {
    margin: '0 auto',
    maxWidth: theme.spacing(102.5),
    minWidth: theme.spacing(93.75),
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(20),
    },
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(10),
    },
  },
  boxRow: {
    flexDirection: 'row',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    fontSize: theme.spacing(2),
  },
  katalonLogoIcon: {
    width: theme.spacing(15.875),
    height: theme.spacing(3.75),
    marginBottom: theme.spacing(2.25),
  },
  selectAccount: {
    color: '#808b9a',
    fontSize: theme.spacing(2),
    float: 'none',
  },
  footerText: {
    display: 'inline',
    fontSize: theme.spacing(2),
  },
}));

const Welcome = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box className={classes.boxRow}>
        <KatalonLogoIcon className={classes.katalonLogoIcon} />
        <Typography variant="subtitle2" className={classes.subText}>
          <FormattedMessage id="welcome.text.title" />
        </Typography>
        <Typography variant="subtitle2" className={classes.selectAccount}>
          <FormattedMessage id="welcome.text.select.account" />
        </Typography>
      </Box>
      <div className={classes.body}>
        <ListAccount />
        <PendingInvitationList />
      </div>
      <Box paddingY={4} className={classes.boxRow}>
        <Typography variant="subtitle2" className={classes.footerText} color="#808b9a">
          <FormattedMessage id="welcome.footer.cannot_find_your_account" />
        </Typography>
        &nbsp;
        <Link href={resolvePath(logout, undefined, {})} underline="none">
          <Typography variant="subtitle2" className={classes.footerText} color="#276EF1">
            <FormattedMessage id="welcome.footer.try_another_email" />
          </Typography>
        </Link>
      </Box>
    </div>
  );
};

export default Welcome;
