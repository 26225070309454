import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SearchIcon from '@mui/icons-material/Search';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import InputAdorment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';
import { Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import orderBy from 'lodash/fp/orderBy';
import React, { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { getAvatarSource, getFullNameUser } from '../../../models';
import { OrganizationRole } from '../../../models/organizationRole';
import { ROWS_PER_PAGE, UserTable } from '../utils';
import RemovedUsersHeaderTable from './RemovedUsersHeaderTable';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    wordBreak: 'break-word',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  paginationBox: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    // there is no font match with design, use custom color here
    color: '#1D3066',
    paddingBottom: theme.spacing(2),
  },
  cell: {
    padding: theme.spacing(1.5, 0, 1.5, 2),
  },

  fullNameCell: {
    minWidth: theme.spacing(25),
    maxWidth: theme.spacing(25),
  },
  email: {
    minWidth: theme.spacing(22),
    maxWidth: theme.spacing(22),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  joinDate: {
    minWidth: theme.spacing(14),
    maxWidth: theme.spacing(14),
  },
  removalDate: {
    minWidth: theme.spacing(18),
    maxWidth: theme.spacing(18),
  },
  removalBy: {
    minWidth: theme.spacing(22),
    maxWidth: theme.spacing(22),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  role: {
    minWidth: theme.spacing(12),
    maxWidth: theme.spacing(12),
  },
  lastLogin: {
    minWidth: theme.spacing(16),
    maxWidth: theme.spacing(16),
  },
  fullNameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  ssoChip: {
    marginLeft: theme.spacing(1),
    borderRadius: theme.spacing(1),
    height: theme.spacing(2),
    width: theme.spacing(5),
    backgroundColor: 'rgba(104, 109, 128, 0.08)',
  },
  ssoChipNew: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0, 0.5),
    borderRadius: theme.spacing(0.5),
    height: theme.spacing(3.375),
    width: theme.spacing(5.625),
    minWidth: theme.spacing(5.625),
    backgroundColor: 'rgba(104, 109, 128, 0.08)',
    color: '#233145',
    '& span': {
      fontSize: theme.spacing(1.75),
      fontWeight: 'normal',
      padding: 0,
    },
  },
  avatar: {
    marginRight: theme.spacing(1),
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  fullName: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  fullNameFlex: {
    flex: 1,
  },
  table: {
    padding: theme.spacing(0, 2),
  },
  checkbox: {
    padding: 0,
    transform: 'scale(0.8)',
  },
  disabledHoverRow: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  filterContainer: {
    margin: theme.spacing(2),
  },
  search: {
    marginRight: theme.spacing(0.5),
    width: theme.spacing(35),
    '& > .MuiInputBase-root': {
      borderRadius: theme.spacing(0.5),
      maxHeight: theme.spacing(4),
      paddingLeft: theme.spacing(1),
    },
    '& > * > .MuiInputBase-inputSizeSmall': {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
    },
  },
  inputSearch: {
    fontSize: '0.875rem',
  },
  textButton: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(0, 0.5),
    minWidth: theme.spacing(5),
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    maxHeight: theme.spacing(4),
  },
  searchIcon: {
    fontSize: theme.spacing(2.1),
    color: '#284169',
  },
  noResultFound: {
    textAlign: 'center',
    margin: theme.spacing(2, 0),
  },
}));

// only accept primitives for sorting
export interface RemovedUserFields {
  id: number;
  fullName: string;
  email: string;
  joinDate?: Date;
  orgRole: OrganizationRole;
  lastAccess: Date | undefined;
  avatar: string;
  removalDate?: Date;
  removedByEmail?: string;
  hasSsoEnabled?: boolean;
}

export type Order = 'asc' | 'desc';
export const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
export const checkedIcon = <CheckBoxIcon color="primary" fontSize="small" />;

interface RemovedUsersTableProps {
  page: number;
  onChangePage: (_: React.ChangeEvent<unknown> | null, value: number) => void;
  dataUser: UserTable[];
  isSsoEnabled: boolean;
}

const RemovedUsersTable = (props: RemovedUsersTableProps) => {
  const { page, onChangePage, dataUser, isSsoEnabled } = props;
  const classes = useStyles();
  const intl = useIntl();
  const [order, setOrder] = useState<Order>('desc');
  const [sortingField, setSortingField] = useState<keyof RemovedUserFields>('joinDate');
  const [currentSearchText, setCurrentSearchText] = useState<string>('');

  const rows: RemovedUserFields[] = dataUser.map(user => {
    const {
      firstName,
      lastName,
      email,
      joinDate,
      orgRole,
      lastLogin,
      id,
      avatar,
      removedByEmail,
      removalDate,
      hasSsoEnabled,
    } = user;
    const avatarSrc = getAvatarSource(avatar);
    return {
      id,
      fullName: getFullNameUser(firstName, lastName, email),
      email,
      joinDate,
      orgRole,
      lastAccess: lastLogin,
      avatar: avatarSrc,
      removalDate,
      removedByEmail,
      hasSsoEnabled,
    };
  });

  const handleRequestSort = (_event: React.MouseEvent<unknown>, field: keyof RemovedUserFields) => {
    const isAsc = sortingField === field && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setSortingField(field);
  };
  const [matchedRows, setMatchedRows] = useState<RemovedUserFields[]>(rows);

  useEffect(() => {
    const searchResultRows = rows.filter(it => (
      it.fullName.toLowerCase().includes(currentSearchText)
        || it.email.toLowerCase().includes(currentSearchText)
    ));
    setMatchedRows(searchResultRows);
    // disable lint for this case, if we add more, the page always rerender in loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUser, currentSearchText]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentSearchText(e.target.value.toLowerCase().trim());
    if (page !== 1) onChangePage(null, 1);
  };
  return (
    <div className={classes.root}>
      <Grid container className={classes.filterContainer}>
        <TextField
          onChange={handleSearchChange}
          size="small"
          variant="standard"
          placeholder={intl.formatMessage({
            id: 'user_management.search.placeholder',
          })}
          InputProps={{
            classes: { input: classes.inputSearch },
            disableUnderline: true,
            startAdornment: (
              <InputAdorment position="start">
                <SearchIcon className={classes.searchIcon} />
              </InputAdorment>
            ),
          }}
          className={classes.search}
          autoComplete="off"
        />
        {/* <Button className={classes.button} variant="contained" startIcon={<FilterIcon />}>
          <Typography variant="subtitle2" className={classes.textButton}>
            <FormattedMessage id="user_management.button.filter" />
          </Typography>
        </Button> */}
      </Grid>
      <TableContainer className={classes.table}>
        <Table>
          <RemovedUsersHeaderTable
            order={order}
            orderBy={sortingField}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {matchedRows.length > 0 ? (
              orderBy(sortingField, order, matchedRows)
                .slice((page - 1) * ROWS_PER_PAGE, (page - 1) * ROWS_PER_PAGE + ROWS_PER_PAGE)
                .map(row => (
                  <TableRow role="checkbox" tabIndex={-1} key={row.id} className={classes.disabledHoverRow}>
                    <TableCell className={clsx(classes.fullNameCell, classes.cell)} padding="none">
                      <div className={classes.fullNameContainer}>
                        <Avatar className={classes.avatar} src={row.avatar} />
                        <span className={clsx(classes.fullName, classes.fullNameFlex)}>
                          {row.fullName}
                        </span>
                        {isSsoEnabled && row.hasSsoEnabled
                          && <Chip className={classes.ssoChipNew} size="small" label="SSO" /> }
                      </div>
                    </TableCell>
                    <TableCell className={clsx(classes.email, classes.cell)} padding="none">
                      <Tooltip
                        title={row.email}
                        placement="top"
                      >
                        <span>
                          {row.email}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={clsx(classes.role, classes.cell)}>
                      {OrganizationRole.getRoleFullName(row.orgRole)}
                    </TableCell>
                    <TableCell className={clsx(classes.joinDate, classes.cell)} padding="none">
                      {row.joinDate ? (
                        <FormattedDate value={new Date(row.joinDate)} month="short" day="numeric" year="numeric" />
                      ) : <FormattedMessage id="user_management.cell.data.not.available" />}
                    </TableCell>
                    <TableCell className={clsx(classes.removalDate, classes.cell)} padding="none">
                      {row.removalDate ? (
                        <FormattedDate
                          value={new Date(row.removalDate)}
                          month="short"
                          day="numeric"
                          hour="numeric"
                          minute="numeric"
                          hourCycle="h23"
                          year="numeric"
                        />
                      ) : <FormattedMessage id="user_management.cell.data.not.available" />}
                    </TableCell>
                    <TableCell className={clsx(classes.removalBy, classes.cell)}>
                      <Tooltip
                        title={row.removedByEmail}
                        placement="top"
                      >
                        <span>
                          {row.removedByEmail}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={clsx(classes.lastLogin, classes.cell)} padding="none">
                      {row.lastAccess ? (
                        <FormattedDate
                          value={new Date(row.lastAccess)}
                          month="short"
                          day="numeric"
                          hour="numeric"
                          minute="numeric"
                          hourCycle="h23"
                          year="numeric"
                        />
                      ) : <FormattedMessage id="user_management.cell.data.not.available" />}
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={7}>
                  <Typography className={classes.noResultFound} variant="subtitle2">
                    {dataUser.length > 0 ? (
                      <FormattedMessage id="user_management.no_result_found" />
                    ) : (
                      <FormattedMessage id="license_utilization.empty_data" />
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {matchedRows.length > 0 && (
        <Box className={classes.paginationBox}>
          <Pagination
            shape="rounded"
            count={Math.ceil(matchedRows.length / ROWS_PER_PAGE)}
            page={page}
            onChange={onChangePage}
          />
          <Typography variant="body2">
            <FormattedMessage
              id={
                matchedRows.length > 1
                  ? 'license_utilization.session_detail.total_records'
                  : 'license_utilization.session_detail.total_record'
              }
              values={{ count: matchedRows.length }}
            />
          </Typography>
        </Box>
      )}
    </div>
  );
};
export default RemovedUsersTable;
