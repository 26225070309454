import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import CopyableLink from '../../components/copyable-link/CopyableLink';
import CustomSwitch from '../../components/switch/CustomSwitch';
import { useConfig } from '../../config';
import { Organization, SsoOptionStatus } from '../../models';
import { fromOrganizationUsers, fromOrganizationUserSso, fromTestOpsUserSsoInvitation, fromUserSsoInvitation } from '../../store';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    padding: theme.spacing(3, 4, 4, 4),
    borderRadius: '1rem',
  },
  title: {
    padding: 0,
  },
  content: {
    padding: theme.spacing(1.5, 0, 0, 0),
  },
  subTitle: {
    marginBottom: theme.spacing(3),
  },
  box: {
    alignContent: 'center',
    alignItems: 'flex-start',
    justifyItems: 'center',
    marginBottom: theme.spacing(2),
  },
  lastBox: {
    marginBottom: theme.spacing(5),
  },
  info: {
    marginLeft: theme.spacing(1.5),
  },
  invitationLink: {
    color: '#516393',
  },
  actions: {
    padding: 0,
  },
  actionsSpacing: {
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
}));

interface EditSsoOptionsDialogProps {
  organizationUserId: number;
  domain: Organization['domain'];
  open: boolean;
  onClose: () => void;
  onSubmit: (
    ssoAuthOptionId: number | undefined,
    basicAuthOptionId: number | undefined,
    basicAuthEnabled: boolean, ssoAuthEnabled: boolean,
  ) => Promise<void>;
}

const getSsoDescription = (domain: string | undefined, isPending: boolean) => {
  if (!domain) {
    if (isPending) {
      return <FormattedMessage id="user_management.edit_sso.sso_auth_pending_custom_domain" />;
    }
    return <FormattedMessage id="user_management.edit_sso.sso_auth_custom_domain" />;
  }
  if (isPending) {
    return (
      <FormattedMessage
        id="user_management.edit_sso.sso_auth_pending"
        values={{
          domain: `${domain}.katalon.io`,
          // eslint-disable-next-line react/no-unstable-nested-components
          a: (domain: string[]) => <Link underline="none" href={`https://${domain}`}>{domain}</Link>,
        }}
      />
    );
  }
  return (
    <FormattedMessage
      id="user_management.edit_sso.sso_auth"
      values={{
        domain: `${domain}.katalon.io`,
        a: (domain: string[]) => <Link underline="none" href={`https://${domain}`}>{domain}</Link>,
      }}
    />
  );
};

const getSsoDescriptionOnPremise = (isPending: boolean) => {
  if (isPending) {
    return <FormattedMessage id="user_management.edit_sso.sso_auth_pending.onpremise" />;
  }
  return <FormattedMessage id="user_management.edit_sso.sso_auth.onpremise" />;
};

const EditSsoOptionsDialog = (props: EditSsoOptionsDialogProps) => {
  const { organizationUserId, domain, open, onClose, onSubmit } = props;
  const classes = useStyles();
  const { config } = useConfig();
  const organizationUser = useSelector(fromOrganizationUsers.selectById(organizationUserId));
  const pendingUserSsoInvitation = useSelector(fromTestOpsUserSsoInvitation
    .selectPendinghByOrganizationIdAndInvitedUserId(
      organizationUser?.organizationId || 0,
      organizationUser?.userId || 0,
    ));
  const k1PendingUserSsoInvitation = useSelector(fromUserSsoInvitation
    .selectPendingByOrganizationIdAndInvitedTestOpsUserId(
      organizationUser?.organizationId || 0,
      organizationUser?.userId || 0,
    ));
  const currentBasicAuth = useSelector(fromOrganizationUserSso
    .selectOneBasicAuthByOrganizationUserId(organizationUserId));
  const currentSsoAuth = useSelector(fromOrganizationUserSso
    .selectOneSsoAuthByOrganizationUserId(organizationUserId));
  const currentSsoAuthEnabled = currentSsoAuth !== undefined;
  const currentBasicAuthEnabled = currentBasicAuth !== undefined;
  const [ssoAuthEnabled, setSsoAuthEnabled] = useState(currentSsoAuthEnabled);
  const [basicAuthEnabled, setBasicAuthEnabled] = useState(currentBasicAuthEnabled);
  const isPendingSso = currentSsoAuth?.status === SsoOptionStatus.PENDING;

  const handleSsoAuthChange = () => setSsoAuthEnabled(!ssoAuthEnabled);

  const handleBasicAuthChange = () => setBasicAuthEnabled(!basicAuthEnabled);

  const handleClose = () => {
    onClose();
    setSsoAuthEnabled(currentSsoAuthEnabled);
    setBasicAuthEnabled(currentBasicAuthEnabled);
  };

  const handleSubmit = async () => {
    // finalBasicAuthId <> undefined && basicAuthEnabled = false => remove basic auth
    // finalBasicAuthId = undefined && basicAuthEnabled = true => add basic auth
    // finalSsoAuthId <> undefined && ssoAuthEnabled = fasle => remove sso auth
    // finalSsoAuthId = undefined && ssoAuthEnabled = true => add sso auth
    onClose();
    await onSubmit(currentSsoAuth?.id, currentBasicAuth?.id, basicAuthEnabled, ssoAuthEnabled);
  };

  useEffect(() => {
    setBasicAuthEnabled(currentBasicAuthEnabled);
    setSsoAuthEnabled(currentSsoAuthEnabled);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationUserId]);

  if (!config) return null;
  const invitationLink = k1PendingUserSsoInvitation ? `${config.publicUrl}/accept-sso?invitation_token=${k1PendingUserSsoInvitation.invitationToken}`
    : pendingUserSsoInvitation ? `${process.env.REACT_APP_ADMIN_URL}/accept-sso?token=${pendingUserSsoInvitation.invitationToken}` : undefined;

  return (
    <Dialog classes={{ paper: classes.dialogPaper }} open={open}>
      <DialogTitle className={classes.title}>
        <FormattedMessage id="user_management.edit_sso.title" />
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography variant="subtitle2" className={classes.subTitle}>
          {
            config?.onpremise ? <FormattedMessage id="user_management.edit_sso.subtitle.onpremise" />
              : (
                <FormattedMessage
                  id="user_management.edit_sso.subtitle"
                  values={{ b: (chunks: string[]) => <strong>{chunks}</strong> }}
                />
              )
          }
        </Typography>
        <Box display="flex" className={classes.box}>
          <CustomSwitch
            onChange={handleSsoAuthChange}
            checked={ssoAuthEnabled}
          />
          <Box className={classes.info}>
            <Typography variant="body2">
              {config?.onpremise
                ? getSsoDescriptionOnPremise(isPendingSso)
                : getSsoDescription(domain, isPendingSso)}
            </Typography>
            {invitationLink && isPendingSso && (
              <div className={classes.invitationLink}>
                <CopyableLink
                  link={invitationLink}
                  displayLink={`${invitationLink.split('token')[0]}...`}
                  isCopyable
                />
              </div>
            )}
          </Box>
        </Box>
        <Box display="flex" className={clsx(classes.box, classes.lastBox)}>
          <CustomSwitch
            onChange={handleBasicAuthChange}
            checked={basicAuthEnabled}
          />
          <Typography variant="body2" className={classes.info}>
            {
              config?.onpremise
                ? <FormattedMessage id="user_management.edit_sso.basic_auth.onpremise" />
                : (
                  <FormattedMessage
                    id="user_management.edit_sso.basic_auth"
                    values={{
                      domain: `${domain}.katalon.io`,
                      a: (domain: string[]) => <Link underline="none" href={`https://${domain}`}>{domain}</Link>,
                    }}
                  />
                )
            }
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions classes={{ root: classes.actions, spacing: classes.actionsSpacing }}>
        <Button size="small" variant="contained" onClick={() => handleClose()}>
          <FormattedMessage id="user_management.edit_sso.cancel" />
        </Button>
        <Button
          disabled={
            ssoAuthEnabled === currentSsoAuthEnabled
              && basicAuthEnabled === currentBasicAuthEnabled
          }
          size="small"
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
        >
          <FormattedMessage id="user_management.edit_sso.save" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditSsoOptionsDialog;
