import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import max from 'lodash/fp/max';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

export interface QuotaInputProps {
  disabled?: boolean,
  minQuota?: number,
  maxQuota?: number,
  initialQuota?: number,
  firstStep?: number,
  step: number,
  currentQuota: number,
  onQuotaChange?: (chosenQuota: number) => void,
}

const useStyles = makeStyles(theme => ({
  root: {
    fontWeight: theme.typography.fontWeightBold,
  },
  textField: {
    width: theme.spacing(17),
    height: theme.spacing(5),
    background: '#FFFFFF',
    border: '1px solid #D5D8DD',
    borderRadius: 6,
  },
}));

const QuotaInput = (props: QuotaInputProps) => {
  const classes = useStyles();
  const { disabled, maxQuota, onQuotaChange, currentQuota } = props;
  // eslint-disable-next-line react/destructuring-assignment
  const minQuota = max([props.minQuota, 0])!;
  // eslint-disable-next-line react/destructuring-assignment
  const initialQuota = max([props.initialQuota, 0]);
  // eslint-disable-next-line react/destructuring-assignment
  const firstStep = max([props.firstStep, 0])!;
  // eslint-disable-next-line react/destructuring-assignment
  const step = max([props.step, 1])!;
  const [chosenQuota, setChosenQuota] = useState(initialQuota ?? 0);
  const getValidQuota = ((selectedQuota: number) => {
    if (maxQuota && (selectedQuota + currentQuota) > maxQuota) {
      return maxQuota - currentQuota;
    }
    if (selectedQuota < minQuota) {
      return minQuota;
    }
    if (selectedQuota > 0 && selectedQuota < firstStep) {
      return selectedQuota < chosenQuota ? 0 : firstStep;
    }
    if (currentQuota && selectedQuota > 0 && selectedQuota < step) {
      return step;
    }
    if (step > 1) {
      return selectedQuota - (selectedQuota % step);
    }
    return selectedQuota;
  });

  const applyQuotaChange = ((quota: number) => {
    setChosenQuota(quota);
    if (onQuotaChange) {
      onQuotaChange(quota);
    }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const applyQuotaDebounced = useCallback(debounce(applyQuotaChange, 1000), []);

  const handleOnChangeQuota = (event: ChangeEvent<HTMLInputElement>) => {
    let selectedQuota = parseInt(event.target.value, 10);
    if (Number.isNaN(selectedQuota) || selectedQuota < 0) {
      selectedQuota = 0;
    }
    const validQuota = getValidQuota(selectedQuota);
    setChosenQuota(selectedQuota);
    applyQuotaDebounced(validQuota);
  };

  useEffect(() => {
    setChosenQuota(initialQuota ?? 0);
  }, [initialQuota]);

  return (
    <TextField
      disabled={disabled}
      variant="outlined"
      size="small"
      type="number"
      required
      value={Number(chosenQuota).toString()}
      onChange={handleOnChangeQuota}
      InputProps={{
        className: classes.textField,
        inputProps: { min: minQuota, step },
      }}
    />
  );
};

export default QuotaInput;
