import Axios from 'axios';
import { PageableResponse, TestOpsEnterpriseProduct } from '../models';
import { composeQuery, Query } from '../models/query';

export const getProductsInfo = async (...criteria: Query<TestOpsEnterpriseProduct>[]) => (
  await Axios.get<PageableResponse<TestOpsEnterpriseProduct>>('/v1/enterprise-products', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    params:
      composeQuery<TestOpsEnterpriseProduct>(...criteria),
    data: {},
  })).data.data;
