import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { ReactNode, useState, useEffect } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import { useTheme } from '@mui/material/styles';
import {
  Account,
  OrganizationFeature,
} from '../../models';
import K1TextField from '../../components/design-system/K1TextField';
import K1Typography from '../../components/design-system/K1Typography';
import {
  fromAccounts,
  fromAccountUsers,
  fromSubscriptions,
  useAppDispatch,
} from '../../store';
import { useNavigate, welcome } from '../../routes';
import LoadingProgress from '../../layout/LoadingProgress';
import { ReactComponent as WarningIcon } from './exclamation-triangle.svg';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
    minWidth: theme.spacing(96),
    borderRadius: theme.spacing(1),
    display: 'flex',
  },
  header: {
    fontSize: theme.spacing(2.5),
    fontWeight: theme.typography.fontWeightMedium,
    color: '#233145',
  },
  title: {
    marginTop: theme.spacing(3),
    fontSize: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
    color: '#233145',
  },
  description: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(3),
    fontSize: theme.spacing(1.75),
    color: '#233145',
  },
  dangerZoneBox: {
    margin: theme.spacing(3),
    display: 'column',
  },
  deleteBtn: {
    padding: theme.spacing(2),
    color: '#ffffff',
    background: '#E11900',
    height: theme.spacing(5),
    alignItems: 'center',
    borderRadius: theme.spacing(0.5),
    '&:hover': {
      background: '#E11900',
    },
  },
  cancelBtn: {
    padding: theme.spacing(2),
    color: '#233145',
    background: '#F0F1F2',
    height: theme.spacing(5),
    alignItems: 'center',
    borderRadius: theme.spacing(0.5),
  },
  textDeleteBtn: {
    color: '#ffffff',
  },
  dialog: {
    position: 'absolute',
    borderRadius: theme.spacing(0.75),
    minWidth: theme.spacing(75),
    padding: theme.spacing(2, 2.5),
  },
  dialogBox: {
    display: 'flex',
    maxHeight: theme.spacing(3.75),
    alignItems: 'center',
  },
  dialogDescription: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    fontSize: theme.spacing(1.75),
    color: '#233145',
    fontWeight: theme.typography.fontWeightMedium,
  },
  accountNameTextField: {
    marginBottom: theme.spacing(3),
  },
  buttonGroup: {
    gap: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  iconClose: {
    fontSize: 'large',
  },
  iconButton: {
    ariaLabel: 'close',
    edge: 'end',
    size: 'large',
    marginTop: theme.spacing(-0.5),
    marginRight: theme.spacing(-2),
  },
  alertBanner: {
    backgroundColor: '#ffede6',
    height: theme.spacing(4.75),
    borderRadius: theme.spacing(0.5),
    position: 'relative',
    marginTop: theme.spacing(1.5),
  },
  alertText: {
    whiteSpace: 'nowrap',
    fontSize: theme.spacing(1.75),
    fontWeight: theme.typography.fontWeightMedium,
  },
  alertIcon: {
    fontSize: theme.spacing(1.75),
    width: theme.spacing(2.75),
    position: 'absolute',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(0.125),
    display: 'block',
  },
  alertCenter: {
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
  },
  alertArea: {
    marginLeft: theme.spacing(4),
  },
}));

interface DeleteAccountProperties {
  userId: number;
  account: Account;
}

const DeleteAccount = (props: DeleteAccountProperties) => {
  const { userId, account } = props;
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { navigate, replaceQuery } = useNavigate();
  const [loading, setLoading] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const [licenses, setLicenses] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const openDeleteAccountDialog = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setOpen(true);
    setCanDelete(false);
  };

  const handleClose = () => {
    setOpen(false);
    setCanDelete(false);
  };

  useEffect(() => {
    if (open) {
      dispatch(fromSubscriptions
        .doGetActiveRecurlySubscriptionsByAccountId({ id: account.id }))
        .then(unwrapResult)
        .then(subs => subs.filter(sub => new Date(sub.endDate).getTime() > Date.now()))
        .then(data => {
          const licenses = data
            .map(item => OrganizationFeature.getFeatureHalfFullName(item.feature))
            .join(', ');
          setLicenses(licenses);
          setHasActiveSubscription(data.length > 0);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, account.id]);

  const handleChangeAccountName = (event: any) => {
    setCanDelete(event.target.value.trim() === account.name.trim());
  };

  const handleSubmit = async () => {
    setLoading(true);
    await dispatch(fromAccounts.doDeleteAccount({ id: account.id }))
      .then(unwrapResult)
      .then(() => {
        enqueueSnackbar(
          <FormattedMessage id="delete_account_dialog.message.success" />,
          { variant: 'success' },
        );
        setTimeout(() => {
          navigate(welcome.path, replaceQuery({}));
        }, 2000);
      })
      .then(() => dispatch(fromAccountUsers.doGetAccountUsersByUserId({ userId })))
      .catch(() => {
        enqueueSnackbar(
          <FormattedMessage
            id="delete_account_dialog.account.existed.subscriptions"
            values={{
              licenses,
              br: <br />,
            }}
          />,
          { variant: 'error' },
        );
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  };

  return (
    <>
      <Paper key="app.page.account.settings.danger.zone" elevation={1} className={classes.root}>
        <Box className={classes.dangerZoneBox}>
          <Typography variant="subtitle2" className={classes.header}>
            <FormattedMessage id="account_setting.danger_zone.header" />
          </Typography>
          <Typography variant="subtitle2" className={classes.title}>
            <FormattedMessage id="account_setting.danger_zone.title" />
          </Typography>
          <Typography variant="subtitle2" className={classes.description}>
            <FormattedMessage id="account_setting.danger_zone.description" />
          </Typography>
          <Button
            id="account_setting.delete_button"
            onClick={openDeleteAccountDialog}
            className={classes.deleteBtn}
            variant="contained"
          >
            <FormattedMessage id="account_setting.danger_zone.button" />
          </Button>
        </Box>
      </Paper>
      <Dialog
        classes={{ paper: classes.dialog }}
        open={open}
        onClose={handleClose}
      >
        <Box justifyContent="space-between" className={classes.dialogBox}>
          <K1Typography variant="h2" className={classes.header}>
            <FormattedMessage id="account_setting.delete_account_dialog.header" />
          </K1Typography>
          <Box>
            <IconButton
              id="create.account.dialog.close.icon"
              className={classes.iconButton}
              onClick={handleClose}
            >
              <CloseIcon className={classes.iconClose} />
            </IconButton>
          </Box>
        </Box>
        <Box className={classes.alertBanner} sx={{ display: `${hasActiveSubscription ? 'inline' : 'none'}` }}>
          <Box className={`${classes.alertIcon} ${classes.alertCenter}`}>
            <WarningIcon />
          </Box>
          <Box className={`${classes.alertArea} ${classes.alertCenter}`}>
            <Typography className={classes.alertText}>
              <FormattedMessage id="account_setting.delete_account_dialog.alert" />
            </Typography>
          </Box>
        </Box>
        <K1Typography variant="h3" className={classes.dialogDescription}>
          {intl.formatMessage({ id: 'account_setting.delete_account_dialog.description' }, {
            accountName: account.name,
            p: (data: ReactNode) => (
              <span
                style={{
                  fontWeight: theme.typography.fontWeightBold,
                  wordBreak: 'break-all',
                }}
              >
                {data}
              </span>
            ),
          })}
        </K1Typography>
        <K1TextField
          id="account_setting.delete_account_dialog.account_name.text_field"
          hiddenLabel
          className={classes.accountNameTextField}
          onChange={handleChangeAccountName}
          inputProps={{
            maxLength: 255,
          }}
          placeholder={intl.formatMessage({ id: 'account_setting.delete_account_dialog.placeholder' }, { accountName: account.name })}
        />
        <Box className={classes.buttonGroup} mt={2}>
          <Button
            id="account_setting.delete_account_dialog.form.button.cancel"
            className={classes.cancelBtn}
            variant="contained"
            onClick={handleClose}
          >
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            id="account_setting.delete_account_dialog.form.button.delete"
            className={classes.deleteBtn}
            disabled={!canDelete}
            variant="contained"
            onClick={handleSubmit}
          >
            <FormattedMessage id="account_setting.delete_account_dialog.button" />
          </Button>
        </Box>
        {loading && <LoadingProgress />}
      </Dialog>
    </>
  );
};

export default DeleteAccount;
