import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import debounce from 'lodash/fp/debounce';
import isEmpty from 'lodash/isEmpty';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import { Invoice, InvoiceState } from '../../../models/invoice';
import { useAppDispatch } from '../../../store';
import { GET_INVOICE_CANNOT_READ_RECURLY_SUBSCRIPTION, GET_INVOICE_RECURLY_SUBSCRIPTION_NOT_EXISTED } from '../../../store/invoiceSlice';
import { fromInvoices, fromOrganizations, fromTestOpsPlatformConfiguration } from '../../../store/rootReducer';
import Forbidden from '../../forbidden';
import InvoiceTable from './InvoiceTable';
import { useQuery } from '../../../routes';
import ButtonToSubscriptionManagement from './ButtonToSubscriptionManagement';
import ButtonToLicenseManagement from './ButtonToLicenseManagement';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    height: '100%',
    overflowY: 'auto',
  },
  title: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2),
    display: 'flex',
    fontSize: theme.spacing(3),
  },
  section: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(4, 8, 4, 8),
  },
  statusContainer: {
    padding: theme.spacing(2, 0),
  },
  statusTitle: {
    fontSize: theme.spacing(2.5),
    marginBottom: theme.spacing(2),
    color: '#172B4D',
    fontWeight: 600,
  },
  statusDescription: {
    marginBottom: theme.spacing(2),
    fontSize: theme.spacing(2),
    fontWeight: '400',
  },
  manageLicenseButton: {
    height: theme.spacing(4),
    marginBottom: theme.spacing(2),
    fontWeight: '500',
  },
  tableCell: {
    padding: theme.spacing(1.75, 0),
    maxWidth: theme.spacing(33),
    '&.MuiTableCell-root': {
      paddingBottom: theme.spacing(1),
      borderBottom: '1px solid #DCDFE6',
    },
  },
  tableInnerCell: {
    padding: theme.spacing(2, 0),
    maxWidth: theme.spacing(33),
    color: '#172B4D',
  },
}));

const PlatformCheckOutPaymentStatus = () => {
  const classes = useStyles();
  const intl = useIntl();
  const { get, getAll } = useQuery();
  const dispatch = useAppDispatch();
  const accountId = Number(get('accountId'));
  const invoiceNumbers = Array.from(new Set(getAll('invoiceNumber')));
  const packageName = get('starterPackageName') ?? undefined;
  const packageInvoiceNumbers = Array.from(new Set(getAll('starterPackageInvoice')));
  const invoices = (useSelector(
    fromInvoices.selectInvoiceByNumbers([...invoiceNumbers, ...packageInvoiceNumbers]),
  ).filter(it => it!!) as Invoice[]);
  const organizations = useSelector(fromOrganizations.selectAllByAccountId(accountId));
  const orgId = isEmpty(organizations) ? 0 : organizations[0].id;
  const [loading, setLoading] = useState(true);
  const [isForbiddenError, setIsForbiddenError] = useState(false);
  const errors = useSelector(fromInvoices.selectErrors());
  const { enqueueSnackbar } = useSnackbar();

  const singleProductInvoices = invoices
    .filter(invoice => invoiceNumbers.includes(invoice?.invoiceNumber!!))
    .sort((a, b) => {
      if (a?.state! > b?.state!) return -1;
      if (a?.state! < b?.state!) return 1;
      return 0;
    });

  const packageInvoices = invoices
    .filter(invoice => packageInvoiceNumbers.includes(invoice?.invoiceNumber!!));

  useEffect(() => {
    const fetchInfo = async () => {
      await Promise.all([
        dispatch(fromTestOpsPlatformConfiguration
          .doGetTestOpsPlatformConfiguration()),
      ]);
    };
    fetchInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!accountId) return;
    const fetchInformation = async () => {
      setLoading(true);
      await Promise.all([
        dispatch(
          fromInvoices.doGetInvoiceByInvoiceNumbers({
            accountId: Number(accountId),
            invoiceNumbers: [...invoiceNumbers, ...packageInvoiceNumbers],
          }),
        ),
        dispatch(fromOrganizations.doFetchOrganizationByAccountId({ accountId })),
      ]);
      setLoading(false);
    };
    fetchInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  const pendingInvoices = invoices
    .filter(it => it?.state !== InvoiceState.FAILED && it?.state !== InvoiceState.PAID)
    .map(invoice => invoice.invoiceNumber!!);

  useEffect(() => {
    if (!loading && pendingInvoices.length > 0) {
      setLoading(true);
      debounce(4000, () => {
        setLoading(true);
        const fetchInvoice = async () => {
          await dispatch(
            fromInvoices.doGetInvoiceByInvoiceNumbers({
              accountId: Number(accountId),
              invoiceNumbers: pendingInvoices,
            }),
          );
        };
        fetchInvoice().catch().finally(() => setLoading(false));
      })();
    }
  }, [pendingInvoices]);

  useEffect(() => {
    if (errors && errors[0]) {
      switch (errors[0].message) {
        case GET_INVOICE_RECURLY_SUBSCRIPTION_NOT_EXISTED:
          enqueueSnackbar(
            <FormattedMessage id="invoice_recurly_subscription_not_existed" />,
            {
              variant: 'error',
              preventDuplicate: true,
              key: intl.formatMessage({ id: 'invoice_recurly_subscription_not_existed' }),
              autoHideDuration: 2000,
              resumeHideDuration: 0,
            },
          );
          dispatch(fromInvoices.doResetErrors());
          setIsForbiddenError(true);
          break;
        case GET_INVOICE_CANNOT_READ_RECURLY_SUBSCRIPTION:
          enqueueSnackbar(
            <FormattedMessage id="invoice_cannot_read_recurly_subscription" />,
            {
              variant: 'error',
              preventDuplicate: true,
              key: intl.formatMessage({ id: 'invoice_cannot_read_recurly_subscription' }),
              autoHideDuration: 2000,
              resumeHideDuration: 0,
            },
          );
          dispatch(fromInvoices.doResetErrors());
          setIsForbiddenError(true);
          break;
        default:
          break;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  // if error code is permision errors -> 403 page but with the sidebars and stuff.
  if (isForbiddenError) {
    return <Forbidden />;
  }

  return (
    <div id="payment-status" className={classes.root}>
      <Paper className={classes.section} elevation={0}>
        <Grid
          id="payment-status-container"
          container
          textAlign="center"
          justifyContent="center"
          alignItems="center"
          className={classes.statusContainer}
        >
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.statusTitle}>
              <FormattedMessage id="payment_status.processed_status" />
            </Typography>
            <Typography variant="h3" className={classes.statusDescription}>
              <FormattedMessage
                id="payment_status.processed_description"
                values={{
                  br: <br />,
                }}
              />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div>
              <ButtonToSubscriptionManagement />
              <ButtonToLicenseManagement orgId={orgId} />
            </div>
          </Grid>
        </Grid>
      </Paper>
      <Paper className={classes.section} elevation={0}>
        <Typography variant="h2" className={classes.statusTitle}>
          {intl.formatMessage({ id: 'payment_success.order_summary.title' })}
        </Typography>
        <InvoiceTable
          singleProductInvoices={singleProductInvoices}
          packageName={packageName}
          packageInvoices={packageInvoices}
        />
      </Paper>
    </div>
  );
};

export default PlatformCheckOutPaymentStatus;
