import { ComponentType, createContext, ReactNode, useContext, useMemo, useState } from 'react';
import Snackbar, { SnackbarCloseReason, SnackbarProps } from '@mui/material/Snackbar';
import { useIntl } from 'react-intl';
import CustomAlert, { CustomAlertProps } from '../components/alert/CustomAlert';

export interface NotificationProps {
  open: boolean;
  sendNotify: (props: CustomAlertProps, snackProps?: Partial<SnackbarProps>) => void;
  sendSuccess: (props: CustomAlertProps, autoHideDuration?: SnackbarProps['autoHideDuration']) => void;
  sendError: (props: CustomAlertProps, override?: boolean) => void;
  dismissNotify: () => void;
}

export const NotificationContext = createContext<NotificationProps>({
  open: false,
  dismissNotify: () => {},
  sendNotify: () => {},
  sendSuccess: () => {},
  sendError: () => {},
});

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState<NotificationProps['open']>(false);
  const [alertProps, setAlertProps] = useState<CustomAlertProps | undefined>();
  const [snackbarProps, setSnackbarProps] = useState<Partial<SnackbarProps>>({});

  const intl = useIntl();

  const sendNotify = (
    props: CustomAlertProps,
    snackProps?: Partial<SnackbarProps>,
    override: boolean = false,
  ) => {
    if (open && !override) {
      return;
    }
    if (override) {
      dismissNotify();
    }
    setOpen(true);
    setAlertProps(props);
    if (snackProps) {
      setSnackbarProps(snackProps);
    }
  };

  const sendSuccess = (props: CustomAlertProps, autoHideDuration?: SnackbarProps['autoHideDuration']) => {
    sendNotify({
      ...props,
      severity: 'success',
      title: intl.formatMessage({ id: 'common.notification.success' }),
    }, { autoHideDuration: autoHideDuration ?? 3000 }, true);
  };

  const sendError = (props: CustomAlertProps, override?: boolean) => {
    sendNotify(
      {
        ...props,
        severity: 'error',
        title: intl.formatMessage({ id: 'common.notification.error' }),
      },
      { autoHideDuration: null },
      override,
    );
  };

  const dismissNotify = () => {
    setSnackbarProps({});
    setAlertProps(undefined);
    setOpen(false);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    if (snackbarProps.onClose) {
      snackbarProps.onClose(event, reason);
    }

    dismissNotify();
  };

  const handleAlertTryAgain = () => {
    dismissNotify();
    // Call back
    if (alertProps?.handleTryAgain) {
      alertProps.handleTryAgain();
    }
  };

  const handleAlertDismiss = () => {
    dismissNotify();
    // Call back
    if (alertProps?.handleDismissNotify) {
      alertProps.handleDismissNotify();
    }
  };

  const notificationContextValue = useMemo(
    () => ({
      open,
      sendNotify,
      dismissNotify,
      sendSuccess,
      sendError,
    }),
    [open, sendNotify, dismissNotify, sendSuccess, sendError],
  );

  return (
    <NotificationContext.Provider value={notificationContextValue}>
      {children}
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        {...snackbarProps}
        onClose={handleClose}
      >
        <div>
          {alertProps && (
            <CustomAlert
              {...alertProps}
              handleTryAgain={handleAlertTryAgain}
              handleDismissNotify={handleAlertDismiss}
            />
          )}
        </div>
      </Snackbar>
    </NotificationContext.Provider>
  );
};

export const withNotification:
  <T extends NotificationProps>(Component: ComponentType<T>) =>
ComponentType<Pick<T, Exclude<keyof T, keyof NotificationProps>>> = (Component: any) => (
  props => <Component {...props} {...useContext(NotificationContext)} />
);

export const useNotification = () => useContext(NotificationContext);
