import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import orderBy from 'lodash/fp/orderBy';
import { SubscriptionStatus } from '../models/subscriptionStatus';
import { AppState } from '.';
import {
  exceptionOf,
  SerializedException,
  SubscriptionType,
  TestCloudSubscription,
  TestOpsSubscriptionStatus,
} from '../models';
import { Operator, Query } from '../models/query';
import { TestCloudSubscriptionService } from '../services';
import { AdditionalResources } from './subscriptionsSlice';

export const TESTCLOUD_SUBSCRIPTION_KEY = 'testCloudSubscription';

interface TestCloudMinuteTier {
  tier: number;
  credits: number;
  concurrentSessions: number;
}

interface TestCloudSubscriptionsState extends EntityState<TestCloudSubscription> {
  loading: boolean;
  errors: SerializedException[];
  count: number;
  testCloudMinuteTiers: TestCloudMinuteTier[];
}

const testCloudSubscriptionsAdapter = createEntityAdapter<TestCloudSubscription>();
const createInitialState = ():
TestCloudSubscriptionsState => testCloudSubscriptionsAdapter.getInitialState({
  testCloudMinuteTiers: [
    { tier: 1, credits: 15000, concurrentSessions: 10 },
    { tier: 2, credits: 30000, concurrentSessions: 15 },
    { tier: 3, credits: 50000, concurrentSessions: 25 },
    { tier: 4, credits: 100000, concurrentSessions: 50 },
    { tier: 5, credits: 200000, concurrentSessions: 100 },
  ],
  loading: false,
  count: 0,
  errors: [],
});
export const doGetTestCloudSubscriptionsByOrganizationId = createAsyncThunk(
  'testCloudSubscriptions/getSubscriptions',
  (input: Pick<TestCloudSubscription, 'organizationId'> & AdditionalResources) => {
    const criteria: Query<TestCloudSubscription>[] = [
      {
        field: 'organizationId',
        operator: Operator.EQ,
        value: input.organizationId,
      },
      {
        field: 'archived',
        operator: Operator.EQ,
        value: false,
      },
      { field: 'createdAt', desc: true },
    ];
    return TestCloudSubscriptionService.getTestCloudSubscriptions(
      input.checkHasPremierSuccess || false,
      ...criteria,
    );
  },
);

export const doGetAccountTestCloudSubscriptionsOrgLevel = createAsyncThunk(
  'testCloudSubscriptions/getSubscriptionsByAccountIdOgrLevel',
  (input: Pick<TestCloudSubscription, 'accountId'> & AdditionalResources) => {
    const criteria: Query<TestCloudSubscription>[] = [
      {
        field: 'accountId',
        operator: Operator.EQ,
        value: input.accountId,
      },
      {
        field: 'archived',
        operator: Operator.EQ,
        value: false,
      },
      { field: 'createdAt', desc: true },
    ];
    return TestCloudSubscriptionService.getAccountTestCloudSubscriptionsOrgLevel(
      input.checkHasPremierSuccess || false,
      ...criteria,
    );
  },
);

export const doGetActiveByAccountId = createAsyncThunk(
  'testCloudSubscriptions/getActiveSubscriptionsByAccountId',
  async (input: Required<Pick<TestCloudSubscription, 'accountId'>> & AdditionalResources, { rejectWithValue }) => {
    const criteria: Query<TestCloudSubscription>[] = [
      {
        field: 'accountId',
        operator: Operator.EQ,
        value: input.accountId,
      },
      {
        field: 'archived',
        operator: Operator.EQ,
        value: false,
      },
      {
        field: 'status',
        operator: Operator.EQ,
        value: SubscriptionStatus.ACTIVE,
      },
      { field: 'createdAt', desc: true },
    ];
    try {
      return (await TestCloudSubscriptionService.getTestCloudSubscriptionsByAccountId(
        input.checkHasPremierSuccess || false,
        ...criteria,
      ));
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doGetByAccountId = createAsyncThunk(
  'testCloudSubscriptions/getSubscriptionsByAccountId',
  async (input: Required<Pick<TestCloudSubscription, 'accountId'>> & AdditionalResources, { rejectWithValue }) => {
    const criteria: Query<TestCloudSubscription>[] = [
      {
        field: 'accountId',
        operator: Operator.EQ,
        value: input.accountId,
      },
      {
        field: 'archived',
        operator: Operator.EQ,
        value: false,
      },
      { field: 'createdAt', desc: true },
    ];
    try {
      return (await TestCloudSubscriptionService.getTestCloudSubscriptionsByAccountId(
        input.checkHasPremierSuccess || false,
        ...criteria,
      ));
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doCancelSubscription = createAsyncThunk(
  'testCloudSubscriptions/cancelSubscription',
  async (input: Required<Pick<TestCloudSubscription, 'id'>>, { rejectWithValue }) => {
    try {
      const data = await TestCloudSubscriptionService.cancelTestCloudSubscription(input);
      return data;
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doReactivateSubscription = createAsyncThunk(
  'testCloudSubscriptions/reactivateSubscription',
  async (input: Required<Pick<TestCloudSubscription, 'id'>>, { rejectWithValue }) => {
    try {
      const data = await TestCloudSubscriptionService.reactivateTestCloudSubscription(input);
      return data;
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doResetEntity = createAction('entity/reset');

const testCloudSubscriptionsSlice = createSlice({
  name: TESTCLOUD_SUBSCRIPTION_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doGetTestCloudSubscriptionsByOrganizationId.pending, state => {
      state.loading = true;
      state.errors = [];
    });
    builder.addCase(doGetTestCloudSubscriptionsByOrganizationId.fulfilled, (state, action) => {
      state.loading = false;
      testCloudSubscriptionsAdapter.upsertMany(state, action.payload.data.map(it => ({
        ...it,
        subscriptionType: (it.recurlySubscription === null || it.recurlySubscriptionId === 0)
          ? SubscriptionType.TRIAL_GRANTED
          : SubscriptionType.PAID,
      })));
      state.count = action.payload.total;
    });
    builder.addCase(doGetTestCloudSubscriptionsByOrganizationId.rejected, state => {
      state.loading = false;
    });
    builder.addCase(doGetAccountTestCloudSubscriptionsOrgLevel.pending, state => {
      state.loading = true;
      state.errors = [];
    });
    builder.addCase(doGetAccountTestCloudSubscriptionsOrgLevel.fulfilled, (state, action) => {
      state.loading = false;
      testCloudSubscriptionsAdapter.upsertMany(state, action.payload.data.map(it => ({
        ...it,
        subscriptionType: (it.recurlySubscription === null || it.recurlySubscriptionId === 0)
          ? SubscriptionType.TRIAL_INITIAL
          : SubscriptionType.PAID,
      })));
      state.count = action.payload.total;
    });
    builder.addCase(doGetAccountTestCloudSubscriptionsOrgLevel.rejected, state => {
      state.loading = false;
    });
    builder.addCase(doCancelSubscription.pending, state => {
      state.loading = true;
      state.errors = [];
    });
    builder.addCase(doCancelSubscription.rejected, (state, action) => {
      state.loading = false;
      state.errors.push(action.payload as SerializedException);
    });
    builder.addCase(doCancelSubscription.fulfilled, state => {
      state.loading = false;
      state.errors = [];
    });
    builder.addCase(doReactivateSubscription.pending, state => {
      state.loading = true;
      state.errors = [];
    });
    builder.addCase(doReactivateSubscription.rejected, (state, action) => {
      state.loading = false;
      state.errors.push(action.payload as SerializedException);
    });
    builder.addCase(doReactivateSubscription.fulfilled, state => {
      state.loading = false;
      state.errors = [];
    });
    builder.addCase(doGetActiveByAccountId.pending, state => {
      state.loading = true;
      state.errors = [];
    });
    builder.addCase(doGetActiveByAccountId.rejected, (state, action) => {
      state.loading = false;
      state.errors.push(action.payload as SerializedException);
    });
    builder.addCase(doGetActiveByAccountId.fulfilled, (state, action) => {
      state.loading = false;
      testCloudSubscriptionsAdapter.upsertMany(state, action.payload.data.map(it => ({
        ...it,
        subscriptionType: (it.recurlySubscription === null || it.recurlySubscriptionId === 0)
          ? SubscriptionType.TRIAL_GRANTED
          : SubscriptionType.PAID,
      })));
      state.errors = [];
    });
    builder.addCase(doGetByAccountId.pending, state => {
      state.loading = true;
      state.errors = [];
    });
    builder.addCase(doGetByAccountId.rejected, (state, action) => {
      state.loading = false;
      state.errors.push(action.payload as SerializedException);
    });
    builder.addCase(doGetByAccountId.fulfilled, (state, action) => {
      state.loading = false;
      testCloudSubscriptionsAdapter.upsertMany(state, action.payload.data.map(it => ({
        ...it,
        subscriptionType: (it.recurlySubscription === null || it.recurlySubscriptionId === 0)
          ? SubscriptionType.TRIAL_GRANTED
          : SubscriptionType.PAID,
      })));
      state.errors = [];
    });
    builder.addCase(doResetEntity, state => {
      testCloudSubscriptionsAdapter.removeAll(state);
    });
  },
});

const selectTestCloudSubscriptions = (state: AppState) => state[TESTCLOUD_SUBSCRIPTION_KEY];

export const {
  selectAll: selectAllTestCloudSubscriptions,
  selectIds,
  selectEntities,
} = testCloudSubscriptionsAdapter.getSelectors(selectTestCloudSubscriptions);

export const selectTestCloudSubscriptionByOrganizationId = (
  orgId: TestCloudSubscription['organizationId'],
) => createSelector(
  selectAllTestCloudSubscriptions,
  testCloudSubscriptions => testCloudSubscriptions.filter(it => it.organizationId === orgId),
);

export const selectLatestActiveTestCloudSubscriptionByOrganizationId = (
  orgId: TestCloudSubscription['organizationId'],
) => createSelector(
  selectAllTestCloudSubscriptions,
  testCloudSubscriptions => orderBy('createdAt', 'desc', testCloudSubscriptions).find(
    it => it.organizationId === orgId
    && it.status === TestOpsSubscriptionStatus.ACTIVE
    && it.expiredAt.valueOf() > new Date().valueOf(),
  ),
);

export const selectLatestInactiveTestCloudSubscriptionByOrganizationId = (
  orgId: TestCloudSubscription['organizationId'],
) => createSelector(
  selectAllTestCloudSubscriptions,
  testCloudSubscriptions => testCloudSubscriptions.find(
    it => it.organizationId === orgId && it.status === TestOpsSubscriptionStatus.INACTIVE,
  ),
);

export const selectLatestTestCloudSubscriptionByOrganizationId = (
  orgId: TestCloudSubscription['organizationId'],
) => createSelector(
  selectAllTestCloudSubscriptions,
  testCloudSubscriptions => orderBy('createdAt', 'desc', testCloudSubscriptions).find(
    it => it.organizationId === orgId,
  ),
);

export const selectByAccountId = (accountId: TestCloudSubscription['accountId']) => createSelector(
  selectAllTestCloudSubscriptions,
  testCloudSubscriptions => orderBy('createdAt', 'desc', testCloudSubscriptions).filter(
    it => it.accountId === accountId,
  ),
);

export const selectActiveByAccountId = (accountId: TestCloudSubscription['accountId']) => createSelector(
  selectAllTestCloudSubscriptions,
  testCloudSubscriptions => orderBy('createdAt', 'desc', testCloudSubscriptions).filter(
    it => it.accountId === accountId && it.status === TestOpsSubscriptionStatus.ACTIVE,
  ),
);

export const selectActiveByAccountIdAndFeature = (accountId: TestCloudSubscription['accountId'], feature: TestCloudSubscription['feature']) => createSelector(
  selectAllTestCloudSubscriptions,
  testCloudSubscriptions => orderBy('createdAt', 'desc', testCloudSubscriptions).filter(
    it => it.accountId === accountId
    && it.status === TestOpsSubscriptionStatus.ACTIVE
    && it.feature === feature
    && it.recurlySubscription !== null,
  ),
);

export const selectCount = createSelector(
  selectTestCloudSubscriptions,
  state => state.count,
);

export const selectTestCloudMinuteTiers = createSelector(
  selectTestCloudSubscriptions,
  it => it.testCloudMinuteTiers,
);

export const selectGrantedTrialTestCloudSubscription = (
  accountId: TestCloudSubscription['accountId'],
) => createSelector(
  selectAllTestCloudSubscriptions,
  testCloudSubscriptions => testCloudSubscriptions.filter(
    it => it.accountId === accountId
      && it.subscriptionType === SubscriptionType.TRIAL_GRANTED
      && it.status === TestOpsSubscriptionStatus.ACTIVE
      && it.expiredAt.valueOf() > new Date().valueOf(),
  ),
);

export const selectInitialTrialTestCloudSubscription = (
  accountId: TestCloudSubscription['accountId'],
) => createSelector(
  selectAllTestCloudSubscriptions,
  testCloudSubscriptions => testCloudSubscriptions.filter(
    it => it.accountId === accountId
      && it.subscriptionType === SubscriptionType.TRIAL_INITIAL
      && it.status === TestOpsSubscriptionStatus.ACTIVE
      && it.expiredAt.valueOf() > new Date().valueOf(),
  ),
);

export const selectHaveActivePaidTestCloudSubscription = (
  accountId: TestCloudSubscription['accountId'],
) => createSelector(
  selectAllTestCloudSubscriptions,
  testCloudSubscriptions => testCloudSubscriptions.some(
    it => it.accountId === accountId
      && it.subscriptionType === SubscriptionType.PAID
      && it.status === TestOpsSubscriptionStatus.ACTIVE
      && it.expiredAt.valueOf() > new Date().valueOf(),
  ),
);

export default testCloudSubscriptionsSlice.reducer;
