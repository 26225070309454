import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import capitalize from 'lodash/fp/capitalize';
import sumBy from 'lodash/fp/sumBy';
import { Invoice, InvoiceState } from '../../../models/invoice';
import {
  getFeatureFromPlan,
  getFeatureName, getUnitName,
  isStudioFeature,
  isTestCloudFeature, isTestExecutionProduct,
  isTestOpsPlatformFeature,
  isVisualTestingFeature,
  OrganizationFeature,
} from '../../../models/organizationFeature';
import { TestOpsEnterprisePlan } from '../../../models/testOpsEnterprisePlan';
import { TestOpsPlanInterval } from '../../../models/testOpsPlanInterval';
import { fromTestOpsPlatformConfiguration } from '../../../store/rootReducer';
import {
  convertToTestOpsPlatformTierV2,
  formatUSPrice,
} from '../utils';
import { RecurlySubscriptionDTO } from '../../../models/recurlySubscriptionDTO';

const useStyles = makeStyles(theme => ({
  name: {
    fontSize: 14,
    fontWeight: 500,
    color: '#233145',
    margin: theme.spacing(0),
  },
  tableCell: {
    padding: theme.spacing(1.75, 0),
    maxWidth: theme.spacing(33),
    '&.MuiTableCell-root': {
      paddingBottom: theme.spacing(1),
      borderBottom: '1px solid #DCDFE6',
    },
  },
  tableInnerCell: {
    padding: theme.spacing(2, 0),
    maxWidth: theme.spacing(33),
    color: '#172B4D',
  },
  cycleSubscription: {
    fontWeight: 500,
  },
  tableInnerCellPricing: {
    padding: theme.spacing(2, 0),
    maxWidth: theme.spacing(33),
    color: '#172B4D',
    fontWeight: 500,
  },
  statusSuccesful: {
    color: '#008060',
  },
  statusFailed: {
    color: '#e11900',
  },
  statusProcessing: {
    color: '#fa8c16',
  },
}));

interface InvoiceTableProps {
  singleProductInvoices: (Invoice)[];
  packageName?: string;
  packageInvoices: (Invoice)[];
}

const InvoiceTable = (props: InvoiceTableProps) => {
  const {
    singleProductInvoices,
    packageName,
    packageInvoices,
  } = props;
  const intl = useIntl();
  const classes = useStyles();
  const getPlanIntervalName = (plan: TestOpsEnterprisePlan) => {
    switch (plan?.interval) {
      case TestOpsPlanInterval.MONTH:
        return intl.formatMessage({ id: 'usage_plan_interval_monthly' });
      case TestOpsPlanInterval.YEAR:
        return intl.formatMessage({ id: 'usage_plan_interval_yearly' });
      default:
        return '';
    }
  };

  const testOpsPlatformInvoices = singleProductInvoices
    .find(it => it?.plan?.enterpriseProduct?.feature === OrganizationFeature.TESTOPS_PLATFORM);

  const tierType = useSelector(fromTestOpsPlatformConfiguration
    .selectTierByNumOfTestResults(testOpsPlatformInvoices?.quantity!));

  const convertPlanName = (feature: OrganizationFeature | undefined) => {
    if (feature === OrganizationFeature.TESTOPS_PLATFORM) {
      return `${OrganizationFeature.getFeatureFullName(
        feature,
      )} ${capitalize(convertToTestOpsPlatformTierV2(tierType))}`;
    }
    return OrganizationFeature.getFeatureFullName(
      feature,
    );
  };

  const getProductUnit = (feature: OrganizationFeature | undefined) => {
    if (feature && isStudioFeature(feature)) {
      return 'License(s)';
    }
    if (feature && isVisualTestingFeature(feature)) {
      return 'Checkpoint Images';
    }
    if (feature && isTestCloudFeature(feature)) {
      return 'Session(s)';
    }
    if (feature && isTestOpsPlatformFeature(feature)) {
      return 'Test Results';
    }

    return '';
  };

  const getInvoiceStatusFormatColor = (invoiceStatus: string | undefined) => {
    switch (invoiceStatus) {
      case InvoiceState.PAID:
        return (
          <span className={classes.statusSuccesful}>
            <b>
              {intl.formatMessage({ id: 'invoice_state.paid' })}
            </b>
          </span>
        );
      case InvoiceState.FAILED:
        return (
          <span className={classes.statusFailed}>
            <b>
              {intl.formatMessage({ id: 'invoice_state.failed' })}
            </b>
          </span>
        );
      case InvoiceState.PENDING:
      case InvoiceState.PAST_DUE:
      case InvoiceState.PROCESSING:
        return (
          <span className={classes.statusProcessing}>
            <b>
              {intl.formatMessage({ id: 'invoice_state.processing' })}
            </b>
          </span>
        );
      default:
        return (<b>Unknown</b>);
    }
  };

  const generateSingleProductRow = (invoice: Invoice, subscription: RecurlySubscriptionDTO) => (
    <>
      <TableRow>
        <TableCell className={classes.tableInnerCell}>
          {!subscription ? <Skeleton width="50%" /> : (
            <>
              <p className={classes.name}>
                {convertPlanName(subscription?.plan?.enterpriseProduct?.feature)}
              </p>
              {intl.formatMessage(
                { id: 'payment_success.order_summary.table.product_info' },
                {
                  quantity: formatUSPrice(subscription.quantity),
                  unit: getProductUnit(subscription?.plan?.enterpriseProduct?.feature),
                },
              )}
            </>
          )}
        </TableCell>
        <TableCell className={classes.tableInnerCell}>
          {!subscription ? <Skeleton width="50%" /> : intl.formatMessage(
            { id: 'payment_success.order_summary.table.plan_interval' },
            {
              // eslint-disable-next-line max-len
              planName: <span className={classes.cycleSubscription}>{getPlanIntervalName(subscription?.plan!!)}</span>,
              br: <br />,
              startDate: intl.formatDate(new Date(subscription?.startDate || ''), {
                month: 'short',
                day: '2-digit',
                year: 'numeric',
              }),
              endDate: intl.formatDate(new Date(subscription?.endDate || ''), {
                month: 'short',
                day: '2-digit',
                year: 'numeric',
              }),
            },
          )}
        </TableCell>
        <TableCell className={classes.tableInnerCellPricing}>
          {!invoice
            ? <Skeleton width="50%" />
            : intl.formatNumber(Math.min(Number(subscription?.subTotal), Number(invoice.total)))}
        </TableCell>
        <TableCell className={classes.tableInnerCell}>
          {!subscription ? <Skeleton width="50%" /> : getInvoiceStatusFormatColor(invoice?.state)}
        </TableCell>
      </TableRow>
    </>
  );

  const getPackageInvoiceState = () => {
    // If all invoices for starter package is paid success, return PAID status
    const paidInvoice = packageInvoices.every(invoice => invoice?.state === InvoiceState.PAID);
    if (paidInvoice) {
      return InvoiceState.PAID;
    }

    // If there are failed invoices, return FAILED status
    const failedInvoice = packageInvoices.some(invoice => invoice?.state === InvoiceState.FAILED);
    if (failedInvoice) {
      return InvoiceState.FAILED;
    }

    // otw, return PROCESSING status
    return InvoiceState.PROCESSING;
  };

  const renderPackageProduct = (
    subscription: RecurlySubscriptionDTO,
  ) => {
    const productName = getFeatureName(subscription?.plan?.enterpriseProduct?.feature);

    return (
      <>
        <FormattedMessage
          id="starter.package.item"
          values={{
            productName: <FormattedMessage id={`subscriptions.${productName}.title`} />,
            quantity: formatUSPrice(subscription.quantity),
            unit: <FormattedMessage id={getUnitName(productName, subscription.quantity)} />,
          }}
        />
        <br />
      </>
    );
  };

  const renderReplaceProducts = (invoices: (Invoice | undefined)[]) => {
    const fromProduct = getFeatureName(
      getFeatureFromPlan(invoices.find(invoice => invoice!!.total!! < 0)?.planId!!),
    );
    const toProduct = getFeatureName(
      getFeatureFromPlan(invoices.find(invoice => invoice!!.total!! > 0)?.planId!!),
    );

    return (
      <FormattedMessage
        id="starter.replace.test.execution"
        values={{
          from: <FormattedMessage id={`subscriptions.${fromProduct}.title`} />,
          to: <FormattedMessage id={`subscriptions.${toProduct}.title`} />,
        }}
      />
    );
  };

  const renderOtherProducts = () => packageInvoices
    ?.filter(invoice => invoice
      ?.subscriptions
      ?.every(subscription => !isTestExecutionProduct(
        subscription?.plan?.enterpriseProduct?.feature,
      )))
    .map(invoice => invoice?.subscriptions
      ?.map(subscription => renderPackageProduct(subscription)));

  const renderTestExecutionProducts = () => {
    const testExecutionInvoices = packageInvoices
      ?.filter(invoice => invoice
        ?.subscriptions
        ?.some(subscription => isTestExecutionProduct(
          subscription?.plan?.enterpriseProduct?.feature,
        )))
    ?? [];

    if (testExecutionInvoices.length > 1) {
      return renderReplaceProducts(testExecutionInvoices);
    }

    return testExecutionInvoices.length === 1
      && testExecutionInvoices
        .map(invoice => invoice?.subscriptions
          ?.map(subscription => renderPackageProduct(subscription)));
  };

  const generatePackageRow = () => {
    const startDate = packageInvoices[0]?.subscriptions!![0]?.startDate;
    const endDate = packageInvoices[0]?.subscriptions!![0]?.endDate;
    const total = sumBy(packageInvoice => packageInvoice?.total ?? 0, packageInvoices);
    return (
      <>
        <TableRow>
          <TableCell className={classes.tableInnerCell}>
            {!packageInvoices
              ? <Skeleton width="50%" />
              : (
                <>
                  <p className={classes.name}>
                    {packageName}
                  </p>
                  {renderOtherProducts()}
                  {renderTestExecutionProducts()}
                </>
              )}
          </TableCell>
          <TableCell className={classes.tableInnerCell}>
            {(!startDate || !endDate) ? <Skeleton width="50%" /> : intl.formatMessage(
              { id: 'payment_success.order_summary.table.plan_interval' },
              {
                // eslint-disable-next-line max-len
                planName: (
                  <span className={classes.cycleSubscription}>
                    <FormattedMessage id="usage_plan_interval_yearly" />
                  </span>
                ),
                br: <br />,
                startDate: intl.formatDate(new Date(startDate || ''), {
                  month: 'short',
                  day: '2-digit',
                  year: 'numeric',
                }),
                endDate: intl.formatDate(new Date(endDate || ''), {
                  month: 'short',
                  day: '2-digit',
                  year: 'numeric',
                }),
              },
            )}
          </TableCell>
          <TableCell className={classes.tableInnerCellPricing}>
            {!packageInvoices
              ? <Skeleton width="50%" />
              : intl.formatNumber(total)}
          </TableCell>
          <TableCell className={classes.tableInnerCell}>
            {!packageInvoices ? <Skeleton width="50%" /> : getInvoiceStatusFormatColor(getPackageInvoiceState())}
          </TableCell>
        </TableRow>
      </>
    );
  };

  const generateSingleProductStatusRows = () => singleProductInvoices
    .map(invoice => invoice?.subscriptions
      ?.map(subscription => generateSingleProductRow(invoice, subscription)));

  return (
    <TableContainer id="invoice-table">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell}>
              {intl.formatMessage({ id: 'payment_success.order_summary.table.product' })}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {intl.formatMessage({
                id: 'payment_success.order_summary.table.description',
              })}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {intl.formatMessage({ id: 'payment_success.order_summary.table.amount' })}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {intl.formatMessage({ id: 'payment_success.order_summary.table.status' })}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {packageInvoices.length > 0 && generatePackageRow()}
          {generateSingleProductStatusRows()}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvoiceTable;
