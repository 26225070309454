import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import DateRangeIcon from '@mui/icons-material/DateRange';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { styled } from '@mui/system';
import { differenceInCalendarDays, startOfDay, startOfToday, subDays, subMonths } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { FormattedMessage, useIntl } from 'react-intl';
import omit from 'lodash/omit';
import { useNavigate, useQuery } from '../../../../routes';
import DateInput from './DateInput';

const useStyles = makeStyles(theme => ({
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  warning: {
    margin: 0,
    marginBottom: theme.spacing(1),
    fontWeight: 'normal',
    color: '#B41400',
    textAlign: 'right',
  },
  buttons: {
    '& > *': {
      marginLeft: theme.spacing(2),
    },
  },
  staticRanges: {
    padding: theme.spacing(1, 3),
  },
  radioLabel: {
    '& > .MuiFormControlLabel-label': {
      fontSize: theme.spacing(2),
      color: '#7591AA',
      margin: theme.spacing(1, 0),
    },
    '& svg': {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
  },
}));
const StyledDateRangePicker = styled(DateRangePicker)(({ theme }) => `
  &.rdrDateRangePickerWrapper .rdrDefinedRangesWrapper {
    display: none;
  }
  & .rdrMonthAndYearPickers, & .rdrInputRanges, & .rdrDateDisplayWrapper {
    display: none;
  }
  & .rdrMonthAndYearWrapper {
    padding: 0;
    height: 40px;
  }
  & .rdrStaticRangeLabel {
    padding: 0;
    color: #1D3066;
  }
  & .rdrMonths.rdrMonthsHorizontal {
    margin-top: ${theme.spacing(-5.5)};
    .rdrMonth:first-child {
      border-right: 1px solid #EFF6FC;
    }
    .rdrMonth {
      flex: 1;
    }
  }
  & .rdrMonthName {
    text-align: center;
  }
  & .rdrDay:not(.rdrDayPassive, .rdrDayDisabled) .rdrDayNumber {
    font-weight: 400;
    span {
      color: #516393;
    }
  }
  & .rdrDayStartPreview {
    margin-left: 2px;
  }
  & .rdrDayEndPreview {
    margin-right: 2px;
  }
  & {
    .rdrDayStartPreview, .rdrDayEndPreview, .rdrDayInPreview {
      margin-top: 2px;
      margin-bottom: 2px;
    }
  }
  & .rdrDay:not(.rdrDayPassive) .rdrStartEdge, & .rdrDay:not(.rdrDayPassive) .rdrEndEdge {
    color: #EEF1FA !important;
    & ~ .rdrDayNumber {
      background-color: #276EF1;
      border-radius: ${theme.spacing(2)};
      margin: ${theme.spacing(0.25, 0.5)};
    }
  }
  & .rdrDay:not(.rdrDayPassive) .rdrInRange {
    color: #EEF1FA !important;
    & ~ .rdrDayNumber span {
      color: #516393 !important;
    }
  }
`);

interface K1DateRangePickerProps {
  defaultStartDate: Date;
  defaultEndDate: Date;
  dateRangeValidator: (startDate: Date, endDate: Date) => boolean;
  onChange: (startDate: Date, endDate: Date) => void;
}

interface DateRange {
  startDate: Date;
  endDate: Date;
  key: string;
}
interface RangeOption {
  label: string;
  range: DateRange;
}

/**
 * The date range shown to the user is an inclusive range
 * Thus, [defaultStartDate, defaultEndDate] is an inclusive range
 */
const K1DateRangePicker = (props: K1DateRangePickerProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { replaceQuery, navigate } = useNavigate();
  const { queryDictionary, get } = useQuery();
  const startTime = get('startTime');
  const endTime = get('endTime');
  const { onChange, defaultStartDate, defaultEndDate, dateRangeValidator } = props;
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const isSmallScreenSize = useMediaQuery(theme.breakpoints.down('md'));
  const now = new Date();
  const maxDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
  const [ranges, setRanges] = useState([
    {
      startDate: defaultStartDate,
      endDate: defaultEndDate,
      key: 'selection',
    },
  ]);
  const [lastAppliedRanges, setLastAppliedRanges] = useState([
    {
      startDate: defaultStartDate,
      endDate: defaultEndDate,
      key: 'selection',
    },
  ]);
  useEffect(() => {
    if (!startTime && !endTime) {
      // triggered when "Reset" or "Clear all filters" is pressed
      setRanges([
        {
          startDate: defaultStartDate,
          endDate: defaultEndDate,
          key: 'selection',
        },
      ]);
      setLastAppliedRanges([
        {
          startDate: defaultStartDate,
          endDate: defaultEndDate,
          key: 'selection',
        },
      ]);
      setOpen(false);
      setLabelRadio(intl.formatMessage({ id: 'license_utilization.date_range.label.custom' }));
      setLastAppliedLabelRadio(intl.formatMessage({ id: 'license_utilization.date_range.label.custom' }));
      setIsRangeValid(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, endTime, defaultStartDate, defaultEndDate]);
  const [isRangeValid, setIsRangeValid] = useState(true);
  const sevenDaysAgo = startOfDay(subDays(now, 6));
  const beginOfThisMonth = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0);
  const sixtyDaysAgo = startOfDay(subDays(now, 59));
  const ninetyDaysAgo = startOfDay(subDays(now, 89));
  const beginOfThisYear = new Date(now.getFullYear(), 0, 1, 0, 0, 0);
  const rangeOptions: RangeOption[] = [
    {
      label: intl.formatMessage({ id: 'license_utilization.date_range.label.today' }),
      range: { startDate: startOfToday(), endDate: now, key: 'selection' },
    },
    {
      label: intl.formatMessage({ id: 'license_utilization.date_range.label.last_7_days' }),
      range: { startDate: sevenDaysAgo, endDate: now, key: 'selection' },
    },
    {
      label: intl.formatMessage({ id: 'license_utilization.date_range.label.this_month' }),
      range: { startDate: beginOfThisMonth, endDate: now, key: 'selection' },
    },
    {
      label: intl.formatMessage({ id: 'license_utilization.date_range.label.last_60_days' }),
      range: { startDate: sixtyDaysAgo, endDate: now, key: 'selection' },
    },
    {
      label: intl.formatMessage({ id: 'license_utilization.date_range.label.last_90_days' }),
      range: { startDate: ninetyDaysAgo, endDate: now, key: 'selection' },
    },
    {
      label: intl.formatMessage({ id: 'license_utilization.date_range.label.this_year' }),
      range: { startDate: beginOfThisYear, endDate: now, key: 'selection' },
    },
    {
      label: intl.formatMessage({ id: 'license_utilization.date_range.label.custom' }),
      range: { startDate: ranges[0].startDate, endDate: ranges[0].endDate, key: 'selection' },
    },
  ];

  const [labelRadio, setLabelRadio] = useState(intl.formatMessage({ id: 'license_utilization.date_range.label.custom' }));
  const [lastAppliedLabelRadio, setLastAppliedLabelRadio] = useState(intl.formatMessage({ id: 'license_utilization.date_range.label.custom' }));

  const handleApply = () => {
    if (dateRangeValidator(ranges[0].startDate, ranges[0].endDate)) {
      if (onChange) {
        const exclusiveEndDate = new Date(
          ranges[0].endDate.getFullYear(),
          ranges[0].endDate.getMonth(),
          ranges[0].endDate.getDate() + 1,
          0,
          0,
          0,
        );
        if (differenceInCalendarDays(now, ranges[0].startDate) === 0) {
          // Today is selected
          onChange(ranges[0].startDate, now);
        } else {
          onChange(ranges[0].startDate, exclusiveEndDate);
        }
      }
      setLastAppliedRanges(ranges);
      setLastAppliedLabelRadio(labelRadio);
      setOpen(false);
    }
  };

  const hidePopper = () => {
    setOpen(false);
    setRanges(lastAppliedRanges);
    setLabelRadio(lastAppliedLabelRadio);
    setIsRangeValid(true); // the last applied range must have been a valid range
  };

  const handleReset = () => {
    navigate(undefined, replaceQuery(omit(queryDictionary(), ['startTime', 'endTime'])));
  };

  const handleToggle = () => {
    if (open) hidePopper();
    else setOpen(true);
  };

  const handleChange = (range: any) => {
    setRanges([range.selection]);
    setIsRangeValid(dateRangeValidator(range.selection.startDate, range.selection.endDate));
    setLabelRadio(intl.formatMessage({ id: 'license_utilization.date_range.label.custom' }));
  };

  const handleClickAway = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    hidePopper();
  };
  const selectRangeRadio = (label: string, range: DateRange) => {
    setLabelRadio(label);
    setRanges([range]);
  };
  const renderRadio = rangeOptions.map(it => (
    <div key={it.label} onClick={() => selectRangeRadio(it.label, it.range)}>
      <FormControlLabel
        className={classes.radioLabel}
        value={it.label}
        control={<Radio />}
        label={it.label}
      />
    </div>
  ));
  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <Button
            variant="text"
            sx={open ? { backgroundColor: '#D5D8DD' } : undefined}
            onClick={handleToggle}
            ref={anchorRef}
            startIcon={<DateRangeIcon style={{ marginRight: '4px' }} />}
          >
            <span>
              {`${intl.formatDate(lastAppliedRanges[0].startDate, { year: 'numeric', month: 'short', day: 'numeric' })} - ${intl.formatDate(lastAppliedRanges[0].endDate, { year: 'numeric', month: 'short', day: 'numeric' })}`}
            </span>
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            transition
            placement="bottom-end"
            disablePortal
            style={{ zIndex: 9999 }}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: 'right top',
                }}
              >
                <Paper elevation={2}>
                  <Box display="flex">
                    <Box className={classes.staticRanges}>
                      <RadioGroup
                        value={labelRadio}
                      >
                        { renderRadio }
                      </RadioGroup>
                    </Box>
                    <Box display="flex" flexDirection="column" mt={3}>
                      <Box display="flex" height={theme.spacing(5)}>
                        <Box flex={1} borderRight="1px solid #EFF6FC">
                          <DateInput
                            label={intl.formatMessage({ id: 'license_utilization.date_range_picker.start_date' })}
                            shownDate={ranges[0].startDate}
                          />
                        </Box>
                        <Box flex={1}>
                          <DateInput
                            label={intl.formatMessage({ id: 'license_utilization.date_range_picker.end_date' })}
                            shownDate={ranges[0].endDate}
                          />
                        </Box>
                      </Box>
                      <StyledDateRangePicker
                        ranges={ranges}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        onChange={handleChange}
                        direction={isSmallScreenSize ? 'vertical' : 'horizontal'}
                        dateDisplayFormat="dd/MM/yyyy"
                        maxDate={maxDate}
                        startDatePlaceholder={intl.formatMessage({ id: 'license_utilization.date_range_picker.start_date' })}
                        endDatePlaceholder={intl.formatMessage({ id: 'license_utilization.date_range_picker.end_date' })}
                        shownDate={subMonths(ranges[0].endDate, 1)}
                        renderStaticRangeLabel={() => null}
                        preventSnapRefocus
                      />
                    </Box>
                  </Box>
                  <div className={classes.footer}>
                    <div>
                      {!isRangeValid && (
                        <p className={classes.warning}>
                          <FormattedMessage id="license_utilization.date_range_picker.error" />
                        </p>
                      )}
                      {/* button section  */}
                      <div className={classes.buttons}>
                        <Button
                          onClick={handleReset}
                        >
                          <FormattedMessage id="license_utilization.date_range_picker.reset" />
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={!isRangeValid}
                          onClick={handleApply}
                        >
                          <FormattedMessage id="license_utilization.date_range_picker.apply" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </ClickAwayListener>
    </>
  );
};

export default K1DateRangePicker;
