import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { AppState } from '.';
import { Organization, TestResultCount } from '../models';
import { Operator, Query } from '../models/query';
import { TestResultService } from '../services';

export const TEST_RESULT_COUNT_FEATURE_KEY = 'testResultCount';

interface TestResultCountState {
  latestTestResultCount: TestResultCount | null;
  allTestResultCounts: TestResultCount[] | null;
  loading: boolean;
  error?: string;
}

export const createInitialState = (): TestResultCountState => ({
  latestTestResultCount: null,
  allTestResultCounts: null,
  loading: false,
});

const MAX_NUMBER_OF_RECORDS = 50;
export const doGetLatestTestResultCount = createAsyncThunk(
  'testResultCount/getLatest',
  async (organizationId: Organization['id']) => {
    const testResultCounts = (await TestResultService.getTestResultCounts(
      { field: 'organizationId', operator: Operator.EQ, value: organizationId },
      { limit: 1 },
      { field: 'createdAt', desc: true },
    )).data;
    return testResultCounts.length > 0 ? testResultCounts[0] : null;
  },
);

export const doGetAllTestResultCounts = createAsyncThunk(
  'dashboard/getTestResultCount',
  async (input: {
    organizationId: TestResultCount['organizationId'],
    startDate: Date,
    endDate: Date
  }) => {
    // There might be more data added during fetching time, though rare, it can mess up with
    // our offset, thus keep createdAt sorted descendingly (1) and reverse it later (2)
    const criteria: Array<Query<TestResultCount>> = [
      { field: 'organizationId', operator: Operator.EQ, value: input.organizationId },
      { field: 'createdAt', operator: Operator.GE, value: input.startDate.getTime() },
      { field: 'createdAt', operator: Operator.LT, value: input.endDate.getTime() },
      { field: 'usagePeriodStartAt', desc: false },
      { field: 'createdAt', desc: false }, // (1)
      { limit: MAX_NUMBER_OF_RECORDS },
    ];
    const firstResponse = await TestResultService.getTestResultCounts(...criteria);
    const numberOfRequests = Math.ceil(firstResponse.total / MAX_NUMBER_OF_RECORDS);
    const remainingRequests = [];
    for (let i = 1; i < numberOfRequests; i += 1) {
      const req = TestResultService.getTestResultCounts(
        ...criteria,
        { offset: MAX_NUMBER_OF_RECORDS * i },
      );
      remainingRequests.push(req);
    }
    const remainingResponses = await Promise.all(remainingRequests);
    const testResult = [...firstResponse.data];
    remainingResponses.forEach(r => {
      testResult.push(...r.data);
    });
    return testResult.reverse(); // (2)
  },
);

const testResultCountSlice = createSlice({
  name: TEST_RESULT_COUNT_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doGetLatestTestResultCount.pending, state => {
      state.loading = true;
      state.error = '';
      state.latestTestResultCount = null;
    });
    builder.addCase(doGetLatestTestResultCount.fulfilled, (state, action) => {
      state.loading = false;
      state.latestTestResultCount = action.payload;
    });
    builder.addCase(doGetLatestTestResultCount.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.latestTestResultCount = null;
    });
    builder.addCase(doGetAllTestResultCounts.pending, state => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(doGetAllTestResultCounts.fulfilled, (state, action) => {
      state.loading = false;
      state.allTestResultCounts = action.payload;
    });
    builder.addCase(doGetAllTestResultCounts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.allTestResultCounts = null;
    });
  },
});

const selectTestResultCountFeature = (state: AppState) => state[TEST_RESULT_COUNT_FEATURE_KEY];

export const selectLatestTestResultCount = createSelector(
  selectTestResultCountFeature,
  testResultCountState => testResultCountState.latestTestResultCount,
);
export const selectAllTestResultCounts = createSelector(
  selectTestResultCountFeature,
  testResultCountState => testResultCountState.allTestResultCounts,
);
export const selectLoading = createSelector(
  selectTestResultCountFeature,
  testResultCountState => testResultCountState.loading,
);
export const selectError = createSelector(
  selectTestResultCountFeature,
  testResultCountState => testResultCountState.error,
);

export default testResultCountSlice.reducer;
