import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import {
  fromAccounts,
  fromTestOpsPlatformConfiguration,
  fromTestOpsPlatformSubscriptions,
  fromTestOpsSubscriptions,
  fromTestResultCount,
} from '../../../../store/rootReducer';
import AdditionalSubscriptionSection from './AdditionalSubscriptionSection';
import { resolvePath, useQuery } from '../../../../routes';
import {
  UsagePlan,
  User,
} from '../../../../models';
import { accountSubscriptionManagement } from '../../../../layout/routes';
import PlatformWarningBanner from '../../../dashboard/components/PlatformWarningBanner';
import { TestOpsPlatformTierType } from '../../../../models/testOpsPlatformTierType';
import PlatformSubscriptionInfo from './PlatformSubscriptionInfo';
import { sendTrackingData } from '../../utils';

const useStyles = makeStyles((theme => ({
  rootContainer: {
    backgroundColor: '#ffffff',
    margin: theme.spacing(0, 2, 2),
    borderRadius: theme.spacing(0.5),
  },
  defaultContainer: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0, 2, 3, 3),
  },
  sectionTitle: {
    padding: theme.spacing(0, 2),
    fontSize: 20,
    fontWeight: 600,
    color: '#233145',
    alignItems: 'center',
    display: 'flex',
  },
  warningBanner: {
    marginTop: theme.spacing(2),
  },
  expandBtn: {
  },
})));

export interface YourSubscriptionSectionProps {
  accountId: number | null,
  orgId: number | null;
  user: User | null;
  isLoading: boolean;
}

const YourSubscriptionSection = (props: YourSubscriptionSectionProps) => {
  const classes = useStyles();
  const {
    accountId,
    orgId,
    user,
    isLoading,
  } = props;
  const { queryDictionary } = useQuery();
  const [showPlatformBanner, setShowPlatformBanner] = useState(false);
  const [testResultCount, setTestResultCount] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [quota, setQuota] = useState(0);
  const latestTestResultCount = useSelector(fromTestResultCount.selectLatestTestResultCount);
  const account = useSelector(fromAccounts.selectAccountById(Number(accountId)))!!;
  const freeTierConfiguration = useSelector(fromTestOpsPlatformConfiguration
    .selectConfigurationAnnualyByFeature(TestOpsPlatformTierType.FREE))!;
  const freeTestResultQuota = useSelector(fromAccounts.selectFreeTestResultQuota);

  const hasTrialTOSubscription = useSelector(
    fromTestOpsPlatformSubscriptions.selectTrialTestOpsPlatformSubscriptionByAccountId(
      Number(accountId),
    ),
  ).length !== 0;

  const currentTestOpsSubscription = useSelector(
    fromTestOpsSubscriptions.selectByAccountId(
      Number(accountId),
    ),
  )?.[0];

  const paidTestopsPlatformSubscription = useSelector(
    fromTestOpsPlatformSubscriptions.selectPaidTestOpsPlatformSubscriptionByAccountId(
      Number(accountId),
    ),
  )?.[0];

  // this constant cover for old model paid, trial subscription and new paid model subscription
  const prioritySubscription = currentTestOpsSubscription || paidTestopsPlatformSubscription;

  const showTOSubscriptionSection = (prioritySubscription !== undefined
    || hasTrialTOSubscription || freeTestResultQuota !== 0) && freeTierConfiguration;

  useEffect(() => {
    if (latestTestResultCount) {
      const { quota, count, usagePlan } = latestTestResultCount;
      const calculatedUsagePercentage = count / quota;
      setQuota(quota);
      setShowPlatformBanner(calculatedUsagePercentage > 1 && usagePlan === UsagePlan.PLATFORM);
      setTestResultCount(count);
    }
  }, [latestTestResultCount]);

  return (
    <div id="your-subscription-container" className={classes.rootContainer}>
      <Button
        id="your-subscription-collapse-btn"
        variant="text"
        style={{ backgroundColor: 'transparent' }}
        onClick={() => {
          setExpanded(!expanded);
          if (!expanded) {
            sendTrackingData(
              'your_subs_opened',
              account?.id!!,
              orgId!!,
            );
          }
        }}
      >
        <Typography variant="h3" className={classes.sectionTitle}>
          <FormattedMessage id="subscriptions.testops_platform.plan_details" />
          {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </Typography>
      </Button>
      <Collapse in={expanded} id="your-subscription-section">
        <Paper
          elevation={0}
          id="defaultContainer"
          className={classes.defaultContainer}
        >
          {showTOSubscriptionSection
            && (
              <PlatformSubscriptionInfo
                accountId={account?.id!!}
                organizationId={Number(orgId)}
                expiryDate={prioritySubscription?.expiryDate}
                quota={quota || freeTierConfiguration[0]?.executionTestResults}
                billingCycle={prioritySubscription?.billingCycle}
                currentTestOpsSubscription={prioritySubscription}
                hasOldModelSubscription={!!currentTestOpsSubscription}
                isLoadingCurrentSubscription={isLoading}
                testResultCount={testResultCount}
                user={user}
              />
            )}
          {showPlatformBanner && (
            <div id="platform-warning-banner" className={classes.warningBanner}>
              <PlatformWarningBanner
                subscriptionLink={resolvePath(
                  accountSubscriptionManagement,
                  undefined,
                  queryDictionary(),
                )}
              />
            </div>
          )}
          <AdditionalSubscriptionSection
            account={account}
            email={user?.email || ''}
            organizationId={Number(orgId)}
          />
        </Paper>
      </Collapse>
    </div>
  );
};
export default YourSubscriptionSection;
