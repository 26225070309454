import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { OrganizationUser, User as UserModel } from '../../models';
import { OrganizationRole } from '../../models/organizationRole';
import { useQuery } from '../../routes';
import { fromAuth, fromCurrentOrgUser, fromOrganizations, fromOrganizationUsers, useAppDispatch } from '../../store';
import LoadingProgress from '../../layout/LoadingProgress';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    borderRadius: '.5rem',
    padding: '1.5rem',
    overflow: 'auto',
  },
  title: {
    width: '100%',
    marginTop: theme.spacing(2.5),
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  sectionTitle: {
    fontWeight: 'normal',
  },
  horizontalBox: {
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(1.5),
    },
  },
  actionButton: {
    backgroundColor: '#EFF6FC',
    '&:hover': {
      backgroundColor: '#EFF6FC',
    },
  },
  textButton: {
    color: '#284169',
  },
  content: {
    paddingLeft: theme.spacing(3),
    margin: theme.spacing(2),
  },
  subTitle: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
    alignItems: 'center',
    alignContent: 'center',
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        marginTop: theme.spacing(1),
      },
    },
  },
  search: {
    borderRadius: theme.shape.borderRadius,
    border: `solid 1px ${theme.palette.action.disabled}`,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: theme.spacing(45),
  },
  accordionDetails: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  accordionDetailsTable: {
    paddingTop: 0,
    paddingRight: theme.spacing(4),
  },
  table: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  pointer: {
    cursor: 'pointer',
  },
  tableCell: {
    borderBottom: 'none',
  },
  iconButtom: {
    padding: theme.spacing(1),
  },
  closeIcon: {
    fill: theme.palette.text.primary,
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'row',
    '& > *:last-child': {
      marginLeft: theme.spacing(1.5),
    },
    alignItems: 'center',
  },
}));

const User = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { get } = useQuery();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const orgId = get('orgId');
  const organization = useSelector(fromOrganizations.selectSelectedOrganization);
  const orgTotalMember = useSelector(fromOrganizations.selectCount);
  const user = useSelector(fromAuth.selectUser);
  const currentOrgUser = useSelector(fromCurrentOrgUser.selectByOrganizationIdAndEmail(
    user?.email || '',
    Number(orgId),
  ));
  const isAdminOrOwner = useMemo(() => currentOrgUser && [
    OrganizationRole.ADMIN, OrganizationRole.OWNER,
  ].includes(currentOrgUser.role), [currentOrgUser]);
  const [loading, setLoading] = useState(false);
  const [hoveringOver, setHoveringOver] = useState(0);
  const [selectedReistered, setSelectedReistered] = useState<number[]>([]);

  const searchRegisteredUsers = fromOrganizationUsers.doGetAcceptedOrgUsersByOrgIdWithSearch;
  const searchPendingUsers = fromOrganizationUsers.doGetPendingOrgUsersByOrgIdWithSearch;
  const registeredUsers = useSelector(fromOrganizationUsers
    .selectAcceptedOrgUsersByOrgIdWithSearch);
  const pendingUsers = useSelector(fromOrganizationUsers.selectPendingOrgUsersByOrgIdWithSearch);
  const [currQueryRegistered, setCurrQueryRegistered] = useState('');
  const [submitQueryRegistered, setSubmitQueryRegistered] = useState('');
  const [currQueryPending, setCurrQueryPending] = useState('');
  const [submitQueryPending, setSubmitQueryPending] = useState('');

  const registeredHeader = useRef([
    'user.registered.fullname',
    'user.registered.email',
    'user.registered.role',
  ]);
  const pendingHeader = useRef([
    'user.peinding.email',
    'user.peinding.role',
  ]);

  /// Handle search box of registered section
  const handleOnKeyDownRegistered = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') setSubmitQueryRegistered(currQueryRegistered);
  };

  const handleOnChangeRegistered = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrQueryRegistered(event.target.value);
  };

  useEffect(() => {
    if (!orgId) return;
    setLoading(true);
    (async () => {
      await dispatch(searchRegisteredUsers({
        id: +orgId,
        textSearch: submitQueryRegistered,
      }));
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitQueryRegistered]);

  /// Handle search box of pending section
  const handleOnKeyDownPending = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') setSubmitQueryPending(currQueryPending);
  };

  const handleOnChangePending = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrQueryPending(event.target.value);
  };

  useEffect(() => {
    if (!orgId) return;
    setLoading(true);
    (async () => {
      await dispatch(searchPendingUsers({
        id: +orgId,
        textSearch: submitQueryPending,
      }));
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitQueryPending]);

  useEffect(() => {
    analytics.page();
  }, []);

  const handleResendInvitation = async (arg: Required<Pick<OrganizationUser, 'organizationId' | 'role'>> & {
    user: Pick<UserModel, 'email'>;
  }) => {
    try {
      setLoading(true);
      await dispatch(fromOrganizationUsers.doCreateOrganizationUser({
        organizationId: arg.organizationId,
        user: { email: arg.user.email },
        role: arg.role,
      })).then(unwrapResult);
      enqueueSnackbar(<FormattedMessage id="user.resend.success" values={{ email: arg.user.email }} />, { variant: 'success' });
      analytics.track(
        'Invitation Resent',
        {
          user_id: user?.id,
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
          system_role: user?.roles,
          reinvited_user: arg.user.email,
          org_id: organization?.id,
          org_name: organization?.name,
          org_role: currentOrgUser?.role,
          total_members: orgTotalMember,
        },
      );
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        user_id: user?.id,
        org_role: currentOrgUser?.role,
        system_role: user?.roles,
        org_id: organization?.id,
        org_name: organization?.name,
        total_members: orgTotalMember,
        event: 'gtm_invitation_resent',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number, userId: number) => {
    event.stopPropagation();
    event.preventDefault();
    if (userId === user?.id) {
      return;
    }
    const selectedIndex = selectedReistered.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedReistered, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedReistered.slice(1));
    } else if (selectedIndex === selectedReistered.length - 1) {
      newSelected = newSelected.concat(selectedReistered.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedReistered.slice(0, selectedIndex),
        selectedReistered.slice(selectedIndex + 1),
      );
    }

    setSelectedReistered(newSelected);
  };

  const handleRemoveUser = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
    email: string,
    registered: boolean,
  ) => {
    event.stopPropagation();
    try {
      setLoading(true);
      await dispatch(fromOrganizationUsers.doRemoveOrganizationUser(id)).then(unwrapResult);
      enqueueSnackbar(<FormattedMessage id="user.removeuser.success" values={{ email }} />, { variant: 'success' });
      if (registered) {
        analytics.track(
          'Registered User Removed',
          {
            user_id: user?.id,
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
            system_role: user?.roles,
            removed_user: email,
            org_id: organization?.id,
            org_name: organization?.name,
            org_role: currentOrgUser?.role,
            total_members: orgTotalMember,
          },
        );
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          user_id: user?.id,
          org_role: currentOrgUser?.role,
          system_role: user?.roles,
          org_id: organization?.id,
          org_name: organization?.name,
          total_members: orgTotalMember,
          removed_user_id: id,
          event: 'gtm_registered_user_removed',
        });
      } else {
        analytics.track(
          'Pending Invitation User Removed',
          {
            user_id: user?.id,
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
            system_role: user?.roles,
            removed_user: email,
            org_id: organization?.id,
            org_name: organization?.name,
            org_role: currentOrgUser?.role,
            total_members: orgTotalMember,
          },
        );
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          user_id: user?.id,
          org_role: currentOrgUser?.role,
          system_role: user?.roles,
          org_id: organization?.id,
          org_name: organization?.name,
          total_members: orgTotalMember,
          event: 'gtm_pending_invitation_user_removed',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = registeredUsers.filter(orgUser => orgUser.userId !== user?.id)
        .map(orgUser => orgUser.id);
      setSelectedReistered(newSelecteds);
      return;
    }
    setSelectedReistered([]);
  };

  const handleDeleteSelected = async () => {
    try {
      setLoading(true);
      const tasks = selectedReistered.map(
        input => dispatch(fromOrganizationUsers.doRemoveOrganizationUser(input)),
      );
      await Promise.all(tasks);
      enqueueSnackbar(<FormattedMessage id="user.remove.success" />, { variant: 'success' });
    } finally {
      setLoading(false);
    }
  };

  const handleRowHover = (id: number) => setHoveringOver(id);
  const handleRowHoverLeave = () => setHoveringOver(0);

  return (
    <div className={classes.root}>
      <Box className={classes.title}>
        <Box className={classes.horizontalBox}>
          <Typography variant="h3">
            <FormattedMessage id="user.usermanagement" />
          </Typography>
        </Box>
        {isAdminOrOwner && (
          <Box className={classes.horizontalBox}>
            <Button className={classes.actionButton}>
              <Typography variant="h5" className={classes.textButton}>
                <FormattedMessage id="user.exportusers" />
              </Typography>
            </Button>
            <Button className={classes.actionButton}>
              <Typography variant="h5" className={classes.textButton}>
                <FormattedMessage id="user.inviteusers" />
              </Typography>
            </Button>
          </Box>
        )}
      </Box>
      <Paper className={classes.content} square={false} elevation={0}>
        <Accordion elevation={0} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="h4" className={classes.sectionTitle}>
              <FormattedMessage id="user.body.registeredusers" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordionDetails }}>
            <Input
              classes={{ root: classes.search }}
              disableUnderline
              placeholder={intl.formatMessage({ id: 'user.body.search' })}
              startAdornment={<InputAdornment position="start"><SearchIcon color="disabled" /></InputAdornment>}
              onChange={handleOnChangeRegistered}
              onKeyDown={handleOnKeyDownRegistered}
            />
            {(selectedReistered.length > 0 && isAdminOrOwner) && (
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={handleDeleteSelected}
              >
                <Typography>
                  <FormattedMessage id="user.body.delete" />
                </Typography>
              </Button>
            )}
          </AccordionDetails>
          <AccordionDetails className={classes.accordionDetailsTable}>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow onClick={event => event.stopPropagation()}>
                    {isAdminOrOwner && (
                      <TableCell padding="checkbox" align="center">
                        <Checkbox
                          color="primary"
                          indeterminate={selectedReistered.length > 0
                            && selectedReistered.length < registeredUsers.length}
                          checked={registeredUsers.length > 1
                            && selectedReistered.length === registeredUsers.length - 1}
                          onChange={handleSelectAllClick}
                        />
                      </TableCell>
                    )}
                    {registeredHeader.current.map(id => (
                      <TableCell align="left" key={id}>
                        <FormattedMessage id={id} />
                      </TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {registeredUsers.map(orgUser => {
                    const isItemSelected = selectedReistered.includes(orgUser.id);
                    const isCurrentUser = orgUser.userId === user?.id;
                    return (
                      <TableRow
                        className={isAdminOrOwner ? classes.pointer : ''}
                        onClick={event => handleClick(event, orgUser.id, orgUser.userId)}
                        onMouseEnter={() => handleRowHover(orgUser.id)}
                        onMouseLeave={() => handleRowHoverLeave()}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={orgUser.id}
                        selected={isItemSelected}
                      >
                        {isAdminOrOwner && (
                          <TableCell classes={{ root: classes.tableCell }} padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              disabled={isCurrentUser}
                            />
                          </TableCell>
                        )}
                        <TableCell classes={{ root: classes.tableCell }} component="th" scope="row">
                          <Box className={classes.userInfo}>
                            <Avatar src={orgUser.user.avatar}>
                              {orgUser.user.firstName
                                ? orgUser.user.firstName[0]
                                : orgUser.user.email[0]}
                            </Avatar>
                            <Typography color="primary">
                              {`${orgUser.user.firstName ? (`${orgUser.user.firstName} ${orgUser.user.lastName ? orgUser.user.lastName : ''}`).trim() : orgUser.user.email}`}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCell }} align="left">{orgUser.user.email}</TableCell>
                        <TableCell classes={{ root: classes.tableCell }} align="left">
                          <FormattedMessage id={`organizationrole.${orgUser.role.toLowerCase()}`} />
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCell }} align="right" padding="checkbox">
                          {isAdminOrOwner && (hoveringOver === orgUser.id) && !isCurrentUser && (
                            <IconButton
                              classes={{ root: classes.iconButtom }}
                              onClick={
                                event => (
                                  handleRemoveUser(event, orgUser.id, orgUser.user.email, true)
                                )
                              }
                              size="large"
                            >
                              <CloseIcon classes={{ root: classes.closeIcon }} fontSize="small" color="inherit" />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </Paper>

      <Paper className={classes.content} square={false} elevation={0}>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="h4" className={classes.sectionTitle}>
              <FormattedMessage id="user.body.pending" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordionDetails }}>
            <Box className={classes.subTitle}>
              <Input
                classes={{ root: classes.search }}
                disableUnderline
                placeholder={intl.formatMessage({ id: 'user.body.search' })}
                startAdornment={<InputAdornment position="start"><SearchIcon color="disabled" /></InputAdornment>}
                onChange={handleOnChangePending}
                onKeyDown={handleOnKeyDownPending}
              />
            </Box>
          </AccordionDetails>
          <AccordionDetails className={classes.accordionDetailsTable}>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow onClick={event => event.stopPropagation()}>
                    {pendingHeader.current.map(id => (
                      <TableCell align="left" key={id}>
                        <FormattedMessage id={id} />
                      </TableCell>
                    ))}
                    <TableCell />
                    {isAdminOrOwner && (
                      <TableCell />
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pendingUsers.length ? pendingUsers.map(orgUser => {
                    const isCurrentUser = orgUser.userId === user?.id;
                    return (
                      <TableRow
                        role="checkbox"
                        tabIndex={-1}
                        key={orgUser.id}
                        onMouseEnter={() => handleRowHover(orgUser.id)}
                        onMouseLeave={() => handleRowHoverLeave()}
                      >
                        <TableCell classes={{ root: classes.tableCell }} align="left">
                          {orgUser.user.email}
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCell }} align="left">
                          <FormattedMessage id={`organizationrole.${orgUser.role.toLowerCase()}`} />
                        </TableCell>
                        {isAdminOrOwner && (
                          <TableCell classes={{ root: classes.tableCell }} align="left">
                            <Button color="primary" onClick={() => handleResendInvitation(orgUser)}>
                              <Typography variant="body1">
                                <FormattedMessage id="user.pending.resend" />
                              </Typography>
                            </Button>
                          </TableCell>
                        )}
                        <TableCell classes={{ root: classes.tableCell }} align="right" padding="checkbox">
                          {isAdminOrOwner && (hoveringOver === orgUser.id) && !isCurrentUser && (
                            <IconButton
                              classes={{ root: classes.iconButtom }}
                              onClick={
                                event => (
                                  handleRemoveUser(event, orgUser.id, orgUser.user.email, false)
                                )
                              }
                              size="large"
                            >
                              <CloseIcon classes={{ root: classes.closeIcon }} fontSize="small" color="inherit" />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  }) : (
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={pendingHeader.current.length + 2}
                      >
                        <Typography>
                          <FormattedMessage id="user.table.nodata" />
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </Paper>
      {loading && <LoadingProgress />}
    </div>
  );
};

export default User;
