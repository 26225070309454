import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useIntl } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { parse } from 'querystring';
import Button from '@katalon-studio/katalon-ui/Button';
import { QueryDictionary, useNavigate, useQuery } from '../../../routes';
import { accountSubscriptionManagement } from '../../../layout/routes';
import PaymentInformationSection from './payment-information-section/PaymentInformationSection';
import {
  fromAccounts,
  fromOrder,
  fromStarterPackage,
  fromStudioSubscriptions,
  fromSubscriptions,
  fromTestCloudSubscriptions,
  fromVisualTestingSubscriptions,
  useAppDispatch,
} from '../../../store';
import {
  fromInvoices,
  fromTestOpsPlatformSubscriptions,
  fromTestOpsSubscriptions,
  fromExistingAccounts,
  fromPaymentMethod,
} from '../../../store/rootReducer';
import OrderConfirmationSection from './order-confirmation-section/OrderConfirmantionSection';
import LoadingProgress from '../../../layout/LoadingProgress';

const useStyles = makeStyles((theme => ({
  root: {
    height: '100%',
    width: '100%',
    borderRadius: '.5rem',
    padding: '1.5rem',
    overflowY: 'auto',
    backgroundColor: '#F9FBFF',
  },
  page: {
    paddingLeft: theme.spacing(2),
  },
  cardTitle: {
    fontSize: theme.spacing(2.5),
  },
  planContainer: {
    margin: theme.spacing(3, 0, 2),
  },
  testOpsPlatformContainer: {
    fontWeight: theme.typography.fontWeightBold,
    color: '#313B58',
    fontSize: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cardPaymentAndBilling: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(2.5),
    fontWeight: theme.typography.fontWeightBold,
    color: '#313B58',
    fontSize: theme.spacing(2),
  },
  planPurchaseDetails: {
    // padding: theme.spacing(4),
    borderRadius: theme.spacing(1),
  },
  badgeSubscription: {
    marginLeft: theme.spacing(1),
  },
  icon: {
    marginRight: 8,
    height: theme.spacing(2.125),
    width: theme.spacing(2.125),
  },
  titleComeBack: {
    color: '#727993',
    fontFamily: 'Inter',
    fontSize: theme.spacing(2),
    marginBottom: theme.spacing(0.75),
    fontWeight: 500,
  },
  iconBack: {
    marginRight: theme.spacing(2),
    color: '#727993',
  },
  backBtn: {
    paddingBottom: theme.spacing(3),
  },
})));

const TestOpsPlatformCheckout = () => {
  const classes = useStyles();
  const intl = useIntl();
  const { get, queryString } = useQuery();

  const [isLoading, setLoading] = useState(false);
  const { replace, replaceQuery } = useNavigate();
  const accountId = Number(get('accountId'));

  const pendingOrder = useSelector(fromOrder.selectPendingOrder);
  const dispatch = useAppDispatch();

  const triggerUpdateCurrentSubscription = async () => {
    setLoading(true);
    await Promise.all([
      dispatch(fromTestOpsPlatformSubscriptions
        .doGetAllTestOpsPlatformSubscriptionsByAccountId({
          accountId: Number(accountId),
        })),
      dispatch(fromStudioSubscriptions.doGetActiveByAccountId({
        accountId: Number(accountId),
      })),
      // get all TC subscriptions
      dispatch(fromTestCloudSubscriptions.doGetByAccountId({
        accountId: Number(accountId),
      })),
      dispatch(fromVisualTestingSubscriptions.doGetActiveByAccountId({
        accountId: Number(accountId),
      })),
      dispatch(fromExistingAccounts.doGetAccountByAccountId({
        accountId: Number(accountId),
      })),
      dispatch(fromTestOpsSubscriptions.doGetActiveByAccountId({
        accountId: Number(accountId),
      })),
      dispatch(fromSubscriptions.doIsAccessStartPackage(Number(accountId))),
      dispatch(fromAccounts.doCheckPublicDomain(Number(accountId))),
    ]).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    dispatch(fromInvoices.doResetPreview());
    triggerUpdateCurrentSubscription().catch(() => {});
  }, []);

  useEffect(() => {
    if (pendingOrder) {
      replace(accountSubscriptionManagement.path, replaceQuery({
        accountId,
      }));
    }
  }, [pendingOrder]);

  useEffect(() => {
    const fetchInfo = async () => {
      if (!accountId) return;
      await Promise.all([
        dispatch(fromTestOpsPlatformSubscriptions
          .doGetAllTestOpsPlatformSubscriptionsByAccountId({
            accountId: Number(accountId),
          })),
        dispatch(fromStudioSubscriptions.doGetActiveByAccountId({
          accountId: Number(accountId),
          checkHasPremierSuccess: true,
        })),
        dispatch(fromTestCloudSubscriptions.doGetActiveByAccountId({
          accountId: Number(accountId),
          checkHasPremierSuccess: true,
        })),
        dispatch(fromVisualTestingSubscriptions.doGetActiveByAccountId({
          accountId: Number(accountId),
          checkHasPremierSuccess: true,
        })),
        dispatch(fromTestOpsSubscriptions.doGetActiveByAccountId({
          accountId: Number(accountId),
        })),
        dispatch(fromStarterPackage.doGetAccountStarterPackage(accountId)),
        dispatch(fromOrder.doGetPendingOrder(accountId)),
        dispatch(fromPaymentMethod.doGetDefaultPaymentMethod(accountId)),
      ]);
    };
    fetchInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  const backToSubscriptionManagementPage = () => {
    replace(
      accountSubscriptionManagement.path,
      replaceQuery(parse(queryString()) as QueryDictionary),
    );
  };

  return (
    <>
      { isLoading ? <LoadingProgress /> : (
        <div id="testops-platform-checkout">
          <Grid container className={classes.root}>
            <Grid id="back-url" item xs={12} classes={classes.backBtn}>
              <Button
                variant="text"
                onClick={backToSubscriptionManagementPage}
                sx={{
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <Grid container alignItems="flex-end" className={classes.page}>
                  <Grid item>
                    <ArrowBackIcon className={classes.iconBack} />
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" className={classes.titleComeBack}>
                      {intl.formatMessage({ id: 'billinginfo.billingcontact.back.to.subscription.management' })}
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            <Grid item xs={8}>
              <Paper className={classes.cardPaymentAndBilling}>
                <PaymentInformationSection
                  accountId={accountId}
                  enableSave
                  availableRenewal
                />
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.planPurchaseDetails}>
                <OrderConfirmationSection
                  accountId={accountId}
                />
              </Paper>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default TestOpsPlatformCheckout;
