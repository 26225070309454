import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import { AppState } from '.';
import { exceptionOf, SerializedException } from '../models/index';
import { Operator, Query } from '../models/query';
import { ExistingAccountService } from '../services';
import { ExistingAccount } from '../models/existingAccount';

export const EXISTING_ACCOUNT_KEY = 'existingAccount';

interface ExistingAccountsState extends EntityState<ExistingAccount> {
  loading: boolean;
  errors: SerializedException[];
}

const existingAccountsAdapter = createEntityAdapter<ExistingAccount>();
const createInitialState = (partialState: Partial<ExistingAccountsState> = {}) => (
  existingAccountsAdapter.getInitialState({
    ...partialState,
    loading: false,
    errors: [] as SerializedException[],
  })
);

export const doGetAccountByAccountId = createAsyncThunk(
  'existingAccounts/getByAccountId',
  async (input: Required<Pick<ExistingAccount, 'accountId'>>, { rejectWithValue }) => {
    const criteria: Query<ExistingAccount>[] = [
      {
        field: 'accountId',
        operator: Operator.EQ,
        value: input.accountId,
      },
    ];
    try {
      return (
        await ExistingAccountService.getAccounts(...criteria)
      );
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

const existingAccountsSlice = createSlice({
  name: EXISTING_ACCOUNT_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doGetAccountByAccountId.pending, state => {
      state.loading = true;
      state.errors = [];
    });
    builder.addCase(
      doGetAccountByAccountId.fulfilled,
      (state, action) => {
        state.loading = false;
        existingAccountsAdapter.setAll(state, action.payload.data);
      },
    );
    builder.addCase(doGetAccountByAccountId.rejected, state => {
      state.loading = false;
    });
  },
});

// eslint-disable-next-line max-len
const selectAccount = (state: AppState) => state[EXISTING_ACCOUNT_KEY];

const { selectAll } = existingAccountsAdapter.getSelectors(selectAccount);

export const selectLoading = createSelector(
  selectAccount,
  accountState => accountState.loading,
);

export const selectAccounts = (id: ExistingAccount['accountId']) => createSelector(
  selectAll,
  all => all.filter(it => it.accountId === id),
);

export default existingAccountsSlice.reducer;
