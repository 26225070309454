import { Navigate } from 'react-router-dom';
import { notFound, RouteConfig } from '.';
import RouteWithPageTitle from './RouteWithPageTitle';
import { useAccountAuthenticate } from './useAccountAuthenticate';

const ProtectedAccountRoute = (props: RouteConfig) => {
  const { isAccountAuthenticated, redirectToNotFound } = useAccountAuthenticate(props);
  if (isAccountAuthenticated) {
    return <RouteWithPageTitle {...props} />;
  }
  if (redirectToNotFound) {
    return <Navigate to={notFound.path} replace />;
  }
  return <></>;
};

export default ProtectedAccountRoute;
