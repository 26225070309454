import Axios from 'axios';

import { Alias } from '../models/alias';
import { Page } from '../models/page';
import { Screen } from '../models/screen';
import { Track } from '../models/track';

const ANONYMOUS_ID = 'ajs_anonymous_id';

export const getAnonymousId = () => {
  let anonymousId = localStorage.getItem(ANONYMOUS_ID);
  if (anonymousId) {
    anonymousId = anonymousId.substring(1, anonymousId.length - 1);
  }
  return anonymousId;
};

export const identify = async () => {
  await Axios.post('/v1/identifies', {});
};

export const track = async (input: Track) => {
  await Axios.post('/v1/tracks', input);
};

export const page = async (input: Page) => {
  await Axios.post('/v1/pages', input);
};

export const screen = async (input: Screen) => {
  await Axios.post('/v1/screen', input);
};

export const alias = async (input: Alias) => {
  await Axios.post('/v1/aliases', input);
};

export const requestLocation = async () => {
  try {
    // Document reference here https://ip.sb/api
    const response = await fetch('https://api.ip.sb/geoip');
    const data = await response.json();
    return { city: data.city, country: data.country_code };
  } catch (error) {
    return { city: null, country: null };
  }
};
