import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import K1Typography from '../../../../components/design-system/K1Typography';
import { getFeatureName, OrganizationFeature, TestOpsPlanInterval } from '../../../../models';
import PlanPurchaseDetails from './PlanPurchaseDetails';
import { fromTestCloudSubscriptions } from '../../../../store';
import { useLaunchDarkly } from '../../../../launchdarkly';
import { PlanPrice } from '../../../../models/planPrice';
import { getCycleFromPathParam } from '../../utils';
import { ReactComponent as CrossBrowserIcon } from '../../../icons/desktop_mobile-icon.svg';
import { ReactComponent as DesktopIcon } from '../../../icons/desktop-icon.svg';
import { ReactComponent as MobileIcon } from '../../../icons/mobile-screen-icon.svg';

const useStyle = makeStyles(theme => ({
  planName: {
    padding: theme.spacing(1.5, 0),
    display: 'flex',
  },
  oldPlanName: {
    display: 'flex',
  },
  planDescription: {
    padding: theme.spacing(0, 0, 2, 0),
  },
  planIcon: {
    paddingRight: theme.spacing(1.5),
  },
  icon: {
    width: theme.spacing(3.5),
  },
}));

export interface TestCloudWebSessionPurchaseDetailsProps {
  accountId: number;
  initialTestCloudQuota?: number;
  initialTestCloudDesktopQuota?: number;
  initialTestCloudCrossBrowserQuota?: number;
  initialTestCloudMobileQuota?: number;
  clearInitialQuota: Function;
}

const TestCloudPurchaseDetails = (props: TestCloudWebSessionPurchaseDetailsProps) => {
  const {
    accountId,
    initialTestCloudQuota,
    initialTestCloudDesktopQuota,
    initialTestCloudCrossBrowserQuota,
    initialTestCloudMobileQuota,
    clearInitialQuota,
  } = props;

  const { flags } = useLaunchDarkly();
  const classes = useStyle();

  const getPlanIcon = (feature: OrganizationFeature) => {
    if (feature === OrganizationFeature.TESTCLOUD_SESSION_WEB_DESKTOP) {
      return <DesktopIcon className={classes.icon} />;
    }

    if (feature === OrganizationFeature.TESTCLOUD_SESSION_CROSS_BROWSER) {
      return <CrossBrowserIcon className={classes.icon} />;
    }

    if (feature === OrganizationFeature.TESTCLOUD_SESSION_MOBILE_APP) {
      return <MobileIcon className={classes.icon} />;
    }

    return null;
  };

  const getPlanNameComponent = (feature: OrganizationFeature) => (
    <div className={feature === OrganizationFeature.TESTCLOUD_SESSION_WEB
      ? classes.oldPlanName
      : classes.planName}
    >
      <span className={classes.planIcon}>
        {getPlanIcon(feature)}
      </span>
      <K1Typography variant="h4" fontWeight="medium">
        <FormattedMessage id={`subscriptions.${getFeatureName(feature)}.title`} />
      </K1Typography>
    </div>
  );
  const quotaConverter = (
    chosenQuota: number,
    currentSubscription?: any,
  ) => chosenQuota + (currentSubscription?.concurrentSessions || 0);

  const renderTestCloudPurchaseDetail = (
    feature: OrganizationFeature,
    monthlyPrice: number,
    annualPrice: number,
    initialQuota?: number,
  ) => {
    const testCloudCycle = getCycleFromPathParam(feature);
    const currentSubscription = useSelector(
      fromTestCloudSubscriptions.selectActiveByAccountIdAndFeature(
        Number(accountId),
        feature,
      ),
    )?.[0];
    return (
      <PlanPurchaseDetails
        currentSubscription={currentSubscription}
        planNameComponent={getPlanNameComponent(feature)}
        showPlanDescription={feature === OrganizationFeature.TESTCLOUD_SESSION_WEB}
        accountId={accountId}
        organizationFeature={feature}
        initialQuota={initialQuota ?? 0}
        initialInterval={testCloudCycle
          || currentSubscription?.billingInterval || TestOpsPlanInterval.YEAR}
        monthlyPrice={monthlyPrice}
        annualyPrice={annualPrice}
        minQuota={0}
        step={1}
        quotaConverter={quotaConverter}
        clearInitialQuota={clearInitialQuota}
      />
    );
  };

  return (
    <>
      <K1Typography variant="h3" fontWeight="medium">
        <FormattedMessage id="subscriptions.test_cloud.title" />
      </K1Typography>
      <Box className={classes.planDescription}>
        <FormattedMessage id="subscriptions.testops_platform.offering.testcloud_new_description" />
      </Box>
      {flags?.newTestCloudSKUsEnabled ? (
        <>
          <Box id="test_cloud_web_desktop">
            {renderTestCloudPurchaseDetail(
              OrganizationFeature.TESTCLOUD_SESSION_WEB_DESKTOP,
              flags?.subscriptionPlanPrice?.testCloudDesktopMonthly
              ?? PlanPrice.TESTCLOUD_DESKTOP_MONTHLY,
              flags?.subscriptionPlanPrice?.testCloudDesktopAnnually
              ?? PlanPrice.TESTCLOUD_DESKTOP_ANNUALLY,
              initialTestCloudDesktopQuota,
            )}
          </Box>
          <Box id="test_cloud_cross_browser">
            {renderTestCloudPurchaseDetail(
              OrganizationFeature.TESTCLOUD_SESSION_CROSS_BROWSER,
              flags?.subscriptionPlanPrice?.testCloudCrossBrowserMonthly
              ?? PlanPrice.TESTCLOUD_CROSS_BROWSER_MONTHLY,
              flags?.subscriptionPlanPrice?.testCloudCrossBrowserAnnually
              ?? PlanPrice.TESTCLOUD_CROSS_BROWSER_ANNUALLY,
              initialTestCloudCrossBrowserQuota,
            )}
          </Box>
          <Box id="test_cloud_mobile">
            {renderTestCloudPurchaseDetail(
              OrganizationFeature.TESTCLOUD_SESSION_MOBILE_APP,
              flags?.subscriptionPlanPrice?.testCloudMobileMonthly
              ?? PlanPrice.TESTCLOUD_MOBILE_MONTHLY,
              flags?.subscriptionPlanPrice?.testCloudMobileAnnually
              ?? PlanPrice.TESTCLOUD_MOBILE_ANNUALLY,
              initialTestCloudMobileQuota,
            )}
          </Box>
        </>

      ) : (
        <Box id="test_cloud_web_desktop">
          {renderTestCloudPurchaseDetail(
            OrganizationFeature.TESTCLOUD_SESSION_WEB,
            flags?.subscriptionPlanPrice?.testCloudMonthly
            ?? PlanPrice.TESTCLOUD_MONTHLY,
            flags?.subscriptionPlanPrice?.testCloudAnnually
            ?? PlanPrice.TESTCLOUD_ANNUALLY,
            initialTestCloudQuota,
          )}
        </Box>
      )}
    </>
  );
};

export default TestCloudPurchaseDetails;
