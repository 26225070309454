import { useSnackbar } from 'notistack';
import { useEffect, useRef } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { FormattedMessage } from 'react-intl';
import CancelSubscriptionDialog from './CancelSubscriptionDialog';
import { fromSubscriptions, useAppDispatch } from '../../../../store';
import { RecurlySubscription } from '../../../../models';

export interface TurnOffRenewalDialogProps {
  name: string;
  organizationId: number;
  email: string | null;
  recurlySubcription: RecurlySubscription;
  isOpenCancelDialog: boolean;
  setOpenCancelDialog: Function;
  handleRefreshSubscription: Function;
  accountId: number;
}
const TurnOffRenewalDialog = (props: TurnOffRenewalDialogProps) => {
  const dispatch = useAppDispatch();
  const {
    name,
    email,
    organizationId,
    recurlySubcription,
    isOpenCancelDialog,
    setOpenCancelDialog,
    handleRefreshSubscription,
    accountId,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const isMounted = useRef(true);

  // set isMounted to false when we unmount the component
  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const closeDialog = () => {
    setOpenCancelDialog(false);
  };

  const handleCancel = () => {
    if (recurlySubcription) {
      dispatch(fromSubscriptions
        .doCancelSubscription(recurlySubcription))
        .then(unwrapResult)
        .then(() => {
          enqueueSnackbar(
            <FormattedMessage
              id="subscriptions.testops_platform.platform_subscription_info.cancel.done"
              values={{
                planName: <FormattedMessage id={name} />,
                b: (chunk: string[]) => (
                  <b>
                    &nbsp;
                    {chunk}
                    &nbsp;
                  </b>
                ),
                br: <br />,
              }}
            />,
            {
              variant: 'success',
            },
          );
        })
        .finally(() => {
          if (isMounted.current) {
            setOpenCancelDialog(false);
            handleRefreshSubscription();
          }
        });
    }
  };

  return (
    <>
      <CancelSubscriptionDialog
        isOpenCancelDialog={isOpenCancelDialog}
        handleCancelSubscription={handleCancel}
        handleCloseDialog={closeDialog}
        organizationId={organizationId}
        email={email}
        accountId={accountId}
        title={(
          <FormattedMessage
            id="subscriptions.testops_platform.platform_subscription_info.cancel"
          />
        )}
        messageText={(
          <>
            <FormattedMessage
              id="subscriptions.testops_platform.platform_subscription_info.cancel.message"
              values={{
                planName: <FormattedMessage id={name} />,
                b: (chunk: string[]) => (<b>{chunk}</b>),
                br: <br />,
              }}
            />
          </>
        )}
        buttonTitleId="subscriptions.testops_platform.platform_subscription_info.button.confirm"
      />
    </>
  );
};

export default TurnOffRenewalDialog;
