/**
 * Not in use, please see ../payment
 */
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage, useIntl } from 'react-intl';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import { useQuery } from '../../routes';
import { fromAuth, fromCurrentOrgUser, fromOrganizations, fromTestOpsPaymentMethod, useAppDispatch } from '../../store';

import visa from '../icons/payment-cards/visa.svg';
import unionPay from '../icons/payment-cards/union-pay.svg';
import masterCard from '../icons/payment-cards/master-card.svg';
import jcb from '../icons/payment-cards/jcb.svg';
import discover from '../icons/payment-cards/discover.svg';
import dinersClub from '../icons/payment-cards/diners-club.svg';
import americanExpress from '../icons/payment-cards/american-express.svg';
import { StripeInput } from './StripeInput';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 0,
    width: '20rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    fontSize: '0.7rem',
  },
  typographyCardNumber: {
    marginLeft: '1rem',
  },
  label: {
    fontWeight: 'bold',
    width: '14rem',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: '1rem',
  },
  buttonSave: {
    width: '5.5rem',
    marginLeft: '3.8rem',
  },
  buttonCancel: {
    width: '5.5rem',
    marginLeft: '1rem',
    [theme.breakpoints.down('md')]: {
      marginTop: '1rem',
      marginLeft: '3.8rem',
    },
  },
  expiryDateTextField: {
    marginLeft: '3.8rem',
    width: '12rem',
  },
  informationTextField: {
    marginLeft: '3.8rem',
  },
  addCardButton: {
    width: '12rem',
    marginRight: '10rem',
    color: '#fff',
  },
  addCardText: {
    color: '#fff',
    width: '12rem',
  },
  typographyButton: {
    margin: '0 auto',
    textTransform: 'capitalize',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.background.paper,
  },
}));

const renderCardImg = (brand: String) => {
  switch (brand) {
    case 'visa': return visa;
    case 'mastercard': return masterCard;
    case 'amex': return americanExpress;
    case 'jcb': return jcb;
    case 'discover': return discover;
    case 'unionpay': return unionPay;
    case 'diners': return dinersClub;
    default:
  }
  return '';
};

const renderBrandName = (brand: String) => {
  switch (brand) {
    case 'visa': return 'VISA';
    case 'mastercard': return 'MASTERCARD';
    case 'amex': return 'AMERICAN EXPRESS';
    case 'jcb': return 'JCB';
    case 'discover': return 'DISCOVER';
    case 'unionpay': return 'UNIONPAY';
    case 'diners': return 'DINERS CLUB';
    default:
  }
  return '';
};

const PaymentMethod = (prop: { organizationId: string | null }) => {
  const classes = useStyles();
  const intl = useIntl();
  const { organizationId } = prop;
  const paymentMethod = useSelector(fromTestOpsPaymentMethod.selectPaymentMethod);
  const loading = useSelector(fromTestOpsPaymentMethod.selectLoading);
  const dispatch = useAppDispatch();
  const elements = useElements();
  const stripe = useStripe();
  const organization = useSelector(fromOrganizations.selectSelectedOrganization);
  const user = useSelector(fromAuth.selectUser);
  const orgTotalMember = useSelector(fromOrganizations.selectCount);
  const { get } = useQuery();
  const orgId = get('orgId');
  const currentOrgUser = useSelector(fromCurrentOrgUser.selectByOrganizationIdAndEmail(
    user?.email || '',
    Number(orgId),
  ));
  const { enqueueSnackbar } = useSnackbar();
  const imageList = [visa, americanExpress, masterCard, discover, unionPay, dinersClub, jcb];
  const columnInputs = ['cardname', 'cardnumber', 'cvv', 'expdate'];
  const [name, setName] = useState('');
  const [disabledCardInfoForm, setDisabledCardInfoForm] = useState(true);

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const changeState = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setDisabledCardInfoForm(!disabledCardInfoForm);
  };

  useEffect(() => {
    if (organizationId) {
      dispatch(fromTestOpsPaymentMethod.doGetPaymentMethod(+organizationId));
    }
  }, [organizationId, dispatch]);

  const submitPaymentMethod = async () => {
    if (!stripe || !elements) {
      return;
    }

    if (organizationId) {
      try {
        const cardNumberElement = elements.getElement(CardNumberElement);
        if (cardNumberElement) {
          const token = await stripe.createToken(cardNumberElement, {
            name,
          });
          if (token.token && paymentMethod) {
            await dispatch(fromTestOpsPaymentMethod.doUpdatePaymentMethod({
              id: paymentMethod?.id,
              stripeToken: token.token.id,
            })).then(unwrapResult);
            enqueueSnackbar(<FormattedMessage id="billinginfo.cardinfo.update.success" />, { variant: 'success' });
            analytics.track(
              'Payment Information Updated',
              {
                user_id: user?.id,
                firstName: user?.firstName,
                lastName: user?.lastName,
                email: user?.email,
                system_role: user?.roles,
                org_id: organization?.id,
                org_name: organization?.name,
                org_role: currentOrgUser?.role,
                total_members: orgTotalMember,
              },
            );
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              user_id: user?.id,
              org_role: currentOrgUser?.role,
              system_role: user?.roles,
              org_id: organization?.id,
              org_name: organization?.name,
              total_members: orgTotalMember,
              event: 'gtm_payment_information_updated',
            });
          } else if (token.token && !paymentMethod) {
            await dispatch(fromTestOpsPaymentMethod.doCreatePaymentMethod({
              organizationId: +organizationId,
              stripeToken: token.token.id,
            })).then(unwrapResult);
            enqueueSnackbar(<FormattedMessage id="billinginfo.cardinfo.save.success" />, { variant: 'success' });
            analytics.track(
              'Payment Information Saved',
              {
                user_id: user?.id,
                firstName: user?.firstName,
                lastName: user?.lastName,
                email: user?.email,
                system_role: user?.roles,
                org_id: organization?.id,
                org_name: organization?.name,
                org_role: currentOrgUser?.role,
                total_members: orgTotalMember,
              },
            );
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              user_id: user?.id,
              org_role: currentOrgUser?.role,
              system_role: user?.roles,
              org_id: organization?.id,
              org_name: organization?.name,
              total_members: orgTotalMember,
              event: 'gtm_payment_information_saved',
            });
          } else if (token.error) {
            enqueueSnackbar(<span>{token.error.message}</span>, { variant: 'error' });
            return;
          }
        }
      } finally {
        setDisabledCardInfoForm(!disabledCardInfoForm);
      }
    }
  };
  const renderAddCardButton = () => {
    if (disabledCardInfoForm) {
      if (!paymentMethod) {
        return (
          <Button onClick={changeState} endIcon={<AddCircleIcon color="primary" />}>
            <Typography variant="h5">
              {intl.formatMessage({ id: 'billinginfo.button.addcard' })}
            </Typography>
          </Button>
        );
      }
      return (
        <Button onClick={changeState}>
          {intl.formatMessage({ id: 'billinginfo.button.edit' })}
        </Button>
      );
    }
    return null;
  };

  const renderTextField = (inputName: String) => {
    switch (inputName) {
      case 'cardname':
        return (
          <TextField
            variant="outlined"
            fullWidth
            disabled={disabledCardInfoForm}
            className={classes.informationTextField}
            placeholder={intl.formatMessage({ id: 'billinginfo.cardinfo.placeholder.cardname' })}
            size="small"
            value={name}
            onChange={handleChangeName}
          />
        );
      case 'cardnumber':
        return (
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            disabled={disabledCardInfoForm}
            className={classes.informationTextField}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                options: {
                  showIcon: true,
                  disabled: disabledCardInfoForm,
                  placeholder: intl.formatMessage({ id: 'billinginfo.cardinfo.placeholder.cardnumber' }),
                },
                element: CardNumberElement,
              },
            }}
          />
        );
      case 'cvv':
        return (
          <TextField
            variant="outlined"
            fullWidth
            disabled={disabledCardInfoForm}
            size="small"
            className={classes.informationTextField}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                options: {
                  disabled: disabledCardInfoForm,
                  placeholder: intl.formatMessage({ id: 'billinginfo.cardinfo.placeholder.cvc' }),
                },
                element: CardCvcElement,
              },
            }}
          />
        );
      case 'expdate':
        return (
          <TextField
            variant="outlined"
            fullWidth
            disabled={disabledCardInfoForm}
            size="small"
            className={classes.expiryDateTextField}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                options: {
                  disabled: disabledCardInfoForm,
                  placeholder: intl.formatMessage({ id: 'billinginfo.cardinfo.placeholder.expdate' }),
                },
                element: CardExpiryElement,
              },
            }}
          />
        );
      default:
    }
    return '';
  };

  return (
    <>
      <Grid container>
        <Grid item sm={8} md={9}>
          {imageList.map(img => (
            <img src={img} alt="img card" style={{ marginLeft: '.4rem', width: '4rem' }} />
          ))}
        </Grid>
        <Grid container justifyContent="flex-end" item sm={4} md={3}>
          {renderAddCardButton()}
        </Grid>
      </Grid>
      <br />
      {paymentMethod ? (
        <Grid container className={classes.container}>
          <Grid item xs={3}>
            <img
              src={renderCardImg(paymentMethod.brand)}
              alt="img card"
              style={{ width: '50%', marginTop: '.4rem' }}
            />
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body1">{renderBrandName(paymentMethod.brand)}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <FiberManualRecordIcon className={classes.icon} />
              <FiberManualRecordIcon className={classes.icon} />
              <FiberManualRecordIcon className={classes.icon} />
              <FiberManualRecordIcon className={classes.icon} />
              <Typography
                variant="body1"
                className={classes.typographyCardNumber}
              >
                {paymentMethod.last4}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      ) : null}
      <br />
      {columnInputs.map(key => (
        <Grid container className={classes.formContainer} spacing={2}>
          <Grid item xs={3} md={2} lg={1}>
            <Typography variant="h5" className={classes.label}>
              {intl.formatMessage({
                id: `billinginfo.cardinfo.${key}`,
              })}
            </Typography>
          </Grid>
          <Grid item xs={6} md={5} lg={5}>
            {renderTextField(key)}
          </Grid>
        </Grid>
      ))}
      <br />
      <Grid container>
        <Grid item xs={3} md={2} lg={1} />
        <Grid item xs={6} md={6} lg={5}>
          <Button variant="contained" color="primary" className={classes.buttonSave} onClick={submitPaymentMethod} disabled={disabledCardInfoForm}>
            <Typography variant="button" className={classes.typographyButton}>
              {intl.formatMessage({ id: 'billinginfo.button.save' })}
            </Typography>
          </Button>
          <Button className={classes.buttonCancel} variant="outlined" disabled={disabledCardInfoForm} onClick={changeState}>
            <Typography align="center" variant="button" className={classes.typographyButton}>
              {intl.formatMessage({ id: 'billinginfo.button.cancel' })}
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default PaymentMethod;
