import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useConfig } from '../../../../config';
import { TestResultCount, UsagePlan } from '../../../../models';
import rocketDialog from './rocket-dialog.svg';

// I hit an error relating to findDOMNode in console for
// WarningDialog and hover tooltip in donut chart, it wont crash the app but annoying;
// Try to debug and found that Material UI havent updated
// their components matching with new React version;
// So I comment here to notice this problem.
// reference: https://stackoverflow.com/a/63111730; https://stackoverflow.com/a/61705445

const useStyles = makeStyles(theme => ({
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row-reverse',
    marginRight: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0.25),
    top: theme.spacing(0.25),
  },
  content: {
    textAlign: 'center',
    padding: theme.spacing(0, 0, 4, 0),
  },
  warningText: {
    margin: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
  },
  rocket: {
    display: 'block',
    width: '50%',
    margin: '0 auto',
    maxWidth: '150px',
  },
  upgradeButton: {
    minWidth: theme.spacing(12),
  },
}));

interface WarningDialogProps {
  testResultCount: TestResultCount | null;
  subscriptionLink: string;
}

const WarningDialog = (props: WarningDialogProps) => {
  const { testResultCount, subscriptionLink } = props;
  const classes = useStyles();
  const intl = useIntl();
  const { config } = useConfig();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (testResultCount) {
      const { canSubmit, organizationId, usagePlan } = testResultCount;
      // Do not show when it's Platform
      if (usagePlan === UsagePlan.PLATFORM) return;
      // get orgIdsShowDialog: []nummber
      // if orgId is in orgIdsShowDialog && !canSubmit => show dialog
      // if orgId is in orgIdsShowDialog && canSubmit => remove orgId in orgIdsShowDialog
      const orgIdsShowDialog: number[] = JSON.parse(localStorage.getItem('orgIdsShowDialog')!) ?? [];
      const currentOrgIdIndex = orgIdsShowDialog.indexOf(organizationId);
      if (!canSubmit && currentOrgIdIndex === -1) {
        orgIdsShowDialog.push(organizationId);
        localStorage.setItem('orgIdsShowDialog', JSON.stringify(orgIdsShowDialog));
        setIsOpen(true);
        return;
      }
      if (canSubmit && currentOrgIdIndex !== -1) {
        orgIdsShowDialog.splice(currentOrgIdIndex, 1);
        localStorage.setItem('orgIdsShowDialog', JSON.stringify(orgIdsShowDialog));
      }
    }
  }, [testResultCount]);

  const handleClose = () => {
    setIsOpen(false);
  };

  if (!config) return <></>;

  return (
    <Dialog fullWidth onClose={handleClose} aria-labelledby="customized-dialog-title" open={isOpen}>
      <DialogTitle>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <img className={classes.rocket} src={rocketDialog} alt="rocket-illustration" />
        <Typography className={classes.warningText} variant="body1" gutterBottom>
          {intl.formatMessage({ id: 'dashboard.warning_dialog.warning_text' })}
        </Typography>
        {config.onpremise
          ? (
            <Typography className={classes.warningText} variant="body1" gutterBottom>
              {
                intl.formatMessage({ id: 'dashboard.upgrade_contact' }, {
                  email: 'business@katalon.com',
                  a: () => <a href="mailto:business@katalon.com">business@katalon.com</a>,
                })
              }
            </Typography>
          ) : (
            <Button
              component={Link}
              to={subscriptionLink}
              variant="contained"
              color="primary"
              className={classes.upgradeButton}
            >
              <Typography>
                {intl.formatMessage({ id: 'dashboard.upgrade_button' })}
              </Typography>
            </Button>
          )}
      </DialogContent>
    </Dialog>
  );
};
export default WarningDialog;
