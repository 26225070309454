import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DurationUnitFormat from 'intl-unofficial-duration-unit-format';
import sum from 'lodash/sum';
import { useIntl } from 'react-intl';
import Skeleton from '@mui/material/Skeleton';

import CustomizedContentTooltip from '../CustomizedContentTooltip';

const options = {
  style: DurationUnitFormat.styles.NARROW,
  format: '{hour} {seconds}',
};
const intlDuration = new DurationUnitFormat('en', options);

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 3),
  },
  productName: {
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  duration: {
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightBold,
    marginTop: theme.spacing(1),
  },
  summaryItem: {
    color: theme.palette.primary.contrastText,
    height: theme.spacing(5),
    alignItems: 'center',
    borderRightWidth: theme.spacing(0.25),
    borderRightStyle: 'solid',
    borderRightColor: theme.palette.background.paper,
    '&:first-child': {
      borderTopLeftRadius: theme.spacing(1.5),
      borderBottomLeftRadius: theme.spacing(1.5),
    },
    '&:last-child': {
      borderTopRightRadius: theme.spacing(1.5),
      borderBottomRightRadius: theme.spacing(1.5),
      borderRightWidth: 0,
    },
  },
  durationText: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(0.5),
    fontSize: theme.spacing(2.5),
    lineHeight: 2,
    fontWeight: theme.typography.fontWeightBold,
    overflow: 'hidden',
    wordBreak: 'break-all',
    height: theme.spacing(5), // must be consistent with summaryItem.height
  },
  legend: {
    fontSize: theme.spacing(1.75),
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(3),
  },
  labelContainer: {
    marginTop: theme.spacing(2),
  },
  itemContainer: {
    flex: 1,
    paddingRight: theme.spacing(4),
  },
  totalText: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
  },
  totalHours: {
    fontSize: theme.spacing(4),
  },
}));

interface ProductUsageSummaryProps {
  productNames: string[],
  durations: number[],
  percentages: number[],
  colors: string[],
  totalDuration: number,
  totalDurationColor: string,
  loading: boolean,
}

// since we have difference styling for hours and text in summary item,
// I create separated decorated function in this component
// instead of using the common one passed down
const LicenseUsageStackedSummary = (props: ProductUsageSummaryProps) => {
  const { productNames, durations, percentages, colors,
    totalDuration, totalDurationColor, loading } = props;
  const threshold = 1;
  const skeletonLabels = 4;
  const barPercentages = (() => {
    const sumOfNormalPct = sum(percentages.filter(it => it >= threshold));
    const countSmallPct = percentages.filter((it, index) => it < threshold
      && durations[index] > 0).length;
    const theRest = 100 - threshold * countSmallPct;
    return percentages.map((it, index) => (it < threshold && durations[index] > 0
      ? threshold
      : (it / sumOfNormalPct) * theRest));
  })();
  const classes = useStyles();
  const intl = useIntl();
  return (
    <Paper className={classes.root} elevation={1}>
      <Grid container>
        <Grid item className={classes.itemContainer}>
          {loading && <Skeleton />}
          {!loading && totalDuration > 0 && (
            <Grid container>
              {
                productNames.map((name, i) => (
                  barPercentages[i] > 0 ? (
                    <Tooltip
                      title={(
                        <CustomizedContentTooltip
                          productNames={[...productNames].reverse()}
                          colors={[...colors].reverse()}
                          durations={[...durations].reverse()}
                          percentages={[...percentages].reverse()}
                        />
                      )}
                      key={name}
                      placement="top"
                    >
                      <div
                        style={{ width: `${barPercentages[i]}%`, backgroundColor: colors[i] }}
                        className={classes.summaryItem}
                        key={name}
                      >
                        <div className={classes.durationText}>
                          {/**
                             * Zero-width space character so that CSS can break the annotation from
                             * the start (useful when the space is too small to contain the first
                             * character of the annotation). &nbsp; won't work here.
                             */}
                          &#8203;
                          {barPercentages[i] > threshold
                            && intlDuration.format(durations[i] / 1000)}
                        </div>
                      </div>
                    </Tooltip>
                  ) : null
                ))
              }
            </Grid>
          )}
          {!loading && totalDuration <= 0 && (
            <div>
              <div
                style={{ width: '100%', backgroundColor: '#EEF1FA' }}
                className={classes.summaryItem}
                key="empty"
              />
            </div>
          )}
          <Grid container className={classes.labelContainer}>
            { loading ? (
              Array.from(Array(skeletonLabels).keys()).map(el => (
                <Skeleton width="10%" className={classes.legend} key={el} />
              ))) : productNames.map((name, i) => (
                <span className={classes.legend} key={name}>
                  <FiberManualRecordIcon style={{ color: colors[i] }} />
                  {name}
                </span>
            ))}
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="body1" className={classes.totalText}>
            { intl.formatMessage({ id: 'license_utilization.total' }) }
          </Typography>
          <Typography variant="h2" className={classes.totalHours} style={{ color: totalDurationColor }}>
            {
              loading ? <Skeleton /> : intlDuration.format(totalDuration / 1000)
            }
          </Typography>
        </Grid>
      </Grid>
    </Paper>

  );
};

export default LicenseUsageStackedSummary;
