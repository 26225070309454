import Axios from 'axios';
import { PageableResponse } from '../models';
import { composeQuery, Query } from '../models/query';
import { ExistingAccount } from '../models/existingAccount';

export const getAccounts = async (
  ...criteria: Query<ExistingAccount>[]
) => (
  await Axios.get<PageableResponse<ExistingAccount>>('/v1/existing-accounts', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    params: {
      ...composeQuery<ExistingAccount>(...criteria),
    },
    data: {},
  })).data;
