import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import { ReactNode } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { FormattedMessage } from 'react-intl';
import { fromSubscriptions, useAppDispatch } from '../../../../store';
import HandleSubscriptionDialog from './HandleSubscriptionDialog';
import { RecurlySubscription } from '../../../../models';

export interface TurnOnRenewalDialogProps {
  name: string;
  recurlySubcription: RecurlySubscription;
  isOpenReactivateDialog: boolean;
  setOpenReactivateDialog: Function;
  handleRefreshSubscription: Function;
}
const TurnOnRenewalDialog = (props: TurnOnRenewalDialogProps) => {
  const dispatch = useAppDispatch();
  const {
    name,
    recurlySubcription,
    isOpenReactivateDialog,
    setOpenReactivateDialog,
    handleRefreshSubscription,
  } = props;
  const { enqueueSnackbar } = useSnackbar();

  const handleReactivate = () => {
    if (recurlySubcription) {
      dispatch(fromSubscriptions
        .doReactivateSubscription(recurlySubcription))
        .then(unwrapResult)
        .then(() => {
          enqueueSnackbar(
            <FormattedMessage
              id="subscriptions.testops_platform.platform_subscription_info.reactivate.done"
              values={{
                planName: <FormattedMessage id={name} />,
                b: (chunk: string[]) => (
                  <b>
                    &nbsp;
                    {chunk}
                    &nbsp;
                  </b>
                ),
                br: <br />,
              }}
            />,
            {
              variant: 'success',
            },
          );
        })
        .finally(() => {
          setOpenReactivateDialog(false);
          handleRefreshSubscription();
        });
    }
  };

  const closeDialog = () => {
    setOpenReactivateDialog(false);
  };

  return (
    <>
      <HandleSubscriptionDialog
        id="turn-on-renewal-dialog"
        isOpenDialog={isOpenReactivateDialog}
        handleCloseDialog={closeDialog}
        title={(
          <FormattedMessage
            id="subscriptions.testops_platform.platform_subscription_info.reactivate"
          />
        )}
        messageText={(
          <>
            <FormattedMessage
              id="subscriptions.testops_platform.platform_subscription_info.reactivate.message"
              values={{
                planName: <FormattedMessage id={name} />,
                b: (chunk: string[]) => (<b>{chunk}</b>),
                span: (chunk: ReactNode) => (<span>{chunk}</span>),
                br: <br />,
              }}
            />
          </>
        )}
        handleButton={(
          <Button
            color="primary"
            size="medium"
            variant="contained"
            onClick={handleReactivate}
          >
            <FormattedMessage id="subscriptions.testops_platform.platform_subscription_info.button.confirm" />
          </Button>
        )}
      />
    </>
  );
};

export default TurnOnRenewalDialog;
