import Axios from 'axios';
import { DataResponse, PageableResponse, TestOpsTeam, TestOpsTeamAddUsers } from '../models';
import { composeQuery, Query } from '../models/query';

export const createTeam = async (
  input: Required<Pick<TestOpsTeam, 'organizationId' >>,
) => (await Axios.post<DataResponse<TestOpsTeam>>('/v1/teams', input)).data.data;

// Get teams of owner or admin
export const getTeamsOfOwnerOrAdmin = async (...criteria: Query<TestOpsTeam>[]) => (
  await Axios.get<PageableResponse<TestOpsTeam>>('/v1/teams', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    params: composeQuery<TestOpsTeam>(...criteria),
    data: {},
  })
).data;

// Add users to team
export const addUsersToTeam = async (
  input: Required<TestOpsTeamAddUsers>,
) => (await Axios.post<Array<{ id: number }>>('v1/teams/users', input)).data;
