import makeStyles from '@mui/styles/makeStyles';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  tableHead: {
    '& th': {
      fontSize: theme.spacing(1.75),
      color: '#233145',
      fontWeight: 500,
      '& > span': {
        textTransform: 'none',
        '& > svg': {
          opacity: 0,
          width: theme.spacing(2.5),
          height: theme.spacing(2.5),
        },
      },
      '& > span.Mui-active': {
        '& > svg': {
          opacity: 1,
        },
      },
      '& > span::hover': {
        '& > svg': {
          opacity: 0.5,
        },
      },
    },
    height: theme.spacing(8),
  },
  cellContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > * > .MuiSvgIcon-root': {
      margin: 0,
      marginLeft: theme.spacing(-0.1),
      width: theme.spacing(2.5),
    },
    textTransform: 'capitalize',
  },
  infoIcon: {
    fontSize: theme.spacing(2.2),
    marginLeft: theme.spacing(-0.4),
    cursor: 'pointer',
  },
}));

interface TableItemFields {
  id: number,
  name: string,
  users: number,
  createdDate: string,
  delete: string,
}

interface HeadCell {
  id: keyof TableItemFields;
  label: string;
  enableSort: boolean;
}

const UserGroupHeaderTable = () => {
  const classes = useStyles();
  const intl = useIntl();
  const headCells: HeadCell[] = [
    { id: 'id', label: intl.formatMessage({ id: 'organization.group.cell.id' }), enableSort: false },
    { id: 'name', label: intl.formatMessage({ id: 'organization.group.cell.name' }), enableSort: false },
    { id: 'users', label: intl.formatMessage({ id: 'organization.group.cell.users' }), enableSort: false },
    { id: 'createdDate', label: intl.formatMessage({ id: 'organization.group.cell.createdDate' }), enableSort: false },
    { id: 'delete', label: '', enableSort: false },
  ];

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            padding="none"
            key={headCell.id.toString()}
          >
            <div className={classes.cellContainer}>
              {
                headCell.label
              }
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default UserGroupHeaderTable;
