import { useSelector } from 'react-redux';
import AdditionalSubscriptionRow from './AdditionalSubscriptionRow';
import { Account, OrganizationFeature, getFeatureName, TestCloudSubscription } from '../../../../models';
import {
  fromTestCloudSubscriptions,
  useAppDispatch,
} from '../../../../store';
import { getTestCloudFreeTrialExpiryDate } from '../../../subscription-management/utils';
import { useConfig } from '../../../../config';
import { useLaunchDarkly } from '../../../../launchdarkly';

export interface TestCloudSubscriptionDetailsProps{
  account: Account | null,
  email: string | null,
  organizationId: number,
}

const TestCloudSubscriptionDetails = (props: TestCloudSubscriptionDetailsProps) => {
  const {
    account,
    organizationId,
    email,
  } = props;
  const dispatch = useAppDispatch();
  const { config } = useConfig();
  const { flags } = useLaunchDarkly();
  const testCloudTrialDate = getTestCloudFreeTrialExpiryDate(
    config,
    account?.createdAt,
  );

  const grantedTrialTestCloudSubscriptions = useSelector(fromTestCloudSubscriptions
    .selectGrantedTrialTestCloudSubscription(
      Number(account?.id),
    ));

  const initialTrialTestCloudSubscriptions = useSelector(fromTestCloudSubscriptions
    .selectInitialTrialTestCloudSubscription(
      Number(account?.id),
    ));

  const currentActiveTestCloudSubscription = useSelector(
    fromTestCloudSubscriptions.selectActiveByAccountIdAndFeature(
      Number(account?.id),
      OrganizationFeature.TESTCLOUD_SESSION_WEB,
    ),
  )?.[0];

  const hasActivePaidTestCloudSubscriptions = useSelector(
    fromTestCloudSubscriptions.selectHaveActivePaidTestCloudSubscription(Number(account?.id)),
  );

  const handleRefreshSubscription = async () => {
    await Promise.all([
      dispatch(fromTestCloudSubscriptions.doGetActiveByAccountId({
        accountId: Number(account?.id),
      })),
    ]);
  };

  const isTestCloudFreeTrialValid = new Date().valueOf() < testCloudTrialDate
    && !hasActivePaidTestCloudSubscriptions
    && (!flags?.newTestCloudSKUsEnabled || !initialTrialTestCloudSubscriptions);

  const name = currentActiveTestCloudSubscription
    ? 'subscriptions.test_cloud'
    : 'subscriptions.test_cloud_trial';

  const getTestCloudKey = (feature: OrganizationFeature) => {
    if (feature === OrganizationFeature.TESTCLOUD_SESSION_WEB_DESKTOP) {
      return 'subscriptions.test_cloud_desktop';
    }
    if (feature === OrganizationFeature.TESTCLOUD_SESSION_CROSS_BROWSER) {
      return 'subscriptions.test_cloud_cross_browser';
    }

    if (feature === OrganizationFeature.TESTCLOUD_SESSION_MOBILE_APP) {
      return 'subscriptions.test_cloud_mobile';
    }

    if (feature === OrganizationFeature.TESTCLOUD_SESSION_PRIVATE_DEVICE) {
      return 'subscriptions.test_cloud_private_device';
    }

    return 'subscriptions.test_cloud';
  };

  const renderTrialSubscription = (subscription: TestCloudSubscription) => (
    <AdditionalSubscriptionRow
      key={`subscriptions.trial_${subscription.feature}`}
      name={`subscriptions.${getFeatureName(subscription.feature)}.title`}
      organizationId={organizationId}
      email={email}
      currentSubscription={subscription}
      initQuantity={5}
      feature={subscription.feature}
      trialExpiryDate={testCloudTrialDate}
      handleRefreshSubscription={handleRefreshSubscription}
      account={account}
    />
  );

  const renderTrial = (feature: OrganizationFeature) => {
    const grantedSubscription = grantedTrialTestCloudSubscriptions.find(
      it => it.feature === feature,
    );

    // priority to show trial granted subscription
    if (grantedSubscription) {
      return renderTrialSubscription(grantedSubscription);
    }

    const initialSubscription = initialTrialTestCloudSubscriptions.find(
      it => it.feature === feature,
    );

    // otw, show initial trial
    if (initialSubscription) {
      return renderTrialSubscription(initialSubscription);
    }

    return null;
  };

  const renderTestCloudSubscription = (feature: OrganizationFeature) => {
    const currentActiveTestCloudSubscription = useSelector(
      fromTestCloudSubscriptions.selectActiveByAccountIdAndFeature(
        Number(account?.id),
        feature,
      ),
    )?.[0];

    return (
      currentActiveTestCloudSubscription
        ? (
          <AdditionalSubscriptionRow
            key={getTestCloudKey(feature)}
            name={getTestCloudKey(feature)}
            organizationId={organizationId}
            email={email}
            currentSubscription={currentActiveTestCloudSubscription}
            initQuantity={5}
            feature={feature}
            trialExpiryDate={testCloudTrialDate}
            handleRefreshSubscription={handleRefreshSubscription}
            account={account}
          />
        ) : renderTrial(feature)
    );
  };

  const renderNewTestCloudSubscriptions = () => {
    if (isTestCloudFreeTrialValid) {
      return (
        <AdditionalSubscriptionRow
          key="subscriptions.test_cloud_trial"
          name="subscriptions.test_cloud_trial"
          organizationId={organizationId}
          email={email}
          currentSubscription={currentActiveTestCloudSubscription}
          initQuantity={5}
          feature={OrganizationFeature.TESTCLOUD_SESSION_WEB}
          trialExpiryDate={testCloudTrialDate}
          handleRefreshSubscription={handleRefreshSubscription}
          account={account}
        />
      );
    }

    return (
      <>
        {renderTestCloudSubscription(OrganizationFeature.TESTCLOUD_SESSION_WEB)}
        {renderTestCloudSubscription(OrganizationFeature.TESTCLOUD_SESSION_WEB_DESKTOP)}
        {renderTestCloudSubscription(OrganizationFeature.TESTCLOUD_SESSION_CROSS_BROWSER)}
        {renderTestCloudSubscription(OrganizationFeature.TESTCLOUD_SESSION_MOBILE_APP)}
        {renderTestCloudSubscription(OrganizationFeature.TESTCLOUD_SESSION_PRIVATE_DEVICE)}
      </>
    );
  };

  const renderLegacyTestCloudSubscription = () => (
    isTestCloudFreeTrialValid || currentActiveTestCloudSubscription
      ? (
        <AdditionalSubscriptionRow
          key={name}
          name={name}
          organizationId={organizationId}
          email={email}
          currentSubscription={currentActiveTestCloudSubscription}
          initQuantity={5}
          feature={OrganizationFeature.TESTCLOUD_SESSION_WEB}
          trialExpiryDate={testCloudTrialDate}
          handleRefreshSubscription={handleRefreshSubscription}
          account={account}
        />
      ) : null
  );

  return (
    <>
      {flags?.newTestCloudSKUsEnabled
        ? renderNewTestCloudSubscriptions() : renderLegacyTestCloudSubscription()}
    </>
  );
};

export default TestCloudSubscriptionDetails;
