import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { OrganizationFeature } from '../../models';
import { notFound, useQuery } from '../../routes';
import { fromAuth, fromCurrentOrgUser } from '../../store';
import AssignLicenseDialogOld from './assign-license-dialog-old';
import AssignLicenseDialog from './assign-license-dialog';
import { useLaunchDarkly } from '../../launchdarkly';

const LicenseManagement = () => {
  const { get } = useQuery();
  const { flags } = useLaunchDarkly();
  const popupView = get('view') || '';
  const orgId = get('orgId');
  const feature = OrganizationFeature.fromString(get('feature') || '');
  const [assignLicenseDialogOpen, setAssignLicenseDialogOpen] = useState(false);
  const isRootOrAdmin = useSelector(fromAuth.selectIsRootOrAdmin);
  const user = useSelector(fromAuth.selectUser);
  const currentOrganizationUser = useSelector(
    fromCurrentOrgUser.selectByOrganizationIdAndEmail(
      user?.email || '',
      Number(orgId),
    ),
  );

  useEffect(() => {
    if (popupView === 'assign_license') setAssignLicenseDialogOpen(true);
    else setAssignLicenseDialogOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupView]);

  // do this so that nothing is shown while loading the role
  if (!isRootOrAdmin && !currentOrganizationUser) return null;

  if (!popupView || !feature) {
    return <Navigate to={notFound.path} replace />;
  }
  const onCloseAssignLicenseDialog = () => {
    setAssignLicenseDialogOpen(false);
  };
  return flags?.bulkActionOfLicenseManagementEnable
    ? (
      <AssignLicenseDialog
        open={assignLicenseDialogOpen}
        onCloseAssignLicenseDialog={onCloseAssignLicenseDialog}
      />
    )
    : (
      <AssignLicenseDialogOld
        open={assignLicenseDialogOpen}
        onCloseAssignLicenseDialog={onCloseAssignLicenseDialog}
      />
    );
};
export default LicenseManagement;
