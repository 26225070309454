import { createAsyncThunk, createSelector, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { AppState } from '.';
import { UserSsoInvitation, UserSsoInvitationStatus } from '../models';
import { Operator, Query } from '../models/query';
import { UserSsoInvitationService } from '../services';

export const USER_SSO_INVITAION_FEATURE_KEY = 'userSsoInvitations';

interface UserSsoInvitationState extends EntityState<UserSsoInvitation> {
  loading: boolean;
}

const userSsoInvitationAdapter = createEntityAdapter<UserSsoInvitation>();
const MAX_NUMBER_OF_RECORDS = 50;

export const createInitialState = (): UserSsoInvitationState => (
  userSsoInvitationAdapter.getInitialState({
    loading: false,
  })
);

export const doGetByOrganizationId = createAsyncThunk(
  'userSsoInvitations/getByOrganizatioId',
  async (input: Required<Pick<UserSsoInvitation, 'organizationId'>>) => {
    const criteria: Query<UserSsoInvitation>[] = [
      {
        field: 'organizationId',
        operator: Operator.EQ,
        value: input.organizationId,
      },
      {
        field: 'status',
        operator: Operator.NE,
        value: UserSsoInvitationStatus.DECLINED,
      },
    ];

    const firstResponse = await UserSsoInvitationService.getUserSsoInvitations(...criteria);
    const numberOfRequests = Math.ceil(firstResponse.total / MAX_NUMBER_OF_RECORDS);

    const remainingRequests = [];
    for (let i = 1; i < numberOfRequests; i += 1) {
      const request = UserSsoInvitationService.getUserSsoInvitations(
        ...criteria,
        { offset: MAX_NUMBER_OF_RECORDS * i },
      );
      remainingRequests.push(request);
    }
    const remainingResponses = await Promise.all(remainingRequests);
    const userSsoInvitation = [...firstResponse.data];
    remainingResponses.forEach(response => userSsoInvitation.push(...response.data));

    return { data: userSsoInvitation, total: firstResponse.total };
  },
);

const userSsoInvitationSlice = createSlice({
  name: USER_SSO_INVITAION_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doGetByOrganizationId.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetByOrganizationId.fulfilled, (state, action) => {
      state.loading = false;
      userSsoInvitationAdapter.setAll(state, action.payload.data);
    });
    builder.addCase(doGetByOrganizationId.rejected, state => {
      state.loading = false;
    });
  },
});

const selectUserSsoInvitationFeature = (state: AppState) => state[USER_SSO_INVITAION_FEATURE_KEY];

export const {
  selectAll: selectAllUserSsoInvitations,
} = userSsoInvitationAdapter.getSelectors(selectUserSsoInvitationFeature);

export const selectPendingByOrganizationIdAndInvitedTestOpsUserId = (
  organizationId: number,
  invitedTestOpsUserId: number,
) => createSelector(
  selectAllUserSsoInvitations,
  userSsoInvitations => userSsoInvitations.find(it => (
    it.organizationId === organizationId
      && it.invitedTestOpsUserId === invitedTestOpsUserId
      && it.status === UserSsoInvitationStatus.PENDING
  )),
);

export default userSsoInvitationSlice.reducer;
