import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import PromotionBanner from '../katalon-offering-section/PromotionBanner';
import { directToSelfServeConfirmation } from '../../../../layout/routes';
import { useNavigate } from '../../../../routes';

const useStyles = makeStyles((theme => ({
  rootContainer: {
    boxShadow: '0 8px 16px 0 rgba(24, 71, 202, 0.08)',
    margin: theme.spacing(0, 2, 4),
  },
  defaultContainer: {
    padding: theme.spacing(0, 1, 2, 4),
    borderRadius: theme.spacing(0.5),
    background: 'white',
    paddingTop: 1,
  },
  description: {
    color: '#233145',
    fontWeight: 500,
    fontSize: 14,
  },
  subscribeBtn: {
    fontWeight: 500,
    fontSize: 14,
  },
})));

export interface DirectToSelfServePromotionSectionProps {
  accountId: number;
}

const DirectToSelfServePromotionSection = (props: DirectToSelfServePromotionSectionProps) => {
  const {
    accountId,
  } = props;
  const classes = useStyles();
  const intl = useIntl();
  const { navigate, replaceQuery } = useNavigate();

  const subscribe = () => {
    const subscribe = async () => {
      navigate(directToSelfServeConfirmation.path, replaceQuery(
        {
          accountId: `${accountId}`,
        },
      ));
    };
    subscribe().catch(() => {});
  };

  const renderContent = () => (
    <div>
      <p className={classes.description}>
        <FormattedMessage id="direct.selfserve.description" />
      </p>
      <Button
        variant="contained"
        size="large"
        color="primary"
        onClick={subscribe}
        className={classes.subscribeBtn}
      >
        {intl.formatMessage({ id: 'direct.selfserve.review_online' })}
      </Button>
    </div>
  );

  return (
    <>
      <div id="direct-selfserve-section" className={classes.rootContainer}>
        {/* Banner */}
        <PromotionBanner
          title="promote_banner.direct.selfserve"
          percent=""
        />
        <Paper
          elevation={0}
          className={classes.defaultContainer}
        >
          {renderContent()}
        </Paper>
      </div>
    </>
  );
};

export default DirectToSelfServePromotionSection;
