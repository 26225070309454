import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { FormattedMessage } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import { ReactComponent as TriangleExclamation } from '../../icons/triangle-exclamation-yellow.svg';

const useStyles = makeStyles((theme => ({
  gracePeriodIcon: {
    width: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  gracePeriodTooltip: {
    fontSize: 10,
    fontWeight: 500,
    color: '#ffffff',
    borderRadius: theme.spacing(0.5),
    backgroundColor: '#616161',
  },
})));

interface CustomizeTooltipStyles {
  maxWidth: number,
  backgroundColor: string,
}

interface CustomizeTooltipProps extends TooltipProps {
  style: CustomizeTooltipStyles,
}

const CustomizeTooltip = styled(({ className, style, ...props }: CustomizeTooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, style }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: theme.spacing(style.maxWidth),
    backgroundColor: style.backgroundColor,
  },
}));

interface GracePeriodTooltipProps {
  expiryDate: Date,
}

const GracePeriodTooltip = (props: GracePeriodTooltipProps) => {
  const { expiryDate } = props;
  const classes = useStyles();
  // const isExpiry = new Date(expiryDate) < new Date();
  return expiryDate < new Date() ? (
    <CustomizeTooltip
      title={(
        <div className={classes.gracePeriodTooltip}>
          <FormattedMessage
            id="subscription.management.tooltip.grace_period.message"
            values={{
              gracePeriodEndDate: moment(expiryDate).add(7, 'd').format('MMM Do'),
            }}
          />
        </div>
      )}
      style={{
        maxWidth: 30,
        backgroundColor: '#616161',
      }}
      placement="bottom"
      arrow
    >
      <TriangleExclamation className={classes.gracePeriodIcon} />
    </CustomizeTooltip>
  ) : null;
};

export default GracePeriodTooltip;
