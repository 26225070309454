import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import { fromOrganizations } from '../../store';
import { useQuery } from '../../routes';
import OrganizationProfile from './OrganizationProfile';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(3),
  },
  container: {
    paddingTop: '1.5rem',
  },
  paper: {
    padding: '0.75rem 1.5rem',
  },
}));

const OrganizationManagement = () => {
  const { get } = useQuery();
  const orgId = Number(get('orgId'));
  const classes = useStyles();
  const currentOrganization = useSelector(fromOrganizations.selectOrganizationById(orgId));

  if (!currentOrganization) {
    return <></>;
  }
  return (
    <>
      <Box className={classes.root}>
        <Typography className={classes.title}>
          <FormattedMessage id="organization.management" />
        </Typography>
        <Box className={classes.container}>
          <Paper className={classes.paper}>
            <OrganizationProfile currentOrganization={currentOrganization} />
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default OrganizationManagement;
