import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';
import { useConfig } from '../../config';
import { fromAuth, fromUserInvitation, useAppDispatch } from '../../store';
import PendingInvitationItem from './PendingInvitationItem';

const useStyles = makeStyles(theme => ({
  pendingBlock: {
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    borderRadius: theme.spacing(1),
    width: '100%',
  },
  title: {
    color: '#233145',
    fontSize: theme.spacing(2.5),
    fontWeight: 600,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  list: {
    maxHeight: theme.spacing(55),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    overflow: 'auto',
    '& > *:not(:last-child)': {
      borderBottom: 'solid 1px #F0F0F0',
    },
  },
  group: {
    '& > *:not(:last-child)': {
      borderBottom: '1px solid #EEF1FA',
    },
  },
}));

const PendingInvitationList = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { config } = useConfig();
  const user = useSelector(fromAuth.selectUser);
  const userInvitations = useSelector(fromUserInvitation
    .selectInvitationsByInvitedUserEmail(user!!.email));

  useEffect(() => {
    if (!config || !user) return;
    const getData = async () => {
      await dispatch(
        fromUserInvitation.doGetK1ActiveUserInvitations({
          email: user?.email || '',
          invitationExpirationExtension: config?.invitationExpirationExtensionMilliseconds ?? 0,
        }),
      );
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, user]);

  return userInvitations.length > 0 ? (
    <Card className={classes.pendingBlock}>
      <Box>
        <Typography variant="subtitle2" className={classes.title}>
          <FormattedMessage id="welcome.pending.title" />
        </Typography>
        <List className={classes.list}>
          <TransitionGroup className={classes.group}>
            {userInvitations.map(item => (
              <Collapse timeout={300} key={item.id}>
                <PendingInvitationItem invitation={item} />
              </Collapse>
            ))}
          </TransitionGroup>
        </List>
      </Box>
    </Card>
  ) : null;
};

export default PendingInvitationList;
