import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { useConfig } from '../../config';
import LoadingProgress from '../../layout/LoadingProgress';
import { OrganizationFeature } from '../../models';
import {
  fromAuth,
  fromOrganizationFeatureFlag,
  fromOrganizations,
  fromSubscriptions,
  fromTestOpsPlatformSubscriptions,
  useAppDispatch,
} from '../../store';

const InvalidSsoComponent = () => <Navigate to="/" replace />;

const SsoTestOpsComponent = () => {
  const { search } = useLocation();
  const { config } = useConfig();
  const dispatch = useAppDispatch();

  const [loadedOrgInfo, setLoadedOrgInfo] = useState(false);
  const [loadedAccountInfo, setLoadedAccountInfo] = useState(false);

  const token = useSelector(fromAuth.selectToken);

  const orgId = new URLSearchParams(search).get('orgId');

  if (!config) return <></>;

  const currentOrganization = useSelector(fromOrganizations.selectOrganizationById(Number(orgId)));
  const accountId = currentOrganization?.accountId;

  const isSsoFlagEnabled = useSelector(fromOrganizationFeatureFlag.selectIsOrganizationSsoEnabled(
    Number(orgId),
  )) || false;

  const activePlatformSubscription = useSelector(fromTestOpsPlatformSubscriptions
    .selectPaidTestOpsPlatformSubscriptionByAccountId(
      Number(accountId),
    )).filter(sub => new Date(sub.expiryDate).getTime() > Date.now())?.[0];
  const hasTOEnterprise = useSelector(fromSubscriptions
    .selectHasTOEnterpriseByOrganizationId(Number(orgId) || 0));

  const hasEnableSSOAndCustomDomain = (
    activePlatformSubscription?.feature === OrganizationFeature.TESTOPS_PLATFORM
    && activePlatformSubscription.quota >= 30000)
  || hasTOEnterprise || isSsoFlagEnabled;

  useEffect(() => {
    if (config.onpremise) {
      setLoadedOrgInfo(true);
      return;
    }
    if (!Number.isNaN(orgId) && Number(orgId)) {
      const fetchInfo = async () => {
        await Promise.all([
          dispatch(
            fromSubscriptions.doGetActiveSubscriptionsByOrgId({ organizationId: Number(orgId) }),
          ),
          dispatch(fromOrganizations.doFetchOrganizationById({ id: Number(orgId) })),
          dispatch(
            fromOrganizationFeatureFlag.doGetByOrganizationId({ organizationId: Number(orgId) }),
          ),
        ]);
        setLoadedOrgInfo(true);
      };
      fetchInfo();
    }
  }, [orgId, config]);

  useEffect(() => {
    if (config.onpremise) {
      setLoadedAccountInfo(true);
      return;
    }
    if (Number(accountId)) {
      const fetchInfo = async () => {
        await Promise.all([
          dispatch(fromTestOpsPlatformSubscriptions
            .doGetAllTestOpsPlatformSubscriptionsByAccountId({
              accountId: Number(accountId),
              checkHasPremierSuccess: true,
            })),
        ]);
        setLoadedAccountInfo(true);
      };
      fetchInfo();
    }
  }, [accountId, config]);

  const getSubdomainLink = (domain: string | undefined) => `https://${domain}.${process.env.REACT_APP_CUSTOM_DOMAIN_POSTFIX}`;

  if (orgId && (!loadedOrgInfo || !loadedAccountInfo)) {
    return <LoadingProgress />;
  }

  // general SSO
  const redirectUri = new URLSearchParams(search).get('redirect_uri');
  if (redirectUri) {
    const convertedRedirectUri = redirectUri.startsWith('//')
      ? `${window.location.origin}${redirectUri.substr(2, redirectUri.length)}`
      : redirectUri;
    const testOpsDomain = currentOrganization?.domain
    && hasEnableSSOAndCustomDomain && !config.onpremise
      ? getSubdomainLink(currentOrganization?.domain) : config?.testOpsPublicUrl;
    const uri = new URL(`${testOpsDomain}${convertedRedirectUri}`);
    const canRedirect = config.whitelistedDomains.split(',').find(
      whitelistedDomain => uri.host === whitelistedDomain.trim() || uri.host.endsWith(`.${whitelistedDomain.trim()}`),
    );
    // if on-premise, ignore whitelisedDomain
    if (config.onpremise || canRedirect) {
      const isMatchUri = uri.href.startsWith(config.testOpsPublicUrl)
        || uri.href.startsWith(getSubdomainLink(currentOrganization?.domain));
      // TestOps using katone_access_token, other sites using access_token
      if (isMatchUri && config?.iamEnabled) {
        uri.searchParams.append('new_login', 'true');
      } else if (isMatchUri) {
        uri.searchParams.append('katone_access_token', token!.jwt);
      } else {
        return <InvalidSsoComponent />;
      }
      window.location.replace(uri.toString());

      return <></>;
    }
  }

  return <InvalidSsoComponent />;
};

export default SsoTestOpsComponent;
