import { createAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { AppState } from '.';
import { Organization, SerializedException, UserSsoInvitation } from '../models';
import { Operator, Query } from '../models/query';
import { UserSsoInvitationService, OrganizationService } from '../services';

export const ACCEPT_INVITE_SSO_PAGE_KEY = 'accept_invite_sso_page';

interface UserSsoInvitationState {
  organization: Organization | null;
  errors: SerializedException[];
  loading: boolean;
  submitting: boolean;
  invitation: UserSsoInvitation | null;
}

export const createInitialState = (): UserSsoInvitationState => ({
  organization: null,
  loading: true,
  errors: [],
  submitting: false,
  invitation: null,
});

export const doResetState = createAction('acceptInviteSso/resetState');

export const getInvitationByToken = createAsyncThunk(
  'acceptInviteSso/getInvitationByToken',
  async (input: Pick<UserSsoInvitation, 'invitationToken'>) => {
    const criteria: Array<Query<UserSsoInvitation>> = [
      {
        field: 'invitationToken',
        operator: Operator.EQ,
        value: input.invitationToken,
      },
      { field: 'archived', operator: Operator.EQ, value: false },
      { limit: 1 },
    ];
    const response = await UserSsoInvitationService.getUserSsoInvitationByToken(...criteria);
    const orgId = response.data
      .find(it => it.invitationToken === input.invitationToken)
      ?.organizationId || 0;
    const orgCriteria: Query<Organization> = {
      field: 'id',
      operator: Operator.EQ,
      value: orgId,
    };

    const orgResponse = await OrganizationService.getOrganizationByOrgId(orgCriteria);
    return { invitation: response.data?.[0], organization: orgResponse[0] };
  },
);

export const acceptSsoInvitation = createAsyncThunk(
  'acceptInviteSso/accept',
  async (input: Pick<UserSsoInvitation, 'id' | 'status' | 'invitationToken'>) => {
    const body = {
      id: input.id,
      status: input.status,
      invitationToken: input.invitationToken,
    };
    const response = await UserSsoInvitationService.updateUserSsoInvitation(body);
    return response.data;
  },
);

export const declineSsoInvitation = createAsyncThunk(
  'acceptInviteSso/decline',
  async (input: Pick<UserSsoInvitation, 'id'>) => {
    const body = {
      id: input.id,
    };
    const response = await UserSsoInvitationService.deleteUserSsoInvitation(body);
    return response.data;
  },
);

const acceptInviteSsoSlice = createSlice({
  name: ACCEPT_INVITE_SSO_PAGE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getInvitationByToken.pending, state => {
      state.loading = true;
    });
    builder.addCase(getInvitationByToken.rejected, (state, action) => {
      const payload = action.payload as SerializedException;
      state.errors.push(payload);
      state.loading = false;
    });
    builder.addCase(getInvitationByToken.fulfilled, (state, action) => {
      state.invitation = action.payload.invitation;
      state.organization = action.payload.organization;
      state.loading = false;
    });
    builder.addCase(doResetState, state => {
      state.loading = false;
      state.submitting = false;
      state.errors = [];
      state.invitation = null;
    });
    builder.addCase(acceptSsoInvitation.pending, state => {
      state.submitting = true;
    });
    builder.addCase(acceptSsoInvitation.rejected, (state, action) => {
      const payload = action.payload as SerializedException;
      state.errors.push(payload);
      state.submitting = false;
    });
    builder.addCase(acceptSsoInvitation.fulfilled, state => {
      state.submitting = false;
    });

    builder.addCase(declineSsoInvitation.pending, state => {
      state.submitting = true;
    });
    builder.addCase(declineSsoInvitation.rejected, (state, action) => {
      const payload = action.payload as SerializedException;
      state.errors.push(payload);
      state.submitting = false;
    });
    builder.addCase(declineSsoInvitation.fulfilled, state => {
      state.submitting = false;
    });
  },
});

const selectSsoInvitation = (state: AppState) => state[ACCEPT_INVITE_SSO_PAGE_KEY];

export const selectLoading = () => createSelector(selectSsoInvitation, state => state.loading);
export const selectSubmitting = () => (
  createSelector(selectSsoInvitation, state => state.submitting)
);
export const selectErrors = () => createSelector(selectSsoInvitation, state => state.errors);
export const selectInvitation = () => (
  createSelector(selectSsoInvitation, state => state.invitation)
);
export const selectOrganization = () => (
  createSelector(selectSsoInvitation, state => state.organization)
);

export default acceptInviteSsoSlice.reducer;
