import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { AppState } from '.';
import { Organization, TestActivity } from '../models';
import { DashboardService } from '../services';

export const DASHBOARD_FEATURE_KEY = 'dashboard';

interface DashboardState {
  past7DaysTestActivities: TestActivity[] | [];
  loading: boolean;
  error?: string;
}

export const createInitialState = (): DashboardState => ({
  past7DaysTestActivities: [],
  loading: false,
});

export const doGetPast7DaysTestActivities = createAsyncThunk(
  'dashboard/getPast7DaysTestActivities',
  async (organizationId: Organization['id']) => {
    const testActivities = await DashboardService.getPast7DaysTestActivities(organizationId);
    return testActivities;
  },
);

const dashboardSlice = createSlice({
  name: DASHBOARD_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doGetPast7DaysTestActivities.pending, state => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(doGetPast7DaysTestActivities.fulfilled, (state, action) => {
      state.loading = false;
      state.past7DaysTestActivities = action.payload;
    });
    builder.addCase(doGetPast7DaysTestActivities.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.past7DaysTestActivities = [];
    });
  },
});

const selectDashboardFeature = (state: AppState) => state[DASHBOARD_FEATURE_KEY];
export const selectPast7DaysTestActivities = createSelector(
  selectDashboardFeature,
  dashboardState => dashboardState.past7DaysTestActivities,
);
export const selectLoading = createSelector(
  selectDashboardFeature,
  dashboardState => dashboardState.loading,
);
export const selectError = createSelector(
  selectDashboardFeature,
  dashboardState => dashboardState.error,
);

export default dashboardSlice.reducer;
