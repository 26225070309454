import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { unwrapResult } from '@reduxjs/toolkit';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { fromSubscriptions, fromVisualTestingSubscriptions, useAppDispatch } from '../../../../store';
import { fromInvoices, fromTestOpsPlatformSubscriptions, fromStarterPackage } from '../../../../store/rootReducer';
import BillingCycle from './BillingCycle';
import { getFeatureName, getUnitName, OrganizationFeature, TestOpsPlanInterval } from '../../../../models';
import { getPlanId } from './utils';
import QuotaInput from './QuotaInput';
import LoadingProgress from '../../../../layout/LoadingProgress';
import { sendTrackingData } from '../../utils';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0.5, 2, 0, 0),
    boxShadow: 'none',
  },
  billingCycle: {
    display: 'flex',
    flexDirection: 'row',
  },
  price: {
    padding: theme.spacing(1, 0),
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    color: '#22283c',
  },
  enabled: {
    '& > *': {
      backgroundColor: theme.palette.getContrastText('#000'),
    },
  },
  quotaInfo: {
    marginRight: theme.spacing(0.25),
    marginLeft: theme.spacing(0.25),
  },
  infoCurrentContainer: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
  },
  planDescription: {
    marginTop: theme.spacing(0.75),
  },
  premierSuccess: {
    marginBottom: theme.spacing(0.5),
    fontWeight: theme.typography.fontWeightBold,
  },
  description: {
    paddingRight: theme.spacing(2),
  },
  subscribeBtn: {
    fontWeight: 500,
    width: theme.spacing(15),
    marginTop: theme.spacing(2.75),
    fontSize: theme.spacing(1.75),
  },
  errorMessage: {
    color: theme.palette.error.main,
    margin: theme.spacing(1, 2, 2, 0),
    fontWeight: '400',
    fontSize: theme.spacing(1.75),
  },
}));

export interface PlanPurchaseDetailsProps {
  currentSubscription?: any,
  accountId: number;
  organizationFeature: OrganizationFeature;
  planNameComponent: React.ReactNode;
  showPlanDescription: boolean;
  initialQuota?: number;
  initialInterval: TestOpsPlanInterval;
  quotaConverter?: (chosenQuota: number, currentSubscription?: any) => number;
  minQuota?: number;
  maxQuota?: number;
  firstStep?: number;
  step: number;
  annualyPrice?: number;
  monthlyPrice?: number;
  priceFn?: (chosenQuota: number, chosenInterval: TestOpsPlanInterval) => number;
  clearInitialQuota: Function;
  isTrialRequest?: boolean;
}

const PlanPurchaseDetails = (props: PlanPurchaseDetailsProps) => {
  const {
    currentSubscription,
    accountId,
    organizationFeature,
    planNameComponent,
    showPlanDescription,
    initialQuota,
    quotaConverter,
    minQuota,
    maxQuota,
    firstStep,
    step,
    monthlyPrice,
    annualyPrice,
    priceFn,
    initialInterval,
    clearInitialQuota,
    isTrialRequest,
  } = props;
  const intl = useIntl();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const invoicesLoading = useSelector(fromInvoices.selectLoading);
  const featureInvoiceLoading = useSelector(fromInvoices
    .selectLoadingByFeature(organizationFeature));
  const [isLoading, setIsLoading] = useState(false);
  const [chosenInterval, setChosenInterval] = useState(initialInterval);
  const [chosenQuota, setChosenQuota] = useState(initialQuota);

  const currentPaidTestOpsPlatformSubscription = useSelector(
    fromTestOpsPlatformSubscriptions.selectPaidTestOpsPlatformSubscriptionByAccountId(
      Number(accountId),
    ),
  )?.[0];
  const planName = getFeatureName(organizationFeature);
  const unitName = getUnitName(planName);
  const [trialDisplayed, setTrialDisplayed] = useState(planName === 'visualtesting' && !isTrialRequest
    && !currentSubscription && currentPaidTestOpsPlatformSubscription);
  const preview = useSelector(fromInvoices
    .selectInvoicePreviewByFeature(organizationFeature));
  const [price, setPrice] = useState(monthlyPrice);

  // eslint-disable-next-line max-len
  const currentBillingCycle = currentSubscription?.billingCycle || currentSubscription?.billingInterval;
  const isNotUpgrading = currentSubscription
    && currentBillingCycle === chosenInterval
    && chosenQuota === 0;
  const hasPremierSuccessAddOn = currentSubscription?.hasPremierSuccessAddOn;

  const selectedStarterPackage = useSelector(fromStarterPackage.selectStarterPackage);

  const dispatch = useAppDispatch();

  const convertQuota = (chosenQuota: number, currentSubscription?: any) => (quotaConverter
    ? quotaConverter(chosenQuota, currentSubscription) : chosenQuota);

  const planId = (chosenInterval: TestOpsPlanInterval): string => getPlanId(
    organizationFeature,
    chosenInterval,
  );

  const doPreviewInvoice = useCallback((
    _chosenQuota,
    _chosenInterval,
    _isNotUpgrading,
    _currentSubscription,
    _maxQuota,
  ) => {
    if (invoicesLoading && featureInvoiceLoading) {
      return;
    }

    if (_chosenQuota >= 0
      && (_chosenInterval === TestOpsPlanInterval.MONTH
        || _chosenInterval === TestOpsPlanInterval.YEAR)) {
      // Not call preview API (and reset preview invoice) when:
      // 1. Addtional session > maximum session (also display warning message)
      // 2. Create new purchase: addtional session = 0
      // 3. Upgrading: additional session = 0 and chosen interval = current interval
      if (_chosenQuota > _maxQuota) {
        enqueueSnackbar(
          <FormattedMessage
            id="subscriptions.testcloud.upgrading.error.maximum_sessions_reached"
            values={{ maxSessions: _maxQuota }}
          />,
          {
            variant: 'error',
          },
        );
        clearInitialQuota(organizationFeature);
        dispatch(fromInvoices.doResetPreviewByFeature(organizationFeature));
      } else if ((!_currentSubscription && _chosenQuota === 0) || _isNotUpgrading) {
        clearInitialQuota(organizationFeature);
        dispatch(fromInvoices.doResetPreviewByFeature(organizationFeature));
      } else {
        const getPreviewInvoice = async () => {
          await dispatch(fromInvoices.doPreviewInvoice({
            organizationId: accountId,
            planId: planId(_chosenInterval),
            number: convertQuota(_chosenQuota, _currentSubscription),
            newQuotaNumber: _chosenQuota,
            billingCycle: _chosenInterval,
            // eslint-disable-next-line max-len
            ...(_currentSubscription && { subscriptionId: _currentSubscription.id, recurlySubscriptionUuid: _currentSubscription.recurlySubscription?.recurlySubscriptionUuid }),
          })).then(unwrapResult);
        };
        getPreviewInvoice().catch(() => {});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!invoicesLoading && !featureInvoiceLoading) {
      if (preview?.order?.newQuotaNumber !== undefined
        && !Number.isNaN(preview?.order?.newQuotaNumber)
        && (chosenQuota === undefined || preview?.order?.newQuotaNumber > Number(chosenQuota))) {
        setChosenQuota(Number(preview?.order?.newQuotaNumber));
      }
    }
  }, [preview]);

  useEffect(() => {
    if (!invoicesLoading && !featureInvoiceLoading) {
      if ((preview?.order?.newQuotaNumber === undefined
        || Number.isNaN(preview?.order?.newQuotaNumber))
        && ((chosenQuota === undefined)
          || (initialQuota !== undefined && Number(initialQuota) >= Number(chosenQuota)))) {
        setChosenQuota(initialQuota);
      }
    }
  }, [initialQuota]);

  useEffect(() => {
    if (chosenInterval === TestOpsPlanInterval.YEAR) {
      setPrice(annualyPrice);
    } else {
      setPrice(monthlyPrice);
    }

    if (currentSubscription?.expiryDate < (new Date().getTime())) {
      return;
    }

    doPreviewInvoice(
      chosenQuota,
      chosenInterval,
      isNotUpgrading,
      currentSubscription,
      maxQuota,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenQuota, chosenInterval]);

  useEffect(() => {
    if (initialInterval === TestOpsPlanInterval.YEAR
      || initialInterval === TestOpsPlanInterval.MONTH) {
      // for creating new order case
      setChosenInterval(initialInterval);
    }
  }, [initialInterval]);

  useEffect(() => {
    if (selectedStarterPackage?.listPlans.find(plan => plan.feature
          === organizationFeature) !== undefined) {
      setChosenInterval(TestOpsPlanInterval.YEAR);
    }
  }, [selectedStarterPackage]);

  const getPrice = (): number => {
    if (price) {
      return price;
    }

    if (priceFn) {
      return priceFn(Number(chosenQuota), chosenInterval);
    }

    return 0;
  };

  const handleRefreshVisualTesting = () => {
    const refreshVisual = async () => {
      await Promise.all([
        dispatch(fromVisualTestingSubscriptions.doGetActiveByAccountId({
          accountId: Number(accountId),
          checkHasPremierSuccess: true,
        })),
      ]);
    };
    refreshVisual().catch(() => {});
  };

  const onTrialVisualTestingClick = () => {
    try {
      setIsLoading(true);
      const createVisualTesting = async () => {
        await dispatch(
          fromSubscriptions
            .doCreateVisualTestingTrialRequest(Number(accountId)),
        )
          .then(unwrapResult)
          .then(() => {
            enqueueSnackbar(
              <FormattedMessage
                id="subscription.trial_visual_testing.pro.success"
              />,
              { variant: 'success' },
            );
            setTrialDisplayed(false);
            handleRefreshVisualTesting();
          });
      };
      createVisualTesting().catch(() => {});
    } finally {
      setIsLoading(false);
    }
  };

  const isMultipleYearsCycle = currentBillingCycle === TestOpsPlanInterval.TWO_YEARS
    || currentBillingCycle === TestOpsPlanInterval.THREE_YEARS;

  const isDisable = (invoicesLoading && featureInvoiceLoading)
    || currentBillingCycle === TestOpsPlanInterval.YEAR
    || (currentSubscription?.expiryDate < (new Date().getTime()))
    || (selectedStarterPackage?.listPlans?.find(plan => plan.feature
        === organizationFeature) !== undefined);

  const handleChangeQuota = (quota: number) => {
    setChosenQuota(quota);
    sendTrackingData(
      'single_product_license_input',
      accountId,
      undefined,
      {
        license_quantitty: quota,
        product: organizationFeature,
        plan: TestOpsPlanInterval.getPlanIntervalName(chosenInterval),
      },
    );
  };

  const handleChangeInterval = (interval: TestOpsPlanInterval) => {
    setChosenInterval(interval);
    sendTrackingData(
      'single_product_license_input',
      accountId,
      undefined,
      {
        license_quantitty: chosenQuota,
        product: organizationFeature,
        plan: TestOpsPlanInterval.getPlanIntervalName(interval),
      },
    );
  };

  return (
    <Paper className={classes.root}>
      <Grid container>
        <Grid item xs={5} className={classes.description}>
          {planNameComponent}
          {showPlanDescription && (
            <Box className={classes.planDescription}>
              <FormattedMessage id={`subscriptions.testops_platform.offering.${planName}_description`} />
            </Box>
          )}
          {trialDisplayed && (
            <Button
              size="small"
              variant="contained"
              color="primary"
              className={classes.subscribeBtn}
              onClick={onTrialVisualTestingClick}
            >
              <FormattedMessage id="subscriptions.visual.testing.pro.trial" />
            </Button>
          )}
        </Grid>
        <Grid item xs={2.5}>
          <QuotaInput
            initialQuota={chosenQuota}
            minQuota={minQuota}
            maxQuota={maxQuota}
            currentQuota={currentSubscription?.quota || 0}
            firstStep={firstStep}
            step={step}
            onQuotaChange={quota => handleChangeQuota(quota)}
            disabled={isMultipleYearsCycle}
          />
          {(currentSubscription?.quota || currentSubscription?.concurrentSessions) && (
            <div className={classes.infoCurrentContainer}>
              <FormattedMessage id="subscriptions.testops_platform.current_quota" />
              :
              <div className={classes.quotaInfo}>
                {currentSubscription?.quota || currentSubscription?.concurrentSessions}
              </div>
            </div>
          )}
        </Grid>
        <Grid item xs={2.5}>
          <Typography variant="body1">
            <FormattedMessage
              id="subscriptions.testops_platform.offering.unit_price"
              values={{
                price: getPrice().toLocaleString('en-US'),
                unit: intl.formatMessage({ id: unitName }),
                b: (chunk: string[]) => (<b>{chunk}</b>),
              }}
            />
          </Typography>
        </Grid>
        <Grid item xs={2} justifyContent="flex-end">
          <BillingCycle
            initialInterval={chosenInterval}
            onChangeInterval={interval => handleChangeInterval(interval)}
            disabled={isDisable || isMultipleYearsCycle}
          />
          <Box display="flex" justifyContent="flex-end">
            <Typography variant="h6" className={classes.price}>
              {
                (invoicesLoading && featureInvoiceLoading)
                  ? <CircularProgress size={20} />
                  : `$${intl.formatNumber(preview?.invoice?.subTotal || 0)}`
              }
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {hasPremierSuccessAddOn && (
        <Grid className={classes.premierSuccess}>
          <FormattedMessage id="subscriptions.premier.success.add.on.title" />
        </Grid>
      )}
      {isMultipleYearsCycle && (
        <Grid className={classes.errorMessage}>
          <FormattedMessage
            id="subscriptions.multiple.year.warning.title"
            values={{
              feature: intl.formatMessage({ id: `subscriptions.${getFeatureName(organizationFeature)}.title` }),
            }}
          />
        </Grid>
      )}
      {isLoading && <LoadingProgress />}
    </Paper>
  );
};

export default PlanPurchaseDetails;
