import Axios from 'axios';
import { DataResponse } from '../models';
import { TestOpsConfiguration } from '../models/testOpsConfiguration';

export const getConfiguration = async (name: string) => (await Axios.get<DataResponse<TestOpsConfiguration>>('/v1/testops/config', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: {
    name,
  },
  data: {},
})).data.data;
