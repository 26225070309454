import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  paper: {
    paddingTop: theme.spacing(7.5),
  },
  drawer: {
    border: 'none',
  },
  drawerOpen: {
    width: theme.spacing(37.5),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.complex,
    }),
    overflow: 'visible',
  },
  drawerClose: {
    width: theme.spacing(37.5),
    marginLeft: theme.spacing(-36),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.complex,
    }),
    overflow: 'visible',
  },
  sidebarCollapseButton: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    left: theme.spacing(36),
    top: theme.spacing(1.5),
    marginTop: theme.spacing(7.5),
    fontSize: theme.spacing(1.75),
    justifyContent: 'center',
    alignItems: 'center',
    color: '#656e8c',
    backgroundColor: '#ffffff',
    boxShadow: '0 2px 3px 0 rgba(193, 199, 205, 0.4)',
    position: 'absolute',
  },
  drawCloseSidebarCollapseButton: {
    '&.MuiButton-root': {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  menuItem: {
    marginLeft: theme.spacing(2),
    width: theme.spacing(33.5),
    height: theme.spacing(5),
    paddingBottom: theme.spacing(1),
  },
  sideTitle: {
    marginLeft: theme.spacing(2),
    width: theme.spacing(25),
  },
  sideSubTitle: {
    marginLeft: theme.spacing(2),
  },
  sideTitleContainer: {
    marginTop: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    width: '90%',
  },
  avatarWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(2),
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

const SkeletonSidebar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreenSize = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (isSmallScreenSize) {
      setOpen(false);
    }
  }, [isSmallScreenSize]);

  const handleSidebar = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  return (
    <Drawer
      variant="permanent"
      classes={{
        root: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
        paper: clsx(
          classes.paper,
          {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          },
        ),
        paperAnchorDockedLeft: classes.drawer,
      }}
    >
      <IconButton
        onClick={handleSidebar}
        className={clsx(classes.sidebarCollapseButton, {
          [classes.drawCloseSidebarCollapseButton]: !open,
        })}
        size="large"
      >
        {
          open
            ? <ChevronLeft />
            : <ChevronRight />
        }
      </IconButton>
      <Grid container alignItems="center" className={classes.sideTitleContainer}>
        <Grid item xs={2}>
          <Box className={classes.avatarWrapper}>
            <Skeleton variant="circular" className={classes.avatar} />
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Skeleton className={classes.sideTitle} />
          <Skeleton className={classes.sideSubTitle} />
        </Grid>
      </Grid>
      <Grid rowSpacing={1}>
        <Divider variant="middle" className={classes.divider} />
        <Skeleton className={classes.menuItem} />
        <Skeleton className={classes.menuItem} />
        <Skeleton className={classes.menuItem} />
        <Skeleton className={classes.menuItem} />
        <Skeleton className={classes.menuItem} />
      </Grid>
    </Drawer>
  );
};

export default SkeletonSidebar;
