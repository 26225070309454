import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import { useIntl, FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
  summarySlot: {
    color: '#172B4D',
    width: theme.spacing(20),
    paddingTop: theme.spacing(2.75),
  },
  summarySlotHeader: {
    fontSize: theme.spacing(1.5),
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
    color: '#516393',
    paddingBottom: theme.spacing(1),
    borderBottom: '1px solid #EEF3FA',
  },
  summaryContainer: {
    marginTop: theme.spacing(0.25),
  },
  summaryTitle: {
    fontWeight: theme.typography.fontWeightBold,
    color: '#516393',
  },
}));
interface SummaryProps {
  assignedSlots: number;
  quota: number;
  hasTestOpsSubscription: boolean;
}
const Summary = (props: SummaryProps) => {
  const { assignedSlots, quota, hasTestOpsSubscription } = props;
  const classes = useStyles();
  const intl = useIntl();

  return (
    <>
      <Typography variant="h4" className={classes.summaryTitle}>
        <FormattedMessage id="support_management.summary_title" />
      </Typography>
      <Grid item className={classes.summaryContainer}>
        <Grid container className={classes.summarySlotHeader}>
          <div className={classes.summarySlot}>
            {intl.formatMessage({ id: 'support_management.summary.quota' })}
          </div>
          <div className={classes.summarySlot}>
            {intl.formatMessage({ id: 'support_management.summary.available_slots' })}
          </div>
          <div className={classes.summarySlot}>
            {intl.formatMessage({ id: 'support_management.summary.assigned_slots' })}
          </div>
        </Grid>
        <Grid container>
          <div className={classes.summarySlot}>
            {quota}
          </div>
          <div className={classes.summarySlot}>
            {quota - assignedSlots > 0 ? quota - assignedSlots : 0}
          </div>
          <div className={classes.summarySlot}>
            {(quota > 0 || hasTestOpsSubscription) ? assignedSlots : 0}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Summary;
