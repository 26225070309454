import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useLaunchDarkly } from '../../launchdarkly';
import { testCloudSubscriptionManagement } from '../../layout/routes';
import { notFound, useQuery } from '../../routes';
import { fromOrganizations } from '../../store';

const SubscriptionManagement = () => {
  const { flags, currentAccountId } = useLaunchDarkly();
  const location = useLocation();
  const { get } = useQuery();
  const organizationId = Number(get('orgId'));
  const organization = useSelector(fromOrganizations.selectOrganizationById(organizationId));

  if (organization?.accountId === currentAccountId
    && flags && flags.subscriptionAndPaymentMethodDisabled === true) {
    return <Navigate to={notFound.path} replace />;
  }

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={(
            <Navigate
              to={{
                pathname: testCloudSubscriptionManagement.path,
                search: location.search,
              }}
              replace
            />
          )}
        />
        <Route
          path="*"
          element={(
            <Navigate
              to={{
                pathname: notFound.path,
              }}
              replace
            />
          )}
        />
      </Routes>
    </div>
  );
};

export default SubscriptionManagement;
