/**
 * Not in use, please see ../payment
 */
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Navigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import { notFound, useQuery } from '../../routes';
import BillingContact from './BillingContact';
import PaymentMethod from './PaymentMethod';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    borderRadius: '.5rem',
    padding: '1.5rem',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  section: {
    padding: '1.25rem 2.5rem',
    marginBottom: '1.25rem',
  },
  title: {
    marginTop: theme.spacing(2.5),
    paddingBottom: '1rem',
  },
  subTitle: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
}));

const BillingInformation = () => {
  const classes = useStyles();
  const orgId = useQuery().get('orgId');
  const intl = useIntl();

  useEffect(() => {
    analytics.page();
  }, []);

  if (!orgId) {
    return <Navigate to={notFound.path} replace />;
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Typography variant="h3" className={classes.title}>
          {intl.formatMessage({ id: 'billing.management' })}
        </Typography>
      </Grid>
      <Grid>
        <Typography variant="h5" className={classes.subTitle}>
          {intl.formatMessage({ id: 'billing.payment.information' })}
        </Typography>
      </Grid>
      <br />
      <Paper className={classes.section} elevation={0}>
        <Elements stripe={stripePromise}>
          <PaymentMethod organizationId={orgId} />
        </Elements>
      </Paper>
      <br />
      <Grid>
        <Typography variant="h5" className={classes.subTitle}>
          {intl.formatMessage({ id: 'billing.information' })}
        </Typography>
      </Grid>
      <br />
      <Paper className={classes.section} elevation={0}>
        <BillingContact organizationId={orgId} />
      </Paper>
    </div>
  );
};

export default BillingInformation;
