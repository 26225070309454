import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import {
  fromAccountUsers,
  fromAuth,
  fromOrganizationUsers,
  fromOrganizations,
  fromUserGroup,
  useAppDispatch,
} from '../../store';
import { useQuery } from '../../routes';
import { isOrganizationAdminOrOwner } from '../../models';
import CreateUserGroupPopup from './CreateUserGroupPopup';
import UserGroupTable, { UserGroupRow } from './UserGroupTable';
import LoadingProgress from '../../layout/LoadingProgress';
import { getAllUserGroups } from '../../store/userGroupSlice';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(4),
    height: '100%',
    width: '100%',
    borderRadius: '.5rem',
    overflowY: 'auto',
    minWidth: theme.spacing(96),
  },
  title: {
    fontWeight: 500,
    fontSize: theme.spacing(3),
  },
  paperRoot: {
    minWidth: theme.spacing(96),
    borderRadius: theme.spacing(1),
    display: 'flex',
  },
  boxScroll: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: theme.spacing(35),
    overflow: 'auto',
  },
  subWelcome: {
    fontSize: theme.spacing(3),
    fontWeight: theme.typography.fontWeightMedium,
    color: '#233145',
  },
  boxFooter: {
    alignItems: 'center',
  },
  editNameArea: {
    marginTop: theme.spacing(2.5),
    display: 'relative',
    color: '#233145',
    height: theme.spacing(5),
    width: '20%',
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
  },
  accountItem: {
    width: '80%',
  },
  titleButton: {
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
    backgroundColor: '#e7e9ef',
    color: '#22283c',
  },
  filterContainer: {
    margin: theme.spacing(2),
  },
  inputSearch: {
    fontSize: '0.875rem',
  },
  search: {
    marginRight: theme.spacing(0.5),
    width: theme.spacing(35),
    '& > .MuiInputBase-root': {
      borderRadius: theme.spacing(0.5),
      maxHeight: theme.spacing(4),
      paddingLeft: theme.spacing(1),
    },
    '& > * > .MuiInputBase-inputSizeSmall': {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
    },
  },
  searchIcon: {
    fontSize: theme.spacing(2.1),
    color: '#284169',
  },
}));

const UserGroup = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const orgId = Number(useQuery().get('orgId') ?? 0);

  const [isOpenCreateDialog, setIsOpenCreateDialog] = useState(false);

  const user = useSelector(fromAuth.selectUser);
  const organization = useSelector(fromOrganizations.selectOrganizationById(Number(orgId)));
  const currentOrgUser = useSelector(fromOrganizationUsers
    .selectByUserEmailAndOrganizationId(
      user?.email || '',
      (orgId),
    ));
  const currentAccountUser = useSelector(fromAccountUsers.selectOneByUserIdAndAccountId(
    Number(user?.id),
    Number(organization?.accountId),
  ));

  const organizationUsers = useSelector(fromOrganizationUsers.selectByOrganizationId(orgId));
  const userGroups = useSelector(fromUserGroup.selectAllUserGroupsByOrganizationId(orgId));
  const isLoadingUserGroups = useSelector(fromUserGroup.selectLoading(getAllUserGroups.typePrefix));

  const [fetchedOrgInfo, setFetchedOrgInfo] = useState(false);
  const [fetchedAccountInfo, setFetchedAccountInfo] = useState(false);

  useEffect(() => {
    if (!orgId) return;

    const fetchInfo = async () => {
      await Promise.all([
        dispatch(fromOrganizationUsers.doGetAllOrgUsers({ organizationId: Number(orgId) })),
        dispatch(fromUserGroup.getAllUserGroups({
          organizationId: orgId,
        })),
      ]);
      setFetchedOrgInfo(true);
    };
    fetchInfo();
  }, [orgId]);

  useEffect(() => {
    if (!organization) return;

    const fetchInfo = async () => {
      await Promise.all([
        dispatch(fromAccountUsers
          .doGetByUserIdAndAccountId({
            userId: user!!.id,
            accountId: organization.accountId ?? 0,
          })),
      ]);
      setFetchedAccountInfo(true);
    };
    fetchInfo();
  }, [organization]);

  const userGroupRows: UserGroupRow[] = useMemo(() => (
    userGroups.map(ug => {
      const { id, name, description, totalUsers, createdAt } = ug;

      return {
        id,
        groupName: name,
        description,
        totalUsers,
        createdAt,
      };
    })
  ), [userGroups]);

  const handleRefreshData = () => {
    dispatch(fromUserGroup.getAllUserGroups({
      organizationId: orgId,
    }));
  };

  if (!fetchedOrgInfo || !fetchedAccountInfo) return <LoadingProgress />;

  return (
    <div className={classes.root}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <Typography id="user.group.page.header" variant="h2" className={classes.title}>
            <FormattedMessage id="user.group.page.header" />
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2} justifyContent="flex-end">
            { currentOrgUser
              && isOrganizationAdminOrOwner(currentOrgUser.role)
              && (
              <Grid item>
                <Button
                  id="user.group.create.new.group"
                  size="small"
                  onClick={() => setIsOpenCreateDialog(true)}
                  variant="contained"
                  className={classes.titleButton}
                >
                  {intl.formatMessage({ id: 'user.group.create.new.group' })}
                </Button>
                {isOpenCreateDialog && (
                  <CreateUserGroupPopup
                    orgId={orgId}
                    isOpen={isOpenCreateDialog}
                    orgUsers={organizationUsers}
                    onCloseDialog={() => setIsOpenCreateDialog(false)}
                    onSuccessCallback={handleRefreshData}
                    currentOrgUser={currentOrgUser}
                  />
                )}
              </Grid>
              )}
          </Grid>
        </Grid>
        <UserGroupTable
          dataTable={userGroupRows}
          orgId={orgId}
          currentOrgUser={currentOrgUser}
          currentAccountUser={currentAccountUser}
        />
      </Grid>
      { isLoadingUserGroups && <LoadingProgress /> }
    </div>
  );
};

export default UserGroup;
