import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { useConfig } from '../../config';
import LoadingProgress from '../../layout/LoadingProgress';
import { activateLicense, signUpOnPremise, useQuery } from '../../routes';
import { TrackService } from '../../services';
import { fromAccountUsers, fromAuth, fromUsers, useAppDispatch } from '../../store';
import { fromActiveLicense } from '../../store/rootReducer';
import ForgotPassword from './components/ForgotPassword';
import { ReactComponent as Banner } from './components/katalon_login_banner.svg';
import { ReactComponent as Logo } from './components/katalon_new_logo.svg';
import ResetPassword from './components/ResetPassword';

const KATALON_POLICY = 'https://www.katalon.com/terms/#testops-agreement';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      height: '200vh',
      '& > *': {
        width: '100vw',
        height: '100vh',
      },
    },
    '& .MuiCardHeader-title': {
      fontSize: theme.spacing(3),
      fontWeight: theme.typography.fontWeightMedium,
      color: '#0B1F3F',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: theme.spacing(1.75),
      fontWeight: 'normal',
    },
    '& .Mui-focused': {
      color: '#5959eb',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#5959eb',
      },
    },
    '& .MuiOutlinedInput-input': {
      fontSize: theme.spacing(2.25),
      fontWeight: theme.typography.fontWeightRegular,
      color: 'black',
    },
    '& .MuiOutlinedInput-root': {
      height: theme.spacing(7.363),
      width: theme.spacing(65.425),
      '::placeholder': {
        fontSize: theme.spacing(2),
        verticalAlign: 'middle',
      },
    },
  },
  banner: {
    flex: '0 0 auto',
    backgroundSize: 'cover',
    position: 'relative',
    [theme.breakpoints.between('sm', 'lg')]: {
      display: 'none',
    },
    '& > svg': {
      height: '100vh',
      width: 'auto',
    },
  },
  page: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 500,
  },
  cardContainer: {
    flex: '1 1 auto',
    display: 'flex',
  },
  card: {
    margin: 'auto',
    width: '100%',
    minWidth: 300,
    maxWidth: '35vw',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '70vw',
    },
  },
  logo: {
    maxWidth: '100%',
    textAlign: 'left',
    padding: theme.spacing(2),
    maxHeight: theme.spacing(6),
    marginBottom: theme.spacing(5),
  },
  katalonPolicy: {
    maxWidth: theme.spacing(65),
    fontFamily: 'Inter',
    fontWeight: 'normal',
    fontSize: theme.spacing(1.75),
    color: '#6a6a6a',
  },
  policyLink: {
    color: '#5959eb',
  },
  header: {
    marginTop: 20,
    marginLeft: 'auto',
    marginRight: theme.spacing(4),
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    '& > *': {
      marginLeft: theme.spacing(2),
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > *:not(:first-child)': {
      marginTop: theme.spacing(2),
    },
    '& .MuiFormControlLabel-root > .MuiButtonBase-root': {
      borderColor: '#d6d6d6',
    },
  },
  action: {
    padding: theme.spacing(2),
    color: '#6A6A6A',
  },
  error: {
    color: '#D14343',
    margin: 0,
    fontWeight: 700,
    fontSize: theme.spacing(1.75),
  },
  text: {
    color: '#fff',
  },
  buttonText: {
    backgroundColor: '#0F8461',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#0C6A4E',
      color: '#FFFFFF',
    },
  },
  buttonSignIn: {
    width: theme.spacing(16.25),
    height: theme.spacing(5),
    fontSize: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
  },
  buttonSignUp: {
    width: theme.spacing(16.25),
    height: theme.spacing(5),
    fontSize: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
  },
  warningWrapper: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    backgroundColor: '#FFFAE6',
  },
  warningMessage: {
    color: '#FA8C16',
  },
  statusLink: {
    textDecoration: 'none',
  },
  titleNoAccount: {
    color: '#09223B',
    fontWeight: 'normal',
    fontFamily: 'Inter',
    fontSize: theme.spacing(2),
  },
  titleSignUp: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(2),
  },
  loginTextfield: {
    '& > * > .MuiOutlinedInput-input': {
      fontSize: theme.spacing(2),
    },
  },
}));

interface LoginFormInputs {
  email: string;
  password: string;
  rememberMe: boolean;
}

const LoginComponent = () => {
  const classes = useStyles();
  const { config } = useConfig();
  const { get, clear } = useQuery();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState(true);
  const loading = useSelector(fromAuth.selectLoading);
  const isAuthenticated = useSelector(fromAuth.selectIsAuthenticated);
  const isUnauthorized = useSelector(fromAuth.selectIsUnauthorized);
  const activated = useSelector(fromActiveLicense.selectActivated);
  const adminCreated = useSelector(fromUsers.selectIsAdminCreated);
  const user = useSelector(fromAuth.selectUser);
  const [loadingData, setLoadingData] = useState(true);
  const anonymousId = TrackService.getAnonymousId();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInputs>({
    defaultValues: { email: '', password: '', rememberMe: true },
  });
  const redirectRef = useRef(get('redirect') ?? '/');
  const view = get('view');
  const resetPasswordCode = get('reset_password_code');
  const [newLogin, setNewLogin] = useState(Boolean(get('new_login')));
  const contextRef = useRef(get('context') ?? '');
  const errorRef = useRef(get('error'));

  useEffect(() => {
    analytics.page();
  }, []);

  useEffect(() => {
    setLoadingData(true);
    if (!config?.onpremise) {
      return;
    }
    const fetchDataInfo = async () => {
      await Promise.all([
        dispatch(fromActiveLicense.doGetActivated()),
        dispatch(fromUsers.doCheckAdminCreated()),
      ]);
      setLoadingData(false);
    };
    // eslint-disable-next-line no-console
    fetchDataInfo().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  useEffect(() => {
    doUniversalLogin();
  }, [config, resetPasswordCode]);

  const handleClickRememberMe = () => {
    setSelected(!selected);
  };

  const doLogin = async ({ email, password, rememberMe }: LoginFormInputs) => {
    const formattedEmail = email.toLowerCase();
    await dispatch(fromAuth.doLogin({ userInfo: { email: formattedEmail, password }, rememberMe }));
    analytics.track(
      'User Signed In',
      {
        user_email: formattedEmail,
        category: 'User acquisition',
        event_text: 'User Signed In',
        label: '',
        referer_page: document.referrer,
        value: '',
      },
      {
        context: {
          page: {
            path: window.location.pathname,
            title: document.title,
            url: window.location.href,
            referrer: document.referrer,
            search: window.location.search,
          },
        },
      },
    );
  };

  const doUniversalLogin = async () => {
    if (config?.iamEnabled
        && view !== 'forgot_password'
        && !(resetPasswordCode && config.onpremise)
        && (!isAuthenticated || newLogin)) {
      if (contextRef.current === 'LOGIN') {
        const authCode = get('code');
        if (authCode) {
          clear('code');
          clear('context');
          clear('state');
          clear('session_state');
          const redirectUri = getRedirectUri();
          dispatch(fromAuth.doExchangeToken({
            code: authCode,
            redirectUri,
          }))
            .then(unwrapResult)
            .then(data => {
              const { token } = data;
              dispatch(fromAuth.doResume(token)).then(() => {
                setNewLogin(false);
              });
            })
            .catch(() => {
              dispatch(fromAuth.doLogout());
            });
        }
        // Must be sure that after this step, the authentication is completed
        return;
      }
      if (errorRef.current) {
        await dispatch(fromAuth.doLogout());
        return;
      }
      if (config) {
        const uri = new URL(`${config?.keyCloakUrl}/realms/${config?.keyCloakRealmId}/protocol/openid-connect/auth`);
        uri.searchParams.set('client_id', config?.keyCloakClientId ?? '');
        uri.searchParams.set('response_mode', 'query');
        uri.searchParams.set('response_type', 'code');
        uri.searchParams.set('scope', 'openid');
        uri.searchParams.set('redirect_uri', getRedirectUri());
        if (config.onpremise) {
          uri.searchParams.set('fqdn', getRedirectUri());
        }
        window.location.replace(uri.toString());
      }
    }
  };

  const getRedirectUri = () => {
    const uri = new URL(`${window.location.origin}${process.env.PUBLIC_URL}/login`);
    uri.searchParams.set('redirect', redirectRef.current);
    if (newLogin) {
      uri.searchParams.set('new_login', 'true');
    }
    return uri.toString();
  };

  useEffect(() => {
    if (user) {
      dispatch(
        fromAuth.doGetSurvey({
          email: user.email,
          lastName: user.lastName || '',
          firstName: user.firstName || '',
          id: user.id,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (user) {
      dispatch(fromAccountUsers.doGetAccountUsersByUserId({ userId: user.id }))
        .then(data => {
          // @ts-ignore
          data.payload.forEach(item => localStorage.setItem(`skip-reach-quota-accId-${item.accountId}`, '0'));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const checkNavigateForgotPasswordKeyCloak = () => (view === 'forgot_password' && config && !config.onpremise);

  if (!config) return <></>;

  if (config?.onpremise && !loadingData) {
    if (!activated) {
      return <Navigate to={activateLicense.path} replace />;
    }
    if (!adminCreated) {
      return <Navigate to={signUpOnPremise.path} replace />;
    }
  }

  if (isAuthenticated) {
    // do logout if user access forgot password page and user is logged-in
    if (view === 'forgot_password') {
      if (!config?.onpremise) {
        const uri = new URL(`${config?.keyCloakUrl}/realms/${config?.keyCloakRealmId}/login-actions/reset-credentials?client_id=${config?.keyCloakClientId}`);
        window.location.replace(uri.toString());
      }
      dispatch(fromAuth.doLogout());
      return <></>;
    }
    if (newLogin) {
      return <></>;
    }
    return <Navigate to={redirectRef.current} replace />;
    // if we have a queryParam called orgId and user is authenticated,
    // direct to Detail page of that org.
  }

  if (checkNavigateForgotPasswordKeyCloak()) {
    const uri = new URL(`${config?.keyCloakUrl}/realms/${config?.keyCloakRealmId}/login-actions/reset-credentials?client_id=${config?.keyCloakClientId}`);
    window.location.replace(uri.toString());
    return <></>;
  }

  if (config?.iamEnabled && !resetPasswordCode) return <></>;

  return (
    <>
      {!resetPasswordCode && (
        <div className={classes.root}>
          <div className={classes.banner}>
            <Banner />
          </div>
          <div className={classes.page}>
            {!config.onpremise && (
              <div className={classes.header}>
                <Typography className={classes.titleNoAccount}>
                  <FormattedMessage id="login.header.noaccount" />
                </Typography>
                <Button
                  className={`${classes.buttonText} ${classes.buttonSignUp}`}
                  variant="outlined"
                  size="large"
                  href={
                    anonymousId
                      ? `${process.env.REACT_APP_WEBSITE_URL}/sign-up?anonymous_id=${anonymousId}`
                      : `${process.env.REACT_APP_WEBSITE_URL}/sign-up`
                  }
                >
                  <Typography className={classes.titleSignUp}>
                    <FormattedMessage id="login.header.signup" />
                  </Typography>
                </Button>
              </div>
            )}
            <div className={classes.cardContainer}>
              <Box className={classes.card}>
                <div className={classes.logo}>
                  <Logo />
                </div>
                <CardHeader title={<FormattedMessage id="login.form.title" />} />
                <CardContent>
                  <form className={classes.form} onSubmit={handleSubmit(doLogin)}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      required
                      type="email"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      className={classes.loginTextfield}
                      label={<FormattedMessage id="login.form.email" />}
                      inputProps={{
                        ...register('email', { required: 'error.required' }),
                        style: {
                          border: '#FFFFFF',
                        },
                      }}
                      error={!!errors.email}
                    />
                    <TextField
                      variant="outlined"
                      fullWidth
                      required
                      type="password"
                      name="password"
                      autoComplete="current-password"
                      className={classes.loginTextfield}
                      label={<FormattedMessage id="login.form.password" />}
                      inputProps={{ ...register('password', { required: 'error.required' }) }}
                      error={!!errors.password}
                    />
                    <Typography className={classes.katalonPolicy}>
                      {intl.formatMessage(
                        { id: 'login.katalon.policy' },
                        {
                          link: (
                            <Link className={classes.policyLink} underline="always" href={KATALON_POLICY}>
                              <FormattedMessage id="login.policy.link" />
                            </Link>
                          ),
                        },
                      )}
                    </Typography>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          color="primary"
                          name="rememberMe"
                          inputProps={{ ...register('rememberMe') }}
                          checked={selected}
                          onClick={handleClickRememberMe}
                        />
                      )}
                      label={<FormattedMessage id="login.form.remember" />}
                    />
                    <Button variant="contained" size="large" className={`${classes.buttonText} ${classes.buttonSignIn}`} type="submit">
                      <FormattedMessage id="login.form.login" />
                    </Button>
                    {isUnauthorized && (
                      <Typography className={classes.error} variant="caption">
                        {intl.formatMessage({ id: 'error.invalid_username_password' })}
                      </Typography>
                    )}
                    {errorRef.current && (
                      <Typography className={classes.error} variant="caption">
                        {intl.formatMessage({ id: 'error.invalid_username_password' })}
                      </Typography>
                    )}
                  </form>
                  <Divider sx={{ mt: 4 }} />
                </CardContent>
                <CardActions disableSpacing classes={{ root: classes.action }}>
                  <ForgotPassword />
                </CardActions>
                {(config.maintenanceStart ?? 0) > 0 && (
                  <div className={classes.warningWrapper}>
                    <Typography className={classes.warningMessage} variant="caption">
                      <FormattedMessage
                        id="message.system_maintenance_mode_warning"
                        values={{
                          maintenanceDate: new Intl.DateTimeFormat(
                            'default',
                            {
                              year: 'numeric', month: 'short', day: '2-digit',
                            },
                          ).format(config.maintenanceStart),
                          maintenanceTime: new Intl.DateTimeFormat(
                            'default',
                            {
                              hour: '2-digit', minute: '2-digit', timeZoneName: 'short',
                            },
                          ).format(config.maintenanceStart),
                          maintenanceDateGMT: new Intl.DateTimeFormat(
                            'default',
                            {
                              year: 'numeric', month: 'short', day: '2-digit', timeZone: 'UTC',
                            },
                          ).format(config.maintenanceStart),
                          maintenanceTimeGMT: new Intl.DateTimeFormat(
                            'default',
                            {
                              hour: '2-digit', minute: '2-digit', timeZone: 'UTC', timeZoneName: 'short',
                            },
                          ).format(config.maintenanceStart),
                          statusLink: (
                            <a href="https://status.katalon.com" target="_blank" rel="noreferrer" className={classes.statusLink}>
                              {intl.formatMessage({ id: 'message.system_maintenance_mode_warning.this_page' })}
                            </a>
                          ),
                        }}
                      />
                    </Typography>
                  </div>
                )}
              </Box>
            </div>
          </div>
        </div>
      )}
      {loading && <LoadingProgress />}
      <ResetPassword />
    </>
  );
};

export default LoginComponent;
