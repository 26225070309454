import { createAsyncThunk, createSelector, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { AppState } from '.';
import { exceptionOf, Organization } from '../models';
import { Operator, Query } from '../models/query';
import { UserGroupService } from '../services';
import { UserGroup } from '../models/userGroup';

export const USER_GROUP_FEATURE_KEY = 'userGroup';

interface UserGroupState extends EntityState<UserGroup> {
  loading: { [key: string]: boolean };
  error?: string;
}

const userGroupAdapter = createEntityAdapter<UserGroup>();

export const createInitialState = (): UserGroupState => (
  userGroupAdapter.getInitialState({
    loading: {},
  })
);

export const doCreateUserGroup = createAsyncThunk(
  'userGroup/doCreateUserGroup',
  async (
    input: { organizationId: number, accountId: number, name: string, description: string },
    { rejectWithValue },
  ) => {
    try {
      const userGroup = await UserGroupService
        .createUserGroup({
          name: input.name,
          description: input.description,
          organizationId: input.organizationId,
          accountId: input.accountId,
        });
      return { userGroupId: userGroup.id, data: userGroup };
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doCreateUserGroupUser = createAsyncThunk(
  'userGroup/doCreateUserGroupUser',
  async (
    input: { userGroupId: number, userEmail: string },
    { rejectWithValue },
  ) => {
    try {
      const userGroupUser = await UserGroupService
        .inviteUserToGroup({
          userGroupId: input.userGroupId,
          userEmail: input.userEmail,
        });
      return { userGroupId: userGroupUser.id, data: userGroupUser };
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const getAllUserGroups = createAsyncThunk(
  'userGroup/getAllUserGroup',
  async (input: Partial<Pick<UserGroup, 'organizationId'>> & Partial<Pick<UserGroup, 'accountId'>>) => {
    let criteria: Array<Query<UserGroup>> = [
      {
        field: 'organizationId',
        operator: Operator.EQ,
        value: input.organizationId,
      },
    ];

    if (input.accountId) {
      criteria = [
        {
          field: 'accountId',
          operator: Operator.EQ,
          value: input.accountId,
        },
      ];
    }

    const response = await UserGroupService.getAllUserGroups(...criteria);

    return { data: response.data, total: response.total };
  },
);

export const doDeleteUserGroup = createAsyncThunk(
  'userGroup/deleteUserGroup',
  async (input: Required<Pick<UserGroup, 'id'>>) => {
    await UserGroupService.deleteUserGroup(input);
    return { userGroup: input };
  },
);

export const doUpdateUserGroup = createAsyncThunk(
  'userGroup/doUpdateUserGroup',
  async (
    input: { id: number, name: string, description: string },
    { rejectWithValue },
  ) => {
    try {
      const userGroup = await UserGroupService
        .updateUserGroup(
          input.id,
          {
            name: input.name,
            description: input.description,
          },
        );
      return { data: userGroup };
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

const userGroupSlice = createSlice({
  name: USER_GROUP_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAllUserGroups.pending, state => {
      state.loading[getAllUserGroups.typePrefix] = true;
    });
    builder.addCase(getAllUserGroups.fulfilled, (state, action) => {
      state.loading[getAllUserGroups.typePrefix] = false;
      userGroupAdapter.setAll(state, action.payload.data);
    });
    builder.addCase(getAllUserGroups.rejected, state => {
      state.loading[getAllUserGroups.typePrefix] = false;
    });
    // Delete an user group
    builder.addCase(doDeleteUserGroup.pending, state => {
      state.loading[doDeleteUserGroup.typePrefix] = true;
    });
    builder.addCase(doDeleteUserGroup.fulfilled, (state, action) => {
      userGroupAdapter.removeOne(state, action.payload.userGroup.id);
      state.loading[doDeleteUserGroup.typePrefix] = false;
    });
    builder.addCase(doDeleteUserGroup.rejected, (state, action) => {
      state.loading[doDeleteUserGroup.typePrefix] = false;
      state.error = action.error.message;
    });
    // Update an User Group
    builder.addCase(doUpdateUserGroup.pending, state => {
      state.loading[doUpdateUserGroup.typePrefix] = true;
    });
    builder.addCase(doUpdateUserGroup.fulfilled, (state, action) => {
      state.loading[doUpdateUserGroup.typePrefix] = false;
      userGroupAdapter.updateOne(state, {
        id: action.payload.data.id,
        changes: action.payload.data,
      });
    });
  },
});

const selectUserGroups = (state: AppState) => state[USER_GROUP_FEATURE_KEY];

export const {
  selectAll: selectAllUserGroups,
  selectEntities,
} = userGroupAdapter.getSelectors(selectUserGroups);

export const selectAllUserGroupsByOrganizationId = (organizationId: Organization['id']) => createSelector(
  selectAllUserGroups,
  userGroups => userGroups.filter(userGroup => userGroup.organizationId === organizationId),
);

export const selectAllUserGroupsByAccountId = (accountId: number) => createSelector(
  selectAllUserGroups,
  userGroups => userGroups.filter(userGroup => userGroup.accountId === accountId),
);

export const selectUserGroupsById = (id: UserGroup['id']) => createSelector(
  selectAllUserGroups,
  userGroups => userGroups.filter(userGroup => userGroup.id === id)[0],
);

export const selectLoading = (prefix: string) => createSelector(
  selectUserGroups,
  state => state.loading[prefix],
);
export default userGroupSlice.reducer;
