import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import { useState } from 'react';
import TotalLicenseDetail from './TotalLicenseDetail';
import { AccountUser } from '../../../../models/accountUser';
import KseLicenseAllocationTable from '../tab-child/kse/KseLicenseAllocationTable';
import { LicenseAllocationTab } from '../../model/LicenseAllocationTab';
import KreLicenseAllocationTable from '../tab-child/kre/KreLicenseAllocationTable';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    color: '#233145',
  },
  title: {
    paddingTop: 0,
    fontSize: theme.spacing(2.5),
    fontWeight: theme.typography.fontWeightMedium,
  },
  subTitle: {
    color: '#808b9a',
    fontSize: theme.spacing(1.5),
  },
  inputSearch: {
    fontSize: theme.spacing(1.75),
  },
  defaultIcon: {
    fontSize: theme.spacing(1.75),
    color: 'rgba(104, 109, 128, 0.54)',
  },
  search: {
    width: theme.spacing(40),
    '& > .MuiInputBase-root': {
      borderRadius: theme.spacing(0.5),
      borderColor: '#dbdde5',
      paddingLeft: theme.spacing(1.5),
      backgroundColor: 'rgb(255, 255, 255)',
    },
    '& > * > .MuiInputBase-inputSizeSmall': {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      '&::placeholder': {
        color: '#808b9a',
      },
    },
  },
}));

export interface OrgLicenseData {
  orgName: string;
  id: number;
}

export interface Column {
  minWidth?: number;
  width?: number;
  align?: 'right';
  actionHeader?: boolean;
}

interface LicenseDetail {
  title: string,
  total: number,
  available: number,
}

interface BaseLicenseAllocationDetailProps {
  licenseDetails: LicenseDetail[],
  currentAccountUser: AccountUser | undefined,
  licenseAllocationTab: LicenseAllocationTab,

  // KSE
  adjustAllocatedKseLicense?: (count: number) => void,
  adjustAllocatedHybridKseLicense?: (count: number) => void,

  // KRE
  adjustAllocatedKreLicense?: (count: number) => void,
  adjustAllocatedHybridKreLicense?: (count: number) => void,
}

const BaseLicenseAllocationDetail = (props: BaseLicenseAllocationDetailProps) => {
  const {
    licenseDetails,
    currentAccountUser,
    licenseAllocationTab,
    adjustAllocatedKseLicense,
    adjustAllocatedHybridKseLicense,
    adjustAllocatedKreLicense,
    adjustAllocatedHybridKreLicense,
  } = props;

  const classes = useStyles();
  const intl = useIntl();

  const [lastUpdated, setLastUpdated] = useState<Date>();
  const [updatedBy, setUpdatedBy] = useState('');
  const [isAllocatedLoaded, setIsAllocatedLoaded] = useState(false);

  const setLoadedAllocated = () => setIsAllocatedLoaded(true);

  return (
    <Grid className={classes.root} container spacing={2}>

      {/* Title */}
      <Grid item xs={12}>
        <Typography id="licenses.allocation.tab.summary" className={classes.title}>
          <FormattedMessage id="licenses.allocation.tab.summary" />
        </Typography>
        <Typography id="licenses.allocation.tab.last_updated" className={classes.subTitle}>
          {intl.formatMessage(
            { id: 'licenses.allocation.tab.last_updated' },
            {
              last_updated: moment(lastUpdated).fromNow(),
              updated_by: updatedBy,
            },
          )}
        </Typography>
      </Grid>

      {/* Card */}
      {licenseDetails.filter(detail => detail.total > 0).map(
        value => (
          <TotalLicenseDetail
            title={value.title}
            total={value.total}
            available={value.available}
            isAllocatedLoaded={isAllocatedLoaded}
          />
        ),
      )}

      {/* Allocation by Org title */}
      <Grid item xs={12}>
        <Typography id="licenses.allocation.tab.allocation_by_org.title" className={classes.title}>
          <FormattedMessage id="licenses.allocation.tab.allocation_by_org.title" />
        </Typography>
      </Grid>

      {/* Table */}
      <Grid item xs={12}>
        {licenseAllocationTab === LicenseAllocationTab.KSE && (
          <KseLicenseAllocationTable
            currentAccountUser={currentAccountUser}
            adjustAllocatedKseLicense={adjustAllocatedKseLicense}
            adjustAllocatedHybridKseLicense={adjustAllocatedHybridKseLicense}
            setLastUpdated={setLastUpdated}
            setUpdatedBy={setUpdatedBy}
            setLoadedAllocated={setLoadedAllocated}
          />
        )}
        {licenseAllocationTab === LicenseAllocationTab.KRE && (
          <KreLicenseAllocationTable
            currentAccountUser={currentAccountUser}
            adjustAllocatedKreLicense={adjustAllocatedKreLicense}
            adjustAllocatedHybridKreLicense={adjustAllocatedHybridKreLicense}
            setLastUpdated={setLastUpdated}
            setUpdatedBy={setUpdatedBy}
            setLoadedAllocated={setLoadedAllocated}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default BaseLicenseAllocationDetail;
