import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { orgCardView } from '../../layout/routes';
import { AccountRole, isManager } from '../../models';
import { resolvePath } from '../../routes';
import { fromAccounts, fromTestOpsProject, useAppDispatch } from '../../store';
import { ReactComponent as OrganizationIcon } from '../icons/project-diagram.svg';
import { ReactComponent as ChartAreaIcon } from './chart-area.svg';

interface AccountItemProps {
  id: number;
  viewedAs: AccountRole;
}

const Avatar = styled('img')(({ theme }) => ({
  width: theme.spacing(6),
  height: theme.spacing(6),
  borderRadius: '50%',
}));

const Bold = styled('span')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}));

const useStyles = makeStyles(theme => ({
  chartArea: {
    textAlign: 'left',
    width: '24px',
    height: '24px',
    flexGrow: 0,
    fontSize: '16px',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#808b9a',
  },
  accountName: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(2),
    color: '#233145',
    wordWrap: 'break-word',
  },
  accountItem: {
    display: 'flex',
    marginLeft: theme.spacing(-0.5),
  },
  accountItemBox: {
    maxWidth: theme.spacing(80),
  },
  itemLabel: {
    fontSize: theme.spacing(2),
    fontWeight: 'normal',
  },
  itemValue: {
    fontWeight: 600,
    fontSize: theme.spacing(2),
  },
  navigateIcon: {
    color: '#233145',
  },
}));

const AccountItem = (props: AccountItemProps) => {
  const { id, viewedAs } = props;
  const account = useSelector(fromAccounts.selectAccountById(id))!!;
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([
        dispatch(fromAccounts.doCountMembersOfAccount({ id })),
        dispatch(fromAccounts.doCountOrganizationsOfAccount({ id })),
        dispatch(fromTestOpsProject.doCountProjectsByAccountId({ accountId: id })),
      ]);
      setLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box display="flex" padding={2} borderBottom="1px solid #EEF1FA" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center" width="100%">
        <Avatar src="https://katalon-testops.s3.amazonaws.com/image/icon/defaultAvatar.png" alt="avatar" />
        <Box width="100%" paddingX={2}>
          <Box className={classes.accountItemBox}>
            <Typography className={classes.accountName} variant="subtitle2">
              {account.name}
            </Typography>
          </Box>
          <Box className={classes.accountItem}>
            {isManager(viewedAs) && (
            <Box display="flex" paddingY={1} width="25%" alignItems="center">
              <PersonOutlineOutlinedIcon color="disabled" />
              <Typography className={classes.itemLabel} ml={0.5} variant="subtitle2">
                <FormattedMessage id="welcome.account.members" />
                &nbsp;
                {account.numberAccountUsers !== undefined
                  ? (
                    <Bold className={classes.itemValue}>{account.numberAccountUsers}</Bold>
                  ) : (
                    <Skeleton width={30} sx={{ display: 'inline-block' }} />
                  )}
              </Typography>
            </Box>
            )}
            {isManager(viewedAs) && (
            <Box display="flex" paddingY={1} width="30%" alignItems="center">
              <OrganizationIcon color="disabled" />
              <Typography className={classes.itemLabel} ml={0.5} variant="subtitle2">
                <FormattedMessage id="welcome.account.organizations" />
                &nbsp;
                {account.numberOrganizations !== undefined
                  ? (
                    <Bold className={classes.itemValue}>{account.numberOrganizations}</Bold>
                  ) : (
                    <Skeleton width={30} sx={{ display: 'inline-block' }} />
                  )}
              </Typography>
            </Box>
            )}
            {isManager(viewedAs) && (
            <Box display="flex" paddingY={1} width="25%" alignItems="center">
              <ChartAreaIcon className={classes.chartArea} />
              <Typography className={classes.itemLabel} ml={0.5} variant="subtitle2">
                <FormattedMessage id="welcome.account.projects" />
                &nbsp;
                {account.numberProjects !== undefined
                  ? (
                    <Bold className={classes.itemValue}>{account.numberProjects}</Bold>
                  ) : (
                    <Skeleton width={30} sx={{ display: 'inline-block' }} />
                  )}
              </Typography>
            </Box>
            )}

            <Box display="flex" paddingY={1} width="20%" alignItems="center">
              <Typography variant="subtitle2">
                <FormattedMessage id="welcome.account.organization.id" />
                <Bold className={classes.itemValue}>{id}</Bold>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {!loading && (
        <Link
          component={RouterLink}
          to={resolvePath(orgCardView, undefined, { accountId: id }, false)}
          underline="none"
        >
          <NavigateNextIcon
            id="welcome.account.item.navigation.icon"
            className={classes.navigateIcon}
          />
        </Link>
      )}
    </Box>
  );
};

export default AccountItem;
