import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { AppState } from '.';
import { exceptionOf, Organization, SerializedException, TestOpsPaymentMethod } from '../models';
import { Operator } from '../models/query';
import { TestOpsPaymentMethodService } from '../services';

export const TESTOPS_PAYMENT_METHOD_FEATURE_KEY = 'testOpsPaymentMethod';

interface PaymentMethodState {
  paymentMethod: TestOpsPaymentMethod | null;
  loading: boolean;
  error?: string;
  selectedId: TestOpsPaymentMethod['id'] | null;
}

export const createInitialState = (): PaymentMethodState => ({
  paymentMethod: null,
  loading: false,
  selectedId: null,
});

export const doCreatePaymentMethod = createAsyncThunk(
  'testOpsPaymentMethod/createPaymentMethod',
  async (input: Parameters<typeof TestOpsPaymentMethodService['createPaymentMethod']>[0], { rejectWithValue }) => {
    try {
      const paymentMethod = await TestOpsPaymentMethodService.createPaymentMethod(input);
      return paymentMethod;
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doGetPaymentMethod = createAsyncThunk(
  'testOpsPaymentMethod/getPaymentMethod',
  async (organizationId: Organization['id'], { rejectWithValue }) => {
    try {
      const paymentMethod = await TestOpsPaymentMethodService.getPaymentMethods({ field: 'organizationId', operator: Operator.EQ, value: organizationId.toString() });

      if (paymentMethod.length === 0) {
        throw new Error();
      }

      return paymentMethod;
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doUpdatePaymentMethod = createAsyncThunk(
  'testOpsPaymentMethod/updatePaymentMethod',
  async (input: Parameters<typeof TestOpsPaymentMethodService['updatePaymentMethod']>[0], { rejectWithValue }) => {
    try {
      const paymentMethod = await TestOpsPaymentMethodService.updatePaymentMethod(input);
      return paymentMethod;
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

const testOpsPaymentMethodSlice = createSlice({
  name: TESTOPS_PAYMENT_METHOD_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doCreatePaymentMethod.pending, state => {
      state.loading = true;
    });
    builder.addCase(doCreatePaymentMethod.fulfilled, (state, action) => {
      state.loading = false;
      state.paymentMethod = action.payload;
      state.selectedId = action.payload.id;
      state.error = undefined;
    });
    builder.addCase(doCreatePaymentMethod.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as SerializedException)?.message;
    });
    builder.addCase(doGetPaymentMethod.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetPaymentMethod.fulfilled, (state, action) => {
      state.loading = false;
      [state.paymentMethod] = action.payload;
      state.selectedId = action.payload[0].id;
      state.error = undefined;
    });
    builder.addCase(doGetPaymentMethod.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as SerializedException)?.message;
      state.paymentMethod = null;
    });
    builder.addCase(doUpdatePaymentMethod.pending, state => {
      state.loading = true;
    });
    builder.addCase(doUpdatePaymentMethod.fulfilled, (state, action) => {
      state.loading = false;
      state.paymentMethod = action.payload;
      state.error = undefined;
    });
    builder.addCase(doUpdatePaymentMethod.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as SerializedException)?.message;
    });
  },
});

const selectPaymentMethodFeature = (state: AppState) => state[TESTOPS_PAYMENT_METHOD_FEATURE_KEY];
export const selectPaymentMethod = createSelector(
  selectPaymentMethodFeature,
  paymentMethodState => paymentMethodState.paymentMethod,
);
export const selectSelectedId = createSelector(
  selectPaymentMethodFeature,
  paymentMethodState => paymentMethodState.selectedId,
);
export const selectLoading = createSelector(
  selectPaymentMethodFeature,
  paymentMethodState => paymentMethodState.loading,
);
export const selectError = createSelector(
  selectPaymentMethodFeature,
  paymentMethodState => paymentMethodState.error,
);

export default testOpsPaymentMethodSlice.reducer;
