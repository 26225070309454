import { OrganizationFeature, TestOpsPlanInterval } from '../../../models';

const getIntervalName = (
  planInterval: TestOpsPlanInterval,
) => TestOpsPlanInterval.getPlanIntervalName(planInterval).toLowerCase();

export const getPlanId = (
  feature: OrganizationFeature,
  planInterval: TestOpsPlanInterval,
): string => {
  const intervalName = getIntervalName(planInterval);
  switch (feature) {
    case OrganizationFeature.TESTOPS_PLATFORM:
      return `testops_platform_${intervalName}`;
    case OrganizationFeature.PER_USER_KSE:
      return `kse_per-user_${intervalName}`;
    case OrganizationFeature.UNLIMITED_ENGINE:
      return `kre_floating_${intervalName}`;
    case OrganizationFeature.TESTCLOUD_SESSION_WEB:
      return `testcloud_session_web_${intervalName}`;
    case OrganizationFeature.TESTCLOUD_SESSION_WEB_DESKTOP:
      return `testcloud_session_webdesktop_${intervalName}`;
    case OrganizationFeature.TESTCLOUD_SESSION_CROSS_BROWSER:
      return `testcloud_session_crossbrowser_${intervalName}`;
    case OrganizationFeature.TESTCLOUD_SESSION_MOBILE_APP:
      return `testcloud_session_mobileapp_${intervalName}`;
    case OrganizationFeature.VISUAL_TESTING_PRO:
      return `visual_testing_pro_${intervalName}`;
    default:
      return '';
  }
};

export const formatMessageRecurly = (message: string) => message.replaceAll(/[^a-zA-Z0-9\s.]/g, '');

export const insertIf = (condition: any, ...elements: any) => (condition ? elements : []);

const trackingErrorTypeMapper: { [key: string]: string; } = {
  matches: 'invalid',
  required: 'required',
};

export const formatErrorTrackingMethod = (fieldName: string, errorType: string) => `${fieldName}_is_${trackingErrorTypeMapper[errorType] ?? 'invalid'}`;
