import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { unwrapResult } from '@reduxjs/toolkit';
import { Account } from '../../models';
import LoadingProgress from '../../layout/LoadingProgress';
import {
  fromOrganizationWhitelistIps,
  useAppDispatch,
} from '../../store';
import { useQuery } from '../../routes';

const useStyles = makeStyles(theme => ({
  header: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  container: {
    width: theme.spacing(57),
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(1.75),
  },
  description: {
    marginTop: theme.spacing(0.5),
    fontSize: theme.spacing(1.5),
    color: '#808b9a',
    lineHeight: 1.5,
  },
  input: {
    width: theme.spacing(77),
    marginLeft: theme.spacing(2.75),
    '& .MuiOutlinedInput-root': {
      fontSize: theme.spacing(1.75),
      lineHeight: '1.5rem',
      border: 'solid 1px #dbdde5',
      backgroundColor: 'white',
      borderRadius: theme.spacing(0.5),
    },
    '& .Mui-disabled': {
      cursor: 'no-drop',
      border: 'none !important',
    },
  },
  button: {
    marginTop: theme.spacing(2.5),
    fontSize: theme.spacing(1.75),
    paddingY: '0.5rem',
    lineHeight: '1.5rem',
    fontWeight: theme.typography.fontWeightMedium,
  },
  plan: {
    backgroundColor: '#fbc02d',
    color: '#233145',
    borderRadius: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    fontSize: theme.spacing(1.75),
    padding: theme.spacing(0.5),
    fontWeight: 'normal !important',
  },
}));

export interface OrganizationSecurityProps {
  hasValidSubscription: boolean;
  organizationId?: number;
}
const IpAddressRestrictions = (props: OrganizationSecurityProps) => {
  const { hasValidSubscription, organizationId } = props;
  const classes = useStyles();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { get } = useQuery();
  const orgId = get('orgId') ?? organizationId;
  const [savedWhitelistIps, setSavedWhitelistIps] = useState('');
  const [currentWhitelistIps, setCurrentWhitelistIps] = useState('');

  const {
    handleSubmit,
  } = useForm<Required<Pick<Account, 'id' | 'name'>>>({
    defaultValues: { name: '' },
  });

  useEffect(() => {
    if (!orgId) return;
    setLoading(true);
    const fetchInformation = async () => {
      await Promise.all([
        dispatch(fromOrganizationWhitelistIps.doGetByOrganizationId(Number(orgId)))
          .then(unwrapResult)
          .then((data: { count: number; whitelistIps: any[]; }) => {
            parseWhiteListIpToString(data);
          })
          .finally(() => setLoading(false)),
      ]);
      setLoading(false);
    };
    fetchInformation().catch(() => {});
  }, [orgId, dispatch]);

  const doUpdate = async () => {
    setLoading(true);
    await dispatch(
      fromOrganizationWhitelistIps.doUpsertWhitelistIps(
        {
          organizationId: Number(orgId),
          ip: currentWhitelistIps,
        },
      ),
    )
      .then(unwrapResult)
      .then(data => {
        // @ts-ignore
        parseWhiteListIpToString({ count: data.length, whitelistIps: data });
        enqueueSnackbar(
          intl.formatMessage(
            { id: 'security_setting.ip_address_restrictions.update.successfully' },
          ),
          { variant: 'success' },
        );
      })
      .catch(() => {
        enqueueSnackbar(
          intl.formatMessage({ id: 'security_setting.ip_address_restrictions.update.failed' }),
          { variant: 'error' },
        );
      })
      .finally(() => setLoading(false));
  };

  const handleChangeIpsRestriction: React.ChangeEventHandler<HTMLInputElement> = e => {
    setCurrentWhitelistIps(String(e.target.value));
  };

  const parseWhiteListIpToString = (data: { count: number; whitelistIps: any[]; }) => {
    let ips: String[] = [];
    if (data.count > 0) {
      ips = data.whitelistIps.map((ip: any) => ip.ip);
    }
    setCurrentWhitelistIps(ips.join(','));
    setSavedWhitelistIps(ips.join(','));
  };

  return (
    <>
      <form onSubmit={handleSubmit(doUpdate)} id="security_setting.ip_address_restrictions.form">
        <Box display="block" padding={2} alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" width="100%">
            <Typography variant="subtitle2" className={classes.header}>
              <FormattedMessage id="security_setting.ip_address_restrictions.header" />
            </Typography>
            <Typography variant="subtitle2" className={`${classes.header} ${classes.plan}`}>
              <FormattedMessage id="security_setting.ultimate_plan" />
            </Typography>
          </Box>
          <Box display="flex">
            <Box display="block" className={classes.container}>
              <Typography variant="subtitle2" className={classes.title}>
                <FormattedMessage id="security_setting.ip_address_restrictions.title" />
              </Typography>
              <Typography variant="subtitle2" className={classes.description}>
                <FormattedMessage id="security_setting.ip_address_restrictions.description" />
              </Typography>
            </Box>
            <TextField
              id="security_setting.ip_address_restrictions.textfield"
              className={classes.input}
              variant="outlined"
              value={currentWhitelistIps}
              onChange={handleChangeIpsRestriction}
              disabled={!hasValidSubscription}
              multiline
              placeholder={intl.formatMessage({ id: 'security_setting.ip_address_restrictions.input.placeholder' })}
              rows={2}
            />
          </Box>
          <Button
            id="security_setting.ip_address_restrictions.button.update"
            variant="contained"
            color="primary"
            type="submit"
            sx={{
              '&.Mui-disabled': {
                background: '#686D80',
                color: '#c0c0c0',
              },
            }}
            disabled={(!savedWhitelistIps && currentWhitelistIps === '')
              || savedWhitelistIps === currentWhitelistIps || !hasValidSubscription}
            className={classes.button}
          >
            <FormattedMessage id="security_setting.ip_address_restrictions.button.update" />
          </Button>
        </Box>
      </form>
      {loading && <LoadingProgress />}
    </>
  );
};

export default IpAddressRestrictions;
