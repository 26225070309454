import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { ReactComponent as DeleteAccountIcon } from '../../../layout/icons/DeleteAccountIcon.svg';
import LoadingProgress from '../../../layout/LoadingProgress';
import { logout as logoutRoute, useNavigate, useQuery } from '../../../routes';
import { deleteUser } from '../../../services/user';
import { fromAuth } from '../../../store';

const useStyles = makeStyles(theme => ({
  dialog: {
    position: 'absolute',
    top: 0,
    alignItems: 'center',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(3),
  },
  title: {
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    width: '100%',
    color: theme.palette.warning.main,
    fontSize: theme.spacing(3),
    fontWeight: 700,
  },
  subtext: {
    fontSize: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    fontWeight: 500,
  },
  form: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(4),
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
  textarea: {
    width: '100%',
    height: '100%',
  },
  icon: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  reasonInput: {
    marginTop: theme.spacing(2),
    '& > *': {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
  },
  passwordInput: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  actions: {
    width: '100%',
  },
  asterisk: {
    color: theme.palette.warning.main,
  },
  error: {
    color: theme.palette.error.main,
    margin: 0,
  },
  deleteLabel: {
    color: '#EF6565',
    verticalAlign: 'middle',
    fontSize: theme.spacing(2),
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: 'capitalize',
  },
}));

interface DeleteAccountInput {
  reason: string;
  password: string;
}

const DeleteAccountDialog = () => {
  const { replace, replaceQuery } = useNavigate();
  const { queryDictionary } = useQuery();
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();
  const user = useSelector(fromAuth.selectUser);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors: inputErrors },
  } = useForm<DeleteAccountInput>({
    defaultValues: { reason: '', password: '' },
  });
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState(['']);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const openDeleteAccountDialog = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
  };

  const closeDeleteAccountDialog = () => {
    setErrors([]); // reset errors as users close the dialog
    setOpen(false);
  };

  const doDeleteUser = async (input: DeleteAccountInput) => {
    setLoading(true);
    try {
      await deleteUser({ id: user!.id, password: input.password, deleteReason: input.reason });
      analytics.track('User Delete Account', {
        event_text: 'User Delete Account',
        user_id: user!.email,
        delete_reason: input.reason,
      });
      replace(logoutRoute.path, replaceQuery(queryDictionary()));
      enqueueSnackbar(<FormattedMessage id="profile.delete_account.success" />, {
        variant: 'success',
      });
    } catch (error) {
      setErrors(['incorrect_password']); // modify the error message to fit one in line 206
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button onClick={openDeleteAccountDialog}>
        <Typography className={classes.deleteLabel}>
          <FormattedMessage id="profile.delete_account.delete" />
        </Typography>
      </Button>
      <Dialog
        classes={{ paper: classes.dialog }}
        open={open}
        maxWidth="sm"
        fullScreen={fullScreen}
        fullWidth
        onClose={closeDeleteAccountDialog}
      >
        <DeleteAccountIcon className={classes.icon} />
        <DialogTitle className={classes.title}>
          <FormattedMessage id="profile.delete_account.title" />
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" className={classes.subtext}>
            <FormattedMessage id="profile.delete_account.warning_message" />
          </Typography>
          <Typography variant="body2" className={classes.subtext}>
            <FormattedMessage id="profile.delete_account.warning_message_testops" />
          </Typography>
          <form
            className={classes.form}
            onSubmit={handleSubmit(doDeleteUser)}
            id="delete-account-form"
          >
            <Typography className={classes.subtitle}>
              <span className={classes.asterisk}>* </span>
              <FormattedMessage id="profile.delete_account.reason" />
            </Typography>
            <TextField
              className={classes.reasonInput}
              multiline
              fullWidth
              autoFocus
              required
              variant="outlined"
              placeholder={intl.formatMessage({ id: 'profile.delete_account.reason.placeholder' })}
              name="reason"
              inputProps={{
                ...register('reason', {
                  required: 'error.required',
                  validate: value => value.trim() !== '' || 'error.missing_reason',
                }),
                maxLength: 1000,
              }}
              helperText={
                inputErrors.reason && <FormattedMessage id={inputErrors.reason?.message} />
              }
              error={!!inputErrors.reason}
            />
            <Typography className={classes.subtitle}>
              <span className={classes.asterisk}>* </span>
              <FormattedMessage id="profile.delete_account.confirm" values={{ char: '*' }} />
            </Typography>
            <TextField
              variant="outlined"
              type="password"
              fullWidth
              required
              name="password"
              inputProps={{
                ...register('password', {
                  required: 'error.required',
                }),
              }}
              placeholder={intl.formatMessage({ id: 'profile.delete_account.confirm.placeholder' })}
              helperText={
                inputErrors.password && (
                  <FormattedMessage id={inputErrors.password?.message} values={{ length: 8 }} />
                )
              }
              InputProps={{
                classes: {
                  input: classes.passwordInput,
                },
              }}
              error={!!inputErrors.password}
            />
            {errors && errors.includes('incorrect_password') && (
              <Typography variant="caption" className={classes.error}>
                <FormattedMessage id="error.incorrect_password" />
              </Typography>
            )}
          </form>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button type="button" variant="contained" onClick={closeDeleteAccountDialog}>
            <FormattedMessage id="profile.delete_account.cancel" />
          </Button>
          <Button type="submit" color="secondary" form="delete-account-form" variant="contained">
            <FormattedMessage id="profile.delete_account.submit" />
          </Button>
        </DialogActions>
        {loading && <LoadingProgress />}
      </Dialog>
    </>
  );
};

export default DeleteAccountDialog;
