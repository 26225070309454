import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { exceptionOf, Organization,
  SerializedException } from '../models';
import { OrganizationWhitelistIps } from '../models/organizationWhitelistIps';
import { OrganizationWhitelistIpsService } from '../services';
import { Operator, Query } from '../models/query';

export const ORGANIZATION_WHITELIST_IPS_FEATURE_KEY = 'organizationWhitelistIps';

interface OrganizationWhitelistIpsState extends EntityState<OrganizationWhitelistIps> {
  loading: boolean;
  count: number;
  error?: SerializedException;
}

const organizationWhitelistIpsConfigurationAdapter = createEntityAdapter
<OrganizationWhitelistIps>();

export const createInitialState = ():
OrganizationWhitelistIpsState => organizationWhitelistIpsConfigurationAdapter
  .getInitialState({
    count: 0,
    loading: false,
  });

export const doUpsertWhitelistIps = createAsyncThunk(
  'organization/doUpsertWhitelistIps',
  async (
    input: {
      organizationId: number,
      ip: string,
    },
    { rejectWithValue },
  ) => {
    try {
      return await OrganizationWhitelistIpsService.doUpsertWhitelistDomainOrganization(input);
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doGetByOrganizationId = createAsyncThunk(
  'organization/getWhitelistIps',
  async (organizationId: Organization['id']) => {
    const criteria: Array<Query<OrganizationWhitelistIps>> = [
      { field: 'organizationId', operator: Operator.EQ, value: organizationId },
    ];
    const response = await OrganizationWhitelistIpsService.getWhitelistIps(...criteria);
    // @ts-ignore
    return { whitelistIps: response.data, count: response.total };
  },
);
const organizationWhitelistIpsSlice = createSlice({
  name: ORGANIZATION_WHITELIST_IPS_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doGetByOrganizationId.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetByOrganizationId.fulfilled, (state, action) => {
      state.loading = false;
      state.count = action.payload.count;
      organizationWhitelistIpsConfigurationAdapter.removeAll(state);
      organizationWhitelistIpsConfigurationAdapter.addMany(state, action.payload.whitelistIps);
    });
    builder.addCase(doGetByOrganizationId.rejected, state => {
      state.loading = false;
    });
    builder.addCase(doUpsertWhitelistIps.pending, state => {
      state.loading = true;
    });
    builder.addCase(doUpsertWhitelistIps.fulfilled, (state, action) => {
      state.loading = false;
      // @ts-ignore
      organizationWhitelistIpsConfigurationAdapter.upsertMany(state, action.payload);
    });
    builder.addCase(doUpsertWhitelistIps.rejected, state => {
      state.loading = false;
    });
  },
});

export default organizationWhitelistIpsSlice.reducer;
