import { BaseModel } from './base';
import { Organization } from './organization';
import { User } from './user';
import { Config } from './config';
import { AccountRole } from './accountRole';

export interface UserInvitation extends BaseModel {
  id: number;
  userId: User['id'];
  accepted: boolean;
  organizationId: Organization['id'];
  invitedUserId: number;
  invitationToken: string;
  email: string;
  invitedUserEmail?: string;
  type: string;
  invitedTestOpsUserId?: number;
  user: User;
  organizationName: Organization['name'];
  organizationDomain: Organization['domain'];
  resetPasswordCode?: string;
  accountName: string;
  accountId: number;
  inviterUserEmail: string;
  inviterUserName: string;
  organizationLogo: Organization['logo'];
  avatar?: string;
  role?: AccountRole;
}

export interface UserInvitationDTO {
  invitedUserEmail: string;
  organizationId: number;
  assignedFeatures: string[];
  ssoOptions: string[];
}

export interface K1UserInvitation extends UserInvitation {
  type: 'K1';
}

export interface KitUserInvitation extends UserInvitation {
  type: 'KIT';
}

export function isK1Invitation(type: string | undefined): boolean {
  return type === 'K1';
}

export function isKitInvitation(type: string | undefined): boolean {
  return type === 'KIT';
}

export function isInvitationExpired(
  invitationLastSentDateInMillis: number,
  config: Config | undefined,
): boolean {
  return new Date().valueOf() - invitationLastSentDateInMillis
    > Number(config?.invitationExpirationExtensionMilliseconds);
}
