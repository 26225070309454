import Box from '@mui/material/Box';
import dateFormat from 'dateformat';

interface DateInputProps {
  label: string;
  shownDate: Date;
  // onChange: undefined | ((newDate: Date) => void);
}

const DateInput = (props: DateInputProps) => {
  const { label, shownDate } = props;
  return (
    <Box fontWeight={400} fontSize={14} color="#516393" ml={2} display="flex">
      <Box>{`${label}:`}</Box>
      <Box ml={3}>{dateFormat(shownDate, 'dd/mm/yyyy')}</Box>
    </Box>
  );
};

export default DateInput;
