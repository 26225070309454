import Axios from 'axios';
import Jimp from 'jimp';

import { DataResponse, GeneratedFields, PageableResponse, Organization, OrganizationMigration } from '../models';

import { composeQuery, Query } from '../models/query';

export const createOrganization = async (
  input: Required<Omit<Organization, GeneratedFields | 'logo' | 'restrictedIps' | 'idleTimeout' | 'platformIdleTimeout' | 'lastAccessedAt' | 'samlSsoEnabled' | 'domain' | 'ownerId'>>
  & Partial<Pick<Organization, 'logo' | 'restrictedIps' | 'idleTimeout' | 'accountId'>>,
) => (await (Axios.post<DataResponse<Organization>>('/v1/orgs', input))).data.data;

export const getOrganizations = async (...criteria: Query<Organization>[]) => (await Axios.get<PageableResponse<Organization>>('/v1/orgs', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: composeQuery<Organization>(...criteria),
  // must specify data to keep Content-Type header
  // though axios will remove request body when using GET method.
  data: {},
})).data;

export const updateOrganization = async (input: Required<Pick<Organization, 'id' | 'name'>> & Partial<Pick<Organization, 'logo' | 'restrictedIps' | 'idleTimeout' | 'platformIdleTimeout' | 'ownerId' | 'accountId'>>) => (await Axios.put<DataResponse<Organization>>(`/v1/orgs/${input.id}`, input)).data.data;

export const deleteOrganization = async (input: Required<Pick<Organization, 'id'>>) => (await Axios.delete(`/v1/orgs/${input.id}`)).data;

export const resizeImage = (file: File) => new Promise<string>(resolve => {
  const reader = new FileReader();

  reader.addEventListener('load', async event => {
    if (event.target?.result instanceof ArrayBuffer) {
      try {
        const buffer = Buffer.from(event.target.result);
        const jimp = await Jimp.read(buffer);
        (jimp.bitmap as any).exifBuffer = undefined; // remove exif info
        const image = await jimp.resize(256, 256).getBase64Async(Jimp.MIME_JPEG);
        resolve(image);
      } catch (error: any) {
        resolve(error);
      }
    }
  });

  reader.readAsArrayBuffer(file);
});

export const getOrganizationByOrgId = async (...criteria: Query<Organization>[]) => (await Axios.get<PageableResponse<Organization>>('/v1/orgs', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: composeQuery<Organization>(...criteria),
  data: {},
})).data.data;

export const getOrgMigrationStatus = async (input: Required<Pick<Organization, 'id'>>) => (
  await Axios.get<OrganizationMigration>(`/v1/organization-migration/${input.id}`)
).data;
