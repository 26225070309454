import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import { AppState } from '.';
import { Organization, OrganizationUser, User } from '../models';
import { Operator } from '../models/query';
import { OrganizationUserService } from '../services';

export const CURRENT_ORGANIZATION_USER_FEATURE_KEY = 'currentOrganizationUser';

type OrganizationUserEntity = OrganizationUser;

const currentOrganizationUserAdapter = createEntityAdapter<OrganizationUserEntity>();

interface OrganizationUserState extends EntityState<OrganizationUserEntity> {
  loading: boolean;
  error?: string;
}
// const MAX_NUMBER_OF_RECORDS = 50;
export const createInitialState = (): OrganizationUserState => (
  currentOrganizationUserAdapter.getInitialState({
    loading: false,
  })
);

export const doGetOrgUserByUserIdAndOrgId = createAsyncThunk(
  'organizationUser/getOrgByUserIdAndOrgId',
  async (input: { id: number, organizationId: number }): Promise<OrganizationUser[]> => {
    const orgUsers = await OrganizationUserService.getOrganizationUsers(
      { field: 'userId', operator: Operator.EQ, value: input.id },
      { field: 'organizationId', operator: Operator.EQ, value: input.organizationId },
    );
    return orgUsers.data;
  },
);

const currentOrganizationUsersSlice = createSlice({
  name: CURRENT_ORGANIZATION_USER_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doGetOrgUserByUserIdAndOrgId.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetOrgUserByUserIdAndOrgId.fulfilled, (state, action) => {
      state.loading = false;
      currentOrganizationUserAdapter.upsertMany(state, action.payload);
    });
    builder.addCase(doGetOrgUserByUserIdAndOrgId.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

const selectOrganizationUser = (state: AppState) => state[CURRENT_ORGANIZATION_USER_FEATURE_KEY];
export const {
  selectAll: selectAllOrganizationUsers,
  selectIds,
  selectEntities,
} = currentOrganizationUserAdapter.getSelectors(selectOrganizationUser);

export const selectByOrganizationIdAndEmail = (email: User['email'], orgId: Organization['id']) => createSelector(
  selectAllOrganizationUsers,
  orgUsers => orgUsers.find(orgUser => (
    orgUser.organizationId === orgId && orgUser.user.email === email
  )),
);

export const selectLoading = createSelector(
  selectOrganizationUser,
  currentOrgUserState => currentOrgUserState.loading,
);

export const selectError = createSelector(
  selectOrganizationUser,
  currentOrgUserState => currentOrgUserState.error,
);

export default currentOrganizationUsersSlice.reducer;
