import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import { AppState } from '.';
import { OrganizationRemovedUser } from '../models';
import { Operator, Query } from '../models/query';
import { OrganizationRemovedUserService } from '../services';

export const ORGANIZATION_REMOVED_USER_FEATURE_KEY = 'organization_removed_users';

interface OrganizationRemovedUserState extends EntityState<OrganizationRemovedUser> {
  loading: boolean;
  count: number;
  error?: string;
}

const MAX_NUMBER_OF_RECORDS = 50;

const organizationRemovedUserAdapter = createEntityAdapter<OrganizationRemovedUser>();

export const createInitialState = (): OrganizationRemovedUserState => (
  organizationRemovedUserAdapter.getInitialState({
    loading: false,
    count: 0,
  })
);

export const doGetAllOrganizationRemovedUser = createAsyncThunk(
  'organizationRemovedUser/getAll',
  async (input: Pick<OrganizationRemovedUser, 'organizationId'>) => {
    const criteria: Query<OrganizationRemovedUser>[] = [
      {
        field: 'organizationId',
        operator: Operator.EQ,
        value: input.organizationId,
      },
      { field: 'archived', operator: Operator.EQ, value: false },
    ];
    const firstResponse = await OrganizationRemovedUserService
      .getOrganizationRemovedUsers(...criteria);
    const numberOfRequests = Math.ceil(firstResponse.total / MAX_NUMBER_OF_RECORDS);
    const remainingRequests = [];
    for (let i = 1; i < numberOfRequests; i += 1) {
      const req = OrganizationRemovedUserService.getOrganizationRemovedUsers(
        ...criteria,
        { offset: MAX_NUMBER_OF_RECORDS * i },
      );
      remainingRequests.push(req);
    }
    const remainingResponses = await Promise.all(remainingRequests);
    const allData = [...firstResponse.data];
    remainingResponses.forEach(r => {
      allData.push(...r.data);
    });
    return allData;
  },
);

// TODO: remove duplicate records from BE side
export const doCountTotalOrganizationRemovedUser = createAsyncThunk(
  'organizationRemovedUser/countTotalOrganizationRemovedUser',
  async (input: Pick<OrganizationRemovedUser, 'organizationId'>) => {
    const criteria: Query<OrganizationRemovedUser>[] = [
      {
        field: 'organizationId',
        operator: Operator.EQ,
        value: input.organizationId,
      },
      { field: 'archived', operator: Operator.EQ, value: false },
      { limit: 0 },
    ];
    const response = await OrganizationRemovedUserService.getOrganizationRemovedUsers(...criteria);
    return { count: response.total };
  },
);

const organizationRemovedUserSlice = createSlice({
  name: ORGANIZATION_REMOVED_USER_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doGetAllOrganizationRemovedUser.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetAllOrganizationRemovedUser.fulfilled, (state, action) => {
      state.loading = false;
      const newState = uniqBy(sortBy(action.payload, it => -it.removedAt), 'email');
      state.count = newState.length;
      organizationRemovedUserAdapter.setAll(state, newState);
    });
    builder.addCase(doGetAllOrganizationRemovedUser.rejected, state => {
      state.loading = false;
    });
    builder.addCase(doCountTotalOrganizationRemovedUser.pending, state => {
      state.loading = true;
    });
    builder.addCase(doCountTotalOrganizationRemovedUser.fulfilled, (state, action) => {
      state.loading = false;
      state.count = action.payload.count;
    });
    builder.addCase(doCountTotalOrganizationRemovedUser.rejected, state => {
      state.loading = false;
    });
  },
});

const selectOrganizationRemovedUser = (
  state: AppState,
) => state[ORGANIZATION_REMOVED_USER_FEATURE_KEY];

const { selectAll } = organizationRemovedUserAdapter.getSelectors(selectOrganizationRemovedUser);

export const selectOrganizationRemovedUserByOrganizationId = (
  organizationId: OrganizationRemovedUser['organizationId'],
) => createSelector(
  selectAll,
  all => all.filter(it => it.organizationId === organizationId),
);
export const selectTotalOrganizationRemovedUsersCount = createSelector(
  selectOrganizationRemovedUser,
  state => state.count,
);
export default organizationRemovedUserSlice.reducer;
