// a bit frustrated but I couldn't find out why this keeps failling on Github action
// adding this import as a temprorary workaround for Github action
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import _ from '@mui/lab/themeAugmentation/overrides';

import { ThemeOptions } from '@mui/material/styles';
import merge from 'lodash/fp/merge';
import themeOptions from './common';

export default merge<Partial<ThemeOptions>>({
  palette: {
    mode: 'light',
    background: {
      default: '#f8f8f8',
    },
    primary: {
      light: '#87a6ff',
      dark: '#004dc1',
      main: '#2A3DC7',
    },
    text: {
      secondary: '#7591AA',
    },
    warning: {
      main: '#E11900',
    },
  },
  typography: {
    subtitle1: {
      color: '#7591AA',
    },
    subtitle2: {
      color: '#284169',
    },
    h3: {
      color: '#172B4D',
    },
    h5: {
      color: '#7591AA',
    },
    h2: {
      color: '#172B4D',
    },
  },
  // override specific hover color in light mode
  // TODO: #9CA6BE primary text color to be overwritten to theme
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#284169',
          '&:hover': {
            backgroundColor: '#EFF6FC',
            color: '#1847CA',
          },
          '&.Mui-selected': {
            color: '#1847CA',
            backgroundColor: '#EFF6FC',
            '&:hover': {
              backgroundColor: '#EFF6FC',
              color: '#1847CA',
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: '#516393',
          borderBottom: 'solid 2px #EEF3FA',
        },
        body: {
          color: '#1D3066',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&$selected': {
            backgroundColor: '#F8F9FD',
            '&:hover': {
              backgroundColor: '#F8F9FD',
            },
          },
          '&:hover': {
            backgroundColor: '#F8F9FD',
          },
        },
        head: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#276EF1',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          marginRight: '2.5rem',
          textTransform: 'none',
          fontWeight: 700,
          fontSize: '1rem',
          padding: 0,
          minWidth: '2.5rem',
          opacity: 0.2,
          '&.Mui-selected': {
            color: '#276EF1',
            opacity: 1,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#172B4D',
          '& > .MuiTooltip-arrow::before': {
            backgroundColor: '#172B4D',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: '0px 9px 22px -6px #C0D6EA40',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#1D3066',
            color: '#FFF',
            '&:hover': {
              backgroundColor: '#1D3066',
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        tag: {
          backgroundColor: '#EEF1FA',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        formControl: {
          backgroundColor: '#FAFBFF',
          border: '1px solid #EEF3FA',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'inherit',
      },
      styleOverrides: {
        containedPrimary: {
          backgroundColor: '#276EF1',
          '&, &.Mui-disabled': {
            backgroundColor: '#276EF1',
            color: '#ffffff',
          },
          '&.Mui-disabled': {
            opacity: '0.3',
          },
        },
        containedSecondary: {
          color: '#ffffff',
          backgroundColor: '#E11900',
        },
        containedInherit: {
          backgroundColor: '#E8ECF0',
          color: '#516393',
          '&.Mui-disabled': {
            opacity: '0.4',
            backgroundColor: '#E8ECF0',
            color: '#516393',
          },
          '&:hover': {
            backgroundColor: '#d5d5d5',
          },
        },
        textInherit: {
          color: '#233145',
          '&:hovered': {
            backgroundColor: '#F0F1F2',
          },
        },
        text: {
          color: '#276EF1',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: '#EEF1FA',
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          '&$active': {
            color: '#516393',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: '#172B4D',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          color: '#172B4D',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: 'rgba(0, 0, 0, 0.12)',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorAction: {
          color: '#808B9A',
        },
      },
    },
  },
})(themeOptions);
