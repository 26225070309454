import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Autocomplete, { AutocompleteProps, AutocompleteRenderGroupParams, createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Popper, { PopperProps } from '@mui/material/Popper';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import ListSubheader from '@mui/material/ListSubheader';
import uniqBy from 'lodash/uniqBy';
import { unwrapResult } from '@reduxjs/toolkit';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { Navigate, Link as RouterLink } from 'react-router-dom';
import { notFound, resolvePath, sso, useNavigate, useQuery } from '../../../routes';
import { supportManagement } from '../../../layout/routes';
import { getAvatarSource, getFullNameUser, OrganizationFeature, User } from '../../../models';
import { fromAssignLicensePage, fromOrganizations, fromServiceCloudOrganizationUser, useAppDispatch } from '../../../store';
import NoResult from '../../license-utilization/components/NoResult';
import LoadingProgress from '../../../layout/LoadingProgress';
import { ERROR_ASSIGN_USER_ALREADY_ASSIGNED, ERROR_ASSIGN_USER_NOT_EXISTED, ERROR_ASSIGN_USER_QUOTA_EXCEEDED } from '../../../store/assignLicensePageSlice';
import { useConfig } from '../../../config';

const useStyle = makeStyles(theme => ({
  root: {
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1.5),
  },
  closeButton: {
    cursor: 'pointer',
    fill: 'gray',
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: theme.spacing(2.5),
  },
  titleContainer: {
    marginBottom: theme.spacing(2),
  },
  inputContainer: {
    width: theme.spacing(55),
  },
  option: {
    width: '100%',
    paddingBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  avatarContainer: {
    position: 'relative',
  },
  avatar: {
    marginRight: theme.spacing(1),
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
  },
  userInfo: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: theme.spacing(1.5),
  },
  button: {
    marginTop: theme.spacing(2),
    width: theme.spacing(44),
  },
  list: {
    backgroundColor: '#FFF',
  },
  muiOption: {
    backgroundColor: '#FFF !important',
    '&.Mui-focused': {
      backgroundColor: '#FFF !important',
    },
  },
  input: {
    '&:hover': {
      border: 'none !important',
    },
  },
  inputWrapper: {
    '& > .MuiOutlinedInput-root': {
      height: theme.spacing(5),
      padding: theme.spacing(0, 1),
    },
  },
  listBox: {
    maxHeight: theme.spacing(56),
  },
  subtitle: {
    fontSize: theme.spacing(1.75),
    textAlign: 'center',
  },
  subtitleContainer: {
    marginBottom: theme.spacing(2),
  },
}));

interface AssignLicenseDialogProps {
  open: boolean;
  onCloseAssignLicenseDialog: () => void;
}

const PopperComponent = (props: PopperProps) => (
  <Popper
    {...props}
    style={{ width: '27.5rem', zIndex: 9999, position: 'unset' }}
    placement="bottom-start"
    disablePortal
    transition
    modifiers={[
      {
        name: 'preventOverflow',
        enabled: false,
        options: {
          altBoundary: true,
        },
      },
    ]}
  />
);

const PaperComponent = (props: any) => (
  <Paper
    {...props}
    style={{ border: '1px solid #EEF3FA', marginTop: '1rem' }}
  />
);

const canFeatureAssignToUser = (feature: OrganizationFeature) => [
  OrganizationFeature.UNLIMITED_ENGINE,
  OrganizationFeature.KSE,
  OrganizationFeature.UNLIMITED_KSE,
  OrganizationFeature.PER_USER_KSE,
].includes(feature);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon color="primary" fontSize="small" />;

const renderGroup = (params: AutocompleteRenderGroupParams) => [
  <ListSubheader key={params.key} component="div" disableSticky>
    {params.group}
  </ListSubheader>,
  params.children,
];

const AssignLicenseDialogOld = (props: AssignLicenseDialogProps) => {
  const { open } = props;
  const classes = useStyle();
  const { get } = useQuery();
  const dispatch = useAppDispatch();
  const { config } = useConfig();
  const { enqueueSnackbar } = useSnackbar();
  const { navigate, replaceQuery } = useNavigate();
  const intl = useIntl();
  const feature = OrganizationFeature.fromString(get('feature') || '');
  const orgId = get('orgId');
  const [focused, setFocused] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [errorEmail, setErrorEmail] = useState('');
  const errors = useSelector(fromAssignLicensePage.selectErrors());
  const organization = useSelector(fromOrganizations.selectSelectedOrganization);
  const listOrgUser = useSelector(
    fromAssignLicensePage.selectAssignLicenseUserListByOrganizationId(Number(orgId) || 0),
  );
  const listUser = useMemo(() => (
    listOrgUser.map(orgUser => orgUser.user)
  ), [listOrgUser]);

  const customMethod = createFilterOptions<User>({
    trim: true,
    stringify: (option: User) => `${option.firstName} ${option.lastName} ${option.email}`,
  });

  const handleClose = () => {
    navigate(sso.path, replaceQuery({ redirect_uri: `${process.env.REACT_APP_ADMIN_URL}/organization/${orgId}/admin/license_keys/${feature?.toLowerCase()}` }));
  };
  const handleChange: AutocompleteProps<User, true, true, undefined>['onChange'] = (_, value) => {
    setSelectedUsers(value);
  };
  const handleSubmit = async () => {
    const assignedUserIds: number[] = [];
    if (feature) {
      setLoading(true);
      // eslint-disable-next-line no-restricted-syntax
      for (const user of selectedUsers) {
        try {
          const currentOrgUser = listOrgUser.find(orgUser => orgUser.user.email === user.email);

          // eslint-disable-next-line no-await-in-loop
          await dispatch(fromAssignLicensePage.doAssignLicense({
            organizationUserId: currentOrgUser?.id || 0,
            feature,
          }))
            .then(unwrapResult)
            .then(() => assignedUserIds.push(user.id));
          if (!config?.onpremise && config?.serviceCloudEnabled) {
            // eslint-disable-next-line no-await-in-loop
            await dispatch(fromServiceCloudOrganizationUser.doCreateServiceCloudOrganizationUser({
              organizationId: Number(orgId),
              email: currentOrgUser?.user.email || '',
            }));
          }
        } catch (_) {
          setLoading(false);
          setSelectedUsers(
            selectedUsers.filter(user => assignedUserIds.findIndex(it => it === user.id) === -1),
          );
          setErrorEmail(user.email);
          return;
        }
      }
      setLoading(false);
      enqueueSnackbar(
        <FormattedMessage
          id="license_management.assign_license.success"
          values={{ count: selectedUsers.length }}
        />,
        { variant: 'success' },
      );
      dispatch(fromAssignLicensePage.doResetErrors());
      setSelectedUsers([]);
      if (selectedUsers.length === listOrgUser.length) {
        setTimeout(() => {
          navigate(
            sso.path,
            replaceQuery({ redirect_uri: `${process.env.REACT_APP_ADMIN_URL}/organization/${orgId}/admin/license_keys/${feature?.toLowerCase()}` }),
          );
        }, 1500);
      }
    }
  };

  useEffect(() => {
    if (errors && errors[0] && errorEmail) {
      switch (errors[0].message) {
        case ERROR_ASSIGN_USER_ALREADY_ASSIGNED:
          enqueueSnackbar(
            <FormattedMessage
              id="license_management.assign_license.error_already_assigned"
              values={{ email: errorEmail }}
            />,
            { variant: 'error' },
          );
          dispatch(fromAssignLicensePage.doResetErrors());
          break;
        case ERROR_ASSIGN_USER_QUOTA_EXCEEDED:
          enqueueSnackbar(
            <FormattedMessage
              id="license_management.assign_license.error_quota_exceeded"
            />,
            { variant: 'error' },
          );
          dispatch(fromAssignLicensePage.doResetErrors());
          break;
        case ERROR_ASSIGN_USER_NOT_EXISTED:
          enqueueSnackbar(
            <FormattedMessage
              id="license_management.assign_license.error_user_not_existed"
              values={{ email: errorEmail }}
            />,
            { variant: 'error' },
          );
          dispatch(fromAssignLicensePage.doResetErrors());
          break;
        default:
          enqueueSnackbar(
            <FormattedMessage
              id="error.unknown"
            />,
            { variant: 'error' },
          );
          dispatch(fromAssignLicensePage.doResetErrors());
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, errorEmail]);

  useEffect(() => {
    if (!orgId || !feature) {
      return;
    }
    const fetchData = async () => {
      setLoading(true);
      await dispatch(fromAssignLicensePage.doGetAssignableUsers({
        organizationId: +orgId,
        feature,
      }));
      setLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, feature]);

  if (!feature || !canFeatureAssignToUser(feature)) {
    return <Navigate to={notFound.path} replace />;
  }
  return (
    <div>
      <Dialog open={open} fullScreen onClose={handleClose} className={classes.root}>
        <DialogTitle className={classes.header}>
          <HighlightOffIcon className={classes.closeButton} onClick={handleClose} />
        </DialogTitle>
        <DialogContent>
          <Grid container flexDirection="column" alignItems="center">
            <Grid item className={classes.titleContainer}>
              <Typography variant="h2" className={classes.title}>
                {intl.formatMessage({ id: 'license_management.assign_license.title' })}
              </Typography>
              <Typography variant="h2" className={classes.title}>
                {OrganizationFeature.getFeatureFullName(feature)}
              </Typography>
            </Grid>
            {!config?.onpremise && config?.serviceCloudEnabled && (
              <Grid item className={classes.subtitleContainer}>
                <Typography variant="h4" className={classes.subtitle}>
                  {intl.formatMessage({ id: 'license_management.assign_license.subtitle1' })}
                </Typography>
                <Typography variant="h4" className={classes.subtitle}>
                  {intl.formatMessage(
                    { id: 'license_management.assign_license.subtitle2' },
                    {
                      a: (name: ReactNode) => (
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          component={RouterLink}
                          to={resolvePath(supportManagement, undefined, { orgId: `${orgId}` }, false)}
                        >
                          {name}
                        </Link>
                      ),
                    },
                  )}
                </Typography>
              </Grid>
            )}
            <Grid item className={classes.inputContainer}>
              <Autocomplete
                classes={{
                  groupUl: classes.list,
                  option: classes.muiOption,
                }}
                multiple
                filterOptions={customMethod}
                open
                fullWidth
                limitTags={1}
                id="assign-license"
                options={uniqBy([...selectedUsers, ...listUser], 'email')}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                getOptionLabel={option => option.email}
                PopperComponent={PopperComponent}
                PaperComponent={PaperComponent}
                popupIcon={null}
                value={selectedUsers}
                onChange={handleChange}
                noOptionsText={(
                  <NoResult
                    messages={listOrgUser.length === 0
                      ? [
                        intl.formatMessage({ id: 'license_management.assign_license.empty_assignable_user_list' }, { orgName: organization?.name }),
                      ]
                      : [intl.formatMessage({ id: 'license_utilization.machine_filter.no_results_found' })]}
                  />
                )}
                ListboxProps={{
                  className: classes.listBox,
                }}
                renderOption={(props, user, { selected }) => (
                  <li {...props}>
                    <div className={classes.option}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={selected}
                      />
                      <div className={classes.avatarContainer}>
                        <Avatar
                          className={classes.avatar}
                          src={getAvatarSource(user.avatar)}
                        />
                      </div>
                      <div className={classes.userInfo}>
                        <Typography variant="body2">
                          {getFullNameUser(user.firstName, user.lastName, user.email)}
                        </Typography>
                        <Typography variant="body2">
                          {user.email}
                        </Typography>
                      </div>
                    </div>
                  </li>
                )}
                groupBy={option => (selectedUsers.find(it => it.email === option.email)
                  ? intl.formatMessage({ id: 'user.body.selected' }).toUpperCase()
                  : intl.formatMessage({ id: 'license_utilization.filter.all' }).toUpperCase()
                )}
                renderGroup={renderGroup}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={selectedUsers.length > 0 ? '' : intl.formatMessage({ id: 'license_management.assign_license.placeholder' })}
                    classes={{
                      root: classes.inputWrapper,
                    }}
                  />
                )}
                {...(!focused ? {} : { renderTags: () => null })}
              />
            </Grid>
            <Grid>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={selectedUsers.length < 1}
                onClick={handleSubmit}
              >
                {intl.formatMessage({ id: 'license_management.assign_license.title' })}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {loading && <LoadingProgress />}
    </div>
  );
};
export default AssignLicenseDialogOld;
