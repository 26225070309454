import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { organization as organizationRoute } from '../../layout/routes';
import { OrganizationRole } from '../../models/organizationRole';
import { resolvePath, useQuery } from '../../routes';
import { useNavigate } from '../../routes/useNavigate';
import { fromAuth, fromCurrentOrgUser, fromOrganizations, fromOrganizationUsers, useAppDispatch } from '../../store';
import { ReactComponent as InviteUserIcon } from './inviteUserIcon.svg';
import LoadingProgress from '../../layout/LoadingProgress';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    height: '100%',
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inviteUser: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    verticalAlign: 'middle',
    justifyContent: 'center',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  icon: {
    marginBottom: theme.spacing(3),
    width: '100%',
    fontSize: '160px',
  },
  title: {
    color: theme.palette.primary.dark[500],
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  form: {
    position: 'relative',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    maxWidth: theme.spacing(70),
  },
  chipInput: {
    marginTop: theme.spacing(2),
    maxHeight: theme.spacing(15),
  },
  input: {
    minWidth: theme.spacing(5),
    maxWidth: theme.spacing(32),
    margin: 'dense !important',
    type: 'email',
  },
  chip: {
    marginTop: theme.spacing(1),
    marginLeft: 0,
    height: theme.spacing(2.5),
    maxWidth: theme.spacing(32),
  },
  outlined: {
    maxWidth: theme.spacing(68),
    overflow: 'auto',
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
  inviteButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    '& > *': {
      justifyContent: 'center',
    },
  },
}));

const InviteMember = () => {
  const classes = useStyles();
  const { get } = useQuery();
  const dispatch = useAppDispatch();
  const user = useSelector(fromAuth.selectUser);
  const organization = useSelector(fromOrganizations.selectSelectedOrganization);
  const orgTotalMember = useSelector(fromOrganizations.selectCount);
  const organizationUsersLoading = useSelector(fromOrganizationUsers.selectLoading);
  const organizationUser = useSelector(
    fromCurrentOrgUser.selectByOrganizationIdAndEmail(user!.email, organization?.id || 0),
  );
  const organizationId = Number(get('orgId'));
  const [emails, setEmails] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const { navigate, mergeQuery } = useNavigate();

  useEffect(() => {
    analytics.page();
  }, []);

  const handleInvitedEmailsSend = async (event: React.FormEvent<HTMLFormElement>) => {
    event.stopPropagation();
    event.preventDefault();

    if (emails.length > 0) {
      try {
        setLoading(true);
        const data = emails.map(email => ({
          organizationId,
          user: { email },
          role: OrganizationRole.MEMBER,
        }));

        // map data json to promise, one of which send a request to server to create org users
        const tasks = data.map(
          input => dispatch(fromOrganizationUsers.doCreateOrganizationUser(input)),
        );
        await Promise.all(tasks);
        analytics.track(
          'Invitation Sent',
          {
            user_id: user?.id,
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
            system_role: user?.roles,
            org_role: organizationUser?.role,
            org_id: organization?.id,
            org_name: organization?.name,
            total_members: orgTotalMember,
            invited_email_amount: emails.length,
          },
        );
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          user_id: user?.id,
          org_id: organization?.id,
          org_name: organization?.name,
          org_role: organizationUser?.role,
          system_role: user?.roles,
          total_members: organization?.orgUsers.length,
          invited_email_amount: emails.length,
          event: 'gtm_invitation_sent',
        });
      } finally {
        setEmails([]);
        setLoading(false);
        navigate(organizationRoute.path, mergeQuery({ orgId: organizationId }));
      }
    }
  };

  return (
    <Paper className={classes.root} elevation={0}>
      <Box className={classes.inviteUser}>
        <form className={classes.form} onSubmit={handleInvitedEmailsSend}>
          <InviteUserIcon className={classes.icon} />
          <Typography variant="h2" className={classes.title}>
            <FormattedMessage id="inviteuser.box.title" />
          </Typography>
          <Typography variant="body1" className={classes.subtitle}>
            <FormattedMessage id="inviteuser.box.subtitle" />
          </Typography>
          <Button
            color="primary"
            variant="contained"
            size="medium"
            type="submit"
            fullWidth
            className={classes.inviteButton}
          >
            <Typography variant="body1">
              <FormattedMessage id="inviteuser.form.invite" />
            </Typography>
          </Button>
          <Button
            color="primary"
            size="small"
            component={RouterLink}
            to={resolvePath(organizationRoute, {}, { orgId: organizationId }, false)}
          >
            <Typography variant="body1">
              <FormattedMessage id="inviteuser.form.gotoorg" />
            </Typography>
          </Button>
        </form>
      </Box>
      {(loading || organizationUsersLoading)
        && (
          <LoadingProgress />
        )}
    </Paper>
  );
};

export default InviteMember;
