import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import { useIntl } from 'react-intl';

const CopiedTooltip = withStyles({
  tooltip: {
    backgroundColor: '#5294FF',
  },
})(Tooltip);

const CopyableLink = (props: { link: string, displayLink: string, isCopyable: boolean }) => {
  const { link, displayLink, isCopyable } = props;
  const intl = useIntl();
  const [copied, setCopied] = useState(false);
  const handleClick = () => {
    if (!isCopyable) {
      return;
    }
    setCopied(true);
    navigator.clipboard.writeText(link);
  };
  return !isCopyable ? (
    <span>{displayLink}</span>
  ) : !copied ? (
    <Tooltip
      title={intl.formatMessage({ id: 'user_management.cell.invitation_link.tooltip' })}
      placement="top-start"
      onClose={() => setCopied(false)}
    >
      <span style={{ cursor: 'pointer' }} onClick={handleClick}>{displayLink}</span>
    </Tooltip>
  ) : (
    <CopiedTooltip
      title={intl.formatMessage({ id: 'user_management.cell.invitation_link.copied_tooltip' })}
      placement="top-start"
      onClose={() => setCopied(false)}
    >
      <span style={{ cursor: 'pointer' }} onClick={handleClick}>{displayLink}</span>
    </CopiedTooltip>
  );
};

export default CopyableLink;
