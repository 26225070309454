import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';
import emptyData from './empty-data.svg';

const useStyles = makeStyles(() => ({
  emptyDataContainer: {
    textAlign: 'center',
  },
  emptyDataImg: {
    display: 'block',
    margin: '0 auto',
  },
}));
const EmptyData = () => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <div className={classes.emptyDataContainer}>
      <img className={classes.emptyDataImg} src={emptyData} alt="empty-data" />
      <p>
        { intl.formatMessage({ id: 'license_utilization.empty_data' }) }
      </p>
    </div>
  );
};
export default EmptyData;
