import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import BankTransferIcon from '@mui/icons-material/AccountBalance';
import PaymentMethodComponent, { PaymentMethod } from './PaymentMethodComponent';
import { PaymentMethodType } from '../../../../models';
import { fromPaymentMethod } from '../../../../store/rootReducer';

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
  },
  iconSelected: {
    width: 21,
    height: 21,
    flexGrow: 0,
    display: 'flex',
    flexDirection: 'row',
    fill: '#1847ca !important',
  },
  icon: {
    width: 21,
    height: 21,
    flexGrow: 0,
    display: 'flex',
    flexDirection: 'row',
  },
}));

const PaymentMethodSelection = () => {
  const classes = useStyles();
  const selectedPaymentMethod = useSelector(fromPaymentMethod.selectSelectedPaymentMethod);

  const PAYMENT_METHODS: PaymentMethod[] = [
    {
      icon: <CreditCardIcon
        className={selectedPaymentMethod === PaymentMethodType.CREDIT_CARD
          ? classes.iconSelected
          : classes.icon}
      />,
      type: PaymentMethodType.CREDIT_CARD,
    },
    {
      icon: <BankTransferIcon
        className={selectedPaymentMethod === PaymentMethodType.BANK_TRANSFER
          ? classes.iconSelected
          : classes.icon}
      />,
      type: PaymentMethodType.BANK_TRANSFER,
    },
  ];

  const renderPaymentMethod = (paymentMethod: PaymentMethod) => (
    <PaymentMethodComponent
      paymentMethod={paymentMethod}
      isSelected={paymentMethod.type === selectedPaymentMethod}
    />
  );

  const renderPaymentMethods = () => PAYMENT_METHODS.map(
    paymentMethod => renderPaymentMethod(paymentMethod),
  );

  return (
    <>
      <Grid container>
        <Grid item>
          <Typography variant="h3" className={classes.title}>
            <FormattedMessage id="billinginfo.payment_method" />
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        {renderPaymentMethods()}
      </Grid>
    </>
  );
};

export default PaymentMethodSelection;
