import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { unwrapResult } from '@reduxjs/toolkit';
import { getData } from 'country-list';
import { useSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { object, string } from 'yup';
import LoadingProgress from '../../../layout/LoadingProgress';
import { useQuery } from '../../../routes';
import CountriesSelection from './CountriesSelection';
import { fromAuth, fromCurrentOrgUser, fromOrganizations, fromTestOpsBillingInformation, useAppDispatch } from '../../../store';

const useStyles = makeStyles(theme => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
  },
  label: {
    fontWeight: '500',
    marginBottom: theme.spacing(1),
    color: '#1D3066',
  },
  typographyButton: {
    margin: '0 auto',
    textTransform: 'capitalize',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  description: {
    fontSize: theme.spacing(1.5),
    fontStyle: 'italic',
    color: '#62839F',
    paddingTop: theme.spacing(1),
  },
  warning: {
    fontSize: theme.spacing(1.5),
    fontStyle: 'italic',
    color: 'red',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  billingInformationText: {
    marginTop: theme.spacing(3),
  },
  informationText: {
    marginBottom: theme.spacing(3),
  },
  getQuoteSubtitle: {
    marginLeft: theme.spacing(1),
    fontSize: theme.spacing(1.75),
  },
  getQuoteTitle: {
    fontSize: theme.spacing(2.5),
    display: 'inline',
  },
  getQuoteTitleContainer: {
    borderBottom: '1px solid #D5D8DD',
    paddingBottom: theme.spacing(2),
  },
}));

interface BillingInformationInputs {
  ccEmails: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  vatNumber: string;
  fullBusinessName: string;
}

const inputs: Array<keyof BillingInformationInputs> = [
  'ccEmails',
  'address1',
  'address2',
  'city',
  'state',
  'country',
  'postalCode',
  'vatNumber',
  'fullBusinessName',
];

const BillingInformation = () => {
  const classes = useStyles();
  const { get } = useQuery();
  const orgId = get('orgId');
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const organization = useSelector(fromOrganizations.selectSelectedOrganization);
  const user = useSelector(fromAuth.selectUser);
  const orgTotalMember = useSelector(fromOrganizations.selectCount);
  const currentOrgUser = useSelector(fromCurrentOrgUser.selectByOrganizationIdAndEmail(
    user?.email || '',
    Number(orgId),
  ));
  const countryOptions = getData();
  const isGetQuoteUrl = window.location.pathname.includes('get-quote');
  const billingInformation = useSelector(
    fromTestOpsBillingInformation.selectTestOpsBillingInformation,
  );
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (orgId) {
      dispatch(fromTestOpsBillingInformation.doGetTestOpsBillingInformation(+orgId));
    }
  }, [orgId, dispatch]);
  const schema = object().shape({
    ccEmails: string()
      .notRequired()
      .matches(
        /^$|[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.email.invalid' }),
      )
      .email(
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.email.invalid' }),
      ),
    fullBusinessName: string().trim()
      .required(
        intl.formatMessage(
          { id: 'billinginfo.billingcontact.error.required' },
          { field: 'Full Business Name' },
        ),
      ),
    country: string().trim()
      .required(
        intl.formatMessage(
          { id: 'billinginfo.billingcontact.error.required' },
          { field: 'Country' },
        ),
      )
      .matches(
        /^[a-zA-Z,. ]*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.country.invalid' }),
      ),
    state: string().trim()
      .required(
        intl.formatMessage(
          { id: 'billinginfo.billingcontact.error.required' },
          { field: 'State' },
        ),
      )
      .matches(
        /^[a-zA-Z ,.;'&/()-]*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.state.invalid' }),
      ),
    city: string().trim()
      .required(
        intl.formatMessage(
          { id: 'billinginfo.billingcontact.error.required' },
          { field: 'City' },
        ),
      )
      .matches(
        /^[a-zA-Z ,.;'&/()-]*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.city.invalid' }),
      ),
    address1: string().trim()
      .required(
        intl.formatMessage(
          { id: 'billinginfo.billingcontact.error.required' },
          { field: 'Address Line 1' },
        ),
      )
      .matches(
        /^[a-zA-Z0-9# .,;:'°]*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.address.invalid' }),
      ),
    address2: string()
      .matches(
        /^[a-zA-Z0-9# .,;:'°]*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.address.invalid' }),
      ),
    postalCode: string().trim()
      .required(
        intl.formatMessage(
          { id: 'billinginfo.billingcontact.error.required' },
          { field: 'Postal Code' },
        ),
      )
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.postalCode.invalid' }),
      ),
    vatNumber: string()
      .matches(
        /^[a-zA-Z0-9 .-]*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.vatId.invalid' }),
      ),
  });
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<BillingInformationInputs>({
    defaultValues: {
      ccEmails: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
      vatNumber: '',
      fullBusinessName: '',
    },
    resolver: yupResolver(schema),
  });

  const mapStateDataToInputs = () => {
    if (billingInformation) {
      inputs.forEach(input => {
        if (billingInformation[input]) {
          setValue(
            input,
            billingInformation[input].toString(),
          );
        } else {
          setValue(
            input,
            '',
          );
        }
      });
    }
  };
  useEffect(() => {
    mapStateDataToInputs();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [billingInformation, setValue]);

  const formBillingInformation = () => (
    <form onSubmit={handleSubmit(submitBillingContact)}>
      {inputs.map(inputKey => (
        <Grid key={inputKey} container className={classes.formContainer}>
          <Grid>
            <Typography variant="h5" className={classes.label}>
              {intl.formatMessage({
                id: `billinginfo.billingcontact.${inputKey}`,
              })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field }) => (inputKey !== 'country' ? (
                <TextField
                  id={inputKey}
                  {...field}
                  variant="outlined"
                  fullWidth
                  placeholder={intl.formatMessage({
                    id: `billinginfo.billingcontact.placeholder.${inputKey}`,
                  })}
                  size="small"
                  error={!!errors[inputKey]}
                  helperText={errors[inputKey]?.message}
                />
              ) : (
                <CountriesSelection
                  field={field}
                  inputKey={inputKey}
                  errors={errors}
                />
              ))}
              name={inputKey}
              control={control}
            />

          </Grid>
        </Grid>
      ))}
      <br />
      <Grid container>
        <Grid item xs={3} md={2} lg={1} />
        <Grid container item>
          <Button
            variant="contained"
            color="primary"
            type="submit"
          >
            {intl.formatMessage({ id: 'billinginfo.billingcontact.button.save' })}
          </Button>
        </Grid>
      </Grid>
      {loading && <LoadingProgress />}
    </form>
  );

  const billingInformationText = () => (
    <Grid className={classes.billingInformationText}>
      {inputs.map(inputKey => (
        <Grid key={inputKey} className={classes.informationText}>
          <Typography variant="h5" className={classes.label}>
            {intl.formatMessage({
              id: `billinginfo.billingcontact.${inputKey}`,
            })}
          </Typography>
          <Typography>
            {billingInformation && (inputKey === 'country' ? countryOptions.find(it => it.code === billingInformation[inputKey])?.name : billingInformation[inputKey])}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );

  const submitBillingContact = async (
    billingContactInputs: BillingInformationInputs,
  ) => {
    setLoading(true);
    await dispatch(
      fromTestOpsBillingInformation.doCreateTestOpsBillingInformation({
        organizationId: Number(orgId),
        ...billingContactInputs,
      }),
    )
      .then(unwrapResult)
      .then(() => {
        enqueueSnackbar(
          <FormattedMessage id="billinginfo.billingcontact.update.success" />,
          { variant: 'success' },
        );
        analytics.track(
          'Billing Information Saved',
          {
            user_id: user?.id,
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
            system_role: user?.roles,
            org_role: currentOrgUser?.role,
            org_id: organization?.id,
            org_name: organization?.name,
            total_members: orgTotalMember,
          },
        );
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          user_id: user?.id,
          org_role: currentOrgUser?.role,
          system_role: user?.roles,
          org_id: organization?.id,
          org_name: organization?.name,
          total_members: orgTotalMember,
          event: 'gtm_billing_information_saved',
        });
      })
      .catch((ex: any) => {
        // If error, restore inputs value to default value
        enqueueSnackbar(
          intl.formatMessage({ id: 'billing.payment.error.billing_info' }, { code: ex.message }),
          { variant: 'error' },
        );
        mapStateDataToInputs();
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Grid>
        {
          isGetQuoteUrl ? (
            <div className={classes.getQuoteTitleContainer}>
              <Typography variant="h6" className={classes.getQuoteTitle}>
                <FormattedMessage
                  id="subscriptions.testcloud.subscription_summary.get_quote"
                />
              </Typography>
              <span className={classes.getQuoteSubtitle}>
                <FormattedMessage
                  id="subscriptions.testcloud.subscription_summary.get_quote.note"
                />
              </span>
            </div>
          ) : (
            <Typography variant="h6">
              {intl.formatMessage({ id: 'billing.information' })}
            </Typography>
          )
        }

        {!isGetQuoteUrl && (
        <Typography variant="subtitle2" className={classes.description}>
          {intl.formatMessage({ id: 'billinginfo.billingcontact.description' })}
        </Typography>
        )}
        {!billingInformation && (
          <Typography variant="subtitle2" className={classes.warning}>
            {intl.formatMessage({ id: 'billinginfo.billingcontact.warning' }, { isGetQuoteUrl })}
          </Typography>
        )}
      </Grid>
      {billingInformation ? billingInformationText() : formBillingInformation()}
    </>
  );
};

export default BillingInformation;
