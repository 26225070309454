import Button from '@mui/material/Button';
import dateFormat from 'dateformat';
import sortBy from 'lodash/fp/sortBy';
import reverse from 'lodash/fp/reverse';
import { useEffect, useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { OrganizationRemovedUser,
  OrganizationUser,
  getFullNameUser,
  OrganizationUserFeature,
  Subscription,
  OrganizationFeature,
  isKreFloatingFeature,
  getFeatureOfHybridFeature } from '../../models';
import { OrganizationRole } from '../../models/organizationRole';
import { useQuery } from '../../routes';
import { fromOrganizations,
  fromOrganizationUsers,
  useAppDispatch,
  fromOrganizationRemovedUser,
  fromOrganizationUserFeature,
  fromSubscriptions } from '../../store';

interface ExportActiveAndRemovedUsersButtonProps {
  onLoading: () => Promise<void>,
  onComplete: () => void,
}

const ExportActiveAndRemovedUsersButton = (props: ExportActiveAndRemovedUsersButtonProps) => {
  const { onLoading, onComplete } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { get } = useQuery();
  const orgId = +(get('orgId') ?? 0);
  const [dataReady, setDataReady] = useState(false);
  const activeUsers = useSelector(fromOrganizationUsers.selectByOrganizationId(orgId));
  const removedUsers = useSelector(fromOrganizationRemovedUser
    .selectOrganizationRemovedUserByOrganizationId(orgId));
  const organization = useSelector(fromOrganizations.selectOrganizationById(orgId));
  const linkRef = useRef<any>(null);
  const userFeatures = useSelector(fromOrganizationUserFeature.selectFeaturesByOrganizationId(
    Number(orgId) || 0,
  ));
  const subscriptions = useSelector(fromSubscriptions
    .selectSubscriptionByOrganizationId(Number(orgId) || 0));
  const getLicenseAccessList = (
    predicate: (_: OrganizationUserFeature) => boolean,
    subscriptions: Subscription[],
    userFeatures: OrganizationUserFeature[],
  ) => {
  // logic check:
  // - if org has both KRE F and NL; user has no KRE => return KRE NL + user's licenses;
  // - if org has both KRE F and NL; user has KRE-NL => return user's licenses;
  // - if org has both KRE F and NL; user has KRE-F => return user's licenses;
  // - if org has KRE F; user has no KRE => return KRE F + user's licenses;
  // - if org has KRE NL; user has no KRE => return KRE NL + user's licenses;
  // - if org has KRE NL / F ; user has KRE NL / F  => return user's licenses;
    const orgHasKreNl = subscriptions
      .find(it => it.feature === OrganizationFeature.ENGINE) !== undefined;
    const orgHasKreFl = subscriptions
      .find(it => isKreFloatingFeature(it.feature)) !== undefined;
    const featuresOfUser = userFeatures
      .filter(predicate)
      .map(it => it.feature);
    const userHasKreFl = featuresOfUser
      .find(it => it === OrganizationFeature.UNLIMITED_ENGINE) !== undefined;
    const userHasKreNl = featuresOfUser
      .find(it => it === OrganizationFeature.ENGINE) !== undefined;
    const finalFeaturesOfUser = (orgHasKreNl && !userHasKreFl && !userHasKreNl)
      ? [...featuresOfUser, OrganizationFeature.ENGINE]
      : (!orgHasKreNl && orgHasKreFl && !userHasKreFl && !userHasKreNl)
        ? [...featuresOfUser, OrganizationFeature.UNLIMITED_ENGINE]
        : featuresOfUser;
    const activeSubscriptionFeatures = subscriptions.map(
      it => getFeatureOfHybridFeature(it.feature),
    );
    const finalActiveFeatures = finalFeaturesOfUser.filter(
      it => activeSubscriptionFeatures.includes(it),
    );
    return Array.from(new Set(finalActiveFeatures
      .map(f => OrganizationFeature.getFeatureHalfFullName(f))));
  }; // remove duplicate value
  const composeCsvData = (
    activeUsers: OrganizationUser[],
    removedUsers: OrganizationRemovedUser[],
  ) => {
    const result = [
      [
        intl.formatMessage({ id: 'user_management.export.user_id' }),
        intl.formatMessage({ id: 'user_management.export.name' }),
        intl.formatMessage({ id: 'user_management.export.email' }),
        intl.formatMessage({ id: 'user_management.export.role' }),
        intl.formatMessage({ id: 'user_management.export.join_date' }),
        intl.formatMessage({ id: 'user_management.export.removal_date' }),
        intl.formatMessage({ id: 'user_management.export.last_login' }),
        intl.formatMessage({ id: 'user_management.export.status' }),
        intl.formatMessage({ id: 'user_management.export.license_access' }),
      ],
    ].concat(
      reverse(sortBy(['createdAt'], activeUsers)).map(orgUser => {
        const userFeaturePredicate = (userFeature: OrganizationUserFeature) => orgUser.id
        === userFeature.organizationUserId;
        const licenseAccesses = getLicenseAccessList(
          userFeaturePredicate,
          subscriptions,
          userFeatures,
        );
        return [
          orgUser.user.id.toString(),
          // Why replace? c.f. https://github.com/react-csv/react-csv/issues/249 and KO-579
          getFullNameUser(orgUser.user.firstName, orgUser.user.lastName, orgUser.user.email).replace(/"/g, '""'),
          orgUser.user.email,
          OrganizationRole.getRoleFullName(orgUser.role),
          dateFormat(new Date(orgUser.createdAt), 'yyyy-mm-dd'),
          '', // removal date: only removedUsers have this field
          orgUser.user.lastLogin
            ? dateFormat(new Date(orgUser.user.lastLogin), 'yyyy-mm-dd HH:MM:ss')
            : '',
          intl.formatMessage({ id: 'user_management.export.status.active' }),
          !licenseAccesses.length ? '' : licenseAccesses.map(license => license).join(', '),
        ];
      }),
    ).concat(
      reverse(sortBy(['joinDate'], removedUsers)).map(orgUser => [
        orgUser.userId.toString(),
        // Why replace? c.f. https://github.com/react-csv/react-csv/issues/249 and KO-579
        getFullNameUser(orgUser.firstName, orgUser.lastName, orgUser.email).replace(/"/g, '""'),
        orgUser.email.toString(),
        OrganizationRole.getRoleFullName(orgUser.role),
        orgUser.joinDate ? dateFormat(new Date(orgUser.joinDate), 'yyyy-mm-dd') : '',
        dateFormat(new Date(orgUser.removedAt), 'yyyy-mm-dd'),
        orgUser.lastAccessedAt
          ? dateFormat(new Date(orgUser.lastAccessedAt), 'yyyy-mm-dd HH:MM:ss')
          : '',
        intl.formatMessage({ id: 'user_management.export.status.removed' }),
        '', // removal license access: only activeUsers have this field
      ]),
    );
    return result;
  };
  const handleClick = async () => {
    try {
      await onLoading();
    } catch (e) {
      return;
    }
    await Promise.all([
      dispatch(fromOrganizationUsers.doGetAllOrgUsers({ organizationId: +orgId })),
      dispatch(fromOrganizationRemovedUser
        .doGetAllOrganizationRemovedUser({ organizationId: +orgId })),
    ]);
    setDataReady(true);
    onComplete();
  };
  useEffect(() => {
    if (activeUsers && dataReady) {
      setTimeout(() => {
        linkRef.current.link.click();
      });
      setDataReady(false);
    }
  }, [activeUsers, dataReady]); // todo: add removedUsers
  return (
    <>
      <Button
        id="user_management.export_active.button.export_user"
        style={{ height: '100%' }}
        variant="contained"
        onClick={handleClick}
      >
        <FormattedMessage id="user_management.button.export_user" />
      </Button>
      {activeUsers && (
        <CSVLink
          data={composeCsvData(activeUsers, removedUsers)}
          filename={`${organization?.name}_Userlist_${dateFormat(new Date(), 'yyyymmdd')}.csv`}
          ref={linkRef}
        />
      )}
    </>
  );
};

export default ExportActiveAndRemovedUsersButton;
