import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ReactComponent as AddAccountIcon } from '../../layout/icons/AddAccount.svg';
import { fromAccountUsers, fromAuth, useAppDispatch } from '../../store';
import AccountItem from './AccountItem';
import { ChartLoadingIcon } from '../../layout/CustomIcon';
import CreateAccountDialog from '../../components/create-account-dialog';
import { useConfig } from '../../config';

const useStyles = makeStyles(theme => ({
  paperRoot: {
    maxWidth: 820,
    minWidth: 800,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2.5),
  },
  boxScroll: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: theme.spacing(35),
    overflow: 'auto',
  },
  subWelcome: {
    fontSize: theme.spacing(2.5),
    fontWeight: 600,
    color: '#233145',
  },
  boxFooter: {
    alignItems: 'center',
  },
  infoIcon: {
    cursor: 'pointer',
    width: theme.spacing(2),
    height: theme.spacing(2),
    flexGrow: 0,
    fontSize: theme.spacing(2.5),
    marginLeft: theme.spacing(1),
    color: '#808b9a',
  },
  accountTooltip: {
    textAlign: 'left',
    maxWidth: theme.spacing(200),
    color: '#ffffff',
    fontSize: theme.spacing(1.625),
    letterSpacing: 'normal',
  },
  hyperLink: {
    color: '#3794ff',
    fontWeight: 600,
  },
  createNewAccount: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(2),
  },
}));

const LearnMoreLink = () => {
  const classes = useStyles();
  return (
    <Link className={classes.hyperLink} underline="always" href="https://docs.katalon.com/docs/katalon-testops/get-started/roles-and-permissions">
      <FormattedMessage id="user_invitation.join.organization.sso.learn_more" />
    </Link>
  );
};

const TooltipDefaultInfoContent = () => {
  const intl = useIntl();
  const classes = useStyles();
  return (
    <div className={classes.accountTooltip}>
      {intl.formatMessage(
        {
          id: 'welcome.account.default.info.tooltip',
        },
        {
          link: <LearnMoreLink />,
        },
      )}
    </div>
  );
};

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: theme.spacing(48.125),
  },
}));

const ListAccount = () => {
  const classes = useStyles();
  const user = useSelector(fromAuth.selectUser);
  const lstAccount = useSelector(fromAccountUsers.selectAccountUsersByUserId(user!!.id));
  const [loading, setLoading] = useState(false);
  const [createAccountDialogOpen, setCreateAccountDialogOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { config } = useConfig();

  useEffect(() => {
    setLoading(true);
    dispatch(fromAccountUsers.doGetAccountUsersByUserId({ userId: user!!.id }))
      .then(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Paper elevation={1} className={classes.paperRoot}>
      <Box>
        <Typography variant="subtitle2" className={classes.subWelcome}>
          <FormattedMessage id="welcome.sub.my.account" />
          <CustomWidthTooltip title={<TooltipDefaultInfoContent />} placement="bottom-start">
            <InfoOutlinedIcon className={classes.infoIcon} />
          </CustomWidthTooltip>
        </Typography>
      </Box>
      {!loading && lstAccount.length === 0 && (
        <Typography variant="subtitle2" marginY={2}>
          <FormattedMessage id={!config?.onpremise ? 'welcome.account.no_account_text' : 'welcome.account.not_an_account_member_text'} />
        </Typography>
      )}
      {!loading && lstAccount.length > 0 && (
        <Box maxHeight={280} overflow="auto" mt={2}>
          {lstAccount.map(item => (
            <AccountItem
              key={item.id}
              id={item.accountId}
              viewedAs={item.role}
            />
          ))}
        </Box>
      )}
      {!config?.onpremise && !loading && (
        <Grid container className={classes.boxFooter} padding={2}>
          <AddAccountIcon
            style={{ cursor: 'pointer', color: '#f0f1f2' }}
            onClick={() => setCreateAccountDialogOpen(true)}
          />
          <Box paddingX={2}>
            <Typography variant="subtitle2" className={classes.createNewAccount}>
              <FormattedMessage id="welcome.create.new.account" />
            </Typography>
          </Box>
        </Grid>
      )}
      {loading && (
        <Box paddingY={4} display="flex" flexDirection="column" alignItems="center">
          <ChartLoadingIcon />
        </Box>
      )}
      {!config?.onpremise && (
        <CreateAccountDialog
          open={createAccountDialogOpen}
          onClose={() => setCreateAccountDialogOpen(false)}
        />
      )}
    </Paper>
  );
};

export default ListAccount;
