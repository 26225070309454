import WarningIcon from '@mui/icons-material/Warning';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import ChipInputEmails, { EmailChipData, EmailDuplicationDictionary } from '../../../components/chip-input/ChipInputEmails';
import CustomSwitch from '../../../components/switch/CustomSwitch';
import { useConfig } from '../../../config';
import { SsoOption } from '../../../models';
import { useQuery } from '../../../routes';
import { fromOrganizations, fromOrganizationUsers } from '../../../store';
import { LoginOptions } from '../utils';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  center: {
    textAlign: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  desc: {
    color: '#516393',
  },
  labelInsert: {
    color: '#233145',
    fontWeight: '500',
    '& > .required-asterisk': {
      color: '#db3131',
    },
  },
  clearAllButton: {
    height: theme.spacing(4.5),
  },
  gridLabelInsertAndClearAllButton: {
    height: theme.spacing(4.5),
  },
  chip: {
    float: 'left',
    margin: theme.spacing(0.5),
    maxWidth: theme.spacing(25),
  },
  invalidChip: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    borderStyle: 'solid',
    borderWidth: 'thin',
    '& > svg': {
      color: theme.palette.secondary.main,
    },
  },
  chipInputWrapper: {
    width: theme.spacing(100),
  },
  gridInsertEmails: {
    marginBottom: theme.spacing(2),
  },
  nextButton: {
    marginTop: theme.spacing(4),
    width: theme.spacing(40),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 'none',
    backgroundColor: '#1847ca',
    '&:hover': {
      boxShadow: 'none',
    },
    '& > span': {
      justifyContent: 'center',
    },
  },
  loginSettings: {
    marginTop: theme.spacing(3),
    flexDirection: 'column',
  },
  loginOption: {
    marginLeft: theme.spacing(1.5),
  },
  optionContainer: {
    margin: theme.spacing(2, 0),
  },
  optionDomain: {
    textDecoration: 'none',
    fontWeight: theme.typography.fontWeightBold,
    color: '#5294FF',
  },
}));

interface EnterEmailsProps {
  onNext: (emails: string[]) => void;
  selectedEmails: string[];
  onChangeEmails: (emails: string[]) => void;
  loginOptions: LoginOptions;
  handleChangeLoginOptions: (event: React.ChangeEvent<HTMLInputElement>, option: string) => void;
  isSsoEnabled: boolean;
  hasKseOrKreLicense: boolean;
}

const EnterEmails = (props: EnterEmailsProps) => {
  const {
    onNext,
    selectedEmails,
    onChangeEmails,
    loginOptions,
    handleChangeLoginOptions,
    isSsoEnabled,
  } = props;
  const { config } = useConfig();
  const classes = useStyles();
  const intl = useIntl();
  const { get } = useQuery();
  const orgId = Number(get('orgId')) || 0;
  const currentOrgUsers = useSelector(fromOrganizationUsers.selectByOrganizationId(orgId));
  const emailDict = useRef<EmailDuplicationDictionary>(selectedEmails.reduce(
    (acc: EmailDuplicationDictionary, currentEmail: string) => {
      acc[currentEmail] = true;
      return acc;
    },
    {},
  ));
  // map emails to chips:
  const selectedChipsWithEmail: EmailChipData[] = selectedEmails.map(it => ({
    value: it,
    text: it,
    valid: 'valid',
  }));
  const [chips, setChips] = useState<EmailChipData[]>(selectedChipsWithEmail);
  const [input, setInput] = useState<string>('');
  const [cntIncorrectFormat, setCntIncorrectFormat] = useState(0);
  const [cntExist, setCntExist] = useState(0);
  const organization = useSelector(fromOrganizations.selectOrganizationById(orgId));
  const domainLink = `https://${organization?.domain}.${process.env.REACT_APP_CUSTOM_DOMAIN_POSTFIX}`;

  const handleClearAll = () => {
    emailDict.current = {};
    setChips([]);
    setInput('');
    setCntIncorrectFormat(0);
    setCntExist(0);
    onChangeEmails([]);
  };
  return (
    <Grid
      id="user_management.invite.enter_emails.container"
      container
      direction="column"
      className={classes.root}
      alignItems="center"
    >
      <Grid id="user_management.invite.enter_emails.title_section" item>
        <div className={classes.center}>
          <Typography id="user_management.invite.enter_emails.title_invitation" variant="h2" className={classes.title}>
            {intl.formatMessage({ id: 'user_management.invite.enter_emails.title' })}
          </Typography>
          <Typography id="user_management.invite.enter_emails.desc_invitation" variant="h4" className={classes.desc}>
            {intl.formatMessage({ id: 'user_management.invite.enter_emails.desc' }, { br: <br /> })}
          </Typography>
        </div>
      </Grid>
      <Grid id="user_management.invite.enter_emails.input_section" item>
        <Grid
          id="user_management.invite.enter_emails.input_emails_gird"
          container
          direction="column"
          alignItems="flex-start"
          className={classes.gridInsertEmails}
        >
          <Grid
            id="user_management.invite.enter_emails.content_gird"
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.gridLabelInsertAndClearAllButton}
          >
            <Typography
              id="user_management.invite.enter_emails.label_insert"
              variant="body1"
              className={classes.labelInsert}
            >
              {intl.formatMessage({ id: 'user_management.invite.enter_emails.label_insert' })}
              <span className="required-asterisk">&nbsp;*</span>
            </Typography>
            {(chips.length > 0 || input.length > 0) && (
              <Button
                id="user_management.invite.enter_emails.clear_emails_button"
                variant="text"
                onClick={handleClearAll}
                className={classes.clearAllButton}
              >
                {intl.formatMessage({ id: 'user_management.invite.enter_emails.clear_all' })}
              </Button>
            )}
          </Grid>
          <Paper
            id="user_management.invite.enter_emails.email_container_paper"
            className={classes.chipInputWrapper}
            elevation={0}
          >
            <ChipInputEmails
              id="user_management.invite.enter_emails.chip_input_emails"
              {...{
                setCntIncorrectFormat,
                setCntExist,
                cntExist,
                cntIncorrectFormat,
                currentOrgUsers,
                setChips,
                chips,
                emailDict,
                initRows: 3,
                placeholder: intl.formatMessage({ id: 'user_management.invite.enter_emails.placeholder_insert' }),
              }}
            />
          </Paper>
        </Grid>
        {cntIncorrectFormat > 0 && (
          <Grid id="user_management.invite.enter_emails.error_fmt_email_gird" container alignItems="center">
            <Grid id="user_management.invite.enter_emails.error_fmt_icon_gird" item>
              <WarningIcon id="user_management.invite.enter_emails.error_fmt_icon" color="secondary" />
            </Grid>
            <Grid id="user_management.invite.enter_emails.error_fmt_message_gird" item>
              <Typography id="user_management.invite.enter_emails.error_fmt_message" variant="body1" color="secondary">
                &nbsp;
                {intl.formatMessage(
                  { id: 'user_management.invite.enter_emails.error.incorrect_format' },
                  { count: cntIncorrectFormat },
                )}
              </Typography>
            </Grid>
          </Grid>
        )}
        {cntExist > 0 && (
          <Grid id="user_management.invite.enter_emails.error_exist_email_gird" container alignItems="center">
            <Grid id="user_management.invite.enter_emails.error_exist_icon_gird" item>
              <WarningIcon id="user_management.invite.enter_emails.error_exist_icon" color="secondary" />
            </Grid>
            <Grid id="user_management.invite.enter_emails.error_exist_message_gird" item>
              <Typography id="user_management.invite.enter_emails.error_exist_message" variant="body1" color="secondary">
                &nbsp;
                {intl.formatMessage(
                  { id: 'user_management.invite.enter_emails.error.exist' },
                  { count: cntExist },
                )}
              </Typography>
            </Grid>
          </Grid>
        )}
        {
          isSsoEnabled && (
            <Grid
              id="user_management.invite.enter_emails.login_setting_gird"
              container
              className={classes.loginSettings}
            >
              <Grid id="user_management.invite.enter_emails.login_setting_content" item>
                <Typography
                  id="user_management.invite.enter_emails.login_setting_label"
                  variant="body1"
                  className={classes.labelInsert}
                >
                  { intl.formatMessage({ id: 'user_management.invite.login_options.title' }) }
                </Typography>
                <Typography id="user_management.invite.enter_emails.login_options_subtitle" variant="subtitle2">
                  { config?.onpremise ? intl.formatMessage({ id: 'user_management.invite.login_options.subtitle.onpremise' })
                    : intl.formatMessage({ id: 'user_management.invite.login_options.subtitle' }) }
                </Typography>
              </Grid>
              <Grid id="user_management.invite.enter_emails.login_option_content" item>
                <Grid
                  id="user_management.invite.enter_emails.login_option_sso_container"
                  container
                  alignItems="center"
                  className={classes.optionContainer}
                >
                  <CustomSwitch
                    id="user_management.invite.enter_emails.login_option_sso_switch"
                    checked={loginOptions.SSO_AUTH}
                    onChange={e => handleChangeLoginOptions(e, SsoOption.SSO_AUTH)}
                  />
                  <Typography
                    id="user_management.invite.enter_emails.login_option_sso_link"
                    variant="subtitle2"
                    className={classes.loginOption}
                  >
                    {
                      config?.onpremise
                        ? (
                          <FormattedMessage
                            id="user_management.invite.login_options.sso.onpremise"
                          />
                        )
                        : (
                          <FormattedMessage
                            id="user_management.invite.login_options.sso"
                            values={{
                              domain: organization?.domain,
                              a: () => (
                                <a className={classes.optionDomain} href={domainLink}>{domainLink?.replace('https://', '')}</a>
                              ),
                            }}
                          />
                        )
                    }
                  </Typography>
                </Grid>
                <Grid
                  id="user_management.invite.enter_emails.login_option_basic_container"
                  container
                  alignItems="center"
                  className={classes.optionContainer}
                >
                  <CustomSwitch
                    id="user_management.invite.enter_emails.login_option_basic_switch"
                    checked={loginOptions.BASIC_AUTH}
                    onChange={e => handleChangeLoginOptions(e, SsoOption.BASIC_AUTH)}
                  />
                  <Typography
                    id="user_management.invite.enter_emails.login_option_basic_link"
                    variant="subtitle2"
                    className={classes.loginOption}
                  >
                    {
                      config?.onpremise
                        ? (
                          <FormattedMessage
                            id="user_management.invite.login_options.username_password.onpremise"
                          />
                        )
                        : (
                          <FormattedMessage
                            id="user_management.invite.login_options.username_password"
                            values={{
                              domain: organization?.domain,
                              a: () => (
                                <a className={classes.optionDomain} href={domainLink}>{domainLink?.replace('https://', '')}</a>
                              ),
                            }}
                          />
                        )
                    }
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )
        }
      </Grid>
      <Button
        id="user_management.invite.enter_emails.next_button"
        variant="contained"
        color="primary"
        disabled={cntIncorrectFormat > 0 || cntExist > 0 || chips.length === 0}
        onClick={() => onNext(chips.map(it => it.text))}
        className={classes.nextButton}
      >
        { intl.formatMessage({ id: 'user_management.invite.enter_emails.next' }) }
      </Button>
    </Grid>
  );
};

export default EnterEmails;
