import { combineReducers } from '@reduxjs/toolkit';

import usersReducer, * as fromUsers from './usersSlice';
import authReducer, * as fromAuth from './authSlice';
import layoutReducer, * as fromLayout from './layoutSlice';
import organizationsReducer, * as fromOrganizations from './organizationsSlice';
import organizationUsersReducer, * as fromOrganizationUsers from './organizationUsersSlice';
import testOpsPaymentMethodReducer, * as fromTestOpsPaymentMethod from './testOpsPaymentMethodSlice';
import testOpsConfigurationReducer, * as fromTestOpsConfiguration from './testOpsConfigurationSlice';
import testOpsBillingInformationReducer, * as fromTestOpsBillingInformation from './testOpsBillingInformationSlice';
import dashboardReducer, * as fromDashboard from './dashboardSlice';
import testResultCountReducer, * as fromTestResultCount from './testResultCountSlice';
import licenseUtilizationReducer, * as fromLicenseUtilization from './licenseUtilizationSlice';
import machinesReducer, * as fromMachines from './machineSlice';
import organizationConfigurationSlice, * as fromOrganizationConfigurations from './organizationConfigurationSlice';
import organizationUserFeatureSlice, * as fromOrganizationUserFeature from './organizationUserFeatureSlice';
import userInvitationSlice, * as fromUserInvitation from './userInvitationSlice';
import subscriptionSlice, * as fromSubscriptions from './subscriptionsSlice';
import testOpsSubscriptionSlice, * as fromTestOpsSubscriptions from './testOpsSubscriptionsSlice';
import visualTestingSubscriptionsSlice, * as fromVisualTestingSubscriptions from './visualTestingSubscriptionsSlice';
import studioSubscriptionsSlice, * as fromStudioSubscriptions from './studioSubscriptionsSlice';
import organizationRemovedUserSlice, * as fromOrganizationRemovedUser from './organizationRemovedUserSlice';
import userPendingInvitationTabSlice, * as fromUserPendingInvitationTab from './userPendingInvitationTabSlice';
import organizationUserSsoSlice, * as fromOrganizationUserSso from './organizationUserSsoSlice';
import organizationFeatureFlagSlice, * as fromOrganizationFeatureFlag from './organizationFeatureFlagSlice';
import currentOrgUserSlice, * as fromCurrentOrgUser from './currentOrgUserSlice';
import testOpsProjectsSlice, * as fromTestOpsProject from './testOpsProjectSlice';
import testOpsUserSsoInvitationSlice, * as fromTestOpsUserSsoInvitation from './testOpsUserSsoInvitationSlice';
import userSsoInvitationSlice, * as fromUserSsoInvitation from './userSsoInvitationSlice';
import acceptInviteSsoPageSlice, * as fromAcceptInviteSsoPage from './acceptSsoPageSlice';
import acceptInvitePageSlice, * as fromAcceptInvitePage from './acceptInvitationPageSlice';
import assignLicensePageSlice, * as fromAssignLicensePage from './assignLicensePageSlice';
import productInterestSlice, * as fromProductInterest from './productInterestSlice';
import serviceCloudOrganizationUserSlice, * as fromServiceCloudOrganizationUser from './serviceCloudOrganizationUserServiceSlice';
import testOpsEnterpriseProductSlice, * as fromTestOpsEnterpriseProduct from './testOpsEnterpriseProductSlice';
import invoiceSlice, * as fromInvoices from './invoiceSlice';
import testCloudSubscriptionsSlice, * as fromTestCloudSubscriptions from './testCloudSubscriptionsSlice';
import testCloudUsageSlice, * as fromTestCloudUsage from './testCloudUsageSlice';
import testCloudQuoteSlice, * as fromTestCloudQuote from './testCloudQuote';
import accountUsersSlice, * as fromAccountUsers from './accountUsersSlice';
import accountsSlice, * as fromAccounts from './accountSlice';
import testOpsPlatformSubscriptionsSlice, * as fromTestOpsPlatformSubscriptions from './testOpsPlatformSubscriptionsSlice';
import testOpsPlatformConfigurationSlice, * as fromTestOpsPlatformConfiguration from './testOpsPlatformConfigurationSlice';
import billingInformationReducer, * as fromBillingInformation from './billingInformationSlice';
import activeLicenseReducer, * as fromActiveLicense from './activateLicenseSlice';
import paymentMethodReducer, * as fromPaymentMethod from './paymentMethodSlice';
import existingAccountSlice, * as fromExistingAccounts from './existingAccountSlice';
import organizationWhitelistIpsSlice, * as fromOrganizationWhitelistIps from './organizationWhitelistIpsSlice';
import starterPackageSlice, * as fromStarterPackage from './starterPackageSlice';
import testOpsTeamsSlice, * as fromTestOpsTeam from './testOpsTeamSlice';
import orderSlice, * as fromOrder from './ordersSlice';
import userGroupSlice, * as fromUserGroup from './userGroupSlice';
import userGroupUserSlice, * as fromUserGroupUser from './userGroupUserSlice';
import securitySettingSlice, * as fromSecuritySetting from './securitySettingSlice';

const rootReducer = combineReducers({
  [fromUsers.USER_FEATURE_KEY]: usersReducer,
  [fromAuth.AUTH_FEATURE_KEY]: authReducer,
  [fromLayout.LAYOUT_FEATURE_KEY]: layoutReducer,
  [fromOrganizations.ORGANIZATION_FEATURE_KEY]: organizationsReducer,
  [fromOrganizationUsers.ORGANIZATION_USERS_FEATURE_KEY]: organizationUsersReducer,
  [fromTestOpsPaymentMethod.TESTOPS_PAYMENT_METHOD_FEATURE_KEY]: testOpsPaymentMethodReducer,
  [fromTestOpsConfiguration.CONFIGURATION_FEATURE_KEY]: testOpsConfigurationReducer,
  [fromTestOpsBillingInformation.TEST_OPS_BILLING_INFORMATION_FEATURE_KEY]:
    testOpsBillingInformationReducer,
  [fromDashboard.DASHBOARD_FEATURE_KEY]: dashboardReducer,
  [fromTestResultCount.TEST_RESULT_COUNT_FEATURE_KEY]: testResultCountReducer,
  [fromLicenseUtilization.LICENSE_UTILIZATION_FEATURE_KEY]: licenseUtilizationReducer,
  [fromMachines.MACHINE_FEATURE_KEY]: machinesReducer,
  [fromOrganizationConfigurations.ORGANIZATION_CONFIGURATION_FEATURE_KEY]:
  organizationConfigurationSlice,
  [fromOrganizationWhitelistIps.ORGANIZATION_WHITELIST_IPS_FEATURE_KEY]:
  organizationWhitelistIpsSlice,
  [fromOrganizationUserFeature.ORGANIZATION_USER_FEATURE_KEY]: organizationUserFeatureSlice,
  [fromUserInvitation.USER_INVITATION_KEY]: userInvitationSlice,
  [fromSubscriptions.SUBSCRIPTION_FEATURE_KEY]: subscriptionSlice,
  [fromTestOpsSubscriptions.TESTOPS_SUBSCRIPTION_KEY]: testOpsSubscriptionSlice,
  [fromVisualTestingSubscriptions.VISUAL_TESTING_SUBSCRIPTION_KEY]:
  visualTestingSubscriptionsSlice,
  [fromStudioSubscriptions.STUDIO_SUBSCRIPTION_KEY]: studioSubscriptionsSlice,
  [fromTestOpsPlatformSubscriptions.TESTOPS_PLATFORM_SUBSCRIPTION_KEY]:
  testOpsPlatformSubscriptionsSlice,
  [fromExistingAccounts.EXISTING_ACCOUNT_KEY]:
  existingAccountSlice,
  [fromTestOpsPlatformConfiguration.TESTOPS_PLATFORM_CONFIGURATION_KEY]:
  testOpsPlatformConfigurationSlice,
  [fromOrganizationRemovedUser.ORGANIZATION_REMOVED_USER_FEATURE_KEY]: organizationRemovedUserSlice,
  [fromUserPendingInvitationTab.USER_PENDING_INVITATION_TAB_KEY]: userPendingInvitationTabSlice,
  [fromOrganizationUserSso.ORGANIZATION_USER_SSO_FEATURE_KEY]: organizationUserSsoSlice,
  [fromOrganizationFeatureFlag.ORGANIZATION_FEATURE_FLAG_FEATURE_KEY]: organizationFeatureFlagSlice,
  [fromCurrentOrgUser.CURRENT_ORGANIZATION_USER_FEATURE_KEY]: currentOrgUserSlice,
  [fromTestOpsProject.TESTOPS_PROJECTS_FEATURE_KEY]: testOpsProjectsSlice,
  [fromTestOpsUserSsoInvitation.TESTOPS_USER_SSO_INVITAION_FEATURE_KEY]:
    testOpsUserSsoInvitationSlice,
  [fromUserSsoInvitation.USER_SSO_INVITAION_FEATURE_KEY]: userSsoInvitationSlice,
  [fromAcceptInviteSsoPage.ACCEPT_INVITE_SSO_PAGE_KEY]: acceptInviteSsoPageSlice,
  [fromAcceptInvitePage.ACCEPT_INVITE_PAGE_KEY]: acceptInvitePageSlice,
  [fromAssignLicensePage.ASSIGN_LICENSE_KEY]: assignLicensePageSlice,
  [fromProductInterest.PRODUCT_INTEREST_FEATURE_KEY]: productInterestSlice,
  [fromServiceCloudOrganizationUser.SERVICE_CLOUD_ORG_USER_KEY]:
  serviceCloudOrganizationUserSlice,
  [fromTestOpsEnterpriseProduct.TESTOPS_ENTERPRISE_PRODUCT_KEY]: testOpsEnterpriseProductSlice,
  [fromInvoices.INVOICE_FEATURE_KEY]: invoiceSlice,
  [fromTestCloudSubscriptions.TESTCLOUD_SUBSCRIPTION_KEY]: testCloudSubscriptionsSlice,
  [fromTestCloudUsage.TESTCLOUD_USAGE_KEY]: testCloudUsageSlice,
  [fromTestCloudQuote.TESTCLOUD_QUOTE_KEY]: testCloudQuoteSlice,
  [fromAccountUsers.ACCOUNT_USERS_FEATURE_KEY]: accountUsersSlice,
  [fromAccounts.ACCOUNT_FEATURE_KEY]: accountsSlice,
  [fromBillingInformation.BILLING_INFORMATION_FEATURE_KEY]: billingInformationReducer,
  [fromActiveLicense.ACTIVATE_LICENSE_FEATURE_KEY]: activeLicenseReducer,
  [fromPaymentMethod.PAYMENT_METHOD_FEATURE_KEY]: paymentMethodReducer,
  [fromStarterPackage.STARTER_PACKAGE_FEATURE_KEY]: starterPackageSlice,
  [fromTestOpsTeam.TESTOPS_TEAMS_FEATURE_KEY]: testOpsTeamsSlice,
  [fromOrder.ORDER_FEATURE_KEY]: orderSlice,
  [fromUserGroup.USER_GROUP_FEATURE_KEY]: userGroupSlice,
  [fromUserGroupUser.USER_GROUP_USER_FEATURE_KEY]: userGroupUserSlice,
  [fromSecuritySetting.SECURITY_SETTINGS_FEATURE_KEY]: securitySettingSlice,
});

export {
  fromUsers,
  fromAuth,
  fromLayout,
  fromOrganizations,
  fromOrganizationUsers,
  fromTestOpsPaymentMethod,
  fromTestOpsConfiguration,
  fromTestOpsBillingInformation,
  fromDashboard,
  fromTestResultCount,
  fromLicenseUtilization,
  fromMachines,
  fromOrganizationUserFeature,
  fromUserInvitation,
  fromSubscriptions,
  fromTestOpsSubscriptions,
  fromVisualTestingSubscriptions,
  fromStudioSubscriptions,
  fromTestOpsPlatformSubscriptions,
  fromExistingAccounts,
  fromOrganizationRemovedUser,
  fromUserPendingInvitationTab,
  fromOrganizationUserSso,
  fromOrganizationFeatureFlag,
  fromCurrentOrgUser,
  fromTestOpsProject,
  fromTestOpsUserSsoInvitation,
  fromUserSsoInvitation,
  fromAcceptInviteSsoPage,
  fromAcceptInvitePage,
  fromAssignLicensePage,
  fromProductInterest,
  fromServiceCloudOrganizationUser,
  fromTestOpsEnterpriseProduct,
  fromInvoices,
  fromTestCloudSubscriptions,
  fromTestCloudUsage,
  fromTestCloudQuote,
  fromAccountUsers,
  fromAccounts,
  fromOrganizationConfigurations,
  fromTestOpsPlatformConfiguration,
  fromBillingInformation,
  fromActiveLicense,
  fromPaymentMethod,
  fromOrganizationWhitelistIps,
  fromStarterPackage,
  fromTestOpsTeam,
  fromOrder,
  fromUserGroup,
  fromUserGroupUser,
  fromSecuritySetting,
};
export default rootReducer;
