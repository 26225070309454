import Axios from 'axios';
import {
  DataResponse,
  OrganizationUser,
  PageableResponse,
  User,
  OrganizationUserFeature,
  OrganizationUserView,
} from '../models';
import { composeQuery, Query } from '../models/query';

export const getOrganizationUsers = async (...criteria: Query<OrganizationUserView>[]) => (
  await Axios.get<PageableResponse<OrganizationUser>>('/v1/org-users', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    params: composeQuery<OrganizationUserView>(...criteria),
    data: {},
  })
).data;

export const getAssignableUsers = async (input: Pick<OrganizationUserFeature, 'organizationId' | 'feature'>, ...criteria: Query<OrganizationUserView>[]) => (
  await Axios.get<PageableResponse<OrganizationUser>>(`/v1/org-users/assign/${input.feature}`, {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    params: composeQuery<OrganizationUserView>(...criteria),
    data: {},
  })
).data;

export const deleteOrganizationUser = async (organizationUserId: OrganizationUser['id']) => (await (Axios.delete<DataResponse<OrganizationUser>>(`/v1/org-users/${organizationUserId}`))).data.data;

export const createOrganizationUser = async (input: Required<Pick<OrganizationUser, 'organizationId' | 'role'>> & { user: Pick<User, 'email'> }) => (await (Axios.post<DataResponse<OrganizationUser>>('/v1/org-users', input))).data.data;

export const updateOrganizationUser = async (input: Required<Pick<OrganizationUser, 'id' | 'role'>> & Partial<Pick<OrganizationUser, 'lastAccessedAt'>>) => (await Axios.put<DataResponse<OrganizationUser>>(`/v1/org-users/${input.id}`, input)).data.data;

export const getOrganizationUserFeatures = async (...criteria: Query<OrganizationUserFeature>[]) => (await Axios.get<PageableResponse<OrganizationUserFeature>>('/v1/org-user-features', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: composeQuery<OrganizationUserFeature>(...criteria),
  data: {},
})).data;

export const createOrganizationUserFeature = async (input: Required<Pick<OrganizationUserFeature, 'feature' | 'organizationUserId'>>) => (await (Axios.post<DataResponse<OrganizationUserFeature>>('/v1/org-user-features', input))).data.data;
