import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import { Dialog, DialogBody, DialogFooter, DialogHeader } from '@katalon-studio/katalon-ui/Dialog';
import {
  Account,
} from '../../models';
import {
  fromAccounts,
  useAppDispatch,
} from '../../store';
import LoadingProgress from '../../layout/LoadingProgress';

const useStyles = makeStyles(theme => ({
  header: {
    fontSize: theme.spacing(2.5),
    fontWeight: theme.typography.fontWeightMedium,
    color: '#233145',
    width: theme.spacing(72.5),
  },
  header1: {
    fontSize: theme.spacing(2.5),
    fontWeight: theme.typography.fontWeightMedium,
    color: '#233145',
  },
  container: {
    marginTop: theme.spacing(3),
  },
  title: {
    marginTop: theme.spacing(3),
    fontSize: theme.spacing(1.75),
    color: '#233145',
    width: theme.spacing(72.5),
  },
  title_x: {
    marginTop: theme.spacing(1.25),
    fontSize: theme.spacing(1.75),
    fontWeight: theme.typography.fontWeightMedium,
    color: '#233145',
  },
  boxKse: {
    width: '100%',
    borderBottom: '1px solid #DCDFE6',
  },
  description: {
    fontSize: theme.spacing(1.5),
    color: '#808b9a',
    marginBottom: theme.spacing(2.5),
    lineHeight: 1.5,
  },
  input: {
    width: theme.spacing(27.5),
    marginBottom: theme.spacing(1),
  },
  switch: {
    marginTop: theme.spacing(0.1),
  },
  button: {
    marginTop: theme.spacing(2.5),
    fontSize: theme.spacing(1.75),
    paddingY: theme.spacing(1),
    lineHeight: theme.spacing(3),
    fontWeight: theme.typography.fontWeightMedium,
  },
  error: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(1),
  },
  root: {
    marginTop: theme.spacing(4),
    minWidth: theme.spacing(96),
    borderRadius: theme.spacing(1),
    display: 'flex',
  },
  dangerZoneBox: {
    margin: theme.spacing(3),
    display: 'column',
  },
  proceedBtn: {
    padding: theme.spacing(2),
    color: '#ffffff',
    background: '#2a3dc7',
    height: theme.spacing(5),
    alignItems: 'center',
    borderRadius: theme.spacing(0.5),
    '&:hover': {
      background: '#2a3dc7',
    },
  },
  cancelBtn: {
    padding: theme.spacing(2),
    color: '#233145',
    background: '#F0F1F2',
    height: theme.spacing(5),
    alignItems: 'center',
    borderRadius: theme.spacing(0.5),
  },
  textDeleteBtn: {
    color: '#ffffff',
  },
  dialog: {
    position: 'absolute',
    borderRadius: theme.spacing(0.75),
    minWidth: theme.spacing(75),
    padding: theme.spacing(2, 2.5),
  },
  dialogBox: {
    display: 'flex',
    maxHeight: theme.spacing(3.75),
    alignItems: 'center',
  },
  dialogDescription: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    fontSize: theme.spacing(1.75),
    color: '#233145',
    fontWeight: theme.typography.fontWeightMedium,
  },
  accountNameTextField: {
    marginBottom: theme.spacing(3),
  },
  buttonGroup: {
    gap: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  iconClose: {
    fontSize: 'large',
  },
  iconButton: {
    ariaLabel: 'close',
    edge: 'end',
    size: 'large',
    marginTop: theme.spacing(-0.5),
    marginRight: theme.spacing(-2),
  },
  alertBanner: {
    backgroundColor: '#ffede6',
    height: theme.spacing(4.75),
    borderRadius: theme.spacing(0.5),
    position: 'relative',
    marginTop: theme.spacing(1.5),
  },
  alertText: {
    whiteSpace: 'nowrap',
    fontSize: theme.spacing(1.75),
    fontWeight: theme.typography.fontWeightMedium,
  },
  alertIcon: {
    fontSize: theme.spacing(1.75),
    width: theme.spacing(2.75),
    position: 'absolute',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(0.125),
    display: 'block',
  },
  alertCenter: {
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
  },
  alertArea: {
    marginLeft: theme.spacing(4),
  },
  helpIcon: {
    color: '#808080',
    fontSize: theme.spacing(2.5),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(-0.5),
  },
  muiTooltip: {
    color: '#808080',
    fontSize: theme.spacing(1.5),
  },
  dlgActions: {
    width: '100%',
    padding: theme.spacing(0, 3, 3, 3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  dlgTitle: {
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    width: '100%',
    fontSize: theme.spacing(2),
    fontWeight: 700,
    color: '#233145',
    display: 'flex',
  },
  editNameLabel: {
    flexGrow: 2,
    fontWeight: 500,
    fontSize: theme.spacing(3),
  },
  dlgContent: {
    width: '100%',
  },
  subtext: {
    width: '100%',
    marginBottom: theme.spacing(1),
    '& p': {
      fontSize: theme.spacing(1.75),
      fontWeight: 500,
    },
  },
}));

interface AISettingProperties {
  account: Account;
  enabled: boolean;
}

const AISetting = (props: AISettingProperties) => {
  const { account, enabled } = props;
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [aiSettingEnable, setAISettingEnable] = useState(enabled);

  const handleOnSwitchAISetting = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const isEnable: boolean = event.target.checked;
    setAISettingEnable(isEnable);
    setOpen(true);
  };

  const handleClose = () => {
    setAISettingEnable(prevSetting => !prevSetting);
    setOpen(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    await dispatch(fromAccounts.doUpdateAISetting({
      accountId: account.id,
      enableAi: aiSettingEnable,
    }))
      .then(unwrapResult)
      .then(() => {
        enqueueSnackbar(
          <FormattedMessage id="account_setting.ai_setting.update.successfully" />,
          { variant: 'success' },
        );
      })
      .catch(() => {
        enqueueSnackbar(
          <FormattedMessage id="error.unknown" />,
          { variant: 'error' },
        );
        setAISettingEnable(prevSetting => !prevSetting);
      })
      .finally(() => {
        setLoading(false);
        setOpen(false);
      });
  };

  return (
    <>
      <Paper key="app.page.account.settings.danger.zone" elevation={1} className={classes.root}>
        <Box className={classes.dangerZoneBox}>
          <Typography variant="subtitle2" className={classes.header}>
            <FormattedMessage id="account_setting.ai_setting.header" />
            <Tooltip
              componentsProps={{ tooltip: { sx: { background: '#616161', maxWidth: '284px', fontSize: '10px', padding: '4px 4px', marginTop: '8px !important' } }, arrow: { sx: { background: 'none !important' } } }}
              title={intl.formatMessage({ id: 'account_setting.ai_setting.tooltip_ai_control' })}
              placement="bottom"
              arrow
            >
              <HelpOutlineIcon className={classes.helpIcon} />
            </Tooltip>
          </Typography>
          <Typography variant="subtitle2" className={classes.title}>
            <FormattedMessage id="account_setting.ai_setting.title" />
          </Typography>
          <Box display="flex" className={classes.container}>
            <Switch
              id="account_setting.ai_setting.switch"
              className={classes.switch}
              checked={aiSettingEnable}
              onChange={handleOnSwitchAISetting}
              color="primary"
            />
            <Typography variant="subtitle2" className={classes.title_x}>
              <FormattedMessage id="account_setting.ai_setting.automatically_allow.title" />
            </Typography>
          </Box>
        </Box>
      </Paper>
      <Dialog
        classes={{ paper: classes.dialog }}
        open={open}
        maxWidth="sm"
        placeholder="center"
        fullWidth
      >
        <DialogHeader
          id="account_setting.ai_setting.proceed_account_dialog.header"
          className={classes.dlgTitle}
          onClick={handleClose}
        >
          <Box className={classes.editNameLabel}>
            <FormattedMessage id="account_setting.ai_setting.proceed_account_dialog.header" />
          </Box>
        </DialogHeader>
        <DialogBody
          id="account_setting.ai_setting.proceed_account_dialog.body"
          className={classes.dlgContent}
        >
          <Box className={classes.subtext}>
            <Typography>
              <FormattedMessage id="account_setting.ai_setting.proceed_account_dialog.description" />
            </Typography>
          </Box>
        </DialogBody>
        <DialogFooter
          id="account_setting.ai_setting.proceed_account_dialog.footer"
          className={classes.dlgActions}
        >
          <Button
            id="account_setting.ai_setting.proceed_account_dialog.form.button.cancel"
            className={classes.cancelBtn}
            variant="text"
            type="button"
            size="small"
            color="primary"
            onClick={handleClose}
          >
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            id="account_setting.ai_setting.proceed_account_dialog.form.button.delete"
            className={classes.proceedBtn}
            onClick={() => handleSubmit()}
            variant="contained"
          >
            <FormattedMessage id="account_setting.ai_setting.proceed_account_dialog.button" />
          </Button>
        </DialogFooter>
      </Dialog>
      {loading && <LoadingProgress />}
    </>
  );
};

export default AISetting;
