import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import LoadingProgress from '../../../layout/LoadingProgress';
import { resizeImage } from '../../../services/organization';
import { fromAuth, fromUsers, useAppDispatch } from '../../../store';
import { ReactComponent as UploadIcon } from './uploadicon.svg';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarWrapper: {
    display: 'grid',
    alignItems: 'end',
  },
  profileAvatar: {
    width: theme.spacing(16),
    height: theme.spacing(16),
    gridArea: '1/1',
    boxShadow: '0px 4px 8px 0px rgba(214, 214, 214, 0.25)',
    border: '8px solid rgba(255, 255, 255, 1)',
  },
  uploadButton: {
    cursor: 'pointer',
    padding: 0,
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  uploadButtonWrapper: {
    gridArea: '1/1',
    justifySelf: 'end',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    zIndex: 1,
    opacity: 1,
    '&:hover': {
      opacity: 1,
    },
  },
}));

const defaultAvatar = 'https://katalon-testops.s3.amazonaws.com/image/icon/defaultAvatar.png';

const UploadAvatar = () => {
  const user = useSelector(fromAuth.selectUser);
  const [resizing, setResizing] = useState(false);
  const [imageURI, setImageURI] = useState(user?.avatar);

  const dispatch = useAppDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (user?.avatar) {
      setImageURI(user.avatar);
    }
  }, [user]);

  if (!user) return <></>;

  const imageURISyncHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      try {
        setResizing(true);
        const resizedImage = await resizeImage(event.target.files[0]);
        setImageURI(resizedImage);
        await dispatch(
          fromUsers.doUpdateUser({
            email: user.email,
            testingSolutions: user.testingSolutions,
            id: user!.id,
            firstName: user?.firstName,
            lastName: user?.lastName,
            jobTitle: user?.jobTitle,
            avatar: resizedImage,
          }),
        ).then(unwrapResult);
      } catch (error: any) {
        // show error here
      } finally {
        setResizing(false);
      }
    }
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.avatarWrapper}>
        <Avatar className={classes.profileAvatar} src={imageURI || defaultAvatar} />
        <Tooltip title="Upload Avatar">
          <>
            <label htmlFor="upload-avatar" className={classes.uploadButtonWrapper}>
              <UploadIcon className={classes.uploadButton} />
              <input
                accept="image/png, image/jpeg"
                type="file"
                id="upload-avatar"
                onChange={e => imageURISyncHandler(e)}
                hidden
              />
            </label>
          </>
        </Tooltip>
        {resizing && <LoadingProgress />}
      </Box>
    </Box>
  );
};

export default UploadAvatar;
