import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  loading: {
    zIndex: 99999,
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
  },
  overlay: {
    background: theme.palette.getContrastText(theme.palette.background.default),
    opacity: 0.5,
  },
  spinner: {
    margin: 'auto',
  },
}));

const LoadingProgress = () => {
  const classes = useStyles();
  return (
    <div className={classes.loading}>
      <div className={clsx(classes.loading, classes.overlay)} />
      <CircularProgress classes={{ root: classes.spinner }} />
    </div>
  );
};
export default LoadingProgress;
