import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import { AppState } from '.';
import { exceptionOf, Organization, SerializedException } from '../models';
import { Operator, Query } from '../models/query';
import { OrganizationConfiguration } from '../models/organizationConfiguration';
import { OrganizationConfigurationService } from '../services';
import { OrganizationConfigurationName } from '../models/organizationConfigurationName';
import { SamlIdp, CustomDomain } from '../models/organizationCustomDomainAndSSO';

export const ORGANIZATION_CONFIGURATION_FEATURE_KEY = 'organizationConfigurations';

interface OrganizationConfigurationsState extends EntityState<OrganizationConfiguration> {
  loading: boolean;
  count: number;
  error?: SerializedException;
}

const organizationConfigurationAdapter = createEntityAdapter<OrganizationConfiguration>();
const MAX_NUMBER_OF_RECORDS = 50;

export const createInitialState = ():
OrganizationConfigurationsState => organizationConfigurationAdapter.getInitialState({
  loading: false,
  count: 0,
});

export const doGetByOrganizationId = createAsyncThunk(
  'organizationConfigurations/getOrganizationConfigurationByOrganizationId',
  async (organizationId: Organization['id']) => {
    const criteria: Array<Query<OrganizationConfiguration>> = [
      { field: 'organizationId', operator: Operator.EQ, value: organizationId },
    ];
    const firstResponse = await OrganizationConfigurationService.get(...criteria);
    const numberOfRequests = Math.ceil(firstResponse.total / MAX_NUMBER_OF_RECORDS);

    const remainingRequests = [];
    for (let i = 1; i < numberOfRequests; i += 1) {
      const request = OrganizationConfigurationService.get(
        ...criteria,
        { offset: MAX_NUMBER_OF_RECORDS * i },
      );
      remainingRequests.push(request);
    }
    const remainingResponses = await Promise.all(remainingRequests);
    const organizationConfigurations = [...firstResponse.data];
    remainingResponses.forEach(response => organizationConfigurations.push(...response.data));

    return { organizationConfigurations, count: organizationConfigurations.length };
  },
);

export const doUpsertOrganizationConfiguration = createAsyncThunk(
  'organizationConfigurations/doUpsertOrganizationConfiguration',
  async (
    input: {
      organizationId: number,
      kseIdleTimeout?: string | null,
      maxTimesContinuousKse?: string | null,
      sessionTimeout?: string | null,
    },
    { rejectWithValue },
  ) => {
    try {
      const data = [];
      if (input.kseIdleTimeout !== '0') {
        data.push(
          {
            organizationId: input.organizationId,
            name: OrganizationConfigurationName.KSE_IDLE_TIMEOUT,
            value: input.kseIdleTimeout,
          },
        );
      }
      if (input.maxTimesContinuousKse !== '0') {
        data.push(
          {
            organizationId: input.organizationId,
            name: OrganizationConfigurationName.MAX_TIMES_CONTINUOUS_KSE,
            value: input.maxTimesContinuousKse,
          },
        );
      }
      if (input.sessionTimeout !== '0') {
        data.push(
          {
            organizationId: input.organizationId,
            name: OrganizationConfigurationName.SESSION_TIMEOUT,
            value: input.sessionTimeout,
          },
        );
      }
      return await OrganizationConfigurationService.upsert(data);
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doUpsertCustomDomainAndSSO = createAsyncThunk(
  'organizationConfigurations/doUpsertCustomDomainAndSSO',
  async (
    input: {
      organizationId: number,
      customDomain: CustomDomain,
      samlIdpDTO: SamlIdp | null,
    },
    { rejectWithValue },
  ) => {
    try {
      return await OrganizationConfigurationService.upsertDomainAndSSO(input);
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doGetCustomDomainAndSSO = createAsyncThunk(
  'organizationConfigurations/getCustomDomainAndSSO',
  async (organizationId: number) => {
    const firstResponse = await OrganizationConfigurationService.getDomainAndSSO(organizationId);
    return firstResponse.data;
  },
);

const organizationConfigurationsSlice = createSlice({
  name: ORGANIZATION_CONFIGURATION_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doGetByOrganizationId.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetByOrganizationId.fulfilled, (state, action) => {
      state.loading = false;
      state.count = action.payload.count;
      organizationConfigurationAdapter.removeAll(state);
      organizationConfigurationAdapter.addMany(state, action.payload.organizationConfigurations);
    });
    builder.addCase(doGetByOrganizationId.rejected, state => {
      state.loading = false;
    });
    builder.addCase(doUpsertOrganizationConfiguration.pending, state => {
      state.loading = true;
    });
    builder.addCase(doUpsertOrganizationConfiguration.fulfilled, (state, action) => {
      state.loading = false;
      organizationConfigurationAdapter.upsertMany(state, action.payload);
    });
    builder.addCase(doUpsertOrganizationConfiguration.rejected, state => {
      state.loading = false;
    });
    builder.addCase(doUpsertCustomDomainAndSSO.pending, state => {
      state.loading = true;
    });
    builder.addCase(doUpsertCustomDomainAndSSO.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(doUpsertCustomDomainAndSSO.rejected, state => {
      state.loading = false;
    });
  },
});

const selectOrganizationConfigurationsFeature = (state: AppState) => state[
  ORGANIZATION_CONFIGURATION_FEATURE_KEY
];
const { selectAll } = organizationConfigurationAdapter.getSelectors(
  selectOrganizationConfigurationsFeature,
);

export const selectByOrganizationId = (orgId: Organization['id']) => createSelector(
  selectAll,
  configurations => configurations.filter(configuration => (
    configuration.organizationId === orgId
  )),
);

export default organizationConfigurationsSlice.reducer;
