import { ReactElement } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import K1Typography from '../../../../components/design-system/K1Typography';
import { ReactComponent as SparkleAi } from '../../../icons/sparkle-ai.svg';
import { BUSINESS_EMAIL } from '../../utils';

const useStyles = makeStyles(theme => ({
  container: {
  },
  containerDescription: {
    display: 'flex',
    flexDirection: 'row',
  },
  description: {
    width: theme.spacing(52),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0),
  },
  contactSaleBtn: {
    backgroundColor: '#E7E9EF',
    color: '#22283C',
    width: theme.spacing(17.5),
    height: theme.spacing(5),
    marginTop: theme.spacing(1.25),
    marginLeft: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(2),
  },
  aiIcon: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.25),
  },
  planNameContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  textAI: {
    fontSize: 16,
    fontWeight: 500,
    color: '#5959eb',
  },
}));

export interface TrueTestPurchaseDetailsProps {
  title: ReactElement;
}

const TrueTestPurchaseDetails = (props: TrueTestPurchaseDetailsProps) => {
  const {
    title,
  } = props;

  const classes = useStyles();

  const planNameComponent = (
    <K1Typography variant="h3" fontWeight="medium" className={classes.planNameContainer}>
      { title }
      <SparkleAi className={classes.aiIcon} />
      <span className={classes.textAI}>
        <FormattedMessage id="subscriptions.testops_platform.offering.ai" />
      </span>
    </K1Typography>
  );

  return (
    <div className={classes.container}>
      {planNameComponent}
      <div className={classes.containerDescription}>
        <span className={classes.description}>
          <FormattedMessage id="subscriptions.testops_platform.offering.truetest_description" />
        </span>
        <Button
          display="flex"
          justifyContent="flex-end"
          fullWidth
          component={Link}
          href={BUSINESS_EMAIL}
          size="medium"
          color="primary"
          className={classes.contactSaleBtn}
        >
          <FormattedMessage id="subscriptions.testops_platform.contact_sales" />
        </Button>
      </div>
    </div>
  );
};

export default TrueTestPurchaseDetails;
