import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSnackbar } from 'notistack';
import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { notFound } from '../../../../routes';
import {
  fromAccounts,
  fromInvoices,
  fromPaymentMethod,
} from '../../../../store/rootReducer';
import BillingInformation from './BillingInformation';
import CardInformation from './CardInformation';
import { getCycleFromPathParam, isBankTransferEnabled, MAX_TOTAL_AMOUNT } from '../../utils';
import PaymentMethodSelection from './PaymentMethodSelection';
import { OrganizationFeature, PaymentMethodType, TestOpsPlanInterval } from '../../../../models';
import { useAppDispatch } from '../../../../store';
import { useLaunchDarkly } from '../../../../launchdarkly';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    borderRadius: '.5rem',
  },
  section: {
    padding: theme.spacing(4, 5),
  },
  title: {
    marginTop: theme.spacing(2.5),
    paddingBottom: '1rem',
  },
  subTitle: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  checkoutButton: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.background.paper,
  },
  paymentMethodSelection: {
    marginBottom: theme.spacing(5),
  },
  subscribeBtn: {
    fontWeight: 500,
    fontSize: 14,
  },
}));
export interface PaymentInformationSectionProps {
  accountId: number;
  enableSave: boolean;
  availableRenewal: boolean;
  setTriggerOptInSelfServe?: Function;
}
const PaymentInformationSection = (props: PaymentInformationSectionProps) => {
  const classes = useStyles();
  const { accountId, enableSave, availableRenewal, setTriggerOptInSelfServe } = props;
  const { flags } = useLaunchDarkly();
  const intl = useIntl();
  const billingRef = useRef<any>(null);
  const cardRef = useRef<any>(null);

  const loading = useSelector(fromInvoices.selectLoading);
  const previewsTotal = useSelector(fromInvoices.selectPreviewsTotal);
  const cardInformationInvalid = useSelector(fromPaymentMethod.isCardInformationInvalid);
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const successEmail = <a target="_blank" href="mailto: success@katalon.com" rel="noreferrer">success@katalon.com</a>;
  const selectedPaymentMethod = useSelector(fromPaymentMethod.selectSelectedPaymentMethod);
  const isAvailableRegion = useSelector(fromPaymentMethod.selectIsAvailableRegion);
  const isPublicDomain = useSelector(fromAccounts.selectIsPublicDomain);

  const platformCycle = getCycleFromPathParam(
    OrganizationFeature.TESTOPS_PLATFORM,
  );
  const kseCycle = getCycleFromPathParam(
    OrganizationFeature.PER_USER_KSE,
  );
  const kreCycle = getCycleFromPathParam(
    OrganizationFeature.UNLIMITED_ENGINE,
  );
  const testCloudCycle = getCycleFromPathParam(
    OrganizationFeature.TESTCLOUD_SESSION_WEB,
  );
  const testCloudDesktopCycle = getCycleFromPathParam(
    OrganizationFeature.TESTCLOUD_SESSION_WEB_DESKTOP,
  );
  const testCloudCrossBrowseCycle = getCycleFromPathParam(
    OrganizationFeature.TESTCLOUD_SESSION_CROSS_BROWSER,
  );
  const testCloudMobileCycle = getCycleFromPathParam(
    OrganizationFeature.TESTCLOUD_SESSION_MOBILE_APP,
  );
  const visualTestingCycle = getCycleFromPathParam(
    OrganizationFeature.VISUAL_TESTING_PRO,
  );

  const hasMonthlyItem = visualTestingCycle === TestOpsPlanInterval.MONTH
    || kseCycle === TestOpsPlanInterval.MONTH || kreCycle === TestOpsPlanInterval.MONTH
    || platformCycle === TestOpsPlanInterval.MONTH
    || (!flags?.newTestCloudSKUsEnabled
      ? testCloudCycle === TestOpsPlanInterval.MONTH
      : testCloudDesktopCycle === TestOpsPlanInterval.MONTH
      || testCloudCrossBrowseCycle === TestOpsPlanInterval.MONTH
      || testCloudMobileCycle === TestOpsPlanInterval.MONTH);

  const notAvailableBankTransfer = () => {
    if (hasMonthlyItem) {
      // Monthly item is not available for bank transfer
      return true;
    }

    if (!availableRenewal) {
      // Renewal is not available
      return true;
    }

    if (!isBankTransferEnabled(accountId, flags)) {
      // Bank transfer is not enabled
      return true;
    }

    if (flags?.EUBankTransferWhiteList?.find(item => item === accountId)) {
      // EU Bank Transfer is enabled for this account
      return false;
    }

    if (flags?.restrictWireTransferEnabled && !isAvailableRegion) {
      // Bank transfer is restricted for this region
      return true;
    }

    return isPublicDomain;
  };

  const getRegion = async () => {
    dispatch(fromPaymentMethod.doGetRegion());
  };

  const initializePage = async () => {
    await getRegion();
    analytics.page();
  };

  useEffect(() => {
    initializePage();
  }, []);

  const isExceededMaxAmount = () => previewsTotal > MAX_TOTAL_AMOUNT;

  useEffect(() => {
    if (!loading && isExceededMaxAmount()) {
      enqueueSnackbar(
        intl.formatMessage(
          { id: 'payment_status.total.exceeded.limit' },
          { email: successEmail },
        ),
        { variant: 'error' },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewsTotal]);

  useEffect(() => {
    if (notAvailableBankTransfer()) {
      dispatch(fromPaymentMethod.doChangeSelectedPaymentMethod(PaymentMethodType.CREDIT_CARD));
    }
  }, [notAvailableBankTransfer()]);

  const handleOptInSelfServe = () => {
    if (setTriggerOptInSelfServe) {
      setTriggerOptInSelfServe(true);
    }
    cardRef.current?.renewOnline();
    billingRef.current?.renewOnline();
  };

  if (!accountId || Number.isNaN(accountId)) return <Navigate to={notFound.path} replace />;
  return (
    <div id="payment-information-section" className={classes.root}>
      <Paper className={classes.section} elevation={0}>
        {!notAvailableBankTransfer()
          && (
          <Grid id="payment-method-selection" className={classes.paymentMethodSelection}>
            <PaymentMethodSelection />
          </Grid>
          )}
        <Grid>
          {(notAvailableBankTransfer() || selectedPaymentMethod === PaymentMethodType.CREDIT_CARD)
            && (
              <Elements stripe={stripePromise}>
                <CardInformation
                  ref={cardRef}
                  accountId={accountId}
                  enableSave={enableSave}
                />
              </Elements>
            )}
        </Grid>
        <BillingInformation
          ref={billingRef}
          accountId={accountId}
          enableSave={enableSave}
        />
        {!enableSave && (
          <Button
            variant="contained"
            size="large"
            color="primary"
            disabled={cardInformationInvalid}
            onClick={handleOptInSelfServe}
            className={classes.subscribeBtn}
          >
            {intl.formatMessage({ id: 'direct.selfserve.review_online' })}
          </Button>
        )}
      </Paper>
    </div>
  );
};

export default PaymentInformationSection;
