import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import K1Typography from '../../../../components/design-system/K1Typography';
import { OrganizationFeature, TestOpsPlanInterval } from '../../../../models';
import PlanPurchaseDetails from './PlanPurchaseDetails';
import { PlanPrice } from '../../../../models/planPrice';
import { fromStudioSubscriptions } from '../../../../store';
import { useLaunchDarkly } from '../../../../launchdarkly';
import { getCycleFromPathParam } from '../../utils';

export interface KreFloatingPurchaseDetailsProps {
  accountId: number;
  initialQuota?: number;
  title: ReactElement;
  clearInitialQuota: Function;
}

const KrePurchaseDetails = (props: KreFloatingPurchaseDetailsProps) => {
  const {
    accountId,
    initialQuota,
    title,
    clearInitialQuota,
  } = props;
  const { flags } = useLaunchDarkly();
  const kreCycle = getCycleFromPathParam(OrganizationFeature.UNLIMITED_ENGINE);
  const currentKRESubscription = useSelector(
    fromStudioSubscriptions.selectPaidSubscriptionByAccountId(
      Number(accountId),
      OrganizationFeature.UNLIMITED_ENGINE,
    ),
  )?.[0];

  const planNameComponent = (
    <K1Typography variant="h3" fontWeight="medium">
      { title }
    </K1Typography>
  );

  const quotaConverter = (
    chosenQuota: number,
    currentSubscription?: any,
  ) => chosenQuota + (currentSubscription?.quota || 0);

  return (
    <PlanPurchaseDetails
      currentSubscription={currentKRESubscription}
      planNameComponent={planNameComponent}
      showPlanDescription
      accountId={accountId}
      organizationFeature={OrganizationFeature.UNLIMITED_ENGINE}
      initialQuota={initialQuota ?? 0}
      initialInterval={kreCycle
        || currentKRESubscription?.billingCycle || TestOpsPlanInterval.YEAR}
      monthlyPrice={flags?.subscriptionPlanPrice?.kreMonthly ?? PlanPrice.KRE_MONTHLY}
      annualyPrice={flags?.subscriptionPlanPrice?.kreAnnually ?? PlanPrice.KRE_ANNUALLY}
      minQuota={0}
      step={1}
      quotaConverter={quotaConverter}
      clearInitialQuota={clearInitialQuota}
    />
  );
};

export default KrePurchaseDetails;
