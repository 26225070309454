import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles(theme => ({
  gridCard: {
    marginRight: theme.spacing(4),
  },
  card: {
    padding: theme.spacing(2, 3),
    border: '1px solid #D5D8DD',
    width: theme.spacing(31.125),
    height: theme.spacing(15.75),
  },
  title: {
    color: '#233145',
    fontSize: theme.spacing(2),
    fontWeight: '600',
    textAlign: 'center',
    width: '100%',
  },
  leftColumn: {
    width: '40%',
    justifyContent: 'flex-start',
  },
  rightColumn: {
    width: '40%',
    justifyContent: 'flex-end',
  },
  divider: {
    backgroundColor: '#dbdde5',
    width: theme.spacing(0.125),
  },
  columnTitle: {
    color: '#808b9a',
    fontSize: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold,
  },
  contentGrid: {
    marginTop: theme.spacing(1.5),
  },
  columnValue: {
    color: '#233145',
    fontSize: theme.spacing(2.5),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

interface TotalLicenseDetailProps {
  title: string,
  total: number,
  available: number,
  isAllocatedLoaded: boolean,
}

const TotalLicenseDetail = (props: TotalLicenseDetailProps) => {
  const {
    title,
    total,
    available,
    isAllocatedLoaded,
  } = props;

  const classes = useStyles();

  return (
    <Grid item xs={5} md={4} lg={3} alignItems="center" className={classes.gridCard}>
      <Card className={classes.card} key={`licenses.allocation.tab.total_license-${title}`}>
        <Typography className={classes.title} id="licenses.allocation.tab.total_license.title">
          {title}
        </Typography>

        <Grid container className={classes.contentGrid}>
          <Grid item xs={5.5}>
            <Box className={classes.leftColumn}>
              <Typography className={classes.columnTitle}>
                Total
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={1} style={{ alignSelf: 'stretch' }}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
          <Grid item xs={5.5}>
            <Box className={classes.rightColumn}>
              <Typography className={classes.columnTitle}>
                Available
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={5.5}>
            <Box className={classes.leftColumn}>
              <Typography className={classes.columnValue}>
                {isAllocatedLoaded ? total : ' '}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={1} style={{ alignSelf: 'stretch' }}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
          <Grid item xs={5.5}>
            <Box className={classes.rightColumn}>
              <Typography className={classes.columnValue}>
                {isAllocatedLoaded ? available : ' '}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default TotalLicenseDetail;
