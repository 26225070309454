import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import Axios from 'axios';
import some from 'lodash/fp/some';

import { AppState } from '.';
import {
  AuthException,
  AuthOdicGrantType,
  BadRequestException,
  ExceptionClasses,
  exceptionOf,
  OrganizationUser,
  Role,
  SerializedException,
  Subscription,
  Token,
  User,
  UserSubscriptionType,
} from '../models';
import { Operator, Query } from '../models/query';
import { SubscriptionSource } from '../models/subscriptionSource';
import { SubscriptionStatus } from '../models/subscriptionStatus';
import { AuthService, OrganizationUserService, SubscriptionService, UserService } from '../services';
import { requestLocation } from '../services/track';
import { upsertHubspotContact } from '../services/webhook';
import { doUpdateUser } from './usersSlice';

const TOKEN = 'k1.login';
const MAX_NUMBER_OF_RECORDS = 50;

export const AUTH_FEATURE_KEY = 'auth';
interface AuthState {
  user: User | null;
  token: Token | null;
  loading: boolean;
  errors: SerializedException[];
  // whether perform doLogin async action.
  didLogin: boolean;
  submitting: boolean;
}

export const createInitialState = (): AuthState => ({
  token: null,
  user: null,
  loading: false,
  errors: [],
  didLogin: false,
  submitting: false,
});

export const doLogin = createAsyncThunk(
  'auth/login',
  async (credential: { userInfo: Parameters<typeof AuthService['login']>[0], rememberMe: boolean; }, { rejectWithValue }) => {
    try {
      const token = await AuthService.login(credential.userInfo);
      Axios.defaults.headers.common.Authorization = `Bearer ${token.jwt}`;
      if (credential.rememberMe) localStorage.setItem(TOKEN, JSON.stringify(token));
      const user = await AuthService.me();

      // update matomo userId before tracking
      /* eslint-disable no-underscore-dangle */
      if ((window as any)._paq) {
        (window as any)._paq.push(['setUserId', user.email]);
      }
      /* eslint-enable */
      localStorage.setItem('ajs_user_id', `"${user.email}"`);
      const { city, country } = await requestLocation();
      analytics.identify(user.email, {
        city,
        country,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        name: `${user.firstName} ${user.lastName}`,
      });
      analytics.alias(user.email);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        user_id: user?.id,
        user_role: 'OWNER',
      });

      return { user, token };
    } catch (e: any) {
      delete Axios.defaults.headers.common.Authorization;
      localStorage.removeItem(TOKEN);
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doResume = createAsyncThunk(
  'auth/resume',
  async (token: Partial<Pick<Token, 'refreshJwt'>> & Required<Pick<Token, 'jwt'>> | undefined, { rejectWithValue }) => {
    try {
      const userToken = token ?? (JSON.parse(localStorage.getItem(TOKEN) || 'null') as Token);
      Axios.defaults.headers.common.Authorization = `Bearer ${userToken.jwt}`;
      const user = await AuthService.me();

      const tokenAfterGetMe = JSON.parse(localStorage.getItem(TOKEN) || 'null') as Token;

      // If user is authenticated by refresh token before get me success
      if (tokenAfterGetMe?.user && !token) {
        return { user: tokenAfterGetMe?.user, token: tokenAfterGetMe };
      }

      localStorage.setItem(TOKEN, JSON.stringify({ jwt: userToken.jwt,
        refreshJwt: userToken.refreshJwt,
        user }));
      // update matomo userId
      /* eslint-disable no-underscore-dangle */
      if ((window as any)._paq) {
        (window as any)._paq.push(['setUserId', user.email]);
      }
      /* eslint-enable */
      localStorage.setItem('ajs_user_id', `"${user.email}"`);
      // eslint-disable-next-line no-underscore-dangle
      window._sva = window._sva || {};
      // eslint-disable-next-line no-underscore-dangle
      window._sva.traits = {
        email: user.email,
        first_name: user.firstName,
        last_name: user.lastName,
      };
      return { user, token: { jwt: userToken.jwt, refreshJwt: userToken.refreshJwt, user } };
    } catch (e: any) {
      delete Axios.defaults.headers.common.Authorization;
      localStorage.removeItem(TOKEN);
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doLogout = createAsyncThunk(
  'auth/logout',
  async () => {
    try {
      const token = localStorage.getItem(TOKEN);
      if (token) {
        const { jwt, refreshJwt } = JSON.parse(token) as Token;
        if (refreshJwt) {
          Axios.defaults.headers.common.Authorization = `Bearer ${jwt}`;
          await AuthService.logoutToken(refreshJwt);
        }
      }
    } finally {
      delete Axios.defaults.headers.common.Authorization;
      localStorage.removeItem(TOKEN);
      // Clear suricate data
      // eslint-disable-next-line no-underscore-dangle
      window._sva = window._sva || {};
      // eslint-disable-next-line no-underscore-dangle
      if (window._sva.destroyVisitor) {
        // eslint-disable-next-line no-underscore-dangle
        window._sva.destroyVisitor();
      }
    }
  },
);

export const doExchangeToken = createAsyncThunk(
  'auth/exchangeToken',
  async (credentials: {
    code: string,
    redirectUri: string; }, { rejectWithValue }) => {
    try {
      const token = await AuthService.exchangeToken(credentials);
      return { token };
    } catch (e: any) {
      delete Axios.defaults.headers.common.Authorization;
      localStorage.removeItem(TOKEN);
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doRefreshToken = createAsyncThunk(
  'auth/refreshToken',
  async (credentials: { refreshToken: string; }, { rejectWithValue }) => {
    try {
      const token = await AuthService.exchangeToken({
        ...credentials,
        grantType: AuthOdicGrantType.REFRESH_TOKEN,
      });
      return { token };
    } catch (e: any) {
      delete Axios.defaults.headers.common.Authorization;
      localStorage.removeItem(TOKEN);
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

// seems not using anywhere, I'll remove it later: @nhat.hoang
export const doSubmitSurvey = createAsyncThunk(
  'auth/submitSurvey',
  async (input: Required<Pick<User, 'id' | 'jobTitle' | 'testingSolutions' | 'roles' | 'email' | 'firstName' | 'lastName'>>, { rejectWithValue }) => {
    try {
      const [updatedUser] = await Promise.all([
        UserService.updateUser(input),
        upsertHubspotContact({
          email: input.email,
          jobTitle: input.jobTitle,
          testingSolutions: input.testingSolutions,
          firstName: input.firstName,
          lastName: input.lastName,
        }),
      ]);
      return { updatedUser };
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doGetSurvey = createAsyncThunk(
  'auth/doGetSurvey',
  async (input: Required<Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>>) => {
    // eslint-disable-next-line no-underscore-dangle
    window._sva = window._sva || {};
    // eslint-disable-next-line no-underscore-dangle
    window._sva.traits = {
      email: input.email,
      first_name: input.firstName,
      last_name: input.lastName,
      subscription_type: await getUserSubscriptionType(input.id),
    };
    // eslint-disable-next-line no-underscore-dangle
    if (window._sva.setVisitorTraits) {
      // eslint-disable-next-line no-underscore-dangle
      window._sva.setVisitorTraits(window._sva.traits);
    }
  },
);

const getUserSubscriptionType = async (userId: number) => {
  // Get all org user
  const criteria: Array<Query<OrganizationUser>> = [
    { field: 'userId', operator: Operator.EQ, value: userId },
    { limit: MAX_NUMBER_OF_RECORDS },
  ];

  const firstResponse = await OrganizationUserService.getOrganizationUsers(...criteria);
  const numberOfRequests = Math.ceil(firstResponse.total / MAX_NUMBER_OF_RECORDS);
  const remainingRequests = [];
  for (let i = 1; i < numberOfRequests; i += 1) {
    const req = OrganizationUserService.getOrganizationUsers(
      ...criteria,
      { offset: MAX_NUMBER_OF_RECORDS * i },
    );
    remainingRequests.push(req);
  }
  const remainingResponses = await Promise.all(remainingRequests);
  const orgUsers = [...firstResponse.data];
  remainingResponses.forEach(r => {
    orgUsers.push(...r.data);
  });

  // Get subscription
  const subsCriteria: Query<Subscription>[] = [
    {
      field: 'organizationId',
      operator: Operator.IN,
      value: orgUsers.map(orgUser => orgUser.organizationId),
    },
    {
      field: 'status',
      operator: Operator.EQ,
      value: SubscriptionStatus.ACTIVE,
    },
    {
      field: 'source',
      operator: Operator.EQ,
      value: SubscriptionSource.RECURLY,
    },
  ];
  const listSubscription = await SubscriptionService.getSubscriptions(...subsCriteria);
  if (listSubscription.length !== 0) {
    return UserSubscriptionType.PAID;
  }
  return UserSubscriptionType.FREE;
};

const authSlice = createSlice({
  name: AUTH_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doLogin.pending, state => {
      state.loading = true;
    });
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.loading = false;
      state.errors = [];
      state.didLogin = true;
    });
    builder.addCase(doLogin.rejected, (state, action) => {
      const payload = action.payload as SerializedException;
      state.user = null;
      state.token = null;
      state.loading = false;
      state.errors.push(payload);
    });

    builder.addCase(doResume.pending, state => {
      state.loading = true;
      state.errors = [];
    });
    builder.addCase(doResume.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.loading = false;
      state.didLogin = true;
    });
    builder.addCase(doResume.rejected, (state, action) => {
      const payload = action.payload as SerializedException;
      state.user = null;
      state.token = null;
      state.loading = false;
      state.errors.push(payload);
    });

    builder.addCase(doLogout.pending, state => {
      state.loading = true;
    });
    builder.addCase(doLogout.fulfilled, state => {
      state.user = null;
      state.token = null;
      state.loading = false;
    });

    builder.addCase(doSubmitSurvey.pending, state => {
      state.loading = true;
    });
    builder.addCase(doSubmitSurvey.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload.updatedUser;
      state.errors = [];
    });
    builder.addCase(doSubmitSurvey.rejected, (state, action) => {
      const payload = action.payload as SerializedException;
      state.user = null;
      state.token = null;
      state.loading = false;
      state.errors.push(payload);
    });

    builder.addCase(doUpdateUser.rejected, (state, action) => {
      const payload = action.payload as SerializedException;
      state.submitting = false;
      state.errors.push(payload);
    });
    builder.addCase(doUpdateUser.pending, state => {
      state.submitting = true;
    });
    builder.addCase(doUpdateUser.fulfilled, (state, action) => {
      const currentUser = state.user;
      const updatedUser = action.payload.user;
      if (updatedUser.id === currentUser?.id) {
        state.user = updatedUser;
      }
      state.submitting = false;
    });
    builder.addCase(doGetSurvey.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetSurvey.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(doGetSurvey.rejected, state => {
      state.loading = false;
    });
    builder.addCase(doExchangeToken.pending, state => {
      state.loading = true;
    });
    builder.addCase(doExchangeToken.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.loading = false;
      state.errors = [];
      state.didLogin = true;
    });
    builder.addCase(doExchangeToken.rejected, (state, action) => {
      const payload = action.payload as SerializedException;
      state.token = null;
      state.loading = false;
      state.errors.push(payload);
    });
    builder.addCase(doRefreshToken.pending, state => {
      state.loading = true;
    });
    builder.addCase(doRefreshToken.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.loading = false;
      state.errors = [];
      state.didLogin = true;
    });
    builder.addCase(doRefreshToken.rejected, (state, action) => {
      const payload = action.payload as SerializedException;
      state.token = null;
      state.loading = false;
      state.errors.push(payload);
    });
  },
});

const selectAuthFeature = (state: AppState) => state[AUTH_FEATURE_KEY];

export const selectSubmitting = createSelector(
  selectAuthFeature,
  authState => authState.submitting,
);
export const selectLoading = createSelector(
  selectAuthFeature,
  authState => authState.loading,
);
export const selectUser = createSelector(
  selectAuthFeature,
  authState => authState.user,
);
export const selectToken = createSelector(
  selectAuthFeature,
  authState => authState.token,
);
export const selectErrors = createSelector(
  selectAuthFeature,
  authState => authState.errors,
);
export const selectIsUnauthorized = createSelector(
  selectErrors,
  errors => !!errors.find((it: SerializedException) => (
    AuthException.isInstanceOf(it) || BadRequestException.isInstanceOf(it)
  )),
);
export const selectException = (exception: ExceptionClasses) => createSelector(
  selectErrors,
  errors => {
    const serializedException = errors.find(exception.isInstanceOf);
    return serializedException ? exception.of(serializedException.message) : null;
  },
);
export const selectIsAuthenticated = createSelector(
  selectAuthFeature,
  authState => !!(authState.user && authState.token) || !!localStorage.getItem(TOKEN),
);
export const selectIsROOT = createSelector(
  selectAuthFeature,
  authState => authState.user?.roles.includes(Role.ROOT),
);
export const selectDidLogin = createSelector(
  selectAuthFeature,
  authState => authState.didLogin,
);
export const selectIsRootOrAdmin = createSelector(
  selectAuthFeature,
  authState => some(role => [Role.ADMIN, Role.ROOT].includes(role), authState.user?.roles),
);

export default authSlice.reducer;
