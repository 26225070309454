import Axios from 'axios';
import {
  DataResponse,
  Subscription,
  PageableResponse,
  SingleProductOrder,
  RecurlySubscription,
  OrganizationTrialRequest,
  GeneratedFields,
  Account,
  SubscriptionView,
} from '../models';
import { composeQuery, Query } from '../models/query';
import { TestOpsSubscription } from '../models/testOpsSubscription';
import { VisualTestingSubscription } from '../models/visualTestingSubscription';
import { StudioSubscription } from '../models/studioSubscription';
import { RecurlySubscriptionDTO } from '../models/recurlySubscriptionDTO';

type OrderType = 'subscriptionId' | 'discountCode' | 'billingCycle' | 'newQuotaNumber';

export const getSubscriptions = async (...criteria: Query<Subscription>[]) => (await Axios.get<PageableResponse<Subscription>>('/v1/subscriptions', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: composeQuery<Subscription>(...criteria),
  data: {},
})).data.data;

export const querySubscriptions = async (...criteria: Query<Subscription>[]) => (await Axios.get<PageableResponse<Subscription>>('/v1/subscriptions', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: composeQuery<Subscription>(...criteria),
  data: {},
})).data;

export const querySubscriptionsView = async (...criteria: Query<SubscriptionView>[]) => (await Axios.get<PageableResponse<Subscription>>('/v1/subscriptions', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: composeQuery<SubscriptionView>(...criteria),
  data: {},
})).data;

export const getActiveRecurlySubscriptions = async (input: Required<Pick<Account, 'id'>>) => getActiveRecurlySubscriptionsWithAllocations(input, false);

export const getActiveRecurlySubscriptionsWithAllocations = async (input: Required<Pick<Account, 'id'>>, fetchAllocation: Boolean) => (await Axios.get<PageableResponse<RecurlySubscriptionDTO>>(`/v1/subscriptions/account/${input.id}`, {
  params: {
    fetchAllocation,
  },
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
})).data.data;

export const subscribe = async (data: Required<Omit<SingleProductOrder, OrderType>> & Partial<Pick<SingleProductOrder, OrderType>>) => (await Axios.post<DataResponse<RecurlySubscription>>('/v1/subscriptions/subscribe', data)).data.data;

export const upgrade = async (data: Required<Omit<SingleProductOrder, OrderType>> & Partial<Pick<SingleProductOrder, OrderType>>) => (await Axios.post<DataResponse<RecurlySubscription>>('/v1/subscriptions/upgrade', data)).data.data;

export const trialRequest = async (data: Required<Omit<OrganizationTrialRequest, GeneratedFields | 'userRequestId' | 'status'>>) => (await Axios.post<DataResponse<OrganizationTrialRequest>>('/v1/subscriptions/trial-request', data)).data.data;

export const cancel = async (data: Required<Pick<RecurlySubscription, 'recurlySubscriptionId'>>) => (await Axios.put<DataResponse<RecurlySubscription>>('/v1/subscriptions/cancel', data)).data.data;

export const reactivate = async (data: Required<Pick<RecurlySubscription, 'recurlySubscriptionId'>>) => (await Axios.put<DataResponse<RecurlySubscription>>('/v1/subscriptions/reactivate', data)).data.data;

export const getVisualTestingSubscriptions = async (checkHasPremierSuccess: boolean, ...criteria: Query<VisualTestingSubscription>[]) => (await Axios.get<PageableResponse<VisualTestingSubscription>>('/v1/visual-testing-subscriptions', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: {
    ...composeQuery<VisualTestingSubscription>(...criteria),
    ...(checkHasPremierSuccess && { include: 'premier_success' }),
  },
  data: {},
})).data.data;

export const getTestOpsSubscriptions = async (...criteria: Query<TestOpsSubscription>[]) => (await Axios.get<PageableResponse<TestOpsSubscription>>('/v1/testops-subscriptions', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: composeQuery<TestOpsSubscription>(...criteria),
  data: {},
})).data.data;

export const getStudioSubscriptions = async (checkHasPremierSuccess: boolean, ...criteria: Query<StudioSubscription>[]) => (await Axios.get<PageableResponse<StudioSubscription>>('/v1/studio-subscriptions', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: {
    ...composeQuery<StudioSubscription>(...criteria),
    ...(checkHasPremierSuccess && { include: 'premier_success' }),
  },
  data: {},
})).data.data;

export const trialVisualTestingRequest = async (account_id: number) => (await Axios.post<DataResponse<VisualTestingSubscription>>('/v1/visual-testing-subscriptions/trial-request', account_id, {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
})).data.data;

export const isAccessStartPackage = async (accountId: number) => (
  await Axios.get<boolean>('/v1/subscriptions/is-access-starter-package', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    params: {
      account_id: accountId,
    },
    data: {},
  })
).data;
