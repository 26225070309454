import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  exceptionOf,
  SerializedException,
  StarterPackage,
  StarterPackageGroup,
} from '../models';

import { AppState } from '.';
import { StarterPackageService } from '../services';
import { AccountStarterPackage } from '../models/accountStarterPackage';

export const STARTER_PACKAGE_FEATURE_KEY = 'starterPackage';

interface StarterPackageState extends EntityState<AccountStarterPackage> {
  selectedStarterPackage?: StarterPackage;
  groupUpgradablePackages: UpgradablePackageState,
  loading: boolean;
  errors: SerializedException[];
}

interface UpgradablePackageState extends EntityState<StarterPackageGroup[]> {
}

const starterPackageAdapter = createEntityAdapter<AccountStarterPackage>();
const upgradablePackageAdapter = createEntityAdapter<StarterPackageGroup[]>();

export const createInitialState = (partialState: Partial<StarterPackageState> = {}) => (
  starterPackageAdapter.getInitialState({
    ...partialState,
    groupUpgradablePackages: upgradablePackageAdapter.getInitialState(),
    loading: false,
    errors: [] as SerializedException[],
  })
);

export const doGetAccountStarterPackage = createAsyncThunk(
  'starterPackage/getAccountStarterPackage',
  async (accountId: number, { rejectWithValue }) => {
    try {
      const data = await StarterPackageService.getAccountStartPackage(accountId);
      return { data };
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doGetUpgradableStarterPackages = createAsyncThunk(
  'starterPackage/getUpgradableStarterPackages',
  async (accountId: number, { rejectWithValue }) => {
    try {
      const data = await StarterPackageService.getUpgradableStartPackages(accountId);
      return { data };
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

const starterPackageSlice = createSlice({
  name: STARTER_PACKAGE_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {
    doSelectStarterPackage(state, action: PayloadAction<StarterPackage>) {
      state.selectedStarterPackage = action.payload;
    },
    doResetSelectedStarterPackage(state) {
      state.selectedStarterPackage = undefined;
    },
    doResetStarterPackage: () => createInitialState(),
    doUpdateUpgradablePackages(state, action: PayloadAction<StarterPackageGroup[]>) {
      upgradablePackageAdapter.upsertOne(state.groupUpgradablePackages, action.payload);
    },
  },
  extraReducers: builder => {
    builder.addCase(doGetAccountStarterPackage.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetAccountStarterPackage.fulfilled, (state, action) => {
      starterPackageAdapter.addOne(state, action.payload.data);
      state.loading = false;
    });
    builder.addCase(doGetAccountStarterPackage.rejected, (state, action) => {
      state.loading = false;
      state.errors.push(action.payload as SerializedException);
    });
    builder.addCase(doGetUpgradableStarterPackages.pending, state => {
      const account = starterPackageAdapter.getSelectors().selectAll(state)[0];
      const listGroupPackage = convertUpgradablePackage(account.groupUpgradablePackages);
      upgradablePackageAdapter.upsertOne(
        state.groupUpgradablePackages,
        listGroupPackage,
      );
      state.loading = true;
    });
    builder.addCase(doGetUpgradableStarterPackages.fulfilled, (state, action) => {
      const listGroupPackage = convertUpgradablePackage(action.payload.data);
      upgradablePackageAdapter.upsertOne(
        state.groupUpgradablePackages,
        listGroupPackage,
      );
      const selectedStarterPackage = { ...state.selectedStarterPackage };
      if (selectedStarterPackage) {
        let cloneSelectedPackage;
        listGroupPackage.forEach(group => {
          if (group.id === selectedStarterPackage.group) {
            cloneSelectedPackage = group.starterPackages.find(
              sp => sp.name === selectedStarterPackage.name,
            );
          }
        });
        state.selectedStarterPackage = cloneSelectedPackage;
      }
      state.loading = false;
    });
    builder.addCase(doGetUpgradableStarterPackages.rejected, (state, action) => {
      state.loading = false;
      state.errors.push(action.payload as SerializedException);
    });
  },
});

const selectStarterPackageFeature = (state: AppState) => state[STARTER_PACKAGE_FEATURE_KEY];
const { selectAll } = starterPackageAdapter.getSelectors(selectStarterPackageFeature);

export const selectAccount = createSelector(
  selectAll,
  all => all[0],
);

const convertUpgradablePackage = (
  groupUpgradablePackages: Map<string, Array<StarterPackage>>,
  selectedPackage?: StarterPackage,
) => {
  const listGroups = Object.entries(groupUpgradablePackages).map(value => {
    const listStarterPackages = (value[1] as Array<StarterPackage>)
      .map(sp => ({ ...sp, selected: false }));
    const groupName = listStarterPackages[0].group;
    const group: StarterPackageGroup = {
      name: value[0],
      id: groupName,
      starterPackages: listStarterPackages,
      selectedStarterPackage: undefined,
      selected: selectedPackage?.group === groupName,
    };
    return group;
  });
  return listGroups.sort((a, b) => a.name.localeCompare(b.name));
};

export const selectGroupPackages = createSelector(
  selectStarterPackageFeature,
  it => {
    const account = starterPackageAdapter.getSelectors().selectAll(it)[0];
    const upgradablePackageSelector = upgradablePackageAdapter.getSelectors();
    const groupUpgradablePackages = upgradablePackageSelector.selectAll(it.groupUpgradablePackages);

    const selectedPackage = it.selectedStarterPackage;
    if (groupUpgradablePackages.length === 0
      && (account.currentStarterPackage === undefined
      || account.currentStarterPackage === null
      )) {
      return convertUpgradablePackage(account.groupUpgradablePackages, selectedPackage);
    }

    if (groupUpgradablePackages.length === 0) return [];
    const listGroups = Object.entries(groupUpgradablePackages[0]).map(value => {
      const group = { ...value[1] };
      if (group.id === selectedPackage?.group) {
        group.selected = true;
        group.selectedStarterPackage = group.starterPackages
          .find(item => item.name === selectedPackage.name);
      }
      return group;
    });
    return listGroups.sort((a, b) => a.name.localeCompare(b.name));
  },
);

export const selectStarterPackage = createSelector(
  selectStarterPackageFeature,
  it => it.selectedStarterPackage,
);

export const selectIsApplyStarterPackage = createSelector(
  selectAll,
  all => {
    if (all[0] === undefined) return false;
    const upgradablePackages = all[0].groupUpgradablePackages;
    return Object.entries(upgradablePackages).length > 0;
  },
);

export const selectCurrentStarterPackage = createSelector(
  selectAll,
  all => all[0]?.currentStarterPackage,
);

export const selectLoading = createSelector(
  selectStarterPackageFeature,
  state => state.loading,
);

export const selectErrors = () => createSelector(
  selectStarterPackageFeature,
  state => state.errors,
);

export const {
  doSelectStarterPackage,
  doResetSelectedStarterPackage,
  doResetStarterPackage,
  doUpdateUpgradablePackages,
} = starterPackageSlice.actions;

export default starterPackageSlice.reducer;
