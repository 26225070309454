import withStyles from '@mui/styles/withStyles';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const CheckedIcon = withStyles(theme => ({
  checkIcon: {
    width: theme.spacing(1.75),
    height: theme.spacing(1.75),
  },
}))(({ classes }: any) => (
  <CheckBoxIcon color="primary" className={classes.checkIcon} />
));

export default CheckedIcon;
