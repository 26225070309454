import Axios from 'axios';
import { OrganizationWhitelistIps } from '../models/organizationWhitelistIps';
import { DataResponse, PageableResponse } from '../models';
import { composeQuery, Query } from '../models/query';

export const doUpsertWhitelistDomainOrganization = async (input: Required<Pick<OrganizationWhitelistIps, 'organizationId' | 'ip'>>) => (await Axios.post<DataResponse<OrganizationWhitelistIps>>('/v1/whitelist-ips', input)).data.data;

export const getWhitelistIps = async (...criteria: Query<OrganizationWhitelistIps>[]) => (
  await Axios.get<PageableResponse<OrganizationWhitelistIps>[]>('/v1/whitelist-ips', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    params: composeQuery<OrganizationWhitelistIps>(...criteria),
    data: {},
  })
).data;
