import Axios from 'axios';

import { DataResponse, PageableResponse, TestOpsPaymentMethod } from '../models';
import { composeQuery, Query } from '../models/query';

export const getPaymentMethods = async (...criteria: Query<TestOpsPaymentMethod>[]) => (await Axios.get<PageableResponse<TestOpsPaymentMethod>>('/v1/payment-methods', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: composeQuery<TestOpsPaymentMethod>(...criteria),
  // must specify data to keep Content-Type header
  // though axios will remove request body when using GET method.
  data: {},
})).data.data;

export const createPaymentMethod = async (input: Required<Pick<TestOpsPaymentMethod, 'organizationId' | 'stripeToken'>>) => (await Axios.post<DataResponse<TestOpsPaymentMethod>>('/v1/payment-methods', input)).data.data;

export const updatePaymentMethod = async (input: Required<Pick<TestOpsPaymentMethod, 'id' | 'stripeToken'>>) => (await Axios.put<DataResponse<TestOpsPaymentMethod>>(`/v1/payment-methods/${input.id}`, input)).data.data;
