import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import { AppState } from '.';
import { Organization, OrganizationUser, OrganizationUserFeature } from '../models';
import { Operator, Query } from '../models/query';
import { OrganizationUserService } from '../services';

export const ORGANIZATION_USER_FEATURE_KEY = 'feature';

interface FeatureState extends EntityState<OrganizationUserFeature> {
  loading: boolean;
  count: number;
}

const MAX_NUMBER_OF_RECORDS = 50;

const organizationUserFeatureAdapter = createEntityAdapter<OrganizationUserFeature>();

export const createInitialState = ():
FeatureState => organizationUserFeatureAdapter.getInitialState({
  loading: false,
  count: 0,
});

export const doGetOrgUserFeatures = createAsyncThunk(
  'organizationUser/getOrgUserFeatures',
  async (input: Pick<OrganizationUser, 'organizationId'>) => {
    const criteria: Array<Query<OrganizationUserFeature>> = [
      { field: 'organizationId', operator: Operator.IN, value: [input.organizationId] },
      { limit: MAX_NUMBER_OF_RECORDS },
    ];
    const firstResponse = await OrganizationUserService.getOrganizationUserFeatures(...criteria);
    const numberOfRequests = Math.ceil(firstResponse.total / MAX_NUMBER_OF_RECORDS);
    const remainingRequests = [];
    for (let i = 1; i < numberOfRequests; i += 1) {
      const req = OrganizationUserService.getOrganizationUserFeatures(
        ...criteria,
        { offset: MAX_NUMBER_OF_RECORDS * i },
      );
      remainingRequests.push(req);
    }
    const remainingResponses = await Promise.all(remainingRequests);
    const orgUserFeatures = [...firstResponse.data];
    remainingResponses.forEach(r => {
      orgUserFeatures.push(...r.data);
    });
    return orgUserFeatures;
  },
);

export const doGetOrgUserFeaturesByListOrgId = createAsyncThunk(
  'organizationUser/getOrgUserFeaturesByListOrgId',
  async (organizationIds: OrganizationUser['organizationId'][]) => {
    const criteria: Array<Query<OrganizationUserFeature>> = [
      { field: 'organizationId', operator: Operator.IN, value: organizationIds },
      { limit: MAX_NUMBER_OF_RECORDS },
    ];
    const firstResponse = await OrganizationUserService.getOrganizationUserFeatures(...criteria);
    const numberOfRequests = Math.ceil(firstResponse.total / MAX_NUMBER_OF_RECORDS);
    const remainingRequests = [];
    for (let i = 1; i < numberOfRequests; i += 1) {
      const req = OrganizationUserService.getOrganizationUserFeatures(
        ...criteria,
        { offset: MAX_NUMBER_OF_RECORDS * i },
      );
      remainingRequests.push(req);
    }
    const remainingResponses = await Promise.all(remainingRequests);
    const orgUserFeatures = [...firstResponse.data];
    remainingResponses.forEach(r => {
      orgUserFeatures.push(...r.data);
    });
    return orgUserFeatures;
  },
);

const organizationUserFeatureSlice = createSlice({
  name: ORGANIZATION_USER_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doGetOrgUserFeatures.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetOrgUserFeatures.fulfilled, (state, action) => {
      state.loading = false;
      state.count = action.payload.length;
      organizationUserFeatureAdapter.setAll(state, action.payload);
    });
    builder.addCase(doGetOrgUserFeatures.rejected, state => {
      state.loading = false;
    });
    builder.addCase(doGetOrgUserFeaturesByListOrgId.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetOrgUserFeaturesByListOrgId.fulfilled, (state, action) => {
      state.loading = false;
      organizationUserFeatureAdapter.setAll(state, action.payload);
    });
    builder.addCase(doGetOrgUserFeaturesByListOrgId.rejected, state => {
      state.loading = false;
    });
  },
});

const selectOrgUserFeature = (state: AppState) => state[ORGANIZATION_USER_FEATURE_KEY];

export const {
  selectAll: selectAllOrganizationUserFeatures,
  selectEntities,
} = organizationUserFeatureAdapter.getSelectors(selectOrgUserFeature);

export const selectFeaturesByOrganizationId = (organizationId: Organization['id']) => createSelector(
  selectAllOrganizationUserFeatures,
  orgUsers => orgUsers.filter(orgUser => orgUser.organizationId === organizationId),
);

export const selectFeaturesByListOrganizationId = (organizationIds: Organization['id'][]) => createSelector(
  selectAllOrganizationUserFeatures,
  orgUsers => orgUsers.filter(orgUser => organizationIds.includes(orgUser.organizationId)),
);

export default organizationUserFeatureSlice.reducer;
