import { useSelector } from 'react-redux';
import {
  Account,
  OrganizationFeature,
} from '../../../../models';
import AdditionalSubscriptionRow from './AdditionalSubscriptionRow';
import {
  fromStudioSubscriptions,
  useAppDispatch,
} from '../../../../store';

export interface KseSubscriptionDetailsRowProps {
  account: Account | null,
  email: string | null,
  organizationId: number,
}

const KreSubscriptionDetails = (props: KseSubscriptionDetailsRowProps) => {
  const {
    account,
    organizationId,
    email,
  } = props;

  const dispatch = useAppDispatch();
  const currentKRESubscription = useSelector(
    fromStudioSubscriptions.selectByAccountIdAndFeature(
      Number(account?.id),
      OrganizationFeature.UNLIMITED_ENGINE,
    ),
  )?.[0];

  const handleRefreshSubscription = async () => {
    await Promise.all([
      dispatch(fromStudioSubscriptions.doGetActiveByAccountId({
        accountId: Number(account?.id),
      })),
    ]);
  };

  return (
    currentKRESubscription ? (
      <>
        <AdditionalSubscriptionRow
          key="subscriptions.katalon_runtime_engine"
          name="subscriptions.katalon_runtime_engine"
          organizationId={organizationId}
          email={email}
          currentSubscription={currentKRESubscription}
          feature={OrganizationFeature.UNLIMITED_ENGINE}
          handleRefreshSubscription={handleRefreshSubscription}
          account={account}
        />
      </>
    ) : null
  );
};

export default KreSubscriptionDetails;
