import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { dashboard } from '../../layout/routes';
import { resolvePath } from '../../routes';
import { fromOrganizationUsers } from '../../store';
import OrganizationCard from './OrganizationCard';

const useStyles = makeStyles(theme => ({
  listOver6: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.spacing(0, 20),
    flexWrap: 'wrap',
  },
  leftBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexBasis: '45%',
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
      paddingRight: 0,
    },
    '& > *': {
      marginBottom: theme.spacing(2.5),
    },
  },
  rightBox: {
    display: 'flex',
    alignItems: 'flex-start',
    flexBasis: '40%',
    flexGrow: 1,
    flexDirection: 'column',
    maxHeight: theme.spacing(88),
    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
    },
    '& > *': {
      marginBottom: theme.spacing(2.5),
    },
  },
  textSecondary: {
    textAlign: 'left',
    textTransform: 'uppercase',
  },
  list: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    maxHeight: '100%',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const OrganizationListOver6 = () => {
  const classes = useStyles();
  const organizationUsers = useSelector(fromOrganizationUsers.selectOrgUsersByUserIdWithSearch);
  const sortedOrgUsers = organizationUsers.filter(orgUser => orgUser !== undefined)
    .sort(
      (orgUser1, orgUser2) => new Date(orgUser2?.lastAccessedAt || 0).getTime()
        - new Date(orgUser1?.lastAccessedAt || 0).getTime(),
    );
  const recentOrgUsers = sortedOrgUsers.slice(0, Math.min(4, sortedOrgUsers.length));

  return (
    <Box className={classes.listOver6}>
      <Box className={classes.leftBox}>
        <Typography variant="subtitle1" className={classes.textSecondary}>
          <FormattedMessage id="organizationlist.over6.recent" />
        </Typography>
        {recentOrgUsers.map(organizationUser => (
          <OrganizationCard key={organizationUser!!.id} id={organizationUser!!.organizationId} />
        ))}
      </Box>
      <Box className={classes.rightBox}>
        <Typography variant="subtitle1" className={classes.textSecondary}>
          <FormattedMessage id="organizationlist.over6.all" />
        </Typography>
        <List className={classes.list}>
          {sortedOrgUsers.map(organizationUser => {
            const { organization } = organizationUser!!;
            return (
              <ListItem
                component={RouterLink}
                to={resolvePath(dashboard, undefined, { orgId: organization.id }, false)}
                key={organization.id}
                button
              >
                <ListItemAvatar>
                  <Avatar
                    alt={organization.name}
                    src={organization.logo}
                  >
                    {organization.name?.[0] ?? ''}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={<Typography variant="h4">{organization.name}</Typography>} />
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Box>
  );
};

export default OrganizationListOver6;
