import Axios from 'axios';
import { PageableResponse, KsSession } from '../models';
import { composeQuery, Query } from '../models/query';

export const getKsSessions = async (...criteria: Query<KsSession>[]) => (await Axios.get<PageableResponse<KsSession>>('/v1/dashboard/ks-sessions', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: composeQuery<KsSession>(...criteria),
  data: {},
})).data;
