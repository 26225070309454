import { IntlShape } from 'react-intl';
import { TestOpsEnterprisePlan } from './testOpsEnterprisePlan';
import { RecurlySubscriptionDTO } from './recurlySubscriptionDTO';

export interface Invoice {
  invoiceNumber?: string,
  productId?: string,
  planId?: string,
  subscriptionId?: string,
  total?: number,
  tax?: number,
  subTotal?: number,
  discountAmount?: number,
  startDate?: Date,
  endDate?: Date,
  errorMessage?: string,
  nameProduct?: string,
  organizationId?: number,
  accountId?: number,
  plan?: TestOpsEnterprisePlan,
  state?: InvoiceState,
  quantity?: number,
  subscriptions?: RecurlySubscriptionDTO[],
}

enum InvoiceState {
  PENDING = 'pending',
  PAST_DUE = 'past_due',
  PROCESSING = 'processing',
  FAILED = 'failed',
  PAID = 'paid',

}

// https://github.com/typescript-eslint/typescript-eslint/issues/3568
// eslint-disable-next-line @typescript-eslint/no-redeclare
namespace InvoiceState {
  export const getInvoiceStatus = (state: InvoiceState | string | undefined, intl: IntlShape) => {
    switch (state) {
      case InvoiceState.PAID:
        return intl.formatMessage({ id: 'invoice_state.paid' });
      case InvoiceState.FAILED:
        return intl.formatMessage({ id: 'invoice_state.failed' });
      case InvoiceState.PENDING:
      case InvoiceState.PAST_DUE:
      case InvoiceState.PROCESSING:
        return intl.formatMessage({ id: 'invoice_state.processing' });
      default:
        return 'Unknown';
    }
  };
}

export { InvoiceState };
