import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { unwrapResult } from '@reduxjs/toolkit';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardNumberElementChangeEvent, StripeElementChangeEvent } from '@stripe/stripe-js';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import LoadingProgress from '../../../../layout/LoadingProgress';
import { Exception } from '../../../../models';
import { fromAuth, fromAccountUsers, fromOrganizations, useAppDispatch } from '../../../../store';
import { fromPaymentMethod, fromAccounts } from '../../../../store/rootReducer';
import americanExpress from '../../../icons/payment-cards/american-express.svg';
import dinersClub from '../../../icons/payment-cards/diners-club.svg';
import discover from '../../../icons/payment-cards/discover.svg';
import jcb from '../../../icons/payment-cards/jcb.svg';
import masterCard from '../../../icons/payment-cards/master-card.svg';
import unionPay from '../../../icons/payment-cards/union-pay.svg';
import visa from '../../../icons/payment-cards/visa.svg';
import { sendTrackingData } from '../../utils';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 0,
    width: '20rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.background.paper,
  },
  cardName: {
    width: '100%',
    fontSize: theme.spacing(1.75),
    fontFamily: 'Inter',
    fontSmoothing: 'antialiased',
    '&:focus': {
      color: '#081D36',
    },
    '&::placeholder': {
      fontFamily: 'Inter',
      color: '#A1A9B3',
      letterSpacing: 'normal',
      fontWeight: 100,
    },
    '&:focus::placeholder': {
      color: '#CFD7DF',
    },
  },
  paymentLogo: {
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
    borderRadius: theme.spacing(0.325),
  },
  disablePaymentLogo: {
    filter: 'grayscale(100%)',
    opacity: '0.2',
  },
  cardInfoLabel: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
  },
  buttonCancel: {
    marginRight: theme.spacing(1),
    float: 'right',
  },
  button: {
    float: 'right',
  },
  savedCardLogo: {
    height: theme.spacing(8.5),
    display: 'block',
    borderRadius: theme.spacing(0.5),
    marginRight: theme.spacing(1.5),
  },
  savedCard: {
    marginBottom: theme.spacing(2),
    borderBottom: '1px solid #DCDFE6',
    paddingBottom: theme.spacing(3),
  },
  savedCardInfo: {
    marginRight: theme.spacing(1.5),
  },
  checkoutTitle: {
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
  },
}));

const CardInputWrapper = styled('div')(({ theme }) => ({
  border: 'solid 1px #d5d8dd',
  borderRadius: theme.spacing(0.75),
  padding: theme.spacing(1.25, 2),
  marginRight: theme.spacing(3),
  marginBottom: theme.spacing(3),
  height: theme.spacing(5),
  backgroundColor: '#FAFBFF',
}));

const CardNameWrapper = styled('div')(({ theme }) => ({
  border: 'solid 1px #d5d8dd',
  borderRadius: theme.spacing(0.75),
  padding: theme.spacing(0.5, 2),
  marginBottom: theme.spacing(3),
  height: theme.spacing(5),
  backgroundColor: '#FAFBFF',
}));

const CardInputEndWrapper = styled('div')(({ theme }) => ({
  border: 'solid 1px #d5d8dd',
  borderRadius: theme.spacing(0.75),
  padding: theme.spacing(1.25, 2),
  marginBottom: theme.spacing(3),
  height: theme.spacing(5),
  backgroundColor: '#FAFBFF',
}));

const NUMBER_OPTIONS = {
  style: {
    base: {
      fontSize: '14px',
      fontFamily: 'Inter, sans-serif',
      fontSmoothing: 'antialiased',

      ':focus': {
        color: '#081D36',
      },
      '::placeholder': {
        color: '#A1A9B3',
      },
      ':focus::placeholder': {
        color: '#CFD7DF',
      },
    },
    invalid: {
      color: '#ff8080',
      ':focus': {
        color: '#ff5050',
      },
      '::placeholder': {
        color: '#FFCCA5',
      },
    },
  },
  placeholder: '---- ---- ---- ----',
};
const CARD_CVC_OPTIONS = {
  style: {
    base: {
      fontSize: '14px',
      fontFamily: 'Inter, sans-serif',
      fontSmoothing: 'antialiased',

      ':focus': {
        color: '#081D36',
      },
      '::placeholder': {
        color: '#A1A9B3',
      },
      ':focus::placeholder': {
        color: '#CFD7DF',
      },
    },
    invalid: {
      color: '#ff8080',
      ':focus': {
        color: '#ff5050',
      },
      '::placeholder': {
        color: '#FFCCA5',
      },
    },
  },
  placeholder: 'CVC',
};
const CARD_EXP_OPTIONS = {
  style: {
    base: {
      fontSize: '14px',
      fontFamily: 'Inter, sans-serif',
      fontSmoothing: 'antialiased',

      ':focus': {
        color: '#081D36',
      },
      '::placeholder': {
        color: '#A1A9B3',
      },
      ':focus::placeholder': {
        color: '#CFD7DF',
      },
    },
    invalid: {
      color: '#ff8080',
      ':focus': {
        color: '#ff5050',
      },
      '::placeholder': {
        color: '#FFCCA5',
      },
    },
  },
  placeholder: 'MM / YY',
};

interface CardBrand {
  src: string;
  brand: string;
}

const CARD_BRAND_LIST: CardBrand[] = [
  {
    src: visa,
    brand: 'visa',
  },
  {
    src: jcb,
    brand: 'jcb',
  },
  {
    src: masterCard,
    brand: 'mastercard',
  },
  {
    src: dinersClub,
    brand: 'dinersclub',
  },
  {
    src: discover,
    brand: 'discover',
  },
  {
    src: americanExpress,
    brand: 'americanexpress',
  },
  {
    src: unionPay,
    brand: 'unionpay',
  },
];

const fieldTrackingInfoMapper: { [key: string]: any } = {
  cardNumber: {
    name: 'card_number',
    isRequired: true,
  },
  cardExpiry: {
    name: 'expiration',
    isRequired: true,
  },
  cardCvc: {
    name: 'cvc',
    isRequired: true,
  },
  cardHolder: {
    name: 'card_holder',
    isRequired: true,
  },
};

interface CardInformationProps {
  accountId: number;
  enableSave: boolean;
  setFormCardOpen?: (isOpen: boolean) => void;
}

const CardInformation = forwardRef((props: CardInformationProps, ref) => {
  const classes = useStyles();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const elements = useElements();
  const stripe = useStripe();
  const user = useSelector(fromAuth.selectUser);
  const orgTotalMember = useSelector(fromOrganizations.selectCount);
  const { accountId, enableSave, setFormCardOpen } = props;

  const currentCard = useSelector(fromPaymentMethod.selectOneByAccountId(Number(accountId)));
  const account = useSelector(fromAccounts.selectAccountById(Number(accountId)));
  const currentAccountUser = useSelector(fromAccountUsers.selectOneByUserIdAndAccountId(
    Number(user?.id),
    Number(accountId),
  ));
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState('');
  const [isCardFormOpen, setIsCardFormOpen] = useState(true);
  const [currentCardBrand, setCurrentCardBrand] = useState('unknown');
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleCardHolderBlurred = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value) {
      sendFilledFieldTracking('cardHolder', true);
    }
  };

  const handleCancel = () => {
    setIsCardFormOpen(false);
    setFormCardOpen?.(false);
    setErrorMessage(undefined);
    setCurrentCardBrand('unknown');
    setName('');
    elements?.getElement(CardNumberElement)?.clear();
    elements?.getElement(CardExpiryElement)?.clear();
    elements?.getElement(CardCvcElement)?.clear();
    dispatch(fromPaymentMethod.doUpdateCardInformationInvalid(currentCard === undefined));
  };

  useEffect(() => {
    setIsCardFormOpen(!currentCard);
  }, [currentCard]);

  useEffect(() => {
    const getPaymentMethod = async () => {
      if (accountId) {
        await dispatch(fromPaymentMethod.doGetPaymentMethod(+accountId));
      }
    };
    getPaymentMethod().catch(() => {
    });
  }, [accountId, dispatch]);

  useEffect(() => {
    if (errorMessage !== undefined) {
      dispatch(fromPaymentMethod.doUpdateCardInformationInvalid(true));
    }
  }, [errorMessage]);

  useImperativeHandle(ref, () => ({
    renewOnline: submitPaymentMethod,
  }));

  const submitPaymentMethod = () => {
    if (!stripe || !elements || loading || !accountId) {
      return;
    }

    const doSubmit = async () => {
      setLoading(true);
      try {
        const cardNumberElement = elements.getElement(CardNumberElement);
        if (cardNumberElement) {
          const token = await stripe.createToken(cardNumberElement, {
            name,
          });
          if (token.token && currentCard) {
            await dispatch(fromPaymentMethod.doUpdatePaymentMethod({
              id: currentCard?.id,
              stripeToken: token.token.id,
            })).then(unwrapResult)
              .then(() => {
                handleCancel();
                enqueueSnackbar(<FormattedMessage id="billinginfo.cardinfo.update.success" />, { variant: 'success' });
                analytics.track(
                  'Payment Information Updated',
                  {
                    user_id: user?.id,
                    firstName: user?.firstName,
                    lastName: user?.lastName,
                    email: user?.email,
                    system_role: user?.roles,
                    org_id: account?.id,
                    org_name: account?.name,
                    org_role: currentAccountUser?.role,
                    total_members: orgTotalMember,
                  },
                );
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  user_id: user?.id,
                  org_role: currentAccountUser?.role,
                  system_role: user?.roles,
                  org_id: account?.id,
                  org_name: account?.name,
                  total_members: orgTotalMember,
                  event: 'gtm_payment_information_updated',
                });
                sendSaveButtonTracking();
              })
              .catch(showErrorAndSendTracking);
          } else if (token.token && !currentCard) {
            setLoading(true);
            await dispatch(fromPaymentMethod.doCreatePaymentMethod({
              accountId: +accountId,
              stripeToken: token.token.id,
            })).then(unwrapResult)
              .then(() => {
                handleCancel();
                enqueueSnackbar(<FormattedMessage id="billinginfo.cardinfo.save.success" />, { variant: 'success' });
                analytics.track(
                  'Payment Information Saved',
                  {
                    user_id: user?.id,
                    firstName: user?.firstName,
                    lastName: user?.lastName,
                    email: user?.email,
                    system_role: user?.roles,
                    org_id: account?.id,
                    org_name: account?.name,
                    org_role: currentAccountUser?.role,
                    total_members: orgTotalMember,
                  },
                );
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  user_id: user?.id,
                  org_role: currentAccountUser?.role,
                  system_role: user?.roles,
                  org_id: account?.id,
                  org_name: account?.name,
                  total_members: orgTotalMember,
                  event: 'gtm_payment_information_saved',
                });
                sendSaveButtonTracking();
              })
              .catch(showErrorAndSendTracking);
          } else if (token.error) {
            enqueueSnackbar(<span>{token.error.message}</span>, { variant: 'error' });
            setErrorMessage('Invalid card information');
            // Invalid information from Stripe
            sendSaveButtonTracking(token.error.code);
          }
        }
      } finally {
        setLoading(false);
      }
    };
    doSubmit().catch(() => {
    });
  };

  const showErrorAndSendTracking = (ex: Exception) => {
    enqueueSnackbar(<span>{ex.message}</span>, { variant: 'error' });
    sendSaveButtonTracking(ex.message);
  };

  const sendSaveButtonTracking = (error?: string) => {
    const isValid = !error;
    const additionInfo = {
      field: 'payment',
      object: 'button',
      action: 'save',
      is_required: true,
      is_valid: isValid,
      error,
    };

    sendTrackingData('checkout_page_all_action', accountId, undefined, additionInfo);
    sendTrackingData('checkout_page_information_saved', accountId, undefined, additionInfo);
  };

  const sendFilledFieldTracking = (field: string, isValid: boolean, errorCode?: string) => {
    const convertedField = fieldTrackingInfoMapper[field];
    sendTrackingData('checkout_page_all_action', accountId, undefined, {
      field: 'payment',
      object: convertedField.name,
      action: 'fill',
      is_required: convertedField.isRequired,
      is_valid: isValid,
      error: isValid ? undefined : errorCode,
    });
  };

  const onFieldFilled = (event: StripeElementChangeEvent) => {
    // Field is blurred and has error
    if (event.error) {
      sendFilledFieldTracking(event.elementType, false, event.error.code);
      return;
    }
    // Field is marked as complete
    if (event.complete) {
      sendFilledFieldTracking(event.elementType, true);
    }
  };
  const handleChangeCardNumber = (e: StripeCardNumberElementChangeEvent) => {
    setCurrentCardBrand(e.brand ?? 'unknown');
    dispatch(fromPaymentMethod.doUpdateCardInformationInvalid(
      errorMessage !== undefined || e.error !== undefined,
    ));
    setErrorMessage(e.error?.message);
    onFieldFilled(e);
  };
  const handleCVCChange = (e: StripeElementChangeEvent) => {
    dispatch(
      fromPaymentMethod.doUpdateCardInformationInvalid(
        errorMessage !== undefined || e.error !== undefined,
      ),
    );
    setErrorMessage(e.error?.message);
    onFieldFilled(e);
  };
  const handleExpirationChange = (e: StripeElementChangeEvent) => {
    dispatch(
      fromPaymentMethod.doUpdateCardInformationInvalid(
        errorMessage !== undefined || e.error !== undefined,
      ),
    );
    setErrorMessage(e.error?.message);
    onFieldFilled(e);
  };

  const renderPaymentLogoList = (cardBrand: CardBrand) => (
    <img
      key={cardBrand.brand}
      src={cardBrand.src}
      alt="payment-logo"
      className={clsx(currentCardBrand.toLowerCase() === cardBrand.brand
        ? classes.paymentLogo
        : [classes.paymentLogo, classes.disablePaymentLogo])}
    />
  );

  const getSavedPaymentCardLogo = () => {
    const cardBrand = CARD_BRAND_LIST.find(it => it.brand === currentCard?.brand?.toLowerCase());
    return cardBrand ? (
      <img
        src={cardBrand.src}
        className={classes.savedCardLogo}
        alt="current-logo"
      />
    ) : null;
  };

  const openCardForm = () => {
    setIsCardFormOpen(true);
    setFormCardOpen?.(true);
    dispatch(fromPaymentMethod.doUpdateCardInformationInvalid(true));
  };

  return (
    <>
      <Grid id="card-information" container>
        <Grid item xs={8}>
          <Typography variant="h3" className={classes.checkoutTitle}>
            <FormattedMessage id="billinginfo.payment" />
          </Typography>
        </Grid>
        <Grid item xs={4}>
          {!isCardFormOpen && (
            <Button
              id="card-update-btn"
              className={classes.button}
              variant="outlined"
              onClick={openCardForm}
            >
              <FormattedMessage id="billinginfo.button.update" />
            </Button>
          )}
          {isCardFormOpen && (
            <Grid>
              {enableSave && (
                <Button
                  id="card-submit-btn"
                  variant="contained"
                  className={classes.button}
                  color="primary"
                  onClick={submitPaymentMethod}
                >
                  {intl.formatMessage({ id: 'billinginfo.button.save' })}
                </Button>
              )}
              {!!currentCard && (
                <Button
                  id="card-cancel-btn"
                  variant="contained"
                  color="inherit"
                  className={classes.buttonCancel}
                  onClick={handleCancel}
                >
                  {intl.formatMessage({ id: 'billinginfo.button.cancel' })}
                </Button>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      {!!currentCard && (
        <Grid container alignItems="center" className={classes.savedCard}>
          {getSavedPaymentCardLogo()}
          <Grid item className={classes.savedCardInfo}>
            <Typography variant="h3" sx={{ textTransform: 'capitalize' }}>
              {`${currentCard.brand} **** ${currentCard.last4}`}
            </Typography>
            <Typography variant="subtitle2">
              {`Expires ${currentCard.expiration}`}
            </Typography>
          </Grid>
        </Grid>
      )}
      {isCardFormOpen && (
        <Grid>
          <div>{CARD_BRAND_LIST.map(it => renderPaymentLogoList(it))}</div>
          <Grid container alignItems="flex-end">
            <Grid item xs={6}>
              <Typography className={classes.cardInfoLabel} variant="subtitle2">Card Number</Typography>
              <CardInputWrapper>
                <CardNumberElement options={NUMBER_OPTIONS} onChange={handleChangeCardNumber} />
              </CardInputWrapper>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.cardInfoLabel} variant="subtitle2">
                <FormattedMessage id="billinginfo.cardinfo.expiration" />
              </Typography>
              <CardInputWrapper>
                <CardExpiryElement options={CARD_EXP_OPTIONS} onChange={handleExpirationChange} />
              </CardInputWrapper>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.cardInfoLabel} variant="subtitle2">
                <FormattedMessage id="billinginfo.cardinfo.cvc" />
              </Typography>
              <CardInputEndWrapper>
                <CardCvcElement options={CARD_CVC_OPTIONS} onChange={handleCVCChange} />
              </CardInputEndWrapper>
            </Grid>
          </Grid>
          <Grid>
            <Typography className={classes.cardInfoLabel} variant="subtitle2">
              <FormattedMessage id="billinginfo.cardinfo.card_holder" />
            </Typography>
            <CardNameWrapper>
              <InputBase
                className={classes.cardName}
                placeholder="Name on card"
                onChange={handleChangeName}
                onBlur={handleCardHolderBlurred}
              />
            </CardNameWrapper>
          </Grid>
          {errorMessage && (
            <Typography variant="subtitle2" color="secondary" fontWeight={500} mb={2}>
              {errorMessage}
            </Typography>
          )}
          {loading && <LoadingProgress />}
        </Grid>
      )}
    </>
  );
});

export default CardInformation;
