import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import DialogTransition from '../../../components/transition/DialogTransition';
import { ReactivateSubscriptionIcon } from '../../../layout/CustomIcon';
import LoadingProgress from '../../../layout/LoadingProgress';
import { OrganizationFeature, RecurlySubscriptionPayloadStatus, TestCloudSubscription } from '../../../models';
import { fromTestCloudSubscriptions, useAppDispatch } from '../../../store';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.spacing(2),
  },
  title: {
    padding: theme.spacing(0, 3),
    fontWeight: theme.typography.fontWeightBold,
    color: '#233145',
  },
  dialogContainer: {
    margin: theme.spacing(3),
    maxWidth: theme.spacing(56),
  },
  currentPlan: {
    fontWeight: theme.typography.fontWeightBold,
  },
  dialogContent: {
    lineHeight: theme.spacing(3),
    margin: theme.spacing(1, 0),
  },
  dialogContentContainer: {
    padding: theme.spacing(1, 3, 2, 3),
  },
  content: {
    color: '#172B4D',
    fontSize: '0.875rem',
  },
  reactivateButton: {
    marginLeft: theme.spacing(2),
    height: theme.spacing(3),
    fontWeight: theme.typography.fontWeightMedium,
    minWidth: theme.spacing(12.5),
  },
  confirmButton: {
    height: theme.spacing(4),
    fontWeight: theme.typography.fontWeightMedium,
  },
  getBackButton: {
    color: '#233145',
    height: theme.spacing(4),
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const ReactivateSubscriptionButton = (props: { subscription: TestCloudSubscription }) => {
  const { subscription } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);
      await dispatch(fromTestCloudSubscriptions.doReactivateSubscription({ id: subscription.id }))
        .then(unwrapResult)
        .then(() => {
          // TODO: change message when product team update message on ticket
          enqueueSnackbar(
            <FormattedMessage
              id="subscriptions.testcloud.reactivate_dialog.success"
              values={{
                planName: OrganizationFeature.getFeatureFullName(subscription.feature),
              }}
            />,
            { variant: 'success' },
          );
          // reload to get new status of recurly subscription & cancelledAt
          setTimeout(() => window.location.reload(), 200);
        });
    } catch (e) {
      // TODO: show speific error if needed
      enqueueSnackbar(
        <FormattedMessage id="subscriptions.testcloud.reactivate_dialog.failed" />,
        { variant: 'error' },
      );
      setLoading(false);
      setOpen(false);
    }
  };

  if (subscription.recurlySubscription?.status !== RecurlySubscriptionPayloadStatus.CANCELED
    || subscription.feature === OrganizationFeature.TESTCLOUD_MINUTE) {
    return null;
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        className={classes.reactivateButton}
        onClick={() => setOpen(true)}
      >
        <FormattedMessage id="subscriptions.testcloud.reactivate_dialog.reactivate" />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{
          paper: classes.root,
        }}
        TransitionComponent={DialogTransition}
      >
        <div
          className={classes.dialogContainer}
        >
          <Grid
            container
            justifyContent="center"
          >
            <ReactivateSubscriptionIcon />
          </Grid>
          <Typography
            className={classes.title}
            variant="h3"
          >
            <FormattedMessage
              id="subscriptions.testcloud.reactivate_dialog.title"
            />
          </Typography>
          <DialogContent
            className={classes.dialogContentContainer}
          >
            <DialogContentText id="alert-dialog-description">
              <Typography className={classes.content}>
                <FormattedMessage
                  id="subscriptions.testcloud.reactivate_dialog.description"
                />
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant="contained"
              className={classes.getBackButton}
            >
              <FormattedMessage id="subscriptions.testcloud.reactivate_dialog.get_back" />
            </Button>
            <Button
              onClick={handleConfirm}
              variant="contained"
              className={classes.confirmButton}
              color="primary"
            >
              <FormattedMessage id="subscriptions.testcloud.reactivate_dialog.reactivate" />
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      {loading && <LoadingProgress />}
    </>
  );
};

export default ReactivateSubscriptionButton;
