import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const K1TextField = styled(TextField)(({ theme }) => ({
  '& > .MuiInputBase-root': {
    '& > input, & > .MuiSelect-select': {
      padding: theme.spacing(1, 1.5),
      fontSize: '14px',
      lineHeight: '20px',
      backgroundColor: theme.palette.background.paper,
    },
    '& > fieldset': {
      borderColor: '#D5D8DD !important', // or put a theme color here
      borderRadius: '6px',
      borderWidth: '1px !important',
    },
  },
}));

export default K1TextField;
