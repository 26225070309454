import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import map from 'lodash/fp/map';
import sumBy from 'lodash/fp/sumBy';
import filter from 'lodash/fp/filter';
import { AppState } from '.';
import {
  exceptionOf,
  getFeatureFromPlan,
  SingleProductOrder,
  OrganizationFeature,
  SerializedException,
  StarterPackage,
} from '../models';
import { Invoice } from '../models/invoice';
import { InvoiceService } from '../services';
import { StarterPackagePreview } from '../models/StarterPackagePreview';

export const SUBSCRIPTION_INVOICE_PENDING_ERROR = 'subscription.no.charge.invoice.found';
export const SUBSCRIPTION_CANNOT_PREVIEW_ERROR = 'subscription.cannot.preview.subscription';
export const INVOICE_FEATURE_KEY = 'invoices';

interface InvoicePreview {
  feature: OrganizationFeature;
  loading: boolean;
  invoice?: Invoice;
  order?: SingleProductOrder;
}

interface StarterPackageInvoicePreview {
  packageLoading: boolean;
  packagePreview?: StarterPackagePreview;
}

interface InvoicePreviewState extends EntityState<InvoicePreview> {
}

const invoicePreviewEntityAdapter = createEntityAdapter<InvoicePreview>({
  selectId: invoicePreview => invoicePreview.feature,
});

const setInvoicePreviewByFeature = (
  mainState: InvoiceState,
  feature: OrganizationFeature,
  isLoading: boolean,
  order?: SingleProductOrder,
  invoice?: Invoice,
) => {
  invoicePreviewEntityAdapter.upsertOne(
    mainState.invoicePreviews,
    {
      feature,
      loading: isLoading,
      invoice,
      order,
    },
  );
};

const setInvoicePreviewForPlan = (
  mainState: InvoiceState,
  planId: string,
  isLoading: boolean,
  order?: SingleProductOrder,
  invoice?: Invoice,
) => {
  const feature = getFeatureFromPlan(planId) as OrganizationFeature;
  setInvoicePreviewByFeature(mainState, feature, isLoading, order, invoice);
};

interface InvoiceState extends EntityState<Invoice> {
  order: SingleProductOrder | null;
  starterPackage: StarterPackage | null;
  preview: Invoice;
  pendingInvoices: Invoice[];
  loading: boolean;
  disabled: boolean;
  errors: SerializedException[];
  invoicePreviews: InvoicePreviewState;
  starterPackageInvoicePreview: StarterPackageInvoicePreview;
  renewalInvoices: Invoice[];
  pastDueInvoices: Invoice[];
  retryPaymentSuccess?: boolean;
}

const entityAdapter = createEntityAdapter<Invoice>({
  selectId: invoice => invoice.invoiceNumber!!,
});

const createInitialState = (): InvoiceState => entityAdapter.getInitialState({
  preview: {},
  order: null,
  starterPackage: null,
  pendingInvoices: [],
  loading: false,
  disabled: false,
  errors: [],
  invoicePreviews: invoicePreviewEntityAdapter.getInitialState(),
  starterPackageInvoicePreview: {
    packageLoading: false,
  },
  starterPackageOld: null,
  renewalInvoices: [],
  pastDueInvoices: [],
  retryPaymentSuccess: undefined,
});

export const GET_INVOICE_RECURLY_SUBSCRIPTION_NOT_EXISTED = 'invoice.recurly.subscription.not.existed';
export const GET_INVOICE_CANNOT_READ_RECURLY_SUBSCRIPTION = 'invoice.cannot.read.recurly.subscription';

export const doGetInvoiceByInvoiceNumbers = createAsyncThunk(
  'invoices/getByInvoiceNumber',
  async (invoiceRequest: Required<Parameters<typeof InvoiceService['getInvoices']>>[0], { rejectWithValue }) => {
    try {
      const response = await InvoiceService.getInvoices(invoiceRequest);
      return response;
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doPreviewInvoice = createAsyncThunk(
  'invoices/previewInvoice',
  async (order: Parameters<typeof InvoiceService['previewInvoice']>[0], { rejectWithValue }) => {
    try {
      const response = await InvoiceService.previewInvoice(order);
      return { order, preview: response };
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doGetPendingInvoices = createAsyncThunk(
  'invoices/getPendingInvoices',
  async (invoiceRequest: Parameters<typeof InvoiceService['getPendingInvoices']>[0], { rejectWithValue }) => {
    try {
      const response = await InvoiceService.getPendingInvoices(invoiceRequest);
      return response;
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doResetPreview = createAction('invoices/resetPreview');

export const doResetPreviewByFeature = createAction('invoices/resetPreviewByFeature', (feature: OrganizationFeature) => ({
  payload: {
    feature,
  },
}));

export const doPreviewStarterPackageInvoice = createAsyncThunk(
  'invoices/previewStarterPackageInvoice',
  async (starterPackageOrder: Parameters<typeof InvoiceService['previewStarterPackageInvoice']>[0], { rejectWithValue }) => {
    try {
      const response = await InvoiceService.previewStarterPackageInvoice(starterPackageOrder);
      return { packageName: starterPackageOrder.packageName, data: response };
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doPreviewRenewal = createAsyncThunk(
  'invoices/previewRenewal',
  async (accountId: number, { rejectWithValue }) => {
    try {
      const response = await InvoiceService.previewRenewal(accountId);
      return response;
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doFetchPastDueInvoice = createAsyncThunk(
  'invoices/fetchPastDueInvoice',
  async (accountId: number, { rejectWithValue }) => {
    try {
      const response = await InvoiceService.fetchPastDueInvoice(accountId);
      return response;
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doRetryPayment = createAsyncThunk(
  'invoices/retryPayment',
  async (accountId: number, { rejectWithValue }) => {
    try {
      const response = await InvoiceService.retryPayment(accountId);
      return response;
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

const invoiceSlice = createSlice({
  name: INVOICE_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {
    doResetErrors(state) {
      state.errors = [];
    },
    doDisabledOffering(state, action: PayloadAction<boolean>) {
      state.disabled = action.payload;
    },
    doResetRetryPaymentStatus(state) {
      state.retryPaymentSuccess = undefined;
    },
  },
  extraReducers: builder => {
    builder.addCase(doGetInvoiceByInvoiceNumbers.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetInvoiceByInvoiceNumbers.fulfilled, (state, action) => {
      state.loading = false;
      entityAdapter.upsertMany(state, action.payload);
    });
    builder.addCase(doGetInvoiceByInvoiceNumbers.rejected, (state, action) => {
      state.loading = false;
      state.errors.push(action.payload as SerializedException);
    });
    builder.addCase(doGetPendingInvoices.pending, state => {
      state.loading = true;
      state.pendingInvoices = [];
    });
    builder.addCase(doGetPendingInvoices.fulfilled, (state, action) => {
      state.loading = false;
      state.pendingInvoices = action.payload;
    });
    builder.addCase(doGetPendingInvoices.rejected, (state, action) => {
      state.loading = false;
      state.errors.push(action.payload as SerializedException);
    });
    builder.addCase(doPreviewInvoice.pending, (state, action) => {
      state.loading = true;
      const order = action.meta.arg;
      setInvoicePreviewForPlan(state, order.planId, true);
    });
    builder.addCase(doPreviewInvoice.fulfilled, (state, action) => {
      state.loading = false;
      state.preview = action.payload.preview;
      state.order = action.payload.order;
      const { planId } = action.payload.order;
      setInvoicePreviewForPlan(
        state,
        planId,
        false,
        action.payload.order,
        action.payload.preview,
      );
    });
    builder.addCase(doPreviewInvoice.rejected, (state, action) => {
      state.loading = false;
      state.errors.push(action.payload as SerializedException);
    });
    builder.addCase(doPreviewStarterPackageInvoice.pending, state => {
      state.loading = true;
      state.starterPackageInvoicePreview.packageLoading = true;
    });
    builder.addCase(doPreviewStarterPackageInvoice.fulfilled, (state, action) => {
      state.loading = false;
      state.starterPackageInvoicePreview.packageLoading = false;
      state.starterPackageInvoicePreview.packagePreview = action.payload.data;
    });
    builder.addCase(doPreviewStarterPackageInvoice.rejected, (state, action) => {
      state.loading = false;
      state.errors.push(action.payload as SerializedException);
    });
    builder.addCase(doResetPreview, state => {
      state.preview = {};
      state.invoicePreviews = invoicePreviewEntityAdapter.getInitialState();
      state.starterPackageInvoicePreview.packagePreview = undefined;
    });
    builder.addCase(doResetPreviewByFeature, (state, action) => {
      setInvoicePreviewByFeature(state, action.payload.feature, false);
    });
    builder.addCase(doPreviewRenewal.pending, state => {
      state.loading = true;
      state.renewalInvoices = [];
    });
    builder.addCase(doPreviewRenewal.fulfilled, (state, action) => {
      state.loading = false;
      state.renewalInvoices = action.payload;
    });
    builder.addCase(doPreviewRenewal.rejected, (state, action) => {
      state.loading = false;
      state.errors.push(action.payload as SerializedException);
    });
    builder.addCase(doRetryPayment.pending, state => {
      state.loading = true;
    });
    builder.addCase(doRetryPayment.fulfilled, state => {
      state.loading = false;
      state.retryPaymentSuccess = true;
    });
    builder.addCase(doRetryPayment.rejected, (state, action) => {
      state.loading = false;
      state.retryPaymentSuccess = false;
      state.errors.push(action.payload as SerializedException);
    });
    builder.addCase(doFetchPastDueInvoice.pending, state => {
      state.loading = true;
    });
    builder.addCase(doFetchPastDueInvoice.fulfilled, (state, action) => {
      state.loading = false;
      state.pastDueInvoices = action.payload;
    });
    builder.addCase(doFetchPastDueInvoice.rejected, (state, action) => {
      state.loading = false;
      state.errors.push(action.payload as SerializedException);
    });
  },
});

const selectInvoiceFeature = (state: AppState) => state[INVOICE_FEATURE_KEY];

export const selectLoading = createSelector(
  selectInvoiceFeature,
  invoiceState => invoiceState.loading || invoiceState.starterPackageInvoicePreview.packageLoading,
);

export const selectErrors = () => createSelector(
  selectInvoiceFeature,
  invoiceState => invoiceState.errors,
);

export const selectInvoiceByNumbers = (invoiceNumbers: string[]) => createSelector(
  selectInvoiceFeature,
  invoiceState => invoiceNumbers.map(it => invoiceState.entities[it]),
);

export const selectPendingInvoices = createSelector(
  selectInvoiceFeature,
  invoiceState => invoiceState.pendingInvoices,
);

export const selectPreview = createSelector(
  selectInvoiceFeature,
  it => it.preview,
);

export const selectOrder = createSelector(
  selectInvoiceFeature,
  it => it.order,
);

export const selectLoadingByFeature = (feature: OrganizationFeature) => createSelector(
  selectInvoiceFeature,
  it => {
    const selectors = invoicePreviewEntityAdapter.getSelectors();
    const invoicePreview = selectors.selectById(it.invoicePreviews, feature);
    return invoicePreview?.loading ?? false;
  },
);

export const selectInvoicePreviewByFeature = (feature: OrganizationFeature) => createSelector(
  selectInvoiceFeature,
  it => invoicePreviewEntityAdapter.getSelectors().selectById(it.invoicePreviews, feature),
);

export const selectPreviewByFeature = (feature: OrganizationFeature) => createSelector(
  selectInvoiceFeature,
  it => {
    const selectors = invoicePreviewEntityAdapter.getSelectors();
    const invoicePreview = selectors.selectById(it.invoicePreviews, feature);
    return invoicePreview?.invoice ?? {};
  },
);

const isLoadedPreview = (invoicePreview: InvoicePreview):boolean => !invoicePreview.loading
  && !!invoicePreview.invoice
  && !!invoicePreview.order;

const getLoadedPreviews = (state: InvoiceState): InvoicePreview[] => {
  const selectors = invoicePreviewEntityAdapter.getSelectors();
  const invoicePreviews = selectors.selectAll(state.invoicePreviews);
  return filter(invoicePreview => isLoadedPreview(invoicePreview), invoicePreviews);
};

export const selectPreviews = createSelector(
  selectInvoiceFeature,
  it => getLoadedPreviews(it),
);

export const selectOrders = createSelector(
  selectInvoiceFeature,
  it => {
    const previews = getLoadedPreviews(it);
    return map(preview => preview.order as SingleProductOrder, previews);
  },
);

export const selectPreviewsSubTotal = createSelector(
  selectInvoiceFeature,
  it => {
    const previews = getLoadedPreviews(it);
    return sumBy(preview => preview.invoice?.subTotal ?? 0, previews)
      + (it.starterPackageInvoicePreview.packagePreview?.subtotal ?? 0);
  },
);

export const selectPreviewsTotal = createSelector(
  selectInvoiceFeature,
  it => {
    const previews = getLoadedPreviews(it);
    return sumBy(preview => preview.invoice?.total ?? 0, previews)
      + (it.starterPackageInvoicePreview?.packagePreview?.total ?? 0);
  },
);

export const selectDiscountTotal = createSelector(
  selectInvoiceFeature,
  it => {
    const previews = getLoadedPreviews(it);
    return sumBy(preview => preview.invoice?.discountAmount ?? 0, previews);
  },
);

export const selectPreviewsTotalTax = createSelector(
  selectInvoiceFeature,
  it => {
    const previews = getLoadedPreviews(it);
    return sumBy(preview => preview.invoice?.tax ?? 0, previews)
      + (it.starterPackageInvoicePreview.packagePreview?.tax ?? 0);
  },
);

export const selectStarterPackageInvoicePreview = createSelector(
  selectInvoiceFeature,
  it => it.starterPackageInvoicePreview,
);

export const isDisabledOffering = createSelector(
  selectInvoiceFeature,
  it => it.disabled,
);

export const selectRenewalInvoices = createSelector(
  selectInvoiceFeature,
  invoiceState => invoiceState.renewalInvoices,
);

export const selectPastDueInvoices = createSelector(
  selectInvoiceFeature,
  invoiceState => invoiceState.pastDueInvoices,
);

export const selectNearestPastDueSubscription = createSelector(
  selectInvoiceFeature,
  invoiceState => {
    if (invoiceState.pastDueInvoices.length > 0) {
      return invoiceState.pastDueInvoices.reduce((prev, curr) => {
        if (prev === undefined || prev.startDate === undefined) {
          return curr;
        }

        if (curr === undefined || curr.startDate === undefined) {
          return prev;
        }

        return prev.startDate < curr.startDate ? prev : curr;
      });
    }

    return undefined;
  },
);

export const selectRetryPaymentSuccess = createSelector(
  selectInvoiceFeature,
  invoiceState => invoiceState.retryPaymentSuccess,
);

export const {
  doResetErrors,
  doDisabledOffering,
  doResetRetryPaymentStatus,
} = invoiceSlice.actions;

export default invoiceSlice.reducer;
