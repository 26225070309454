import makeStyles from '@mui/styles/makeStyles';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import { ReactElement } from 'react';
import CloseableDialog from './CloseableDialog';

const useStyles = makeStyles(theme => ({
  dialogAction: {
    padding: 16,
  },
  dialogContent: {
    margin: theme.spacing(0, 1, 0, 1),
  },
  goBackButton: {
    backgroundColor: '#f0f1f2',
    color: 'var(--text-alias-default-text-color-initial)',
  },
}));

interface HandleSubscriptionDialogProps {
  id?: string;
  isOpenDialog: boolean;
  handleCloseDialog: Function;
  title: ReactElement;
  messageText: ReactElement;
  handleButton: ReactElement;
}

function HandleSubscriptionDialog(props: HandleSubscriptionDialogProps) {
  const classes = useStyles();

  const {
    id,
    isOpenDialog,
    handleCloseDialog,
    title,
    messageText,
    handleButton,
  } = props;

  const onCloseButtonClick = () => {
    handleCloseDialog();
  };

  return (
    <>
      <CloseableDialog
        id={id}
        isOpenDialog={isOpenDialog}
        handleCloseDialog={handleCloseDialog}
        title={title}
        maxWidth="md"
      >
        <DialogContent id={`${id}-content`} className={classes.dialogContent}>
          {messageText}
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button id={`${id}-close-btn`} className={classes.goBackButton} size="medium" variant="contained" onClick={onCloseButtonClick}>
            <FormattedMessage id="subscriptions.testops_platform.cancel.go_back" />
          </Button>
          {handleButton}
        </DialogActions>
      </CloseableDialog>
    </>
  );
}

export default HandleSubscriptionDialog;
