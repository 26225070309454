import { stringify, parse } from 'querystring';
import { useCallback, useMemo } from 'react';
import { useNavigate as useRouterNavigate, useLocation } from 'react-router-dom';

type QueryType = string | number | boolean | undefined | string[];
export type QueryDictionary<T = unknown, K extends keyof T = keyof T> = Record<K, QueryType>;

interface MergeQuery<T = unknown> {
  type: 'MERGE_QUERY';
  dictionary: QueryDictionary<T>;
}

interface ReplaceQuery<T = unknown> {
  type: 'REPLACE_QUERY',
  dictionary: QueryDictionary<T>;
}

function isReplaceQuery(arg: any): arg is ReplaceQuery {
  return arg.type === 'REPLACE_QUERY';
}

type QueryConfig<T> = MergeQuery<T> | ReplaceQuery<T>;

export const useNavigate = <T = any>() => {
  const routerNavigate = useRouterNavigate();
  const location = useLocation();

  const convertSearchString = useCallback((query: QueryConfig<T>) => (isReplaceQuery(query)
    ? stringify(query.dictionary)
    : stringify({
      ...parse(location.search.replace('?', '')),
      ...query.dictionary,
    })), [location.search]);
  const navigate = useCallback((pathname: string | undefined, query: QueryConfig<T>) => {
    routerNavigate(
      {
        pathname: pathname ? `../../../../${pathname}` : `../../../../${location.pathname}`,
        search: convertSearchString(query),
      },
    );
  }, [routerNavigate, location.pathname, convertSearchString]);
  const replace = useCallback((pathname: string | undefined, query: QueryConfig<T>) => {
    routerNavigate(
      {
        pathname: pathname ?? location.pathname,
        search: convertSearchString(query),
      },
      {
        replace: true,
      },
    );
  }, [routerNavigate, location.pathname, convertSearchString]);
  const mergeQuery = useCallback((query: QueryDictionary<T>): MergeQuery<T> => ({
    type: 'MERGE_QUERY',
    dictionary: query,
  }), []);
  const replaceQuery = useCallback((query: QueryDictionary<T>): ReplaceQuery<T> => ({
    type: 'REPLACE_QUERY',
    dictionary: query,
  }), []);

  const redirect = ((uri: string | undefined) => {
    if (typeof uri === 'string') {
      window.location.href = uri;
    }
  });

  return useMemo(() => ({
    navigate,
    replace,
    mergeQuery,
    replaceQuery,
    redirect,
  }), [navigate, replace, mergeQuery, replaceQuery]);
};
