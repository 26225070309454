import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { unwrapResult } from '@reduxjs/toolkit';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardNumberElementChangeEvent, StripeElementChangeEvent } from '@stripe/stripe-js';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import LoadingProgress from '../../layout/LoadingProgress';
import { Exception, Organization } from '../../models';
import { fromAccountUsers, fromAuth, fromOrganizations, useAppDispatch } from '../../store';
import { fromAccounts, fromPaymentMethod } from '../../store/rootReducer';
import americanExpress from '../icons/payment-cards/american-express.svg';
import dinersClub from '../icons/payment-cards/diners-club.svg';
import discover from '../icons/payment-cards/discover.svg';
import jcb from '../icons/payment-cards/jcb.svg';
import masterCard from '../icons/payment-cards/master-card.svg';
import unionPay from '../icons/payment-cards/union-pay.svg';
import visa from '../icons/payment-cards/visa.svg';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 0,
    width: '20rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.background.paper,
  },
  deleteDialogPaper: {
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  cardName: {
    width: '100%',
    fontSize: '18px',
    fontWeight: 300,
    fontFamily: 'Source Code Pro, monospace !important',
    letterSpacing: '2px',
    fontSmoothing: 'antialiased',

    '&:focus': {
      color: '#081D36',
    },
    '&::placeholder': {
      fontFamily: 'Source Code Pro, monospace',
      color: '#A1A9B3',
      letterSpacing: '5px',
      fontWeight: 100,
    },
    '&:focus::placeholder': {
      color: '#CFD7DF',
    },
  },
  paymentLogo: {
    height: '24px',
    marginRight: theme.spacing(1),
    borderRadius: '3px',
  },
  disablePaymentLogo: {
    filter: 'grayscale(100%)',
    opacity: '0.2',
  },
  cardInfoComponent: {
    margin: theme.spacing(2, 0),
  },
  cardInfoLabel: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(2),
  },
  savedCardLogo: {
    height: '45px',
    display: 'block',
    borderRadius: '4px',
    marginRight: theme.spacing(1.5),
  },
  savedCard: {
    marginBottom: theme.spacing(2),
    borderBottom: '1px solid #DCDFE6',
    paddingBottom: theme.spacing(1.5),
  },
  savedCardInfo: {
    marginRight: theme.spacing(1.5),
  },
}));

const CARD_NUMBER_OPTIONS = {
  style: {
    base: {
      fontSize: '18px',
      fontWeight: 300,
      fontFamily: 'Source Code Pro, monospace',
      letterSpacing: '2px',
      fontSmoothing: 'antialiased',

      ':focus': {
        color: '#081D36',
      },
      '::placeholder': {
        color: '#A1A9B3',
        letterSpacing: '5px',
        fontWeight: 100,
      },
      ':focus::placeholder': {
        color: '#CFD7DF',
      },
    },
    invalid: {
      color: '#ff8080',
      ':focus': {
        color: '#ff5050',
      },
      '::placeholder': {
        color: '#FFCCA5',
      },
    },
  },
  placeholder: '---- ---- ---- ----',
};
const CARD_CVC_OPTIONS = {
  style: {
    base: {
      fontSize: '18px',
      fontWeight: 300,
      fontFamily: 'Source Code Pro, monospace',
      letterSpacing: '2px',
      fontSmoothing: 'antialiased',

      ':focus': {
        color: '#081D36',
      },
      '::placeholder': {
        color: '#A1A9B3',
        letterSpacing: '5px',
        fontWeight: 100,
      },
      ':focus::placeholder': {
        color: '#CFD7DF',
      },
    },
    invalid: {
      color: '#ff8080',
      ':focus': {
        color: '#ff5050',
      },
      '::placeholder': {
        color: '#FFCCA5',
      },
    },
  },
  placeholder: 'CVC',
};
const CARD_EXP_OPTIONS = {
  style: {
    base: {
      fontSize: '18px',
      fontWeight: 300,
      fontFamily: 'Source Code Pro, monospace',
      letterSpacing: '2px',
      fontSmoothing: 'antialiased',

      ':focus': {
        color: '#081D36',
      },
      '::placeholder': {
        color: '#A1A9B3',
        letterSpacing: '5px',
        fontWeight: 100,
      },
      ':focus::placeholder': {
        color: '#CFD7DF',
      },
    },
    invalid: {
      color: '#ff8080',
      ':focus': {
        color: '#ff5050',
      },
      '::placeholder': {
        color: '#FFCCA5',
      },
    },
  },
  placeholder: 'MM / YY',
};

interface CardBrand {
  src: string;
  brand: string;
}
const CARD_BRAND_LIST: CardBrand[] = [
  {
    src: visa,
    brand: 'visa',
  },
  {
    src: jcb,
    brand: 'jcb',
  },
  {
    src: masterCard,
    brand: 'mastercard',
  },
  {
    src: dinersClub,
    brand: 'dinersclub',
  },
  {
    src: discover,
    brand: 'discover',
  },
  {
    src: americanExpress,
    brand: 'americanexpress',
  },
  {
    src: unionPay,
    brand: 'unionpay',
  },
];

const CardInformation = (props: Required<Pick<Organization, 'accountId'>>) => {
  const classes = useStyles();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const elements = useElements();
  const stripe = useStripe();
  const user = useSelector(fromAuth.selectUser);
  const orgTotalMember = useSelector(fromOrganizations.selectCount);
  const { accountId } = props;
  const currentCard = useSelector(fromPaymentMethod.selectOneByAccountId(Number(accountId)));
  const account = useSelector(fromAccounts.selectAccountById(Number(accountId)));
  const currentAccountUser = useSelector(fromAccountUsers.selectOneByUserIdAndAccountId(
    Number(user?.id),
    Number(accountId),
  ));
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState('');
  const [isCardFormOpen, setIsCardFormOpen] = useState(true);
  const [currentCardBrand, setCurrentCardBrand] = useState(intl.formatMessage({ id: 'unknown' }));
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isCardNumberComplete, setIsCardNumberComplete] = useState(false);
  const [isExpirationComplete, setIsExpirationComplete] = useState(false);
  const [isCVCComplete, setIsCVCComplete] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleCancel = () => {
    setIsCardFormOpen(false);
    setErrorMessage(undefined);
    setCurrentCardBrand(intl.formatMessage({ id: 'unknown' }));
    setIsCardNumberComplete(false);
    setIsExpirationComplete(false);
    setIsCVCComplete(false);
    setName('');
    elements?.getElement(CardNumberElement)?.clear();
    elements?.getElement(CardExpiryElement)?.clear();
    elements?.getElement(CardCvcElement)?.clear();
  };

  const handleCloseDeleteDialog = () => setIsDeleteDialogOpen(false);

  const handleDeletePaymentMethod = async () => {
    if (!currentCard) return;
    setLoading(true);
    await dispatch(fromPaymentMethod.doDeletePaymentMethod({
      id: currentCard!!.id,
    })).then(unwrapResult)
      .then(() => {
        handleCancel();
        handleCloseDeleteDialog();
        enqueueSnackbar(<FormattedMessage id="billinginfo.cardinfo.delete.success" />, { variant: 'success' });
      })
      .catch((ex: Exception) => enqueueSnackbar(<span>{ex.message}</span>, { variant: 'error' }))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setIsCardFormOpen(!currentCard);
  }, [currentCard]);

  const submitPaymentMethod = async () => {
    if (!stripe || !elements) {
      return;
    }

    setLoading(true);
    if (accountId) {
      const cardNumberElement = elements.getElement(CardNumberElement);
      if (cardNumberElement) {
        const token = await stripe.createToken(cardNumberElement, {
          name,
        });
        if (token.token && currentCard) {
          await dispatch(fromPaymentMethod.doUpdatePaymentMethod({
            id: currentCard?.id,
            stripeToken: token.token.id,
          })).then(unwrapResult)
            .then(() => {
              handleCancel();
              enqueueSnackbar(<FormattedMessage id="billinginfo.cardinfo.update.success" />, { variant: 'success' });
              analytics.track(
                'Payment Information Updated',
                {
                  user_id: user?.id,
                  firstName: user?.firstName,
                  lastName: user?.lastName,
                  email: user?.email,
                  system_role: user?.roles,
                  org_id: account?.id,
                  org_name: account?.name,
                  org_role: currentAccountUser?.role,
                  total_members: orgTotalMember,
                },
              );
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                user_id: user?.id,
                org_role: currentAccountUser?.role,
                system_role: user?.roles,
                org_id: account?.id,
                org_name: account?.name,
                total_members: orgTotalMember,
                event: 'gtm_payment_information_updated',
              });
            })
            .catch((ex: Exception) => enqueueSnackbar(<span>{ex.message}</span>, { variant: 'error' }))
            .finally(() => setLoading(false));
        } else if (token.token && !currentCard) {
          setLoading(true);
          await dispatch(fromPaymentMethod.doCreatePaymentMethod({
            accountId: +accountId,
            stripeToken: token.token.id,
          })).then(unwrapResult)
            .then(() => {
              handleCancel();
              enqueueSnackbar(<FormattedMessage id="billinginfo.cardinfo.save.success" />, { variant: 'success' });
              analytics.track(
                'Payment Information Saved',
                {
                  user_id: user?.id,
                  firstName: user?.firstName,
                  lastName: user?.lastName,
                  email: user?.email,
                  system_role: user?.roles,
                  org_id: account?.id,
                  org_name: account?.name,
                  org_role: currentAccountUser?.role,
                  total_members: orgTotalMember,
                },
              );
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                user_id: user?.id,
                org_role: currentAccountUser?.role,
                system_role: user?.roles,
                org_id: account?.id,
                org_name: account?.name,
                total_members: orgTotalMember,
                event: 'gtm_payment_information_saved',
              });
            })
            .catch((ex: Exception) => enqueueSnackbar(<span>{ex.message}</span>, { variant: 'error' }))
            .finally(() => setLoading(false));
        } else if (token.error) {
          enqueueSnackbar(<span>{token.error.message}</span>, { variant: 'error' });
          setErrorMessage(intl.formatMessage({ id: 'error.card.invalid' }));
        }
      }
    }
    setLoading(false);
  };
  const handleChangeCardNumber = (e: StripeCardNumberElementChangeEvent) => {
    setCurrentCardBrand(e.brand ?? intl.formatMessage({ id: 'unknown' }));
    setErrorMessage(e.error?.message);
    setIsCardNumberComplete(e.complete);
  };
  const handleCVCChange = (e: StripeElementChangeEvent) => {
    setErrorMessage(e.error?.message);
    setIsCVCComplete(e.complete);
  };
  const handleExpirationChange = (e: StripeElementChangeEvent) => {
    setErrorMessage(e.error?.message);
    setIsExpirationComplete(e.complete);
  };
  const renderPaymentLogoList = (cardBrand: CardBrand) => (
    <img
      key={cardBrand.brand}
      src={cardBrand.src}
      alt="payment-logo"
      className={clsx(currentCardBrand.toLowerCase() === cardBrand.brand
        ? classes.paymentLogo
        : [classes.paymentLogo, classes.disablePaymentLogo])}
    />
  );

  const getSavedPaymentCardLogo = () => {
    const cardBrand = CARD_BRAND_LIST.find(it => it.brand === currentCard?.brand?.toLowerCase());
    return cardBrand ? (
      <img
        src={cardBrand.src}
        className={classes.savedCardLogo}
        alt="current-logo"
      />
    ) : null;
  };

  return (
    <>
      <Typography
        sx={{
          mb: '0.5rem',
          pb: '0.5rem',
          fontWeight: 500,
          borderBottom: '1px solid #DCDFE6',
          fontSize: '1.125rem',
        }}
      >
        {intl.formatMessage({ id: 'billinginfo.payment_method' })}
      </Typography>
      {!!currentCard && (
        <Grid
          container
          alignItems="center"
          className={classes.savedCard}
        >
          {getSavedPaymentCardLogo()}
          <Grid item className={classes.savedCardInfo}>
            <Typography
              sx={{
                textTransform: 'capitalize',
                fontWeight: 450,
                fontSize: '1.125rem',
              }}
            >
              {`${currentCard.brand} **** ${currentCard.last4}`}
            </Typography>
            <Typography variant="subtitle2">
              <FormattedMessage id="billinginfo.cardinfo.expires" values={{ expirationDate: currentCard.expiration }} />
            </Typography>
          </Grid>
          <Button
            variant="outlined"
            onClick={() => setIsCardFormOpen(true)}
            disabled={isCardFormOpen}
          >
            {intl.formatMessage({ id: 'billinginfo.button.update' })}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setIsDeleteDialogOpen(true)}
            disabled={isCardFormOpen}
            sx={{ ml: '1rem' }}
          >
            {intl.formatMessage({ id: 'billinginfo.button.delete' })}
          </Button>
        </Grid>
      )}
      <Collapse in={isCardFormOpen}>
        <div>
          <div>
            {CARD_BRAND_LIST.map(it => renderPaymentLogoList(it))}
          </div>
          <div>
            <div className={classes.cardInfoComponent}>
              <Typography className={classes.cardInfoLabel} variant="subtitle2">
                <FormattedMessage id="billinginfo.cardinfo.cardnumber" />
              </Typography>
              <CardNumberElement
                options={CARD_NUMBER_OPTIONS}
                onChange={handleChangeCardNumber}
              />
            </div>
            <div className={classes.cardInfoComponent}>
              <Typography className={classes.cardInfoLabel} variant="subtitle2">
                <FormattedMessage id="billinginfo.cardinfo.expiration" />
              </Typography>
              <CardExpiryElement
                options={CARD_EXP_OPTIONS}
                onChange={handleExpirationChange}
              />
            </div>
            <div className={classes.cardInfoComponent}>
              <Typography className={classes.cardInfoLabel} variant="subtitle2">
                <FormattedMessage id="billinginfo.cardinfo.cvc" />
              </Typography>
              <CardCvcElement
                options={CARD_CVC_OPTIONS}
                onChange={handleCVCChange}
              />
            </div>
            <div className={classes.cardInfoComponent}>
              <Typography className={classes.cardInfoLabel} variant="subtitle2">
                <FormattedMessage id="billinginfo.cardinfo.card_holder" />
              </Typography>
              <InputBase
                className={classes.cardName}
                placeholder={intl.formatMessage({ id: 'billinginfo.cardinfo.placeholder.card_holder' })}
                onChange={handleChangeName}
                value={name}
              />
            </div>
          </div>
          {errorMessage && (
            <Typography variant="subtitle2" color="secondary" fontWeight={500} mb={2}>
              {errorMessage}
            </Typography>
          )}
          <Grid container alignItems="flex-end">
            <Button
              variant="contained"
              className={classes.button}
              color="primary"
              onClick={submitPaymentMethod}
              disabled={!!errorMessage || currentCardBrand === 'unknown' || !isCVCComplete || !isCardNumberComplete || !isExpirationComplete || !name?.length}
            >
              {intl.formatMessage({ id: 'billinginfo.button.save' })}
            </Button>
            {currentCard && (
              <Button variant="contained" color="inherit" className={classes.button} onClick={handleCancel}>
                {intl.formatMessage({ id: 'billinginfo.button.cancel' })}
              </Button>
            )}
          </Grid>
        </div>
      </Collapse>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        classes={{
          paper: classes.deleteDialogPaper,
        }}
      >
        <DialogTitle>
          <FormattedMessage id="billinginfo.delete_dialog.title" />
        </DialogTitle>
        <DialogContent>
          <Typography>
            <FormattedMessage
              id="billinginfo.delete_dialog.warning1"
              values={{ b: (name: string) => <strong>{name}</strong> }}
            />
          </Typography>
          <Typography>
            <FormattedMessage
              id="billinginfo.delete_dialog.warning2"
              values={{ accountName: account?.name }}
            />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDeleteDialog}
            variant="contained"
          >
            <FormattedMessage id="billinginfo.delete_dialog.cancel" />
          </Button>
          <Button
            onClick={handleDeletePaymentMethod}
            variant="contained"
            color="secondary"
          >
            <FormattedMessage id="billinginfo.delete_dialog.delete" />
          </Button>
        </DialogActions>
      </Dialog>
      {loading && <LoadingProgress />}
    </>
  );
};

export default CardInformation;
