import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';

import { AppState } from '.';
import { Organization, User, OrganizationUserView, OrganizationMigrationStatus } from '../models';
import { Operator, Query } from '../models/query';
import { OrganizationService, OrganizationUserService } from '../services';
import { doHideMenuByNavigateToCreateOrg } from './layoutSlice';
import { doGetOrgUserByUserIdAndAccountId } from './organizationUsersSlice';

export const ORGANIZATION_FEATURE_KEY = 'organizations';

interface OrganizationEntity extends Organization {
  orgUsers: User['id'][];
}

const toEntity = (org: Organization, orgUsers: User['id'][] = []): OrganizationEntity => ({
  ...org,
  orgUsers,
});

interface OrganizationsState extends EntityState<OrganizationEntity> {
  loading: { [key: string]: boolean };
  error?: string;
  selectedId: Organization['id'] | null;
  migrationStatus: { [key: number]: OrganizationMigrationStatus | undefined };
  count: number;
}

const organizationsAdapter = createEntityAdapter<OrganizationEntity>();
const MAX_NUMBER_OF_RECORDS = 50;

export const createInitialState = (partialState: Partial<OrganizationsState> = {}) => (
  organizationsAdapter.getInitialState({
    loading: {},
    selectedId: null,
    count: 0,
    projectCountByOrgId: {},
    projectsByOrgId: {},
    migrationStatus: {},
    ...partialState, // overwrite if necessary
  })
);

export const doCreateOrganization = createAsyncThunk(
  'organizations/createOrganization',
  async (input: Parameters<typeof OrganizationService['createOrganization']>[0]) => {
    const organization = await OrganizationService.createOrganization(input);
    return { organization: toEntity(organization) };
  },
);

// seems not used anywhere
export const doFetchOrganizations = createAsyncThunk(
  'organizations/fetchOrganizations',
  async () => {
    const organizations = await OrganizationService.getOrganizations();
    return { organizations: organizations.data.map(org => toEntity(org)) };
  },
);

export const doFetchOrganizationById = createAsyncThunk(
  'organizations/fetchOrganizationsById',
  async (input: Required<Pick<Organization, 'id'>>) => {
    const orgCriteria: Array<Query<Organization>> = [
      {
        field: 'id',
        operator: Operator.EQ,
        value: input.id,
      },
      {
        field: 'archived',
        operator: Operator.EQ,
        value: false,
      },
    ];

    return fetchData(orgCriteria);
  },
);

export const doFetchOrganizationByAccountId = createAsyncThunk(
  'organizations/fetchOrganizationsByAccountId',
  async (input: Required<Pick<Organization, 'accountId'>>) => {
    const orgCriteria: Array<Query<Organization>> = [
      {
        field: 'accountId',
        operator: Operator.EQ,
        value: input.accountId,
      },
      {
        field: 'archived',
        operator: Operator.EQ,
        value: false,
      },
    ];

    return fetchData(orgCriteria);
  },
);

const fetchData = async (orgCriteria: Array<Query<Organization>>) => {
  const orgResponse = await OrganizationService.getOrganizations(...orgCriteria);
  return { organizations: orgResponse.data.map(org => toEntity(org)) };
};

export const doGetOrganizationsByUserId = createAsyncThunk(
  'organizations/getByUserId',
  async (input: Required<Pick<User, 'id'>>) => {
    const criteria: Array<Query<OrganizationUserView>> = [
      {
        field: 'userId',
        operator: Operator.EQ,
        value: input.id,
      },
      {
        field: 'organization.archived',
        operator: Operator.EQ,
        value: false,
      },
    ];
    const firstResponse = await OrganizationUserService.getOrganizationUsers(...criteria);
    const numberOfRequests = Math.ceil(firstResponse.total / MAX_NUMBER_OF_RECORDS);

    const remainingRequests = [];
    for (let i = 1; i < numberOfRequests; i += 1) {
      const request = OrganizationUserService.getOrganizationUsers(
        ...criteria,
        { offset: MAX_NUMBER_OF_RECORDS * i },
      );
      remainingRequests.push(request);
    }
    const remainingResponses = await Promise.all(remainingRequests);
    const organizationUsers = [...firstResponse.data];
    remainingResponses.forEach(response => organizationUsers.push(...response.data));

    const organizations = organizationUsers.map(orgUser => orgUser.organization);
    return { organizationUsers, organizations };
  },
);

export const doUpdateOrganization = createAsyncThunk(
  'organizations/updateOrganization',
  async (input: Parameters<typeof OrganizationService['updateOrganization']>[0]) => {
    const organization = await OrganizationService.updateOrganization(input);
    return { organization };
  },
);

export const doDeleteOrganization = createAsyncThunk(
  'organization/deleteOrganization',
  async (input: Parameters<typeof OrganizationService['deleteOrganization']>[0]) => {
    await OrganizationService.deleteOrganization(input);
    return { organization: input };
  },
);

export const doGetOrgMigrationStatus = createAsyncThunk(
  'organizations/getOrgMigrationStatus',
  async (input: Required<Pick<Organization, 'id'>>) => {
    const result = await OrganizationService.getOrgMigrationStatus(input);
    return { orgId: result.organizationId, migrated: result.migrated };
  },
);

const organizationsSlice = createSlice({
  name: ORGANIZATION_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {
    doChangeSelectedId(state, action: PayloadAction<OrganizationsState['selectedId']>) {
      state.selectedId = action.payload;
    },
    doChangeError(state, action: PayloadAction<OrganizationsState['error']>) {
      state.error = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(doHideMenuByNavigateToCreateOrg, state => {
      state.selectedId = null;
    });
    // Create Organization
    builder.addCase(doCreateOrganization.pending, state => {
      state.loading[doCreateOrganization.typePrefix] = true;
    });
    builder.addCase(doCreateOrganization.fulfilled, (state, action) => {
      organizationsAdapter.addOne(state, action.payload.organization);
      state.selectedId = action.payload.organization.id;
      state.loading[doCreateOrganization.typePrefix] = false;
    });
    builder.addCase(doCreateOrganization.rejected, (state, action) => {
      state.loading[doCreateOrganization.typePrefix] = false;
      state.error = action.error.message;
    });
    // Fetch Organizations
    builder.addCase(doFetchOrganizations.pending, state => {
      state.loading[doFetchOrganizations.typePrefix] = true;
    });
    builder.addCase(doFetchOrganizations.fulfilled, (state, action) => {
      state.loading[doFetchOrganizations.typePrefix] = false;
      organizationsAdapter.upsertMany(state, action.payload.organizations);
    });
    builder.addCase(doFetchOrganizations.rejected, (state, action) => {
      state.loading[doFetchOrganizations.typePrefix] = true;
      state.error = action.error.message;
    });
    // Update an Organization
    builder.addCase(doUpdateOrganization.pending, state => {
      state.loading[doUpdateOrganization.typePrefix] = true;
    });
    builder.addCase(doUpdateOrganization.fulfilled, (state, action) => {
      state.loading[doUpdateOrganization.typePrefix] = false;
      organizationsAdapter.updateOne(state, {
        id: action.payload.organization.id,
        changes: action.payload.organization,
      });
    });
    // Delete an Organization
    builder.addCase(doDeleteOrganization.pending, state => {
      state.loading[doDeleteOrganization.typePrefix] = true;
    });
    builder.addCase(doDeleteOrganization.fulfilled, (state, action) => {
      organizationsAdapter.removeOne(state, action.payload.organization.id);
      state.loading[doDeleteOrganization.typePrefix] = false;
    });
    builder.addCase(doDeleteOrganization.rejected, (state, action) => {
      state.loading[doDeleteOrganization.typePrefix] = true;
      state.error = action.error.message;
    });
    // Get Organizations of a User
    builder.addCase(doGetOrganizationsByUserId.pending, state => {
      state.loading[doGetOrganizationsByUserId.typePrefix] = true;
    });
    builder.addCase(doGetOrganizationsByUserId.fulfilled, (state, action) => {
      const organizations = action.payload.organizations.map(
        organization => toEntity(organization),
      );
      organizationsAdapter.upsertMany(state, organizations);
      state.loading[doGetOrganizationsByUserId.typePrefix] = false;
    });
    builder.addCase(doGetOrganizationsByUserId.rejected, (state, action) => {
      state.loading[doGetOrganizationsByUserId.typePrefix] = false;
      state.error = action.error.message;
    });
    builder.addCase(doFetchOrganizationById.pending, state => {
      state.loading[doFetchOrganizationById.typePrefix] = true;
    });
    builder.addCase(doFetchOrganizationById.fulfilled, (state, action) => {
      organizationsAdapter.upsertMany(state, action.payload.organizations);
      state.loading[doFetchOrganizationById.typePrefix] = false;
    });
    builder.addCase(doFetchOrganizationById.rejected, (state, action) => {
      state.loading[doFetchOrganizationById.typePrefix] = false;
      state.error = action.error.message;
    });
    builder.addCase(doFetchOrganizationByAccountId.pending, state => {
      state.loading[doFetchOrganizationByAccountId.typePrefix] = true;
    });
    builder.addCase(doFetchOrganizationByAccountId.fulfilled, (state, action) => {
      organizationsAdapter.upsertMany(state, action.payload.organizations);
      state.loading[doFetchOrganizationByAccountId.typePrefix] = false;
    });
    builder.addCase(doFetchOrganizationByAccountId.rejected, (state, action) => {
      state.loading[doFetchOrganizationByAccountId.typePrefix] = false;
      state.error = action.error.message;
    });
    builder.addCase(doGetOrgMigrationStatus.pending, state => {
      state.loading[doGetOrgMigrationStatus.typePrefix] = true;
    });
    builder.addCase(doGetOrgMigrationStatus.fulfilled, (state, action) => {
      state.migrationStatus[action.payload.orgId] = action.payload.migrated;
      state.loading[doGetOrgMigrationStatus.typePrefix] = false;
    });
    builder.addCase(doGetOrgMigrationStatus.rejected, (state, action) => {
      state.loading[doGetOrgMigrationStatus.typePrefix] = false;
      state.error = action.error.message;
    });
    builder.addCase(doGetOrgUserByUserIdAndAccountId.fulfilled, (state, action) => {
      organizationsAdapter.upsertMany(state, action.payload.map(
        organizationUser => toEntity(organizationUser.organization),
      ));
    });
  },
});

const selectOrganizationsFeature = (state: AppState) => state[ORGANIZATION_FEATURE_KEY];
const { selectAll, selectEntities } = organizationsAdapter.getSelectors(selectOrganizationsFeature);

export const selectLoadingGetOrganizationsByUserId = createSelector(
  selectOrganizationsFeature,
  organizationsState => organizationsState.loading[doGetOrganizationsByUserId.typePrefix],
);

export const selectLoadingGetOrganizationById = createSelector(
  selectOrganizationsFeature,
  organizationsState => organizationsState.loading[doFetchOrganizationById.typePrefix],
);
export const selectSelectedId = createSelector(
  selectOrganizationsFeature,
  organizationsState => organizationsState.selectedId,
);
export const selectAllByAccountId = (accountId: number) => createSelector(
  selectAll,
  all => all
    .filter(organization => organization.accountId === accountId)
    .sort((x, y) => new Date(y.createdAt).getTime() - new Date(x.createdAt).getTime()),
);
export const selectError = createSelector(
  selectOrganizationsFeature,
  organizationState => organizationState.error,
);
export const selectAllOrganizations = selectAll;
export const selectSelectedOrganization = createSelector(
  selectEntities,
  selectSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : null),
);
export const selectOrganizationById = (id: Organization['id']) => createSelector(
  selectEntities,
  entities => entities[id],
);
export const selectCount = createSelector(
  selectOrganizationsFeature,
  organizationsState => organizationsState.count,
);
export const selectOrgMigrationStatusById = (id: Organization['id']) => createSelector(
  selectOrganizationsFeature,
  organizationsState => organizationsState.migrationStatus[id],
);

export const selectLoading = (prefix: string) => createSelector(
  selectOrganizationsFeature,
  state => state.loading[prefix],
);

export const { doChangeSelectedId, doChangeError } = organizationsSlice.actions;
export default organizationsSlice.reducer;
