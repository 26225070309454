import Axios from 'axios';
import { Query, composeQuery } from '../models/query';
import { SecuritySettings, UpdateSecuritySettings } from '../models/securitySetting';
import { DataResponse, PageableResponse } from '../models';

export const getAllSecuritySettings = async (...criteria: Query<SecuritySettings>[]) => (await Axios.get<PageableResponse<SecuritySettings>>('/v1/account-settings', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: composeQuery<SecuritySettings>(...criteria),
  data: {},
})).data;

export const upsertSecuritySettings = async (
  input: Partial<UpdateSecuritySettings>,
) => (await Axios.post<DataResponse<SecuritySettings>>('/v1/account-settings', input)).data.data;
