// Temporarily use this until we have time to fix the theme
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

type K1TypographyVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

const k1TypographyVariants: Record<K1TypographyVariant, SxProps<Theme>> = {
  h1: {
    fontSize: '28px',
    lineHeight: '28px',
  },
  h2: {
    fontSize: '20px',
    lineHeight: '30px',
  },
  h3: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  h4: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  h5: {
    fontSize: '12px',
    lineHeight: '16px',
  },
  h6: {
    fontSize: '10px',
    lineHeight: '16px',
  },
};

interface K1TypographyProps extends React.ComponentProps<typeof Typography> {
  variant: K1TypographyVariant;
}

const K1Typography = (props: K1TypographyProps) => {
  const { variant, children, ...others } = props;
  return (
    <Typography sx={k1TypographyVariants[variant]} {...others}>
      {children}
    </Typography>
  );
};

export default K1Typography;
