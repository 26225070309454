import Axios from 'axios';
import { DataResponse, GeneratedFields, PageableResponse, User } from '../models';
import { composeQuery, Query } from '../models/query';

export const createUser = async (
  input: Required<Pick<User, 'email' | 'password'>> &
  Omit<User, GeneratedFields | 'status' | 'newPassword'>,
) => (await Axios.post<DataResponse<User>>('/v1/users', input)).data.data;

export const updateUser = async (
  input: Required<Pick<User, 'id'>> &
  Partial<
  Pick<
  User,
  | 'testingSolutions'
  | 'email'
  | 'avatar'
  | 'firstName'
  | 'lastName'
  | 'password'
  | 'newPassword'
  | 'jobTitle'
  >
  >,
) => (await Axios.put<DataResponse<User>>(`/v1/users/${input.id}`, input)).data.data;

export const deleteUser = async (
  input: Required<Pick<User, 'id' | 'deleteReason'>> & Pick<User, 'password'>,
) => (
  await Axios.request<DataResponse<undefined>>({
    method: 'delete',
    url: `/v1/users/${input.id}`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    data: input,
  })
).data.data;

export const getUsers = async (...criteria: Query<User>[]) => (
  await Axios.get<PageableResponse<User>>('/v1/users', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    params: composeQuery<User>(...criteria),
    data: {},
  })
).data;

export const queryUsers = async (...criteria: Query<User>[]) => (
  await Axios.get<PageableResponse<User>>('/v1/users/query', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    params: composeQuery<User>(...criteria),
    data: {},
  })
).data;

export const checkAdminCreated = async () => (
  await Axios.get<DataResponse<User>>('/v1/users/user-created', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
  })
).data;

export const forgotPassword = async (input: Required<Pick<User, 'email'>>) => (await Axios.post('/v1/users/forgot-password', input)).data;

export const resetPassword = async (
  input: Required<Pick<User, 'password' | 'resetPasswordCode'>>,
) => (await Axios.post('/v1/users/reset-password', input)).data;
