import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FieldErrors, ControllerRenderProps } from 'react-hook-form/dist/types';
import MenuItem from '@mui/material/MenuItem';
import Popper, { PopperProps } from '@mui/material/Popper';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import { getData } from 'country-list';
import { BillingInformationInputs } from '../utils';

const useStyles = makeStyles(theme => ({
  root: {
    '& > .MuiOutlinedInput-root': {
      height: theme.spacing(5),
      padding: theme.spacing(0, 1),
    },
  },
  container: {
    border: '1px solid #D5D8DD',
    borderRadius: theme.spacing(0.5),
  },
}));

interface CountriesSelectionProps {
  inputKey: string;
  errors: FieldErrors;
  field: ControllerRenderProps<BillingInformationInputs>;
}

const PopperComponent = (props: PopperProps) => (
  <Popper
    {...props}
    placement="bottom-start"
    modifiers={[
      {
        name: 'preventOverflow',
        enabled: false,
        options: {
          altBoundary: true,
        },
      },
    ]}
  />
);

const CountriesSelection = (props: CountriesSelectionProps) => {
  const { field, errors, inputKey } = props;
  const countryOptions = getData();
  const classes = useStyles();
  return (
    <Autocomplete
      key={inputKey}
      id="select"
      aria-autocomplete="none"
      disableClearable
      onChange={(_e, item) => {
        field.onChange(item.code);
      }}
      value={countryOptions.find(it => it.code === field.value)}
      options={countryOptions}
      getOptionLabel={option => option.name}
      renderInput={params => (
        <TextField
          {...params}
          classes={{
            root: classes.root,
          }}
          variant="outlined"
          error={!!errors[inputKey]}
          helperText={errors[inputKey]?.message}
          required
          aria-autocomplete="none"
        />
      )}
      renderOption={(props, option) => (
        <MenuItem {...props} key={option.code} value={option.code}>{option.name}</MenuItem>
      )}
      PopperComponent={PopperComponent}
      PaperComponent={({ children }) => (
        <Paper className={classes.container} elevation={0}>
          { children }
        </Paper>
      )}
      autoComplete={false}
    />
  );
};

export default CountriesSelection;
