import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { useIntl } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import { Order } from './RemovedUsersTable';

const useStyles = makeStyles(theme => ({
  cellContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > * > .MuiSvgIcon-root': {
      margin: 0,
      marginLeft: theme.spacing(-0.1),
      width: theme.spacing(2.5),
    },
  },
  infoIcon: {
    fontSize: theme.spacing(2.2),
    marginLeft: theme.spacing(-0.4),
    cursor: 'pointer',
  },
  licenseTooltip: {
    textAlign: 'center',
    maxWidth: theme.spacing(28),
  },
  cell: {
    padding: theme.spacing(1, 0, 1, 2),
  },
}));

interface TableItemFields {
  id: number;
  fullName: string;
  email: string;
  joinDate: string;
  orgRole: string;
  lastAccess: string;
  removalDate: string;
  removedByEmail: string;
}

interface HeadCell {
  id: keyof TableItemFields;
  label: string;
  enableSort: boolean;
}

interface HeaderTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof TableItemFields) => void;
  order: Order;
  orderBy: string;
}

interface HeaderFields {
  id: number;
  fullName: string;
  email: string;
  joinDate: string;
  orgRole: string;
  lastAccess: string;
  removalDate: string;
  removedByEmail: string;
}

const RemovedUsersHeaderTable = (props: HeaderTableProps) => {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;
  const intl = useIntl();
  const headCells: HeadCell[] = [
    { id: 'fullName', label: intl.formatMessage({ id: 'user_management.cell.fullname' }), enableSort: true },
    { id: 'email', label: intl.formatMessage({ id: 'user_management.cell.email' }), enableSort: false },
    { id: 'orgRole', label: intl.formatMessage({ id: 'user_management.cell.role' }), enableSort: true },
    { id: 'joinDate', label: intl.formatMessage({ id: 'user_management.cell.join_date' }), enableSort: true },
    { id: 'removalDate', label: intl.formatMessage({ id: 'user_management.cell.removal_date' }), enableSort: true },
    {
      id: 'removedByEmail',
      label: intl.formatMessage({ id: 'user_management.cell.removed_by_email' }),
      enableSort: false,
    },
    { id: 'lastAccess', label: intl.formatMessage({ id: 'user_management.cell.last_login' }), enableSort: false },

  ];
  const createSortHandler = (property: keyof HeaderFields) => (
    (event: React.MouseEvent<unknown>) => { onRequestSort(event, property); }
  );
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            className={classes.cell}
            padding="none"
            key={headCell.id.toString()}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <div className={classes.cellContainer}>
              {headCell.enableSort ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                  IconComponent={ArrowDropDownIcon}
                >
                  {headCell.label}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
export default RemovedUsersHeaderTable;
