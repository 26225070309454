import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';

import { AppState } from '.';
import { Account, BillingInformation, exceptionOf } from '../models';
import { Operator } from '../models/query';
import { BillingInformationService } from '../services';

export const BILLING_INFORMATION_FEATURE_KEY = 'billingInformation';

interface BillingInformationState extends EntityState<BillingInformation> {
  loading: boolean;
  error?: string;
}

const billingInformationAdapter = createEntityAdapter<BillingInformation>();

export const createInitialState = (partialState: Partial<BillingInformationState> = {}) => (
  billingInformationAdapter.getInitialState({
    loading: false,
    error: undefined,
    ...partialState,
  })
);

export const doCreateBillingInformation = createAsyncThunk(
  'billingInformation/createBillingInformation',
  async (input: Parameters<typeof BillingInformationService['upsertBillingInformation']>[0], { rejectWithValue }) => {
    try {
      return await BillingInformationService
        .upsertBillingInformation(input);
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doGetBillingInformation = createAsyncThunk(
  'billingInformation/getBillingInformation',
  async (accountId: BillingInformation['accountId']) => {
    const billingContact = await BillingInformationService.getBillingInformation({ field: 'accountId', operator: Operator.EQ, value: accountId.toString() });

    if (billingContact.length === 0) {
      throw new Error();
    }

    return billingContact;
  },
);

export const doUpdateBillingInformation = createAsyncThunk(
  'billingInformation/updateBillingInformation',
  async (input: Parameters<typeof BillingInformationService['upsertBillingInformation']>[0]) => {
    const billingInformation = await BillingInformationService
      .upsertBillingInformation(input);
    return billingInformation;
  },
);

const billingInformationSlice = createSlice({
  name: BILLING_INFORMATION_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doCreateBillingInformation.pending, state => {
      state.loading = true;
    });
    builder.addCase(doCreateBillingInformation.fulfilled, (state, action) => {
      state.loading = false;
      // use upsert here because create function do create or update the billing information
      billingInformationAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(doCreateBillingInformation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(doGetBillingInformation.pending, state => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(doGetBillingInformation.fulfilled, (state, action) => {
      state.loading = false;
      billingInformationAdapter.upsertMany(state, action.payload);
    });
    builder.addCase(doGetBillingInformation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(doUpdateBillingInformation.pending, state => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(doUpdateBillingInformation.fulfilled, (state, action) => {
      state.loading = false;
      billingInformationAdapter.updateOne(
        state,
        {
          id: action.payload.id,
          changes: action.payload,
        },
      );
    });
    builder.addCase(doUpdateBillingInformation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

const selectBillingInformationFeature = (state: AppState) => state[
  BILLING_INFORMATION_FEATURE_KEY
];
const { selectAll } = billingInformationAdapter.getSelectors(selectBillingInformationFeature);
export const selectOneByAccountId = (accountId: Account['id']) => createSelector(
  selectAll,
  billingInformations => billingInformations.find(it => it.accountId === accountId),
);
export const selectLoading = createSelector(
  selectBillingInformationFeature,
  state => state.loading,
);

export default billingInformationSlice.reducer;
