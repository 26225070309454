import Typography from '@mui/material/Typography';
import React from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';
import Icon from '@katalon-studio/katalon-ui/Icon';
import clsx from 'clsx';
import Button from '@katalon-studio/katalon-ui/Button';
import IconButton from '@mui/material/IconButton';
import { FormattedMessage } from 'react-intl';

export type CustomAlertProps = {
  title?: string;
  message?: string;
  severity?: AlertProps['severity'];
  hasTryAgain?: boolean;
  handleTryAgain?: () => void;
  hasDismissIcon?: boolean;
  handleDismissNotify?: () => void;
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />
));

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.spacing(40),
  },
  title: {
    fontSize: theme.spacing(2),
    fontWeight: 500,
  },
  message: {
    fontSize: theme.spacing(1.75),
    fontWeight: 'normal',
  },
  iconContainer: {
    width: theme.spacing(2),
    height: theme.spacing(2.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    fontSize: theme.spacing(2),
    color: '#0f8461',
  },
  iconError: {
    color: '#d24720',
  },
  iconClose: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    fontSize: theme.spacing(1.5),
    color: 'rgba(104, 109, 128, 0.54)',
  },
  tryAgainButton: {
    padding: theme.spacing(0.5),
    height: theme.spacing(3),
    fontWeight: 500,
    fontSize: theme.spacing(1.5),
  },
}));

const CustomAlert = (props: CustomAlertProps) => {
  const classes = useStyles();
  const {
    title,
    message,
    severity = 'success',
    hasTryAgain = false,
    handleTryAgain,
    hasDismissIcon = false,
    handleDismissNotify,
  } = props;

  const iconMappings = {
    success: (
      <div className={classes.iconContainer}>
        <Icon
          name="fa-circle-check"
          type="fa-regular"
          className={classes.icon}
        />
      </div>
    ),
    error: (
      <div className={classes.iconContainer}>
        <Icon
          name="fa-circle-exclamation"
          type="fa-regular"
          className={clsx(classes.icon, classes.iconError)}
        />
      </div>
    ),
  };

  const renderActions = () => {
    if (!hasTryAgain && (!hasDismissIcon && severity !== 'error')) {
      return undefined;
    }
    return (
      <>
        {
          hasTryAgain
          && handleTryAgain
          && (
            <Button
              id="project_management.common.try_again_button"
              className={classes.tryAgainButton}
              type="button"
              variant="text"
              size="small"
              color="primary"
              onClick={handleTryAgain}
            >
              <FormattedMessage id="common.try_again" />
            </Button>
          )
        }
        {
          (hasDismissIcon || severity === 'error') && (
            <IconButton
              id="project_management.common.close_button"
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleDismissNotify}
            >
              <Icon name="fa-xmark" type="fa-solid" className={classes.iconClose} />
            </IconButton>
          )
        }
      </>
    );
  };

  return (
    <Alert
      iconMapping={iconMappings}
      severity={severity}
      className={classes.root}
      action={renderActions()}
    >
      { title
        && <Typography className={classes.title}>{title}</Typography>}
      { message
        && <Typography className={classes.message}>{message}</Typography>}
    </Alert>
  );
};

export default CustomAlert;
