import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Popover from '@mui/material/Popover';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { AuthService } from '../../../services';
import { fromAuth } from '../../../store';
import { useSwitchTheme } from '../../../theme';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    alignItems: 'center',
  },
  dialog: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
  size: {
    width: '15%',
    height: 'auto',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 1,
    paddingBottom: theme.spacing(2),
  },
  email: {
    color: theme.palette.primary.main,
  },
  title: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  button: {
    margin: 0,
  },
  popover: {
    overflowX: 'unset',
    overflowY: 'unset',
    boxShadow: 'unset',
  },
  verifyButton: {
    fontSize: theme.spacing(1.75),
    fontWeight: theme.typography.fontWeightMedium,
    color: '#5294FF',
    textTransform: 'capitalize',
  },
}));

const Verify = () => {
  const [open, setOpen] = useState(false);
  const user = useSelector(fromAuth.selectUser);
  const classes = useStyles();
  const [, setError] = useState<Error>();
  const fullScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [captcha, setCaptcha] = useState(false);

  const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCaptcha(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
    setCaptcha(false);
  };

  const handleVerify = async () => {
    try {
      await AuthService.sendActivationEmail();
      setOpen(true);
    } catch (e: any) {
      setError(e);
    }
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const captchaKey = process.env.REACT_APP_CAPTCHA_KEY || '';
  const { currentTheme } = useSwitchTheme();

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>
        <Typography className={classes.verifyButton}>
          <FormattedMessage id="profile.card.verify" />
        </Typography>
      </Button>
      <Popover
        classes={{ paper: classes.popover }}
        open={captcha}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ReCAPTCHA theme={currentTheme} sitekey={captchaKey} onChange={handleVerify} />
      </Popover>
      <Dialog
        classes={{ paper: clsx({ [classes.root]: fullScreen }) }}
        fullScreen={fullScreen}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogContent>
          <Box className={classes.dialog}>
            <img
              className={classes.size}
              alt="logo"
              src="https://d1h3p5fzmizjvp.cloudfront.net/themes/katalon_4/images/popup/email_box.png"
            />
            <Box className={classes.content}>
              <Typography className={classes.title} variant="h6">
                <FormattedMessage id="profile.verify.sent" />
              </Typography>
              <Typography gutterBottom>
                <FormattedMessage
                  id="profile.verify.instruction"
                  values={{
                    email: <span className={classes.email}>{user?.email ?? ''}</span>,
                  }}
                />
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button type="button" size="large" onClick={handleClose}>
            <FormattedMessage id="profile.verify.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Verify;
