import Axios from 'axios';
import { DataResponse, PageableResponse, ProductInterest } from '../models';
import { composeQuery, Query } from '../models/query';

export const getByCurrentUserId = async (...criteria: Query<ProductInterest>[]) => (await Axios.get<PageableResponse<ProductInterest>>('/v1/product-interests', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: composeQuery<ProductInterest>(...criteria),
  data: {},
})).data;

export const addProductInterest = async (
  input: Required<Pick<ProductInterest, 'productName'>>,
) => (await Axios.post<DataResponse<ProductInterest>>('/v1/product-interests', input)).data.data;

export const removeProductInterest = async (
  input: Required<Pick<ProductInterest, 'id' | 'productName'>>,
) => (await Axios.delete<DataResponse<ProductInterest>>(`/v1/product-interests/${input.id}`)).data.data;
