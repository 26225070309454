import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import Avatar from '@mui/material/Avatar';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import DurationUnitFormat from 'intl-unofficial-duration-unit-format';
import { useEffect, useState } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { useSelector } from 'react-redux';
import { KsSessionView, getFullNameUser, getAvatarSource } from '../../../../models';
import { OrganizationFeature } from '../../../../models/organizationFeature';
import { fromLicenseUtilization, fromOrganizations, useAppDispatch } from '../../../../store';
import { Filter } from '../../../../models/query';
import TableBodySkeleton from '../../../../components/table-body-skeleton';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2.5, 0),
    margin: theme.spacing(4, 2, 2, 2),
  },
  paginationBox: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    // there is no font match with design, use custom color here
    color: '#1D3066',
  },
  title: {
    paddingLeft: theme.spacing(3),
  },
  emptyData: {
    padding: theme.spacing(4, 0, 4, 0),
  },
  fullnameContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  fullnameText: {
    margin: 0,
    marginBottom: theme.spacing(0.25),
  },
  avatar: {
    marginRight: theme.spacing(1),
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  firstCell: {
    paddingLeft: theme.spacing(3),
  },
}));

const options = {
  style: DurationUnitFormat.styles.NARROW,
  format: '{hours} {minutes} {seconds}',
};
const intlDuration = new DurationUnitFormat('en', options);

const ROWS_PER_PAGE = 30;

interface SessionDetailProps {
  filters: Filter<KsSessionView>[];
  startDate: Date;
  endDate: Date;
}

const SessionDetail = (props: SessionDetailProps) => {
  const { filters, startDate, endDate } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const organizationId = useSelector(fromOrganizations.selectSelectedId);
  const ksSessions = useSelector(fromLicenseUtilization.selectCurrentPageKsSessions);
  const ksSessionCount = useSelector(fromLicenseUtilization.selectCount);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchKsSessions = async (orgId: number) => {
      const newFilter: Filter<KsSessionView>[] = filters.map(it => (it.field === 'email' ? ({
        field: 'user.email',
        operator: it.operator,
        value: it.value,
      }) : it));
      try {
        setLoading(true);
        await dispatch(fromLicenseUtilization.doGetKsSessions({
          limit: ROWS_PER_PAGE,
          offset: (page - 1) * ROWS_PER_PAGE,
          startDate,
          endDate,
          organizationId: orgId,
          filters: newFilter,
        }));
      } finally {
        setLoading(false);
      }
    };

    if (organizationId) {
      fetchKsSessions(organizationId);
    }
  }, [dispatch, filters, startDate, endDate, organizationId, page]);

  const handleChangePage = (_: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Paper className={classes.root} elevation={0}>
      <Typography className={classes.title} variant="h3"><FormattedMessage id="license_utilization.session_detail.session" /></Typography>
      <TableContainer>
        <Table>
          <TableHead>
            {/* TODO: add up/down button & handle sorting */}
            <TableRow>
              <TableCell className={classes.firstCell}><FormattedMessage id="license_utilization.session_detail.user" /></TableCell>
              <TableCell><FormattedMessage id="license_utilization.session_detail.session_id" /></TableCell>
              <TableCell><FormattedMessage id="license_utilization.session_detail.machine_id" /></TableCell>
              <TableCell><FormattedMessage id="license_utilization.session_detail.license" /></TableCell>
              <TableCell><FormattedMessage id="license_utilization.session_detail.started" /></TableCell>
              <TableCell><FormattedMessage id="license_utilization.session_detail.duration" /></TableCell>
            </TableRow>
          </TableHead>
          {loading && <TableBodySkeleton rows={3} cols={6} />}
          {!loading && ksSessions.length > 0 && (
            <TableBody>
              {ksSessions.map(ksSession => (
                <TableRow key={ksSession.id}>
                  <TableCell className={classes.firstCell}>
                    <div className={classes.fullnameContainer}>
                      <Avatar
                        className={classes.avatar}
                        src={getAvatarSource(ksSession.user.avatar)}
                      />
                      <div>
                        <p className={classes.fullnameText}>
                          {getFullNameUser(
                            ksSession.user.firstName,
                            ksSession.user.lastName,
                            ksSession.user.email,
                          )}
                        </p>
                        <p className={classes.fullnameText}>
                          {ksSession.user.email}
                        </p>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>{ksSession.sessionId}</TableCell>
                  <TableCell>{ksSession.machineId}</TableCell>
                  <TableCell>
                    {OrganizationFeature.getFeatureHalfFullName(ksSession.feature)}
                  </TableCell>
                  <TableCell>
                    <FormattedDate
                      value={ksSession.startTime ? new Date(ksSession.startTime) : ''}
                      month="short"
                      day="numeric"
                      hour="numeric"
                      minute="numeric"
                      hourCycle="h23"
                    />
                  </TableCell>
                  <TableCell>{ intlDuration.format(ksSession.duration / 1000) }</TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
          {!loading && ksSessions.length <= 0 && (
            <TableBody>
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={6}
                  classes={{ root: classes.emptyData }}
                >
                  <FormattedMessage id="license_utilization.empty_data" />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {ksSessionCount > 0 && !loading && (
        <Box className={classes.paginationBox}>
          <Pagination
            shape="rounded"
            count={Math.ceil(ksSessionCount / ROWS_PER_PAGE)}
            page={page}
            onChange={handleChangePage}
          />
          <Typography variant="body2">
            <FormattedMessage
              id={ksSessionCount > 1 ? 'license_utilization.session_detail.total_records'
                : 'license_utilization.session_detail.total_record'}
              values={{ count: ksSessionCount }}
            />
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

export default SessionDetail;
