import Button from '@mui/material/Button';
import { useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { resolvePath, sso as ssoPath } from '../../../routes';

interface ButtonToLicenseManagementProps {
  orgId: number;
}

const ButtonToLicenseManagement = (props: ButtonToLicenseManagementProps) => {
  const { orgId } = props;
  const intl = useIntl();

  return (
    <Button
      id="goto-licence-management-page"
      variant="contained"
      size="large"
      color="primary"
      sx={{
        width: 322,
        color: '#233145',
        ml: 1,
      }}
      component={RouterLink}
      to={resolvePath(ssoPath, undefined, {
        redirect_uri: `${process.env.REACT_APP_ADMIN_URL}/organization/${orgId}/admin/license_keys/kse`,
      }, false)}
    >
      {intl.formatMessage({ id: 'payment_status.button.back_license_management' })}
    </Button>
  );
};

export default ButtonToLicenseManagement;
