import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import LoadingProgress from '../../layout/LoadingProgress';
import { orgCardView } from '../../layout/routes';
import { isManager } from '../../models';
import { forbidden, notFound, resolvePath, useQuery, welcome } from '../../routes';
import {
  fromAccounts,
  fromAccountUsers,
  fromAuth,
  fromStudioSubscriptions,
  fromSubscriptions,
  fromTestCloudSubscriptions,
  fromTestOpsProject,
  fromTestOpsSubscriptions,
  fromTestResultCount,
  useAppDispatch,
} from '../../store';
import { fromTestOpsPlatformSubscriptions } from '../../store/rootReducer';

const AccountHome = () => {
  const { get } = useQuery();
  const [loading, setLoading] = useState(true);
  const orgId = Number(get('orgId'));
  const accountId = Number(get('accountId'));
  const user = useSelector(fromAuth.selectUser);
  const accountUser = useSelector(fromAccountUsers.findAccountUserByAccountId(accountId));
  const testResultCount = useSelector(fromTestResultCount.selectLatestTestResultCount);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!accountId) return;
    const fetchInformation = async () => {
      setLoading(true);
      await Promise.all([
        dispatch(fromAccountUsers.doGetAccountUsersByUserId({ userId: user!!.id })),
        dispatch(fromTestOpsSubscriptions.doGetActiveByAccountId({ accountId })),
        dispatch(fromTestCloudSubscriptions.doGetByAccountId({ accountId })),
        dispatch(fromStudioSubscriptions.doGetActiveByAccountId({ accountId })),
        dispatch(fromSubscriptions
          .doGetActiveSubscriptionsByOrgId({ organizationId: Number(orgId) })),
        dispatch(fromTestOpsPlatformSubscriptions
          .doGetAllTestOpsPlatformSubscriptionsByAccountId({ accountId })),
        dispatch(fromTestResultCount.doGetLatestTestResultCount(orgId)).then(data => {
          let remainUsage = 0;
          if (data.payload) {
            // @ts-ignore
            remainUsage = (data.payload.quota - data.payload.count) / data.payload.quota;
          }
          if (remainUsage > 0.75) {
            localStorage.setItem(`not-show-reach-quota-accId-${accountId}`, 'false');
          }
        }),
      ]).then(() => {
        dispatch(fromTestOpsProject.doCountProjectsByAccountId({ accountId }));
        dispatch(fromAccounts.doCountMembersOfAccount({ id: accountId }));
        dispatch(fromAccounts.doCountOrganizationsOfAccount({ id: accountId }));
      });
      setLoading(false);
    };
    fetchInformation().catch(() => { });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, orgId]);

  if (!orgId && !accountId) {
    return <Navigate to={notFound.path} replace />;
  }

  if (!loading) {
    if (!accountUser) {
      return <Navigate to={welcome.path} replace />;
    }
    if (!isManager(accountUser.role)) {
      return <Navigate to={forbidden.path} replace />;
    }

    let remainUsage = 0;
    if (testResultCount) {
      remainUsage = (testResultCount.quota - testResultCount.count)
        / testResultCount.quota;
    }

    if (remainUsage < 0) {
      remainUsage = 0;
    }

    let skipCheckQuotaAccountAt = JSON.parse(localStorage.getItem(`skip-reach-quota-accId-${accountId}`)!!);
    const userLoginTimeStamp = new Date(user?.lastLogin!).valueOf();
    if (skipCheckQuotaAccountAt !== userLoginTimeStamp) {
      localStorage.setItem(`skip-reach-quota-accId-${accountId}`, '0');
      skipCheckQuotaAccountAt = 0;
    }

    return (
      <Navigate
        to={
          resolvePath(orgCardView, undefined, { accountId })
        }
        replace
      />
    );
  }
  return (
    <LoadingProgress />
  );
};

export default AccountHome;
