import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { login as loginPath, resolvePath, useNavigate, useQuery } from '../../../routes';
import { UserService } from '../../../services';
import { useSwitchTheme } from '../../../theme';
import LoadingProgress from '../../../layout/LoadingProgress';
import { useConfig } from '../../../config';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.main,
    margin: 0,
  },
  form: {
    margin: theme.spacing(2),
  },
  title: {
    textAlign: 'center',
  },
}));

interface ForgotPasswordInput {
  email: string;
}

const ForgotPassword = () => {
  const captchaKey = process.env.REACT_APP_CAPTCHA_KEY;
  const { config } = useConfig();
  const { search } = useLocation();
  const { replace, mergeQuery, replaceQuery } = useNavigate();
  const { queryDictionary, clear } = useQuery();
  const [open, setOpen] = useState(false);
  const [resendOpen, setResendOpen] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors: inputErrors },
  } = useForm<ForgotPasswordInput>({
    defaultValues: { email: '' },
  });
  const [email, setEmail] = useState(''); // store the email for resending
  const [, setError] = useState<Error>();
  const [notCompleteCaptcha, setNotCompleteCaptcha] = useState(false);
  const [captcha, setCaptcha] = useState<string | null>(captchaKey ? null : 'verified');
  const [resendCaptcha, setResendCaptcha] = useState<ReCAPTCHA | null>();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { currentTheme } = useSwitchTheme();
  const { enqueueSnackbar } = useSnackbar();

  const fullScreen = useMediaQuery(useTheme().breakpoints.down('sm'));

  const view = new URLSearchParams(search).get('view');
  useEffect(() => {
    setOpen(view === 'forgot_password');
  }, [view]);

  const handleResendOpen = () => {
    setOpen(false);
    setResendOpen(true);
  };

  const handleResendClose = () => {
    unsetViewParam();
    setResendOpen(false);
  };

  const unsetViewParam = () => {
    clear('view');
    replace(undefined, replaceQuery(queryDictionary()));
  };

  const setViewParam = () => {
    replace(undefined, mergeQuery({ view: 'forgot_password' }));
  };

  const onSubmitResend = (e: React.FormEvent<HTMLFormElement>) => {
    e.stopPropagation();
    e.preventDefault();

    doForgotPassword({ email });
  };

  const doForgotPassword = async (input: ForgotPasswordInput) => {
    setEmail(input.email);
    setLoading(true);
    if (config?.onpremise) {
      try {
        await UserService.forgotPassword({ email: input.email.toLowerCase() });
        enqueueSnackbar(
          <FormattedMessage id="forgot.form.success" />,
          { variant: 'success' },
        );
        setOpen(false);
        unsetViewParam();
      } catch (e: any) {
        setError(e);
        enqueueSnackbar(
          <FormattedMessage id="forgot.form.failed" />,
          { variant: 'error' },
        );
      } finally {
        setLoading(false);
      }
    } else if (captcha){
      try {
        await UserService.forgotPassword({ email: input.email.toLowerCase() });
        analytics.track(
          'user_reset_password',
          {
            event_text: 'User Reset Password',
            user_id: input.email,
          },
        );
        if (open) {
          handleResendOpen();
          setNotCompleteCaptcha(false);
          // If captchaKey is not defined, keep captcha verified else reset the captcha
          if (captchaKey) {
            setCaptcha('');
          }
        } else if (resendOpen) {
          setNotCompleteCaptcha(false);
          resendCaptcha!.reset();
          // If captchaKey is not defined, keep captcha verified else reset the captcha
          if (captchaKey) {
            setCaptcha('');
          }
        }
      } catch (e: any) {
        setError(e);
      } finally {
        setLoading(false);
      }
    } else {
      setNotCompleteCaptcha(true);
    }
  };

  const doVerify = (value: string | null) => {
    setCaptcha(value);
    setNotCompleteCaptcha(!value);
  };

  useEffect(() => {
    setNotCompleteCaptcha(false);
    setCaptcha('');
  }, [open]);

  return (
    <>
      { config?.onpremise && (
        <Link
          sx={{ color: '#7b8197', fontWeight: '500', fontSize: '14px', fontFamily: 'Inter' }}
          component={RouterLink}
          to={resolvePath(loginPath, undefined, { ...queryDictionary(), view: 'forgot_password' }, false)}
          onClick={setViewParam}
        >
          <FormattedMessage id="login.form.forgot" />
        </Link>
      )}
      { !config?.onpremise && (
        <Link
          sx={{ color: '#7b8197', fontWeight: '500', fontSize: '14px', fontFamily: 'Inter' }}
          href={`${config?.keyCloakUrl}/realms/${config?.keyCloakRealmId}/login-actions/reset-credentials?client_id=${config?.keyCloakClientId}`}
        >
          <FormattedMessage id="login.form.forgot" />
        </Link>
      )}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={unsetViewParam}
        aria-labelledby="forgot-form-dialog-title"
      >
        <DialogTitle className={classes.title}>
          <FormattedMessage id="forgot.form.title" />
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(doForgotPassword)} id="forgot-password-form">
            <DialogContentText>
              <FormattedMessage id="forgot.form.instruction" />
            </DialogContentText>
            <TextField
              className={classes.root}
              variant="outlined"
              margin="normal"
              fullWidth
              required
              type="email"
              name="email"
              autoComplete="email"
              autoFocus
              inputProps={{ ...register('email', { required: 'error.required' }) }}
              helperText={inputErrors.email && <FormattedMessage id={inputErrors.email?.message} />}
              error={!!inputErrors.email}
              label={<FormattedMessage id="login.form.email" />}
            />
            {captchaKey && (
              <>
                <ReCAPTCHA
                  size="normal"
                  theme={currentTheme}
                  sitekey={captchaKey}
                  onChange={doVerify}
                />
                {notCompleteCaptcha && (
                  <Typography className={classes.error} variant="caption">
                    <FormattedMessage id="error.incomplete.recaptcha" />
                  </Typography>
                )}
              </>
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            size="large"
            onClick={unsetViewParam}
          >
            <FormattedMessage id="forgot.form.cancel" />
          </Button>
          <Button
            type="submit"
            form="forgot-password-form"
            color="primary"
            size="large"
          >
            <FormattedMessage id="forgot.form.title" />
          </Button>
        </DialogActions>
        {loading && <LoadingProgress />}
      </Dialog>

      <Dialog fullScreen={fullScreen} open={resendOpen} onClose={handleResendClose} aria-labelledby="resendforgot-form-dialog-title">
        <form onSubmit={onSubmitResend}>
          <DialogContent>
            <DialogContentText>
              <Typography variant="subtitle2" align="center">
                <Box fontStyle="italic">
                  <FormattedMessage id="resendforgot.form.confirmation" />
                </Box>
              </Typography>
              <Typography color="textPrimary" variant="h6" align="center">
                <FormattedMessage id="resendforgot.form.not_received" />
              </Typography>
            </DialogContentText>
            {captchaKey && (
              <>
                <ReCAPTCHA
                  size="normal"
                  theme={currentTheme}
                  sitekey={captchaKey}
                  onChange={doVerify}
                  ref={el => { setResendCaptcha(el); }}
                />
                {notCompleteCaptcha
                  && (
                  <Typography className={classes.error} variant="caption">
                    <FormattedMessage id="error.incomplete.recaptcha" />
                  </Typography>
                  )}
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleResendClose}>
              <FormattedMessage id="resendforgot.form.cancel" />
            </Button>
            <Button color="primary" type="submit">
              <FormattedMessage id="resendforgot.form.title" />
            </Button>
          </DialogActions>
        </form>
        {loading && <LoadingProgress />}
      </Dialog>
    </>
  );
};

export default ForgotPassword;
