import { BaseModel } from './base';
import {
  Organization,
  OrganizationFeature,
  TestOpsSubscriptionStatus,
  TestOpsPlanInterval,
  RecurlySubscription,
} from './index';
import { RecurlySubscriptionPayloadStatus } from './recurlySubscription';

export interface TestCloudSubscription extends BaseModel {
  expiredAt: Date;
  recurlySubscriptionId?: RecurlySubscription['id'];
  recurlySubscription?: RecurlySubscription;
  organizationId: Organization['id'];
  status: TestOpsSubscriptionStatus;
  feature: OrganizationFeature;
  tier?: number;
  hasPremierSuccessAddOn?: Boolean;
  credits?: number;
  concurrentSessions: number;
  billingInterval: TestOpsPlanInterval;
  accountId: number;
  subscriptionType: SubscriptionType;
}

/**
 * Check if user can subscribe other testcloud plan from current plan
 * @param currentSubscription
 * @return boolean
 */
export const canSubscribeOtherPlan = (
  currentSubscription?: TestCloudSubscription,
) => !currentSubscription || currentSubscription
  .recurlySubscription?.status === RecurlySubscriptionPayloadStatus.EXPIRED
  || currentSubscription.status !== TestOpsSubscriptionStatus.ACTIVE;

export enum SubscriptionType {
  TRIAL_INITIAL = 'TRIAL_INITIAL',
  TRIAL_GRANTED = 'TRIAL_GRANTED',
  PAID = 'PAID',
}
