import { createAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import { AppState } from '.';
import {
  exceptionOf,
  isK1Invitation,
  K1UserInvitation,
  KitUserInvitation,
  Organization,
  OrganizationUser,
  SerializedException,
  UserInvitation,
  User,
  Account,
} from '../models';
import { Operator, Query } from '../models/query';
import { UserInvitationService } from '../services';

export const USER_INVITATION_KEY = 'invitation';

interface UserInvitationState extends EntityState<UserInvitation> {
  loading: boolean;
  count: number;
  numberInvitations: number;
  errors: SerializedException[];
}

const MAX_NUMBER_OF_RECORDS = 50;

const userInvitationAdapter = createEntityAdapter<UserInvitation>();

export const createInitialState = (): UserInvitationState => userInvitationAdapter.getInitialState({
  loading: false,
  count: 0,
  numberInvitations: 0,
  errors: [],
});

export const doCountInvitationsOfAccount = createAsyncThunk(
  'account/countInvitationsOfAccount',
  async (input: Pick<Account, 'id'>) => {
    const criteria: Array<Query<UserInvitation>> = [
      { field: 'accountId', operator: Operator.EQ, value: input.id },
      { field: 'accepted', operator: Operator.EQ, value: false },
      { field: 'archived', operator: Operator.EQ, value: false },
      { limit: 1 }, // save bandwidth
    ];
    const response = await UserInvitationService.getUserInvitations(...criteria);
    return { numberInvitations: response.total };
  },
);

export const doGetK1ActiveUserInvitations = createAsyncThunk(
  'userInvitation/doGetAllInvitations',
  async (input: Pick<User, 'email'> & { invitationExpirationExtension: number }) => {
    const criteria: Array<Query<UserInvitation>> = [
      { field: 'accepted', operator: Operator.EQ, value: false },
      { field: 'archived', operator: Operator.EQ, value: false },
      { field: 'email', operator: Operator.EQ, value: input.email },
      { limit: MAX_NUMBER_OF_RECORDS },
    ];
    if (input.invitationExpirationExtension) {
      criteria.push(
        { field: 'updatedAt', operator: Operator.GT, value: new Date().getTime() - input.invitationExpirationExtension },
      );
    }

    // start getting invitations from k1:
    const firstResponse = await UserInvitationService.getUserInvitations(
      ...criteria,
    );
    const numberOfRequests = Math.ceil(firstResponse.total / MAX_NUMBER_OF_RECORDS);
    const remainingRequests = [];
    for (let i = 1; i < numberOfRequests; i += 1) {
      const req = UserInvitationService.getUserInvitations(
        ...criteria,
        { offset: MAX_NUMBER_OF_RECORDS * i },
      );
      remainingRequests.push(req);
    }
    const remainingResponses = await Promise.all(remainingRequests);
    const k1Invitations = [...firstResponse.data];
    remainingResponses.forEach(r => {
      k1Invitations.push(...r.data);
    });
    const k1UserInvitations: K1UserInvitation[] = k1Invitations.map(it => ({ ...it, type: 'K1' }));
    return k1UserInvitations;
  },
);

export const doGetUserInvitations = createAsyncThunk(
  'userInvitation/getUserInvitations',
  async (input: Pick<OrganizationUser, 'organizationId'>) => {
    const criteria: Array<Query<UserInvitation>> = [
      { field: 'organizationId', operator: Operator.IN, value: [input.organizationId] },
      { field: 'accepted', operator: Operator.EQ, value: false },
      { limit: MAX_NUMBER_OF_RECORDS },
    ];

    // start getting invitations from k1:
    const firstResponse = await UserInvitationService.getUserInvitations(
      ...criteria,
      { field: 'archived', operator: Operator.EQ, value: false },
    );
    const numberOfRequests = Math.ceil(firstResponse.total / MAX_NUMBER_OF_RECORDS);
    const remainingRequests = [];
    for (let i = 1; i < numberOfRequests; i += 1) {
      const req = UserInvitationService.getUserInvitations(
        ...criteria,
        { field: 'archived', operator: Operator.EQ, value: false },
        { offset: MAX_NUMBER_OF_RECORDS * i },
      );
      remainingRequests.push(req);
    }
    const remainingResponses = await Promise.all(remainingRequests);
    const k1Invitations = [...firstResponse.data];
    remainingResponses.forEach(r => {
      k1Invitations.push(...r.data);
    });
    const k1UserInvitations: K1UserInvitation[] = k1Invitations.map(it => ({ ...it, type: 'K1' }));
    // end getting invitations from k1.

    // get invitations from kit:
    const secondResponse = await UserInvitationService.getKitUserInvitations(...criteria);
    const kitNumberOfRequests = Math.ceil(secondResponse.total / MAX_NUMBER_OF_RECORDS);
    const kitRemainingRequests = [];
    for (let i = 1; i < kitNumberOfRequests; i += 1) {
      const req = UserInvitationService.getKitUserInvitations(
        ...criteria,
        { offset: MAX_NUMBER_OF_RECORDS * i },
      );
      kitRemainingRequests.push(req);
    }
    const kitRemainingResponses = await Promise.all(kitRemainingRequests);
    const kitInvitations = [...secondResponse.data];
    kitRemainingResponses.forEach(r => {
      kitInvitations.push(...r.data);
    });
    const kitUserInvitations: KitUserInvitation[] = kitInvitations.map(it => ({ ...it, type: 'KIT' }));
    // end getting invitations from kit.

    return [...k1UserInvitations, ...kitUserInvitations];
  },
);

export const doGetUserInvitationsByAccountId = createAsyncThunk(
  'userInvitation/getUserInvitationsByAccountId',
  async (input: Pick<UserInvitation, 'accountId'>) => {
    const criteria: Array<Query<UserInvitation>> = [
      { field: 'accountId', operator: Operator.IN, value: [input.accountId] },
      { field: 'accepted', operator: Operator.EQ, value: false },
      { field: 'archived', operator: Operator.EQ, value: false },
      { limit: MAX_NUMBER_OF_RECORDS },
    ];

    const firstResponse = await UserInvitationService.getUserInvitations(...criteria);
    const numberOfRequests = Math.ceil(firstResponse.total / MAX_NUMBER_OF_RECORDS);
    const remainingRequests = [];
    for (let i = 1; i < numberOfRequests; i += 1) {
      const req = UserInvitationService.getUserInvitations(
        ...criteria,
        { offset: MAX_NUMBER_OF_RECORDS * i },
      );
      remainingRequests.push(req);
    }
    const remainingResponses = await Promise.all(remainingRequests);
    const invitations = [...firstResponse.data];
    remainingResponses.forEach(r => {
      invitations.push(...r.data);
    });
    return invitations;
  },
);

export const doCountTotalUserInvitations = createAsyncThunk(
  'userInvitation/countTotalUserInvitations',
  async (input: Pick<OrganizationUser, 'organizationId'>) => {
    const criteria: Array<Query<UserInvitation>> = [
      { field: 'organizationId', operator: Operator.IN, value: [input.organizationId] },
      { field: 'accepted', operator: Operator.EQ, value: false },
      { limit: 0 },
    ];
    // start getting invitations from k1:
    const k1Response = await UserInvitationService.getUserInvitations(
      ...criteria,
      { field: 'archived', operator: Operator.EQ, value: false },
    );

    // get invitations from kit:
    const kitResponse = await UserInvitationService.getKitUserInvitations(...criteria);

    return { count: k1Response.total + kitResponse.total };
  },
);

export const doRevokeInvitation = createAsyncThunk(
  'userInvitation/revokeInvitation',
  async (input: Parameters<typeof UserInvitationService['deleteUserInvitation']>[0]) => {
    if (isK1Invitation(input.type)) {
      await UserInvitationService.deleteUserInvitation(input);
    } else {
      await UserInvitationService.deleteKitUserInvitation(input);
    }
    return { invitationId: input.id };
  },
);

export const doCreateInvitation = createAsyncThunk(
  'userInvitation/createInvitation',
  async (input: Parameters<typeof UserInvitationService['createInvitation']>[0], { rejectWithValue }) => {
    try {
      const response = await UserInvitationService.createInvitation(input);
      return { ...response.data, type: 'K1' };
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doRemoveInvitation = createAction<UserInvitation['id']>('userInvitation/removeInvitation');

export const doGetUserInvitationByToken = createAsyncThunk(
  'userInvitation/getUserInvitationByToken',
  async (input: Pick<UserInvitation, 'invitationToken'>) => {
    const criteria: Array<Query<UserInvitation>> = [
      { field: 'invitationToken', operator: Operator.EQ, value: input.invitationToken },
      { field: 'archived', operator: Operator.EQ, value: false },
      { field: 'accepted', operator: Operator.EQ, value: false },
      { limit: 1 },
    ];
    // start fetch invitation by token
    const response = await UserInvitationService.getUserInvitations(...criteria);
    return { invitations: response.data };
  },
);

export const doAcceptInvitation = createAsyncThunk(
  'userInvitation/doAcceptInvitation',
  async (input: Pick<UserInvitation, 'id' | 'invitationToken'>, { rejectWithValue }) => {
    try {
      // start fetch invitation by token
      const response = await UserInvitationService.updateInvitation(input);
      return { invitation: response.data };
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doDeclineInvitation = createAsyncThunk(
  'userInvitation/doDeclineInvitation',
  async (input: Pick<UserInvitation, 'id'>) => {
    // start fetch invitation by token
    const requestBody = {
      id: input.id,
      type: 'K1',
    };
    const response = await UserInvitationService.deleteUserInvitation(requestBody);
    return { invitation: response };
  },
);

const userInvitationSlice = createSlice({
  name: USER_INVITATION_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doGetUserInvitations.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetUserInvitations.fulfilled, (state, action) => {
      state.loading = false;
      state.count = action.payload.length;
      userInvitationAdapter.setAll(state, action.payload);
    });
    builder.addCase(doGetUserInvitations.rejected, state => {
      state.loading = false;
    });
    builder.addCase(doGetUserInvitationsByAccountId.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetUserInvitationsByAccountId.fulfilled, (state, action) => {
      state.loading = false;
      state.count = action.payload.length;
      userInvitationAdapter.setAll(state, action.payload);
    });
    builder.addCase(doGetUserInvitationsByAccountId.rejected, state => {
      state.loading = false;
    });
    builder.addCase(doGetK1ActiveUserInvitations.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetK1ActiveUserInvitations.fulfilled, (state, action) => {
      state.loading = false;
      state.count = action.payload.length;
      userInvitationAdapter.setAll(state, action.payload);
    });
    builder.addCase(doGetK1ActiveUserInvitations.rejected, state => {
      state.loading = false;
    });
    builder.addCase(doAcceptInvitation.pending, state => {
      state.loading = true;
    });
    builder.addCase(doAcceptInvitation.fulfilled, (state, action) => {
      state.loading = false;
      userInvitationAdapter.removeOne(state, action.payload.invitation.id);
      state.count -= 1;
    });
    builder.addCase(doAcceptInvitation.rejected, (state, action) => {
      state.loading = false;
      state.errors.push(action.payload as SerializedException);
    });
    builder.addCase(doRevokeInvitation.pending, state => {
      state.loading = true;
    });
    builder.addCase(doRevokeInvitation.fulfilled, (state, action) => {
      state.loading = false;
      userInvitationAdapter.removeOne(state, action.payload.invitationId);
      state.count -= 1;
    });
    builder.addCase(doRevokeInvitation.rejected, state => {
      state.loading = false;
    });
    builder.addCase(doCreateInvitation.pending, state => {
      state.loading = true;
    });
    builder.addCase(doCreateInvitation.fulfilled, (state, action) => {
      state.loading = false;
      userInvitationAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(doCreateInvitation.rejected, (state, action) => {
      state.loading = false;
      state.errors.push(action.payload as SerializedException);
    });
    builder.addCase(doRemoveInvitation, (state, action) => {
      userInvitationAdapter.removeOne(state, action.payload);
    });
    builder.addCase(doGetUserInvitationByToken.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetUserInvitationByToken.fulfilled, (state, action) => {
      state.loading = false;
      userInvitationAdapter.setAll(state, action.payload.invitations);
    });
    builder.addCase(doGetUserInvitationByToken.rejected, state => {
      state.loading = false;
    });
    builder.addCase(doCountTotalUserInvitations.pending, state => {
      state.loading = true;
    });
    builder.addCase(doCountTotalUserInvitations.fulfilled, (state, action) => {
      state.loading = false;
      state.count = action.payload.count;
    });
    builder.addCase(doCountTotalUserInvitations.rejected, state => {
      state.loading = false;
    });
    builder.addCase(doCountInvitationsOfAccount.pending, state => {
      state.loading = true;
    });
    builder.addCase(doCountInvitationsOfAccount.fulfilled, (state, action) => {
      state.loading = false;
      state.numberInvitations = action.payload.numberInvitations;
    });
    builder.addCase(doCountInvitationsOfAccount.rejected, state => {
      state.loading = false;
    });
    builder.addCase(doDeclineInvitation.pending, state => {
      state.loading = true;
    });
    builder.addCase(doDeclineInvitation.fulfilled, (state, action) => {
      state.loading = false;
      userInvitationAdapter.removeOne(state, action.payload.invitation.id);
      state.count -= 1;
    });
    builder.addCase(doDeclineInvitation.rejected, state => {
      state.loading = true;
    });
  },
});

const selectUserInvitation = (state: AppState) => state[USER_INVITATION_KEY];

export const {
  selectAll: selectAllUserInvitations,
  selectEntities,
} = userInvitationAdapter.getSelectors(selectUserInvitation);

export const selectInvitationsByInvitedUserEmail = (email: string) => createSelector(
  selectAllUserInvitations,
  invitations => invitations.filter(it => it.email === email),
);

export const selectInvitationsByOrganizationId = (organizationId: Organization['id']) => createSelector(
  selectAllUserInvitations,
  userInvitations => userInvitations
    .filter(invitation => invitation.organizationId === organizationId),
);

export const selectInvitationsByAccountId = (accountId: UserInvitation['accountId']) => createSelector(
  selectAllUserInvitations,
  userInvitations => userInvitations.filter(invitation => invitation.accountId === accountId),
);

export const selectTotalUserInvitationsInAccount = createSelector(
  selectUserInvitation,
  state => state.numberInvitations,
);

export const selectInvitationByInvitationToken = (invitationToken: UserInvitation['invitationToken']) => createSelector(
  selectAllUserInvitations,
  invitations => invitations.find(invitation => invitation.invitationToken === invitationToken),
);

export const selectErrors = createSelector(
  selectUserInvitation,
  state => state.errors,
);

export const selectTotalUserInvitationsCount = createSelector(
  selectUserInvitation,
  state => state.count,
);
export default userInvitationSlice.reducer;
