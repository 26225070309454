import Axios from 'axios';
import { DataResponse, AuthorizationCode } from '../models';

export const getAuthorizationCode = async (responseType: string | null, clientId: string | null, redirectUri: string | null) => (await Axios.get<DataResponse<AuthorizationCode>>('/v1/oauth/authorize', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: {
    response_type: responseType,
    client_id: clientId,
    redirect_uri: redirectUri,
  },
  data: {},
})).data.data;
