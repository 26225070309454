import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { unwrapResult } from '@reduxjs/toolkit';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSnackbar } from 'notistack';
import { ReactNode, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import LoadingProgress from '../../../layout/LoadingProgress';
import { paymentSuccess, testCloudSubscriptionManagement } from '../../../layout/routes';
import { notFound, useNavigate, useQuery } from '../../../routes';
import { useAppDispatch } from '../../../store';
import { fromSubscriptions, fromInvoices, fromTestCloudQuote, fromTestOpsBillingInformation, fromTestOpsPaymentMethod } from '../../../store/rootReducer';
import { BasePurchaseInfoProps } from '../testcloud/TestCloudCheckout';
import BillingInformation from './BillingInformation';
import CardInformation from './CardInformation';
import { formatMessageRecurly } from '../utils';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    borderRadius: '.5rem',
    paddingLeft: theme.spacing(2),
  },
  section: {
    padding: theme.spacing(4),
  },
  title: {
    marginTop: theme.spacing(2.5),
    paddingBottom: '1rem',
  },
  subTitle: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  checkoutTitle: {
    paddingBottom: theme.spacing(1),
    borderBottom: '1px solid #DCDFE6',
    marginBottom: theme.spacing(1.5),
  },
  billingInformation: {
    paddingBottom: theme.spacing(3),
  },
  checkoutButton: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.background.paper,
  },
  cardInfomation: {
    marginBottom: theme.spacing(5),
  },
}));

const PaymentInfo = (props: BasePurchaseInfoProps) => {
  const { currentSubscription } = props;
  const classes = useStyles();
  const { get } = useQuery();
  const orgId = get('orgId');
  const { replace, replaceQuery, navigate } = useNavigate();
  const intl = useIntl();
  const loading = useSelector(fromInvoices.selectLoading);
  const preview = useSelector(fromInvoices.selectPreview);
  const order = useSelector(fromInvoices.selectOrder);
  const invoiceNumbers = useSelector(fromSubscriptions.selectInvoiceNumbers);
  const billingInformation = useSelector(
    fromTestOpsBillingInformation.selectTestOpsBillingInformation,
  );
  const paymentMethod = useSelector(fromTestOpsPaymentMethod.selectPaymentMethod);
  const [checkoutStatus, setCheckoutStatus] = useState<'loading' | 'finished' | undefined>(undefined);
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const isGetQuoteUrl = window.location.pathname.includes('get-quote');
  const errors = useSelector(fromSubscriptions.selectErrors());
  useEffect(() => {
    analytics.page();
  }, []);

  useEffect(() => {
    if (checkoutStatus === 'finished') {
      if (!invoiceNumbers?.length) {
        enqueueSnackbar(
          errors?.[0]?.message ? formatMessageRecurly(errors[0].message) : intl.formatMessage({ id: 'error.unknown' }),
          { variant: 'error' },
        );
        return;
      }
      replace(paymentSuccess.path, replaceQuery({
        orgId: Number(orgId),
        invoiceNumber: invoiceNumbers,
        ...(currentSubscription && { upgrading: 'true' }),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutStatus]);

  const handleOnClickCheckout = async () => {
    if (order) {
      setCheckoutStatus('loading');
      if (currentSubscription) {
        await dispatch(fromSubscriptions.doUpgrade([order]));
        setCheckoutStatus('finished');
      } else if (isGetQuoteUrl) {
        await dispatch(fromTestCloudQuote.doCreateTestCloudQuote(order))
          .then(unwrapResult)
          .then(() => {
            enqueueSnackbar(
              intl.formatMessage(
                { id: 'subscriptions.testcloud.get_quote.success' },
                {
                  span: (chunk: ReactNode) => (<span>{chunk}</span>),
                  b: (chunk: ReactNode) => (<b>{chunk}</b>),
                  br: <br />,
                },
              ),
              {
                variant: 'success',
                style: {
                  width: '380px',
                },
              },
            );
            navigate(testCloudSubscriptionManagement.path, replaceQuery({ orgId: `${orgId}` }));
          })
          .catch(() => {
            enqueueSnackbar(
              intl.formatMessage({ id: 'subscriptions.testcloud.get_quote.failed' }),
              { variant: 'error' },
            );
          })
          .finally(() => setCheckoutStatus('finished'));
      } else {
        await dispatch(fromSubscriptions.doSubscribe([order]));
        setCheckoutStatus('finished');
      }
    }
  };

  if (!orgId || Number.isNaN(orgId)) return <Navigate to={notFound.path} replace />;
  return (
    <div className={classes.root}>
      <Paper className={classes.section} elevation={0}>
        {
          !isGetQuoteUrl && (
            <>
              <Typography
                variant="h3"
                className={classes.checkoutTitle}
              >
                <FormattedMessage id="billinginfo.checkout" />
              </Typography>
              <Grid className={classes.cardInfomation}>
                <Elements stripe={stripePromise}>
                  <CardInformation />
                </Elements>
              </Grid>
            </>
          )
        }
        <Grid className={classes.billingInformation}>
          <BillingInformation />
        </Grid>
        <Box
          display="flex"
          justifyContent="space-between"
          mb={1}
          pb={1}
          sx={{ borderBottom: '1px solid rgba(0,0,0,.1)' }}
        >
          <Typography variant="subtitle2">
            <FormattedMessage id="payment_method.total" />
          </Typography>
          <Box display="flex" alignItems="center">
            <Typography variant="h2">
              $
            </Typography>
            <Typography variant="h2">
              {
                loading && checkoutStatus !== 'loading'
                  ? <Skeleton width={40} height={50} sx={{ ml: 0.5 }} />
                  : intl.formatNumber(preview.total || 0)
              }
            </Typography>
          </Box>
        </Box>
        <Grid>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOnClickCheckout}
            disabled={!billingInformation
              || (!isGetQuoteUrl && !paymentMethod) || Object.keys(preview).length === 0}
            className={classes.checkoutButton}
          >
            {isGetQuoteUrl ? intl.formatMessage({ id: 'subscriptions.testcloud.subscription_summary.get_quote' }) : intl.formatMessage({ id: 'billinginfo.checkout' })}
          </Button>
        </Grid>
      </Paper>
      {checkoutStatus === 'loading' && <LoadingProgress />}
    </div>
  );
};

export default PaymentInfo;
