import { OrganizationFeature } from './organizationFeature';

export enum ProductType {
  KATALON_STUDIO = 'KATALON_STUDIO',
  TESTOPS = 'TESTOPS',
  TESTCLOUD = 'TESTCLOUD',
}
export interface TestOpsEnterpriseProduct {
  id: number;
  productId: string;
  name: string;
  description: string;
  feature: OrganizationFeature;
  type: ProductType;
}
