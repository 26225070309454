/**
 * Not in use, please see ../payment
 */
import { yupResolver } from '@hookform/resolvers/yup';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import EditIcon from '@mui/icons-material/Edit';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { object, string } from 'yup';
import { useQuery } from '../../routes';
import { fromAuth, fromOrganizations, fromTestOpsBillingInformation, useAppDispatch, fromCurrentOrgUser } from '../../store';

const useStyles = makeStyles(theme => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: '1rem',
    paddingTop: '1rem',
  },
  label: {
    fontWeight: 'bold',
    marginRight: '4rem',
    paddingRight: '4rem',
    width: '14rem',
  },
  buttonSave: {
    width: '5.5rem',
    marginLeft: '3.8rem',
  },
  buttonCancel: {
    width: '5.5rem',
    marginLeft: '1rem',
    [theme.breakpoints.down('md')]: {
      marginTop: '1rem',
      marginLeft: '3.8rem',
    },
  },
  informationTextField: {
    marginLeft: '3.8rem',
  },
  typographyButton: {
    margin: '0 auto',
    textTransform: 'capitalize',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

interface BillingContactInputs {
  ccEmails: string;
  fullBusinessName: string;
  address1: string;
  address2: string;
  postalCode: string;
  city: string;
  state: string;
  country: string;
  vatNumber: string;
}

const inputs: Array<keyof BillingContactInputs> = [
  'fullBusinessName',
  'ccEmails',
  'country',
  'state',
  'city',
  'address1',
  'address2',
  'postalCode',
  'vatNumber',
];

const BillingContact = (prop: { organizationId: string | null }) => {
  const classes = useStyles();
  const { get } = useQuery();
  const orgId = get('orgId');
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const organization = useSelector(fromOrganizations.selectSelectedOrganization);
  const user = useSelector(fromAuth.selectUser);
  const orgTotalMember = useSelector(fromOrganizations.selectCount);
  const currentOrgUser = useSelector(fromCurrentOrgUser.selectByOrganizationIdAndEmail(
    user?.email || '',
    Number(orgId),
  ));
  const billingContact = useSelector(fromTestOpsBillingInformation.selectTestOpsBillingInformation);
  const loading = useSelector(fromTestOpsBillingInformation.selectLoading);
  const [disabledBillingContactForm, setDisabledBillingContactForm] = useState(true);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { organizationId } = prop;
  useEffect(() => {
    if (organizationId) {
      dispatch(fromTestOpsBillingInformation.doGetTestOpsBillingInformation(+organizationId));
    }
  }, [organizationId, dispatch]);
  const schema = object().shape({
    email: string()
      .required(
        intl.formatMessage(
          { id: 'billinginfo.billingcontact.error.required' },
          { field: 'Email' },
        ),
      )
      .matches(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.email.invalid' }),
      )
      .email(
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.email.invalid' }),
      ),
    name: string().trim()
      .required(
        intl.formatMessage(
          { id: 'billinginfo.billingcontact.error.required' },
          { field: 'Full Business Name' },
        ),
      ),
    country: string().trim()
      .required(
        intl.formatMessage(
          { id: 'billinginfo.billingcontact.error.required' },
          { field: 'Country' },
        ),
      )
      .matches(
        /^[a-zA-Z,. ]*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.country.invalid' }),
      ),
    state: string().trim()
      .required(
        intl.formatMessage(
          { id: 'billinginfo.billingcontact.error.required' },
          { field: 'State' },
        ),
      )
      .matches(
        /^[a-zA-Z ,.;'&/()-]*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.state.invalid' }),
      ),
    city: string().trim()
      .required(
        intl.formatMessage(
          { id: 'billinginfo.billingcontact.error.required' },
          { field: 'City' },
        ),
      )
      .matches(
        /^[a-zA-Z ,.;'&/()-]*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.city.invalid' }),
      ),
    address1: string().trim()
      .required(
        intl.formatMessage(
          { id: 'billinginfo.billingcontact.error.required' },
          { field: 'Address Line 1' },
        ),
      )
      .matches(
        /^[a-zA-Z0-9# .,;:'°]*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.address.invalid' }),
      ),
    address2: string()
      .matches(
        /^[a-zA-Z0-9# .,;:'°]*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.address.invalid' }),
      ),
    postalCode: string().trim()
      .required(
        intl.formatMessage(
          { id: 'billinginfo.billingcontact.error.required' },
          { field: 'Postal Code' },
        ),
      )
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.postalCode.invalid' }),
      ),
    vatId: string()
      .required(
        intl.formatMessage(
          { id: 'billinginfo.billingcontact.error.required' },
          { field: 'VAT/GSC ID' },
        ),
      )
      .matches(
        /^[a-zA-Z0-9 .-]*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.vatId.invalid' }),
      ),
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<BillingContactInputs>({
    defaultValues: {
      ccEmails: '',
      fullBusinessName: '',
      address1: '',
      address2: '',
      postalCode: '',
      city: '',
      state: '',
      country: '',
      vatNumber: '',
    },
    resolver: yupResolver(schema),
  });

  const mapStateDataToInputs = () => {
    if (billingContact) {
      inputs.forEach(input => {
        if (billingContact[input]) {
          setValue(
            input,
            billingContact[input].toString(),
          );
        } else {
          setValue(
            input,
            '',
          );
        }
      });
    }
  };
  useEffect(() => {
    mapStateDataToInputs();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [billingContact, setValue]);

  const changeState = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setDisabledBillingContactForm(!disabledBillingContactForm);
  };

  const submitBillingContact = async (
    billingContactInputs: BillingContactInputs,
  ) => {
    try {
      if (billingContact) {
        await dispatch(
          fromTestOpsBillingInformation.doUpdateTestOpsBillingInformation({
            id: billingContact.id,
            organizationId: billingContact.organizationId,
            ...billingContactInputs,
          }),
        )
          .then(unwrapResult)
          .then(() => {
            enqueueSnackbar(
              <FormattedMessage id="billinginfo.billingcontact.update.success" />,
              { variant: 'success' },
            );
            analytics.track(
              'Billing Information Saved',
              {
                user_id: user?.id,
                firstName: user?.firstName,
                lastName: user?.lastName,
                email: user?.email,
                system_role: user?.roles,
                org_role: currentOrgUser?.role,
                org_id: organization?.id,
                org_name: organization?.name,
                total_members: orgTotalMember,
              },
            );
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              user_id: user?.id,
              org_role: currentOrgUser?.role,
              system_role: user?.roles,
              org_id: organization?.id,
              org_name: organization?.name,
              total_members: orgTotalMember,
              event: 'gtm_billing_information_saved',
            });
          })

          .catch(() => {
            // If error, restore inputs value to default value
            mapStateDataToInputs();
          });
      }
    } finally {
      setDisabledBillingContactForm(true);
    }
  };

  const renderEditButton = (index: number) => (
    <Grid container item xs={12} md={5} lg={6} justifyContent="flex-end">
      {index === 0 && disabledBillingContactForm ? (
        <Button onClick={changeState} endIcon={<EditIcon color="primary" />}>
          <Typography variant="h5">
            {intl.formatMessage({ id: 'billinginfo.button.edit' })}
          </Typography>
        </Button>
      ) : null}
    </Grid>
  );

  return (
    <form onSubmit={handleSubmit(submitBillingContact)}>
      { matches
        ? (
          <Grid container className={classes.formContainer}>
            { renderEditButton(0) }
          </Grid>
        ) : null }
      {inputs.map((key, index) => (
        <Grid container className={classes.formContainer}>
          <Grid item xs={3} md={2} lg={1}>
            <Typography variant="h5" className={classes.label}>
              {intl.formatMessage({
                id: `billinginfo.billingcontact.${key}`,
              })}
            </Typography>
          </Grid>
          <Grid item xs={6} md={5} lg={5}>
            <TextField
              variant="outlined"
              disabled={disabledBillingContactForm}
              className={classes.informationTextField}
              fullWidth
              placeholder={intl.formatMessage({
                id: `billinginfo.billingcontact.placeholder.${key}`,
              })}
              size="small"
              name={key}
              inputProps={{ ...register(key) }}
              error={!!errors[key]}
              helperText={
                errors[key]?.message
              }
            />
          </Grid>
          { !matches ? renderEditButton(index) : null }
        </Grid>
      ))}
      <br />
      <Grid container>
        <Grid item xs={3} md={2} lg={1} />
        <Grid container item xs={6} md={6} lg={5}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={disabledBillingContactForm}
            className={classes.buttonSave}
          >
            <Typography
              variant="button"
              className={classes.typographyButton}
            >
              {intl.formatMessage({ id: 'billinginfo.button.save' })}
            </Typography>
          </Button>
          <Button
            className={classes.buttonCancel}
            onClick={changeState}
            variant="outlined"
            disabled={disabledBillingContactForm}
          >
            <Typography
              variant="button"
              className={classes.typographyButton}
            >
              {intl.formatMessage({ id: 'billinginfo.button.cancel' })}
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </form>
  );
};

export default BillingContact;
