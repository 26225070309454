import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { fromAccounts, fromAccountUsers, fromAuth, fromOrganizations, useAppDispatch } from '../../store';
import AccountSettingItem from './AccountSettingItem';
import EditAccountNameDialog from './EditAccountNameDialog';
import { useQuery } from '../../routes';
import DeleteAccount from './DeleteAccount';
import { useConfig } from '../../config';
import AISetting from './AISetting';
import { AccountRole, OrganizationMigrationStatus } from '../../models';
import { useLaunchDarkly } from '../../launchdarkly';
import LoadingProgress from '../../layout/LoadingProgress';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    height: '100%',
    width: '100%',
    borderRadius: '.5rem',
    overflowY: 'auto',
    minWidth: theme.spacing(96),
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(3),
  },
  paperRoot: {
    minWidth: theme.spacing(96),
    borderRadius: theme.spacing(1),
    display: 'flex',
  },
  boxScroll: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: theme.spacing(35),
    overflow: 'auto',
  },
  subWelcome: {
    fontSize: theme.spacing(3),
    fontWeight: theme.typography.fontWeightMedium,
    color: '#233145',
  },
  boxFooter: {
    alignItems: 'center',
  },
  editNameArea: {
    marginTop: theme.spacing(2.5),
    display: 'relative',
    color: '#233145',
    height: theme.spacing(5),
    width: '20%',
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
  },
  accountItem: {
    width: '80%',
  },
}));

const AccountSetting = () => {
  const classes = useStyles();
  const { get } = useQuery();
  const { flags } = useLaunchDarkly();
  const accountIdFromUrl = get('accountId');
  const orgId = get('orgId');
  const dispatch = useAppDispatch();
  const currentOrganization = useSelector(fromOrganizations.selectOrganizationById(Number(orgId)));
  const accountId = Number(accountIdFromUrl) || Number(currentOrganization?.accountId);
  const user = useSelector(fromAuth.selectUser);
  const accountUser = useSelector(fromAccountUsers.selectOneByUserIdAndAccountId(
    Number(user?.id),
    Number(accountId),
  ));
  const account = useSelector(fromAccounts.selectAccountById(accountId));
  const { config } = useConfig();
  const orgMigrationStatus = useSelector(
    fromOrganizations.selectOrgMigrationStatusById(Number(orgId)),
  );
  const isRootOrAdmin = useSelector(fromAuth.selectIsRootOrAdmin);

  const [settingAiEnabled, setSettingAiEnabled] = useState(true);
  const [settingAiFetched, setSettingAiFetched] = useState(false);

  useEffect(() => {
    if (!accountId) return;
    dispatch(fromAccountUsers.doGetByUserIdAndAccountId({
      userId: user?.id || 0,
      accountId,
    }));
    // TODO: check permission to prevent access by URL
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  useEffect(() => {
    if (!flags || !accountId || (!accountUser && !isRootOrAdmin)) return;
    if (!AccountRole.isOwner(accountUser?.role) && !isRootOrAdmin) {
      setSettingAiFetched(true);
      return;
    }
    const fetchInformation = async () => {
      await dispatch(fromAccounts.doGetAISetting({
        accountId,
      }))
        .then(unwrapResult)
        .then(res => {
          const { data } = res;
          if (!isEmpty(data)) {
            const { enableAi } = data[0];
            setSettingAiEnabled(enableAi);
          }
        });
      setSettingAiFetched(true);
    };
    fetchInformation().catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flags, accountId, accountUser]);

  if (!account || !settingAiFetched) return <LoadingProgress />;

  return (
    <>
      <Box key="app.page.title.account.settings" className={classes.root}>
        <Typography variant="subtitle2" className={classes.subWelcome}>
          <FormattedMessage id="account.setting.header.account.setting" />
        </Typography>
        {(accountUser || isRootOrAdmin) && (
          <Box width="100%" overflow="auto" mt={2}>
            <Paper key="app.page.account.settings.paper" elevation={1} className={classes.paperRoot}>
              <Box key="app.page.account.settings.item" className={classes.accountItem}>
                <AccountSettingItem
                  key={accountUser?.id ?? account.id}
                  id={account.id}
                  viewedAs={accountUser?.role}
                />
              </Box>
              <Box key="app.page.account.settings.btn" className={classes.editNameArea}>
                <EditAccountNameDialog role={accountUser?.role} account={account} />
              </Box>
            </Paper>
            {
              AccountRole.isOwner(accountUser?.role)
              && !config?.onpremise
              && <AISetting account={account} enabled={settingAiEnabled} />
            }
            {
              (!flags?.projectStandardizeEnabled
                || orgMigrationStatus !== OrganizationMigrationStatus.MIGRATING
              )
              && AccountRole.isOwner(accountUser?.role)
              && !config?.onpremise
              && <DeleteAccount userId={user?.id!!} account={account} />
            }
          </Box>
        )}
      </Box>
    </>
  );
};

export default AccountSetting;
