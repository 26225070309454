import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { resolvePath, welcome } from '../../routes';
import { ReactComponent as ForbiddenIcon } from './ForbiddenIcon.svg';

const useStyles = makeStyles(theme => ({
  title: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  center: {
    textAlign: 'center',
  },
  root: {
    marginTop: theme.spacing(10),
  },
  goToOrg: {
    marginTop: theme.spacing(2),
  },
}));

const Forbidden = () => {
  const classes = useStyles();
  return (
    <Grid container justifyContent="center" className={classes.root}>
      <Grid item className={classes.center}>
        <ForbiddenIcon />
        <Typography variant="h2" className={classes.title}>
          <FormattedMessage id="forbidden.access_denied" />
        </Typography>
        <Typography variant="h3">
          <FormattedMessage id="forbidden.explanation" />
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.goToOrg}
          component={RouterLink}
          to={resolvePath(welcome, undefined, {}, false)}
        >
          <FormattedMessage id="forbidden.go_to_welcome_page" />
        </Button>
      </Grid>
    </Grid>
  );
};

export default Forbidden;
