import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import { TestOpsSubscriptionSource } from '../models/testOpsSubscriptionSource';
import {
  exceptionOf,
  OrganizationFeature,
  OrganizationTrialRequest,
  SerializedException,
} from '../models';
import { Operator, Query } from '../models/query';
import { SubscriptionStatus } from '../models/subscriptionStatus';
import { SubscriptionService } from '../services';
import { AppState } from './index';
import { StudioSubscription } from '../models/studioSubscription';
import { AdditionalResources } from './subscriptionsSlice';

export const STUDIO_SUBSCRIPTION_KEY = 'studioSubscriptions';

interface StudioSubscriptionState extends EntityState<StudioSubscription> {
  loading: boolean;
  invoiceNumbers: string[];
  trialRequest: OrganizationTrialRequest | null | undefined;
  errors: SerializedException[];
}

const studioSubscriptionsAdapter = createEntityAdapter<StudioSubscription>();
const createInitialState = (partialState: Partial<StudioSubscriptionState> = {}) => (
  studioSubscriptionsAdapter.getInitialState({
    ...partialState,
    loading: false,
    errors: [] as SerializedException[],
  })
);

export const doGetActiveByAccountId = createAsyncThunk(
  'studioSubscriptions/getByAccountId',
  async (input: Required<Pick<StudioSubscription, 'accountId'>> & AdditionalResources, { rejectWithValue }) => {
    const criteria: Query<StudioSubscription>[] = [
      {
        field: 'accountId',
        operator: Operator.EQ,
        value: input.accountId,
      },
      {
        field: 'status',
        operator: Operator.EQ,
        value: SubscriptionStatus.ACTIVE,
      },
      {
        field: 'expiryDate',
        operator: Operator.GT,
        value: new Date(Date.now() - (7 * 24 * 60 * 60 * 1000)).getTime(),
      },
    ];
    try {
      return (await SubscriptionService.getStudioSubscriptions(
        input.checkHasPremierSuccess || false,
        ...criteria,
      ));
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doResetEntity = createAction('entity/reset');

const studioSubscriptionsSlice = createSlice({
  name: STUDIO_SUBSCRIPTION_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doGetActiveByAccountId.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetActiveByAccountId.fulfilled, (state, action) => {
      state.loading = false;
      studioSubscriptionsAdapter.upsertMany(state, action.payload);
    });
    builder.addCase(doGetActiveByAccountId.rejected, (state, action) => {
      state.loading = false;
      state.errors.push(action.payload as SerializedException);
    });
    builder.addCase(doResetEntity, state => {
      studioSubscriptionsAdapter.removeAll(state);
    });
  },
});

const selectKsSubscriptionFeature = (state: AppState) => state[STUDIO_SUBSCRIPTION_KEY];
const { selectAll } = studioSubscriptionsAdapter.getSelectors(selectKsSubscriptionFeature);

export const selectLoading = createSelector(
  selectKsSubscriptionFeature,
  subscriptionState => subscriptionState.loading,
);

export const selectByAccountId = (id: StudioSubscription['accountId']) => createSelector(
  selectAll,
  all => all.filter(it => it.accountId === id),
);

export const selectByAccountIdAndFeature = (id: StudioSubscription['accountId'], feature: StudioSubscription['feature']) => createSelector(
  selectAll,
  all => all.filter(it => it.accountId === id)
    .filter(it => it.feature === feature),
);

export const selectPaidSubscriptionByAccountId = (id: StudioSubscription['accountId'], feature: StudioSubscription['feature']) => createSelector(
  selectAll,
  all => all.filter(it => it.accountId === id)
    .filter(it => it.feature === feature && it.source === TestOpsSubscriptionSource.RECURLY),
);

export const selectPaidKseSubscriptionByAccountId = (id: StudioSubscription['accountId']) => createSelector(
  selectAll,
  all => all.filter(it => it.accountId === id)
    .filter(it => (
      it.feature === OrganizationFeature.KSE
      || it.feature === OrganizationFeature.PER_USER_KSE
      || it.feature === OrganizationFeature.UNLIMITED_KSE
    ) && it.source === TestOpsSubscriptionSource.RECURLY),
);

export const selectPaidHybridKseSubscriptionByAccountId = (id: StudioSubscription['accountId']) => createSelector(
  selectAll,
  all => all.filter(it => it.accountId === id)
    .filter(it => it.feature === OrganizationFeature.PER_USER_KSE_OFFLINE
      && it.source === TestOpsSubscriptionSource.RECURLY),
);

export const selectPaidKreSubscriptionByAccountId = (id: StudioSubscription['accountId']) => createSelector(
  selectAll,
  all => all.filter(it => it.accountId === id)
    .filter(it => (
      it.feature === OrganizationFeature.UNLIMITED_ENGINE
    ) && it.source === TestOpsSubscriptionSource.RECURLY),
);

export const selectPaidHybridKreSubscriptionByAccountId = (id: StudioSubscription['accountId']) => createSelector(
  selectAll,
  all => all.filter(it => it.accountId === id)
    .filter(it => it.feature === OrganizationFeature.UNLIMITED_ENGINE_OFFLINE
      && it.source === TestOpsSubscriptionSource.RECURLY),
);

export default studioSubscriptionsSlice.reducer;
