enum UsagePlan {
  COMMUNITY = 'COMMUNITY',
  BUSINESS = 'BUSINESS',
  ENTERPRISE = 'ENTERPRISE',
  PLATFORM = 'PLATFORM',
}

// https://github.com/typescript-eslint/typescript-eslint/issues/3568
// eslint-disable-next-line @typescript-eslint/no-redeclare
namespace UsagePlan {
  export const getPlanFullName = (
    plan: UsagePlan | string | undefined,
  ) => {
    switch (plan) {
      case UsagePlan.COMMUNITY:
        return 'TestOps';
      case UsagePlan.BUSINESS:
        return 'TestOps Business';
      case UsagePlan.ENTERPRISE:
        return 'TestOps Enterprise';
      case UsagePlan.PLATFORM:
        return 'Katalon Platform';
      default:
        return 'Unknown';
    }
  };
}

export { UsagePlan };
