import Axios from 'axios';

import { BillingInformation, DataResponse, GeneratedFields, PageableResponse } from '../models';
import { composeQuery, Query } from '../models/query';

export const upsertBillingInformation = async (input: Required<Pick<BillingInformation, 'accountId'>> & Partial<Omit<BillingInformation, 'accountId' | GeneratedFields>> & Partial<Pick<BillingInformation, 'id'>>) => (await Axios.put<DataResponse<BillingInformation>>('/v1/account-billing-information', input)).data.data;

export const getBillingInformation = async (...criteria: Query<BillingInformation>[]) => (await Axios.get<PageableResponse<BillingInformation>>('/v1/account-billing-information', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: composeQuery<BillingInformation>(...criteria),
  data: {},
})).data.data;
