import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  useAppDispatch,
  fromSecuritySetting,
  fromTestOpsPlatformSubscriptions,
  fromSubscriptions,
} from '../../store';
import { useQuery } from '../../routes';
import LoadingProgress from '../../layout/LoadingProgress';
import AccountIdleTimeout from './AccountIdleTimeout';
import AccountIpAddressRestrictions from './AccountIpAddressRestrictions';
import { OrganizationFeature } from '../../models';
import AccountCustomDomainAndSso from './AccountCustomDomainAndSso';
import { useConfig } from '../../config';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    height: '100%',
    width: '100%',
    borderRadius: '.5rem',
    overflowY: 'auto',
    minWidth: theme.spacing(96),
  },
  container: {
    width: '100%',
  },
  paperRoot: {
    margin: theme.spacing(2, 0),
    minWidth: theme.spacing(96),
    borderRadius: theme.spacing(1),
  },
  header: {
    fontSize: theme.spacing(3),
    fontWeight: theme.typography.fontWeightMedium,
    color: '#233145',
  },
}));

const AccountSecuritySetting = () => {
  const { get } = useQuery();
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { config } = useConfig();

  const accountId = Number(get('accountId'));

  const securitySettings = useSelector(
    fromSecuritySetting.selectAllSecuritySettingsByAccountId(accountId),
  );

  useEffect(() => {
    if (!accountId) return;

    setLoading(true);

    Promise.allSettled([
      dispatch(fromTestOpsPlatformSubscriptions
        .doGetAllTestOpsPlatformSubscriptionsByAccountId({
          accountId,
        })),
      dispatch(fromSubscriptions
        .doGetActiveSubscriptionsByAccountId({ accountId })),
      dispatch(fromSecuritySetting.getAllSecuritySettings({ accountId })),
    ]).then(() => { setLoading(false); });
  }, [accountId]);

  const activePlatformSubscription = useSelector(fromTestOpsPlatformSubscriptions
    .selectPaidTestOpsPlatformSubscriptionByAccountId(
      accountId,
    )).filter(sub => new Date(sub.expiryDate).getTime() > Date.now())?.[0];

  // TODO: waiting for migration
  const isSsoFlagEnabled = false;

  const hasTOEnterprise = useSelector(fromSubscriptions.selectHasTOEnterprise());

  const isEnableConfigureCustomDomainAndSSO = (
    activePlatformSubscription?.feature === OrganizationFeature.TESTOPS_PLATFORM
    && activePlatformSubscription.quota >= 30000)
    || hasTOEnterprise
    || isSsoFlagEnabled;

  if (loading) {
    return <LoadingProgress />;
  }

  return (
    <>
      <Box key="app.page.title.security_settings" className={classes.root}>
        <Typography variant="subtitle2" className={classes.header}>
          <FormattedMessage id="security_setting.header" />
        </Typography>
        <Box overflow="auto" className={classes.container}>
          <Paper key="app.page.security_settings.idle_timeout.paper" elevation={1} className={classes.paperRoot}>
            <AccountIdleTimeout
              securitySettings={securitySettings[0]}
            />
          </Paper>
          <Paper key="app.page.security_settings.custom_domain.paper" elevation={1} className={classes.paperRoot}>
            <AccountCustomDomainAndSso
              securitySettings={securitySettings[0]}
              hasValidSubscription={isEnableConfigureCustomDomainAndSSO}
            />
          </Paper>
          {!config?.onpremise && (
            <Paper key="app.page.security_settings.ip_address_restrictions.paper" elevation={1} className={classes.paperRoot}>
              <AccountIpAddressRestrictions
                securitySettings={securitySettings[0]}
                hasValidSubscription={isEnableConfigureCustomDomainAndSSO}
              />
            </Paper>
          )}
        </Box>
      </Box>
    </>
  );
};

export default AccountSecuritySetting;
