import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '.';
import { Organization, TestOpsTeam } from '../models';
import { Operator } from '../models/query';
import { TestOpsTeamService } from '../services';

export const TESTOPS_TEAMS_FEATURE_KEY = 'teams';
export const ERROR_ORGANIZATION_USER_NOT_EXISTED = 'testops.organization.use.not.existed';
export const ERROR_TEAM_USER_EXISTED = 'testops.team.user.existed';

interface TestOpsTeamsState
  extends EntityState<TestOpsTeam> {
  loading: { [key: string]: boolean };
  error?: string;
  teamsOfOwnerOrAdminByOrgId: { [key: number]: TestOpsTeam[] };
}

const teamAdapter = createEntityAdapter<TestOpsTeam>();

export const createInitialState = (partialState: Partial<TestOpsTeamsState> = {}) => (
  teamAdapter.getInitialState({
    loading: {},
    teamsOfOwnerOrAdminByOrgId: [],
    ...partialState, // overwrite if necessary
  })
);

export const doGetTeamsOfOwnerOrAdminByOrgId = createAsyncThunk(
  'teams/getByTeamOwnerOrAdminAndOrgId',
  async (input: { organizationId: Organization['id'] }) => {
    const teams = await TestOpsTeamService.getTeamsOfOwnerOrAdmin(
      { field: 'organizationId', operator: Operator.EQ, value: input.organizationId },
      { field: 'name', desc: false },
    );

    return { organizationId: input.organizationId, teams: [...teams.data] };
  },
);
const testOpsTeamSlice = createSlice({
  name: TESTOPS_TEAMS_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {
    doChangeError(state, action: PayloadAction<TestOpsTeamsState['error']>) {
      state.error = action.payload;
    },
  },
  extraReducers: builder => {
    // Get teams of owner or admin in org
    builder.addCase(doGetTeamsOfOwnerOrAdminByOrgId.pending, state => {
      state.loading[doGetTeamsOfOwnerOrAdminByOrgId.typePrefix] = true;
    });
    builder.addCase(doGetTeamsOfOwnerOrAdminByOrgId.fulfilled, (state, action) => {
      state.teamsOfOwnerOrAdminByOrgId[action.payload.organizationId] = action.payload.teams;
      state.loading[doGetTeamsOfOwnerOrAdminByOrgId.typePrefix] = false;
    });
    builder.addCase(doGetTeamsOfOwnerOrAdminByOrgId.rejected, (state, action) => {
      state.loading[doGetTeamsOfOwnerOrAdminByOrgId.typePrefix] = false;
      state.error = action.error.message;
    });
  },
});

const selectTeamsFeature = (state: AppState) => state[TESTOPS_TEAMS_FEATURE_KEY];

export const selectLoading = createSelector(
  selectTeamsFeature,
  state => state.loading,
);

export const selectFetchingTeamsOfOwnerOrAdminLoading = createSelector(
  selectTeamsFeature,
  state => state.loading[doGetTeamsOfOwnerOrAdminByOrgId.typePrefix],
);
export const selectError = createSelector(
  selectTeamsFeature,
  state => state.error,
);

export const selectTeamsOfOwnerOrAdminByOrgId = (id: Organization['id']) => createSelector(
  selectTeamsFeature,
  state => state.teamsOfOwnerOrAdminByOrgId[id],
);

export const { doChangeError } = testOpsTeamSlice.actions;
export default testOpsTeamSlice.reducer;
