import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { login, logout, useNavigate } from '.';
import { fromAuth, useAppDispatch } from '../store';
import { useQuery } from './useQuery';
import { useConfig } from '../config';

export const TOKEN = 'k1.login';

// This hook can be moved into ProtectedRoute
// but it will be more developer friendly when we need different route guard
// which can stay in another hook, ex: Role
export const useAuthenticate = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { replaceQuery, replace } = useNavigate();
  const { get, clear, queryString, queryDictionary } = useQuery();
  const { config } = useConfig();
  const user = useSelector(fromAuth.selectUser);
  const isAuthenticated = useSelector(fromAuth.selectIsAuthenticated);
  const [isAfterResume, setIsAfterResume] = useState(false);

  useEffect(() => {
    if (!config) return;
    (async () => {
      try {
        const newLogin = get('new_login');
        if (newLogin === 'true') {
          clear('new_login');
          replace(
            login.path,
            replaceQuery({
              redirect: `${location.pathname}?${queryString()}`,
              new_login: true,
            }),
          );
          return;
        }
        const jwt = get('access_token');
        if (!!jwt || (isAuthenticated && !user)) {
          clear('access_token');
          replace(undefined, replaceQuery(queryDictionary()));
          await dispatch(fromAuth.doResume(jwt ? { jwt } : undefined));
        }
      } finally {
        setIsAfterResume(true);
      }
    })();

    // This hook should run only once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  // trigger an action to call doResume when token changed
  // either user logout or change token manually
  const localStorageChangeListener = (e: StorageEvent) => {
    // ignore if key changed is not TOKEN or user isn't authenticate
    if (e.key !== TOKEN || !isAuthenticated) return;

    const tokenString = localStorage.getItem(TOKEN);
    // if token is not set => logout immediately
    if (!tokenString) {
      replace(
        logout.path,
        replaceQuery(queryDictionary()),
      );
      return;
    }

    (async () => {
      try {
        await dispatch(fromAuth.doResume());
      } catch (_) {
        // do nothing
      }
    })();
  };
  useEffect(() => {
    window.addEventListener('storage', localStorageChangeListener);

    return () => window.removeEventListener('storage', localStorageChangeListener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAfterResume && !isAuthenticated) {
      replace(
        login.path,
        replaceQuery({ redirect: `${location.pathname}?${queryString()}` }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isAfterResume]);

  return isAfterResume && isAuthenticated;
};
