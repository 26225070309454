import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { inviteMember as inviteMemberRoute, organizationList as organizationListRoute } from '../../layout/routes';
import { resolvePath, useNavigate } from '../../routes';
import { fromAuth, fromTestOpsBillingInformation, fromOrganizations, useAppDispatch } from '../../store';
import { ReactComponent as CreateOrganizationIcon } from './createOrgIcon.svg';
import LoadingProgress from '../../layout/LoadingProgress';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    borderRadius: theme.shape.borderRadius,
  },
  createOrg: {
    width: '100%',
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    verticalAlign: 'middle',
    justifyContent: 'center',
    margin: '0 auto',
    paddingTop: theme.spacing(3),
  },
  icon: {
    marginBottom: theme.spacing(-3),
    width: '100%',
    fontSize: '160px',
  },
  title: {
    color: theme.palette.primary.dark[500],
    paddingBottom: theme.spacing(5),
  },
  form: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  orgName: {
    marginTop: theme.spacing(3),
  },
  create: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
    '& > *': {
      justifyContent: 'center',
    },
  },
}));

interface CreateOrgInputs {
  name: string;
}

const CreateOrganization = () => {
  const [loading, setLoading] = useState(false);
  const organizationLoading = useSelector(fromOrganizations.selectLoadingGetOrganizationsByUserId);
  const dispatch = useAppDispatch();
  const { navigate, mergeQuery } = useNavigate();
  const classes = useStyles();
  const user = useSelector(fromAuth.selectUser);
  const { register, handleSubmit, formState: { errors } } = useForm<CreateOrgInputs>({
    defaultValues: { name: '' },
  });

  const doCreateOrganization = async ({ name }: CreateOrgInputs) => {
    if (name) {
      setLoading(true);
      try {
        const org = unwrapResult(
          await dispatch(fromOrganizations.doCreateOrganization({ name, accountId: 1 })),
        );
        await dispatch(fromTestOpsBillingInformation.doCreateTestOpsBillingInformation({
          organizationId: org.organization.id,
        }));
        analytics.track(
          'Organization Created',
          {
            user_id: user?.id,
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
            system_role: user?.roles,
            org_role: 'OWNER',
            org_id: org?.organization.id,
            org_name: org?.organization.name,
          },
        );
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          user_id: user?.id,
          system_role: user?.roles,
          org_role: 'OWNER',
          org_id: org.organization.id,
          org_name: org.organization.name,
          event: 'gtm_organization_created',
        });
        navigate(resolvePath(inviteMemberRoute), mergeQuery({ orgId: org.organization.id }));
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    analytics.page();
  }, []);

  return (
    <Paper className={classes.root} elevation={0}>
      <Box className={classes.createOrg}>
        <CreateOrganizationIcon className={classes.icon} />
        <Typography className={classes.title} variant="h2">
          <FormattedMessage id="createorganization.box.title" />
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(doCreateOrganization)}>
          <TextField
            className={classes.orgName}
            variant="outlined"
            size="small"
            fullWidth
            autoFocus
            name="name"
            label={<FormattedMessage id="createorganization.form.orgname" />}
            inputProps={{
              ...register('name', {
                required: { message: 'error.required', value: true },
                maxLength: { message: 'error.maxlength', value: 255 },
                validate: value => value.trim() !== '' || 'error.notblank',
              }),
            }}
            helperText={
              errors.name
              && <FormattedMessage id={errors.name?.message} values={{ length: 255 }} />
            }
            error={!!errors.name}
          />
          <Button
            className={classes.create}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size="large"
          >
            <FormattedMessage id="createorganization.form.create" />
          </Button>
          <Button
            color="primary"
            size="small"
            component={RouterLink}
            to={resolvePath(organizationListRoute, undefined, {}, false)}
          >
            <Typography variant="body1">
              <FormattedMessage id="createorganization.form.back" />
            </Typography>
          </Button>
        </form>
      </Box>
      {(loading || organizationLoading) && (
        <LoadingProgress />
      )}
    </Paper>
  );
};

export default CreateOrganization;
