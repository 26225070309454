import Axios, { AxiosRequestHeaders } from 'axios';

import { AuthOidcTokenParams, DataResponse, Token, User } from '../models';

export const login = async (credential: Required<Pick<User, 'email' | 'password'>>) => (
  await Axios.post<DataResponse<Token>>(
    '/v1/auth/login',
    credential,
    {
      transformRequest: [
        (data: any, headers: AxiosRequestHeaders) => {
          delete headers.Authorization;
          return data;
        },
      ].concat(Axios.defaults.transformRequest ? Axios.defaults.transformRequest : []),
    },
  )).data.data;

export const me = async () => (await Axios.get<DataResponse<User>>('/v1/auth/me')).data.data;

export const sendActivationEmail = async () => (await Axios.post('/v1/auth/send-activation-email')).data;

export const activate = async (activationCode: string) => (await Axios.post<DataResponse<Token>>(
  '/v1/auth/activate',
  activationCode,
  { headers: { 'Content-Type': 'text/plain' } },
)).data.data;

export const exchangeToken = async (credentials: Partial<AuthOidcTokenParams>) => (
  await Axios.post<DataResponse<Token>>(
    '/v1/auth/oidc/token',
    credentials,
    {
      transformRequest: [
        (data: any, headers: AxiosRequestHeaders) => {
          delete headers.Authorization;
          return data;
        },
      ].concat(Axios.defaults.transformRequest ? Axios.defaults.transformRequest : []),
    },
  )
).data.data;

export const logoutToken = async (token: string) => (await Axios.post<DataResponse<Token>>(
  '/v1/auth/oidc/logout',
  token,
  { headers: { 'Content-Type': 'text/plain' } },
)).data;
