enum OrganizationRole {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  BILLING_MANAGER = 'BILLING_MANAGER',
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
namespace OrganizationRole {
  export const fromString = (str: string): OrganizationRole | undefined => (
    OrganizationRole as any)[str];
  export const getRoleFullName = (role: OrganizationRole | undefined) => {
    switch (role) {
      case OrganizationRole.OWNER:
        return 'Owner';
      case OrganizationRole.ADMIN:
        return 'Admin';
      case OrganizationRole.MEMBER:
        return 'Member';
      case OrganizationRole.BILLING_MANAGER:
        return 'Billing Manager';
      default:
        return 'Unknown';
    }
  };
  export const getRoleFullNameWithOrganization = (role: OrganizationRole | undefined) => {
    switch (role) {
      case OrganizationRole.OWNER:
        return 'Organization Owner';
      case OrganizationRole.ADMIN:
        return 'Organization Admin';
      case OrganizationRole.MEMBER:
        return 'Member';
      case OrganizationRole.BILLING_MANAGER:
        return 'Billing Manager';
      default:
        return 'Unknown';
    }
  };
  export const isAdminOrOwnerRole = (role: OrganizationRole | undefined) => (
    role === OrganizationRole.OWNER || role === OrganizationRole.ADMIN
  );
  export const isAdmin = (role: OrganizationRole | undefined) => (role === OrganizationRole.ADMIN);
  export const isMember = (role: OrganizationRole | undefined) => (
    role === OrganizationRole.MEMBER
  );
}

export { OrganizationRole };

export const isOrganizationAdminOrOwner = (role: OrganizationRole) => [
  OrganizationRole.ADMIN,
  OrganizationRole.OWNER,
].includes(role);

export const isOrganizationBillingManagerOrOwner = (role: OrganizationRole) => [
  OrganizationRole.BILLING_MANAGER,
  OrganizationRole.OWNER,
].includes(role);

export const isOrganizationManager = (role: OrganizationRole) => [
  OrganizationRole.BILLING_MANAGER,
  OrganizationRole.ADMIN,
  OrganizationRole.OWNER,
].includes(role);
