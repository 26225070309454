import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useLaunchDarkly } from '../../launchdarkly';
import { notFound, resolvePath, useNavigate, useQuery } from '../../routes';
import { accountUserManagement } from '../../layout/routes';
import ActiveUserTab from './components/ActiveUserTab';
import PendingInvitationTab from './components/PendingInvitationTab';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 3),
  },
  pageTitle: {
    fontSize: theme.spacing(3),
    fontWeight: 500,
  },
  tabContainer: {
    minHeight: theme.spacing(5),
  },
  tabItem: {
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
    padding: theme.spacing(1, 2),
    marginRight: theme.spacing(0),
    minHeight: theme.spacing(5),
    '&.Mui-selected': {
      color: '#1847ca',
    },
  },
  tabContent: {
    padding: theme.spacing(2),
  },
  boxSizing: {
    height: theme.spacing(2),
  },
}));

const tabList = ['active', 'pending', 'removed'];

const AccountUserManagement = () => {
  const { initialized, flags } = useLaunchDarkly();
  const { replace, replaceQuery } = useNavigate();
  const classes = useStyles();
  const intl = useIntl();
  const query = useQuery();

  const currentTab = window.location.pathname.split('/').pop() ?? '';
  const accountId = Number(query.get('accountId'));

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  useEffect(() => {
    if (!initialized) {
      return;
    }

    if (!flags?.accountUserManagementEnabled) {
      replace(notFound.path, replaceQuery({}));
      return;
    }

    if (!tabList.includes(currentTab)) {
      const accountUserPath = resolvePath(accountUserManagement, { tab: 'active' }, {});
      replace(`${accountUserPath}`, replaceQuery({ accountId }));
      return;
    }
    setSelectedTabIndex(tabList.findIndex(it => it === currentTab));
  }, [initialized, flags, currentTab, accountId]);

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  return (
    <div className={classes.root}>

      <span className={classes.pageTitle}>
        { intl.formatMessage({ id: 'account.users.title' }) }
      </span>
      {/* Tabs */}
      <Tabs
        value={selectedTabIndex}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        textColor="inherit"
        className={classes.tabContainer}
      >
        {
          tabList.map(tab => (
            <Tab
              key={`account.users.tab.${tab}`}
              className={classes.tabItem}
              id={`account.users.tab.${tab}`}
              label={intl.formatMessage({ id: `account.users.tab.${tab}` })}
              component={Link}
              to={resolvePath(accountUserManagement, { tab }, { accountId })}
            />
          ))
        }
      </Tabs>
      <Box className={classes.boxSizing} />
      {/* Tab content */}
      <Paper className={classes.tabContent}>
        { currentTab === 'active' && (
          <ActiveUserTab accountId={accountId} />
        )}
        { currentTab === 'pending' && (
          <PendingInvitationTab accountId={accountId} />
        )}
      </Paper>
    </div>
  );
};

export default AccountUserManagement;
