import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';

import {
  exceptionOf,
  Order,
  SerializedException,
} from '../models';

import { AppState } from '.';
import { OrderService } from '../services';
import { OrderResponse } from '../models/order';

export const ORDER_FEATURE_KEY = 'order';

interface OrderState extends EntityState<OrderResponse> {
  pendingOrder?: OrderResponse;
  orderResponse?: OrderResponse;
  loading: boolean;
  errors: SerializedException[];
  checkoutError: SerializedException;
}

const orderAdapter = createEntityAdapter<OrderState>();

export const createInitialState = (partialState: Partial<OrderState> = {}) => (
  orderAdapter.getInitialState({
    ...partialState,
    loading: false,
    errors: [] as SerializedException[],
  })
);

export const doGetPendingOrder = createAsyncThunk(
  'order/getPendingOrder',
  async (accountId: number, { rejectWithValue }) => {
    try {
      const data = await OrderService.getPendingOrder(accountId);
      return { data };
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doCheckout = createAsyncThunk(
  'order/checkout',
  async (
    order: Order,
    { rejectWithValue },
  ) => {
    try {
      const data = await OrderService.checkout(order);
      return { data };
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

const orderSlice = createSlice({
  name: ORDER_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doGetPendingOrder.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetPendingOrder.fulfilled, (state, action) => {
      state.pendingOrder = action.payload.data.dueDate ? action.payload.data : undefined;
      state.loading = false;
    });
    builder.addCase(doGetPendingOrder.rejected, (state, action) => {
      state.loading = false;
      state.errors.push(action.payload as SerializedException);
    });
    builder.addCase(doCheckout.pending, state => {
      state.loading = true;
      state.checkoutError = undefined;
      state.orderResponse = undefined;
    });
    builder.addCase(doCheckout.fulfilled, (state, action) => {
      state.orderResponse = action.payload.data.data;
      state.loading = false;
    });
    builder.addCase(doCheckout.rejected, (state, action) => {
      state.loading = false;
      state.checkoutError = action.payload as SerializedException;
    });
  },
});

const selectOrderFeature = (state: AppState) => state[ORDER_FEATURE_KEY];

export const selectPendingOrder = createSelector(
  selectOrderFeature,
  it => it.pendingOrder,
);

export const selectOrderResponse = createSelector(
  selectOrderFeature,
  it => it.orderResponse,
);

export const selectLoading = createSelector(
  selectOrderFeature,
  it => it.loading,
);

export const selectCheckoutError = createSelector(
  selectOrderFeature,
  it => it.checkoutError,
);

export default orderSlice.reducer;
