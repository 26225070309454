import { alpha, useTheme } from '@mui/material/styles';
import { ChartData, ChartOptions } from 'chart.js';
import debounce from 'lodash/fp/debounce';
import max from 'lodash/max';
import ms from 'ms';
import { useEffect, useRef } from 'react';
import { Chart } from 'react-chartjs-2';
import { useIntl } from 'react-intl';

import { licenseUtilizationChartTooltip } from '../CustomizedContentTooltip';

interface LicenseUsageStackedChartProps {
  durations: number[][];
  xLabels: string[];
  productNames: string[];
  colors: string[];
  totals: number[];
  percentageEachMonth: number[][];
  startDateStrings: string[];
  endDateStrings: string[];
}

const LicenseUsageStackedChart = (props: LicenseUsageStackedChartProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const {
    xLabels,
    durations,
    productNames,
    colors,
    totals,
    percentageEachMonth,
    startDateStrings,
    endDateStrings,
  } = props;
  const maxDuration = Math.max(...durations.flat());
  const unit = maxDuration > ms('1 hour') ? intl.formatMessage({ id: 'license_utilization.unit.hours' }) : intl.formatMessage({ id: 'license_utilization.unit.seconds' });
  const maxTotal = max(totals) ?? 0;
  const forceMinDuration = (2 / 100) * maxTotal;
  const xIndexHovered = useRef<number | undefined | null>(null);
  const chartRef = useRef(null);
  const resetColor = debounce(300, (chart: any) => {
    xIndexHovered.current = null;
    chart.update('none');
  });
  const data: ChartData = {
    labels: xLabels,
    datasets: productNames.map((f, i) => ({
      label: f,
      data: durations[i].map(d => (d === 0 ? 0 : Math.max((d / ms(`1 ${unit}`)), (forceMinDuration / ms(`1 ${unit}`) ?? 0)))),
      backgroundColor: (context: any) => (
        xIndexHovered.current === null || context.dataIndex === xIndexHovered.current
          ? colors[i]
          : alpha(colors[i], 0.3)),
      barThickness: 40,
      borderRadius: 0,
      borderWidth: {
        top: 2,
        bottom: 2,
      },
      borderColor: '#fff',
      hoverBorderColor: '#fff',
    })),
    // TODO borderWidth isn't compatible,
    // make sure that it works and remove the cast to any and/or add comment if any
  } as any;
  const options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 3,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: licenseUtilizationChartTooltip({
          productNames,
          percentageEachMonth,
          durations,
          theme,
          colors,
          startDateStrings,
          endDateStrings,
        }),
        callbacks: {
          label(tooltipContext) {
            return tooltipContext.dataIndex.toString();
          },
        },
      },
    },
    onHover: (_, activeElement, chart) => {
      const xIndex = activeElement[0]?.index;
      const nothingHoveredOld = xIndexHovered.current === undefined
        || xIndexHovered.current === null;
      const nothingHoveredNew = xIndex === undefined; // can't be null
      if (nothingHoveredOld && nothingHoveredNew) return;
      if (xIndex !== xIndexHovered.current) {
        xIndexHovered.current = xIndex;
        if (xIndex === undefined) {
          resetColor(chart);
        }
        chart.update('none');
      }
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          borderDash: [10, 2],
        },
        title: {
          display: true,
          text: intl.formatMessage(
            { id: 'license_utilization.duration' },
            { unit },
          ),
          padding: {
            bottom: 20,
          },
          font: {
            weight: 'bold',
          },
        },
      },
    },
  };

  useEffect(() => (
    () => {
      // @ts-ignore
      chartRef?.current?.update('none'); // update chart before unmounting to reset the tooltip
    }
  ), []);

  return (
    <Chart
      ref={chartRef}
      type="bar"
      data={data}
      options={options}
    />
  );
};

export default LicenseUsageStackedChart;
