import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import {
  OrganizationFeature,
  TestOpsPlanInterval,
} from '../../../../models';
import PlanPurchaseDetails from './PlanPurchaseDetails';
import K1Typography from '../../../../components/design-system/K1Typography';
import { PlanPrice } from '../../../../models/planPrice';
import { fromStudioSubscriptions } from '../../../../store';
import { useLaunchDarkly } from '../../../../launchdarkly';
import { getCycleFromPathParam } from '../../utils';

export interface PerUsKsPurchaseDetailsProps {
  accountId: number;
  initialQuota?: number;
  title: ReactElement;
  clearInitialQuota: Function;
}

const KsePurchaseDetails = (props: PerUsKsPurchaseDetailsProps) => {
  const {
    accountId,
    initialQuota,
    title,
    clearInitialQuota,
  } = props;
  const { flags } = useLaunchDarkly();
  const kseCycle = getCycleFromPathParam(OrganizationFeature.PER_USER_KSE);
  const planNameComponent = (
    <K1Typography variant="h3" fontWeight="medium">
      { title }
    </K1Typography>
  );

  const currentKSESubscription = useSelector(
    fromStudioSubscriptions.selectPaidSubscriptionByAccountId(
      Number(accountId),
      OrganizationFeature.PER_USER_KSE,
    ),
  )?.[0];

  const quotaConverter = (
    chosenQuota: number,
    currentSubscription?: any,
  ) => chosenQuota + (currentSubscription?.quota || 0);

  return (
    <PlanPurchaseDetails
      currentSubscription={currentKSESubscription}
      planNameComponent={planNameComponent}
      showPlanDescription
      accountId={accountId}
      organizationFeature={OrganizationFeature.PER_USER_KSE}
      initialQuota={initialQuota ?? 0}
      initialInterval={kseCycle
        || currentKSESubscription?.billingCycle || TestOpsPlanInterval.YEAR}
      monthlyPrice={flags?.subscriptionPlanPrice?.kseMonthly ?? PlanPrice.KSE_MONTHLY}
      annualyPrice={flags?.subscriptionPlanPrice?.kseAnnually ?? PlanPrice.KSE_ANNUALLY}
      minQuota={0}
      step={1}
      quotaConverter={quotaConverter}
      clearInitialQuota={clearInitialQuota}
    />
  );
};

export default KsePurchaseDetails;
