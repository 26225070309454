import { SsoOptionStatus, TestOpsSubscriptionSource, User } from '../../models';
import { OrganizationFeature } from '../../models/organizationFeature';
import { OrganizationRole } from '../../models/organizationRole';

export type Order = 'asc' | 'desc';

export interface UserTable extends User {
  licenseAccess: string[];
  orgRole: OrganizationRole;
  invitationLink?: string;
  invitedUserId?: number;
  isSelectable?: boolean;
  removalDate?: Date;
  removedByEmail?: string;
  joinDate?: Date;
  invitedBy?: string;
  type?: string; // currently support for k1,kit invitation
  hasSsoEnabled?: boolean; // use to render SSO tag in active user & pending invitation table
  ssoStatus?: SsoOptionStatus;
  invitedTestOpsUserId?: number;
  hasKseLicense?: boolean;
}

export const ROWS_PER_PAGE = 20;

export const unlimitedAssignableSubs = [
  OrganizationFeature.ENGINE,
  OrganizationFeature.UNLIMITED_ENGINE,
  OrganizationFeature.UNLIMITED_KSE,
];

export interface SubscriptionInvitation {
  feature: OrganizationFeature;
  quantity: number;
}

export interface AvailableSubscription {
  feature: OrganizationFeature;
  remainingLicenses: number;
  total: number;
  featureHalfFullName: string; // this field is for sorting
  source: TestOpsSubscriptionSource;
}

export interface LoginOptions {
  SSO_AUTH: boolean;
  BASIC_AUTH: boolean;
}

export const defaultLoginOptions: LoginOptions = {
  SSO_AUTH: true,
  BASIC_AUTH: true,
};
