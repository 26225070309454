import Checkbox from '@mui/material/Checkbox';
import ListSubheader from '@mui/material/ListSubheader';
import Popper, { PopperProps } from '@mui/material/Popper';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete, { AutocompleteProps, AutocompleteRenderGroupParams, createFilterOptions } from '@mui/material/Autocomplete';
import { useState, useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import NoResult from '../NoResult';

const useStyles = makeStyles(() => ({
  machineFilter: {
    width: '100%',
  },
  option: {
    '&[aria-selected="true"]': {
      backgroundColor: '#F0F8FF !important',
    },
  },
  listbox: {
    '&.MuiAutocomplete-listbox': {
      border: '1px solid #D5D8DD',
      borderRadius: '4px',
    },
  },
  noOptions: {
    border: '1px solid #D5D8DD',
    borderRadius: '4px',
    overflowX: 'hidden',
  },
}));

const PopperComponent = (props: PopperProps) => (
  <Popper
    {...props}
    placement="bottom-start"
    modifiers={[
      {
        name: 'preventOverflow',
        enabled: false,
        options: {
          altBoundary: true,
        },
      },
    ]}
  />
);

interface MachineFilterProps {
  onChange: (value: String[]) => void;
  defaultValue: String[];
  machineKeys: String[];
  disabled: boolean;
}

const renderGroup = (params: AutocompleteRenderGroupParams) => [
  <ListSubheader key={params.key} component="div" disableSticky>
    {params.group}
  </ListSubheader>,
  params.children,
];

const MachineFilter = (props: MachineFilterProps) => {
  const { onChange, defaultValue, machineKeys, disabled } = props;
  const classes = useStyles();
  const intl = useIntl();
  const [focused, setFocused] = useState(false);
  const customMethod = createFilterOptions<String>({ trim: true });
  /*
    For some reason, the focused state is not updated after the page loads,
    so another ref is used to keep track of this by comparing itself with the
    current active (focused) element
  */
  const filterInputRef = useRef<HTMLDivElement>(null);
  const handleMachineChange: AutocompleteProps<String, true, true, undefined>['onChange'] = (_, value) => {
    onChange(value);
  };
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon color="primary" fontSize="small" />;

  useEffect(() => {
    if (document.activeElement !== filterInputRef.current) {
      setFocused(false);
    } else {
      setFocused(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.activeElement, filterInputRef]);

  return (
    <Autocomplete
      key={`${defaultValue}`} // adding this would reinitialized the component with new defaultValue
      filterOptions={customMethod}
      className={classes.machineFilter}
      classes={{
        option: classes.option,
        listbox: classes.listbox,
        noOptions: classes.noOptions,
      }}
      disabled={disabled}
      multiple
      limitTags={1}
      size="small"
      id="machine-filter"
      options={Array.from(new Set([...defaultValue, ...machineKeys]))}
      defaultValue={defaultValue}
      disableCloseOnSelect
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onChange={handleMachineChange}
      getOptionLabel={machineKey => machineKey.toString()}
      clearText={intl.formatMessage({ id: 'license_utilization.machine_filter.clear_all' })}
      noOptionsText={<NoResult messages={[intl.formatMessage({ id: 'license_utilization.machine_filter.no_results_found' })]} />}
      PopperComponent={PopperComponent}
      groupBy={option => (
        (defaultValue.find(it => it === option)
          ? intl.formatMessage({ id: 'license_utilization.filter.selected' }, { count: defaultValue.length })
          : intl.formatMessage({ id: 'license_utilization.filter.all' })).toUpperCase()
      )}
      renderGroup={renderGroup}
      renderOption={(props, machineKey, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            checked={selected}
          />
          <Typography variant="body2">
            {machineKey}
          </Typography>
        </li>
      )}
      renderInput={params => (
        <TextField
          {...params}
          inputRef={filterInputRef}
          variant="outlined"
          label={intl.formatMessage({ id: 'license_utilization.machine_filter.machine_id' })}
          placeholder={
            defaultValue.length === 0
              ? intl.formatMessage({ id: 'license_utilization.machine_filter.enter_machine_id' })
              : undefined
          }
        />
      )}
      open={focused}
      {...(!focused ? {} : { renderTags: () => null })}
    />
  );
};

export default MachineFilter;
