import { parse } from 'querystring';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { QueryDictionary } from './useNavigate';

export const useQuery = <T = any>() => {
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const get = useCallback((name: string) => query.get(name), [query]);
  const getAll = useCallback((name: string) => query.getAll(name), [query]);
  const clear = useCallback((name: string) => {
    query.delete(name);
  }, [query]);
  const queryDictionary = useCallback(
    () => parse(query.toString()) as QueryDictionary<T>,
    [query],
  );
  const queryString = useCallback(() => query.toString(), [query]);

  return useMemo(() => ({
    get,
    getAll,
    clear,
    queryDictionary,
    queryString,
  }), [get, getAll, clear, queryDictionary, queryString]);
};
