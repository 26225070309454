import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import { AppState } from '.';
import { QuoteInformation, SerializedException } from '../models/index';
import { TestCloudQuote } from '../services';

export const TESTCLOUD_QUOTE_KEY = 'testCloudQuote';

interface TestCloudQuoteState extends EntityState<QuoteInformation> {
  loading: boolean;
  errors: SerializedException[];
  count: number;
}

const testCloudQuoteAdapter = createEntityAdapter<QuoteInformation>();
const createInitialState = ():
TestCloudQuoteState => testCloudQuoteAdapter.getInitialState({
  loading: false,
  count: 0,
  errors: [],
});
export const doCreateTestCloudQuote = createAsyncThunk(
  'testCloudQuotes/createQuote',
  async (data: Parameters<typeof TestCloudQuote['createTestCloudQuote']>[0]) => {
    const response = await TestCloudQuote.createTestCloudQuote(data);
    return response;
  },
);

const testCloudQuoteOrdersSlice = createSlice({
  name: TESTCLOUD_QUOTE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doCreateTestCloudQuote.pending, state => {
      state.loading = true;
      state.errors = [];
    });
    builder.addCase(
      doCreateTestCloudQuote.fulfilled,
      (state, action) => {
        state.loading = false;
        testCloudQuoteAdapter.setAll(state, action.payload.data);
      },
    );
    builder.addCase(doCreateTestCloudQuote.rejected, state => {
      state.loading = false;
    });
  },
});

const selectTestCloudSubscriptions = (state: AppState) => state[TESTCLOUD_QUOTE_KEY];

export const {
  selectAll: selectAllTestCloudQuoteOrders,
  selectIds,
  selectEntities,
} = testCloudQuoteAdapter.getSelectors(selectTestCloudSubscriptions);

export default testCloudQuoteOrdersSlice.reducer;
