import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as CircleIllus } from '../../../icons/circleIllus.svg';

const useStyles = makeStyles((theme => ({
  rootContainer: {
    position: 'relative',
  },
  informationContainer: {
    width: '100%',
    borderRadius: theme.spacing(0.5, 0.5, 0, 0),
    backgroundColor: '#ecf4f2',
    paddingLeft: theme.spacing(4),
    display: 'flex',
  },
  textPlatform: {
    color: 'white',
    backgroundColor: '#1847CA',
    borderRadius: 50,
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0, 2),
    display: 'inline-block',
    textAlign: 'center',
  },
  description: {
    fontSize: 24,
    fontWeight: 700,
    margin: theme.spacing(1, 0, 1, 0),
    lineHeight: 1.3,
  },
  illustration: {
    borderRadius: theme.spacing(0, 1, 0, 0),
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
  },
})));

interface PromotionBannerProps {
  title: string,
  percent: string
}

const PromotionBanner = (props: PromotionBannerProps) => {
  const classes = useStyles();
  const {
    title,
    percent,
  } = props;

  return (
    <div id="promotion-banner" className={classes.rootContainer}>
      <div className={classes.informationContainer}>
        <p className={classes.description}>
          <FormattedMessage id={title} />
          {
            percent !== ''
            && (<span className={classes.textPlatform}><FormattedMessage id={percent} /></span>)
          }
        </p>
      </div>
      <CircleIllus className={classes.illustration} />
    </div>
  );
};

export default PromotionBanner;
