import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl';
import { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Navigate } from 'react-router-dom';
import {
  fromAccounts,
  fromAuth,
  fromStudioSubscriptions,
  fromSubscriptions,
  fromTestCloudSubscriptions,
  fromVisualTestingSubscriptions,
  useAppDispatch,
} from '../../../store';
import {
  fromExistingAccounts,
  fromInvoices,
  fromOrder,
  fromOrganizations,
  fromStarterPackage,
  fromTestOpsPlatformConfiguration,
  fromTestOpsPlatformSubscriptions,
  fromTestOpsSubscriptions,
  fromTestResultCount,
} from '../../../store/rootReducer';
import { forbidden, useQuery } from '../../../routes';
import { EXISTED_SUBSCRIPTION_TESTOPS_PLATFORM } from '../../../store/testOpsPlatformSubscriptionsSlice';
import LoadingProgress from '../../../layout/LoadingProgress';
import { REACTIVATE_RECURLY_SUBSCRIPTION_INVALID, RECURLY_SUBSCRIPTION_INVALID } from '../../../store/subscriptionsSlice';
import { useLaunchDarkly } from '../../../launchdarkly';
import YourSubscriptionSection from './subscription-info-section/YourSubscriptionSection';
import SingleProductsOfferingSection from './katalon-offering-section/SingleProductsOfferingSection';
import StarterPlanOfferingSection from './katalon-offering-section/StarterPlanOfferingSection';
import PriceSummarySection from './katalon-offering-section/PriceSummarySection';
import { OrganizationFeature } from '../../../models';
import OrderDetailSection from './order-section/OrderDetailSection';
import NoActiveSubscriptionsSection from './subscription-info-section/NoActiveSubscriptionsSection';
import { getQuotaFromPathParam } from '../utils';
import DirectToSelfServePromotionSection from './direct-to-selfserve-promotion-section/DirectToSelfServePromotionSection';
import RetryPayment from './retry-payment-section/RetryPayment';

const useStyles = makeStyles((theme => ({
  root: {
    width: '100%',
    height: '100%',
    borderRadius: '.5rem',
    padding: '1.5rem',
    overflowY: 'auto',
    backgroundColor: '#F9FBFF',
  },
  productTitle: {
    padding: theme.spacing(0, 2),
    marginBottom: theme.spacing(3),
    fontWeight: '600',
    fontSize: theme.spacing(3),
  },
  choosePlanContainer: {
    margin: theme.spacing(0, 2, 4),
    padding: 0,
  },
  cardTitle: {
    margin: theme.spacing(3, 0, 2),
    fontWeight: theme.typography.fontWeightBold,
    color: '#313B58',
    fontSize: 16,
  },
  icon: {
    marginRight: 8,
  },
  card: {
    display: 'flex',
  },
  standalonePurchase: {
    padding: theme.spacing(0, 2),
    marginBottom: theme.spacing(1.5),
  },
  warningBanner: {
    marginTop: theme.spacing(2),
  },
})));

const AccountSubscriptionManagement = () => {
  const classes = useStyles();
  const { get } = useQuery();
  const { enqueueSnackbar } = useSnackbar();

  const { flags } = useLaunchDarkly();

  const [isLoading, setLoading] = useState(true);

  const accountId = Number(get('accountId'));

  const [initialQuotas, setInitialQuotas] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      initialPlatformQuota: getQuotaFromPathParam(
        OrganizationFeature.TESTOPS_PLATFORM,
      ),
      initialKseQuota: getQuotaFromPathParam(
        OrganizationFeature.PER_USER_KSE,
      ),
      initialKreQuota: getQuotaFromPathParam(
        OrganizationFeature.UNLIMITED_ENGINE,
      ),
      initialTestCloudQuota: getQuotaFromPathParam(
        OrganizationFeature.TESTCLOUD_SESSION_WEB,
      ),
      initialTestCloudDesktopQuota: getQuotaFromPathParam(
        OrganizationFeature.TESTCLOUD_SESSION_WEB_DESKTOP,
      ),
      initialTestCloudCrossBrowserQuota: getQuotaFromPathParam(
        OrganizationFeature.TESTCLOUD_SESSION_CROSS_BROWSER,
      ),
      initialTestCloudMobileQuota: getQuotaFromPathParam(
        OrganizationFeature.TESTCLOUD_SESSION_MOBILE_APP,
      ),
      initialVisualTestingQuota: getQuotaFromPathParam(
        OrganizationFeature.VISUAL_TESTING_PRO,
      ),
    },
  );

  const user = useSelector(fromAuth.selectUser);
  const dispatch = useAppDispatch();
  const errors = useSelector(fromTestOpsPlatformSubscriptions.selectErrors());
  const subscriptionErrors = useSelector(fromSubscriptions.selectErrors());
  const pendingOrder = useSelector(fromOrder.selectPendingOrder);
  const organizations = useSelector(fromOrganizations.selectAllByAccountId(accountId));
  const orgId = isEmpty(organizations) ? 0 : organizations[0].id;
  const isApplyStarterPackage = useSelector(fromStarterPackage.selectIsApplyStarterPackage);
  const freeTestResultQuota = useSelector(fromAccounts.selectFreeTestResultQuota);
  const isOptInToSelfServe = useSelector(fromAccounts.selectIsOptInToSelfServe);
  const pastDueInvoices = useSelector(fromInvoices.selectPastDueInvoices);

  const hasPlatformSubscription = useSelector(
    fromTestOpsPlatformSubscriptions.selectPaidTestOpsPlatformSubscriptionByAccountId(
      Number(accountId),
    ),
  ).length !== 0;
  const hasTrialTOSubscription = useSelector(
    fromTestOpsPlatformSubscriptions.selectTrialTestOpsPlatformSubscriptionByAccountId(
      Number(accountId),
    ),
  ).length !== 0;
  const hasKSESubscription = useSelector(
    fromStudioSubscriptions.selectByAccountIdAndFeature(
      Number(accountId),
      OrganizationFeature.PER_USER_KSE,
    ),
  ).length !== 0;
  const hasKRESubscription = useSelector(
    fromStudioSubscriptions.selectByAccountIdAndFeature(
      Number(accountId),
      OrganizationFeature.UNLIMITED_ENGINE,
    ),
  ).length !== 0;
  const hasTestCloudSubscriptions = useSelector(
    fromTestCloudSubscriptions.selectByAccountId(
      Number(accountId),
    ),
  ).length !== 0;
  const hasActivePaidTestCloudSubscriptions = useSelector(
    fromTestCloudSubscriptions.selectHaveActivePaidTestCloudSubscription(
      Number(accountId),
    ),
  );

  const hasCurrentSubscription = hasPlatformSubscription || hasTrialTOSubscription
    || hasKSESubscription || hasKRESubscription || hasTestCloudSubscriptions;

  useEffect(() => {
    if (errors && errors[0]) {
      switch (errors[0].message) {
        case EXISTED_SUBSCRIPTION_TESTOPS_PLATFORM:
          enqueueSnackbar(
            <FormattedMessage id="subscription.testops_platform.error.existed" />,
            {
              variant: 'error',
            },
          );
          break;
        default:
          break;
      }
    }
  }, [errors, enqueueSnackbar]);

  useEffect(() => {
    if (subscriptionErrors && subscriptionErrors[0]) {
      switch (subscriptionErrors[0].message) {
        case RECURLY_SUBSCRIPTION_INVALID:
          enqueueSnackbar(
            <FormattedMessage id="subscription.testops_platform.recurly_subscription.invalid" />,
            {
              variant: 'error',
            },
          );
          break;
        case REACTIVATE_RECURLY_SUBSCRIPTION_INVALID:
          enqueueSnackbar(
            <FormattedMessage
              id="subscription.testops_platform.reactivate.error"
              values={{
                br: <br />,
              }}
            />,
            {
              variant: 'error',
            },
          );
          break;
        default:
          break;
      }
    }
  }, [subscriptionErrors, enqueueSnackbar]);

  const triggerResetState = () => {
    dispatch(fromInvoices.doResetPreview());
    dispatch(fromInvoices.doResetRetryPaymentStatus());
    dispatch(fromStarterPackage.doResetStarterPackage());
    dispatch(fromTestOpsPlatformSubscriptions.doResetEntity());
    dispatch(fromStudioSubscriptions.doResetEntity());
    dispatch(fromTestCloudSubscriptions.doResetEntity());
    dispatch(fromVisualTestingSubscriptions.doResetEntity());
    dispatch(fromSubscriptions.doReset());
    dispatch(fromOrder.doGetPendingOrder(accountId));
  };

  const fetchData = async () => {
    await Promise.all([
      dispatch(fromTestOpsPlatformConfiguration.doGetTestOpsPlatformConfiguration()),
      dispatch(fromTestOpsPlatformSubscriptions.doGetAllTestOpsPlatformSubscriptionsByAccountId({
        accountId,
        checkHasPremierSuccess: true,
      })),
      dispatch(fromStudioSubscriptions.doGetActiveByAccountId({
        accountId,
        checkHasPremierSuccess: true,
      })),
      dispatch(fromTestCloudSubscriptions.doGetByAccountId({
        accountId,
        checkHasPremierSuccess: true,
      })),
      dispatch(fromVisualTestingSubscriptions.doGetActiveByAccountId({
        accountId,
        checkHasPremierSuccess: true,
      })),
      dispatch(fromExistingAccounts.doGetAccountByAccountId({ accountId })),
      dispatch(fromTestOpsSubscriptions.doGetActiveByAccountId({ accountId })),
      dispatch(fromStarterPackage.doGetAccountStarterPackage(accountId)),
      dispatch(fromAccounts.doGetFreeTestResultQuota(accountId)),
      dispatch(fromTestCloudSubscriptions
        .doGetAccountTestCloudSubscriptionsOrgLevel({
          accountId: Number(accountId),
          checkHasPremierSuccess: true,
        })),
      dispatch(fromOrganizations.doFetchOrganizationByAccountId({ accountId })),
      (flags?.directEcomTransition?.directEcomTransitionEnabled
        && flags?.directEcomTransition.accountWhiteList.find(accId => accId === accountId)
        && dispatch(fromAccounts.doCheckOptInSelfServe(accountId))),
      (flags?.subscriptionRetryPayment
        && dispatch(fromInvoices.doFetchPastDueInvoice(accountId))),
    ]).finally(() => {
      setLoading(false);
    });
  };

  const clearInitialQuota = (feature: OrganizationFeature) => {
    switch (feature) {
      case OrganizationFeature.TESTOPS_PLATFORM: {
        if (initialQuotas.initialPlatformQuota !== undefined) {
          setInitialQuotas({ initialPlatformQuota: 0 });
        }
        break;
      }
      case OrganizationFeature.PER_USER_KSE: {
        if (initialQuotas.initialKseQuota !== undefined) {
          setInitialQuotas({ initialKseQuota: 0 });
        }
        break;
      }
      case OrganizationFeature.UNLIMITED_ENGINE: {
        if (initialQuotas.initialKreQuota !== undefined) {
          setInitialQuotas({ initialKreQuota: 0 });
        }
        break;
      }
      case OrganizationFeature.TESTCLOUD_SESSION_WEB: {
        if (initialQuotas.initialTestCloudQuota !== undefined) {
          setInitialQuotas({ initialTestCloudQuota: 0 });
        }
        break;
      }
      case OrganizationFeature.TESTCLOUD_SESSION_WEB_DESKTOP: {
        if (initialQuotas.initialTestCloudDesktopQuota !== undefined) {
          setInitialQuotas({ initialTestCloudDesktopQuota: 0 });
        }
        break;
      }
      case OrganizationFeature.TESTCLOUD_SESSION_CROSS_BROWSER: {
        if (initialQuotas.initialTestCloudCrossBrowserQuota !== undefined) {
          setInitialQuotas({ initialTestCloudCrossBrowserQuota: 0 });
        }
        break;
      }
      case OrganizationFeature.TESTCLOUD_SESSION_MOBILE_APP: {
        if (initialQuotas.initialTestCloudMobileQuota !== undefined) {
          setInitialQuotas({ initialTestCloudMobileQuota: 0 });
        }
        break;
      }
      case OrganizationFeature.VISUAL_TESTING_PRO: {
        if (initialQuotas.initialVisualTestingQuota !== undefined) {
          setInitialQuotas({ initialVisualTestingQuota: 0 });
        }
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    const fetchInfo = async () => {
      if (!orgId) return;
      await dispatch(fromTestResultCount.doGetLatestTestResultCount(+orgId));
    };
    fetchInfo().catch(() => {});
  }, [orgId]);

  useEffect(() => {
    const fetchInfo = async () => {
      if (!accountId) return;
      setLoading(true);
      triggerResetState();
      await fetchData();
    };
    fetchInfo().catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  if (flags && flags.subscriptionAndPaymentMethodDisabled === true) {
    return <Navigate to={forbidden.path} replace />;
  }

  const showDirectToSelfServeSection = () => {
    if (!hasPlatformSubscription && !hasKSESubscription
      && !hasKRESubscription && !hasActivePaidTestCloudSubscriptions) {
      return false;
    }
    if (!flags
        || !flags?.directEcomTransition?.directEcomTransitionEnabled
        || isOptInToSelfServe) {
      return false;
    }
    return flags.directEcomTransition.accountWhiteList.find(accId => accId === accountId);
  };

  return (
    <>
      { isLoading ? <LoadingProgress /> : (
        <div className={classes.root}>
          <Typography variant="h2" className={classes.productTitle}>
            <FormattedMessage id="subscriptions.testops_platform.subscription_management" />
          </Typography>

          {/* Retry last failed payment section */}
          {flags?.subscriptionRetryPayment
            && pastDueInvoices.length > 0
            && !pendingOrder
            && (
              <RetryPayment accountId={accountId} />
            )}

          {/* Direct customer transition to eCom section  */}
          {showDirectToSelfServeSection()
            && (
              <DirectToSelfServePromotionSection
                accountId={accountId}
              />
            )}

          {/* pending order information */}
          {pendingOrder
          && (
            <OrderDetailSection
              accountId={accountId}
              pendingOrder={pendingOrder}
            />
          )}

          {/* Current subscription information */}
          {
            !hasCurrentSubscription && freeTestResultQuota === 0 ? (
              <NoActiveSubscriptionsSection />
            ) : (
              <YourSubscriptionSection
                accountId={accountId}
                orgId={orgId}
                user={user}
                isLoading={isLoading}
              />
            )
          }

          {/* Katalon Starter Package Offering */}
          {isApplyStarterPackage
            && (
            <StarterPlanOfferingSection
              accountId={Number(accountId)}
            />
            )}

          {/* Katalon Single Product Offering */}
          <SingleProductsOfferingSection
            accountId={accountId}
            orgId={orgId}
            initialPlatformQuota={initialQuotas.initialPlatformQuota}
            initialKseQuota={initialQuotas.initialKseQuota}
            initialKreQuota={initialQuotas.initialKreQuota}
            initialTestCloudQuota={initialQuotas.initialTestCloudQuota}
            initialTestCloudDesktopQuota={initialQuotas.initialTestCloudDesktopQuota}
            initialTestCloudCrossBrowserQuota={initialQuotas.initialTestCloudCrossBrowserQuota}
            initialTestCloudMobileQuota={initialQuotas.initialTestCloudMobileQuota}
            initialVisualTestingQuota={initialQuotas.initialVisualTestingQuota}
            clearInitialQuota={clearInitialQuota}
            isApplyStarterPackage={isApplyStarterPackage}
          />

          {/* Summary the total net price */}
          <PriceSummarySection
            accountId={accountId}
          />
        </div>
      )}
    </>
  );
};

export default AccountSubscriptionManagement;
