import { Theme, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import DurationUnitFormat from 'intl-unofficial-duration-unit-format';
import { renderToString } from 'react-dom/server';
import { chartTooltip } from '../../../../components/chart-tooltip/chartTooltip';

const options = {
  style: DurationUnitFormat.styles.NARROW,
  format: '{hour} {seconds}',
};
const intlDuration = new DurationUnitFormat('en', options);
const useStylesWithTheme = (theme: Theme) => makeStyles(() => ({
  root: {
    fontSize: theme.spacing(1.75),
    borderRadius: theme.spacing(0.5),
    color: theme.palette.background.paper,
    margin: theme.spacing(1, 2),
    borderCollapse: 'collapse',
  },
  leftColumn: {
    textAlign: 'right',
    fontWeight: theme.typography.fontWeightRegular,
    padding: theme.spacing(0.5),
  },
  rightColumn: {
    padding: theme.spacing(0.5),
    textAlign: 'left',
    fontWeight: theme.typography.fontWeightBold,
  },
}));
interface TooltipContentProps {
  productNames: string[];
  durations: number[];
  percentages: number[];
  colors: string[];
  theme?: Theme;
  startDateString?: string;
  endDateString?: string;
}
interface ChartTooltipData {
  productNames: string[];
  colors: string[];
  theme: Theme;
  durations: number[][];
  percentageEachMonth: number[][];
  startDateStrings: string[];
  endDateStrings: string[];
}

// Custom chart's tooltip function
export const licenseUtilizationChartTooltip = (data: ChartTooltipData) => chartTooltip(
  (tooltipModel: any) => {
    const index = +(tooltipModel.body[0]?.lines?.[0] ?? 0).toString();
    const {
      productNames,
      durations,
      colors,
      percentageEachMonth,
      startDateStrings,
      endDateStrings,
    } = data;
    const customizedContentTooltip = (
      <CustomizedContentTooltip
        productNames={[...productNames].reverse()}
        colors={[...colors].reverse()}
        durations={durations.map(it => it[index]).reverse()}
        percentages={percentageEachMonth.map(it => it[index]).reverse()}
        startDateString={startDateStrings[index]}
        endDateString={endDateStrings[index]}
      />
    );
    return renderToString(customizedContentTooltip);
  },
  {
    adjustRightWhenCursorInRightHalf: 20,
    adjustLeftWhenCursorInLeftHalf: 20,
    adjustTopWhenCursorInTopHalf: -30,
    adjustTopWhenCursorInBottomHalf: -100,
  },
  data.theme,
);

const CustomizedContentTooltip = (props: TooltipContentProps) => {
  const {
    productNames,
    durations,
    percentages,
    colors,
    startDateString,
    endDateString,
    theme: alternativeTheme,
  } = props;
  const theme = useTheme();
  const classes = useStylesWithTheme(alternativeTheme ?? theme)();
  return (
    <table className={classes.root}>
      <tbody>
        {(startDateString || endDateString) && (
          <tr>
            <td className={classes.leftColumn}>
              Period:
            </td>
            <td className={classes.rightColumn}>
              {`${startDateString}${endDateString?.length ? ` - ${endDateString}` : ''}`}
            </td>
          </tr>
        )}
        <tr>
          <td className={classes.leftColumn}>
            {/* since React intl can't be used in renderToString method,
                hard code total text here */}
            Total:
          </td>
          <td className={classes.rightColumn}>
            {intlDuration.format(durations.reduce((acc, duration) => acc + duration) / 1000)}
          </td>
        </tr>
        {
          productNames.map((name: string, index: number) => (
            <tr key={name}>
              <td className={classes.leftColumn}>
                {`${name}:`}
              </td>
              <td className={classes.rightColumn} style={{ color: colors[index] }}>
                {`${intlDuration.format(durations[index] / 1000)} (${percentages[index]}%)`}
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  );
};

export default CustomizedContentTooltip;
