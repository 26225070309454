import { alpha, useTheme } from '@mui/material/styles';
import { ChartData, ChartOptions, Plugin } from 'chart.js';
import ms from 'ms';
import { useEffect, useRef } from 'react';
import { Chart } from 'react-chartjs-2';
import { useIntl } from 'react-intl';

import { licenseUtilizationChartTooltip } from '../CustomizedContentTooltip';

interface LicenseUsageLinearChartProps {
  xLabels: string[];
  productNames: string[];
  colors: string[];
  durations: number[][];
  percentageEachMonth: number[][];
  startDateStrings: string[];
  endDateStrings: string[];
}
// custom tooltips: https://github.com/reactchartjs/react-chartjs-2/issues/151#issuecomment-316939204;
// https://www.chartjs.org/docs/latest/configuration/tooltip.html#external-custom-tooltips
// render to string: https://stackoverflow.com/a/56984442
const LicenseUsageLinearChart = (props: LicenseUsageLinearChartProps) => {
  const intl = useIntl();
  const {
    xLabels,
    productNames,
    colors,
    percentageEachMonth,
    durations,
    startDateStrings,
    endDateStrings,
  } = props;
  const maxDuration = Math.max(...durations.flat());
  const unit = maxDuration > ms('1 hour') ? intl.formatMessage({ id: 'license_utilization.unit.hours' }) : intl.formatMessage({ id: 'license_utilization.unit.seconds' });
  const theme = useTheme();
  const xIndexHovered = useRef<number | undefined>(undefined);
  const chartRef = useRef();
  const data: ChartData = {
    labels: xLabels,
    datasets: productNames.map((f, i) => ({
      label: f,
      data: durations[i].map(d => d / ms(`1 ${unit}`)),
      fill: false,
      backgroundColor: (context: any) => (
        xIndexHovered.current === undefined || context.datasetIndex === xIndexHovered.current
          ? colors[i]
          : alpha(colors[i], 0.2)),
      borderColor: (context: any) => (
        xIndexHovered.current === undefined || context.datasetIndex === xIndexHovered.current
          ? colors[i]
          : alpha(colors[i], 0.2)),
    })),
  };
  const plugins: Plugin[] = [{
    id: 'mouseOutHandler',
    afterEvent(chart, args) {
      if (args.event.type === 'mouseout') {
        xIndexHovered.current = undefined;
        chart.update('none');
      }
    },
  }];
  const options: ChartOptions = {
    font: {
      family: theme.typography.fontFamily,
      size: theme.typography.fontSize,
    },
    hover: {
      mode: 'dataset',
    },
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 3,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: licenseUtilizationChartTooltip({
          productNames,
          percentageEachMonth,
          durations,
          theme,
          colors,
          startDateStrings,
          endDateStrings,
        }),
        displayColors: false,
        callbacks: {
          label(tooltipContext) {
            return tooltipContext.dataIndex.toString();
          },
        },
      },
    },
    onHover(_, activeElement, chart) {
      const xIndex = activeElement[0]?.datasetIndex;
      if (xIndex !== xIndexHovered.current) {
        xIndexHovered.current = xIndex;
        chart.update('none');
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        offset: true,
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: intl.formatMessage(
            { id: 'license_utilization.duration' },
            { unit },
          ),
          padding: {
            bottom: 20,
          },
          font: {
            weight: `${theme.typography.fontWeightBold}`,
          },
        },
        grid: {
          display: true,
          borderDash: [10, 2],
        },
      },
    },
    elements: {
      point: {
        radius: 3,
      },
      line: {
        borderWidth: 1,
      },
    },
  };
  useEffect(() => (
    () => {
      // @ts-ignore
      chartRef?.current?.update('none'); // update chart after unmounting to reset the tooltip
    }
  ), []);

  return <Chart ref={chartRef} type="line" data={data} options={options} plugins={plugins} />;
};
export default LicenseUsageLinearChart;
