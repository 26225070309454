import Axios from 'axios';
import { AccountStarterPackage } from '../models/accountStarterPackage';
import { StarterPackage } from '../models';

export const getAccountStartPackage = async (accountId: number) => (
  await Axios.get<AccountStarterPackage>('/v1/starter-package/get-account-starter-package', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    params: {
      account_id: accountId,
    },
    data: {},
  })
).data;

export const getUpgradableStartPackages = async (accountId: number) => (
  await Axios.get< Map<string, Array<StarterPackage>>>('/v1/starter-package/get-upgradable-starter-packages', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    params: {
      account_id: accountId,
    },
    data: {},
  })
).data;
