import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements } from '@stripe/react-stripe-js';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import BankTransferIcon from '@mui/icons-material/AccountBalance';
import React, { useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { Exception, Organization, PaymentMethodType } from '../../models';
import NewCardInformation from './NewCardInformation';
import { fromAccounts, fromPaymentMethod } from '../../store/rootReducer';
import visa from '../icons/payment-cards/visa.svg';
import jcb from '../icons/payment-cards/jcb.svg';
import masterCard from '../icons/payment-cards/master-card.svg';
import dinersClub from '../icons/payment-cards/diners-club.svg';
import discover from '../icons/payment-cards/discover.svg';
import americanExpress from '../icons/payment-cards/american-express.svg';
import unionPay from '../icons/payment-cards/union-pay.svg';
import { useAppDispatch } from '../../store';
import {
  DefaultPaymentMethodTooltip,
} from '../account/testops-platform-checkout/payment-information-section/PaymentMethodComponent';
import { ReactComponent as InfoCircle } from '../icons/info-circle-white.svg';

const useStyles = makeStyles(theme => ({
  paymentMethodTitle: {
    display: 'flex',
  },
  bankTransferSection: {
    marginBottom: theme.spacing(1),
  },
  paymentMethodLabel: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 20,
    marginTop: theme.spacing(0.75),
    marginLeft: theme.spacing(2),
  },
  paymentMethodIcon: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    display: 'flex',
    padding: theme.spacing(1),
    marginTop: theme.spacing(0.25),
    borderRadius: theme.spacing(0.5),
    border: 'solid 1px #bec1cd',
  },
  seperateLine: {
    height: 1,
    backgroundColor: '#eceef5',
    width: '100%',
    marginBottom: theme.spacing(2.5),
  },
  dialogPaper: {
    minWidth: 600,
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  savedCard: {
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(1.5),
  },
  savedCardLogo: {
    minWidth: theme.spacing(5.5),
    height: theme.spacing(3.5),
    borderRadius: '4px',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1.5),
    marginLeft: theme.spacing(3),
  },
  savedCardInfo: {
    minWidth: theme.spacing(20),
    textTransform: 'capitalize',
    marginTop: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(2.5),
    marginRight: theme.spacing(1.5),
  },
  savedCardExpiration: {
    minWidth: theme.spacing(17),
    marginTop: theme.spacing(1.25),
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(2),
    marginRight: theme.spacing(1.5),
    color: '#808b9a',
  },
  buttonGroup: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  chip: {
    display: 'flex',
    flexDirection: 'row',
    width: theme.spacing(15),
    height: theme.spacing(3),
    backgroundColor: '#1847ca',
    borderRadius: theme.spacing(0.75),
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  chipLabelContainer: {
    display: 'flex',
  },
  chipLabel: {
    fontWeight: theme.typography.fontWeightLight,
    color: '#fff',
    fontSize: 14,
    marginTop: theme.spacing(0.25),
  },
  chipIcon: {
    display: 'flex',
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(0.75),
  },
}));

interface CardBrand {
  src: string;
  brand: string;
}

const CARD_BRAND_LIST: CardBrand[] = [
  {
    src: visa,
    brand: 'visa',
  },
  {
    src: jcb,
    brand: 'jcb',
  },
  {
    src: masterCard,
    brand: 'mastercard',
  },
  {
    src: dinersClub,
    brand: 'dinersclub',
  },
  {
    src: discover,
    brand: 'discover',
  },
  {
    src: americanExpress,
    brand: 'americanexpress',
  },
  {
    src: unionPay,
    brand: 'unionpay',
  },
];

const DefaultPaymentMethod = (props: Required<Pick<Organization, 'accountId'>>) => {
  const { accountId } = props;
  const intl = useIntl();
  const elements = useElements();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const currentDefaultPaymentMethod = useSelector(fromPaymentMethod.selectDefaultPaymentMethod);
  const currentCard = useSelector(fromPaymentMethod.selectOneByAccountId(Number(accountId)));
  const account = useSelector(fromAccounts.selectAccountById(Number(accountId)));
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [defaultPaymentMethodDialogOpen, setDefaultPaymentMethodDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCardFormOpen, setIsCardFormOpen] = useState(!currentCard);
  const [selectedDefaultPaymentMethod, setSelectedDefaultPaymentMethod] = useState(
    currentDefaultPaymentMethod,
  );

  useEffect(() => {
    setIsCardFormOpen(!currentCard);
  }, [currentCard]);

  const openDefaultPaymentMethodDialog = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDefaultPaymentMethod(event.target.value as PaymentMethodType);
    setDefaultPaymentMethodDialogOpen(true);
  };

  const handleCancelDefaultPaymentMethod = () => {
    setSelectedDefaultPaymentMethod(currentDefaultPaymentMethod);
    setDefaultPaymentMethodDialogOpen(false);
  };

  const handleUpdateDefaultPaymentMethod = async () => selectedDefaultPaymentMethod
    && updateDefaultPaymentMethod(selectedDefaultPaymentMethod);

  const updateDefaultPaymentMethod = async (paymentMethod: PaymentMethodType) => {
    setLoading(true);
    await dispatch(fromPaymentMethod.doUpdateDefaultPaymentMethod({
      accountId,
      defaultPaymentMethod: paymentMethod,
    }))
      .then(unwrapResult)
      .then(() => {
        setDefaultPaymentMethodDialogOpen(false);
        enqueueSnackbar(<FormattedMessage id="billinginfo.default_payment_method.change.success" />, { variant: 'success' });
      })
      .catch((ex: Exception) => enqueueSnackbar(<span>{ex.message}</span>, { variant: 'error' }))
      .finally(() => setLoading(false));
  };

  const getSavedPaymentCardLogo = () => {
    const cardBrand = CARD_BRAND_LIST.find(it => it.brand === currentCard?.brand?.toLowerCase());
    return cardBrand ? (
      <img
        src={cardBrand.src}
        className={classes.savedCardLogo}
        alt="current-logo"
      />
    ) : null;
  };

  const handleCancel = () => {
    setIsCardFormOpen(true);
    elements?.getElement(CardNumberElement)?.clear();
    elements?.getElement(CardExpiryElement)?.clear();
    elements?.getElement(CardCvcElement)?.clear();
  };

  const handleCloseDeleteDialog = () => setIsDeleteDialogOpen(false);

  const handleDeletePaymentMethod = async () => {
    if (!currentCard) return;
    setLoading(true);
    await dispatch(fromPaymentMethod.doDeletePaymentMethod({
      id: currentCard.id,
    })).then(unwrapResult)
      .then(() => {
        handleCancel();
        handleCloseDeleteDialog();
        updateDefaultPaymentMethod(PaymentMethodType.BANK_TRANSFER);
        enqueueSnackbar(<FormattedMessage id="billinginfo.cardinfo.delete.success" />, { variant: 'success' });
      })
      .catch((ex: Exception) => enqueueSnackbar(<span>{ex.message}</span>, { variant: 'error' }))
      .finally(() => setLoading(false));
  };

  const renderDefaultPaymentMethodChip = () => (
    <Chip
      id="default.payment.method"
      className={classes.chip}
      label={(
        <div className={classes.chipLabelContainer}>
          <span className={classes.chipLabel}>
            <FormattedMessage id="billinginfo.payment_method.default" />
          </span>
          <span className={classes.chipIcon}>
            <DefaultPaymentMethodTooltip
              arrow
              placement="top"
              title={intl.formatMessage({ id: 'billinginfo.payment_method.default.tooltip' })}
            >
              <InfoCircle />
            </DefaultPaymentMethodTooltip>
          </span>
        </div>
      )}
    />
  );
  const renderSelectDefaultPaymentMethodDialog = () => (
    <Dialog
      open={defaultPaymentMethodDialogOpen}
      onClose={() => setDefaultPaymentMethodDialogOpen(false)}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle>
        <FormattedMessage
          id="billinginfo.default_payment_method.dialog.title"
          values={{
            paymentMethod: intl.formatMessage({ id: `billinginfo.payment_method.${selectedDefaultPaymentMethod}` }),
          }}
        />
      </DialogTitle>
      <DialogContent>
        <Typography>
          <FormattedMessage
            id="billinginfo.default_payment_method.dialog.content"
            values={{ accountName: account?.name }}
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancelDefaultPaymentMethod}
          variant="text"
          disabled={loading}
          sx={{
            color: '#1847ca',
            fontSize: 14,
            fontWeight: '500',
          }}
        >
          <FormattedMessage id="billinginfo.default_payment_method.dialog.cancel" />
        </Button>
        <Button
          onClick={handleUpdateDefaultPaymentMethod}
          variant="contained"
          color="primary"
          disabled={loading}
          sx={{
            backgroundColor: '#1847ca',
            fontSize: 14,
            fontWeight: '500',
            color: '#fff',
            '&:hover': {
              boxShadow: '0px 6px 8px rgba(209, 209, 209, 0.6)',
              backgroundColor: '#2A3DC7',
            },
          }}
        >
          <FormattedMessage id="billinginfo.default_payment_method.dialog.confirm" />
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderDeleteDialog = () => (
    <Dialog
      open={isDeleteDialogOpen}
      onClose={() => setIsDeleteDialogOpen(false)}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle>
        <FormattedMessage id="billinginfo.default_payment_method.delete.card.title" />
      </DialogTitle>
      <DialogContent>
        <Typography>
          <FormattedMessage
            id="billinginfo.default_payment_method.delete.card.content"
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseDeleteDialog}
          variant="contained"
          disabled={loading}
        >
          <FormattedMessage id="billinginfo.delete_dialog.cancel" />
        </Button>
        <Button
          onClick={handleDeletePaymentMethod}
          variant="contained"
          color="secondary"
          disabled={loading}
        >
          <FormattedMessage id="billinginfo.delete_dialog.delete" />
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderCurrentCardInfo = () => !!currentCard && (
    <div className={classes.savedCard}>
      {getSavedPaymentCardLogo()}
      <Typography variant="inherit" className={classes.savedCardInfo}>
        {`${currentCard.brand} **** ${currentCard.last4}`}
      </Typography>
      <Typography variant="inherit" className={classes.savedCardExpiration}>
        <FormattedMessage id="billinginfo.cardinfo.expires" values={{ expirationDate: currentCard.expiration }} />
      </Typography>
      {currentDefaultPaymentMethod === PaymentMethodType.CREDIT_CARD
        && renderDefaultPaymentMethodChip()}
      <Box className={classes.buttonGroup}>
        <Button
          variant="outlined"
          onClick={() => setIsCardFormOpen(true)}
          disabled={isCardFormOpen}
        >
          {intl.formatMessage({ id: 'billinginfo.button.update' })}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setIsDeleteDialogOpen(true)}
          disabled={isCardFormOpen}
          sx={{ ml: '1rem' }}
        >
          {intl.formatMessage({ id: 'billinginfo.button.delete' })}
        </Button>
      </Box>
    </div>
  );

  const renderCreditCardSection = () => (
    <Grid container>
      <Grid item xs={0.5}>
        <Radio
          checked={currentDefaultPaymentMethod === PaymentMethodType.CREDIT_CARD}
          onChange={openDefaultPaymentMethodDialog}
          value={PaymentMethodType.CREDIT_CARD}
          disabled={!currentCard}
          name="radio-buttons"
        />
      </Grid>
      <Grid item xs={11.5}>
        <div className={classes.paymentMethodTitle}>
          <CreditCardIcon className={classes.paymentMethodIcon} />
          <span className={classes.paymentMethodLabel}>
            <FormattedMessage id={`billinginfo.payment_method.${PaymentMethodType.CREDIT_CARD}`} />
          </span>
          {renderCurrentCardInfo()}
          {renderDeleteDialog()}
          {renderSelectDefaultPaymentMethodDialog()}
        </div>

        <NewCardInformation
          accountid={accountId}
          accountName={account?.name}
          currentCard={currentCard}
          isCardFormOpen={isCardFormOpen}
          setIsCardFormOpen={setIsCardFormOpen}
          loading={loading}
          setLoading={setLoading}
          updateDefaultPaymentMethod={updateDefaultPaymentMethod}
        />
      </Grid>
    </Grid>
  );

  const renderBankTransferSection = () => (
    <Grid container className={classes.bankTransferSection}>
      <Grid item xs={0.5}>
        <Radio
          checked={currentDefaultPaymentMethod === PaymentMethodType.BANK_TRANSFER}
          onChange={openDefaultPaymentMethodDialog}
          value={PaymentMethodType.BANK_TRANSFER}
          name="radio-buttons"
        />
      </Grid>
      <Grid item xs={11.5}>
        <div className={classes.paymentMethodTitle}>
          <BankTransferIcon className={classes.paymentMethodIcon} />
          <span className={classes.paymentMethodLabel}>
            <FormattedMessage id={`billinginfo.payment_method.${PaymentMethodType.BANK_TRANSFER}`} />
          </span>
          {currentDefaultPaymentMethod === PaymentMethodType.BANK_TRANSFER
            && renderDefaultPaymentMethodChip()}
        </div>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Typography
        sx={{
          mb: '0.5rem',
          pb: '0.5rem',
          fontWeight: 500,
          fontSize: '1.125rem',
        }}
      >
        {intl.formatMessage({ id: 'billinginfo.default_payment_method' })}
      </Typography>
      <div className={classes.seperateLine} />

      {renderCreditCardSection()}
      {renderBankTransferSection()}
    </>
  );
};

export default DefaultPaymentMethod;
