import Axios from 'axios';
import { Account, DataResponse, FreeKsExpiryDate, PageableResponse } from '../models';
import { composeQuery, Query } from '../models/query';
import { AISetting } from '../models/aiSetting';

// TODO: Remove ` & { organizationId: number }` when column accounts.id can auto-generate
export const createAccount = async (data: Required<Pick<Account, 'name'>>) => (
  await Axios.post<DataResponse<Account>>('/v1/accounts', data)
).data.data;

export const updateAccount = async (input: Required<Pick<Account, 'id' | 'name'>>) => (await Axios.put<DataResponse<Account>>(`/v1/accounts/${input.id}`, input)).data.data;
export const deleteAccount = async (input: Required<Pick<Account, 'id'>>) => (await Axios.delete<DataResponse<Account>>(`/v1/accounts/${input.id}`)).data.data;

export const getAccounts = async (...criteria: Query<Account>[]) => (
  await Axios.get<PageableResponse<Account>>('/v1/accounts', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    params: composeQuery<Account>(...criteria),
    data: {},
  })
).data;

export const getFreeKsExpiryDate = async (input: Required<Pick<Account, 'id'>>) => (
  await Axios.get<DataResponse<FreeKsExpiryDate>>(`/v1/accounts/${input.id}/free-ks-expiry-date`)
).data.data;

export const checkPublicDomain = async (input: Required<Pick<Account, 'id'>>) => (
  await Axios.get<DataResponse<boolean>>(`/v1/accounts/${input.id}/check-public-domain`)
).data.data;

export const updateAISetting = async (input: Required<Pick<AISetting, 'accountId' | 'enableAi'>>) => (
  await Axios.post<DataResponse<AISetting>>('/v1/account-settings/ai', input)).data.data;

export const getAISetting = async (...criteria: Query<AISetting>[]) => (
  await Axios.get<PageableResponse<AISetting>>('/v1/account-settings', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    params: composeQuery<AISetting>(...criteria),
    data: {},
  })
).data;

export const getFreeTestResultQuota = async (input: Required<Pick<Account, 'id'>>) => (
  await Axios.get<DataResponse<number>>(`/v1/accounts/${input.id}/free-test-result-quota`)
).data.data;

export const sendEnableAiEmail = async (accountId: number) => (
  await Axios.post(`/v1/account-settings/send-enable-ai-email?accountId=${accountId}`)
).data;

export const optInSelfServe = async (input: Required<Pick<Account, 'id'>>) => (
  await Axios.post(`/v1/existing-accounts/${input.id}/opt-in-self-serve`)
).data;

export const isOptInSelfServe = async (accountId: number) => (
  await Axios.get<DataResponse<boolean>>(`/v1/existing-accounts/${accountId}/is-opt-in-self-serve`)
).data;
