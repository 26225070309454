import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import { ReactComponent as UploadIcon } from './uploadicon.svg';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarWrapper: {
    display: 'grid',
    alignItems: 'end',
  },
  profileAvatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    gridArea: '1/1',
    boxShadow: '0px 4px 8px 0px rgba(214, 214, 214, 0.25)',
    border: '8px solid rgba(255, 255, 255, 1)',
  },
  uploadButton: {
    cursor: 'pointer',
    padding: 0,
    width: theme.spacing(4),
    height: theme.spacing(4),
    margin: theme.spacing(-1.5),
  },
  uploadButtonWrapper: {
    gridArea: '1/1',
    justifySelf: 'end',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    zIndex: 1,
    opacity: 1,
    '&:hover': {
      opacity: 1,
    },
  },
}));

const defaultAvatar = 'https://katalon-testops.s3.amazonaws.com/image/icon/defaultAvatar.png';

const AvatarAccount = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.avatarWrapper}>
        <Avatar className={classes.profileAvatar} src={defaultAvatar} />
        <Tooltip title="Avatar Account">
          <>
            <label htmlFor="upload-avatar" className={classes.uploadButtonWrapper}>
              <UploadIcon className={classes.uploadButton} />
              <input
                accept="image/png, image/jpeg"
                type="file"
                id="upload-avatar"
                hidden
              />
            </label>
          </>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default AvatarAccount;
