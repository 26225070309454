import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import orderBy from 'lodash/fp/orderBy';
import { AppState } from '.';
import { Account, AccountUser, User } from '../models';
import { AccountUserService } from '../services';
import { Operator, Query } from '../models/query';
import { doCreateAccountAndOrganizationAndTeamAndProject } from './accountSlice';

export const ACCOUNT_USERS_FEATURE_KEY = 'accountUsers';

type AccountUserEntity = AccountUser;
const accountUsersAdapter = createEntityAdapter<AccountUserEntity>();
const MAX_NUMBER_OF_RECORDS = 50;

interface AccountUserState extends EntityState<AccountUserEntity> {
  loading: boolean;
  error?: string;
  loadingPrefix: { [key: string]: boolean };
}

export const createInitialState = (): AccountUserState => (
  accountUsersAdapter.getInitialState({
    loading: false,
    loadingPrefix: {},
  })
);

export const doGetAccountUsersByUserId = createAsyncThunk(
  'accountUser/getAccountUsersByUserId',
  async (input: Pick<AccountUser, 'userId'>) => {
    const criteria: Array<Query<AccountUser>> = [
      { field: 'userId', operator: Operator.EQ, value: input.userId },
    ];
    const firstResponse = await AccountUserService.getAccountUsers(...criteria);
    const numberOfRequests = Math.ceil(firstResponse.total / MAX_NUMBER_OF_RECORDS);
    const remainingRequests = [];
    for (let i = 1; i < numberOfRequests; i += 1) {
      const req = AccountUserService.getAccountUsers(
        ...criteria,
        { offset: MAX_NUMBER_OF_RECORDS * i },
      );
      remainingRequests.push(req);
    }
    const remainingResponses = await Promise.all(remainingRequests);
    const accountUsers = [...firstResponse.data];
    remainingResponses.forEach(r => {
      accountUsers.push(...r.data);
    });
    return accountUsers;
  },
);

export const doGetByUserIdAndAccountId = createAsyncThunk(
  'accountUser/getByUserIdAndAccountId',
  async (input: Pick<AccountUser, 'userId' | 'accountId'>) => {
    const criteria: Array<Query<AccountUser>> = [
      { field: 'userId', operator: Operator.EQ, value: input.userId },
      { field: 'accountId', operator: Operator.EQ, value: input.accountId },
      { field: 'archived', operator: Operator.EQ, value: false },
      { limit: 1 },
    ];
    const data = await AccountUserService.getAccountUsers(...criteria);
    return data;
  },
);

export const doGetByAccountId = createAsyncThunk(
  'accountUser/doGetByAccountId',
  async (input: Pick<AccountUser, 'accountId'>) => {
    const criteria: Array<Query<AccountUser>> = [
      { field: 'accountId', operator: Operator.EQ, value: input.accountId },
      { field: 'archived', operator: Operator.EQ, value: false },
    ];
    const response = await AccountUserService.getAccountUsers(...criteria);
    return response.data;
  },
);

export const doGetAllByAccountId = createAsyncThunk(
  'accountUser/doGetAllByAccountId',
  async (input: Pick<AccountUser, 'accountId'>) => {
    const criteria: Array<Query<AccountUser>> = [
      { field: 'accountId', operator: Operator.EQ, value: input.accountId },
      { field: 'archived', operator: Operator.EQ, value: false },
    ];
    const firstResponse = await AccountUserService.getAccountUsers(...criteria);
    const numberOfRequests = Math.ceil(firstResponse.total / MAX_NUMBER_OF_RECORDS);
    const remainingRequests = [];
    for (let i = 1; i < numberOfRequests; i += 1) {
      const req = AccountUserService.getAccountUsers(
        ...criteria,
        { offset: MAX_NUMBER_OF_RECORDS * i },
      );
      remainingRequests.push(req);
    }
    const remainingResponses = await Promise.all(remainingRequests);
    const accountUsers = [...firstResponse.data];
    remainingResponses.forEach(r => {
      accountUsers.push(...r.data);
    });
    return accountUsers;
  },
);

export const doCreateRecurlyAccount = createAsyncThunk(
  'accountUser/createRecurlyAccount',
  async (input: number) => {
    await AccountUserService.createRecurlyAccount(input);
  },
);

const accountUsersSlice = createSlice({
  name: ACCOUNT_USERS_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doGetAccountUsersByUserId.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetAccountUsersByUserId.fulfilled, (state, action) => {
      state.loading = false;
      accountUsersAdapter.setAll(state, action.payload);
    });
    builder.addCase(doGetAccountUsersByUserId.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(doGetByAccountId.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetByAccountId.fulfilled, (state, action) => {
      state.loading = false;
      accountUsersAdapter.upsertMany(state, action.payload);
    });
    builder.addCase(doGetByAccountId.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(doGetAllByAccountId.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetAllByAccountId.fulfilled, (state, action) => {
      state.loading = false;
      accountUsersAdapter.upsertMany(state, action.payload);
    });
    builder.addCase(doGetAllByAccountId.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(doGetByUserIdAndAccountId.pending, state => {
      state.loading = true;
      state.loadingPrefix[doGetByUserIdAndAccountId.typePrefix] = true;
    });
    builder.addCase(doGetByUserIdAndAccountId.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.data.length > 0) {
        accountUsersAdapter.upsertOne(state, action.payload.data.at(0)!!);
      }
      state.loadingPrefix[doGetByUserIdAndAccountId.typePrefix] = false;
    });
    builder.addCase(doGetByUserIdAndAccountId.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.loadingPrefix[doGetByUserIdAndAccountId.typePrefix] = false;
    });
    builder.addCase(doCreateAccountAndOrganizationAndTeamAndProject.fulfilled, (state, action) => {
      const accountUser = action.payload.accountUser.length > 0
        ? action.payload.accountUser[0]
        : undefined;
      if (accountUser !== undefined) {
        accountUsersAdapter.addOne(state, accountUser);
      }
    });
    builder.addCase(doCreateRecurlyAccount.rejected, (state, action) => {
      state.loading = true;
      state.error = action.error.message;
    });
  },
});

const selectAccountUserFeature = (state: AppState) => state[ACCOUNT_USERS_FEATURE_KEY];
const { selectAll } = accountUsersAdapter.getSelectors(selectAccountUserFeature);

export const selectAccountUsersByUserId = (userId: User['id']) => createSelector(
  selectAll,
  all => orderBy('createdAt', 'desc', all).filter(
    it => it.userId === userId,
  ),
);

export const selectMinimumAccountIdAccountUsersByUserId = (userId: User['id']) => createSelector(
  selectAll,
  all => orderBy('accountId', 'asc', all).filter(
    it => it.userId === userId,
  )[0],
);

export const findAccountUserByAccountId = (accountId: Account['id']) => createSelector(
  selectAll,
  all => all.find(it => it.accountId === accountId),
);

export const selectAccountUserByAccountId = (accountId: Account['id']) => createSelector(
  selectAll,
  all => all.filter(it => it.accountId === accountId),
);

export const selectOneByUserIdAndAccountId = (userId: User['id'], accountId: Account['id']) => createSelector(
  selectAll,
  all => all.find(it => it.userId === userId && it.accountId === accountId),
);

export const selectCountByAccountId = (accountId: Account['id']) => createSelector(
  selectAll,
  all => all.filter(it => it.accountId === accountId).length,
);

export const selectCurrentAccountUserLoading = createSelector(
  selectAccountUserFeature,
  state => state.loadingPrefix[doGetByUserIdAndAccountId.typePrefix],
);

export default accountUsersSlice.reducer;
