enum OrganizationMigrationStatus {
  NOT_YET = -1,
  MIGRATING = 0,
  MIGRATED = 1,
}

namespace OrganizationMigrationStatus {
  export const fromInt = (value: number): OrganizationMigrationStatus => (
    OrganizationMigrationStatus as any)[value] ?? OrganizationMigrationStatus.NOT_YET;
}

export { OrganizationMigrationStatus };

export interface OrganizationMigration {
  organizationId: number;
  migrated: number;
}
