import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Select, { SelectProps } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { unwrapResult } from '@reduxjs/toolkit';
import LoadingProgress from '../../layout/LoadingProgress';
import { fromOrganizationConfigurations, useAppDispatch } from '../../store';
import { useQuery } from '../../routes';
import { OrganizationConfigurationName } from '../../models/organizationConfigurationName';
import { OrganizationConfiguration } from '../../models/organizationConfiguration';

const useStyles = makeStyles(theme => ({
  header: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(2),
    marginBottom: theme.spacing(2.0625),
  },
  boxKse: {
    width: '100%',
    borderBottom: '1px solid #DCDFE6',
  },
  boxAllowIdleTimeoutBypass: {
    marginTop: theme.spacing(1.5),
  },
  boxPlatform: {
    marginTop: theme.spacing(2.5),
  },
  container: {
    marginBottom: theme.spacing(1),
    width: theme.spacing(57),
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(1.75),
  },
  description: {
    fontSize: theme.spacing(1.5),
    color: '#808b9a',
    marginBottom: theme.spacing(2.5),
    lineHeight: 1.5,
  },
  input: {
    width: theme.spacing(27.5),
    marginBottom: theme.spacing(1),
  },
  switch: {
    marginTop: theme.spacing(-1.25),
  },
  button: {
    marginTop: theme.spacing(2.5),
    fontSize: theme.spacing(1.75),
    paddingY: theme.spacing(1),
    lineHeight: theme.spacing(3),
    fontWeight: theme.typography.fontWeightMedium,
  },
  error: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(1),
  },
}));

interface OrganizationConfigurationInputs {
  kseIdleTimeout?: string | null;
  kseIdleTimeoutCustom?: string | null;
  maxTimesContinuousKse?: string | null;
  platformIdleTimeout?: string | null;
  platformIdleTimeoutCustom?: string | null;
}
export interface ConfigurationItemProps {
  configurations: OrganizationConfiguration[];
  organizationId?: number;
}

const IdleTimeout = (props: ConfigurationItemProps) => {
  const { configurations, organizationId } = props;
  const { get } = useQuery();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const orgId = Number(get('orgId') ?? organizationId);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const currentKseSessionTimeout = configurations
    ?.find(item => item.name === OrganizationConfigurationName.KSE_IDLE_TIMEOUT)?.value ?? null;
  const currentKseMaxTimesContinuous = configurations
    ?.find(item => (
      item.name === OrganizationConfigurationName.MAX_TIMES_CONTINUOUS_KSE))?.value ?? null;
  const currentPlatformSessionTimeout = configurations
    ?.find(item => item.name === OrganizationConfigurationName.SESSION_TIMEOUT)?.value ?? null;

  const checkEnableToggle = (value: string | null | undefined) => value !== undefined && value !== null && value !== '';
  const arrayValueField = ['60', '180', '360', '720', '1440'];
  const currentEnableKse = checkEnableToggle(currentKseSessionTimeout);
  const currentEnableKseMaxTimes = checkEnableToggle(currentKseMaxTimesContinuous);
  const currentEnablePlatform = checkEnableToggle(currentPlatformSessionTimeout);

  const [kseTimeoutEnable, setKseTimeoutEnable] = useState(false);
  const [kseCustomTimeoutEnable, setKseCustomTimeoutEnable] = useState(false);
  const [kseMaxTimesContinuousEnable, setKseMaxTimesContinuousEnable] = useState(false);
  const [platformSessionTimeoutEnable, setPlatformSessionTimeoutEnable] = useState(false);
  const [platformCustomTimeoutEnable, setPlatformCustomTimeoutEnable] = useState(false);

  const [kseIdleTimeout, setKseIdleTimeout] = useState('');
  const [kseIdleTimeoutCustom, setKseIdleTimeoutCustom] = useState('');
  const [maxTimesContinuousKse, setMaxTimesContinuousKse] = useState('');
  const [platformIdleTimeout, setPlatformIdleTimeout] = useState('');
  const [platformIdleTimeoutCustom, setPlatformIdleTimeoutCustom] = useState('');

  const [kseIdleTimeoutCustomError, setKseIdleTimeoutCustomError] = useState('');
  const [kseMaxTimesContinuousError, setKseMaxTimesContinuousError] = useState('');
  const [platformIdleTimeoutCustomError, setPlatformIdleTimeoutCustomError] = useState('');

  const {
    handleSubmit,
  } = useForm<OrganizationConfigurationInputs>({
  });

  useEffect(() => {
    if (configurations) {
      if (currentKseSessionTimeout) {
        setKseTimeoutEnable(true);
        const enableKseCustom = arrayValueField.indexOf(String(currentKseSessionTimeout)) === -1;
        if (enableKseCustom) {
          setKseIdleTimeout('0');
          setKseIdleTimeoutCustom(currentKseSessionTimeout);
        } else {
          setKseIdleTimeout(currentKseSessionTimeout);
        }
        setKseCustomTimeoutEnable(enableKseCustom);
      }
      if (currentKseMaxTimesContinuous) {
        setKseMaxTimesContinuousEnable(true);
        setMaxTimesContinuousKse(currentKseMaxTimesContinuous);
      }
      if (currentPlatformSessionTimeout) {
        setPlatformSessionTimeoutEnable(true);
        const enablePlatformCustom = arrayValueField
          .indexOf(String(currentPlatformSessionTimeout)) === -1;
        if (enablePlatformCustom) {
          setPlatformIdleTimeout('0');
          setPlatformIdleTimeoutCustom(currentPlatformSessionTimeout);
        } else {
          setPlatformIdleTimeout(currentPlatformSessionTimeout);
        }
        setPlatformCustomTimeoutEnable(enablePlatformCustom);
      }
    }
  }, [configurations]);

  const doSubmit = async () => {
    let isError = false;
    if (kseTimeoutEnable && kseCustomTimeoutEnable) {
      if (kseIdleTimeoutCustom === null || kseIdleTimeoutCustom === undefined || kseIdleTimeoutCustom === '') {
        setKseIdleTimeoutCustomError('security_setting.idle_timeout.error.required');
        isError = true;
      } else if (!regixNumber.test(kseIdleTimeoutCustom)) {
        setKseIdleTimeoutCustomError('security_setting.idle_timeout.error.invalid.idle.timeout');
        isError = true;
      } else {
        setKseIdleTimeoutCustomError('');
      }
    }

    if (kseTimeoutEnable && kseMaxTimesContinuousEnable) {
      if (maxTimesContinuousKse === null || maxTimesContinuousKse === undefined || maxTimesContinuousKse === '') {
        setKseMaxTimesContinuousError('security_setting.idle_timeout.error.required');
        isError = true;
      } else if (!regixNumber.test(maxTimesContinuousKse)) {
        setKseMaxTimesContinuousError('security_setting.idle_timeout.error.invalid.idle.timeout');
        isError = true;
      } else {
        setKseMaxTimesContinuousError('');
      }
    }

    if (platformSessionTimeoutEnable && platformCustomTimeoutEnable) {
      if (platformIdleTimeoutCustom === null || platformIdleTimeoutCustom === undefined || platformIdleTimeoutCustom === '') {
        setPlatformIdleTimeoutCustomError('security_setting.idle_timeout.error.required');
        isError = true;
      } else if (!regixNumber.test(platformIdleTimeoutCustom)) {
        setPlatformIdleTimeoutCustomError('security_setting.idle_timeout.error.invalid.idle.timeout');
        isError = true;
      } else {
        setPlatformIdleTimeoutCustomError('');
      }
    }

    const updateIdleTimeout = async () => {
      setLoading(true);

      const kseIdleTimeoutFinal = getFinalKseIdleTimeout();
      const maxTimesContinuousKseFinal = getFinalMaxTimesContinuousKse();
      const platformTimeoutFinal = getFinalPlatform();

      const kseTimeout = getIdleTimeout(isEditKseTimeout(), kseIdleTimeoutFinal);
      const kseMaxTimes = getIdleTimeout(isEditKseMaxTime(), maxTimesContinuousKseFinal);
      const platformTimeout = getIdleTimeout(isEditPlatformTimeout(), platformTimeoutFinal);

      await dispatch(
        fromOrganizationConfigurations.doUpsertOrganizationConfiguration({
          organizationId: orgId,
          kseIdleTimeout: kseTimeout,
          maxTimesContinuousKse: kseMaxTimes,
          sessionTimeout: platformTimeout,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          enqueueSnackbar(
            <FormattedMessage id="security_setting.idle_timeout.update.successfully" />,
            { variant: 'success' },
          );
          if (isEditKseTimeout() && kseIdleTimeoutFinal !== null) {
            setKseCustomTimeoutEnable(
              arrayValueField.indexOf(String(kseIdleTimeoutFinal)) === -1,
            );
          }
          if (!kseTimeoutEnable) {
            setKseMaxTimesContinuousEnable(false);
          }
          if (isEditPlatformTimeout()) {
            setPlatformCustomTimeoutEnable(
              arrayValueField.indexOf(String(platformTimeoutFinal)) === -1,
            );
          }
        })
        .catch(() => {
          // If error, restore inputs value to default value
          enqueueSnackbar(
            intl.formatMessage({ id: 'security_setting.idle_timeout.update.fail' }),
            { variant: 'error' },
          );
        })
        .finally(() => setLoading(false));
    };

    if (!isError) {
      updateIdleTimeout().catch(() => {});
    }
  };

  const handleOnSwitchKseIdleTimeout = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isEnable: boolean = event.target.checked;
    if (!isEnable) {
      setKseIdleTimeoutCustomError('');
      setKseMaxTimesContinuousError('');
    }
    setKseTimeoutEnable(isEnable);
    if (!kseIdleTimeout) {
      setKseIdleTimeout('60');
    }
  };

  const handleOnSwitchAllowIdleTimeoutLimit = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isEnable: boolean = event.target.checked;
    if (!isEnable) {
      setKseMaxTimesContinuousError('');
    }
    setKseMaxTimesContinuousEnable(isEnable);
  };

  const handleOnChangeKseMaxTimesContinuous = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setMaxTimesContinuousKse(String(event.target.value).replace(/^0+/, ''));
    } else {
      setMaxTimesContinuousKse('');
    }
  };

  const handleOnChangeKseIdleTimeout: SelectProps['onChange'] = e => {
    if (e.target.value === 0) {
      setKseCustomTimeoutEnable(true);
    } else {
      setKseCustomTimeoutEnable(false);
      setKseIdleTimeoutCustomError('');
    }
    setKseIdleTimeout(String(e.target.value));
  };

  const handleChangeCustomKseTimeout: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.value) {
      setKseIdleTimeoutCustom(String(e.target.value).replace(/^0+/, ''));
    } else {
      setKseIdleTimeoutCustom('');
    }
  };

  const handleOnSwitchPlatformIdleTimeout = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isEnable: boolean = event.target.checked;
    if (!isEnable) {
      setPlatformIdleTimeoutCustomError('');
    }
    setPlatformSessionTimeoutEnable(isEnable);
    if (!platformIdleTimeout) {
      setPlatformIdleTimeout('60');
    }
  };

  const handleOnChangePlatformIdleTimeout: SelectProps['onChange'] = e => {
    setPlatformCustomTimeoutEnable(e.target.value === 0);
    if (e.target.value === 0) {
      setPlatformCustomTimeoutEnable(true);
    } else {
      setPlatformCustomTimeoutEnable(false);
      setPlatformIdleTimeoutCustomError('');
    }
    setPlatformIdleTimeout(String(e.target.value));
  };

  const handleChangeCustomPlatformTimeout: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.value) {
      setPlatformIdleTimeoutCustom(String(e.target.value).replace(/^0+/, ''));
    } else {
      setPlatformIdleTimeoutCustom('');
    }
  };

  const getFinalKseIdleTimeout = () => {
    if (!kseTimeoutEnable) {
      return null;
    }
    return kseCustomTimeoutEnable ? kseIdleTimeoutCustom : kseIdleTimeout;
  };

  const getFinalMaxTimesContinuousKse = () => {
    if (!kseTimeoutEnable || !kseMaxTimesContinuousEnable) {
      return null;
    }
    return maxTimesContinuousKse;
  };

  const getFinalPlatform = () => {
    if (!platformSessionTimeoutEnable) {
      return null;
    }
    return platformCustomTimeoutEnable ? platformIdleTimeoutCustom : platformIdleTimeout;
  };
  const isEditKseTimeout = () => currentKseSessionTimeout !== getFinalKseIdleTimeout();
  const isEditKseMaxTime = () => currentKseMaxTimesContinuous !== getFinalMaxTimesContinuousKse();
  const isEditPlatformTimeout = () => currentPlatformSessionTimeout !== getFinalPlatform();
  const regixNumber = /^[1-9][0-9]{0,5}?$|^1000000$/;

  const isSwitchEnableKse = () => currentEnableKse !== kseTimeoutEnable;
  const isSwitchEnableMaxTime = () => currentEnableKseMaxTimes !== kseMaxTimesContinuousEnable;
  const isSwitchEnablePlatform = () => currentEnablePlatform !== platformSessionTimeoutEnable;
  const getIdleTimeout = (
    isEditTimeout: boolean,
    kseIdleTimeoutFinal: string | null | undefined,
  ) => {
    if (isEditTimeout) {
      return kseIdleTimeoutFinal ?? null;
    }
    return '0';
  };

  const isEnableEditButton = isSwitchEnableKse()
    || isSwitchEnableMaxTime()
    || isSwitchEnablePlatform()
    || isEditKseTimeout()
    || isEditKseMaxTime()
    || isEditPlatformTimeout();

  return (
    <>
      {!loading && (
        <form onSubmit={handleSubmit(doSubmit)} id="security_setting.idle_timeout.form">
          <Box display="block" padding={2} alignItems="center" justifyContent="space-between">
            <Typography variant="subtitle2" className={classes.header}>
              <FormattedMessage id="security_setting.idle_timeout.kse.header" />
            </Typography>
            <Box display="block" alignItems="center" className={classes.boxKse}>
              <Box display="flex" className={classes.container}>
                <Switch
                  id="security_setting.idle_timeout.kse.switch"
                  className={classes.switch}
                  checked={kseTimeoutEnable}
                  onChange={handleOnSwitchKseIdleTimeout}
                />
                <Typography variant="subtitle2" className={classes.title}>
                  <FormattedMessage id="security_setting.idle_timeout.kse.automatically_log.title" />
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2" className={classes.description}>
                  <FormattedMessage id="security_setting.idle_timeout.kse.automatically_log.description" />
                </Typography>
              </Box>
              { kseTimeoutEnable && (
                <Box display="flex">
                  <Box display="block" className={classes.container}>
                    <Typography variant="subtitle2" className={classes.title}>
                      <FormattedMessage id="security_setting.idle_timeout.kse.timeout_limit.title" />
                    </Typography>
                    <Typography variant="subtitle2" className={classes.description}>
                      <FormattedMessage id="security_setting.idle_timeout.kse.timeout_limit.description" />
                    </Typography>
                  </Box>
                  <Box display="grid">
                    <Select
                      id="security_setting.idle_timeout.kse.timeout_limit.select"
                      value={kseIdleTimeout ? Number(kseIdleTimeout) : '60'}
                      className={classes.input}
                      onChange={handleOnChangeKseIdleTimeout}
                    >
                      <MenuItem id="security_setting.idle_timeout.kse.timeout_limit.item.one_hour" value={60}>1 hour</MenuItem>
                      <MenuItem id="security_setting.idle_timeout.kse.timeout_limit.item.three_hours" value={180}>3 hours</MenuItem>
                      <MenuItem id="security_setting.idle_timeout.kse.timeout_limit.item.six_hours" value={360}>6 hours</MenuItem>
                      <MenuItem id="security_setting.idle_timeout.kse.timeout_limit.item.twelve_hours" value={720}>12 hours</MenuItem>
                      <MenuItem id="security_setting.idle_timeout.kse.timeout_limit.item.twenty_four_hours" value={1440}>24 hours</MenuItem>
                      <MenuItem id="security_setting.idle_timeout.kse.timeout_limit.item.custom" value={0}>Custom (in minutes)</MenuItem>
                    </Select>
                    { kseCustomTimeoutEnable && (
                      <Box display="grid">
                        <TextField
                          id="security_setting.idle_timeout.kse.timeout_limit.timeout_limit.input"
                          className={classes.input}
                          value={kseIdleTimeoutCustom}
                          variant="outlined"
                          error={kseIdleTimeoutCustomError !== ''}
                          onChange={handleChangeCustomKseTimeout}
                        />
                        {kseIdleTimeoutCustomError && (
                          <Typography className={classes.error} variant="caption">
                            <FormattedMessage id={kseIdleTimeoutCustomError} />
                          </Typography>
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
              { kseTimeoutEnable && (
                <Box display="flex" className={classes.boxAllowIdleTimeoutBypass}>
                  <Box display="block">
                    <Box display="flex" className={classes.container}>
                      <Switch
                        id="security_setting.idle_timeout.kse.allow_idle_timeout_bypass.switch"
                        className={classes.switch}
                        checked={kseMaxTimesContinuousEnable}
                        onChange={handleOnSwitchAllowIdleTimeoutLimit}
                      />
                      <Typography variant="subtitle2" className={classes.title}>
                        <FormattedMessage id="security_setting.idle_timeout.kse.allow_idle_timeout_bypass.title" />
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2" className={classes.description}>
                        <FormattedMessage id="security_setting.idle_timeout.kse.allow_idle_timeout_bypass.description" />
                      </Typography>
                    </Box>
                  </Box>
                  <Box display="grid">
                    <TextField
                      id="security_setting.idle_timeout.kse.timeout_limit.allow_idle_timeout_bypass.input"
                      disabled={!kseMaxTimesContinuousEnable}
                      className={classes.input}
                      variant="outlined"
                      value={maxTimesContinuousKse}
                      error={kseMaxTimesContinuousError !== ''}
                      onChange={handleOnChangeKseMaxTimesContinuous}
                    />
                    {kseMaxTimesContinuousError && (
                      <Typography className={classes.error} variant="caption">
                        <FormattedMessage id={kseMaxTimesContinuousError} />
                      </Typography>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
            <Box className={classes.boxPlatform}>
              <Typography variant="subtitle2" className={classes.header}>
                <FormattedMessage id="security_setting.idle_timeout.platform.header" />
              </Typography>
              <Box display="block" alignItems="center" width="100%">
                <Box display="flex" className={classes.container}>
                  <Switch
                    id="security_setting.idle_timeout.platform.switch"
                    className={classes.switch}
                    checked={platformSessionTimeoutEnable}
                    onChange={handleOnSwitchPlatformIdleTimeout}
                  />
                  <Typography variant="subtitle2" className={classes.title}>
                    <FormattedMessage id="security_setting.idle_timeout.platform.title" />
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle2" className={classes.description}>
                    <FormattedMessage id="security_setting.idle_timeout.platform.description" />
                  </Typography>
                </Box>
                { platformSessionTimeoutEnable && (
                  <Box display="flex">
                    <Box display="block" className={classes.container}>
                      <Typography variant="subtitle2" className={classes.title}>
                        <FormattedMessage id="security_setting.idle_timeout.platform.timeout_limit.title" />
                      </Typography>
                      <Typography variant="subtitle2" className={classes.description}>
                        <FormattedMessage id="security_setting.idle_timeout.platform.timeout_limit.description" />
                      </Typography>
                    </Box>
                    <Box display="grid">
                      <Select
                        id="security_setting.idle_timeout.platform.timeout_limit.select"
                        value={platformIdleTimeout}
                        className={classes.input}
                        onChange={handleOnChangePlatformIdleTimeout}
                      >
                        <MenuItem id="security_setting.idle_timeout.platform.timeout_limit.item.one_hour" value={60}>1 hour</MenuItem>
                        <MenuItem id="security_setting.idle_timeout.platform.timeout_limit.item.three_hours" value={180}>3 hours</MenuItem>
                        <MenuItem id="security_setting.idle_timeout.platform.timeout_limit.item.six_hours" value={360}>6 hours</MenuItem>
                        <MenuItem id="security_setting.idle_timeout.platform.timeout_limit.item.twelve_hours" value={720}>12 hours</MenuItem>
                        <MenuItem id="security_setting.idle_timeout.platform.timeout_limit.item.twenty_four_hours" value={1440}>24 hours</MenuItem>
                        <MenuItem id="security_setting.idle_timeout.platform.timeout_limit.item.custom" value={0}>Custom (in minutes)</MenuItem>
                      </Select>
                      { platformCustomTimeoutEnable && (
                        <Box display="grid">
                          <TextField
                            id="security_setting.idle_timeout.platform.timeout_limit.input"
                            className={classes.input}
                            value={platformIdleTimeoutCustom}
                            variant="outlined"
                            error={platformIdleTimeoutCustomError !== ''}
                            onChange={handleChangeCustomPlatformTimeout}
                          />
                          {platformIdleTimeoutCustomError && (
                            <Typography className={classes.error} variant="caption">
                              <FormattedMessage id={platformIdleTimeoutCustomError} />
                            </Typography>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            <Button
              id="security_setting.idle_timeout.button.update"
              variant="contained"
              disabled={!isEnableEditButton}
              color="primary"
              type="submit"
              sx={{
                '&.Mui-disabled': {
                  background: '#686D80',
                  color: '#c0c0c0',
                },
              }}
              className={classes.button}
            >
              <FormattedMessage id="security_setting.idle_timeout.button.update" />
            </Button>
          </Box>
        </form>
      )}
      {loading && <LoadingProgress />}
    </>
  );
};

export default IdleTimeout;
