enum TestOpsPlanInterval {
  YEAR = 'YEAR',
  MONTH = 'MONTH',
  TWO_YEARS = 'TWO_YEARS',
  THREE_YEARS = 'THREE_YEARS',
}

namespace TestOpsPlanInterval {
  export const fromString = (str: string) => {
    if (str === 'MONTH') {
      return TestOpsPlanInterval.MONTH;
    }
    if (str === 'YEAR') {
      return TestOpsPlanInterval.YEAR;
    }
    return (TestOpsPlanInterval as any)[str];
  };

  export const getPlanIntervalName = (planInterval: TestOpsPlanInterval) => {
    switch (planInterval) {
      case TestOpsPlanInterval.YEAR:
        return 'Annual';
      case TestOpsPlanInterval.MONTH:
        return 'Monthly';
      case TestOpsPlanInterval.TWO_YEARS:
        return '2 years';
      case TestOpsPlanInterval.THREE_YEARS:
        return '3 years';
      default:
        return '-';
    }
  };
}

export { TestOpsPlanInterval };
