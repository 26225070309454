import { styled } from '@mui/material/styles';
import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useConfig } from '../../config';
import { useLaunchDarkly } from '../../launchdarkly';
import LoadingProgress from '../../layout/LoadingProgress';
import { fromAuth, fromLayout, useAppDispatch } from '../../store';
import { getIndexFile, mountMicroApp } from '../../utils/MicroFrontend';

export interface MicroAppProps {
  id: string,
  path: string,
  useLoading?: boolean,
  overrideConfig?: {},
  nodeId?: string,
}

const MicroApp = ({
  id,
  path,
  useLoading = false,
  overrideConfig = {},
  nodeId,
}: MicroAppProps) => {
  const [rendered, setRendered] = useState(false);
  const [mounted, setMounted] = useState(false);
  const { config } = useConfig();
  const { initialized, flags } = useLaunchDarkly();
  const dispatch = useAppDispatch();
  const token = useSelector(fromAuth.selectToken);

  const refreshToken = () => {
    if (token?.refreshJwt) {
      return dispatch(fromAuth.doRefreshToken({ refreshToken: token?.refreshJwt }))
        .then(unwrapResult)
        .then(it => it.token.jwt);
    }
    return '';
  };

  useEffect(() => {
    dispatch(fromLayout.doSetMountStatusMFE({ mfeName: nodeId ?? id, isMounted: false }));
  }, []);

  useEffect(() => {
    if (!rendered && config && initialized && flags) {
      document.addEventListener(`parent-app:handleMounted-${nodeId ?? id}`, () => {
        setMounted(true);
        dispatch(fromLayout.doSetMountStatusMFE({ mfeName: nodeId ?? id, isMounted: true }));
      });
      let customNode: Element | null;
      if (nodeId) {
        customNode = document.getElementById(nodeId);
      }
      getIndexFile(nodeId ?? id, path, () => mountMicroApp(
        id,
        {
          config: {
            ...config,
            accessToken: token?.jwt,
            getNewAccessToken: refreshToken,
            nodeId,
            randomSeedStyle: `${new Date().getTime()}`,
            ...overrideConfig,
          },
          ldFlags: flags,
        },
        customNode,
      ));
      setRendered(true);
      return () => {
        if (window.microapps) {
          document.removeEventListener(`parent-app:handleMounted-${nodeId ?? id}`, () => {
            setMounted(true);
          });
          window.microapps[id]?.unmountApp(nodeId);
          dispatch(fromLayout.doSetMountStatusMFE({ mfeName: nodeId ?? id, isMounted: false }));
        }
      };
    }
    return () => {};
  }, [rendered, config, initialized, flags]);

  return (
    <>
      {useLoading && !mounted && <LoadingProgress />}
      { !nodeId && <MicroAppContainer id={id} /> }
    </>
  );
};

const MicroAppContainer = styled('div')(() => ({
  height: '100%',
  width: '100%',
}));

export default MicroApp;
