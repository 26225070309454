import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { AppState } from '.';
import { TestOpsBillingInformation, exceptionOf } from '../models';
import { Operator } from '../models/query';
import { TestOpsBillingInformationService } from '../services';

export const TEST_OPS_BILLING_INFORMATION_FEATURE_KEY = 'testOpsBillingInformation';

interface TestOpsBillingInformationState {
  testOpsBillingInformation: TestOpsBillingInformation | null;
  loading: boolean;
  error?: string;
  selectedId: TestOpsBillingInformation['id'] | null;
}

export const createInitialState = (): TestOpsBillingInformationState => ({
  testOpsBillingInformation: null,
  loading: false,
  selectedId: null,
});

export const doCreateTestOpsBillingInformation = createAsyncThunk(
  'testOpsBillingInformation/createTestOpsBillingInformation',
  async (input: Parameters<typeof TestOpsBillingInformationService['upsertTestOpsBillingInformation']>[0], { rejectWithValue }) => {
    try {
      const billingInformation = await TestOpsBillingInformationService
        .upsertTestOpsBillingInformation(input);
      return billingInformation;
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doGetTestOpsBillingInformation = createAsyncThunk(
  'testOpsBillingInformation/getTestOpsBillingInformation',
  async (organizationId: TestOpsBillingInformation['organizationId']) => {
    const billingContact = await TestOpsBillingInformationService.getTestOpsBillingInformation({ field: 'organizationId', operator: Operator.EQ, value: organizationId.toString() });

    if (billingContact.length === 0) {
      throw new Error();
    }

    return billingContact;
  },
);

export const doUpdateTestOpsBillingInformation = createAsyncThunk(
  'testOpsBillingInformation/updateTestOpsBillingInformation',
  async (input: Parameters<typeof TestOpsBillingInformationService['upsertTestOpsBillingInformation']>[0]) => {
    const billingInformation = await TestOpsBillingInformationService
      .upsertTestOpsBillingInformation(input);
    return billingInformation;
  },
);

const testOpsBillingInformationSlice = createSlice({
  name: TEST_OPS_BILLING_INFORMATION_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doCreateTestOpsBillingInformation.pending, state => {
      state.loading = true;
    });
    builder.addCase(doCreateTestOpsBillingInformation.fulfilled, (state, action) => {
      state.loading = false;
      state.testOpsBillingInformation = action.payload;
      state.selectedId = action.payload.id;
    });
    builder.addCase(doCreateTestOpsBillingInformation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(doGetTestOpsBillingInformation.pending, state => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(doGetTestOpsBillingInformation.fulfilled, (state, action) => {
      state.loading = false;
      [state.testOpsBillingInformation] = action.payload;
      state.selectedId = action.payload[0].id;
    });
    builder.addCase(doGetTestOpsBillingInformation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.testOpsBillingInformation = null;
    });
    builder.addCase(doUpdateTestOpsBillingInformation.pending, state => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(doUpdateTestOpsBillingInformation.fulfilled, (state, action) => {
      state.loading = false;
      state.testOpsBillingInformation = action.payload;
    });
    builder.addCase(doUpdateTestOpsBillingInformation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

const selectTestOpsBillingInformationFeature = (state: AppState) => state[
  TEST_OPS_BILLING_INFORMATION_FEATURE_KEY
];
export const selectTestOpsBillingInformation = createSelector(
  selectTestOpsBillingInformationFeature,
  billingContactState => billingContactState.testOpsBillingInformation,
);
export const selectSelectedId = createSelector(
  selectTestOpsBillingInformationFeature,
  billingContactState => billingContactState.selectedId,
);
export const selectLoading = createSelector(
  selectTestOpsBillingInformationFeature,
  billingContactState => billingContactState.loading,
);
export const selectError = createSelector(
  selectTestOpsBillingInformationFeature,
  billingContactState => billingContactState.error,
);

export default testOpsBillingInformationSlice.reducer;
