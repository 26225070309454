import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { AppState } from '.';
import { MetricData, Organization, TestCloudUsage } from '../models';
import { IntervalUnit, MetricQuery, Operator, Statistic } from '../models/query';
import { TestCloudUsageEventType } from '../models/testCloudUsageEventType';
import { TestCloudUsageService } from '../services';

export const TESTCLOUD_USAGE_KEY = 'testCloudUsage';

interface TestCloudUsageSlice {
  lastestTestCloudUsage: TestCloudUsage | null;
  fallbackTestCloudUsages: TestCloudUsage[] | null;
  concurrentSessionMetricData: MetricData[] | null;
  adjustedConcurrentSessionMetricData: MetricData[] | null;
  loading: boolean;
  error?: string;
}

export const createInitialState = (): TestCloudUsageSlice => ({
  fallbackTestCloudUsages: null,
  concurrentSessionMetricData: null,
  adjustedConcurrentSessionMetricData: null,
  lastestTestCloudUsage: null,
  loading: false,
});

export const doGetLatestTestCloudUsage = createAsyncThunk(
  'testCloudUsage/getLatest',
  async (input: Required<Pick<TestCloudUsage, 'organizationId'>>) => {
    const lastestTestCloudUsage = TestCloudUsageService.getLatestTestCloudUsage(input);
    return lastestTestCloudUsage;
  },
);

// This function would send 6 requests to backend, make sure it's only called once.
export const doGetTestCloudDashboardData = createAsyncThunk(
  'testCloudUsage/getMetrics',
  async (input: {
    organizationId: Organization['id'],
    startDate: Date,
    endDate: Date,
  }) => {
    const { startDate, endDate, organizationId } = input;
    const { timeZone } = new Intl.DateTimeFormat().resolvedOptions();
    const baseParams: MetricQuery<TestCloudUsage>[] = [
      { field: 'organizationId', operator: Operator.EQ, value: organizationId },
      {
        field: 'timestamp',
        startTime: startDate.getTime(),
        endTime: endDate.getTime(),
        interval: 1,
        unit: IntervalUnit.DAYS,
        timezone: timeZone,
      },
    ];
    const lastRecordEachPeriod = await TestCloudUsageService.getTestCloudUsageMetrics(
      ...baseParams,
      { field: 'timestamp', statistic: Statistic.GREATEST },
    );
    const metricDataResponses = await Promise.all([
      TestCloudUsageService.getTestCloudUsageMetrics(
        ...baseParams,
        { field: 'concurrentSessions', statistic: Statistic.MAX },
      ),
      // exclude SESSION_ENDED records whose timestamp is not the begin of a period
      TestCloudUsageService.getTestCloudUsageMetrics(
        ...baseParams,
        { field: 'eventType', operator: Operator.EQ, value: TestCloudUsageEventType.SESSION_ENDED },
        { field: 'timestamp', operator: Operator.NI, value: lastRecordEachPeriod.data.map(it => it.startTime) },
        { field: 'concurrentSessions', statistic: Statistic.MAX },
      ),
    ]);
    metricDataResponses[1].data.forEach(it => it.value = it.value!! + 1);
    const usageResponses = await Promise.all([
      TestCloudUsageService.getTestCloudUsage(
        { field: 'organizationId', operator: Operator.EQ, value: organizationId },
        { field: 'timestamp', operator: Operator.LE, value: startDate.getTime() },
        { limit: 1 },
        { field: 'timestamp', desc: true },
      ),
      TestCloudUsageService.getTestCloudUsage(
        { field: 'timestamp', operator: Operator.IN, value: lastRecordEachPeriod.data.map(it => it.value) },
        { field: 'timestamp', desc: false },
      ),
    ]);
    return {
      concurrentSessionMetricData: metricDataResponses[0].data,
      adjustedConcurrentSessionMetricData: metricDataResponses[1].data,
      fallbackTestCloudUsages: [...usageResponses[0].data, ...usageResponses[1].data],
    };
  },
);

const testCloudUsageSlice = createSlice({
  name: TESTCLOUD_USAGE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doGetLatestTestCloudUsage.pending, state => {
      state.loading = true;
      state.error = '';
      state.lastestTestCloudUsage = null;
    });
    builder.addCase(doGetLatestTestCloudUsage.fulfilled, (state, action) => {
      state.loading = false;
      state.lastestTestCloudUsage = action.payload;
    });
    builder.addCase(doGetLatestTestCloudUsage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.lastestTestCloudUsage = null;
    });
    builder.addCase(doGetTestCloudDashboardData.pending, state => {
      state.loading = true;
      state.error = '';
      state.concurrentSessionMetricData = null;
    });
    builder.addCase(doGetTestCloudDashboardData.fulfilled, (state, action) => {
      state.loading = false;
      const { payload } = action;
      state.concurrentSessionMetricData = payload.concurrentSessionMetricData;
      state.adjustedConcurrentSessionMetricData = payload.adjustedConcurrentSessionMetricData;
      state.fallbackTestCloudUsages = payload.fallbackTestCloudUsages;
    });
    builder.addCase(doGetTestCloudDashboardData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.concurrentSessionMetricData = null;
    });
  },
});

const selectTestCloudUsage = (state: AppState) => state[TESTCLOUD_USAGE_KEY];

export const selectLatestTestCloudUsage = createSelector(
  selectTestCloudUsage,
  testCloudUsageState => testCloudUsageState.lastestTestCloudUsage,
);

export const selectFallbackTestCloudUsages = createSelector(
  selectTestCloudUsage,
  it => it.fallbackTestCloudUsages,
);

export const selectConcurrentSessionMetricData = createSelector(
  selectTestCloudUsage,
  it => it.concurrentSessionMetricData,
);

export const selectAdjustedConcurrentSessionMetricData = createSelector(
  selectTestCloudUsage,
  it => it.adjustedConcurrentSessionMetricData,
);

export default testCloudUsageSlice.reducer;
