import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { fromStudioSubscriptions, fromSubscriptions, useAppDispatch } from '../../../../../store';
import BaseLicenseAllocationDetail from '../../base/BaseLicenseAllocationDetail';
import { useQuery } from '../../../../../routes';
import LoadingProgress from '../../../../../layout/LoadingProgress';
import { AccountUser } from '../../../../../models';
import { LicenseAllocationTab } from '../../../model/LicenseAllocationTab';
import BaseUpgradeLicenseView from '../../base/BaseUpgradeLicenseView';

interface KseLicenseAllocationProps {
  currentAccountUser: AccountUser | undefined,
}

const KseLicenseAllocation = (props: KseLicenseAllocationProps) => {
  const { currentAccountUser } = props;

  const dispatch = useAppDispatch();
  const query = useQuery();
  const intl = useIntl();

  const accountId = query.get('accountId');

  const [isDataFetched, setIsDataFetched] = useState(false);
  const [totalKseLicenses, setTotalKseLicenses] = useState(0);
  const [allocatedKseLicenses, setAllocatedKseLicenses] = useState(0);
  const [allocatedHybridKseLicenses, setAllocatedHybridKseLicenses] = useState(0);
  const [totalHybridKseLicenses, setTotalHybridKseLicenses] = useState(0);

  const kseLicenses = useSelector(
    fromStudioSubscriptions.selectPaidKseSubscriptionByAccountId(
      Number(accountId),
    ),
  );

  const hybridKseLicenses = useSelector(
    fromStudioSubscriptions.selectPaidHybridKseSubscriptionByAccountId(
      Number(accountId),
    ),
  );

  useEffect(() => {
    setAllocatedKseLicenses(0);
    setAllocatedHybridKseLicenses(0);
  }, []);

  useEffect(() => {
    setIsDataFetched(false);
    const fetchInfo = async () => {
      await Promise.all([
        dispatch(fromStudioSubscriptions.doGetActiveByAccountId(
          { accountId: Number(accountId) },
        )),
        dispatch(
          fromSubscriptions.doGetActiveRecurlySubscriptionsByAccountId(
            { id: Number(accountId) },
          ),
        ),
      ]);
      setIsDataFetched(true);
    };
    fetchInfo();
  }, [accountId]);

  useEffect(() => {
    if (kseLicenses.length === 0) setTotalKseLicenses(0);

    const total = kseLicenses.map(license => license.quota).reduce((a, b) => a + b, 0);
    setTotalKseLicenses(total);
  }, [kseLicenses]);

  useEffect(() => {
    if (hybridKseLicenses.length === 0) setTotalHybridKseLicenses(0);

    const total = hybridKseLicenses.map(license => license.quota).reduce((a, b) => a + b, 0);
    setTotalHybridKseLicenses(total);
  }, [hybridKseLicenses]);

  const adjustAllocatedKseLicense = (count: number) => {
    setAllocatedKseLicenses(allocatedKseLicenses + count);
  };

  const adjustAllocatedHybridKseLicense = (count: number) => {
    setAllocatedHybridKseLicenses(allocatedHybridKseLicenses + count);
  };

  return (
    <>
      {isDataFetched
        && totalKseLicenses === 0
        && totalHybridKseLicenses === 0
        && (
          <BaseUpgradeLicenseView
            accountId={Number(accountId)}
            accountUserRole={currentAccountUser?.role}
            licenseAllocationTab={LicenseAllocationTab.KSE}
          />
        )}
      {isDataFetched
        && (totalKseLicenses > 0 || totalHybridKseLicenses > 0)
        && (
          <BaseLicenseAllocationDetail
            currentAccountUser={currentAccountUser}
            licenseAllocationTab={LicenseAllocationTab.KSE}
            licenseDetails={[
              {
                title: intl.formatMessage({ id: 'licenses.allocation.tab.kse.kse_licenses.title' }),
                total: totalKseLicenses,
                available: totalKseLicenses - allocatedKseLicenses,
              },
              {
                title: intl.formatMessage({ id: 'licenses.allocation.tab.kse.hybrid_kse_licenses.title' }),
                total: totalHybridKseLicenses,
                available: totalHybridKseLicenses - allocatedHybridKseLicenses,
              },
            ]}
            adjustAllocatedKseLicense={adjustAllocatedKseLicense}
            adjustAllocatedHybridKseLicense={adjustAllocatedHybridKseLicense}
          />
        )}
      {!isDataFetched && (<LoadingProgress />)}
    </>
  );
};

export default KseLicenseAllocation;
