import React, { useState } from 'react';
import ListItem from '@mui/material/ListItem';
import Popover from '@mui/material/Popover';
import makeStyles from '@mui/styles/makeStyles';

export interface PopoverDropdownMenuProps {
  items: React.ReactElement[],
  icon: React.ReactNode,
}
const useStyles = makeStyles((theme => ({
  menuItems: {
    padding: theme.spacing(0),
    maxWidth: theme.spacing(24),
  },
})));

const PopoverDropdownMenu = (props: PopoverDropdownMenuProps) => {
  const {
    items,
    icon,
  } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'popover-dropdown-toggle' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      { icon
      && (
      <div aria-describedby={id} onClick={handleClick}>
        {icon}
      </div>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {/**
         * Temporary use item as a key when the item is unique
         * Should find the way to generate unique key for non-uqnique object
         * */}
        {items.map(item => (
          <ListItem
            key={item.key}
            onClick={handleClose}
            className={classes.menuItems}
          >
            {item}
          </ListItem>
        ))}
      </Popover>
    </div>
  );
};

export default PopoverDropdownMenu;
