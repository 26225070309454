import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { unwrapResult } from '@reduxjs/toolkit';
import { getData } from 'country-list';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { object, string } from 'yup';
import LoadingProgress from '../../layout/LoadingProgress';
import { BillingInformation, Organization } from '../../models';
import { fromAccounts, fromAccountUsers, fromAuth, useAppDispatch } from '../../store';
import { fromBillingInformation } from '../../store/rootReducer';
import CountriesSelection from './CountriesSelection';

const useStyles = makeStyles(theme => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
  },
  label: {
    fontWeight: '500',
    marginBottom: theme.spacing(1),
    color: '#1D3066',
  },
  typographyButton: {
    margin: '0 auto',
    textTransform: 'capitalize',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  description: {
    fontSize: theme.spacing(1.5),
    fontStyle: 'italic',
    color: '#62839F',
    paddingTop: theme.spacing(1),
  },
  warning: {
    fontSize: theme.spacing(1.5),
    fontStyle: 'italic',
    color: 'red',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  billingInformationText: {
    marginTop: theme.spacing(1),
  },
  informationText: {
    marginBottom: theme.spacing(3),
  },
  getQuoteSubtitle: {
    marginLeft: theme.spacing(1),
    fontSize: theme.spacing(1.75),
  },
  getQuoteTitle: {
    fontSize: theme.spacing(2.5),
    display: 'inline',
  },
  getQuoteTitleContainer: {
    borderBottom: '1px solid #D5D8DD',
    paddingBottom: theme.spacing(2),
  },
}));

const inputs: Array<keyof Required<Omit<BillingInformation, 'id' | 'createdAt' | 'updatedAt' | 'accountId' | 'archived'>>> = [
  'ccEmail',
  'address1',
  'address2',
  'city',
  'state',
  'country',
  'postalCode',
  'vatNumber',
  'fullBusinessName',
];

// use different name to avoid confuse with BillingInformation model
const BillingInformationComponent = (props: Required<Pick<Organization, 'accountId'>>) => {
  const classes = useStyles();
  const { accountId } = props;
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector(fromAccounts.selectAccountById(Number(accountId)));
  const user = useSelector(fromAuth.selectUser);
  const currentBillingInformation = useSelector(fromBillingInformation.selectOneByAccountId(
    Number(accountId),
  ));
  const accountTotalMembers = useSelector(fromAccountUsers.selectCountByAccountId(
    Number(accountId),
  ));
  const currentAccountUser = useSelector(fromAccountUsers.selectOneByUserIdAndAccountId(
    Number(user?.id),
    Number(account?.id),
  ));
  const countryOptions = getData();
  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState(false);

  const schema = object().shape({
    ccEmail: string()
      .notRequired()
      .matches(
        /^$|[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.email.invalid' }),
      )
      .email(
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.email.invalid' }),
      ),
    fullBusinessName: string().trim()
      .required(
        intl.formatMessage(
          { id: 'billinginfo.billingcontact.error.required' },
          { field: 'Full Business Name' },
        ),
      ),
    country: string().trim()
      .required(
        intl.formatMessage(
          { id: 'billinginfo.billingcontact.error.required' },
          { field: 'Country' },
        ),
      )
      .matches(
        /^[a-zA-Z,. ]*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.country.invalid' }),
      ),
    state: string().trim()
      .required(
        intl.formatMessage(
          { id: 'billinginfo.billingcontact.error.required' },
          { field: 'State' },
        ),
      )
      .matches(
        /^[a-zA-Z ,.;'&/()-]*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.state.invalid' }),
      ),
    city: string().trim()
      .required(
        intl.formatMessage(
          { id: 'billinginfo.billingcontact.error.required' },
          { field: 'City' },
        ),
      )
      .matches(
        /^[a-zA-Z ,.;'&/()-]*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.city.invalid' }),
      ),
    address1: string().trim()
      .required(
        intl.formatMessage(
          { id: 'billinginfo.billingcontact.error.required' },
          { field: 'Address Line 1' },
        ),
      )
      .matches(
        /^[a-zA-Z0-9# .,;:'°]*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.address.invalid' }),
      ),
    address2: string()
      .matches(
        /^[a-zA-Z0-9# .,;:'°]*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.address.invalid' }),
      ),
    postalCode: string().trim()
      .required(
        intl.formatMessage(
          { id: 'billinginfo.billingcontact.error.required' },
          { field: 'Postal Code' },
        ),
      )
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.postalCode.invalid' }),
      ),
    vatNumber: string()
      .matches(
        /^[a-zA-Z0-9 .-]*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.vatId.invalid' }),
      ),
  });
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    clearErrors,
  } = useForm<Required<Omit<BillingInformation, 'id' | 'createdAt' | 'updatedAt' | 'accountId' | 'archived'>>>({
    defaultValues: {
      ccEmail: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
      vatNumber: '',
      fullBusinessName: '',
    },
    resolver: yupResolver(schema),
  });

  const mapStateDataToInputs = () => {
    if (currentBillingInformation) {
      inputs.forEach(input => {
        if (currentBillingInformation[input]) {
          setValue(
            input,
            currentBillingInformation[input].toString(),
          );
        } else {
          setValue(
            input,
            '',
          );
        }
      });
    }
  };

  const handleCancel = () => {
    clearErrors();
    setOpenForm(false);
  };
  useEffect(() => {
    mapStateDataToInputs();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [currentBillingInformation, setValue]);

  const formBillingInformation = () => (
    <form onSubmit={handleSubmit(submitBillingContact)}>
      {inputs.map(inputKey => (
        <Grid key={inputKey} container className={classes.formContainer}>
          <Grid>
            <Typography variant="h5" className={classes.label}>
              {intl.formatMessage({
                id: `billinginfo.billingcontact.${inputKey}`,
              })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field }) => (inputKey !== 'country' ? (
                <TextField
                  sx={{
                    maxWidth: '50%',
                  }}
                  id={inputKey}
                  {...field}
                  variant="outlined"
                  fullWidth
                  placeholder={intl.formatMessage({
                    id: `billinginfo.billingcontact.placeholder.${inputKey}`,
                  })}
                  size="small"
                  error={!!errors[inputKey]}
                  helperText={errors[inputKey]?.message}
                />
              ) : (
                <CountriesSelection
                  field={field}
                  inputKey={inputKey}
                  errors={errors}
                />
              ))}
              name={inputKey}
              control={control}
            />

          </Grid>
        </Grid>
      ))}
      <br />
      <Box display="flex" flexDirection="row">
        <Button
          variant="contained"
          color="primary"
          type="submit"
        >
          {intl.formatMessage({ id: 'billinginfo.billingcontact.button.save' })}
        </Button>
        {currentBillingInformation && (
          <Button
            variant="contained"
            color="inherit"
            onClick={handleCancel}
            sx={{
              ml: '1rem',
            }}
          >
            {intl.formatMessage({ id: 'billinginfo.billingcontact.button.cancel' })}
          </Button>
        )}
      </Box>
      {loading && <LoadingProgress />}
    </form>
  );

  const billingInformationText = () => (
    <Grid className={classes.billingInformationText}>
      {inputs.map(inputKey => (
        <Grid key={inputKey} className={classes.informationText}>
          <Typography variant="h5" className={classes.label}>
            {intl.formatMessage({
              id: `billinginfo.billingcontact.${inputKey}`,
            })}
          </Typography>
          <Typography>
            {currentBillingInformation && (inputKey === 'country' ? countryOptions.find(it => it.code === currentBillingInformation[inputKey])?.name : currentBillingInformation[inputKey])}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );

  const submitBillingContact = async (
    inputs: Required<Omit<BillingInformation, 'id' | 'createdAt' | 'updatedAt' | 'accountId' | 'archived'>>,
  ) => {
    setLoading(true);
    await dispatch(
      fromBillingInformation.doCreateBillingInformation({
        accountId: Number(accountId),
        ...inputs,
      }),
    )
      .then(unwrapResult)
      .then(() => {
        enqueueSnackbar(
          <FormattedMessage id="billinginfo.billingcontact.update.success" />,
          { variant: 'success' },
        );
        setOpenForm(false);
        analytics.track(
          'Billing Information Saved',
          {
            user_id: user?.id,
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
            system_role: user?.roles,
            org_role: currentAccountUser?.role,
            org_id: account?.id,
            org_name: account?.name,
            total_members: accountTotalMembers,
          },
        );
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          user_id: user?.id,
          org_role: currentAccountUser?.role,
          system_role: user?.roles,
          org_id: account?.id,
          org_name: account?.name,
          total_members: accountTotalMembers,
          event: 'gtm_billing_information_saved',
        });
      })
      .catch((ex: any) => {
        // If error, restore inputs value to default value
        enqueueSnackbar(
          intl.formatMessage({ id: 'billing.payment.error.billing_info' }, { code: ex.message }),
          { variant: 'error' },
        );
        mapStateDataToInputs();
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        alignContent="center"
        alignSelf="center"
        sx={{ borderBottom: '1px solid #DCDFE6', paddingBottom: '0.5rem' }}
      >
        <Typography sx={{ fontWeight: 500, fontSize: '1.125rem' }}>
          {intl.formatMessage({ id: 'billing.information' })}
        </Typography>
        <Button disabled={openForm || !currentBillingInformation} onClick={() => setOpenForm(true)} sx={{ ml: '1rem' }} variant="outlined">
          {intl.formatMessage({ id: 'billinginfo.billingcontact.button.update' })}
        </Button>
      </Box>
      {(currentBillingInformation && !openForm)
        ? billingInformationText()
        : formBillingInformation()}
    </>
  );
};

export default BillingInformationComponent;
