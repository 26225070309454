import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Link from '@mui/material/Link';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import { Role } from '../../models/role';
import { login as loginPath, resolvePath } from '../../routes';
import { UserService } from '../../services';
import { fromAuth, useAppDispatch } from '../../store';
import LoadingProgress from '../../layout/LoadingProgress';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100vw',
    minHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      '& > *': {
        width: '100vw',
        height: '100vh',
      },
    },
    backgroundPosition: 'flex',
    background: 'linear-gradient(45deg, #4dc3ff 40%, #59a136 80%)',
    color: theme.palette.background.default,
  },
  logo: {
    position: 'fixed',
    top: 40,
    left: 50,
    width: 161,
    [theme.breakpoints.between('xs', 'md')]: {
      display: 'none',
    },
  },
  card: {
    margin: 'auto',
    width: '100%',
    minWidth: 300,
    maxWidth: '35vw',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '70vw',
    },
    borderRadius: '5px',
    backgroundColor: alpha(theme.palette.background.default, 0.1),
    padding: '10px',
  },
  form: {
    flex: '1 1 auto',
    display: 'flex',
    minHeight: 500,
    fontWeight: 'bold',
  },
  header: {
    position: 'absolute',
    top: 40,
    right: 50,
    display: 'flex',
    alignItems: 'baseline',
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > *': {
      marginTop: theme.spacing(2),
    },
    color: theme.palette.background.default,
  },
  error: {
    color: theme.palette.error.main,
    margin: 0,
  },
  button: {
    color: theme.palette.background.default,
  },
  inputLabel: {
    color: theme.palette.background.default,
  },
  textField: {
    color: theme.palette.background.default,
  },
}));

const GettingStartedComponent = () => {
  const classes = useStyles();
  const isAuthenticated = useSelector(fromAuth.selectIsAuthenticated);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = e => (
    setEmail(e.target.value)
  );
  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = e => (
    setPassword(e.target.value)
  );
  const handleError = (errors: string[]) => {
    setErrors(errors);
    setLoading(false);
  };

  const doSignup = async (e: React.FormEvent<HTMLFormElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (email && password) {
      const formattedEmail = email.toLowerCase();
      setLoading(true);
      try {
        await UserService.createUser({
          email: formattedEmail,
          password,
          roles: [Role.USER, Role.ROOT],
        });
        await dispatch(fromAuth.doLogin({
          userInfo: {
            email: formattedEmail,
            password,
          },
          rememberMe: true,
        }));
      } catch (e: any) {
        handleError(e.response && e.response.data.errors);
        window.location.href = '/';
      }
    }
  };

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <div className={classes.root}>
        <Link href={process.env.REACT_APP_WEBSITE_URL}>
          <img alt="logo" src="https://d1h3p5fzmizjvp.cloudfront.net/themes/katalon_4/images/katalon_template_1809/logo@2x.png" className={classes.logo} />
        </Link>
        <form className={classes.form} onSubmit={doSignup}>
          <Box className={classes.card}>
            <CardHeader
              style={{ textAlign: 'center' }}
              title={<FormattedMessage id="root.signup.form.title" />}
            />
            <div className={classes.header}>
              <Typography><FormattedMessage id="signup.header.haveaccount" /></Typography>
              <Button
                variant="outlined"
                size="large"
                component={RouterLink}
                to={resolvePath(loginPath, undefined, {}, false)}
                className={classes.button}
              >
                <FormattedMessage id="signup.header.login" />
              </Button>
            </div>
            <CardContent classes={{ root: classes.content }}>
              <TextField
                variant="outlined"
                type="email"
                fullWidth
                required
                label={<FormattedMessage id="signup.form.bussinessemail" />}
                error={!email}
                onChange={handleEmailChange}
                inputProps={{ maxLength: 255 }}
                InputProps={{ classes: { root: classes.textField } }}
                InputLabelProps={{ classes: { root: classes.inputLabel } }}
              />
              {errors && errors.includes('uq_users_email') && (
              <Typography className={classes.error} variant="caption">
                <FormattedMessage id="error.uq_users_email" />
              </Typography>
              )}
              <TextField
                variant="outlined"
                fullWidth
                required
                type="password"
                inputProps={{ minLength: 8 }}
                label={<FormattedMessage id="signup.form.password" />}
                error={!password}
                onChange={handlePasswordChange}
                InputProps={{ classes: { root: classes.textField } }}
                InputLabelProps={{ classes: { root: classes.inputLabel } }}
              />
            </CardContent>
            <CardActions style={{ justifyContent: 'center' }}>
              <Button variant="contained" size="medium" color="secondary" type="submit"><FormattedMessage id="signup.form.getstarted" /></Button>
            </CardActions>
          </Box>
        </form>
      </div>
      {loading && <LoadingProgress />}
    </>
  );
};

export default GettingStartedComponent;
