import Button from '@katalon-studio/katalon-ui/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';
import { ReactComponent as UpgradeLicenseIcon } from '../icons/LicenseAllocationUpgrade.svg';
import { AccountRole } from '../../../../models/accountRole';
import { useNavigate } from '../../../../routes/useNavigate';
import { accountSubscriptionManagement } from '../../../../layout/routes';
import { LicenseAllocationTab } from '../../model/LicenseAllocationTab';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(3, 0),
  },
  paperContainer: {
    height: '70vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(1, 2),
  },
  upgradeTitle: {
    fontSize: theme.spacing(2.5),
    fontWeight: 'bold',
    maxWidth: theme.spacing(55),
    textAlign: 'center',
  },
  upgradeContent: {
    fontSize: theme.spacing(1.75),
    maxWidth: theme.spacing(55),
    textAlign: 'center',
  },
  upgradeButton: {
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
    backgroundColor: '#1847ca',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#1847ca',
    },
  },
  boxSizing: {
    height: theme.spacing(2),
  },
}));

interface BaseUpgradeLicenseViewProps {
  accountId: number;
  accountUserRole: AccountRole | undefined;
  licenseAllocationTab: LicenseAllocationTab;
}

const BaseUpgradeLicenseView = (props: BaseUpgradeLicenseViewProps) => {
  const {
    accountUserRole,
    accountId,
    licenseAllocationTab,
  } = props;

  const classes = useStyles();
  const intl = useIntl();
  const { navigate, replaceQuery } = useNavigate();

  const onClick = () => navigate(accountSubscriptionManagement.path, replaceQuery({ accountId }));

  return (
    <div className={classes.root}>
      <Paper className={classes.paperContainer}>
        <UpgradeLicenseIcon />
        <Box className={classes.boxSizing} />
        <Typography className={classes.upgradeTitle}>
          {intl.formatMessage({ id: `licenses.allocation.${licenseAllocationTab.toLowerCase()}.upgrade_account.title` })}
        </Typography>
        <Box className={classes.boxSizing} />
        <Typography className={classes.upgradeContent}>
          {AccountRole.isOwner(accountUserRole) && intl.formatMessage({ id: `licenses.allocation.${licenseAllocationTab.toLowerCase()}.upgrade_account.owner.content` })}
          {AccountRole.isAdmin(accountUserRole) && intl.formatMessage({ id: `licenses.allocation.${licenseAllocationTab.toLowerCase()}.upgrade_account.admin.content` })}
        </Typography>
        {
          AccountRole.isOwner(accountUserRole) && (
            <>
              <Box className={classes.boxSizing} />
              <Button
                id="licenses.allocation.upgrade_account.button"
                size="small"
                variant="contained"
                className={classes.upgradeButton}
                onClick={onClick}
              >
                {intl.formatMessage({ id: `licenses.allocation.${licenseAllocationTab.toLowerCase()}.upgrade_account.button` })}
              </Button>
            </>
          )
        }
      </Paper>
    </div>
  );
};

export default BaseUpgradeLicenseView;
