import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import { AppState } from '.';
import { exceptionOf, Organization, SerializedException, ServiceCloudOrganizationUser } from '../models';
import { Operator, Query } from '../models/query';
import { ServiceCloudOrganizationUserService } from '../services';

export const SERVICE_CLOUD_ORG_USER_KEY = 'serviceCloudOrgUser';
export const ERROR_SERVICE_CLOUD_CANNOT_ASSIGN_OWNER = 'service.cloud.organization.user.cannot.assign.owner';
export const ERROR_SERVICE_CLOUD_USER_DOES_NOT_EXIST = 'service.cloud.organization.user.user.does.not.exist';
export const ERROR_SERVICE_CLOUD_USER_ALREADY_ASSIGNED = 'service.cloud.organization.user.already.assigned';
export const ERROR_SERVICE_CLOUD_QUOTA_EXCEEDED = 'service.cloud.organization.user.quota.exceeded';
export const ERROR_SERVICE_CLOUD_USER_DOES_NOT_HAVE_PERMISSION = 'service.cloud.organization.user.does.not.have.permission';
export const ERROR_SERVICE_CLOUD_INVALID_ID = 'service.cloud.organization.user.invalid.id';
export const ERROR_SERVICE_CLOUD_DESELECT_ONLY_MEMBER = 'service.cloud.organization.user.deselect.only.member';

type ServiceCloudOrganizationUserEntity = ServiceCloudOrganizationUser;

const serviceCloudOrganizationUserAdapter = createEntityAdapter
<ServiceCloudOrganizationUserEntity>();

interface ServiceCloudOrganizationUserState
  extends EntityState<ServiceCloudOrganizationUserEntity> {
  loading: boolean;
  errors: SerializedException[];
  count: number;
  activeServiceCloudOrganizationUser: ServiceCloudOrganizationUser | undefined;
}
const MAX_NUMBER_OF_RECORDS = 50;
export const createInitialState = (): ServiceCloudOrganizationUserState => (
  serviceCloudOrganizationUserAdapter.getInitialState({
    loading: false,
    count: 0,
    errors: [],
    activeServiceCloudOrganizationUser: undefined,
  })
);

export const doGetAllServiceCloudOrganizationUsers = createAsyncThunk(
  'organizationUserSalesforce/getAllOrgUsersSalesforce',
  async (input: Pick<ServiceCloudOrganizationUser, 'organizationId'>) => {
    const criteria: Array<Query<ServiceCloudOrganizationUser>> = [
      { field: 'organizationId', operator: Operator.EQ, value: input.organizationId },
      { limit: MAX_NUMBER_OF_RECORDS },
    ];

    const firstResponse = await ServiceCloudOrganizationUserService
      .getServiceCloudOrganizationUsers(...criteria);
    const numberOfRequests = Math.ceil(firstResponse.total / MAX_NUMBER_OF_RECORDS);
    const remainingRequests = [];
    for (let i = 1; i < numberOfRequests; i += 1) {
      const req = ServiceCloudOrganizationUserService.getServiceCloudOrganizationUsers(
        ...criteria,
        { offset: MAX_NUMBER_OF_RECORDS * i },
      );
      remainingRequests.push(req);
    }
    const remainingResponses = await Promise.all(remainingRequests);
    const serviceCloudOrgUsers = [...firstResponse.data];
    remainingResponses.forEach(r => {
      serviceCloudOrgUsers.push(...r.data);
    });
    return { serviceCloudOrgUsers };
  },
);

export const doCreateServiceCloudOrganizationUser = createAsyncThunk(
  'organizationUserSalesforce/createServiceCloudOrganizationUser',
  async (input: Required<Pick<ServiceCloudOrganizationUser, 'organizationId' | 'email'>>, { rejectWithValue }) => {
    try {
      const data = await ServiceCloudOrganizationUserService
        .createServiceCloudOrganizationUser(input);
      return { data };
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doDeleteServiceCloudOrganizationUserById = createAsyncThunk(
  'organizationUserSalesforce/deleteById',
  async (input: Required<Pick<ServiceCloudOrganizationUser, 'id'>>, { rejectWithValue }) => {
    try {
      await ServiceCloudOrganizationUserService
        .deleteServiceCloudOrganizationUser({ id: input.id });
      return input;
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doGetActiveServiceCloudOrganizationUser = createAsyncThunk(
  'organizationUserSalesforce/getActiveServiceCloudOrganizationUser',
  async () => {
    const data = await ServiceCloudOrganizationUserService
      .getActiveServiceCloudOrganizationUser();
    return data;
  },
);

const serviceCloudOrganizationUserSlice = createSlice({
  name: SERVICE_CLOUD_ORG_USER_KEY,
  initialState: createInitialState(),
  reducers: {
    doResetErrors(state) {
      state.errors = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(doGetAllServiceCloudOrganizationUsers.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetAllServiceCloudOrganizationUsers.fulfilled, (state, action) => {
      state.loading = false;
      serviceCloudOrganizationUserAdapter.setAll(state, action.payload.serviceCloudOrgUsers);
    });
    builder.addCase(doGetAllServiceCloudOrganizationUsers.rejected, state => {
      state.loading = false;
    });
    builder.addCase(doCreateServiceCloudOrganizationUser.pending, state => {
      state.loading = true;
    });
    builder.addCase(doCreateServiceCloudOrganizationUser.fulfilled, (state, action) => {
      state.loading = false;
      serviceCloudOrganizationUserAdapter.addOne(state, action.payload.data);
    });
    builder.addCase(doCreateServiceCloudOrganizationUser.rejected, (state, action) => {
      const payload = action.payload as SerializedException;
      state.errors.push(payload);
      state.loading = false;
    });
    builder.addCase(doDeleteServiceCloudOrganizationUserById.pending, state => {
      state.loading = true;
    });
    builder.addCase(doDeleteServiceCloudOrganizationUserById.fulfilled, (state, action) => {
      state.loading = false;
      serviceCloudOrganizationUserAdapter.removeOne(state, action.payload.id);
    });
    builder.addCase(doDeleteServiceCloudOrganizationUserById.rejected, (state, action) => {
      const payload = action.payload as SerializedException;
      state.errors.push(payload);
      state.loading = false;
    });
    builder.addCase(doGetActiveServiceCloudOrganizationUser.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetActiveServiceCloudOrganizationUser.fulfilled, (state, action) => {
      state.activeServiceCloudOrganizationUser = action.payload;
      state.loading = false;
    });
    builder.addCase(doGetActiveServiceCloudOrganizationUser.rejected, state => {
      state.loading = false;
    });
  },
});

const selectServiceCloudOrganizationUser = (
  state: AppState,
) => state[SERVICE_CLOUD_ORG_USER_KEY];
export const {
  selectAll: selectAllServiceCloudOrgUsers,
  selectIds,
  selectEntities,
} = serviceCloudOrganizationUserAdapter.getSelectors(selectServiceCloudOrganizationUser);

export const selectByOrganizationId = (organizationId: Organization['id']) => createSelector(
  selectAllServiceCloudOrgUsers,
  users => users
    .filter(user => user.organizationId === organizationId),
);

export const selectLoading = createSelector(
  selectServiceCloudOrganizationUser,
  serviceCloudOrganizationUserState => serviceCloudOrganizationUserState.loading,
);

export const selectErrors = () => createSelector(
  selectServiceCloudOrganizationUser,
  serviceCloudOrganizationUserState => serviceCloudOrganizationUserState.errors,
);

export const selectActiveServiceCloud = createSelector(
  selectServiceCloudOrganizationUser,
  state => state.activeServiceCloudOrganizationUser,
);

export const { doResetErrors } = serviceCloudOrganizationUserSlice.actions;

export default serviceCloudOrganizationUserSlice.reducer;
