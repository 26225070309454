import AllInclusive from '@mui/icons-material/AllInclusive';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { ChartData, ChartOptions } from 'chart.js';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { useIntl } from 'react-intl';
import { useConfig } from '../../../config';
import { SubscriptionSource, TestResultCount, UsagePlan } from '../../../models';
import { UNLIMITED_EXECUTIONS } from '../../home/utils';

const TEST_RESULT_DOC_URL = 'https://docs.katalon.com/docs/administer/katalon-platform-packages/katalon-platform-quotas#test-results';

const useStyles = makeStyles(theme => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  sectionTitle: {
    paddingBottom: '1.25rem',
  },
  infoIcon: {
    verticalAlign: 'middle',
  },
  chartContainer: {
    // position is relative so that Chart can be responsive
    position: 'relative',
    // width 100% to fit the chart when the container/screen width is small
    width: '100%',
    // maxWidth is set to prevent the chart from growing infinitely
    maxWidth: theme.spacing(35),
    padding: '1.5rem 0',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  toUpper: {
    textTransform: 'uppercase',
  },
  textInsideDonut: {
    fontWeight: 'bold',
    color: theme.typography.h2.color ?? '#000',
  },
  tooltipInfoContainer: {
    marginLeft: theme.spacing(-3),
  },
  tooltipInfoContent: {
    marginBottom: theme.spacing(0.5),
  },
  tooltipLink: {
    color: theme.palette.primary.light,
  },
  tooltip: {
    width: theme.spacing(35),
  },
}));

const getDateStringWithFormat = (date: Date | undefined): string => {
  if (date === null || date === undefined) return 'MMM dd, YYYY';
  const d = new Date(date);
  return `${d.toLocaleDateString('default', { month: 'long' })} ${d.getDate()}, ${d.getFullYear()}`;
};

const TooltipInfoContent = () => {
  const intl = useIntl();
  return (
    <div>
      {
        intl.formatMessage({ id: 'dashboard.test_execution_chart.tooltip.info1' }, {
          a: (data: ReactNode) => (
            <Link
              color="inherit"
              underline="always"
              fontWeight={500}
              href={TEST_RESULT_DOC_URL}
            >
              {data}
            </Link>
          ),
        })
      }
    </div>
  );
};

interface TestExecutionUsageChartProps {
  testResultCount: TestResultCount | null;
}

const TestExecutionUsageChart = (props: TestExecutionUsageChartProps) => {
  const { testResultCount } = props;
  const classes = useStyles();
  const [exceeded, setExceeded] = useState(false);
  const intl = useIntl();
  const [dataReady, setDataReady] = useState(false);
  const [statistic, setStatistic] = useState([NaN, NaN]);
  const theme = useTheme();
  const { config } = useConfig();
  const [testBalance, setTestBalance] = useState(0);
  const [isUnlimited, setUnlimited] = useState(false);
  const defineChartBorderColor = () => {
    if (testResultCount) {
      const { quota, count, canSubmit, hardLimit } = testResultCount;
      if (canSubmit) {
        if (count <= quota || isUnlimited) return ['#C4D7E9', '#18CAAA'];
        if (count > quota && count < hardLimit) return ['#FFA776', '#FFA776'];
      }
      return ['#CA444C', '#CA444C'];
    }
    return undefined;
  };
  // Ideally there should be a compute function outside of this component
  // to prevent re-evaluation of functions between re-renders
  const chartData: ChartData = useMemo(() => ({
    datasets: [
      {
        data: exceeded || isUnlimited ? [0, testBalance] : statistic,
        backgroundColor: defineChartBorderColor(),
        borderWidth: 0,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [statistic, exceeded]);
  const chartOptions: ChartOptions = useMemo(() => ({
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1,
    legend: {
      onClick: (e: any) => e.stopPropagation(),
    },
    cutout: '85%',
    plugins: {
      tooltip: { enabled: false },
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [statistic]);

  const getBalanceResultText = () => {
    if (!dataReady) return '';
    if (!isUnlimited) return intl.formatMessage({ id: 'dashboard.test_execution_chart.balance' }, { usage: testBalance });
    return intl.formatMessage({ id: 'dashboard.test_execution_chart.balance_unlimited' });
  };

  useEffect(() => {
    if (testResultCount) {
      const { quota, count } = testResultCount;
      const newStatistic = [Math.max(quota - count, 0), count];
      if (newStatistic !== statistic) {
        // Blindly overwrite statistic will trigger unnecessary recomputation
        // of chartData and chartOptions
        setStatistic(newStatistic);
        setTestBalance(quota - count);
        setExceeded(count > quota);
        setUnlimited(testResultCount.subscriptionSource === SubscriptionSource.TRIAL
          && (testResultCount.usagePlan !== UsagePlan.PLATFORM
            || testResultCount.quota >= UNLIMITED_EXECUTIONS
          ));
        setDataReady(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testResultCount]);

  // Memoize the Doughnut component
  const Doughnut = useMemo(() => (
    dataReady && (
      <Chart
        type="doughnut"
        data={chartData}
        options={chartOptions}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [chartData, chartOptions]);

  return (
    <Box className={classes.section}>
      <Grid container direction="column">
        <Grid item>
          <Typography variant="h3">
            {intl.formatMessage({ id: 'dashboard.test_execution_chart.title' })}
            &nbsp;
            <Tooltip classes={{ tooltip: classes.tooltip }} title={<TooltipInfoContent />} placement="right-end">
              <InfoOutlinedIcon fontSize="inherit" className={classes.infoIcon} />
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" className={classes.toUpper}>
            {intl.formatMessage({ id: 'dashboard.test_execution_chart.from_date' }, { fromDate: getDateStringWithFormat(testResultCount?.usagePeriodStartAt) })}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="column" alignContent="space-around">
        <Tooltip
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: { offset: [-72, -110] },
              },
            ],
          }}
          title={getBalanceResultText()}
          placement="right"
        >
          <Box className={classes.chartContainer}>
            {Doughnut}
          </Box>
        </Tooltip>
      </Grid>
      <Grid container direction="column">
        <Grid item style={{ textAlign: 'center', marginTop: theme.spacing(-25) }}>
          <Typography variant="h2">{intl.formatNumber(statistic[1])}</Typography>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="subtitle2" display="inline" className={classes.textInsideDonut}>
              {intl.formatMessage({ id: 'dashboard.test_execution_chart.limit_test_execution' })}
              &nbsp;
            </Typography>
            {isUnlimited ? (
              <AllInclusive />
            ) : (
              <Typography variant="subtitle2" display="inline" className={classes.textInsideDonut}>
                {intl.formatNumber(testResultCount?.quota ?? 0)}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      {!!testResultCount?.usagePlan
      && testResultCount?.usagePlan !== UsagePlan.COMMUNITY && (
        <Grid container direction="column">
          {config?.onpremise ? (
            <>
              <Grid item>
                <Typography variant="h3">
                  {intl.formatMessage({ id: 'dashboard.test_execution_chart.expiry_date' })}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" className={classes.toUpper}>
                  {getDateStringWithFormat(testResultCount?.subscriptionExpiryDate)}
                </Typography>
              </Grid>
            </>
          ) : (
            testResultCount?.subscriptionCreatedAt
            && testResultCount?.subscriptionExpiryDate
            && (
              <>
                <Grid item>
                  <Typography variant="h3">
                    {intl.formatMessage({ id: 'dashboard.test_execution_chart.billing_cycle' })}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" className={classes.toUpper}>
                    {`${getDateStringWithFormat(testResultCount?.subscriptionCreatedAt)} - ${getDateStringWithFormat(testResultCount?.subscriptionExpiryDate)}`}
                  </Typography>
                </Grid>
              </>
            )
          )}
        </Grid>
      )}
    </Box>
  );
};

export default TestExecutionUsageChart;
