import { useEffect, VFC } from 'react';
import { useSelector } from 'react-redux';

import { useConfig } from '../../config';
import { fromAuth, useAppDispatch } from '../../store';

const Logout: VFC = () => {
  const { config } = useConfig();
  const dispatch = useAppDispatch();
  const isAuthenticated = useSelector(fromAuth.selectIsAuthenticated);

  useEffect(() => {
    dispatch(fromAuth.doLogout());
  }, [dispatch]);

  if (config && !isAuthenticated) {
    // if is onpremises, redirect to TestOps. Otherwise, redirect to website
    // try to use configurable host. If it's invalid, use current host
    if (config.onpremise) {
      try {
        // Rediect to TestOps logout to remove TO token in cookies
        const uri = new URL(`${process.env.REACT_APP_ADMIN_URL}/logout`);
        window.location.replace(uri.toString());
      } catch (e) {
        window.location.replace(`${window.location.origin}/logout`);
      }
    } else {
      window.location.replace(`${window.location.origin}/login`);
    }
  }

  return null;
};

export default Logout;
