import makeStyles from '@mui/styles/makeStyles';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Grid from '@mui/material/Grid';
import PopoverDropdownMenu from '../../../../components/popover/PopoverDropdownMenu';
import {
  Account,
  getFeatureName,
  getUnitName,
  OrganizationFeature,
  RecurlySubscriptionPayloadStatus,
  TestOpsPlanInterval,
  TestOpsSubscriptionSource,
} from '../../../../models';
import TurnOffRenewalDialog from './TurnOffRenewalDialog';
import TurnOnRenewalDialog from './TurnOnRenewalDialog';
import { sendTrackingData } from '../../utils';
import GracePeriodTooltip from '../GracePeriodTooltip';

const useStyles = makeStyles((theme => ({
  tableCell: {
    paddingTop: 5,
    fontSize: 14,
    fontWeight: 500,
    color: '#233145',
    '&:hover': {
      background: '#ffffff',
    },
  },
  tableRow: {
    '&:hover': {
      background: '#ffffff',
    },
  },
  menuItem: {
    width: '100%',
    textAlign: 'left',
  },
  menuIcon: {
    display: 'flex',
  },
  menuIconContainer: {
    margin: 'auto',
    width: 25,
    '&:hover': {
      background: '#f5f5f5',
    },
  },
  canceledAt: {
    paddingLeft: 5,
  },
  cancelLabel: {
    color: 'red',
  },
  reactivateButton: {
    backgroundColor: '#276EF1',
    color: '#fff',
  },
  gracePeriodIcon: {
    marginBottom: theme.spacing(0),
  },
})));

export interface AdditionalSubscriptionRowProps {
  name: string;
  email: string | null;
  organizationId: number;
  currentSubscription?: any;
  initQuantity?: number | string;
  feature: OrganizationFeature;
  trialExpiryDate?: number;
  handleRefreshSubscription: Function;
  account: Account | null;
}

const AdditionalSubscriptionRow = (props: AdditionalSubscriptionRowProps) => {
  const classes = useStyles();
  const {
    name,
    organizationId,
    email,
    currentSubscription,
    initQuantity,
    feature,
    trialExpiryDate,
    handleRefreshSubscription,
    account,
  } = props;

  const billingCycle = currentSubscription?.recurlySubscription
    ? TestOpsPlanInterval.getPlanIntervalName(
      currentSubscription?.billingCycle || currentSubscription?.billingInterval,
    )
    : '-';
  const quantity = currentSubscription
    ? (currentSubscription?.quota || currentSubscription?.concurrentSessions)
    : initQuantity;
  const expiryDate = currentSubscription?.expiryDate
    || currentSubscription?.expiredAt || trialExpiryDate;
  const source = currentSubscription?.source;
  const icon = (
    <div className={classes.menuIconContainer}>
      <MoreHorizIcon className={classes.menuIcon} />
    </div>
  );

  const isTrial = source && source === TestOpsSubscriptionSource.TRIAL_REQUEST;
  const canceled: boolean = !!currentSubscription?.recurlySubscription
    && currentSubscription.recurlySubscription.status === RecurlySubscriptionPayloadStatus.CANCELED;

  const canceledAt: Date | undefined = currentSubscription?.recurlySubscription?.canceledAt;
  const [isOpenCancelDialog, setOpenCancelDialog] = useState(false);
  const [isOpenReactivateDialog, setOpenReactivateDialog] = useState(false);

  const handleOpenCancelDialog = () => {
    setOpenCancelDialog(true);
    sendTrackingData(
      'your_subs_cancellation_clicked',
      account?.id!!,
      organizationId,
    );
  };

  const handleOpenReactivateDialog = () => {
    setOpenReactivateDialog(true);
  };

  const items = [];
  if (!canceled && currentSubscription?.recurlySubscription && !isTrial) {
    items.push(
      <MenuItem
        key={`${name}.cancel.menu`}
        className={`${classes.menuItem} ${classes.cancelLabel}`}
        onClick={() => handleOpenCancelDialog()}
      >
        <FormattedMessage id="subscriptions.testops_platform.platform_subscription_info.cancel" />
      </MenuItem>,
    );
  }

  if (canceled) {
    items.push(
      <MenuItem
        key={`${name}.reactive.menu`}
        className={classes.menuItem}
        onClick={() => handleOpenReactivateDialog()}
      >
        <FormattedMessage id="subscriptions.testops_platform.platform_subscription_info.reactivate" />
      </MenuItem>,
    );
  }

  return (
    <>
      <Grid id="additional-subscription-row" container className={classes.tableRow}>
        <Grid item xs={5} className={classes.tableCell}>
          <FormattedMessage id={name} />
        </Grid>
        <Grid item xs={2} className={classes.tableCell}>
          {billingCycle}
        </Grid>
        <Grid item xs={2.5} className={classes.tableCell}>
          {quantity}
          &nbsp;
          <FormattedMessage id={getUnitName(getFeatureName(feature), quantity)} />
        </Grid>
        <Grid item xs={2} className={classes.tableCell}>
          { expiryDate
            ? (
              <>
                <FormattedDate
                  value={new Date(expiryDate)}
                  month="short"
                  day="numeric"
                  year="numeric"
                />
                <span className={classes.gracePeriodIcon}>
                  <GracePeriodTooltip expiryDate={expiryDate} />
                </span>
              </>
            ) : <span>-</span>}
          { canceled && canceledAt
            && (
              <div>
                Canceled:
                <span className={classes.canceledAt}>
                  <FormattedDate
                    value={new Date(canceledAt)}
                    month="short"
                    day="numeric"
                    year="numeric"
                  />
                </span>
              </div>
            ) }
        </Grid>
        <Grid item xs={0.5} className={classes.tableCell}>
          { items.length > 0
            && (
              <PopoverDropdownMenu
                key={`${name}.dropdown.menu`}
                icon={icon}
                items={items}
              />
            )}
        </Grid>
      </Grid>
      <TurnOffRenewalDialog
        name={name}
        organizationId={organizationId}
        email={email}
        recurlySubcription={currentSubscription?.recurlySubscription}
        isOpenCancelDialog={isOpenCancelDialog}
        setOpenCancelDialog={setOpenCancelDialog}
        handleRefreshSubscription={handleRefreshSubscription}
        accountId={account?.id!!}
      />
      <TurnOnRenewalDialog
        name={name}
        recurlySubcription={currentSubscription?.recurlySubscription}
        isOpenReactivateDialog={isOpenReactivateDialog}
        setOpenReactivateDialog={setOpenReactivateDialog}
        handleRefreshSubscription={handleRefreshSubscription}
      />
    </>
  );
};

export default AdditionalSubscriptionRow;
