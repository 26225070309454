import Axios from 'axios';
import { OrganizationRemovedUser, PageableResponse } from '../models';
import { composeQuery, Query } from '../models/query';

export const getOrganizationRemovedUsers = async (...criteria: Query<OrganizationRemovedUser>[]) => (await Axios.get<PageableResponse<OrganizationRemovedUser>>('/v1/organization-removed-users', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: composeQuery<OrganizationRemovedUser>(...criteria),
  data: {},
})).data;
