import Axios from 'axios';
import { DataResponse, PageableResponse, TestCloudSubscription } from '../models';
import { composeQuery, Query } from '../models/query';

export const getTestCloudSubscriptions = async (
  checkHasPremierSuccess: boolean,
  ...criteria: Query<TestCloudSubscription>[]
) => (
  await Axios.get<PageableResponse<TestCloudSubscription>>('/v1/testcloud-subscriptions', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    params: {
      ...composeQuery<TestCloudSubscription>(...criteria),
      ...(checkHasPremierSuccess && { include: 'premier_success' }),
    },
    data: {},
  })).data;

export const getTestCloudSubscriptionsByAccountId = async (
  checkHasPremierSuccess: boolean,
  ...criteria: Query<TestCloudSubscription>[]
) => (
  await Axios.get<PageableResponse<TestCloudSubscription>>('/v1/account-testcloud-subscriptions', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    params: {
      ...composeQuery<TestCloudSubscription>(...criteria),
      ...(checkHasPremierSuccess && { include: 'premier_success' }),
    },
    data: {},
  })).data;

export const cancelTestCloudSubscription = async (input: Required<Pick<TestCloudSubscription, 'id'>>) => (
  (await Axios.put<DataResponse<TestCloudSubscription>>(`/v1/testcloud-subscriptions/${input.id}/cancel`)).data).data;

export const reactivateTestCloudSubscription = async (input: Required<Pick<TestCloudSubscription, 'id'>>) => (
  (await Axios.put<DataResponse<TestCloudSubscription>>(`/v1/testcloud-subscriptions/${input.id}/reactivate`)).data).data;

export const getAccountTestCloudSubscriptionsOrgLevel = async (
  checkHasPremierSuccess: boolean,
  ...criteria: Query<TestCloudSubscription>[]
) => (
  await Axios.get<PageableResponse<TestCloudSubscription>>('/v1/testcloud-subscriptions/account-subscriptions', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    params: {
      ...composeQuery<TestCloudSubscription>(...criteria),
      ...(checkHasPremierSuccess && { include: 'premier_success' }),
    },
    data: {},
  })).data;
