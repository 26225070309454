import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage, useIntl } from 'react-intl';
import Dialog from '@mui/material/Dialog';
import { useEffect, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { accountSubscriptionManagement } from '../../../../layout/routes';
import { OrderResponse } from '../../../../models';
import { resolvePath, useQuery } from '../../../../routes';
import { fromAccountUsers } from '../../../../store';
import { BUSINESS_EMAIL } from '../../utils';

const useStyles = makeStyles((theme => ({
  rootContainer: {
    backgroundColor: '#fef9e7',
    padding: '8px 24px',
    borderRadius: theme.spacing(0.5),
    margin: theme.spacing(0, 2, 2),
  },
  orderNumberContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: -10,
  },
  orderNumber: {
    fontSize: 20,
    fontWeight: 600,
    color: '#22283c',
    lineHeight: 1.5,
    marginBottom: 5,
  },
  orderStatus: {
    height: 27,
    margin: 0,
    marginLeft: 20,
    alignItems: 'center',
    padding: '0 8px',
    paddingTop: 3,
    borderRadius: 4,
    backgroundColor: '#f0be0d',
    color: '#22283c',
    fontSize: 14,
    lineHeight: 1.5,
    marginBottom: -10,
  },
  orderDetailContainer: {
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  orderDetailTitle: {
    fontSize: 16,
    fontWeight: 500,
    color: '#808b9a',
  },
  orderDetailValue: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#233145',
  },
  description: {
    fontSize: 14,
    lineHeight: 1.5,
    color: '#22283c',
  },
  card: {
    display: 'flex',
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    margin: theme.spacing(0, 0, 0),
    padding: theme.spacing(3, 0, 1, 2),
  },
  cardTitleContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: theme.spacing(3),
    padding: theme.spacing(0, 1, 1, 0),
  },
  dialogContent: {
    lineHeight: 1.5,
    fontSize: theme.spacing(2),
    padding: theme.spacing(0, 0, 3, 2),
  },
  businessEmail: {
    textDecoration: 'none',
    color: '#598ef9',
  },
})));

export interface OrderDetailSectionProps {
  accountId: number,
  pendingOrder: OrderResponse,
}

const OrderDetailSection = (props: OrderDetailSectionProps) => {
  const classes = useStyles();
  const { get } = useQuery();
  const [showInstructionPopUp, setShowInstructionPopUp] = useState(
    (get('showInstructionPopup') ?? 'false') === 'true',
  );
  const {
    accountId,
    pendingOrder,
  } = props;

  const accountUser = useSelector(fromAccountUsers.findAccountUserByAccountId(accountId));
  const intl = useIntl();
  const businessEmailAnchor = () => (
    <a id="order-confirmation-dialog-anchor" className={classes.businessEmail} href={BUSINESS_EMAIL}>
      contact us
    </a>
  );

  const renderInstructionPopUp = () => (
    <Dialog
      open={showInstructionPopUp}
      id="order-confirmation-dialog"
      onClose={handleCloseInstructionDialog}
      maxWidth="md"
      placeholder="center"
      fullWidth
      PaperProps={{
        sx: {
          paddingX: 2,
          borderRadius: 1.5,
        },
      }}
    >
      <div className={classes.card}>
        <div id="order-confirmation-dialog-title" className={classes.cardTitleContent}>
          <DialogTitle className={classes.title}>
            <FormattedMessage id="checkout.orderconfirmation" />
          </DialogTitle>
          <IconButton
            id="order-confirmation-dialog-closed"
            aria-label="close"
            onClick={handleCloseInstructionDialog}
            edge="end"
            size="small"
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
        </div>
      </div>
      <DialogContent id="order-confirmation-dialog-content" className={classes.dialogContent}>
        {intl.formatMessage(
          { id: 'subscriptions.bank_transfer.instruction_first' },
          {
            br: <br />,
          },
        )}
        {intl.formatMessage(
          { id: 'subscriptions.bank_transfer.instruction_second' },
          {
            email: accountUser?.account.ownerEmail,
          },
        )}
        {intl.formatMessage(
          { id: 'subscriptions.bank_transfer.instruction_third' },
          {
            a: businessEmailAnchor,
            br: <br />,
          },
        )}
      </DialogContent>
    </Dialog>
  );

  useEffect(() => {
    if (showInstructionPopUp) {
      window.history.replaceState(
        null,
        '',
        resolvePath(accountSubscriptionManagement, undefined, {
          accountId,
        }),
      );
    }
  }, [showInstructionPopUp]);

  const handleCloseInstructionDialog = () => {
    setShowInstructionPopUp(false);
  };

  const renderDetailItem = (id: string, title: string, description: string) => (
    <Grid
      id={id}
      className={classes.orderDetailContainer}
      container
    >
      <Grid
        className={classes.orderDetailTitle}
        xs={1.5}
      >
        <p style={{ margin: '5px 0px 0px' }}>
          <FormattedMessage
            id={title}
          />
        </p>
      </Grid>
      <Grid
        className={classes.orderDetailValue}
        xs={2.5}
      >
        <p style={{ margin: '5px 0px 0px' }}>{description}</p>
      </Grid>
    </Grid>
  );

  const getDueDate = () => intl.formatDate(new Date(pendingOrder?.dueDate ?? ''), {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });

  return (
    <>
      <div id="order-detail-section" className={classes.rootContainer}>
        <div id="pending-order-number" className={classes.orderNumberContainer}>
          <p className={classes.orderNumber}>
            <FormattedMessage
              id="pending.order.number"
              values={{
                number: pendingOrder?.orderId,
              }}
            />
          </p>
          <p className={classes.orderStatus}>Waiting for Payment</p>
        </div>
        {renderDetailItem(
          'pending-order-amount',
          'pending.order.amount_due',
          `$${intl.formatNumber(pendingOrder.totalAmount)}`,
        )}
        {renderDetailItem('pending-order-due-date', 'pending.order.due_date', getDueDate())}
        <p className={classes.description}>
          <FormattedMessage
            id="pending.order.note"
          />
        </p>
      </div>
      {renderInstructionPopUp()}
    </>
  );
};

export default OrderDetailSection;
