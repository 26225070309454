import WarningIcon from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import LoadingProgress from '../../../layout/LoadingProgress';
import { logout as logoutRoute, useNavigate, useQuery } from '../../../routes';
import { UserService } from '../../../services';
import { updateUser } from '../../../services/user';
import { fromAuth } from '../../../store';
import { useSwitchTheme } from '../../../theme';

const useStyles = makeStyles(theme => ({
  dialog: {
    maxWidth: theme.spacing(85),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    position: 'absolute',
    top: 0,
  },
  title: {
    textAlign: 'left',
    fontSize: theme.spacing(3),
    marginBottom: theme.spacing(4),
    color: '#516393', // Todo: Move to system design later
    fontWeight: 700,
  },
  subtitle: {
    marginTop: theme.spacing(1.25),
    fontSize: theme.spacing(1.75),
    color: '#516393', // Todo: Move to system design later
  },
  captchaBox: {
    marginTop: theme.spacing(1),
  },
  form: {
    alignItems: 'center',
  },
  errorTextBox: {
    '& > .MuiOutlinedInput-input': {
      backgroundColor: '#FFEDE6',
    },
  },
  forgotBox: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1.25),
    backgroundColor: '#FFFAE6', // Todo: Move to system design later
    borderRadius: '0.5rem',
  },
  forgotText: {
    color: '#FA8C16', // Todo: Move to system design later
  },
  container: {
    marginTop: theme.spacing(2),
    flexWrap: 'wrap',
    alignItems: 'start',
    width: '100%',
  },
  link: {
    color: '#5294FF',
  },
  input: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    fontSize: theme.spacing(2),
    fontWeight: '900',
    backgroundColor: '#FFFFFF',
  },
  error: {
    color: theme.palette.error.main,
    margin: 0,
  },
  errorContainer: {
    display: 'flex',
    alignItems: 'start',
    marginTop: '.2rem',
  },
  errorIcon: {
    marginRight: theme.spacing(0.5),
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  action: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
    width: '100%',
  },
  popover: {
    overflowX: 'unset',
    overflowY: 'unset',
    boxShadow: 'unset',
  },
  button: {
    fontSize: theme.spacing(1.75),
    fontWeight: theme.typography.fontWeightMedium,
    color: '#5294FF',
    textTransform: 'capitalize',
  },
}));

interface ChangePasswordInput {
  password: string;
  newPassword: string;
  confirmPassword: string;
}

const ChangePasswordDialog = () => {
  const captchaKey = process.env.REACT_APP_CAPTCHA_KEY || '';
  const { replace, replaceQuery } = useNavigate();
  const { queryDictionary } = useQuery();
  const theme = useTheme();
  const intl = useIntl();
  const [notCompleteCaptcha, setNotCompleteCaptcha] = useState(false);
  const classes = useStyles();
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { currentTheme } = useSwitchTheme();
  const [loading, setLoading] = useState(false);
  const user = useSelector(fromAuth.selectUser);
  const {
    register,
    handleSubmit,
    formState: { errors: inputErrors },
    watch,
    reset,
    setError,
  } = useForm<ChangePasswordInput>({
    defaultValues: { password: '', newPassword: '', confirmPassword: '' },
  });
  const [changePasswordOpened, setChangePasswordOpened] = useState(false);
  const [captchaOpen, setCaptchaOpen] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const openChangePasswordDialog = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setChangePasswordOpened(true);
  };

  const closeChangePasswordDialog = () => {
    reset();
    setResetEmailSent(false);
    setChangePasswordOpened(false);
  };

  const handleVerify = async (value: string | null) => {
    if (!user) return;
    if (value) {
      try {
        await UserService.forgotPassword({ email: user.email });
        setResetEmailSent(true);
        setNotCompleteCaptcha(false);
      } catch (_) {
        enqueueSnackbar(<FormattedMessage id="profile.change_password.forgot_password.failed" />, {
          variant: 'error',
        });
      }
    } else {
      setNotCompleteCaptcha(true);
    }
  };

  const handleError = (e: string[]) => {
    if (Array.isArray(e) && e.length > 0) {
      setError('password', {
        type: 'validate',
        message: `error.${e[0]}`,
      });
    } else {
      enqueueSnackbar(<FormattedMessage id="error.unknown" />, { variant: 'error' });
    }

    setLoading(false);
  };

  const doChangePassword = async (input: ChangePasswordInput) => {
    setLoading(true);
    try {
      await updateUser({
        id: user!.id,
        password: input.password,
        newPassword: input.newPassword,
        firstName: user?.firstName,
        lastName: user?.lastName,
        jobTitle: user?.jobTitle,
      });
      analytics.track('User Changed Password', {
        event_text: 'User Changed Password',
        user_id: user!.email,
      });
      enqueueSnackbar(<FormattedMessage id="profile.change_password.change_password.success" />, {
        variant: 'success',
        autoHideDuration: 2000,
      });
      setTimeout(() => {
        replace(logoutRoute.path, replaceQuery(queryDictionary()));
      }, 2000);
    } catch (error: any) {
      handleError(error.response.data.errors);
    }
  };

  useEffect(() => {
    setNotCompleteCaptcha(false);
    setCaptchaOpen(false);
  }, [changePasswordOpened]);

  return (
    <div>
      <Button color="primary" onClick={openChangePasswordDialog}>
        <Typography className={classes.button}>
          <FormattedMessage id="profile.change_password.title" />
        </Typography>
      </Button>
      <Dialog
        classes={{ paper: classes.dialog }}
        fullScreen={fullScreen}
        fullWidth
        open={changePasswordOpened}
        onClose={closeChangePasswordDialog}
      >
        <DialogTitle className={classes.title}>
          <FormattedMessage id="profile.change_password.title" />
        </DialogTitle>
        <DialogContent>
          <Divider />
          <form
            className={classes.form}
            onSubmit={handleSubmit(doChangePassword)}
            id="change-password-form"
          >
            <Box>
              <Grid container className={classes.container}>
                <Grid item sm={3.25} md={3.25} xl={3.25}>
                  <Typography className={classes.subtitle}>
                    <FormattedMessage id="profile.change_password.password" values={{ char: '*' }} />
                  </Typography>
                </Grid>
                <Grid item sm={8.75} md={8.75} xl={8.75}>
                  <TextField
                    variant="outlined"
                    type="password"
                    autoFocus
                    fullWidth
                    name="password"
                    inputProps={{
                      ...register('password', {
                        required: 'error.field.required',
                      }),
                    }}
                    InputProps={{
                      classes: {
                        input: classes.input,
                        error: classes.errorTextBox,
                      },
                    }}
                    helperText={
                      inputErrors.password && (
                        <div className={classes.errorContainer}>
                          <WarningIcon className={classes.errorIcon} />
                          <FormattedMessage
                            id={inputErrors.password?.message}
                            values={{
                              length: 8,
                              field: <FormattedMessage id="profile.change_password.password" values={{ char: '' }} />,
                            }}
                          />
                        </div>
                      )
                    }
                    error={!!inputErrors.password}
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.container}>
                <Grid item sm={3.25} md={3.25} xl={3.25}>
                  <Typography className={classes.subtitle}>
                    <FormattedMessage
                      id="profile.change_password.new_password"
                      values={{ char: '*' }}
                    />
                  </Typography>
                </Grid>
                <Grid item sm={8.75} md={8.75} xl={8.75}>
                  <TextField
                    variant="outlined"
                    type="password"
                    fullWidth
                    helperText={
                      inputErrors.newPassword && (
                        <div className={classes.errorContainer}>
                          <WarningIcon className={classes.errorIcon} />
                          <FormattedMessage
                            id={inputErrors.newPassword?.message}
                            values={{
                              field: (
                                <FormattedMessage
                                  id="profile.change_password.new_password"
                                  values={{ char: '' }}
                                />
                              ),
                            }}
                          />
                        </div>
                      )
                    }
                    name="newPassword"
                    inputProps={{
                      ...register('newPassword', {
                        required: 'error.field.required',
                        maxLength: { message: intl.formatMessage({ id: 'error.maxlength' }, { length: 255 }), value: 255 },
                        pattern: {
                          value:
                            /^(?=.*[a-zA-Z]?)(?=.*[@#$%^&+=!*\\/?`(){}[\]|;:'",.<>])(?=.*\d?).{8,255}$/,
                          message: 'error.user.password.invalid',
                        },
                        validate: value => value !== watch('password')
                          || 'profile.change_password.new_password_match_current',
                      }),
                    }}
                    InputProps={{
                      classes: {
                        input: classes.input,
                        error: classes.errorTextBox,
                      },
                    }}
                    error={!!inputErrors.newPassword}
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.container}>
                <Grid item sm={3.25} md={3.25} xl={3.25}>
                  <Typography className={classes.subtitle}>
                    <FormattedMessage id="profile.change_password.confirm" values={{ char: '*' }} />
                  </Typography>
                </Grid>
                <Grid item sm={8.75} md={8.75} xl={8.75}>
                  <TextField
                    variant="outlined"
                    type="password"
                    fullWidth
                    name="confirmPassword"
                    inputProps={{
                      ...register('confirmPassword', {
                        required: 'error.field.required',
                        validate: value => value === watch('newPassword')
                          || 'profile.change_password.password_not_match',
                      }),
                    }}
                    InputProps={{
                      classes: {
                        input: classes.input,
                        error: classes.errorTextBox,
                      },
                    }}
                    helperText={
                      inputErrors.confirmPassword && (
                        <div className={classes.errorContainer}>
                          <WarningIcon className={classes.errorIcon} />
                          <FormattedMessage
                            id={inputErrors.confirmPassword?.message}
                            values={{
                              field: (
                                <FormattedMessage
                                  id="profile.change_password.confirm"
                                  values={{ char: '' }}
                                />
                              ),
                            }}
                          />
                        </div>
                      )
                    }
                    error={!!inputErrors.confirmPassword}
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.container}>
                <Grid item sm={3.25} md={3.25} xl={3.25} />
                <Grid item sm={8.75} md={8.75} xl={8.75}>
                  {captchaKey && (
                    <Box className={classes.captchaBox}>
                      {captchaOpen ? (
                        <ReCAPTCHA
                          size="normal"
                          theme={currentTheme}
                          sitekey={captchaKey}
                          onChange={handleVerify}
                        />
                      ) : ''}
                      {notCompleteCaptcha && (
                      <Typography className={classes.error} variant="caption">
                        <FormattedMessage id="error.incomplete.recaptcha" />
                      </Typography>
                      )}
                    </Box>
                  )}
                  {resetEmailSent && (
                    <Box className={classes.forgotBox}>
                      <Typography variant="caption" className={classes.forgotText}>
                        <FormattedMessage
                          id="profile.change_password.forgot_message"
                          values={{ email: user?.email }}
                        />
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          </form>
        </DialogContent>
        <DialogActions className={classes.action}>
          <Button
            type="button"
            size="medium"
            onClick={closeChangePasswordDialog}
            variant="contained"
          >
            <FormattedMessage id="profile.change_password.cancel" />
          </Button>
          <Button
            type="submit"
            form="change-password-form"
            color="primary"
            size="medium"
            variant="contained"
          >
            <FormattedMessage id="profile.change_password.submit" />
          </Button>
        </DialogActions>
        {loading && <LoadingProgress />}
      </Dialog>
    </div>
  );
};

export default ChangePasswordDialog;
