import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import makeStyles from '@mui/styles/makeStyles';
import { ReactChild } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import {
  RecurlySubscription,
  RecurlySubscriptionPayloadStatus,
  TestOpsPlatformSubscription,
} from '../../../../models';
import { TestOpsPlatformTierType } from '../../../../models/testOpsPlatformTierType';
import { ReactComponent as CalendarClock } from '../../../icons/calendar-clock.svg';
import { ReactComponent as OrganizationIcon } from '../../../icons/sitemap.svg';
import { ReactComponent as WarningIcon } from '../../../../layout/icons/PlatformWarningIcon.svg';
import { UNLIMITED_PROJECT, UNLIMITED_TEST_EXECUTION, PLATFORM_FREE_USER } from '../../utils';
import GracePeriodTooltip from '../GracePeriodTooltip';

const useStyles = makeStyles((theme => ({
  boxTitle: {
    fontSize: theme.spacing(1.5),
    color: '#727993',
    fontWeight: theme.typography.fontWeightMedium,
    height: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.4),
  },
  itemValue: {
    fontSize: theme.spacing(2),
    color: '#22283c',
    fontWeight: theme.typography.fontWeightMedium,
    display: 'flex',
    alignContent: 'space-between',
    flexWrap: 'wrap',
  },
  spanValue: {
    color: '#727993',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(1.5),
    paddingTop: theme.spacing(0.5),
    textAlign: 'left',
  },
  canceledAt: {
    paddingLeft: 5,
  },
  subTitleNote: {
    fontSize: theme.spacing(1.5),
    margin: theme.spacing(2, 0, 2, 0),
  },
  divider: {
    background: '#eceef5',
    width: 1,
    marginRight: theme.spacing(1.5),
  },
  gracePeriodIcon: {
    marginBottom: theme.spacing(0.2),
  },
})));

export interface TestOpsPlatformSectionProps {
  numOfTestExecutions: number;
  numOfActiveProjects: number;
  expiryDate: Date | undefined;
  recurlySubscription?: RecurlySubscription;
  trialSubscription: TestOpsPlatformSubscription;
  numOfOrganizations?: number;
  testResultCount?: number;
}

const TestOpsPlatformSection = (props: TestOpsPlatformSectionProps) => {
  const {
    numOfTestExecutions,
    numOfActiveProjects,
    expiryDate,
    trialSubscription,
    recurlySubscription,
    numOfOrganizations,
    testResultCount,
  } = props;
  const classes = useStyles();

  const sectionExpiryDateColumn = (
    idMessage: string,
    className: string,
    value: Date,
  ) => (
    <Grid item xs={3} className={className}>
      { idMessage !== '' && (
        <p className={classes.boxTitle}>
          <FormattedMessage id={idMessage} />
        </p>
      )}
      <div className={classes.itemValue}>
        <CalendarClock className={classes.icon} />
        <b>
          {new Date(value).toLocaleDateString(
            'en-US',
            {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            },
          )}
        </b>
      </div>
    </Grid>
  );
  const sectionTestResultsColumn = (
    idMessage: string,
    value: string | number,
    className: string,
  ) => (
    <Grid item xs={3} className={className}>
      { idMessage !== '' && (
        <p className={classes.boxTitle}>
          <FormattedMessage id={idMessage} />
        </p>
      )}
      <div className={classes.itemValue}>
        <div style={{ minWidth: '40%' }}>
          <FormattedMessage id="subscriptions.testops_platform.usage" />
        </div>
        <div style={{ minWidth: '60%', overflow: 'overlay', display: 'flex' }}>
          <b>
            {testResultCount}
            /
            {value}
            {testResultCount && testResultCount > Number(value)
              ? <WarningIcon className={classes.icon} /> : ''}
          </b>
        </div>
      </div>
    </Grid>
  );

  const sectionAdditionalInfoColumn = (
    idMessage: string,
    icon: ReactChild,
    numOfProject: number,
    className: string,
    numOfUsers: string,
    numOfOrganizations: number = 0,
  ) => (
    <Grid item xs={5} className={className}>
      <p className={classes.boxTitle}>
        {idMessage !== '' && <FormattedMessage id={idMessage} />}
      </p>
      <div className={classes.itemValue}>
        {icon}
        <b>
          {numOfOrganizations}
        </b>
        &nbsp;
        <span className={classes.spanValue}>
          Organization(s)/
        </span>
        &nbsp;
        <b>
          {numOfProject === UNLIMITED_PROJECT ? 'Unlimited' : numOfProject}
        </b>
        &nbsp;
        <span className={classes.spanValue}>
          Project(s)/
        </span>
        &nbsp;
        <b>
          {numOfUsers}
        </b>
        &nbsp;
        <span className={classes.spanValue}>
          User(s)
        </span>
      </div>
    </Grid>
  );

  // eslint-disable-next-line max-len
  const sectionDateColumn = (idMessage: string, icon: ReactChild, value: Date, className: string) => {
    const canceled: boolean = !!recurlySubscription
      && recurlySubscription.status === RecurlySubscriptionPayloadStatus.CANCELED;

    const canceledAt: Date | undefined = recurlySubscription?.canceledAt;
    return (
      <Grid item xs={3} className={className}>
        <p className={classes.boxTitle}>
          <FormattedMessage id={idMessage} />
        </p>
        <div className={classes.itemValue}>
          {icon}
          <FormattedDate
            value={new Date(value)}
            month="short"
            day="numeric"
            year="numeric"
          />
          <span className={classes.gracePeriodIcon}>
            <GracePeriodTooltip expiryDate={value} />
          </span>
        </div>
        { canceled && canceledAt
        && (
          <div>
            Canceled:
            <span className={classes.canceledAt}>
              <FormattedDate
                value={new Date(canceledAt)}
                month="short"
                day="numeric"
                year="numeric"
              />
            </span>
          </div>
        ) }
      </Grid>
    );
  };
  const testExecutions = numOfTestExecutions === UNLIMITED_TEST_EXECUTION ? 'Unlimited' : numOfTestExecutions;
  const isTrialUnlimited = !!trialSubscription
    && trialSubscription.tier === TestOpsPlatformTierType.ENTERPRISE;
  const isTrial30DaysPro = !!trialSubscription
    && trialSubscription.tier === TestOpsPlatformTierType.PROFESSIONAL;
  const isNotTrial = !isTrialUnlimited && !isTrial30DaysPro && expiryDate;
  const numOfUsers = (isTrialUnlimited || isTrial30DaysPro || expiryDate) ? 'Unlimited' : PLATFORM_FREE_USER.toString();
  return (
    <Grid
      id="testops-platform-section"
      key="subscriptions.testops_platform.platform_subscription_additional_info.grid_container"
      container
    >
      {isNotTrial
        && sectionDateColumn('subscriptions.testops_platform.expiry_date', <CalendarClock className={classes.icon} />, expiryDate, '')}
      {isNotTrial && <Divider flexItem orientation="vertical" className={classes.divider} />}
      {trialSubscription?.expiryDate && sectionExpiryDateColumn(
        'subscriptions.testops_platform_section.info.expiryDate',
        '',
        trialSubscription?.expiryDate,
      )}
      {trialSubscription && <Divider flexItem orientation="vertical" className={classes.divider} />}
      {sectionTestResultsColumn(
        'subscriptions.testops_platform.monthly_test_results',
        `${testExecutions}`,
        '',
      )}
      <Divider flexItem orientation="vertical" className={classes.divider} />
      {sectionAdditionalInfoColumn(
        '',
        <OrganizationIcon className={classes.icon} />,
        numOfActiveProjects,
        '',
        numOfUsers,
        numOfOrganizations,
      )}
    </Grid>
  );
};
export default TestOpsPlatformSection;
