import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-numberformat/polyfill';
import 'chart.js/auto';
import '@katalon-studio/katalon-ui/assets/css/all.css';
import './index.css';
import 'typeface-inter';

import Axios from 'axios';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

import App from './app/App';

// polyfill for NumberFormat
(async () => {
  Axios.defaults.baseURL = process.env.REACT_APP_API_URL ?? process.env.PUBLIC_URL;

  ReactDOM.render(
    <StrictMode>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </BrowserRouter>
    </StrictMode>,
    document.getElementById('root'),
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  // serviceWorker.unregister();
})();
