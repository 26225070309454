import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {
  Account,
} from '../../../../models';
import KseSubscriptionDetails from './KseSubscriptionDetails';
import VisualTestingSubscriptionDetails from './VisualTestingSubscriptionDetails';
import TestCloudSubscriptionDetails from './TestCloudSubscriptionDetails';
import KreSubscriptionDetails from './KreSubscriptionDetails';
import KseOfflineSubscriptionDetails from './KseOfflineSubscriptionDetails';
import KreOfflineSubscriptionDetails from './KreOfflineSubscriptionDetails';

const useStyles = makeStyles((theme => ({
  itemValue: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium,
  },
  tableContainer: {
    margin: theme.spacing(0, 0, 3),
    borderRadius: theme.spacing(1),
    marginTop: 20,
  },
  headerTableCell: {
    padding: theme.spacing(1, 0),
    fontSize: 14,
    fontWeight: 600,
    color: '#808b9a',
    textTransform: 'none',
  },
})));

export interface AdditionalSubscriptionSectionProps {
  account: Account | null,
  email: string | null,
  organizationId: number,
}

const AdditionalSubscriptionSection = (props: AdditionalSubscriptionSectionProps) => {
  const {
    organizationId,
    account,
    email,
  } = props;
  const classes = useStyles();

  return (
    <Box id="additional-subscription-section" className={classes.tableContainer}>
      <Grid container>
        <Grid item xs={5} className={classes.headerTableCell}>
          <FormattedMessage id="subscriptions.testops_platform.subscription_summary.name" />
        </Grid>
        <Grid item xs={2} className={classes.headerTableCell}>
          <FormattedMessage id="subscriptions.testops_platform.billing_cycle" />
        </Grid>
        <Grid item xs={2.5} className={classes.headerTableCell}>
          <FormattedMessage id="subscriptions.testops_platform.quantity" />
        </Grid>
        <Grid item xs={2} className={classes.headerTableCell}>
          <FormattedMessage id="subscriptions.testops_platform.expiry_date" />
        </Grid>
        <Grid item xs={0.5} className={classes.headerTableCell} />
      </Grid>
      <KseSubscriptionDetails
        account={account}
        organizationId={organizationId}
        email={email}
      />
      <KseOfflineSubscriptionDetails
        account={account}
        organizationId={organizationId}
        email={email}
      />
      <KreSubscriptionDetails
        account={account}
        organizationId={organizationId}
        email={email}
      />
      <KreOfflineSubscriptionDetails
        account={account}
        organizationId={organizationId}
        email={email}
      />
      <TestCloudSubscriptionDetails
        account={account}
        organizationId={organizationId}
        email={email}
      />
      <VisualTestingSubscriptionDetails
        account={account}
        organizationId={organizationId}
        email={email}
      />
    </Box>
  );
};
export default AdditionalSubscriptionSection;
