import Axios from 'axios';
import { TestOpsPlatformConfiguration } from '../models/testOpsPlatformConfiguration';
import { PageableResponse, DataResponse } from '../models';
import { composeQuery, Query } from '../models/query';
import { TestOpsPlatformSubscription } from '../models/testOpsPlatformSubscription';

export const getTestOpsPlatformSubscriptions = async (
  checkHasPremierSuccess: boolean,
  ...criteria: Query<TestOpsPlatformSubscription>[]
) => (
  await Axios.get<PageableResponse<TestOpsPlatformSubscription>>('/v1/testops-platform-subscriptions', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    params: {
      ...composeQuery<TestOpsPlatformSubscription>(...criteria),
      ...(checkHasPremierSuccess && { include: 'premier_success' }),
    },
    data: {},
  })).data;

export const getTestOpsPlatformConfiguration = async () => (
  await Axios.get<DataResponse<TestOpsPlatformConfiguration>>('/v1/testops-platform-subscriptions/configuration', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    params: {},
    data: {},
  })).data;

export const subscribeUnlimitedTrial = async (
  data: Required<Pick<TestOpsPlatformSubscription, 'accountId'>>,
) => (
  await Axios.post<DataResponse<TestOpsPlatformSubscription>>('/v1/subscriptions/trial-testops-platform', data)
).data.data;

export const cancelUnlimitedTrial = async (
  data: Required<Pick<TestOpsPlatformSubscription, 'accountId'>>,
) => (
  await Axios.put<DataResponse<TestOpsPlatformSubscription>>('/v1/subscriptions/cancel-trial-testops-platform', data)
).data.data;

export const getTrialTestOpsPlatformSubscriptions = async (accountId: number) => (
  await Axios.get<PageableResponse<TestOpsPlatformSubscription>>('/v1/testops-platform-subscriptions/trial-subscriptions', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    params: {
      account_id: accountId,
    },
    data: {},
  })).data;
