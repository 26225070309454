import { createAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import { AppState } from '.';
import { exceptionOf, Organization, OrganizationUser, OrganizationUserFeature, SerializedException } from '../models';
import { Operator, Query } from '../models/query';
import { OrganizationUserService } from '../services';

export const ASSIGN_LICENSE_KEY = 'assign_license_page';

interface AssignLicenseState extends EntityState<OrganizationUser> {
  loading: boolean;
  errors: SerializedException[];
}

const MAX_NUMBER_OF_RECORDS = 50;
export const ERROR_ASSIGN_USER_ALREADY_ASSIGNED = 'assign.user.already.assigned';
export const ERROR_ASSIGN_USER_QUOTA_EXCEEDED = 'assign.user.quota.exceeded';
export const ERROR_ASSIGN_USER_NOT_EXISTED = 'assign.user.not.existed';

const assignLicenseUserListAdapter = createEntityAdapter<OrganizationUser>();

export const createInitialState = ():
AssignLicenseState => assignLicenseUserListAdapter.getInitialState({
  loading: false,
  errors: [],
});

export const doAssignLicense = createAsyncThunk(
  'assignLicense/doAssignLicense',
  async (input: Required<Pick<OrganizationUserFeature, 'organizationUserId' | 'feature'>>, { rejectWithValue }) => {
    try {
      return await OrganizationUserService.createOrganizationUserFeature(input);
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doResetErrors = createAction('assignLicense/resetError');

export const doGetAssignableUsers = createAsyncThunk(
  'assignLicense/getAllAssignableOrgUsers',
  async (input: Pick<OrganizationUserFeature, 'organizationId' | 'feature'>) => {
    const criteria: Array<Query<OrganizationUser>> = [
      { field: 'organizationId', operator: Operator.EQ, value: input.organizationId },
      { limit: MAX_NUMBER_OF_RECORDS },
    ];
    const firstResponse = await OrganizationUserService.getAssignableUsers(input, ...criteria);
    const numberOfRequests = Math.ceil(firstResponse.total / MAX_NUMBER_OF_RECORDS);
    const remainingRequests = [];
    for (let i = 1; i < numberOfRequests; i += 1) {
      const req = OrganizationUserService.getAssignableUsers(
        input,
        ...criteria,
        { offset: MAX_NUMBER_OF_RECORDS * i },
      );
      remainingRequests.push(req);
    }
    const remainingResponses = await Promise.all(remainingRequests);
    const orgUsers = [...firstResponse.data];
    remainingResponses.forEach(r => {
      orgUsers.push(...r.data);
    });
    return { orgUsers };
  },
);

const assignLicensePageSlice = createSlice({
  name: ASSIGN_LICENSE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doAssignLicense.pending, state => {
      state.loading = true;
    });
    builder.addCase(doAssignLicense.fulfilled, (state, action) => {
      state.loading = false;
      assignLicenseUserListAdapter.removeOne(state, action.payload.organizationUserId);
      state.errors = [];
    });
    builder.addCase(doAssignLicense.rejected, (state, action) => {
      const payload = action.payload as SerializedException;
      state.loading = false;
      state.errors.push(payload);
    });
    builder.addCase(doGetAssignableUsers.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetAssignableUsers.fulfilled, (state, action) => {
      state.loading = false;
      assignLicenseUserListAdapter.setAll(state, action.payload.orgUsers);
      state.errors = [];
    });
    builder.addCase(doGetAssignableUsers.rejected, state => {
      state.loading = false;
    });
    builder.addCase(doResetErrors, state => {
      state.errors = [];
    });
  },
});

const selectAssignLicenseUserList = (state: AppState) => state[ASSIGN_LICENSE_KEY];

export const {
  selectAll: selectAllAssignLicenseUserList,
  selectEntities,
} = assignLicenseUserListAdapter.getSelectors(selectAssignLicenseUserList);

export const selectAssignLicenseUserListByOrganizationId = (organizationId: Organization['id']) => createSelector(
  selectAllAssignLicenseUserList,
  orgUsers => orgUsers.filter(orgUser => orgUser.organizationId === organizationId),
);

export const selectErrors = () => createSelector(
  selectAssignLicenseUserList,
  state => state.errors,
);

export default assignLicensePageSlice.reducer;
