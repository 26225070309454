import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import { unwrapResult } from '@reduxjs/toolkit';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { FormattedMessage, useIntl } from 'react-intl';
import LoadingProgress from '../../layout/LoadingProgress';
import { fromAccounts, useAppDispatch } from '../../store';
import { AccountRole, Account } from '../../models';

const useStyles = makeStyles(theme => ({
  dialog: {
    position: 'absolute',
    borderRadius: theme.spacing(0.75),
    minWidth: theme.spacing(96),
  },
  subtext: {
    width: '100%',
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
  },
  subtitle: {
    fontWeight: 500,
  },
  form: {
    marginBottom: theme.spacing(2),
  },
  textarea: {
    width: '100%',
    height: '100%',
  },
  icon: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  reasonInput: {
    marginTop: theme.spacing(2),
    '& > *': {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
  },
  nameInput: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  asterisk: {
    color: theme.palette.warning.main,
  },
  error: {
    color: theme.palette.error.main,
    margin: 0,
  },
  editNameBtn: {
    width: theme.spacing(15),
    height: theme.spacing(5),
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(3),
    float: 'right',
    background: '#f0f1f2',
    textAlign: 'right',
    fontSize: theme.spacing(2),
    '& .MuiTypography-root': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    minWidth: theme.spacing(15),
  },
  closeButton: {
    float: 'right',
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
  iconBox: {
    flexGrow: 8,
  },
  editNameLabel: {
    flexGrow: 2,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(2.5),
  },
  disableBtn: {
    color: '#233145',
    background: '#f0f1f2',
    width: theme.spacing(8),
    height: theme.spacing(5),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.spacing(0.5),
  },
  activeBtn: {
    color: '#ffffff',
    background: '#276ef1',
    width: theme.spacing(8),
    height: theme.spacing(5),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: 'primary',
    },
  },
  dlgContent: {
    width: '100%',
  },
  dlgTitle: {
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    width: '100%',
    fontSize: theme.spacing(2),
    fontWeight: 700,
    color: '#233145',
    display: 'flex',
  },
  dlgActions: {
    width: '100%',
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2.5),
  },
  textField: {
    '& .MuiInputBase-root': {
      borderRadius: theme.spacing(0.75),
    },
  },
}));

interface AccountUserProperties {
  role: AccountRole | undefined;
  account: Account;
}

const EditAccountNameDialog = (props: AccountUserProperties) => {
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const { account, role } = props;
  const [accountName, setAccountName] = useState(account.name);
  const {
    register,
    handleSubmit,
  } = useForm<Required<Pick<Account, 'id' | 'name'>>>({
    defaultValues: { name: '' },
  });
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const openEditAccountNameDialog = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
    setAccountName(account.name);
  };

  const closeEditAccountNameDialog = () => {
    setOpen(false);
    setCanSave(false);
    setAccountName('');
  };

  const handleChangeText = (event: { target: { value: any; name: any; }; }) => {
    const { value } = event.target;
    setCanSave(isAccountNameValidToSave(value));
    setAccountName(value);
  };

  const isAccountNameValidToSave = (str: String) => str.trim() !== '' && str !== account.name;

  const doEditAccountName = async () => {
    setLoading(true);
    dispatch(fromAccounts.doEditAccountName({ id: account.id, accountName: accountName.trim() }))
      .then(unwrapResult)
      .then(() => {
        enqueueSnackbar(
          intl.formatMessage(
            { id: 'account.setting.popup.edit.name.saving.successfully' },
          ),
          { variant: 'success' },
        );
        // Dispatch to micro FE navigation UI to update account name
        const updateEvent = new CustomEvent('parent-app:changeAccountName', { detail: { accountId: account.id } });
        window.dispatchEvent(updateEvent);
      })
      .catch(() => {
        enqueueSnackbar(
          intl.formatMessage(
            { id: 'account.setting.popup.edit.name.saving.failed' },
          ),
          { variant: 'error' },
        );
      })
      .finally(() => {
        setLoading(false);
        closeEditAccountNameDialog();
      });
  };

  return (
    <>
      {AccountRole.isAdminOrOwnerRole(role) && (
        <Button id="account.setting.edit.name.button" onClick={openEditAccountNameDialog} className={classes.editNameBtn}>
          <Typography>
            <FormattedMessage id="account.setting.title.edit.name" />
          </Typography>
        </Button>
      )}
      <Dialog
        classes={{ paper: classes.dialog }}
        open={open}
        maxWidth="sm"
        placeholder="center"
        fullScreen={fullScreen}
        fullWidth
        onClose={closeEditAccountNameDialog}
      >
        <DialogTitle id="account.setting.dialog.title" className={classes.dlgTitle}>
          <Box className={classes.editNameLabel}>
            <FormattedMessage id="account.setting.title.edit.name" />
          </Box>
          <Box className={classes.iconBox}>
            <IconButton
              id="account.setting.dialog.title.close.icon"
              className={classes.closeButton}
              onClick={closeEditAccountNameDialog}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent id="account.setting.dialog.content" className={classes.dlgContent}>
          <Box className={classes.subtext}>
            <Typography>
              <FormattedMessage id="create_account_dialog.account_name" />
            </Typography>
          </Box>
          <form
            className={classes.form}
            onSubmit={handleSubmit(doEditAccountName)}
            id="edit-account-name-form"
          >
            <TextField
              id="account.setting.dialog.textfield.account.name"
              variant="outlined"
              fullWidth
              required
              name="name"
              value={accountName}
              className={classes.textField}
              inputProps={{
                classes: {
                  input: classes.nameInput,
                },
                ...register('name', {
                  required: 'error.required',
                }),
                maxLength: 255,
              }}
              autoFocus
              onChange={handleChangeText}
              placeholder={intl.formatMessage({ id: 'create_account_dialog.account_name' })}
            />
          </form>
        </DialogContent>
        <DialogActions id="account.setting.dialog.actions" className={classes.dlgActions}>
          <Button id="account.setting.dialog.actions.button.cancel" className={classes.disableBtn} type="button" variant="contained" onClick={closeEditAccountNameDialog}>
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button id="account.setting.dialog.actions.button.save" color="primary" disabled={!canSave} className={canSave ? classes.activeBtn : classes.disableBtn} type="submit" form="edit-account-name-form" variant="contained">
            <FormattedMessage id="billinginfo.button.save" />
          </Button>
        </DialogActions>
        {loading && <LoadingProgress />}
      </Dialog>
    </>
  );
};

export default EditAccountNameDialog;
