import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import InputAdorment from '@mui/material/InputAdornment';
import Button from '@katalon-studio/katalon-ui/Button';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import orderTable from 'lodash/orderBy';
import { ReactComponent as SearchIcon } from '../icons/search-icon.svg';
import { ReactComponent as TrashAltIcon } from '../icons/trash-alt-icon.svg';
import { useAppDispatch } from '../../store';
import { fromAuth, fromUserGroup, fromUserGroupUser, fromUsers } from '../../store/rootReducer';
import { getFullNameUser } from '../../models/user';
import { OrganizationRole } from '../../models/organizationRole';
import { AccountRole, AccountUser, OrganizationUser } from '../../models';
import DeleteUserGroupUserPopup from './DeleteUserGroupUserPopup';

const useStyles = makeStyles(theme => ({
  titleGrid: {
    display: 'flex',
    alignItems: 'center',
  },
  pageTitle: {
    fontSize: theme.spacing(2.5),
    fontWeight: theme.typography.fontWeightMedium,
    color: '#233145',
  },
  totalUser: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    fontSize: theme.spacing(1.5),
    fontWeight: theme.typography.fontWeightRegular,
    color: '#233145',
    backgroundColor: '#d9d9d9',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    marginLeft: theme.spacing(1),
    minWidth: theme.spacing(2.375),
    height: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  card: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  actionIconButton: {
    minWidth: theme.spacing(4.5),
    maxWidth: theme.spacing(4.5),
  },
  actionIcon: {
    color: '#808b9a',
  },
  inputSearch: {
    fontSize: theme.spacing(1.75),
  },
  defaultIcon: {
    fontSize: theme.spacing(1.75),
    color: 'rgba(104, 109, 128, 0.54)',
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  search: {
    width: theme.spacing(40),
    '& > .MuiInputBase-root': {
      borderRadius: theme.spacing(0.5),
      borderColor: '#dbdde5',
      paddingLeft: theme.spacing(1.5),
      backgroundColor: 'rgb(255, 255, 255)',
    },
    '& > * > .MuiInputBase-inputSizeSmall': {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      '&::placeholder': {
        color: '#808b9a',
      },
    },
  },
  tableHead: {
    '& th': {
      fontSize: theme.spacing(1.75),
      color: '#233145',
      fontWeight: 500,
      '& > span': {
        textTransform: 'none',
        '& > svg': {
          opacity: 0,
          width: theme.spacing(2.5),
          height: theme.spacing(2.5),
        },
      },
      '& > span.Mui-active': {
        '& > svg': {
          opacity: 1,
        },
      },
      '& > span::hover': {
        '& > svg': {
          opacity: 0.5,
        },
      },
    },
  },
  noDataLabel: {
    '&:hover': {
      backgroundColor: 'unset',
    },
    '& td': {
      borderBottom: 'none',
    },
  },
  tableBody: {
    '& td': {
      fontSize: theme.spacing(1.75),
      color: '#233145',
    },
  },
  tablePaginationSelectLabel: {
    fontSize: theme.spacing(1.5),
    color: '#808b9a',
  },
  tablePaginationSelect: {
    color: '#233145',
  },
  tablePaginationSelectIcon: {
    color: 'rgba(104, 109, 128, 0.54)',
    size: '10',
  },
  tablePaginationToolbar: {
    '& > p:nth-of-type(2)': {
      color: '#233145',
    },
  },
  dataRow: {
    height: theme.spacing(9.5),
  },
}));

interface Column {
  id: 'id' | 'name' | 'email' | 'joinDate' | 'action';
  minWidth?: number;
  width?: number;
  align?: 'right';
  render?: (row: Data) => React.ReactElement;
  ignoreHeader?: boolean;
}

const columns: readonly Column[] = [
  {
    id: 'name',
  },
  { id: 'email' },
  {
    id: 'joinDate',
  },
  {
    id: 'action',
    align: 'right',
    width: 60,
    ignoreHeader: true,
  },
];

export interface UserData {
  id: number;
  name: string;
  email: string;
  joinDate: string;
}
interface Data extends UserData {
  action: React.ReactElement;
}

interface UserGroupDetailUserListProps {
  userGroupId: number,
  currentOrgUser?: OrganizationUser,
  currentAccountUser?: AccountUser,
  addUsersRefresh: number,
}

type Order = 'asc' | 'desc';

const UserGroupDetailUserList = (props: UserGroupDetailUserListProps) => {
  const {
    userGroupId,
    currentOrgUser,
    currentAccountUser,
    addUsersRefresh,
  } = props;
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const isRootOrAdmin = useSelector(fromAuth.selectIsRootOrAdmin);
  const currentUserGroup = useSelector(fromUserGroup.selectUserGroupsById(Number(userGroupId)));
  const userGroupUsers = useSelector(fromUserGroupUser.selectAll());

  const [searchText, setSearchText] = useState('');
  const [dataTable, setDataTable] = useState<Data[]>([]);
  const [users, setUsers] = useState<Data[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState<Order>('asc');
  const [isDeletePopupOpened, setDeletePopupOpened] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);

  const handleChangeSearchText = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const value = event.target.value.toLowerCase();
    const trimValue = value.trim();
    if (trimValue.length > 0) {
      setSearchText(value);
      setDataTable(users.filter(it => (
        it.email.toLowerCase().indexOf(trimValue) > -1
        || it.name.toLowerCase().indexOf(trimValue) > -1)));
      return;
    }
    setSearchText('');
    setDataTable(users);
  };

  const createData = (rawData: {
    id: number;
    name: string;
    email: string;
    joinDate: string;
  }): Data => ({
    id: rawData.id,
    name: rawData.name,
    email: rawData.email,
    joinDate: rawData.joinDate,
    action: <>{renderAction(rawData)}</>,
  });

  const renderAction = (row: {
    id: number;
    name: string;
    email: string;
    joinDate: string;
  }) => {
    const isEditableRole = AccountRole.isAdminOrOwnerRole(currentAccountUser?.role)
      || OrganizationRole.isAdminOrOwnerRole(currentOrgUser?.role)
      || isRootOrAdmin;
    if (!isEditableRole) {
      return null;
    }
    return (
      <Button
        id={`user.group.detail.userlist.table.row-${row.id}.action_button`}
        className={classes.actionIconButton}
        variant="text"
        onClick={() => {
          setSelectedId(row.id);
          setDeletePopupOpened(true);
        }}
      >
        <TrashAltIcon className={classes.actionIcon} />
      </Button>
    );
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const createSortHandler = (property: keyof Data) => () => {
    handleRequestSort(property);
  };

  const handleRequestSort = (
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    if (!addUsersRefresh) return;

    dispatch(fromUserGroupUser.getAllUserGroupUsers(
      { userGroupId },
    ));
  }, [addUsersRefresh]);

  useEffect(() => {
    const usersData = userGroupUsers.map(it => createData({
      id: it.id,
      name: getFullNameUser(it.user?.firstName, it.user?.lastName, it.user?.email ?? ''),
      email: it.user?.email ?? '',
      joinDate: intl.formatDate(new Date(it.createdAt), { month: 'short', day: 'numeric', year: 'numeric' }),
    }));
    setUsers(usersData);
  }, [userGroupUsers]);

  useEffect(() => {
    if (!currentUserGroup) return;
    const fetchInformation = async () => {
      await Promise.all([
        dispatch(fromUsers.doFetchUserById({ id: Number(currentUserGroup.createdBy ?? 0) })),
      ]);
    };
    fetchInformation();
  }, [currentUserGroup]);

  useEffect(() => {
    if (!userGroupId) return;
    const fetchInformation = async () => {
      await Promise.all([
        dispatch(fromUserGroupUser.getAllUserGroupUsers(
          { userGroupId },
        )),
      ]);
    };
    fetchInformation();
  }, [userGroupId]);

  useEffect(() => {
    setSearchText('');
    setDataTable(users);
  }, [users]);

  return (
    <>
      <Card className={classes.card}>
        <Grid container spacing={2}>
          {/* Users */}
          <Grid item xs={12}>
            <Grid container className={classes.titleGrid}>
              <Grid item>
                <Typography className={classes.pageTitle}>
                  {intl.formatMessage({ id: 'user.group.detail.userlist.title' })}
                </Typography>
              </Grid>
              <Grid item className={classes.totalUser} id="user.group.detail.userlist.total_users">
                {users.length}
              </Grid>
            </Grid>
          </Grid>

          {/* Search Box */}
          <Grid item xs={12}>
            <TextField
              id="user.group.detail.userlist.search_box"
              size="small"
              variant="standard"
              placeholder={intl.formatMessage({ id: 'user.group.detail.userlist.table.search.placeholder' })}
              InputProps={{
                classes: { input: classes.inputSearch },
                disableUnderline: true,
                startAdornment: (
                  <InputAdorment position="start">
                    <SearchIcon className={classes.defaultIcon} />
                  </InputAdorment>
                ),
              }}
              className={classes.search}
              autoComplete="off"
              onChange={handleChangeSearchText}
              value={searchText}
            />
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table aria-label="table" id="user.group.detail.userlist.table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    {columns.map(column => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, width: column.width }}
                        id={`user.group.detail.userlist.table.header-${column.id}`}
                      >
                        {!column.ignoreHeader
                          && (
                            <TableSortLabel
                              active={orderBy === column.id}
                              direction={orderBy === column.id ? order : 'asc'}
                              onClick={createSortHandler(column.id)}
                            >
                              {intl.formatMessage({ id: `user.group.detail.userlist.table.column_${column.id}` })}
                            </TableSortLabel>
                          )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {orderTable(
                    dataTable,
                    [data => {
                      const value = data[orderBy as keyof Data];
                      return typeof value === 'string'
                        ? value.toLowerCase()
                        : value;
                    }],
                    order,
                  ).slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage,
                  ).map(row => (
                    <TableRow hover tabIndex={-1} key={row.id} className={classes.dataRow}>
                      {columns.map(column => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            id={`user.group.detail.userlist.table.user.row-${row.id}.column-${column.id}`}
                            sx={{ borderBottom: 'solid 1px #DBDDE5 !important' }}
                          >
                            {column.render?.(row)}
                            {!column.render && value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                  {dataTable.length === 0 && (
                    <TableRow className={classes.noDataLabel}>
                      <TableCell
                        id="user.group.detail.userlist.table.no_data"
                        colSpan={columns.length}
                        align="center"
                      >
                        {userGroupUsers.length > 0
                          ? intl.formatMessage({ id: 'common.message.table_no_result_found' })
                          : intl.formatMessage({ id: 'common.message.table_no_data' })}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {dataTable.length > 0 && (
              <TablePagination
                rowsPerPageOptions={[10, 15, 20]}
                component="div"
                count={dataTable.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                classes={{
                  toolbar: classes.tablePaginationToolbar,
                  selectLabel: classes.tablePaginationSelectLabel,
                  select: classes.tablePaginationSelect,
                  selectIcon: classes.tablePaginationSelectIcon,
                  menuItem: classes.tablePaginationSelect,
                }}
              />
            )}
          </Grid>
        </Grid>
      </Card>
      {isDeletePopupOpened && (
        <DeleteUserGroupUserPopup
          id={selectedId}
          userGroupId={userGroupId}
          isOpen={isDeletePopupOpened}
          onCloseDialog={() => setDeletePopupOpened(false)}
          onSuccessCallback={() => { }}
        />
      )}
    </>
  );
};

export default UserGroupDetailUserList;
