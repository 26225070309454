import { createAsyncThunk, createSelector, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { AppState } from '.';
import { OrganizationFeatureFlag } from '../models';
import { Operator, Query } from '../models/query';
import { OrganizationFeatureFlagService } from '../services';

export const ORGANIZATION_FEATURE_FLAG_FEATURE_KEY = 'organizationFeatureFlags';

interface OrganizationFeatureFlagState extends EntityState<OrganizationFeatureFlag> {
  loading: boolean;
  count: number;
}

const organizationFeatureFlagAdapter = createEntityAdapter<OrganizationFeatureFlag>();

export const createInitialState = (): OrganizationFeatureFlagState => (
  organizationFeatureFlagAdapter.getInitialState({
    loading: false,
    count: 0,
  })
);

export const doGetByOrganizationId = createAsyncThunk(
  'organizationFeatureFlags/GgtByOrganizationId',
  async (input: Required<Pick<OrganizationFeatureFlag, 'organizationId'>>) => {
    const criteria: Query<OrganizationFeatureFlag>[] = [
      {
        field: 'organizationId',
        operator: Operator.EQ,
        value: input.organizationId,
      },
    ];

    // unique organizationId of OrganizationFeatureFlag => max 1 record
    const data = await OrganizationFeatureFlagService.getOrganizationFeatureFlags(...criteria);
    return data;
  },
);

const organizayionFeatureFlagSlice = createSlice({
  name: ORGANIZATION_FEATURE_FLAG_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doGetByOrganizationId.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetByOrganizationId.fulfilled, (state, action) => {
      state.loading = false;
      state.count = action.payload.total;
      organizationFeatureFlagAdapter.setAll(state, action.payload.data);
    });
    builder.addCase(doGetByOrganizationId.rejected, state => {
      state.loading = false;
    });
  },
});

const selectOrganizationFeatureFlagFeature = (
  state: AppState,
) => state[ORGANIZATION_FEATURE_FLAG_FEATURE_KEY];

export const {
  selectAll: selectAllOrganizationFeatureFlags,
  selectIds,
  selectEntities,
} = organizationFeatureFlagAdapter.getSelectors(selectOrganizationFeatureFlagFeature);

export const selectOneByOrganizationId = (organizationId: OrganizationFeatureFlag['organizationId']) => createSelector(
  selectAllOrganizationFeatureFlags,
  all => all.find(it => it.organizationId === organizationId),
);

export const selectIsOrganizationSsoEnabled = (organizationId: OrganizationFeatureFlag['organizationId']) => createSelector(
  selectOneByOrganizationId(organizationId),
  featureFlag => featureFlag && featureFlag.sso,
);

export default organizayionFeatureFlagSlice.reducer;
