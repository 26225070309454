import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import identity from 'lodash/fp/identity';
import { useSnackbar } from 'notistack';
import { memo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { PayloadAction } from '@reduxjs/toolkit';
import { AuthException, BadRequestException, exceptionOf, ForbiddenException, Token, UnknownException } from './models';
import { logout as logoutRoute, forbidden, useNavigate, useQuery } from './routes';
import { useResponseInterceptor } from './services/http-hook';
import { fromAuth, useAppDispatch } from './store';
import { useConfig } from './config';
import { isInSystemMaintenance } from './models/config';
import {
  accountSubscriptionManagement,
  testOpsPlatformCheckout,
} from './layout/routes';

const ErrorHandler = () => {
  const { replace, replaceQuery } = useNavigate();
  const { queryDictionary, get } = useQuery();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const didLogin = useSelector(fromAuth.selectDidLogin);
  const { config } = useConfig();
  const dispatch = useAppDispatch();
  let refreshTokenPromise: Promise<any> | undefined;
  const location = window.location.href;

  const IGNORE_REFRESH_URL = [
    '/v1/auth/login',
    '/v1/auth/oidc/token',
  ];

  const SUBSCRIPTION_PAGE_PATHS = [
    accountSubscriptionManagement.path,
    testOpsPlatformCheckout.path,
  ];

  const handleUnauthorized = async (error: AxiosError) => {
    const originalConfig = error.config;
    if (checkIgnoreUnauthorizedRequest(error)) {
      return error;
    }
    const storedToken = localStorage.getItem('k1.login') ?? 'null';
    const { refreshJwt } = JSON.parse(storedToken) as Token;
    if (refreshJwt && originalConfig) {
      const refreshResult = await handleRefreshToken(error, originalConfig, refreshJwt);
      return refreshResult;
    }
    return error;
  };

  const checkIgnoreUnauthorizedRequest = (error: AxiosError) => {
    const originalConfig = error.config;
    // Check if login => do not refresh
    if (IGNORE_REFRESH_URL.includes(originalConfig?.url ?? '')) {
      if (!get('access_token') && didLogin) {
        replace(
          logoutRoute.path,
          replaceQuery(queryDictionary()),
        );
      }
      return true;
    }
    return false;
  };

  const handleRefreshToken = async (
    error: AxiosError,
    originalConfig: InternalAxiosRequestConfig<any>,
    refreshToken: string,
  ) => {
    try {
      const isOriginRefreshRequest = !refreshTokenPromise;
      if (isOriginRefreshRequest) {
        try {
          refreshTokenPromise = dispatch(
            fromAuth.doRefreshToken({ refreshToken }),
          );
        } catch (ex) {
          // do nothing
          return error;
        }
      }
      const response: PayloadAction<{ token: Token }> = await refreshTokenPromise;
      const { token } = response.payload;
      if (isOriginRefreshRequest) {
        await dispatch(fromAuth.doResume(token));
      }
      originalConfig.headers.Authorization = `Bearer ${token.jwt}`;
    } catch (ex) {
      // do nothing
      return error;
    } finally {
      refreshTokenPromise = undefined;
    }
    const requestAfterRefresh = await axios(originalConfig);
    return requestAfterRefresh;
  };

  useResponseInterceptor(identity, async (error, appConfig) => {
    const exception = exceptionOf(error);
    if (isInSystemMaintenance(appConfig)) {
      enqueueSnackbar(
        intl.formatMessage({ id: 'error.system_maintenance_mode' }),
        { variant: 'error' },
      );
    } else if (exception.isInstanceOf(AuthException)) {
      const result = await handleUnauthorized(error);
      return result;
    }
    if (exception.isInstanceOf(ForbiddenException)) {
      replace(forbidden.path, replaceQuery(queryDictionary()));
      return error;
    }
    if (exception.isInstanceOf(UnknownException)) {
      enqueueSnackbar(
        intl.formatMessage({ id: exception.message }),
        { variant: 'error' },
      );
    }
    if (exception.isInstanceOf(BadRequestException)
      && SUBSCRIPTION_PAGE_PATHS.some(path => location.includes(path))) {
      enqueueSnackbar(
        intl.formatMessage({ id: exception.message }),
        { variant: 'error' },
      );
    }
    return error;
  }, config);

  return null;
};

export default memo(ErrorHandler);
