import Axios from 'axios';

import { DataResponse, Organization, TestActivity } from '../models';

export const getPast7DaysTestActivities = async (input: Organization['id']) => (await Axios.get<DataResponse<TestActivity[]>>('/v1/dashboard/test-activities', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: {
    organizationId: input,
  },
  data: {},
})).data.data;
