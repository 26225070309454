import { useSelector } from 'react-redux';
import {
  Account,
  OrganizationFeature,
} from '../../../../models';
import AdditionalSubscriptionRow from './AdditionalSubscriptionRow';
import {
  fromStudioSubscriptions,
  useAppDispatch,
} from '../../../../store';

export interface KseOfflineSubscriptionDetailsRowProps {
  account: Account | null,
  email: string | null,
  organizationId: number,
}

const KseOfflineSubscriptionDetails = (props: KseOfflineSubscriptionDetailsRowProps) => {
  const {
    account,
    organizationId,
    email,
  } = props;

  const dispatch = useAppDispatch();
  const currentKSESubscription = useSelector(
    fromStudioSubscriptions.selectByAccountIdAndFeature(
      Number(account?.id),
      OrganizationFeature.PER_USER_KSE_OFFLINE,
    ),
  )?.[0];

  const handleRefreshSubscription = async () => {
    await Promise.all([
      dispatch(fromStudioSubscriptions.doGetActiveByAccountId({
        accountId: Number(account?.id),
      })),
    ]);
  };

  return (
    currentKSESubscription ? (
      <AdditionalSubscriptionRow
        key="subscriptions.katalon_studio_enterprise_offline"
        name="subscriptions.katalon_studio_enterprise_offline"
        organizationId={organizationId}
        email={email}
        currentSubscription={currentKSESubscription}
        feature={OrganizationFeature.PER_USER_KSE_OFFLINE}
        handleRefreshSubscription={handleRefreshSubscription}
        account={account}
      />
    ) : null
  );
};

export default KseOfflineSubscriptionDetails;
