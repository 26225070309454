import Axios from 'axios';

import { DataResponse, DefaultPaymentMethod, PageableResponse, PaymentMethod, PaymentMethodType } from '../models';
import { composeQuery, Query } from '../models/query';

export const getPaymentMethods = async (...criteria: Query<PaymentMethod>[]) => (await Axios.get<PageableResponse<PaymentMethod>>('/v1/account-payment-methods', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: composeQuery<PaymentMethod>(...criteria),
  // must specify data to keep Content-Type header
  // though axios will remove request body when using GET method.
  data: {},
})).data.data;

export const createPaymentMethod = async (input: Required<Pick<PaymentMethod, 'accountId' | 'stripeToken'>>) => (await Axios.post<DataResponse<PaymentMethod>>('/v1/account-payment-methods', input)).data.data;

export const updatePaymentMethod = async (input: Required<Pick<PaymentMethod, 'id' | 'stripeToken'>>) => (await Axios.put<DataResponse<PaymentMethod>>(`/v1/account-payment-methods/${input.id}`, input)).data.data;

export const deletePaymentMethod = async (input: Required<Pick<PaymentMethod, 'id'>>) => (await Axios.delete<DataResponse<PaymentMethod>>(`/v1/account-payment-methods/${input.id}`)).data.data;

export const getDefaultPaymentMethod = async (accountId: number) => (
  await Axios.get<DataResponse<PaymentMethodType>>('/v1/account-payment-methods/default-payment-method', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    params: {
      account_id: accountId,
    },
    data: {},
  })
).data.data;

export const updateDefaultPaymentMethod = async (input: Required<DefaultPaymentMethod>) => (
  await Axios.put<DataResponse<PaymentMethodType>>('/v1/account-payment-methods/default-payment-method', input)
).data.data;
