import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ClearIcon from '@mui/icons-material/Clear';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import uniq from 'lodash/uniq';
import { User, OrganizationRole } from '../../models';
import { fromAuth, fromOrganizations, fromOrganizationUsers } from '../../store';
import DialogTransition from '../../components/transition/DialogTransition';
import { useConfig } from '../../config';
import { useLaunchDarkly } from '../../launchdarkly';

type ConfirmChangeDialogVariant = 'removeUser' | 'changeRole' | 'revokeInvitation' | 'resendInvitation' | 'revokeSsoInvitation';

const testOpsDomain = 'testops.katalon.io';

const toUnderscore = (v: ConfirmChangeDialogVariant) => {
  switch (v) {
    case 'removeUser':
      return 'remove_user';
    case 'changeRole':
      return 'change_role';
    case 'revokeInvitation':
      return 'revoke_invitation';
    case 'resendInvitation':
      return 'resend_invitation';
    case 'revokeSsoInvitation':
      return 'revoke_sso_invitation';
    default: return null;
  }
};
interface ConfirmChangeDialogProps<GenericUser extends User> {
  variant: ConfirmChangeDialogVariant;
  open: boolean;
  onClose: () => void;
  affectedUsers: GenericUser[];
  onSubmit: (finalListUsers: GenericUser[], role: OrganizationRole | undefined) => void;
  isSsoEnabled?: boolean;
}

const useStyles = makeStyles(theme => ({
  dialog: {
    '& > * > .MuiDialog-paper': {
      padding: theme.spacing(1, 3),
      borderRadius: '1rem',
      [theme.breakpoints.up('sm')]: {
        minWidth: '40rem',
      },
    },
  },
  subtitle: {
    // fontSize: theme.typography.h5.fontSize,
    marginBottom: theme.spacing(2),
  },
  chip: {
    margin: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
  },
  emailContainer: {
    padding: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    minHeight: theme.spacing(5),
    maxHeight: theme.spacing(23),
    border: '1px solid #EEF3FA',
    borderRadius: theme.spacing(1),
    overflow: 'auto',
  },
  dialogActions: {
    marginTop: theme.spacing(4),
    margin: theme.spacing(2),
  },
  chooseRoleSection: {
    marginTop: theme.spacing(1),
  },
  chooseRoleTitle: {
    color: '#516393',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chooseRoleLink: {
    color: '#1880F8',
    fontWeight: theme.typography.fontWeightBold,
  },
  chooseRoleSelect: {
    marginTop: theme.spacing(1.5),
    width: '70%',
  },
  cancelButton: {
    marginRight: theme.spacing(1.5),
  },
}));

const ConfirmChangeDialog = <GenericUser extends User>
  (props: ConfirmChangeDialogProps<GenericUser>) => {
  const classes = useStyles();
  const { flags } = useLaunchDarkly();
  const { affectedUsers, open, onClose, variant, onSubmit, isSsoEnabled } = props;
  const [finalListUsers, setFinalListUsers] = useState([...affectedUsers]);
  const intlVariant = toUnderscore(variant);
  const intl = useIntl();
  const currentOrg = useSelector(fromOrganizations.selectSelectedOrganization);
  const { config } = useConfig();
  useEffect(() => {
    setFinalListUsers([...affectedUsers]);
  }, [affectedUsers]);
  const user = useSelector(fromAuth.selectUser);
  const currentOrgUser = useSelector(fromOrganizationUsers.selectByUserEmailAndOrganizationId(
    user?.email || '',
    currentOrg?.id || 0,
  ));

  const handleDelete = (deletedUser: GenericUser) => () => {
    setFinalListUsers(finalListUsers.filter(it => it.id !== deletedUser.id));
  };
  const [newRole, setNewRole] = useState('');
  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewRole(event.target.value ?? '');
  };

  useEffect(() => {
    if (!open) {
      // Reset role after submit action
      setNewRole('');
    }
  }, [open]);

  const handleSubmit = () => {
    onSubmit(finalListUsers, OrganizationRole.fromString(newRole));
  };
  const handleClose = () => {
    setNewRole('');
    setFinalListUsers([...affectedUsers]);
    onClose();
  };
  const customDomain = `${currentOrg?.domain}.katalon.io`;
  // different description between revokeInvitation with sso enabled and without sso enabled
  const subTitleId = (isSsoEnabled && variant === 'revokeInvitation')
    ? `user_management.confirm_action_dialog.${intlVariant}.subtitle_sso`
    : `user_management.confirm_action_dialog.${intlVariant}.subtitle`;

  const assignableRoles = !config?.onpremise
    ? [
      OrganizationRole.BILLING_MANAGER,
      OrganizationRole.MEMBER,
    ]
    : [
      OrganizationRole.ADMIN,
      OrganizationRole.MEMBER,
    ];

  if ((!flags?.projectStandardizeEnabled || currentOrgUser?.role === OrganizationRole.OWNER)
    && !config?.onpremise) {
    assignableRoles.push(OrganizationRole.ADMIN);
  }

  return (
    <Dialog open={open} onClose={handleClose} TransitionComponent={DialogTransition} aria-labelledby="form-dialog-title" className={classes.dialog}>
      <DialogTitle id="form-dialog-title">
        {intl.formatMessage({ id: `user_management.confirm_action_dialog.${intlVariant}.title` })}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" className={classes.subtitle}>
          {intl.formatMessage(
            { id: subTitleId },
            {
              orgName: currentOrg?.name,
              testOpsLink: (
                <Link underline="none" href={`https://${testOpsDomain}`}>
                  {testOpsDomain}
                </Link>
              ),
              customDomainLink: (
                <Link underline="none" href={`https://${customDomain}`}>
                  {customDomain}
                </Link>
              ),
            },
          )}
        </Typography>
        <Paper className={classes.emailContainer} variant="outlined">
          {finalListUsers.map(user => (
            <Chip
              key={`user${user.id}`}
              label={user.email}
              onDelete={handleDelete(user)}
              className={classes.chip}
              deleteIcon={<ClearIcon />}
              size="small"
            />
          ))}
        </Paper>
        {variant === 'changeRole' && (
          <div className={classes.chooseRoleSection}>
            <Typography variant="body2" className={classes.chooseRoleTitle}>
              {intl.formatMessage({ id: 'user_management.confirm_action_dialog.change_role.choose_role' })}
            </Typography>
            {/* <br /> */}
            <Typography variant="body2">
              {intl.formatMessage({ id: 'user_management.confirm_action_dialog.change_role.choose_role_desc' }, { orgName: currentOrg?.name })}
            </Typography>
            <div>
              <Link color="textPrimary" variant="body2" href="https://docs.katalon.com/katalon-analytics/docs/testops-roles-privileges.html" target="_blank" rel="noopener noreferrer" className={classes.chooseRoleLink}>
                {intl.formatMessage({ id: 'user_management.confirm_action_dialog.change_role.choose_role_link' })}
              </Link>
            </div>
            <TextField
              value={newRole}
              onChange={handleRoleChange}
              select
              SelectProps={{
                IconComponent: KeyboardArrowDownIcon,
              }}
              className={classes.chooseRoleSelect}
              variant="outlined"
              size="small"
            >
              {uniq(assignableRoles).map(role => (
                <MenuItem key={role.toString()} value={role.toString()}>
                  {OrganizationRole.getRoleFullName(role)}
                </MenuItem>
              ))}
            </TextField>
          </div>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={handleClose} variant="contained" className={classes.cancelButton}>
          {intl.formatMessage({ id: 'user_management.confirm_action_dialog.common.cancel' })}
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={finalListUsers.length === 0 || (variant === 'changeRole' && !newRole)}
        >
          {intl.formatMessage({ id: `user_management.confirm_action_dialog.${intlVariant}.submit` })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmChangeDialog;
