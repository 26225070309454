import Axios from 'axios';
import {
  DataResponse,
  ServiceCloudOrganizationUser,
  PageableResponse,
} from '../models';
import { composeQuery, Query } from '../models/query';

export const getServiceCloudOrganizationUsers = async (
  ...criteria: Query<ServiceCloudOrganizationUser>[]
) => (
  await Axios.get<PageableResponse<ServiceCloudOrganizationUser>>('/v1/service-cloud-organization-users', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    params: composeQuery<ServiceCloudOrganizationUser>(...criteria),
    data: {},
  })
).data;

export const createServiceCloudOrganizationUser = async (
  input: Required<Pick<ServiceCloudOrganizationUser, 'organizationId' | 'email'>>,
) => (await (Axios.post<DataResponse<ServiceCloudOrganizationUser>>('/v1/service-cloud-organization-users', input))).data.data;

export const deleteServiceCloudOrganizationUser = async (
  input: Required<Pick<ServiceCloudOrganizationUser, 'id'>>,
) => (await Axios.delete<DataResponse<ServiceCloudOrganizationUser>>(`/v1/service-cloud-organization-users/${input.id}`)).data.data;

export const getActiveServiceCloudOrganizationUser = async () => (await (Axios.get<DataResponse<ServiceCloudOrganizationUser>>('/v1/service-cloud-organization-users/active', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  data: {},
}))).data.data;
