import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import WarningIcon from '@mui/icons-material/Warning';
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useConfig } from '../../config';
import LoadingProgress from '../../layout/LoadingProgress';
import { ReactComponent as Banner } from '../../layout/icons/katalon_login_banner.svg';
import { ReactComponent as Logo } from '../../layout/icons/katalon_new_logo.svg';
import { createUser } from '../../services/user';
import { activateLicense, login, useNavigate, useQuery, welcome } from '../../routes';
import { Role } from '../../models';
import { fromAuth, fromUsers, useAppDispatch } from '../../store';
import { fromActiveLicense } from '../../store/rootReducer';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      height: '200vh',
      '& > *': {
        width: '100vw',
        height: '100vh',
      },
    },
    '& .MuiCardHeader-title': {
      fontSize: theme.spacing(3),
      fontWeight: theme.typography.fontWeightMedium,
      color: '#0B1F3F',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: theme.spacing(1.75),
      fontWeight: 'normal',
    },
    '& .MuiOutlinedInput-input': {
      fontSize: theme.spacing(2.25),
      fontWeight: theme.typography.fontWeightRegular,
      color: 'black',
    },
    '& .MuiOutlinedInput-root': {
      height: theme.spacing(7),
      width: theme.spacing(63),
      '::placeholder': {
        fontSize: theme.spacing(2),
        verticalAlign: 'middle',
      },
    },
  },
  banner: {
    flex: '0 0 auto',
    backgroundSize: 'cover',
    position: 'relative',
    [theme.breakpoints.between('sm', 'lg')]: {
      display: 'none',
    },
    '& > svg': {
      height: '100vh',
      width: 'auto',
    },
  },
  page: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    minHeight: theme.spacing(62.5),
  },
  cardContainer: {
    flex: '1 1 auto',
    display: 'flex',
  },
  card: {
    margin: theme.spacing(21),
    width: '100%',
    minWidth: theme.spacing(37.5),
    maxWidth: '35vw',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '70vw',
    },
  },
  logo: {
    maxWidth: '100%',
    textAlign: 'left',
    paddingLeft: theme.spacing(2),
    maxHeight: theme.spacing(6),
    marginBottom: theme.spacing(5),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > *:first-child': {
      marginTop: theme.spacing(4),
    },
    '& > *:not(:first-child)': {
      marginTop: theme.spacing(3),
    },
    '& > *:last-child': {
      marginTop: theme.spacing(5),
    },
    '& .MuiFormControlLabel-root > .MuiButtonBase-root': {
      borderColor: '#d6d6d6',
    },
  },
  buttonCreateRootUser: {
    width: theme.spacing(18.875),
    height: theme.spacing(7),
    marginTop: theme.spacing(10),
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(2),
    backgroundColor: '#0F8461',
    color: '#FFFFFF',
  },
  textField: {
    '& > * > .MuiOutlinedInput-input': {
      fontSize: theme.spacing(2),
    },
  },
  title: {
    borderBottom: '2px solid #d5d5e2',
    width: theme.spacing(63),
  },
  titleText: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(2),
    color: '#22283c',
    borderBottom: '2px solid #5959eb',
    marginBottom: theme.spacing(-0.25),
    paddingBottom: theme.spacing(0.5),
    width: theme.spacing(15.75),
  },
  error: {
    color: theme.palette.error.main,
    margin: 0,
  },
  errorContainer: {
    display: 'flex',
    alignItems: 'start',
    marginTop: '.2rem',
  },
  errorIcon: {
    marginRight: theme.spacing(0.5),
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  input: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    fontSize: theme.spacing(2),
    fontWeight: '900',
  },
}));

interface SignUpOnPremiseInput {
  email: string;
  password: string;
  confirmPassword: string;
}

const SignUpOnPremiseComponent = () => {
  const classes = useStyles();
  const { config } = useConfig();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { navigate, replace, replaceQuery } = useNavigate();
  const { queryDictionary } = useQuery();
  const activated = useSelector(fromActiveLicense.selectActivated);
  const adminCreated = useSelector(fromUsers.selectIsAdminCreated);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<SignUpOnPremiseInput>({
    defaultValues: { email: '', password: '', confirmPassword: '' },
  });

  useEffect(() => {
    setLoading(true);
    const getActivated = async () => {
      await dispatch(fromActiveLicense.doGetActivated());
    };
    getActivated()
      .then(() => setLoading(false))
      // eslint-disable-next-line no-console
      .catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(true);
    const checkAdminCreated = async () => {
      await dispatch(fromUsers.doCheckAdminCreated());
    };
    checkAdminCreated()
      .then(() => setLoading(false))
      // eslint-disable-next-line no-console
      .catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doCreateRootUser = async (input: SignUpOnPremiseInput) => {
    setLoading(true);
    try {
      await createUser({
        email: input!.email,
        password: input.password,
        roles: [Role.ROOT, Role.USER],
      });
      enqueueSnackbar(<FormattedMessage id="signup.root.user.success" />, {
        variant: 'success',
        autoHideDuration: 2000,
      });
      await dispatch(fromAuth.doLogin({
        userInfo: {
          email: input.email,
          password: input.password,
        },
        rememberMe: false,
      }));
      setTimeout(() => {
        replace(welcome.path, replaceQuery(queryDictionary()));
      }, 1000);
    } catch (error: any) {
      handleError(error.response.data.errors);
    } finally {
      setLoading(false);
    }
  };

  const handleError = (e: string[]) => {
    if (Array.isArray(e) && e.length > 0) {
      enqueueSnackbar(<FormattedMessage id={e[0]} />, { variant: 'error' });
    } else {
      enqueueSnackbar(<FormattedMessage id="error.unknown" />, { variant: 'error' });
    }
  };

  if (!config) return <></>;

  if (!activated) {
    navigate(activateLicense.path, replaceQuery({ }));
  }

  if (adminCreated) {
    navigate(login.path, replaceQuery({ }));
  }

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.banner}>
          <Banner />
        </Box>
        <Box className={classes.page}>
          <Box className={classes.cardContainer}>
            <Box className={classes.card}>
              <Box className={classes.logo}>
                <Logo />
              </Box>
              <CardContent>
                <Box className={classes.title}>
                  <Box className={classes.titleText}>
                    <FormattedMessage id="signup.root.user.form.title" />
                  </Box>
                </Box>
                <form className={classes.form} onSubmit={handleSubmit(doCreateRootUser)}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    className={classes.textField}
                    label={<FormattedMessage id="signup.root.user.form.email" />}
                    InputProps={{
                      classes: {
                        input: classes.input,
                      },
                    }}
                    inputProps={{
                      ...register('email', { required: 'error.field.required' }),
                      style: {
                        border: '#FFFFFF',
                      },
                    }}
                    helperText={
                      errors.email && (
                        <div className={classes.errorContainer}>
                          <WarningIcon className={classes.errorIcon} />
                          <FormattedMessage
                            id={errors.email?.message}
                            values={{
                              field: (
                                <FormattedMessage
                                  id="signup.root.user.form.email"
                                  values={{ char: '' }}
                                />
                              ),
                            }}
                          />
                        </div>
                      )
                    }
                    error={!!errors.email}
                  />
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="password"
                    name="password"
                    className={classes.textField}
                    label={<FormattedMessage id="signup.root.user.form.password" />}
                    inputProps={{
                      ...register('password', {
                        required: 'error.field.required',
                        maxLength: { message: intl.formatMessage({ id: 'error.maxlength' }, { length: 255 }), value: 255 },
                        pattern: {
                          value:
                            /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~‘-”])^[!-~‘-”][ -~‘-”]{6,253}[!-~‘-”]$/,
                          message: 'error.user.password.invalid',
                        },
                      }),
                    }}
                    InputProps={{
                      classes: {
                        input: classes.input,
                      },
                    }}
                    helperText={
                      errors.password && (
                        <div className={classes.errorContainer}>
                          <WarningIcon className={classes.errorIcon} />
                          <FormattedMessage
                            id={errors.password?.message}
                            values={{
                              field: (
                                <FormattedMessage
                                  id="signup.root.user.form.password"
                                  values={{ char: '' }}
                                />
                              ),
                            }}
                          />
                        </div>
                      )
                    }
                    error={!!errors.password}
                  />
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="password"
                    name="confirmPassword"
                    className={classes.textField}
                    label={<FormattedMessage id="signup.root.user.form.confirm.password" />}
                    inputProps={{
                      ...register('confirmPassword', {
                        required: 'error.field.required',
                        validate: value => value === watch('password')
                          || 'signup.root.user.form.password_not_match.error',
                      }),
                    }}
                    InputProps={{
                      classes: {
                        input: classes.input,
                      },
                    }}
                    helperText={
                      errors.confirmPassword && (
                        <div className={classes.errorContainer}>
                          <WarningIcon className={classes.errorIcon} />
                          <FormattedMessage
                            id={errors.confirmPassword?.message}
                            values={{
                              field: (
                                <FormattedMessage
                                  id="signup.root.user.form.confirm.password"
                                  values={{ char: '' }}
                                />
                              ),
                            }}
                          />
                        </div>
                      )
                    }
                    error={!!errors.confirmPassword}
                  />
                  <Button variant="contained" size="large" className={classes.buttonCreateRootUser} type="submit">
                    <FormattedMessage id="signup.root.user.form.action.getting.started" />
                  </Button>
                </form>
              </CardContent>
            </Box>
          </Box>
        </Box>
      </Box>
      {loading && <LoadingProgress />}
    </>
  );
};

export default SignUpOnPremiseComponent;
