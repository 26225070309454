import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useEffect, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import { useConfig } from '../../config';
import LoadingProgress from '../../layout/LoadingProgress';
import { fromActiveLicense, fromTestOpsConfiguration } from '../../store/rootReducer';
import { useAppDispatch } from '../../store';
import { activateLicense } from '../../services/activateLicense';
import { notFound } from '../../routes';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(3),
  },
  licenseField: {
    fontWeight: theme.typography.fontWeightMedium,
    color: '#1D3066',
  },
  label: {
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(1),
    color: '#1D3066',
  },
}));

interface ActivateLicenseServerInput {
  license: string;
}

const ActivateLicenseServer = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { config } = useConfig();
  const intl = useIntl();
  const machineId = useSelector(fromActiveLicense.selectMachineId);
  const [licenseKey, setLicenseKey] = useState('');
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const formRef = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    setLoading(true);
    const fetchMachineId = async () => {
      await dispatch(fromActiveLicense.doGetMachineId()).then(() => setLoading(false));
    };
    // eslint-disable-next-line no-console
    fetchMachineId().catch(console.error);
  }, []);
  const licenseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const readFile = async () => {
      if (event.target.files) {
        event.preventDefault();
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
          const license = fileReader.result;
          setLicenseKey(license as string);
        };
        const file = event.target.files[0];
        fileReader.readAsText(file);
      }
    };
    // eslint-disable-next-line no-console
    readFile().catch(console.error);
  };

  const doActivateLicense = () => {
    setLoading(true);
    const executeActivateLicense = async () => {
      await activateLicense({
        license: licenseKey,
      });
      await dispatch(fromTestOpsConfiguration.doGetTestOpsFeature());
    };

    executeActivateLicense()
      .then(() => {
        setLicenseKey('');
        formRef.current?.reset();
        enqueueSnackbar(<FormattedMessage id="activate.license.server.success" />, { variant: 'success' });
      })
      .catch(error => handleError(error.response.data.errors))
      .finally(() => setLoading(false));
  };

  const handleError = (e: string[]) => {
    if (Array.isArray(e) && e.length > 0) {
      enqueueSnackbar(<FormattedMessage id={e[0]} />, { variant: 'error' });
    } else {
      enqueueSnackbar(<FormattedMessage id="error.unknown" />, { variant: 'error' });
    }
  };
  const {
    handleSubmit,
  } = useForm<ActivateLicenseServerInput>({
    defaultValues: { license: '' },
  });
  if (!config) return <></>;
  if (!config?.onpremise) return <Navigate to={notFound.path} replace />;

  return (
    <>
      <Box className={classes.root}>
        <Typography className={classes.title}>
          <FormattedMessage id="activate.license.server.title" />
        </Typography>
        <Box sx={{ pt: '1.5rem' }}>
          <Paper sx={{ padding: '0.75rem 1.5rem' }}>
            <form
              id="activate.license.server.form"
              // eslint-disable-next-line no-void
              onSubmit={event => void handleSubmit(doActivateLicense)(event)}
              ref={formRef}
            >
              <Grid>
                <Typography variant="h5" className={classes.label}>
                  {intl.formatMessage({ id: 'activate.license.form.machine_id' })}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="h5" className={classes.label}>
                  {machineId}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="h5" className={classes.label}>
                  {intl.formatMessage({ id: 'activate.license.form.license' })}
                </Typography>
              </Grid>
              <Grid>
                <input
                  type="file"
                  id="active.license.server.licenseKey"
                  required
                  className={classes.licenseField}
                  onChange={e => licenseChange(e)}
                />
              </Grid>
              <br />
              <Button
                variant="contained"
                type="submit"
                color="primary"
              >
                <FormattedMessage id="activate.license.form.action.activate" />
              </Button>
            </form>
          </Paper>
        </Box>
      </Box>
      {loading && <LoadingProgress />}
    </>
  );
};

export default ActivateLicenseServer;
