import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { startOfDay, startOfMonth } from 'date-fns';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useConfig } from '../../config';
import { ChartLoadingIcon } from '../../layout/CustomIcon';
import { isOrganizationManager, OrganizationFeature, TestCloudSubscription, TestOpsPlanInterval } from '../../models';
import { Config } from '../../models/config';
import { useQuery } from '../../routes';
import {
  fromAuth,
  fromCurrentOrgUser,
  fromOrganizations,
  fromTestCloudSubscriptions,
  fromTestCloudUsage,
  useAppDispatch,
} from '../../store';
import { getTestCloudFeature, getTestCloudFreeTrialExpiryDate } from '../subscription-management/utils';
import ConcurrentSessionChart from './ConcurrentSessionChart';

// O(1)
const computeCurrentCycleStartAndEnd = (currSub: TestCloudSubscription) => {
  if (!currSub) return { cycleStart: new Date(), cycleEnd: new Date() };
  let subStartedAt = new Date(currSub.expiredAt);

  switch (currSub.billingInterval) {
    case TestOpsPlanInterval.MONTH:
      subStartedAt.setMonth(subStartedAt.getMonth() - 1);
      break;
    case TestOpsPlanInterval.YEAR:
      subStartedAt.setFullYear(subStartedAt.getFullYear() - 1);
      break;
    case TestOpsPlanInterval.TWO_YEARS:
      subStartedAt.setFullYear(subStartedAt.getFullYear() - 2);
      break;
    case TestOpsPlanInterval.THREE_YEARS:
      subStartedAt.setFullYear(subStartedAt.getFullYear() - 3);
      break;
    default:
      break;
  }

  // If the subscription is created by API, started date is created date
  if (!currSub.recurlySubscription) {
    subStartedAt = new Date(currSub.createdAt);
  }
  const now = new Date();
  let cycleStart = new Date(subStartedAt);
  let cycleEnd = new Date(subStartedAt);
  const expiredAt = new Date(currSub.expiredAt);
  cycleEnd.setMonth(cycleEnd.getMonth() + 1);
  while (cycleEnd.getTime() < now.getTime()
    && cycleEnd.getTime() < expiredAt.getTime()) {
    cycleStart = new Date(cycleEnd);
    cycleEnd.setMonth(cycleEnd.getMonth() + 1);
  }
  if (cycleEnd.getTime() > expiredAt.getTime()) {
    cycleEnd = new Date(expiredAt);
  }
  return { cycleStart, cycleEnd };
};

// O(1)
const computeChartStartDate = (
  config: Config | undefined,
  orgCreatedAt: Date | undefined,
  lastActiveSub: TestCloudSubscription | undefined,
  lastInactiveSub: TestCloudSubscription | undefined,
) => {
  if (lastActiveSub) {
    // If there is an active subscription at the moment, show the chart
    // from the start of the usage cycle
    return startOfDay(computeCurrentCycleStartAndEnd(lastActiveSub).cycleStart);
  }
  const now = new Date();
  const startOfCurrentMonth = startOfMonth(now);
  if (lastInactiveSub) {
    const startOfMonthExpiredAt = startOfMonth(lastInactiveSub.expiredAt);
    if (new Date(lastInactiveSub.expiredAt).getTime() > now.getTime()) {
      // This only happens when the subscription is terminated manually on Recurly
      return startOfCurrentMonth;
    }
    if (startOfMonthExpiredAt.getTime() === startOfCurrentMonth.getTime()) {
      // When the subscription expired in current month,
      // show the chart from the expiry date
      return startOfDay(lastInactiveSub.expiredAt);
    }
    // When the subscription expired in last month or before that,
    // show the chart from the begin of current month
    return startOfCurrentMonth;
  }
  // No active or inactive subscription was found, so it could be in free trial
  // Get the trial plan's expiry date
  const trialExpiredAt = new Date(getTestCloudFreeTrialExpiryDate(config, orgCreatedAt));
  if (trialExpiredAt.getTime() > now.getTime()) {
    // Still in trial period, show from the beginning of the trial period
    return startOfDay(trialExpiredAt.setDate(trialExpiredAt.getDate() - 30));
  }
  // Trial plan expired
  const startOfMonthExpiredAt = startOfMonth(trialExpiredAt);
  if (startOfMonthExpiredAt.getTime() === startOfCurrentMonth.getTime()) {
    // If the expiry date is in the current month, show from the expiry date
    return startOfDay(trialExpiredAt);
  }
  // Trial plan expired in last month or before that,
  // show the chart from the begin of current month.
  return startOfCurrentMonth;
};

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    borderRadius: '.5rem',
    padding: '1.5rem',
  },
  titleContainer: {
    padding: theme.spacing(0, 2),
    marginBottom: theme.spacing(3),
  },
  main: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    flex: 1,
  },
  dashboardTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  subTitle: {
    color: '#808b9a',
    fontSize: theme.spacing(1.75),
  },
}));

const TestCloudDashboard = () => {
  const classes = useStyles();
  const intl = useIntl();
  const { get } = useQuery();
  const orgId = get('orgId');
  const dispatch = useAppDispatch();
  const { config } = useConfig();
  const [loading, setLoading] = useState(false);
  const [isOrgUserUpdated, setIsOrgUserUpdated] = useState(false);
  const [chartRange, setChartRange] = useState<{ startDate: Date, endDate: Date } | null>(null);
  const [feature, setFeature] = useState<OrganizationFeature | undefined>(undefined);
  const currentOrganization = useSelector(fromOrganizations.selectSelectedOrganization);
  const user = useSelector(fromAuth.selectUser);
  const currentOrganizationUser = useSelector(
    fromCurrentOrgUser.selectByOrganizationIdAndEmail(
      user?.email || '',
      Number(orgId),
    ),
  );
  const isRootOrAdmin = useSelector(fromAuth.selectIsRootOrAdmin);
  // const allSubscriptions = useSelector(fromTestCloudSubscriptions
  //   .selectAllTestCloudSubscriptions);
  const currentSubscription = useSelector(fromTestCloudSubscriptions
    .selectLatestActiveTestCloudSubscriptionByOrganizationId(+orgId!!));
  const lastInactiveSubscription = useSelector(fromTestCloudSubscriptions
    .selectLatestInactiveTestCloudSubscriptionByOrganizationId(+orgId!!));
  const latestTestCloudSubscription = useSelector(fromTestCloudSubscriptions
    .selectLatestActiveTestCloudSubscriptionByOrganizationId(+orgId!!));
  const concurrentSessionMetricData = useSelector(fromTestCloudUsage
    .selectConcurrentSessionMetricData);
  const adjustedConcurrentSessionData = useSelector(fromTestCloudUsage
    .selectAdjustedConcurrentSessionMetricData);
  const fallbackTestCloudUsages = useSelector(fromTestCloudUsage
    .selectFallbackTestCloudUsages);
  const latestUsage = useSelector(fromTestCloudUsage.selectLatestTestCloudUsage);

  useEffect(() => {
    if (!orgId || !user) return;
    (async () => {
      setIsOrgUserUpdated(false);
      await dispatch(fromCurrentOrgUser
        .doGetOrgUserByUserIdAndOrgId({ id: user.id, organizationId: +orgId }));
      setIsOrgUserUpdated(true);
    })();
    analytics.page();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, user]);

  useEffect(() => {
    if (!orgId || !isOrgUserUpdated) return;
    if (!isRootOrAdmin
      && (!currentOrganizationUser || !isOrganizationManager(currentOrganizationUser.role))) return;
    setLoading(true);
    const fetchInfo = async () => {
      await Promise.all([
        dispatch(fromTestCloudSubscriptions
          .doGetTestCloudSubscriptionsByOrganizationId({ organizationId: +orgId })),
        dispatch(fromTestCloudUsage.doGetLatestTestCloudUsage({ organizationId: +orgId })),
      ]);
    };
    fetchInfo()
      .catch(() => {})
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOrgUserUpdated]);

  useEffect(() => {
    (async () => {
      if (loading || !currentOrganization) return;
      setFeature(getTestCloudFeature(
        latestTestCloudSubscription,
        config,
        currentOrganization?.createdAt,
      ));
      const startDate = computeChartStartDate(
        config,
        currentOrganization!!.createdAt,
        currentSubscription,
        lastInactiveSubscription,
      );
      const endDate = new Date();
      setChartRange({ startDate, endDate });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, currentOrganization]);

  useEffect(() => {
    if (chartRange && orgId) {
      dispatch(fromTestCloudUsage.doGetTestCloudDashboardData({
        organizationId: +orgId,
        startDate: chartRange.startDate,
        endDate: chartRange.endDate,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRange]);
  const trialExpiryDateString = feature === OrganizationFeature.TESTCLOUD_FREE_TRIAL
    && latestUsage !== null && latestUsage.subscriptionExpiredAt !== undefined
    ? ` ${intl.formatMessage(
      { id: 'testcloud_dashboard.trial_expiry_date' },
      {
        expiryDate: intl.formatDate(
          new Date(latestUsage.subscriptionExpiredAt),
          { year: 'numeric', month: 'long', day: 'numeric' },
        ),
      },
    )}`
    : null;

  return (
    <div className={classes.root}>
      <Grid container className={classes.titleContainer} justifyContent="space-between" alignItems="flex-start">
        <Grid item>
          <Grid container direction="column">
            <Typography variant="h2" className={classes.dashboardTitle}>
              <FormattedMessage id="app.page.title.testcloud_dashboard" />
            </Typography>
            <Typography variant="h5" className={classes.subTitle}>
              {
                !feature
                  ? intl.formatMessage({ id: 'testcloud_dashboard.you_have_no_sub' })
                  : intl.formatMessage(
                    { id: 'testcloud_dashboard.you_are_on_plan' },
                    {
                      plan: trialExpiryDateString ? OrganizationFeature.getFeatureFullName(feature) : 'Katalon TestCloud',
                    },
                  )
              }
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Box className={classes.main}>
        <Paper elevation={0} sx={{ py: 2, px: 4, mt: 3, mb: 2, my: 2 }}>
          <Typography variant="h3" mb={2}>
            <FormattedMessage id="testcloud_dashboard.usage_summary" />
          </Typography>
          {
            !loading ? (
              <ConcurrentSessionChart
                fallbackUsages={fallbackTestCloudUsages}
                concurrentSessionData={concurrentSessionMetricData}
                adjustedConcurrentSessionData={adjustedConcurrentSessionData}
                startDate={chartRange?.startDate}
                endDate={chartRange?.endDate}
              />
            ) : (
              <Box height={200} display="flex" justifyContent="center" alignItems="center">
                <ChartLoadingIcon />
              </Box>
            )
          }
        </Paper>
      </Box>
    </div>
  );
};

export default TestCloudDashboard;
