import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useConfig } from '../../config';
import { ReactComponent as RocketDashboard } from './components/RocketDashboard.svg';
import { resolvePath, useQuery } from '../../routes';
import {
  fromAuth,
  fromCurrentOrgUser,
  fromDashboard,
  fromTestResultCount,
  useAppDispatch,
} from '../../store';
import ExportTestResultCountButton from './components/ExportTestResultCountButton';
import TestActivitiesChart from './components/TestActivitiesChart';
import TestExecutionUsageChart from './components/TestExecutionUsageChart';
import WarningBanner from './components/WarningBanner';
import WarningDialog from './components/WarningDialog';
import { UsagePlan, SubscriptionSource } from '../../models';
import LoadingProgress from '../../layout/LoadingProgress';
import { accountSubscriptionManagement } from '../../layout/routes';
import PlatformWarningBanner from './components/PlatformWarningBanner';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    borderRadius: '.5rem',
    padding: '1.5rem',
  },
  titleContainer: {
    padding: theme.spacing(0, 2),
    marginBottom: theme.spacing(3),
  },
  main: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    flex: 1,
  },
  card: {
    padding: '1rem',
    height: '100%',
  },
  noLicenseScreen: {
    height: '100%',
    padding: theme.spacing(20, 0),
    textAlign: 'center',
  },
  boldText: {
    fontWeight: 'bold',
  },
  dashboardTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  warningBanner: {
    padding: theme.spacing(0, 2, 2, 2),
    marginBottom: theme.spacing(2),
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const intl = useIntl();
  const { get } = useQuery();
  const orgId = get('orgId');
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const user = useSelector(fromAuth.selectUser);
  const { config } = useConfig();
  const [dashboardHidden, setDashboardHidden] = useState(false);
  const [showPlatformBanner, setShowPlatformBanner] = useState(false);
  const testResultCount = useSelector(fromTestResultCount.selectLatestTestResultCount);
  const testActivities = useSelector(fromDashboard.selectPast7DaysTestActivities);
  const currentOrgUser = useSelector(fromCurrentOrgUser.selectByOrganizationIdAndEmail(
    user?.email || '',
    Number(orgId),
  ));
  const subscriptionLink = resolvePath(
    accountSubscriptionManagement,
    undefined,
    { orgId: Number(orgId), accountId: currentOrgUser?.organization.accountId },
  );
  useEffect(() => {
    const fetchInfo = async () => {
      if (orgId && user) {
        setLoading(true);
        await Promise.all([
          dispatch(fromDashboard.doGetPast7DaysTestActivities(+orgId)),
          dispatch(fromTestResultCount.doGetLatestTestResultCount(+orgId)),
        ]);
        setLoading(false);
      }
    };
    fetchInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, user?.email]);

  useEffect(() => {
    setDashboardHidden((config?.onpremise ?? false)
      && (testResultCount?.usagePlan === UsagePlan.COMMUNITY || testResultCount?.quota === 0));
    if (testResultCount) {
      const { quota, count, usagePlan } = testResultCount;
      const calculatedUsagePercentage = count / quota;
      setShowPlatformBanner(calculatedUsagePercentage > 1 && usagePlan === UsagePlan.PLATFORM);
    }
  }, [testResultCount, config?.onpremise]);

  return (
    <div className={classes.root}>
      <Grid container className={classes.titleContainer} justifyContent="space-between" alignItems="flex-start">
        <Grid item>
          <Grid container direction="column">
            <Typography variant="h2" className={classes.dashboardTitle}>
              <FormattedMessage id="dashboard.dashboard" />
            </Typography>
            {!dashboardHidden && (
              <Typography variant="h5">
                {
                  testResultCount?.quota === 0 ? intl.formatMessage({ id: 'dashboard.subtitle.no_subscription' }, {}) : (
                    testResultCount?.subscriptionSource === SubscriptionSource.TRIAL
                      ? intl.formatMessage({ id: 'dashboard.subtitle.trial' }, { planName: UsagePlan.getPlanFullName(testResultCount?.usagePlan) })
                      : intl.formatMessage({ id: 'dashboard.subtitle.paid' }, { planName: UsagePlan.getPlanFullName(testResultCount?.usagePlan) })
                  )
                }
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item>
          {!dashboardHidden && (
            <ExportTestResultCountButton
              organizationId={+orgId!!}
              onLoading={() => setLoading(true)}
              onComplete={() => setLoading(false)}
            />
          )}
        </Grid>
      </Grid>
      {!dashboardHidden && (
        <>
          <WarningBanner
            subscriptionLink={subscriptionLink}
            testResultCount={testResultCount}
          />
          {showPlatformBanner && (
            <div className={classes.warningBanner}>
              <PlatformWarningBanner
                subscriptionLink={subscriptionLink}
              />
            </div>
          )}
        </>
      )}
      <Box className={classes.main}>
        {
          dashboardHidden ? (
            <Paper className={classes.noLicenseScreen}>
              <Grid container direction="column" alignItems="stretch">
                <Grid item>
                  <RocketDashboard />
                  <Typography variant="h3" className={classes.boldText}>
                    {intl.formatMessage({ id: 'dashboard.no_license_message' })}
                  </Typography>
                  <Typography variant="h3" className={classes.boldText}>
                    {
                      intl.formatMessage({ id: 'dashboard.upgrade_contact' }, {
                        email: 'business@katalon.com',
                        a: () => <a href="mailto:business@katalon.com">business@katalon.com</a>,
                      })
                    }
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          ) : (
            <Grid container spacing={4} alignItems="stretch">
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <Card className={classes.card}>
                  {!loading && <TestExecutionUsageChart testResultCount={testResultCount} />}
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={8}>
                <Card className={classes.card}>
                  {!loading && <TestActivitiesChart testActivities={testActivities} />}
                </Card>
              </Grid>
            </Grid>
          )
        }
      </Box>
      {!dashboardHidden && (
        <WarningDialog
          subscriptionLink={subscriptionLink}
          testResultCount={testResultCount}
        />
      )}
      {loading && <LoadingProgress />}
    </div>
  );
};

export default Dashboard;
