import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdorment from '@mui/material/InputAdornment';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import { Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import orderBy from 'lodash/fp/orderBy';
import React, { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import CheckedIcon from '../../../components/icons/CheckedIcon';
import UnCheckedIcon from '../../../components/icons/UnCheckedIcon';
import { useLaunchDarkly } from '../../../launchdarkly';
import { CheckCircleIcon, QuestionCircleIcon } from '../../../layout/CustomIcon';
import { getAvatarSource, getFullNameUser, OrganizationRole, OrganizationUser, SsoOptionStatus } from '../../../models';
import { Order, ROWS_PER_PAGE, UserTable } from '../utils';
import ActiveUsersHeaderTable from './ActiveUsersHeaderTable';
import TransferOwnershipDialog from './TransferOwnershipDialog';
import { ReactComponent as WarningIcon } from './warning-icon.svg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    wordBreak: 'break-word',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  paginationBox: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    // there is no font match with design, use custom color here
    color: '#1D3066',
    paddingBottom: theme.spacing(2),
  },
  cell: {
    padding: theme.spacing(1, 0, 1, 2),
  },
  licenseAccessCell: {
    cursor: 'pointer',
    minWidth: theme.spacing(30),
  },
  email: {
    minWidth: theme.spacing(22),
    maxWidth: theme.spacing(22),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  joinDate: {
    minWidth: theme.spacing(18),
  },
  role: {
    minWidth: theme.spacing(15),
  },
  lastLogin: {
    minWidth: theme.spacing(22),
  },
  fullNameCell: {
    minWidth: theme.spacing(25),
    maxWidth: theme.spacing(25),
  },
  fullNameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  fullName: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  fullNameFlex: {
    flex: 1,
  },
  ssoChip: {
    marginLeft: theme.spacing(1),
    borderRadius: theme.spacing(1),
    height: theme.spacing(2),
    width: theme.spacing(5),
    backgroundColor: 'rgba(104, 109, 128, 0.08)',
  },
  ssoChipNew: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0, 0.5),
    borderRadius: theme.spacing(0.5),
    height: theme.spacing(3.375),
    width: theme.spacing(5.625),
    minWidth: theme.spacing(5.625),
    backgroundColor: 'rgba(104, 109, 128, 0.08)',
    color: '#233145',
    '& span': {
      fontSize: theme.spacing(1.75),
      fontWeight: 'normal',
      padding: 0,
    },
  },
  ssoChipEnabled: {
    backgroundColor: '#0f8461',
    color: '#fff',
  },
  sso: {
    maxWidth: theme.spacing(1),
  },
  configButton: {
    marginRight: theme.spacing(-1),
    maxWidth: theme.spacing(3),
    maxHeight: theme.spacing(3),
    '&:hover': {
      borderRadius: theme.spacing(0.5),
    },
    padding: 0,
  },
  table: {
    padding: theme.spacing(0, 2),
  },
  paddingNone: {
    padding: 0,
  },
  checkboxWrapper: {
    width: theme.spacing(1.75),
  },
  disabledHoverRow: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  filterContainer: {
    margin: theme.spacing(2),
  },
  search: {
    marginRight: theme.spacing(0.5),
    width: theme.spacing(35),
    '& > .MuiInputBase-root': {
      borderRadius: theme.spacing(0.5),
      maxHeight: theme.spacing(4),
      paddingLeft: theme.spacing(1),
    },
    '& > * > .MuiInputBase-inputSizeSmall': {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
    },
  },
  inputSearch: {
    fontSize: '0.875rem',
  },
  textButton: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(0, 0.5),
    minWidth: theme.spacing(5),
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    maxHeight: theme.spacing(4),
  },
  searchIcon: {
    fontSize: theme.spacing(2.1),
    color: '#284169',
  },
  noResultFound: {
    textAlign: 'center',
    margin: theme.spacing(2, 0),
  },
  menuPaper: {
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1.5),
    border: '1px solid #EEF1FA',
  },
  menuItem: {
    borderRadius: theme.spacing(0.5),
  },
  licenseAccessContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  warningWrapper: {
    marginRight: theme.spacing(0.25),
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    display: 'flex',
    alignItems: 'center',
  },
  warningWrapperEmpty: {
    marginRight: theme.spacing(1),
  },
  warningIcon: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
  },
  newLicenseAccessContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
  },
  licenseListContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  checkIcon: {
    minWidth: theme.spacing(2.5),
    minHeight: theme.spacing(2.5),
  },
  helpIcon: {
    minWidth: theme.spacing(2.5),
    minHeight: theme.spacing(2.5),
  },
  licenseBoxSpacing: {
    width: theme.spacing(0.75),
  },
  licenseBoxSpacingColumn: {
    height: theme.spacing(0.75),
  },
}));

// only accept primitives for sorting
interface ActiveUserFields {
  id: number;
  fullName: string;
  email: string;
  joinDate: Date;
  invitedBy: string;
  orgRole: OrganizationRole;
  licenseAccess: string;
  lastAccess: Date | undefined;
  avatar: string;
  isSelectable: boolean;
  hasSsoEnabled?: boolean;
  ssoStatus?: SsoOptionStatus,
  hasKseLicense: boolean;
}

interface ActiveUsersTableProps {
  onSelectUser: (user: UserTable) => void;
  onSelectAllUsers: (isCheckedAll: boolean, selectedList: UserTable[]) => void;
  selectedUsers: UserTable[];
  page: number;
  onChangePage: (_: React.ChangeEvent<unknown> | null, value: number) => void;
  dataUser: UserTable[];
  onChangeRoleClick: () => void;
  onRemoveUserClick: () => void;
  isSsoEnabled: boolean;
  onEditSsoOptionsButtonClick: (organizationUserId: number) => void;
  currentOrganizationUser: OrganizationUser | undefined;
  isShowWarning: boolean | undefined;
  isNewLicenseUI: boolean | undefined;
}

const showEditLoginOption = (
  isSsoEnabled: boolean,
  row: ActiveUserFields | undefined,
  currentOrganizationUser: OrganizationUser | undefined,
) => isSsoEnabled
  && (row?.isSelectable || currentOrganizationUser?.role === OrganizationRole.OWNER);
const showTransferOwnership = (
  currentOrganizationUser: OrganizationUser | undefined,
  row: ActiveUserFields | undefined,
) => (
  currentOrganizationUser?.role === OrganizationRole.OWNER
  && row?.orgRole !== OrganizationRole.OWNER
);

const ActiveUsersTable = (props: ActiveUsersTableProps) => {
  const { onSelectUser,
    onSelectAllUsers,
    selectedUsers,
    page,
    onChangePage,
    dataUser,
    onChangeRoleClick,
    onRemoveUserClick,
    isSsoEnabled,
    onEditSsoOptionsButtonClick,
    currentOrganizationUser,
    isShowWarning,
    isNewLicenseUI } = props;
  const classes = useStyles();
  const intl = useIntl();
  const { flags } = useLaunchDarkly();
  const [order, setOrder] = useState<Order>('desc');
  const [sortingField, setSortingField] = useState<keyof ActiveUserFields>('joinDate');
  const [currentSearchText, setCurrentSearchText] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState<ActiveUserFields>();
  const [transferOwnershipDialogOpen, setTransferOwnershipDialogOpen] = useState(false);
  const rows: ActiveUserFields[] = dataUser.map(user => {
    const { firstName, lastName, email, createdAt, invitedBy, orgRole, lastLogin, licenseAccess,
      id, avatar, isSelectable, hasSsoEnabled, ssoStatus, hasKseLicense } = user;
    const avatarSrc = getAvatarSource(avatar);
    return {
      id,
      // if firstName and lastName of user invitation are null => show prefix email
      fullName: getFullNameUser(firstName, lastName, email),
      email,
      joinDate: createdAt,
      invitedBy: invitedBy || '',
      orgRole,
      licenseAccess: licenseAccess.join(', ') || '',
      lastAccess: lastLogin,
      avatar: avatarSrc,
      isSelectable: isSelectable ?? false,
      hasSsoEnabled,
      ssoStatus,
      hasKseLicense: hasKseLicense ?? false,
    };
  });

  const handleOpenConfigMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    row: ActiveUserFields,
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditSsoOptionsButtonClick = () => {
    setAnchorEl(null);
    onEditSsoOptionsButtonClick(selectedRow!!.id);
  };

  const handleOpenTransferOwnershipDialog = () => {
    setAnchorEl(null);
    setTransferOwnershipDialogOpen(true);
  };

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    field: keyof ActiveUserFields,
  ) => {
    const isAsc = sortingField === field && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setSortingField(field);
  };
  const [matchedRows, setMatchedRows] = useState<ActiveUserFields[]>([]);
  const currentPageRows = matchedRows
    .slice((page - 1) * ROWS_PER_PAGE, ((page - 1) * ROWS_PER_PAGE) + ROWS_PER_PAGE);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentSearchText = e.target.value.toLowerCase().trim();
    setCurrentSearchText(currentSearchText);
    if (page !== 1) onChangePage(null, 1);
  };

  useEffect(() => {
    const searchResultRows = rows.filter(
      it => it.fullName.toLowerCase().includes(currentSearchText)
        || it.email.toLowerCase().includes(currentSearchText),
    );
    setMatchedRows(orderBy(sortingField, order, searchResultRows));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUser, currentSearchText, order, sortingField]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    // check if there's searching
    if (currentSearchText.length > 0) {
      const filterMatchedUsers = dataUser.filter(
        user => currentPageRows.some(it => it.email === user.email) && user.isSelectable,
      );
      onSelectAllUsers(event.target.checked, filterMatchedUsers);
    } else {
      onSelectAllUsers(
        event.target.checked,
        dataUser.filter(user => user.isSelectable
          && currentPageRows.some(it => it.email === user.email)),
      );
    }
  };

  const handleClick = (_event: React.MouseEvent<unknown>, email: string) => {
    const selectedUserIndex = dataUser.findIndex(it => it.email === email);
    onSelectUser(dataUser[selectedUserIndex]);
  };

  const isSelected = (email: string) => selectedUsers.findIndex(it => it.email === email) !== -1;
  const showConfigIcon = (row: ActiveUserFields) => (
    showEditLoginOption(isSsoEnabled, row, currentOrganizationUser)
    || showTransferOwnership(currentOrganizationUser, row)
  );

  const renderLicenseAccess = (row: ActiveUserFields) => {
    const { licenseAccess, hasKseLicense } = row;
    if (isNewLicenseUI) {
      return (
        <Box className={classes.newLicenseAccessContainer}>
          <Tooltip
            title={licenseAccess.length ? licenseAccess : ''}
            placement="top"
            disableInteractive
          >
            <Box className={classes.licenseListContainer}>
              <CheckCircleIcon className={classes.checkIcon} />
              <Box className={classes.licenseBoxSpacing} />
              {
                !licenseAccess.length ? <FormattedMessage id="user_management.cell.data.not.available" />
                  : (
                    <span>
                      {licenseAccess.split(', ')[0]}
                      {licenseAccess.split(', ').length > 1 && ',...'}
                    </span>
                  )
              }
            </Box>
          </Tooltip>
          { !hasKseLicense && (
            <>
              <Box className={classes.licenseBoxSpacingColumn} />
              <Tooltip
                title={intl.formatMessage({ id: 'user_management.cell.license_access.tooltip_no_kse' })}
                placement="top"
                disableInteractive
              >
                <Box className={classes.licenseListContainer}>
                  <QuestionCircleIcon className={classes.helpIcon} />
                  <Box className={classes.licenseBoxSpacing} />
                  { intl.formatMessage({ id: 'user_management.cell.license_access.no_kse' }) }
                </Box>
              </Tooltip>
            </>
          )}
        </Box>
      );
    }
    return (
      <Box className={classes.licenseAccessContainer}>
        {
          isShowWarning
          && !hasKseLicense
          && (
            <span className={clsx(classes.warningWrapper, {
              [classes.warningWrapperEmpty]: !licenseAccess.length,
            })}
            >
              <Tooltip
                title={intl.formatMessage({ id: 'user_management.cell.license_access.tooltip_violate_user' })}
                placement="top"
              >
                <WarningIcon className={classes.warningIcon} />
              </Tooltip>
            </span>
          )
        }
        {
          !licenseAccess.length ? <FormattedMessage id="user_management.cell.data.not.available" />
            : (
              <Tooltip title={licenseAccess} placement="top">
                <span>
                  {licenseAccess.split(', ')[0]}
                  {licenseAccess.split(', ').length > 1 && ',...'}
                </span>
              </Tooltip>
            )
        }
      </Box>
    );
  };

  const renderSsoTag = (row: ActiveUserFields) => {
    const userSsoActivated = row.ssoStatus === SsoOptionStatus.ACTIVE;
    return (
      <Tooltip
        title={intl.formatMessage({
          id: userSsoActivated
            ? 'user_management.cell.sso.enabled.tooltip'
            : 'user_management.cell.sso.not_enabled.tooltip',
        })}
        placement="top"
      >
        <Chip
          className={clsx(classes.ssoChipNew, {
            [classes.ssoChipEnabled]: userSsoActivated,
          })}
          size="small"
          label="SSO"
        />
      </Tooltip>
    );
  };

  return (
    <div className={classes.root}>
      <Grid container className={classes.filterContainer}>
        <TextField
          onChange={handleSearchChange}
          size="small"
          variant="standard"
          placeholder={intl.formatMessage({ id: 'user_management.search.placeholder' })}
          InputProps={{
            classes: { input: classes.inputSearch },
            disableUnderline: true,
            startAdornment: (<InputAdorment position="start"><SearchIcon className={classes.searchIcon} /></InputAdorment>),
          }}
          className={classes.search}
          autoComplete="off"
        />
        <Button onClick={onChangeRoleClick} disabled={selectedUsers.length === 0} className={classes.button} variant="contained">
          <FormattedMessage id="user_management.button.change_role" />
        </Button>
        <Button onClick={onRemoveUserClick} disabled={selectedUsers.length === 0} className={classes.button} variant="contained">
          <FormattedMessage id="user_management.button.remove_user" />
        </Button>
      </Grid>
      <TableContainer className={classes.table}>
        <Table>
          <ActiveUsersHeaderTable
            maxSelectable={currentPageRows.filter(row => row.isSelectable).length}
            numSelected={currentPageRows
              .filter(row => selectedUsers.some(it => it.email === row.email))
              .length}
            order={order}
            orderBy={sortingField}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={matchedRows.length}
          />
          <TableBody>
            {matchedRows.length > 0 ? currentPageRows
              .map(row => (
                <TableRow
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  className={classes.disabledHoverRow}
                >
                  <TableCell className={clsx(classes.checkboxWrapper)} padding="none">
                    {row.isSelectable ? (
                      <Checkbox
                        onClick={event => handleClick(event, row.email)}
                        classes={{
                          root: classes.paddingNone,
                        }}
                        icon={<UnCheckedIcon />}
                        checkedIcon={<CheckedIcon />}
                        checked={isSelected(row.email)}
                      />
                    ) : null}
                  </TableCell>
                  <TableCell className={clsx(classes.fullNameCell, classes.cell)} padding="none">
                    <div className={classes.fullNameContainer}>
                      <Avatar className={classes.avatar} src={row.avatar} />
                      <span className={clsx(classes.fullName, classes.fullNameFlex)}>
                        {row.fullName}
                      </span>
                      {isSsoEnabled && row.hasSsoEnabled && renderSsoTag(row)}
                    </div>
                  </TableCell>
                  <TableCell className={clsx(classes.email, classes.cell)} padding="none">
                    <Tooltip
                      title={row.email}
                      placement="top"
                    >
                      <span>
                        {row.email}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell className={clsx(classes.role, classes.cell)}>
                    {OrganizationRole.getRoleFullName(row.orgRole)}
                  </TableCell>
                  <TableCell className={clsx(classes.cell, classes.licenseAccessCell)}>
                    {
                      renderLicenseAccess(row)
                    }
                  </TableCell>
                  {flags?.invitedByEnabled && (
                    <TableCell className={clsx(classes.email, classes.cell)}>
                      <Tooltip
                        title={row.invitedBy}
                        placement="top"
                      >
                        <span>
                          {row.invitedBy}
                        </span>
                      </Tooltip>
                    </TableCell>
                  )}
                  <TableCell className={clsx(classes.joinDate, classes.cell)} padding="none">
                    {
                      row.joinDate ? (
                        <FormattedDate
                          value={new Date(row.joinDate)}
                          month="short"
                          day="numeric"
                          year="numeric"
                        />
                      ) : <FormattedMessage id="user_management.cell.data.not.available" />
                    }
                  </TableCell>
                  <TableCell className={clsx(classes.cell, classes.lastLogin)} padding="none">
                    <Box display="flex" justifyContent="space-between" flexGrow={1}>
                      {
                        row.lastAccess ? (
                          <FormattedDate
                            value={new Date(row.lastAccess)}
                            month="short"
                            day="numeric"
                            hour="numeric"
                            minute="numeric"
                            hourCycle="h23"
                            year="numeric"
                          />
                        ) : <FormattedMessage id="user_management.cell.data.not.available" />
                      }
                      {showConfigIcon(row)
                        && (
                        <IconButton
                          className={classes.configButton}
                          color="inherit"
                          size="small"
                          onClick={event => handleOpenConfigMenu(event, row)}
                          id="open-config-button"
                        >
                          <MoreVertIcon color="inherit" fontSize="small" />
                        </IconButton>
                        )}
                    </Box>
                  </TableCell>
                </TableRow>
              )) : (
                <TableRow>
                  <TableCell colSpan={7}>
                    <Typography className={classes.noResultFound} variant="subtitle2">
                      {dataUser.length > 0 ? (
                        <FormattedMessage id="user_management.no_result_found" />
                      ) : (
                        <FormattedMessage id="license_utilization.empty_data" />
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu
        id="login options"
        classes={{
          list: classes.paddingNone,
          paper: classes.menuPaper,
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={handleMenuClose}
      >
        {showEditLoginOption(isSsoEnabled, selectedRow, currentOrganizationUser)
          && (
          <MenuItem className={classes.menuItem} onClick={() => handleEditSsoOptionsButtonClick()}>
            <FormattedMessage id="user_management.config_menu.edit_login" />
          </MenuItem>
          )}
        {showTransferOwnership(currentOrganizationUser, selectedRow)
          && (
          <MenuItem
            className={classes.menuItem}
            onClick={() => handleOpenTransferOwnershipDialog()}
          >
            <FormattedMessage id="user_management.config_menu.transfer_owner_ship" />
          </MenuItem>
          )}
      </Menu>
      {selectedRow && (
      <TransferOwnershipDialog
        open={transferOwnershipDialogOpen}
        onClose={() => setTransferOwnershipDialogOpen(false)}
        organizationUserId={selectedRow.id}
      />
      )}
      {matchedRows.length > 0 && (
        <Box className={classes.paginationBox}>
          <Pagination
            shape="rounded"
            count={Math.ceil(matchedRows.length / ROWS_PER_PAGE)}
            page={page}
            onChange={onChangePage}
          />
          <Typography variant="body2">
            <FormattedMessage
              id={matchedRows.length > 1 ? 'license_utilization.session_detail.total_records'
                : 'license_utilization.session_detail.total_record'}
              values={{ count: matchedRows.length }}
            />
          </Typography>
        </Box>
      )}
    </div>
  );
};
export default ActiveUsersTable;
