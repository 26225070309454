import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import { exceptionOf, SerializedException } from '../models';
import { ActivateLicenseService } from '../services';
import { AppState } from './index';

export const ACTIVATE_LICENSE_FEATURE_KEY = 'activate_license';
interface LicenseEntity {
  machineId: string;
  license: string;
  activated: boolean;
}

const activateLicenseAdapter = createEntityAdapter<LicenseEntity>();

interface ActivateLicenseState extends EntityState<LicenseEntity> {
  loading: boolean;
  errors: SerializedException[];
  machineId: string;
  activated: boolean;
  license: string;
}

export const createInitialState = (): ActivateLicenseState => (
  activateLicenseAdapter.getInitialState({
    loading: false,
    errors: [] as SerializedException[],
    machineId: '',
    activated: false,
    license: '',
  })
);

export const doGetActivated = createAsyncThunk(
  'activateLicense/getActivated',
  async () => {
    const response = await ActivateLicenseService.getActivated();
    return { activated: response.data.activated };
  },
);

export const doGetMachineId = createAsyncThunk(
  'activateLicense/getMachineId',
  async () => {
    const response = await ActivateLicenseService.getMachineId();
    return { machineId: response.data.machineId };
  },
);

export const doActivateLicense = createAsyncThunk(
  'activateLicense/activateLicense',
  async (input: Required<Pick<LicenseEntity, 'license'>>, { rejectWithValue }) => {
    try {
      const license = await ActivateLicenseService.activateLicense(input);
      return { license };
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

const activateLicenseSlice = createSlice({
  name: ACTIVATE_LICENSE_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doGetMachineId.fulfilled, (state, action) => {
      state.loading = false;
      state.errors = [];
      state.machineId = action.payload.machineId;
    });
    builder.addCase(doGetMachineId.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetMachineId.rejected, (state, action) => {
      state.loading = true;
      state.errors.push(action.payload as SerializedException);
    });
    builder.addCase(doActivateLicense.fulfilled, state => {
      state.loading = false;
      state.errors = [];
    });
    builder.addCase(doActivateLicense.pending, state => {
      state.loading = true;
    });
    builder.addCase(doActivateLicense.rejected, (state, action) => {
      state.loading = true;
      state.errors.push(action.payload as SerializedException);
    });
    builder.addCase(doGetActivated.fulfilled, (state, action) => {
      state.loading = false;
      state.errors = [];
      state.activated = action.payload.activated;
    });
    builder.addCase(doGetActivated.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetActivated.rejected, (state, action) => {
      state.loading = true;
      state.errors.push(action.payload as SerializedException);
    });
  },
});

const selectActivateLicenseFeature = (state: AppState) => state[ACTIVATE_LICENSE_FEATURE_KEY];

export const selectMachineId = createSelector(
  selectActivateLicenseFeature,
  licenseState => licenseState.machineId,
);

export const selectActivated = createSelector(
  selectActivateLicenseFeature,
  licenseState => licenseState.activated,
);

export default activateLicenseSlice.reducer;
