import { IntervalUnit } from '../../models/query';

const set = (date: Date, unit: IntervalUnit, delta: number) => {
  switch (unit) {
    case IntervalUnit.DAYS:
      return date.setDate(date.getDate() + delta);
    case IntervalUnit.HOURS:
      return date.setHours(date.getHours() + delta);
    case IntervalUnit.MILLIS:
      return date.setMilliseconds(date.getMilliseconds() + delta);
    case IntervalUnit.MINUTES:
      return date.setMinutes(date.getMinutes() + delta);
    case IntervalUnit.MONTHS:
      return date.setMonth(date.getMonth() + delta);
    case IntervalUnit.SECONDS:
      return date.setSeconds(date.getSeconds() + delta);
    case IntervalUnit.WEEKS:
      return date.setDate(date.getDate() + delta * 7); // FIXME
    case IntervalUnit.YEARS:
      return date.setFullYear(date.getFullYear() + delta);
    default:
      return date;
  }
};

export const computeTimeIntervals = (
  startDate: Date,
  endDate: Date,
  interval: number,
  unit: IntervalUnit,
  addEndDateToResult: boolean = false,
): Date[] => {
  if (startDate.getTime() > endDate.getTime()) return [];
  const d = new Date(startDate);
  const ans: Date[] = [new Date(d)];
  while (d.getTime() < endDate.getTime()) {
    set(d, unit, interval);
    if (d.getTime() < endDate.getTime()) ans.push(new Date(d));
  }
  if (addEndDateToResult) ans.push(new Date(endDate));
  return ans;
};
