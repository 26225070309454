import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import uniq from 'lodash/fp/uniq';
import { useNavigate, useQuery } from '../../../../routes';
import OrderItem from './OrderItem';
import {
  fromOrder,
  fromStarterPackage,
  fromStudioSubscriptions,
  fromSubscriptions,
  fromTestCloudSubscriptions,
  fromVisualTestingSubscriptions,
  useAppDispatch,
} from '../../../../store';
import {
  fromBillingInformation,
  fromInvoices,
  fromPaymentMethod,
  fromTestOpsPlatformSubscriptions,
} from '../../../../store/rootReducer';
import { getCycleFromPathParam, getQuotaFromPathParam, MAX_TOTAL_AMOUNT, sendTrackingData } from '../../utils';
import { formatMessageRecurly } from '../utils';
import { accountSubscriptionManagement, testOpsPlatformPaymentStatus } from '../../../../layout/routes';
import {
  OrganizationFeature,
  PaymentMethodType,
  TestOpsPlanInterval,
} from '../../../../models';
import { PlanPrice } from '../../../../models/planPrice';
import LoadingProgress from '../../../../layout/LoadingProgress';
import PackageItem from './PackageItem';
import { ReactComponent as MemoIcon } from '../../../icons/icon_small_memo-circle-info.svg';
import { useLaunchDarkly } from '../../../../launchdarkly';

const useStyles = makeStyles(theme => ({
  orderItemsContainer: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  pricingSectionContainer: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  checkoutSectionContainer: {
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  textTitle: {
    fontSize: 20,
    fontWeight: 600,
  },
  memoContainer: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  memoTitle: {
    paddingTop: theme.spacing(2),
  },
  memoTitleText: {
    marginLeft: theme.spacing(1),
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.57,
    color: '#1847ca',
  },
  memoText: {
    marginTop: theme.spacing(0.5),
    fontSize: 12,
    fontWeight: 500,
    color: '#727993',
  },
  checkoutButton: {
    marginTop: theme.spacing(2),
    width: '100%',
    fontSize: 16,
  },
  containerPrice: {
    position: 'relative',
    width: '100%',
    height: 35,
  },
  titlePrice: {
    fontSize: 14,
    fontWeight: 500,
    color: '#727993',
    position: 'absolute',
    top: 0,
    bottom: 0,
  },
  valuePrice: {
    fontSize: 14,
    fontWeight: 600,
    color: '#4d5369',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  discountPrice: {
    fontSize: 14,
    fontWeight: 600,
    color: '#008060',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  containerTotalPrice: {
    position: 'relative',
    width: '100%',
    height: 50,
  },
  titleTotalPrice: {
    fontSize: 14,
    fontWeight: 500,
    color: '#727993',
    position: 'absolute',
    top: 1,
    bottom: 0,
  },
  totalPrice: {
    fontSize: 20,
    fontWeight: 700,
    color: '#276ef1',
    position: 'absolute',
    top: 0,
    right: 0,
    marginTop: 10,
  },
  seperateLine: {
    height: 1,
    backgroundColor: '#eceef5',
    width: '100%',
    marginTop: -1,
  },
}));

interface OrderConfirmationSectionProps {
  accountId: number;
}

const OrderConfirmationSection = (props: OrderConfirmationSectionProps) => {
  const {
    accountId,
  } = props;

  const classes = useStyles();
  const { get } = useQuery();
  const { flags } = useLaunchDarkly();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const previews = useSelector(fromInvoices.selectPreviews);
  const previewsTotal = useSelector(fromInvoices.selectPreviewsTotal);
  const previewsNetTotal = useSelector(fromInvoices.selectPreviewsSubTotal);
  const previewsTotalTax = useSelector(fromInvoices.selectPreviewsTotalTax);
  const previewsDiscountTotal = useSelector(fromInvoices.selectDiscountTotal);
  const { packagePreview } = useSelector(fromInvoices.selectStarterPackageInvoicePreview);
  const loading = useSelector(fromInvoices.selectLoading);
  const [isLoading, setLoading] = useState(false);
  const orderResponse = useSelector(fromOrder.selectOrderResponse);
  const checkoutError = useSelector(fromOrder.selectCheckoutError);
  const errors = useSelector(fromSubscriptions.selectErrors());
  const isApplyStarterPackage = useSelector(fromStarterPackage.selectIsApplyStarterPackage);
  const currentBillingInformation = useSelector(fromBillingInformation.selectOneByAccountId(
    Number(accountId),
  ));
  const currentCard = useSelector(fromPaymentMethod.selectOneByAccountId(Number(accountId)));
  const selectedPaymentMethod = useSelector(fromPaymentMethod.selectSelectedPaymentMethod);
  const [checkoutStatus, setCheckoutStatus] = useState<'loading' | 'finished' | undefined>(undefined);
  const [orderConfirmationKey, setOrderConfirmationKey] = useState(1);
  const [discountNumber, setDiscountNumber] = useState(previewsDiscountTotal);
  const { replace, replaceQuery } = useNavigate();
  const isExceededMaxAmount = () => previewsTotal > MAX_TOTAL_AMOUNT;
  const successEmail = <a target="_blank" href="mailto: success@katalon.com" rel="noreferrer">success@katalon.com</a>;
  const starterPackage = get('starterPackage') ?? undefined;

  // quota get from path param
  const platformQuota = getQuotaFromPathParam(
    OrganizationFeature.TESTOPS_PLATFORM,
  );
  const kseQuota = getQuotaFromPathParam(
    OrganizationFeature.PER_USER_KSE,
  );
  const kreQuota = getQuotaFromPathParam(
    OrganizationFeature.UNLIMITED_ENGINE,
  );
  const testCloudQuota = getQuotaFromPathParam(
    OrganizationFeature.TESTCLOUD_SESSION_WEB,
  );
  const testCloudDesktopQuota = getQuotaFromPathParam(
    OrganizationFeature.TESTCLOUD_SESSION_WEB_DESKTOP,
  );
  const testCloudCrossBrowseQuota = getQuotaFromPathParam(
    OrganizationFeature.TESTCLOUD_SESSION_CROSS_BROWSER,
  );
  const testCloudMobileQuota = getQuotaFromPathParam(
    OrganizationFeature.TESTCLOUD_SESSION_MOBILE_APP,
  );
  const visualTestingQuota = getQuotaFromPathParam(
    OrganizationFeature.TESTOPS_PLATFORM,
  );

  // cycle get from path param
  const platformCycle = getCycleFromPathParam(
    OrganizationFeature.TESTOPS_PLATFORM,
  );
  const kseCycle = getCycleFromPathParam(
    OrganizationFeature.PER_USER_KSE,
  );
  const kreCycle = getCycleFromPathParam(
    OrganizationFeature.UNLIMITED_ENGINE,
  );
  const testCloudCycle = getCycleFromPathParam(
    OrganizationFeature.TESTCLOUD_SESSION_WEB,
  );
  const testCloudDesktopCycle = getCycleFromPathParam(
    OrganizationFeature.TESTCLOUD_SESSION_WEB_DESKTOP,
  );
  const testCloudCrossBrowseCycle = getCycleFromPathParam(
    OrganizationFeature.TESTCLOUD_SESSION_CROSS_BROWSER,
  );
  const testCloudMobileCycle = getCycleFromPathParam(
    OrganizationFeature.TESTCLOUD_SESSION_MOBILE_APP,
  );
  const visualTestingCycle = getCycleFromPathParam(
    OrganizationFeature.VISUAL_TESTING_PRO,
  );

  const orders = useSelector(fromInvoices.selectOrders);

  const hasMonthlyItem = visualTestingCycle === TestOpsPlanInterval.MONTH
    || kseCycle === TestOpsPlanInterval.MONTH || kreCycle === TestOpsPlanInterval.MONTH
    || platformCycle === TestOpsPlanInterval.MONTH
    || (!flags?.newTestCloudSKUsEnabled
      ? testCloudCycle === TestOpsPlanInterval.MONTH
      : testCloudDesktopCycle === TestOpsPlanInterval.MONTH
      || testCloudCrossBrowseCycle === TestOpsPlanInterval.MONTH
      || testCloudMobileCycle === TestOpsPlanInterval.MONTH);

  const currentTestCloudSubscription = useSelector(
    fromTestCloudSubscriptions.selectActiveByAccountIdAndFeature(
      Number(accountId),
      OrganizationFeature.TESTCLOUD_SESSION_WEB,
    ),
  )?.[0];

  const currentTestCloudDesktopSubscription = useSelector(
    fromTestCloudSubscriptions.selectActiveByAccountIdAndFeature(
      Number(accountId),
      OrganizationFeature.TESTCLOUD_SESSION_WEB_DESKTOP,
    ),
  )?.[0];

  const currentTestCloudCrossBrowserSubscription = useSelector(
    fromTestCloudSubscriptions.selectActiveByAccountIdAndFeature(
      Number(accountId),
      OrganizationFeature.TESTCLOUD_SESSION_CROSS_BROWSER,
    ),
  )?.[0];

  const currentTestCloudMobileSubscription = useSelector(
    fromTestCloudSubscriptions.selectActiveByAccountIdAndFeature(
      Number(accountId),
      OrganizationFeature.TESTCLOUD_SESSION_MOBILE_APP,
    ),
  )?.[0];

  const currentVisualTestingSubscription = useSelector(
    fromVisualTestingSubscriptions.selectPaidSubscriptionByAccountId(
      Number(accountId),
    ),
  )?.[0];

  const currentKSESubscription = useSelector(
    fromStudioSubscriptions.selectPaidSubscriptionByAccountId(
      Number(accountId),
      OrganizationFeature.PER_USER_KSE,
    ),
  )?.[0];

  const currentKRESubscription = useSelector(
    fromStudioSubscriptions.selectPaidSubscriptionByAccountId(
      Number(accountId),
      OrganizationFeature.UNLIMITED_ENGINE,
    ),
  )?.[0];

  const testopsPlatformSubscription = useSelector(
    fromTestOpsPlatformSubscriptions.selectPaidTestOpsPlatformSubscriptionByAccountId(
      Number(accountId),
    ),
  )?.[0];

  useEffect(() => {
    if (loading) return;

    if (isExceededMaxAmount()) {
      enqueueSnackbar(
        intl.formatMessage(
          { id: 'payment_status.total.exceeded.limit' },
          { email: successEmail },
        ),
        { variant: 'error' },
      );
    }

    if (isApplyStarterPackage && packagePreview !== undefined) {
      setDiscountNumber(previewsDiscountTotal + (packagePreview?.discountAmount ?? 0));
    }
  }, [previewsTotal, packagePreview, isApplyStarterPackage]);

  useEffect(() => {
    const redirectToPaymentStatusPage = () => {
      const invoiceNumbers = orderResponse?.singleProductInvoices;
      const packageInvoiceNumbers = orderResponse?.starterPackageInvoices;
      if (!invoiceNumbers?.length && !packageInvoiceNumbers?.length) {
        enqueueSnackbar(
          errors?.[0]?.message
            ? formatMessageRecurly(errors[0].message)
            : intl.formatMessage({ id: 'error.unknown' }),
          { variant: 'error' },
        );
        return;
      }
      replace(testOpsPlatformPaymentStatus.path, replaceQuery({
        accountId,
        invoiceNumber: uniq(invoiceNumbers),
        ...(packagePreview?.name) && {
          starterPackageName: packagePreview?.name,
          starterPackageInvoice: uniq(packageInvoiceNumbers),
        },
      }));
    };

    const redirectToSubscriptionManagementPage = () => {
      replace(accountSubscriptionManagement.path, replaceQuery({
        accountId,
        showInstructionPopup: true,
      }));
    };

    if (checkoutStatus === 'finished') {
      if (selectedPaymentMethod === PaymentMethodType.CREDIT_CARD) {
        redirectToPaymentStatusPage();
      }

      if (selectedPaymentMethod === PaymentMethodType.BANK_TRANSFER) {
        redirectToSubscriptionManagementPage();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutStatus]);

  useEffect(() => {
    // Reload this section when billing information is updated
    setOrderConfirmationKey(window.crypto.getRandomValues(new Uint8Array(256))[0]);
  }, [currentBillingInformation]);

  const sendCheckoutClickTracking = (error?: string) => {
    sendTrackingData(
      'checkout_page_all_action',
      accountId,
      undefined,
      {
        field: 'order_confirmation',
        object: 'button',
        action: 'checkout',
        is_required: false,
        is_valid: !error,
        error,
      },
    );
  };

  useEffect(() => {
    if (checkoutStatus !== 'finished') {
      // Action doCheckout - not dispatch yet
      return;
    }
    const hasCheckoutResult = !!checkoutError || !!orderResponse;
    if (!hasCheckoutResult) {
      return;
    }

    const isSuccessCheckout = !!orderResponse;
    if (isSuccessCheckout) {
      sendCheckoutClickTracking();
    } else {
      sendCheckoutClickTracking(checkoutError?.message);
    }
  }, [checkoutError, orderResponse, checkoutStatus]);

  const handleCheckout = () => {
    const checkout = async () => {
      setLoading(true);
      try {
        setCheckoutStatus('loading');
        await dispatch(fromOrder.doCheckout(
          {
            accountId,
            ...(starterPackage !== undefined) && {
              starterPackageOrder: {
                accountId,
                packageName: starterPackage,
              },
            },
            listSingleProductOrder: orders,
            paymentMethod: selectedPaymentMethod,
          },
        ));
        setCheckoutStatus('finished');
      } finally {
        setLoading(false);
      }
    };
    checkout().catch();
  };

  const renderViewPrice = (textId: string, value: ReactElement) => (
    <div className={classes.containerPrice}>
      <p className={classes.titlePrice}>
        <FormattedMessage id={textId} />
      </p>
      {
        loading
          ? <CircularProgress className={classes.totalPrice} size={20} />
          : value
      }
    </div>
  );

  const renderOrderItems = () => (
    <div id="order-confirmation" key={orderConfirmationKey} className={classes.orderItemsContainer}>
      <Typography className={classes.textTitle}>
        <FormattedMessage id="checkout.orderconfirmation" />
      </Typography>
      {starterPackage && (
        <PackageItem
          accountId={accountId}
          packageName={starterPackage}
        />
      )}
      {flags?.testOpsUIEnabled && platformCycle && (
        <OrderItem
          currentSubscription={testopsPlatformSubscription}
          accountId={accountId}
          chosenInterval={platformCycle}
          organizationFeature={OrganizationFeature.TESTOPS_PLATFORM}
          chosenQuota={platformQuota}
          unitPrice={platformCycle === 'YEAR' ? 399 : 41}
          planName={`subscriptions.testops_platform.testops_platform.${platformQuota}`}
          packageName="dashboard.test_execution_chart"
        />
      )}
      {kseCycle && (
        <OrderItem
          currentSubscription={currentKSESubscription}
          accountId={accountId}
          chosenInterval={kseCycle}
          organizationFeature={OrganizationFeature.PER_USER_KSE}
          chosenQuota={kseQuota}
          unitPrice={kseCycle === 'YEAR'
            ? flags?.subscriptionPlanPrice?.kseAnnually ?? PlanPrice.KSE_ANNUALLY
            : flags?.subscriptionPlanPrice?.kseMonthly ?? PlanPrice.KSE_MONTHLY}
          planName="subscriptions.kse.title"
          packageName="subscriptions.testops_platform.no_licenses"
        />
      )}
      {kreCycle && (
        <OrderItem
          currentSubscription={currentKRESubscription}
          accountId={accountId}
          chosenInterval={kreCycle}
          organizationFeature={OrganizationFeature.UNLIMITED_ENGINE}
          chosenQuota={kreQuota}
          unitPrice={kreCycle === 'YEAR'
            ? flags?.subscriptionPlanPrice?.kreAnnually ?? PlanPrice.KRE_ANNUALLY
            : flags?.subscriptionPlanPrice?.kreMonthly ?? PlanPrice.KRE_MONTHLY}
          planName="subscriptions.kre.title"
          packageName="subscriptions.testops_platform.no_licenses"
        />
      )}
      {!flags?.newTestCloudSKUsEnabled && testCloudCycle && (
        <OrderItem
          currentSubscription={currentTestCloudSubscription}
          accountId={accountId}
          chosenInterval={testCloudCycle}
          organizationFeature={OrganizationFeature.TESTCLOUD_SESSION_WEB}
          chosenQuota={testCloudQuota}
          unitPrice={testCloudCycle === 'YEAR'
            ? flags?.subscriptionPlanPrice?.testCloudAnnually ?? PlanPrice.TESTCLOUD_ANNUALLY
            : flags?.subscriptionPlanPrice?.testCloudMonthly ?? PlanPrice.TESTCLOUD_MONTHLY}
          planName="subscriptions.test_cloud.title"
          packageName="subscriptions.testops_platform.no_sessions"
        />
      )}
      {flags?.newTestCloudSKUsEnabled && testCloudDesktopCycle && (
        <OrderItem
          currentSubscription={currentTestCloudDesktopSubscription}
          accountId={accountId}
          chosenInterval={testCloudDesktopCycle}
          organizationFeature={OrganizationFeature.TESTCLOUD_SESSION_WEB_DESKTOP}
          chosenQuota={testCloudDesktopQuota}
          unitPrice={
            testCloudDesktopCycle === 'YEAR'
              ? flags?.subscriptionPlanPrice?.testCloudDesktopAnnually
              ?? PlanPrice.TESTCLOUD_DESKTOP_ANNUALLY
              : flags?.subscriptionPlanPrice?.testCloudDesktopMonthly
              ?? PlanPrice.TESTCLOUD_DESKTOP_MONTHLY
          }
          planName="subscriptions.test_cloud_desktop.title"
          packageName="subscriptions.testops_platform.no_sessions"
        />
      )}
      {flags?.newTestCloudSKUsEnabled && testCloudCrossBrowseCycle && (
        <OrderItem
          currentSubscription={currentTestCloudCrossBrowserSubscription}
          accountId={accountId}
          chosenInterval={testCloudCrossBrowseCycle}
          organizationFeature={OrganizationFeature.TESTCLOUD_SESSION_CROSS_BROWSER}
          chosenQuota={testCloudCrossBrowseQuota}
          unitPrice={
            testCloudCrossBrowseCycle === 'YEAR'
              ? flags?.subscriptionPlanPrice?.testCloudCrossBrowserAnnually
              ?? PlanPrice.TESTCLOUD_CROSS_BROWSER_ANNUALLY
              : flags?.subscriptionPlanPrice?.testCloudCrossBrowserMonthly
              ?? PlanPrice.TESTCLOUD_CROSS_BROWSER_MONTHLY
          }
          planName="subscriptions.test_cloud_cross_browser.title"
          packageName="subscriptions.testops_platform.no_sessions"
        />
      )}
      {flags?.newTestCloudSKUsEnabled && testCloudMobileCycle && (
        <OrderItem
          currentSubscription={currentTestCloudMobileSubscription}
          accountId={accountId}
          chosenInterval={testCloudMobileCycle}
          organizationFeature={OrganizationFeature.TESTCLOUD_SESSION_MOBILE_APP}
          chosenQuota={testCloudMobileQuota}
          unitPrice={
            testCloudMobileCycle === 'YEAR'
              ? flags?.subscriptionPlanPrice?.testCloudMobileAnnually
              ?? PlanPrice.TESTCLOUD_MOBILE_ANNUALLY
              : flags?.subscriptionPlanPrice?.testCloudMobileMonthly
              ?? PlanPrice.TESTCLOUD_MOBILE_MONTHLY
          }
          planName="subscriptions.test_cloud_mobile.title"
          packageName="subscriptions.testops_platform.no_sessions"
        />
      )}
      {visualTestingCycle && (
        <OrderItem
          currentSubscription={currentVisualTestingSubscription}
          accountId={accountId}
          chosenInterval={visualTestingCycle}
          organizationFeature={OrganizationFeature.VISUAL_TESTING_PRO}
          chosenQuota={visualTestingQuota}
          unitPrice={
            visualTestingCycle === 'YEAR'
              ? flags?.subscriptionPlanPrice?.visualTestingProAnnually
              ?? PlanPrice.VISUAL_TESTING_PRO_ANNUALLY
              : flags?.subscriptionPlanPrice?.visualTestingProMonthly
              ?? PlanPrice.VISUAL_TESTING_PRO_MONTHLY
          }
          planName="subscriptions.visual_testing.title"
          packageName="subscriptions.testops_platform.no_checkpoint_images"
        />
      )}
    </div>
  );

  const renderMemo = () => (
    <>
      <div className={classes.seperateLine} />
      <div id="memo" className={classes.memoContainer}>
        <Typography className={classes.memoTitle}>
          <MemoIcon />
          <span className={classes.memoTitleText}>
            <FormattedMessage id="checkout.memo" />
          </span>
        </Typography>
        <p className={classes.memoText}>
          <FormattedMessage id="checkout.memo.first" />
        </p>
        <p className={classes.memoText}>
          <FormattedMessage id="checkout.memo.second" />
        </p>
      </div>
    </>
  );

  const renderPricingSection = () => (
    <>
      <div className={classes.seperateLine} />

      <div id="pricing-section" className={classes.pricingSectionContainer}>
        {renderViewPrice(
          'checkout.total_net_price',
          (
            <p className={classes.valuePrice}>
              $
              {intl.formatNumber(previewsNetTotal || 0)}
            </p>
          ),
        )}

        {renderViewPrice(
          'checkout.discount',
          (
            <p className={classes.discountPrice}>
              - $
              {intl.formatNumber(discountNumber || 0)}
            </p>
          ),
        )}

        {renderViewPrice(
          'subscriptions.testops_platform.tax',
          (
            <p className={classes.valuePrice}>
              $
              {intl.formatNumber(previewsTotalTax || 0)}
            </p>
          ),
        )}

        <div className={classes.containerTotalPrice}>
          <p className={classes.titleTotalPrice}>
            <FormattedMessage id="license_utilization.total" />
          </p>
          {
            loading
              ? <CircularProgress className={classes.totalPrice} size={20} />
              : (
                <p className={classes.totalPrice}>
                  $
                  {intl.formatNumber(previewsTotal || 0)}
                </p>
              )
          }
        </div>
      </div>
    </>
  );

  const renderCheckoutButton = () => (
    <div className={classes.checkoutSectionContainer}>
      <Button
        id="checkout-btn"
        variant="contained"
        color="primary"
        onClick={handleCheckout}
        disabled={
          (previews.length === 0 && packagePreview === undefined)
          || isExceededMaxAmount()
          || !currentBillingInformation
          || (selectedPaymentMethod === PaymentMethodType.CREDIT_CARD && !currentCard)
          || (selectedPaymentMethod === PaymentMethodType.BANK_TRANSFER && hasMonthlyItem)
        }
        className={classes.checkoutButton}
      >
        {
            selectedPaymentMethod === PaymentMethodType.CREDIT_CARD
              ? intl.formatMessage({ id: 'billinginfo.checkout' })
              : intl.formatMessage({ id: 'bank.transfer.place_order' })
          }
      </Button>
    </div>
  );

  return (
    <div id="purchase-details-section">
      {renderOrderItems()}
      {renderPricingSection()}
      {selectedPaymentMethod === PaymentMethodType.BANK_TRANSFER
        && renderMemo()}
      {renderCheckoutButton()}
      {isLoading && <LoadingProgress />}
    </div>
  );
};

export default OrderConfirmationSection;
