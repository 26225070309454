import Button from '@mui/material/Button';
import { useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { resolvePath, useQuery } from '../../../routes';
import { accountSubscriptionManagement } from '../../../layout/routes';

const ButtonToSubscriptionManagement = () => {
  const intl = useIntl();
  const { get } = useQuery();
  const accountId = get('accountId');

  return (
    <Button
      id="goto-sub-management-page"
      variant="contained"
      size="large"
      sx={{
        backgroundColor: '#e7e9ef',
        color: '#233145',
        pl: 7.5,
        pr: 7.5,
        mr: 1,
      }}
      component={RouterLink}
      to={resolvePath(accountSubscriptionManagement, undefined, {
        accountId: Number(accountId),
      }, false)}
    >
      {intl.formatMessage({ id: 'payment_status.button.back_sub_management' })}
    </Button>
  );
};

export default ButtonToSubscriptionManagement;
