import Axios from 'axios';
import { PageableResponse, UserSsoInvitation, DataResponse } from '../models';
import { composeQuery, Query } from '../models/query';

export const getUserSsoInvitations = async (...criteria: Query<UserSsoInvitation>[]) => (await Axios.get<PageableResponse<UserSsoInvitation>>('/v1/user-sso-invitations', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: composeQuery<UserSsoInvitation>(...criteria),
  data: {},
})).data;

// get user invitation from k1
export const getUserSsoInvitationByToken = async (...criteria: Query<UserSsoInvitation>[]) => (await Axios.get<PageableResponse<UserSsoInvitation>>('/v1/user-sso-invitations', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: composeQuery<UserSsoInvitation>(...criteria),
  data: {},
})).data;

// delete user invitation from k1
export const deleteUserSsoInvitation = async (input: Required<Pick<UserSsoInvitation, 'id'>>) => (await Axios.delete(`/v1/user-sso-invitations/${input.id}`)).data.data;

// update invitation k1
export const updateUserSsoInvitation = async (input: Required<Pick<UserSsoInvitation, 'id' | 'status' | 'invitationToken'>>) => (await (Axios.put<DataResponse<UserSsoInvitation>>(`/v1/user-sso-invitations/${input.id}`, input))).data;
