const AppContext: MicroAppProps = {
  config: {},
  ldFlags: {},
};

export const mountMicroApp = async (id: string, appContext = AppContext, node?: Element | null) => {
  await window.microapps[id]?.mountApp({
    node,
    props: appContext,
  });
};

export const mountMicroComponent = async (
  id: string,
  componentId: keyof MicroAppComponents,
  props: {},
  appContext = AppContext,
) => {
  await window.microapps[id].mountComponent(componentId, {
    ...appContext,
    componentProps: { ...props },
  });
};

const removeDuplicatedSrc = (url: string) => {
  const scripts = document.getElementsByTagName('script');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < scripts.length; i++) {
    if (scripts[i].src === url) {
      scripts[i].remove();
    }
  }
};

const loadScripts = (id: string, indexString: string, mountCallBack: () => Promise<void>) => {
  const doc = new DOMParser().parseFromString(indexString, 'text/html');
  const scripts = doc.getElementsByTagName('script');
  const newScripts: any[] = [];
  Array.from(scripts).forEach(script => {
    let src = script.getAttribute('src');
    if (!src) return;
    src = `${src}?id=${id}`;
    if (newScripts.find(s => s.src === src)) return;
    const newScript = document.createElement('script');
    newScript.setAttribute('type', 'text/javascript');
    newScript.src = src;
    newScripts.push(newScript);
  });
  const loadNextScript = async () => {
    const script = newScripts.shift();
    if (script) {
      let loaded = false;
      removeDuplicatedSrc(script.src);
      document.head.appendChild(script);
      const onScriptStateChange = () => {
        const rs = script.readyState;
        if (rs && rs !== 'complete' && rs !== 'loaded') return;
        if (loaded) return;
        loaded = true;
        if (scripts.length >= 0) {
          loadNextScript();
        }
      };
      script.onload = onScriptStateChange;
      script.onreadystatechange = onScriptStateChange;
    } else {
      // eslint-disable-next-line no-lonely-if
      if (window.microapps) {
        await mountCallBack();
      }
    }
  };
  loadNextScript();
};

export const getIndexFile = (id: string, path: string, mountCallBack: () => Promise<void>) => {
  const xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = () => {
    if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
      loadScripts(id, xmlHttp.responseText, mountCallBack);
    }
  };
  const unCachedPath = `${path}/index.html?t=${new Date().getTime()}`;
  xmlHttp.open('GET', unCachedPath, true);
  xmlHttp.send();
};
