import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import Avatar from 'react-avatar';
import { ReactNode, useState } from 'react';
import { useSnackbar } from 'notistack';
import { AccountIcon } from '../../layout/CustomIcon';
import { UserInvitation, OrganizationFeature } from '../../models';
import { resolvePath, sso as ssoPath, useNavigate } from '../../routes';
import {
  fromOrganizations,
  fromUserInvitation,
  fromTestOpsPlatformSubscriptions,
  fromSubscriptions,
  fromOrganizationFeatureFlag,
  useAppDispatch,
} from '../../store';
import {
  ERROR_USER_INVITATION_INVALID_LINK,
  ERROR_USER_INVITATION_LIMITED_USER,
} from '../../store/acceptInvitationPageSlice';
import { useConfig } from '../../config';

const useStyles = makeStyles(theme => ({
  item: {
    width: '100%',
    margin: theme.spacing(2),
  },
  itemDetail: {
    color: '#233145',
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
  },
  logoBox: {
    paddingRight: theme.spacing(2),
  },
  logo: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    borderRadius: '50%',
  },
  accountAndOrgInfo: {
    minWidth: theme.spacing(49.15),
    display: 'flex',
    flexDirection: 'column',
    maxWidth: theme.spacing(100),
  },
  organizationInfo: {
    width: '100%',
    height: '50%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  accountInfo: {
    '& > *': {
      fontSize: '0.875rem',
    },
    '& > *:first-child': {
      paddingRight: theme.spacing(1),
    },
    width: '100%',
    height: '50%',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  accountName: {
    maxWidth: theme.spacing(37.5),
    '& > *:first-child': {
      marginRight: theme.spacing(1),
    },
  },
  action: {
    display: 'flex',
    width: '100%',
    marginLeft: theme.spacing(4.5),
    paddingRight: theme.spacing(4.5),
    justifyContent: 'end',
    '& > *:last-child': {
      marginLeft: theme.spacing(1.5),
    },
  },
  button: {
    fontSize: '1rem',
    fontWeight: theme.typography.fontWeightMedium,
    width: theme.spacing(10),
  },
  account: {
    display: '-webkit-box',
    maxHeight: theme.spacing(6),
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
  },
}));

interface PendingInvitationItemProps {
  invitation: UserInvitation;
}
const PendingInvitationItem = (props: PendingInvitationItemProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { navigate, replaceQuery } = useNavigate();
  const { config } = useConfig();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { invitation } = props;
  const {
    organizationName,
    organizationId,
    invitationToken,
    inviterUserName,
    inviterUserEmail,
    accountName,
    accountId,
    organizationLogo,
  } = invitation;

  const activePlatformSubscription = useSelector(fromTestOpsPlatformSubscriptions
    .selectPaidTestOpsPlatformSubscriptionByAccountId(
      accountId,
    )).filter(sub => new Date(sub.expiryDate).getTime() > Date.now())?.[0];

  const hasTOEnterprise = useSelector(fromSubscriptions
    .selectHasTOEnterpriseByOrganizationId(Number(organizationId) || 0));

  const isSsoFlagEnabled = useSelector(fromOrganizationFeatureFlag.selectIsOrganizationSsoEnabled(
    Number(organizationId),
  )) || false;

  const hasEnableSSOAndCustomDomain = (
    activePlatformSubscription?.feature === OrganizationFeature.TESTOPS_PLATFORM
    && activePlatformSubscription.quota >= 30000)
    || hasTOEnterprise
    || isSsoFlagEnabled;

  const getSubdomainLink = (domain: string | undefined) => `https://${domain}.${process.env.REACT_APP_CUSTOM_DOMAIN_POSTFIX}`;

  const handleAcceptInvitation = async () => {
    setLoading(true);
    const invitationRequest = {
      id: invitation?.id ?? 0,
      accepted: true,
      invitationToken,
    };
    try {
      await dispatch(fromUserInvitation.doAcceptInvitation(invitationRequest)).then(
        unwrapResult,
      );
      // Temporary navigate to organization home
      setTimeout(() => {
        // Fetch organization before navigating to prevent redirecting to forbidden page
        // on Layout Component (probably needs to figure out a better solution to fix this later)
        dispatch(fromOrganizations.doFetchOrganizationById({
          id: Number(invitation?.organizationId),
        })).then(unwrapResult)
          .then(data => {
            const currentOrg = data.organizations.filter(item => item.id === organizationId)[0];
            dispatch(fromOrganizations.doChangeSelectedId(organizationId));
            const urlHome = currentOrg?.domain
              ? resolvePath(ssoPath, undefined, { redirect_uri: `${getSubdomainLink(currentOrg?.domain)}/organization/${invitation.organizationId}/home` })
              : resolvePath(ssoPath, undefined, { redirect_uri: `${config?.testOpsPublicUrl}/organization/${invitation.organizationId}/home` });
            // replace old history state, avoid caching old invitation:
            window.history.replaceState(null, '', urlHome);
            navigate(ssoPath.path, currentOrg?.domain && hasEnableSSOAndCustomDomain
              ? replaceQuery({ redirect_uri: `${getSubdomainLink(currentOrg?.domain)}/organization/${organizationId}/home` })
              : replaceQuery({ redirect_uri: `${config?.testOpsPublicUrl}/organization/${organizationId}/home` }));
          });
      }, 1000);
      // eslint-disable-next-line no-empty
    } catch (e: any) {
      if (e.message === ERROR_USER_INVITATION_INVALID_LINK) {
        enqueueSnackbar(
          intl.formatMessage({ id: ERROR_USER_INVITATION_INVALID_LINK }),
          { variant: 'error' },
        );
      } else if (e.message === ERROR_USER_INVITATION_LIMITED_USER) {
        enqueueSnackbar(
          intl.formatMessage({ id: ERROR_USER_INVITATION_LIMITED_USER }),
          { variant: 'error' },
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDeclineInvitation = async () => {
    setLoading(true);
    const invitationRequest = {
      id: invitation?.id ?? 0,
      type: 'K1',
    };

    await dispatch(fromUserInvitation.doDeclineInvitation(invitationRequest));
    setLoading(false);
  };
  return (
    <Box className={classes.item}>
      <Typography color="#808B9A">
        {intl.formatMessage({ id: 'welcome.pending.invite_by' }, {
          userName: inviterUserName,
          email: inviterUserEmail,
          p: (data: ReactNode) => <span style={{ fontWeight: 600 }}>{data}</span>,
        })}
      </Typography>
      <Box className={classes.itemDetail}>
        <Box className={classes.logoBox}>
          {organizationLogo?.length ? (
            <img
              className={classes.logo}
              alt={organizationName}
              title={organizationName}
              src={`/${organizationLogo}`}
            />
          ) : (
            <Avatar
              size="48"
              round
              name={organizationName}
              maxInitials={2}
            />
          )}
        </Box>
        <Box className={classes.accountAndOrgInfo}>
          <Box className={classes.organizationInfo}>
            <Typography fontWeight={700} maxWidth={300}>
              {organizationName}
            </Typography>
            <Typography minWidth={40}>
              <FormattedMessage id="welcome.pending.id" />
              <span style={{ fontWeight: 700 }}>
                {organizationId}
              </span>
            </Typography>
          </Box>
          <Box className={classes.accountInfo}>
            <Box
              className={classes.accountName}
              display="flex"
              flexDirection="row"
              alignContent="center"
              alignItems="center"
            >
              <Box width={15} display="flex" alignItems="center">
                <AccountIcon />
              </Box>
              <Typography className={classes.account}>
                <FormattedMessage id="welcome.pending.account" />
                <span style={{ fontWeight: 500 }}>
                  {`${accountName || organizationName}`}
                </span>
              </Typography>
            </Box>
            <Typography fontWeight={500} marginTop={0.25}>
              <FormattedMessage id="welcome.pending.id" />
              <span>
                {`${accountId || organizationId}`}
              </span>
            </Typography>
          </Box>
        </Box>
        <Box className={classes.action}>
          <Button
            onClick={() => handleDeclineInvitation()}
            className={classes.button}
            size="large"
            variant="contained"
            disabled={loading}
          >
            <FormattedMessage id="welcome.pending.decline" />
          </Button>
          <Button
            onClick={() => handleAcceptInvitation()}
            disabled={loading}
            className={classes.button}
            variant="contained"
            color="primary"
          >
            <FormattedMessage id="welcome.pending.join" />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PendingInvitationItem;
