import withStyles from '@mui/styles/withStyles';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

const IndeterminateIcon = withStyles(theme => ({
  checkIcon: {
    width: theme.spacing(1.75),
    height: theme.spacing(1.75),
  },
}))(({ classes }: any) => (
  <IndeterminateCheckBoxIcon className={classes.checkIcon} />
));

export default IndeterminateIcon;
