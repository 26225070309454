import Checkbox from '@mui/material/Checkbox';
import makeStyles from '@mui/styles/makeStyles';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import CheckedIcon from '../../../components/icons/CheckedIcon';
import UnCheckedIcon from '../../../components/icons/UnCheckedIcon';
import IndeterminateIcon from '../../../components/icons/IndeterminateIcon';
import { SubscriptionSource, UsagePlan } from '../../../models';
import { fromTestResultCount } from '../../../store';
import { Order } from '../utils';
import { useLaunchDarkly } from '../../../launchdarkly';

const useStyles = makeStyles(theme => ({
  cellContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > * > .MuiSvgIcon-root': {
      margin: 0,
      marginLeft: theme.spacing(-0.1),
      width: theme.spacing(2.5),
    },
  },
  infoIcon: {
    fontSize: theme.spacing(2.2),
    marginLeft: theme.spacing(-0.4),
    cursor: 'pointer',
  },
  licenseTooltip: {
    textAlign: 'center',
    maxWidth: theme.spacing(28),
  },
  cell: {
    padding: theme.spacing(0.875, 0, 0.875, 2),
  },
  checkbox: {
    padding: 0,
  },
}));

interface TableItemFields {
  id: number,
  fullName: string,
  email: string,
  joinDate: string,
  invitedBy: string,
  orgRole: string,
  licenseAccess: string[],
  lastAccess: string,
}

interface HeadCell {
  id: keyof TableItemFields;
  label: string;
  enableSort: boolean;
}

interface HeaderTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof TableItemFields) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  maxSelectable: number;
}

interface HeaderFields {
  id: number;
  fullName: string;
  email: string;
  joinDate: string;
  invitedBy: string;
  orgRole: string;
  licenseAccess: string;
  lastAccess: string;
}

const ActiveUsersHeaderTable = (props: HeaderTableProps) => {
  const { flags } = useLaunchDarkly();

  const classes = useStyles();
  const { onSelectAllClick, order,
    orderBy, numSelected,
    rowCount, onRequestSort, maxSelectable } = props;
  const intl = useIntl();
  const headCells: HeadCell[] = [
    { id: 'fullName', label: intl.formatMessage({ id: 'user_management.cell.fullname' }), enableSort: true },
    { id: 'email', label: intl.formatMessage({ id: 'user_management.cell.email' }), enableSort: false },
    { id: 'orgRole', label: intl.formatMessage({ id: 'user_management.cell.role' }), enableSort: true },
    { id: 'licenseAccess', label: intl.formatMessage({ id: 'user_management.cell.license_access' }), enableSort: true },
    { id: 'invitedBy', label: intl.formatMessage({ id: 'user_management.cell.invited_by' }), enableSort: false },
    { id: 'joinDate', label: intl.formatMessage({ id: 'user_management.cell.join_date' }), enableSort: true },
    { id: 'lastAccess', label: intl.formatMessage({ id: 'user_management.cell.last_login' }), enableSort: false },
  ];

  const createSortHandler = (property: keyof HeaderFields) => (
    event: React.MouseEvent<unknown>,
  ) => {
    onRequestSort(event, property);
  };
  const latestTestResultCount = useSelector(fromTestResultCount.selectLatestTestResultCount);
  const testOpsPlan = UsagePlan.getPlanFullName(latestTestResultCount?.usagePlan);
  const trial = latestTestResultCount?.subscriptionSource === SubscriptionSource.TRIAL;
  const licenseTooltip = () => (
    <div className={classes.licenseTooltip}>
      {trial
        ? intl.formatMessage({ id: 'user_management.cell.license_access.tooltip_trial' }, { testOpsPlan })
        : intl.formatMessage({ id: 'user_management.cell.license_access.tooltip' }, { testOpsPlan })}
    </div>
  );
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="none">
          {
            maxSelectable > 0 && (
              <Checkbox
                classes={{
                  root: classes.checkbox,
                }}
                icon={<UnCheckedIcon />}
                checkedIcon={<CheckedIcon />}
                indeterminate={numSelected > 0 && numSelected < maxSelectable}
                indeterminateIcon={<IndeterminateIcon />}
                checked={rowCount > 0 && numSelected === maxSelectable}
                onChange={onSelectAllClick}
              />
            )
          }
        </TableCell>
        {headCells.filter(headCell => flags?.invitedByEnabled || headCell.id !== 'invitedBy').map(headCell => (
          <TableCell
            className={classes.cell}
            padding="none"
            key={headCell.id.toString()}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <div className={classes.cellContainer}>
              {
                headCell.enableSort ? (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                    IconComponent={ArrowDropDownIcon}
                  >
                    {headCell.label}
                  </TableSortLabel>
                ) : headCell.label
              }
              {
                headCell.id === 'licenseAccess' && latestTestResultCount?.quota !== 0 ? (
                  <Tooltip title={licenseTooltip()} placement="top">
                    <InfoOutlinedIcon className={classes.infoIcon} />
                  </Tooltip>
                ) : null
              }
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
export default ActiveUsersHeaderTable;
