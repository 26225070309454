enum ProductName {
  STUDIO = 'STUDIO',
  STUDIO_ENTERPRISE = 'STUDIO_ENTERPRISE',
  RECORDER = 'RECORDER',
  KATALIUM = 'KATALIUM',
  RUNTIME_ENGINE = 'RUNTIME_ENGINE',
  TESTOPS = 'TESTOPS',
  TESTCLOUD_MINUTE = 'TESTCLOUD_MINUTE',
  TESTCLOUD_SESSION_WEB = 'TESTCLOUD_SESSION_WEB',
  TESTCLOUD_SESSION_WEB_DESKTOP = 'TESTCLOUD_SESSION_WEB_DESKTOP',
  TESTCLOUD_SESSION_CROSS_BROWSER = 'TESTCLOUD_SESSION_CROSS_BROWSER',
  TESTCLOUD_SESSION_MOBILE_APP = 'TESTCLOUD_SESSION_MOBILE_APP',
}

namespace ProductName {
  export const fromString = (str: string): ProductName => (
    ProductName as any)[str];
  export const getProductFullName = (product: ProductName) => {
    switch (product) {
      case ProductName.STUDIO:
        return 'Katalon Studio';
      case ProductName.STUDIO_ENTERPRISE:
        return 'Katalon Studio Enterprise';
      case ProductName.RECORDER:
        return 'Katalon Recorder';
      case ProductName.KATALIUM:
        return 'Katalium';
      case ProductName.RUNTIME_ENGINE:
        return 'Katalon Runtime Engine';
      case ProductName.TESTOPS:
        return 'Katalon TestOps';
      case ProductName.TESTCLOUD_MINUTE:
        return 'TestCloud Per Minute';
      case ProductName.TESTCLOUD_SESSION_WEB:
        return 'TestCloud Per Session';
      case ProductName.TESTCLOUD_SESSION_WEB_DESKTOP:
        return 'TestCloud Per Session (Desktop Browser)';
      case ProductName.TESTCLOUD_SESSION_CROSS_BROWSER:
        return 'TestCloud Per Session (Cross Browser)';
      case ProductName.TESTCLOUD_SESSION_MOBILE_APP:
        return 'TestCloud Per Session (Mobile Native App)';
      default:
        return '';
    }
  };
}

export { ProductName };
