import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import LoadingProgress from '../../layout/LoadingProgress';
import { fromOrganizations, useAppDispatch } from '../../store';
import { Organization } from '../../models';
import { ReactComponent as UploadIcon } from './uploadicon.svg';
import { resizeImage } from '../../services/organization';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    minWidth: theme.spacing(96),
    borderRadius: theme.spacing(1),
    display: 'flex',
  },
  header: {
    fontSize: '1.125rem',
    fontWeight: theme.typography.fontWeightMedium,
  },
  organizationBox: {
    display: 'flex',
    paddingTop: theme.spacing(1.5),
  },
  organizationProfileBox: {
    width: '100%',
    display: 'column',
  },
  label: {
    paddingTop: '0.75rem',
    paddingBottom: '0.5rem',
    fontWeight: theme.typography.fontWeightMedium,
  },
  avatarBox: {
    marginRight: theme.spacing(2),
    height: '100%',
    display: 'flex',
  },
  avatar: {
    fontSize: theme.spacing(6),
    fontWeight: theme.typography.fontWeightMedium,
    color: '#ffffff',
    backgroundColor: '#d73d32',
    width: theme.spacing(18),
    height: theme.spacing(18),
  },
  buttonSubmit: {
    backgroundColor: '#1847ca',
    marginBottom: theme.spacing(1.5),
  },
  textField: {
    '& .MuiOutlinedInput-input': {
      paddingTop: theme.spacing(0.75),
      paddingBottom: theme.spacing(0.75),
      paddingLeft: theme.spacing(1.25),
      paddingRight: theme.spacing(1.25),
    },
    '& .MuiInputBase-root': {
      borderRadius: theme.spacing(0.75),
    },
    marginBottom: theme.spacing(1.5),
  },
  organizationInformationBox: {
    display: 'column',
    width: theme.spacing(41.5),
    padding: theme.spacing(0, 2),
  },
  headerBox: {
    marginBottom: '0.5rem',
    paddingBottom: '0.5rem',
    fontWeight: theme.typography.fontWeightMedium,
    borderBottom: '1px solid #DCDFE6',
    fontSize: '1.125rem',
  },
  organizationData: {
    fontSize: '1rem',
    marginBottom: theme.spacing(1),
  },
  uploadButton: {
    cursor: 'pointer',
    padding: 0,
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  uploadButtonWrapper: {
    gridArea: '1/1',
    justifySelf: 'end',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(-4),
    zIndex: 1,
    opacity: 1,
    '&:hover': {
      opacity: 1,
    },
  },
  avatarWrapper: {
    display: 'flex',
    alignItems: 'end',
  },
  profileAvatar: {
    width: theme.spacing(16),
    height: theme.spacing(16),
    gridArea: '1/1',
    boxShadow: '0px 4px 8px 0px rgba(214, 214, 214, 0.25)',
    border: '8px solid rgba(255, 255, 255, 1)',
  },
}));

interface OrganizationProfileProps {
  currentOrganization: Organization;
}

const OrganizationProfile = (props: OrganizationProfileProps) => {
  const { currentOrganization } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [organizationName, setOrganizationName] = useState('');
  const [imageURI, setImageURI] = useState(currentOrganization?.logo);

  useEffect(() => {
    if (!currentOrganization) {
      return;
    }
    setOrganizationName(currentOrganization?.name);
  }, [currentOrganization]);

  const {
    register,
    handleSubmit,
  } = useForm<Required<Pick<Organization, 'id' | 'name'>>>({
    defaultValues: { name: '' },
  });

  const handleChangeName = (event: any) => {
    setOrganizationName(event.target.value);
  };

  const doUpdateOrganizationName = () => {
    if (!currentOrganization || organizationName.trim().length === 0) {
      return;
    }
    const handleUpdateOrganizationName = async () => {
      setLoading(true);
      await dispatch(
        fromOrganizations.doUpdateOrganization({
          id: currentOrganization?.id,
          name: organizationName,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          enqueueSnackbar(
            <FormattedMessage id="message.update.organization.name.success" />,
            { variant: 'success' },
          );
          // Dispatch to micro FE navigation UI to update org name
          const updateEvent = new CustomEvent('parent-app:changeOrgName', { detail: { orgId: currentOrganization?.id } });
          window.dispatchEvent(updateEvent);
        })
        .catch(() => {
          enqueueSnackbar(
            <FormattedMessage id="error.unknown" />,
            { variant: 'error' },
          );
        })
        .finally(() => {
          setLoading(false);
        });
    };
    handleUpdateOrganizationName().catch(() => {});
  };

  const stringAvatar = (name: string) => {
    if (name.split(' ').length > 1) {
      return ({
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
      });
    }
    return ({
      children: `${name.split(' ')[0][0]}`,
    });
  };

  function onPromise<T>(
    promise: (event: React.SyntheticEvent) => Promise<T>,
  ) {
    return (event: React.SyntheticEvent) => {
      if (promise) {
        promise(event).catch(() => {});
      }
    };
  }

  const doUpdateOrganizationLogo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const imageURISyncHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (currentOrganization && event.target.files) {
        try {
          setLoading(true);
          const resizedImage = await resizeImage(event.target.files[0]);
          setImageURI(resizedImage);
          await dispatch(
            fromOrganizations.doUpdateOrganization({
              id: currentOrganization?.id!!,
              name: currentOrganization?.name!!,
              logo: resizedImage,
            }),
          )
            .then(unwrapResult)
            .then(() => {
              enqueueSnackbar(
                <FormattedMessage id="message.update.organization.avatar.success" />,
                { variant: 'success' },
              );
              // Dispatch to micro FE navigation UI to update org name
              const updateEvent = new CustomEvent('parent-app:changeOrgName', { detail: { orgId: currentOrganization?.id } });
              window.dispatchEvent(updateEvent);
            })
            .catch(() => {
              enqueueSnackbar(
                <FormattedMessage id="error.unknown" />,
                { variant: 'error' },
              );
            })
            .finally(() => {
              setLoading(false);
            });
        } catch (error: any) {
          // show error here
        } finally {
          setLoading(false);
        }
      }
    };
    imageURISyncHandler(event).catch(() => {});
  };

  if (!currentOrganization) {
    return <></>;
  }

  return (
    <>
      <Typography id="organization_management.organization_profile.header" className={classes.headerBox}>
        <FormattedMessage id="organization_management.organization_profile.header" />
      </Typography>
      <Box className={classes.organizationBox}>
        <Box className={classes.avatarBox}>
          <Box className={classes.avatarWrapper}>
            {imageURI && <Avatar className={classes.avatar} src={imageURI} />}
            {!imageURI && <Avatar id="organization_management.organization_profile.organization.avatar" className={classes.avatar} {...stringAvatar(currentOrganization?.name)} />}
            <Tooltip title="Upload Avatar">
              <>
                <label htmlFor="upload-avatar" className={classes.uploadButtonWrapper}>
                  <UploadIcon className={classes.uploadButton} />
                  <input
                    accept="image/png, image/jpeg"
                    type="file"
                    id="upload-avatar"
                    onChange={e => doUpdateOrganizationLogo(e)}
                    hidden
                  />
                </label>
              </>
            </Tooltip>
          </Box>
        </Box>
        <Box className={classes.organizationInformationBox}>
          <Box>
            <Typography id="organization_management.organization_profile.organization.title.id" variant="subtitle2" className={classes.label}>
              <FormattedMessage id="organization_management.organization_profile.organization.title.id" />
            </Typography>
          </Box>
          <Box id="organization_management.organization_profile.organization.id" className={classes.organizationData}>
            {currentOrganization?.id}
          </Box>
          <Box>
            <Typography id="organization_management.organization_profile.organization.title.name" variant="subtitle2" className={classes.label}>
              <FormattedMessage id="organization_management.organization_profile.organization.title.name" />
            </Typography>
          </Box>
          <Box className={classes.organizationData}>
            <form
              onSubmit={onPromise(handleSubmit(doUpdateOrganizationName))}
              id="organization_management.organization_profile.form"
            >
              <TextField
                id="organization_management.organization_profile.textfield.organization_name"
                variant="outlined"
                fullWidth
                required
                name="name"
                value={organizationName}
                className={classes.textField}
                inputProps={{
                  ...register('name', {
                    required: 'error.required',
                  }),
                  maxLength: 255,
                }}
                onChange={handleChangeName}
              />
              <Button
                id="organization_management.organization_profile.organization.button.update"
                type="submit"
                variant="contained"
                color="primary"
                className={classes.buttonSubmit}
                size="medium"
              >
                <FormattedMessage id="organization_management.organization_profile.organization.button.update" />
              </Button>
            </form>
          </Box>
        </Box>
      </Box>
      {loading && <LoadingProgress />}
    </>
  );
};

export default OrganizationProfile;
