import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link as RouterLink, Navigate } from 'react-router-dom';
import { useConfig } from '../../../config';
import { useLaunchDarkly } from '../../../launchdarkly';
import { EmptySubscriptionIcon } from '../../../layout/CustomIcon';
import LoadingProgress from '../../../layout/LoadingProgress';
import { testCloudCheckout, testCloudGetQuote } from '../../../layout/routes';
import {
  canSubscribeOtherPlan,
  OrganizationFeature,
  ProductType,
  RecurlySubscriptionPayloadStatus,
  TestOpsPlanInterval,
  TestOpsSubscriptionStatus,
} from '../../../models';
import { notFound, useNavigate, useQuery } from '../../../routes';
import {
  fromAuth,
  fromCurrentOrgUser,
  fromOrganizations,
  fromTestCloudSubscriptions,
  fromTestOpsEnterpriseProduct,
  useAppDispatch,
} from '../../../store';
import { fromInvoices } from '../../../store/rootReducer';
import InvoiceTable from '../../payment-success/InvoiceTable';
import { getTestCloudFreeTrialDefault, getTestCloudFreeTrialExpiryDate, isTestCloudPerMinute } from '../utils';
import CancelSubscriptionButton from './CancelSubscriptionButton';
import ReactivateSubscriptionButton from './ReactivateSubscriptionButton';
import SubscriptionWarningDialog from './SubscriptionWarningDialog';
import UpgradeWarningDialog from './UpgradeWarningDialog';
import { BUSINESS_EMAIL } from '../../account/utils';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    borderRadius: '.5rem',
    padding: '1.5rem',
    overflowY: 'auto',
  },
  productTitle: {
    padding: theme.spacing(0, 2),
    marginBottom: theme.spacing(3),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(3),
  },
  tableContainer: {
    margin: theme.spacing(0, 2, 3),
    padding: theme.spacing(2.5, 3.5),
    borderRadius: theme.spacing(1),
  },
  tableCell: {
    padding: theme.spacing(3, 0),
  },
  status: {
    textTransform: 'capitalize',
  },
  headerTableCell: {
    padding: theme.spacing(1, 0),
  },
  button: {
    marginLeft: theme.spacing(2),
    height: theme.spacing(3),
    fontWeight: theme.typography.fontWeightMedium,
  },
  getQuoteButton: {
    color: '#233145',
    backgroundColor: '#F0F1F2',
    minWidth: theme.spacing(12),
  },
  contactSaleButton: {
    minWidth: theme.spacing(13.75),
  },
  tableRow: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  sectionTitle: {
    marginBottom: theme.spacing(1.5),
    fontWeight: theme.typography.fontWeightBold,
    color: '#516393',
    fontSize: theme.spacing(2.5),
  },
  emptyMessage: {
    paddingTop: theme.spacing(1),
    color: '#808B9A',
    fontSize: '0.75rem',
  },
}));

const TestCloudSubscription = () => {
  const classes = useStyles();
  const { navigate, replaceQuery } = useNavigate();
  const { get } = useQuery();
  const orgId = get('orgId');
  const intl = useIntl();
  const { flags, currentAccountId } = useLaunchDarkly();
  const user = useSelector(fromAuth.selectUser);
  const [isOpenWarningDialog, setIsOpenWarningDialog] = useState(false);
  const [isOpenUpgradeWarningDialog, setIsOpenUpgradeWarningDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const { config } = useConfig();

  const pendingInvoices = useSelector(fromInvoices.selectPendingInvoices);
  const currentOrganization = useSelector(fromOrganizations.selectSelectedOrganization);
  const currentTestCloudSubscription = useSelector(
    fromTestCloudSubscriptions.selectLatestActiveTestCloudSubscriptionByOrganizationId(
      Number(orgId),
    ),
  );
  const testCloudSubscriptionCount = useSelector(
    fromTestCloudSubscriptions.selectCount,
  );
  const MAX_TIER = 5;
  const disabledUpgrade = (
    currentTestCloudSubscription?.status === TestOpsSubscriptionStatus.ACTIVE
    && currentTestCloudSubscription?.feature === OrganizationFeature.TESTCLOUD_MINUTE
    && currentTestCloudSubscription?.tier === MAX_TIER
    && currentTestCloudSubscription?.billingInterval === TestOpsPlanInterval.YEAR
  );
  const suggestionPlan = useSelector(fromTestOpsEnterpriseProduct.selectSuggestFeaturePlan(
    currentTestCloudSubscription?.feature,
  ));
  useEffect(() => {
    if (!orgId || !user) return;
    const fetchInfo = async () => {
      await Promise.all([
        dispatch(fromInvoices.doGetPendingInvoices({
          organizationId: Number(orgId),
          features: [OrganizationFeature.TESTCLOUD_SESSION_WEB],
        })),
        dispatch(fromTestOpsEnterpriseProduct.doGetProductsInfoByType({
          type: ProductType.TESTCLOUD,
        })),
        dispatch(fromTestCloudSubscriptions.doGetTestCloudSubscriptionsByOrganizationId({
          organizationId: Number(orgId),
        })),
        dispatch(fromCurrentOrgUser
          .doGetOrgUserByUserIdAndOrgId({ id: user.id, organizationId: +orgId })),
      ]).finally(() => setLoading(false));
    };
    fetchInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, user]);

  const handleSubscribeButtonClick = (feature: OrganizationFeature) => {
    if (canSubscribeOtherPlan(currentTestCloudSubscription)) {
      navigate(testCloudCheckout.path, replaceQuery({ orgId: `${orgId}`, feature }));
    } else {
      setIsOpenWarningDialog(true);
    }
  };

  const freeTrialInformation = getTestCloudFreeTrialDefault();
  const freeTrialExpiryDate = getTestCloudFreeTrialExpiryDate(
    config,
    currentOrganization?.createdAt,
  );

  const handlePurchaseButtonClick = () => {
    if (disabledUpgrade) setIsOpenUpgradeWarningDialog(true);
    else {
      navigate(
        testCloudCheckout.path,
        replaceQuery({ orgId: `${orgId}`, feature: currentTestCloudSubscription?.feature }),
      );
    }
  };

  if (currentOrganization?.accountId === currentAccountId
  && flags && flags.subscriptionAndPaymentMethodDisabled === true) {
    return <Navigate to={notFound.path} replace />;
  }

  return (
    <div className={classes.root}>
      {!loading && (
        <>
          <Typography variant="h2" className={classes.productTitle}>
            <FormattedMessage id="subscriptions.testcloud.testcloud_subscription" />
          </Typography>
          <Paper
            elevation={0}
            className={classes.tableContainer}
          >
            <Typography variant="h3" className={classes.sectionTitle}>
              <FormattedMessage id="subscriptions.testcloud.subscription_summary" />
            </Typography>
            {
              currentTestCloudSubscription ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow
                        className={classes.tableRow}
                      >
                        <TableCell
                          padding="none"
                          className={classes.headerTableCell}
                        >
                          <FormattedMessage id="subscriptions.testcloud.subscription_summary.name" />
                        </TableCell>
                        <TableCell
                          padding="none"
                          className={classes.headerTableCell}
                        >
                          <FormattedMessage id="subscriptions.testcloud.subscription_summary.billing_cycle" />
                        </TableCell>
                        <TableCell
                          padding="none"
                          className={classes.headerTableCell}
                        >
                          <FormattedMessage id="subscriptions.testcloud.subscription_summary.environment" />
                        </TableCell>
                        <TableCell
                          padding="none"
                          className={classes.headerTableCell}
                        >
                          <FormattedMessage
                            id={
                              isTestCloudPerMinute(currentTestCloudSubscription.feature)
                                ? 'subscriptions.testcloud.subscription_summary.number_credits'
                                : 'subscriptions.testcloud.subscription_summary.number_sessions'
                            }
                          />
                        </TableCell>
                        <TableCell
                          padding="none"
                          className={classes.headerTableCell}
                        >
                          <FormattedMessage id="subscriptions.testcloud.subscription_summary.expiry_date" />
                        </TableCell>
                        <TableCell
                          padding="none"
                          className={classes.headerTableCell}
                        >
                          <FormattedMessage id="subscriptions.testcloud.subscription_summary.status" />
                        </TableCell>
                        <TableCell padding="none" />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        className={classes.tableRow}
                      >
                        <TableCell
                          padding="none"
                          className={classes.tableCell}
                        >
                          {
                            OrganizationFeature
                              .getFeatureFullName(currentTestCloudSubscription.feature)
                          }
                        </TableCell>
                        <TableCell
                          padding="none"
                          className={classes.tableCell}
                        >
                          {TestOpsPlanInterval.getPlanIntervalName(
                            currentTestCloudSubscription.billingInterval,
                          )}
                        </TableCell>
                        <TableCell
                          padding="none"
                          className={classes.tableCell}
                        >
                          <FormattedMessage id="subscriptions.testcloud.environment.web" />
                        </TableCell>
                        <TableCell
                          padding="none"
                          className={classes.tableCell}
                        >
                          {
                            isTestCloudPerMinute(currentTestCloudSubscription.feature)
                              ? intl.formatNumber(currentTestCloudSubscription.credits || 0)
                              : intl.formatNumber(currentTestCloudSubscription.concurrentSessions)
                          }
                        </TableCell>
                        <TableCell
                          padding="none"
                          className={classes.tableCell}
                        >
                          <FormattedDate
                            value={new Date(currentTestCloudSubscription.expiredAt)}
                            month="short"
                            day="numeric"
                            year="numeric"
                          />
                        </TableCell>
                        <TableCell
                          padding="none"
                          className={clsx(classes.tableCell, classes.status)}
                        >
                          {
                            // if recurlySubscription's is null, use TestCloudSubscription's status
                            currentTestCloudSubscription.recurlySubscription?.status?.toLowerCase()
                            || currentTestCloudSubscription.status.toLowerCase()
                          }
                        </TableCell>
                        <TableCell
                          padding="none"
                          className={classes.tableCell}
                          align="right"
                        >
                          <CancelSubscriptionButton
                            subscription={currentTestCloudSubscription}
                          />
                          <ReactivateSubscriptionButton
                            subscription={currentTestCloudSubscription}
                          />
                          {currentTestCloudSubscription.recurlySubscription?.status
                            !== RecurlySubscriptionPayloadStatus.CANCELED
                            && currentTestCloudSubscription.feature
                              !== OrganizationFeature.TESTCLOUD_MINUTE
                            && currentTestCloudSubscription.recurlySubscription
                            && (
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                className={classes.button}
                                onClick={handlePurchaseButtonClick}
                              >
                                <FormattedMessage
                                  id={
                                    currentTestCloudSubscription.recurlySubscription?.status
                                      === RecurlySubscriptionPayloadStatus.EXPIRED
                                      ? 'subscriptions.testcloud.subscription_summary.subscribe'
                                      : 'subscriptions.testcloud.subscription_summary.upgrade'
                                  }
                                />
                              </Button>
                            )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (new Date().valueOf() > freeTrialExpiryDate
              || testCloudSubscriptionCount > 0) ? (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignContent="center"
                  alignItems="center"
                  sx={{ pt: 5.5, pb: 3.75 }}
                >
                  <EmptySubscriptionIcon />
                  <Typography className={classes.emptyMessage} sx={{ pt: 1 }}>
                    <FormattedMessage id="subscriptions.testcloud.subscription_summary.empty_message" />
                  </Typography>
                </Box>
                ) : (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow className={classes.tableRow}>
                          <TableCell padding="none" className={classes.headerTableCell}>
                            <FormattedMessage id="subscriptions.testcloud.subscription_summary.name" />
                          </TableCell>
                          <TableCell padding="none" className={classes.headerTableCell}>
                            <FormattedMessage id="subscriptions.testcloud.subscription_summary.billing_cycle" />
                          </TableCell>
                          <TableCell padding="none" className={classes.headerTableCell}>
                            <FormattedMessage id="subscriptions.testcloud.subscription_summary.environment" />
                          </TableCell>
                          <TableCell padding="none" className={classes.headerTableCell}>
                            <FormattedMessage id="subscriptions.testcloud.subscription_summary.number_sessions" />
                          </TableCell>
                          <TableCell padding="none" className={classes.headerTableCell}>
                            <FormattedMessage id="subscriptions.testcloud.subscription_summary.expiry_date" />
                          </TableCell>
                          <TableCell padding="none" className={classes.headerTableCell}>
                            <FormattedMessage id="subscriptions.testcloud.subscription_summary.status" />
                          </TableCell>
                          <TableCell padding="none" />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow className={classes.tableRow}>
                          <TableCell padding="none" className={classes.tableCell}>
                            <FormattedMessage id="subscriptions.testcloud.free_trial" />
                          </TableCell>
                          <TableCell padding="none" className={classes.tableCell}>
                            <FormattedMessage id="subscriptions.testcloud.billing_cycle.none" />
                          </TableCell>
                          <TableCell padding="none" className={classes.tableCell}>
                            <FormattedMessage id="subscriptions.testcloud.environment.web" />
                          </TableCell>
                          <TableCell padding="none" className={classes.tableCell}>
                            {freeTrialInformation.concurrentSessions}
                          </TableCell>
                          <TableCell padding="none" className={classes.tableCell}>
                            <FormattedDate
                              value={new Date(freeTrialExpiryDate)}
                              month="short"
                              day="numeric"
                              year="numeric"
                            />
                          </TableCell>
                          <TableCell padding="none" className={clsx(classes.tableCell, classes.status)}>
                            {TestOpsSubscriptionStatus.ACTIVE.toLowerCase()}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                )
            }
          </Paper>
          {/* Suggestion */}
          <Paper
            elevation={0}
            className={classes.tableContainer}
          >
            <Typography variant="h3" className={classes.sectionTitle}>
              <FormattedMessage id="subscriptions.testcloud.subscription_summary.available_subscriptions" />
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      padding="none"
                      className={classes.headerTableCell}
                    >
                      <FormattedMessage id="subscriptions.testcloud.subscription_summary.name" />
                    </TableCell>
                    <TableCell
                      padding="none"
                      className={classes.headerTableCell}
                    >
                      <FormattedMessage id="subscriptions.testcloud.subscription_summary.environment" />
                    </TableCell>
                    <TableCell padding="none" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {suggestionPlan.map(row => (
                    <TableRow
                      className={classes.tableRow}
                      key={row.name}
                    >
                      <TableCell component="th" padding="none">
                        {OrganizationFeature.getFeatureFullName(row.feature)}
                      </TableCell>
                      <TableCell
                        padding="none"
                        className={classes.tableCell}
                      >
                        <FormattedMessage id="subscriptions.testcloud.environment.web" />
                      </TableCell>
                      <TableCell
                        padding="none"
                        className={classes.tableCell}
                        align="right"
                      >
                        {
                          !currentTestCloudSubscription && (
                          <Button
                            variant="contained"
                            className={clsx(classes.getQuoteButton, classes.button)}
                            component={RouterLink}
                            to={`${process.env.PUBLIC_URL}${testCloudGetQuote.path}?orgId=${orgId}&feature=${row.feature}`}
                          >
                            <FormattedMessage
                              id="subscriptions.testcloud.subscription_summary.get_quote"
                            />
                          </Button>
                          )
                        }
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          className={classes.button}
                          onClick={() => handleSubscribeButtonClick(row.feature)}
                        >
                          <FormattedMessage id="subscriptions.testcloud.subscription_summary.subscribe" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow
                    className={classes.tableRow}
                  >
                    <TableCell
                      component="th"
                      padding="none"
                      className={classes.tableCell}
                    >
                      <FormattedMessage id="subscriptions.testcloud.custom_plan" />
                    </TableCell>
                    <TableCell
                      padding="none"
                      className={classes.tableCell}
                    />
                    <TableCell
                      padding="none"
                      align="right"
                      className={classes.tableCell}
                    >
                      <Button
                        component={Link}
                        href={BUSINESS_EMAIL}
                        variant="contained"
                        color="primary"
                        size="small"
                        className={clsx(classes.contactSaleButton, classes.button)}
                      >
                        <FormattedMessage id="subscriptions.testcloud.custom_plan.button" />
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {pendingInvoices.length > 0 && (
            <Paper
              elevation={0}
              className={classes.tableContainer}
            >
              <Typography variant="h3" className={classes.sectionTitle}>
                <FormattedMessage id="subscriptions.testcloud.pending_invoices" />
              </Typography>
              <InvoiceTable invoices={pendingInvoices} />
            </Paper>
          )}
        </>
      )}
      {currentTestCloudSubscription && (
        <SubscriptionWarningDialog
          open={isOpenWarningDialog}
          handleClose={() => setIsOpenWarningDialog(false)}
          currentTestCloudFeature={currentTestCloudSubscription.feature}
        />
      )}
      {currentTestCloudSubscription && (
        <UpgradeWarningDialog
          open={isOpenUpgradeWarningDialog}
          handleClose={() => setIsOpenUpgradeWarningDialog(false)}
        />
      )}
      {loading && <LoadingProgress />}
    </div>
  );
};

export default TestCloudSubscription;
