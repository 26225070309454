import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/InfoRounded';
import { unwrapResult } from '@reduxjs/toolkit';
import throttle from 'lodash/throttle';
import { useSnackbar } from 'notistack';
import { useEffect, VFC } from 'react';
import { Navigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useConfig } from '../../config';
import LoadingProgress from '../../layout/LoadingProgress';
import { home, invalidInvitation } from '../../layout/routes';
import { isInvitationExpired, OrganizationFeature } from '../../models';
import { resolvePath, sso as ssoPath, useNavigate, useQuery, welcome } from '../../routes';
import {
  fromAcceptInvitePage,
  fromAuth,
  fromOrganizationFeatureFlag,
  fromOrganizations,
  fromSubscriptions,
  fromTestOpsPlatformSubscriptions,
  useAppDispatch,
} from '../../store';
import {
  ERROR_USER_INVITATION_ALREADY_JOINED_ORG,
  ERROR_USER_INVITATION_EXPIRED_LINK,
  ERROR_USER_INVITATION_INVALID_LINK,
  ERROR_USER_INVITATION_LIMITED_USER,
  ERROR_USER_INVITATION_REVOKED_LINK,
} from '../../store/acceptInvitationPageSlice';
import { ReactComponent as WelcomeToJoinOrgIcon } from './WelcomeToJoinOrgIcon.svg';

const INVITATION_TOKEN_KEY = 'invitation_token';
const ssoSettingUrl = 'https://docs.katalon.com/katalon-analytics/docs/sso-settings.html';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(4),
    textAlign: 'center',
  },
  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2.5),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.3,
  },
  message: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.22,
  },
  supportMessage: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.22,
    marginTop: theme.spacing(4),
  },
  supportSsoMessageWrapper: {
    marginTop: theme.spacing(4),
    flexDirection: 'column',
  },
  supportSsoMessage: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.22,
    textAlign: 'center',
  },
  ssoLink: {
    textDecoration: 'none',
  },
  infoIcon: {
    marginRight: theme.spacing(1),
    fontSize: theme.spacing(3),
    color: theme.typography.subtitle2.color,
  },
  buttonDecline: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(0, 0.5),
    minWidth: theme.spacing(5),
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    color: '#233145',
  },
  buttonAccept: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(0, 0.5),
    minWidth: theme.spacing(5),
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
  },
  supportSsoTitle: {
    marginBottom: theme.spacing(1),
  },
}));

const getSubdomain = (domain: string | undefined) => `${domain}.${process.env.REACT_APP_CUSTOM_DOMAIN_POSTFIX}`;

const AcceptInvitationPage: VFC = () => {
  const dispatch = useAppDispatch();
  const accountId = Number(useQuery().get('accountId'));
  const classes = useStyles();
  const { config } = useConfig();
  const { enqueueSnackbar } = useSnackbar();
  const { get } = useQuery();
  const intl = useIntl();
  const { replace, replaceQuery, navigate } = useNavigate();
  const invitationToken = get(INVITATION_TOKEN_KEY) || '';
  const loading = useSelector(fromAcceptInvitePage.selectLoading());
  const submitting = useSelector(fromAcceptInvitePage.selectSubmitting());
  const invitations = useSelector(fromAcceptInvitePage.selectInvitation());
  const errors = useSelector(fromAcceptInvitePage.selectErrors());
  let orgId = invitations?.[0]?.organizationId ?? 0;
  const currentUser = useSelector(fromAuth.selectUser);
  const organizationUserSso = useSelector(fromAcceptInvitePage.selectOrganizationUserSso());
  useEffect(() => {
    const fetchInfo = async () => {
      await dispatch(fromAcceptInvitePage.doGetUserInvitationData(
        {
          invitationToken,
          email: currentUser?.email!,
        },
      ));
    };
    fetchInfo().catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationToken]);
  // delay after show toast
  const navigateToOrganizationHome = () => {
    setTimeout(() => {
      // Fetch organization before navigating to prevent redirecting to forbidden page
      // on Layout Component (probably needs to figure out a better solution to fix this later)
      dispatch(fromOrganizations.doFetchOrganizationById({
        id: Number(validInvitations?.[0]?.organizationId),
      }));
      const urlHome = resolvePath(home, undefined, { orgId });
      // replace old history state, avoid caching old invitations:
      window.history.replaceState(null, '', urlHome);
      navigate(home.path, replaceQuery({ orgId }));
    }, 1000);
  };

  const activePlatformSubscription = useSelector(fromTestOpsPlatformSubscriptions
    .selectPaidTestOpsPlatformSubscriptionByAccountId(
      accountId,
    )).filter(sub => new Date(sub.expiryDate).getTime() > Date.now())?.[0];

  const hasTOEnterprise = useSelector(fromSubscriptions
    .selectHasTOEnterpriseByOrganizationId(Number(orgId) || 0));

  const isSsoFlagEnabled = useSelector(fromOrganizationFeatureFlag.selectIsOrganizationSsoEnabled(
    Number(orgId),
  )) || false;

  const hasEnableCustomDomainAndSSO = (
    activePlatformSubscription?.feature === OrganizationFeature.TESTOPS_PLATFORM
    && activePlatformSubscription.quota >= 30000)
  || hasTOEnterprise
  || isSsoFlagEnabled;

  const getSubdomainLink = (domain: string | undefined) => `https://${domain}.${process.env.REACT_APP_CUSTOM_DOMAIN_POSTFIX}`;

  const navigateToTestOpsHomepage = () => {
    setTimeout(() => {
      dispatch(fromOrganizations.doFetchOrganizationById({
        id: Number(orgId),
      })).then(unwrapResult)
        .then(data => {
          const currentOrg = data.organizations.filter(item => item.id === orgId)[0];
          dispatch(fromOrganizations.doChangeSelectedId(orgId));
          const urlHome = currentOrg?.domain
            ? resolvePath(ssoPath, undefined, { redirect_uri: `${getSubdomainLink(currentOrg?.domain)}/organization/${orgId}/home` })
            : resolvePath(ssoPath, undefined, { redirect_uri: `${config?.testOpsPublicUrl}/organization/${orgId}/home` });
          // replace old history state, avoid caching old invitations:
          window.history.replaceState(null, '', urlHome);
          navigate(ssoPath.path, currentOrg?.domain && hasEnableCustomDomainAndSSO
            ? replaceQuery({ redirect_uri: `${getSubdomainLink(currentOrg?.domain)}/organization/${orgId}/home` })
            : replaceQuery({ redirect_uri: `${config?.testOpsPublicUrl}/organization/${orgId}/home` }));
        })
        .catch((ex: any) => {
          enqueueSnackbar(
            intl.formatMessage(
              { id: ex.message },
            ),
            { variant: 'error' },
          );
        });
    }, 1000);
  };
  // delay after show toast
  const navigateToHome = () => {
    setTimeout(() => {
      replace(home.path, replaceQuery({}));
    }, 1000);
  };

  const navigateInvalidPage = () => {
    replace(invalidInvitation.path, replaceQuery({}));
  };

  useEffect(() => {
    if (errors && errors[0]) {
      switch (errors[0].message) {
        case ERROR_USER_INVITATION_INVALID_LINK:
        case ERROR_USER_INVITATION_LIMITED_USER:
          enqueueSnackbar(
            <FormattedMessage id={errors[0].message} />,
            {
              variant: 'error',
            },
          );
          break;
        case ERROR_USER_INVITATION_EXPIRED_LINK:
          navigateInvalidPage();
          break;
        case ERROR_USER_INVITATION_REVOKED_LINK:
          enqueueSnackbar(
            <FormattedMessage id="user_invitation.join.organization.accept.failed.revoked.message" />,
            {
              variant: 'error',
            },
          );
          navigateToHome();
          break;
        case ERROR_USER_INVITATION_ALREADY_JOINED_ORG:
          navigateToOrganizationHome();
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [errors, invitationToken]);

  if (loading || !config || !currentUser) {
    return <></>;
  }

  const validInvitations = invitations
    ?.filter(invitation => !isInvitationExpired(
      Number(invitation.updatedAt ?? invitation.createdAt ?? 0),
      config,
    ));
  const expiredInvitations = invitations
    ?.filter(invitation => isInvitationExpired(
      Number(invitation.updatedAt ?? invitation.createdAt ?? 0),
      config,
    ));

  if ((validInvitations?.length === 1
    && validInvitations?.[0]?.email !== currentUser?.email)
  || (validInvitations?.length === 0 && (expiredInvitations?.length ?? 0) > 0)) {
    navigateInvalidPage();
    return <></>;
  }

  if (!validInvitations || (validInvitations?.length ?? 0) !== 1) {
    return <Navigate to={welcome.path} replace />;
  }

  orgId = validInvitations?.[0]?.organizationId;

  const handleAcceptToJoinOrganization = throttle(
    async () => {
      const invitationRequest = {
        id: validInvitations?.[0]?.id ?? 0,
        accepted: true,
        invitationToken: invitationToken.length > 0
          ? invitationToken
          : validInvitations?.[0]?.invitationToken,
      };
      try {
        await dispatch(fromAcceptInvitePage.doAcceptInvitation(invitationRequest)).then(
          unwrapResult,
        );
        enqueueSnackbar(
          <FormattedMessage
            id="user_invitation.join.organization.accept.success.message"
            values={{ organizationName: invitations?.[0]?.organizationName ?? '' }}
          />,
          { variant: 'success' },
        );
        navigateToTestOpsHomepage();
        // eslint-disable-next-line no-empty
      } catch (_) {}
    },
    500,
    { leading: true, trailing: false },
  );

  const handleDeclineToJoinOrganization = throttle(
    () => {
      const invitationRequest = {
        id: validInvitations?.[0]?.id ?? 0,
        type: 'K1',
      };

      dispatch(fromAcceptInvitePage.doDeclineInvitation(invitationRequest)).finally(() => {
        navigateToHome();
      });
    },
    500,
    { leading: true, trailing: false },
  );

  return (
    <Grid container justifyContent="center">
      <Grid item className={classes.root}>
        <WelcomeToJoinOrgIcon />
        <Typography variant="h2" className={classes.title}>
          <FormattedMessage
            id="user_invitation.join.organization.title"
            values={{ br: <br />, organizationName: validInvitations?.[0]?.organizationName }}
          />
        </Typography>
        <Typography variant="h3" className={classes.message}>
          <FormattedMessage
            id="user_invitation.join.organization.message"
            values={{ br: <br />, account: validInvitations?.[0]?.email }}
          />
        </Typography>
        {organizationUserSso && (
          <Typography variant="h3" className={classes.message}>
            <FormattedMessage
              id="user_invitation.join.organization.your_subdomain"
              values={{ br: <br />,
                subDomain: getSubdomain(validInvitations?.[0]?.organizationDomain) }}
            />
          </Typography>
        )}
        {organizationUserSso && (
          <Grid container justifyContent="center" className={classes.supportSsoMessageWrapper}>
            <Grid item className={classes.supportSsoTitle}>
              <Grid container justifyContent="center" alignItems="center">
                <InfoIcon className={classes.infoIcon} />
                <Typography variant="h3" className={classes.supportSsoMessage}>
                  <FormattedMessage id="user_invitation.join.organization.sso.message.title" />
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container justifyContent="center">
                <Typography variant="h3" className={classes.supportSsoMessage}>
                  <FormattedMessage
                    id="user_invitation.join.organization.sso.message.content"
                    values={{
                      organizationName: validInvitations?.[0]?.organizationName,
                      learnMore: (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.ssoLink}
                          href={ssoSettingUrl}
                        >
                          {intl.formatMessage({
                            id: 'user_invitation.join.organization.sso.learn_more',
                          })}
                        </a>
                      ),
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid className={classes.buttonWrapper}>
          <Button
            disabled={submitting}
            className={classes.buttonDecline}
            variant="contained"
            onClick={handleDeclineToJoinOrganization}
          >
            <FormattedMessage id="user_invitation.join.organization.button.decline" />
          </Button>
          <Button
            disabled={submitting}
            className={classes.buttonAccept}
            variant="contained"
            color="primary"
            onClick={handleAcceptToJoinOrganization}
          >
            <FormattedMessage
              id="user_invitation.join.organization.button.accept"
              values={{ organizationName: validInvitations?.[0]?.organizationName }}
            />
          </Button>
        </Grid>
        <Typography variant="h3" className={classes.supportMessage}>
          <FormattedMessage
            id="user_invitation.join.organization.support.message"
            values={{
              br: <br />,
              supportAccount: <b>support@katalon.com</b>,
            }}
          />
        </Typography>
      </Grid>
      {loading && <LoadingProgress />}
    </Grid>
  );
};

export default AcceptInvitationPage;
