import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import CardActions from '@mui/material/CardActions';
import { OrganizationFeature, TestOpsPlanInterval } from '../../../../models';
import { fromStarterPackage } from '../../../../store/rootReducer';

const PlanSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    color: '#276EF1',
    padding: 2,
    '&.Mui-checked': {
      color: '#276EF1',
      transform: 'translateX(12px)',
      '& + .MuiSwitch-track': {
        backgroundColor: '#ffffff',
      },
      '&.Mui-disabled': {
        color: '#f5f5f5',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    border: '2px solid #276EF1',
    backgroundColor: '#ffffff',
  },
}));

const useStyles = makeStyles((theme => ({
  productTitle: {
    padding: theme.spacing(0, 2),
    marginBottom: theme.spacing(3),
    fontSize: theme.spacing(3),
  },
  tierDescription: {
    fontSize: 12,
    marginTop: 8,
  },
  formatSelection: {
    backgroundColor: '#FFFFFF',
    borderColor: '#D5D8DD',
    marginLeft: 20,
    width: 180,
    height: 32,
    fontWeight: 600,
    fontSize: 14,
  },
  formatNumberOfProjects: {
    fontWeight: 600,
    paddingLeft: 20,
  },
  formatPrice: {
    fontWeight: 700,
    fontSize: 24,
    align: 'left',
    color: '#233145',
    marginRight: 5,
  },
  formatUnit: {
    fontSize: 14,
    color: '#808B9A',
  },
  formatIcon: {
    marginRight: 5,
  },
  formatOnToggle: {
    fontSize: 12,
    fontWeight: 500,
    color: '#233145',
  },
  formatOffToggle: {
    fontSize: 12,
    color: '#808B9A',
  },
  badgeSubscription: {
    fontWeight: theme.typography.fontWeightBold,
  },
  formatBorderCard: {
    border: '1px solid #7AB2FF',
    height: '600px',
  },
  formatCurrentActive: {
    backgroundColor: '#F9FBFF',
  },
  formatBadgeCurrentActive: {
    backgroundColor: '#102C60',
    borderRadius: '4px 4px 0px 0px',
    color: '#FFFFFF',
    fontSize: 12,
  },
})));

export interface BillingCycleProps {
  initialInterval: TestOpsPlanInterval;
  onChangeInterval?: (interval: TestOpsPlanInterval) => void;
  disabled?: boolean;
}

const BillingCycle = (props: BillingCycleProps) => {
  const classes = useStyles();
  const { initialInterval, onChangeInterval, disabled } = props;
  const [isMonthly, setIsMonthly] = useState(initialInterval === TestOpsPlanInterval.MONTH);
  const starterPackage = useSelector(fromStarterPackage.selectStarterPackage);

  const getInterval = (isMonthly: boolean): TestOpsPlanInterval => (isMonthly
    ? TestOpsPlanInterval.MONTH
    : TestOpsPlanInterval.YEAR);

  const handleOnChangePlan = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isMonthly: boolean = event.target.checked;
    setIsMonthly(isMonthly);

    const interval = getInterval(isMonthly);
    if (onChangeInterval) {
      onChangeInterval(interval);
    }
  };

  useEffect(() => {
    if (starterPackage?.listPlans.find(plan => plan.feature
      === OrganizationFeature.TESTOPS_PLATFORM && plan.quota > 2000) !== undefined) {
      setIsMonthly(false);
    }
  }, [starterPackage]);

  useEffect(() => {
    if (initialInterval === TestOpsPlanInterval.YEAR) {
      setIsMonthly(false);
    } else {
      setIsMonthly(true);
    }
  }, [initialInterval]);

  return (
    <CardActions sx={{ height: 16, padding: 0, justifyContent: 'flex-end' }}>
      <span className={isMonthly ? classes.formatOffToggle : classes.formatOnToggle}>
        <FormattedMessage id="subscriptions.testops_platform.annual" />
      </span>
      <PlanSwitch disabled={disabled} checked={isMonthly} onChange={handleOnChangePlan} />
      <span className={isMonthly ? classes.formatOnToggle : classes.formatOffToggle}>
        <FormattedMessage id="subscriptions.testops_platform.monthly" />
      </span>
    </CardActions>
  );
};

export default BillingCycle;
