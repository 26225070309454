import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { createRef, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { Provider, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import userflow from 'userflow.js';
import { parse } from 'querystring';
import AppLoadingProgress from './AppLoadingProgress';
import { ConfigProvider } from './config';
import ErrorHandler from './ErrorHandler';
import routes, { AppRoutes, QueryDictionary, useNavigate, useQuery } from './routes';
import store, { fromAccountUsers, fromAuth, fromOrganizations, fromSecuritySetting, useAppDispatch } from './store';
import { SwitchThemeProvider } from './theme';
import { en } from './translations';
import { LaunchDarklyProvider } from './launchdarkly';
import { AccountRole, User, getFullNameUser } from './models';
import { NotificationProvider } from './notification';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  // error region
  // force element's color for both dark mode and light mode
  // util we have another design for dark mode
  snackbar: {
    paddingLeft: theme.spacing(2),
  },
  iconSuccess: {
    color: '#36B37E',
    marginRight: theme.spacing(1),
  },
  iconError: {
    color: '#FA6F50',
    marginRight: theme.spacing(1),
  },
  // see why !important is used here: https://github.com/iamhosseindhv/notistack/issues/461
  variantSuccess: {
    backgroundColor: '#E7F8F1 !important',
    color: '#000000 !important',
  },
  variantError: {
    backgroundColor: '#FFEDE6 !important',
    color: '#000000 !important',
  },
  action: {
    marginLeft: theme.spacing(2),
  },
  closeButton: {
    padding: 0,
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  closeIcon: {
    color: '#516393',
    backgroundColor: 'inherit',
  },
  // end error region
}));

const ThemeProviderChildrenAdapter = () => {
  const currentUser = useSelector(fromAuth.selectUser);
  let accountId = useQuery().get('accountId');
  const orgId = useQuery().get('orgId');
  const dispatch = useAppDispatch();
  const [securitySettingsLoaded, setSecuritySettingsLoaded] = useState(false);

  const organization = useSelector(
    fromOrganizations.selectOrganizationById(+(orgId || 0)),
  );

  if (!accountId && orgId && organization) {
    accountId = String(organization.accountId);
  }

  const accountUser = useSelector(
    fromAccountUsers.selectOneByUserIdAndAccountId(currentUser?.id || 0, +(accountId || 0)),
  );

  const securitySetting = useSelector(
    fromSecuritySetting.selectAllSecuritySettingsByAccountId(Number(accountId)),
  );

  const USER_FLOW_TOKEN = process.env.REACT_APP_USER_FLOW_TOKEN;
  const USER_FLOW_GROUP_ID = process.env.REACT_APP_USER_FLOW_GROUP_ID;

  const sendAttributesToUserFlow = (
    currentUser: User,
    aiAdopted?: boolean,
  ) => {
    if (!USER_FLOW_TOKEN || !USER_FLOW_GROUP_ID) return;

    const fullName = getFullNameUser(
      currentUser.firstName,
      currentUser.lastName,
      currentUser.email,
    );
    const userId = currentUser.id.toString();
    const signedUpAt = new Date(currentUser.createdAt).toISOString();
    userflow.init(USER_FLOW_TOKEN);
    userflow.identify(userId, {
      name: fullName,
      email: currentUser.email,
      signed_up_at: signedUpAt,
      ai_adopted: aiAdopted !== undefined ? String(aiAdopted) : undefined,
    });
    userflow.group(USER_FLOW_GROUP_ID);
  };

  if (USER_FLOW_TOKEN && USER_FLOW_GROUP_ID) {
    if (currentUser && accountId && securitySettingsLoaded) {
      let aiAdopted;
      if (securitySetting.length && securitySetting[0].enableAi === true) {
        aiAdopted = true;
      } else if (accountUser && accountUser.role === AccountRole.OWNER) {
        aiAdopted = false;
      }

      if (aiAdopted !== undefined) {
        sendAttributesToUserFlow(currentUser, aiAdopted);
      }
    } else if (currentUser && !accountId && !orgId) {
      sendAttributesToUserFlow(currentUser);
    }
  }

  const classes = useStyles();
  const notistackRef = createRef<SnackbarProvider>();
  const onClickDismiss = (key: SnackbarKey) => () => {
    if (!notistackRef.current) return;
    notistackRef.current.closeSnackbar(key);
  };

  useEffect(() => {
    if (accountId && currentUser && !securitySettingsLoaded) {
      dispatch(fromSecuritySetting.getAllSecuritySettings({ accountId: Number(accountId) })).then(
        () => {
          setSecuritySettingsLoaded(true);
        },
      );
    }
  }, [dispatch, currentUser, accountId]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <IntlProvider locale="en" messages={en}>
        <NotificationProvider>
          <SnackbarProvider
            className={classes.snackbar}
            hideIconVariant={false}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            iconVariant={{
              success: <CheckCircleIcon className={classes.iconSuccess} />,
              error: <ErrorIcon className={classes.iconError} />,
            }}
            classes={{
              variantSuccess: classes.variantSuccess,
              variantError: classes.variantError,
            }}
            ref={notistackRef}
            action={key => (
              <Box className={classes.action}>
                <IconButton
                  classes={{ root: classes.closeButton }}
                  onClick={onClickDismiss(key)}
                  size="large"
                >
                  <HighlightOffIcon className={classes.closeIcon} fontSize="small" />
                </IconButton>
              </Box>
            )}
          >
            {
              // This component must be mounted here
              // because it need inject Store, intl, Snackbar as dependencies
            }
            <ErrorHandler />
            <AppLoadingProgress />
            <AppRoutes routes={routes} />
          </SnackbarProvider>
        </NotificationProvider>
      </IntlProvider>
    </div>
  );
};

const App = () => {
  const location = useLocation();
  const { pathname, search } = location;
  const { replace, replaceQuery } = useNavigate();
  const { clear, get, queryDictionary, queryString } = useQuery();

  useEffect(() => {
    const anonymousIdFromQuery = get('anonymous_id');
    if (anonymousIdFromQuery) {
      // anonymousId is formated by segment, it contains '"' and '"' in then begining and ending
      // so, we need to remove it when creating alias
      analytics.alias(anonymousIdFromQuery);
      clear('anonymous_id');
      replace(undefined, replaceQuery(queryDictionary()));
    }
  }, [replace, location.search]);

  useEffect(() => {
    window.addEventListener('micro-app:navigate', handleNavigate);
    return () => {
      window.removeEventListener('micro-app:navigate', handleNavigate);
    };
  }, []);

  useEffect(() => {
    let path = pathname;
    if (!path.startsWith(process.env.PUBLIC_URL)) {
      path = process.env.PUBLIC_URL + path;
    }
    const navigateEvent = new CustomEvent('parent-app:navigate', { detail: { path, search: queryString() } });
    window.dispatchEvent(navigateEvent);
  }, [pathname, search]);

  const handleNavigate = ({ detail }: CustomEventInit) => {
    let { path } = detail;
    const { search } = detail;
    if (path.startsWith(process.env.PUBLIC_URL)) {
      path = path.replace(process.env.PUBLIC_URL, '');
    }
    replace(path, replaceQuery(parse(search) as QueryDictionary));
  };

  return (
    <ConfigProvider>
      <LaunchDarklyProvider>
        <Provider store={store}>
          <SwitchThemeProvider>
            <ThemeProviderChildrenAdapter />
          </SwitchThemeProvider>
        </Provider>
      </LaunchDarklyProvider>
    </ConfigProvider>
  );
};

export default App;
