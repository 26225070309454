import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import UserGroupDetailAbout from './UserGroupDetailAbout';
import UserGroupDetailDelete from './UserGroupDetailDelete';
import { useNavigate, useQuery } from '../../routes';
import { fromAccountUsers, fromAuth, fromOrganizationUsers, fromOrganizations, fromUserGroup, useAppDispatch } from '../../store';
import LoadingProgress from '../../layout/LoadingProgress';
import { userGroup } from '../../layout/routes';
import UserGroupDetailUserList from './UserGroupDetailUserList';
import UserGroupDetailHeader from './UserGroupDetailHeader';
import { getAllUserGroups } from '../../store/userGroupSlice';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    padding: theme.spacing(2, 2.75),
    overflowY: 'auto',
  },
}));

const UserGroupDetail = () => {
  const orgId = useQuery().get('orgId');
  const params = useParams();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { replace, replaceQuery } = useNavigate();

  const user = useSelector(fromAuth.selectUser);
  const organization = useSelector(fromOrganizations.selectOrganizationById(Number(orgId)));
  const currentOrgUser = useSelector(fromOrganizationUsers.selectByUserEmailAndOrganizationId(
    user?.email ?? '',
    Number(orgId),
  ));
  const currentAccountUser = useSelector(fromAccountUsers.selectOneByUserIdAndAccountId(
    Number(user?.id),
    Number(organization?.accountId),
  ));

  const userGroupId = params.id;

  const currentUserGroup = useSelector(fromUserGroup.selectUserGroupsById(Number(userGroupId)));
  const isLoadingUserGroups = useSelector(fromUserGroup.selectLoading(getAllUserGroups.typePrefix));

  const [fetchedOrgInfo, setFetchedOrgInfo] = useState(false);
  const [fetchedAccountInfo, setFetchedAccountInfo] = useState(false);
  const [addUsersRefresh, setAddUsersRefresh] = useState(0);

  useEffect(() => {
    const fetchInformation = async () => {
      await Promise.all([
        dispatch(fromUserGroup.getAllUserGroups({ organizationId: Number(orgId) })),
      ]);
    };
    fetchInformation();
  }, [orgId]);

  useEffect(() => {
    if (!orgId) return;

    const fetchInfo = async () => {
      await Promise.all([
        dispatch(fromOrganizationUsers.doGetAllOrgUsers({ organizationId: Number(orgId) })),
      ]);
      setFetchedOrgInfo(true);
    };
    fetchInfo();
  }, [orgId]);

  useEffect(() => {
    if (!organization) return;

    const fetchInfo = async () => {
      await Promise.all([
        dispatch(fromAccountUsers
          .doGetByUserIdAndAccountId({
            userId: user!!.id,
            accountId: organization.accountId ?? 0,
          })),
      ]);
      setFetchedAccountInfo(true);
    };
    fetchInfo();
  }, [organization]);

  const goToListPage = () => {
    replace(
      userGroup.path,
      replaceQuery({ orgId: Number(orgId) }),
    );
  };

  if (fetchedOrgInfo && fetchedAccountInfo && !isLoadingUserGroups && !currentUserGroup) {
    goToListPage();
  }

  if (!fetchedOrgInfo || !fetchedAccountInfo) return <LoadingProgress />;

  const handleRefreshGroupUsersData = () => {
    setAddUsersRefresh(addUsersRefresh + 1);
  };

  return (
    <div className={classes.root}>
      {/* Header */}
      <UserGroupDetailHeader
        currentOrgUser={currentOrgUser}
        userGroupId={Number(userGroupId)}
        orgId={Number(orgId)}
        addUserDialogCallback={handleRefreshGroupUsersData}
        currentAccountUser={currentAccountUser}
      />

      {/* About */}
      <UserGroupDetailAbout
        userGroupId={Number(userGroupId)}
        currentOrgUser={currentOrgUser}
        currentAccountUser={currentAccountUser}
        orgId={Number(orgId)}
      />

      {/* User List */}
      <UserGroupDetailUserList
        userGroupId={Number(userGroupId)}
        currentOrgUser={currentOrgUser}
        currentAccountUser={currentAccountUser}
        addUsersRefresh={addUsersRefresh}
      />

      {/* Delete */}
      <UserGroupDetailDelete
        id={Number(userGroupId)}
        userOrgRole={currentOrgUser?.role}
        userAccountRole={currentAccountUser?.role}
        goToListPage={goToListPage}
      />
    </div>
  );
};

export default UserGroupDetail;
