import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { unwrapResult } from '@reduxjs/toolkit';
import subMilliseconds from 'date-fns/subMilliseconds';
import dateFormat from 'dateformat';
import DurationUnitFormat from 'intl-unofficial-duration-unit-format';
import { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { getFullNameUser, KsSession, KsSessionView, OrganizationFeature, OrganizationRole, User } from '../../../models';
import { Filter } from '../../../models/query';
import { forbidden, useNavigate, useQuery } from '../../../routes';
import { fromCurrentOrgUser, fromLicenseUtilization, fromOrganizations, useAppDispatch } from '../../../store';
import { ReactComponent as ExportIcon } from '../../icons/export-icon.svg';

const options = {
  style: DurationUnitFormat.styles.NARROW,
  format: '{hours} {minutes} {seconds}',
};
const intlDuration = new DurationUnitFormat('en', options);

interface ExportSessionButtonProps {
  user: User;
  organizationId: number;
  filters: Filter<KsSessionView>[];
  startDate: Date;
  endDate: Date;
}

/**
 * References
 * https://www.cluemediator.com/loading-data-asynchronously-and-download-csv-using-react-csv
 * see also: https://github.com/react-csv/react-csv/issues/282
 * Note that CSVDownload does not support custom file name.
 */

const ExportSessionButton = (props: ExportSessionButtonProps) => {
  const { organizationId, filters, user, startDate, endDate } = props;
  const { replace, replaceQuery } = useNavigate();
  const { queryDictionary } = useQuery();
  const intl = useIntl();
  const composeCsvData = (sessions: KsSession[]): string[][] => {
    const result = [
      [
        intl.formatMessage({ id: 'license_utilization.export.username' }),
        intl.formatMessage({ id: 'license_utilization.export.user_email' }),
        intl.formatMessage({ id: 'license_utilization.export.session_id' }),
        intl.formatMessage({ id: 'license_utilization.export.machine_id' }),
        intl.formatMessage({ id: 'license_utilization.export.license_type' }),
        intl.formatMessage({ id: 'license_utilization.export.started' }),
        intl.formatMessage({ id: 'license_utilization.export.duration' }),
      ],
    ].concat(
      sessions.map(sess => [
        getFullNameUser(sess.user.firstName, sess.user.lastName, sess.user.email),
        sess.user.email,
        sess.sessionId ?? '',
        sess.machineId ?? '',
        OrganizationFeature.getFeatureHalfFullName(sess.feature),
        sess.startTime
          ? intl.formatDate(
            new Date(sess.startTime),
            {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hourCycle: 'h23',
            },
          )
          : '',
        intlDuration.format((sess.duration ?? 0) / 1000),
      ]),
    );
    return result;
  };
  const allSessions = useSelector(fromLicenseUtilization.selectAllKsSessions);
  const linkRef = useRef<any>(null);
  const organization = useSelector(fromOrganizations.selectOrganizationById(organizationId));
  const currentOrgUser = useSelector(fromCurrentOrgUser
    .selectByOrganizationIdAndEmail(user.email, organizationId));
  const dispatch = useAppDispatch();
  // control the rendering of component LoadingProgress
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await dispatch(fromCurrentOrgUser.doGetOrgUserByUserIdAndOrgId({ id: user.id, organizationId }))
      .then(unwrapResult);
    if (!(currentOrgUser?.role
      && [OrganizationRole.ADMIN, OrganizationRole.OWNER].includes(currentOrgUser?.role))
    ) {
      replace(forbidden.path, replaceQuery(queryDictionary()));
    }
    (async () => {
      const newFilters: Filter<KsSessionView>[] = filters.map(it => (it.field === 'email' ? ({
        field: 'user.email',
        operator: it.operator,
        value: it.value,
      }) : it));
      await dispatch(fromLicenseUtilization
        .doGetAllKsSessions({ startDate, endDate, organizationId, filters: newFilters }))
        .then(unwrapResult);
      setLoading(false);
    })();
  };

  useEffect(() => {
    if (allSessions) {
      setTimeout(() => {
        // CSVLink may not be rendered, use ?. so that the app won't crash (NPE)
        linkRef.current?.link?.click();
      });
    }
  }, [allSessions]);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        startIcon={loading ? <CircularProgress size={20} style={{ color: '#fff' }} /> : <ExportIcon />}
      >
        {loading ? intl.formatMessage({ id: 'license_utilization.exporting' }) : intl.formatMessage({ id: 'dashboard.export' })}
      </Button>
      {allSessions && (
        <CSVLink
          data={composeCsvData(allSessions!!)}
          filename={`License Utilization_${organization?.name}_${organizationId}_${dateFormat(new Date(startDate), 'yyyymmdd')}-${dateFormat(subMilliseconds(new Date(endDate), 1), 'yyyymmdd')}.csv`}
          ref={linkRef}
        />
      )}
    </>
  );
};

export default ExportSessionButton;
