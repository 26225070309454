import { BaseModel } from './base';
import { Role } from './role';
import { UserStatus } from './userStatus';

export interface User extends BaseModel {
  avatar?: string;
  email: string;
  password?: string;
  roles: Role[];
  archived: boolean;
  status?: UserStatus;
  firstName?: string;
  lastName?: string;
  resetPasswordCode?: string;
  newPassword?: string;
  jobTitle?: string;
  testingSolutions?: string;
  deleteReason?: string;
  createdAt: Date;
  lastLogin?: Date;
  isRemoved?: boolean;
  userExists?: boolean;
  testopUserId?: User['id'];
}
export const getFullNameUser = (firstName: string | undefined, lastName: string | undefined, email: string | undefined) => (!firstName && !lastName ? (email || '').split('@')[0] : `${firstName || ''} ${lastName || ''}`.trim());

export const getAvatarSource = (src: string | undefined) => {
  if (src) {
    // case fullink, case from K1, case base64 just return the current value
    if (src.startsWith('http') || src.startsWith('/') || src.startsWith('data:')) return src;
    return `/${src}`; // image from TO so we append /
  }
  return 'https://katalon-testops.s3.amazonaws.com/image/icon/defaultAvatar.png';
};

export enum UserSubscriptionType {
  FREE = 'FREE',
  PAID = 'PAID',
}
