import { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { FormattedMessage, useIntl } from 'react-intl';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useNavigate, useQuery } from '../../../routes';
import {
  fromPaymentMethod,
  fromInvoices,
} from '../../../store/rootReducer';
import {
  accountSubscriptionManagement,
} from '../../../layout/routes';
import { useAppDispatch } from '../../../store';
import CardInformation from '../testops-platform-checkout/payment-information-section/CardInformation';
import { PaymentMethodType } from '../../../models';
import LoadingProgress from '../../../layout/LoadingProgress';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

const useStyles = makeStyles((theme => ({
  root: {
    height: '100%',
    width: '100%',
    borderRadius: '.5rem',
    padding: '1.5rem',
    overflowY: 'auto',
    backgroundColor: '#F9FBFF',
  },
  page: {
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
  },
  iconBack: {
    marginRight: theme.spacing(2),
    color: '#727993',
  },
  backBtn: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  titleComeBack: {
    color: '#727993',
    fontFamily: 'Inter',
    fontSize: theme.spacing(2),
    marginBottom: theme.spacing(0.75),
    fontWeight: 500,
  },
  cardPaymentAndBilling: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(2.5),
    fontWeight: theme.typography.fontWeightBold,
    color: '#313B58',
    fontSize: theme.spacing(2),
  },
  section: {
    padding: theme.spacing(4, 5),
  },
  retryButton: {
    marginTop: theme.spacing(2),
    fontWeight: 500,
  },
})));

const RetryPaymentPage = () => {
  const classes = useStyles();
  const { get } = useQuery();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { replaceQuery, navigate } = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const accountId = Number(get('accountId'));
  const retryPaymentSuccess = useSelector(fromInvoices.selectRetryPaymentSuccess);
  const selectedPaymentMethod = useSelector(fromPaymentMethod.selectSelectedPaymentMethod);
  const currentCard = useSelector(fromPaymentMethod.selectOneByAccountId(Number(accountId)));
  const [isLoading, setIsLoading] = useState(false);
  const [isFormCardOpen, setIsFormCardOpen] = useState(!currentCard);

  const goSubscriptionManagement = () => {
    navigate(accountSubscriptionManagement.path, replaceQuery({
      accountId: `${accountId}`,
    }));
  };

  const renderCreditCard = () => (
    <div>
      <Elements stripe={stripePromise}>
        <CardInformation
          accountId={accountId}
          enableSave
          setFormCardOpen={isOpen => {
            setIsFormCardOpen(isOpen);
          }}
        />
      </Elements>
    </div>
  );

  useEffect(() => {
    if (retryPaymentSuccess !== undefined) {
      setIsLoading(false);

      if (retryPaymentSuccess === true) {
        enqueueSnackbar(
          <FormattedMessage id="retry.payment.success" />,
          {
            variant: 'success',
          },
        );
      }

      navigate(accountSubscriptionManagement.path, replaceQuery({
        accountId: `${accountId}`,
      }));
    }
  }, [retryPaymentSuccess]);

  const handleRetryPayment = () => {
    setIsLoading(true);
    dispatch(fromInvoices.doRetryPayment(+accountId));
  };

  useEffect(() => {
    setIsFormCardOpen(!currentCard);
  }, [currentCard]);

  return (
    <>
      {isLoading ? <LoadingProgress /> : (
        <div className={classes.root}>
          <Button
            className={classes.backBtn}
            onClick={goSubscriptionManagement}
          >
            <Grid container alignItems="flex-end" className={classes.page}>
              <Grid item>
                <ArrowBackIcon className={classes.iconBack} />
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.titleComeBack}>
                  {intl.formatMessage({ id: 'billinginfo.billingcontact.back.to.subscription.management' })}
                </Typography>
              </Grid>
            </Grid>
          </Button>
          <Paper className={classes.section} elevation={0}>
            {renderCreditCard()}
            <Button
              variant="contained"
              color="primary"
              onClick={handleRetryPayment}
              disabled={(!currentCard
                && selectedPaymentMethod === PaymentMethodType.CREDIT_CARD)
                || isFormCardOpen}
              className={classes.retryButton}
            >
              {intl.formatMessage({ id: 'retry.last.payment' })}
            </Button>
          </Paper>
        </div>
      )}
    </>
  );
};

export default RetryPaymentPage;
