import Button from '@katalon-studio/katalon-ui/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { AccountRole, OrganizationRole } from '../../models';
import DeleteUserGroupPopup from './DeleteUserGroupPopup';
import { fromAuth } from '../../store';

interface UserGroupDetailDeleteProps {
  id: number;
  userOrgRole?: OrganizationRole;
  userAccountRole?: AccountRole;
  goToListPage: () => void;
}

const useStyles = makeStyles(theme => ({
  paper: {
    flexDirection: 'column',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  title: {
    fontWeight: 500,
    fontSize: theme.spacing(2.5),
  },
  itemTitle: {
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
  },
  itemSubTitle: {
    fontWeight: 'normal',
    fontSize: theme.spacing(1.5),
    color: '#808b9a',
    // width: '50%',
  },
  activeButton: {
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
    backgroundColor: '#d24720',
    '&:hover': {
      backgroundColor: '#d24720',
    },
  },
  boxSpacing: {
    height: theme.spacing(2),
  },
}));

const UserGroupDetailDelete = (props: UserGroupDetailDeleteProps) => {
  const { id, userOrgRole, userAccountRole, goToListPage } = props;

  const classes = useStyles();

  const isRootOrAdmin = useSelector(fromAuth.selectIsRootOrAdmin);

  const [isOpen, setIsOpen] = useState(false);

  if (!AccountRole.isAdminOrOwnerRole(userAccountRole)
    && !OrganizationRole.isAdminOrOwnerRole(userOrgRole)
    && !isRootOrAdmin) return <></>;

  return (
    <>
      <Paper className={classes.paper} square elevation={0}>
        <Typography
          id="user.group.detail.delete.title"
          variant="h3"
          className={classes.title}
        >
          <FormattedMessage id="user.group.detail.delete.title" />
        </Typography>
        <Box className={classes.boxSpacing} />
        <Typography
          id="user.group.detail.delete.sub_title"
          variant="h5"
          className={classes.itemSubTitle}
        >
          <FormattedMessage id="user.group.detail.delete.sub_title" />
        </Typography>
        <Box className={classes.boxSpacing} />
        <Button
          id="user.group.detail.general.delete_button"
          className={classes.activeButton}
          type="submit"
          onClick={() => setIsOpen(true)}
          color="error"
          variant="contained"
          size="small"
        >
          <FormattedMessage id="user.group.detail.delete.delete_button" />
        </Button>
      </Paper>
      {
        isOpen && (
          <DeleteUserGroupPopup
            id={id}
            isOpen={isOpen}
            onCloseDialog={() => setIsOpen(false)}
            onSuccessCallback={goToListPage}
          />
        )
      }
    </>
  );
};

export default UserGroupDetailDelete;
