import Axios from 'axios';
import { UserInvitation, PageableResponse, DataResponse, UserInvitationDTO } from '../models';
import { composeQuery, Query } from '../models/query';

// get user invitation from k1
export const getUserInvitations = async (...criteria: Query<UserInvitation>[]) => (await Axios.get<PageableResponse<UserInvitation>>('/v1/user-invitations', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: composeQuery<UserInvitation>(...criteria),
  data: {},
})).data;

// get user invitations from kit
export const getKitUserInvitations = async (...criteria: Query<UserInvitation>[]) => (await Axios.get<PageableResponse<UserInvitation>>('/v1/testops-user-invitations', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: composeQuery<UserInvitation>(...criteria),
  data: {},
})).data;

// create invitation:
export const createInvitation = async (input: Required<Pick<UserInvitationDTO, 'invitedUserEmail'>>) => (await (Axios.post<DataResponse<UserInvitation>>('/v1/user-invitations', input))).data;

// delete user invitation from k1
export const deleteUserInvitation = async (input: Required<Pick<UserInvitation, 'id' | 'type'>>) => (await Axios.delete(`/v1/user-invitations/${input.id}`)).data.data;

// delete user invitation from kit
export const deleteKitUserInvitation = async (input: Required<Pick<UserInvitation, 'id'>>) => (await Axios.delete(`/v1/testops-user-invitations/${input.id}`)).data.data;

// update invitation k1
export const updateInvitation = async (input: Required<Pick<UserInvitation, 'id' | 'invitationToken'>>) => (await (Axios.put<DataResponse<UserInvitation>>(`/v1/user-invitations/${input.id}`, input))).data;
