import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { OrganizationRole } from '../models';
import { fromAuth, fromCurrentOrgUser, useAppDispatch } from '../store';
import { useQuery } from './useQuery';

export const useOrgUserAuthenticate = (
  accessibleOrgRoles?: OrganizationRole[],
  isRootOrAdminAccessible?: boolean,
) => {
  const user = useSelector(fromAuth.selectUser);
  const { get } = useQuery();
  const orgId = get('orgId');
  const dispatch = useAppDispatch();
  const isRootOrAdmin = useSelector(fromAuth.selectIsRootOrAdmin);
  const [isUserOrgAuthenticated, setIsUserOrgAuthenticated] = useState(false);

  useEffect(() => {
    const fetchInfo = async () => {
      if (user) {
        setIsUserOrgAuthenticated(false);
        dispatch(fromCurrentOrgUser.doGetOrgUserByUserIdAndOrgId({
          id: user.id,
          organizationId: Number(orgId),
        }))
          .then(unwrapResult)
          .then(currentOrgUser => {
            const orgUser = currentOrgUser.find(
              it => it.organizationId === Number(orgId) && it.user.email === user.email,
            );
            if ((isRootOrAdminAccessible && isRootOrAdmin)
              || (orgUser && accessibleOrgRoles?.includes(orgUser.role))) {
              setIsUserOrgAuthenticated(true);
            }
          });
      }
    };
    fetchInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.email, orgId, accessibleOrgRoles, isRootOrAdminAccessible]);
  return isUserOrgAuthenticated;
};
