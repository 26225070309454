import { yupResolver } from '@hookform/resolvers/yup';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { object, string } from 'yup';
import LoadingProgress from '../../../layout/LoadingProgress';
import { getAvatarSource, getFullNameUser } from '../../../models';
import { AuthService } from '../../../services';
import { fromAuth, fromOrganizations, fromOrganizationUsers, useAppDispatch } from '../../../store';
import { useLaunchDarkly } from '../../../launchdarkly';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    borderRadius: '1rem',
  },
  title: {
    padding: theme.spacing(3, 5, 2, 5),
  },
  content: {
    padding: theme.spacing(0, 5),
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  transferTo: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  avatar: {
    marginRight: theme.spacing(1),
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
  },
  userBox: {
    backgroundColor: '#FAFAFA',
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(0.75),
    marginBottom: theme.spacing(2.5),
  },
  actions: {
    padding: theme.spacing(0, 5, 3, 5),
    marginTop: theme.spacing(5),
  },
  actionsSpacing: {
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
  error: {
    color: theme.palette.error.main,
    margin: 0,
  },
  textEllipsis: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  captchaBox: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

interface TransferOwnershipDialogProps {
  organizationUserId: number;
  open: boolean;
  onClose: () => void;
}
interface TransferOwnershipFormProps {
  password: string;
}

const TransferOwnershipDialog = (props: TransferOwnershipDialogProps) => {
  const { organizationUserId, open, onClose } = props;
  const classes = useStyles();
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { flags } = useLaunchDarkly();
  const organizationUser = useSelector(fromOrganizationUsers.selectById(organizationUserId));
  const user = organizationUser?.user;
  const organization = organizationUser?.organization;
  const userName = getFullNameUser(user?.firstName, user?.lastName, user?.email || '');
  const currentUser = useSelector(fromAuth.selectUser);
  // @ts-ignore
  const allowSetPassword = JSON.parse(localStorage.getItem('k1.login')).user.allowSetPassword!! || false;
  const schema = object().shape({
    password: (flags?.setPasswordEnable && allowSetPassword) ? string() : string()
      .required(
        intl.formatMessage(
          { id: 'user_management.transfer_owner_ship.error.required' },
          { field: 'Password' },
        ),
      ),
  });
  const {
    register,
    handleSubmit,
    formState: { errors: inputErrors },
    clearErrors,
    setValue,
  } = useForm<TransferOwnershipFormProps>({
    defaultValues: { password: '' },
    resolver: yupResolver(schema),
  });

  const clearIncorrectPasswordError = () => {
    if (incorrectPassword) {
      setIncorrectPassword(false);
    }
  };

  const handleClose = () => {
    clearIncorrectPasswordError();
    clearErrors('password');
    setValue('password', '');
    onClose();
  };

  const handleSubmitTransfer = async (input: TransferOwnershipFormProps) => {
    if (!currentUser || !organization || !user) return;
    setLoading(true);
    if (!flags?.setPasswordEnable || !allowSetPassword) {
      try {
        await AuthService.login({
          email: currentUser.email,
          password: input.password,
        });
      } catch (_) {
        setIncorrectPassword(true);
        setLoading(false);
        return;
      }
    }
    try {
      await dispatch(fromOrganizations.doUpdateOrganization({
        id: organization.id,
        name: organization.name,
        ownerId: user!!.id,
      })).then(unwrapResult);
      enqueueSnackbar(
        <FormattedMessage
          id="user_management.transfer_owner_ship.success"
          values={{ newOwner: userName }}
        />,
        { variant: 'success' },
      );
      onClose();
      // We have several slice for organization & organizationUser
      // => reload the page to get new data would be better than update state
      window.location.reload();
    } catch (_) {
      enqueueSnackbar(
        <FormattedMessage
          id="user_management.transfer_owner_ship.failed"
          values={{ newOwner: userName }}
        />,
        { variant: 'error' },
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog maxWidth="sm" fullWidth classes={{ paper: classes.dialogPaper }} open={open}>
      <DialogTitle className={classes.title}>
        <FormattedMessage id="user_management.transfer_owner_ship.title" />
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography className={classes.marginBottom} variant="body2">
          <FormattedMessage id="user_management.transfer_owner_ship.subtitle" />
        </Typography>
        <Typography variant="body2">
          <FormattedMessage
            id="user_management.transfer_owner_ship.description"
            values={{
              organizationName: organizationUser?.organization.name,
              // eslint-disable-next-line react/no-unstable-nested-components
              b: (chunks: string[]) => <strong>{chunks}</strong>,
            }}
          />
        </Typography>
        <b>
          <Link
            underline="none"
            target="_blank"
            rel="noreferrer"
            href="https://docs.katalon.com/katalon-analytics/docs/testops-roles-privileges.html"
          >
            <FormattedMessage id="user_management.transfer_owner_ship.learn_organization_roles" />
          </Link>
        </b>
        <Typography className={classes.transferTo} variant="body2">
          <b><FormattedMessage id="user_management.transfer_owner_ship.transfer_to" /></b>
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          alignContent="center"
          className={classes.userBox}
        >
          <Avatar className={classes.avatar} src={getAvatarSource(user?.avatar)} />
          <Box className={classes.textEllipsis}>
            <Typography variant="body2">
              {userName}
            </Typography>
            <Typography variant="body2">
              {user?.email}
            </Typography>
          </Box>
        </Box>
        <form id="transfer-ownership-form" onSubmit={handleSubmit(handleSubmitTransfer)}>
          { (!flags?.setPasswordEnable || !allowSetPassword)
            && (
              <TextField
                className={classes.marginBottom}
                variant="outlined"
                fullWidth
                autoFocus
                size="small"
                type="password"
                name="password"
                autoComplete="current-password"
                placeholder={intl.formatMessage({
                  id: 'user_management.transfer_owner_ship.enter_password',
                })}
                inputProps={{ ...register('password'), maxlength: 255 }}
                error={!!inputErrors.password || incorrectPassword}
                helperText={inputErrors.password?.message}
                onFocus={() => setIncorrectPassword(false)}
                onChange={() => clearIncorrectPasswordError()}
              />
            )}
          {(incorrectPassword && !inputErrors.password
              && <Typography variant="caption" className={classes.error}><FormattedMessage id="error.incorrect_password" /></Typography>
            )}
        </form>
      </DialogContent>
      <DialogActions classes={{ root: classes.actions, spacing: classes.actionsSpacing }}>
        <Button size="small" variant="contained" onClick={() => handleClose()}>
          <FormattedMessage id="user_management.transfer_owner_ship.cancel" />
        </Button>
        <Button
          type="submit"
          size="small"
          color="secondary"
          variant="contained"
          form="transfer-ownership-form"
        >
          <FormattedMessage id="user_management.transfer_owner_ship.submit" />
        </Button>
      </DialogActions>
      {loading && <LoadingProgress />}
    </Dialog>
  );
};

export default TransferOwnershipDialog;
