import Link from '@mui/material/Link';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import PlatformWarningDialog from '../PlatformWarningDialog';
import { ReactComponent as WarningIcon } from '../../../../layout/icons/PlatformWarningIcon.svg';

const useStyles = makeStyles(theme => ({
  warningIcon: {
    width: theme.spacing(1.75),
    height: theme.spacing(1.75),
  },
  section: {
    display: 'flex',
    alignContent: 'flex-start',
    padding: theme.spacing(1.25),
    width: '100%',
    border: 'solid 1px #ffc043',
    backgroundColor: '#fffcf0',
    borderRadius: theme.spacing(0.75),
    alignItems: 'center',
  },
  bannerContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
  },
  message: {
    margin: theme.spacing(0, 1),
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    textAlign: 'left',
    color: '#233145',
  },
  link: {
    color: '#276ef1',
    cursor: 'pointer',
  },
}));

interface PlatformWarningBannerProps {
  subscriptionLink: string;
}

const PlatformWarningBanner = (props: PlatformWarningBannerProps) => {
  const { subscriptionLink } = props;
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const intl = useIntl();
  const handleCloseDialog = () => setDialogOpen(false);

  return (
    <div>
      <div className={classes.section}>
        <div className={classes.bannerContainer}>
          <WarningIcon className={classes.warningIcon} />
          <Typography className={classes.message} variant="body1">
            {intl.formatMessage({ id: 'dashboard.warning_banner.exceed.platform' }, {
              a: (data: ReactNode) => (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link
                  underline="none"
                  className={classes.link}
                  onClick={() => setDialogOpen(true)}
                >
                  {data}
                </Link>
              ),
            })}
          </Typography>
        </div>
      </div>
      <PlatformWarningDialog
        subscriptionLink={subscriptionLink}
        isOpen={dialogOpen}
        onClose={handleCloseDialog}
      />
    </div>
  );
};
export default PlatformWarningBanner;
