import axios, { AxiosError, AxiosResponse } from 'axios';
import { useEffect } from 'react';
import { Config } from '../models/config';

export const useResponseInterceptor = <T = any>(
  onFulfilled: (result: AxiosResponse<T>) => AxiosResponse,
  onRejected: (error: AxiosError<T>, config: Config | undefined)
  => Promise<AxiosResponse | AxiosError | null>,
  config: Config | undefined,
) => {
  useEffect(() => {
    if (!config) return;
    axios.interceptors.response.use(
      onFulfilled,
      async (responseError: AxiosError<T, any>) => {
        const result = await onRejected(responseError, config);
        if (result instanceof AxiosError) {
          return Promise.reject(result);
        }
        return Promise.resolve(result);
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);
};
