enum AccountRole {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  BILLING_MANAGER = 'BILLING_MANAGER',
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
namespace AccountRole {
  export const fromString = (str: string): AccountRole | undefined => (
    AccountRole as any)[str];
  export const getRoleFullName = (role: AccountRole | undefined) => {
    switch (role) {
      case AccountRole.OWNER:
        return 'Owner';
      case AccountRole.ADMIN:
        return 'Admin';
      case AccountRole.MEMBER:
        return 'Member';
      case AccountRole.BILLING_MANAGER:
        return 'Billing Manager';
      default:
        return 'Unknown';
    }
  };

  export const isAdminOrOwnerRole = (role: AccountRole | undefined) => (
    role === AccountRole.OWNER || role === AccountRole.ADMIN
  );
  export const isOwner = (role: AccountRole | undefined) => (role === AccountRole.OWNER);
  export const isAdmin = (role: AccountRole | undefined) => (role === AccountRole.ADMIN);
  export const isMember = (role: AccountRole | undefined) => (
    role === AccountRole.MEMBER
  );
  export const isBillingManager = (role: AccountRole | undefined) => (
    role === AccountRole.BILLING_MANAGER
  );
  export const isManager = (role: AccountRole | undefined) => (
    role === AccountRole.OWNER || role === AccountRole.ADMIN || role === AccountRole.BILLING_MANAGER
  );
  export const isBillingManagerOrOwner = (role: AccountRole | undefined) => (
    role === AccountRole.OWNER || role === AccountRole.BILLING_MANAGER
  );
}

export { AccountRole };

export const isAdminOrOwner = (role: AccountRole) => [
  AccountRole.ADMIN,
  AccountRole.OWNER,
].includes(role);

export const isAdmin = (role: AccountRole) => [
  AccountRole.ADMIN,
].includes(role);

export const isOwner = (role: AccountRole) => [
  AccountRole.OWNER,
].includes(role);

export const isBillingManager = (role: AccountRole) => [
  AccountRole.BILLING_MANAGER,
].includes(role);

export const isBillingManagerOrOwner = (role: AccountRole) => [
  AccountRole.BILLING_MANAGER,
  AccountRole.OWNER,
].includes(role);

export const isManager = (role: AccountRole) => [
  AccountRole.BILLING_MANAGER,
  AccountRole.ADMIN,
  AccountRole.OWNER,
].includes(role);
