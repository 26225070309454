import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { unwrapResult } from '@reduxjs/toolkit';
import { Account } from '../../models';
import LoadingProgress from '../../layout/LoadingProgress';
import {
  fromSecuritySetting,
  useAppDispatch,
} from '../../store';
import { IpRestrictions, SecuritySettings } from '../../models/securitySetting';

const useStyles = makeStyles(theme => ({
  header: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  container: {
    width: theme.spacing(57),
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(1.75),
  },
  description: {
    marginTop: theme.spacing(0.5),
    fontSize: theme.spacing(1.5),
    color: '#808b9a',
    lineHeight: 1.5,
  },
  input: {
    width: theme.spacing(77),
    marginLeft: theme.spacing(2.75),
    '& .MuiOutlinedInput-root': {
      fontSize: theme.spacing(1.75),
      lineHeight: '1.5rem',
      border: 'solid 1px #dbdde5',
      backgroundColor: 'white',
      borderRadius: theme.spacing(0.5),
    },
    '& .Mui-disabled': {
      cursor: 'no-drop',
      border: 'none !important',
    },
  },
  button: {
    marginTop: theme.spacing(2.5),
    fontSize: theme.spacing(1.75),
    paddingY: '0.5rem',
    lineHeight: '1.5rem',
    fontWeight: theme.typography.fontWeightMedium,
  },
  plan: {
    backgroundColor: '#fbc02d',
    color: '#233145',
    borderRadius: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    fontSize: theme.spacing(1.75),
    padding: theme.spacing(0.5),
    fontWeight: 'normal !important',
  },
}));

export interface AccountIpAddressRestrictionsProps {
  hasValidSubscription: boolean;
  securitySettings: SecuritySettings;
}
const AccountIpAddressRestrictions = (props: AccountIpAddressRestrictionsProps) => {
  const { hasValidSubscription, securitySettings } = props;
  const classes = useStyles();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [currentWhitelistIps, setCurrentWhitelistIps] = useState(securitySettings.ipsRestriction ?? '');
  const [updatingIp, setUpdatingIp] = useState(currentWhitelistIps);

  const {
    handleSubmit,
  } = useForm<Required<Pick<Account, 'id' | 'name'>>>({
    defaultValues: { name: '' },
  });

  const doUpdate = async () => {
    setLoading(true);

    const input: IpRestrictions = {
      ipRestriction: updatingIp.trim().length > 0 ? updatingIp : undefined,
    };

    await dispatch(
      fromSecuritySetting.doUpsertSecuritySettings({
        accountId: securitySettings.accountId,
        ipRestrictions: input,
      }),
    )
      .then(unwrapResult)
      .then(data => {
        parseWhiteListIpToString(data.data);
        enqueueSnackbar(
          intl.formatMessage(
            { id: 'security_setting.ip_address_restrictions.update.successfully' },
          ),
          { variant: 'success' },
        );
      })
      .catch(() => {
        enqueueSnackbar(
          intl.formatMessage({ id: 'security_setting.ip_address_restrictions.update.failed' }),
          { variant: 'error' },
        );
      })
      .finally(() => setLoading(false));
  };

  const handleChangeIpsRestriction: React.ChangeEventHandler<HTMLInputElement> = e => {
    setUpdatingIp(e.target.value);
  };

  const parseWhiteListIpToString = (data: SecuritySettings) => {
    setCurrentWhitelistIps(data.ipsRestriction ?? '');
  };

  return (
    <>
      <form onSubmit={handleSubmit(doUpdate)} id="security_setting.ip_address_restrictions.form">
        <Box display="block" padding={2} alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" width="100%">
            <Typography variant="subtitle2" className={classes.header}>
              <FormattedMessage id="security_setting.ip_address_restrictions.header" />
            </Typography>
            <Typography variant="subtitle2" className={`${classes.header} ${classes.plan}`}>
              <FormattedMessage id="security_setting.ultimate_plan" />
            </Typography>
          </Box>
          <Box display="flex">
            <Box display="block" className={classes.container}>
              <Typography variant="subtitle2" className={classes.title}>
                <FormattedMessage id="security_setting.ip_address_restrictions.title" />
              </Typography>
              <Typography variant="subtitle2" className={classes.description}>
                <FormattedMessage id="security_setting.ip_address_restrictions.description" />
              </Typography>
            </Box>
            <TextField
              id="security_setting.ip_address_restrictions.textfield"
              className={classes.input}
              variant="outlined"
              value={updatingIp}
              onChange={handleChangeIpsRestriction}
              disabled={!hasValidSubscription}
              multiline
              placeholder={intl.formatMessage({ id: 'security_setting.ip_address_restrictions.input.placeholder' })}
              rows={2}
            />
          </Box>
          <Button
            id="security_setting.ip_address_restrictions.button.update"
            variant="contained"
            color="primary"
            type="submit"
            sx={{
              '&.Mui-disabled': {
                background: '#686D80',
                color: '#c0c0c0',
              },
            }}
            disabled={updatingIp === currentWhitelistIps
              || !hasValidSubscription}
            className={classes.button}
          >
            <FormattedMessage id="security_setting.ip_address_restrictions.button.update" />
          </Button>
        </Box>
      </form>
      {loading && <LoadingProgress />}
    </>
  );
};

export default AccountIpAddressRestrictions;
