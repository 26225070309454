import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useConfig } from '../../../../config';
import { TestResultCount, UsagePlan } from '../../../../models';
import banner from './warning-banner.svg';

const useStyles = makeStyles(theme => ({
  warningBanner: {
    padding: theme.spacing(2),
  },
  section: {
    width: '100%',
    backgroundImage: `url(${banner})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    minHeight: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bannerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
  },
  message: {
    margin: theme.spacing(0, 1),
    fontWeight: theme.typography.fontWeightMedium,
  },
  upgradeButton: {
    minWidth: theme.spacing(12),
    margin: theme.spacing(0, 2),
  },
}));

interface WarningBannerProps {
  testResultCount: TestResultCount | null;
  subscriptionLink: string;
}

const WarningBanner = (props: WarningBannerProps) => {
  const { testResultCount, subscriptionLink } = props;
  const classes = useStyles();
  const intl = useIntl();
  const [isShowedBanner, setIsShowedBanner] = useState(false);
  const [usagePercent, setUsagePercent] = useState(0);
  const { config } = useConfig();

  useEffect(() => {
    if (testResultCount) {
      const { quota, count, canSubmit, hardLimit, usagePlan } = testResultCount;
      const calculatedusagePercent = count / quota;
      setUsagePercent(calculatedusagePercent);
      setIsShowedBanner(false);
      if (usagePlan === UsagePlan.PLATFORM) return;
      if (!canSubmit || (hardLimit === quota && calculatedusagePercent >= 0.9
        && calculatedusagePercent <= 1) || (count > quota && count < hardLimit)) {
        setIsShowedBanner(true);
      }
    }
  }, [testResultCount]);

  const getBannerMessage = () => {
    if (testResultCount) {
      const { quota, count, canSubmit, hardLimit } = testResultCount;
      if (canSubmit) {
        if ((usagePercent >= 0.9 && usagePercent <= 1) && hardLimit === quota) {
          return config?.onpremise
            ? intl.formatMessage({ id: 'op.dashboard.warning_banner.exceed.status.purple' }, {
              email: 'business@katalon.com',
              a: () => <a href="mailto:business@katalon.com">business@katalon.com</a>,
            })
            : intl.formatMessage({ id: 'dashboard.warning_banner.exceed.status.purple' });
        }
        if (count > quota && count < hardLimit) {
          return config?.onpremise
            ? intl.formatMessage({ id: 'op.dashboard.warning_banner.exceed.status.yellow' }, {
              email: 'business@katalon.com',
              a: () => <a href="mailto:business@katalon.com">business@katalon.com</a>,
            })
            : intl.formatMessage({ id: 'dashboard.warning_banner.exceed.status.yellow' });
        }
      }
      return config?.onpremise
        ? intl.formatMessage({ id: 'op.dashboard.warning_banner.exceed.status.red' }, {
          email: 'business@katalon.com',
          a: () => <a href="mailto:business@katalon.com">business@katalon.com</a>,
        })
        : intl.formatMessage({ id: 'dashboard.warning_banner.exceed.status.red' });
    }
    return null;
  };

  if (!config) return <></>;

  return isShowedBanner
    ? (
      <div className={classes.warningBanner}>
        <div className={classes.section}>
          <div className={classes.bannerContainer}>
            <Typography className={classes.message} variant="body1">
              {getBannerMessage()}
            </Typography>
            {!config.onpremise && (
              <Button
                component={Link}
                to={subscriptionLink}
                variant="contained"
                color="primary"
                className={classes.upgradeButton}
              >
                <Typography>
                  {intl.formatMessage({ id: 'dashboard.upgrade_button' })}
                </Typography>
              </Button>
            )}
          </div>
        </div>
      </div>
    )
    : (<></>);
};
export default WarningBanner;
