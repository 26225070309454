import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { fromStudioSubscriptions, fromSubscriptions, useAppDispatch } from '../../../../../store';
import BaseLicenseAllocationDetail from '../../base/BaseLicenseAllocationDetail';
import { useQuery } from '../../../../../routes';
import LoadingProgress from '../../../../../layout/LoadingProgress';
import { AccountUser } from '../../../../../models';
import { LicenseAllocationTab } from '../../../model/LicenseAllocationTab';
import BaseUpgradeLicenseView from '../../base/BaseUpgradeLicenseView';

interface KreLicenseAllocationProps {
  currentAccountUser: AccountUser | undefined,
}

const KreLicenseAllocation = (props: KreLicenseAllocationProps) => {
  const { currentAccountUser } = props;

  const dispatch = useAppDispatch();
  const query = useQuery();
  const intl = useIntl();

  const accountId = query.get('accountId');

  const [isDataFetched, setIsDataFetched] = useState(false);
  const [totalKreLicenses, setTotalKreLicenses] = useState(0);
  const [allocatedKreLicenses, setAllocatedKreLicenses] = useState(0);
  const [allocatedHybridKreLicenses, setAllocatedHybridKreLicenses] = useState(0);
  const [totalHybridKreLicenses, setTotalHybridKreLicenses] = useState(0);

  const kreLicenses = useSelector(
    fromStudioSubscriptions.selectPaidKreSubscriptionByAccountId(
      Number(accountId),
    ),
  );

  const hybridKreLicenses = useSelector(
    fromStudioSubscriptions.selectPaidHybridKreSubscriptionByAccountId(
      Number(accountId),
    ),
  );

  useEffect(() => {
    setAllocatedKreLicenses(0);
    setAllocatedHybridKreLicenses(0);
  }, []);

  useEffect(() => {
    setIsDataFetched(false);
    const fetchInfo = async () => {
      await Promise.all([
        dispatch(fromStudioSubscriptions.doGetActiveByAccountId(
          { accountId: Number(accountId) },
        )),
        dispatch(
          fromSubscriptions.doGetActiveRecurlySubscriptionsByAccountId(
            { id: Number(accountId) },
          ),
        ),
      ]);
      setIsDataFetched(true);
    };
    fetchInfo();
  }, [accountId]);

  useEffect(() => {
    if (kreLicenses.length === 0) setTotalKreLicenses(0);

    const total = kreLicenses.map(license => license.quota).reduce((a, b) => a + b, 0);
    setTotalKreLicenses(total);
  }, [kreLicenses]);

  useEffect(() => {
    if (hybridKreLicenses.length === 0) setTotalHybridKreLicenses(0);

    const total = hybridKreLicenses.map(license => license.quota).reduce((a, b) => a + b, 0);
    setTotalHybridKreLicenses(total);
  }, [hybridKreLicenses]);

  const adjustAllocatedKreLicense = (count: number) => {
    setAllocatedKreLicenses(allocatedKreLicenses + count);
  };

  const adjustAllocatedHybridKreLicense = (count: number) => {
    setAllocatedHybridKreLicenses(allocatedHybridKreLicenses + count);
  };

  return (
    <>
      {isDataFetched
        && totalKreLicenses === 0
        && totalHybridKreLicenses === 0
        && (
          <BaseUpgradeLicenseView
            accountId={Number(accountId)}
            accountUserRole={currentAccountUser?.role}
            licenseAllocationTab={LicenseAllocationTab.KRE}
          />
        )}
      {isDataFetched
        && (totalKreLicenses > 0 || totalHybridKreLicenses > 0)
        && (
          <BaseLicenseAllocationDetail
            currentAccountUser={currentAccountUser}
            licenseAllocationTab={LicenseAllocationTab.KRE}
            licenseDetails={[
              {
                title: intl.formatMessage({ id: 'licenses.allocation.tab.kse.kse_licenses.title' }),
                total: totalKreLicenses,
                available: totalKreLicenses - allocatedKreLicenses,
              },
              {
                title: intl.formatMessage({ id: 'licenses.allocation.tab.kse.hybrid_kse_licenses.title' }),
                total: totalHybridKreLicenses,
                available: totalHybridKreLicenses - allocatedHybridKreLicenses,
              },
            ]}
            adjustAllocatedKreLicense={adjustAllocatedKreLicense}
            adjustAllocatedHybridKseLicense={adjustAllocatedHybridKreLicense}
          />
        )}
      {!isDataFetched && (<LoadingProgress />)}
    </>
  );
};

export default KreLicenseAllocation;
