import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { resolvePath, sso as ssoPath } from '../../../routes';
import { useSwitchTheme } from '../../../theme';
import logoURL from './katalonLogo.svg';
import logoNoColorURL from './katalonLogoFullNoColor.svg';

const useStyles = makeStyles(theme => ({
  background: {
    height: 176,
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  navBar: {
    position: 'absolute',
    paddingTop: theme.spacing(2),
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: 'inherit',
    [theme.breakpoints.down('md')]: {
      backgroundColor: theme.palette.primary.dark,
      top: 0,
      position: 'fixed',
      padding: 0,
      color: theme.palette.getContrastText('#000000'),
    },
  },
  navRow: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    float: 'left',
    alignItems: 'center',
    textAlign: 'center',
    verticalAlign: 'middle',
    width: '100%',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
    },
  },
  leftNavBar: {
    [theme.breakpoints.down('md')]: {
      '& > :not(:first-child)': {
        display: 'none',
      },
    },
    display: 'flex',
  },
  rightNavBar: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    float: 'right',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'center',
    margin: 'auto 0',
    [theme.breakpoints.down('md')]: {
      '& > :not(:first-child)': {
        display: 'none',
      },
    },
  },
  logoItem: {
    marginRight: theme.spacing(5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logo: {
    width: 180,
    paddingTop: theme.spacing(0.5),
    [theme.breakpoints.down('md')]: {
      width: 100,
    },
  },
  profile: {
    whiteSpace: 'nowrap',
  },
  download: {
    whiteSpace: 'nowrap',
    border: 'solid 2px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRadius: theme.spacing(0.6),
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  menuButton: {
    float: 'right',
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
  },
  item: {
    height: '100%',
    '&:hover': {
      marginBottom: 12,
      borderBottom: 'solid 3px',
    },
  },
  drawer: {
    width: '100%',
  },
  menuHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    verticalAlign: 'middle',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  rightIcon: {
    float: 'right',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  root: {
    boxShadow: 'none',
    border: 'none',
    position: 'relative',
    '&.MuiAccordion-root:before': {
      backgroundColor: 'white',
    },
  },
}));

const NavBar = () => {
  const theme = useTheme();
  const classes = useStyles();
  const mediumWidth = useMediaQuery(theme.breakpoints.down('md'));
  const currentTheme = useSwitchTheme();
  const [openDrawer, setOpenDrawer] = useState(false);

  // eslint-disable-next-line no-nested-ternary
  const logoUrl = mediumWidth
    ? logoNoColorURL
    : currentTheme.currentTheme === 'dark'
      ? logoNoColorURL
      : logoURL;

  const logoUrlMobile = currentTheme.currentTheme === 'dark'
    ? logoNoColorURL
    : logoURL;
  return (
    <div className={classes.background}>
      <AppBar className={classes.navBar}>
        <Container disableGutters>
          <Box className={classes.navRow}>
            <Box className={classes.leftNavBar}>
              <ListItem className={classes.logoItem}>
                <Link
                  component={RouterLink}
                  to={resolvePath(
                    ssoPath,
                    undefined,
                    { redirect_uri: process.env.REACT_APP_WEBSITE_URL },
                    false,
                  )}
                >
                  <img className={classes.logo} src={logoUrl} alt="Katalon logo" />

                </Link>
              </ListItem>
            </Box>
            <Box className={classes.rightNavBar}>
              <Drawer
                classes={{ paper: classes.drawer }}
                anchor="left"
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
              >
                <Box className={classes.menuHeader}>
                  <ListItem className={classes.logoItem}>
                    <Link
                      href={resolvePath(
                        ssoPath,
                        undefined,
                        { redirect_uri: process.env.REACT_APP_WEBSITE_URL },
                      )}
                    >
                      <img className={classes.logo} src={logoUrlMobile} alt="Katalon logo" />
                    </Link>
                  </ListItem>
                </Box>
              </Drawer>
            </Box>
          </Box>
        </Container>
      </AppBar>
    </div>
  );
};

export default NavBar;
