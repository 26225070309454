import Axios from 'axios';
import { DataResponse, OrganizationUserSso, PageableResponse } from '../models';
import { composeQuery, Query } from '../models/query';

export const getOrganizationUserSsos = async (...criteria: Query<OrganizationUserSso>[]) => (await Axios.get<PageableResponse<OrganizationUserSso>>('/v1/testops-organization-user-ssos', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: composeQuery<OrganizationUserSso>(...criteria),
  data: {},
})).data;

export const addOption = async (input: Required<Pick<OrganizationUserSso, 'organizationUserId' | 'option'>>) => (await Axios.post<DataResponse<OrganizationUserSso>>('/v1/testops-organization-user-ssos', input)).data.data;

export const deleteById = async (input: Required<Pick<OrganizationUserSso, 'id'>>) => (await Axios.delete<DataResponse<OrganizationUserSso>>(`/v1/testops-organization-user-ssos/${input.id}`)).data.data;
