import InputAdornment from '@mui/material/InputAdornment';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';

import { ReactComponent as ClearIcon } from './clearIcon.svg';
import { ReactComponent as ConfirmIcon } from './confirmIcon.svg';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  formField: {
    width: '100%',
    '& .MuiOutlinedInput-input': {
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.25),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      color: '#172B4D',
      cursor: 'pointer',
    },
    '& .MuiOutlinedInput-root': {
      cursor: 'pointer',
      '&.MuiInputBase-formControl fieldset': {
        borderColor: '#00000000',
      },
      '&.MuiInputBase-formControl.Mui-focused fieldset': {
        borderColor: '#5294FF',
      },
      '&.MuiInputBase-formControl': {
        backgroundColor: 'transparent',
        border: '1px solid #D5D8DD',
        borderRadius: theme.spacing(1),
      },
      '&.MuiInputBase-formControl:hover': {
        backgroundColor: '#F4F5F7',
      },
      '&.Mui-focused.Mui-error fieldset': {
        borderColor: '#d32f2f',
      },
      '&.Mui-error fieldset': {
        borderColor: '#d32f2f',
      },
    },
  },
  icon: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
  },
  iconButtonInput: {
    padding: theme.spacing(0),
    minWidth: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  form: {
    flex: 1,
  },
}));

interface FormInput {
  text: string;
}

export interface EditableTextFieldProps {
  onSubmit: (value: string) => void;
  validationOptions: any;
  submitting: boolean;
  placeholder?: string;
  onCancelUpdateJobtitle: () => void;
}

const JobTitleTextField = (props: EditableTextFieldProps) => {
  const { onSubmit,
    validationOptions,
    placeholder,
    onCancelUpdateJobtitle,
    submitting } = props;
  const classes = useStyles();
  const [focus, setFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm<FormInput>({
    defaultValues: { text: '' },
  });

  const doSubmit = async ({ text }: FormInput) => {
    onSubmit(text);
    setFocus(false);
    // @ts-ignore
    inputRef?.current?.blur();
  };

  const handleFocusChange = () => {
    setFocus(true);
  };

  const handleBlurChange = () => {
    if (errors.text?.message) {
      return;
    }
    handleSubmit(() => doSubmit(getValues()))();
  };

  const handleCancel = () => {
    setFocus(false);
    // @ts-ignore
    inputRef?.current?.blur();
    setValue('text', '');
    clearErrors();
    onCancelUpdateJobtitle();
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
  };

  const handleOnKeyDownRegistered = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSubmit(() => doSubmit(getValues()))();
    } else if (event.key === 'Escape') {
      handleCancel();
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <form onSubmit={handleSubmit(doSubmit)} className={classes.form}>
      <TextField
        className={classes.formField}
        variant="outlined"
        inputRef={inputRef}
        onFocus={handleFocusChange}
        onBlur={handleBlurChange}
        disabled={submitting}
        size="small"
        autoComplete="off" // avoid background color change after autocomplete
        fullWidth
        name="fullName"
        onKeyDown={handleOnKeyDownRegistered}
        placeholder={placeholder}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {focus && (
                <div>
                  <Button
                    className={classes.iconButtonInput}
                    onClick={handleCancel}
                    onMouseDown={handleMouseDownPassword}
                    size="large"
                  >
                    <ClearIcon className={classes.icon} />
                  </Button>
                  <Button
                    type="submit"
                    className={classes.iconButtonInput}
                    size="large"
                    onMouseDown={handleMouseDownPassword}
                  >
                    <ConfirmIcon className={classes.icon} />
                  </Button>
                </div>
              )}
            </InputAdornment>
          ),
        }}
        inputProps={{
          ...register('text', validationOptions),
        }}
        error={!!errors?.text}
        helperText={focus && errors.text?.message}
      />
    </form>
  );
};

export default JobTitleTextField;
