import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useLaunchDarkly } from '../../../launchdarkly';
import { UserStatus } from '../../../models/userStatus';
import { fromAuth, fromUsers, useAppDispatch } from '../../../store';
import { getUserFullName, StyleProps } from '../utils';
import ChangePasswordDialog from './ChangePassword';
import EditableTextField from './EditableTextField';
import Verify from './Verify';
import JobTitleTextField from './JobTitleTextField';

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  root: {
    height: '100%',
    width: '100%',
    padding: theme.spacing(4),
  },
  update: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    display: 'flex',
    '& > *': {
      justifyContent: 'initial',
    },
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    minHeight: theme.spacing(10),
    alignItems: 'center',
    verticalAlign: 'middle',
    borderBottom: '1px solid #EEF1FA',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      '& > *': {
        width: '90vw',
      },
    },
    '&:last-child': {
      borderBottom: 0,
      paddingBottom: 0,
    },
  },
  value: {
    display: 'flex',
    flex: 'row',
    flexWrap: 'wrap',
    wordBreak: 'break-word',
    margin: 'auto 0',
    alignItems: 'center',
    color: '#172B4D',
    '& > img': {
      width: 20,
      height: 20,
    },
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  changePassword: {
    flexGrow: 1,
    float: 'right',
    margin: 'auto 0 auto auto',
    textAlign: 'right',
  },
  email: {
    wordBreak: 'break-word',
    [theme.breakpoints.down('sm')]: {
      width: ({ status }: StyleProps) => (status === UserStatus.NEW ? '80%' : '40%'),
    },
    flex: 1,
    color: '#172B4D',
  },
  verify: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  profileTitle: {
    fontSize: theme.spacing(2.5),
    color: '#516393',
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.2,
    marginTop: theme.spacing(5),
    width: '100%',
    height: 'auto',
  },
  subtitle2: {
    fontSize: theme.spacing(1.5),
    color: '#516393',
    textTransform: 'uppercase',
    lineHeight: 1,
  },
  nonEditable: {
    paddingLeft: theme.spacing(1),
  },
  selectJobTitleDropdown: {
    width: '100%',
  },
  jobTitleText: {
    marginTop: theme.spacing(1),
  },
  select: {
    maxHeight: theme.spacing(5.5),
    backgroundColor: 'transparent',
    '&:hover': {
      '&& fieldset': {
        borderColor: '#D5D8DD',
        backgroundColor: 'transparent',
      },
    },
    '&.MuiInputBase-formControl fieldset': {
      borderRadius: theme.spacing(1),
      borderColor: '#D5D8DD',
    },
    '&.MuiInputBase-formControl.Mui-focused fieldset': {
      borderColor: '#5294FF',
    },
    '&.MuiInputBase-formControl:hover': {
      backgroundColor: '#F4F5F7',
    },
  },
}));

interface BasicUserInformationProps {
  allowSetPassword: boolean,
}

const BasicUserInformation = (props: BasicUserInformationProps) => {
  const {
    allowSetPassword,
  } = props;
  const user = useSelector(fromAuth.selectUser);
  const intl = useIntl();
  const [submittingFullName, setSubmitingFullName] = useState(false);
  const [submittingJobTitle, setSubmitingJobTitle] = useState(false);
  const [isSelectJobTitleOther, setIsSelectJobTitleOther] = useState(false);
  const [jobTitle, setJobTitle] = useState(user?.jobTitle);
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { flags } = useLaunchDarkly();
  const MAX_LENGTH = 255;
  const jobTitleSelections = [
    intl.formatMessage({ id: 'profile.job_title.dropdown.dev_qa' }),
    intl.formatMessage({ id: 'profile.job_title.dropdown.team_lead' }),
    intl.formatMessage({ id: 'profile.job_title.dropdown.director_vp_cto' }),
    intl.formatMessage({ id: 'profile.job_title.dropdown.it_analyst_consultant' }),
    intl.formatMessage({ id: 'profile.job_title.dropdown.other' }),
  ];
  const classes = useStyles({ status: user?.status || '' });

  useEffect(() => {
    setJobTitle(user?.jobTitle);
  }, [user]);

  const doUpdateFullName = async (value: string) => {
    if (!user) {
      return;
    }
    setSubmitingFullName(true);
    const lastSpace: number = value.trim().lastIndexOf(' ');
    const firstName: string = (lastSpace > -1 ? value.substr(0, lastSpace) : value)
      .replace(/\s\s+/g, ' ')
      .trim();
    const lastName: string = (lastSpace > -1 ? value.substring(lastSpace + 1) : '').trim();
    const payload = {
      testingSolutions: user.testingSolutions,
      email: user.email,
      id: user.id,
      firstName,
      lastName,
      jobTitle: user.jobTitle,
    };
    await dispatch(
      fromUsers.doUpdateUser(payload),
    );
    enqueueSnackbar(<FormattedMessage id="profile.form.update.name.success" />, {
      variant: 'success',
      autoHideDuration: 2000,
    });
    setSubmitingFullName(false);
    dispatch(fromUsers.doUpdateHubSpot(payload));
  };

  const doUpdateJobTitle = async (value: string) => {
    if (!user || value.trim() === '') {
      return;
    }
    setSubmitingJobTitle(true);
    setIsSelectJobTitleOther(false);
    // update job title as pattern "Other - ..." if Other selected to sync with sign-up flow
    const newJobTitle = jobTitleSelections.includes(value.trim())
      ? value.trim()
      : intl.formatMessage({ id: 'profile.job_title.other_pattern' }, { jobTitle: value.trim() });
    const payload = {
      testingSolutions: user.testingSolutions,
      email: user.email,
      id: user.id,
      firstName: user?.firstName,
      lastName: user?.lastName,
      jobTitle: newJobTitle,
    };
    dispatch(
      fromUsers.doUpdateUser(payload),
    );
    enqueueSnackbar(<FormattedMessage id="profile.form.update.job.success" />, {
      variant: 'success',
      autoHideDuration: 2000,
    });
    setSubmitingJobTitle(false);
    dispatch(fromUsers.doUpdateHubSpot(payload));
    setJobTitle(newJobTitle);
  };

  const handleChange = (event: SelectChangeEvent) => {
    const newJobTitle = event.target.value;
    setJobTitle(newJobTitle);
    if (newJobTitle !== intl.formatMessage({ id: 'profile.job_title.dropdown.other' })) {
      doUpdateJobTitle(newJobTitle);
    } else {
      setIsSelectJobTitleOther(true);
    }
  };

  const handleCancelUpdateJobtitle = () => {
    setIsSelectJobTitleOther(false);
    setJobTitle(user?.jobTitle);
  };

  return (
    <Box className={classes.root}>
      <Grid container className={classes.container}>
        <Box className={classes.row}>
          <Grid item sm={3} md={3} xl={3}>
            <Typography variant="h2" className={classes.profileTitle}>
              <FormattedMessage id="profile.card.title" />
            </Typography>
          </Grid>
        </Box>
        <Box className={classes.row}>
          <Grid item sm={3} md={3} xl={3}>
            <Typography variant="subtitle2" className={classes.subtitle2}>
              <FormattedMessage id="profile.card.fullname" />
            </Typography>
          </Grid>
          <EditableTextField
            onSubmit={doUpdateFullName}
            initialValue={getUserFullName(user)}
            submitting={submittingFullName}
            validationOptions={{
              required: intl.formatMessage({ id: 'error.field.required' }, { field: 'NAME' }),
              maxLength: {
                message: intl.formatMessage({ id: 'error.maxlength' }, { length: MAX_LENGTH }),
                value: MAX_LENGTH,
              },
              pattern: {
                value: /^[^0-9`~!@#$%^&*()_+={}[\]|\\:;"'/<,>.?๐฿]*$/,
                message: intl.formatMessage({ id: 'error.name.invalid' }),
              },
              validate: (value: string) => value.trim() !== '' || intl.formatMessage({ id: 'error.notblank' }),
            }}
          />
        </Box>
        <Box className={classes.row}>
          <Grid item sm={3} md={3} xl={3}>
            <Typography variant="subtitle2" className={classes.subtitle2}>
              <FormattedMessage id="profile.card.email" />
            </Typography>
          </Grid>
          <Grid className={clsx(classes.value, classes.nonEditable)} item sm={9} md={9} xl={9}>
            <Typography className={classes.email} variant="body1">
              {user?.email}
            </Typography>
            {user?.status === UserStatus.NEW && <Verify />}
          </Grid>
        </Box>
        <Box className={classes.row}>
          <Grid item sm={3} md={3} xl={3}>
            <Typography variant="subtitle2" className={classes.subtitle2}>
              <FormattedMessage id="profile.card.password" />
            </Typography>
          </Grid>
          <Grid className={clsx(classes.value, classes.nonEditable)} item sm={9} md={9} xl={9}>
            <Typography className={classes.email} variant="body1">
              <FormattedMessage id="profile.card.password.display" />
            </Typography>
            <div className={classes.changePassword}>
              { (!flags?.setPasswordEnable || !allowSetPassword)
                && <ChangePasswordDialog />}
            </div>
          </Grid>
        </Box>
        <Box className={classes.row}>
          <Grid item sm={3} md={3} xl={3}>
            <Typography variant="subtitle2" className={classes.subtitle2}>
              <FormattedMessage id="profile.card.jobtitle" />
            </Typography>
          </Grid>
          <Grid item sm={9} md={9} xl={9}>
            <Box className={classes.selectJobTitleDropdown}>
              <FormControl fullWidth className={classes.formItem}>
                <Select
                  className={classes.select}
                  value={jobTitle}
                  onChange={handleChange}
                  displayEmpty
                  renderValue={selected => {
                    if (!selected || selected?.length === 0) {
                      return intl.formatMessage({ id: 'profile.job_title.dropdown.placeholder' });
                    }
                    return selected;
                  }}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  {
                    jobTitleSelections.map(it => (
                      <MenuItem key={it} value={it}>{it}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
              {
                (jobTitle === intl.formatMessage({ id: 'profile.job_title.dropdown.other' }) && isSelectJobTitleOther) ? (
                  <div className={classes.jobTitleText}>
                    <JobTitleTextField
                      placeholder={intl.formatMessage({ id: 'profile.job_title.other.placeholder' })}
                      onSubmit={doUpdateJobTitle}
                      submitting={submittingJobTitle}
                      validationOptions={{
                        maxLength: {
                          message: intl.formatMessage({ id: 'error.maxlength' }, { length: MAX_LENGTH }),
                          value: MAX_LENGTH,
                        },
                        required: intl.formatMessage({ id: 'error.field.required' }, { field: 'JOB TITLE' }),
                        validate: (value: string) => value.trim() !== '' || intl.formatMessage({ id: 'error.notblank' }),
                      }}
                      onCancelUpdateJobtitle={handleCancelUpdateJobtitle}
                      // The Katalon website adds backslash as an escape character
                      // when sending request. This is just a temporary solution to improve UX
                    />
                  </div>
                ) : null
              }
            </Box>
          </Grid>
        </Box>
        <Box className={classes.row}>
          <Grid item sm={3} md={3} xl={3}>
            <Typography variant="subtitle2" className={classes.subtitle2}>
              <FormattedMessage id="profile.card.registereddate" />
            </Typography>
          </Grid>
          <Grid className={clsx(classes.value, classes.nonEditable)} item sm={9}>
            <Typography variant="body1">
              <FormattedDate value={user?.createdAt} year="numeric" month="long" day="2-digit" />
            </Typography>
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
};

export default BasicUserInformation;
