import sortBy from 'lodash/sortBy';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import LoadingProgress from '../../layout/LoadingProgress';
import { projectList } from '../../layout/routes';
import { resolvePath, sso, useNavigate, useQuery } from '../../routes';
import { fromAuth, fromOrganizations, fromTestOpsProject, useAppDispatch } from '../../store';

const OrgHomePage = () => {
  const { get } = useQuery();
  const dispatch = useAppDispatch();
  const { replace, replaceQuery } = useNavigate();

  const [orgId, setOrgId] = useState(get('orgId'));

  const user = useSelector(fromAuth.selectUser);
  const organizations = useSelector(fromOrganizations.selectAllOrganizations);
  const isGetOrganizationsByUserIdLoading = useSelector(
    fromOrganizations.selectLoadingGetOrganizationsByUserId,
  );
  const isFetchRecentProjectLoading = useSelector(fromTestOpsProject
    .selectFetchRecentProjectLoading);
  const recentAccessedProject = useSelector(fromTestOpsProject.selectRecentAccessProject);

  useEffect(() => {
    if (!orgId && user) {
      // use === false in case first time is undefined
      dispatch(
        fromTestOpsProject.doGetRecentProjectByUserEmailAndOrgs({
          userEmail: user.email,
          organizations,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  useEffect(() => {
    if (
      !orgId
      && isGetOrganizationsByUserIdLoading === false
      && isFetchRecentProjectLoading === false
    ) {
      // use loading in organization slices to make sure we're done fetching organizations
      if (organizations.length === 0) {
        replace(
          sso.path,
          replaceQuery({
            redirect_uri: `${process.env.REACT_APP_ADMIN_URL}/home/create-organization`,
          }),
        );
        return;
      }

      const mostRecentOrganizationId = recentAccessedProject?.team.organizationId
        || sortBy(organizations, ['createdAt']).slice(-1)[0].id;
      setOrgId(mostRecentOrganizationId.toString());
      replace(undefined, replaceQuery({ orgId: mostRecentOrganizationId }));
    }
  }, [orgId, isGetOrganizationsByUserIdLoading, isFetchRecentProjectLoading]);

  if (orgId) {
    return (
      <Navigate to={resolvePath(projectList, undefined, { orgId }, false)} />
    );
  }

  if (isGetOrganizationsByUserIdLoading || isFetchRecentProjectLoading) {
    return (
      <LoadingProgress />
    );
  }

  return null;
};

export default OrgHomePage;
