export enum PlanPrice {
  KSE_MONTHLY = 208,
  KSE_ANNUALLY = 1999,
  KRE_MONTHLY = 166,
  KRE_ANNUALLY = 1599,
  TESTCLOUD_MONTHLY = 192,
  TESTCLOUD_ANNUALLY = 1849,
  TESTCLOUD_DESKTOP_MONTHLY = 192,
  TESTCLOUD_DESKTOP_ANNUALLY = 1849,
  TESTCLOUD_CROSS_BROWSER_MONTHLY = 192,
  TESTCLOUD_CROSS_BROWSER_ANNUALLY = 1849,
  TESTCLOUD_MOBILE_MONTHLY = 192,
  TESTCLOUD_MOBILE_ANNUALLY = 1849,
  VISUAL_TESTING_PRO_MONTHLY = 1.4,
  VISUAL_TESTING_PRO_ANNUALLY = 14.28,
  PLATFORM_3500_ANNUALLY = 399,
}
