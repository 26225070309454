export enum Operator {
  EQ = 'EQ',
  NE = 'NE',
  LT = 'LT',
  LE = 'LE',
  GT = 'GT',
  GE = 'GE',
  IN = 'IN',
  NI = 'NI',
  SEARCH = 'SEARCH',
}

export enum Statistic {
  SUM = 'SUM',
  AVG = 'AVG',
  COUNT = 'COUNT',
  MAX = 'MAX',
  MIN = 'MIN',
  LEAST = 'LEAST',
  GREATEST = 'GREATEST',
}

export enum IntervalUnit {
  MILLIS = 'MILLIS',
  SECONDS = 'SECONDS',
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS',
}

export interface Filter<T = unknown> {
  field: keyof T;
  operator: Operator;
  value: any,
}

interface Sort<T = unknown> {
  field: keyof T;
  desc: boolean;
}

interface Fetch {
  fetch: string;
}

interface Offset {
  offset: number;
}

interface Limit {
  limit: number;
}

interface Select<T = unknown> {
  field: keyof T;
  statistic: Statistic;
}

interface MetricGroupBy {
  startTime: number;
  endTime: number;
  interval: number;
  unit: IntervalUnit;
  timezone: string;
}

interface StatisticGroupBy<T = unknown> {
  group: keyof T;
}

function isSort(arg: any): arg is Sort {
  return arg.desc !== undefined;
}

function isFilter(arg: any): arg is Filter {
  return arg.operator !== undefined;
}

function isFetch(arg: any): arg is Fetch {
  return arg.fetch !== undefined;
}

function isOffset(arg: any): arg is Offset {
  return arg.offset !== undefined;
}

function isLimit(arg: any): arg is Limit {
  return arg.limit !== undefined;
}

function isSelect(arg: any): arg is Select {
  return arg.statistic !== undefined;
}

function isMetricGroupBy(arg: any): arg is MetricGroupBy {
  return arg.unit !== undefined;
}

function isStatisticGroupBy(arg: any): arg is StatisticGroupBy {
  return arg.group !== undefined;
}

export type Query<T = unknown> = Filter<T> | Sort<T> | Fetch | Offset | Limit;

export type MetricQuery<T = unknown> = Filter<T> | Sort<T> | Fetch | Offset | Limit
| Select<T> | MetricGroupBy;

export type StatisticQuery<T = unknown> = Filter<T> | Offset | Limit | Select<T>
| StatisticGroupBy<T>;

/**
 *
 * @template T: Resource Type
 * @param {...Query<T>[]} criteria: Predicates
 * @example
 * composeQuery<Organization>(
 *   { field: 'id', operator: Operator.GT, value: '10' },
 *   { field: 'name', desc: true },
 *   { fetch: 'user'},
 *   { limit: 10 },
 *   { offset: 20 },
 * )
 *
 */
export const composeQuery = <T>(...criteria: Query<T>[]) => ({
  query: JSON.stringify({
    filters: criteria.filter(isFilter),
    sorts: criteria.filter(isSort),
    fetches: criteria.filter(isFetch).map(arg => arg.fetch),
    ...{
      offset: criteria.filter(isOffset).map(arg => arg.offset)?.[0] ?? undefined,
      limit: criteria.filter(isLimit).map(arg => arg.limit)?.[0] ?? undefined,
    },
  }),
});

export const composeMetricQuery = <T>(...criteria: MetricQuery<T>[]) => ({
  query: JSON.stringify({
    filters: criteria.filter(isFilter),
    select: criteria.filter(isSelect)?.[0] ?? undefined,
    groupBy: criteria.filter(isMetricGroupBy)?.[0] ?? undefined,
    offset: criteria.filter(isOffset).map(arg => arg.offset)?.[0] ?? undefined,
    limit: criteria.filter(isLimit).map(arg => arg.limit)?.[0] ?? undefined,
  }),
});

export const composeStatisticQuery = <T>(...criteria: StatisticQuery<T>[]) => ({
  query: JSON.stringify({
    filters: criteria.filter(isFilter),
    select: criteria.filter(isSelect)?.[0] ?? undefined,
    groupBy: criteria.filter(isStatisticGroupBy)?.[0] ?? undefined,
    offset: criteria.filter(isOffset).map(arg => arg.offset)?.[0] ?? undefined,
    limit: criteria.filter(isLimit).map(arg => arg.limit)?.[0] ?? undefined,
  }),
});
