import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import { useEffect, VFC } from 'react';
import { FormattedMessage } from 'react-intl';
import { validate as uuidValidate } from 'uuid';
import { AuthService } from '../../services';
import { fromAuth, useAppDispatch } from '../../store';
import { sso, useNavigate, useQuery } from '../../routes';
import { TOKEN } from '../../routes/useAuthenticate';

const Activate: VFC = () => {
  const dispatch = useAppDispatch();
  const { redirect, navigate, replaceQuery } = useNavigate();
  const { queryDictionary, clear, get } = useQuery();
  const activationCode = get('activation_code');
  const { enqueueSnackbar } = useSnackbar();
  const currentToken = localStorage.getItem(TOKEN);

  useEffect(() => {
    (async () => {
      if (activationCode) {
        clear('activation_code');

        // if not is a valid uuid, redirect to '/'
        if (!uuidValidate(activationCode)) {
          enqueueSnackbar(<FormattedMessage id="activate.invalid_link" />, { variant: 'error' });
          navigate('/', replaceQuery(queryDictionary()));
          return;
        }

        try {
          const token = await AuthService.activate(activationCode);
          await dispatch(fromAuth.doResume(token)).then(unwrapResult);
          navigate(sso.path, replaceQuery({ redirect_uri: `${process.env.REACT_APP_WEBSITE_URL}/verify-email?status=succeed` }));
        } catch (error) {
          if (currentToken) {
            navigate(sso.path, replaceQuery({ redirect_uri: `${process.env.REACT_APP_WEBSITE_URL}/verify-email?status=failed` }));
          } else {
            redirect(`${process.env.REACT_APP_WEBSITE_URL}/verify-email?status=failed`);
          }
        }
      } else {
        navigate('/', replaceQuery(queryDictionary()));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
export default Activate;
