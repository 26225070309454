import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { resolvePath, welcome } from '../../routes';
import BasicUserInformation from './components/BasicUserInformation';
import DeleteAccountDialog from './components/DeleteAccountDialog';
import LogoutDialog from './components/LogoutDialog';
import NavBar from './components/NavBar';
import UploadAvatar from './components/UploadAvatar';
import { useLaunchDarkly } from '../../launchdarkly';
import { useConfig } from '../../config';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'auto',
    paddingTop: theme.spacing(8),
    backgroundColor: '#F0F3FB', // TODO move to theme
  },
  basicInfoWrapper: {
    width: '100%',
    margin: 'auto',
    maxWidth: 940,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'grid',
    alignItems: 'flex-start',
  },
  gotoWelcomeButton: {
    color: '#233145',
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(-3.5),
  },
  card: {
    gridArea: '1/1',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 940,
    borderRadius: 8,
    boxShadow: '0px 65px 49px -50px rgba(0, 17, 65, 0.05)',
  },
  cardContent: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
  },
  buttons: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(3),
    maxWidth: 940,
    verticalAlign: 'middle',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(10),
    width: '100vw',
  },
  avatarWrapper: {
    gridArea: '1/1',
    marginTop: theme.spacing(-9),
  },
  buttonGoToWelcomePageContent: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  avatarContent: {},
}));

const ProfileComponent = () => {
  const classes = useStyles();
  const { flags } = useLaunchDarkly();
  // @ts-ignore
  const allowSetPassword = JSON.parse(localStorage.getItem('k1.login')).user.allowSetPassword!! || false;
  const { config } = useConfig();

  return (
    <div className={classes.root}>
      <NavBar />
      <div className={classes.basicInfoWrapper}>
        <div className={classes.avatarWrapper}>
          <div className={classes.avatarContent}>
            <Button
              id="profile.go_to_welcome"
              className={classes.gotoWelcomeButton}
              startIcon={<ArrowBackIcon />}
              component={RouterLink}
              to={resolvePath(welcome, undefined, {}, false)}
            >
              <Typography className={classes.buttonGoToWelcomePageContent}>
                <FormattedMessage id="profile.go_to_welcome" />
              </Typography>
            </Button>
            <UploadAvatar />
          </div>
        </div>
        <Card className={classes.card}>
          <CardContent classes={{ root: classes.cardContent }}>
            <Grid container>
              <BasicUserInformation allowSetPassword={allowSetPassword} />
            </Grid>
          </CardContent>
        </Card>
      </div>
      <Box className={classes.buttons}>
        {((!flags?.setPasswordEnable || !allowSetPassword) && !config?.onpremise)
          && <DeleteAccountDialog />}
        <LogoutDialog />
      </Box>
    </div>
  );
};

export default ProfileComponent;
