import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import { differenceInCalendarDays } from 'date-fns';
import chunk from 'lodash/chunk';

import { AppState } from '.';
import { Organization, KsSession, KsSessionView } from '../models';
import { OrganizationFeature } from '../models/organizationFeature';
import { Filter, IntervalUnit, Operator, Query, Statistic } from '../models/query';
import { LicenseUsageService, KsSessionService } from '../services';

export const LICENSE_UTILIZATION_FEATURE_KEY = 'license-utilization';

export interface LicenseUsage {
  startTime: Date,
  endTime: Date,
  feature: OrganizationFeature;
  duration: number; // value
  unit: IntervalUnit;
}

interface LicenseUtilizationState extends EntityState<KsSession> {
  filteredLicenseUsage: LicenseUsage[] | null;
  allSessions: KsSession[] | null; // for export csv
  loading: boolean;
  error?: string;
  count: number;
}

const MAX_NUMBER_OF_RECORDS = 50;

const ksSessionsAdapter = createEntityAdapter<KsSession>();

export const createInitialState = (): LicenseUtilizationState => ksSessionsAdapter.getInitialState({
  allSessions: null,
  filteredLicenseUsage: null,
  loading: false,
  count: 0,
});

export const doGetLicenseUsageByFilters = createAsyncThunk(
  'license-utilization/getAllLicenseUsage',
  async (input: {
    organizationId: Organization['id'],
    startDate: Date,
    endDate: Date,
    filters: Filter<KsSession>[],
  }) => {
    const { startDate, endDate, organizationId, filters } = input;
    const { timeZone } = new Intl.DateTimeFormat().resolvedOptions();
    const featureFilterIndex = filters.findIndex(it => it.field === 'feature');
    const filtersWithoutFeature = [...filters];
    if (featureFilterIndex !== -1) filtersWithoutFeature.splice(featureFilterIndex, 1);
    const featureFilter = ((featureFilterIndex !== -1) ? filters[featureFilterIndex] : undefined)
      ?.value?.map((it: any) => OrganizationFeature.fromString(it.toString()))
      ?.filter((it: OrganizationFeature | undefined) => it !== undefined);
    const finalist: OrganizationFeature[] = (!featureFilter || featureFilter.length === 0) ? [
      OrganizationFeature.KSE,
      OrganizationFeature.UNLIMITED_KSE,
      OrganizationFeature.ENGINE,
      OrganizationFeature.UNLIMITED_ENGINE,
      OrganizationFeature.PER_USER_KSE,
    ] : featureFilter;
    let unit = IntervalUnit.MONTHS;
    let firstIntervalData: LicenseUsage[] = [];
    let newStartDate = startDate;
    if (differenceInCalendarDays(endDate, startDate) <= 1) {
      // e.g. 01/01 - 01/01 is selected
      unit = IntervalUnit.HOURS;
    } else if (differenceInCalendarDays(endDate, startDate) <= 14) {
      // e.g. 01/01 - 14/01 is selected
      // note that [startDate, endDate) is an exclusive range, therefore endDate would
      // actually be 15/01 00:00:00, making the difference 14
      unit = IntervalUnit.DAYS;
    } else {
      // e.g. 01/01 - 15/01 is selected
      const beginOfNextMonthOfStart = new Date(
        startDate.getFullYear(),
        startDate.getMonth() + 1,
        1,
        0,
        0,
        0,
      );
      if (beginOfNextMonthOfStart.getTime() < endDate.getTime()) {
        const requests = finalist.map(feature => LicenseUsageService.getLicenseUsage(
          { field: 'organizationId', operator: Operator.EQ, value: organizationId },
          { field: 'duration', statistic: Statistic.SUM },
          {
            field: 'createdAt',
            startTime: startDate.getTime(),
            endTime: beginOfNextMonthOfStart.getTime(),
            interval: 1,
            unit,
            timezone: timeZone,
          },
          { field: 'feature', operator: Operator.EQ, value: feature },
          ...filtersWithoutFeature,
        ));
        firstIntervalData = (await Promise.all(requests))
          .map((row, i) => row.map(item => ({
            startTime: item.startTime,
            endTime: item.endTime,
            feature: finalist[i],
            duration: item.value ?? 0,
            unit: IntervalUnit.MONTHS,
          })))
          .flat();
        newStartDate = new Date(beginOfNextMonthOfStart);
      }
    }
    const requests = finalist.map(feature => LicenseUsageService.getLicenseUsage(
      { field: 'organizationId', operator: Operator.EQ, value: organizationId },
      { field: 'duration', statistic: Statistic.SUM },
      {
        field: 'createdAt',
        startTime: newStartDate.getTime(),
        endTime: endDate.getTime(),
        interval: 1,
        unit,
        timezone: timeZone,
      },
      { field: 'feature', operator: Operator.EQ, value: feature },
      ...filtersWithoutFeature,
    ));
    const licenseUsage: LicenseUsage[] = (await Promise.all(requests))
      .map((row, i) => row.map(item => ({
        startTime: item.startTime,
        endTime: item.endTime,
        feature: finalist[i],
        duration: item.value ?? 0,
        unit,
      })))
      .flat();
    return [...firstIntervalData, ...licenseUsage];
  },
);

export const doGetKsSessions = createAsyncThunk(
  'license-utilization/getKsSessions',
  async ({ offset, limit, startDate, endDate, organizationId, filters }: { offset: number, limit: number, startDate: Date, endDate: Date, organizationId: Organization['id'], filters: Filter<KsSession>[] }) => {
    const criteria: Array<Query<KsSession>> = [
      ...filters,
      { field: 'organizationId', operator: Operator.EQ, value: organizationId },
      { field: 'endTime', operator: Operator.GE, value: startDate.getTime() },
      { field: 'endTime', operator: Operator.LT, value: endDate.getTime() },
      { field: 'startTime', desc: true },
      { offset },
      { limit },
    ];
    const ksSessions = await KsSessionService.getKsSessions(...criteria);
    return { ksSessions: ksSessions.data, count: ksSessions.total };
  },
);

export const doGetAllKsSessions = createAsyncThunk(
  'license-utilization/getAllKsSessions',
  async (input: { startDate: Date, endDate: Date, organizationId: Organization['id'], filters: Filter<KsSessionView>[] }) => {
    const criteria: Array<Query<KsSession>> = [
      ...input.filters,
      { field: 'organizationId', operator: Operator.EQ, value: input.organizationId },
      { field: 'endTime', operator: Operator.GE, value: input.startDate.getTime() },
      { field: 'endTime', operator: Operator.LT, value: input.endDate.getTime() },
      { field: 'startTime', desc: true },
    ];
    const firstResponse = await KsSessionService.getKsSessions(...criteria);
    const numberOfRequests = Math.ceil(firstResponse.total / MAX_NUMBER_OF_RECORDS);
    const remainingRequestParams = [];
    for (let i = 1; i < numberOfRequests; i += 1) {
      remainingRequestParams.push({
        ...criteria,
        offset: MAX_NUMBER_OF_RECORDS * i,
      });
    }
    const all = [...firstResponse.data];
    const chunks = chunk(remainingRequestParams, 10);
    // eslint-disable-next-line
    for (const chunk of chunks) {
      // eslint-disable-next-line
      const chunkResponses = await Promise.all(chunk.map(params => {
        return KsSessionService.getKsSessions(params);
      }));

      chunkResponses.forEach(it => all.push(...it.data));
    }
    return all;
  },
);

const licenseUtilizationSlice = createSlice({
  name: LICENSE_UTILIZATION_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doGetLicenseUsageByFilters.pending, state => {
      state.filteredLicenseUsage = null;
      state.loading = true;
      state.error = '';
    });
    builder.addCase(doGetLicenseUsageByFilters.fulfilled, (state, action) => {
      state.loading = false;
      state.filteredLicenseUsage = action.payload;
    });
    builder.addCase(doGetLicenseUsageByFilters.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.filteredLicenseUsage = null;
    });

    builder.addCase(doGetKsSessions.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetKsSessions.fulfilled, (state, action) => {
      ksSessionsAdapter.removeAll(state);
      state.loading = false;
      state.count = action.payload.count;
      ksSessionsAdapter.addMany(state, action.payload.ksSessions);
    });
    builder.addCase(doGetKsSessions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(doGetAllKsSessions.pending, state => {
      state.allSessions = null;
      state.loading = true;
      state.error = '';
    });
    builder.addCase(doGetAllKsSessions.fulfilled, (state, action) => {
      state.loading = false;
      state.allSessions = action.payload;
    });
    builder.addCase(doGetAllKsSessions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.allSessions = null;
    });
  },
});

const selectLicenseUtilizationFeature = (state: AppState) => state[LICENSE_UTILIZATION_FEATURE_KEY];
const { selectAll } = ksSessionsAdapter.getSelectors(selectLicenseUtilizationFeature);

export const selectFilteredLicenseUsage = createSelector(
  selectLicenseUtilizationFeature,
  licenseUtilizationState => licenseUtilizationState.filteredLicenseUsage,
);
export const selectLoading = createSelector(
  selectLicenseUtilizationFeature,
  licenseUtilizationState => licenseUtilizationState.loading,
);
export const selectError = createSelector(
  selectLicenseUtilizationFeature,
  licenseUtilizationState => licenseUtilizationState.error,
);

export const selectCount = createSelector(
  selectLicenseUtilizationFeature,
  licenseUtilizationState => licenseUtilizationState.count,
);

export const selectAllKsSessions = createSelector(
  selectLicenseUtilizationFeature,
  licenseUtilizationState => licenseUtilizationState.allSessions,
);

export const selectCurrentPageKsSessions = selectAll;

export default licenseUtilizationSlice.reducer;
