import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { RouteProps } from 'react-router-dom';

import { RouteConfig } from './index';

const DEFAULT_PAGE_TITLE = 'app.page.title';

export type RouteWithPageTitleProps = RouteProps & {
  pageTitle?: RouteConfig['pageTitle'];
};

const RouteWithPageTitle = (props: RouteWithPageTitleProps) => {
  const { pageTitle, element } = props;
  const intl = useIntl();
  useEffect(() => {
    document.title = intl.formatMessage({ id: pageTitle || DEFAULT_PAGE_TITLE });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // set only once
  return <>{element}</>;
};

export default RouteWithPageTitle;
