import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteConfig } from '.';
import { AccountUser } from '../models';
import { fromAccountUsers, fromAuth, useAppDispatch } from '../store';
import { useQuery } from './useQuery';

export const useAccountAuthenticate = (
  props: RouteConfig,
) => {
  const { isRootOrAdminAccessible, accessibleAccountRoles } = props;
  const user = useSelector(fromAuth.selectUser);
  const { get } = useQuery();
  const accountId = get('accountId');
  const dispatch = useAppDispatch();
  const isRootOrAdmin = useSelector(fromAuth.selectIsRootOrAdmin);
  const [isAccountAuthenticated, setIsAccountAuthenticated] = useState(false);
  const [accountUser, setAccountUser] = useState<AccountUser | null | undefined>(null);
  const [currentAccountId, setCurrentAccountId] = useState<string | null>(null);

  useEffect(() => {
    const fetchInfo = async () => {
      if (user) {
        setIsAccountAuthenticated(false);
        await dispatch(fromAccountUsers.doGetByUserIdAndAccountId({
          userId: user?.id,
          accountId: Number(accountId),
        }))
          .then(unwrapResult)
          .then(currentAccountUser => {
            const accountUser = currentAccountUser.total > 0
              ? currentAccountUser.data.at(0) : undefined;
            if ((isRootOrAdminAccessible && isRootOrAdmin)
              || (accountUser && accessibleAccountRoles?.includes(accountUser.role))) {
              setIsAccountAuthenticated(true);
            }
            setAccountUser(accountUser);
          });
      }
    };
    fetchInfo();
    if (accountId) {
      setCurrentAccountId(accountId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.email, accountId, accessibleAccountRoles, isRootOrAdminAccessible]);
  return {
    isAccountAuthenticated,
    redirectToNotFound: accountUser === undefined && !isRootOrAdmin
    && currentAccountId !== null && currentAccountId === accountId,
  };
};
