import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '.';
import { Account, exceptionOf, Organization, Role, TestOpsProject, TestOpsProjectView, User } from '../models';
import { Operator, Query, Statistic, StatisticQuery } from '../models/query';
import { TestOpsProjectService } from '../services';

export const TESTOPS_PROJECTS_FEATURE_KEY = 'projects';

interface TestOpsProjectsState
  extends EntityState<TestOpsProject> {
  loading: { [key: string]: boolean };
  error?: string;
  projectCountByOrgId: { [key: number]: number };
  projectCountByAccountId: { [key: number]: number };
  projectsByCurrentUserAndOrgId: { [key: number]: TestOpsProject[] };
  projectsByOrgId: { [key: number]: TestOpsProject[] };
  recentAccessedProject?: TestOpsProject;
}

const projectAdapter = createEntityAdapter<TestOpsProject>();
const MAX_NUMBER_OF_RECORDS = 50;

export const createInitialState = (partialState: Partial<TestOpsProjectsState> = {}) => (
  projectAdapter.getInitialState({
    loading: {},
    projectCountByOrgId: {},
    projectCountByAccountId: {},
    projectsByCurrentUserAndOrgId: {},
    projectsByOrgId: {},
    recentAccessedProject: null,
    ...partialState, // overwrite if necessary
  })
);
export const doCountProjectsByUserEmail = createAsyncThunk(
  'projects/countByUserId',
  async (input: Required<Pick<User, 'email' | 'roles'>>) => {
    const criteria: StatisticQuery<TestOpsProjectView>[] = [
      { group: 'team.organizationId' },
      { field: 'id', statistic: Statistic.COUNT },
    ];
    if (input.roles.includes(Role.ADMIN)
      || input.roles.includes(Role.ROOT)
    ) {
      criteria.push({ field: 'team.teamUsers.user.email', operator: Operator.EQ, value: input.email });
    }
    const firstResponse = await TestOpsProjectService.getTestOpsProjectStatistics(...criteria);
    const numberOfRequests = Math.ceil(firstResponse.total / MAX_NUMBER_OF_RECORDS);

    const remainingRequests = [];
    for (let i = 1; i < numberOfRequests; i += 1) {
      const request = TestOpsProjectService.getTestOpsProjectStatistics(
        ...criteria,
        { offset: MAX_NUMBER_OF_RECORDS * i },
      );
      remainingRequests.push(request);
    }
    const remainingResponses = await Promise.all(remainingRequests);
    const projectCount = [...firstResponse.data];
    remainingResponses.forEach(response => projectCount.push(...response.data));
    const projectCountByOrgId: { [key: number]: number } = {};
    projectCount.forEach(it => projectCountByOrgId[it.group.value] = it.value);
    return projectCountByOrgId;
  },
);

export const doCreateProject = createAsyncThunk(
  'projects/doCreateProject',
  async (
    input: { projectName: string, orgId: number },
    { rejectWithValue },
  ) => {
    try {
      const project = await TestOpsProjectService
        .createProject({ name: input.projectName, organizationId: input.orgId });
      return { projectId: project.id, data: project };
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doCountProjectsByAccountId = createAsyncThunk(
  'projects/countByAccountId',
  async (input: { accountId: Account['id'] }) => {
    const criteria: Query<TestOpsProjectView>[] = [
      { field: 'team.organization.accountId', operator: Operator.EQ, value: input.accountId },
    ];
    const firstResponse = await TestOpsProjectService.getTestOpsProjects(...criteria);
    return { accountId: input.accountId, numberProjects: firstResponse.total };
  },
);

export const doGetProjectsByUserEmailAndOrgId = createAsyncThunk(
  'projects/getByUserIdAndOrgId',
  async (input: { userEmail: User['email'], organizationId: Organization['id'] }) => {
    const recentProjects = await TestOpsProjectService.getTestOpsRecentProjects(
      { field: 'user.email', operator: Operator.EQ, value: input.userEmail },
      { field: 'project.team.organizationId', operator: Operator.EQ, value: input.organizationId },
      { field: 'accessedAt', desc: true },
      { limit: 3 },
    );
    let normalProjects;
    if (recentProjects.total < 3) {
      const criteria: Query<TestOpsProjectView>[] = [
        { field: 'team.organizationId', operator: Operator.EQ, value: input.organizationId },
        { field: 'id', desc: false },
        { limit: 3 - recentProjects.total },
      ];
      if (recentProjects.total > 0) {
        normalProjects = await TestOpsProjectService.getTestOpsProjects(
          ...criteria,
          { field: 'id', operator: Operator.NI, value: recentProjects.data.map(it => it.projectId) },
        );
      } else {
        normalProjects = await TestOpsProjectService.getTestOpsProjects(...criteria);
      }
    }
    const projects = [
      ...recentProjects.data.map(it => it.project),
      ...(normalProjects?.data ?? []),
    ];
    return { organizationId: input.organizationId, projects };
  },
);

export const doGetRecentProjectByUserEmailAndOrgs = createAsyncThunk(
  'projects/getRecentProjectByUserIdAndOrgId',
  async (input: { userEmail: User['email'], organizations: Organization[] }) => {
    const recentProjects = await TestOpsProjectService.getTestOpsRecentProjects(
      { field: 'user.email', operator: Operator.EQ, value: input.userEmail },
      { field: 'project.team.organizationId', operator: Operator.IN, value: input.organizations.map(it => it.id) },
      { field: 'accessedAt', desc: true },
      { limit: 1 },
    );

    return { recentAccessedProject: recentProjects.data?.[0]?.project };
  },
);

export const doGetProjectsByOrgId = createAsyncThunk(
  'projects/getByOrgId',
  async (input: { organizationId: Organization['id'] }) => {
    const criteria: Query<TestOpsProjectView>[] = [
      { field: 'team.organizationId', operator: Operator.EQ, value: input.organizationId },
      { field: 'name', desc: true },
      { limit: MAX_NUMBER_OF_RECORDS },
    ];
    const firstResponse = await TestOpsProjectService.getTestOpsProjects(...criteria);
    const numberOfRequests = Math.ceil(firstResponse.total / MAX_NUMBER_OF_RECORDS);
    const remainingRequests = [];
    for (let i = 1; i < numberOfRequests; i += 1) {
      const req = TestOpsProjectService.getTestOpsProjects(
        ...criteria,
        { offset: MAX_NUMBER_OF_RECORDS * i },
      );
      remainingRequests.push(req);
    }
    const remainingResponses = await Promise.all(remainingRequests);
    const projects = [...firstResponse.data];
    remainingResponses.forEach(r => {
      projects.push(...r.data);
    });
    return { organizationId: input.organizationId, projects };
  },
);

const testOpsProjectSlice = createSlice({
  name: TESTOPS_PROJECTS_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {
    doChangeError(state, action: PayloadAction<TestOpsProjectsState['error']>) {
      state.error = action.payload;
    },
  },
  extraReducers: builder => {
    // Count projects of a user, grouped by org
    builder.addCase(doCountProjectsByUserEmail.pending, state => {
      state.loading[doCountProjectsByUserEmail.typePrefix] = true;
    });
    builder.addCase(doCountProjectsByUserEmail.fulfilled, (state, action) => {
      state.projectCountByOrgId = action.payload;
      state.loading[doCountProjectsByUserEmail.typePrefix] = false;
    });
    builder.addCase(doCountProjectsByUserEmail.rejected, (state, action) => {
      state.loading[doCountProjectsByUserEmail.typePrefix] = false;
      state.error = action.error.message;
    });
    // Get 3 recent projects of a user in an org
    builder.addCase(doGetProjectsByUserEmailAndOrgId.pending, state => {
      state.loading[doGetProjectsByUserEmailAndOrgId.typePrefix] = true;
    });
    builder.addCase(doGetProjectsByUserEmailAndOrgId.fulfilled, (state, action) => {
      state.projectsByCurrentUserAndOrgId[action.payload.organizationId] = action.payload.projects;
      state.loading[doGetProjectsByUserEmailAndOrgId.typePrefix] = false;
    });
    builder.addCase(doGetProjectsByUserEmailAndOrgId.rejected, (state, action) => {
      state.loading[doGetProjectsByUserEmailAndOrgId.typePrefix] = false;
      state.error = action.error.message;
    });
    builder.addCase(doGetRecentProjectByUserEmailAndOrgs.pending, state => {
      state.loading[doGetRecentProjectByUserEmailAndOrgs.typePrefix] = true;
    });
    builder.addCase(doGetRecentProjectByUserEmailAndOrgs.fulfilled, (state, action) => {
      state.recentAccessedProject = action.payload.recentAccessedProject;
      state.loading[doGetRecentProjectByUserEmailAndOrgs.typePrefix] = false;
    });
    builder.addCase(doGetRecentProjectByUserEmailAndOrgs.rejected, (state, action) => {
      state.loading[doGetRecentProjectByUserEmailAndOrgs.typePrefix] = false;
      state.error = action.error.message;
    });
    builder.addCase(doGetProjectsByOrgId.pending, state => {
      state.loading[doGetProjectsByOrgId.typePrefix] = true;
    });
    builder.addCase(doGetProjectsByOrgId.fulfilled, (state, action) => {
      state.projectsByOrgId[action.payload.organizationId] = action.payload.projects;
      state.loading[doGetProjectsByOrgId.typePrefix] = false;
    });
    builder.addCase(doGetProjectsByOrgId.rejected, (state, action) => {
      state.loading[doGetProjectsByOrgId.typePrefix] = false;
      state.error = action.error.message;
    });
  },
});

const selectProjectsFeature = (state: AppState) => state[TESTOPS_PROJECTS_FEATURE_KEY];

export const selectLoading = (typePrefix: string) => createSelector(
  selectProjectsFeature,
  state => state.loading[typePrefix],
);

export const selectFetchRecentProjectLoading = createSelector(
  selectProjectsFeature,
  state => state.loading[doGetRecentProjectByUserEmailAndOrgs.typePrefix],
);
export const selectError = createSelector(
  selectProjectsFeature,
  state => state.error,
);
export const selectProjectCount = createSelector(
  selectProjectsFeature,
  state => state.projectCountByOrgId,
);
export const selectProjectsByCurrentUserAndOrgId = (id: Organization['id']) => createSelector(
  selectProjectsFeature,
  state => state.projectsByCurrentUserAndOrgId[id],
);

export const selectProjectCountByOrganizationId = (id: Organization['id']) => createSelector(
  selectProjectsFeature,
  state => state.projectCountByOrgId[id],
);

export const selectRecentAccessProject = createSelector(
  selectProjectsFeature,
  state => state.recentAccessedProject,
);

export const selectAllProjectsByOrganizationId = (id: Organization['id']) => createSelector(
  selectProjectsFeature,
  state => state.projectsByOrgId[id],
);

export const { doChangeError } = testOpsProjectSlice.actions;
export default testOpsProjectSlice.reducer;
