import { useEffect, useState } from 'react';
import orderBy from 'lodash/orderBy';
import { useSelector } from 'react-redux';
import { OrgLicenseData } from '../../base/BaseLicenseAllocationDetail';
import { fromAuth, fromSubscriptions, useAppDispatch } from '../../../../../store';
import { AccountRole, AccountUser, OrganizationFeature } from '../../../../../models';
import { SubscriptionAllocation } from '../../../../../models/recurlySubscriptionDTO';
import CommonDataTable, { Column } from '../../../../../components/common/CommonDataTable';

interface KseOrgLicenseData extends OrgLicenseData {
  kseUsage: string;
  hybridKseUsage: string;
}

const columns: Column<KseOrgLicenseData>[] = [
  { id: 'id' },
  {
    id: 'orgName',
  },
  {
    id: 'kseUsage',
  },
  {
    id: 'hybridKseUsage',
  },
];

const kseLicenseTypes: readonly OrganizationFeature[] = [
  OrganizationFeature.KSE,
  OrganizationFeature.PER_USER_KSE,
  OrganizationFeature.UNLIMITED_KSE,
];

const hybridKseLicenseTypes: readonly OrganizationFeature[] = [
  OrganizationFeature.PER_USER_KSE_OFFLINE,
];

interface KseLicenseAllocationTableProps {
  currentAccountUser: AccountUser | undefined,
  adjustAllocatedKseLicense?: (count: number) => void,
  adjustAllocatedHybridKseLicense?: (count: number) => void,
  setLastUpdated: (lastUpdated: Date) => void,
  setUpdatedBy: (updatedBy: string) => void,
  setLoadedAllocated: () => void,
}

const KseLicenseAllocationTable = (props: KseLicenseAllocationTableProps) => {
  const {
    currentAccountUser,
    adjustAllocatedKseLicense,
    adjustAllocatedHybridKseLicense,
    setLastUpdated,
    setUpdatedBy,
    setLoadedAllocated,
  } = props;

  const dispatch = useAppDispatch();

  const isRootOrAdmin = useSelector(fromAuth.selectIsRootOrAdmin);

  const [dataTable, setDataTable] = useState<KseOrgLicenseData[]>([]);
  const [selectedOrgIds, setSelectedOrgIds] = useState<readonly number[]>([]);
  const [orgAllocations, setOrgAllocations] = useState<SubscriptionAllocation[]>([]);
  const [isEditable, setIsEditable] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);

  const createData = (rawData: {
    id: number;
    orgName: string;
    kseUsage: string;
    hybridKseUsage: string;
  }): KseOrgLicenseData => ({
    id: rawData.id,
    orgName: rawData.orgName,
    kseUsage: rawData.kseUsage,
    hybridKseUsage: rawData.hybridKseUsage,
  });

  useEffect(() => {
    setIsDataFetched(false);
    dispatch(
      fromSubscriptions.doGetActiveRecurlySubscriptionsWithAllocationsByAccountId(
        { id: currentAccountUser?.accountId ?? 0 },
      ),
    )
      .unwrap()
      .then(data => {
        data
          .filter(subAllo => kseLicenseTypes.includes(subAllo.feature)
            || hybridKseLicenseTypes.includes(subAllo.feature))
          .forEach(subs => {
            const clonedList = [...orgAllocations];
            subs.allocations.forEach(subAllo => clonedList.push(subAllo));
            setOrgAllocations(clonedList);
            setLoadedAllocated();
          });
      }).finally(() => setIsDataFetched(true));
  }, [currentAccountUser]);

  useEffect(() => {
    orgAllocations.forEach(orgAllo => {
      if (adjustAllocatedKseLicense) {
        adjustAllocatedKseLicense(orgAllo.allocatedKseQuota);
      }
      if (adjustAllocatedHybridKseLicense) {
        adjustAllocatedHybridKseLicense(orgAllo.allocatedHybridKseQuota);
      }

      const data = createData({
        id: orgAllo.orgId,
        orgName: orgAllo.orgName,
        kseUsage: `${orgAllo.usedKseQuota}/${orgAllo.allocatedKseQuota}`,
        hybridKseUsage: `${orgAllo.usedHybridKseQuota}/${orgAllo.allocatedHybridKseQuota}`,
      });
      const cloned = [...dataTable];
      cloned.push(data);
      setDataTable(cloned);

      const lastUpdated = orderBy(orgAllocations, ['updatedAt'], ['desc'])[0];
      setLastUpdated(lastUpdated.updatedAt);
      setUpdatedBy(lastUpdated.updatedBy?.fullName ?? 'System');

      columns
        .filter(() => orgAllo.allocatedKseQuota < 1)
        .forEach((columnHeader, index) => {
          if (columnHeader && columnHeader.id === 'kseUsage') {
            delete columns[index];
          }
        });
      columns
        .filter(() => orgAllo.allocatedHybridKseQuota < 1)
        .forEach((columnHeader, index) => {
          if (columnHeader && columnHeader.id === 'hybridKseUsage') {
            delete columns[index];
          }
        });
    });
  }, [orgAllocations]);

  const handleFilter = (value: string) => dataTable
    .filter(it => it.orgName && it.orgName.toLowerCase().indexOf(value) > -1);

  const handleSelect = (selected: readonly number[]) => setSelectedOrgIds(selected);

  useEffect(() => {
    setIsEditable(AccountRole.isAdminOrOwnerRole(currentAccountUser?.role)
      || isRootOrAdmin);
  }, [currentAccountUser, isRootOrAdmin]);

  return (
    <CommonDataTable
      tableKey="licenses.allocation.table"
      columns={columns}
      data={dataTable}
      sortBy="id"
      sortType="desc"
      paginationOptions={[10, 20, 50]}
      searchable
      onSearchTextChange={handleFilter}
      selectable={isEditable}
      onSelectRow={handleSelect}
      selectedRows={selectedOrgIds}
      loading={!isDataFetched}
    />
  );
};

export default KseLicenseAllocationTable;
