import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { unwrapResult } from '@reduxjs/toolkit';
import { getData } from 'country-list';
import { useSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { object, string } from 'yup';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import LoadingProgress from '../../../../layout/LoadingProgress';
import { BillingInformation } from '../../../../models';
import CountriesSelection from './CountriesSelection';
import { fromBillingInformation } from '../../../../store/rootReducer';
import { fromAuth, fromAccounts, fromAccountUsers, useAppDispatch } from '../../../../store';
import { sendTrackingData } from '../../utils';
import { formatErrorTrackingMethod } from '../utils';

type BillingFormData = Required<Omit<BillingInformation, 'id' | 'createdAt' | 'updatedAt' | 'accountId' | 'archived'>>;
const useStyles = makeStyles(theme => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
  },
  label: {
    fontWeight: '500',
    marginBottom: theme.spacing(1),
    color: '#1D3066',
  },
  typographyButton: {
    margin: '0 auto',
    textTransform: 'capitalize',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  description: {
    fontSize: theme.spacing(1.5),
    color: '#62839F',
    paddingTop: theme.spacing(1),
  },
  warning: {
    fontSize: theme.spacing(1.5),
    fontStyle: 'italic',
    color: 'red',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  billingInformationText: {
    marginTop: theme.spacing(3),
  },
  informationText: {
    marginBottom: theme.spacing(3),
  },
  getQuoteSubtitle: {
    marginLeft: theme.spacing(1),
    fontSize: theme.spacing(1.75),
  },
  getQuoteTitle: {
    fontSize: theme.spacing(2.5),
    display: 'inline',
  },
  getQuoteTitleContainer: {
    borderBottom: '1px solid #D5D8DD',
    paddingBottom: theme.spacing(2),
  },
  button: {
    float: 'right',
  },
  buttonCancel: {
    marginRight: theme.spacing(1),
    float: 'right',
  },
}));

const inputs: Array<keyof Required<Omit<BillingInformation, 'id' | 'createdAt' | 'updatedAt' | 'accountId' | 'archived'>>> = [
  'ccEmail',
  'address1',
  'address2',
  'city',
  'state',
  'country',
  'postalCode',
  'vatNumber',
  'fullBusinessName',
];

const fieldTrackingInfoMapper: { [key: string]: any } = {
  ccEmail: {
    name: 'cc_email',
    isRequired: false,
  },
  address1: {
    name: 'address_1',
    isRequired: true,
  },

  address2:
    {
      name: 'address_2',
      isRequired: true,
    },
  city: {
    name: 'city',
    isRequired: true,
  },
  state: {
    name: 'state',
    isRequired: true,
  },
  country: {
    name: 'country',
    isRequired: true,
  },
  postalCode: {
    name: 'postal_code',
    isRequired: true,
  },
  vatNumber: {
    name: 'vat_gsc_id',
    isRequired: true,
  },
  fullBusinessName: {
    name: 'business_name',
    isRequired: true,
  },
};

interface BillingInformationComponentProps {
  accountId: number;
  enableSave: boolean;
}

const BillingInformationComponent = forwardRef((props: BillingInformationComponentProps, ref) => {
  const classes = useStyles();
  const { accountId, enableSave } = props;
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const formRef = useRef<any>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [isCardFormOpen, setIsCardFormOpen] = useState(false);
  const account = useSelector(fromAccounts.selectAccountById(Number(accountId)));
  const user = useSelector(fromAuth.selectUser);
  const currentBillingInformation = useSelector(fromBillingInformation.selectOneByAccountId(
    Number(accountId),
  ));
  const accountTotalMembers = useSelector(fromAccountUsers.selectCountByAccountId(
    Number(accountId),
  ));
  const currentAccountUser = useSelector(fromAccountUsers.selectOneByUserIdAndAccountId(
    Number(user?.id),
    Number(account?.id),
  ));
  const countryOptions = getData();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getBillingInformation = async () => {
      if (accountId) {
        await dispatch(fromBillingInformation.doGetBillingInformation(+accountId));
      }
    };
    getBillingInformation().catch(() => {});
  }, [accountId, dispatch]);
  const schema = object().shape({
    ccEmail: string()
      .notRequired()
      .matches(
        /^$|[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.email.invalid' }),
      )
      .email(
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.email.invalid' }),
      ),
    fullBusinessName: string().trim()
      .required(
        intl.formatMessage(
          { id: 'billinginfo.billingcontact.error.required' },
          { field: 'Full Business Name' },
        ),
      ),
    country: string().trim()
      .required(
        intl.formatMessage(
          { id: 'billinginfo.billingcontact.error.required' },
          { field: 'Country' },
        ),
      )
      .matches(
        /^[a-zA-Z,. ]*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.country.invalid' }),
      ),
    state: string().trim()
      .required(
        intl.formatMessage(
          { id: 'billinginfo.billingcontact.error.required' },
          { field: 'State' },
        ),
      )
      .matches(
        /^[a-zA-Z ,.;'&/()-]*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.state.invalid' }),
      ),
    city: string().trim()
      .required(
        intl.formatMessage(
          { id: 'billinginfo.billingcontact.error.required' },
          { field: 'City' },
        ),
      )
      .matches(
        /^[a-zA-Z ,.;'&/()-]*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.city.invalid' }),
      ),
    address1: string().trim()
      .required(
        intl.formatMessage(
          { id: 'billinginfo.billingcontact.error.required' },
          { field: 'Address Line 1' },
        ),
      )
      .matches(
        /^[a-zA-Z0-9# .,;:'°]*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.address.invalid' }),
      ),
    address2: string()
      .matches(
        /^[a-zA-Z0-9# .,;:'°]*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.address.invalid' }),
      ),
    postalCode: string().trim()
      .required(
        intl.formatMessage(
          { id: 'billinginfo.billingcontact.error.required' },
          { field: 'Postal Code' },
        ),
      )
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.postalCode.invalid' }),
      ),
    vatNumber: string()
      .matches(
        /^[a-zA-Z0-9 .-]*$/,
        intl.formatMessage({ id: 'billinginfo.billingcontact.error.vatId.invalid' }),
      ),
  });
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    clearErrors,
    trigger,
  } = useForm<Required<Omit<BillingInformation, 'id' | 'createdAt' | 'updatedAt' | 'accountId' | 'archived'>>>({
    defaultValues: currentBillingInformation ?? {
      ccEmail: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
      vatNumber: '',
      fullBusinessName: '',
    },
    resolver: yupResolver(schema),
  });

  const mapStateDataToInputs = () => {
    setIsCardFormOpen(!currentBillingInformation);
    if (currentBillingInformation) {
      inputs.forEach(input => {
        if (currentBillingInformation[input]) {
          setValue(
            input,
            currentBillingInformation[input].toString(),
          );
        } else {
          setValue(
            input,
            '',
          );
        }
      });
    }
  };

  useEffect(() => {
    mapStateDataToInputs();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [currentBillingInformation, setValue]);

  const renewOnline = () => {
    formRef.current?.requestSubmit();
  };

  useImperativeHandle(ref, () => ({
    renewOnline,
  }));

  const sendInvalidSaveButtonTracking = (errors: string | undefined | string[]) => {
    const additionalInfos = {
      field: 'billing_information',
      object: 'button',
      action: 'save',
      is_required: true,
      is_valid: false,
      error: errors,
    };
    sendTrackingData(
      'checkout_page_all_action',
      accountId,
      undefined,
      additionalInfos,
    );
    sendTrackingData(
      'checkout_page_information_saved',
      accountId,
      undefined,
      additionalInfos,
    );
  };

  const sendValidSaveButtonTracking = () => {
    const additionalInfos = {
      field: 'billing_information',
      object: 'button',
      action: 'save',
      is_required: true,
      is_valid: true,
    };
    sendTrackingData(
      'checkout_page_all_action',
      accountId,
      undefined,
      additionalInfos,
    );
    sendTrackingData(
      'checkout_page_information_saved',
      accountId,
      undefined,
      additionalInfos,
    );
  };

  const onSubmitFormError = (fieldErrors: FieldErrors) => {
    if (fieldErrors) {
      const convertedErrors: string[] = Object.keys(errors)
        .map(field => {
          const errorType = (fieldErrors[(field as keyof BillingFormData)]?.type) as string;
          const fieldInfo = fieldTrackingInfoMapper[field];
          return formatErrorTrackingMethod(fieldInfo?.name, errorType);
        });
      sendInvalidSaveButtonTracking(convertedErrors);
    }
  };

  const onBlurredField = (event: React.FocusEvent<HTMLInputElement>) => {
    const fieldName = event.target.name as keyof BillingFormData;
    trigger(fieldName).then(() => {
      const fieldState = control.getFieldState(fieldName);
      const trackingFieldInfo = fieldTrackingInfoMapper[fieldName];
      let additionalInfos = {};

      if (fieldState.error) {
        // Invalid tracking infor
        const isContentDeleted = !event.target.value;
        // Field fullBusinessName - consider delete event
        if (fieldName === 'fullBusinessName' && isContentDeleted) {
          additionalInfos = {
            field: 'billing_information',
            object: trackingFieldInfo.name,
            action: 'delete',
            is_required: trackingFieldInfo.isRequired,
            is_valid: false,
            error: formatErrorTrackingMethod(trackingFieldInfo.name, fieldState.error.type),
          };
        } else {
          additionalInfos = {
            field: 'billing_information',
            object: trackingFieldInfo.name,
            action: 'fill',
            is_required: trackingFieldInfo.isRequired,
            is_valid: false,
            error: formatErrorTrackingMethod(trackingFieldInfo.name, fieldState.error.type),
          };
        }
      } else {
        // Valid tracking infor
        additionalInfos = {
          field: 'billing_information',
          object: trackingFieldInfo.name,
          action: 'fill',
          is_required: trackingFieldInfo.isRequired,
          is_valid: true,
        };
      }

      sendTrackingData(
        'checkout_page_all_action',
        accountId,
        undefined,
        additionalInfos,
      );
    });
  };

  const formBillingInformation = () => (
    <form
      id="billing-information-form"
      ref={formRef}
      onSubmit={handleSubmit(submitBillingContact, onSubmitFormError)}
    >
      <Grid container item>
        <Grid item xs={8}>
          <Typography variant="h6">
            {intl.formatMessage({ id: 'billing.information' })}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          {enableSave && (
            <Button variant="contained" color="primary" className={classes.button} type="submit">
              {intl.formatMessage({ id: 'billinginfo.button.save' })}
            </Button>
          )}
          {!!currentBillingInformation && (
            <Button variant="contained" color="inherit" className={classes.buttonCancel} onClick={handleCancel}>
              {intl.formatMessage({ id: 'billinginfo.button.cancel' })}
            </Button>
          )}
        </Grid>
      </Grid>
      <Typography variant="subtitle2" className={classes.description}>
        {intl.formatMessage({ id: 'billinginfo.billingcontact.description' })}
      </Typography>
      {enableSave && !currentBillingInformation && (
        <Typography variant="subtitle2" className={classes.warning}>
          {intl.formatMessage({ id: 'billinginfo.billingcontact.warning' }, { isGetQuoteUrl: false })}
        </Typography>
      )}
      {inputs.map(inputKey => (
        <Grid key={inputKey} container className={classes.formContainer}>
          <Grid>
            <Typography variant="h5" className={classes.label}>
              {intl.formatMessage({
                id: `billinginfo.billingcontact.${inputKey}`,
              })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field }) => (inputKey !== 'country' ? (
                <TextField
                  id={inputKey}
                  {...field}
                  variant="outlined"
                  fullWidth
                  placeholder={intl.formatMessage({
                    id: `billinginfo.billingcontact.placeholder.${inputKey}`,
                  })}
                  size="small"
                  error={!!errors[inputKey]}
                  helperText={errors[inputKey]?.message}
                  onBlur={onBlurredField}
                />
              ) : (
                <CountriesSelection
                  field={field}
                  inputKey={inputKey}
                  errors={errors}
                />
              ))}
              name={inputKey}
              control={control}
            />
          </Grid>
        </Grid>
      ))}
      <br />
      <Grid container>
        <Grid item xs={3} md={2} lg={1} />
      </Grid>
      {loading && <LoadingProgress />}
    </form>
  );

  const billingInformationText = () => (
    <Grid id="billing-information-text" className={classes.billingInformationText}>
      {inputs.map(inputKey => (
        <Grid key={inputKey} className={classes.informationText}>
          <Typography variant="h5" className={classes.label}>
            {intl.formatMessage({
              id: `billinginfo.billingcontact.${inputKey}`,
            })}
          </Typography>
          <Typography>
            {currentBillingInformation && (inputKey === 'country' ? countryOptions.find(it => it.code === currentBillingInformation[inputKey])?.name : currentBillingInformation[inputKey])}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );

  const submitBillingContact = (
    inputs: Required<Omit<BillingInformation, 'id' | 'createdAt' | 'updatedAt' | 'accountId' | 'archived'>>,
  ) => {
    const createBillingInformation = async () => {
      setLoading(true);
      await dispatch(
        fromBillingInformation.doCreateBillingInformation({
          accountId: Number(accountId),
          ...inputs,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          enqueueSnackbar(
            <FormattedMessage id="billinginfo.billingcontact.update.success" />,
            { variant: 'success' },
          );
          analytics.track(
            'Billing Information Saved',
            {
              user_id: user?.id,
              firstName: user?.firstName,
              lastName: user?.lastName,
              email: user?.email,
              system_role: user?.roles,
              org_role: currentAccountUser?.role,
              org_id: account?.id,
              org_name: account?.name,
              total_members: accountTotalMembers,
            },
          );
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            user_id: user?.id,
            org_role: currentAccountUser?.role,
            system_role: user?.roles,
            org_id: account?.id,
            org_name: account?.name,
            total_members: accountTotalMembers,
            event: 'gtm_billing_information_saved',
          });
          sendValidSaveButtonTracking();
        })
        .catch((ex: any) => {
          // If error, restore inputs value to default value
          enqueueSnackbar(
            intl.formatMessage({ id: 'billing.payment.error.billing_info' }, { code: ex.message }),
            { variant: 'error' },
          );
          mapStateDataToInputs();
          sendInvalidSaveButtonTracking(ex.message);
        })
        .finally(() => setLoading(false));
    };
    createBillingInformation().catch(() => {});
  };

  const handleCancel = () => {
    clearErrors();
    setIsCardFormOpen(false);
  };

  return (
    <>
      {!isCardFormOpen && (
        <Grid container>
          <Grid container item>
            <Grid item xs={8}>
              <Typography variant="h6">
                {intl.formatMessage({ id: 'billing.information' })}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Button
                id="biling-info-update-btn"
                variant="outlined"
                className={classes.button}
                onClick={() => setIsCardFormOpen(true)}
              >
                <FormattedMessage id="billinginfo.button.update" />
              </Button>
            </Grid>
          </Grid>
          <Typography variant="subtitle2" className={classes.description}>
            {intl.formatMessage({ id: 'billinginfo.billingcontact.description' })}
          </Typography>
          {!currentBillingInformation && (
            <Typography variant="subtitle2" className={classes.warning}>
              {intl.formatMessage({ id: 'billinginfo.billingcontact.warning' }, { isGetQuoteUrl: false })}
            </Typography>
          )}
        </Grid>
      )}
      {isCardFormOpen
        ? formBillingInformation() : billingInformationText()}
    </>
  );
});

export default BillingInformationComponent;
