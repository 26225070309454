import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as SignOutIcon } from '../../../layout/icons/SignOutIcon.svg';
import { logout as logoutRoute, useNavigate, useQuery } from '../../../routes';

const useStyles = makeStyles(theme => ({
  dialog: {
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    borderRadius: theme.spacing(2),
  },
  button: {
    color: '#276EF1',
    fontSize: theme.spacing(2),
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: 'capitalize',
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    textAlign: 'center',
    fontWeight: 700,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    marginBottom: theme.spacing(4),
    fontSize: theme.spacing(2.75),
  },
}));

const LogoutDialog = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { replace, replaceQuery } = useNavigate();
  const { queryDictionary } = useQuery();

  const openLogoutDialog = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
  };

  const closeLogoutDialog = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    replace(logoutRoute.path, replaceQuery(queryDictionary()));
  };

  return (
    <>
      <Button color="primary" onClick={openLogoutDialog} endIcon={<SignOutIcon />}>
        <Typography className={classes.button}>
          <FormattedMessage id="profile.logout.button" />
        </Typography>
      </Button>
      <Dialog
        classes={{ paper: classes.dialog }}
        open={open}
        maxWidth="xs"
        fullWidth
        onClose={closeLogoutDialog}
      >
        <DialogTitle className={classes.title}>
          <FormattedMessage id="profile.logout.title" />
        </DialogTitle>
        <DialogActions className={classes.actions}>
          <Button variant="contained" onClick={closeLogoutDialog}>
            <FormattedMessage id="profile.logout.cancel" />
          </Button>
          <Button type="button" color="primary" variant="contained" onClick={handleLogout}>
            <FormattedMessage id="profile.logout.submit" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LogoutDialog;
