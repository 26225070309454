import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useConfig } from '../../config';
import { useAppDispatch } from '../../store';
import LoadingProgress from '../../layout/LoadingProgress';
import { ReactComponent as Banner } from './components/katalon_login_banner.svg';
import { ReactComponent as Logo } from './components/katalon_new_logo.svg';
import { fromActiveLicense } from '../../store/rootReducer';
import K1Typography from '../../components/design-system/K1Typography';
import { activateLicense } from '../../services/activateLicense';
import { login, signUpOnPremise, useNavigate, useQuery } from '../../routes';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
  },
  banner: {
    flex: '0 0 auto',
    backgroundSize: 'cover',
    position: 'relative',
    [theme.breakpoints.between('sm', 'lg')]: {
      display: 'none',
    },
    '& > svg': {
      height: '100vh',
      width: 'auto',
    },
  },
  page: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    minHeight: theme.spacing(62.5),
  },
  cardContainer: {
    flex: '1 1 auto',
    display: 'flex',
  },
  card: {
    margin: theme.spacing(25.125),
    width: '100%',
    minWidth: theme.spacing(37.5),
    maxWidth: '35vw',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '70vw',
    },
  },
  logo: {
    maxWidth: '100%',
    textAlign: 'left',
    maxHeight: theme.spacing(6),
    marginBottom: theme.spacing(7),
  },
  buttonActivate: {
    width: theme.spacing(12),
    height: theme.spacing(7),
    marginTop: theme.spacing(5),
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(2),
    backgroundColor: '#0F8461',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#0C6A4E',
      color: '#FFFFFF',
    },
  },
  title: {
    borderBottom: '2px solid #d5d5e2',
  },
  titleText: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(2),
    color: '#22283c',
    borderBottom: '2px solid #5959eb',
    marginBottom: theme.spacing(-0.25),
    paddingBottom: theme.spacing(0.5),
    width: theme.spacing(25.75),
  },
  titleMachineIdInput: {
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: theme.spacing(4),
  },
  machineIdInput: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  titleLicenseInput: {
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: theme.spacing(3),
  },
  licenseInput: {
    marginTop: theme.spacing(0.875),
  },
  licenseField: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

interface ActivateLicenseInput {
  license: string;
}

const ActivateLicenseComponent = () => {
  const classes = useStyles();
  const { config } = useConfig();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { navigate, replace, replaceQuery } = useNavigate();
  const { queryDictionary } = useQuery();
  const [loading, setLoading] = useState(false);
  const [licenseKey, setLicenseKey] = useState('');
  const machineId = useSelector(fromActiveLicense.selectMachineId);
  const activated = useSelector(fromActiveLicense.selectActivated);
  const {
    handleSubmit,
  } = useForm<ActivateLicenseInput>({
    defaultValues: { license: '' },
  });

  useEffect(() => {
    setLoading(true);
    const fetchMachineId = async () => {
      await dispatch(fromActiveLicense.doGetMachineId()).then(() => setLoading(false));
    };
    // eslint-disable-next-line no-console
    fetchMachineId().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchCheckingActivation = async () => {
      await dispatch(fromActiveLicense.doGetActivated()).then(() => setLoading(false));
    };
    // eslint-disable-next-line no-console
    fetchCheckingActivation().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doActiveLicense = () => {
    setLoading(true);
    const executeActivateLicense = async () => {
      await activateLicense({
        license: licenseKey,
      });
    };

    executeActivateLicense()
      .then(() => replace(login.path, replaceQuery(queryDictionary())))
      .catch(error => handleError(error.response.data.errors))
      .finally(() => setLoading(false));
  };

  const handleError = (e: string[]) => {
    if (Array.isArray(e) && e.length > 0) {
      enqueueSnackbar(<FormattedMessage id={e[0]} />, { variant: 'error' });
    } else {
      enqueueSnackbar(<FormattedMessage id="error.unknown" />, { variant: 'error' });
    }
  };

  const licenseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const readFile = async () => {
      if (event.target.files) {
        event.preventDefault();
        const file = event.target.files[0];
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
          const license = fileReader.result;
          setLicenseKey(license as string);
        };
        fileReader.readAsText(file);
      }
    };
    // eslint-disable-next-line no-console
    readFile().catch(console.error);
  };

  if (!config) return <></>;

  if (activated) {
    navigate(signUpOnPremise.path, replaceQuery({ }));
  }

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.banner}>
          <Banner />
        </Box>
        <Box className={classes.page}>
          <form
            id="activate.license.form"
            // eslint-disable-next-line no-void
            onSubmit={event => void handleSubmit(doActiveLicense)(event)}
          >
            <Box className={classes.cardContainer}>
              <Box className={classes.card}>
                <div className={classes.logo}>
                  <Logo />
                </div>
                <Box className={classes.title}>
                  <Box className={classes.titleText}>
                    <FormattedMessage id="activate.license.form.title" />
                  </Box>
                </Box>
                <Box>
                  <Box className={classes.titleMachineIdInput}>
                    <K1Typography variant="h5" fontWeight="medium">
                      <FormattedMessage id="activate.license.form.machine_id" />
                    </K1Typography>
                  </Box>
                  <Box className={classes.machineIdInput}>
                    <K1Typography id="activate.license.form" variant="h4">
                      {machineId}
                    </K1Typography>
                  </Box>
                  <Box className={classes.titleLicenseInput}>
                    <K1Typography variant="h5" fontWeight="medium">
                      <FormattedMessage id="activate.license.form.license" />
                    </K1Typography>
                  </Box>
                  <Box className={classes.licenseInput}>
                    <input
                      type="file"
                      id="licenseKey"
                      required
                      className={classes.licenseField}
                      onChange={e => licenseChange(e)}
                    />
                  </Box>
                  <Button
                    variant="contained"
                    size="large"
                    className={classes.buttonActivate}
                    type="submit"
                  >
                    <FormattedMessage id="activate.license.form.action.activate" />
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
      {loading && <LoadingProgress />}
    </>
  );
};

export default ActivateLicenseComponent;
