import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Lottie from 'lottie-react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { ReactNode, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import RedirectLoadingIcon from '../../layout/icons/RedirectLoadingIcon.json';
import { SalesforceAccessDeniedIcon } from '../../layout/CustomIcon';
import { home } from '../../layout/routes';
import { sso, useNavigate, useQuery } from '../../routes';
import { OAuthService } from '../../services';
import { fromServiceCloudOrganizationUser, useAppDispatch } from '../../store';
import { useConfig } from '../../config';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1),
  },
  closeButton: {
    cursor: 'pointer',
    fill: 'gray',
  },
  content: {
    height: '80vh',
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  continue: {
    marginTop: theme.spacing(4),
  },
  icon: {
    fontSize: theme.spacing(0.5),
    color: '#CFD7EC',
  },
  itemIcon: {
    minWidth: theme.spacing(1.5),
  },
  loadingIcon: {
    display: 'flex',
    marginBottom: theme.spacing(5),
    justifyContent: 'center',
  },
}));

const OAuthServiceCloud = () => {
  const classes = useStyles();
  const intl = useIntl();
  const { navigate, replaceQuery } = useNavigate();
  const { get } = useQuery();
  const dispatch = useAppDispatch();
  const { config } = useConfig();
  const oAuthState = get('state');
  const redirectUri = get('redirect_uri');
  const responseType = get('response_type');
  const clientId = get('client_id');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const serviceCloudLoading = useSelector(fromServiceCloudOrganizationUser.selectLoading);
  const serviceCloud = useSelector(fromServiceCloudOrganizationUser.selectActiveServiceCloud);

  const handleRedirectToSupportPortal = async () => {
    const authCode = await OAuthService.getAuthorizationCode(
      responseType,
      clientId,
      redirectUri,
    );
    const url = new URL(redirectUri || '');
    url.searchParams.append('code', authCode.code);
    url.searchParams.append('state', oAuthState || '');
    window.location.href = url.toString();
  };

  useEffect(() => {
    if (!oAuthState?.length || !redirectUri?.length
      || !responseType?.length || !clientId?.length
    ) {
      window.location.href = `${process.env.REACT_APP_SERVICE_CLOUD_OAUTH_INITIALIZATION_URL}`;
      return;
    }
    (async () => {
      await dispatch(fromServiceCloudOrganizationUser.doGetActiveServiceCloudOrganizationUser());
    })();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!config || !config.serviceCloudEnabled) return;
    if (!loading && !serviceCloudLoading && !serviceCloud) {
      setDialogOpen(true);
      return;
    }
    if (!loading && !serviceCloudLoading && serviceCloud) {
      handleRedirectToSupportPortal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, serviceCloudLoading, config]);

  const handleClose = () => {
    navigate(home.path, replaceQuery({}));
    setDialogOpen(false);
  };

  if (!config) return null;
  if (!config.serviceCloudEnabled) {
    navigate(sso.path, replaceQuery({ redirect_uri: `${process.env.REACT_APP_WEBSITE_URL}/help-center-redirect` }));
    return null;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      textAlign="center"
      flexGrow={1}
    >
      {!dialogOpen && (
        <Box>
          <Box className={classes.loadingIcon}>
            <Lottie
              loop
              autoplay
              animationData={RedirectLoadingIcon}
              style={{ width: '120px', height: '120px' }}
            />
          </Box>
          <Typography>
            <FormattedMessage id="oauth.salesforce_dialog.redirect_loading1" />
          </Typography>
          <Typography>
            <FormattedMessage id="oauth.salesforce_dialog.redirect_loading2" />
          </Typography>
        </Box>
      )}
      {!loading && (
        <Dialog fullScreen fullWidth open={dialogOpen} onClose={handleClose}>
          <DialogTitle className={classes.header}>
            <HighlightOffIcon className={classes.closeButton} onClick={handleClose} />
          </DialogTitle>
          <DialogContent>
            <Box
              className={classes.content}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              textAlign="center"
              flexGrow={1}
            >
              <SalesforceAccessDeniedIcon />
              <Typography fontWeight="bold" className={classes.title}>
                <FormattedMessage id="oauth.salesforce_dialog.title" />
              </Typography>
              <List>
                <ListItem disablePadding>
                  <ListItemIcon classes={{ root: classes.itemIcon }}>
                    <FiberManualRecordIcon
                      className={classes.icon}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={intl.formatMessage(
                      { id: 'oauth.salesforce_dialog.description1' },
                      { a: (name: ReactNode) => <Link underline="none" href="https://forum.katalon.com">{name}</Link> },
                    )}
                  />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon classes={{ root: classes.itemIcon }}>
                    <FiberManualRecordIcon
                      className={classes.icon}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={intl.formatMessage({ id: 'oauth.salesforce_dialog.description2' })}
                  />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon classes={{ root: classes.itemIcon }}>
                    <FiberManualRecordIcon
                      className={classes.icon}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={intl.formatMessage(
                      { id: 'oauth.salesforce_dialog.description3' },
                      { a: (email: ReactNode) => <Link underline="none" href={`mailto:${email}`}>{email}</Link> },
                    )}
                  />
                </ListItem>
              </List>
              <Button
                variant="contained"
                color="primary"
                className={classes.continue}
                onClick={handleRedirectToSupportPortal}
              >
                {intl.formatMessage({ id: 'oauth.salesforce_dialog.continue' })}
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default OAuthServiceCloud;
