import { unwrapResult } from '@reduxjs/toolkit';
import intersection from 'lodash/fp/intersection';
import isEmpty from 'lodash/fp/isEmpty';
import { stringify } from 'querystring';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  generatePath,
  matchPath,
  Navigate,
  PathRouteProps,
  Route,
  Routes,
  RoutesProps,
  useLocation,
} from 'react-router-dom';
import { useConfig } from '../config';
import Layout from '../layout';
import {
  accountHome,
  accountSubscriptionManagement,
  home,
  paymentMethod,
  supportManagement,
} from '../layout/routes';
import { AccountRole, OrganizationRole } from '../models';
import Activate from '../pages/activate';
import ActivateLicenseComponent from '../pages/activate-license';
import Forbidden from '../pages/forbidden';
import RootSignup from '../pages/getting-started';
import Login from '../pages/login';
import Logout from '../pages/logout';
import NotFoundComponent from '../pages/not-found';
import Profile from '../pages/profile';
import SignUpOnPremiseComponent from '../pages/signup-op';
import SsoComponent from '../pages/sso';
import SsoTestOpsComponent from '../pages/sso/SsoTestOps';
import Welcome from '../pages/welcome';
import {
  fromAccounts,
  fromAccountUsers,
  fromAuth,
  fromCurrentOrgUser,
  useAppDispatch,
} from '../store';
import ProtectedAccountRoute from './ProtectedAccountRoute';
import ProtectedOrganizationRoute from './ProtectedOrganizationRoute';
import ProtectedRoute from './ProtectedRoute';
import { RouteType } from './routeType';
import RouteWithPageTitle from './RouteWithPageTitle';
import { QueryDictionary, useNavigate } from './useNavigate';
import EnableAiSetting from '../pages/enable-ai-setting';

export type RouteConfig = PathRouteProps & {
  path: string;
  redirect?: string;
  requireAuth?: boolean;
  roles?: string[];
  pageTitle?: string;
  accessibleOrgRoles?: OrganizationRole[];
  accessibleAccountRoles?: AccountRole[];
  isRootOrAdminAccessible?: boolean;
  type?: RouteType;
};

const accountPages = [accountHome.path, accountSubscriptionManagement.path, paymentMethod.path];

export const renderRoutes = (
  routes: RouteConfig[],
  switchProps?: RoutesProps,
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { replaceQuery, navigate } = useNavigate();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useAppDispatch();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const user = useSelector(fromAuth.selectUser);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const location = useLocation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const orgId = (new URLSearchParams(window.location.search)).get('orgId');
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const accountId = (new URLSearchParams(window.location.search)).get('accountId');
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const currentOrgUser = useSelector(fromCurrentOrgUser.selectByOrganizationIdAndEmail(
    user?.email || '',
    Number(orgId),
  ));
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const currentAccountUser = useSelector(fromAccountUsers.selectOneByUserIdAndAccountId(
    Number(user?.id),
    Number(accountId),
  ));
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isRootOrAdmin = useSelector(fromAuth.selectIsRootOrAdmin);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { config } = useConfig();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isLoadingCurrentOrgUser = useSelector(fromCurrentOrgUser.selectLoading);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const isAccountPage = accountPages
      .find(path => matchPath(path, location.pathname)?.pathname !== undefined) !== undefined;
    if (!user?.id || !isAccountPage) return;
    dispatch(fromAccounts.doCountByCurrentUser())
      .then(unwrapResult)
      .then(data => {
        if (data.total === 0) {
          navigate(welcome.path, replaceQuery({}));
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, location.pathname]);

  const routeList = routes.map((
    { redirect,
      requireAuth,
      path,
      roles,
      accessibleOrgRoles,
      isRootOrAdminAccessible,
      accessibleAccountRoles,
      type,
      element,
      ...routeProps },
  ) => {
    if (config?.onpremise && window.location.href.includes(supportManagement.path)) {
      return (
        <Route
          key={path}
          path={path}
          element={(
            <Navigate
              to="/notfound"
              replace
              {...routeProps}
            />
          )}
        />
      );
    }
    if (redirect) {
      return (
        <Route
          key={path}
          path={path}
          element={(
            <Navigate
              to={redirect}
              replace
              {...routeProps}
            />
          )}
        />
      );
    }
    if (requireAuth) {
      // Check if user roles match at least 1 route role
      if (!isEmpty(roles) && isEmpty(intersection(user?.roles, roles))) {
        return (
          <Route
            key={path}
            path={path}
            element={(
              <Navigate to={notFound.path} replace />
            )}
          />
        );
      }

      // redirect to notfound when access account's page without param accountId in url
      if (type === RouteType.ACCOUNT && accountId === null) {
        return (
          <Route
            key={path}
            path={path}
            element={(
              <Navigate to={notFound.path} replace />
            )}
          />
        );
      }

      // redirect to notfound when access organization's page without param orgId in url
      // exclude home page
      if (type === RouteType.ORGANIZATION && orgId === null && path !== home.path) {
        return (
          <Route
            key={path}
            path={path}
            element={(
              <Navigate to={notFound.path} replace />
            )}
          />
        );
      }

      // if user does not have organization permission => redirect to forbidden page
      if (!isRootOrAdmin
        && !isLoadingCurrentOrgUser
        && currentOrgUser
        && accessibleOrgRoles
        && !accessibleOrgRoles?.includes(currentOrgUser.role)
      ) {
        return (
          <Route
            key={path}
            path={path}
            element={(
              <Navigate to={forbidden.path} replace />
            )}
          />
        );
      }
      // if user does not have account permission => redirect to forbidden page
      if (!isRootOrAdmin && currentAccountUser
        && (accessibleAccountRoles
          && !accessibleAccountRoles?.includes(currentAccountUser.role))) {
        return (
          <Route
            key={path}
            path={path}
            element={(
              <Navigate to={forbidden.path} replace />
            )}
          />
        );
      }
      // skip check organization role if user is root or admin
      if (type === RouteType.ORGANIZATION
        && ((isRootOrAdminAccessible !== undefined && isRootOrAdmin)
          || (accessibleOrgRoles && accessibleOrgRoles.length > 0))) {
        return (
          <Route
            key={path}
            path={path}
            element={(
              <ProtectedOrganizationRoute
                key={path}
                path={path}
                element={element}
                accessibleOrgRoles={accessibleOrgRoles}
                isRootOrAdminAccessible={isRootOrAdminAccessible}
                {...routeProps}
              />
            )}
            {...routeProps}
          />
        );
      }
      // skip check account role if user is root or admin
      if (type === RouteType.ACCOUNT && accountId
        && ((isRootOrAdminAccessible !== undefined && isRootOrAdmin)
          || (accessibleAccountRoles && accessibleAccountRoles.length > 0))) {
        return (
          <Route
            key={path}
            path={path}
            element={(
              <ProtectedAccountRoute
                key={path}
                path={path}
                element={element}
                isRootOrAdminAccessible={isRootOrAdminAccessible}
                accessibleAccountRoles={accessibleAccountRoles}
                {...routeProps}
              />
            )}
            {...routeProps}
          />
        );
      }

      return (
        <Route
          key={path}
          path={path}
          element={(
            <ProtectedRoute element={element} {...routeProps} />
          )}
          {...routeProps}
        />
      );
    }
    return (
      <Route
        key={path}
        path={path}
        element={<RouteWithPageTitle element={element} />}
        {...routeProps}
      />
    );
  });
  return (
    <Routes {...switchProps}>
      {routeList}
    </Routes>
  );
};

export const AppRoutes = (
  { routes, switchProps }: { routes: RouteConfig[]; switchProps?: RoutesProps; },
) => (
  renderRoutes(routes, switchProps)
);

// we're trying to make the sub routes generic enough to not knowing their ancestors
// so in case a route has never been rendered,
// it's correct to assume that it'll be located in root path
// though it's not what we always expect, but it'll work most of the time
export const resolvePath = <T extends any>(
  route: RouteConfig,
  // eslint-disable-next-line default-param-last
  params: Parameters<typeof generatePath>[1] = {},
  queries?: QueryDictionary<T>,
  shouldAddBasename = true,
) => Array.of(
    generatePath(shouldAddBasename
      ? generateRelativePathWhenNotRouterLink(route.path)
      : generateRelativePath(route.path), params),
    queries ? stringify(queries) : '',
  ).filter(Boolean).join('?');

const generateRelativePath = (path: string) => {
  const currentPathArray = window.location.pathname.split('/').filter(Boolean);
  if (currentPathArray.length - 1 > 0) {
    const relativePath = '../'.repeat(currentPathArray.length - 1);
    return `${relativePath}..${path}`;
  }
  return `..${path}`;
};

const generateRelativePathWhenNotRouterLink = (path: string) => {
  const currentPathArray = window.location.pathname.split('/').filter(Boolean);
  if (currentPathArray.length - 1 > 0) {
    const relativePath = '../'.repeat(currentPathArray.length - 1);
    const fullPath = `${relativePath}..${path.startsWith(process.env.PUBLIC_URL) ? path : (process.env.PUBLIC_URL + path)}`;
    return fullPath;
  }
  if (path.startsWith(process.env.PUBLIC_URL)) {
    return `..${path}`;
  }
  return `..${process.env.PUBLIC_URL}${path}`;
};

export const login: RouteConfig = {
  path: '/login',
  element: <Login />,
};

const rootSignup: RouteConfig = {
  path: '/getting-started',
  element: <RootSignup />,
};

export const sso: RouteConfig = {
  path: '/sso',
  element: <SsoComponent />,
  requireAuth: true,
};

export const ssoTestOps: RouteConfig = {
  path: '/sso-testops',
  element: <SsoTestOpsComponent />,
  requireAuth: true,
};

export const activate: RouteConfig = {
  path: '/activate',
  element: <Activate />,
};

export const activateLicense: RouteConfig = {
  path: '/activate-license',
  element: <ActivateLicenseComponent />,
};

export const signUpOnPremise: RouteConfig = {
  path: '/sign-up-op',
  element: <SignUpOnPremiseComponent />,
};

export const notFound: RouteConfig = {
  path: '/notfound',
  element: <NotFoundComponent />,
  requireAuth: true,
};

export const logout: RouteConfig = {
  path: '/logout',
  element: <Logout />,
};

export const forbidden: RouteConfig = {
  path: '/forbidden',
  element: <Forbidden />,
  requireAuth: true,
  pageTitle: 'app.page.title.forbidden',
};

export const profile: RouteConfig = {
  path: '/profile',
  element: <Profile />,
  requireAuth: true,
  pageTitle: 'app.page.title.profile',
};

export const welcome: RouteConfig = {
  path: '/welcome',
  element: <Welcome />,
  requireAuth: true,
  pageTitle: 'app.page.title.welcome',
  isRootOrAdminAccessible: true,
};

export const enableAiSetting: RouteConfig = {
  path: '/enable-ai-settings',
  element: <EnableAiSetting />,
  requireAuth: true,
  pageTitle: 'app.page.title.enable_ai_settings',
  isRootOrAdminAccessible: true,
  type: RouteType.ACCOUNT,
};

const routes: RouteConfig[] = [
  activate,
  login,
  rootSignup,
  sso,
  ssoTestOps,
  logout,
  notFound,
  activateLicense,
  signUpOnPremise,
  forbidden,
  profile,
  welcome,
  enableAiSetting,
  {
    path: '/*',
    element: <Layout />,
    requireAuth: true,
  },
];

export * from './useNavigate';
export * from './useQuery';
export default routes;
