import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Organization } from '../../models';
import { fromOrganizations, fromOrganizationUsers } from '../../store';

interface OrganizationCardProps {
  id: Organization['id'];
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  content: {
    marginLeft: theme.spacing(6),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  horizaontalBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const OrganizationCard = (props: OrganizationCardProps) => {
  const { id } = props;
  const organization = useSelector(fromOrganizations.selectOrganizationById(id));
  const organizationUsers = useSelector(fromOrganizationUsers.selectByOrganizationId(id));
  const classes = useStyles();

  return organization ? (
    <Card className={classes.root}>
      <CardHeader
        avatar={(
          <Avatar src={organization?.logo}>
            {organization?.name[0]}
          </Avatar>
        )}
        titleTypographyProps={{ variant: 'h3' }}
        action={(
          <IconButton size="large">
            <MoreVertIcon />
          </IconButton>
        )}
        title={organization?.name}
      />
      <CardContent>
        <Box className={classes.content}>
          <Box className={classes.horizaontalBox}>
            <Typography variant="h4">{organizationUsers.length}</Typography>
            <Typography variant="body1" color="textSecondary">
              <FormattedMessage id="organizationlist.card.members" />
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  ) : null;
};

export default OrganizationCard;
