import { AccountRole, OrganizationRole, Role } from '../models';
import BillingInformation from '../pages/billing-information';
import CreateOrganization from '../pages/create-organization';
import Dashboard from '../pages/dashboard';
import InviteMember from '../pages/invite-member';
import Organization from '../pages/organization';
import OrganizationList from '../pages/organization-list';
import Setting from '../pages/setting';
import User from '../pages/user';
import UserManagement from '../pages/user-management';
import LicenseUtilization from '../pages/license-utilization';
import { RouteConfig } from '../routes';
import AcceptInvitationPage from '../pages/accept-invitation';
import InvalidInvitation from '../pages/invalid-invitation';
import AcceptSsoInvitation from '../pages/accept-sso-invitation';
import LicenseManagement from '../pages/license-management';
import OAuthServiceCloud from '../pages/oauth-service-cloud';
import SupportManagement from '../pages/support-management';
import SubscriptionManagement from '../pages/subscription-management';
import CheckOutQuotePaymentSuccess from '../pages/payment-success';
import TestCloudSubscription from '../pages/subscription-management/testcloud/TestCloudSubscription';
import TestCloudCheckout from '../pages/subscription-management/testcloud/TestCloudCheckout';
import TestCloudDashboard from '../pages/testcloud-dashboard';
import TestCloudGetQuote from '../pages/subscription-management/get-quote';
import AccountSubscriptionManagement from '../pages/account/subscription-management';
import { RouteType } from '../routes/routeType';
import AccountHome from '../pages/account-home';
import PaymentMethod from '../pages/payment-method';
import AccountSetting from '../pages/account-setting';
import OrganizationManagement from '../pages/organization-management';
import DistributeStudioLicenses from '../pages/distribute-studio-licenses';
import DistributeTestOpsLicenses from '../pages/distribute-testcloud-licenses';
import DistributeTestCloudLicenses from '../pages/distribute-testops-licenses';
import TestOpsPlatformCheckout from '../pages/account/testops-platform-checkout';
import PlatformCheckOutPaymentStatus from '../pages/account/payment-status';
import SurveyComplete from '../pages/account/survey-complete';
import ActivateLicenseServer from '../pages/activate-license-server';
import SecuritySetting from '../pages/security-settings';
import ProjectManagementMicroApp from '../pages/project-management';
import MaintenanceProcess from '../pages/maintenance';
import OrgCardViewManagement from '../pages/organization-card-view';
import AccountWelcome from '../pages/account/welcome';
import UserGroup from '../pages/user-group';
import OrgHomePage from '../pages/home/OrgHomePage';
import UserGroupDetail from '../pages/user-group/UserGroupDetail';
import LicensesAllocation from '../pages/licenses-allocation';
import AccountUserManagement from '../pages/account-user-management';
import DirectToSelfServeConfirmation from '../pages/account/direct-to-self-serve-confirmation';
import RetryPaymentPage from '../pages/account/retry-payment';

export const userManagement: RouteConfig = {
  path: '/user-management/*',
  element: <UserManagement />,
  requireAuth: true,
  pageTitle: 'app.page.title.user_management',
  accessibleOrgRoles: [
    OrganizationRole.ADMIN,
    OrganizationRole.OWNER],
  isRootOrAdminAccessible: true,
  type: RouteType.ORGANIZATION,
};

export const setting: RouteConfig = {
  path: '/setting',
  requireAuth: true,
  element: <Setting />,
  roles: [Role.ROOT],
};

export const createOrganization: RouteConfig = {
  path: '/create-organization',
  element: <CreateOrganization />,
  requireAuth: true,
};

export const organizationList: RouteConfig = {
  path: '/organizations',
  element: <OrganizationList />,
  requireAuth: true,
};

export const organization: RouteConfig = {
  path: '/organization',
  element: <Organization />,
  requireAuth: true,
};

export const user: RouteConfig = {
  path: '/user',
  element: <User />,
  requireAuth: true,
};

export const inviteMember: RouteConfig = {
  path: '/invite-member',
  element: <InviteMember />,
  requireAuth: true,
};

export const billingInformation: RouteConfig = {
  path: '/billing-information',
  element: <BillingInformation />,
  requireAuth: true,
};

export const dashboard: RouteConfig = {
  path: '/dashboard',
  element: <Dashboard />,
  requireAuth: true,
  pageTitle: 'app.page.title.dashboard',
  accessibleOrgRoles: [
    OrganizationRole.BILLING_MANAGER,
    OrganizationRole.ADMIN,
    OrganizationRole.OWNER],
  isRootOrAdminAccessible: true,
  type: RouteType.ORGANIZATION,
};

export const licenseUtilization: RouteConfig = {
  path: '/license-utilization/*',
  element: <LicenseUtilization />,
  requireAuth: true,
  pageTitle: 'app.page.title.license_utilization',
  accessibleOrgRoles: [
    OrganizationRole.ADMIN,
    OrganizationRole.OWNER,
  ],
  isRootOrAdminAccessible: true,
  type: RouteType.ORGANIZATION,
};

export const acceptInvitation: RouteConfig = {
  path: '/accept-invitation',
  element: <AcceptInvitationPage />,
  requireAuth: true,
  pageTitle: 'app.page.title.accept_invitation',
};

export const invalidInvitation: RouteConfig = {
  path: '/invalid-invitation',
  element: <InvalidInvitation />,
  requireAuth: true,
  pageTitle: 'app.page.title.invalid_invitation',
};

export const maintenanceProcess: RouteConfig = {
  path: '/maintenance-process',
  element: <MaintenanceProcess />,
  requireAuth: true,
  pageTitle: 'maintenance.page.title',
};

export const home: RouteConfig = {
  path: '/home',
  element: <OrgHomePage />,
  requireAuth: true,
  isRootOrAdminAccessible: true,
  type: RouteType.ORGANIZATION,
};

export const acceptSsoInvitation: RouteConfig = {
  path: '/accept-sso',
  element: <AcceptSsoInvitation />,
  requireAuth: true,
  pageTitle: 'app.page.title.accept_sso_invitation',
};

export const licenseManagement: RouteConfig = {
  path: '/license-management',
  element: <LicenseManagement />,
  requireAuth: true,
  pageTitle: 'app.page.title.license_management',
  accessibleOrgRoles: [
    OrganizationRole.ADMIN,
    OrganizationRole.OWNER,
  ],
  isRootOrAdminAccessible: true,
  type: RouteType.ORGANIZATION,
};

export const oAuthServiceCloud: RouteConfig = {
  path: '/oauth/service-cloud',
  element: <OAuthServiceCloud />,
  requireAuth: true,
};

export const supportManagement: RouteConfig = {
  path: '/support-management',
  element: <SupportManagement />,
  requireAuth: true,
  pageTitle: 'app.page.title.support_management',
  accessibleOrgRoles: [
    OrganizationRole.ADMIN,
    OrganizationRole.OWNER,
  ],
  isRootOrAdminAccessible: true,
  type: RouteType.ORGANIZATION,
};

export const subscriptionManagement: RouteConfig = {
  path: '/subscription-management',
  element: <SubscriptionManagement />,
  requireAuth: true,
  pageTitle: 'app.page.title.subscription_management',
  accessibleOrgRoles: [
    OrganizationRole.OWNER,
    OrganizationRole.BILLING_MANAGER,
  ],
  isRootOrAdminAccessible: true,
  type: RouteType.ORGANIZATION,
};

export const testCloudSubscriptionManagement: RouteConfig = {
  path: `${subscriptionManagement.path}/testcloud`,
  element: <TestCloudSubscription />,
  requireAuth: true,
  pageTitle: 'app.page.title.testcloud_subscription_management',
  accessibleOrgRoles: [
    OrganizationRole.OWNER,
    OrganizationRole.BILLING_MANAGER,
  ],
  isRootOrAdminAccessible: true,
  type: RouteType.ORGANIZATION,
};

export const testCloudCheckout: RouteConfig = {
  path: `${testCloudSubscriptionManagement.path}/purchase`,
  element: <TestCloudCheckout />,
  requireAuth: true,
  pageTitle: 'app.page.title.testcloud_subscription_management',
  accessibleOrgRoles: [
    OrganizationRole.OWNER,
    OrganizationRole.BILLING_MANAGER,
  ],
  isRootOrAdminAccessible: true,
  type: RouteType.ORGANIZATION,
};

export const userGroup: RouteConfig = {
  path: '/user-group',
  element: <UserGroup />,
  requireAuth: true,
  accessibleOrgRoles: [
    OrganizationRole.OWNER,
    OrganizationRole.ADMIN,
    OrganizationRole.MEMBER,
    OrganizationRole.BILLING_MANAGER,
  ],
  pageTitle: 'app.page.title.user.group',
  isRootOrAdminAccessible: true,
  type: RouteType.ORGANIZATION,
};

export const userGroupDetail: RouteConfig = {
  path: '/user-group/:id/detail',
  element: <UserGroupDetail />,
  requireAuth: true,
  accessibleOrgRoles: [
    OrganizationRole.OWNER,
    OrganizationRole.ADMIN,
    OrganizationRole.MEMBER,
    OrganizationRole.BILLING_MANAGER,
  ],
  pageTitle: 'app.page.title.user.group',
  isRootOrAdminAccessible: true,
  type: RouteType.ORGANIZATION,
};

export const paymentSuccess: RouteConfig = {
  path: '/payment-success',
  element: <CheckOutQuotePaymentSuccess />,
  requireAuth: true,
  pageTitle: 'app.page.title.payment_success',
  accessibleOrgRoles: [
    OrganizationRole.OWNER,
    OrganizationRole.BILLING_MANAGER,
  ],
  isRootOrAdminAccessible: true,
  type: RouteType.ORGANIZATION,
};

export const testCloudDashboard: RouteConfig = {
  path: '/testcloud-dashboard',
  element: <TestCloudDashboard />,
  requireAuth: true,
  pageTitle: 'app.page.title.testcloud_dashboard',
  accessibleOrgRoles: [
    OrganizationRole.BILLING_MANAGER,
    OrganizationRole.ADMIN,
    OrganizationRole.OWNER,
  ],
  isRootOrAdminAccessible: true,
  type: RouteType.ORGANIZATION,
};

export const testCloudGetQuote: RouteConfig = {
  path: `${testCloudSubscriptionManagement.path}/get-quote`,
  element: <TestCloudGetQuote />,
  requireAuth: true,
  pageTitle: 'app.page.title.subscription_management',
  accessibleOrgRoles: [
    OrganizationRole.BILLING_MANAGER,
    OrganizationRole.OWNER,
  ],
  isRootOrAdminAccessible: true,
  type: RouteType.ORGANIZATION,
};

export const accountSubscriptionManagement: RouteConfig = {
  path: '/account/subscription-management',
  element: <AccountSubscriptionManagement />,
  requireAuth: true,
  pageTitle: 'app.page.title.account_subscription_management',
  isRootOrAdminAccessible: true,
  type: RouteType.ACCOUNT,
  accessibleAccountRoles: [AccountRole.BILLING_MANAGER, AccountRole.OWNER],
};

export const testOpsPlatformCheckout: RouteConfig = {
  path: `${accountSubscriptionManagement.path}/purchase`,
  element: <TestOpsPlatformCheckout />,
  requireAuth: true,
  pageTitle: 'app.page.title.testops_platform.choose_plan',
  isRootOrAdminAccessible: true,
  type: RouteType.ACCOUNT,
  accessibleAccountRoles: [AccountRole.BILLING_MANAGER, AccountRole.OWNER],
};

export const directToSelfServeConfirmation: RouteConfig = {
  path: `${accountSubscriptionManagement.path}/direct-to-self-serve`,
  element: <DirectToSelfServeConfirmation />,
  requireAuth: true,
  pageTitle: 'app.page.title.opt_in_self_serve',
  isRootOrAdminAccessible: true,
  type: RouteType.ACCOUNT,
  accessibleAccountRoles: [AccountRole.BILLING_MANAGER, AccountRole.OWNER],
};

export const testOpsPlatformPaymentStatus: RouteConfig = {
  path: '/testops-platform-payment-status',
  element: <PlatformCheckOutPaymentStatus />,
  requireAuth: true,
  pageTitle: 'app.page.title.payment_status',
  isRootOrAdminAccessible: true,
  type: RouteType.ACCOUNT,
  accessibleAccountRoles: [AccountRole.BILLING_MANAGER, AccountRole.OWNER],
};

export const accountHome: RouteConfig = {
  path: '/account-home',
  element: <AccountHome />,
  requireAuth: true,
  pageTitle: 'app.page.title.account.home',
  isRootOrAdminAccessible: true,
  type: RouteType.ACCOUNT,
  accessibleAccountRoles: [AccountRole.BILLING_MANAGER, AccountRole.OWNER, AccountRole.ADMIN],
};

export const paymentMethod: RouteConfig = {
  path: '/payment-method',
  element: <PaymentMethod />,
  requireAuth: true,
  pageTitle: 'app.page.title.payment_method',
  isRootOrAdminAccessible: true,
  type: RouteType.ACCOUNT,
  accessibleAccountRoles: [AccountRole.BILLING_MANAGER, AccountRole.OWNER],
};

export const accountSetting: RouteConfig = {
  path: '/account-settings',
  element: <AccountSetting />,
  requireAuth: true,
  pageTitle: 'app.page.title.account_settings',
  isRootOrAdminAccessible: true,
  type: RouteType.ACCOUNT,
  accessibleAccountRoles: [AccountRole.ADMIN, AccountRole.OWNER, AccountRole.BILLING_MANAGER],
};

export const activateLicenseServer: RouteConfig = {
  path: '/activate-license-server',
  element: <ActivateLicenseServer />,
  requireAuth: true,
  pageTitle: 'app.page.title.activate_license_server',
  isRootOrAdminAccessible: true,
  type: RouteType.ACCOUNT,
  accessibleAccountRoles: [AccountRole.ADMIN, AccountRole.OWNER],
};

export const organizationManagement: RouteConfig = {
  path: '/organization-management',
  element: <OrganizationManagement />,
  requireAuth: true,
  pageTitle: 'app.page.title.organization_management',
  isRootOrAdminAccessible: true,
  accessibleAccountRoles: [AccountRole.ADMIN, AccountRole.OWNER],
  type: RouteType.ACCOUNT,
};

export const securitySettings: RouteConfig = {
  path: '/security-settings',
  element: <SecuritySetting />,
  requireAuth: true,
  pageTitle: 'app.page.title.security_settings',
  isRootOrAdminAccessible: true,
  accessibleAccountRoles: [AccountRole.ADMIN, AccountRole.OWNER],
  type: RouteType.ACCOUNT,
};

export const distributeStudioLicenses: RouteConfig = {
  path: '/distribute-studio',
  element: <DistributeStudioLicenses />,
  requireAuth: true,
  pageTitle: 'app.page.title.account_license_management',
  isRootOrAdminAccessible: true,
  type: RouteType.ACCOUNT,
  accessibleAccountRoles: [AccountRole.ADMIN, AccountRole.OWNER, AccountRole.BILLING_MANAGER],
};

export const distributeTestOpsLicenses: RouteConfig = {
  path: '/distribute-testops',
  element: <DistributeTestOpsLicenses />,
  requireAuth: true,
  pageTitle: 'app.page.title.account_license_management',
  isRootOrAdminAccessible: true,
  type: RouteType.ACCOUNT,
  accessibleAccountRoles: [AccountRole.ADMIN, AccountRole.OWNER, AccountRole.BILLING_MANAGER],
};

export const distributeTestCloudLicenses: RouteConfig = {
  path: '/distribute-testcloud',
  element: <DistributeTestCloudLicenses />,
  requireAuth: true,
  pageTitle: 'app.page.title.account_license_management',
  isRootOrAdminAccessible: true,
  type: RouteType.ACCOUNT,
  accessibleAccountRoles: [AccountRole.ADMIN, AccountRole.OWNER, AccountRole.BILLING_MANAGER],
};

export const surveyComplete: RouteConfig = {
  path: '/survey-completed',
  element: <SurveyComplete />,
  requireAuth: false,
};

export const projectManagement: RouteConfig = {
  path: '/project-management/*',
  element: <ProjectManagementMicroApp />,
  requireAuth: true,
  accessibleOrgRoles: [
    OrganizationRole.ADMIN,
    OrganizationRole.OWNER,
    OrganizationRole.BILLING_MANAGER,
    OrganizationRole.MEMBER,
  ],
  isRootOrAdminAccessible: true,
  type: RouteType.ORGANIZATION,
};

// This is use to check route matching list only
// All page request is route to `projectManagement` page
export const projectList: RouteConfig = {
  path: '/project-management/list',
  type: RouteType.ORGANIZATION,
};

export const orgCardView: RouteConfig = {
  path: '/account/organizations',
  element: <OrgCardViewManagement />,
  requireAuth: true,
  isRootOrAdminAccessible: true,
  pageTitle: 'organization.card_view.page.title',
  type: RouteType.ACCOUNT,
};

export const accountWelcome: RouteConfig = {
  path: '/',
  element: <AccountWelcome />,
  pageTitle: 'app.page.title',
};

export const licensesAllocation: RouteConfig = {
  path: '/account/licenses-allocation/:page',
  element: <LicensesAllocation />,
  requireAuth: true,
  pageTitle: 'app.page.title.licenses_allocation',
  isRootOrAdminAccessible: true,
  type: RouteType.ACCOUNT,
  accessibleAccountRoles: [AccountRole.OWNER, AccountRole.ADMIN],
};

export const accountUserManagement: RouteConfig = {
  path: '/account/user-management/:tab',
  element: <AccountUserManagement />,
  requireAuth: true,
  pageTitle: 'app.page.title.account_user_management',
  isRootOrAdminAccessible: true,
  type: RouteType.ACCOUNT,
  accessibleAccountRoles: [AccountRole.OWNER, AccountRole.ADMIN],
};

export const retryPayment: RouteConfig = {
  path: `${accountSubscriptionManagement.path}/retry-payment`,
  element: <RetryPaymentPage />,
  requireAuth: true,
  pageTitle: 'app.page.title.retry_payment',
  isRootOrAdminAccessible: true,
  type: RouteType.ACCOUNT,
  accessibleAccountRoles: [AccountRole.BILLING_MANAGER, AccountRole.OWNER],
};

const routes: RouteConfig[] = [
  accountWelcome,
  userManagement,
  setting,
  dashboard,
  // createOrganization,
  // inviteMember,
  // organization,
  // user,
  // organizationList,
  // billingInformation,
  licenseUtilization,
  acceptInvitation,
  invalidInvitation,
  home,
  accountHome,
  acceptSsoInvitation,
  licenseManagement,
  oAuthServiceCloud,
  supportManagement,
  subscriptionManagement,
  paymentSuccess,
  testCloudSubscriptionManagement,
  testCloudCheckout,
  testCloudDashboard,
  testCloudGetQuote,
  accountSubscriptionManagement,
  testOpsPlatformCheckout,
  testOpsPlatformPaymentStatus,
  paymentMethod,
  accountSetting,
  surveyComplete,
  activateLicenseServer,
  // Disabled due to release version 2.0.0 does not contain these pages
  organizationManagement,
  securitySettings,
  // distributeStudioLicenses,
  // distributeTestOpsLicenses,
  // distributeTestCloudLicenses,
  projectManagement,
  maintenanceProcess,
  orgCardView,
  userGroup,
  userGroupDetail,
  licensesAllocation,
  accountUserManagement,
  directToSelfServeConfirmation,
  retryPayment,
  {
    path: '*',
    redirect: '/notfound',
    pageTitle: 'app.page.title.notfound',
  },
];

export interface LayoutRouteQuery {
  orgId: string;
}

export default routes;
