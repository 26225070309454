import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { OrganizationFeature } from '../../models';
import { Invoice, InvoiceState } from '../../models/invoice';
import { TestOpsEnterprisePlan } from '../../models/testOpsEnterprisePlan';
import { TestOpsPlanInterval } from '../../models/testOpsPlanInterval';

const useStyles = makeStyles(theme => ({
  tableCell: {
    padding: theme.spacing(1.75, 0),
    maxWidth: theme.spacing(33),
    '&.MuiTableCell-root': {
      paddingBottom: theme.spacing(1),
      borderBottom: '1px solid #DCDFE6',
    },
  },
  tableInnerCell: {
    padding: theme.spacing(2, 0),
    maxWidth: theme.spacing(33),
    color: '#172B4D',
  },
}));

interface InvoiceTableProps {
  invoices: (Invoice | undefined)[];
}

const InvoiceTable = (props: InvoiceTableProps) => {
  const { invoices } = props;
  const intl = useIntl();
  const classes = useStyles();
  const getPlanIntervalName = (plan: TestOpsEnterprisePlan) => {
    switch (plan?.interval) {
      case TestOpsPlanInterval.MONTH:
        return intl.formatMessage({ id: 'usage_plan_interval_monthly' });
      case TestOpsPlanInterval.YEAR:
        return intl.formatMessage({ id: 'usage_plan_interval_yearly' });
      default:
        return '';
    }
  };
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell}>
              {intl.formatMessage({ id: 'payment_success.order_summary.table.product' })}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {intl.formatMessage({
                id: 'payment_success.order_summary.table.description',
              })}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {intl.formatMessage({ id: 'payment_success.order_summary.table.amount' })}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {intl.formatMessage({ id: 'payment_success.order_summary.table.status' })}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices.map(invoice => (
            <TableRow>
              <TableCell className={classes.tableInnerCell}>
                {!invoice ? <Skeleton width="50%" /> : OrganizationFeature.getFeatureFullName(
                  invoice?.plan?.enterpriseProduct?.feature,
                )}
              </TableCell>
              <TableCell className={classes.tableInnerCell}>
                {!invoice ? <Skeleton width="50%" /> : intl.formatMessage(
                  { id: 'payment_success.order_summary.table.plan_interval' },
                  {
                    planName: getPlanIntervalName(invoice?.plan!!),
                    br: <br />,
                    startDate: intl.formatDate(new Date(invoice?.startDate || ''), {
                      month: 'long',
                      day: '2-digit',
                      year: 'numeric',
                    }),
                    endDate: intl.formatDate(new Date(invoice?.endDate || ''), {
                      month: 'long',
                      day: '2-digit',
                      year: 'numeric',
                    }),
                  },
                )}
              </TableCell>
              <TableCell className={classes.tableInnerCell}>
                {!invoice ? <Skeleton width="50%" /> : intl.formatNumber(Number(invoice?.total))}
              </TableCell>
              <TableCell className={classes.tableInnerCell}>
                {!invoice ? <Skeleton width="50%" /> : InvoiceState.getInvoiceStatus(invoice?.state, intl)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvoiceTable;
