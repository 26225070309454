import Dialog from '@mui/material/Dialog';
import makeStyles from '@mui/styles/makeStyles';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { useIntl, FormattedMessage } from 'react-intl';
import { matchPath } from 'react-router-dom';
import { surveyComplete } from '../../../../layout/routes';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
  },
  title: {
    margin: theme.spacing(0, 0, 0),
  },
  cardTitleContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: theme.spacing(0, 1, 0, 1),
  },
  dialogContent: {
    margin: theme.spacing(0, 1, 0, 1),
  },
}));

interface CancelSubscriptionDialogProps {
  isOpenCancellationSurveyDialog: boolean;
  onCompleteSurvey: Function;
  surveyUrl: string;
  width?: any;
  height?: any;
}

function CancelSubscriptionDialog(props: CancelSubscriptionDialogProps) {
  const classes = useStyles();
  const intl = useIntl();

  const {
    isOpenCancellationSurveyDialog,
    onCompleteSurvey,
    surveyUrl,
    width,
    height,
  } = props;

  const onLoad = () => {
    const iframe = (document?.querySelector('#cancellationIframe') as HTMLIFrameElement);
    try {
      if (iframe.contentWindow) {
        const path = iframe.contentWindow.location.pathname;
        if (matchPath(path, surveyComplete.path)) {
          onCompleteSurvey();
        }
      }
    } catch (e) {
      // do nothing
    }
  };

  return (
    <>
      <Dialog id="cancellation-survey-dialog" open={isOpenCancellationSurveyDialog} maxWidth="sm">
        <div className={classes.card}>
          <div className={classes.cardTitleContent}>
            <DialogTitle className={classes.title}>
              <FormattedMessage id="subscriptions.testops_platform.cancel-survey-title" />
            </DialogTitle>
          </div>
        </div>
        <DialogContent className={classes.dialogContent}>
          <iframe
            id="cancellationIframe"
            title={intl.formatMessage({ id: 'subscriptions.testops_platform.cancel-survey-title' })}
            width={width || '100%'}
            height={height || '500'}
            sandbox="allow-scripts allow-forms allow-same-origin"
            src={surveyUrl}
            onLoad={onLoad}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CancelSubscriptionDialog;
