import ClearIcon from '@mui/icons-material/Clear';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import withStyles from '@mui/styles/withStyles';
import uniq from 'lodash/fp/uniq';
import React, { useState } from 'react';
import * as yup from 'yup';
import makeStyles from '@mui/styles/makeStyles';
import { OrganizationUser } from '../../models';

const StyledAutocomplete = withStyles(theme => ({
  root: {
    padding: '0 !important',
    '& > * > *': {
      maxHeight: theme.spacing(30),
      height: '100%',
      overflow: 'auto',
    },
    '& > * > * > input': {
      padding: theme.spacing(0.5),
      paddingLeft: theme.spacing(1),
      height: theme.spacing(2.5),
      fontSize: '14px',
    },
    '& > * > * > fieldset': {
      display: 'none',
    },
    '& > *': {
      maxWidth: '100% !important',
      visibility: 'visible',
    },
    backgroundColor: 'inherit',
  },
  chipContainer: {
    width: '100% !important',
    height: '100% !important',
  },
  inputRoot: {
    paddingLeft: `${theme.spacing(0.5)} !important`,
    paddingTop: `${theme.spacing(0.5)} !important`,
    paddingBottom: `${theme.spacing(0.5)} !important`,
    border: '1px solid #D5D8DD',
    borderRadius: theme.spacing(0.75),
    padding: theme.spacing(0),
    width: '100%',
    backgroundColor: 'inherit',
  },
}))(Autocomplete);

export type EmailValidationResult = 'valid' | 'exist' | 'incorrect-format';
export interface EmailChipData {
  value: string; // chip key
  text: string; // email
  valid: EmailValidationResult;
}

export interface EmailDuplicationDictionary {
  [email: string]: boolean | undefined,
}

type ChipInputEmailsProps = Partial<React.ComponentProps<typeof StyledAutocomplete>> & {
  setCntIncorrectFormat?: React.Dispatch<React.SetStateAction<number>>;
  cntIncorrectFormat?: number;
  setCntExist?: React.Dispatch<React.SetStateAction<number>>;
  cntExist?: number;
  currentOrgUsers?: OrganizationUser[];
  setChips: React.Dispatch<React.SetStateAction<EmailChipData[]>>;
  chips: EmailChipData[];
  emailDict: React.MutableRefObject<EmailDuplicationDictionary>;
  initRows?: number;
};

const ChipInputEmails = (props: ChipInputEmailsProps) => {
  const {
    setCntIncorrectFormat,
    setCntExist,
    cntExist,
    cntIncorrectFormat,
    currentOrgUsers,
    setChips,
    chips,
    emailDict,
    initRows,
    ...others
  } = props;
  const [input, setInput] = useState<string>('');
  const theme = useTheme();
  const needCheckFormat = setCntIncorrectFormat !== undefined && cntIncorrectFormat !== undefined;
  const needCheckExistingMembers = setCntExist !== undefined
    && cntExist !== undefined && currentOrgUsers !== undefined;

  /**
   * Validate emails to see if they are duplicate with existing users or in incorrect email format
   * Then update the states to reflect the number of errors (cntExist and cntIncorrectFormat)
   * @param bulk list of emails to be checked
   * @returns list of validation results in accordance with the given emails
   */
  const areEmailsInvalid = (bulk: string[]): EmailValidationResult[] => {
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#basic_validation
    const formatValidation = bulk.map(it => (
      !yup.string()
        .matches(/^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)
        .email().isValidSync(it)
    ));
    const cntIncorrect = formatValidation.filter(it => it).length;
    if (needCheckFormat && cntIncorrect) setCntIncorrectFormat(cntIncorrectFormat + cntIncorrect);
    const existValidation = needCheckExistingMembers ? bulk.map(it => (
      currentOrgUsers.find(orgUser => orgUser.user.email === it.toLowerCase())
    )) : [];
    if (needCheckExistingMembers) {
      const cntDup = existValidation.filter(it => it).length;
      if (cntDup) setCntExist(cntExist + cntDup);
    }
    return formatValidation.map((it, index) => {
      if (it) return 'incorrect-format';
      return existValidation[index] ? 'exist' : 'valid';
    });
  };
  /**
   * Given the chip-key, return a delete handler. Before the chip is deleted,
   * subtract one from either cntExist or cntIncorrectFormat if necessary.
   * @param value "de facto" id key of the chip need to be deleted
   * @returns a lambda that, upon called, deletes the chip from the list
   */
  const handleDelete = (email: string) => () => {
    setChips(chips.filter(it => {
      if (it.text !== email) return true;
      switch (it.valid) {
        case 'exist':
          if (needCheckExistingMembers) setCntExist(cntExist - 1);
          break;
        case 'incorrect-format':
          if (needCheckFormat) setCntIncorrectFormat(cntIncorrectFormat - 1);
          break;
        default:
          break;
      }
      emailDict.current[it.text.toLowerCase()] = false;
      return false;
    }));
  };
  /**
   * Parse the input value into chips when the user enters something (from clipboard).
   * As a rule:
   *   - chips that are going to be added must not be duplicate with existing chips (by emails)
   *   - chips will be validated before adding to the list (even if they're invalid)
   * If the user is just typing normally, the function will end early.
   * @param event a keyboard event
   * @returns void
   */
  const handleUpdateInput = (newValue: string, forceUpdate?: boolean) => {
    const enteredEmails = newValue.split(/[ ,\n\t]+/);
    if (enteredEmails.length <= 1 && !forceUpdate) { // the user is just typing
      setInput(newValue);
      return;
    }
    // the user pasted a string that contains separation character
    // filter out emails that not in the dictionary
    const newEmails = uniq(enteredEmails)
      .filter(it => it.length > 0 && !emailDict.current[it.toLowerCase()]);
    newEmails.forEach(it => {
      emailDict.current[it] = true;
    });
    const newValidations = areEmailsInvalid(newEmails);
    setChips([
      ...chips,
      ...newEmails.map((it, index) => ({
        value: it,
        text: it,
        valid: newValidations[index],
      })),
    ]);
    setInput('');
  };
  /**
   * Add one email into the list. This function is triggered when one of the separation
   * key is pressed (see `newChipKeys`), or when the component ChipInput is blurred
   * @param chip the chip that's going to be added
   * @returns void
   * @see handleAdd @see handleBlur
   */
  const addEmail = (email: string) => {
    if (!email.length) return;
    setInput('');
    if (emailDict.current[email.toLowerCase()]) return;
    emailDict.current[email.toLowerCase()] = true;
    const invalid = areEmailsInvalid([email])[0];
    setChips([...chips, { value: email, text: email, valid: invalid }]);
  };
  const handleBlur = () => addEmail(input);
  const handleKeyPressed = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!input.length && event.key === 'Backspace' && chips.length > 0) {
      const lastChipEmail = chips[chips.length - 1].text;
      handleDelete(lastChipEmail)();
    }
  };
  const handleDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === ' ' || event.key === 'Alt' || event.key === 'Tab' || event.key === 'Enter') {
      handleUpdateInput(input, true);
      setInput('');
    }
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    handleUpdateInput(event.target.value);
  };
  const multilineStyles = makeStyles(theme => ({
    overrideRoot: initRows ? {
      '& > * > *': {
        minHeight: theme.spacing((initRows || 0) * 4.75),
        alignItems: 'flex-start',
        alignContent: 'start',
      },
    } : {},
  }));
  const multilineClasses = multilineStyles();

  return (
    <>
      <StyledAutocomplete
        className={multilineClasses.overrideRoot}
        multiple
        options={[]}
        freeSolo
        defaultValue={[]}
        onBlur={handleBlur}
        value={[...chips]}
        onKeyDown={event => handleDown(event)}
        disableClearable
        inputValue={input}
        renderTags={() => chips.map(
          (chip: EmailChipData) => (
            <Chip
              key={chip.value}
              label={chip.text}
              deleteIcon={<ClearIcon />}
              sx={{
                float: 'left',
                margin: 0.25,
                maxWidth: 200,
                ...(
                  chip.valid === 'valid'
                    ? {}
                    : {
                      color: theme.palette.secondary.main,
                      borderColor: theme.palette.secondary.main,
                      borderStyle: 'solid',
                      borderWidth: 'thin',
                      '& > svg': {
                        color: theme.palette.secondary.main,
                      },
                    }
                ),
              }}
              onDelete={handleDelete(chip.text)}
            />
          ),
        )}
        renderInput={params => (
          <TextField
            {...params}
            onKeyDown={handleKeyPressed}
            placeholder={(chips.length === 0 && others.placeholder) || undefined}
            onChange={event => handleInputChange(event)}
          />
        )}
        {...others}
      />
    </>
  );
};

export default ChipInputEmails;
