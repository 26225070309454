import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { unwrapResult } from '@reduxjs/toolkit';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { TestOpsPlanInterval } from '../../../models';
import { useQuery } from '../../../routes';
import { useAppDispatch } from '../../../store';
import { fromInvoices, fromTestCloudSubscriptions, fromTestOpsBillingInformation } from '../../../store/rootReducer';
import { ReactComponent as MinusButton } from '../../icons/minus-square.svg';
import { ReactComponent as PlusButton } from '../../icons/plus-square.svg';
import { getTestCloudPerSessionPlanId } from '../utils';
import { BasePurchaseInfoProps } from './TestCloudCheckout';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tableCell: {
    padding: theme.spacing(2, 0),
  },
  tableCellHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
  },
  billingCycle: {
    display: 'flex',
    flexDirection: 'row',
  },
  subTotal: {
    marginRight: theme.spacing(4),
    fontSize: theme.spacing(2),
  },
  gray: {
    color: '#172B4D',
  },
  billingInfoRow: {
    borderBottom: '2px solid #EFF6FC',
  },
  disabledHoverRow: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  inputNumber: {
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
    '-moz-appearance': 'textfield',
    '&:focus': {
      outline: 'none',
    },
    border: 'none',
    maxWidth: theme.spacing(5),
    textAlign: 'center',
  },
  inputNumberContainer: {
    maxWidth: theme.spacing(15),
    border: '1px solid #D5D8DD',
    borderRadius: '4px',
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-around',
  },
  haveaDiscount: {
    color: '#276EF1',
  },
  cancelDiscount: {
    color: '#A1A9B3',
    fontWeight: 500,
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  },
  discountIcon: {
    fontSize: `${theme.spacing(3)} !important`,
  },
  discountInput: {
    width: theme.spacing(20.625),
    paddingLeft: theme.spacing(0.5),
    '& > *': {
      '& > fieldset': {
        border: '1px solid #D5D8DD',
      },
    },
  },
  enabled: {
    '& > *': {
      backgroundColor: theme.palette.getContrastText('#000'),
    },
  },
  discountStatus: {
    fontSize: '.875rem',
  },
  discountLabel: {
    fontSize: '.875rem',
    marginRight: theme.spacing(0.875),
  },
  linkColor: {
    color: '#507dfa',
  },
  applyButton: {
    color: '#507dfa',
    fontWeight: 500,
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  },
  lastColumn: {
    textAlign: 'right',
  },
  radioButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const PerSessionWebPurchaseInfo = (props: BasePurchaseInfoProps) => {
  const { currentSubscription } = props;
  const intl = useIntl();
  const classes = useStyles();
  const { get } = useQuery();
  const { enqueueSnackbar } = useSnackbar();
  const organizationId = Number(get('orgId'));
  const initialInterval = get('interval');
  const [chosenInterval, setChosenInterval] = useState('');
  const isFromQuoteCheckout = Boolean(get('fromQuoteCheckout'));
  const isGetQuoteUrl = window.location.pathname.includes('get-quote');
  const isDisabledMonthly = (currentSubscription
    && currentSubscription.billingInterval === TestOpsPlanInterval.YEAR);
  const initialSession = Number(get('sessions'));
  const [chosenSession, setChosenSession] = useState(
    Math.max(Number.isNaN(initialSession) ? 0 : initialSession, 0),
  );
  const preview = useSelector(fromInvoices.selectPreview);
  const loadingFromServer = useSelector(fromInvoices.selectLoading);
  const [loading, setLoading] = useState(true);
  const [discountCodeOpen, setDiscountCodeOpen] = useState(false);
  const [discountCode, setDiscountCode] = useState<string>();
  const [effectiveDiscountCode, setEffectiveDiscountCode] = useState('');
  const [validDiscountCode, setValidDiscountCode] = useState<boolean | undefined>(undefined);
  const [verifyingDiscountCode, setVerifyingDiscountCode] = useState(false);
  const billingInformation = useSelector(
    fromTestOpsBillingInformation.selectTestOpsBillingInformation,
  );
  const discountCodeFromUrl = get('discountCode');

  // Stripe will throw error if the subtotal amount of the invoice is greater
  // than 1 million dollars. So we will limit the number of session user can buy to avoid this
  const MAX_NUMBER_OF_SESSIONS = chosenInterval === 'monthly' ? 6000 : 600;

  const currentBillingInterval = currentSubscription?.billingInterval === TestOpsPlanInterval.MONTH ? 'monthly' : 'annual';

  const isNotUpgrading = currentSubscription
    && chosenSession === 0
    && currentBillingInterval === chosenInterval;

  const dispatch = useAppDispatch();
  const handleMinus = () => {
    setChosenSession(it => (it > 0 ? it - 1 : 0));
  };
  const handlePlus = () => {
    setChosenSession(it => it + 1);
  };
  const doPreviewInvoice = useCallback((
    _chosenSession,
    _chosenInterval,
    _validDiscountCode,
    _effectiveDiscountCode,
    _isNotUpgrading,
    _currentSubscription,
    _maxSessions,
  ) => {
    if (_chosenSession >= 0 && (_chosenInterval === 'monthly' || _chosenInterval === 'annual')) {
      // Not call preview API (and reset preview invoice) when:
      // 1. Addtional session > maximum session (also display warning message)
      // 2. Create new purchase: addtional session = 0
      // 3. Upgrading: additional session = 0 and chosen interval = current interval
      if (_chosenSession > _maxSessions) {
        enqueueSnackbar(
          <FormattedMessage
            id="subscriptions.testcloud.upgrading.error.maximum_sessions_reached"
            values={{ maxSessions: _maxSessions }}
          />,
          {
            variant: 'error',
          },
        );
        dispatch(fromInvoices.doResetPreview());
        setLoading(false);
      } else if ((!currentSubscription && _chosenSession === 0) || _isNotUpgrading) {
        dispatch(fromInvoices.doResetPreview());
        setLoading(false);
      } else {
        setLoading(true);
        dispatch(fromInvoices.doPreviewInvoice({
          organizationId,
          planId: getTestCloudPerSessionPlanId(_chosenInterval),
          number: (_currentSubscription?.concurrentSessions ?? 0) + _chosenSession,
          discountCode: _validDiscountCode ? _effectiveDiscountCode : undefined,
          ...(_currentSubscription && { subscriptionId: _currentSubscription.id }),
        }))
          .then(unwrapResult);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const doPreviewInvoiceDebounced = useCallback(debounce(doPreviewInvoice, 1000), []);

  useEffect(() => {
    if (discountCodeFromUrl && discountCodeFromUrl !== '') {
      setDiscountCode(discountCodeFromUrl);
      handleApplyCoupon(false);
    } else {
      doPreviewInvoiceDebounced(
        chosenSession,
        chosenInterval,
        validDiscountCode,
        effectiveDiscountCode,
        isNotUpgrading,
        currentSubscription,
        MAX_NUMBER_OF_SESSIONS,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenSession, chosenInterval, billingInformation, currentSubscription, discountCodeFromUrl]);

  useEffect(() => {
    setLoading(loadingFromServer);
  }, [loadingFromServer]);

  useEffect(() => {
    if ((initialInterval === 'annual' || initialInterval === 'monthly') && !currentSubscription?.billingInterval) {
      // for creating new order case
      setChosenInterval(initialInterval);
    } else {
      // for upgrading case
      setChosenInterval(currentBillingInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialInterval]);

  const resetCouponState = () => {
    setDiscountCode('');
    setEffectiveDiscountCode('');
    setValidDiscountCode(undefined);
  };

  const reloadTestCloudSubscription = () => {
    dispatch(fromTestCloudSubscriptions
      .doGetTestCloudSubscriptionsByOrganizationId({
        organizationId: Number(organizationId) || 0,
      }));
  };

  const handleCancel = () => {
    if (preview.total !== undefined && preview.total <= 0) return;
    setDiscountCodeOpen(false);
    resetCouponState();
    handleApplyCoupon(true);
  };

  const handleApplyCoupon = (isRemoveCoupon: boolean) => {
    if ((preview.total !== undefined && preview.total <= 0) || isNotUpgrading) return;
    if (chosenSession >= 0 && (chosenInterval === 'monthly' || chosenInterval === 'annual')) {
      setLoading(true);
      if (!isRemoveCoupon && discountCode !== undefined && discountCode !== '') {
        setValidDiscountCode(undefined);
        setVerifyingDiscountCode(true);
        setEffectiveDiscountCode(discountCode);
        dispatch(fromInvoices.doPreviewInvoice({
          organizationId,
          planId: getTestCloudPerSessionPlanId(chosenInterval),
          number: (currentSubscription?.concurrentSessions ?? 0) + chosenSession,
          discountCode: discountCode || undefined,
          // Add subscriptionId params for upgrading case
          ...(currentSubscription && { subscriptionId: currentSubscription.id }),
        })).then(unwrapResult)
          .then(() => setValidDiscountCode(true))
          // TODO: define error at back-end code, temporary force as invalid discount code
          .catch(() => {
            reloadTestCloudSubscription();
            setValidDiscountCode(false);
          })
          .finally(() => setVerifyingDiscountCode(false));
      } else {
        if (discountCode === undefined || discountCode === '') {
          resetCouponState();
        }
        dispatch(fromInvoices.doPreviewInvoice({
          organizationId,
          planId: getTestCloudPerSessionPlanId(chosenInterval),
          number: (currentSubscription?.concurrentSessions ?? 0) + chosenSession,
          // Add subscriptionId params for upgrading case
          ...(currentSubscription && { subscriptionId: currentSubscription.id }),
        })).then(unwrapResult);
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(+e.target.value)) {
      setChosenSession(0);
    } else setChosenSession(+e.target.value);
  };

  const statusId = verifyingDiscountCode ? 'subscriptions.testcloud.discount_code.verifying'
    : (validDiscountCode ? 'subscriptions.testcloud.discount_code.applied'
      : validDiscountCode === false ? 'subscriptions.testcloud.discount_code.invalid' : undefined);

  const disabledInput = isNotUpgrading || chosenSession <= 0
    || verifyingDiscountCode
    || (preview.total !== undefined && preview.total <= 0)
    || (validDiscountCode && !preview.total);
  if (currentSubscription && currentSubscription.tier) return null;
  return (
    <Paper className={classes.root}>
      <Typography fontWeight="bold" className={classes.gray}>
        <FormattedMessage id="subscriptions.testcloud.plan.per_session" />
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                padding="none"
                width="40%"
                className={classes.tableCellHeader}
              >
                <FormattedMessage id="subscriptions.testcloud.subscription_summary.billing_cycle" />
              </TableCell>
              <TableCell
                padding="none"
                width="30%"
                className={classes.tableCellHeader}
              >
                <FormattedMessage id={
                  currentSubscription
                    ? 'subscriptions.testcloud.subscription_summary.additional_session'
                    : 'subscriptions.testcloud.subscription_summary.number_sessions'
                }
                />
              </TableCell>
              <TableCell
                padding="none"
                width="30%"
                className={clsx(classes.tableCellHeader, classes.lastColumn)}
              >
                <FormattedMessage id="subscriptions.testcloud.subscription_summary.tax" />
              </TableCell>
              <TableCell padding="none" />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={clsx(classes.disabledHoverRow, classes.billingInfoRow)}>
              <TableCell
                padding="none"
                className={classes.tableCell}
              >
                <RadioGroup
                  name="radio-buttons-group"
                  className={classes.billingCycle}
                  value={chosenInterval}
                  onChange={e => setChosenInterval(e.target.value)}
                >
                  <FormControlLabel value="annual" control={<Radio className={classes.radioButton} />} label="Annual" />
                  {!isFromQuoteCheckout && (<FormControlLabel value="monthly" control={<Radio className={classes.radioButton} />} label="Monthly" disabled={isDisabledMonthly || isGetQuoteUrl} />)}
                </RadioGroup>
              </TableCell>
              <TableCell
                padding="none"
                className={classes.tableCell}
              >
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  className={classes.inputNumberContainer}
                >
                  {!isFromQuoteCheckout && (<MinusButton onClick={handleMinus} />)}
                  <input
                    className={classes.inputNumber}
                    type="text"
                    pattern="[1-9]"
                    value={chosenSession > 0 ? chosenSession : ''}
                    onChange={handleInputChange}
                    disabled={isFromQuoteCheckout}
                  />
                  {!isFromQuoteCheckout && (<PlusButton onClick={handlePlus} />)}
                </Grid>
              </TableCell>
              <TableCell
                padding="none"
                className={clsx(classes.tableCell, classes.lastColumn)}
              >
                <Box display="flex" alignItems="center" justifyContent="flex-end">
                  <Typography variant="body1">
                    $
                  </Typography>
                  <Typography>
                    {loading
                      ? <Skeleton width={30} height={30} sx={{ ml: 0.5 }} />
                      : intl.formatNumber(preview.tax || 0)}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow
              className={classes.disabledHoverRow}
            >
              <TableCell
                padding="none"
                sx={{ pt: 2 }}
              >
                {
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => setDiscountCodeOpen(!discountCodeOpen)}
                    fontWeight={500}
                    sx={{ color: '#276EF1' }}
                  >
                    <FormattedMessage
                      id="subscriptions.testcloud.subscription_summary.have_discount_code"
                    />
                  </Link>
                }
              </TableCell>
              <TableCell colSpan={2} padding="none" sx={{ pt: 2 }}>
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                  <Typography variant="subtitle2" className={classes.subTotal}>
                    <FormattedMessage id="payment_method.subtotal" />
                  </Typography>
                  <Typography variant="h6" className={classes.gray}>
                    $
                  </Typography>
                  <Typography variant="h6" className={classes.gray}>
                    {
                      loading
                        ? <Skeleton width={50} height={30} sx={{ ml: 0.5 }} />
                        : intl.formatNumber(preview.total || 0)
                    }
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
            {validDiscountCode && !discountCodeOpen && (
              <Typography
                className={classes.discountStatus}
              >
                {intl.formatMessage(
                  { id: 'subscriptions.testcloud.discount_code.applied_bracket' },
                  {
                    discountCode: effectiveDiscountCode,
                    span: () => (
                      <span className={classes.linkColor}>
                        {effectiveDiscountCode}
                      </span>
                    ),
                  },
                )}
              </Typography>
            )}
            <TableRow
              className={classes.disabledHoverRow}
            >
              <TableCell colSpan={3} padding="none">
                <Collapse
                  in={discountCodeOpen}
                  unmountOnExit
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    alignContent="center"
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      alignContent="center"
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        alignContent="center"
                      >
                        <Typography
                          className={classes.discountLabel}
                        >
                          <FormattedMessage
                            id="subscriptions.testcloud.subscription_summary.discount_code"
                          />
                        </Typography>
                        <TextField
                          className={
                            disabledInput ? classes.discountInput
                              : clsx(classes.discountInput, classes.enabled)
                          }
                          value={discountCode}
                          onChange={event => setDiscountCode(event.target.value)}
                          sx={{ pl: 1 }}
                          disabled={disabledInput}
                        />
                      </Box>
                      <Box
                        display="flex"
                        alignSelf="flex-end"
                        alignContent="center"
                        sx={{ pt: 1 }}
                      >
                        {statusId && (
                          <Typography
                            className={classes.discountStatus}
                            color={validDiscountCode ? '#2bd92b' : ((validDiscountCode !== undefined) ? '#e05169' : '#faaf00')}
                          >
                            <FormattedMessage
                              id={statusId}
                              values={{ discountCode: effectiveDiscountCode }}
                            />
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="flex-end"
                      justifyContent="flex-end"
                      sx={{ pl: 9, mt: 1 }}
                    >
                      <Button
                        className={classes.cancelDiscount}
                        onClick={handleCancel}
                        variant="text"
                        startIcon={<HighlightOffIcon className={classes.discountIcon} />}
                      >
                        <FormattedMessage
                          id="subscriptions.testcloud.subscription_summary.cancel"
                        />
                      </Button>
                      <Button
                        className={classes.applyButton}
                        variant="text"
                        onClick={() => handleApplyCoupon(false)}
                        startIcon={<CheckCircleOutlineIcon className={classes.discountIcon} />}
                      >
                        <FormattedMessage
                          id="subscriptions.testcloud.subscription_summary.apply"
                        />
                      </Button>
                    </Box>
                  </Box>
                </Collapse>
              </TableCell>
              <TableCell padding="none" />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default PerSessionWebPurchaseInfo;
