import React, { useEffect, useState } from 'react';
import Icon from '@katalon-studio/katalon-ui/Icon';
import TextField from '@katalon-studio/katalon-ui/TextField';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import InputAdorment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination, { TablePaginationProps } from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import handleSort from 'lodash/orderBy';
import { useIntl } from 'react-intl';

export interface Column<T> {
  id: keyof T;
  minWidth?: number;
  width?: number;
  maxWidth?: number;
  align?: 'right';
  formatNumber?: (value: number) => string;
  render?: (row: T) => React.ReactElement;
  ignoreHeader?: boolean;
  sortable?: boolean;
  hasTooltip?: boolean;
}

export type OrderType = 'asc' | 'desc';

interface UserDataTableProps<T extends BaseData> {
  tableKey: string;
  columns: Column<T>[];
  data: T[] | undefined;
  sortBy?: keyof T;
  sortType?: OrderType;
  paginationOptions?: TablePaginationProps['rowsPerPageOptions'];
  searchable?: boolean;
  onSearchTextChange?: (value: string) => T[];
  selectable?: boolean;
  onSelectRow?: (selected: readonly number[]) => void;
  selectedRows: readonly number[];
  additionToolbar?: React.ReactElement;
  loading?: boolean;
}

interface BaseData extends Object {
  id: number;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  searchTextField: {
    width: theme.spacing(40),
    '& > .MuiInputBase-root': {
      borderRadius: theme.spacing(0.5),
      borderColor: '#dbdde5',
      paddingLeft: theme.spacing(1.5),
      backgroundColor: 'rgb(255, 255, 255)',
    },
    '& > * > .MuiInputBase-inputSizeSmall': {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      '&::placeholder': {
        color: '#808b9a',
      },
    },
  },
  inputSearch: {
    fontSize: theme.spacing(1.75),
  },
  searchIcon: {
    fontSize: theme.spacing(1.75),
    color: 'rgba(104, 109, 128, 0.54)',
  },
  boxSpacing: {
    height: theme.spacing(2),
  },
  tableHead: {
    '& th': {
      fontSize: theme.spacing(1.75),
      color: '#233145',
      fontWeight: 500,
      textTransform: 'none',
    },
  },
  tableBody: {
    '& td': {
      fontSize: theme.spacing(1.75),
      color: '#233145',
    },
  },
  tableCell: {
    fontSize: theme.spacing(1.75),
    color: '#233145',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    borderBottom: 'solid 1px #DBDDE5 !important',
  },
  tableCheckbox: {
    paddingTop: theme.spacing(1.25),
    '& > svg': {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
    },
  },
  tableSortLabel: {
    textTransform: 'none',
    '& > svg': {
      opacity: 0,
      width: theme.spacing(2.25),
      height: theme.spacing(2.25),
    },
    '& .Mui-active': {
      '& > svg': {
        opacity: 1,
      },
    },
    '&:hover': {
      color: '#233145',
      '& > svg': {
        opacity: 0.5,
      },
    },
  },
  tablePaginationSelectLabel: {
    fontSize: theme.spacing(1.5),
    color: '#808b9a',
  },
  tablePaginationSelect: {
    color: '#233145',
  },
  tablePaginationSelectIcon: {
    color: 'rgba(104, 109, 128, 0.54)',
    size: '10',
  },
  tablePaginationToolbar: {
    '& > p:nth-of-type(2)': {
      color: '#233145',
    },
  },
  noDataLabel: {
    '&:hover': {
      backgroundColor: 'unset',
    },
    '& td': {
      borderBottom: 'none',
    },
  },
}));

const CommonDataTable = <T extends BaseData>({
  tableKey,
  columns,
  data = [],
  sortBy,
  sortType,
  paginationOptions = [10, 15, 20],
  searchable = true,
  onSearchTextChange,
  selectable = true,
  onSelectRow,
  selectedRows,
  additionToolbar,
  loading: isLoading,
}: UserDataTableProps<T>) => {
  const classes = useStyles();
  const intl = useIntl();

  const [visibleData, setVisibleData] = useState<typeof data>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('id');
  const [orderType, setOrderType] = useState<OrderType>('asc');
  const [searchText, setSearchText] = useState('');
  const [numSelected, setNumSelected] = useState(0);

  const rowsInPage = handleSort(
    visibleData,
    [data => {
      const value = data[orderBy as keyof T];
      return typeof value === 'string'
        ? value.toLowerCase()
        : value;
    }],
    orderType,
  ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  useEffect(() => {
    setSearchText('');
    setVisibleData(data);
  }, [data]);

  useEffect(() => {
    if (sortBy && sortType) {
      setOrderBy(String(sortBy));
      setOrderType(sortType);
      return;
    }
    if (sortBy) {
      setOrderBy(String(sortBy));
    }
    if (sortType) {
      setOrderType(sortType);
    }
  }, [sortBy, sortType]);

  useEffect(() => {
    setNumSelected(selectedRows.length);
  }, [selectedRows]);

  const handleChangePage = (_: unknown, newPageNumber: number) => {
    setPage(newPageNumber);
    onSelectRow?.([]);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const createSortHandler = (property: keyof T) => () => {
    handleRequestSort(String(property));
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && orderType === 'asc';
    setOrderType(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    onSelectRow?.([]);
  };

  const handleClick = (_event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }
    onSelectRow?.(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rowsInPage.map(it => it.id);
      onSelectRow?.(newSelected);
      return;
    }
    onSelectRow?.([]);
  };

  const handleSearchText = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (!searchable || !onSearchTextChange) return;
    const value = event.target.value.toLowerCase();
    const trimValue = value.trim();
    if (trimValue.length > 0) {
      setSearchText(value);
      const dataFiltered = onSearchTextChange(trimValue);
      setVisibleData(dataFiltered);
      onSelectRow?.([]);
      return;
    }
    setSearchText('');
    setVisibleData(data);
  };

  const isSelected = (id: number) => selectedRows.indexOf(id) !== -1;

  return (
    <div className={classes.root}>
      <Grid container alignItems="center">
        {searchable && (
          <TextField
            id={`${tableKey}.search_box`}
            size="small"
            variant="standard"
            placeholder={intl.formatMessage({ id: `${tableKey}.search_placeholder` })}
            InputProps={{
              classes: { input: classes.inputSearch },
              disableUnderline: true,
              startAdornment: (
                <InputAdorment position="start">
                  <Icon name="fa-search" type="fa-solid" className={classes.searchIcon} />
                </InputAdorment>
              ),
            }}
            className={classes.searchTextField}
            autoComplete="off"
            onChange={handleSearchText}
            value={searchText}
          />
        )}
        { additionToolbar }
      </Grid>
      {searchable && <Box className={classes.boxSpacing} />}
      <Grid>
        <TableContainer>
          <Table aria-label="table" id={tableKey}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                {selectable && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={numSelected > 0 && numSelected < rowsInPage.length}
                      checked={rowsInPage.length > 0 && numSelected === rowsInPage.length}
                      onChange={handleSelectAllClick}
                      className={classes.tableCheckbox}
                    />
                  </TableCell>
                )}
                {columns.map(column => (
                  <TableCell
                    key={String(column.id)}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      width: column.width,
                      maxWidth: column.maxWidth,
                    }}
                  >
                    {!column.ignoreHeader
                        && !column.sortable
                        && intl.formatMessage({ id: `${tableKey}.column_${String(column.id)}` })}
                    {!column.ignoreHeader
                        && column.sortable
                        && (
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={orderBy === column.id ? orderType : 'asc'}
                          onClick={createSortHandler(column.id)}
                          className={classes.tableSortLabel}
                        >
                          {intl.formatMessage({ id: `${tableKey}.column_${String(column.id)}` })}
                        </TableSortLabel>
                        )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {rowsInPage.map(row => {
                const isItemSelected = isSelected(row.id);
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.id}
                    role="checkbox"
                    sx={{ cursor: selectable ? 'pointer' : 'none' }}
                    onClick={event => handleClick(event, row.id)}
                  >
                    {selectable && (
                      <TableCell padding="checkbox" sx={{ borderBottom: 'solid 1px #DBDDE5 !important' }}>
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          className={classes.tableCheckbox}
                        />
                      </TableCell>
                    )}
                    {columns.map(column => {
                      const value = row[column.id];
                      const fmtValue = column.formatNumber && typeof value === 'number' ? column.formatNumber(value) : value;
                      return (
                        <TableCell
                          key={String(column.id)}
                          align={column.align}
                          className={classes.tableCell}
                          style={{
                            minWidth: column.minWidth,
                            width: column.width,
                            maxWidth: column.maxWidth,
                          }}
                        >
                          {column.render?.(row)}
                          {!column.render && (
                            <Tooltip title={column.hasTooltip ? fmtValue : undefined} placement="top">
                              <span>
                                {fmtValue}
                              </span>
                            </Tooltip>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
              {visibleData.length === 0 && (
                <TableRow className={classes.noDataLabel}>
                  <TableCell
                    id={`${tableKey}.no_data`}
                    colSpan={columns.length}
                    align="center"
                  >
                    {!isLoading && (data.length > 0
                      ? intl.formatMessage({ id: 'common.message.table_no_result_found' })
                      : intl.formatMessage({ id: 'common.message.table_no_data' }))}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {visibleData.length > 0 && (
          <TablePagination
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={visibleData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            classes={{
              toolbar: classes.tablePaginationToolbar,
              selectLabel: classes.tablePaginationSelectLabel,
              select: classes.tablePaginationSelect,
              selectIcon: classes.tablePaginationSelectIcon,
              menuItem: classes.tablePaginationSelect,
            }}
          />
        )}
      </Grid>
    </div>
  );
};

export default CommonDataTable;
