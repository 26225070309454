import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useEffect, useState, useCallback, useRef, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import { useSnackbar } from 'notistack';
import { unwrapResult } from '@reduxjs/toolkit';
import capitalize from 'lodash/fp/capitalize';
import Button from '@mui/material/Button';
import TestOpsPlatformSection from './TestOpsPlatformSection';
import CancelSubscriptionDialog from './CancelSubscriptionDialog';
import {
  useAppDispatch,
  fromSubscriptions,
  fromTestOpsSubscriptions,
  fromAccounts,
} from '../../../../store';
import { fromTestOpsPlatformConfiguration, fromTestOpsPlatformSubscriptions } from '../../../../store/rootReducer';
import {
  RecurlySubscriptionPayloadStatus,
  TestOpsPlanInterval,
  TestOpsSubscription,
  User,
} from '../../../../models';
import PopoverDropdownMenu from '../../../../components/popover/PopoverDropdownMenu';
import LoadingProgress from '../../../../layout/LoadingProgress';
import {
  convertToTestOpsPlatformTierV2,
  sendTrackingData,
  UNLIMITED_PROJECT,
} from '../../utils';
import HandleSubscriptionDialog from './HandleSubscriptionDialog';
import { insertIf } from '../../testops-platform-checkout/utils';

const useStyles = makeStyles((theme => ({
  cardTitle: {
    margin: theme.spacing(2.5, 0, 2),
    fontWeight: 600,
    color: '#22283c',
    fontSize: theme.spacing(2),
  },
  card: {
    display: 'flex',
  },
  cardTitleContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  menuItem: {
    width: '100%',
    textAlign: 'left',
    color: '#b41400',
    fontWeight: 500,
  },
  menuReactivateItem: {
    width: '100%',
    textAlign: 'left',
    color: '#233145',
  },
  menuIcon: {
    display: 'flex',
  },
  menuIconContainer: {
    margin: 'auto',
    width: 25,
    '&:hover': {
      background: '#f5f5f5',
    },
  },
  supportContent: {
    marginBottom: theme.spacing(1),
  },
  trialPlanContent: {
    margin: theme.spacing(2),
    lineHeight: 2,
  },
  reactivateButton: {
    backgroundColor: '#276EF1',
    color: '#fff',
  },
  contactInfo: {
    width: '100%',
    height: theme.spacing(2),
    // flex-grow: 0;
    fontSize: theme.spacing(1.5),
    fontWeight: 'normal',
    lineHeight: 2,
    textAlign: 'left',
  },
  groupButtonAction: {
    maxWidth: '200px',
    justifyContent: 'end',
  },
  buttonAction: {
    fontSize: theme.spacing(1.75),
    fontWeight: theme.typography.fontWeightMedium,
  },
  popoverDropdownMenu: {
    marginLeft: theme.spacing(1),
  },
  iconButton: {
    backgroundColor: '#ffffff',
    color: 'black',
    fontSize: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    borderWidth: theme.spacing(0.125),
    borderColor: '#d6dae4',
    cursor: 'pointer',
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    border: '1px solid',
  },
  labelRenewalButton: {
    marginLeft: theme.spacing(2),
    backgroundColor: '#22283c80',
    color: '#ffffff',
    borderRadius: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
    '& .MuiChip-label': {
      fontSize: theme.spacing(1.5),
    },
  },
})));

export interface PlatformSubscriptionInfoProps {
  accountId: number;
  organizationId: number;
  quota: number;
  expiryDate: Date | undefined;
  billingCycle: TestOpsPlanInterval;
  currentTestOpsSubscription: TestOpsSubscription;
  hasOldModelSubscription: boolean;
  isLoadingCurrentSubscription: boolean;
  testResultCount: number;
  user: User | null;
}

const PlatformSubscriptionInfo = (props: PlatformSubscriptionInfoProps) => {
  const classes = useStyles();
  const [isOpenCancelDialog, setOpenCancelDialog] = useState(false);
  const [isOpenReactiveDialog, setOpenReactiveDialog] = useState(false);
  const [allowCancelingSubscription, setAllowCancelingSubscription] = useState(false);
  const [allowReactiveSubscription, setAllowReactiveSubscription] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [allowAutoRenew, setAllowAutoRenew] = useState(false);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const isMounted = useRef(true);

  const {
    accountId,
    organizationId,
    billingCycle,
    quota,
    expiryDate,
    currentTestOpsSubscription,
    hasOldModelSubscription,
    isLoadingCurrentSubscription,
    testResultCount,
    user,
  } = props;

  const account = useSelector(fromAccounts.selectAccountById(Number(accountId)))!!;

  const trialSubscription = useSelector(
    fromTestOpsPlatformSubscriptions.selectActiveTrialTestOpsPlatformSubscriptionByAccountId(
      Number(accountId),
    ),
  )?.[0];

  const hasPaidTestOpsPlatformSubscription = !hasOldModelSubscription
    && !!currentTestOpsSubscription;

  const hasTrialAndNonPaidTOPlatform = trialSubscription
    && !hasPaidTestOpsPlatformSubscription;

  useEffect(() => {
    // Accept cancel for start trial unlimited or paid active subscription
    setAllowReactiveSubscription((
      hasPaidTestOpsPlatformSubscription
      && currentTestOpsSubscription.recurlySubscription?.status
        === RecurlySubscriptionPayloadStatus.CANCELED
    ) && !hasTrialAndNonPaidTOPlatform);

    setAllowCancelingSubscription(!!trialSubscription);

    setAllowAutoRenew(currentTestOpsSubscription
      && currentTestOpsSubscription.recurlySubscription?.status
      !== RecurlySubscriptionPayloadStatus.CANCELED);
  }, [
    hasPaidTestOpsPlatformSubscription,
    currentTestOpsSubscription,
    hasTrialAndNonPaidTOPlatform]);

  const confirmCancelSubscription = useCallback(async () => {
    if (currentTestOpsSubscription) {
      await confirmCancelPaidTestOpsPlatform();
    } else if (trialSubscription) {
      await confirmCancelingTrial();
    }
  }, [hasPaidTestOpsPlatformSubscription, trialSubscription, currentTestOpsSubscription]);

  const confirmReactiveSubscription = useCallback(() => {
    if (isOpenReactiveDialog) {
      if (currentTestOpsSubscription.recurlySubscription) {
        setLoading(true);
        dispatch(
          fromSubscriptions
            .doReactivateSubscription(currentTestOpsSubscription.recurlySubscription),
        )
          .then(unwrapResult)
          .then(() => {
            if (isMounted.current) {
              setAllowCancelingSubscription(true);
              setAllowReactiveSubscription(false);
            }
            enqueueSnackbar(
              <FormattedMessage
                id="subscriptions.testops_platform.platform_subscription_info.reactivate.done"
                values={{
                  planName: getPlanName(),
                  b: (chunk: string[]) => (
                    <b>
                      &nbsp;
                      {chunk}
                      &nbsp;
                    </b>
                  ),
                  br: <br />,
                }}
              />,
              {
                variant: 'success',
              },
            );
          })
          .finally(() => {
            closeReactivateDialog();
            if (isMounted.current) {
              setLoading(false);
            }
          });
      }
    }
  }, [isOpenReactiveDialog]);

  // set isMounted to false when we unmount the component
  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const getCurrentQuota = () => {
    if (trialSubscription) return trialSubscription.quota;
    return currentTestOpsSubscription?.quota || quota;
  };

  const configurationOfCurrentSub = useSelector(fromTestOpsPlatformConfiguration
    .selectStairStepsByBillingCycleNumOfTestResults(getCurrentQuota(), billingCycle))!;

  const getSubscriptionName = () => {
    if (hasOldModelSubscription) {
      // case old model TO
      return capitalize(currentTestOpsSubscription?.feature.split('_')[1]);
    }

    if (trialSubscription) {
      // case for existing user just trial only
      return 'Trial';
    }

    return capitalize(
      convertToTestOpsPlatformTierV2(configurationOfCurrentSub[0]?.testOpsPlatformTier),
    );
  };

  const icon = (
    <IconButton
      id="subscriptions.testops_platform.platform_subscription_info.cancel_trial.btn"
      className={classes.iconButton}
    >
      <MoreHorizIcon className={classes.menuIcon} />
    </IconButton>
  );

  const handleCancelTrial = () => {
    setOpenCancelDialog(true);
    sendTrackingData(
      'your_subs_cancellation_clicked',
      account?.id!!,
      organizationId,
    );
  };

  const handleReactivate = () => {
    setOpenReactiveDialog(true);
  };

  const closeDialog = () => {
    setOpenCancelDialog(false);
  };

  const closeReactivateDialog = () => {
    setOpenReactiveDialog(false);
  };

  const renderCancelDialog = () => {
    let planName = 'subscriptions.testops_platform.platform_subscription_info.trial_plan';
    let id = 'subscriptions.testops_platform.platform_subscription_info.cancel_trial';
    let buttonTitleId = 'subscriptions.testops_platform.cancel.cancel_trial';

    if (currentTestOpsSubscription) {
      const prefixPackage = intl.formatMessage({
        id: hasOldModelSubscription
          ? 'subscription.testops_platform.title_plan_name.suffix_testops'
          : 'subscriptions.testops_platform.testops_platform',
      });
      planName = `${prefixPackage} ${getSubscriptionName()}`;
      id = 'subscriptions.testops_platform.platform_subscription_info.cancel';
      buttonTitleId = 'subscriptions.testops_platform.platform_subscription_info.button.confirm';
    }

    return (
      <CancelSubscriptionDialog
        isOpenCancelDialog={isOpenCancelDialog}
        handleCancelSubscription={confirmCancelSubscription}
        handleCloseDialog={closeDialog}
        organizationId={organizationId}
        email={user?.email || ''}
        accountId={accountId}
        title={(
          <FormattedMessage
            id={id}
          />
        )}
        messageText={(
          <>
            <FormattedMessage
              id={`${id}.message`}
              values={{
                planName: <FormattedMessage id={planName} />,
                b: (chunk: string[]) => (<b>{chunk}</b>),
                br: <br />,
              }}
            />
          </>
        )}
        buttonTitleId={buttonTitleId}
      />
    );
  };

  const getPlanName = () => {
    const prefixPackage = intl.formatMessage({
      id: 'subscriptions.testops_platform.testops_platform',
    });
    return `${prefixPackage} ${getSubscriptionName()}`;
  };

  const renderReactivateDialog = () => (
    <HandleSubscriptionDialog
      id="reactive-dialog"
      isOpenDialog={isOpenReactiveDialog}
      handleCloseDialog={closeReactivateDialog}
      title={(
        <FormattedMessage
          id="subscriptions.testops_platform.platform_subscription_info.reactivate"
        />
      )}
      messageText={(
        <>
          <FormattedMessage
            id="subscriptions.testops_platform.platform_subscription_info.reactivate.message"
            values={{
              planName: getPlanName(),
              b: (chunk: string[]) => (<b>{chunk}</b>),
              span: (chunk: ReactNode) => (<span>{chunk}</span>),
              br: <br />,
            }}
          />
        </>
      )}
      handleButton={(
        <Button
          className={classes.reactivateButton}
          size="medium"
          variant="contained"
          color="primary"
          onClick={confirmReactiveSubscription}
        >
          <FormattedMessage
            id="subscriptions.testops_platform.platform_subscription_info.button.confirm"
          />
        </Button>
      )}
    />
  );

  const handleRefreshPlatform = () => {
    const fetchInfo = async () => {
      await Promise.all([
        dispatch(fromTestOpsPlatformSubscriptions
          .doGetAllTestOpsPlatformSubscriptionsByAccountId({
            accountId: Number(accountId),
            checkHasPremierSuccess: true,
          })),
        dispatch(fromTestOpsSubscriptions.doGetActiveByAccountId({
          accountId: Number(accountId),
        })),
      ]);
    };
    // eslint-disable-next-line no-console
    fetchInfo().catch(console.error);
  };

  const confirmCancelingTrial = async () => {
    await dispatch(
      fromTestOpsPlatformSubscriptions
        .doCancelUnlimitedTrial({ accountId }),
    )
      .then(unwrapResult)
      .then(() => {
        handleRefreshPlatform();
        enqueueSnackbar(
          <FormattedMessage
            id="subscription.testops_platform.success.cancel-subscription"
          />,
          { variant: 'success' },
        );
      })
      .finally(closeDialog);
  };

  const confirmCancelPaidTestOpsPlatform = async () => {
    if (currentTestOpsSubscription.recurlySubscription) {
      setLoading(true);
      await dispatch(
        fromSubscriptions
          .doCancelSubscription(currentTestOpsSubscription.recurlySubscription),
      )
        .then(unwrapResult)
        .then(() => {
          if (isMounted) {
            setAllowCancelingSubscription(false);
            setAllowAutoRenew(false);
            setAllowReactiveSubscription(true);
          }
          enqueueSnackbar(
            <FormattedMessage
              id="subscriptions.testops_platform.platform_subscription_info.cancel.done"
              values={{
                planName: getPlanName(),
                b: (chunk: string[]) => (
                  <b>
                    &nbsp;
                    {chunk}
                    &nbsp;
                  </b>
                ),
                br: <br />,
              }}
            />,
            {
              variant: 'success',
            },
          );
        })
        .finally(() => {
          closeDialog();
          if (isMounted) {
            setLoading(false);
          }
        });
    }
  };

  const items: React.ReactElement[] = [
    ...insertIf(
      allowCancelingSubscription || allowAutoRenew, (
        <MenuItem className={classes.menuItem} onClick={() => handleCancelTrial()}>
          <FormattedMessage id={
            allowCancelingSubscription && !hasPaidTestOpsPlatformSubscription
              ? 'subscriptions.testops_platform.platform_subscription_info.cancel_trial'
              : 'subscriptions.testops_platform.platform_subscription_info.cancel'
          }
          />
        </MenuItem>
      ),
    ),
  ];

  const renderActionButton = () => {
    const id = allowReactiveSubscription
      ? 'subscriptions.testops_platform.platform_subscription_info.reactivate'
      : 'subscriptions.testops_platform.platform_subscription_info.upgrade';

    return (
      <Grid
        key="subscriptions.testops_platform.platform_subscription_info.grid_container"
        container
        className={classes.groupButtonAction}
      >
        { allowReactiveSubscription && (
          <Grid
            key="subscriptions.testops_platform.platform_subscription_info.update"
            item
          >
            <Button
              id={`${id}.btn`}
              variant="contained"
              color="primary"
              onClick={handleReactivate}
              className={classes.buttonAction}
            >
              <FormattedMessage id={id} />
            </Button>
          </Grid>
        )}
        { (allowCancelingSubscription || allowAutoRenew) && (
          <Grid
            key="subscriptions.testops_platform.platform_subscription_info.menu"
            item
            className={classes.popoverDropdownMenu}
          >
            <PopoverDropdownMenu
              icon={icon}
              items={items}
            />
          </Grid>
        )}
      </Grid>
    );
  };

  const getSubscriptionNameAndBillingCycle = () => {
    const planName = TestOpsPlanInterval.getPlanIntervalName(billingCycle);
    // add trial 30 day suffix if it's montly trial (not grant trial via API)
    if (trialSubscription?.billingCycle === TestOpsPlanInterval.MONTH) {
      return intl.formatMessage({
        id: 'subscription.title_plan_name.trial_monthly_suffix',
      });
    }

    if (planName === '-') {
      return '';
    }

    return ` - Billed ${planName}`;
  };

  return (
    <>
      {!isLoadingCurrentSubscription
        && configurationOfCurrentSub
        && configurationOfCurrentSub[0].executionTestResults > 0 && (
        <>
          <div id="platform-subscription-info" className={classes.card}>
            <div className={classes.cardTitleContent}>
              <Typography variant="h3" className={classes.cardTitle}>
                {
                  hasOldModelSubscription
                    ? <FormattedMessage id="subscription.testops_platform.title_plan_name.suffix_testops" />
                    : <FormattedMessage id={`subscriptions.testops_platform.testops_platform.${configurationOfCurrentSub[0].executionTestResults}`} />
                }
                {getSubscriptionNameAndBillingCycle()}
                {
                  allowReactiveSubscription && (
                    <>
                      <Chip
                        id="subscription.testops_platform.auto_reviewal.status.btn"
                        sx={{ color: '#22283C' }}
                        className={classes.labelRenewalButton}
                        label={<FormattedMessage id="subscriptions.testops_platform.platform_subscription_info.auto_renewal.turnedoff" />}
                      />
                    </>
                  )
                }
                <Typography className={classes.contactInfo}>
                  <FormattedMessage
                    id="subscription.testops_platform.support.note"
                    values={{
                      email: <a target="_blank" href="mailto: success@katalon.com" rel="noreferrer">Contact us</a>,
                    }}
                  />
                </Typography>
              </Typography>
              {!isLoading && renderActionButton()}
            </div>
          </div>
          <TestOpsPlatformSection
            numOfTestExecutions={getCurrentQuota()}
            numOfActiveProjects={currentTestOpsSubscription
              ? (currentTestOpsSubscription?.activeProjects || UNLIMITED_PROJECT)
              : configurationOfCurrentSub[0]?.activeProjects}
            numOfOrganizations={1}
            expiryDate={expiryDate}
            trialSubscription={trialSubscription}
            recurlySubscription={currentTestOpsSubscription?.recurlySubscription}
            testResultCount={testResultCount}
          />
          {renderCancelDialog()}
          {renderReactivateDialog()}
          {isLoading && <LoadingProgress />}
        </>
      )}
    </>
  );
};

export default PlatformSubscriptionInfo;
