import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import PromotionBanner from '../subscription-management/katalon-offering-section/PromotionBanner';
import SubscriptionDetail from './SubscriptionDetail';
import {
  fromInvoices,
} from '../../../store/rootReducer';

const useStyles = makeStyles((theme => ({
  rootContainer: {
    boxShadow: '0 8px 16px 0 rgba(24, 71, 202, 0.08)',
    margin: theme.spacing(0, 2, 4),
  },
  defaultContainer: {
    padding: theme.spacing(1, 4, 0.5, 4),
    borderRadius: theme.spacing(0.5),
    background: 'white',
  },
  description: {
    color: '#233145',
    fontWeight: 500,
    fontSize: 14,
  },
  productsContainer: {
    marginRight: theme.spacing(4),
  },
  questionsContainer: {
    paddingTop: theme.spacing(0.5),
  },
  title: {
    color: '#233145',
    fontWeight: 600,
    fontSize: 16,
    marginBottom: 0,
  },
  question: {
    color: '#000000',
    fontWeight: 400,
    fontSize: 14,
  },
  headerTableCell: {
    padding: theme.spacing(1, 0),
    fontSize: 14,
    fontWeight: 600,
    color: '#808b9a',
    textTransform: 'none',
  },
})));

const PromotionSection = () => {
  const classes = useStyles();
  const renewalInvoices = useSelector(fromInvoices.selectRenewalInvoices);

  const renderContent = () => (
    <div>
      <p className={classes.description}>
        <FormattedMessage id="direct.selfserve.description" />
      </p>
      {renderListProducts()}
      {renderListQuestions()}
    </div>
  );

  const renderListProducts = () => (
    <div className={classes.productsContainer}>
      <p className={classes.title}>
        <FormattedMessage id="promotion.upcoming.renewal.title" />
      </p>
      <Box>
        <Grid container>
          <Grid item xs={4} className={classes.headerTableCell}>
            <FormattedMessage id="subscriptions.testops_platform.subscription_summary.name" />
          </Grid>
          <Grid item xs={2} className={classes.headerTableCell}>
            <FormattedMessage id="subscriptions.testops_platform.quantity" />
          </Grid>
          <Grid item xs={2.5} className={classes.headerTableCell}>
            <FormattedMessage id="subscriptions.testops_platform.charge_date" />
          </Grid>
          <Grid item xs={3} className={classes.headerTableCell}>
            <FormattedMessage id="subscriptions.testops_platform.origin_discount_price" />
          </Grid>
        </Grid>
        <Divider />
        {renewalInvoices.map(invoice => (
          <SubscriptionDetail
            key={invoice.nameProduct}
            invoice={invoice}
          />
        ))}
      </Box>
    </div>
  );

  const renderListQuestions = () => (
    <div className={classes.questionsContainer}>
      <p className={classes.title}>
        <FormattedMessage id="promotion.question.title" />
      </p>
      <div>
        <p className={classes.question}>
          <FormattedMessage id="promotion.question1" />
        </p>
        <p className={classes.question}>
          <FormattedMessage id="promotion.question2" />
        </p>
        <p className={classes.question}>
          <FormattedMessage id="promotion.question3" />
        </p>
      </div>
    </div>
  );

  return (
    <>
      <div id="direct-selfserve-section" className={classes.rootContainer}>
        {/* Banner */}
        <PromotionBanner
          title="promote_banner.direct.selfserve"
          percent=""
        />
        <Paper
          elevation={0}
          className={classes.defaultContainer}
        >
          {renderContent()}
        </Paper>
      </div>
    </>
  );
};

export default PromotionSection;
