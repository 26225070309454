import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { ReactNode } from 'react';
import { fromAccountUsers, fromAccounts, fromAuth } from '../../store';
import UpgradeAccountView from './components/UpgradeAccountView';
import { notFound, useNavigate, useQuery } from '../../routes';
import { useLaunchDarkly } from '../../launchdarkly';
import LicensesAllocationDetail from './components/LicenseAllocationDetail';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    height: '100%',
    width: '100%',
    borderRadius: '.5rem',
    overflowY: 'auto',
    minWidth: theme.spacing(96),
  },
  title: {
    marginTop: theme.spacing(2),
    fontSize: theme.spacing(3),
    fontWeight: 500,
  },
  description: {
    marginBottom: theme.spacing(2),
    fontSize: theme.spacing(1.5),
    fontWeight: 500,
    color: '#808b9a',
  },
}));

const LicensesAllocation = () => {
  const intl = useIntl();
  const query = useQuery();
  const classes = useStyles();
  const { flags, initialized } = useLaunchDarkly();
  const { replace, replaceQuery } = useNavigate();

  const accountId = Number(query.get('accountId'));

  const isLoadingAccount = useSelector(fromAccounts.selectLoading);
  const isLoadingAccountUser = useSelector(fromAccountUsers.selectCurrentAccountUserLoading);
  const { isPaid } = useSelector(fromAccounts.selectFreeKsExpiry) ?? {};
  const user = useSelector(fromAuth.selectUser);
  const accountUser = useSelector(fromAccountUsers.selectOneByUserIdAndAccountId(
    Number(user?.id),
    Number(accountId),
  ));

  if (isLoadingAccount || isLoadingAccountUser || !initialized) {
    return null;
  }

  if (!flags?.licenseAllocationEnabled) {
    replace(notFound.path, replaceQuery({}));
    return null;
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>
        {intl.formatMessage({ id: 'licenses.allocation.title' })}
      </Typography>
      <Typography className={classes.description}>
        {intl.formatMessage({ id: 'licenses.allocation.description' }, {
          a: (data: ReactNode) => (
            <Link
              color="#598ef9"
              underline="hover"
              href="https://docs.katalon.com/"
              target="_blank"
            >
              {data}
            </Link>
          ),
        })}
      </Typography>
      {!isPaid && (
        <UpgradeAccountView
          accountId={Number(accountId)}
          accountUserRole={accountUser?.role}
        />
      )}
      {isPaid && (
        <LicensesAllocationDetail currentAccountUser={accountUser} />
      )}
    </div>
  );
};

export default LicensesAllocation;
