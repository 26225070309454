export enum AuthOdicGrantType {
  AUTHORIZATION_CODE = 'AUTHORIZATION_CODE',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
}

export interface AuthOidcTokenParams {
  code: string;
  redirectUri: string;
  grantType: AuthOdicGrantType;
  refreshToken: string;
}
