enum KatalonOffering {
  PLATFORM = 'PLATFORM',
  STANDALONE = 'STANDALONE',
}

namespace KatalonOffering {
  export const fromString = (str: string) => {
    if (str === 'PLATFORM') {
      return KatalonOffering.PLATFORM;
    }
    if (str === 'STANDALONE') {
      return KatalonOffering.STANDALONE;
    }
    return (KatalonOffering as any)[str];
  };
}

export { KatalonOffering };
