import Paper from '@mui/material/Paper';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { unwrapResult } from '@reduxjs/toolkit';
import memorize from 'lodash/fp/memoize';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import CustomSwitch from '../../components/switch/CustomSwitch';
import LoadingProgress from '../../layout/LoadingProgress';
import {
  isStudioFeature,
  isTestOpsFeature,
  OrganizationFeature,
  OrganizationRole,
  OrganizationUser,
  ServiceCloudOrganizationUser,
  Subscription,
  TestOpsSubscriptionSource,
} from '../../models';
import { getAvatarSource, getFullNameUser } from '../../models/user';
import { useQuery } from '../../routes';
import {
  ERROR_SERVICE_CLOUD_CANNOT_ASSIGN_OWNER,
  ERROR_SERVICE_CLOUD_USER_ALREADY_ASSIGNED,
  ERROR_SERVICE_CLOUD_INVALID_ID,
  ERROR_SERVICE_CLOUD_QUOTA_EXCEEDED,
  ERROR_SERVICE_CLOUD_USER_DOES_NOT_EXIST,
  ERROR_SERVICE_CLOUD_USER_DOES_NOT_HAVE_PERMISSION,
  ERROR_SERVICE_CLOUD_DESELECT_ONLY_MEMBER,
} from '../../store/serviceCloudOrganizationUserServiceSlice';
import {
  fromOrganizationUsers,
  fromServiceCloudOrganizationUser,
  fromSubscriptions,
  useAppDispatch,
} from '../../store';
import Summary from './Summary';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    height: '100%',
    overflowY: 'auto',
  },
  title: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2),
    display: 'flex',
    fontSize: theme.spacing(3),
  },
  subtitle: {
    marginTop: theme.spacing(1.375),
    marginBottom: theme.spacing(4),
    fontSize: theme.spacing(2),
    fontWeight: theme.typography.fontWeightRegular,
    color: '#516393',
  },
  link: {
    textDecoration: 'none',
    fontWeight: theme.typography.fontWeightBold,
    color: '#276EF1',
  },
  summary: {
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2.5, 4),
    borderRadius: theme.spacing(1),
  },
  searchContainer: {
    margin: theme.spacing(1.5, 0),
  },
  list: {
    margin: theme.spacing(2, 0),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderRadius: theme.spacing(1),
  },
  listTitle: {
    margin: theme.spacing(0, 0, 1.5, 0),
    paddingTop: theme.spacing(2.5),
    color: '#516393',
  },
  warning: {
    padding: theme.spacing(1.25),
    borderRadius: theme.spacing(0.5),
    backgroundColor: '#E5EDFF',
  },
  search: {
    marginRight: theme.spacing(0.5),
    width: theme.spacing(35),
    '& > .MuiInputBase-root': {
      borderRadius: theme.spacing(0.5),
      maxHeight: theme.spacing(4),
      paddingLeft: theme.spacing(1),
    },
    '& > * > .MuiInputBase-inputSizeSmall': {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
    },
  },
  inputSearch: {
    fontSize: '0.875rem',
  },
  searchIcon: {
    fontSize: theme.spacing(2.1),
    color: '#284169',
  },
  noResultFound: {
    textAlign: 'center',
    margin: theme.spacing(2, 0),
  },
  table: {
    padding: 0,
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
  tableCell: {
    padding: theme.spacing(1, 0),
  },
  paginationBox: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: '#1D3066',
    paddingBottom: theme.spacing(2),
  },
}));

interface ServiceCloudOrganizationUserField {
  fullName: string;
  email: string;
  avatar: string;
  organizationId: number;
  role: OrganizationRole,
  serviceCloudId?: number;
}

const getData = memorize((
  { userOrganizations, serviceClouds, quota, hasTestOpsSubscription }: {
    userOrganizations: OrganizationUser[],
    serviceClouds: ServiceCloudOrganizationUser[],
    quota: number,
    hasTestOpsSubscription: boolean,
  },
) => userOrganizations
  .map(userOrganization => ({
    email: userOrganization.user.email,
    organizationId: userOrganization.organizationId,
    fullName: getFullNameUser(
      userOrganization.user.firstName,
      userOrganization.user.lastName,
      userOrganization.user.email,
    ),
    role: userOrganization.role,
    avatar: getAvatarSource(userOrganization.user.avatar),
    serviceCloudId: (quota > 0 || hasTestOpsSubscription) ? serviceClouds.find(
      it => it.testOpsUserId === userOrganization.userId,
    )?.id : undefined,
  })));

const getMatchedRows = memorize((
  { rows, currentSearchText }: {
    rows: ServiceCloudOrganizationUserField[],
    currentSearchText: string,
  },
) => rows
  .sort((a, b) => {
    // sort by role: owner -> before
    if (a.role === OrganizationRole.OWNER) return -1;
    if (b.role === OrganizationRole.OWNER) return 1;
    // sort by selected
    if (a.serviceCloudId && b.serviceCloudId) return 0;
    if (a.serviceCloudId) return -1;
    if (b.serviceCloudId) return 1;
    return 0;
  })
  .filter(
    it => it.email.toLowerCase().includes(currentSearchText)
      || it.fullName.toLowerCase().includes(currentSearchText),
  ));

const hasTripleQuota = (feature: OrganizationFeature) => [
  OrganizationFeature.UNLIMITED_ENGINE,
  OrganizationFeature.UNLIMITED_KSE,
  OrganizationFeature.PER_USER_KSE_OFFLINE,
  OrganizationFeature.UNLIMITED_ENGINE_OFFLINE,
].includes(feature);

const getQuota = memorize((subscriptions: Subscription[]) => subscriptions
  .filter(it => it.source === TestOpsSubscriptionSource.RECURLY) // quota not count trial request
  .filter(it => isStudioFeature(it.feature))
  .reduce((quota, current) => quota + (hasTripleQuota(current.feature)
    ? current.quantity * 3 : current.quantity), 0));

const getKatalonPlatformQuota = memorize((subscriptions: Subscription[]) => subscriptions
  .filter(it => it.source === TestOpsSubscriptionSource.RECURLY)
  .filter(it => OrganizationFeature.TESTOPS_PLATFORM === it.feature)
  .reduce((quota, current) => quota + (current.quantity <= 10000
    ? 1 : 3), 0));

const ROWS_PER_PAGE = 30;

const SupportManagement = () => {
  const classes = useStyles();
  const { get } = useQuery();
  const { enqueueSnackbar } = useSnackbar();
  const orgId = get('orgId');
  const orgIdNumber = Number(orgId) || 0;
  const updatingEmails = new Set<string>();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [currentSearchText, setCurrentSearchText] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const serviceClouds = useSelector(fromServiceCloudOrganizationUser
    .selectByOrganizationId(orgIdNumber));
  const userOrganizations = useSelector(fromOrganizationUsers
    .selectByOrganizationId(orgIdNumber));
  const errors = useSelector(fromServiceCloudOrganizationUser.selectErrors());
  const subscriptions = useSelector(fromSubscriptions.selectSubscriptionByOrganizationId(
    orgIdNumber,
  ));
  const hasTestOpsSubscription = subscriptions
    .filter(it => isTestOpsFeature(it.feature))
    .length > 0;
  const quota = getQuota(subscriptions) + getKatalonPlatformQuota(subscriptions);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase().trim();
    setCurrentSearchText(value);
    if (page !== 1) setPage(1);
  };

  const rows: ServiceCloudOrganizationUserField[] = getData({
    userOrganizations,
    serviceClouds,
    quota,
    hasTestOpsSubscription,
  });

  const matchedRows = getMatchedRows({ rows, currentSearchText });

  const currentPageRows = matchedRows
    .slice((page - 1) * ROWS_PER_PAGE, ((page - 1) * ROWS_PER_PAGE) + ROWS_PER_PAGE);

  useEffect(() => {
    if (!orgId) return;

    const fetchOrganizationData = async (organizationId: number) => {
      setLoading(true);
      await Promise.all([
        dispatch(fromOrganizationUsers.doGetAllOrgUsers({
          organizationId,
        })),
        dispatch(fromServiceCloudOrganizationUser.doGetAllServiceCloudOrganizationUsers({
          organizationId,
        })),
        dispatch(fromSubscriptions.doGetActiveSubscriptionsByOrgId({
          organizationId,
        })),
      ]);
      setLoading(false);
    };
    fetchOrganizationData(+orgId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  useEffect(() => {
    if (errors && errors[0]) {
      switch (errors[0].message) {
        case ERROR_SERVICE_CLOUD_QUOTA_EXCEEDED:
          enqueueSnackbar(
            <FormattedMessage id="support_management.update.quota_exceeded" />,
            {
              variant: 'error',
              preventDuplicate: true,
              key: intl.formatMessage({ id: 'support_management.update.quota_exceeded' }),
              autoHideDuration: 2000,
              resumeHideDuration: 0,
            },
          );
          dispatch(fromServiceCloudOrganizationUser.doResetErrors());
          break;
        case ERROR_SERVICE_CLOUD_CANNOT_ASSIGN_OWNER:
        case ERROR_SERVICE_CLOUD_USER_ALREADY_ASSIGNED:
        case ERROR_SERVICE_CLOUD_INVALID_ID:
        case ERROR_SERVICE_CLOUD_USER_DOES_NOT_EXIST:
        case ERROR_SERVICE_CLOUD_USER_DOES_NOT_HAVE_PERMISSION:
          enqueueSnackbar(
            <FormattedMessage id="support_management.update.failed_general" />,
            {
              variant: 'error',
              preventDuplicate: true,
              key: intl.formatMessage({ id: 'support_management.update.failed_general' }),
              autoHideDuration: 2000,
              resumeHideDuration: 0,
            },
          );
          dispatch(fromServiceCloudOrganizationUser.doResetErrors());
          break;
        case ERROR_SERVICE_CLOUD_DESELECT_ONLY_MEMBER:
          enqueueSnackbar(
            <FormattedMessage id="support_management.update.last_deselect" />,
            {
              variant: 'error',
              preventDuplicate: true,
              key: intl.formatMessage({ id: 'support_management.update.last_deselect' }),
              autoHideDuration: 2000,
              resumeHideDuration: 0,
            },
          );
          dispatch(fromServiceCloudOrganizationUser.doResetErrors());
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [errors]);

  const handlePageChange = (_: React.ChangeEvent<unknown> | null, value: number) => {
    setPage(value);
  };

  const handleChange = async (row: ServiceCloudOrganizationUserField) => {
    if (updatingEmails.has(row.email) || loading) return;
    if (quota === 0 && !hasTestOpsSubscription) {
      enqueueSnackbar(
        <FormattedMessage id="support_management.update.subscribe" />,
        {
          variant: 'error',
          preventDuplicate: true,
          key: intl.formatMessage({ id: 'support_management.update.subscribe' }),
          autoHideDuration: 2000,
          resumeHideDuration: 0,
        },
      );
      return;
    }
    updatingEmails.add(row.email);
    try {
      setLoading(true);
      if (row.serviceCloudId !== undefined) {
        await dispatch(fromServiceCloudOrganizationUser
          .doDeleteServiceCloudOrganizationUserById({
            id: row.serviceCloudId,
          }))
          .then(unwrapResult)
          .then(() => {
            enqueueSnackbar(
              <FormattedMessage id="support_management.update.success" />,
              {
                variant: 'success',
                preventDuplicate: true,
                key: intl.formatMessage({ id: 'support_management.update.success' }),
                autoHideDuration: 2000,
                resumeHideDuration: 0,
              },
            );
            setLoading(false);
          });
      } else {
        await dispatch(fromServiceCloudOrganizationUser
          .doCreateServiceCloudOrganizationUser({
            organizationId: row.organizationId,
            email: row.email,
          }))
          .then(unwrapResult)
          .then(() => {
            enqueueSnackbar(
              <FormattedMessage id="support_management.update.success" />,
              {
                variant: 'success',
                preventDuplicate: true,
                key: intl.formatMessage({ id: 'support_management.update.success' }),
                autoHideDuration: 2000,
                resumeHideDuration: 0,
              },
            );
            setLoading(false);
          });
      }
      // eslint-disable-next-line no-empty
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error(e.message);
      setLoading(false);
    }
    updatingEmails.delete(row.email);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h2" className={classes.title}>
        <FormattedMessage id="support_management.title" />
      </Typography>
      <div className={classes.subtitle}>
        <Typography>
          {intl.formatMessage({ id: 'support_management.subtitle1' })}
        </Typography>
        <Typography>
          {intl.formatMessage(
            { id: 'support_management.subtitle2' },
            {
              a: () => (
                <a href={`${process.env.REACT_APP_SERVICE_CLOUD_URL}`} target="_blank" rel="noopener noreferrer" className={classes.link}>
                  <FormattedMessage id="katalon.help.center" />
                </a>
              ),
            },
          )}
        </Typography>
      </div>
      <Grid container className={classes.summary}>
        {!loading && (
          <Summary
            assignedSlots={serviceClouds.length}
            quota={quota}
            hasTestOpsSubscription={hasTestOpsSubscription}
          />
        )}
      </Grid>
      <Paper className={classes.list} elevation={0}>
        <Grid>
          <Typography fontWeight="bold" variant="h3" className={classes.listTitle}>
            <FormattedMessage id="support_management.list.title" />
          </Typography>
        </Grid>
        <Box className={classes.warning}>
          <Typography>
            <FormattedMessage id="support_management.list.warning" />
          </Typography>
        </Box>
        <Box className={classes.searchContainer}>
          <TextField
            onChange={handleSearchChange}
            size="small"
            variant="standard"
            placeholder={intl.formatMessage({ id: 'support_management.search.placeholder' })}
            InputProps={{
              classes: { input: classes.inputSearch },
              disableUnderline: true,
              startAdornment: (<InputAdornment position="start"><SearchIcon className={classes.searchIcon} /></InputAdornment>),
            }}
            className={classes.search}
            autoComplete="off"
          />
        </Box>
        <TableContainer className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>
                  <FormattedMessage id="support_management.list.full_name" />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <FormattedMessage id="support_management.list.email" />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <FormattedMessage id="support_management.list.assign" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {matchedRows.length > 0 ? currentPageRows
                .map(it => (
                  <TableRow key={it.email}>
                    <TableCell
                      className={classes.tableCell}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        textAlign="center"
                      >
                        <Avatar
                          className={classes.avatar}
                          src={it.avatar || 'https://katalon-testops.s3.amazonaws.com/image/icon/defaultAvatar.png'}
                        >
                          <PersonIcon />
                        </Avatar>
                        {it.fullName}
                      </Box>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {it.email}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <CustomSwitch
                        checked={
                          it.serviceCloudId !== undefined
                          || ((quota > 0 || hasTestOpsSubscription)
                            && it.role === OrganizationRole.OWNER)
                        }
                        onChange={() => handleChange(it)}
                        disabled={it.role === OrganizationRole.OWNER && (
                          hasTestOpsSubscription || quota > 0)}
                      />
                    </TableCell>
                  </TableRow>
                )) : (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Typography className={classes.noResultFound} variant="subtitle2">
                        {rows.length > 0 ? (
                          <FormattedMessage id="user_management.no_result_found" />
                        ) : (
                          <FormattedMessage id="license_utilization.empty_data" />
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {matchedRows.length > 0 && (
          <Box className={classes.paginationBox}>
            <Pagination
              shape="rounded"
              count={Math.ceil(matchedRows.length / ROWS_PER_PAGE)}
              page={page}
              onChange={handlePageChange}
            />
            <Typography variant="body2">
              <FormattedMessage
                id={matchedRows.length > 1 ? 'support_management.list.total_records'
                  : 'support_management.list.total_record'}
                values={{ count: matchedRows.length }}
              />
            </Typography>
          </Box>
        )}
      </Paper>
      {loading && <LoadingProgress />}
    </div>
  );
};

export default SupportManagement;
