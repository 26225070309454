import { createAsyncThunk, createSelector, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { AppState } from '.';
import { UserGroupUserService } from '../services';
import { UserGroupUser } from '../models/userGroup';

export const USER_GROUP_USER_FEATURE_KEY = 'userGroupUser';

interface UserGroupUserState extends EntityState<UserGroupUser> {
  loading: { [key: string]: boolean };
  error?: string;
}

const userGroupUserAdapter = createEntityAdapter<UserGroupUser>();

export const createInitialState = (): UserGroupUserState => (
  userGroupUserAdapter.getInitialState({
    loading: {},
  })
);

export const getAllUserGroupUsers = createAsyncThunk(
  'userGroup/getAllUserGroupUsers',
  async (input: Required<Pick<UserGroupUser, 'userGroupId'>>) => {
    // @ts-ignore
    const response = await UserGroupUserService.getAllUserGroupUsers(input.userGroupId, []);

    return { data: response.data, total: response.total };
  },
);

export const doDeleteUserGroupUser = createAsyncThunk(
  'userGroup/doDeleteUserGroupUser',
  async (input: Pick<UserGroupUser, 'id' | 'userGroupId'>) => {
    await UserGroupUserService.deleteUserGroupUser(input.userGroupId, input.id);
    return { userGroupUser: input };
  },
);

const userGroupSlice = createSlice({
  name: USER_GROUP_USER_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAllUserGroupUsers.pending, state => {
      state.loading[getAllUserGroupUsers.typePrefix] = true;
    });
    builder.addCase(getAllUserGroupUsers.fulfilled, (state, action) => {
      state.loading[getAllUserGroupUsers.typePrefix] = false;
      userGroupUserAdapter.setAll(state, action.payload.data);
    });
    builder.addCase(getAllUserGroupUsers.rejected, state => {
      state.loading[getAllUserGroupUsers.typePrefix] = false;
    });

    // Delete an user group
    builder.addCase(doDeleteUserGroupUser.pending, state => {
      state.loading[doDeleteUserGroupUser.typePrefix] = true;
    });
    builder.addCase(doDeleteUserGroupUser.fulfilled, (state, action) => {
      userGroupUserAdapter.removeOne(state, action.payload.userGroupUser.id);
      state.loading[doDeleteUserGroupUser.typePrefix] = false;
    });
    builder.addCase(doDeleteUserGroupUser.rejected, (state, action) => {
      state.loading[doDeleteUserGroupUser.typePrefix] = false;
      state.error = action.error.message;
    });
  },
});

const selectUserGroupUsers = (state: AppState) => state[USER_GROUP_USER_FEATURE_KEY];

export const {
  selectAll: selectAllUserGroupUsers,
  selectEntities,
} = userGroupUserAdapter.getSelectors(selectUserGroupUsers);

export const selectUserGroupUserByEmail = (id: UserGroupUser['id']) => createSelector(
  selectAllUserGroupUsers,
  userGroupUsers => userGroupUsers.filter(userGroup => userGroup.id === id)[0],
);

export const selectAll = () => createSelector(
  selectAllUserGroupUsers,
  userGroupUsers => userGroupUsers,
);

export const selectLoading = createSelector(
  selectUserGroupUsers,
  state => state.loading,
);

export default userGroupSlice.reducer;
