import { BaseModel } from './base';

export interface Config extends BaseModel {
  onpremise: boolean;
  publicUrl: string;
  whitelistedDomains: string;
  forumSecret: string;
  mandrillApiKey: string;
  mandrillSubAccount: string;
  segmentWriteKey: string;
  invitationExpirationExtensionMilliseconds?: number;
  maintenanceStart?: number;
  testCloudFreeTrialTimeStart?: number;
  testOpsPublicUrl: string;
  serviceCloudEnabled: boolean;
  endTimeOfFreePeriod?: number;
  startTimeOfFreePeriod?: number;
  keyCloakUrl?: string;
  keyCloakRealmId?: string;
  keyCloakClientId?: string;
  freeKsViolationNotificationStartDate?: number;
  iamEnabled?: boolean;
}

export const isInSystemMaintenance = (appConfig: Config | undefined) => (
  (appConfig?.maintenanceStart ?? 0) > 0
    && (appConfig?.maintenanceStart ?? 0) <= new Date().valueOf()
);
