import Axios from 'axios';
import { OrganizationFeatureFlag, PageableResponse } from '../models';
import { composeQuery, Query } from '../models/query';

export const getOrganizationFeatureFlags = async (...criteria: Query<OrganizationFeatureFlag>[]) => (await Axios.get<PageableResponse<OrganizationFeatureFlag>>('/v1/testops-organization-feature-flags', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: composeQuery<OrganizationFeatureFlag>(...criteria),
  data: {},
})).data;
