import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { TestOpsPlatformTier } from '../models/testOpsPlatformTier';
import { SerializedException, TestOpsPlanInterval } from '../models/index';
import { TestOpsPlatformSubscriptionService } from '../services';
import { TestOpsPlatformConfiguration } from '../models/testOpsPlatformConfiguration';
import { AppState } from '.';
import { TestOpsPlatformTierType } from '../models/testOpsPlatformTierType';

export const TESTOPS_PLATFORM_CONFIGURATION_KEY = 'testOpsPlatformConfiguration';

interface TestOpsPlatformConfigurationState {
  testOpsPlatformConfiguration: TestOpsPlatformConfiguration | null;
  loading: boolean;
  errors: SerializedException[];
}

export const createInitialState = (): TestOpsPlatformConfigurationState => ({
  testOpsPlatformConfiguration: null,
  loading: false,
  errors: [] as SerializedException[],
});

export const doGetTestOpsPlatformConfiguration = createAsyncThunk(
  'testOpsPlatformConfiguration/getConfiguration',
  async () => TestOpsPlatformSubscriptionService.getTestOpsPlatformConfiguration(),
);

const testOpsPlatformSubscriptionsSlice = createSlice({
  name: TESTOPS_PLATFORM_CONFIGURATION_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doGetTestOpsPlatformConfiguration.pending, state => {
      state.loading = true;
      state.errors = [];
    });
    builder.addCase(
      doGetTestOpsPlatformConfiguration.fulfilled,
      (state, action) => {
        state.loading = false;
        state.testOpsPlatformConfiguration = action.payload.data;
      },
    );
    builder.addCase(doGetTestOpsPlatformConfiguration.rejected, state => {
      state.loading = false;
    });
  },
});

// eslint-disable-next-line max-len
export const selectTestOpsPlatformConfiguration = (state: AppState) => state[TESTOPS_PLATFORM_CONFIGURATION_KEY];

export const selectLoad = () => createSelector(
  selectTestOpsPlatformConfiguration,
  configuration => configuration.loading,
);

export const selectConfigurationByBillingCycle = (
  billingCycle: TestOpsPlanInterval,
) => createSelector(
  selectTestOpsPlatformConfiguration,
  configuration => {
    const testOpsConfig = (billingCycle === TestOpsPlanInterval.YEAR)
      ? configuration.testOpsPlatformConfiguration?.year
      : configuration.testOpsPlatformConfiguration?.month;

    const premiumConfig = testOpsConfig
      ?.filter(tier => tier.testOpsPlatformTier === TestOpsPlatformTierType.PROFESSIONAL);
    const enterpriseConfig = testOpsConfig
      ?.filter(tier => tier.testOpsPlatformTier === TestOpsPlatformTierType.BUSINESS);

    if (premiumConfig && enterpriseConfig) {
      premiumConfig.push(enterpriseConfig[0]);
      return premiumConfig;
    }

    return [];
  },
);

export const selectNextConfigurationByCurrentQuota = (
  executionTestResults: TestOpsPlatformTier['executionTestResults'],
) => createSelector(
  selectTestOpsPlatformConfiguration,
  configuration => configuration.testOpsPlatformConfiguration?.month
    .filter((
      tier: { testOpsPlatformTier: TestOpsPlatformTierType; executionTestResults: number; },
    ) => tier.executionTestResults > executionTestResults)[0],
);

export const selectConfigurationMonthlyByFeature = (testOpsPlatformTier: TestOpsPlatformTier['testOpsPlatformTier']) => createSelector(
  selectTestOpsPlatformConfiguration,
  configuration => configuration.testOpsPlatformConfiguration?.month
    ?.filter(tier => tier.testOpsPlatformTier === testOpsPlatformTier),
);

export const selectConfigurationAnnualyByFeature = (testOpsPlatformTier: TestOpsPlatformTier['testOpsPlatformTier']) => createSelector(
  selectTestOpsPlatformConfiguration,
  configuration => configuration.testOpsPlatformConfiguration?.year
    ?.filter(tier => tier.testOpsPlatformTier === testOpsPlatformTier),
);

export const selectConfigurationByBillingCycleNumOfTestResults = (
  executionTestResults: TestOpsPlatformTier['executionTestResults'],
  billingCycle: TestOpsPlanInterval,
) => createSelector(
  selectTestOpsPlatformConfiguration,
  configuration => (billingCycle === TestOpsPlanInterval.MONTH
    ? configuration.testOpsPlatformConfiguration?.month
      ?.filter(tier => tier.executionTestResults === executionTestResults)
    : configuration.testOpsPlatformConfiguration?.year
      ?.filter(tier => tier.executionTestResults === executionTestResults)),
);

export const selectStairStepsByBillingCycleNumOfTestResults = (
  executionTestResults: TestOpsPlatformTier['executionTestResults'],
  billingCycle: TestOpsPlanInterval,
) => createSelector(
  selectTestOpsPlatformConfiguration,
  configuration => (billingCycle === TestOpsPlanInterval.MONTH
    ? configuration.testOpsPlatformConfiguration?.month
      ?.filter(tier => tier.executionTestResults >= executionTestResults)
    : configuration.testOpsPlatformConfiguration?.year
      ?.filter(tier => tier.executionTestResults >= executionTestResults)),
);

export const selectTierByNumOfTestResults = (
  executionTestResults: TestOpsPlatformTier['executionTestResults'],
) => createSelector(
  selectTestOpsPlatformConfiguration,
  configuration => {
    const tiers = configuration.testOpsPlatformConfiguration?.month
      .filter(tier => tier.executionTestResults === executionTestResults);
    return tiers ? tiers[0]?.testOpsPlatformTier : TestOpsPlatformTierType.FREE;
  },
);

export default testOpsPlatformSubscriptionsSlice.reducer;
