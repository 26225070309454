import { OrganizationFeature, TestCloudSubscription, TestOpsSubscriptionStatus } from '../../models';
import { Config } from '../../models/config';

export const isTestCloudPerMinute = (feature: OrganizationFeature) => (
  feature === OrganizationFeature.TESTCLOUD_MINUTE
);

export const getTestCloudFreeTrialDefault = () => ({
  concurrentSessions: 5,
  billingInterval: 'None',
});

export const getTestCloudFreeTrialExpiryDate = (
  appConfig: Config | undefined,
  organizationCreatedDate: Date | undefined,
) => {
  const testCloudFreeTrialConfigStartedDate = appConfig?.testCloudFreeTrialTimeStart ?? 0;
  const organizationCreatedDateTimeStamp = organizationCreatedDate?.valueOf() ?? 0;

  const organizationFreeTrialStartedDate = new Date(Math.max(
    testCloudFreeTrialConfigStartedDate,
    organizationCreatedDateTimeStamp,
  ));
  return organizationFreeTrialStartedDate.setDate(organizationFreeTrialStartedDate.getDate() + 30);
};

export const getTestCloudFeature = (
  latestTestCloudSubscription: TestCloudSubscription | undefined,
  appConfig: Config | undefined,
  organizationCreatedDate: Date | undefined,
) => {
  const isTestCloudFreeTrialValid = new Date().valueOf() < getTestCloudFreeTrialExpiryDate(
    appConfig,
    organizationCreatedDate,
  );
  return ((latestTestCloudSubscription === undefined
    || latestTestCloudSubscription.recurlySubscription === null)
    ? (isTestCloudFreeTrialValid
      ? OrganizationFeature.TESTCLOUD_FREE_TRIAL
      : undefined
    )
    : (latestTestCloudSubscription.status === TestOpsSubscriptionStatus.INACTIVE
      ? undefined
      : latestTestCloudSubscription?.feature
    )) as OrganizationFeature;
};

export const getTestCloudPerSessionPlanId = (planInterval: string) => `testcloud_session_web_${planInterval}`;

export const getTestCloudPerMinutePlanId = (planInterval: string) => `testcloud_minute_${planInterval}`;

export const formatMessageRecurly = (message: string) => message.replaceAll(/[^a-zA-Z0-9\s.]/g, '');

export interface BillingInformationInputs {
  ccEmails: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  vatNumber: string;
  fullBusinessName: string;
}
