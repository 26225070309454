import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import LoadingProgress from '../../layout/LoadingProgress';
import { OrganizationUser } from '../../models';
import {
  fromAccounts,
  fromAuth,
  fromUserInvitation,
  useAppDispatch,
  fromAccountUsers,
} from '../../store';
import ChipInputEmails, { EmailChipData, EmailDuplicationDictionary } from '../chip-input/ChipInputEmails';
import K1TextField from '../design-system/K1TextField';
import K1Typography from '../design-system/K1Typography';
import { accountHome } from '../../layout/routes';
import { useNavigate } from '../../routes';
import { ERROR_USER_INVITATION_LIMITED_USER } from '../../store/acceptInvitationPageSlice';
import { useLaunchDarkly } from '../../launchdarkly';

const useStyles = makeStyles(theme => ({
  warningError: {
    width: theme.spacing(1.75),
    height: theme.spacing(1.75),
    marginRight: theme.spacing(0.5),
  },
}));

interface CreateAccountDialogProps extends React.ComponentProps<typeof Dialog> {
  onClose: () => void;
}

const CreateAccountDialog = (props: CreateAccountDialogProps) => {
  const classes = useStyles();

  const { open, onClose } = props;
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [accountName, setAccountName] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [projectName, setProjectName] = useState('');
  const [errorAccountName, setErrorAccountName] = useState('');
  const [errorOrganizationName, setErrorOrganizationName] = useState('');
  const [errorInvitations, setErrorInvitations] = useState('');
  const [errorProjectName, setErrorProjectName] = useState('');
  const [chips, setChips] = useState<EmailChipData[]>([]);
  const [cntIncorrectFormat, setCntIncorrectFormat] = useState(0);
  const [cntExist, setCntExist] = useState(0);
  const user = useSelector(fromAuth.selectUser);
  const currentOrgUsers = [{ user } as OrganizationUser];
  const emailDict = useRef<EmailDuplicationDictionary>({});
  const { navigate, replaceQuery } = useNavigate();
  const dispatch = useAppDispatch();
  const { flags } = useLaunchDarkly();
  const handleClose = () => {
    setAccountName('');
    setOrganizationName('');
    setProjectName('');
    setErrorAccountName('');
    setErrorOrganizationName('');
    setErrorProjectName('');
    setChips([]);
    setCntIncorrectFormat(0);
    setCntExist(0);
    emailDict.current = {};
    onClose();
  };
  const handleClearAllEmails = () => {
    setChips([]);
    setCntIncorrectFormat(0);
    setCntExist(0);
    emailDict.current = {};
  };
  const handleChangeAccountName = (event: any) => {
    setAccountName(event.target.value);
  };
  const handleChangeOrganizationName = (event: any) => {
    setOrganizationName(event.target.value);
  };
  const handleChangeProjectName = (event: any) => {
    setProjectName(event.target.value);
  };

  const createRecurlyAndStripeAccount = async (accountId?: number) => {
    if (accountId) {
      await dispatch(fromAccountUsers.doCreateRecurlyAccount(accountId));
    } else {
      enqueueSnackbar(
        intl.formatMessage({ id: 'user_management.create_recurly_stripe_account.failed' }),
        { variant: 'error' },
      );
    }
  };

  const handleSubmit = async () => {
    let validAccountName = false;
    let validOrganizationName = false;
    let validProjectName = false;
    let validInvitations = false;
    if (accountName.trim().length === 0) setErrorAccountName(intl.formatMessage({ id: 'create_account_dialog.must_fill_required_field' }));
    else {
      setErrorAccountName('');
      validAccountName = true;
    }
    if (organizationName.trim().length === 0) setErrorOrganizationName(intl.formatMessage({ id: 'create_account_dialog.must_fill_required_field' }));
    else {
      setErrorOrganizationName('');
      validOrganizationName = true;
    }
    if (projectName.trim().length === 0) setErrorProjectName(intl.formatMessage({ id: 'create_account_dialog.must_fill_required_field' }));
    else {
      setErrorProjectName('');
      validProjectName = true;
    }
    if (flags?.limitUserEnabled && chips.length >= 5) {
      setErrorInvitations(intl.formatMessage({ id: ERROR_USER_INVITATION_LIMITED_USER }));
    } else {
      setErrorInvitations('');
      validInvitations = true;
    }
    if (validAccountName && validOrganizationName && validProjectName && validInvitations
      && cntExist === 0 && cntIncorrectFormat === 0) {
      setLoading(true);
      const { organization } = unwrapResult(
        await dispatch(fromAccounts.doCreateAccountAndOrganizationAndTeamAndProject({
          accountName: accountName.trim(),
          organizationName: organizationName.trim(),
          projectName: projectName.trim(),
          isTeamRemovalEnabled: flags?.organizationMigrationStatusEnabled,
        })),
      );

      // Create Recurly and Stripe account
      createRecurlyAndStripeAccount(organization.accountId);

      const invitedEmails = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const { text: email } of chips) {
        const invitation = {
          invitedUserEmail: email,
          organizationId: organization.id,
          assignedFeatures: [],
          ssoOptions: [],
        };
        try {
          // eslint-disable-next-line no-await-in-loop
          await dispatch(fromUserInvitation.doCreateInvitation(invitation));
          invitedEmails.push(email);
        } catch (e: any) {
          switch (e.message) {
            case ERROR_USER_INVITATION_LIMITED_USER:
              enqueueSnackbar(
                intl.formatMessage({ id: e.message }),
                { variant: 'error' },
              );
              break;
            default:
              enqueueSnackbar(
                intl.formatMessage({ id: 'user_management.invite_users.failed' }, { count: chips.length - invitedEmails.length }),
                { variant: 'error' },
              );
              break;
          }
          break;
        }
      }

      setLoading(false);
      handleClose();
      // Temporary use orgId here due to some account features are not implemented
      navigate(accountHome.path, replaceQuery({ orgId: `${organization.id}`, accountId: organization.accountId }));
    }
  };
  return (
    <Dialog
      id="create_account_dialog"
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          paddingX: 2,
          paddingY: 2.5,
          width: 600,
          borderRadius: 1.5,
        },
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <K1Typography variant="h2" fontWeight="medium" mb={2}>
          <FormattedMessage id="create_account_dialog.create_new_account" />
        </K1Typography>
        <Box>
          <IconButton
            id="create.account.dialog.close.icon"
            aria-label="close"
            onClick={handleClose}
            edge="end"
            size="small"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <K1Typography variant="h3" fontWeight="medium" mb={2}>
        <FormattedMessage id="create_account_dialog.general_information" />
      </K1Typography>
      <Box mb={0.5} display="flex" gap={0.5} alignItems="center">
        <K1Typography variant="h5" fontWeight="medium">
          <FormattedMessage id="create_account_dialog.account_name" />
        </K1Typography>
      </Box>
      <K1TextField
        id="create.account.form.account_name.text_field"
        hiddenLabel
        value={accountName}
        onChange={handleChangeAccountName}
        error
        helperText={errorAccountName.length > 0 && (
          <Box display="flex" flexDirection="row" alignContent="center" alignItems="center">
            <WarningIcon className={classes.warningError} color="secondary" />
            <K1Typography id="create.account.form.account_name.error.message" variant="h4">
              {errorAccountName}
            </K1Typography>
          </Box>
        )}
        inputProps={{
          maxLength: 255,
        }}
        placeholder={intl.formatMessage({ id: 'create_account_dialog.enter_account_name' })}
      />
      <Box mt={1} mb={0.5} display="flex" gap={0.5} alignItems="center">
        <K1Typography variant="h5" fontWeight="medium">
          <FormattedMessage id="create_account_dialog.organization_name" />
        </K1Typography>
      </Box>
      <K1TextField
        id="create.account.form.organization_name.text_field"
        hiddenLabel
        value={organizationName}
        onChange={handleChangeOrganizationName}
        error
        helperText={errorOrganizationName.length > 0 && (
          <Box display="flex" flexDirection="row" alignContent="center" alignItems="center">
            <WarningIcon className={classes.warningError} color="secondary" />
            <K1Typography id="create.account.form.organization_name.error" variant="h4">
              {errorOrganizationName}
            </K1Typography>
          </Box>
        )}
        inputProps={{
          maxLength: 255,
        }}
        placeholder={intl.formatMessage({ id: 'create_account_dialog.enter_organization_name' })}
      />
      <Box mt={1} mb={0.5} display="flex" gap={0.5} alignItems="center">
        <K1Typography variant="h5" fontWeight="medium">
          <FormattedMessage id="create_account_dialog.project_name" />
        </K1Typography>
      </Box>
      <K1TextField
        id="create.account.form.project_name.text_field"
        hiddenLabel
        value={projectName}
        onChange={handleChangeProjectName}
        error
        helperText={errorProjectName.length > 0 && (
          <Box display="flex" flexDirection="row" alignContent="center" alignItems="center">
            <WarningIcon className={classes.warningError} color="secondary" />
            <K1Typography id="create.account.form.project_name.error" variant="h4">
              {errorProjectName}
            </K1Typography>
          </Box>
        )}
        inputProps={{
          maxLength: 255,
        }}
        placeholder={intl.formatMessage({ id: 'create_account_dialog.enter_project_name' })}
      />
      <Box mt={1} mb={0.5} display="flex" justifyContent="space-between" alignItems="center" alignContent="center">
        <K1Typography variant="h5" fontWeight="medium">
          <FormattedMessage id="create_account_dialog.invite_user" />
        </K1Typography>
        {chips.length > 0 && (
          <Button sx={{ padding: 0, fontWeight: 500, fontSize: '14px', lineHeight: '16px' }} variant="text" onClick={handleClearAllEmails}>
            <FormattedMessage id="create_account_dialog.clear_all" />
          </Button>
        )}
      </Box>
      <ChipInputEmails
        id="create.account.form.emails.text_field"
        {...{
          chips,
          setChips,
          emailDict,
          cntIncorrectFormat,
          setCntIncorrectFormat,
          cntExist,
          setCntExist,
          currentOrgUsers,
          placeholder: intl.formatMessage({ id: 'create_account_dialog.enter_emails' }),
        }}
      />
      {cntIncorrectFormat + cntExist > 0 && (
        <Box display="flex" mt={1} alignItems="center">
          <WarningIcon sx={{ width: '14px', height: '14px', marginRight: '4px' }} color="secondary" />
          <K1Typography id="create.account.form.emails.error" variant="h4" color="secondary">
            {intl.formatMessage(
              { id: 'create_account_dialog.enter_emails.error' },
              { count: cntIncorrectFormat + cntExist },
            )}
          </K1Typography>
        </Box>
      )}
      {errorInvitations && (
        <Box display="flex" mt={1} alignItems="center">
          <WarningIcon sx={{ width: '14px', height: '14px', marginRight: '4px' }} color="secondary" />
          <K1Typography id="create.account.form.emails.invitations.error" variant="h4" color="secondary">
            <FormattedMessage id={ERROR_USER_INVITATION_LIMITED_USER} />
          </K1Typography>
        </Box>
      )}
      <Box mt={2} gap={1.5} display="flex" justifyContent="flex-end">
        <Button
          id="create.account.form.button.cancel"
          variant="contained"
          color="inherit"
          onClick={handleClose}
          sx={{
            fontSize: '1rem',
            paddingY: '0.5rem',
            lineHeight: '1.5rem',
          }}
        >
          <FormattedMessage id="create_account_dialog.cancel" />
        </Button>
        <Button
          id="create.account.form.button.create"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{
            fontSize: '1rem',
            paddingY: '0.5rem',
            lineHeight: '1.5rem',
          }}
        >
          <FormattedMessage id="create_account_dialog.create" />
        </Button>
      </Box>
      {loading && <LoadingProgress />}
    </Dialog>
  );
};

export default CreateAccountDialog;
