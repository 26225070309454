import withStyles from '@mui/styles/withStyles';
import Switch from '@mui/material/Switch';

const CustomSwitch = withStyles(theme => ({
  root: {
    width: theme.spacing(3.5),
    height: theme.spacing(2),
    padding: 0,
    display: 'flex',
    '& > .Mui-disabled.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      opacity: '0.4',
    },
  },
  switchBase: {
    padding: 2,
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#5294FF',
        borderColor: '#5294FF',
      },
    },
  },
  thumb: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    boxShadow: 'none',
    backgroundColor: '#CFD7EC',
  },
  track: {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#F4F5F7',
  },
  checked: {
    '& > .MuiSwitch-thumb': {
      backgroundColor: '#F4F5F7',
    },
  },

}))(Switch);

export default CustomSwitch;
