import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Navigate } from 'react-router-dom';
import LoadingProgress from '../../../layout/LoadingProgress';
import { subscriptionManagement } from '../../../layout/routes';
import { isTestCloudFeature, OrganizationFeature, TestCloudSubscription } from '../../../models';
import { notFound, useNavigate, useQuery } from '../../../routes';
import { fromAuth, fromCurrentOrgUser, fromTestCloudSubscriptions, fromTestCloudUsage, useAppDispatch } from '../../../store';
import { fromInvoices, fromOrganizations } from '../../../store/rootReducer';
import CardAndBillingInformation from '../payment/CardAndBillingInformation';
import PerMinutePurchaseInfo from './PerMinutePurchaseInfo';
import PerSessionWebPurchaseInfo from './PerSesssionWebPurchaseInfo';
import { useLaunchDarkly } from '../../../launchdarkly';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    borderRadius: '.5rem',
    padding: '1.5rem 2.5rem',
    overflowY: 'auto',
  },
  productTitle: {
    marginBottom: theme.spacing(5.75),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(3),
  },
  infoContainer: {
    backgroundColor: '#EFFAFB',
    padding: theme.spacing(2),
    border: '1px solid #5BCDDA',
    borderRadius: theme.spacing(0.75),
    marginBottom: theme.spacing(2),
  },
  infoIcon: {
    fill: '#5BCDDA',
    marginRight: theme.spacing(1),
  },
  premierAddOnText: {
    fontStyle: 'italic',
  },
}));

export interface BasePurchaseInfoProps {
  currentSubscription: TestCloudSubscription | undefined;
}

const TestCloudCheckout = () => {
  const classes = useStyles();
  const { get } = useQuery();
  const { enqueueSnackbar } = useSnackbar();
  const feature = get('feature');
  const organizationId = Number(get('orgId'));
  const dispatch = useAppDispatch();
  const { replace, replaceQuery } = useNavigate();
  const { flags, currentAccountId } = useLaunchDarkly();
  const organization = useSelector(fromOrganizations.selectOrganizationById(organizationId));
  const currentSubscription = useSelector(
    fromTestCloudSubscriptions.selectLatestActiveTestCloudSubscriptionByOrganizationId(
      Number(organizationId),
    ),
  );
  const [loading, setLoading] = useState(true);

  const user = useSelector(fromAuth.selectUser);

  const latestUsage = useSelector(fromTestCloudUsage.selectLatestTestCloudUsage);

  useEffect(() => {
    if (!organizationId || !user) return;
    const fetchCurrentSubscription = async () => {
      setLoading(true);
      await Promise.all([
        dispatch(fromTestCloudSubscriptions.doGetTestCloudSubscriptionsByOrganizationId({
          organizationId: Number(organizationId) || 0,
          checkHasPremierSuccess: true,
        })),
        dispatch(fromCurrentOrgUser
          .doGetOrgUserByUserIdAndOrgId({ id: user.id, organizationId: +organizationId })),
        dispatch(fromTestCloudUsage.doGetLatestTestCloudUsage(
          { organizationId: +organizationId },
        )),
      ]).finally(() => setLoading(false));
    };
    fetchCurrentSubscription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  useEffect(() => {
    if (
      currentSubscription
      && currentSubscription.feature
      && currentSubscription.feature !== feature
    ) {
      enqueueSnackbar(
        <FormattedMessage id="home.trial_testcloud.error" values={{ plan: OrganizationFeature.getFeatureFullName(currentSubscription.feature) }} />,
        {
          variant: 'error',
        },
      );
      replace(`${subscriptionManagement.path}/testcloud`, replaceQuery({ orgId: organizationId }));
      return;
    }
    if (!isTestCloudFeature(OrganizationFeature.fromString(feature || ''))) {
      replace(notFound.path, replaceQuery({ orgId: organizationId }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSubscription, feature]);

  useEffect(() => () => {
    dispatch(fromInvoices.doResetPreview());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (organization?.accountId === currentAccountId
    && flags && flags.subscriptionAndPaymentMethodDisabled === true) {
    return <Navigate to={notFound.path} replace />;
  }

  if (feature === OrganizationFeature.TESTCLOUD_MINUTE) {
    replace(`${subscriptionManagement.path}/testcloud`, replaceQuery({ orgId: organizationId }));
    return null;
  }
  return (
    <div className={classes.root}>
      {!loading && (
        <>
          <Typography variant="h2" className={classes.productTitle}>
            <FormattedMessage
              id={currentSubscription ? 'subscriptions.testcloud.upgrading' : 'subscriptions.testcloud.purchasing'}
            />
          </Typography>
          <Grid container>
            <Grid item xs={8}>
              {feature === OrganizationFeature.TESTCLOUD_MINUTE
                ? latestUsage && (
                  <Grid
                    container
                    className={classes.infoContainer}
                    alignItems="center"
                  >
                    <InfoOutlinedIcon className={classes.infoIcon} />
                    <Typography variant="body2">
                      <FormattedMessage
                        id="subscriptions.testcloud.subscription_summary.remaining_credits"
                        values={{ count: Math.round((
                          latestUsage.creditBalance
                            - Math.max(latestUsage.carryOverCredits, 0)) / 60) }}
                      />
                    </Typography>
                  </Grid>
                )
                : (currentSubscription && currentSubscription.concurrentSessions) && (
                  <Grid
                    container
                    className={classes.infoContainer}
                    alignItems="center"
                  >
                    <InfoOutlinedIcon className={classes.infoIcon} />
                    <Typography variant="body2">
                      <FormattedMessage
                        id="subscriptions.testcloud.subscription_summary.current_sessions_quota"
                        values={{ count: currentSubscription.concurrentSessions }}
                      />
                    </Typography>
                  </Grid>
                )}
              <Paper
                elevation={0}
                // className={classes.checkoutBilling}
              >
                {
                  feature === OrganizationFeature.TESTCLOUD_MINUTE
                    ? <PerMinutePurchaseInfo currentSubscription={currentSubscription} />
                    : <PerSessionWebPurchaseInfo currentSubscription={currentSubscription} />
                }
              </Paper>
              {currentSubscription?.hasPremierSuccessAddOn && (
                <Typography variant="body2" className={classes.premierAddOnText}>
                  <FormattedMessage
                    id="subscriptions.testcloud.premier_success_addon"
                  />
                </Typography>
              )}
            </Grid>
            <Grid item xs={4}>
              <CardAndBillingInformation currentSubscription={currentSubscription} />
            </Grid>
          </Grid>
        </>
      )}
      {loading && <LoadingProgress />}
    </div>
  );
};

export default TestCloudCheckout;
