import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Paper from '@mui/material/Paper';
import StepConnector from '@mui/material/StepConnector';
import makeStyles from '@mui/styles/makeStyles';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import EnterEmails from './EnterEmails';
import SelectTeamsAndLicenses from './SelectTeamsAndLicenses';
import { AvailableSubscription, SubscriptionInvitation, LoginOptions } from '../utils';
import { ERROR_USER_INVITATION_LIMITED_USER } from '../../../store/acceptInvitationPageSlice';
import { useLaunchDarkly } from '../../../launchdarkly';
import { OrganizationMigrationStatus, TestOpsProject, TestOpsTeam } from '../../../models';
import { useQuery } from '../../../routes';
import { fromOrganizations } from '../../../store';
import SelectProjectsAndLicenses from './SelectProjectsAndLicenses';

const useStyle = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    padding: theme.spacing(3),
  },
  center: {
    textAlign: 'center',
    marginTop: theme.spacing(20),
    marginBottom: theme.spacing(5),
  },
  centerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 0,
    padding: theme.spacing(3, 3, 3, 0),
  },
  stepperLabel: {
    justifyContent: 'center',
  },
  connectorRoot: {
    flex: '0',
  },
  connectorLine: {
    width: theme.spacing(6),
  },
  closeButton: {
    cursor: 'pointer',
    fill: 'gray',
  },
}));

interface InviteUsersDialogV2Props {
  open: boolean;
  onClose: () => void;
  availableSubs: AvailableSubscription[];
  handleSubmit: (emails: string[],
    selectedSubs: SubscriptionInvitation[],
    selectedTeams: number[]) => void;
  selectedEmails: string[];
  selectedSubs: SubscriptionInvitation[];
  handleSelectEmails: (emails: string[]) => void;
  onBackButton: () => void;
  handleSelectSubs: (subs: SubscriptionInvitation[]) => void;
  loginOptions: LoginOptions;
  handleChangeLoginOptions: (event: React.ChangeEvent<HTMLInputElement>, option: string) => void;
  isSsoEnabled: boolean,
  havePlatformSubscription: boolean,
  haveTestOpsSubscription: boolean,
  totalAccountMembers: number,
  totalInvitations: number,
  hasKseOrKreLicense: boolean,
  teams: TestOpsTeam[],
  handleRefreshTeams: () => Promise<void>,
  selectedTeams: number[],
  handleSelectTeams: (teamsId: number[]) => void,
  projects: TestOpsProject[],
  handleRefreshProjects: () => Promise<void>,
  selectedProjects: number[],
  handleSelectProjects: (projectIds: number[]) => void,
}
const InviteUsersDialogV2 = (props: InviteUsersDialogV2Props) => {
  const {
    open,
    onClose,
    availableSubs,
    handleSubmit,
    handleSelectEmails,
    handleSelectSubs,
    selectedEmails,
    selectedSubs,
    loginOptions,
    handleChangeLoginOptions,
    onBackButton,
    isSsoEnabled,
    havePlatformSubscription,
    haveTestOpsSubscription,
    totalAccountMembers,
    totalInvitations,
    hasKseOrKreLicense,
    teams,
    handleRefreshTeams,
    selectedTeams,
    handleSelectTeams,
    projects,
    handleRefreshProjects,
    selectedProjects,
    handleSelectProjects,
  } = props;
  const { get } = useQuery();
  const orgId = get('orgId');
  const [activeStep, setActiveStep] = useState<number>(0);
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const classes = useStyle();
  const { flags } = useLaunchDarkly();
  const orgMigrationStatus = useSelector(
    fromOrganizations.selectOrgMigrationStatusById(Number(orgId)),
  );

  const handleClose = () => {
    onClose();
    setActiveStep(0);
  };
  const handleEmailNext = (emails: string[]) => {
    if (!flags?.limitUserEnabled || (havePlatformSubscription
      || haveTestOpsSubscription
      || totalAccountMembers + totalInvitations + emails.length <= 5)) {
      handleSelectEmails(emails);
      handleNext();
    } else {
      enqueueSnackbar(
        intl.formatMessage({ id: ERROR_USER_INVITATION_LIMITED_USER }),
        { variant: 'error' },
      );
    }
  };
  const handleTeamsAndLicenseBack = () => {
    handleBack();
    onBackButton();
  };

  const submitInvitations = () => {
    handleSubmit(selectedEmails, selectedSubs, selectedTeams);
    setActiveStep(0);
  };

  const handleNext = () => {
    setActiveStep(prevStep => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevStep => prevStep - 1);
  };

  const steps = [
    {
      label: intl.formatMessage({ id: 'user_management.invite.enter_emails.title' }),
      content: <EnterEmails
        isSsoEnabled={isSsoEnabled}
        loginOptions={loginOptions}
        handleChangeLoginOptions={handleChangeLoginOptions}
        onChangeEmails={handleSelectEmails}
        onNext={handleEmailNext}
        hasKseOrKreLicense={hasKseOrKreLicense}
        selectedEmails={selectedEmails}
      />,
    },
    orgMigrationStatus === OrganizationMigrationStatus.MIGRATED
      ? {
        label: intl.formatMessage({ id: 'user_management.invite.projects_licenses.title' }),
        content: <SelectProjectsAndLicenses
          orgId={Number(orgId)}
          selectedSubs={selectedSubs}
          countUsers={selectedEmails.length}
          availableSubs={availableSubs}
          onBack={handleTeamsAndLicenseBack}
          onSubmit={submitInvitations}
          handleSelectedSub={handleSelectSubs}
          projects={projects}
          selectedProjects={selectedProjects}
          handleSelectedProjects={handleSelectProjects}
          handleRefreshProjects={handleRefreshProjects}
        />,
      }
      : {
        label: intl.formatMessage({ id: 'user_management.invite.teams_licenses.title' }),
        content: <SelectTeamsAndLicenses
          selectedSubs={selectedSubs}
          countUsers={selectedEmails.length}
          availableSubs={availableSubs}
          onBack={handleTeamsAndLicenseBack}
          onSubmit={submitInvitations}
          handleSelectedSub={handleSelectSubs}
          teams={teams}
          selectedTeams={selectedTeams}
          handleSelectedTeams={handleSelectTeams}
          handleRefreshTeams={handleRefreshTeams}
        />,
      },
  ];

  return (
    <Dialog id="user_management.invite.dialog.container" open={open} fullScreen onClose={handleClose}>
      <DialogTitle id="user_management.invite.dialog.title" className={classes.header}>
        <HighlightOffIcon className={classes.closeButton} onClick={handleClose} />
      </DialogTitle>
      <DialogContent id="user_management.invite.dialog.content" className={classes.centerContainer}>
        <Grid
          id="user_management.invite.dialog.gird_container"
          container
          direction="column"
          className={classes.root}
          alignItems="center"
        >
          <Paper id="user_management.invite.dialog.paper_container" elevation={0}>
            <Stepper
              id="user_management.invite.dialog.stepper"
              activeStep={activeStep}
              className={classes.stepperLabel}
              connector={(
                <StepConnector classes={{
                  root: classes.connectorRoot,
                  line: classes.connectorLine,
                }}
                />
              )}
            >
              {
                steps.map(stepItem => {
                  const stepProps: { completed?: boolean } = {};
                  const labelProps: {
                    optional?: React.ReactNode;
                  } = {};
                  return (
                    <Step
                      id="user_management.invite.dialog.stepper_item"
                      key={`stepper-${stepItem.label}`}
                      {...stepProps}
                    >
                      <StepLabel
                        id="user_management.invite.dialog.stepper_label"
                        {...labelProps}
                        sx={{
                          '& span': {
                            fontWeight: 'normal !important',
                          },
                          '& .Mui-completed svg': {
                            color: '#1847ca',
                          },
                          '& .Mui-active svg': {
                            color: '#1847ca',
                          },
                          '& .Mui-disabled svg': {
                            color: '#d9d9d9',
                          },
                        }}
                      >
                        {stepItem.label}
                      </StepLabel>
                    </Step>
                  );
                })
              }
            </Stepper>
            { steps[activeStep].content }
          </Paper>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default InviteUsersDialogV2;
