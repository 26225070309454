import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';

interface TableBodySkeletonProps {
  rows: number;
  cols: number;
}

const TableBodySkeleton = (props: TableBodySkeletonProps) => {
  const { rows, cols } = props;
  return (
    <>
      <TableBody>
        {Array.from(Array(rows).keys()).map(row => (
          <TableRow key={row}>
            {Array.from(Array(cols).keys()).map(col => (
              <TableCell key={col}>
                <Skeleton />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </>
  );
};

export default TableBodySkeleton;
