import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Button from '@mui/material/Button';
import WarningIcon from '@mui/icons-material/Warning';
import clsx from 'clsx';
import Link from '@mui/material/Link';
import { FormattedMessage, useIntl } from 'react-intl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import RefreshIcon from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import filter from 'lodash/filter';
import { fromOrganizations } from '../../../store';
import { AvailableSubscription, SubscriptionInvitation, unlimitedAssignableSubs } from '../utils';
import { OrganizationFeature, isVisualTestingFeature } from '../../../models/organizationFeature';
import { ReactComponent as KREIcon } from './logo-license/kre.svg';
import { ReactComponent as KSEIcon } from './logo-license/kse.svg';
import { ReactComponent as TestOpsIcon } from './logo-license/testops.svg';
import CustomSwitch from '../../../components/switch/CustomSwitch';
import { OrganizationMigrationStatus, TestOpsTeam } from '../../../models';
import { resolvePath, sso as ssoPath, useQuery } from '../../../routes';
import { useLaunchDarkly } from '../../../launchdarkly';

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: theme.spacing(3),
  },
  sectionTeamWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  labelSection: {
    color: '#233145',
    fontWeight: '500',
    '& > .required-asterisk': {
      color: '#db3131',
    },
  },
  teamContainer: {
    maxWidth: theme.spacing(54),
    flexDirection: 'column',
  },
  team: {
    display: 'flex',
    alignItems: 'center',
    width: theme.spacing(52),
  },
  teamWrapper: {
    padding: theme.spacing(1, 0),
    maxWidth: theme.spacing(54),
  },
  selectionTeams: {
    flex: 1,
  },
  selectionAction: {
    backgroundColor: '#FFFFFF',
    height: theme.spacing(4),
    fontWeight: 600,
    color: '#1847ca',
    '& > span': {
      fontWeight: 'normal !important',
      fontSize: '0.925rem !important',
      paddingLeft: theme.spacing(1.5),
    },
  },
  teamsRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 0.5,
    overflow: 'auto',
    maxHeight: theme.spacing(8),
  },
  teamChip: {
    float: 'left',
    margin: theme.spacing(0.25),
    maxWidth: 200,
  },
  teamLabel: {
    fontSize: '0.925rem',
  },
  teamSelectedItem: {
    backgroundColor: '#FFFFFF !important',
    color: '#284169 !important',
    '& > div > span': {
      fontWeight: 'normal !important',
      fontSize: '0.925rem !important',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  teamItemText: {
    '& > span': {
      fontWeight: 'normal !important',
      fontSize: '0.925rem !important',
      color: '#284169 !important',
    },
  },
  menuPaper: {
    maxHeight: theme.spacing(24),
    maxWidth: theme.spacing(45),
  },
  buttonRefresh: {
    marginLeft: theme.spacing(0.5),
  },
  iconRefresh: {
    fontSize: theme.spacing(2.5),
    color: theme.typography.subtitle2.color,
  },
  sectionLicenseWrapper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2.5),
  },
  licenseContainer: {
    maxWidth: theme.spacing(54),
    flexDirection: 'column',
  },
  license: {
    display: 'flex',
    alignItems: 'center',
  },
  licenseWrapper: {
    padding: theme.spacing(1, 0),
    maxWidth: theme.spacing(60),
  },
  licenseName: {
    marginLeft: theme.spacing(1),
    color: '#1D3066',
  },
  message: {
    marginLeft: theme.spacing(4.5),
    marginTop: theme.spacing(1),
    paddingTop: 0,
    fontSize: theme.spacing(1.5),
    color: '#516393',
    maxHeight: theme.spacing(7.5),
    minHeight: theme.spacing(3.75),
    maxWidth: theme.spacing(43.75),
    lineHeight: '1.41',
  },
  infoIcon: {
    marginLeft: theme.spacing(1),
    fontSize: theme.spacing(2.5),
    color: theme.typography.subtitle2.color,
  },
  disabledSwitch: {
    opacity: '0.5',
  },
  licenseInfo: {
    display: 'flex',
    flex: 1,
    marginLeft: theme.spacing(1),
    maxWidth: theme.spacing(43.75),
  },
  button: {
    margin: theme.spacing(1, 0),
    width: theme.spacing(40),
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '& > span': {
      justifyContent: 'center',
    },
  },
  warning: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
  },
  warningMessage: {
    color: theme.palette.warning.main,
    fontSize: theme.spacing(1.25),
    paddingTop: 0,
    height: theme.spacing(3.75),
    maxHeight: theme.spacing(3.75),
  },
  warningIcon: {
    color: theme.palette.warning.main,
    fontSize: theme.spacing(2),
    marginRight: theme.spacing(0.5),
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
  },
  buttonConfirm: {
    backgroundColor: '#1847ca',
    marginTop: 0,
  },
  buttonBack: {
    color: '#276ef1',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  occupiedSpace: {
    marginTop: theme.spacing(1),
    height: theme.spacing(3.75),
  },
}));

const kSEproducts = [
  OrganizationFeature.KSE,
  OrganizationFeature.UNLIMITED_KSE,
  OrganizationFeature.PER_USER_KSE,
];
const kREProducts = [
  OrganizationFeature.ENGINE,
  OrganizationFeature.UNLIMITED_ENGINE,
  OrganizationFeature.FLOATING_ENGINE,
];
const PATH_KSE_INFO = 'https://docs.katalon.com/docs/legacy/products-and-licenses/katalon-studio-enterprise-and-runtime-engine-licenses/sunsetting-plan-for-node-locked-and-floating-licenses';
const PATH_KSE_FLOATING = 'https://docs.katalon.com/docs/legacy/products-and-licenses/katalon-studio-enterprise-and-runtime-engine-licenses/sunsetting-plan-for-node-locked-and-floating-licenses';
const PATH_KRE_INFO = 'https://docs.katalon.com/docs/legacy/products-and-licenses/katalon-studio-enterprise-and-runtime-engine-licenses/sunsetting-plan-for-node-locked-and-floating-licenses';
const PATH_UNLIMITED_ENGINE = 'https://docs.katalon.com/docs/administer/katalon-studio-enterprise-and-katalon-runtime-engine-license/katalon-runtime-engine-floating-license';
const PATH_KSE_PER_USER = 'https://docs.katalon.com/docs/administer/katalon-studio-enterprise-and-katalon-runtime-engine-license/katalon-studio-enterprise-per-user-license#ariaid-title1';
const PATH_LICENSE_OVERVIEW = 'https://docs.katalon.com/docs/administer/katalon-studio-enterprise-and-katalon-runtime-engine-license/license-overview';
const PATH_TESTOPS_PLATFORM_PLAN = 'https://docs.katalon.com/docs/administer/katalon-platform-packages/katalon-platform-plans';
interface SelectTeamsAndLicensesProps {
  availableSubs: AvailableSubscription[];
  countUsers: number;
  onBack: () => void;
  onSubmit: () => void;
  handleSelectedSub: (subs: SubscriptionInvitation[]) => void;
  selectedSubs: SubscriptionInvitation[];
  selectedTeams: number[];
  teams: TestOpsTeam[];
  handleSelectedTeams: (teamIds: number[]) => void;
  handleRefreshTeams: () => Promise<void>;
}

const SelectTeamsAndLicenses = (props: SelectTeamsAndLicensesProps) => {
  const classes = useStyle();
  const intl = useIntl();
  const { get } = useQuery();
  const {
    availableSubs,
    countUsers,
    onBack,
    onSubmit,
    handleSelectedSub,
    selectedSubs,
    teams,
    selectedTeams,
    handleSelectedTeams,
    handleRefreshTeams,
  } = props;
  const orgId = get('orgId');
  const organization = useSelector(fromOrganizations.selectSelectedOrganization);
  const orgMigrationStatus = useSelector(
    fromOrganizations.selectOrgMigrationStatusById(Number(orgId)),
  );
  const { flags } = useLaunchDarkly();
  const [allowedSubmit, setAllowedSubmit] = useState(true);
  // check if both KRE NL and F are in available:
  const isContainedNLandF = () => availableSubs.filter(
    it => (
      it.feature === OrganizationFeature.ENGINE
        || it.feature === OrganizationFeature.UNLIMITED_ENGINE
    ),
  ).length > 1;
  const isEmptyTeams = isEmpty(teams);
  const isSelectAllTeams = selectedTeams.length === teams.length;
  const isSelectNoTeam = isEmpty(selectedTeams);

  useEffect(() => {
    // check if there's any default enabled licenses:
    if (!isEmpty(selectedSubs)) {
      return;
    }
    const currentKRELicenses = availableSubs.filter(
      it => (
        it.feature === OrganizationFeature.ENGINE
          || it.feature === OrganizationFeature.UNLIMITED_ENGINE
      ),
    );
    if (currentKRELicenses.length === 1) {
      handleSelectedSub([{
        feature: currentKRELicenses[0].feature,
        quantity: countUsers,
      }]);
    } else if (currentKRELicenses.length === 2) { // if 2 types of KRE: KRE NL is selected
      handleSelectedSub([{
        feature: OrganizationFeature.ENGINE,
        quantity: countUsers,
      }]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countUsers]);

  useEffect(() => {
    handleSelectedTeams([...teams.map(it => it.id)]);
  }, [teams]);

  const onTeamsSelected = (event: SelectChangeEvent<typeof selectedTeams>) => {
    const {
      target: { value },
    } = event;
    if (isArray(value)) {
      const selectedTeamIds = filter(teams, team => value.indexOf(team.id) > -1)
        .map(team => team.id);
      handleSelectedTeams([...selectedTeamIds]);
      return;
    }
    if (typeof value === 'number') {
      handleSelectedTeams([...selectedTeams, value]);
    }
  };

  const handleDeleteTeam = (id: number) => () => {
    handleSelectedTeams([...filter(selectedTeams, item => item !== id)]);
  };

  const handleClearSelectedTeams = () => {
    handleSelectedTeams([]);
  };

  const handleSelectAllTeams = () => {
    const teamIds = teams.map(team => team.id);
    handleSelectedTeams(teamIds);
  };

  const onClickRefreshTeams = async () => {
    await handleRefreshTeams();
  };

  const checkProductType = (
    sub: OrganizationFeature,
    tempSelectedSubs: SubscriptionInvitation[],
  ) => {
    // only 1 sub in type should be selected
    // ex: if KSE N-L is selected => KSE Floating should be unselected
    if (kSEproducts.includes(sub)) {
      const remainSub = kSEproducts.find(it => it !== sub);
      if (selectedSubs.find(it => it.feature === remainSub)) {
        const index = selectedSubs.findIndex(it => it.feature === remainSub);
        tempSelectedSubs.splice(index, 1);
      }
    } else if (kREProducts.includes(sub)) {
      const remainSub = kREProducts.find(it => it !== sub);
      if (selectedSubs.find(it => it.feature === remainSub)) {
        const index = selectedSubs.findIndex(it => it.feature === remainSub);
        tempSelectedSubs.splice(index, 1);
      }
    }
  };

  const handleToggleLicense = (event: React.ChangeEvent<HTMLInputElement>) => {
    const sub: OrganizationFeature = event.target.value as OrganizationFeature;
    const tempSelectedSubs = [...selectedSubs];
    const currentAvailableSub = availableSubs.find(it => it.feature === sub);
    const addSub = (quantity: number) => tempSelectedSubs.push({
      feature: sub,
      quantity,
    });

    if (selectedSubs.find(it => it.feature === sub)) {
      const index = selectedSubs.findIndex(it => it.feature === sub);
      tempSelectedSubs.splice(index, 1);
    } else if (!unlimitedAssignableSubs.includes(sub)
      && currentAvailableSub
      && countUsers > currentAvailableSub.remainingLicenses
    ) {
      // validate sub: if sub is unlimited assignable or not
      // unlimited assignable: no need to check currentAvailableSubs and countUsers
      setAllowedSubmit(false);
      checkProductType(sub, tempSelectedSubs);
      addSub(0);
    } else { // unlimited assignable:
      checkProductType(sub, tempSelectedSubs);
      addSub(countUsers);
    }
    // check if org has 2 types of KRE and all are disabled => add KRE NL
    if (kREProducts.includes(sub) && isContainedNLandF()
      && tempSelectedSubs.filter(it => (
        it.feature === OrganizationFeature.ENGINE
          || it.feature === OrganizationFeature.UNLIMITED_ENGINE
      )).length < 1
    ) {
      tempSelectedSubs.push({
        feature: kREProducts.find(it => it !== sub) ?? OrganizationFeature.ENGINE,
        quantity: countUsers,
      });
    }
    // check if there's any sub has assigned quantity = 0 and then allowSubmit or not
    if (!tempSelectedSubs.find(it => it.quantity === 0)) {
      setAllowedSubmit(true);
    }
    handleSelectedSub(tempSelectedSubs);
  };

  const renderTeams = () => (
    teams.map(team => (
      <MenuItem
        id={`user_management.invite.teams_licenses.team_item_${team.id}`}
        key={`teamSelectItem-${team.id}`}
        value={team.id}
        classes={{ selected: classes.teamSelectedItem }}
      >
        <Checkbox
          id={`user_management.invite.teams_licenses.team_item_checkbox_${team.id}`}
          checked={selectedTeams.indexOf(team.id) > -1}
        />
        <ListItemText
          id={`user_management.invite.teams_licenses.team_item_name_${team.id}`}
          primary={team.name}
          className={classes.teamItemText}
        />
      </MenuItem>
    ))
  );

  const renderTeamItem = (id: number) => {
    const teamInfo = find(teams, team => team.id === id);
    return teamInfo
      ? (
        <Chip
          id={`user_management.invite.teams_licenses.team_chip_${teamInfo.id}`}
          key={`teamChip-${teamInfo.id}`}
          label={teamInfo.name}
          deleteIcon={<ClearIcon />}
          className={classes.teamChip}
          onDelete={handleDeleteTeam(teamInfo.id)}
          onMouseDown={event => {
            event.stopPropagation();
          }}
        />
      )
      : <></>;
  };

  const renderTeamsPlaceHolder = (selectTeamIds: number[]) => {
    if (isEmptyTeams) {
      return (
        <Typography id="user_management.invite.teams_licenses.team_not_found_placeholder" className={classes.teamLabel}>
          <FormattedMessage id="user_management.invite.teams_licenses.label_not_found_teams" />
        </Typography>
      );
    }
    if (isSelectAllTeams) {
      return (
        <Typography id="user_management.invite.teams_licenses.team_all_placeholder" className={classes.teamLabel}>
          <FormattedMessage id="user_management.invite.teams_licenses.label_all_teams" />
        </Typography>
      );
    }
    if (isSelectNoTeam) {
      return (
        <Typography id="user_management.invite.teams_licenses.team_no_placeholder" className={classes.teamLabel}>
          <FormattedMessage id="user_management.invite.teams_licenses.label_no_team" />
        </Typography>
      );
    }
    return (
      <Box
        id="user_management.invite.teams_licenses.team_box_placeholder"
        className={classes.teamsRoot}
      >
        {selectTeamIds.map(item => renderTeamItem(item))}
      </Box>
    );
  };

  const renderLinkCreateTeam = () => {
    const redirectUri = `${process.env.REACT_APP_ADMIN_URL}/organization/${organization?.id}/teams/settings`;
    return (
      <Link
        id="user-management.invite.teams_licenses.link_create_team"
        href={resolvePath(ssoPath, undefined, { redirect_uri: `${redirectUri}` })}
        target="child"
        underline="none"
      >
        <Typography
          id="user-management.invite.teams_licenses.link_create_team_text"
          variant="subtitle2"
          color="#1847ca"
        >
          <FormattedMessage id="user_management.invite.teams_licenses.link_create_team" />
        </Typography>
      </Link>
    );
  };

  const renderLinkComponent = (feature: OrganizationFeature) => {
    switch (feature) {
      case OrganizationFeature.KSE:
        return (
          <Link
            id="user-management.invite-user-dialog.kse-info"
            href={PATH_KSE_INFO}
            target="_blank"
            underline="none"
          >
            <InfoOutlinedIcon className={classes.infoIcon} />
          </Link>
        );
      case OrganizationFeature.PER_USER_KSE:
        return (
          <Link
            id="user-management.invite-user-dialog.kse-per-user"
            href={PATH_KSE_PER_USER}
            target="_blank"
            underline="none"
          >
            <InfoOutlinedIcon className={classes.infoIcon} />
          </Link>
        );
      case OrganizationFeature.UNLIMITED_KSE:
        return (
          <Link
            id="user-management.invite-user-dialog.kse.floating"
            href={PATH_KSE_FLOATING}
            target="_blank"
            underline="none"
          >
            <InfoOutlinedIcon className={classes.infoIcon} />
          </Link>
        );
      case OrganizationFeature.UNLIMITED_ENGINE:
        return (
          <Link
            id="user-management.invite-user-dialog.kre-floating"
            href={PATH_UNLIMITED_ENGINE}
            target="_blank"
            underline="none"
          >
            <InfoOutlinedIcon className={classes.infoIcon} />
          </Link>
        );
      case OrganizationFeature.ENGINE:
        return (
          <Link
            id="user-management.invite-user-dialog.kre-info"
            href={PATH_KRE_INFO}
            target="_blank"
            underline="none"
          >
            <InfoOutlinedIcon className={classes.infoIcon} />
          </Link>
        );
      case OrganizationFeature.TESTOPS_PLATFORM:
        return (
          <Link
            id="user-management.invite-user-dialog.testops-info"
            href={PATH_TESTOPS_PLATFORM_PLAN}
            target="_blank"
            underline="none"
          >
            <InfoOutlinedIcon className={classes.infoIcon} />
          </Link>
        );
      default:
        return (
          <Link
            id="user-management.invite-user-dialog.license-overview"
            href={PATH_LICENSE_OVERVIEW}
            target="_blank"
            underline="none"
          >
            <InfoOutlinedIcon className={classes.infoIcon} />
          </Link>
        );
    }
  };

  const renderSubSwitch = (feature: OrganizationFeature, isAutoAssignedKRE: () => boolean) => (
    isAutoAssignedKRE()
      ? (
        <CustomSwitch
          id={`user_management.invite.teams_licenses.switch_${feature}`}
          value={feature}
          defaultChecked
          disabled
        />
      ) : (
        <CustomSwitch
          id={`user_management.invite.teams_licenses.switch_${feature}`}
          value={feature}
          onChange={handleToggleLicense}
          checked={selectedSubs.map(it => it.feature).includes(feature)}
        />
      ));

  const renderSubTitleMsg = (sub: AvailableSubscription) => {
    const featureName = `${OrganizationFeature.getFeatureFullName(sub.feature).replace('(', '').replace(')', '')}`;
    const slotsRemaining = unlimitedAssignableSubs.includes(sub.feature)
      ? intl.formatMessage({ id: 'user_management.invite_users.unlimited' })
      : `${sub.remainingLicenses}/${sub.total}`;
    return `${featureName} (${slotsRemaining})`;
  };

  const renderSubIcon = (feature: OrganizationFeature) => (
    [OrganizationFeature.UNLIMITED_ENGINE, OrganizationFeature.ENGINE]
      .includes(feature) ? (<KREIcon />) : (<KSEIcon />)
  );

  const renderSubMsg = (
    sub: AvailableSubscription,
    isCurrentSubSelected: () => boolean | undefined,
    isAutoAssignedKRE: () => boolean,
    currentSelectedSub?: SubscriptionInvitation,
  ) => {
    if (isCurrentSubSelected() || isAutoAssignedKRE()) {
      // KRE license: auto assigned when it's only 1 subscription (KRE NL or KRE F)
      return (
        <Typography
          id={`user_management.invite.teams_licenses.invite_msg_${sub.feature}`}
          variant="subtitle2"
          className={classes.message}
        >
          <FormattedMessage
            id="user_management.invite_users.invite_message"
            values={{
              countUsers,
              orgName: organization?.name,
              subName: sub.featureHalfFullName,
            }}
          />
        </Typography>
      );
    }
    if (currentSelectedSub && currentSelectedSub.quantity === 0) {
      return (
        <div className={clsx(classes.warning)}>
          <WarningIcon className={classes.warningIcon} />
          <Typography
            id={`user_management.invite.teams_licenses.warning_msg_${sub.feature}`}
            variant="subtitle2"
            className={classes.warningMessage}
          >
            <FormattedMessage id="user_management.invite_users.users_exceed" values={{ br: <br /> }} />
          </Typography>
        </div>
      );
    }
    return (
      <Typography
        id="user_management.invite.teams_licenses.invite_msg_occupied_space"
        variant="subtitle2"
        className={classes.occupiedSpace}
      />
    );
  };

  const renderSubs = () => [
    <div key={`${OrganizationFeature.TESTOPS_PLATFORM}-licenseWrapper`} className={classes.licenseWrapper}>
      <div className={classes.license}>
        <CustomSwitch
          id={`user_management.invite.teams_licenses.switch_${OrganizationFeature.TESTOPS_PLATFORM}`}
          defaultChecked
          disabled
        />
        <div className={classes.licenseInfo}>
          <TestOpsIcon />
          <Typography
            id={`user_management.invite.teams_licenses.feature_name_${OrganizationFeature.TESTOPS_PLATFORM}`}
            className={classes.licenseName}
            variant="subtitle2"
          >
            {`${OrganizationFeature.getFeatureFullName(OrganizationFeature.TESTOPS_PLATFORM)
              .replace('(', '').replace(')', '')}`}
          </Typography>
        </div>
        {
          renderLinkComponent(OrganizationFeature.TESTOPS_PLATFORM)
        }
      </div>
      <Typography
        id={`user_management.invite.teams_licenses.invite_msg_${OrganizationFeature.TESTOPS_PLATFORM}`}
        variant="subtitle2"
        className={classes.message}
      >
        <FormattedMessage
          id="user_management.invite_users.invite_message"
          values={{
            countUsers,
            orgName: organization?.name,
            subName: OrganizationFeature
              .getFeatureHalfFullName(OrganizationFeature.TESTOPS_PLATFORM),
          }}
        />
      </Typography>
    </div>,
    ...availableSubs
      .filter(it => !it.feature.includes(OrganizationFeature.TESTOPS)
        && !isVisualTestingFeature(it.feature))
      .map(sub => {
        const currentSelectedSub = selectedSubs.find(it => it.feature === sub.feature);
        const isCurrentSubSelected = () => (currentSelectedSub && currentSelectedSub.quantity > 0);
        const isAutoAssignedKRE = () => kREProducts.includes(sub.feature) && !isContainedNLandF();
        return (
          <div key={`${sub.feature}-licenseWrapper`} className={classes.licenseWrapper}>
            <div className={classes.license}>
              {
                renderSubSwitch(sub.feature, isAutoAssignedKRE)
              }
              <div className={classes.licenseInfo}>
                {
                  renderSubIcon(sub.feature)
                }
                <Typography
                  id={`user_management.invite.teams_licenses.license_name_${sub.feature}`}
                  className={classes.licenseName}
                  variant="subtitle2"
                >
                  {
                    renderSubTitleMsg(sub)
                  }
                </Typography>
              </div>
              {
                renderLinkComponent(sub.feature)
              }
            </div>
            {
              renderSubMsg(sub, isCurrentSubSelected, isAutoAssignedKRE, currentSelectedSub)
            }
          </div>
        );
      }),
  ];

  return (
    <Grid
      id="user_management.invite.teams_licenses.container"
      container
      direction="column"
      className={classes.root}
      alignItems="center"
    >
      <Grid id="user_management.invite.teams_licenses.title_section" item>
        <Typography
          id="user_management.invite.teams_licenses.title_invitation"
          variant="h2"
          className={classes.title}
        >
          <FormattedMessage id="user_management.invite.teams_licenses.title" />
        </Typography>
      </Grid>
      <Grid id="user_management.invite.teams_licenses.input_section" item>
        {
          (!flags?.projectStandardizeEnabled
            || orgMigrationStatus !== OrganizationMigrationStatus.MIGRATING)
          && (
            <Grid
              id="user_management.invite.teams_licenses.team_gird_container"
              item
              justifyContent="center"
              className={classes.sectionTeamWrapper}
            >
              <Typography
                id="user_management.invite.teams_licenses.team_label_container"
                variant="body1"
                className={classes.labelSection}
              >
                <FormattedMessage id="user_management.invite.teams_licenses.label_add_teams" />
              </Typography>
              <div className={classes.teamContainer}>
                <div className={classes.licenseWrapper}>
                  <div className={classes.team}>
                    <Select
                      id="user_management.invite.teams_licenses.team_select_box"
                      multiple
                      displayEmpty
                      disabled={isEmptyTeams}
                      value={selectedTeams}
                      onChange={onTeamsSelected}
                      renderValue={renderTeamsPlaceHolder}
                      className={classes.selectionTeams}
                      MenuProps={{ classes: { paper: classes.menuPaper }, autoFocus: false }}
                    >
                      <MenuItem
                        id="user_management.invite.teams_licenses.team_action_item"
                        className={classes.selectionAction}
                        onClick={isEmpty(selectedTeams)
                          ? handleSelectAllTeams
                          : handleClearSelectedTeams}
                      >
                        <span>
                          { intl.formatMessage({ id: isEmpty(selectedTeams)
                            ? 'user_management.invite.teams_licenses.select_all_teams'
                            : 'user_management.invite.teams_licenses.deselect_teams' })}
                        </span>
                      </MenuItem>
                      {
                        renderTeams()
                      }
                    </Select>
                    <IconButton
                      id="user_management.invite.teams_licenses.refresh_team_button"
                      onClick={onClickRefreshTeams}
                    >
                      <RefreshIcon className={classes.iconRefresh} />
                    </IconButton>
                  </div>
                </div>
              </div>
              {
                renderLinkCreateTeam()
              }
            </Grid>
          )
        }
        <Grid
          id="user_management.invite.teams_licenses.license_gird_container"
          item
          justifyContent="center"
          className={classes.sectionLicenseWrapper}
        >
          <Typography
            id="user_management.invite.teams_licenses.license_label_container"
            variant="body1"
            className={classes.labelSection}
          >
            <FormattedMessage id="user_management.invite.teams_licenses.label_select_licenses" />
          </Typography>
          <div className={classes.licenseContainer}>
            { renderSubs() }
          </div>
        </Grid>
      </Grid>
      <div className={classes.buttonContainer}>
        <Button
          id="user_management.invite_users.confirm_button"
          variant="contained"
          color="primary"
          className={clsx(classes.button, classes.buttonConfirm)}
          disabled={!allowedSubmit}
          onClick={onSubmit}
        >
          <FormattedMessage id="user_management.invite_users.confirm" />
        </Button>
        <Button
          id="user_management.invite_users.back_button"
          className={clsx(classes.button, classes.buttonBack)}
          variant="text"
          onClick={onBack}
        >
          <FormattedMessage id="user_management.invite_users.back" />
        </Button>
      </div>
    </Grid>
  );
};
export default SelectTeamsAndLicenses;
