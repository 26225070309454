import { useSelector } from 'react-redux';
import MicroApp from '../components/micro-frontend/MicroApp';
import { useConfig } from '../config';
import { useLaunchDarkly } from '../launchdarkly';
import { OrganizationMigrationStatus } from '../models';
import { forbidden, login, logout, notFound, profile, resolvePath, sso, ssoTestOps, useQuery, welcome } from '../routes';
import { fromOrganizations } from '../store';
import { maintenanceProcess, orgCardView, projectManagement } from './routes';

const HeaderOrgRemodeling = () => {
  const { flags } = useLaunchDarkly();
  const { config } = useConfig();

  const orgId = Number(useQuery().get('orgId'));
  const organization = useSelector(fromOrganizations.selectOrganizationById(orgId));
  let accountId = useQuery().get('accountId') ?? '';
  if (!accountId) {
    accountId = organization?.accountId?.toString() ?? '';
  }

  const orgMigrationStatus = useSelector(
    fromOrganizations.selectOrgMigrationStatusById(Number(orgId)),
  );

  const resolveOrgHomePath = (orgId: number) => {
    let projectUri: string;
    const oldUI = resolvePath(sso, undefined, { redirect_uri: `${process.env.REACT_APP_ADMIN_URL}/organization/${orgId}/teams/projects` });
    const newUI = resolvePath(projectManagement, undefined, { orgId: `${orgId}` });
    const maintenanceUI = resolvePath(maintenanceProcess, undefined);
    if (orgMigrationStatus === OrganizationMigrationStatus.MIGRATED
      || !!config?.onpremise) {
      projectUri = newUI;
    } else if (flags?.projectStandardizeEnabled) {
      projectUri = maintenanceUI;
    } else if (flags?.projectManagementNewUIEnabled) {
      projectUri = newUI;
    } else {
      projectUri = oldUI;
    }
    return projectUri;
  };

  const resolveTestOpsHomePath = (orgId: number | undefined) => {
    if (!orgId) return resolvePath(ssoTestOps, undefined, { redirect_uri: '/home' });
    return resolvePath(ssoTestOps, undefined, { redirect_uri: `/organization/${orgId}/home`, orgId });
  };

  const resolveTestOpsHomePathFromAccount = (orgId: number | undefined) => {
    if (!orgId) return resolvePath(ssoTestOps, undefined, { redirect_uri: '/home' });
    return resolvePath(ssoTestOps, undefined, { redirect_uri: `/organization/${orgId}/home`, orgId }); // keep navigate to default Org in Account
  };

  const resolveUserSettingsPath = (orgId: number | undefined) => {
    // NOTE: update to use custom domain of Account when KO-4517 is done
    if (!orgId) return resolvePath(ssoTestOps, undefined, { redirect_uri: '/user/settings' });
    return resolvePath(ssoTestOps, undefined, { redirect_uri: '/user/settings', orgId });
  };

  if (accountId === '') {
    return null;
  }

  return (
    <MicroApp
      id="katalon-navigation-ui"
      nodeId="katalon-navigation-ui-header"
      path={process.env.REACT_APP_NAVIGATION_MICROAPP_URL ?? ''}
      overrideConfig={{
        myBaseUrl: `${window.location.origin}${process.env.PUBLIC_URL}`,
        katOneBaseName: config?.onpremise ? process.env.PUBLIC_URL : undefined,
        customDomainPostfix: `${process.env.REACT_APP_CUSTOM_DOMAIN_POSTFIX}`,
        navigationPaths: {
          login: `${process.env.PUBLIC_URL}${login.path}`,
          notFound: `${process.env.PUBLIC_URL}${notFound.path}`,
          forbidden: `${process.env.PUBLIC_URL}${forbidden.path}`,
          logout: `${process.env.PUBLIC_URL}${logout.path}`,
          createOrganization: resolvePath(sso, undefined, { redirect_uri: `${process.env.REACT_APP_ADMIN_URL}/home/create-organization` }),
          userSetting: resolveUserSettingsPath,
          profile: `${process.env.PUBLIC_URL}${profile.path}`,
          welcome: `${process.env.PUBLIC_URL}${welcome.path}`,
          serviceCloud: process.env.REACT_APP_SERVICE_CLOUD_OAUTH_INITIALIZATION_URL,
          home: resolvePath(orgCardView, undefined, { accountId }),
        },
        resolveOrgHomePath,
        resolveTestOpsHomePath,
        resolveTestOpsHomePathFromAccount,
        navigationType: 'HEADER',
        nodeId: 'katalon-navigation-ui-header',
      }}
    />
  );
};

export default HeaderOrgRemodeling;
