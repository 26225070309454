import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';

import { AppState } from '.';
import { User } from '../models';
import { Operator } from '../models/query';
import { UserService } from '../services';
import { upsertHubspotContact } from '../services/webhook';

export const USER_FEATURE_KEY = 'users';
interface UsersState extends EntityState<User> {
  loading: boolean;
  error?: string;
  selectedId: User['id'] | null;
  count: number;
  isAdminCreated?: boolean;
  submitting: boolean;
}

const usersAdapter = createEntityAdapter<User>();

export const createInitialState = (): UsersState => usersAdapter.getInitialState({
  loading: false,
  selectedId: null,
  selectedEmail: null,
  count: 0,
  isAdminCreated: false,
  submitting: false,
});

export const doCreateUser = createAsyncThunk(
  'users/createUser',
  async (input: Parameters<typeof UserService['createUser']>[0]) => {
    const user = await UserService.createUser(input);
    return { user };
  },
);

export const doUpdateUser = createAsyncThunk(
  'users/updateUser',
  async (input: Parameters<typeof UserService['updateUser']>[0]) => {
    const user = await UserService.updateUser(input);
    return { user };
  },
);

export const doUpdateHubSpot = createAsyncThunk(
  'users/updateHubspot',
  async (input: Parameters<typeof UserService['updateUser']>[0]) => {
    upsertHubspotContact({
      email: input.email || '',
      jobTitle: input.jobTitle || '',
      firstName: input.firstName || '',
      lastName: input.lastName || '',
      testingSolutions: input.testingSolutions || '',
    });
  },
);

export const doRevokeAccess = createAsyncThunk(
  'users/deleteUser',
  async (input: Parameters<typeof UserService['deleteUser']>[0]) => {
    await UserService.deleteUser(input);
    return { user: input };
  },
);

export const getUsers = createAsyncThunk(
  'users/getUsers',
  async ({ offset, limit, email }: { offset: number, limit: number, email?: User['email'] }) => {
    const users = email ? await UserService.getUsers(
      { field: 'archived', operator: Operator.EQ, value: false },
      { field: 'email', operator: Operator.EQ, value: email },
      { offset },
      { limit },
    ) : await UserService.getUsers(
      { field: 'archived', operator: Operator.EQ, value: false },
      { offset },
      { limit },
    );
    return { users: users.data, count: users.total };
  },
);

export const doFetchUserById = createAsyncThunk(
  'users/doFetchUserById',
  async (input: { id: User['id'] }) => {
    const users = await UserService.queryUsers(
      { field: 'archived', operator: Operator.EQ, value: false },
      { field: 'id', operator: Operator.EQ, value: input.id },
    );
    return { users: users.data, count: users.total };
  },
);

export const doCheckAdminCreated = createAsyncThunk(
  'users/checkAdminCreated',
  async () => {
    const response = await UserService.checkAdminCreated();
    return { isAdminCreated: response.data.userExists };
  },
);

const usersSlice = createSlice({
  name: USER_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {
    doChangeSelectedId(state, action: PayloadAction<Pick<User, 'id'>>) {
      state.selectedId = action.payload.id;
    },
  },
  extraReducers: builder => {
    builder.addCase(doCreateUser.pending, state => {
      state.loading = true;
    });
    builder.addCase(doCreateUser.fulfilled, (state, action) => {
      usersAdapter.addOne(state, action.payload.user);
      state.loading = false;
    });
    builder.addCase(doCreateUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(doUpdateUser.pending, state => {
      state.submitting = true;
    });
    builder.addCase(doUpdateUser.fulfilled, (state, action) => {
      usersAdapter.updateOne(state, {
        id: action.payload.user.id,
        changes: action.payload.user,
      });
      state.submitting = false;
    });
    builder.addCase(doUpdateUser.rejected, (state, action) => {
      state.submitting = false;
      state.error = action.error.message;
    });
    builder.addCase(doRevokeAccess.pending, state => {
      state.loading = true;
    });
    builder.addCase(doRevokeAccess.fulfilled, (state, action) => {
      usersAdapter.removeOne(state, action.payload.user.id);
      state.loading = false;
    });
    builder.addCase(doRevokeAccess.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getUsers.pending, state => {
      state.loading = true;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      usersAdapter.removeAll(state);
      state.loading = false;
      state.count = action.payload.count;
      usersAdapter.addMany(state, action.payload.users);
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(doFetchUserById.pending, state => {
      state.loading = true;
    });
    builder.addCase(doFetchUserById.fulfilled, (state, action) => {
      usersAdapter.removeAll(state);
      state.loading = false;
      state.count = action.payload.count;
      usersAdapter.addMany(state, action.payload.users);
    });
    builder.addCase(doFetchUserById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(doCheckAdminCreated.fulfilled, (state, action) => {
      state.loading = false;
      state.isAdminCreated = action.payload.isAdminCreated;
    });
    builder.addCase(doCheckAdminCreated.pending, state => {
      state.loading = true;
    });
    builder.addCase(doCheckAdminCreated.rejected, (state, action) => {
      state.loading = true;
      state.error = action.error.message;
    });
  },
});

const selectUsersFeature = (state: AppState) => state[USER_FEATURE_KEY];
const { selectAll, selectEntities } = usersAdapter.getSelectors(selectUsersFeature);
export const selectLoading = createSelector(
  selectUsersFeature,
  usersState => usersState.loading,
);
export const selectSubmitting = createSelector(
  selectUsersFeature,
  usersState => usersState.submitting,
);
export const selectError = createSelector(
  selectUsersFeature,
  usersState => usersState.error,
);
export const selectSelectedId = createSelector(
  selectUsersFeature,
  usersState => usersState.selectedId,
);
export const selectAllUsers = selectAll;
export const selectSelectedUser = createSelector(
  selectEntities,
  selectSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : null),
);
// becauseof missmatch user's id with TestOps, we need to select User by email instead of id
export const selectUserByEmail = (email: User['email']) => createSelector(
  selectAll,
  users => users.find(user => user.email === email),
);

export const selectUserById = (id: User['id']) => createSelector(
  selectAll,
  users => users.find(user => user.id === id),
);

export const selectCount = createSelector(
  selectUsersFeature,
  usersState => usersState.count,
);

export const selectIsAdminCreated = createSelector(
  selectUsersFeature,
  licenseState => licenseState.isAdminCreated,
);

export const { doChangeSelectedId } = usersSlice.actions;
export default usersSlice.reducer;
