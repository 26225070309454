import { useEffect, useState } from 'react';
import Button from '@katalon-studio/katalon-ui/Button';
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { fromUserInvitation, useAppDispatch } from '../../../store';
import CommonDataTable, { Column } from '../../../components/common/CommonDataTable';
import { AccountRole, UserInvitation, isInvitationExpired } from '../../../models';
import { useConfig } from '../../../config';
import CopyableLink from '../../../components/copyable-link/CopyableLink';
import LoadingProgress from '../../../layout/LoadingProgress';

interface PendingInvitationTabProps {
  accountId: number;
}

interface UserInvitationData extends UserInvitation {
  fullName?: string;
  action: React.ReactElement;
}

const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  toolbarBoxSizing: {
    width: theme.spacing(2),
  },
  toolbarButton: {
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
    backgroundColor: '#e7e9ef !important',
    color: '#22283c !important',
    '&:hover': {
      backgroundColor: '#e7e9ef',
      color: '#22283c',
    },
  },
  actionButton: {
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
  },
  invitationLinkContainer: {
    display: 'flex',
    maxWidth: theme.spacing(34),
    alignItems: 'self-end',
    paddingLeft: 0,
    paddingRight: 0,
  },
  invitationLink: {
    whiteSpace: 'nowrap',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  invitationLinkExpired: {
    whiteSpace: 'nowrap',
    color: '#B0B8CB',
  },
  ellipsisOverflow: {
    maxWidth: theme.spacing(32),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  fullNameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  fullName: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  fullNameFlex: {
    flex: 1,
  },
}));

const PendingInvitationTab = ({ accountId }: PendingInvitationTabProps) => {
  const columns: Column<UserInvitationData>[] = [
    {
      id: 'fullName',
      sortable: true,
      width: 200,
      minWidth: 180,
      render: row => (
        <div className={classes.fullNameContainer}>
          <Avatar className={classes.avatar} src={row.avatar} />
          <span className={classes.fullName}>
            {row.fullName}
          </span>
        </div>
      ),
    },
    {
      id: 'email',
      sortable: true,
      width: 200,
      minWidth: 180,
      hasTooltip: true,
    },
    {
      id: 'inviterUserEmail',
      sortable: true,
      width: 200,
      minWidth: 180,
      hasTooltip: true,
    },
    {
      id: 'createdAt',
      sortable: true,
      width: 160,
      minWidth: 160,
      render: row => (
        <>
          {
            row.createdAt ? intl.formatDate(
              row.createdAt,
              {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hourCycle: 'h23',
              },
            ) : noDataMessage
          }
        </>
      ),
    },
    {
      id: 'role',
      sortable: true,
      minWidth: 160,
      width: 160,
      render: row => (
        <>
          {AccountRole.getRoleFullName(row.role)}
        </>
      ),
    },
    {
      id: 'invitationToken',
      sortable: true,
      width: 240,
      minWidth: 200,
      render: row => renderInvitationLink(row),
    },
    {
      id: 'action',
      align: 'right',
      width: 80,
      ignoreHeader: true,
    },
  ];

  const dispatch = useAppDispatch();
  const intl = useIntl();
  const classes = useStyles();
  const { config } = useConfig();

  const noDataMessage = intl.formatMessage({ id: 'account.users.table.no_data_cell' });

  const userInvitations = useSelector(fromUserInvitation.selectInvitationsByAccountId(accountId));

  const [fetched, setFetched] = useState(false);
  const [data, setData] = useState<UserInvitationData[]>([]);
  const [selectedRows, setSelectedRows] = useState<readonly number[]>([]);

  useEffect(() => {
    const fetchInfo = async () => {
      const promises: Promise<any>[] = [
        dispatch(fromUserInvitation.doGetUserInvitationsByAccountId({ accountId })),
      ];
      await Promise.all(promises);
      setFetched(true);
    };
    if (accountId) {
      fetchInfo();
    }
  }, [accountId]);

  useEffect(() => {
    if (!fetched) return;
    setData(userInvitations.map(invitation => ({
      ...invitation,
      role: invitation.role ?? AccountRole.MEMBER,
      action: isActiveInvitation(invitation) ? <></> : renderAction(),
    })));
  }, [fetched]);

  const handleFilter = (value: string) => data.filter(it => {
    const isMatchName = it.fullName && it.fullName.toLowerCase().indexOf(value) > -1;
    const isMatchEmail = it.email && it.email.toLowerCase().indexOf(value) > -1;
    return isMatchName || isMatchEmail;
  });

  const handleSelect = (selected: readonly number[]) => setSelectedRows(selected);

  const isActiveInvitation = (row: UserInvitation) => {
    const invitationLastSentDate = row.updatedAt ?? row.createdAt ?? 0;
    return !isInvitationExpired(Number(invitationLastSentDate), config);
  };

  const renderAction = () => (
    <Button
      id="account.users.table.pending.action.resend_invitation"
      size="small"
      color="primary"
      variant="text"
      className={classes.actionButton}
    >
      {intl.formatMessage({ id: 'account.users.table.pending.action.resend_invitation.button' })}
    </Button>
  );

  const renderInvitationLink = (row: UserInvitationData) => {
    const invitationLink = config?.onpremise && row.resetPasswordCode
      ? `${config?.publicUrl}/login?reset_password_code=${row.resetPasswordCode}&redirect=/accept-invitation?invitation_token=${row.invitationToken}&source=accept-invitation`
      : `${config?.publicUrl}/accept-invitation?invitation_token=${row.invitationToken}&source=accept-invitation`;
    const invitationDisplayLink = `${(invitationLink ?? '').split('token')[0]}...`;
    return (
      <div
        className={
          clsx({
            [classes.invitationLink]: isActiveInvitation(row),
            [classes.invitationLinkExpired]: !isActiveInvitation(row),
            [classes.ellipsisOverflow]: navigator.clipboard,
            [classes.cursorPointer]: isActiveInvitation(row) && navigator.clipboard,
          })
        }
      >
        {!navigator.clipboard
          ? <span>{invitationLink}</span>
          : (
            <CopyableLink
              link={invitationLink}
              displayLink={invitationDisplayLink}
              isCopyable={isActiveInvitation(row)}
            />
          )}
      </div>
    );
  };

  if (!fetched) {
    return <LoadingProgress />;
  }

  return (
    <CommonDataTable
      tableKey="account.users.table.pending"
      columns={columns}
      data={data}
      sortBy="createdAt"
      sortType="desc"
      paginationOptions={[10, 20, 50]}
      searchable
      onSearchTextChange={handleFilter}
      selectable
      onSelectRow={handleSelect}
      selectedRows={selectedRows}
      additionToolbar={(
        <Grid item flex={1} className={classes.toolbar}>
          <Box className={classes.toolbarBoxSizing} />
          <Button
            id="account.users.table.pending.toolbar.resend_invitation"
            size="small"
            color="primary"
            variant="contained"
            disabled={selectedRows.length === 0}
            className={classes.toolbarButton}
          >
            {intl.formatMessage({ id: 'account.users.table.pending.toolbar.resend_invitation.button' })}
          </Button>
          <Box className={classes.toolbarBoxSizing} />
          <Button
            id="account.users.table.pending.toolbar.revoke_invitation"
            size="small"
            color="primary"
            variant="contained"
            disabled={selectedRows.length === 0}
            className={classes.toolbarButton}
          >
            {intl.formatMessage({ id: 'account.users.table.pending.toolbar.revoke_invitation.button' })}
          </Button>
        </Grid>
      )}
    />
  );
};

export default PendingInvitationTab;
