import { TestOpsPlatformTierType } from '../../models/testOpsPlatformTierType';
import { TestOpsPlatformTierTypeV2 } from '../../models/testOpsPlatformTierTypeV2';

import {
  getIntervalPathParam,
  getQuotaPathParam,
  OrganizationFeature,
  TestOpsPlanInterval,
  TestOpsPlatformSubscription,
} from '../../models';
import { LaunchDarklyFlag } from '../../launchdarkly';
import { useQuery } from '../../routes';
import store from '../../store';

export const UNLIMITED_PROJECT = 999999999;
export const UNLIMITED_TEST_EXECUTION = 999999999;
export const PLATFORM_FREE_USER = 5;
const MONTH_RESTRICT_UPGRADE = 2764800000; // 32 days
const YEAR_RESTRICT_UPGRADE = 31622400000; // 366 days

// maximum amount that recurly allow for a payment
export const MAX_TOTAL_AMOUNT = 1000000;
export const DISCOUNT_PLATFORM_1_AMOUNT = 1199;
export const DISCOUNT_PLATFORM_2_AMOUNT = 1274;
export const DISCOUNT_STANDALONE_1_AMOUNT = 1079;
export const DISCOUNT_STANDALONE_2_AMOUNT = 1199;
export const DISCOUNT_STANDALONE_3_AMOUNT = 600;

export const STARTER_PACKAGE_DEFAULT = 'PACKAGE_1_1';

// User created account before startTimeOfFreePeriod, they can not upgrade in the first cycle
export const restrictTestOpsPlatformUpgrade = (
  accountCreatedAt: number,
  startTimeOfFreePeriod: number,
  currentPaidTestOpsPlatformSubscription?: TestOpsPlatformSubscription,
): boolean => {
  const currentTime = new Date().valueOf();
  if (currentPaidTestOpsPlatformSubscription
    && (accountCreatedAt < startTimeOfFreePeriod)
  ) {
    const createdAt = currentPaidTestOpsPlatformSubscription
      ?.recurlySubscription?.createdAt.valueOf();
    const remainingTime = currentTime - createdAt;
    const currentBillingCycle = currentPaidTestOpsPlatformSubscription.billingCycle;
    if ((currentBillingCycle === TestOpsPlanInterval.MONTH
        && remainingTime < MONTH_RESTRICT_UPGRADE)
      || (currentBillingCycle === TestOpsPlanInterval.YEAR
        && remainingTime < YEAR_RESTRICT_UPGRADE)
    ) {
      return true;
    }
  }
  return false;
};

export const convertToTestOpsPlatformTierV2 = (
  feature: TestOpsPlatformTierType,
) => {
  switch (feature) {
    case TestOpsPlatformTierType.FREE:
      return TestOpsPlatformTierTypeV2.FREE;
    case TestOpsPlatformTierType.PROFESSIONAL:
      return TestOpsPlatformTierTypeV2.PREMIUM;
    case TestOpsPlatformTierType.BUSINESS:
      return TestOpsPlatformTierTypeV2.ULTIMATE;
    case TestOpsPlatformTierType.ENTERPRISE:
      return TestOpsPlatformTierTypeV2.ULTIMATE;
    default:
      return feature;
  }
};

export const getGroupNameByPackageName = (packageName: string) => {
  if (packageName.startsWith('PACKAGE_1')) return 'PACKAGE_1';
  if (packageName.startsWith('PACKAGE_2')) return 'PACKAGE_2';
  if (packageName.startsWith('PACKAGE_3')) return 'PACKAGE_3';
  if (packageName.startsWith('PACKAGE_4')) return 'PACKAGE_4';
  return '';
};

export const formatUSPrice = (price: number) => price.toLocaleString('en-US');

export const differenceInMonthDays = (date1: Date, date2: Date) => {
  const timeDiff = date1.getTime() - date2.getTime();
  const monthDiff = date1.getMonth() - date2.getMonth();
  const yearDiff = date1.getFullYear() - date2.getFullYear();

  const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

  return {
    months: monthDiff + yearDiff * 12 - 1,
    days: dayDiff,
  };
};

export const isDiscountCampaignEnabled = (accountId: number, flags?: LaunchDarklyFlag) => {
  if (flags?.monthlySubsYearEndCampaignConfig === undefined || flags?.monthlySubsYearEndCampaignConfig === '') {
    return false;
  }
  const discountCampaignConfig = JSON.parse(flags?.monthlySubsYearEndCampaignConfig);
  return discountCampaignConfig?.accountIds.includes(accountId)
    && Object.keys(discountCampaignConfig?.coupons).length > 0;
};

export const isBankTransferEnabled = (accountId: number, flags?: LaunchDarklyFlag) => {
  if (flags?.bankTransferEnabled === true) {
    return true;
  }

  return flags?.bankTransferWhiteList.includes(accountId);
};

export const BUSINESS_EMAIL = 'mailto:business@katalon.com';
export const CUTOMER_SUPPORT_EMAIL = 'mailto:support@katalon.com';

export const getQuotaFromPathParam = (feature: OrganizationFeature) => {
  const { get } = useQuery();
  return Number(
    get(getQuotaPathParam(feature)),
  ) || 0;
};

export const getCycleFromPathParam = (feature: OrganizationFeature) => {
  const { get } = useQuery();
  return (TestOpsPlanInterval as any)[get(getIntervalPathParam(feature)) ?? ''];
};

export const sendTrackingData = (
  eventName: string,
  accountId: number,
  organizationId?: number,
  additionalProps?: Object | undefined,
) => {
  const currentState = store.getState();
  const user = currentState.auth.user || undefined;
  const accountTotalMembers = Object.values(
    currentState.accountUsers.entities,
  ).filter(it => it !== undefined && it.accountId === accountId).length;

  analytics.track(
    eventName,
    {
      id: user?.id,
      email: user?.email,
      account_id: accountId,
      timestamp: new Date().toISOString(),
      ...additionalProps ?? {
        organization_id: organizationId,
        role: user?.roles,
        total_members: accountTotalMembers,
      },
    },
  );
};
