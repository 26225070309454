import Axios from 'axios';
import { PageableResponse, TestOpsUserSsoInvitation } from '../models';
import { composeQuery, Query } from '../models/query';

export const getTestOpsUserSsoInvitations = async (...criteria: Query<TestOpsUserSsoInvitation>[]) => (await Axios.get<PageableResponse<TestOpsUserSsoInvitation>>('/v1/testops-user-sso-invitations', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: composeQuery<TestOpsUserSsoInvitation>(...criteria),
  data: {},
})).data;
