import Axios from 'axios';
import { UserGroupUser } from '../models/userGroup';
import { composeQuery, Query } from '../models/query';
import { PageableResponse } from '../models';

export const getAllUserGroupUsers = async (
  userGroupId: number,
  ...criteria: Query<UserGroupUser>[]
) => (
  await Axios.get<PageableResponse<UserGroupUser>>(`/v1/user-groups/${userGroupId}/users`, {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    params: composeQuery<UserGroupUser>(...criteria),
    data: {},
  })
).data;

export const deleteUserGroupUser = async (
  userGroupId: number,
  userGroupUserId: number,
) => (
  await Axios.delete<PageableResponse<UserGroupUser>>(`/v1/user-groups/${userGroupId}/users/${userGroupUserId}`, {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    data: {},
  })
).data;
