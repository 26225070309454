import Axios from 'axios';
import { DataResponse, MetricData, PageableResponse, TestCloudUsage } from '../models';
import { composeMetricQuery, composeQuery, MetricQuery, Query } from '../models/query';

export const getLatestTestCloudUsage = async (input: Required<Pick<TestCloudUsage, 'organizationId'>>) => (
  (await Axios.get<DataResponse<TestCloudUsage>>(`/v1/testcloud-usage/latest/${input.organizationId}`)).data).data;

export const getTestCloudUsage = async (...criteria: Query<TestCloudUsage>[]) => (
  (await Axios.get<PageableResponse<TestCloudUsage>>('/v1/testcloud-usage', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    params: composeQuery<TestCloudUsage>(...criteria),
    data: {},
  })).data
);

export const getTestCloudUsageMetrics = async (...criteria: MetricQuery<TestCloudUsage>[]) => (
  (await Axios.get<PageableResponse<MetricData>>('/v1/testcloud-usage/metrics', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    params: composeMetricQuery<TestCloudUsage>(...criteria),
    data: {},
  })).data
);
