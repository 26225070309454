import { ThemeOptions } from '@mui/material/styles';

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      50: '#80d4ff',
      100: '#66cbff',
      200: '#4dc3ff',
      300: '#33baff',
      400: '#1ab2ff',
      500: '#00a9ff',
      600: '#0098e6',
      700: '#0087cc',
      800: '#0076b3',
      900: '#006599',
      A100: '#99ddff',
      A200: '#b3e5ff',
      A400: '#cceeff',
      A700: '#005480',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#ff6982',
      dark: '#a1002e',
      main: '#d93256',
      50: '#244116',
      100: '#2f541c',
      200: '#396722',
      300: '#447b29',
      400: '#4f8e2f',
      500: '#59a136',
      600: '#70c248',
      700: '#7fc85b',
      800: '#8ecf6e',
      900: '#9dd581',
      A100: '#70c248',
      A200: '#64b43c',
      A400: '#59a136',
      A700: '#acdb94',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#E11900',
    },
  },

  typography: {
    fontFamily: 'Inter, sans-serif',
    h5: {
      fontSize: '0.925rem',
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 'normal',
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
    },
    h2: {
      fontSize: '2.0243rem',
      fontWeight: 500,
      lineHeight: 1.235,
      letterSpacing: '0.00735em',
    },
    subtitle1: {
      fontSize: '.85rem',
      fontWeight: 'bold',
    },
    subtitle2: {
      fontSize: '0.925rem',
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
      fontWeight: 'normal',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
      },
      styleOverrides: {
        containedPrimary: {
          backgroundColor: '#276EF1',
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        text: {
          padding: '6px 12px',
        },
        root: {
          padding: '6px 12px',
          justifyContent: 'center',
          textTransform: 'none',
          fontWeight: 'bold',
          // button text use direct label,
          // this is in case button wrap the Typography component:
          '& > *': {
            fontWeight: 'bold',
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 30,
          height: 30,
        },
        colorDefault: {
          backgroundColor: '#ffffff',
          color: '#4b78f5',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          textOverflow: 'ellipsis',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontSize: '0.75rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
          textTransform: 'uppercase',
          fontWeight: 'bold',
        },
        root: {
          fontSize: '1rem',
          borderBottom: 'none',
        },
        body: {
          fontSize: '.875rem',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '0.925rem',
          textOverflow: 'ellipsis',
          lineHeight: 1.43,
          // if screen's size down to sm, the height can be too short
          //  we need to make it taller
          '@media (min-width: 600px)': {
            minHeight: '40px',
          },
          '.MuiListItemIcon-root': {
            minWidth: '32px',
          },
          '& > *': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: '1rem',
          fontWeight: 500,
        },
        secondary: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderStyle: 'solid',
          borderWidth: 0,
          borderTopWidth: '1px',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#172B4D',
          lineHeight: '1.3',
          fontSize: '14px',
          fontWeight: 'normal',
          maxWidth: '350px', // increase from default 300px
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        tag: {
          maxWidth: 'calc(100% - 72px)',
          borderRadius: '4px',
          padding: '2px 3px',
          fontSize: '12px',
        },
        popper: {
          // follow https://material-ui.com/customization/z-index/
          // default order is 1300, it make the popup on top of appbar
          zIndex: 1075,
        },
        option: {
          '&[data-focus="true"]': {
            backgroundColor: 'transparent',
          },
          '&[aria-selected="true"]': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          fontSize: '26px',
          opacity: '0.2',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        deleteIcon: {
          width: '1rem',
          color: 'rgba(0, 0, 0, 0.7)',
          '&:hover': {
            color: 'rgba(0, 0, 0, 1)',
          },
        },
        root: {
          height: '28px',
          borderRadius: '2px',
        },
        label: {
          fontSize: '0.925rem',
          paddingLeft: '8px',
          paddingRight: '8px',
        },
        labelSmall: {
          fontSize: '0.75rem',
          paddingLeft: '6px',
          paddingRight: '6px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          border: 'none',
          '& fieldset': {
            border: '1px solid #EEF3FA',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          // zIndex of drawer(1200) + 1
          zIndex: 1201,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem', // see: https://mui.com/guides/migration-v4/#cssbaseline
        },
        html: {
          '*': {
            scrollbarColor: '#5C6C99 #DDE1EB',
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
              background: '#DDE1EB',
              width: '4px',
              height: '4px',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-track': {
              display: 'none',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#5C6C99',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-corner': {
              display: 'none',
            },
            '&::-webkit-scrollbar-button': {
              display: 'none',
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          '@media(min-width: 1200px)': {
            fontSize: '1.5rem',
          },
          fontSize: '1.25rem',
          fontWeight: 'bold',
          marginTop: '0.75rem',
          marginBottom: '0.75rem',
          paddingBottom: 0,
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        grouped: {
          borderRadius: '4px',
          '&:not(:first-of-type)': {
            borderRadius: '4px',
          },
          '&:not(:last-of-type)': {
            borderRadius: '4px',
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
};

export default themeOptions;
