import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import memorize from 'lodash/fp/memoize';
import { AppState } from '.';
import { Machine, Organization } from '../models';
import { Operator, Query } from '../models/query';
import { MachineService } from '../services';

export const MACHINE_FEATURE_KEY = 'machines';

interface MachinesState extends EntityState<Machine> {
  loading: boolean;
  count: number;
}

const machineAdapter = createEntityAdapter<Machine>();
const MAX_NUMBER_OF_RECORDS = 50;

export const createInitialState = (): MachinesState => machineAdapter.getInitialState({
  loading: false,
  count: 0,
});

export const doGetMachinesByOrganizationId = createAsyncThunk(
  'machines/getMachinesByOrganizationId',
  async (organizationId: Organization['id']) => {
    const criteria: Array<Query<Machine>> = [
      { field: 'organizationId', operator: Operator.EQ, value: organizationId },
    ];
    const firstResponse = await MachineService.getMachines(...criteria);
    const numberOfRequests = Math.ceil(firstResponse.total / MAX_NUMBER_OF_RECORDS);

    const remainingRequests = [];
    for (let i = 1; i < numberOfRequests; i += 1) {
      const request = MachineService.getMachines(
        ...criteria,
        { offset: MAX_NUMBER_OF_RECORDS * i },
      );
      remainingRequests.push(request);
    }
    const remainingResponses = await Promise.all(remainingRequests);
    const machines = [...firstResponse.data];
    remainingResponses.forEach(response => machines.push(...response.data));

    return { machines, count: machines.length };
  },
);

const machinesSlice = createSlice({
  name: MACHINE_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doGetMachinesByOrganizationId.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetMachinesByOrganizationId.fulfilled, (state, action) => {
      state.loading = false;
      state.count = action.payload.count;
      machineAdapter.removeAll(state);
      machineAdapter.addMany(state, action.payload.machines);
    });
    builder.addCase(doGetMachinesByOrganizationId.rejected, state => {
      state.loading = false;
    });
  },
});

const selectMachinesFeature = (state: AppState) => state[MACHINE_FEATURE_KEY];

export const {
  selectAll: selectAllMachines,
  selectIds,
  selectEntities,
} = machineAdapter.getSelectors(selectMachinesFeature);

export const selectUniqueMachineKeysByOrganizationId = memorize((organizationId: Organization['id']) => createSelector(
  selectAllMachines,
  machines => machines
    .filter(machine => machine.organizationId === organizationId)
    .map(machine => machine.machineKey)
    .filter((value, index, self) => self.indexOf(value) === index)
    .sort(),
));

export default machinesSlice.reducer;
