import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import { TestOpsSubscriptionSource } from '../models/testOpsSubscriptionSource';
import {
  exceptionOf,
  OrganizationTrialRequest,
  SerializedException,
} from '../models';
import { Operator, Query } from '../models/query';
import { SubscriptionStatus } from '../models/subscriptionStatus';
import { SubscriptionService } from '../services';
import { AppState } from './index';
import { VisualTestingSubscription } from '../models/visualTestingSubscription';
import { AdditionalResources } from './subscriptionsSlice';

export const VISUAL_TESTING_SUBSCRIPTION_KEY = 'visualTestingSubscriptions';

interface VisualTestingSubscriptionState extends EntityState<VisualTestingSubscription> {
  loading: boolean;
  invoiceNumbers: string[];
  trialRequest: OrganizationTrialRequest | null | undefined;
  errors: SerializedException[];
}

const visualTestingSubscriptionsAdapter = createEntityAdapter<VisualTestingSubscription>();
const createInitialState = (partialState: Partial<VisualTestingSubscriptionState> = {}) => (
  visualTestingSubscriptionsAdapter.getInitialState({
    ...partialState,
    loading: false,
    errors: [] as SerializedException[],
  })
);

export const doGetActiveByAccountId = createAsyncThunk(
  'visualTestingSubscriptions/getByAccountId',
  async (input: Required<Pick<VisualTestingSubscription, 'accountId'>> & AdditionalResources, { rejectWithValue }) => {
    const criteria: Query<VisualTestingSubscription>[] = [
      {
        field: 'accountId',
        operator: Operator.EQ,
        value: input.accountId,
      },
      {
        field: 'status',
        operator: Operator.EQ,
        value: SubscriptionStatus.ACTIVE,
      },
      {
        field: 'expiryDate',
        operator: Operator.GT,
        value: new Date(Date.now() - (7 * 24 * 60 * 60 * 1000)).getTime(),
      },
    ];
    try {
      return (await SubscriptionService.getVisualTestingSubscriptions(
        input.checkHasPremierSuccess || false,
        ...criteria,
      ));
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doResetEntity = createAction('entity/reset');

const visualTestingSubscriptionsSlice = createSlice({
  name: VISUAL_TESTING_SUBSCRIPTION_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doGetActiveByAccountId.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetActiveByAccountId.fulfilled, (state, action) => {
      state.loading = true;
      visualTestingSubscriptionsAdapter.upsertMany(state, action.payload);
    });
    builder.addCase(doGetActiveByAccountId.rejected, (state, action) => {
      state.loading = true;
      state.errors.push(action.payload as SerializedException);
    });
    builder.addCase(doResetEntity, state => {
      visualTestingSubscriptionsAdapter.removeAll(state);
    });
  },
});

const selectVSTSubscriptionFeature = (state: AppState) => state[VISUAL_TESTING_SUBSCRIPTION_KEY];
const { selectAll } = visualTestingSubscriptionsAdapter.getSelectors(selectVSTSubscriptionFeature);

export const selectLoading = createSelector(
  selectVSTSubscriptionFeature,
  subscriptionState => subscriptionState.loading,
);

export const selectByAccountId = (id: VisualTestingSubscription['accountId']) => createSelector(
  selectAll,
  all => all.filter(it => it.accountId === id),
);

export const selectPaidSubscriptionByAccountId = (id: VisualTestingSubscription['accountId']) => createSelector(
  selectAll,
  all => all.filter(it => it.accountId === id && it.source === TestOpsSubscriptionSource.RECURLY),
);

export const selectTrialRequestSubscriptionByAccountId = (id: VisualTestingSubscription['accountId']) => createSelector(
  selectAll,
  all => all.filter(it => it.accountId === id
    && it.source === TestOpsSubscriptionSource.TRIAL_REQUEST),
);

export default visualTestingSubscriptionsSlice.reducer;
