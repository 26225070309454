import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import { useIntl, FormattedMessage, FormattedDate } from 'react-intl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdorment from '@mui/material/InputAdornment';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import TablePagination from '@mui/material/TablePagination';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import orderBy from 'lodash/orderBy';
import UserGroupHeaderTable from './UserGroupHeaderTable';
import { AccountRole, AccountUser, OrganizationRole, OrganizationUser } from '../../models';
import { fromAuth } from '../../store';
import DeleteUserGroupPopup from './DeleteUserGroupPopup';
import { resolvePath } from '../../routes';
import { userGroupDetail } from '../../layout/routes';
import { ReactComponent as SearchIcon } from '../icons/search-icon.svg';
import { ReactComponent as TrashAltIcon } from '../icons/trash-alt-icon.svg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    wordBreak: 'break-word',
  },
  filterContainer: {
    margin: theme.spacing(2),
  },
  search: {
    marginRight: theme.spacing(0.5),
    '& > .MuiInputBase-root': {
      width: theme.spacing(40),
      height: theme.spacing(5),
      borderRadius: theme.spacing(0.5),
      maxHeight: theme.spacing(5),
      paddingLeft: theme.spacing(1),
      backgroundColor: 'rgb(255, 255, 255)',
    },
    '& > * > .MuiInputBase-inputSizeSmall': {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
    },
  },
  inputSearch: {
    fontSize: '0.875rem',
  },
  textButton: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(0, 0.5),
    minWidth: theme.spacing(5),
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    maxHeight: theme.spacing(4),
  },
  defaultIcon: {
    fontSize: theme.spacing(1.75),
    color: 'rgba(104, 109, 128, 0.54)',
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  noResultFound: {
    textAlign: 'center',
    margin: theme.spacing(2, 0),
  },
  disabledHoverRow: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    height: theme.spacing(8),
  },
  checkboxWrapper: {
    width: theme.spacing(1.75),
  },
  groupName: {
    color: '#598ef9',
    '&:hover': {
      textDecoration: 'none',
    },
    fontSize: theme.spacing(1.75),
  },
  groupDescription: {
    color: '#808b9a',
    maxWidth: theme.spacing(57),
    fontSize: theme.spacing(1.75),
  },
  deleteIcon: {
    width: theme.spacing(1.75),
    height: theme.spacing(1.75),
    fontSize: theme.spacing(1.75),
    color: '#808b9a',
  },
  tablePaginationSelectLabel: {
    fontSize: theme.spacing(1.5),
    color: '#808b9a',
  },
  tablePaginationSelect: {
    color: '#233145',
  },
  tablePaginationSelectIcon: {
    color: 'rgba(104, 109, 128, 0.54)',
    size: '10',
  },
  tablePaginationToolbar: {
    '& > p:nth-of-type(2)': {
      color: '#233145',
    },
  },
  paper: {
    flexDirection: 'column',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

export interface UserGroupRow {
  id: number,
  groupName: string,
  description?: string,
  totalUsers?: number,
  createdAt: Date,
}

export interface UserGroupTableProps {
  dataTable: UserGroupRow[],
  orgId: number,
  currentOrgUser?: OrganizationUser,
  currentAccountUser?: AccountUser,
}

const UserGroupTable = (props: UserGroupTableProps) => {
  const {
    dataTable,
    currentOrgUser,
    currentAccountUser,
    orgId,
  } = props;
  const classes = useStyles();
  const intl = useIntl();

  const isRootOrAdmin = useSelector(fromAuth.selectIsRootOrAdmin);

  const [matchedRows, setMatchedRows] = useState<UserGroupRow[]>(dataTable);
  const [currentSearchText, setCurrentSearchText] = useState<string>('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const currentPageRows = matchedRows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);

  const renderDateColumn = (dateValue: Date) => (
    <FormattedDate
      value={new Date(dateValue)}
      month="short"
      day="numeric"
      year="numeric"
    />
  );

  useEffect(() => {
    const searchResultRows = dataTable.filter(
      it => (
        it.groupName.toLowerCase().includes(currentSearchText)
        || it.id.toString().includes(currentSearchText)
      ),
    );
    setMatchedRows(searchResultRows);
  }, [dataTable, currentSearchText]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentSearchText = e.target.value.toLowerCase().trim();
    setCurrentSearchText(currentSearchText);
    if (page !== 0) setPage(0);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} square elevation={0}>
        <Grid container className={classes.filterContainer}>
          <TextField
            onChange={handleSearchChange}
            size="small"
            variant="standard"
            placeholder={intl.formatMessage({ id: 'organization.group.search.placeholder' })}
            InputProps={{
              classes: { input: classes.inputSearch },
              disableUnderline: true,
              startAdornment: (
                <InputAdorment position="start">
                  <SearchIcon className={classes.defaultIcon} />
                </InputAdorment>
              ),
            }}
            className={classes.search}
            autoComplete="off"
          />
        </Grid>
        <TableContainer>
          <Table>
            <UserGroupHeaderTable />
            <TableBody>
              {matchedRows.length > 0 ? orderBy(currentPageRows, ['createdAt'], ['desc']).map(row => (
                <TableRow
                  tabIndex={-1}
                  key={row.id}
                  className={classes.disabledHoverRow}
                >
                  <TableCell
                    padding="none"
                    sx={{
                      borderBottom: 'solid 1px #DBDDE5 !important',
                      width: '10%',
                    }}
                  >
                    {row.id}
                  </TableCell>
                  <TableCell
                    padding="none"
                    sx={{
                      borderBottom: 'solid 1px #DBDDE5 !important',
                      width: '60%',
                    }}
                  >
                    <div>
                      <Link
                        id={`organization.group.row.group_name_link_${row.id}`}
                        color="primary"
                        component={RouterLink}
                        to={resolvePath(userGroupDetail, { id: row.id }, { orgId }, false)}
                        className={classes.groupName}
                      >
                        <Typography
                          className={classes.groupName}
                          sx={{ minWidth: '83%' }}
                          noWrap
                          textOverflow="ellipsis"
                        >
                          {row.groupName}
                        </Typography>
                      </Link>
                    </div>
                    <Typography
                      className={classes.groupDescription}
                      sx={{ minWidth: '83%' }}
                      noWrap
                      textOverflow="ellipsis"
                    >
                      {row.description}
                    </Typography>
                  </TableCell>
                  <TableCell
                    padding="none"
                    sx={{
                      borderBottom: 'solid 1px #DBDDE5 !important',
                      width: '10%',
                    }}
                  >
                    {row.totalUsers}
                  </TableCell>
                  <TableCell
                    padding="none"
                    sx={{
                      borderBottom: 'solid 1px #DBDDE5 !important',
                      width: '20%',
                    }}
                  >
                    {renderDateColumn(row.createdAt)}
                  </TableCell>

                  <TableCell
                    padding="none"
                    align="right"
                    sx={{
                      borderBottom: 'solid 1px #DBDDE5 !important',
                      width: '10%',
                    }}
                  >
                    {
                      (isRootOrAdmin
                        || OrganizationRole.isAdminOrOwnerRole(currentOrgUser?.role)
                        || AccountRole.isAdminOrOwnerRole(currentAccountUser?.role)) && (
                        <Tooltip title={intl.formatMessage({ id: 'common.delete' })}>
                          <IconButton
                            id={`organization.group.delete_button_${row.id}`}
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={() => {
                              setSelectedId(row.id);
                              setIsOpenDeleteDialog(true);
                            }}
                          >
                            <TrashAltIcon className={classes.deleteIcon} />
                          </IconButton>
                        </Tooltip>
                      )
                    }
                  </TableCell>

                </TableRow>
              )) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography className={classes.noResultFound} variant="subtitle2">
                      <FormattedMessage id="user_management.no_result_found" />
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {matchedRows.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={matchedRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            classes={{
              toolbar: classes.tablePaginationToolbar,
              selectLabel: classes.tablePaginationSelectLabel,
              select: classes.tablePaginationSelect,
              selectIcon: classes.tablePaginationSelectIcon,
              menuItem: classes.tablePaginationSelect,
            }}
          />
        )}
        {isOpenDeleteDialog && (
          <DeleteUserGroupPopup
            id={selectedId}
            isOpen={isOpenDeleteDialog}
            onCloseDialog={() => setIsOpenDeleteDialog(false)}
            onSuccessCallback={() => { }}
          />
        )}
      </Paper>
    </div>
  );
};

export default UserGroupTable;
