import { createAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import { AppState } from '.';
import { Organization, OrganizationRole, OrganizationUser, OrganizationUserView, User } from '../models';
import { Operator, Query } from '../models/query';
import { OrganizationUserService } from '../services';

export const ORGANIZATION_USERS_FEATURE_KEY = 'organizationUsers';

type OrganizationUserEntity = OrganizationUser;

const isOrganizationUserEntity = (x: OrganizationUserEntity | undefined):
  x is OrganizationUserEntity => !!x;

const organizationUsersAdapter = createEntityAdapter<OrganizationUserEntity>();

interface OrganizationUserState extends EntityState<OrganizationUserEntity> {
  loading: boolean;
  searchResult?: { // for every search box
    byUserId?: OrganizationUser['id'][];
    byOrgIdAndAccepted?: OrganizationUser['id'][];
    byOrgIdAndPending?: OrganizationUser['id'][];
  }
  error?: string;
  count: number;
}
const MAX_NUMBER_OF_RECORDS = 50;
export const createInitialState = (): OrganizationUserState => (
  organizationUsersAdapter.getInitialState({
    loading: false,
    count: 0,
  })
);

export const doGetAllOrgUsers = createAsyncThunk(
  'organizationUser/getAllOrgUsers',
  async (input: Pick<OrganizationUser, 'organizationId'>) => {
    const criteria: Array<Query<OrganizationUser>> = [
      { field: 'organizationId', operator: Operator.EQ, value: input.organizationId },
      { limit: MAX_NUMBER_OF_RECORDS },
    ];

    const firstResponse = await OrganizationUserService.getOrganizationUsers(...criteria);
    const numberOfRequests = Math.ceil(firstResponse.total / MAX_NUMBER_OF_RECORDS);
    const remainingRequests = [];
    for (let i = 1; i < numberOfRequests; i += 1) {
      const req = OrganizationUserService.getOrganizationUsers(
        ...criteria,
        { offset: MAX_NUMBER_OF_RECORDS * i },
      );
      remainingRequests.push(req);
    }
    const remainingResponses = await Promise.all(remainingRequests);
    const orgUsers = [...firstResponse.data];
    remainingResponses.forEach(r => {
      orgUsers.push(...r.data);
    });
    return { orgUsers };
  },
);

export const doGetOrgUserByUserIdAndAccountId = createAsyncThunk(
  'organizationUser/getOrgByUserIdAndOrgId',
  async (input: { userId: number, accountId: number }): Promise<OrganizationUser[]> => {
    const orgUsers = await OrganizationUserService.getOrganizationUsers(
      { field: 'userId', operator: Operator.EQ, value: input.userId },
      { field: 'organization.accountId', operator: Operator.EQ, value: input.accountId },
    );
    return orgUsers.data;
  },
);

export const doCountTotalOrgUsers = createAsyncThunk(
  'organizationUser/countTotalOrgUsers',
  async (input: Pick<OrganizationUser, 'organizationId'>) => {
    const criteria: Array<Query<OrganizationUser>> = [
      { field: 'organizationId', operator: Operator.EQ, value: input.organizationId },
      { limit: 0 },
    ];

    const response = await OrganizationUserService.getOrganizationUsers(...criteria);
    return { count: response.total };
  },
);

export const doGetOrgUsersByUserIdWithSearch = createAsyncThunk(
  'organizationUser/getByUserIdWithSearch',
  async (input: { id: number, textSearch: string }) => {
    const orgUsers = await OrganizationUserService.getOrganizationUsers(
      { field: 'userId', operator: Operator.EQ, value: input.id },
      { field: 'organization', operator: Operator.SEARCH, value: input.textSearch },
    );
    return { orgUsers: orgUsers.data, ids: orgUsers.data.map(orgUser => orgUser.id) };
  },
);

export const doGetAcceptedOrgUsersByOrgIdWithSearch = createAsyncThunk(
  'organizationUser/getAcceptedByOrgIdWithSearch',
  async (input: { id: number, textSearch: string }) => {
    const orgUsers = await OrganizationUserService.getOrganizationUsers(
      { field: 'organizationId', operator: Operator.EQ, value: input.id },
      { field: 'user', operator: Operator.SEARCH, value: input.textSearch },
      { field: 'accepted', operator: Operator.EQ, value: true },
    );
    return { orgUsers: orgUsers.data, ids: orgUsers.data.map(orgUser => orgUser.id) };
  },
);

export const doGetPendingOrgUsersByOrgIdWithSearch = createAsyncThunk(
  'organizationUser/getPendingByOrgIdWithSearch',
  async (input: { id: number, textSearch: string }) => {
    const orgUsers = await OrganizationUserService.getOrganizationUsers(
      { field: 'organizationId', operator: Operator.EQ, value: input.id },
      { field: 'user', operator: Operator.SEARCH, value: input.textSearch },
      { field: 'accepted', operator: Operator.EQ, value: false },
    );
    return { orgUsers: orgUsers.data, ids: orgUsers.data.map(orgUser => orgUser.id) };
  },
);

export const doRemoveOrganizationUser = createAsyncThunk(
  'organizationUser/removeOrganizationUser',
  async (organizationUserId: OrganizationUser['id']) => {
    await OrganizationUserService.deleteOrganizationUser(organizationUserId);
    return { organizationUserId };
  },
);

export const doCreateOrganizationUser = createAsyncThunk(
  'organizationUser/createOrganizationUser',
  async (input: Parameters<typeof OrganizationUserService.createOrganizationUser>[0]) => {
    const res = await OrganizationUserService.createOrganizationUser(input);
    return { organizationUser: res };
  },
);

export const doUpdateOrganizationUser = createAsyncThunk(
  'organizationUser/updateOrganizationUser',
  async (input: Parameters<typeof OrganizationUserService['updateOrganizationUser']>[0]) => {
    const user = await OrganizationUserService.updateOrganizationUser(input);
    return { user };
  },
);

export const doRemoveBulkOrganizationUsers = createAction<OrganizationUser['id'][]>(
  'organizationUser/removeBulkOrganizationUsers',
);

export const doGetOrgUserByAccountId = createAsyncThunk(
  'organizationUser/getOrgUserByAccountId',
  async (input: { accountId: number }): Promise<OrganizationUser[]> => {
    const orgUsers = await OrganizationUserService.getOrganizationUsers(
      { field: 'organization.accountId', operator: Operator.EQ, value: input.accountId },
    );
    return orgUsers.data;
  },
);

const organizationUsersSlice = createSlice({
  name: ORGANIZATION_USERS_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    // Search Organizations of a User
    builder.addCase(doGetOrgUsersByUserIdWithSearch.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetOrgUsersByUserIdWithSearch.fulfilled, (state, action) => {
      state.loading = false;
      organizationUsersAdapter.upsertMany(state, action.payload.orgUsers);
      state.searchResult = { ...state.searchResult, byUserId: action.payload.ids };
    });
    builder.addCase(doGetOrgUsersByUserIdWithSearch.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(doGetPendingOrgUsersByOrgIdWithSearch.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetPendingOrgUsersByOrgIdWithSearch.fulfilled, (state, action) => {
      state.loading = false;
      organizationUsersAdapter.upsertMany(state, action.payload.orgUsers);
      state.searchResult = { ...state.searchResult, byOrgIdAndPending: action.payload.ids };
    });
    builder.addCase(doGetPendingOrgUsersByOrgIdWithSearch.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(doGetAcceptedOrgUsersByOrgIdWithSearch.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetAcceptedOrgUsersByOrgIdWithSearch.fulfilled, (state, action) => {
      state.loading = false;
      organizationUsersAdapter.upsertMany(state, action.payload.orgUsers);
      state.searchResult = { ...state.searchResult, byOrgIdAndAccepted: action.payload.ids };
    });
    builder.addCase(doGetAcceptedOrgUsersByOrgIdWithSearch.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(doCreateOrganizationUser.pending, state => {
      state.loading = true;
    });
    builder.addCase(doCreateOrganizationUser.fulfilled, (state, action) => {
      organizationUsersAdapter.addOne(state, action.payload.organizationUser);
      state.loading = false;
    });
    builder.addCase(doCreateOrganizationUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(doRemoveOrganizationUser.pending, state => {
      state.loading = true;
    });
    builder.addCase(doRemoveOrganizationUser.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(doRemoveOrganizationUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(doRemoveBulkOrganizationUsers, (state, action) => {
      organizationUsersAdapter.removeMany(state, action.payload);
    });
    builder.addCase(doUpdateOrganizationUser.pending, state => {
      state.loading = true;
    });
    builder.addCase(doUpdateOrganizationUser.fulfilled, (state, action) => {
      organizationUsersAdapter.updateOne(state, {
        id: action.payload.user.id,
        changes: action.payload.user,
      });
      state.loading = false;
    });
    builder.addCase(doUpdateOrganizationUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(doGetAllOrgUsers.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetAllOrgUsers.fulfilled, (state, action) => {
      state.loading = false;
      organizationUsersAdapter.upsertMany(state, action.payload.orgUsers);
    });
    builder.addCase(doGetAllOrgUsers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(doGetOrgUserByUserIdAndAccountId.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetOrgUserByUserIdAndAccountId.fulfilled, (state, action) => {
      state.loading = false;
      organizationUsersAdapter.upsertMany(state, action.payload);
    });
    builder.addCase(doGetOrgUserByUserIdAndAccountId.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(doCountTotalOrgUsers.pending, state => {
      state.loading = true;
    });
    builder.addCase(doCountTotalOrgUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.count = action.payload.count;
    });
    builder.addCase(doCountTotalOrgUsers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(doGetOrgUserByAccountId.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetOrgUserByAccountId.fulfilled, (state, action) => {
      state.loading = false;
      organizationUsersAdapter.upsertMany(state, action.payload);
    });
    builder.addCase(doGetOrgUserByAccountId.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

const selectOrganizationUsersFeature = (state: AppState) => state[ORGANIZATION_USERS_FEATURE_KEY];
export const {
  selectAll: selectAllOrganizationUsers,
  selectIds,
  selectEntities,
} = organizationUsersAdapter.getSelectors(selectOrganizationUsersFeature);

// becauseof missmatch user's id with TestOps, we need to select by user's email instead
export const selectByUserEmailAndOrganizationId = (email: User['email'], orgId: Organization['id']) => createSelector(
  selectAllOrganizationUsers,
  orgUsers => orgUsers.find(
    orgUser => orgUser.user.email === email && orgUser.organizationId === orgId,
  ),
);

export const selectByOrganizationId = (organizationId: Organization['id']) => createSelector(
  selectAllOrganizationUsers,
  orgUsers => orgUsers.filter(orgUser => orgUser.organizationId === organizationId),
);

export const selectOwnerByOrganizationId = (orgId: Organization['id']) => createSelector(
  selectAllOrganizationUsers,
  orgUsers => orgUsers.find(
    orgUser => orgUser.role === OrganizationRole.OWNER && orgUser.organizationId === orgId,
  ),
);

export const selectById = (id: OrganizationUser['id']) => createSelector(
  selectEntities,
  entities => entities[id],
);

export const selectOrgUsersSearchResult = createSelector(
  selectOrganizationUsersFeature,
  organizationsState => organizationsState.searchResult,
);

export const selectOrgUsersByUserIdWithSearch = createSelector(
  selectOrgUsersSearchResult,
  selectEntities,
  (result, allEntities) => result?.byUserId?.map(id => allEntities[id])
    ?.filter(isOrganizationUserEntity) ?? [],
);

export const selectPendingOrgUsersByOrgIdWithSearch = createSelector(
  selectOrgUsersSearchResult,
  selectEntities,
  (result, allEntities) => result?.byOrgIdAndPending?.map(id => allEntities[id])
    ?.filter(isOrganizationUserEntity) ?? [],
);

export const selectAcceptedOrgUsersByOrgIdWithSearch = createSelector(
  selectOrgUsersSearchResult,
  selectEntities,
  (result, allEntities) => result?.byOrgIdAndAccepted?.map(id => allEntities[id])
    ?.filter(isOrganizationUserEntity) ?? [],
);

export const selectTotalOrgUsersCount = createSelector(
  selectOrganizationUsersFeature,
  organizationUserState => organizationUserState.count,
);

export const selectCountOrgMembers = (orgId: Organization['id']) => createSelector(
  selectAllOrganizationUsers,
  orgUsers => orgUsers.filter(
    orgUser => orgUser.organizationId === orgId,
  ).length,
);

export const selectLoading = createSelector(
  selectOrganizationUsersFeature,
  organizationUserState => organizationUserState.loading,
);

export const selectError = createSelector(
  selectOrganizationUsersFeature,
  organizationUserState => organizationUserState.error,
);

export const selectByAccountId = (accountId: OrganizationUserView['organization.accountId']) => createSelector(
  selectAllOrganizationUsers,
  orgUsers => orgUsers.filter(orgUser => orgUser.organization.accountId === accountId),
);

export default organizationUsersSlice.reducer;
