import Button from '@mui/material/Button';
import { unwrapResult } from '@reduxjs/toolkit';
import dateFormat from 'dateformat';
import prettyMilliseconds from 'pretty-ms';
import { useState, useEffect, useRef } from 'react';
import { CSVLink } from 'react-csv';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { TestResultCount, UsagePlan, SubscriptionSource } from '../../../../models';
import { fromOrganizations, fromTestResultCount, useAppDispatch } from '../../../../store';
import { UNLIMITED_TEST_EXECUTION } from '../../../account/utils';
import { ReactComponent as ExportIcon } from '../../../icons/export-icon.svg';

interface ExportTestResultCountButtonProps {
  organizationId: number,
  onLoading?: () => void,
  onComplete?: () => void,
}

/**
 * References
 * https://www.cluemediator.com/loading-data-asynchronously-and-download-csv-using-react-csv
 * see also: https://github.com/react-csv/react-csv/issues/282
 * Note that CSVDownload does not support custom file name.
 */

const ExportTestResultCountButton = (props: ExportTestResultCountButtonProps) => {
  const { organizationId, onLoading, onComplete } = props;
  const intl = useIntl();
  const getQuota = (testResultCount: TestResultCount) => {
    if (testResultCount.subscriptionSource === SubscriptionSource.TRIAL) {
      if (testResultCount.usagePlan !== UsagePlan.PLATFORM
        || testResultCount.quota >= UNLIMITED_TEST_EXECUTION) {
        return intl.formatMessage({ id: 'common.unlimted' });
      }
    }
    return testResultCount.quota.toString();
  };
  const composeCsvData = (trc: TestResultCount[]): string[][] => {
    const hasSignature = !!trc[0].signature;
    const result = [
      [
        intl.formatMessage({ id: 'dashboard.export.created_at' }),
        intl.formatMessage({ id: 'dashboard.export.usage_plan' }),
        intl.formatMessage({ id: 'dashboard.export.subscription_created' }),
        intl.formatMessage({ id: 'dashboard.export.subscription_expiry' }),
        intl.formatMessage({ id: 'dashboard.export.quota' }),
        intl.formatMessage({ id: 'dashboard.export.used' }),
        intl.formatMessage({ id: 'dashboard.export.usage_period_start' }),
        intl.formatMessage({ id: 'dashboard.export.duration' }),
        hasSignature ? intl.formatMessage({ id: 'dashboard.export.signature' }) : '',
      ],
    ].concat(
      trc.map(snapshot => [
        dateFormat(new Date(snapshot.createdAt), 'dd/mm/yyyy HH:MM:ss'),
        `${snapshot.subscriptionSource === SubscriptionSource.TRIAL ? 'Trial ' : ''}${UsagePlan.getPlanFullName(snapshot.usagePlan)}`,
        snapshot.subscriptionId ? dateFormat(new Date(snapshot.subscriptionCreatedAt), 'dd/mm/yyyy') : '',
        snapshot.subscriptionId ? dateFormat(new Date(snapshot.subscriptionExpiryDate), 'dd/mm/yyyy') : '',
        getQuota(snapshot),
        snapshot.count.toString(),
        dateFormat(snapshot.usagePeriodStartAt, 'dd/mm/yyyy'),
        prettyMilliseconds(snapshot.duration, { unitCount: 2, secondsDecimalDigits: 0 }),
        hasSignature ? snapshot.signature : '',
      ]),
    );
    return result;
  };
  const allTestResultCounts = useSelector(fromTestResultCount.selectAllTestResultCounts);
  const linkRef = useRef<any>(null);
  const organization = useSelector(fromOrganizations.selectOrganizationById(organizationId));
  const dispatch = useAppDispatch();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const handleClick = () => {
    if (onLoading) onLoading();
    const ed = new Date();
    setEndDate(ed);
    const tmp = new Date(ed); // temporary
    tmp.setDate(ed.getDate() - 30);
    setStartDate(new Date(tmp));
  };

  useEffect(() => {
    if (startDate && endDate) {
      (async () => {
        await dispatch(fromTestResultCount.doGetAllTestResultCounts({
          organizationId,
          startDate,
          endDate,
        })).then(unwrapResult);
        if (onComplete) onComplete();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    if (allTestResultCounts) {
      setTimeout(() => {
        // CSVLink may not be rendered, use ?. so that the app won't crash (NPE)
        linkRef.current?.link?.click();
      });
    }
  }, [allTestResultCounts]);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        startIcon={<ExportIcon />}
      >
        {intl.formatMessage({ id: 'dashboard.export' })}
      </Button>
      {allTestResultCounts && startDate && endDate && (
        <CSVLink
          data={composeCsvData(allTestResultCounts!!)}
          filename={`Usage Report_${organization?.name}_${organizationId}_${dateFormat(new Date(startDate), 'yyyymmdd')}-${dateFormat(new Date(endDate || new Date()), 'yyyymmdd')}.csv`}
          ref={linkRef}
        />
      )}
    </>
  );
};

ExportTestResultCountButton.defaultProps = {
  onLoading: () => { },
  onComplete: () => { },
};

export default ExportTestResultCountButton;
