import { alpha, Theme } from '@mui/material/styles';

interface PositionAdjust {
  adjustRightWhenCursorInRightHalf: number;
  adjustLeftWhenCursorInLeftHalf: number;
  adjustTopWhenCursorInTopHalf: number;
  adjustTopWhenCursorInBottomHalf: number;
}

// Custom chart's tooltip function
export const chartTooltip = (
  tooltipHTML: (tooltipModel: any) => string,
  positionAdjust: PositionAdjust,
  theme: Theme,
) => (context: any) => {
  let tooltipEl = document.getElementById('chartjs-tooltip');
  const position = context.chart.canvas.getBoundingClientRect();
  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    document.body.appendChild(tooltipEl);
  }
  // Hide if no tooltip or canvas is not initilized yet
  const tooltipModel = context.tooltip;
  if (tooltipModel.opacity === 0
    || (position.width === 0 && position.height === 0)
  ) {
    tooltipEl.animate([
      {},
      { opacity: '0', transform: 'scale(0)' },
    ], { fill: 'forwards', duration: 70 });
    return;
  }

  // Set Text
  if (tooltipModel.body) {
    tooltipEl.innerHTML = tooltipHTML(tooltipModel);
  }
  // Display, position, and set styles for font
  tooltipEl.style.position = 'absolute';
  const cursorX = position.left + window.pageXOffset + tooltipModel.caretX;
  const rightEdge = window.innerWidth + window.pageXOffset;
  const {
    adjustLeftWhenCursorInLeftHalf,
    adjustRightWhenCursorInRightHalf,
    adjustTopWhenCursorInBottomHalf,
    adjustTopWhenCursorInTopHalf,
  } = positionAdjust;
  if (tooltipModel.caretX > context.chart.width / 2) {
    tooltipEl.style.right = `${rightEdge - cursorX + adjustRightWhenCursorInRightHalf}px`; // +20
    tooltipEl.style.left = '';
  } else {
    tooltipEl.style.left = `${position.left + window.pageXOffset + tooltipModel.caretX + adjustLeftWhenCursorInLeftHalf}px`; // +20
    tooltipEl.style.right = '';
  }
  if (tooltipModel.caretY > context.chart.height / 2) {
    tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY + adjustTopWhenCursorInBottomHalf}px`; // -100
  } else {
    tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY + adjustTopWhenCursorInTopHalf}px`; // -30
  }
  tooltipEl.animate([
    {},
    { opacity: '1', transform: 'scale(1)' },
  ], { fill: 'forwards', duration: 70 });
  tooltipEl.style.padding = `${theme.spacing(1)} ${theme.spacing(0.5)}`;
  tooltipEl.style.borderRadius = theme.spacing(0.5).toString();
  tooltipEl.style.pointerEvents = 'none';
  tooltipEl.style.backgroundColor = `${(theme.components?.MuiTooltip?.styleOverrides?.tooltip as any)?.backgroundColor ?? alpha(theme.palette.grey[700], 0.92)}`;
};
