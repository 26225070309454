import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, Outlet, useLocation } from 'react-router-dom';

import { AppRoutes, forbidden, useNavigate, useQuery } from '../routes';
import { RouteType } from '../routes/routeType';
import {
  fromAccounts,
  fromAuth,
  fromCurrentOrgUser,
  fromLayout,
  fromOrganizations,
  fromSubscriptions,
  fromTestOpsProject,
  useAppDispatch,
} from '../store';
// Temporary disable due to some account features are not implemented
// import AccountSidebar from './AccountSideBar';
import FreeKsViolationWarningBanner from '../components/free-ks-violation-warning';
import { useConfig } from '../config';
import { useLaunchDarkly } from '../launchdarkly';
import HeaderOrgRemodeling from './HeaderOrgRemodeling';
import LoadingProgress from './LoadingProgress';
import routes, {
  acceptInvitation,
  acceptSsoInvitation,
  accountHome,
  accountWelcome,
  home,
  invalidInvitation,
  oAuthServiceCloud,
} from './routes';
import SidebarOrgRemodeling from './SidebarOrgRemodeling';
import SkeletonSidebar from './SkeletonSidebar';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    width: '100vw',
  },
  headerSection: {
    zIndex: 10,
  },
  content: {
    flexGrow: 1,
    flexDirection: 'column',
    display: 'flex',
    overflow: 'auto',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  bannerContainer: {
    flexGrow: 1,
    flexDirection: 'column',
    display: 'flex',
    overflowY: 'hidden',
  },
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(7.5),
  },
  warningBanner: {
    paddingTop: theme.spacing(7.5),
  },
  drawer: {
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
    },
  },
  headerSectionContainer: {
    zIndex: 1201,
    position: 'fixed',
    backgroundColor: '#1847ca',
    width: '100%',
    height: theme.spacing(7.5),
  },
  contentHidden: {
    visibility: 'hidden',
  },
  contentShow: {
    visibility: 'visible',
  },
}));

const hideSidebarPaths = [
  acceptInvitation.path,
  invalidInvitation.path,
  home.path,
  accountHome.path,
];

const Layout = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { replace, replaceQuery } = useNavigate();
  const { get } = useQuery();
  const { initialized, changeAccount } = useLaunchDarkly();
  const { config } = useConfig();
  const currentPath = useLocation().pathname;
  const isAccountPage = routes.filter(
    route => (matchPath(route.path, currentPath)?.pathname && route.type === RouteType.ACCOUNT),
  ).length > 0;
  const isOrganizationPage = routes.filter(
    route => (matchPath(route.path, currentPath)?.pathname
      && route.type === RouteType.ORGANIZATION),
  ).length > 0;
  const isAccountWelcome = matchPath(accountWelcome.path, currentPath)?.pathname !== undefined;
  const isAcceptInvitation = matchPath(acceptInvitation.path, currentPath)?.pathname !== undefined;
  const isAcceptSsoInvitation = matchPath(acceptSsoInvitation.path, currentPath)
    ?.pathname !== undefined;
  const isInvalidInvitation = matchPath(invalidInvitation.path, currentPath)
    ?.pathname !== undefined;
  const isAuthServiceCloud = matchPath(oAuthServiceCloud.path, currentPath)?.pathname !== undefined;
  const hideSidebar = hideSidebarPaths
    .find(path => matchPath(path, currentPath)?.pathname) !== undefined;
  const selectedOrgId = get('orgId');
  const selectedAccountId = get('accountId');
  const user = useSelector(fromAuth.selectUser);
  const organization = useSelector(fromOrganizations.selectOrganizationById(Number(selectedOrgId)));
  const account = useSelector(fromAccounts.selectAccountById(Number(selectedAccountId)));
  const isRootOrAdmin = useSelector(fromAuth.selectIsRootOrAdmin);
  const isAuthenticated = useSelector(fromAuth.selectIsAuthenticated);
  const isFetchCurrentOrgLoading = useSelector(fromOrganizations.selectLoadingGetOrganizationById);
  const isMountedHeader = useSelector(fromLayout.selectIsMountedMFE('katalon-navigation-ui-header'));
  const isMountedSidebar = useSelector(fromLayout.selectIsMountedMFE('katalon-navigation-ui-sidebar'));
  const shouldShowSidebar = ((isOrganizationPage && selectedOrgId
      && (organization || (isRootOrAdmin && !config?.onpremise)))
    || (isAccountPage && selectedAccountId && (account || (isRootOrAdmin && !config?.onpremise)))
  ) && !hideSidebar;
  const shouldShowHeader = !isAccountWelcome;
  const isLoading = (isMountedHeader === false || isMountedSidebar === false)
    && !isAuthServiceCloud
    && !isAcceptSsoInvitation
    && !isInvalidInvitation
    && !isAcceptInvitation; // skip loading for some pages
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    const fetchOrganizations: Promise<any>[] = [
      dispatch(fromOrganizations.doGetOrganizationsByUserId({ id: user!.id })),
      dispatch(fromOrganizations.doFetchOrganizationById({ id: Number(selectedOrgId) })),
      dispatch(fromTestOpsProject.doCountProjectsByUserEmail(user!)),
      dispatch(fromTestOpsProject.doGetProjectsByUserEmailAndOrgId({
        userEmail: user!.email,
        organizationId: Number(selectedOrgId) || 0,
      })),
      dispatch(fromAccounts.doGetAccountById({ id: Number(selectedAccountId) })),
    ];

    if ((isOrganizationPage || isAccountPage)
      && isAuthenticated && user && !Number.isNaN(Number(selectedOrgId))) {
      Promise.all([
        ...fetchOrganizations,
        dispatch(
          fromCurrentOrgUser.doGetOrgUserByUserIdAndOrgId({
            id: user.id,
            organizationId: Number(selectedOrgId),
          }),
        ),
        dispatch(fromOrganizations.doGetOrgMigrationStatus({ id: Number(selectedOrgId) })),
      ]).then(() => {
        setFetched(true);
      });
    }
  }, [dispatch, isAccountPage, isAuthenticated, isOrganizationPage, selectedOrgId, user]);

  const handleChangeOrg = ({ detail }: CustomEventInit) => {
    const { orgId } = detail;
    if (!orgId) return;
    dispatch(fromOrganizations.doChangeSelectedId(orgId));
    dispatch(fromOrganizations.doFetchOrganizationById({ id: orgId }));
    dispatch(fromSubscriptions.doGetActiveSubscriptionsByOrgId(
      { organizationId: +orgId },
    ));
  };

  const handleChangeAccount = ({ detail }: CustomEventInit) => {
    const { accountId } = detail;
    if (!accountId || !changeAccount) return;
    changeAccount(+accountId);
  };

  useEffect(() => {
    window.addEventListener('navigation-ui:changeOrg', handleChangeOrg);
    window.addEventListener('navigation-ui:changeAccount', handleChangeAccount);
    return () => {
      window.removeEventListener('navigation-ui:changeOrg', handleChangeOrg);
      window.removeEventListener('navigation-ui:changeAccount', handleChangeAccount);
    };
  }, []);

  // has no access to org, throw to /forbidden, except in accept an invitation page
  if (selectedOrgId && isOrganizationPage && isFetchCurrentOrgLoading === false && !organization) {
    replace(forbidden.path, replaceQuery({}));
    return null;
  }

  if (!initialized
    || ((isAccountPage || isOrganizationPage) && !fetched)
    || !config) {
    return null;
  }

  return (
    <Box className={classes.root}>
      <Grid item className={classes.headerSection}>
        {shouldShowHeader && (
          <>
            <div id="katalon-navigation-ui-header" className={classes.headerSectionContainer} />
            <HeaderOrgRemodeling />
          </>
        )}
      </Grid>
      <Box className={classes.container}>
        {/* Sidebar */}
        <div>
          {shouldShowSidebar && (
            <Box className={classes.drawer}>
              <>
                { !isMountedSidebar && <SkeletonSidebar /> }
                <div id="katalon-navigation-ui-sidebar">
                  <SkeletonSidebar />
                </div>
                <SidebarOrgRemodeling />
              </>
            </Box>
          )}
        </div>
        {/* Content */}
        <Box className={classes.bannerContainer}>
          <FreeKsViolationWarningBanner />
          <Box className={classes.content}>
            <div className={clsx({
              [classes.contentHidden]: isLoading,
              [classes.contentShow]: !isLoading,
            })}
            >
              <AppRoutes routes={routes} />
            </div>
          </Box>
        </Box>
      </Box>
      { isLoading && <LoadingProgress /> }
      <div className={clsx({
        [classes.contentHidden]: isLoading,
        [classes.contentShow]: !isLoading,
      })}
      >
        <Outlet />
      </div>
    </Box>
  );
};

export default Layout;
