import Axios from 'axios';
import { PageableResponse } from '../models';
import { composeQuery, Query } from '../models/query';
import { TestResultCount } from '../models/testResultCount';

export const getTestResultCounts = async (...criteria: Query<TestResultCount>[]) => (await Axios.get<PageableResponse<TestResultCount>>('/v1/test-result-counts', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: composeQuery<TestResultCount>(...criteria),
  data: {},
})).data;
