import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { unwrapResult } from '@reduxjs/toolkit';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardNumberElementChangeEvent, StripeElementChangeEvent } from '@stripe/stripe-js';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import LoadingProgress from '../../../layout/LoadingProgress';
import { Exception } from '../../../models';
import { useQuery } from '../../../routes';
import { fromAuth, fromCurrentOrgUser, fromOrganizations, fromTestCloudSubscriptions, fromTestOpsPaymentMethod, useAppDispatch } from '../../../store';
import americanExpress from '../../icons/payment-cards/american-express.svg';
import dinersClub from '../../icons/payment-cards/diners-club.svg';
import discover from '../../icons/payment-cards/discover.svg';
import jcb from '../../icons/payment-cards/jcb.svg';
import masterCard from '../../icons/payment-cards/master-card.svg';
import unionPay from '../../icons/payment-cards/union-pay.svg';
import visa from '../../icons/payment-cards/visa.svg';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 0,
    width: '20rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.background.paper,
  },
  cardName: {
    width: '100%',
    fontSize: '18px',
    fontWeight: 300,
    fontFamily: 'Source Code Pro, monospace !important',
    letterSpacing: '2px',
    fontSmoothing: 'antialiased',

    '&:focus': {
      color: '#081D36',
    },
    '&::placeholder': {
      fontFamily: 'Source Code Pro, monospace',
      color: '#A1A9B3',
      letterSpacing: '5px',
      fontWeight: 100,
    },
    '&:focus::placeholder': {
      color: '#CFD7DF',
    },
  },
  paymentLogo: {
    height: '24px',
    marginRight: theme.spacing(1),
    borderRadius: '3px',
  },
  disablePaymentLogo: {
    filter: 'grayscale(100%)',
    opacity: '0.2',
  },
  cardInfoComponent: {
    margin: theme.spacing(2, 0),
  },
  cardInfoLabel: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(2),
  },
  savedCardLogo: {
    height: '45px',
    display: 'block',
    borderRadius: '4px',
    marginRight: theme.spacing(1.5),
  },
  savedCard: {
    marginBottom: theme.spacing(2),
    borderBottom: '1px solid #DCDFE6',
    paddingBottom: theme.spacing(1.5),
  },
  savedCardInfo: {
    marginRight: theme.spacing(1.5),
  },
}));

const CARD_NUMBER_OPTIONS = {
  style: {
    base: {
      fontSize: '18px',
      fontWeight: 300,
      fontFamily: 'Source Code Pro, monospace',
      letterSpacing: '2px',
      fontSmoothing: 'antialiased',

      ':focus': {
        color: '#081D36',
      },
      '::placeholder': {
        color: '#A1A9B3',
        letterSpacing: '5px',
        fontWeight: 100,
      },
      ':focus::placeholder': {
        color: '#CFD7DF',
      },
    },
    invalid: {
      color: '#ff8080',
      ':focus': {
        color: '#ff5050',
      },
      '::placeholder': {
        color: '#FFCCA5',
      },
    },
  },
  placeholder: '---- ---- ---- ----',
};
const CARD_CVC_OPTIONS = {
  style: {
    base: {
      fontSize: '18px',
      fontWeight: 300,
      fontFamily: 'Source Code Pro, monospace',
      letterSpacing: '2px',
      fontSmoothing: 'antialiased',

      ':focus': {
        color: '#081D36',
      },
      '::placeholder': {
        color: '#A1A9B3',
        letterSpacing: '5px',
        fontWeight: 100,
      },
      ':focus::placeholder': {
        color: '#CFD7DF',
      },
    },
    invalid: {
      color: '#ff8080',
      ':focus': {
        color: '#ff5050',
      },
      '::placeholder': {
        color: '#FFCCA5',
      },
    },
  },
  placeholder: 'CVC',
};
const CARD_EXP_OPTIONS = {
  style: {
    base: {
      fontSize: '18px',
      fontWeight: 300,
      fontFamily: 'Source Code Pro, monospace',
      letterSpacing: '2px',
      fontSmoothing: 'antialiased',

      ':focus': {
        color: '#081D36',
      },
      '::placeholder': {
        color: '#A1A9B3',
        letterSpacing: '5px',
        fontWeight: 100,
      },
      ':focus::placeholder': {
        color: '#CFD7DF',
      },
    },
    invalid: {
      color: '#ff8080',
      ':focus': {
        color: '#ff5050',
      },
      '::placeholder': {
        color: '#FFCCA5',
      },
    },
  },
  placeholder: 'MM / YY',
};

interface CardBrand {
  src: string;
  brand: string;
}
const CARD_BRAND_LIST: CardBrand[] = [
  {
    src: visa,
    brand: 'visa',
  },
  {
    src: jcb,
    brand: 'jcb',
  },
  {
    src: masterCard,
    brand: 'mastercard',
  },
  {
    src: dinersClub,
    brand: 'dinersclub',
  },
  {
    src: discover,
    brand: 'discover',
  },
  {
    src: americanExpress,
    brand: 'americanexpress',
  },
  {
    src: unionPay,
    brand: 'unionpay',
  },
];

const CardInformation = () => {
  const classes = useStyles();
  const intl = useIntl();
  const currentCard = useSelector(fromTestOpsPaymentMethod.selectPaymentMethod);
  const loading = useSelector(fromTestOpsPaymentMethod.selectLoading);
  const dispatch = useAppDispatch();
  const elements = useElements();
  const stripe = useStripe();
  const organization = useSelector(fromOrganizations.selectSelectedOrganization);
  const user = useSelector(fromAuth.selectUser);
  const orgTotalMember = useSelector(fromOrganizations.selectCount);
  const { get } = useQuery();
  const organizationId = get('orgId');
  const currentOrgUser = useSelector(fromCurrentOrgUser.selectByOrganizationIdAndEmail(
    user?.email || '',
    Number(organizationId),
  ));
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState('');
  const [isCardFormOpen, setIsCardFormOpen] = useState(true);
  const [currentCardBrand, setCurrentCardBrand] = useState('unknown');
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isCardNumberComplete, setIsCardNumberComplete] = useState(false);
  const [isExpirationComplete, setIsExpirationComplete] = useState(false);
  const [isCVCComplete, setIsCVCComplete] = useState(false);

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleCancel = () => {
    setIsCardFormOpen(false);
    setErrorMessage(undefined);
    setCurrentCardBrand('unknown');
    setIsCardNumberComplete(false);
    setIsExpirationComplete(false);
    setIsCVCComplete(false);
    setName('');
  };

  const reloadTestCloudSubscription = () => dispatch(fromTestCloudSubscriptions
    .doGetTestCloudSubscriptionsByOrganizationId({
      organizationId: Number(organizationId) || 0,
    }));

  useEffect(() => {
    if (currentCard) setIsCardFormOpen(false);
  }, [currentCard]);

  useEffect(() => {
    if (organizationId) {
      dispatch(fromTestOpsPaymentMethod.doGetPaymentMethod(+organizationId));
    }
  }, [organizationId, dispatch]);

  const submitPaymentMethod = async () => {
    if (!stripe || !elements) {
      return;
    }

    if (organizationId) {
      const cardNumberElement = elements.getElement(CardNumberElement);
      if (cardNumberElement) {
        const token = await stripe.createToken(cardNumberElement, {
          name,
        });
        if (token.token && currentCard) {
          await dispatch(fromTestOpsPaymentMethod.doUpdatePaymentMethod({
            id: currentCard?.id,
            stripeToken: token.token.id,
          })).then(unwrapResult)
            .then(() => {
              handleCancel();
              enqueueSnackbar(<FormattedMessage id="billinginfo.cardinfo.update.success" />, { variant: 'success' });
              analytics.track(
                'Payment Information Updated',
                {
                  user_id: user?.id,
                  firstName: user?.firstName,
                  lastName: user?.lastName,
                  email: user?.email,
                  system_role: user?.roles,
                  org_id: organization?.id,
                  org_name: organization?.name,
                  org_role: currentOrgUser?.role,
                  total_members: orgTotalMember,
                },
              );
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                user_id: user?.id,
                org_role: currentOrgUser?.role,
                system_role: user?.roles,
                org_id: organization?.id,
                org_name: organization?.name,
                total_members: orgTotalMember,
                event: 'gtm_payment_information_updated',
              });
            })
            .then(() => reloadTestCloudSubscription())
            .catch((ex: Exception) => enqueueSnackbar(<span>{ex.message}</span>, { variant: 'error' }));
        } else if (token.token && !currentCard) {
          await dispatch(fromTestOpsPaymentMethod.doCreatePaymentMethod({
            organizationId: +organizationId,
            stripeToken: token.token.id,
          })).then(unwrapResult)
            .then(() => {
              handleCancel();
              enqueueSnackbar(<FormattedMessage id="billinginfo.cardinfo.save.success" />, { variant: 'success' });
              analytics.track(
                'Payment Information Saved',
                {
                  user_id: user?.id,
                  firstName: user?.firstName,
                  lastName: user?.lastName,
                  email: user?.email,
                  system_role: user?.roles,
                  org_id: organization?.id,
                  org_name: organization?.name,
                  org_role: currentOrgUser?.role,
                  total_members: orgTotalMember,
                },
              );
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                user_id: user?.id,
                org_role: currentOrgUser?.role,
                system_role: user?.roles,
                org_id: organization?.id,
                org_name: organization?.name,
                total_members: orgTotalMember,
                event: 'gtm_payment_information_saved',
              });
            })
            .catch((ex: Exception) => enqueueSnackbar(<span>{ex.message}</span>, { variant: 'error' }));
        } else if (token.error) {
          enqueueSnackbar(<span>{token.error.message}</span>, { variant: 'error' });
          setErrorMessage('Invalid card information');
        }
      }
    }
  };
  const handleChangeCardNumber = (e: StripeCardNumberElementChangeEvent) => {
    setCurrentCardBrand(e.brand ?? 'unknown');
    setErrorMessage(e.error?.message);
    setIsCardNumberComplete(e.complete);
  };
  const handleCVCChange = (e: StripeElementChangeEvent) => {
    setErrorMessage(e.error?.message);
    setIsCVCComplete(e.complete);
  };
  const handleExpirationChange = (e: StripeElementChangeEvent) => {
    setErrorMessage(e.error?.message);
    setIsExpirationComplete(e.complete);
  };
  const renderPaymentLogoList = (cardBrand: CardBrand) => (
    <img
      key={cardBrand.brand}
      src={cardBrand.src}
      alt="payment-logo"
      className={clsx(currentCardBrand.toLowerCase() === cardBrand.brand
        ? classes.paymentLogo
        : [classes.paymentLogo, classes.disablePaymentLogo])}
    />
  );

  const getSavedPaymentCardLogo = () => {
    const cardBrand = CARD_BRAND_LIST.find(it => it.brand === currentCard?.brand?.toLowerCase());
    return cardBrand ? (
      <img
        src={cardBrand.src}
        className={classes.savedCardLogo}
        alt="current-logo"
      />
    ) : null;
  };

  return (
    <>
      {!!currentCard && (
        <Grid
          container
          alignItems="center"
          className={classes.savedCard}
        >
          {getSavedPaymentCardLogo()}
          <Grid item className={classes.savedCardInfo}>
            <Typography variant="h3" sx={{ textTransform: 'capitalize' }}>
              {`${currentCard.brand} **** ${currentCard.last4}`}
            </Typography>
            <Typography variant="subtitle2">
              {`Expires ${currentCard.expiration}`}
            </Typography>
          </Grid>
          <Button
            variant="outlined"
            onClick={() => setIsCardFormOpen(true)}
            disabled={isCardFormOpen}
          >
            Update
          </Button>
        </Grid>
      )}
      {isCardFormOpen && (
        <div>
          <div>
            {CARD_BRAND_LIST.map(it => renderPaymentLogoList(it))}
          </div>
          <div>
            <div className={classes.cardInfoComponent}>
              <Typography className={classes.cardInfoLabel} variant="subtitle2">Card Number</Typography>
              <CardNumberElement
                options={CARD_NUMBER_OPTIONS}
                onChange={handleChangeCardNumber}
              />
            </div>
            <div className={classes.cardInfoComponent}>
              <Typography className={classes.cardInfoLabel} variant="subtitle2">Expiration</Typography>
              <CardExpiryElement
                options={CARD_EXP_OPTIONS}
                onChange={handleExpirationChange}
              />
            </div>
            <div className={classes.cardInfoComponent}>
              <Typography className={classes.cardInfoLabel} variant="subtitle2">CVC</Typography>
              <CardCvcElement
                options={CARD_CVC_OPTIONS}
                onChange={handleCVCChange}
              />
            </div>
            <div className={classes.cardInfoComponent}>
              <Typography className={classes.cardInfoLabel} variant="subtitle2">Card Holder</Typography>
              <InputBase
                className={classes.cardName}
                placeholder="Name on card"
                onChange={handleChangeName}
              />
            </div>
          </div>
          {errorMessage && (
            <Typography variant="subtitle2" color="secondary" fontWeight={500} mb={2}>
              {errorMessage}
            </Typography>
          )}
          <Grid container alignItems="flex-end">
            <Button
              variant="contained"
              className={classes.button}
              color="primary"
              onClick={submitPaymentMethod}
              disabled={!!errorMessage || currentCardBrand === 'unknown' || !isCVCComplete || !isCardNumberComplete || !isExpirationComplete || !name?.length}
            >
              {intl.formatMessage({ id: 'billinginfo.button.save' })}
            </Button>
            {currentCard && (
              <Button variant="contained" color="inherit" className={classes.button} onClick={handleCancel}>
                {intl.formatMessage({ id: 'billinginfo.button.cancel' })}
              </Button>
            )}
          </Grid>
          {loading && <LoadingProgress />}
        </div>
      )}
    </>
  );
};

export default CardInformation;
