import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { ReactComponent as NoResultsFound } from '../icons/noResultsFound.svg';

const useStyles = makeStyles(theme => ({
  noResults: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4),
  },
  message: {
    textAlign: 'center',
  },
}));

interface NoResultProps {
  messages: string[];
}

const NoResult = (props: NoResultProps) => {
  const { messages } = props;
  const classes = useStyles();

  return (
    <Box className={classes.noResults}>
      <NoResultsFound />
      {messages.map(message => (
        <Typography variant="subtitle2" className={classes.message}>
          {message}
        </Typography>
      ))}
    </Box>
  );
};

export default NoResult;
