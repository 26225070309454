import EditIcon from '@mui/icons-material/Edit';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { projectList } from '../../layout/routes';
import { isAdminOrOwner } from '../../models/accountRole';
import { OrganizationRole } from '../../models/organizationRole';
import { useNavigate } from '../../routes/useNavigate';
import { fromAccountUsers, fromAuth, fromOrganizations, fromOrganizationUsers, fromTestOpsProject, useAppDispatch } from '../../store';
import UpdateOrgNameDialog from './UpdateOrgNameDialog';

const useStyles = makeStyles(theme => ({
  organizationBox: {
    display: 'flex',
    paddingTop: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5),
  },
  label: {
    fontSize: theme.spacing(1.75),
    fontWeight: theme.typography.fontWeightMedium,
    color: '#808b9a',
  },
  value: {
    marginRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    fontSize: theme.spacing(1.75),
    fontWeight: theme.typography.fontWeightMedium,
    color: '#233145',
  },
  avatar: {
    fontSize: theme.spacing(2.5),
    fontWeight: theme.typography.fontWeightMedium,
    color: '#233145',
    backgroundColor: 'rgba(97, 144, 239, 0.12)',
    width: theme.spacing(7.5),
    height: theme.spacing(7.5),
  },
  headerBox: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  devider: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5),
    width: '90%',
  },
  avatarWrapper: {
    display: 'flex',
    alignItems: 'end',
  },
  orgName: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(2),
    maxWidth: '60%',
    color: '#1847ca',
  },
  editIcon: {
    width: theme.spacing(1.75),
    height: theme.spacing(1.75),
  },
  orgNameBox: {
    minWidth: '90%',
    maxWidth: '90%',
  },
  card: {
    borderRadius: theme.spacing(0.75),
    padding: theme.spacing(1.5),
    '&:hover': {
      boxShadow: '0 8px 16px 0 rgba(24, 71, 402, 0.08)',
      cursor: 'pointer',
    },
  },
}));

interface OrgCardViewProps {
  orgId: number,
}

const OrgCardView = (props: OrgCardViewProps) => {
  const classes = useStyles();
  const { orgId } = props;
  const currentOrganization = useSelector(fromOrganizations.selectOrganizationById(orgId));
  const [organizationName, setOrganizationName] = useState('');
  const [organizationLogo, setOrganizationLogo] = useState('');
  const [isFetched, setIsFetched] = useState(false);
  const dispatch = useAppDispatch();

  const [dialogOpen, setDialogOpen] = useState(false);

  const currentUser = useSelector(fromAuth.selectUser);
  const orgUser = useSelector(fromOrganizationUsers.selectByUserEmailAndOrganizationId(currentUser?.email ?? '', orgId));
  const orgOwner = useSelector(fromOrganizationUsers.selectOwnerByOrganizationId(orgId));
  const orgMemberCount = useSelector(fromOrganizationUsers.selectCountOrgMembers(orgId));
  const orgProjectCount = useSelector(fromTestOpsProject.selectProjectCountByOrganizationId(orgId));

  const currentAccountUser = useSelector(
    fromAccountUsers.selectOneByUserIdAndAccountId(
      currentUser?.id ?? 0,
      orgUser?.organization.accountId ?? 0,
    ),
  );

  const { navigate, replaceQuery } = useNavigate();
  const handleClick = () => {
    if (!dialogOpen) {
      navigate(projectList.path, replaceQuery({
        orgId: orgId.toString(),
      }));
    }
  };

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    const fetchInformation = async () => {
      await Promise.all([
        dispatch(fromOrganizationUsers.doGetAllOrgUsers({ organizationId: orgId })),
        dispatch(fromTestOpsProject.doGetProjectsByOrgId({ organizationId: orgId })),
        dispatch(fromAccountUsers.doGetAccountUsersByUserId({ userId: currentUser?.id ?? 0 })),
      ]);
      setIsFetched(true);
    };
    fetchInformation().catch(() => {});
  }, [orgId]);

  useEffect(() => {
    if (!currentOrganization) {
      return;
    }
    setOrganizationName(currentOrganization?.name);
    setOrganizationLogo(currentOrganization.logo ?? '');
  }, [currentOrganization]);

  const stringAvatar = (name: string) => {
    if (name.split(' ').length > 1) {
      return ({
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
      });
    }
    return ({
      children: `${name.split(' ')[0][0]}`,
    });
  };

  const renderAvatar = () => {
    if (organizationLogo && (organizationLogo.startsWith('http')
      || organizationLogo.startsWith('https') || organizationLogo.startsWith('data:'))) {
      return (
        <Avatar
          id={`organization.card_view.organization_profile.avatar-${orgId}`}
          src={organizationLogo}
          className={classes.avatar}
        />
      );
    }
    if (organizationLogo) {
      return (
        <Avatar
          id={`organization.card_view.organization_profile.avatar-${orgId}`}
          src={`/${organizationLogo}`}
          className={classes.avatar}
        />
      );
    }
    return (
      <Avatar
        id={`organization.card_view.organization_profile.avatar-${orgId}`}
        className={classes.avatar}
        {...stringAvatar(currentOrganization?.name ?? '')}
      />
    );
  };

  return (
    <Card className={classes.card} onClick={handleClick}>
      <Box id={`organization.card_view.header-${orgId}`} className={classes.headerBox}>
        <Box className={classes.avatarWrapper}>
          {renderAvatar()}
        </Box>
        <Tooltip title={organizationName} arrow>
          <Box display="flex" justifyContent="center" alignItems="center" className={classes.orgNameBox}>
            <Typography id={`organization.card_view.name-${orgId}`} className={classes.orgName} noWrap textOverflow="ellipsis">
              {organizationName}
            </Typography>
            {currentAccountUser
              && isAdminOrOwner(currentAccountUser?.role)
              && OrganizationRole.isAdminOrOwnerRole(orgUser?.role)
              && (
                <IconButton aria-label="edit" onClick={handleOpen}>
                  <EditIcon className={classes.editIcon} />
                </IconButton>
              )}
          </Box>
        </Tooltip>
        <Divider variant="middle" className={classes.devider} />
      </Box>
      <UpdateOrgNameDialog
        orgId={orgId}
        defaultValue={organizationName}
        open={dialogOpen}
        onClose={handleClose}
      />
      <Grid container spacing={2} className={classes.organizationBox}>
        <Grid item xs={3}>
          <Typography id={`organization.card_view.organization_profile.user.role-${orgId}`} className={classes.label}>
            <FormattedMessage id="organization.card_view.organization_profile.user.role" />
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography id={`organization.card_view.organization_profile.user.role.value-${orgId}`} className={classes.value}>
            {isFetched ? OrganizationRole.getRoleFullName(orgUser?.role) : ''}
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography id={`organization.card_view.organization_profile.project.count-${orgId}`} className={classes.label}>
            <FormattedMessage id="organization.card_view.organization_profile.project.count" />
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography id={`organization.card_view.organization_profile.project.count.value-${orgId}`} className={classes.value}>
            {isFetched ? (orgProjectCount ?? 0) : ''}
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography id={`organization.card_view.organization_profile.owner-${orgId}`} className={classes.label}>
            <FormattedMessage id="organization.card_view.organization_profile.owner" />
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Tooltip title={isFetched ? orgOwner?.user.email : ''} arrow>
            <Typography id={`organization.card_view.organization_profile.owner.value-${orgId}`} className={classes.value} noWrap textOverflow="ellipsis">
              {isFetched ? orgOwner?.user.email : ''}
            </Typography>
          </Tooltip>
        </Grid>

        <Grid item xs={3}>
          <Typography id={`organization.card_view.organization_profile.member.count-${orgId}`} className={classes.label}>
            <FormattedMessage id="organization.card_view.organization_profile.member.count" />
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography id={`organization.card_view.organization_profile.member.count.value-${orgId}`} className={classes.value}>
            {isFetched ? orgMemberCount : ''}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default OrgCardView;
