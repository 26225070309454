import Axios from 'axios';
import { AccountUser, PageableResponse } from '../models';
import { composeQuery, Query } from '../models/query';

export const getAccountUsers = async (...criteria: Query<AccountUser>[]) => (
  await Axios.get<PageableResponse<AccountUser>>('/v1/account-users', {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    params: composeQuery<AccountUser>(...criteria),
    data: {},
  })
).data;

export const createRecurlyAccount = async (accountId: number) => (
  await Axios.post(`/v1/existing-accounts/create-recurly-account/${accountId}`)
).data;
