import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import { AppState } from '.';
import { Account, AccountUser, exceptionOf, Organization, SerializedException, TestOpsProject, TestOpsTeam, User } from '../models';
import { Operator, Query } from '../models/query';
import {
  AccountService,
  AccountUserService,
  OrganizationService,
  TestOpsTeamService,
  TestOpsProjectService,
  TestOpsPlatformSubscriptionService,
} from '../services';
import {
  doGetAccountUsersByUserId,
  doGetByUserIdAndAccountId,
  selectAccountUsersByUserId,
} from './accountUsersSlice';
import { doCountProjectsByAccountId } from './testOpsProjectSlice';
import { AISetting } from '../models/aiSetting';
import { DEFAULT_EXECUTIONS } from '../pages/home/utils';

export const ACCOUNT_FEATURE_KEY = 'accounts';
interface AccountEntity extends Account {
  numberOrganizations?: number;
  numberAccountUsers?: number;
  numberProjects?: number;
}

interface AccountFreeKsExpiry {
  isPaid: boolean;
  expiryDate: number;
}

const accountsAdapter = createEntityAdapter<AccountEntity>();

interface AccountState extends EntityState<AccountEntity> {
  loading: boolean;
  error?: SerializedException;
  selectedAccountId: number | undefined;
  isPublicDomain?: boolean;
  accountFreeKsExpiry?: AccountFreeKsExpiry;
  freeTestResultQuota?: number;
  isOptInToSelfServe: boolean;
}

export const createInitialState = (): AccountState => (
  accountsAdapter.getInitialState({
    loading: false,
    isPublicDomain: true,
    selectedAccountId: undefined,
    freeTestResultQuota: DEFAULT_EXECUTIONS,
    isOptInToSelfServe: false,
  })
);

export const doCountOrganizationsOfAccount = createAsyncThunk(
  'account/countOrganizationsOfAccount',
  async (input: Pick<Account, 'id'>) => {
    const criteria: Array<Query<Organization>> = [
      { field: 'accountId', operator: Operator.EQ, value: input.id },
      // Temporary get one to support combine organization layout and account layout
      { limit: 1 }, // save bandwidth
    ];
    const response = await OrganizationService.getOrganizations(...criteria);
    const organization = response.data.length > 0 ? response.data[0] : undefined;
    return { id: input.id, numberOrganizations: response.total, organization };
  },
);

export const doCountByCurrentUser = createAsyncThunk(
  'account/countByUserId',
  async () => {
    const criteria: Array<Query<Account>> = [
      { field: 'archived', operator: Operator.EQ, value: false },
      { limit: 0 },
    ];
    const data = await AccountService.getAccounts(...criteria);
    return data;
  },
);

export const doCountMembersOfAccount = createAsyncThunk(
  'account/countMembersOfAccount',
  async (input: Pick<Account, 'id'>) => {
    const criteria: Array<Query<AccountUser>> = [
      { field: 'accountId', operator: Operator.EQ, value: input.id },
      { limit: 0 }, // save bandwidth
    ];
    const response = await AccountUserService.getAccountUsers(...criteria);
    return { id: input.id, numberAccountUsers: response.total };
  },
);

export const doCreateAccountAndOrganizationAndTeamAndProject = createAsyncThunk(
  'account/createAccountAndOrganizationAndTrialRequest',
  async (
    input: {
      accountName: string,
      organizationName: string,
      projectName: string,
      isTeamRemovalEnabled?: boolean,
    },
    { rejectWithValue },
  ) => {
    try {
      const account = await AccountService
        .createAccount({ name: input.accountName });
      const organization = await OrganizationService
        .createOrganization({ name: input.organizationName, accountId: account.id });
      let project: TestOpsProject;
      let team: TestOpsTeam | undefined;
      if (input.isTeamRemovalEnabled) {
        project = await TestOpsProjectService
          .createProject({ organizationId: organization.id, name: input.projectName });
        team = project.team;
      } else {
        const team = await TestOpsTeamService.createTeam({ organizationId: organization.id });
        project = await TestOpsProjectService
          .createProject({ teamId: team.id, name: input.projectName });
      }
      const trialRequest = await TestOpsPlatformSubscriptionService
        .subscribeUnlimitedTrial({ accountId: account.id });
      const criteria: Query<AccountUser>[] = [
        { field: 'accountId', operator: Operator.EQ, value: account.id },
        { limit: 1 },
      ];
      const accountUser = (await AccountUserService.getAccountUsers(...criteria)).data;
      return { account, organization, accountUser, team, project, trialRequest };
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doEditAccountName = createAsyncThunk(
  'account/editAccountName',
  async (
    input: { accountName: string, id: number },
    { rejectWithValue },
  ) => {
    try {
      const account = await AccountService.updateAccount({ id: input.id, name: input.accountName });
      return { account };
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doDeleteAccount = createAsyncThunk(
  'account/deleteAccount',
  async (
    input: { id: number },
    { rejectWithValue },
  ) => {
    try {
      const account = await AccountService.deleteAccount({ id: input.id });
      return { account };
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doGetAISetting = createAsyncThunk(
  'account-setting/getAISetting',
  async (
    input: {
      accountId: number,
    },
  ) => {
    const criteria: Array<Query<AISetting>> = [
      { field: 'accountId', operator: Operator.EQ, value: input.accountId },
      { limit: 1 },
    ];
    const data = await AccountService.getAISetting(...criteria);
    return data;
  },
);

export const doUpdateAISetting = createAsyncThunk(
  'account-setting/updateAISetting',
  async (
    input: {
      accountId: number,
      enableAi: boolean,
    },
    { rejectWithValue },
  ) => {
    try {
      const aiSetting = await AccountService.updateAISetting(
        {
          accountId: input.accountId,
          enableAi: input.enableAi,
        },
      );
      return { aiSetting };
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doGetFreeKsExpiryDate = createAsyncThunk(
  'account/getFreeKsExpiryDate',
  async (accountId: number) => {
    try {
      const data = await AccountService.getFreeKsExpiryDate({ id: accountId });
      return { isPaid: data.paid, expiryDate: data.expiryDate } as AccountFreeKsExpiry;
    } catch (e: any) {
      return { isPaid: false } as AccountFreeKsExpiry;
    }
  },
);

export const doGetAccountById = createAsyncThunk(
  'account/getAccountById',
  async (input: Pick<Account, 'id'>) => {
    const criteria: Array<Query<Account>> = [
      { field: 'id', operator: Operator.EQ, value: input.id },
      { field: 'archived', operator: Operator.EQ, value: false },
      { limit: 1 },
    ];
    const data = await AccountService.getAccounts(...criteria);
    return data;
  },
);

export const doCheckPublicDomain = createAsyncThunk(
  'account/checkPublicDomain',
  async (accountId: number, { rejectWithValue }) => {
    try {
      return await AccountService.checkPublicDomain({ id: accountId });
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doGetFreeTestResultQuota = createAsyncThunk(
  'account/getFreeTestResultQuota',
  async (accountId: number, { rejectWithValue }) => {
    try {
      return await AccountService.getFreeTestResultQuota({ id: accountId });
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doOptInSelfServe = createAsyncThunk(
  'account/optinEcom',
  async (accountId: number, { rejectWithValue }) => {
    try {
      return await AccountService.optInSelfServe({ id: accountId });
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doCheckOptInSelfServe = createAsyncThunk(
  'accountUser/isOptInSelfServe',
  async (input: number) => {
    const response = await AccountService.isOptInSelfServe(input);
    return response;
  },
);

const accountsSlice = createSlice({
  name: ACCOUNT_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doGetAccountUsersByUserId.fulfilled, (state, action) => {
      accountsAdapter.upsertMany(state, action.payload.map(it => it.account));
    });
    builder.addCase(doGetByUserIdAndAccountId.fulfilled, (state, action) => {
      accountsAdapter.upsertMany(
        state,
        action.payload.data.map(accountUser => accountUser.account),
      );
    });
    builder.addCase(doEditAccountName.fulfilled, (state, action) => {
      accountsAdapter.updateOne(state, {
        id: action.payload.account.id,
        changes: {
          name: action.payload.account.name,
        },
      });
    });
    builder.addCase(doEditAccountName.pending, state => {
      state.loading = true;
    });
    builder.addCase(doEditAccountName.rejected, state => {
      state.loading = true;
    });
    builder.addCase(doCountOrganizationsOfAccount.fulfilled, (state, action) => {
      accountsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: {
          numberOrganizations: action.payload.numberOrganizations,
        },
      });
    });
    builder.addCase(doCountMembersOfAccount.fulfilled, (state, action) => {
      accountsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: {
          numberAccountUsers: action.payload.numberAccountUsers,
        },
      });
    });
    builder.addCase(doCreateAccountAndOrganizationAndTeamAndProject.pending, state => {
      state.loading = true;
    });
    builder.addCase(doCreateAccountAndOrganizationAndTeamAndProject.fulfilled, (state, action) => {
      state.loading = false;
      accountsAdapter.addOne(state, action.payload.account);
    });
    builder.addCase(doCreateAccountAndOrganizationAndTeamAndProject.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as SerializedException;
    });
    builder.addCase(doCountProjectsByAccountId.fulfilled, (state, action) => {
      state.loading = false;
      accountsAdapter.updateOne(state, {
        id: action.payload.accountId,
        changes: {
          numberProjects: action.payload.numberProjects,
        },
      });
    });
    builder.addCase(doDeleteAccount.fulfilled, (state, action) => {
      accountsAdapter.removeOne(state, action.payload.account.id);
    });
    builder.addCase(doDeleteAccount.pending, state => {
      state.loading = true;
    });
    builder.addCase(doDeleteAccount.rejected, (state, action) => {
      state.loading = true;
      state.error = action.payload as SerializedException;
    });
    builder.addCase(doGetFreeKsExpiryDate.fulfilled, (state, action) => {
      state.loading = false;
      state.accountFreeKsExpiry = action.payload;
    });
    builder.addCase(doGetFreeKsExpiryDate.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetFreeKsExpiryDate.rejected, state => {
      state.loading = false;
    });
    builder.addCase(doCheckPublicDomain.pending, state => {
      state.loading = true;
    });
    builder.addCase(doCheckPublicDomain.fulfilled, (state, action) => {
      state.loading = false;
      state.isPublicDomain = action.payload;
    });
    builder.addCase(doCheckPublicDomain.rejected, state => {
      state.loading = false;
    });
    builder.addCase(doGetAccountById.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetAccountById.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.data.length > 0) {
        accountsAdapter.upsertOne(state, action.payload.data[0]);
      }
    });
    builder.addCase(doGetAccountById.rejected, state => {
      state.loading = false;
    });
    builder.addCase(doGetFreeTestResultQuota.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetFreeTestResultQuota.fulfilled, (state, action) => {
      state.loading = false;
      state.freeTestResultQuota = action.payload;
    });
    builder.addCase(doGetFreeTestResultQuota.rejected, state => {
      state.loading = false;
    });
    builder.addCase(doOptInSelfServe.pending, state => {
      state.loading = true;
    });
    builder.addCase(doOptInSelfServe.fulfilled, state => {
      state.isOptInToSelfServe = true;
      state.loading = false;
    });
    builder.addCase(doCheckOptInSelfServe.pending, state => {
      state.loading = true;
    });
    builder.addCase(doCheckOptInSelfServe.fulfilled, (state, action) => {
      state.loading = false;
      state.isOptInToSelfServe = action.payload.data;
    });
    builder.addCase(doCheckOptInSelfServe.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as SerializedException;
    });
    builder.addCase(doOptInSelfServe.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as SerializedException;
    });
  },
});

const selectAccountFeature = (state: AppState) => state[ACCOUNT_FEATURE_KEY];
const { selectEntities } = accountsAdapter.getSelectors(selectAccountFeature);

export const selectAccountsOfUserId = (userId: User['id']) => createSelector(
  selectEntities,
  selectAccountUsersByUserId(userId),
  (entities, accountUsers) => accountUsers.map(it => entities[it.accountId]),
);

export const selectAccountById = (id: Account['id']) => createSelector(
  selectEntities,
  entities => entities[id],
);

export const selectSelectedAccountId = createSelector(
  selectAccountFeature,
  state => state.selectedAccountId,
);

export const selectFreeKsExpiry = createSelector(
  selectAccountFeature,
  state => state.accountFreeKsExpiry,
);

export const selectIsPublicDomain = createSelector(
  selectAccountFeature,
  state => state.isPublicDomain,
);

export const selectLoading = createSelector(
  selectAccountFeature,
  state => state.loading,
);

export const selectFreeTestResultQuota = createSelector(
  selectAccountFeature,
  state => state.freeTestResultQuota,
);

export const selectIsOptInToSelfServe = createSelector(
  selectAccountFeature,
  state => state.isOptInToSelfServe,
);

export default accountsSlice.reducer;
