import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import { unwrapResult } from '@reduxjs/toolkit';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { TestOpsPlanInterval } from '../../../models';
import { useQuery } from '../../../routes';
import { fromTestCloudSubscriptions, useAppDispatch } from '../../../store';
import { fromInvoices, fromTestOpsBillingInformation } from '../../../store/rootReducer';
import { getTestCloudPerMinutePlanId } from '../utils';
import { BasePurchaseInfoProps } from './TestCloudCheckout';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  infoIcon: {
    margin: theme.spacing(0, 0.5),
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
  },
  tableCell: {
    padding: theme.spacing(2, 0),
  },
  tableCellHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
  },
  billingCycle: {
    display: 'flex',
    flexDirection: 'row',
  },
  subTotal: {
    marginRight: theme.spacing(4),
    fontSize: theme.spacing(2),
  },
  gray: {
    color: '#172B4D',
  },
  billingInfoRow: {
    borderBottom: '2px solid #EFF6FC',
  },
  tierSelect: {
    height: theme.spacing(4),
  },
  disabledHoverRow: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  discountCell: {
    color: '#276EF1',
  },
  haveaDiscount: {
    color: '#276EF1',
  },
  cancelDiscount: {
    color: '#A1A9B3',
    fontWeight: 500,
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  },
  discountIcon: {
    fontSize: `${theme.spacing(3)} !important`,
  },
  discountInput: {
    width: theme.spacing(20.625),
    paddingLeft: theme.spacing(0.5),
    '& > *': {
      '& > fieldset': {
        border: '1px solid #D5D8DD',
      },
    },
  },
  enabled: {
    '& > *': {
      backgroundColor: theme.palette.getContrastText('#000'),
    },
  },
  discountStatus: {
    fontSize: '.875rem',
  },
  discountLabel: {
    fontSize: '.875rem',
    marginRight: theme.spacing(0.875),
  },
  linkColor: {
    color: '#507dfa',
  },
  applyButton: {
    color: '#507dfa',
    fontWeight: 500,
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  },
  tooltipInfoContent: {
    padding: theme.spacing(1),
  },
  lastColumn: {
    textAlign: 'right',
  },
}));

const TooltipInfoContent = () => {
  const classes = useStyles();
  return (
    <div className={classes.tooltipInfoContent}>
      <FormattedMessage id="subscriptions.testcloud.tooltip.info" />
    </div>
  );
};

const PerMinutePurchaseInfo = (props: BasePurchaseInfoProps) => {
  const { currentSubscription } = props;
  const { get } = useQuery();
  const intl = useIntl();
  const [discountCodeOpen, setDiscountCodeOpen] = useState(false);
  const [discountCode, setDiscountCode] = useState<string>();
  const [effectiveDiscountCode, setEffectiveDiscountCode] = useState('');
  const [validDiscountCode, setValidDiscountCode] = useState<boolean | undefined>(undefined);
  const [verifyingDiscountCode, setVerifyingDiscountCode] = useState(false);
  const organizationId = Number(get('orgId'));
  const dispatch = useAppDispatch();
  const tiers = useSelector(fromTestCloudSubscriptions.selectTestCloudMinuteTiers);
  const billingInformation = useSelector(
    fromTestOpsBillingInformation.selectTestOpsBillingInformation,
  );
  // if upgrading, get current active sub's tier. Else get from URL params
  const initialTier = currentSubscription ? currentSubscription.tier!! : Number(get('tier'));
  const minimumTier = currentSubscription ? currentSubscription.tier!! : 1;
  const isFromQuoteCheckout = Boolean(get('fromQuoteCheckout'));
  const disabledMonthly = (currentSubscription
    && currentSubscription.billingInterval === TestOpsPlanInterval.YEAR)
    || (isFromQuoteCheckout || window.location.pathname.includes('get-quote'));
  const [chosenTier, setChosenTier] = useState(
    Math.max(Number.isNaN(initialTier) ? 0 : initialTier, minimumTier),
  );
  const currentBillingInterval = currentSubscription?.billingInterval === TestOpsPlanInterval.MONTH ? 'monthly' : 'annual';

  const initialInterval = get('interval');
  const [chosenInterval, setChosenInterval] = useState('');
  const isNotUpgrading = currentSubscription
    && currentSubscription.tier === chosenTier
    && currentBillingInterval === chosenInterval;

  const doPreviewInvoice = useCallback((
    _chosenTier,
    _chosenInterval,
    _validDiscountCode,
    _effectiveDiscountCode,
    _isNotUpgrading,
    _currentSubscription,
  ) => {
    const tier = tiers.find(it => it.tier === _chosenTier);
    if (!!tier && (_chosenInterval === 'monthly' || _chosenInterval === 'annual')) {
      if (_isNotUpgrading) {
        dispatch(fromInvoices.doResetPreview());
      } else {
        dispatch(fromInvoices.doPreviewInvoice({
          organizationId,
          planId: getTestCloudPerMinutePlanId(_chosenInterval),
          number: tier.credits,
          discountCode: _validDiscountCode ? _effectiveDiscountCode : undefined,
          // Add subscriptionId params for upgrading case
          ...(_currentSubscription && { subscriptionId: _currentSubscription.id }),
        }))
          .then(unwrapResult);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const doPreviewInvoiceDebounced = useCallback(debounce(doPreviewInvoice, 500), []);

  useEffect(() => {
    if ((initialInterval === 'annual' || initialInterval === 'monthly') && !currentSubscription?.billingInterval) {
      // for creating new order case
      setChosenInterval(initialInterval);
    } else {
      // for upgrading case
      setChosenInterval(currentBillingInterval);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialInterval]);

  useEffect(() => {
    if (isFromQuoteCheckout) {
      setChosenInterval('annual');
    }
  }, [isFromQuoteCheckout]);

  const reloadTestCloudSubscription = () => {
    dispatch(fromTestCloudSubscriptions
      .doGetTestCloudSubscriptionsByOrganizationId({
        organizationId: Number(organizationId) || 0,
      }));
  };

  useEffect(() => {
    doPreviewInvoiceDebounced(
      chosenTier,
      chosenInterval,
      validDiscountCode,
      effectiveDiscountCode,
      isNotUpgrading,
      currentSubscription,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenTier, chosenInterval, billingInformation,
    currentSubscription]);
  const loadingFromServer = useSelector(fromInvoices.selectLoading);
  const preview = useSelector(fromInvoices.selectPreview);

  const resetCouponState = () => {
    setDiscountCode('');
    setEffectiveDiscountCode('');
    setValidDiscountCode(undefined);
  };

  const handleCancel = () => {
    if (preview.total !== undefined && preview.total <= 0) return;
    setDiscountCodeOpen(false);
    resetCouponState();
    handleApplyCoupon(true);
  };

  const handleApplyCoupon = (isRemoveCoupon: boolean) => {
    if ((preview.total !== undefined && preview.total <= 0) || isNotUpgrading) return;
    const tier = tiers.find(it => it.tier === chosenTier);
    if (!!tier && (chosenInterval === 'monthly' || chosenInterval === 'annual')) {
      if (!isRemoveCoupon && discountCode !== undefined && discountCode !== '') {
        setValidDiscountCode(undefined);
        setVerifyingDiscountCode(true);
        setEffectiveDiscountCode(discountCode);
        dispatch(fromInvoices.doPreviewInvoice({
          organizationId,
          planId: getTestCloudPerMinutePlanId(chosenInterval),
          number: tier.credits,
          discountCode: discountCode || undefined,
          // Add subscriptionId params for upgrading case
          ...(currentSubscription && { subscriptionId: currentSubscription.id }),
        })).then(unwrapResult)
          .then(() => setValidDiscountCode(true))
          // TODO: define error at back-end code, temporary force as invalid discount code
          .catch(() => {
            reloadTestCloudSubscription();
            setValidDiscountCode(false);
          })
          .finally(() => setVerifyingDiscountCode(false));
      } else {
        if (discountCode === undefined || discountCode === '') {
          resetCouponState();
        }
        dispatch(fromInvoices.doPreviewInvoice({
          organizationId,
          planId: getTestCloudPerMinutePlanId(chosenInterval),
          number: tier.credits,
          // Add subscriptionId params for upgrading case
          ...(currentSubscription && { subscriptionId: currentSubscription.id }),
        })).then(unwrapResult);
      }
    }
  };

  const statusId = verifyingDiscountCode ? 'subscriptions.testcloud.discount_code.verifying'
    : (validDiscountCode ? 'subscriptions.testcloud.discount_code.applied'
      : validDiscountCode === false ? 'subscriptions.testcloud.discount_code.invalid' : undefined);

  const disabledInput = isNotUpgrading || verifyingDiscountCode
    || (preview.total !== undefined && preview.total <= 0)
    || (validDiscountCode && !preview.total);
  const classes = useStyles();
  return (
    <Paper elevation={0} className={classes.root}>
      <Typography fontWeight="bold" className={classes.gray}>
        <FormattedMessage id="subscriptions.testcloud.plan.per_minute" />
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                padding="none"
                width="40%"
                className={classes.tableCellHeader}
              >
                <FormattedMessage id="subscriptions.testcloud.subscription_summary.billing_cycle" />
              </TableCell>
              <TableCell
                padding="none"
                width="40%"
                className={classes.tableCellHeader}
              >
                <FormattedMessage id="subscriptions.testcloud.subscription_summary.select_tier" />
                &nbsp;
                <Tooltip title={<TooltipInfoContent />} placement="right-end">
                  <InfoOutlinedIcon fontSize="large" className={classes.infoIcon} />
                </Tooltip>
              </TableCell>
              <TableCell
                padding="none"
                width="20%"
                className={clsx(classes.tableCellHeader, classes.lastColumn)}
              >
                <FormattedMessage id="subscriptions.testcloud.subscription_summary.tax" />
              </TableCell>
              <TableCell padding="none" />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              className={clsx(classes.disabledHoverRow, classes.billingInfoRow)}
            >
              <TableCell
                padding="none"
                className={classes.tableCell}
              >
                <RadioGroup
                  name="radio-buttons-group"
                  className={classes.billingCycle}
                  value={chosenInterval}
                  onChange={e => setChosenInterval(e.target.value)}
                >
                  <FormControlLabel
                    value="annual"
                    control={(
                      <Radio
                        sx={{
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}
                        disableRipple
                      />
                    )}
                    label="Annual"
                  />
                  { !isFromQuoteCheckout && (
                  <FormControlLabel
                    value="monthly"
                    control={(
                      <Radio
                        sx={{
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}
                        disableRipple
                        disabled={disabledMonthly}
                      />
                    )}
                    label="Monthly"
                  />
                  )}
                </RadioGroup>
              </TableCell>
              <TableCell
                padding="none"
                className={classes.tableCell}
              >
                <div>
                  <FormControl>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={chosenTier}
                      defaultValue={currentSubscription ? minimumTier : chosenTier}
                      onChange={e => setChosenTier(+e.target.value)}
                      className={classes.tierSelect}
                      disabled={isFromQuoteCheckout}
                    >
                      {
                        tiers.map(it => (
                          <MenuItem key={it.tier} value={it.tier} disabled={it.tier < minimumTier}>
                            <FormattedMessage
                              id="subscriptions.testcloud.subscription_summary.tier_credits_sessions"
                              values={{
                                tier: it.tier,
                                credits: intl.formatNumber(it.credits),
                                concurrentSessions: intl.formatNumber(it.concurrentSessions),
                              }}
                            />
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </div>
              </TableCell>
              <TableCell
                padding="none"
                className={clsx(classes.tableCell, classes.lastColumn)}
              >
                <Box display="flex" alignItems="center" justifyContent="flex-end">
                  <Typography variant="body1">
                    $
                  </Typography>
                  <Typography>
                    {loadingFromServer
                      ? <Skeleton width={30} height={30} sx={{ ml: 0.5 }} />
                      : intl.formatNumber(preview.tax || 0)}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow
              className={classes.disabledHoverRow}
            >
              <TableCell
                padding="none"
                sx={{ pt: 2 }}
              >
                {
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => setDiscountCodeOpen(!discountCodeOpen)}
                    fontWeight={500}
                    sx={{ color: '#276EF1' }}
                  >
                    <FormattedMessage
                      id="subscriptions.testcloud.subscription_summary.have_discount_code"
                    />
                  </Link>
                }
              </TableCell>
              <TableCell colSpan={3} padding="none" sx={{ pt: 2 }}>
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                  <Typography variant="subtitle2" className={classes.subTotal}>
                    <FormattedMessage id="payment_method.subtotal" />
                  </Typography>
                  <Typography variant="h6" className={classes.gray}>
                    $
                  </Typography>
                  <Typography variant="h6" className={classes.gray}>
                    {
                      loadingFromServer
                        ? <Skeleton width={50} height={30} sx={{ ml: 0.5 }} />
                        : intl.formatNumber(preview.total || 0)
                    }
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
            {validDiscountCode && !discountCodeOpen && (
              <Typography
                className={classes.discountStatus}
              >
                {intl.formatMessage(
                  { id: 'subscriptions.testcloud.discount_code.applied_bracket' },
                  {
                    discountCode: effectiveDiscountCode,
                    span: () => (
                      <span className={classes.linkColor}>
                        {effectiveDiscountCode}
                      </span>
                    ),
                  },
                )}
              </Typography>
            )}
            <TableRow
              className={classes.disabledHoverRow}
            >
              <TableCell colSpan={3} padding="none">
                <Collapse
                  in={discountCodeOpen}
                  unmountOnExit
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    alignContent="center"
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      alignContent="center"
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        alignContent="center"
                      >
                        <Typography
                          className={classes.discountLabel}
                        >
                          <FormattedMessage
                            id="subscriptions.testcloud.subscription_summary.discount_code"
                          />
                        </Typography>
                        <TextField
                          className={
                            disabledInput ? classes.discountInput
                              : clsx(classes.discountInput, classes.enabled)
                          }
                          value={discountCode}
                          onChange={event => setDiscountCode(event.target.value)}
                          sx={{ pl: 1 }}
                          disabled={disabledInput}
                        />
                      </Box>
                      <Box
                        display="flex"
                        alignSelf="flex-end"
                        alignContent="center"
                        sx={{ pt: 1 }}
                      >
                        {statusId && (
                          <Typography
                            className={classes.discountStatus}
                            color={validDiscountCode ? '#2bd92b' : ((validDiscountCode !== undefined) ? '#e05169' : '#faaf00')}
                          >
                            <FormattedMessage
                              id={statusId}
                              values={{ discountCode: effectiveDiscountCode }}
                            />
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="flex-end"
                      justifyContent="flex-end"
                      sx={{ pl: 9, mt: 1 }}
                    >
                      <Button
                        className={classes.cancelDiscount}
                        onClick={handleCancel}
                        variant="text"
                        startIcon={<HighlightOffIcon className={classes.discountIcon} />}
                      >
                        <FormattedMessage
                          id="subscriptions.testcloud.subscription_summary.cancel"
                        />
                      </Button>
                      <Button
                        className={classes.applyButton}
                        variant="text"
                        onClick={() => handleApplyCoupon(false)}
                        startIcon={<CheckCircleOutlineIcon className={classes.discountIcon} />}
                      >
                        <FormattedMessage
                          id="subscriptions.testcloud.subscription_summary.apply"
                        />
                      </Button>
                    </Box>
                  </Box>
                </Collapse>
              </TableCell>
              <TableCell padding="none" />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default PerMinutePurchaseInfo;
