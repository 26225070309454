import Checkbox from '@mui/material/Checkbox';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ListSubheader from '@mui/material/ListSubheader';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Popper, { PopperProps } from '@mui/material/Popper';
import Autocomplete, { AutocompleteProps, AutocompleteRenderGroupParams, createFilterOptions } from '@mui/material/Autocomplete';
import { useState, useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { OrganizationFeature } from '../../../models/organizationFeature';
import NoResult from './NoResult';

const useStyles = makeStyles(() => ({
  filterBox: {
    width: '100%',
  },
  option: {
    '&[aria-selected="true"]': {
      backgroundColor: '#F0F8FF !important',
    },
  },
  listbox: {
    '&.MuiAutocomplete-listbox': {
      border: '1px solid #D5D8DD',
      borderRadius: '4px',
    },
  },
  noOptions: {
    border: '1px solid #D5D8DD',
    borderRadius: '4px',
    overflowX: 'hidden',
  },
}));

const allFeatures = [
  OrganizationFeature.UNLIMITED_ENGINE,
  OrganizationFeature.ENGINE,
  OrganizationFeature.UNLIMITED_KSE,
  OrganizationFeature.KSE,
  OrganizationFeature.PER_USER_KSE,
];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon color="primary" fontSize="small" />;

const PopperComponent = (props: PopperProps) => (
  <Popper
    {...props}
    placement="bottom-start"
    modifiers={[
      {
        name: 'preventOverflow',
        enabled: false,
        options: {
          altBoundary: true,
        },
      },
    ]}
  />
);

interface LicenseFilterButtonProps {
  onChange: (value: OrganizationFeature[]) => void;
  defaultValue: OrganizationFeature[];
  disabled: boolean;
}

const renderGroup = (params: AutocompleteRenderGroupParams) => [
  <ListSubheader key={params.key} component="div" disableSticky>
    {params.group}
  </ListSubheader>,
  params.children,
];

const LicenseFilterButton = (props: LicenseFilterButtonProps) => {
  const { onChange, defaultValue, disabled } = props;
  const classes = useStyles();
  const intl = useIntl();
  const selectionChange: AutocompleteProps<OrganizationFeature, true, true, undefined>['onChange'] = (_, value) => {
    onChange(value);
  };
  const customMethod = createFilterOptions<OrganizationFeature>({ trim: true });
  /*
    For some reason, the focused state is not updated after the page loads,
    so another ref is used to keep track of this by comparing itself with the
    current active (focused) element
  */
  const filterInputRef = useRef<HTMLDivElement>(null);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (document.activeElement !== filterInputRef.current) {
      setFocused(false);
    } else {
      setFocused(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.activeElement, filterInputRef]);

  return (
    <Autocomplete
      key={`${defaultValue}`} // adding this would reinitialized the component with new defaultValue
      filterOptions={customMethod}
      id="license-filter"
      multiple
      disabled={disabled}
      defaultValue={defaultValue}
      onChange={selectionChange}
      disableCloseOnSelect
      options={Array.from(new Set([...defaultValue, ...allFeatures]))}
      noOptionsText={<NoResult messages={[intl.formatMessage({ id: 'license_utilization.machine_filter.no_results_found' })]} />}
      PopperComponent={PopperComponent}
      groupBy={option => (
        (defaultValue.find(it => it === option)
          ? intl.formatMessage({ id: 'license_utilization.filter.selected' }, { count: defaultValue.length })
          : intl.formatMessage({ id: 'license_utilization.filter.all' }).toUpperCase())
      )}
      getOptionLabel={it => OrganizationFeature.getFeatureHalfFullName(it)}
      renderGroup={renderGroup}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          <Typography variant="body2">
            {OrganizationFeature.getFeatureHalfFullName(option)}
          </Typography>
        </li>
      )}
      renderInput={params => (
        <TextField
          {...params}
          inputRef={filterInputRef}
          variant="outlined"
          label={intl.formatMessage({ id: 'license_utilization.filter.license.label' })}
          placeholder={defaultValue.length === 0 ? intl.formatMessage({ id: 'license_utilization.filter.choose_license' }) : ''}
        />
      )}
      className={classes.filterBox}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      classes={{
        option: classes.option,
        listbox: classes.listbox,
        noOptions: classes.noOptions,
      }}
      clearText={intl.formatMessage({ id: 'license_utilization.filter.clear_all' })}
      size="small"
      limitTags={1}
      open={focused}
      {...(!focused ? {} : { renderTags: () => null })}
    />
  );
};

export default LicenseFilterButton;
