import { useEffect, useState } from 'react';
import Button from '@katalon-studio/katalon-ui/Button';
import Icon from '@katalon-studio/katalon-ui/Icon';
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { unwrapResult } from '@reduxjs/toolkit';
import groupBy from 'lodash/groupBy';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  fromAccountUsers,
  fromOrganizationUserFeature,
  fromOrganizationUsers,
  fromOrganizations,
  fromSubscriptions,
  useAppDispatch,
} from '../../../store';
import CommonDataTable, { Column } from '../../../components/common/CommonDataTable';
import {
  AccountRole,
  AccountUser,
  OrganizationFeature,
  OrganizationUser,
  OrganizationUserFeature,
  Subscription,
  getAvatarSource,
  getFeatureOfHybridFeature,
  isKreFloatingFeature,
} from '../../../models';
import LoadingProgress from '../../../layout/LoadingProgress';

interface ActiveUserTabProps {
  accountId: number;
}

interface ActiveUserData extends AccountUser {
  licenseAccess: string[];
  organizations: OrganizationUser[];
  joinDate: Date;
  lastLogin?: number;
  action: React.ReactElement;
}

const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  toolbarBoxSizing: {
    width: theme.spacing(2),
  },
  toolbarButton: {
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
    backgroundColor: '#e7e9ef !important',
    color: '#22283c !important',
    '&:hover': {
      backgroundColor: '#e7e9ef',
      color: '#22283c',
    },
  },
  actionIconButton: {
    minWidth: theme.spacing(4.5),
    maxWidth: theme.spacing(4.5),
  },
  actionIcon: {
    color: '#808b9a',
  },
  fullNameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  fullName: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  fullNameFlex: {
    flex: 1,
  },
  organizationCell: {
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    '&:hover > button': {
      visibility: 'visible',
      backgroundColor: 'transparent',
    },
  },
  organizationButton: {
    width: theme.spacing(2),
    minWidth: theme.spacing(2),
    maxWidth: theme.spacing(2),
    height: theme.spacing(2),
    minHeight: theme.spacing(2),
    maxHeight: theme.spacing(2),
    padding: 0,
    flex: 1,
    visibility: 'hidden',
  },
  organizationButtonIcon: {
    color: '#1847ca',
    fontSize: theme.spacing(1.5),
  },
}));

const ActiveUserTab = ({ accountId } : ActiveUserTabProps) => {
  const columns: Column<ActiveUserData>[] = [
    {
      id: 'fullName',
      sortable: true,
      width: 200,
      minWidth: 180,
      render: row => (
        <div className={classes.fullNameContainer}>
          <Avatar
            className={classes.avatar}
            src={getAvatarSource(row.avatar)}
          />
          <span className={classes.fullName}>
            {row.fullName}
          </span>
        </div>
      ),
    },
    {
      id: 'email',
      sortable: true,
      width: 200,
      minWidth: 180,
      hasTooltip: true,
    },
    {
      id: 'role',
      sortable: true,
      minWidth: 160,
      width: 160,
      render: row => (
        <>
          { AccountRole.getRoleFullName(row.role) }
        </>
      ),
    },
    {
      id: 'organizations',
      minWidth: 160,
      width: 160,
      maxWidth: 200,
      render: row => {
        const hasOrganization = row.organizations.length > 0;
        if (!hasOrganization) {
          return <>{noDataMessage}</>;
        }
        const organizations = row.organizations.map(it => it.organization);
        return (
          <div className={classes.organizationCell}>
            <span>
              {organizations[0].name}
              {organizations.length > 1 && ',...'}
            </span>
            <Button
              id={`account.users.table.active.row.organization_button_${row.id}`}
              className={classes.organizationButton}
              variant="text"
              onClick={event => { event.stopPropagation(); }}
            >
              <Icon name="fa-eye" type="fa-solid" className={classes.organizationButtonIcon} />
            </Button>
          </div>
        );
      },
    },
    {
      id: 'licenseAccess',
      sortable: true,
      minWidth: 180,
      width: 180,
      maxWidth: 180,
      render: row => {
        const hasLicense = row.licenseAccess.length > 0;
        if (!hasLicense) {
          return <>{noDataMessage}</>;
        }
        return (
          <Tooltip title={row.licenseAccess.join(', ')} placement="top">
            <span>
              {row.licenseAccess[0]}
              {row.licenseAccess.length > 1 && ',...'}
            </span>
          </Tooltip>
        );
      },
    },
    {
      id: 'invitedBy',
      width: 200,
      minWidth: 200,
      hasTooltip: true,
      render: row => {
        if (!row.invitedBy) {
          return <>{noDataMessage}</>;
        }
        return (
          <Tooltip title={row.invitedBy} placement="top">
            <span>
              {row.invitedBy}
            </span>
          </Tooltip>
        );
      },
    },
    {
      id: 'joinDate',
      sortable: true,
      width: 160,
      minWidth: 160,
      render: row => (
        <>
          {
            row.createdAt ? intl.formatDate(
              row.createdAt,
              {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hourCycle: 'h23',
              },
            ) : noDataMessage
          }
        </>
      ),
    },
    {
      id: 'lastLogin',
      minWidth: 160,
      render: row => {
        const lastLogin = row.lastAccessedAt;
        if (!lastLogin) {
          return <>{noDataMessage}</>;
        }
        const lastLoginStr = intl.formatDate(
          new Date(lastLogin),
          {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hourCycle: 'h23',
          },
        );
        return (
          <span>
            {lastLogin ? lastLoginStr : noDataMessage}
          </span>
        );
      },
    },
    {
      id: 'action',
      align: 'right',
      width: 60,
      ignoreHeader: true,
    },
  ];

  const dispatch = useAppDispatch();
  const intl = useIntl();
  const classes = useStyles();

  const noDataMessage = intl.formatMessage({ id: 'account.users.table.no_data_cell' });

  const accountUsers = useSelector(fromAccountUsers.selectAccountUserByAccountId(accountId));
  const organizationUsers = useSelector(fromOrganizationUsers.selectByAccountId(accountId));
  const organizations = useSelector(fromOrganizations.selectAllByAccountId(accountId));
  const organizationIds = organizations.map(it => it.id);
  const orgUserFeatures = useSelector(
    fromOrganizationUserFeature.selectFeaturesByListOrganizationId(organizationIds),
  );
  const subscriptions = useSelector(
    fromSubscriptions.selectSubscriptionByListOrganizationId(organizationIds),
  );

  const [fetched, setFetched] = useState(false);
  const [data, setData] = useState<ActiveUserData[]>([]);
  const [selectedRows, setSelectedRows] = useState<readonly number[]>([]);

  useEffect(() => {
    const fetchInfo = async () => {
      const { organizations } = await dispatch(
        fromOrganizations.doFetchOrganizationByAccountId({ accountId }),
      ).then(unwrapResult);
      const orgIds = organizations.map(it => it.id);
      const promises: Promise<any>[] = [
        dispatch(fromAccountUsers.doGetAllByAccountId({ accountId })),
        dispatch(fromOrganizationUsers.doGetOrgUserByAccountId({ accountId })),
      ];
      if (orgIds.length > 0) {
        promises.push(dispatch(fromSubscriptions.doGetActiveSubscriptionsByListOrgId(orgIds)));
        promises.push(dispatch(
          fromOrganizationUserFeature.doGetOrgUserFeaturesByListOrgId(orgIds),
        ));
      }
      await Promise.all(promises);
      setFetched(true);
    };
    if (accountId) {
      fetchInfo();
    }
  }, [accountId]);

  useEffect(() => {
    if (!fetched) return;
    const orgUserGroup = groupBy(organizationUsers, 'user.email');
    setData(accountUsers.map(user => {
      const organizations = orgUserGroup[user.email ?? ''] ?? [];
      const orgIds = organizations.map(it => it.organizationId);
      const userOrgIds = organizations.map(it => it.id);
      const assignedFeaturesInOrgs = orgUserFeatures
        .filter(it => orgIds.includes(it.organizationId));
      const subscriptionsInOrgs = subscriptions
        .filter(it => orgIds.includes(it.organizationId));
      const userFeaturePredicate = (userFeature: OrganizationUserFeature) => userOrgIds
        .includes(userFeature.organizationUserId);
      const features = getLicenseAccessList(
        userFeaturePredicate,
        subscriptionsInOrgs,
        assignedFeaturesInOrgs,
      );
      return {
        ...user,
        joinDate: user.createdAt,
        licenseAccess: features,
        organizations,
        lastLogin: user.lastAccessedAt,
        action: <>{!AccountRole.isOwner(user.role) && renderAction(user)}</>,
      };
    }));
  }, [fetched]);

  const handleFilter = (value: string) => data.filter(it => {
    const isMatchName = it.fullName && it.fullName.toLowerCase().indexOf(value) > -1;
    const isMatchEmail = it.email && it.email.toLowerCase().indexOf(value) > -1;
    return isMatchName || isMatchEmail;
  });

  const handleSelect = (selected: readonly number[]) => setSelectedRows(selected);

  const getLicenseAccessList = (
    predicate: (_: OrganizationUserFeature) => boolean,
    subscriptions: Subscription[],
    userFeatures: OrganizationUserFeature[],
  ) => {
    // logic check:
    // - if org has both KRE F and NL; user has no KRE => return KRE NL + user's licenses;
    // - if org has both KRE F and NL; user has KRE-NL => return user's licenses;
    // - if org has both KRE F and NL; user has KRE-F => return user's licenses;
    // - if org has KRE F; user has no KRE => return KRE F + user's licenses;
    // - if org has KRE NL; user has no KRE => return KRE NL + user's licenses;
    // - if org has KRE NL / F ; user has KRE NL / F  => return user's licenses;
    const orgHasKreNl = subscriptions
      .find(it => it.feature === OrganizationFeature.ENGINE) !== undefined;
    const orgHasKreFl = subscriptions
      .find(it => isKreFloatingFeature(it.feature)) !== undefined;
    const featuresOfUser = userFeatures
      .filter(predicate)
      .map(it => it.feature);
    const userHasKreFl = featuresOfUser
      .find(it => it === OrganizationFeature.UNLIMITED_ENGINE) !== undefined;
    const userHasKreNl = featuresOfUser
      .find(it => it === OrganizationFeature.ENGINE) !== undefined;

    const finalFeaturesOfUser = (orgHasKreNl && !userHasKreFl && !userHasKreNl)
      ? [...featuresOfUser, OrganizationFeature.ENGINE]
      : (!orgHasKreNl && orgHasKreFl && !userHasKreFl && !userHasKreNl)
        ? [...featuresOfUser, OrganizationFeature.UNLIMITED_ENGINE]
        : featuresOfUser;
    const activeSubscriptionFeatures = subscriptions
      .map(it => getFeatureOfHybridFeature(it.feature));
    const finalActiveFeatures = finalFeaturesOfUser.filter(
      it => activeSubscriptionFeatures.includes(it),
    );
    return Array.from(new Set(finalActiveFeatures
      .map(f => OrganizationFeature.getFeatureHalfFullName(f))));
  };

  const renderAction = (row: AccountUser) => (
    <Button
      id={`account.users.table.active.row.action_button_${row.id}`}
      className={classes.actionIconButton}
      variant="text"
      onClick={event => { event.stopPropagation(); }}
    >
      <Icon name="fa-ellipsis-vertical" type="fa-solid" className={classes.actionIcon} />
    </Button>
  );

  if (!fetched) {
    return <LoadingProgress />;
  }

  return (
    <CommonDataTable
      tableKey="account.users.table.active"
      columns={columns}
      data={data}
      sortBy="joinDate"
      sortType="desc"
      paginationOptions={[10, 20, 50]}
      searchable
      onSearchTextChange={handleFilter}
      selectable
      onSelectRow={handleSelect}
      selectedRows={selectedRows}
      additionToolbar={(
        <Grid item flex={1} className={classes.toolbar}>
          <Button
            id="account.users.table.active.toolbar.transfer_ownership"
            size="small"
            color="primary"
            variant="contained"
            disabled={selectedRows.length !== 1}
            className={classes.toolbarButton}
          >
            {intl.formatMessage({ id: 'account.users.table.active.toolbar.transfer_ownership.button' })}
          </Button>
          <Box className={classes.toolbarBoxSizing} />
          <Button
            id="account.users.table.active.toolbar.edit_login_option"
            size="small"
            color="primary"
            variant="contained"
            disabled={selectedRows.length === 0}
            className={classes.toolbarButton}
          >
            {intl.formatMessage({ id: 'account.users.table.active.toolbar.edit_login_option.button' })}
          </Button>
        </Grid>
      )}
    />
  );
};

export default ActiveUserTab;
