import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../store';
import { fromInvoices } from '../../../../store/rootReducer';
import { OrganizationFeature, TestOpsPlanInterval, isTestCloudFeature } from '../../../../models';
import { getPlanId } from '../utils';

const useStyles = makeStyles(({
  root: {
  },
  container: {
    width: '100%',
  },
  namePriceContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    position: 'relative',
  },
  name: {
    fontSize: 14,
    fontWeight: 500,
    color: '#233145',
    width: '70%',
    marginBottom: 0,
  },
  sumPrice: {
    fontSize: 14,
    fontWeight: 600,
    color: '#22283c',
    top: 0,
    right: 0,
    position: 'absolute',
  },
  circleProgress: {
    top: 12,
    right: 0,
    position: 'absolute',
  },
  unitPriceBillingCycleContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    width: '100%',
  },
  unitPrice: {
    fontSize: 12,
    fontWeight: 500,
    color: '#727993',
  },
  billingCycle: {
    fontSize: 12,
    fontWeight: 500,
    color: '#4d5369',
    bottom: 0,
    right: 0,
    position: 'absolute',
  },
  line: {
    border: 'none',
    borderTop: '1px dashed #d6dae4',
  },
}));

interface OrderItemProps {
  currentSubscription?: any,
  accountId: number,
  chosenInterval: any,
  organizationFeature: OrganizationFeature,
  chosenQuota: number,
  unitPrice: number,
  planName: string,
  packageName: string,
}

const OrderItem = (props: OrderItemProps) => {
  const {
    currentSubscription,
    accountId,
    chosenInterval,
    organizationFeature,
    chosenQuota,
    unitPrice,
    planName,
    packageName,
  } = props;

  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const preview = useSelector(fromInvoices
    .selectPreviewByFeature(organizationFeature), shallowEqual);

  const loading = useSelector(fromInvoices
    .selectLoadingByFeature(organizationFeature));

  const planId = (chosenInterval: TestOpsPlanInterval): string => getPlanId(
    organizationFeature,
    chosenInterval,
  );
  const getQuota = (): number => {
    if (!currentSubscription) return chosenQuota;
    if (isTestCloudFeature(currentSubscription.feature)) {
      return chosenQuota + (currentSubscription?.concurrentSessions || 0);
    }
    if (currentSubscription.feature === OrganizationFeature.TESTOPS_PLATFORM) {
      return chosenQuota;
    }
    return chosenQuota + (currentSubscription?.quota || 0);
  };

  const getQuotaForView = (): number => {
    if (!currentSubscription
      || currentSubscription.feature === OrganizationFeature.TESTOPS_PLATFORM) {
      return chosenQuota;
    }

    const currentQuota = isTestCloudFeature(currentSubscription.feature)
      ? currentSubscription.concurrentSessions
      : currentSubscription.quota;
    if ((currentSubscription.billingCycle !== chosenInterval
      && !isTestCloudFeature(currentSubscription.feature))
      || (currentSubscription.billingInterval !== chosenInterval
      && isTestCloudFeature(currentSubscription.feature))) {
      return currentQuota + chosenQuota;
    }
    return chosenQuota;
  };

  useEffect(() => {
    const quota = getQuota();
    if (quota === 0) return;
    const getPreviewInvoice = async () => {
      await dispatch(fromInvoices.doPreviewInvoice({
        organizationId: accountId,
        planId: planId(chosenInterval),
        number: getQuota(),
        newQuotaNumber: chosenQuota,
        // eslint-disable-next-line max-len
        ...(currentSubscription && { subscriptionId: currentSubscription.id, recurlySubscriptionUuid: currentSubscription.recurlySubscription?.recurlySubscriptionUuid }),
      }));
    };
    getPreviewInvoice().catch(() => {});
  }, []);

  return (
    <div id="order-item" className={classes.root}>
      <div className={classes.container}>
        <div className={classes.namePriceContainer}>
          <p className={classes.name}>
            <FormattedMessage id={planName} />
          </p>
          {
            loading
              ? <CircularProgress className={classes.circleProgress} size={20} />
              : <p className={classes.sumPrice}>{`$${intl.formatNumber(preview?.subTotal || 0)}`}</p>
          }
        </div>
        <div className={classes.unitPriceBillingCycleContainer}>
          <p className={classes.unitPrice}>
            {organizationFeature === OrganizationFeature.TESTOPS_PLATFORM || organizationFeature === OrganizationFeature.VISUAL_TESTING_PRO ? `${getQuotaForView()} ` : `$${unitPrice} x ${getQuotaForView()} `}
            <FormattedMessage id={packageName} />
          </p>
          <p className={classes.billingCycle}>
            {'Bill '}
            {chosenInterval === 'YEAR' ? <FormattedMessage id="subscriptions.testops_platform.annual" /> : <FormattedMessage id="subscriptions.testops_platform.monthly" />}
          </p>
        </div>
      </div>
      <div className={classes.line} />
    </div>
  );
};

export default OrderItem;
