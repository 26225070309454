import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import { TestOpsEnterpriseProduct, OrganizationFeature } from '../models/index';
import { TestOpsEnterpriseProductService } from '../services';
import { AppState } from '.';
import { Operator, Query } from '../models/query';

export const TESTOPS_ENTERPRISE_PRODUCT_KEY = 'enterpriseProducts';

interface EnterpriseProductsState extends EntityState<TestOpsEnterpriseProduct> {
  loading: boolean;
  error?: string;
}

const enterpriseProductsAdapter = createEntityAdapter<TestOpsEnterpriseProduct>();
const createInitialState = ():
EnterpriseProductsState => enterpriseProductsAdapter.getInitialState({
  loading: false,
  count: 0,
});

export const doGetProductsInfoByType = createAsyncThunk(
  'enterpriseProducts/getProductsInfo',
  async (input: Pick<TestOpsEnterpriseProduct, 'type'>) => {
    const criteria: Query<TestOpsEnterpriseProduct>[] = [
      {
        field: 'type',
        operator: Operator.EQ,
        value: input.type,
      },
    ];
    const response = await TestOpsEnterpriseProductService.getProductsInfo(...criteria);
    return response;
  },
);

const enterpriseProductSlice = createSlice({
  name: TESTOPS_ENTERPRISE_PRODUCT_KEY,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doGetProductsInfoByType.pending, state => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(doGetProductsInfoByType.fulfilled, (state, action) => {
      state.loading = false;
      enterpriseProductsAdapter.setAll(state, action.payload);
    });
    builder.addCase(doGetProductsInfoByType.rejected, state => {
      state.loading = false;
    });
  },
});

const selectEnterpriseProducts = (state: AppState) => state[TESTOPS_ENTERPRISE_PRODUCT_KEY];

export const {
  selectAll: selectAllEnterpriseProducts,
  selectIds,
  selectEntities,
} = enterpriseProductsAdapter.getSelectors(selectEnterpriseProducts);

export const selectEnterpriseProductsByType = (type: TestOpsEnterpriseProduct['type']) => createSelector(
  selectAllEnterpriseProducts,
  enterpriseProducts => enterpriseProducts.filter(it => it.type === type),
);

export const selectSuggestFeaturePlan = (
  currentFeaturePlan: OrganizationFeature | undefined,
) => createSelector(
  selectAllEnterpriseProducts,
  enterpriseProducts => {
    const filteredProducts = enterpriseProducts.filter(
      it => it.feature !== OrganizationFeature.TESTCLOUD_MINUTE,
    );
    if (!currentFeaturePlan) return filteredProducts;
    return filteredProducts.filter(it => it.feature !== currentFeaturePlan);
  },
);

export default enterpriseProductSlice.reducer;
