import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { ReactComponent as OrganizationIcon } from '../icons/project-diagram.svg';
import { AccountRole } from '../../models';
import { fromAccounts, fromTestOpsProject, useAppDispatch } from '../../store';
import { ReactComponent as ChartAreaIcon } from './chart-area.svg';
import AvatarAccount from './AvatarAccount';

interface AccountItemProps {
  id: number;
  viewedAs: AccountRole | undefined;
}

const Bold = styled('span')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}));

const useStyles = makeStyles(theme => ({
  chartArea: {
    textAlign: 'left',
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: theme.spacing(2),
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#808b9a',
  },
  avatarContent: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    gridArea: '1/1',
    border: '8px solid rgba(255, 255, 255, 1)',
  },
  accountDetail: {
    minWidth: theme.spacing(62.5),
  },
  accountData: {
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    fontSize: theme.spacing(2),
    minWidth: theme.spacing(20),
  },
  personalItem: {
    maxWidth: theme.spacing(20),
  },
  organizationItem: {
    maxWidth: theme.spacing(22),
  },
  projectItem: {
    paddingLeft: theme.spacing(3),
    maxWidth: theme.spacing(22),
  },
  idItem: {
    maxWidth: theme.spacing(25),
  },
  nameBox: {
    wordWrap: 'break-word',
  },
  accountName: {
    marginLeft: theme.spacing(1),
    fontSize: theme.spacing(2.5),
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const AccountSettingItem = (props: AccountItemProps) => {
  const { id, viewedAs } = props;
  const account = useSelector(fromAccounts.selectAccountById(id))!!;
  const dispatch = useAppDispatch();
  const classes = useStyles();
  useEffect(() => {
    dispatch(fromAccounts.doCountMembersOfAccount({ id }));
    dispatch(fromAccounts.doCountOrganizationsOfAccount({ id }));
    dispatch(fromTestOpsProject.doCountProjectsByAccountId({ accountId: id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box display="flex" padding={3} alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center" width="100%">
        <Box className={classes.avatarContent}>
          <AvatarAccount />
        </Box>
        <Box className={classes.accountDetail}>
          <Box className={classes.nameBox}>
            <Typography className={classes.accountName} variant="subtitle2">
              {account.name}
            </Typography>
          </Box>
          <Box display="flex">
            {AccountRole.isManager(viewedAs) && (
            <Box className={`${classes.accountData} ${classes.personalItem}`}>
              <PersonOutlineOutlinedIcon color="disabled" />
              <Typography ml={0.5} variant="subtitle2">
                <FormattedMessage id="welcome.account.members" />
                &nbsp;
                {account.numberAccountUsers !== undefined
                  ? (
                    <Bold>{account.numberAccountUsers}</Bold>
                  ) : (
                    <Skeleton width={30} sx={{ display: 'inline-block' }} />
                  )}
              </Typography>
            </Box>
            )}
            {AccountRole.isManager(viewedAs) && (
            <Box className={`${classes.accountData} ${classes.personalItem}`}>
              <OrganizationIcon color="disabled" />
              <Typography ml={0.5} variant="subtitle2">
                <FormattedMessage id="welcome.account.organizations" />
                &nbsp;
                {account.numberOrganizations !== undefined
                  ? (
                    <Bold>{account.numberOrganizations}</Bold>
                  ) : (
                    <Skeleton width={30} sx={{ display: 'inline-block' }} />
                  )}
              </Typography>
            </Box>
            )}
            {AccountRole.isManager(viewedAs) && (
            <Box className={`${classes.accountData} ${classes.projectItem}`}>
              <ChartAreaIcon className={classes.chartArea} />
              <Typography ml={0.5} variant="subtitle2">
                <FormattedMessage id="welcome.account.projects" />
                &nbsp;
                {account.numberProjects !== undefined
                  ? (
                    <Bold>{account.numberProjects}</Bold>
                  ) : (
                    <Skeleton width={30} sx={{ display: 'inline-block' }} />
                  )}
              </Typography>
            </Box>
            )}
            {AccountRole.isManager(viewedAs) && (
            <Box className={`${classes.accountData} ${classes.idItem}`}>
              <Typography variant="subtitle2">
                <FormattedMessage id="welcome.account.organization.id" />
                <Bold>{id}</Bold>
              </Typography>
            </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AccountSettingItem;
