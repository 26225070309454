import { useEffect, useState } from 'react';
import orderBy from 'lodash/orderBy';
import { useSelector } from 'react-redux';
import { OrgLicenseData } from '../../base/BaseLicenseAllocationDetail';
import { fromAuth, fromSubscriptions, useAppDispatch } from '../../../../../store';
import { AccountRole, AccountUser, OrganizationFeature } from '../../../../../models';
import { SubscriptionAllocation } from '../../../../../models/recurlySubscriptionDTO';
import CommonDataTable, { Column } from '../../../../../components/common/CommonDataTable';

interface KreOrgLicenseData extends OrgLicenseData {
  kreUsage: string;
  hybridKreUsage: string;
}

const columns: Column<KreOrgLicenseData>[] = [
  { id: 'id' },
  {
    id: 'orgName',
  },
  {
    id: 'kreUsage',
  },
  {
    id: 'hybridKreUsage',
  },
];

const kreLicenseTypes: readonly OrganizationFeature[] = [
  OrganizationFeature.UNLIMITED_ENGINE,
];

const hybridKreLicenseTypes: readonly OrganizationFeature[] = [
  OrganizationFeature.UNLIMITED_ENGINE_OFFLINE,
];

interface KreLicenseAllocationTableProps {
  currentAccountUser: AccountUser | undefined,
  adjustAllocatedKreLicense?: (count: number) => void,
  adjustAllocatedHybridKreLicense?: (count: number) => void,
  setLastUpdated: (lastUpdated: Date) => void,
  setUpdatedBy: (updatedBy: string) => void,
  setLoadedAllocated: () => void,
}

const KreLicenseAllocationTable = (props: KreLicenseAllocationTableProps) => {
  const {
    currentAccountUser,
    adjustAllocatedKreLicense,
    adjustAllocatedHybridKreLicense,
    setLastUpdated,
    setUpdatedBy,
    setLoadedAllocated,
  } = props;

  const dispatch = useAppDispatch();

  const isRootOrAdmin = useSelector(fromAuth.selectIsRootOrAdmin);

  const [dataTable, setDataTable] = useState<KreOrgLicenseData[]>([]);
  const [selectedOrgIds, setSelectedOrgIds] = useState<readonly number[]>([]);
  const [orgAllocations, setOrgAllocations] = useState<SubscriptionAllocation[]>([]);
  const [isEditable, setIsEditable] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);

  const createData = (rawData: {
    id: number;
    orgName: string;
    kreUsage: string;
    hybridKreUsage: string;
  }): KreOrgLicenseData => ({
    id: rawData.id,
    orgName: rawData.orgName,
    kreUsage: rawData.kreUsage,
    hybridKreUsage: rawData.hybridKreUsage,
  });

  useEffect(() => {
    setIsDataFetched(false);
    dispatch(
      fromSubscriptions.doGetActiveRecurlySubscriptionsWithAllocationsByAccountId(
        { id: currentAccountUser?.accountId ?? 0 },
      ),
    )
      .unwrap()
      .then(data => {
        data
          .filter(subAllo => kreLicenseTypes.includes(subAllo.feature)
            || hybridKreLicenseTypes.includes(subAllo.feature))
          .forEach(subs => {
            const clonedList = [...orgAllocations];
            subs.allocations.forEach(subAllo => clonedList.push(subAllo));
            setOrgAllocations(clonedList);
            setLoadedAllocated();
          });
      }).finally(() => setIsDataFetched(true));
  }, [currentAccountUser]);

  useEffect(() => {
    orgAllocations.forEach(orgAllo => {
      if (adjustAllocatedKreLicense) {
        adjustAllocatedKreLicense(orgAllo.allocatedKreQuota);
      }
      if (adjustAllocatedHybridKreLicense) {
        adjustAllocatedHybridKreLicense(orgAllo.allocatedHybridKreQuota);
      }

      const data = createData({
        id: orgAllo.orgId,
        orgName: orgAllo.orgName,
        kreUsage: `${orgAllo.allocatedKreQuota}`,
        hybridKreUsage: `${orgAllo.allocatedHybridKreQuota}`,
      });
      const cloned = [...dataTable];
      cloned.push(data);
      setDataTable(cloned);

      const lastUpdated = orderBy(orgAllocations, ['updatedAt'], ['desc'])[0];
      setLastUpdated(lastUpdated.updatedAt);
      setUpdatedBy(lastUpdated.updatedBy?.fullName ?? 'System');

      columns
        .filter(() => orgAllo.allocatedKreQuota < 1)
        .forEach((columnHeader, index) => {
          if (columnHeader && columnHeader.id === 'kreUsage') {
            delete columns[index];
          }
        });
      columns
        .filter(() => orgAllo.allocatedHybridKreQuota < 1)
        .forEach((columnHeader, index) => {
          if (columnHeader && columnHeader.id === 'hybridKreUsage') {
            delete columns[index];
          }
        });
    });
  }, [orgAllocations]);

  const handleFilter = (value: string) => dataTable
    .filter(it => it.orgName && it.orgName.toLowerCase().indexOf(value) > -1);

  const handleSelect = (selected: readonly number[]) => setSelectedOrgIds(selected);

  useEffect(() => {
    setIsEditable(AccountRole.isAdminOrOwnerRole(currentAccountUser?.role)
      || isRootOrAdmin);
  }, [currentAccountUser, isRootOrAdmin]);

  return (
    <CommonDataTable
      tableKey="licenses.allocation.table"
      columns={columns}
      data={dataTable}
      sortBy="id"
      sortType="desc"
      paginationOptions={[10, 20, 50]}
      searchable
      onSearchTextChange={handleFilter}
      selectable={isEditable}
      onSelectRow={handleSelect}
      selectedRows={selectedOrgIds}
      loading={!isDataFetched}
    />
  );
};

export default KreLicenseAllocationTable;
