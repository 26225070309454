import { BaseModel } from './base';

export enum RecurlySubscriptionPayloadStatus {
  ACTIVE = 'ACTIVE',
  FUTURE = 'FUTURE',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
}

export interface RecurlySubscription extends BaseModel {
  recurlySubscriptionId: number;
  recurlySubscriptionUuid?: string;
  unpaid?: boolean;
  status?: RecurlySubscriptionPayloadStatus;
  stripeCustomerId?: number;
  testOpsStripeCustomerId?: number;
  enterprisePlanId?: number;
  recurlyInvoiceNumber?: string;
  canceledAt?: Date,
  planId?: string,
}
