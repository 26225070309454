import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { AppState } from '.';
import {
  exceptionOf,
  PaymentMethod,
  PaymentMethodType,
  SerializedException,
} from '../models';
import { Operator } from '../models/query';
import { PaymentMethodService } from '../services';
import { requestLocation } from '../services/track';

export const PAYMENT_METHOD_FEATURE_KEY = 'paymentMethod';

interface PaymentMethodState {
  selectedPaymentType: PaymentMethodType;
  defaultPaymentMethod?: PaymentMethodType;
  isAvailableRegion: boolean,
  cardInformationInvalid: boolean,
  loading: boolean;
  error?: string;
}

const paymentMethodAdapter = createEntityAdapter<PaymentMethod>();

export const createInitialState = (partialState: Partial<PaymentMethodState> = {}) => (
  paymentMethodAdapter.getInitialState({
    selectedPaymentType: PaymentMethodType.CREDIT_CARD,
    isAvailableRegion: false,
    cardInformationInvalid: true,
    loading: false,
    error: undefined,
    ...partialState,
  })
);

export const doCreatePaymentMethod = createAsyncThunk(
  'paymentMethod/createPaymentMethod',
  async (input: Parameters<typeof PaymentMethodService['createPaymentMethod']>[0], { rejectWithValue }) => {
    try {
      return await PaymentMethodService.createPaymentMethod(input);
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doGetPaymentMethod = createAsyncThunk(
  'paymentMethod/getPaymentMethod',
  async (accountId: PaymentMethod['accountId'], { rejectWithValue }) => {
    try {
      const paymentMethod = await PaymentMethodService.getPaymentMethods(
        { field: 'accountId', operator: Operator.EQ, value: accountId.toString() },
        { field: 'archived', operator: Operator.EQ, value: false },
      );

      if (paymentMethod.length === 0) {
        throw new Error();
      }

      return paymentMethod;
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doGetDefaultPaymentMethod = createAsyncThunk(
  'paymentMethod/getDefaultPaymentMethod',
  async (accountId: number, { rejectWithValue }) => {
    try {
      return await PaymentMethodService.getDefaultPaymentMethod(accountId);
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doUpdatePaymentMethod = createAsyncThunk(
  'paymentMethod/updatePaymentMethod',
  async (input: Parameters<typeof PaymentMethodService['updatePaymentMethod']>[0], { rejectWithValue }) => {
    try {
      return await PaymentMethodService.updatePaymentMethod(input);
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doUpdateDefaultPaymentMethod = createAsyncThunk(
  'paymentMethod/updateDefaultPaymentMethod',
  async (input: Parameters<typeof PaymentMethodService['updateDefaultPaymentMethod']>[0], { rejectWithValue }) => {
    try {
      return await PaymentMethodService.updateDefaultPaymentMethod(input);
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doDeletePaymentMethod = createAsyncThunk(
  'paymentMethod/deletePaymentMethod',
  async (input: Parameters<typeof PaymentMethodService['deletePaymentMethod']>[0], { rejectWithValue }) => {
    try {
      return await PaymentMethodService.deletePaymentMethod(input);
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

export const doGetRegion = createAsyncThunk(
  'paymentMethod/getRegion',
  async (_, { rejectWithValue }) => {
    try {
      const data = await requestLocation();
      return data;
    } catch (e: any) {
      return rejectWithValue(exceptionOf(e).toJson());
    }
  },
);

const testOpsPaymentMethodSlice = createSlice({
  name: PAYMENT_METHOD_FEATURE_KEY,
  initialState: createInitialState(),
  reducers: {
    doChangeSelectedPaymentMethod(state, action: PayloadAction<PaymentMethodType>) {
      state.selectedPaymentType = action.payload;
    },
    doUpdateCardInformationInvalid(state, action: PayloadAction<boolean>) {
      state.cardInformationInvalid = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(doCreatePaymentMethod.pending, state => {
      state.loading = true;
    });
    builder.addCase(doCreatePaymentMethod.fulfilled, (state, action) => {
      state.loading = false;
      paymentMethodAdapter.addOne(state, action.payload);
      state.cardInformationInvalid = false;
      state.error = undefined;
    });
    builder.addCase(doCreatePaymentMethod.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as SerializedException)?.message;
    });
    builder.addCase(doGetPaymentMethod.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetPaymentMethod.fulfilled, (state, action) => {
      state.loading = false;
      paymentMethodAdapter.upsertMany(state, action.payload);
      state.cardInformationInvalid = false;
      state.error = undefined;
    });
    builder.addCase(doGetPaymentMethod.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as SerializedException)?.message;
    });
    builder.addCase(doGetDefaultPaymentMethod.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetDefaultPaymentMethod.fulfilled, (state, action) => {
      state.loading = false;
      state.defaultPaymentMethod = action.payload;
      state.selectedPaymentType = action.payload;
      state.error = undefined;
    });
    builder.addCase(doGetDefaultPaymentMethod.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as SerializedException)?.message;
    });
    builder.addCase(doUpdatePaymentMethod.pending, state => {
      state.loading = true;
    });
    builder.addCase(doUpdatePaymentMethod.fulfilled, (state, action) => {
      state.loading = false;
      paymentMethodAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
      state.cardInformationInvalid = false;
      state.error = undefined;
    });
    builder.addCase(doUpdatePaymentMethod.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as SerializedException)?.message;
    });
    builder.addCase(doUpdateDefaultPaymentMethod.pending, state => {
      state.loading = true;
    });
    builder.addCase(doUpdateDefaultPaymentMethod.fulfilled, (state, action) => {
      state.loading = false;
      state.defaultPaymentMethod = action.payload;
      state.error = undefined;
    });
    builder.addCase(doUpdateDefaultPaymentMethod.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as SerializedException)?.message;
    });
    builder.addCase(doDeletePaymentMethod.pending, state => {
      state.loading = true;
    });
    builder.addCase(doDeletePaymentMethod.fulfilled, (state, action) => {
      state.loading = false;
      paymentMethodAdapter.removeOne(state, action.payload.id);
      state.cardInformationInvalid = true;
      state.error = undefined;
    });
    builder.addCase(doDeletePaymentMethod.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as SerializedException)?.message;
    });

    builder.addCase(doGetRegion.pending, state => {
      state.loading = true;
    });
    builder.addCase(doGetRegion.fulfilled, (state, action) => {
      state.loading = false;
      state.isAvailableRegion = action.payload.country === 'US';
      state.error = undefined;
    });
    builder.addCase(doGetRegion.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as SerializedException)?.message;
    });
  },
});

const selectPaymentMethodFeature = (state: AppState) => state[PAYMENT_METHOD_FEATURE_KEY];
const { selectAll } = paymentMethodAdapter.getSelectors(selectPaymentMethodFeature);
export const selectOneByAccountId = (accountId: PaymentMethod['accountId']) => createSelector(
  selectAll,
  paymentMethods => paymentMethods.find(it => it.accountId === accountId),
);
export const selectLoading = createSelector(
  selectPaymentMethodFeature,
  paymentMethodState => paymentMethodState.loading,
);
export const selectError = createSelector(
  selectPaymentMethodFeature,
  paymentMethodState => paymentMethodState.error,
);

export const selectSelectedPaymentMethod = createSelector(
  selectPaymentMethodFeature,
  it => it.selectedPaymentType,
);

export const selectDefaultPaymentMethod = createSelector(
  selectPaymentMethodFeature,
  it => it.defaultPaymentMethod,
);

export const selectIsAvailableRegion = createSelector(
  selectPaymentMethodFeature,
  it => it.isAvailableRegion,
);

export const isCardInformationInvalid = createSelector(
  selectPaymentMethodFeature,
  it => it.cardInformationInvalid,
);

export const {
  doChangeSelectedPaymentMethod,
  doUpdateCardInformationInvalid,
} = testOpsPaymentMethodSlice.actions;

export default testOpsPaymentMethodSlice.reducer;
