import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoadingProgress from '../../layout/LoadingProgress';
import { forbidden, useQuery } from '../../routes';
import { fromAccounts, useAppDispatch } from '../../store';
import { fromBillingInformation, fromPaymentMethod } from '../../store/rootReducer';
import BillingInformation from './BillingInformationComponent';
import { useLaunchDarkly } from '../../launchdarkly';
import DefaultPaymentMethod from './DefaultPaymentMethod';
import CardInformation from './CardInformation';
import { isBankTransferEnabled } from '../account/utils';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(3),
  },

}));
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

const PaymentMethod = () => {
  const classes = useStyles();
  const { get } = useQuery();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const { flags } = useLaunchDarkly();
  const accountId = Number(get('accountId'));

  const isAvailableRegion = useSelector(fromPaymentMethod.selectIsAvailableRegion);
  const isPublicDomain = useSelector(fromAccounts.selectIsPublicDomain);

  useEffect(() => {
    if (!accountId) return;
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([
        dispatch(fromPaymentMethod.doGetPaymentMethod(+accountId)),
        dispatch(fromBillingInformation.doGetBillingInformation(+accountId)),
        dispatch(fromPaymentMethod.doGetDefaultPaymentMethod(+accountId)),
        dispatch(fromAccounts.doCheckPublicDomain(+accountId)),
      ]).finally(() => setLoading(false));
    };
    fetchData();
  }, [accountId, dispatch]);

  const getRegion = async () => {
    dispatch(fromPaymentMethod.doGetRegion());
  };

  useEffect(() => {
    getRegion();
  }, []);

  const isBankTransferAvailable = () => {
    if (!isBankTransferEnabled(accountId, flags)) {
      // Bank transfer is not enabled
      return false;
    }

    if (flags?.EUBankTransferWhiteList?.includes(accountId)) {
      // EU Bank Transfer is enabled for this account
      return true;
    }

    if (flags?.restrictWireTransferEnabled && !isAvailableRegion) {
      // Bank transfer is restricted for this region
      return false;
    }

    return !isPublicDomain;
  };

  if (flags && flags.subscriptionAndPaymentMethodDisabled) {
    return <Navigate to={forbidden.path} replace />;
  }

  const renderDefaultPaymentMethodSection = () => (
    <Paper sx={{ padding: '0.75rem 1.5rem' }}>
      <Elements stripe={stripePromise}>
        <DefaultPaymentMethod accountId={accountId} />
      </Elements>
    </Paper>
  );

  const renderPaymentMethodSection = () => (
    <Paper sx={{ padding: '0.75rem 1.5rem' }}>
      <Elements stripe={stripePromise}>
        <CardInformation accountId={accountId} />
      </Elements>
    </Paper>
  );

  const renderBillingInformationSection = () => (
    <Paper sx={{ padding: '0.75rem 1.5rem' }}>
      <BillingInformation accountId={accountId} />
    </Paper>
  );

  return (
    <>
      <Box className={classes.root}>
        <Typography className={classes.title}>
          <FormattedMessage id="billinginfo.payment_method" />
        </Typography>
        <Box sx={{ pt: '1.5rem' }}>
          {isBankTransferAvailable()
            ? renderDefaultPaymentMethodSection()
            : renderPaymentMethodSection()}

          <br />
          {renderBillingInformationSection()}
        </Box>
      </Box>
      {loading && <LoadingProgress />}
    </>
  );
};

export default PaymentMethod;
