import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getFeatureName, getIntervalPathParam, OrganizationFeature, TestOpsPlanInterval } from '../../../../models';
import KsePurchaseDetails from './KsePurchaseDetails';
import KrePurchaseDetails from './KrePurchaseDetails';
import TestCloudPurchaseDetails from './TestCloudPurchaseDetails';
import VisualTestingPurchaseDetails from './VisualTestingPurchaseDetails';
import K1Typography from '../../../../components/design-system/K1Typography';
import PlatformPurchaseDetails from './PlatformPurchaseDetails';
import { useQuery } from '../../../../routes';
import { useLaunchDarkly } from '../../../../launchdarkly';
import { fromTestOpsPlatformSubscriptions } from '../../../../store';
import TrueTestPurchaseDetails from './TrueTestPurchaseDetails';

const useStyles = makeStyles(theme => ({
  groupTitle: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(2, 0, 0, 0),
    color: '#727993',
    marginBottom: 8,
  },
  card: {
    border: 'solid 1px #dbdde5',
    borderRadius: theme.spacing(0.5),
    backgroundColor: '#fff',
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
}));

export interface PlatformOfferingSectionProps{
  accountId: number;
  initialPlatformQuota?: number;
  initialKseQuota?: number;
  initialKreQuota?: number;
  initialTestCloudQuota?: number;
  initialTestCloudDesktopQuota?: number;
  initialTestCloudCrossBrowserQuota?: number;
  initialTestCloudMobileQuota?: number;
  initialVisualTestingQuota?: number;
  clearInitialQuota: Function;
}

const PlatformOfferingSection = (props: PlatformOfferingSectionProps) => {
  const classes = useStyles();
  const {
    accountId,
    initialPlatformQuota,
    initialKseQuota,
    initialKreQuota,
    initialTestCloudQuota,
    initialTestCloudDesktopQuota,
    initialTestCloudCrossBrowserQuota,
    initialTestCloudMobileQuota,
    initialVisualTestingQuota,
    clearInitialQuota,
  } = props;
  const { get } = useQuery();
  const { flags } = useLaunchDarkly();
  const platformCycle = TestOpsPlanInterval.fromString(
    get(getIntervalPathParam(OrganizationFeature.TESTOPS_PLATFORM)) || '',
  );

  const currentPlatformSubscription = useSelector(
    fromTestOpsPlatformSubscriptions.selectPaidTestOpsPlatformSubscriptionByAccountId(
      Number(accountId),
    ),
  )?.[0];

  const getInitialPlatformBillingCycle = () => {
    if (currentPlatformSubscription
      && currentPlatformSubscription?.billingCycle === TestOpsPlanInterval.YEAR) {
      return TestOpsPlanInterval.YEAR;
    }

    if (platformCycle) {
      return platformCycle;
    }
    return currentPlatformSubscription?.billingCycle || TestOpsPlanInterval.YEAR;
  };

  const renderKseRow = () => (
    <Box id="platform-kse">
      <KsePurchaseDetails
        accountId={accountId}
        initialQuota={initialKseQuota}
        title={<FormattedMessage id={`subscriptions.${getFeatureName(OrganizationFeature.PER_USER_KSE)}.title`} />}
        clearInitialQuota={clearInitialQuota}
      />
    </Box>
  );

  const renderTrueTestRow = () => (
    <Box id="truetest">
      <TrueTestPurchaseDetails
        title={<FormattedMessage id={`subscriptions.${getFeatureName(OrganizationFeature.TRUE_TEST)}.title`} />}
      />
    </Box>
  );

  const renderKreRow = () => (
    <Box id="platform-kre">
      <KrePurchaseDetails
        accountId={accountId}
        initialQuota={initialKreQuota}
        title={<FormattedMessage id="subscriptions.kre.title" />}
        clearInitialQuota={clearInitialQuota}
      />
    </Box>
  );

  const renderTestCloudPurchaseRows = () => (
    <Box id="test_cloud">
      <TestCloudPurchaseDetails
        accountId={accountId}
        initialTestCloudQuota={initialTestCloudQuota}
        initialTestCloudDesktopQuota={initialTestCloudDesktopQuota}
        initialTestCloudCrossBrowserQuota={initialTestCloudCrossBrowserQuota}
        initialTestCloudMobileQuota={initialTestCloudMobileQuota}
        clearInitialQuota={clearInitialQuota}
      />
    </Box>
  );

  return (
    <>
      {flags?.testOpsUIEnabled && (
        <Box id="platform">
          <K1Typography variant="h3" fontWeight="medium" className={classes.groupTitle}>
            <FormattedMessage id="subscriptions.testops_platform.offering.test_management" />
          </K1Typography>
          <PlatformPurchaseDetails
            accountId={accountId}
            currentSubscription={currentPlatformSubscription}
            initialInterval={getInitialPlatformBillingCycle()}
            initialQuota={initialPlatformQuota}
            clearInitialQuota={clearInitialQuota}
          />
        </Box>
      )}
      {flags?.testOpsUIEnabled && <Divider />}
      <Box id="katalon-test-createion">
        <K1Typography variant="h3" fontWeight="medium" className={classes.groupTitle}>
          <FormattedMessage id="subscriptions.testops_platform.offering.test_creation" />
        </K1Typography>
        <div className={classes.card}>
          {renderKseRow()}
        </div>
        {
          flags && flags.truetestUIEnabled && (
            <div className={classes.card}>
              {renderTrueTestRow()}
            </div>
          )
        }

      </Box>
      <Divider />
      <Box id="katalon-test-execution">
        <K1Typography variant="h3" fontWeight="medium" className={classes.groupTitle}>
          <FormattedMessage id="subscriptions.testops_platform.offering.test_execution" />
        </K1Typography>
        <div className={classes.card}>
          {renderKreRow()}
        </div>
        <div className={classes.card}>
          {renderTestCloudPurchaseRows()}
        </div>
      </Box>

      {flags && !flags.unlimitedVTPEnabled && <Divider />}
      {flags && !flags.unlimitedVTPEnabled && (
        <Box id="visual_testing">
          <K1Typography variant="h3" fontWeight="medium" className={classes.groupTitle}>
            <FormattedMessage id="subscriptions.testops_platform.offering.add_on" />
          </K1Typography>
          <VisualTestingPurchaseDetails
            accountId={accountId}
            initialQuota={initialVisualTestingQuota}
            title={<FormattedMessage id="subscriptions.visual_testing.title" />}
            clearInitialQuota={clearInitialQuota}
          />
        </Box>
      )}
      {flags && !flags.unlimitedVTPEnabled && <Divider />}
    </>
  );
};

export default PlatformOfferingSection;
