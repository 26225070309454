import Axios from 'axios';
import { Machine, PageableResponse } from '../models';
import { composeQuery, Query } from '../models/query';

export const getMachines = async (...criteria: Query<Machine>[]) => (await Axios.get<PageableResponse<Machine>>('/v1/dashboard/machines', {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  params: composeQuery<Machine>(...criteria),
  data: {},
})).data;
