import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { differenceInCalendarDays, differenceInDays, startOfDay, subMilliseconds } from 'date-fns';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import sortBy from 'lodash/sortBy';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import LoadingProgress from '../../layout/LoadingProgress';

import { KsSessionView, Role, User } from '../../models';
import { OrganizationFeature } from '../../models/organizationFeature';
import { Filter, Operator } from '../../models/query';
import { useNavigate, useQuery } from '../../routes';
import {
  fromAuth,
  fromLicenseUtilization,
  fromMachines,
  fromOrganizationRemovedUser,
  fromOrganizationUsers,
  useAppDispatch,
} from '../../store';
import K1DateRangePicker from './components/date-range-picker';
import ExportSessionButton from './components/ExportSessionButton';
import LicenseUsageSection from './components/license-usage-section';
import LicenseFilterButton from './components/LicenseFilterButton';
import MachineFilter from './components/machine-filter';
import SessionDetail from './components/session-detail';
import UserFilter from './components/user-filter';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    borderRadius: '.5rem',
    padding: '1.5rem',
    overflowY: 'auto',
  },
  title: {
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  filterAndDateContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(2.5),
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  filterContainer: {
    flex: 1,
    width: '100%',
  },
  dateRangeIcon: {
    marginRight: theme.spacing(0.5),
  },
  skeleton: {
    width: theme.spacing(30),
    height: theme.spacing(7),
  },
  filterButton: {
    marginBottom: theme.spacing(1),
  },
  date: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0.5),
  },
  licenseUtilTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(3),
  },
}));

const findFilter = (currentFilters: Filter<KsSessionView>[], filterName: keyof KsSessionView) => {
  const i = currentFilters.findIndex(
    it => it.field === filterName && it.operator === Operator.IN,
  );
  if (i === -1) return undefined;
  return currentFilters[i];
};

/**
 * A date range [startDate, endDate] is valid if it isn't longer than 366 days,
 *   both startDate and endDate must be <= now
 */
const dateRangeValidator = (startDate: Date, inclusiveEndDate: Date) => (
  startOfDay(inclusiveEndDate) <= startOfDay(new Date())
    && startOfDay(startDate) <= startOfDay(new Date())
    && startOfDay(inclusiveEndDate) >= startOfDay(startDate)
    && differenceInDays(inclusiveEndDate, startDate) <= 365 // max number of days schosen is 366
);

const LicenseUtilization = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { queryDictionary, get } = useQuery();
  const { navigate, replaceQuery } = useNavigate();
  const orgId = get('orgId');
  const startTime = get('startTime');
  const endTime = get('endTime');
  const machineKeys = useSelector(fromMachines.selectUniqueMachineKeysByOrganizationId(
    Number(orgId) || 0,
  ));
  const currentFilters = useRef<Filter<KsSessionView>[]>(JSON.parse(get('filters') || '[]'));
  const [loading, setLoading] = useState(true);
  const user = useSelector(fromAuth.selectUser);
  const listOrgUser = useSelector(fromOrganizationUsers.selectByOrganizationId(
    Number(orgId) || 0,
  ));
  const listRemovedUser = useSelector(fromOrganizationRemovedUser
    .selectOrganizationRemovedUserByOrganizationId(Number(orgId) || 0))
    .filter(removedUser => (
      !listOrgUser.some(it => it.user.email === removedUser.email)
    )); // filter out duplicate removedUser in activeUser list
  const listUser = useMemo(() => (
    sortBy(
      listOrgUser.map(it => ({ ...it.user, isRemoved: false })),
      user => user.email,
    ).concat(sortBy(
      listRemovedUser.map(it => ({ ...it, isRemoved: true, roles: it.roles || [Role.USER] })),
      user => user.email,
    ))
  ), [listOrgUser, listRemovedUser]);
  const defaultValueForUserFilter: User[] = findFilter(currentFilters.current, 'email')?.value
    ?.map((email: string) => listUser.find(user => user.email === email))
    ?.filter((user: User) => user !== undefined)
    ?? [];
  const defaultValueForMachineFilter: string[] = findFilter(currentFilters.current, 'machineId')?.value
    ?.filter((machineKey: string) => machineKeys.includes(machineKey)) ?? [];
  const defaultValueForLicenseFilter: OrganizationFeature[] = findFilter(currentFilters.current, 'feature')?.value
    ?.map((it: string) => OrganizationFeature.fromString(it.toString()))
    ?.filter((it: any) => it !== undefined) ?? [];

  const changeUrl = () => {
    navigate(undefined, replaceQuery(
      currentFilters.current.length > 0
        ? {
          ...queryDictionary(),
          filters: JSON.stringify(currentFilters.current),
        } : omit(queryDictionary(), 'filters'),
    ));
  };

  const removeParams = (paramsToBeRemoved: string[]) => {
    navigate(undefined, replaceQuery(omit(queryDictionary(), paramsToBeRemoved)));
  };

  const changeUrlDebounced = debounce(changeUrl, 2000);

  const filterChanged = (
    filterName: keyof KsSessionView,
    valueSet: any[],
    changeUrl: boolean = true,
  ) => {
    const index = currentFilters.current.findIndex(
      it => it.field === filterName && it.operator === Operator.IN,
    );
    const newFilters = index !== -1 ? [...currentFilters.current]
      : [...currentFilters.current, null];
    if (valueSet.length > 0) {
      newFilters[index !== -1 ? index : newFilters.length - 1] = {
        field: filterName,
        operator: Operator.IN,
        value: valueSet,
      };
    } else {
      if (index === -1) return;
      newFilters.splice(index, 1);
    }
    currentFilters.current = newFilters as any[];
    if (changeUrl) changeUrlDebounced();
  };

  const now = new Date();
  const defaultStartDate = new Date(now.getFullYear() - 1, now.getMonth() + 1, 1, 0, 0, 0);
  // [startTime, endTime) is an exclusive range, minus 1ms on endTime to get the inclusive end
  // if startTime and endTime are not given in the query params, use defaultStartDate and now
  const [endDate, setEndDate] = useState(endTime ? new Date(+endTime) : now);
  const [startDate, setStartDate] = useState(startTime ? new Date(+startTime) : defaultStartDate);

  useEffect(() => {
    const newStartDate = startTime ? new Date(+startTime) : defaultStartDate;
    const newEndDate = endTime ? new Date(+endTime) : now;
    if (!dateRangeValidator(newStartDate, subMilliseconds(newEndDate, 1))) {
      // if the date range provided by the params is not valid, remove params,
      // effectively setting the startDate and endDate to their defaults
      removeParams(['startTime', 'endTime']);
    } else {
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, endTime]);

  const handleDatesChanged = (newStartDate: Date, newEndDate: Date) => {
    if (differenceInCalendarDays(newEndDate, newStartDate) >= 0) {
      navigate(undefined, replaceQuery({
        ...queryDictionary(),
        startTime: newStartDate.getTime(),
        endTime: newEndDate.getTime(),
      }));
    }
  };

  useEffect(() => {
    if (!orgId || !user?.id) return;
    const fetchInformation = async () => {
      setLoading(true);
      await Promise.all([
        dispatch(fromLicenseUtilization.doGetLicenseUsageByFilters({
          organizationId: +orgId,
          startDate,
          endDate,
          filters: currentFilters.current,
        })),
        dispatch(fromMachines.doGetMachinesByOrganizationId(+orgId)),
        dispatch(fromOrganizationUsers.doGetAllOrgUsers(
          {
            organizationId: +orgId,
          },
        )),
        dispatch(fromOrganizationRemovedUser
          .doGetAllOrganizationRemovedUser({ organizationId: +orgId })),
      ]);
      setLoading(false);
    };

    fetchInformation();
    analytics.page();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get('filters'), startDate, endDate, orgId, user?.id]);

  return (
    <div className={classes.root}>
      <Grid
        container
        className={classes.title}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid item>
          <Typography variant="h2" className={classes.licenseUtilTitle}>
            <FormattedMessage id="license_utilization.license_utilization" />
          </Typography>
        </Grid>
        <Grid item>
          <ExportSessionButton
            user={user!!}
            organizationId={+orgId!!}
            filters={currentFilters.current}
            startDate={startDate}
            endDate={endDate}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" justifyContent="space-between" className={classes.filterAndDateContainer}>
        <Grid item className={classes.filterContainer}>
          {/* filter boxes are here */}
          <Grid container spacing={1} style={{ width: '100%' }} alignItems="center">
            <Grid item xs={12} sm={3.5} className={classes.filterButton}>
              <UserFilter
                onChange={value => filterChanged('email', value.map(user => user.email))}
                defaultValue={defaultValueForUserFilter}
                options={listUser}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} sm={3.5} className={classes.filterButton}>
              <MachineFilter
                onChange={value => filterChanged('machineId', value)}
                defaultValue={defaultValueForMachineFilter}
                machineKeys={machineKeys}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} sm={3} className={classes.filterButton}>
              <LicenseFilterButton
                onChange={value => filterChanged('feature', value)}
                defaultValue={defaultValueForLicenseFilter}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} sm={2} className={classes.filterButton}>
              {(currentFilters.current.length > 0 || startTime || endTime) && (
              <Button
                disabled={loading}
                onClick={() => {
                  filterChanged('email', [], false);
                  filterChanged('machineId', [], false);
                  filterChanged('feature', [], false);
                  removeParams(['filters', 'startTime', 'endTime']);
                }}
                style={{ height: '100%', marginBottom: '1px' }}
              >
                <FormattedMessage id="license_utilization.clear_all_filters" />
              </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.date}>
          <Grid container alignItems="center">
            <K1DateRangePicker
              dateRangeValidator={dateRangeValidator}
              defaultStartDate={startDate}
              defaultEndDate={subMilliseconds(endDate, 1)}
              onChange={handleDatesChanged}
            />
          </Grid>
        </Grid>
      </Grid>
      <LicenseUsageSection loading={loading} />
      <SessionDetail
        filters={currentFilters.current}
        startDate={startDate}
        endDate={endDate}
      />
      {loading && <LoadingProgress />}
    </div>
  );
};

export default LicenseUtilization;
