import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useConfig } from '../../config';
import LoadingProgress from '../../layout/LoadingProgress';
import { OrganizationFeature } from '../../models';
import { useQuery } from '../../routes';
import {
  fromOrganizationConfigurations,
  fromOrganizationFeatureFlag,
  fromOrganizations,
  fromSubscriptions,
  fromTestOpsPlatformSubscriptions,
  useAppDispatch,
} from '../../store';
import CustomDomainAndSso from './CustomDomainAndSso';
import IdleTimeout from './IdleTimeout';
import IpAddressRestrictions from './IpAddressRestrictions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    height: '100%',
    width: '100%',
    borderRadius: '.5rem',
    overflowY: 'auto',
    minWidth: theme.spacing(96),
  },
  container: {
    width: '100%',
  },
  paperRoot: {
    margin: theme.spacing(2, 0),
    minWidth: theme.spacing(96),
    borderRadius: theme.spacing(1),
  },
  header: {
    fontSize: theme.spacing(3),
    fontWeight: theme.typography.fontWeightMedium,
    color: '#233145',
  },
}));

const OrgSecuritySetting = () => {
  const { get } = useQuery();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { config } = useConfig();
  const orgIdFromUrl = Number(get('orgId'));
  const accountId = Number(get('accountId'));

  const [orgId, setOrgId] = useState(orgIdFromUrl);

  const organizationConfigurations = useSelector(
    fromOrganizationConfigurations.selectByOrganizationId(orgId),
  );
  const activePlatformSubscription = useSelector(fromTestOpsPlatformSubscriptions
    .selectPaidTestOpsPlatformSubscriptionByAccountId(
      accountId,
    )).filter(sub => new Date(sub.expiryDate).getTime() > Date.now())?.[0];

  const hasTOEnterprise = useSelector(fromSubscriptions
    .selectHasTOEnterpriseByOrganizationId(Number(orgId) || 0));

  const isSsoFlagEnabled = useSelector(fromOrganizationFeatureFlag.selectIsOrganizationSsoEnabled(
    Number(orgId),
  )) || false;

  const isEnableConfigureCustomDomainAndSSO = (
    activePlatformSubscription?.feature === OrganizationFeature.TESTOPS_PLATFORM
    && activePlatformSubscription.quota >= 30000)
    || hasTOEnterprise
    || isSsoFlagEnabled;

  const organizationFromAccounts = useSelector(fromOrganizations.selectAllByAccountId(accountId));

  useEffect(() => {
    if (!orgId) return;
    setLoading(true);
    const fetchInformation = async () => {
      const promises: Promise<any>[] = [
        dispatch(fromOrganizationConfigurations.doGetByOrganizationId(orgId)),
        dispatch(fromOrganizationFeatureFlag
          .doGetByOrganizationId({ organizationId: Number(orgId) })),
      ];
      if (!config?.onpremise) {
        promises.push(
          dispatch(fromTestOpsPlatformSubscriptions
            .doGetAllTestOpsPlatformSubscriptionsByAccountId({
              accountId: Number(accountId),
            })),
          dispatch(fromSubscriptions
            .doGetActiveSubscriptionsByOrgId({ organizationId: Number(orgId) })),
        );
      }
      await Promise.all(promises);
      setLoading(false);
    };
    fetchInformation().catch(() => {});
  }, [orgId, dispatch]);

  useEffect(() => {
    if (!orgIdFromUrl && accountId) {
      dispatch(fromOrganizations.doFetchOrganizationByAccountId({ accountId }));
    }
  }, [orgIdFromUrl, accountId]);

  useEffect(() => {
    if (isEmpty(organizationFromAccounts) || orgIdFromUrl) return;
    setOrgId(organizationFromAccounts[0].id);
  }, [orgIdFromUrl, organizationFromAccounts]);

  return (
    <>
      <Box key="app.page.title.security_settings" className={classes.root}>
        <Typography variant="subtitle2" className={classes.header}>
          <FormattedMessage id="security_setting.header" />
        </Typography>
        <Box overflow="auto" className={classes.container}>
          <Paper key="app.page.security_settings.idle_timeout.paper" elevation={1} className={classes.paperRoot}>
            <IdleTimeout
              organizationId={orgId}
              configurations={organizationConfigurations}
            />
          </Paper>
          <Paper key="app.page.security_settings.custom_domain.paper" elevation={1} className={classes.paperRoot}>
            <CustomDomainAndSso
              organizationId={orgId}
              hasValidSubscription={isEnableConfigureCustomDomainAndSSO}
              onpremise={!!config?.onpremise}
            />
          </Paper>
          {
            !config?.onpremise && (
              <Paper key="app.page.security_settings.ip_address_restrictions.paper" elevation={1} className={classes.paperRoot}>
                <IpAddressRestrictions
                  organizationId={orgId}
                  hasValidSubscription={isEnableConfigureCustomDomainAndSSO}
                />
              </Paper>
            )
          }
        </Box>
      </Box>
      {loading && <LoadingProgress />}
    </>
  );
};

export default OrgSecuritySetting;
