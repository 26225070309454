import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import Divider from '@mui/material/Divider';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import find from 'lodash/fp/find';
import isEmpty from 'lodash/fp/isEmpty';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { ReactComponent as arrowDownGray } from '../../../icons/arrowDownGray.svg';
import { useAppDispatch } from '../../../../store';
import {
  fromInvoices,
  fromTestOpsPlatformConfiguration,
  fromTestOpsPlatformSubscriptions,
  fromStarterPackage,
} from '../../../../store/rootReducer';
import BillingCycle from './BillingCycle';
import {
  getFeatureName,
  OrganizationFeature,
  TestOpsPlanInterval,
  TestOpsPlatformSubscription,
  TestOpsPlatformTier,
} from '../../../../models';
import { ReactComponent as BoxIcon } from '../../../icons/box.svg';
import { ReactComponent as Question } from '../../../icons/question_message.svg';
import { ReactComponent as Organization } from '../../../icons/sitemap.svg';
import { ReactComponent as Users } from '../../../icons/users.svg';
import { TestOpsPlatformTierType } from '../../../../models/testOpsPlatformTierType';
import { getPlanId } from './utils';
import { ReactComponent as Project } from '../../../icons/project-diagram.svg';
import { BUSINESS_EMAIL } from '../../utils';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0.5, 2, 0, 0),
    boxShadow: 'none',
  },
  container: {
    marginBottom: theme.spacing(3),
  },
  description: {
    paddingRight: theme.spacing(2),
  },
  billingCycle: {
    display: 'flex',
    flexDirection: 'row',
  },
  price: {
    padding: theme.spacing(1, 0),
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    color: '#22283c',
  },
  enabled: {
    '& > *': {
      backgroundColor: theme.palette.getContrastText('#000'),
    },
  },
  selectionForm: {
    width: '70%',
  },
  formatSelection: {
    border: 'solid 1px',
    backgroundColor: '#FFFFFF',
    borderColor: '#D5D8DD',
    height: theme.spacing(5),
    fontWeight: 500,
    fontSize: 14,
  },
  clearSession: {
    backgroundColor: '#FFFFFF',
    borderColor: '#D5D8DD',
    height: 32,
    fontWeight: 600,
    fontSize: 14,
    color: '#276ef1',
  },
  planDescription: {
    marginTop: theme.spacing(1),
  },
  benefit: {
    padding: theme.spacing(0, 4, 0, 0),
    margin: theme.spacing(0, 0, 1, 0),
  },
  icon: {
    margin: theme.spacing(0, 1, 0, 0),
    color: '#4f5465',
  },
  premierSuccess: {
    marginBottom: theme.spacing(0.5),
    fontWeight: theme.typography.fontWeightBold,
  },
  questionIcon: {
    margin: theme.spacing(0, 1, 0, 0),
    color: '#ffffff',
  },
  contactSale: {
    minWidth: theme.spacing(20),
  },
  contactSaleBtn: {
    backgroundColor: '#1847CA',
    color: 'white',
    width: theme.spacing(20),
    minWidth: theme.spacing(20),
  },
  errorMessage: {
    color: theme.palette.error.main,
    margin: theme.spacing(1, 2, 2, 0),
    fontWeight: '400',
    fontSize: theme.spacing(1.75),
  },
}));

export interface PlatformPurchaseDetailsProps {
  accountId: number;
  currentSubscription?: TestOpsPlatformSubscription;
  initialInterval: TestOpsPlanInterval;
  initialQuota?: number;
  clearInitialQuota: Function;
}

const PlatformPurchaseDetails = (props: PlatformPurchaseDetailsProps) => {
  const {
    accountId,
    currentSubscription,
    initialInterval,
    initialQuota,
    clearInitialQuota,
  } = props;
  const intl = useIntl();
  const classes = useStyles();
  const [chosenInterval, setChosenInterval] = useState(initialInterval);
  const preview = useSelector(fromInvoices
    .selectInvoicePreviewByFeature(OrganizationFeature.TESTOPS_PLATFORM), shallowEqual);
  const loading = useSelector(fromInvoices
    .selectLoadingByFeature(OrganizationFeature.TESTOPS_PLATFORM));
  const monthlyTierList = useSelector(fromTestOpsPlatformConfiguration
    .selectConfigurationByBillingCycle(TestOpsPlanInterval.MONTH));
  const anualyTierList = useSelector(fromTestOpsPlatformConfiguration
    .selectConfigurationByBillingCycle(TestOpsPlanInterval.YEAR));
  const [selectedQuota, setSelectedQuota] = useState(initialQuota);
  const [tierList, setTierList] = useState(anualyTierList);
  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const dispatch = useAppDispatch();
  // eslint-disable-next-line max-len
  const isUnlimited = (quota: number): boolean => (quota >= anualyTierList?.at(-1)?.executionTestResults!!);
  const isCurrentSubscriptionUnlimited = currentSubscription
    && isUnlimited(currentSubscription.quota);

  const trialPlatformSubscription = useSelector(
    fromTestOpsPlatformSubscriptions.selectActiveTrialTestOpsPlatformSubscriptionByAccountId(
      Number(accountId),
    ),
  )?.[0];

  const disabledOffering = (disabled: boolean) => {
    dispatch(fromInvoices.doDisabledOffering(disabled));
  };

  const selectedStarterPackage = useSelector(fromStarterPackage.selectStarterPackage);

  const doPreviewInvoice = useCallback((
    _chosenQuota,
    _chosenInterval,
    _currentSubscription,
  ) => {
    if ((_chosenQuota !== _currentSubscription?.quota
        || _chosenInterval !== _currentSubscription?.billingCycle)
      && (_chosenInterval === TestOpsPlanInterval.MONTH
        || _chosenInterval === TestOpsPlanInterval.YEAR)) {
      dispatch(fromInvoices.doPreviewInvoice({
        organizationId: accountId,
        planId: getPlanId(
          OrganizationFeature.TESTOPS_PLATFORM,
          _chosenInterval,
        ),
        number: _chosenQuota,
        newQuotaNumber: _chosenQuota,
        billingCycle: _chosenInterval,
        // eslint-disable-next-line max-len
        ...(_currentSubscription && { subscriptionId: _currentSubscription.id, recurlySubscriptionUuid: _currentSubscription.recurlySubscription?.recurlySubscriptionUuid }),
      }))
        .then(unwrapResult)
        .finally(() => disabledOffering(false));
    } else {
      dispatch(fromInvoices.doResetPreviewByFeature(OrganizationFeature.TESTOPS_PLATFORM));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTierFromTierList = (tierList: TestOpsPlatformTier[]) => {
    if (isCurrentSubscriptionUnlimited) {
      return tierList.at(-1);
    }

    return find(
      it => {
        if (Number(initialQuota) > 0) {
          return it.executionTestResults === initialQuota;
        }
        return false;
      },
      tierList,
    );
  };

  useEffect(() => {
    if (isEmpty(preview?.invoice)) {
      const tierList = getTierListBaseOnInterval(initialInterval);
      const tier = getTierFromTierList(tierList);
      if (tier) {
        disabledOffering(
          isUnlimited(tier.executionTestResults)
          && tier.testOpsPlatformTier !== currentSubscription?.tier,
        );
        setTierList(tierList);
        setSelectedQuota(tier.executionTestResults);
      } else {
        // By default, just select annual list
        // and disable offering section when not select platform tier or select enterprise tier
        disabledOffering(!trialPlatformSubscription);
        setTierList(anualyTierList);
        setSelectedQuota(0);
      }
    } else if (!isEmpty(preview?.order)){
      setTierList(getTierListBaseOnInterval(preview?.order.billingCycle ?? initialInterval));
      setSelectedQuota(preview?.order.number);
    }
  }, [currentSubscription, initialInterval, initialQuota]);

  useEffect(() => {
    if (selectedQuota
      && (selectedQuota !== currentSubscription?.quota
      || chosenInterval !== currentSubscription?.billingCycle)
      && !isUnlimited(selectedQuota)) {
      doPreviewInvoice(
        selectedQuota,
        chosenInterval,
        currentSubscription,
      );
    }
  }, [selectedQuota, chosenInterval]);

  useEffect(() => {
    if (selectedStarterPackage?.listPlans.find(plan => plan.feature
      === OrganizationFeature.TESTOPS_PLATFORM && plan.quota > 2000) !== undefined) {
      handleDisabledOffering();
      dispatch(fromInvoices.doResetPreviewByFeature(OrganizationFeature.TESTOPS_PLATFORM));
      setSelectedQuota(0);
      setChosenInterval(TestOpsPlanInterval.YEAR);
      clearInitialQuota(OrganizationFeature.TESTOPS_PLATFORM);
    }
    setIsOpenSelect(false);
  }, [selectedStarterPackage]);

  const handleOnSelectExecutions = (event: SelectChangeEvent) => {
    const executionTestResults = Number(event.target.value);
    const tier = find(
      it => it.executionTestResults === executionTestResults,
      tierList,
    );
    if (tier) {
      if (currentSubscription) {
        if (currentSubscription.billingCycle === TestOpsPlanInterval.MONTH
          && currentSubscription.quota === tier.executionTestResults) {
          setChosenInterval(TestOpsPlanInterval.YEAR);
        } else {
          setChosenInterval(currentSubscription.billingCycle);
        }
      }
      setSelectedQuota(tier.executionTestResults);
      if (isUnlimited(tier.executionTestResults)
        || (tier.executionTestResults === currentSubscription?.quota)) {
        disabledOffering(!currentSubscription);
        dispatch(fromInvoices.doResetPreviewByFeature(OrganizationFeature.TESTOPS_PLATFORM));
      } else {
        disabledOffering(false);
      }
    }
  };

  const getTierListBaseOnInterval = (interval: TestOpsPlanInterval) => {
    if (interval === TestOpsPlanInterval.YEAR) {
      return anualyTierList;
    }
    return monthlyTierList;
  };

  const handleChangeInterval = (interval: TestOpsPlanInterval) => {
    setIsOpenSelect(false);
    setChosenInterval(interval);
    const tierList = getTierListBaseOnInterval(interval);
    const tier = find(
      it => it.executionTestResults === selectedQuota,
      tierList,
    );
    if (tier) {
      disabledOffering(false);
      setTierList(tierList);
      setSelectedQuota(tier.executionTestResults);
      doPreviewInvoice(
        tier?.executionTestResults,
        interval,
        currentSubscription,
      );
    }
  };

  const handleDisabledOffering = () => {
    if (currentSubscription) {
      disabledOffering(isUnlimited(currentSubscription.quota));
    } else if (trialPlatformSubscription) {
      disabledOffering(isUnlimited(trialPlatformSubscription.quota));
    } else {
      disabledOffering(true);
    }
  };

  const handleResetSelectedTier = () => {
    handleDisabledOffering();
    dispatch(fromInvoices.doResetPreviewByFeature(OrganizationFeature.TESTOPS_PLATFORM));
    setSelectedQuota(currentSubscription ? currentSubscription.quota : 0);
    setIsOpenSelect(false);
    clearInitialQuota(OrganizationFeature.TESTOPS_PLATFORM);
  };

  const handleClickSelect = () => !loading && setIsOpenSelect(!isOpenSelect);

  const getTierFromQuota = (quota: number | undefined) => find(
    it => it.executionTestResults === quota,
    tierList,
  );

  const selectedTier = getTierFromQuota(selectedQuota);

  const isDowngrade = (executionTestResults: number) => {
    if (currentSubscription) {
      if (currentSubscription.quota > executionTestResults
        || (currentSubscription.billingCycle !== TestOpsPlanInterval.MONTH
          && currentSubscription.quota === executionTestResults)) {
        return true;
      }
    }
    return false;
  };

  const isMultipleYearsCycle = currentSubscription?.billingCycle === TestOpsPlanInterval.TWO_YEARS
    || currentSubscription?.billingCycle === TestOpsPlanInterval.THREE_YEARS;

  const disabledBillingCycle = loading
    || currentSubscription?.billingCycle === TestOpsPlanInterval.YEAR
    || (selectedStarterPackage?.listPlans?.find(plan => (plan.feature
        === OrganizationFeature.TESTOPS_PLATFORM && plan.quota > 2000)) !== undefined);

  const disabledSelectionTestResults = loading
      || (selectedStarterPackage?.listPlans?.find(plan => (plan.feature
          === OrganizationFeature.TESTOPS_PLATFORM && plan.quota > 2000)) !== undefined);

  const renderPlatformSelection = () => (
    <>
      <Grid item xs={5} className={classes.description}>
        <FormControl className={classes.selectionForm}>
          <Select
            labelId="demo-controlled-open-select-label"
            className={classes.formatSelection}
            onChange={handleOnSelectExecutions}
            value={String(selectedQuota)}
            disabled={disabledSelectionTestResults || isMultipleYearsCycle}
            open={isOpenSelect && !disabledSelectionTestResults}
            onClick={handleClickSelect}
            defaultValue="0"
            IconComponent={arrowDownGray}
            sx={{
              '& .MuiSelect-select .notranslate::after':
                {
                  content: '"Select Platform Tier"',
                  color: '#727993',
                  fontWeight: 500,
                },
            }}
          >
            {tierList?.map(({ executionTestResults }) => (
              <MenuItem
                key={executionTestResults}
                value={executionTestResults}
                disabled={isDowngrade(executionTestResults)}
              >
                <FormattedMessage id={`subscriptions.testops_platform.testops_platform.label.${executionTestResults}`} />
              </MenuItem>
            ))}
            {!isCurrentSubscriptionUnlimited && selectedTier && (
              <div>
                <Divider />
                <MenuItem
                  className={classes.clearSession}
                  onClick={handleResetSelectedTier}
                  key="subscriptions.testops_platform.no_test_executions.clear"
                >
                  <FormattedMessage id="subscriptions.testops_platform.clear" />
                </MenuItem>
              </div>
            )}
          </Select>
        </FormControl>
        <Box className={classes.planDescription}>
          <FormattedMessage
            id="subscriptions.testops_platform.offering.platform_description"
            values={{
              br: <br />,
            }}
          />
        </Box>
      </Grid>
    </>
  );

  const renderPlatformBenefit = () => {
    if (selectedTier) {
      if (selectedTier.testOpsPlatformTier === TestOpsPlatformTierType.PROFESSIONAL) {
        return (
          <>
            <Grid item xs={2.5}>
              <Box className={classes.benefit}>
                <Organization className={classes.icon} />
                <FormattedMessage
                  id="subscriptions.testops_platform.offering.no_of_org"
                  values={{
                    org: selectedTier.activeOrganizations || 1,
                    b: (chunk: string[]) => (<b>{chunk}</b>),
                  }}
                />
              </Box>
              <Box className={classes.benefit}>
                <Project className={classes.icon} />
                <FormattedMessage
                  id="subscriptions.testops_platform.offering.no_of_project"
                  values={{
                    prject: selectedTier.activeProjects || 'Unlimited',
                    b: (chunk: string[]) => (<b>{chunk}</b>),
                  }}
                />
              </Box>
              <Box className={classes.benefit}>
                <Users className={classes.icon} />
                <FormattedMessage
                  id="subscriptions.testops_platform.offering.no_of_user"
                  values={{
                    user: selectedTier.activeUsers || 'Unlimited',
                    b: (chunk: string[]) => (<b>{chunk}</b>),
                  }}
                />
              </Box>
            </Grid>
          </>
        );
      }

      return (
        <>
          <Grid item xs={1} />
          <Grid
            item
            xs={3.5}
          >
            <Box
              className={classes.benefit}
            >
              <Project className={classes.icon} />
              <FormattedMessage
                id="subscriptions.testops_platform.offering.enterprise_benefit"
                values={{
                  b: (chunk: string[]) => (<b>{chunk}</b>),
                }}
              />
            </Box>
          </Grid>
        </>
      );
    }

    return (
      <Grid item xs={2.5}>
        <Box className={classes.benefit}>
          <BoxIcon className={classes.icon} />
          <FormattedMessage id="subscriptions.testops_platform.offering.platform_benefit" />
        </Box>
      </Grid>
    );
  };

  const renderPriceSection = () => {
    if (selectedTier && isUnlimited(selectedTier.executionTestResults)) {
      return (
        <>
          <Grid item xs={2.5} justifyContent="flex-end" className={classes.contactSale}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                display="flex"
                justifyContent="flex-end"
                fullWidth
                component={Link}
                href={BUSINESS_EMAIL}
                size="medium"
                color="primary"
                variant="contained"
                className={classes.contactSaleBtn}
              >
                <Question className={classes.questionIcon} />
                <FormattedMessage id="subscriptions.testops_platform.contact_sales" />
              </Button>
            </Box>
          </Grid>
        </>
      );
    }

    return (
      <>
        <Grid item xs={4.5} justifyContent="flex-end">
          <BillingCycle
            initialInterval={chosenInterval}
            onChangeInterval={interval => handleChangeInterval(interval)}
            disabled={disabledBillingCycle || isMultipleYearsCycle}
          />
          <Box display="flex" justifyContent="flex-end">
            <Typography variant="h6" className={classes.price}>
              {
                loading
                  ? <CircularProgress size={20} />
                  : `$${intl.formatNumber(preview?.invoice?.subTotal ?? 0)}`
              }
            </Typography>
          </Box>
        </Grid>
      </>
    );
  };

  return (
    <Paper className={classes.root}>
      <Grid container className={classes.container}>
        {renderPlatformSelection()}
        {renderPlatformBenefit()}
        {renderPriceSection()}
      </Grid>
      {currentSubscription?.hasPremierSuccessAddOn && (
        <Grid className={classes.premierSuccess}>
          <FormattedMessage id="subscriptions.premier.success.add.on.title" />
        </Grid>
      )}
      {isMultipleYearsCycle && (
        <Grid className={classes.errorMessage}>
          <FormattedMessage
            id="subscriptions.multiple.year.warning.title"
            values={{
              feature: intl.formatMessage({ id: `subscriptions.${getFeatureName(OrganizationFeature.TESTOPS_PLATFORM)}.title` }),
            }}
          />
        </Grid>
      )}
    </Paper>
  );
};

export default PlatformPurchaseDetails;
