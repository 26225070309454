import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { ChartData, ChartOptions } from 'chart.js';
import prettyMilliseconds from 'pretty-ms';
import { useEffect, useMemo, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { FormattedMessage, useIntl } from 'react-intl';

import { TestActivity } from '../../../models/dashboard';

const useStyles = makeStyles(theme => ({
  section: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  infoIcon: {
    verticalAlign: 'middle',
  },
  tooltipInfoContent: {
    padding: theme.spacing(1),
  },
  root: {
    height: '100%',
    borderRadius: '.5rem',
    padding: '.5rem',
  },
  testTotalLabel: {
    overflow: 'visible',
    textTransform: 'uppercase',
  },
  chartContainer: {
    // position is relative so that Chart can be responsive
    position: 'relative',
    // width 100% to fit the chart when the container/screen width is small
    width: '100%',
    // maxWidth is set to prevent the chart from growing infinitely
    maxWidth: theme.spacing(65),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  toUpper: {
    textTransform: 'uppercase',
  },
}));

const getPass7Days = () => {
  const result = [];
  for (let i = 0; i < 7; i += 1) {
    const day = new Date();
    result.unshift(day.setDate(day.getDate() - i));
  }
  return result;
};

const last7Days = getPass7Days();

const getTestActivitiesData = (data: TestActivity[]) => {
  const dayList = last7Days;
  const testResultData = [];
  const testDurationData = [];
  let testDataIndex = 0;
  for (let i = 0; i < 7; i += 1) {
    if (testDataIndex === data.length) {
      testDurationData.push(0);
      testResultData.push(0);
    } else {
      const timeDiff = Math.abs(new Date(dayList[i]).getTime()
        - new Date(data[testDataIndex].date).getTime());
      if (Math.ceil(timeDiff / (1000 * 3600 * 24))) {
        testResultData.push(data[testDataIndex].testResult);
        // Cast duration from ms to s for better display
        testDurationData.push(data[testDataIndex].testDuration);
        testDataIndex += 1;
      } else {
        testDurationData.push(0);
        testResultData.push(0);
      }
    }
  }
  return { testResultData, testDurationData };
};

const TooltipInfoContent = () => {
  const classes = useStyles();
  return (
    <div className={classes.tooltipInfoContent}>
      <FormattedMessage id="dashboard.past_7_day_activities_chart.tooltip.info" />
    </div>
  );
};

interface TestActivitiesChartProps {
  testActivities: TestActivity[];
}

const TestActivitiesChart = (props: TestActivitiesChartProps) => {
  const { testActivities } = props;
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const [dataReady, setDataReady] = useState(false);

  // Call getTestActivitiesData here just to deduce the type for useState
  const [testActivitiesData, setTestActivitiesData] = useState(
    getTestActivitiesData(testActivities),
  );

  useEffect(() => {
    if (testActivities.length) {
      const newTestActivitiesData = getTestActivitiesData(testActivities);
      if (testActivitiesData !== newTestActivitiesData) {
        setTestActivitiesData(newTestActivitiesData);
        setDataReady(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testActivities]);

  const data: ChartData = useMemo(() => ({
    labels: last7Days,
    datasets: [
      {
        type: 'line',
        label: intl.formatMessage({ id: 'dashboard.past_7_day_activities_chart.test_duration' }),
        data: testActivitiesData.testDurationData,
        fill: false,
        backgroundColor: theme.palette.background.paper,
        borderColor: theme.palette.mode === 'light' ? '#367cff' : '#f8f8f8', // apply line color for specific theme
        order: 1,
        yAxisID: 'yLeft',
      },
      {
        type: 'bar',
        label: intl.formatMessage({ id: 'dashboard.past_7_day_activities_chart.test_activities' }),
        data: testActivitiesData.testResultData,
        fill: false,
        barThickness: 40,
        backgroundColor: '#BAE6FF',
        hoverBackgroundColor: theme.palette.primary.main,
        borderRadius: 6,
        order: 2,
        pointStyle: 'rectRounded',
        yAxisID: 'yRight',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [testActivitiesData]);

  const options = useMemo<ChartOptions>(() => ({
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1.7,
    transitions: {
      show: {
        animation: {
          duration: 0,
        },
      },
      hide: {
        animation: {
          duration: 0,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          usePointStyle: true,
        },
      },
      tooltip: {
        mode: 'nearest',
        backgroundColor: theme.palette.background.default,
        titleColor: theme.palette.text.primary,
        titleFont: {
          family: theme.typography.fontFamily,
          size: theme.typography.fontSize,
          weight: `${theme.typography.fontWeightMedium}`,
        },
        bodyFont: {
          family: theme.typography.fontFamily,
          size: theme.typography.fontSize,
          weight: `${theme.typography.fontWeightRegular}`,
        },
        bodyColor: theme.palette.text.primary,
        callbacks: {
          title(context) {
            return intl.formatDate(new Date(+context[0].label), {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            });
          },
          label(context) {
            let label = context.dataset.label || '';
            if (label === intl.formatMessage({ id: 'dashboard.past_7_day_activities_chart.test_duration' })) {
              if (testActivitiesData.testDurationData[context.dataIndex]) {
                label += `: ${+testActivitiesData.testDurationData[context.dataIndex] > 1
                  ? '~'
                  : ''}${prettyMilliseconds(testActivitiesData.testDurationData[context.dataIndex], {
                  unitCount: 2,
                  secondsDecimalDigits: 0,
                })}`;
              } else {
                label += `: ${prettyMilliseconds(0)}`;
              }
            } else {
              label += `: ${testActivitiesData.testResultData[context.dataIndex]}`;
            }
            return label;
          },
        },
      },
    },
    elements: {
      point: {
        radius: 4,
      },
    },
    scales: {
      yLeft: {
        display: false,
        suggestedMin: 0,
      },
      yRight: {
        display: false,
        suggestedMin: 0,
      },
      x: {
        ticks: {
          font: {
            size: theme.typography.fontSize,
            weight: `${theme.typography.fontWeightMedium}`,
            family: theme.typography.fontFamily,
          },
          color: theme.typography.h5.color,
          callback(value) {
            return intl.formatDate(new Date(+last7Days[+value]), {
              weekday: 'short',
            });
          },
        },
      },
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [testActivitiesData]);

  const Bar = useMemo(() => (
    <Chart
      type="bar"
      data={data}
      options={options}
    />
  ), [data, options]);

  return (
    <div className={classes.section}>
      <Grid container>
        <Grid item>
          <Typography variant="h3">
            {intl.formatMessage({ id: 'dashboard.past_7_day_activities_chart.test_activities' })}
            &nbsp;
            <Tooltip title={<TooltipInfoContent />} placement="right-end">
              <InfoOutlinedIcon fontSize="inherit" className={classes.infoIcon} />
            </Tooltip>
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item>
          <Typography variant="subtitle1" className={classes.toUpper}>
            {`Today, ${intl.formatDate(new Date(last7Days[last7Days.length - 1]), {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            })}`}
          </Typography>
        </Grid>
      </Grid>

      {dataReady && (
        <Grid container style={{ flex: '1', marginTop: '1.5rem' }}>
          <Grid item xs={9} style={{ display: 'flex', alignItems: 'stretch' }}>
            <Grid container justifyContent="space-around" alignItems="center">
              <Grid item className={classes.chartContainer}>
                {Bar}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} />
          <Grid container item xs={2} direction="column" alignItems="flex-start" justifyContent="space-evenly">
            <Grid item>
              <Typography variant="h2" style={{ overflow: 'visible' }}>
                {intl.formatNumber(testActivitiesData.testResultData.reduce((a, b) => a + b, 0))}
              </Typography>
              <Typography variant="h5" className={classes.testTotalLabel}>
                {intl.formatMessage({ id: 'dashboard.past_7_day_activities_chart.test_executions' })}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h2" style={{ overflow: 'visible' }}>
                {prettyMilliseconds(testActivitiesData?.testDurationData
                  ?.reduce((a, b) => a + b, 0) ?? 0, {
                  unitCount: 2,
                  secondsDecimalDigits: 0,
                })}
              </Typography>
              <Typography variant="h5" className={classes.testTotalLabel}>
                {intl.formatMessage({ id: 'dashboard.past_7_day_activities_chart.test_duration' })}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>

  );
};

export default TestActivitiesChart;
